var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "company-base-info" }, [
    _c("div", { staticClass: "base-title" }, [_vm._v("基础信息")]),
    _vm._v(" "),
    _c("div", { staticClass: "base-wrap" }, [
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("法人:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right" }, [
          _vm._v(_vm._s(_vm.detail.legal_name)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("人员规模:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right" }, [
          _vm._v(_vm._s(_vm.detail.employe_scale)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("参保人数:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right" }, [
          _vm._v(_vm._s(_vm.detail.employe_number)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("注册地址:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right" }, [
          _vm.detail.isVirtual
            ? _c("span", { staticClass: "virtual-address" }, [
                _vm._v("疑似虚拟注册地址"),
              ])
            : _c("span", [_vm._v(_vm._s(_vm.detail.registered_address))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("营业期限:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right" }, [
          _vm._v(_vm._s(_vm.detail.period)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("纳税人识别号:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right" }, [
          _vm._v(_vm._s(_vm.detail.company_number)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("注册资本:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right" }, [
          _vm._v(_vm._s(_vm.detail.registered_capital)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("经营状态:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right", style: _vm.isLogOut }, [
          _vm._v("\n        " + _vm._s(_vm.detail.company_status) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("公司类型:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right" }, [
          _vm._v(_vm._s(_vm.detail.company_type)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("行业:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right" }, [
          _vm._v(_vm._s(_vm.detail.industry)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "base-item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("注册时间:")]),
        _vm._v(" "),
        _c("div", { staticClass: "base-right" }, [
          _vm._v(_vm._s(_vm.detail.registered_time)),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "last-wrap" }, [
      _c("div", { staticClass: "last-fl" }, [_vm._v("经营范围:")]),
      _vm._v(" "),
      _c("div", { staticClass: "last-fr" }, [
        _vm._v(_vm._s(_vm.detail.business_scope)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }