var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-main" }, [
    _c("div", { staticClass: "btn-list" }, [
      _c(
        "div",
        { staticClass: "input-row" },
        [
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: { size: "mini", placeholder: "输入商机名称/关联公司" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-row" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.searchAgent },
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.reset } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "g-search", on: { click: _vm.searchPlus } },
            [
              _vm._v("\n        高级筛选"),
              _c("svg-icon", {
                staticClass: "filtrate",
                attrs: { "icon-class": "filtrate" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.condition,
                  expression: "condition",
                },
              ],
              staticClass: "condition",
            },
            [
              _c(
                "span",
                {
                  staticClass: "condition-icon",
                  on: { click: _vm.removeSearch },
                },
                [_c("i", { staticClass: "el-icon-circle-close" })]
              ),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { size: "mini" },
                model: {
                  value: _vm.showCondition,
                  callback: function ($$v) {
                    _vm.showCondition = $$v
                  },
                  expression: "showCondition",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.predictAmount,
                  expression: "predictAmount",
                },
              ],
              staticStyle: {
                "margin-right": "15px",
                display: "flex",
                "align-items": "center",
                "font-size": "12px",
              },
            },
            [
              _vm._v(
                "\n        预估签单合计金额：" +
                  _vm._s(_vm.predictAmount.toLocaleString() + "元") +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "dt-permission",
                  rawName: "v-dt-permission",
                  value: "客户管理CRM-商机管理-新建",
                  expression: "'客户管理CRM-商机管理-新建'",
                },
              ],
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.chanceCreate },
            },
            [_vm._v("\n        新建商机\n      ")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "table",
      },
      [
        _c(
          "el-table",
          {
            key: _vm.refreshKey,
            staticClass: "table-dt",
            staticStyle: {
              width: "100%",
              height: "100%",
              "font-size": "12px",
              "background-color": "#fff",
            },
            attrs: {
              data: _vm.tableData,
              border: "",
              stripe: "",
              "cell-class-name": "hover-state",
            },
          },
          _vm._l(_vm.columnList, function (item) {
            return _c("el-table-column", {
              attrs: {
                "show-tooltip-when-overflow":
                  item.field !== "billKeypersonDesc",
                prop: item.field,
                align: "center",
                width: item.field === "nicheStateDesc" ? "200" : "",
                label: item.name,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        item.type !== "select"
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.name) +
                                  "\n          "
                              ),
                            ])
                          : _c(
                              "span",
                              [
                                _c(
                                  "el-dropdown",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      trigger: "click",
                                    },
                                    on: {
                                      command: (val) =>
                                        _vm.filterFunc(val, item),
                                    },
                                  },
                                  [
                                    item.field === "followName"
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "el-dropdown-link",
                                              class: _vm.userId ? "active" : "",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name)),
                                              ]),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c("dt-drop-down", {
                                                attrs: {
                                                  "source-list": _vm.userList,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (row) {
                                                        return [
                                                          _c(
                                                            "el-scrollbar",
                                                            {
                                                              staticClass:
                                                                "dt-dropdown-dt",
                                                            },
                                                            [
                                                              !row.filterList
                                                                .length
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "dt-drop-down-no-data",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          无数据\n                        "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                row.filterList,
                                                                function (
                                                                  _item
                                                                ) {
                                                                  return _c(
                                                                    "el-dropdown-item",
                                                                    {
                                                                      class:
                                                                        _vm.userId ===
                                                                        _item.id
                                                                          ? "active"
                                                                          : "",
                                                                      attrs: {
                                                                        command:
                                                                          _item.id,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  _item.htmlEl
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : item.field === "stateDesc"
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "el-dropdown-link",
                                              class: _vm.nicheStatus
                                                ? "active"
                                                : "",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name)),
                                              ]),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c(
                                                "el-scrollbar",
                                                {
                                                  staticClass: "dt-dropdown-dt",
                                                },
                                                _vm._l(
                                                  _vm.nicheStatusList,
                                                  function (_item) {
                                                    return _c(
                                                      "el-dropdown-item",
                                                      {
                                                        class:
                                                          _vm.nicheStatus ===
                                                          _item.value
                                                            ? "active"
                                                            : "",
                                                        attrs: {
                                                          command: _item.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_item.name)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : item.field === "supplyFollowName"
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "el-dropdown-link",
                                              class: _vm.supplyUserId
                                                ? "active"
                                                : "",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name)),
                                              ]),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c("dt-drop-down", {
                                                attrs: {
                                                  "source-list": _vm.userList,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (row) {
                                                        return [
                                                          _c(
                                                            "el-scrollbar",
                                                            {
                                                              staticClass:
                                                                "dt-dropdown-dt",
                                                            },
                                                            [
                                                              !row.filterList
                                                                .length
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "dt-drop-down-no-data",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          无数据\n                        "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                row.filterList,
                                                                function (
                                                                  _item
                                                                ) {
                                                                  return _c(
                                                                    "el-dropdown-item",
                                                                    {
                                                                      class:
                                                                        _vm.supplyUserId ===
                                                                        _item.id
                                                                          ? "active"
                                                                          : "",
                                                                      attrs: {
                                                                        command:
                                                                          _item.id,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  _item.htmlEl
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "el-dropdown-link",
                                              class: _vm.createUserId
                                                ? "active"
                                                : "",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name)),
                                              ]),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c("dt-drop-down", {
                                                attrs: {
                                                  "source-list": _vm.userList,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (row) {
                                                        return [
                                                          _c(
                                                            "el-scrollbar",
                                                            {
                                                              staticClass:
                                                                "dt-dropdown-dt",
                                                            },
                                                            [
                                                              !row.filterList
                                                                .length
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "dt-drop-down-no-data",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          无数据\n                        "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                row.filterList,
                                                                function (
                                                                  _item
                                                                ) {
                                                                  return _c(
                                                                    "el-dropdown-item",
                                                                    {
                                                                      class:
                                                                        _vm.createUserId ===
                                                                        _item.id
                                                                          ? "active"
                                                                          : "",
                                                                      attrs: {
                                                                        command:
                                                                          _item.id,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  _item.htmlEl
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.field === "setting"
                          ? _c("span", { staticClass: "set" }, [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "dt-permission",
                                      rawName: "v-dt-permission",
                                      value: "客户管理CRM-商机管理-编辑",
                                      expression: "'客户管理CRM-商机管理-编辑'",
                                    },
                                  ],
                                  staticClass: "set-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editChance(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("\n              编辑\n            ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "dt-permission",
                                      rawName: "v-dt-permission",
                                      value: "客户管理CRM-商机管理-编辑",
                                      expression: "'客户管理CRM-商机管理-编辑'",
                                    },
                                  ],
                                },
                                [_vm._v(" | ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "dt-permission",
                                      rawName: "v-dt-permission",
                                      value: "客户管理CRM-商机管理-删除",
                                      expression: "'客户管理CRM-商机管理-删除'",
                                    },
                                  ],
                                  staticClass: "set-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteChance(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("\n              删除\n            ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "dt-permission",
                                      rawName: "v-dt-permission",
                                      value: "客户管理CRM-商机管理-删除",
                                      expression: "'客户管理CRM-商机管理-删除'",
                                    },
                                  ],
                                },
                                [_vm._v(" | ")]
                              ),
                            ])
                          : item.field === "nicheStateDesc"
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "100%",
                                  height: "100%",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row[item.field]) +
                                        "\n              "
                                    ),
                                    _c(
                                      "el-popover",
                                      {
                                        ref: `popover-${scope.row.id}`,
                                        refInFor: true,
                                        attrs: {
                                          "popper-class": "el-popper-dt-dt",
                                          placement: "bottom",
                                          width: "300",
                                          trigger: "click",
                                        },
                                        on: {
                                          show: function ($event) {
                                            _vm.nicheState =
                                              scope.row.nicheState
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "poper-state" },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "请选择商机阶段",
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value: _vm.nicheState,
                                                      callback: function ($$v) {
                                                        _vm.nicheState = $$v
                                                      },
                                                      expression: "nicheState",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.nicheStateList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "poper-footer" },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.closePopover(
                                                          `popover-${scope.row.id}`
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      取消\n                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.updateNicheState(
                                                          scope.row,
                                                          `popover-${scope.row.id}`
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      确定\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("svg-icon", {
                                          staticClass: "editNicheStateDesc",
                                          attrs: {
                                            slot: "reference",
                                            "icon-class": "edit-zt",
                                          },
                                          slot: "reference",
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : item.field === "companyName"
                          ? _c(
                              "span",
                              {
                                class: _vm.isLink ? "c-name" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.jump(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row[item.field]) +
                                    "\n          "
                                ),
                              ]
                            )
                          : item.field === "billKeypersonDesc"
                          ? _c(
                              "span",
                              { staticClass: "no-dot" },
                              _vm._l(scope.row["contactList"], function (user) {
                                return _c(
                                  "span",
                                  { key: user.id },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        staticStyle: {
                                          color: "#0099CC",
                                          "font-size": "12px",
                                        },
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.viewContact(user)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(user.contact))]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "x" }, [
                                      _vm._v("，"),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row[item.field]) +
                                  "\n          "
                              ),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page page-div page-1" },
      [
        _c("footer-page", {
          attrs: { pageStatus: _vm.pageStatus },
          on: {
            handleCurrentChange: _vm.handleCurrentChange,
            goNumPage: _vm.goNumPage,
            changePageSize: _vm.changePageSize,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }