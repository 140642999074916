<template>
  <div>
    <dia-model
      ref="scribesDialog"
      :btnLoading="btnLoading"
      :dialogStatus="dialogStatus"
      v-if="showModel"
      @submitContBtn="submitContBtn"
    >
      <div
        slot="modalCont"
        v-loading="getNumLoading"
        :element-loading-text="keyScribesLoading"
      >
        <div class=" clearfix scri-input-dq" style="width:100%;">
          <div class="page-cont-title" style="color:#8F8F8F ">选择大区:</div>
          <div class="select cont-title-select new_select_icon">
            <el-select
              v-model="currentDqValue"
              default-first-option
              filterable
              placeholder="请选择大区"
              @change="getScribesAdrNum"
            >
              <el-option
                v-for="item in orderPackDdic"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
                <!-- <span style="float: left">{{ item.packageName }}</span>
                                <span class="tip-number" v-if="false">2</span> -->
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          class="scribe-tip-dq"
          v-if="currentDqValue && currentComNum !== ''"
        >
          共找到
          <span style="color:#FF6E6E"> {{ currentComNum }} </span>
          条线索， 使用
          <div class="plus_minus_meal">
            <span
              class="card_up_down num_plus valign"
              @click="plusPackNum('plus')"
              @mouseenter="enterPackPlusMinus('enter')"
              @mouseleave="outPackPlusMinus('out')"
            >
              <i
                :class="
                  plusPackEnter ? 'card_icon_plusenter' : 'card_icon_plus'
                "
                class="card_icon valign-t"
              ></i> </span
            ><span class="card_num_input valign">
              {{ userCardsNum }} </span
            ><span
              class="card_up_down num_minus valign"
              @click="plusPackNum('minus')"
              @mouseenter="enterPackPlusMinus('enter')"
              @mouseleave="outPackPlusMinus('out')"
            >
              <i
                :class="
                  minnusPackEnter ? 'card_icon_minusenter' : 'card_icon_minus'
                "
                class="card_icon valign-t"
              ></i>
            </span>
          </div>
          张订阅卡，订阅线索{{ scribesKeyNum * userCardsNum }}条
        </div>
        <p class="scribe-tip-tip" v-if="earaDes">含：{{ earaDes }}</p>
        <p
          class="scribe-tip-tip"
          v-if="currentComNum < 500 && currentComNum !== '' && currentDqValue"
        >
          提示:小于500条，仍会消耗一张订阅卡，但下次订阅会优先扣除该卡剩余条数部分
        </p>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import { getMode, getBookingComNum, getPackBookingMore } from '@/api/user.js'
export default {
  props: {
    currentId: {
      type: Number,
      default: null
    },
    currentTitle: {
      type: String,
      default: ''
    },
    type: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModel: false,
      dialogCont: '',
      showCancelBtn: true,
      orderPackDdic: [],
      currentDqValue: '',
      currentComNum: '',
      btnLoading: false,
      dialogStatus: {},
      getNumLoading: false,
      keyScribesLoading: '',
      earaDes: '',
      scribesKeyNum: 500,
      userCardsNum: 0,
      plusPackEnter: true,
      minnusPackEnter: true,
      userCards: null
    }
  },
  components: {
    diaModel
  },
  created() {
    const num = this.$store.getters.sysConfig
    if (!num['cardCompanyLimit']) {
      this.scribesKeyNum = 500
    } else {
      this.scribesKeyNum = num['cardCompanyLimit']
    }
  },
  methods: {
    plusPackNum(val) {
      if (this.currentComNum === 0) {
        return
      }
      const num = Math.ceil(this.currentComNum / this.scribesKeyNum)
      const allowCardsMax = Math.min(num, this.userCards)

      if (val === 'plus') {
        if (this.userCardsNum === 0) {
          return
        } else {
          this.userCardsNum -= 1
        }
      } else {
        if (this.userCardsNum >= allowCardsMax) {
          return
        }
        this.userCardsNum += 1
      }
      if (this.currentComNum === 0 || this.userCardsNum === 0) {
        this.dialogStatus.noAllow = true
      } else {
        this.dialogStatus.noAllow = false
      }
    },
    outPackPlusMinus(val) {
      this.plusPackEnter = false
      this.minnusPackEnter = false
    },
    enterPackPlusMinus(val) {
      this.plusPackEnter = true
      this.minnusPackEnter = true
    },
    initScribes(val) {
      const _this = this
      _this.userCards = val
      _this.currentDqValue = ''
      _this.currentComNum = ''
      _this.showModel = true
      _this.getNumLoading = false
      _this.$nextTick(() => {
        _this.dialogStatus = {
          dialogWidth: '550px',
          dialogTitle: _this.currentTitle,
          dialogTop: '250px',
          showFoot: true,
          singleBtn: false,
          noAllow: true,
          dialogSurBtn: '确认'
        }
        if (_this.currentComNum === 0) {
          _this.dialogStatus.noAllow = true
        }
        _this.getOrderPackDdic()
        _this.$refs.scribesDialog.init()
      })
    },
    closeScribes() {
      this.btnLoading = false
      this.showModel = false
    },
    closeLoading() {
      this.getNumLoading = false
      this.btnLoading = true
    },
    submitContBtn() {
      this.btnLoading = true
      this.getNumLoading = true
      this.dialogStatus.noAllow = true
      this.keyScribesLoading = '线索订阅中...'
      if (this.type) {
        this.getPackBookingMore()
      } else {
        const params = {
          value: this.currentDqValue,
          num: this.userCardsNum
        }
        this.$emit('sureScribesPack', params)
      }
    },
    // 获取公司业务产品 和产品行业
    getOrderPackDdic() {
      const params = {
        type: ['DQ_01'].toString()
      }
      getMode(params).then(res => {
        this.orderPackDdic = res.data.DQ_01
      })
    },
    getScribesAdrNum(val) {
      this.userCardsNum = 0
      this.getBookingComNum(val)
      this.orderPackDdic.forEach(item => {
        if (item.value === val) {
          this.earaDes = item.node
          return
        }
      })
    },
    getBookingComNum(val) {
      this.getNumLoading = true
      this.keyScribesLoading = '线索搜索中...'
      const params = {
        packageId: this.currentId,
        regionCode: val.toString()
      }
      getBookingComNum(params).then(res => {
        this.getNumLoading = false
        if (res.code === 60000) {
          this.currentComNum = res.data.companyNum
          if (this.currentComNum === 0 || this.userCardsNum === 0) {
            this.dialogStatus.noAllow = true
          } else {
            this.dialogStatus.noAllow = false
          }
        }
      })
    },
    getPackBookingMore() {
      this.btnLoading = true
      const params = {
        packageId: this.currentId,
        regionCode: this.currentDqValue,
        cardNum: this.userCardsNum
      }
      getPackBookingMore(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.$emit('sureScribesPack', this.currentDqValue)
          this.btnLoading = false
          this.showModel = false
        }
      })
    }
  }
}
</script>

<style>
@import '../../styles/threadpx.scss';
</style>
<style scoped>
.scri-input-dq ::v-deep .el-input__inner {
  height: 30px;
  width: 250px;
}
.scribe-tip-dq {
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(46, 46, 46, 1);
  line-height: 12px;
  margin: 13px 0 20px;
}
.scribe-tip-tip {
  width: 100%;
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(46, 46, 46, 0.6);
  line-height: 12px;
  margin: 13px 0 20px;
}
.plus_minus_meal {
  height: 25px;
  line-height: 25px;
  display: inline-block;
}
.card_up_down {
  width: 25px;
  height: 25px;
  display: inline-block;
  border: 1px solid #cccbcb;
  vertical-align: top;
  padding: 5px;
  cursor: pointer;
}
.card_icon_plus {
  background: url('../../assets/minus-num-no.png') no-repeat;
  background-size: 100% 100%;
}
.card_icon_plusenter {
  background: url('../../assets/minus-num.png') no-repeat;
  background-size: 100% 100%;
}
.card_icon_minus {
  background: url('../../assets/plus-num-no.png') no-repeat;
  background-size: 100% 100%;
}
.card_icon_minusenter {
  background: url('../../assets/plus-num.png') no-repeat;
  background-size: 100% 100%;
}
.card_icon {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.num_plus {
  border-right: none !important;
}
.num_minus {
  border-left: none !important;
}
.card_num_input {
  display: inline-block;
  width: 40px;
  height: 25px;
  border: 1px solid #cccbcb;
  color: #2e2e2e;
  line-height: 25px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  user-select: none;
}
.valign {
  vertical-align: middle;
}
.valign-t {
  vertical-align: top;
}

.new-meal-bottom {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
</style>
