var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "company-group-detail" },
    [
      _c("p", [
        _c("span", [_vm._v(" 企业族群")]),
        _vm._v(" "),
        _vm.IsAccess == 0 || _vm.IsAccess == 1
          ? _c(
              "span",
              {
                staticClass: "normal-data",
                on: { click: _vm.handleNormalData },
              },
              [_vm._v(_vm._s(_vm.$updateText))]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search" },
        [
          _c("el-input", {
            staticStyle: { width: "320px" },
            attrs: { size: "mini", placeholder: "请输入企业族群名称" },
            on: { change: _vm.handleInputChange },
            model: {
              value: _vm.companyName,
              callback: function ($$v) {
                _vm.companyName = $$v
              },
              expression: "companyName",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.handleInquire },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleReset } },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c("span", [_vm._v("共" + _vm._s(_vm.pageParams.total) + "个结果")]),
          _vm._v(" "),
          _c("PeTable", {
            attrs: {
              peCurrentPage: _vm.peCurrentPage,
              peTableData: _vm.tableList,
              peColumnList: _vm.tableColumn,
              pePage: _vm.pageParams,
              peLoading: _vm.peLoading,
              IsAccess: _vm.IsAccess,
            },
            on: {
              sortPeChange: _vm.handleSortPeChange,
              handleCurrentPePage: _vm.handleCurrentPePage,
              handleSizePeChange: _vm.handleSizePeChange,
              handleSelectCommand: _vm.handleSelectCommand,
            },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function ({ scope }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "company-name-click",
                        on: {
                          click: function ($event) {
                            return _vm.handleEnter(scope.data.groupUuid)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.data.name))]
                    ),
                  ]
                },
              },
              {
                key: "influence",
                fn: function ({ scope }) {
                  return [
                    scope.data.influence
                      ? _c("span", [
                          _vm._v(_vm._s(scope.data.influence) + "星"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }