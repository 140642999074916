<template>
  <div class="pe-circle-page" :style="getStoreStyle">
    <div
      id="circleMap"
      ref="circleDom"
      class="circle-map"
      v-loading="loading"
      element-loading-text="加载中"
    >
      <infoWindow ref="info" v-show="infoWindow" :info-data="infoWindowData" />
    </div>
    <FullScreen :refDom="$refs.circleDom" />
  </div>
</template>

<script>
import { getDistrictData, getStoreData } from '@/api/module'
import infoWindow from '@/components/Peculiar/store/info'
import { debounce } from 'lodash'
import FullScreen from '@/components/Peculiar/expend'
export default {
  name: 'PeBusiness',
  data() {
    return {
      map: null,
      loading: false,
      circleList: [],
      markList: [],
      districtOption: {
        provinceName: '',
        cityName: '',
        areaName: '',
        topLat: '',
        bottomLat: '',
        leftLng: '',
        rightLng: ''
      },

      isFull: false,
      infoWindowData: {},
      infoWindow: null
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    getStoreStyle() {
      return this.isFull ? `position:static` : 'position:relative'
    }
  },
  methods: {
    init() {
      const that = this
      this.map = new window.dtMap.Map('circleMap', {
        zoom: 4,
        viewMode: '2D',
        mapStyle: 'amap://styles/normal',
        center: [102.342785, 35.312316]
      })

      this.map.on('complete', () => {
        this.loading = false
        this.map.plugin(['AMap.ToolBar', 'AMap.Scale'], () => {
          this.map.addControl(new AMap.ToolBar())
          this.map.addControl(new AMap.Scale())
        })
        //加载相关组件
        function initPage(DistrictCluster, PointSimplifier, $) {
          that.pointSimplifierIns = new PointSimplifier({
            map: that.map, //所属的地图实例
            autoSetFitView: false, //禁止自动更新地图视野
            zIndex: 100,
            getPosition: function(item) {
              if (!item) {
                return null
              }
              return item.location
            },
            getHoverTitle: function(item) {
              return '商圈:' + item.name
            },
            renderOptions: {
              //点的样式
              pointStyle: {
                width: 3,
                height: 3,
                fillStyle: 'rgba(153, 0, 153, 0.3)'
                // strokeStyle: 'dashed'
              },
              //鼠标hover时的title信息
              hoverTitleStyle: {
                position: 'top'
              }
            }
          })

          that.distCluster = new DistrictCluster({
            theme: 'my-red',
            zIndex: 90,
            map: that.map, //所属的地图实例
            getPosition: function(item) {
              if (!item) {
                return null
              }
              return item.location
            }
          })
          // window.distCluster = distCluster
          function refresh() {
            //获取 pointStyle
            var pointStyle = that.pointSimplifierIns.getRenderOptions()
              .pointStyle
            //根据当前zoom调整点的尺寸
            pointStyle.width = pointStyle.height =
              2 * Math.pow(1.1, that.map.getZoom() - 4)
            console.log(pointStyle)
          }
          that.map.on(
            'moveend',
            debounce(() => {
              refresh()
              that.getMapLngLatAndZoom()
            }, 1000)
          )
          refresh()
        }
        AMapUI.load(
          ['ui/geo/DistrictCluster', 'ui/misc/PointSimplifier', 'lib/$'],
          function(DistrictCluster, PointSimplifier, $) {
            //启动页面
            initPage(DistrictCluster, PointSimplifier, $)
            that.getMapData()
          }
        )
      })
    },

    async handleCircleData() {
      const { data } = await getDistrictData(this.districtOption)
      data.forEach(item => {
        const circle = new AMap.Circle({
          center: [item.longitude, item.latitude],
          radius: item.radius, //半径
          borderWeight: 3,
          strokeColor: '#41cabf',
          strokeWeight: 3,
          strokeOpacity: 1,
          // fillOpacity: 0.5,
          strokeStyle: 'dashed',
          strokeDasharray: [10, 10],
          fillColor: 'transparent',
          zIndex: 100
        })
        this.circleList.push(circle)
        this.map.remove(circle)
        this.map.add(circle)
      })
    },

    async renderStore() {
      const { leftLng, rightLng, topLat, bottomLat } = this.districtOption
      this.loading = true
      const { data } = await getStoreData({
        topLat,
        bottomLat,
        leftLng,
        rightLng,
        level: 4,
        companyUuid: this.$route.query.companyUuid,
        type: 1
      })
      //标记显示
      data.forEach(i => {
        i.name = i.companyName
        let startMarker = new AMap.Marker({
          position: [i.longitude, i.latitude],
          zIndex: 200,
          offset: new AMap.Pixel(-13, -30),
          content: this.getContent(i)
        })
        this.map?.add(startMarker)
        this.markList.push(startMarker)
        // 鼠标进入覆盖物
        startMarker.on('mouseover', () => {
          this.showPop(i)
        })

        // 鼠标离开覆盖物
        startMarker.on('mouseout', () => {
          this.infoWindow?.close()
        })
      })
      this.loading = false
    },

    showPop(data) {
      const el = this.$refs.info.$el
      this.infoWindow = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        content: el,
        offset: new AMap.Pixel(-13, -30),
        autoMove: true
      })
      this.infoWindowData = data
      this.infoWindow.open(this.map, [data.longitude, data.latitude])
    },

    getContent(data) {
      const defaultImg =
        'https://web-app.dtbird.cn/web_icon/liansuo_mall_default.png'
      return `<div class="circle-map-marker-name">
                <img   width='30' height='30' src=${
                  data.brandLogo ? data.brandLogo : defaultImg
                } />
          </div>`
    },

    async getMapLngLatAndZoom() {
      const bound = this.map.getBounds()
      const northWest = bound.getNorthWest()
      const southEast = bound.getSouthEast()
      let zoom = this.map.getZoom()
      if (zoom >= 13) {
        zoom = 4 // 公司
      } else if (zoom >= 10 && zoom < 13) {
        zoom = 3 // 区、县
      } else if (zoom >= 7 && zoom < 10) {
        zoom = 2 // 市
      } else {
        zoom = 1 // 省
      }
      this.districtOption = {
        topLat: northWest.lat,
        bottomLat: southEast.lat,
        leftLng: northWest.lng,
        rightLng: southEast.lng,
        provinceName: '',
        cityName: '',
        areaName: ''
      }
      if (zoom == 4) {
        this.pointSimplifierIns.show()
        this.handleCircleData()
        this.renderStore()
      } else {
        this.pointSimplifierIns.hide()
        this.circleList.forEach(circle => {
          this.map.remove(circle)
        })
        this.markList.forEach(mark => {
          this.map.remove(mark)
        })
        this.circleList = []
        this.markList = []
      }
    },

    async getMapData() {
      this.loading = true
      //商圈数据
      const { data } = await getDistrictData(this.districtOption)
      this.loading = false
      this.map?.clearMap()
      this.addMapMarker(data)
    },

    addMapMarker(list) {
      const points = []
      const specList = ['上海市', '北京市', '重庆市', '天津市']
      let name = ''
      list.forEach(item => {
        if (specList.includes(item.provinceName)) {
          name = item.provinceName + item.areaName + item.circleName
        } else {
          name =
            item.provinceName + item.cityName + item.areaName + item.circleName
        }
        points.push({
          name,
          location: [parseFloat(item.longitude), parseFloat(item.latitude)]
        })
      })
      this.distCluster.setData(points)
      this.pointSimplifierIns.setData(points)
    }
  },
  beforeDestroy() {
    this.map.destroy()
    this.map.off('moveend')
  },
  components: {
    infoWindow,
    FullScreen
  }
}
</script>

<style lang="scss">
.pe-circle-page {
  .amap-e {
    display: none !important;
  }
  .circle-map {
    width: 100%;
    height: 600px;
  }
  .circle-map-marker-name {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 2px solid #ccc;
    background-color: #1f6cdde6;
    white-space: nowrap;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
}
</style>
