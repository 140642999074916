var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "edit-body",
      on: {
        click: function ($event) {
          _vm.showLandPageSelect ? (_vm.showLandPageSelect = false) : ""
        },
      },
    },
    [
      _c(
        "el-scrollbar",
        {
          staticClass: "mini-scroll",
          staticStyle: { height: "100%", width: "100%" },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-position": "left",
                "label-width": "70px",
                size: "mini",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "头图" } }, [
                _c(
                  "div",
                  { staticClass: "upload-icon upload-icon4" },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-uploader",
                        attrs: {
                          action: "",
                          accept: ".jpg, .png, .jpeg",
                          "before-upload": _vm.saveFile("thumbnail"),
                          "show-file-list": false,
                          "http-request": _vm.uploadThumbnailToOss,
                          "auto-upload": true,
                        },
                      },
                      [
                        _vm.htmlStyle.share.img
                          ? _c("img", {
                              staticClass: "avatar",
                              attrs: { src: _vm.htmlStyle.share.img },
                            })
                          : _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标题" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入落地页标题" },
                    model: {
                      value: _vm.htmlStyle.share.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.share, "title", $$v)
                      },
                      expression: "htmlStyle.share.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "简介" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入简介" },
                    model: {
                      value: _vm.htmlStyle.share.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.share, "desc", $$v)
                      },
                      expression: "htmlStyle.share.desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "70px",
                "label-position": "left",
                size: "small",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择模版" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.htmlStyle.cardType,
                        callback: function ($$v) {
                          _vm.$set(_vm.htmlStyle, "cardType", $$v)
                        },
                        expression: "htmlStyle.cardType",
                      },
                    },
                    [_vm._v("黑色")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.htmlStyle.cardType,
                        callback: function ($$v) {
                          _vm.$set(_vm.htmlStyle, "cardType", $$v)
                        },
                        expression: "htmlStyle.cardType",
                      },
                    },
                    [_vm._v("白色")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公司" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入公司信息" },
                    model: {
                      value: _vm.htmlStyle.earnMsg.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.earnMsg, "companyName", $$v)
                      },
                      expression: "htmlStyle.earnMsg.companyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "姓名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.htmlStyle.earnMsg.followName,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.earnMsg, "followName", $$v)
                      },
                      expression: "htmlStyle.earnMsg.followName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "职位" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入职位" },
                    model: {
                      value: _vm.htmlStyle.earnMsg.position,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.earnMsg, "position", $$v)
                      },
                      expression: "htmlStyle.earnMsg.position",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "电话" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入电话" },
                    model: {
                      value: _vm.htmlStyle.earnMsg.phoneNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.earnMsg, "phoneNum", $$v)
                      },
                      expression: "htmlStyle.earnMsg.phoneNum",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入邮箱" },
                    model: {
                      value: _vm.htmlStyle.earnMsg.emailNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.earnMsg, "emailNum", $$v)
                      },
                      expression: "htmlStyle.earnMsg.emailNum",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "官网" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入官网" },
                    model: {
                      value: _vm.htmlStyle.earnMsg.website,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.earnMsg, "website", $$v)
                      },
                      expression: "htmlStyle.earnMsg.website",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "地址" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入地址" },
                    model: {
                      value: _vm.htmlStyle.earnMsg.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.earnMsg, "address", $$v)
                      },
                      expression: "htmlStyle.earnMsg.address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "upload-row" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "upload-icon" },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action: "",
                              accept: ".jpg, .png, .jpeg",
                              "before-upload": _vm.saveFile("weChatLogo"),
                              "show-file-list": false,
                              "http-request": _vm.uploadWeChartHeaderImgToOss,
                              "auto-upload": true,
                            },
                          },
                          [
                            _vm.htmlStyle.earnMsg.avatar
                              ? _c("img", {
                                  staticClass: "avatar",
                                  staticStyle: {
                                    height: "60px",
                                    width: "60px",
                                  },
                                  attrs: { src: _vm.htmlStyle.earnMsg.avatar },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "upload-icon upload-icon1" },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action: "",
                              accept: ".jpg, .png, .jpeg",
                              "before-upload": _vm.saveFile("companyLogo"),
                              "show-file-list": false,
                              "http-request": _vm.uploadCompanyLogoToOss,
                              "auto-upload": true,
                            },
                          },
                          [
                            _vm.htmlStyle.earnMsg.companyLogo
                              ? _c("el-image", {
                                  attrs: {
                                    src:
                                      _vm.htmlStyle.earnMsg.companyLogo +
                                      "?x-oss-process=image/resize,h_60,w_60",
                                  },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "upload-icon upload-icon2" },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action: "",
                              accept: ".jpg, .png, .jpeg",
                              "before-upload": _vm.saveFile("erCodeImg"),
                              "show-file-list": false,
                              "http-request": _vm.uploadErCodeImgToOss,
                              "auto-upload": true,
                            },
                          },
                          [
                            _vm.htmlStyle.earnMsg.ercode
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.htmlStyle.earnMsg.ercode },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "auto",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "视频" } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.htmlStyle.show.video,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.show, "video", $$v)
                      },
                      expression: "htmlStyle.show.video",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { disabled: !_vm.htmlStyle.show.video } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-div",
                      class: _vm.htmlStyle.show["video"] ? "" : "disabled",
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "el-upload",
                          attrs: {
                            action: "",
                            accept: ".mp4, .mov, .avi, .flv, .wmv, .mpeg, .rm",
                            "show-file-list": false,
                            "before-upload": _vm.saveFile("video"),
                            "http-request": _vm.uploadFileToOss,
                            "auto-upload": true,
                            disabled: !_vm.htmlStyle.show["video"],
                          },
                        },
                        [
                          _c("div", [
                            _c("i", { staticClass: "el-icon-plus" }, [
                              _vm._v(
                                "\n                      上传视频\n                    "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "图片" } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.htmlStyle.show.photo,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.show, "photo", $$v)
                      },
                      expression: "htmlStyle.show.photo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { disable: !_vm.htmlStyle.show.photo } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-div",
                      class: _vm.htmlStyle.show["photo"] ? "" : "disabled",
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "el-upload",
                          attrs: {
                            action: "",
                            accept: ".jpg, .png, .jpeg",
                            "show-file-list": false,
                            "before-upload": _vm.saveFile("photo"),
                            "http-request": _vm.uploadPhotoToOss,
                            "auto-upload": true,
                            disabled: !_vm.htmlStyle.show["photo"],
                          },
                        },
                        [
                          _c("div", [
                            _c("i", { staticClass: "el-icon-plus" }, [
                              _vm._v(
                                "\n                      上传图片\n                    "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "文字" } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.htmlStyle.show.content,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.htmlStyle.show,
                          "content",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "htmlStyle.show.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      disabled: !_vm.htmlStyle.show.content,
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 100 },
                    },
                    model: {
                      value: _vm.htmlStyle.textareaCont,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle, "textareaCont", $$v)
                      },
                      expression: "htmlStyle.textareaCont",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "底部菜单" } }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { label: "动态" },
                },
                [
                  _c("div", { staticClass: "wyx" }, [
                    _c(
                      "div",
                      [
                        _c("el-checkbox", {
                          model: {
                            value: _vm.htmlStyle.barStatus.showYx,
                            callback: function ($$v) {
                              _vm.$set(_vm.htmlStyle.barStatus, "showYx", $$v)
                            },
                            expression: "htmlStyle.barStatus.showYx",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "up-search",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.htmlStyle.barStatus.showYx
                              ? (_vm.showLandPageSelect = true)
                              : ""
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showLandPageSelect,
                                expression: "showLandPageSelect",
                              },
                            ],
                            staticClass: "up-search-row",
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { padding: "0 10px" } },
                              [
                                _c(
                                  "el-form",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { size: "mini" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入或选择落地页",
                                            "suffix-icon": "el-icon-search",
                                          },
                                          on: {
                                            input: _vm.getLandPage,
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                          model: {
                                            value: _vm.searchPageName,
                                            callback: function ($$v) {
                                              _vm.searchPageName = $$v
                                            },
                                            expression: "searchPageName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-scrollbar",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading,
                                    expression: "loading",
                                  },
                                ],
                                staticClass: "landpage-list",
                              },
                              [
                                _vm._l(_vm.landPageList, function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.value,
                                      staticClass: "landpage-item",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.selectChange(item.value)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(item.label) +
                                          "\n                          "
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                !_vm.landPageList.length
                                  ? _c("div", { staticClass: "empty" }, [
                                      _vm._v(
                                        "\n                              空空如也～\n                          "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-div",
                            class: _vm.htmlStyle.barStatus.showYx
                              ? "text-left"
                              : "disabled text-left",
                            staticStyle: { height: "32px" },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.htmlStyle.barItem.landPageName ||
                                    "请选择素材"
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名片" } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.htmlStyle.barStatus.showMp,
                      callback: function ($$v) {
                        _vm.$set(_vm.htmlStyle.barStatus, "showMp", $$v)
                      },
                      expression: "htmlStyle.barStatus.showMp",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "官网" } }, [
                _c("div", { staticClass: "gw" }, [
                  _c(
                    "div",
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.htmlStyle.barStatus.showWs,
                          callback: function ($$v) {
                            _vm.$set(_vm.htmlStyle.barStatus, "showWs", $$v)
                          },
                          expression: "htmlStyle.barStatus.showWs",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "请输入官网",
                            disabled: !_vm.htmlStyle.barStatus.showWs,
                          },
                          model: {
                            value: _vm.htmlStyle.barItem.websiteUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.htmlStyle.barItem, "websiteUrl", $$v)
                            },
                            expression: "htmlStyle.barItem.websiteUrl",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { slot: "prepend", placeholder: "请选择" },
                              slot: "prepend",
                              model: {
                                value: _vm.httpArr,
                                callback: function ($$v) {
                                  _vm.httpArr = $$v
                                },
                                expression: "httpArr",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "http", value: "http" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "https", value: "https" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }