<template>
  <div class="editPwd-main">
    <el-form :rules="rules" :model="pwdForm" label-width="auto" ref="pwdForm">
      <el-form-item label="新密码" prop="passWord">
        <el-input
          placeholder="请您输入新密码"
          v-model="pwdForm.passWord"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item
        style="margin-top: 50px;"
        label="确认密码"
        prop="confimPass"
      >
        <el-input
          placeholder="请您确认新密码"
          v-model="pwdForm.confimPass"
          show-password
        ></el-input>
      </el-form-item>

      <el-form-item label="手机号" prop="mobile" v-if="!showBind">
        <el-input
          :disabled="showBind"
          placeholder="请输入绑定手机号码"
          v-model.number="pwdForm.mobile"
        ></el-input>
      </el-form-item>

      <el-form-item label="验证码" prop="smsCode">
        <span v-if="showBind" class="code">
          请输入{{ pwdForm.mobile | formatPhone }}收到的短信验证码
        </span>
        <el-input v-model="pwdForm.smsCode" placeholder="请输入验证码">
          <template slot="append">
            <el-button class="dt-button" @click="sendSms">{{
              time !== '获取验证码' ? `${time} 秒` : time
            }}</el-button>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="footer" :style="{ height: showTip ? '65px' : '50px' }">
      <div class="tip-footer">
        <div style="padding-right: 3px;box-sizing: border-box" v-if="showTip">
          <i class="el-icon-warning-outline"></i>
        </div>
        <div>
          <span v-if="showTip">
            <span style="color: #F56C6C;font-size: 10px">
              尊敬的客户，当前账号拥有管理员权限，系统提升了该账号的安全等级。该账号需要重置原始密码后才能登录。若账号使用中碰到问题，可拨打客服电话400-9965711
            </span>
          </span>
        </div>
      </div>
      <div>
        <el-button size="small" @click="cancelBtn" v-if="!showTip"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="confirmBtn"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getSmsSend, changePsw } from '@/api/usercenter'
import { validatePassword } from '@/utils/helper'
import { throttle } from 'lodash'
export default {
  name: 'editPwd',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请您确认新密码'))
      } else if (value !== this.pwdForm.passWord) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    var validatePass1 = (rule, value, callback) => {
      const msg = validatePassword(value)
      msg ? callback(new Error(msg)) : callback()
    }
    return {
      time: '获取验证码',
      timeObj: null,
      showTip: true,
      pwdForm: {
        passWord: '',
        confimPass: '',
        smsCode: '',
        mobile: ''
      },
      rules: {
        passWord: [
          { required: true, message: '请您输入新密码', trigger: 'blur' },
          { validator: validatePass1, trigger: 'blur' }
        ],
        confimPass: [
          { required: true, message: '请您确认新密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入绑定手机号码', trigger: 'blur' }
        ],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  watch: {},
  beforeDestroy() {
    if (this.timeObj) clearInterval(this.timeObj)
  },
  created() {
    this.sendSms = throttle(
      () => {
        this._sendSms()
      },
      500,
      { trailing: false }
    )
  },
  mounted() {
    const modalOptions = this.$store.state.plugin.modal.options
    this.showTip = modalOptions.showTip
  },
  computed: {
    showBind() {
      const accountInfo = this.$store.state.user.accountInfo
      if (Boolean(accountInfo.mobile)) this.pwdForm.mobile = accountInfo.mobile
      return Boolean(accountInfo.mobile)
    }
  },
  filters: {
    formatPhone(val) {
      const reg = /^(\d{3})\d{4}(\d{4})$/
      return val.replace(reg, '$1****$2')
    }
  },
  methods: {
    cancelBtn() {
      this.$store.dispatch('hideModal')
    },

    confirmBtn() {
      this.$refs['pwdForm'].validate(valid => {
        if (valid) {
          this.getChangePsw()
        }
      })
    },

    getChangePsw() {
      changePsw({
        passWord: this.pwdForm.passWord,
        mobile: this.pwdForm.mobile,
        code: this.pwdForm.smsCode
      })
        .then(res => {
          if (res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
            this.cancelBtn()
          }
        })
        .catch(() => {
          this.$store.dispatch('showTip', {
            text: '修改失败',
            type: 'error'
          })
        })
      // this.cancelBtn()
    },

    _sendSms() {
      if (!this.pwdForm.mobile) return
      if (this.pwdForm.mobile && this.showBind) {
        this.pwdForm.mobile = this.pwdForm.mobile = this.$store.state.user.accountInfo.mobile
      }
      if (this.time !== '获取验证码') return
      if (this.timeObj) clearInterval(this.timeObj)
      getSmsSend({ type: 4, mobile: this.pwdForm.mobile }).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('showTip', {
            text: '验证码发送成功',
            type: 'success'
          })
          this.time = 60
          this.timeObj = setInterval(() => {
            if (this.time > 1) {
              this.time -= 1
            } else {
              this.time = '获取验证码'
              clearInterval(this.timeObj)
            }
          }, 1000)
        }
      })
    },

    formatMobile(mobile) {
      const reg = /^(\d{3})\d{4}(\d{4})$/
      return mobile.replace(reg, '$1****$2')
    }
  }
}
</script>

<style lang="scss">
.editPwd-main {
  .el-form-item__error {
    line-height: 20px;
  }
}
</style>

<style scoped>
.editPwd-main {
  width: 100%;
  min-height: 100px;
  height: auto;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: relative;
  box-sizing: border-box;
}
.footer::before {
  content: '';
  height: 1px;
  width: calc(100% + 40px);
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: -20px;
  position: absolute;
  transform: scaleY(0.5);
}
.dt-button {
  width: 112px;
}
.code {
  position: absolute;
  z-index: 999;
  font-size: 12px;
  transform: translateY(-32px);
}
.el-form-item {
  margin-bottom: 40px !important;
}
.tip-footer {
  display: flex;
  color: #e6a23c;
  padding-right: 20px;
  box-sizing: border-box;
}
</style>
