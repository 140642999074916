<template>
  <div class="payManage-main">
    <div class="header">
      <div>
        收/退款管理
        <span style="font-size: 14px;color: #8A8A8A"
          >({{ payData.companyName }})</span
        >
      </div>
      <div>
        <el-button size="small" type="primary" @click="refreshPayBack"
          >刷新回款</el-button
        >
        <el-button size="small" type="primary" @click="createPayBack"
          >新建收款单</el-button
        >
        <el-button size="small" type="danger" @click="createReturnPay"
          >新建退款单</el-button
        >
      </div>
    </div>
    <div class="desc">
      <span>合同金额：{{ transferNum(moneyData.contractAmount) }}元</span>
      <span style="margin: 0 10px"
        >有效回款金额：{{ transferNum(moneyData.returnAmount) }}元</span
      >
      <span>欠款金额：{{ transferNum(moneyData.debtAmount) }}元</span>
    </div>
    <el-scrollbar class="main dt-dropdown-dt">
      <span class="empty" v-show="payList.length === 0" v-loading="loading">
        暂无数据
      </span>

      <div class="ready-pay" v-if="!canCreate">
        <el-image
          style="width: 100px; height: 100px"
          :src="require('../../../assets/readypay.png')"
          fit="fit"
        ></el-image>
      </div>

      <el-card
        shadow="hover"
        v-for="item in payList"
        :key="item.id"
        style="margin-bottom: 20px"
      >
        <el-row :gutter="20">
          <div
            :class="item.returnType === 2 ? 'icon-tag' : 'icon-tag icon-tag-s'"
          >
            {{ item.returnType === 2 ? '退款' : '收款' }}
          </div>
          <div class="edit">
            <i
              class="el-icon-setting item-edit"
              @click="editPayBack(item)"
              v-dt-permission="'客户管理CRM-收/退款管理-编辑'"
            ></i>
            <i
              class="el-icon-delete item-del"
              @click="delPayBack(item)"
              v-dt-permission="'客户管理CRM-收/退款管理-删除'"
            ></i>
          </div>
          <el-col :span="12" style="margin-bottom: 10px">
            <span class="key"
              >{{ item.returnType === 2 ? '退款单编号' : '收款单编号' }} :</span
            ><span class="value">{{ item.contractReturnNumber || '-' }}</span>
          </el-col>
          <el-col :span="12" style="margin-bottom: 10px">
            <span class="key"
              >{{ item.returnType === 2 ? '退款金额' : '收款金额' }}:</span
            ><span class="value">{{
              transferNum(item.returnMoney) || '-'
            }}</span>
          </el-col>
          <el-col :span="12" style="margin-bottom: 10px">
            <span class="key"
              >{{ item.returnType === 2 ? '退款日期' : '收款日期' }}:</span
            ><span class="value">{{ item.returnDate || '-' }}</span>
          </el-col>
          <el-col :span="12" style="margin-bottom: 10px">
            <span class="key">归属人:</span
            ><span class="value">{{ item.belongUser || '-' }}</span>
          </el-col>
          <el-col :span="12" style="margin-bottom: 10px">
            <span class="key">归属月份:</span
            ><span class="value">{{ item.achievementBelongDate || '-' }}</span>
          </el-col>
          <el-col :span="12" style="margin-bottom: 10px">
            <span class="key">创建时间:</span
            ><span class="value">{{ item.createdAt || '-' }}</span>
          </el-col>
          <el-col :span="24">
            <span class="key">备注:</span
            ><span class="value">{{ item.remark || '-' }}</span>
          </el-col>
        </el-row>
      </el-card>
    </el-scrollbar>
  </div>
</template>

<script>
import { returnList, delPayBack } from '@/api/customerpond'
import { refreshPayBack } from '@/api/pay'
import { formatRegex } from '@/utils/helper'
import createOrEditPayBack from '@/views/contract/dialog/createOrEditPayBack'
import createOrEditReturnBack from '@/views/contract/dialog/createOrEditReturnBack'

export default {
  name: 'payManage',
  data() {
    return {
      payList: [],
      loading: true,
      // canCreate: false
      canCreate: true,
      moneyData: {}
    }
  },
  props: {
    payData: {
      type: Object,
      default: () => {}
    },
    followUserList: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.getPayList()
    this.refreshPayBack()
  },
  methods: {
    transferNum(num) {
      return formatRegex(num)
    },
    refreshPayBack() {
      refreshPayBack({ contractId: this.payData.id }).then(res => {
        console.log(res)
        if (res.code === 60000) {
          this.moneyData = res?.data || {}
        }
      })
    },

    getPayList() {
      const params = {
        pageId: '2006',
        pageNo: 1,
        pageSize: 20,
        contractId: this.payData.id
      }
      returnList(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.payList = res.data.data

          // 计算收款金额
          // const total = parseFloat(this.payData.contractAmount)
          // let currentTotal = 0
          // this.payList.forEach(item => {
          //   currentTotal += parseFloat(item.returnMoney)
          // })
          // currentTotal >= total ? this.canCreate = false : this.canCreate = true
        }
      })
    },

    createReturnPay() {
      this.$store.dispatch('showModal', {
        view: createOrEditReturnBack,
        size: '600px',
        title: '新建退款单',
        options: {
          userList: this.followUserList,
          type: 'contractUpdate',
          contractData: {
            contractId: this.payData.id,
            companyName: this.payData.companyName
          }
        },
        onClose: res => {
          if (res.refresh) {
            this.getPayList()
          }
        }
      })
    },

    createPayBack() {
      // if(!this.canCreate){
      //   this.$store.dispatch('showTip', {
      //     text: '该合同款项已收齐',
      //     type: 'error'
      //   })
      //   return
      // }

      this.$store.dispatch('showModal', {
        view: createOrEditPayBack,
        size: '600px',
        title: '新建收款单',
        options: {
          userList: this.followUserList,
          type: 'contractUpdate',
          contractData: {
            contractId: this.payData.id,
            companyName: this.payData.companyName
          }
        },
        onClose: res => {
          if (res.refresh) {
            this.getPayList()
          }
        }
      })
    },

    delPayBack(params) {
      const { id, contractReturnNumber } = params
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: `确认删除 ${contractReturnNumber}? `,
        onClose: res => {
          if (res === 'confirm') {
            delPayBack({ id }).then(res => {
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: res.code === 60000 ? 'success' : 'error'
              })
              res.code === 60000 ? this.getPayList() : ''
            })
          }
        },
        type: 'warning'
      })
    },

    editPayBack(params) {
      this.$store.dispatch('showModal', {
        view:
          params.returnType === 2
            ? createOrEditReturnBack
            : createOrEditPayBack,
        size: '600px',
        title: params.returnType === 2 ? '编辑退款单' : '编辑收款单',
        options: {
          userList: this.followUserList,
          type: 'update',
          data: params
        },
        onClose: res => {
          if (res.refresh) {
            this.getPayList()
          }
        }
      })
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payManage-main {
  padding: 10px;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
}
.desc {
  height: 30px;
  display: flex;
  align-items: center;
}
.main {
  height: calc(100% - 50px - 30px);
  width: 100%;
  position: relative;
  padding-top: 20px;
  box-sizing: border-box;
}
.empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #8a8a8a;
  font-size: 12px;
}
.key {
  color: #8a8a8a;
  margin-right: 5px;
  font-size: 13px;
}
.value {
  font-size: 13px;
}
.edit {
  position: absolute;
  height: 30px;
  width: 60px;
  right: 0;
  top: 0;
  font-size: 16px;
  color: #41cabf;
  cursor: pointer;
}
.item-edit {
  /*margin-right: 3px;*/
}
.ready-pay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.icon-tag {
  position: absolute;
  top: -20px;
  left: -10px;
  width: 40px;
  height: 18px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ffffff;
  border-bottom-right-radius: 5px;
}
.icon-tag-s {
  background-color: #41cabf;
}
</style>
