<template>
  <div class="downloadContract-main">
    <div v-for="item in list" class="item" @click="downloadContract(item)">
      <div>
        {{ item.name }}
      </div>
      <div class="download">
        下载
      </div>
    </div>

    <div class="footer">
      <el-button type="primary" size="small" @click="$store.dispatch('hideModal')">
        关闭
      </el-button>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
export default {
  name: 'downloadContract',
  data () {
    return {
      list: [],
      cname: ''
    }
  },
  mounted () {
    const modalOptions = this.$store.state.plugin.modal.options
    this.list = modalOptions.list
    this.cname = modalOptions.companyName
  },
  computed: {},
  methods: {
    downloadContract(item){
      let downUrl = item.url.replace('http://', 'https://')
      saveAs(downUrl, `${ this.cname }-${ item.name }`)
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.downloadContract-main{
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
}
.downloadContract-main > .item:last-child:before{
  display: none;
}
.item{
  height: 50px;
  line-height: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.item:hover{
  background-color: rgba(0,0,0,.01);
}
.item:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.1);
  transform: scaleY(.5);
  cursor: pointer;
}
.footer{
  height: 50px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.download{
  color: #0099CC;
}
</style>
