var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dia-model",
        {
          ref: "addBook",
          attrs: {
            dialogStatus: _vm.dialogAddBookStatus,
            showCheck: _vm.showCheck,
            btnLoading: _vm.btnLoading,
          },
          on: {
            handleCheckAllChange: _vm.handleCheckAllChange,
            submitContBtn: _vm.addBookBtn,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "padding-bottom": "20px" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户培育-线索培育-新建培育池",
                      expression: "'客户培育-线索培育-新建培育池'",
                    },
                  ],
                  staticClass: "created-new-book",
                  on: { click: _vm.addNewBook },
                },
                [_vm._v("新建培育池")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "add-collect-select" },
                _vm._l(_vm.BookArr, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "book-list-type" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: item.id },
                          on: {
                            change: function ($event) {
                              return _vm.setIsDefault(item)
                            },
                          },
                          model: {
                            value: _vm.selectBook,
                            callback: function ($$v) {
                              _vm.selectBook = $$v
                            },
                            expression: "selectBook",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("add-book", {
        ref: "addBookModel",
        on: { addBookSuccess: _vm.addBookSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }