<template>
  <div
    ref="mapRef"
    class="parkMap-main"
    id="park-map"
    :style="{ height: height }"
    v-loading="loading"
    element-loading-text="加载中"
  >
    <infoWindow
      ref="info"
      v-show="infoWindow"
      :info-data="infoWindowData"
    ></infoWindow>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { parkMap } from '@/api/customerpond'
import infoWindow from '@/views/insight/park/infoWindow'

export default {
  name: 'parkMap',
  components: {
    infoWindow
  },
  data() {
    return {
      map: null,
      height: window.innerHeight - 185 + 'px',
      loading: false,

      district: null,
      polygons: [],

      mapOption: {
        level: 1
      },
      infoWindow: null,
      infoWindowData: {},

      canReq: true,
      markerList: []
    }
  },
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    params: {
      handler: function() {
        this.$nextTick(() => {
          this.getMapData()
        })
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.mapInit()
      this.height =
        this.$refs?.mapRef?.getBoundingClientRect()?.top ||
        `${window.innerHeight - 185}px`
    })
  },
  methods: {
    mapInit() {
      if (window.BMap) {
        this.init()
      } else {
        setTimeout(() => {
          this.init()
        }, 1000)
      }
    },
    init() {
      this.map = new window.dtMap.Map('park-map', {
        zoom: 5,
        resizeEnable: true
      })
      this.map.on('complete', () => {
        this.map.plugin(['AMap.ToolBar'], () => {
          this.map.addControl(new AMap.ToolBar())
        })
        this.loading = false
        this.getMapData()
        this.map.on(
          'moveend',
          debounce(() => {
            if (this.canReq) this.getMapLngLatAndZoom()
          }, 500)
        )

        this.map.on(
          'zoomend',
          debounce(() => {
            this.getMapLngLatAndZoom()
          }, 500)
        )
      })
    },

    getMapLngLatAndZoom() {
      const bound = this.map.getBounds()
      const northWest = bound.getNorthWest()
      const southEast = bound.getSouthEast()
      let zoom = this.map.getZoom()
      console.log(northWest, southEast)
      if (zoom >= 13) {
        zoom = 4 // 公司
      } else if (zoom >= 10 && zoom < 13) {
        zoom = 3 // 区、县
      } else if (zoom >= 7 && zoom < 10) {
        zoom = 2 // 市
      } else {
        zoom = 1 // 省
      }
      this.mapOption = {
        level: zoom,
        topLat: northWest.lat,
        bottomLat: southEast.lat,
        leftLng: northWest.lng,
        rightLng: southEast.lng
      }
      this.getMapData(zoom)
    },

    getMapData(zoom) {
      this.$nextTick(() => {
        this.addMapMarker(zoom)
      })
    },

    parkMap() {
      if (!this.canReq) return
      this.loading = true
      return new Promise((resolve, reject) => {
        parkMap(Object.assign({}, this.params, this.mapOption)).then(res => {
          this.loading = false
          if (res.code === 60000) {
            this.map?.clearMap()
            this.markerList = []
            this.$nextTick(() => {
              resolve(res?.data || [])
            })
            return
          }
          resolve([])
        })
      })
    },

    async addMapMarker(zoom) {
      const list = await this.parkMap()
      list.forEach(i => {
        const exitMarker = this.markerList.find(
          marker =>
            marker.latitude === i.latitude &&
            marker.longitude === marker.longitude
        )
        let startMarker = new AMap.Marker({
          position: new AMap.LngLat(i.longitude, i.latitude),
          offset: exitMarker
            ? new AMap.Pixel(-13, 10)
            : new AMap.Pixel(-13, -30),
          content: this.getContent(`${i.name || i.parkName}`, i.count, zoom)
        })
        this.map?.add(startMarker)
        this.markerList.push(i)
        // 点击覆盖物
        startMarker.on('click', e => {
          if (zoom !== 4) {
            if (zoom === 3) {
              this.map.setZoom(14)
            } else if (zoom === 2) {
              this.map.setZoom(11)
            } else {
              const city = ['重庆市', '上海市', '天津市', '北京市']
              city.includes(i.province)
                ? this.map.setZoom(11)
                : this.map.setZoom(8)
            }
            this.map.setCenter([i.longitude, i.latitude])
          }
        })

        // 鼠标进入覆盖物
        startMarker.on('mouseover', e => {
          if (zoom === 4) this.showPop(i, exitMarker)
        })

        // 鼠标离开覆盖物
        startMarker.on('mouseout', e => {
          this.infoWindow?.close()
          this.canReq = true
        })
      })
    },

    getContent(name, value, zoom) {
      if (zoom !== 4) {
        name = name
          .replace('新疆维吾尔自治区', '新疆')
          .replace('西藏自治区', '西藏')
          .replace('内蒙古自治区', '内蒙古')
          .replace('广西壮族自治区', '广西')
          .replace('宁夏回族自治区', '宁夏')
        return `
        <div class="park-map-marker">
          <div>${name}</div>
          <div class="park-map-num">${value}个</div>
        </div>
      `
      } else {
        return `<div class="park-map-marker-cname">${name}</div>`
      }
    },

    showPop(data, offset) {
      const el = this.$refs.info.$el
      this.infoWindow = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        content: el,
        offset: offset ? new AMap.Pixel(-13, 10) : new AMap.Pixel(-13, -30),
        autoMove: true
      })
      this.infoWindowData = data
      this.infoWindow.open(this.map, [data.longitude, data.latitude])
      this.canReq = false
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped lang="scss">
.parkMap-main {
  height: 500px;
  width: 100%;
}
</style>
<style lang="scss">
.park-map-marker {
  height: 100px;
  width: 100px;
  background-color: rgba(31, 108, 221, 0.9);
  border: 1px solid transparent;
  color: white;
  padding: 2px;
  border-radius: 50%;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.park-map-marker:hover {
  background-color: orange;
}
.park-map-num {
  margin-top: 5px;
  font-size: 16px;
}
.park-map-marker-cname {
  padding: 3px 10px;
  box-sizing: border-box;
  display: inline-block;
  background-color: #1f6cdd;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  white-space: pre;
}
.park-map-pop {
  padding: 10px;
  box-sizing: border-box;
  height: 140px;
  width: 400px;
  overflow-x: hidden;
  display: flex;
  background-color: #fff;
}
.park-map-pop > .img {
  width: 120px;
  background-color: #41cabf;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.park-map-pop > .desc {
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 700;
}
.park-map-pop .money {
  color: orange;
}
.park-map-pop .name {
  font-size: 16px;
}
</style>
