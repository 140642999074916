var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-main" }, [
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.step === 1,
          expression: "step === 1",
        },
      ],
      staticClass: "er-code",
      attrs: { id: "wx_regs" },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step !== 1,
            expression: "step !== 1",
          },
        ],
        staticClass: "btn",
        on: { click: _vm.confirmClick },
      },
      [_vm._v("确定")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }