var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-dialog" },
    [
      _c(
        "pe-dialog",
        {
          attrs: {
            "pe-dialog-show": _vm.show,
            "pe-dialog-show-sure": false,
            PeDialogClass: "d-class",
            "pe-dialog-title": "详情",
          },
          on: { handlePeDialogClose: _vm.handleClose },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", { staticClass: "c-name" }, [
              _vm._v(_vm._s(_vm.companyInfo.companyName)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("企业标签")]),
                _vm._v(" "),
                _vm._l(_vm.companyInfo.tags, function (tag, idx) {
                  return _c("el-tag", { key: idx, attrs: { size: "mini" } }, [
                    _vm._v(_vm._s(tag)),
                  ])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "title" }, [_vm._v("企业简介")]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.companyInfo.introduce))]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("企业特征")]),
                _vm._v(" "),
                _vm._l(_vm.companyInfo.msgList, function (item, index) {
                  return _c("div", { key: index }, [
                    _vm._v("\n          " + _vm._s(item) + "\n        "),
                  ])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "el" }, [
                _c("div", { staticClass: "title" }, [_vm._v("属地落税：")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.companyInfo.taxArea))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "el" }, [
                _c("div", { staticClass: "title" }, [_vm._v("区域版块：")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.companyInfo.block))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "el" }, [
                _c("div", { staticClass: "title" }, [_vm._v("质量评分：")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.companyInfo.score))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "el" }, [
                _c("div", { staticClass: "title" }, [_vm._v("质量评级：")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.companyInfo.level))]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }