var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-scroll" },
    [
      _c(
        "el-scrollbar",
        { ref: "scrollbar", class: _vm.scrollClass },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.scrollLoading,
            expression: "scrollLoading",
          },
        ],
        staticClass: "task-load",
        attrs: { "element-loading-text": "加载中..." },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }