<template>
  <div class="body">
    <div class="body" id="myChart" v-if="chartData.length"></div>
    <div class="body tip" v-else v-loading="loading">
      <span v-show="!loading">
        无数据
      </span>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'dtChart',
  data() {
    return {
      myChart: null
    }
  },
  props: {
    chartData: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.chartData.length) {
        this.drawLine()
      }
      window.onresize = () => {
        this.myChart ? this.myChart.resize() : ''
      }
    })
  },
  methods: {
    drawLine() {
      this.chartData.sort((a, b) => {
        return b.value - a.value
      })
      let key = this.chartData.map(res => res.key)
      let value = this.chartData.map(res => res.value)
      let max = Math.max(...this.chartData.map(res => res.value))
      let dataShadow = []
      this.chartData.forEach(() => dataShadow.push(max))
      let myChart = echarts.init(document.getElementById('myChart'))
      this.myChart = myChart
      let option = {
        tooltip: {
          formatter: params => {
            return `${params['name']}: ${
              this.chartData[params['dataIndex']]['value']
            }家`
          }
        },

        xAxis: {
          data: key,
          axisLabel: {
            // inside: true,
            interval: 0,
            show: true,
            rotate: 40,
            textStyle: {
              color: '#333',
              fontSize: '10'
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          z: 10
        },

        yAxis: {
          axisLine: {
            show: false
          },
          allowDecimals: false,
          minInterval: 1,
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#999'
            }
          }
        },
        grid: [{ top: '20px', height: '230px' }],
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            itemStyle: {
              color: 'rgba(0,0,0,0.05)'
            },
            barMaxWidth: 40,
            barGap: '-100%',
            barCategoryGap: '40%',
            data: dataShadow,
            animation: false
          },
          {
            type: 'bar',
            barMaxWidth: 40,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            },
            data: value
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.body {
  height: 100%;
  width: 100%;
}
.tip {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
}
</style>
