var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tip-main" },
    [
      _c("div", { staticClass: "tip-text" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "er-code" }),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { prop: "mobile", label: "手机号", width: "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "openId", label: "微信OpenID", width: "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "bindTime", label: "绑定时间" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dt-modal-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.closeModal },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }