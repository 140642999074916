var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "viewPhone-main" }, [
    _c("div", { staticClass: "kf" }),
    _vm._v(" "),
    _c("div", { staticClass: "header" }, [
      _vm._v("\n    1、五卡话机是什么\n  "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "header",
        staticStyle: { "padding-top": "15px!important" },
      },
      [
        _vm._v(
          "\n    五卡话机方案是领鸟最新上线的电销方案，区别于以往的线路方案，话机方案可以类比于日常的手机打电话场景，具有稳定，高接通率等特点。\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "phone-image" }),
      _vm._v(" "),
      _c("div", { staticClass: "phone-table" }, [
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "90%" },
                attrs: { data: _vm.tableData, border: "", size: "mini" },
              },
              [
                _c("el-table-column", { attrs: { prop: "one", label: "" } }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "two", width: "150", label: "传统电销方案" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "three", label: "五卡话机方案" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "header" }, [_vm._v("\n    2、接入流程\n  ")]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.getSim },
          },
          [_vm._v("\n        立即申请试用\n      ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "liu-cheng" }, [
      _c("div", { staticClass: "no-jt" }, [_vm._v("\n      申请试用\n    ")]),
      _vm._v(" "),
      _c("div", [_vm._v("\n      商务沟通\n    ")]),
      _vm._v(" "),
      _c("div", [_vm._v("\n      设备部署\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "long" }, [
        _vm._v(
          "\n      渠道办卡（需提供公司资质且报备销售话术，通过后方可购买SIM卡，卡入网需要实名制）\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "no-line" }, [_vm._v("\n      投入使用\n    ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }