<template>
    <div class="dialog-body">
        <div class="qr-code" id="qr-code" ref="code">

        </div>
        <div class="op">
          <span @click="copyUrl">复制链接</span><span @click="downLoadErCode">下载二维码</span>
        </div>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2'
    export default {
        name: "createErCode",
        data(){
          return {
            url: ''
          }
        },
        mounted() {
          const modalParams = this.$store.state.plugin.modal.options;
          this.url = modalParams.url;
          this.createCode()
        },
        methods: {
            createCode(){
              const codeEl = this.$refs['code']
              new QRCode(codeEl, this.url);
            },
            copyUrl(){
              let oInput = document.createElement("input");
              oInput.value = this.url;
              document.body.appendChild(oInput);
              this.selectText(oInput);
              document.execCommand("Copy");
              oInput.className = "oInput";
              oInput.style.display = "none";
              this.$store.dispatch('showTip', {
                text: `复制成功 ${ this.url }  `,
                type: 'success',
                duration: 2000
              })
            },
            selectText(oInput) {
              if (oInput.createTextRange) {
                //ie
                oInput.select();
              } else {
                //firefox/chrome
                oInput.setSelectionRange(0, oInput.value.length);
                oInput.focus();
              }
            },
            downLoadErCode(){
              var drawing = this.$refs.code.childNodes[0];
              //确定浏览器支持<canvas>元素
              if (drawing.getContext) {
                var context = drawing.getContext("2d");
                //取得图像的数据 URI
                var imgURI = drawing.toDataURL("image/png");
                //显示图像
                var image = document.createElement("img");
                image.src = imgURI;
                var a = document.createElement("a");
                var event = new MouseEvent("click");
                a.download = "code";
                a.href = imgURI;
                a.dispatchEvent(event);
              }
            }
        }
    }
</script>

<style scoped>
.dialog-body{
  height: auto;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  flex-direction: column;
}
.op{
  margin: 20px 0;
}
.op > span:last-child{
  margin-left: 20px;
}
span{
  color: #0099CC;
  cursor: pointer;
}
</style>
