var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "zfb-page" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v("购买产品:" + _vm._s(_vm.chartName)),
          ]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              on: { change: _vm.handleRadioChange },
              model: {
                value: _vm.radio,
                callback: function ($$v) {
                  _vm.radio = $$v
                },
                expression: "radio",
              },
            },
            _vm._l(_vm.chartPrice, function (item, index) {
              return _c("el-radio", { attrs: { label: index } }, [
                _c("span", [_vm._v(_vm._s(item.month) + "个月:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.price) + "元")]),
              ])
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "\n        剩余虚拟币：" +
                _vm._s(_vm.virtualCoin * 100) +
                "个，100个抵用1元\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _vm._v("支付金额=折后价格-虚拟币数量/100"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "money" }, [
          _c("span", [_vm._v("支付金额")]),
          _vm._v(" "),
          _c("span", { staticClass: "num" }, [
            _vm._v(_vm._s(_vm.selectPrice) + "元"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "way" }, [
          _c("span", [_vm._v("支付方式")]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "zfb" },
            [
              _c("svg-icon", {
                staticStyle: { "font-size": "24px" },
                attrs: { "icon-class": "zfb_apply" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "txt" }, [_vm._v("支付宝")]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "notice" }, [
          _vm._v("请等待支付完成后在关闭弹窗,以免造成订单失败"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "canvas",
          },
          [
            _vm.isShowCode
              ? _c("iframe", {
                  staticStyle: { overflow: "hidden" },
                  attrs: {
                    srcdoc: _vm.formData,
                    id: "qrCode",
                    frameborder: "no",
                    border: "0",
                    marginwidth: "0",
                    marginheight: "0",
                    scrolling: "no",
                    width: "200",
                    height: "200",
                  },
                })
              : _vm._e(),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }