<template>
    <div class="proxy-create-main">
      <div class="main">
        <div class="tip">
          <div class="text">
            1、<span @click="copy">点击复制</span>专属链接，发给代理商
          </div>
          <div class="text">
            2、代理商通过此链接完成注册
          </div>
          <div class="text">
            3、向代理商分配线索及资源
          </div>
        </div>
        <div class="link" @click="copy" v-show="false">
          {{ shareUrl }}
        </div>
      </div>
      <div class="footer">
        <div>
          <el-button size="small" @click="cancelBtn" v-show="false">取消</el-button>
          <el-button size="small" type="primary" @click="confirmBtn">确认</el-button>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'proxy-create',
    data () {
      return {
        shareUrl: ''
      }
    },
    watch: {},
    beforeDestroy () {},
    created () {},
    mounted () {
      const isDev = process.env.NODE_ENV === 'development'
      if(isDev){
        this.shareUrl = `http://test.dtbird.cn/#/registerBase?shareId=${ this.id }`
      }else {
        this.shareUrl = `https://ai.dtbird.cn/#/registerBase?shareId=${ this.id }`
      }
    },
    computed: {
      id(){
        return  this.$store.state.user.accountInfo.enterpriseKcId
      }
    },
    methods: {
      cancelBtn(){
        this.$store.dispatch('hideModal')
      },
      confirmBtn(){
        this.cancelBtn()
      },
      selectText(oInput) {
        if (oInput.createTextRange) {
          oInput.select();
        } else {
          oInput.setSelectionRange(0, oInput.value.length);
          oInput.focus();
        }
      },
      copy(){
        event.stopPropagation();
        const oInput = document.createElement("input");
        oInput.value = this.shareUrl;
        document.body.appendChild(oInput);
        this.selectText(oInput);
        document.execCommand("Copy");
        oInput.className = "oInput";
        oInput.style.display = "none";
        this.$store.dispatch('showTip', {
          text: `复制成功`,
          type: 'success',
          duration: 2000
        })
      }
    }
  }
</script>

<style scoped>
.proxy-create-main{
  width: 100%;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}
.main{
  min-height: 140px;
  height: auto;
}
.footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  position: relative;
}
.footer::before{
  content: '';
  height: 1px;
  width: calc( 100% + 40px );
  background-color: rgba(0,0,0,.1);
  top: 0;
  left: -20px;
  position: absolute;
  transform: scaleY(.5);
}
.text{
  font-size: 14px;
  line-height: 30px;
  user-select: none;
}
.text > span{
  color: #2b8dfd;
  cursor: pointer;
}
.link{
  margin: 20px 0 30px 0;
  padding: 0 30px;
  box-sizing: border-box;
  height: 60px;
  border: rgba(0,0,0,.05) solid 1px;
  border-radius: 2px;
  line-height: 60px;
  cursor: pointer;
  user-select: none;
}
.link:hover{
  background-color: rgba(0,0,0,.03);
}
.tip{
  font-size: 12px;
  line-height: inherit;
  padding: 14px 20px;
  vertical-align: middle;
  /*color: #002da0;*/
  /*border: 1px solid #d5e7ff;*/
  border-radius: 0;
  /*background: #41CABF;*/
}
</style>
