<template>
  <div class="view-div" v-loading="loading">
    <permission
      :key="loading"
      :name="name"
      :permission-list="updatePermission"
      :update-permission="updatePermission"
      type="详细"
    />
  </div>
</template>

<script>
import { getPanelList } from '@/api/permission'
import permission from './component/permission'
export default {
  components: {
    permission
  },
  data() {
    return {
      updatePermission: [], //要查看的permission数据
      loading: false,
      roleId: null,
      name: ''
    }
  },

  created() {
    this.name = this.$route.query['name']
    this.roleId = this.$route.query['roleId']
    if (!this.name || !this.roleId) {
      this.$router.replace('/permission/permission')
      return
    }
    this.$bus.$on('permissionValueChange', () => {
      console.log(222777)
    })
    this.init() //获取permission数据
  },

  methods: {
    tip(msg, type = 'success', time = 800) {
      this.$message({
        message: msg,
        type: type,
        duration: time
      })
    },
    async init() {
      if (!this.roleId) {
        this.tip('参数错误', 'error')
        return
      }
      this.loading = true
      try {
        const res = await getPanelList({ roleId: this.roleId })
        if (res && res.code === 60000 && res.data && res.data.code === 60000) {
          this.updatePermission = res.data.data
        } else {
          this.tip('出错了', 'error')
          this.$router.replace('/permission/permission')
        }
      } catch (e) {
        this.tip('出错了', 'error')
        this.$router.replace('/permission/permission')
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
.view-div {
  /*height: 100%;*/
  width: 100%;
}
</style>
