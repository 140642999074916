var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "enter-name-input" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "draggable",
          {
            attrs: {
              filter: ".forbid",
              handle: ".move",
              preventOnFilter: false,
              animation: "300",
            },
            on: { update: _vm.datadragEnd },
            model: {
              value: _vm.tags,
              callback: function ($$v) {
                _vm.tags = $$v
              },
              expression: "tags",
            },
          },
          [
            _vm._l(_vm.tags, function (item, index) {
              return [
                _c(
                  "div",
                  { staticClass: "drag-content" },
                  [
                    _c("el-input", {
                      staticClass: "input-new-tag forbid",
                      staticStyle: { width: "80%", "margin-top": "10px" },
                      attrs: { clearable: "", placeholder: "输入标签名称" },
                      model: {
                        value: item.name,
                        callback: function ($$v) {
                          _vm.$set(item, "name", $$v)
                        },
                        expression: "item.name",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "drag-icon" },
                      [
                        _c(
                          "svg-icon",
                          {
                            staticClass: "move",
                            staticStyle: { margin: "0 10px" },
                            attrs: { iconClass: "sort" },
                          },
                          [_vm._v("排序")]
                        ),
                        _vm._v(" "),
                        _c(
                          "svg-icon",
                          {
                            staticClass: "forbid",
                            attrs: { iconClass: "close" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.handleDelete(item, index)
                              },
                            },
                          },
                          [_vm._v("\n              删除标签\n            ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-plus", type: "text" },
            on: { click: _vm.handleAdd },
          },
          [_vm._v("添加")]
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.cancelBtn } },
              [_vm._v("取消")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.handleSure },
              },
              [_vm._v("确认")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }