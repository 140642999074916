<template>
  <div class="p-main">
    <el-row>
      <el-col :span="24">
        <div
          class="list-item-link"
          v-dt-permission="'客户培育-线索培育-新建培育池'"
        >
          <el-link type="primary" @click="addFavoriteClick">新建培育池</el-link>
        </div>
      </el-col>
    </el-row>
    <el-scrollbar class="list dt-dropdown-dt">
      <el-row v-infinite-scroll="load">
        <el-col :span="24" v-for="item in list" :key="item.id">
          <div class="list-item">
            <el-radio v-model="favoriteId" :label="item.id">{{
              item.name
            }}</el-radio>
          </div>
        </el-col>
      </el-row>
    </el-scrollbar>

    <div class="footer">
      <div class="save-this-search">
        <el-checkbox v-model="isShowSelectBook">下次不再提示</el-checkbox>
      </div>
      <div>
        <el-button size="small" @click="cancelBtn">取消</el-button>
        <el-button type="primary" size="small" @click="confirmBtn"
          >确认</el-button
        >
      </div>
    </div>

    <el-dialog
      class="dialog-dt"
      append-to-body
      :visible.sync="addFavorite"
      width="650px"
      :close-on-click-modal="false"
      :before-close="addFavoriteClose"
    >
      <div slot="title">
        <div class="header">新建培育池</div>
      </div>
      <div class="create-favorite">
        <el-form :model="formData" label-width="auto" :rules="rule" ref="form">
          <el-form-item label="名称" prop="name">
            <el-input
              v-model.trim="formData.name"
              placeholder="请输入培育池名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="备注" prop="note">
            <el-input
              v-model.trim="formData.note"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              placeholder="请输入备注内容"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer" slot="footer">
        <div class="save-this-search"></div>
        <div>
          <el-button size="small" @click="addFavoriteClose">取消</el-button>
          <el-button type="primary" size="small" @click="addFavoriteConfirm"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getFavoritesList, getFavoritesAdd } from '@/api/table'
import DtTable from '@/components/element/DtTable'
export default {
  name: 'PeiYuList',
  components: {
    DtTable
  },
  data() {
    return {
      list: [],
      isShowSelectBook: false,
      pageObj: {
        pageNo: 0,
        pageSize: 1000,
        total: 0
      },
      favoriteId: '',
      // 新建培育池弹框
      addFavorite: false,
      formData: {
        name: '',
        note: ''
      },
      rule: {
        name: [{ required: true, message: '请输入培育池名称', trigger: 'blur' }]
      }
    }
  },

  mounted() {
    this.favoriteId = this.$store.state.user.sysConfig.defaultFavoriteId
  },

  methods: {
    load() {
      // this.$store.state.user.sysConfig.defaultFavoriteId
      if (this.pageObj.total > this.list.length || this.pageObj.total === 0) {
        this.pageObj.pageNo += 1
        this.getFavoriteList()
      }
    },

    getFavoriteList() {
      getFavoritesList(this.pageObj).then(res => {
        if (res.code === 60000) {
          this.list = [...this.list, ...res.data.list]
          this.pageObj.total = res.data.page.total
        }
      })
    },

    cancelBtn() {
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          refresh: false
        }
      })
      this.$store.dispatch('hideModal')
    },

    confirmBtn() {
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          refresh: true,
          favoriteObj: this.list.filter(item => item.id === this.favoriteId)[0],
          isShowSelectBook: this.isShowSelectBook
        }
      })
      this.$store.dispatch('hideModal')
    },

    // 关闭新建培育池回调
    addFavoriteClose() {
      this.addFavorite = false
    },

    addFavoriteClick() {
      this.addFavorite = true
    },

    addFavoriteConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          getFavoritesAdd(this.formData).then(res => {
            if (res.code === 60000) {
              this.pageObj.pageNo = 1
              this.list = []
              this.getFavoriteList()
              this.addFavorite = false
              this.formData.name = ''
              this.formData.note = ''
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.p-main {
  width: 100%;
  min-height: 300px;
  height: auto;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
}
.list {
  height: 250px;
  width: 100%;
}

.list-item {
  height: 50px;
  width: 100%;
  font-size: 20px;
  padding: 0 50px;
  position: relative;
  display: flex;
  align-items: center;
}

.list-item::before {
  content: '';
  display: inline-block;
  height: 1px;
  width: calc(100% - 100px);
  position: absolute;
  bottom: 0;
  left: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
}

.list-item-link {
  height: 40px;
  width: 100%;
  font-size: 20px;
  padding: 0 50px;
}

.name-f {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: relative;
}
.footer::before {
  content: '';
  height: 1px;
  width: calc(100% + 40px);
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: -20px;
  position: absolute;
  transform: scaleY(0.5);
}
.dialog-dt /deep/ .el-dialog__header {
  padding: 0 !important;
}

.dialog-dt /deep/ .el-dialog__close {
  color: #ffffff !important;
}
.dialog-dt /deep/ .el-dialog__footer {
  padding: 0 10px !important;
}
.dialog-dt /deep/ .el-dialog__body {
  padding: 0 !important;
}

.header {
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #41cabf;
  padding: 0 20px;
  box-sizing: border-box;
  color: #ffffff;
}

.create-favorite {
  padding: 10px 30px;
  box-sizing: border-box;
}
</style>
