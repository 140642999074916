<template>
  <div class="compete-module">
    <div class="title">
      <span>同行竞品(已上市企业)</span>
    </div>
    <template v-if="isBlur">
      <div class="touch-no-receive">
        <div class="touch-no-cont">
          <div class="touch-no-icon">
            <span
              style="color: #008CD9;text-decoration: underline;cursor:pointer;"
              @click="getReceiveData('phone')"
              >领取</span
            >
            <span>以查看联系方式</span>
          </div>
        </div>
      </div>
    </template>
    <div :style="blurObj">
      <div class="listed-wrap">
        <template v-for="(item, index) in tags1">
          <span
            :class="currentIndex1 == index ? 'active tag' : 'tag'"
            @click="handleIndex1(item, index, 'listed')"
            >{{ item }}</span
          >
        </template>
      </div>

      <PeTable
        class="market-table"
        :peTableData="peTableData1"
        :peColumnList="peColumnList1"
        :peLoading="peLoading"
        @handleSelectCommand="handleSelectCommand(arguments, 'listed')"
        @sortPeChange="handleSortPeChange(arguments, 'listed')"
        @handleCurrentPePage="handleCurrentPePage(arguments, 'listed')"
        @handleSizePeChange="handleSizePeChange(arguments, 'listed')"
        :pePage="pePage1"
        :peFooter="false"
        :current-column-width="{ company_name: 180 }"
      >
        <template v-slot:company_name="{ scope }">
          <div
            class="company-name-click"
            @click="handleEnter(scope.data, scope.index, 'listed')"
          >
            {{ scope.data.company_name }}
          </div>
        </template>
      </PeTable>
      <div class="title">
        <span> 同行竞品(未上市企业)</span>
      </div>
      <div class="listed-wrap">
        <template v-for="(item, index) in tags2">
          <span
            :class="currentIndex2 == index ? 'active tag' : 'tag'"
            @click="handleIndex2(item, index, 'unListed')"
            >{{ item }}</span
          >
        </template>
      </div>
      <PeTable
        class="un-market"
        :peTableData="peTableData2"
        :peLoading="peLoading2"
        @handleSelectCommand="handleSelectCommand(arguments, 'unListed')"
        @sortPeChange="handleSortPeChange(arguments, 'unListed')"
        @handleCurrentPePage="handleCurrentPePage(arguments, 'unListed')"
        @handleSizePeChange="handleSizePeChange(arguments, 'unListed')"
        :peColumnList="peColumnList2"
        :pePage="pePage2"
        :peFooter="false"
        :current-column-width="{ company_name: 180 }"
      >
        <template v-slot:company_name="{ scope }">
          <div
            class="company-name-click"
            @click="handleEnter(scope.data, scope.index, 'unListed')"
          >
            {{ scope.data.company_name }}
          </div>
        </template>
      </PeTable>
    </div>
  </div>
</template>

<script>
import PeTable from '@/components/Peculiar/table'
import { getComDetailModel } from '@/api/thread'
import bindMobile from '@/views/usercenter/dialog/bindMobile'
export default {
  name: 'CompeteProduct',
  props: {
    moduleDetail: {
      type: Object,
      default: () => {}
    },
    moduleParam: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      peTableData1: [],
      peColumnList1: [],
      peTableData2: [],
      peColumnList2: [],
      peLoading: false,
      peLoading2: false,
      pePage1: {
        pageSizes: [10, 20, 50]
      },
      pePage2: {
        pageSizes: [10, 20, 50]
      },
      pageParams: {
        listed: {
          pageNo: sessionStorage.listedNo || 1,
          pageSize: sessionStorage.listedSize || 10
        },
        unListed: {
          pageNo: sessionStorage.unListedNo || 1,
          pageSize: sessionStorage.unListedSize || 10
        }
      },
      tags1: [],
      tags2: [],
      currentIndex1: -1,
      currentIndex2: -1,
      selectType: ''
    }
  },
  components: {
    PeTable
  },
  computed: {
    isBlur() {
      return !this.moduleParam.isReceive && this.moduleParam.module_shade
    },
    blurObj() {
      const blur = !this.moduleParam.isReceive && this.moduleParam.module_shade
      if (blur) {
        return `filter:blur(1.5px)`
      } else {
        return ''
      }
    }
  },
  mounted() {
    // this.getList()
  },
  watch: {
    moduleParam: {
      handler(val) {
        if (val.isReceive) {
          this.getList()
        }
        console.log(val, 1144)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getReceiveData() {
      if (
        this.$store.state.plugin.env === 'dd' &&
        !this.$store.state.user.accountInfo.mobile
      ) {
        this.$store.dispatch('showModal', {
          title: '绑定手机号',
          view: bindMobile,
          size: '600px'
        })
        return
      }

      //预览数据不可领取
      if (localStorage.getItem('isPreview') == true) {
        this.$store.dispatch('showTip', {
          text: '样例数据不可领取',
          type: 'error'
        })
        return
      }

      // this.getLoading = true
      this.$store.state.settings.isOn = true
      this.$bus.$emit('getContactType', true)
    },
    handleIndex1(item, index, type) {
      this.currentIndex1 = index
      this.pageParams[type].tagName = item == '全部' ? '' : item
      this.selectType = type
      this.getList()
    },
    handleIndex2(item, index, type) {
      this.currentIndex2 = index
      this.pageParams[type].tagName = item == '全部' ? '' : item
      this.selectType = type
      this.getList()
    },
    getData(obj) {
      //   debugger
      const market = obj['已上市企业']
      const unMarket = obj['未上市企业']
      this.peTableData1 = market.list
      this.peTableData2 = unMarket.list
      this.peColumnList1 = market.columns
      this.peColumnList2 = unMarket.columns
      this.pePage1 = Object.assign(this.pePage1, market.page)
      this.pePage2 = Object.assign(this.pePage2, unMarket.page)
      this.tags1 = market.tags.map(item => item.tagName)
      this.tags2 = unMarket.tags.map(item => item.tagName)
      if (!this.tags1.includes('全部')) {
        this.tags1.unshift('全部')
      }
      if (!this.tags2.includes('全部')) {
        this.tags2.unshift('全部')
      }
    },
    async handleEnter(data, index, type) {
      this.selectType = type
      const list = type == 'listed' ? this.peTableData1 : this.peTableData2
      localStorage.setItem('cuttentList', JSON.stringify(list))
      localStorage.setItem('currSindex', index)
      const packageId = this.$route.query.packageId || 0
      this.$bus.$emit(
        'handleCompeteData',
        data.company_uuid,
        data.company_name,
        packageId,
        index,
        true
      )
    },
    handleSortPeChange(params, type) {
      const map = [
        {
          key: 'desc',
          value: '降序'
        },
        {
          key: 'asc',
          value: '升序'
        }
      ]
      let sort = ''
      const value = map.find(item => item.key == params[2].sortOrder)?.value
      if (params[2].sortAlias) {
        sort = `${params[2].sortAlias}:${value}`
      } else {
        sort = `${params[2].sortField}:${value}`
      }
      this.pageParams[type].sort = sort
      this.selectType = type
      this.getList()
    },
    handleSelectCommand(params, type) {
      this.pageParams[type].activityClass = `${params[1]}`
      this.selectType = type
      this.getList()
    },
    handleCurrentPePage(e, type) {
      this.pageParams[type].pageNo = e[0]
      sessionStorage.setItem(`${type}No`, e[0])
      this.selectType = type
      this.getList()
    },
    handleSizePeChange(e, type) {
      sessionStorage.setItem(`${type}Size`, e[0])
      sessionStorage.setItem(`${type}No`, 1)
      this.pageParams[type].pageSize = e[0]
      this.pageParams[type].pageNo = 1
      this.selectType = type
      this.getList()
    },
    handleLoading(flag) {
      if (this.selectType == 'listed') {
        this.peLoading = flag
      } else {
        this.peLoading2 = flag
      }
    },
    async getList() {
      const params = {
        module: this.moduleParam.module_api,
        packageId: this.moduleParam.packageId,
        companyUuid: this.moduleParam.companyUuid,
        param: this.pageParams
      }
      this.handleLoading(true)
      const { data } = await getComDetailModel(params)
      const obj = data['同行竞品']
      this.getData(obj)
      this.handleLoading(false)
    }
  }
}
</script>
<style lang="scss" scoped>
.compete-module {
  padding: 10px;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #2e2e2e;
  }
  .market {
    font-size: 12px;
    margin-top: 10px;
  }
  .market-table {
    padding-bottom: 0 !important;
  }

  .listed-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .tag {
    font-size: 14px;
    cursor: pointer;
    margin: 4px 10px;
  }
  .active {
    color: rgb(65, 202, 191);
  }
  .touch-no-receive {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 11;
    .touch-no-cont {
      position: relative;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      .touch-no-icon {
        width: 150px;
        height: 40px;
        margin-top: 100px;
        position: relative;
        font-size: 14px;
        color: #8f8f8f;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 41px;
          height: 22px;
          top: -50px;
          left: 42px;
          background: url('../../../assets/no-receive.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
