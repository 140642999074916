<template>
  <div class="yunServiceHistoryList-main">
    <div v-if="!serviceData.length" class="empty-data">
      暂无数据
    </div>
    <div
      :class="index === 0 ? 'active main' : 'main'"
      v-for="(item, index) in historyList"
      :key="index"
    >
      <div class="time-name">
        <span class="time">{{ item.timeAt }}</span
        ><span class="name">{{ item.gongyingshang }}</span>
      </div>
      <div class="type">
        <div class="add-ip">
          <span class="address">{{ item.isp }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'yunServiceHistoryList',
  data() {
    return {
      historyList: []
    }
  },
  props: {
    serviceData: {
      type: Array,
      default: () => []
    },
    showLimit: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    serviceData: {
      handler() {
        if (this.showLimit) {
          this.historyList = this.serviceData.slice(0, 2)
        } else {
          this.historyList = this.serviceData
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    copy(text) {
      event.stopPropagation()
      var oInput = document.createElement('input')
      oInput.value = text
      document.body.appendChild(oInput)
      this.selectText(oInput)
      document.execCommand('Copy')
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this.$store.dispatch('showTip', {
        text: '复制成功',
        type: 'success',
        duration: 800
      })
    },
    selectText(oInput) {
      if (oInput.createTextRange) {
        //ie
        oInput.select()
      } else {
        //firefox/chrome
        oInput.setSelectionRange(0, oInput.value.length)
        oInput.focus()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.yunServiceHistoryList-main {
  height: 100vh;
  width: 100%;
  position: relative;
  font-size: 12px !important;
  padding: 10px;
  & .main:last-child {
    border-left: none !important;
  }
  .main {
    min-height: 60px;
    padding-left: 30px;
    box-sizing: border-box;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    .time-name {
      display: flex;
      align-items: center;
      .name {
        line-height: 20px;
        margin: 0 20px;
      }
      .pe-icon {
        width: 20px;
        height: 20px;
      }
    }
    .type {
      padding: 10px 0;
      box-sizing: border-box;
      flex: 1;
      & > .add-ip:last-child {
        margin-bottom: 0;
      }
      .add-ip {
        color: #8a8a8a;
        margin-bottom: 10px;
        .address {
          margin-right: 20px;
          line-height: 20px;
        }
        .ip-add {
          text-decoration: underline;
          cursor: pointer;
          user-select: none;
        }
      }
    }
    &:after {
      content: '';
      display: inline-block;
      height: 10px;
      width: 10px;
      background-color: #8a8a8a;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: -5px;
    }
  }
  .active {
    &:after {
      background-color: red;
    }
  }
  .empty-data {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8a8a8a;
  }
}
</style>
