var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payManage-main" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", [
          _vm._v("\n      收/退款管理\n      "),
          _c(
            "span",
            { staticStyle: { "font-size": "14px", color: "#8A8A8A" } },
            [_vm._v("(" + _vm._s(_vm.payData.companyName) + ")")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.refreshPayBack },
              },
              [_vm._v("刷新回款")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.createPayBack },
              },
              [_vm._v("新建收款单")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "danger" },
                on: { click: _vm.createReturnPay },
              },
              [_vm._v("新建退款单")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        _c("span", [
          _vm._v(
            "合同金额：" +
              _vm._s(_vm.transferNum(_vm.moneyData.contractAmount)) +
              "元"
          ),
        ]),
        _vm._v(" "),
        _c("span", { staticStyle: { margin: "0 10px" } }, [
          _vm._v(
            "有效回款金额：" +
              _vm._s(_vm.transferNum(_vm.moneyData.returnAmount)) +
              "元"
          ),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "欠款金额：" +
              _vm._s(_vm.transferNum(_vm.moneyData.debtAmount)) +
              "元"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { staticClass: "main dt-dropdown-dt" },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.payList.length === 0,
                  expression: "payList.length === 0",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "empty",
            },
            [_vm._v("\n      暂无数据\n    ")]
          ),
          _vm._v(" "),
          !_vm.canCreate
            ? _c(
                "div",
                { staticClass: "ready-pay" },
                [
                  _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: {
                      src: require("../../../assets/readypay.png"),
                      fit: "fit",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.payList, function (item) {
            return _c(
              "el-card",
              {
                key: item.id,
                staticStyle: { "margin-bottom": "20px" },
                attrs: { shadow: "hover" },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "div",
                      {
                        class:
                          item.returnType === 2
                            ? "icon-tag"
                            : "icon-tag icon-tag-s",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.returnType === 2 ? "退款" : "收款") +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "edit" }, [
                      _c("i", {
                        directives: [
                          {
                            name: "dt-permission",
                            rawName: "v-dt-permission",
                            value: "客户管理CRM-收/退款管理-编辑",
                            expression: "'客户管理CRM-收/退款管理-编辑'",
                          },
                        ],
                        staticClass: "el-icon-setting item-edit",
                        on: {
                          click: function ($event) {
                            return _vm.editPayBack(item)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "dt-permission",
                            rawName: "v-dt-permission",
                            value: "客户管理CRM-收/退款管理-删除",
                            expression: "'客户管理CRM-收/退款管理-删除'",
                          },
                        ],
                        staticClass: "el-icon-delete item-del",
                        on: {
                          click: function ($event) {
                            return _vm.delPayBack(item)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { span: 12 },
                      },
                      [
                        _c("span", { staticClass: "key" }, [
                          _vm._v(
                            _vm._s(
                              item.returnType === 2
                                ? "退款单编号"
                                : "收款单编号"
                            ) + " :"
                          ),
                        ]),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.contractReturnNumber || "-")),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { span: 12 },
                      },
                      [
                        _c("span", { staticClass: "key" }, [
                          _vm._v(
                            _vm._s(
                              item.returnType === 2 ? "退款金额" : "收款金额"
                            ) + ":"
                          ),
                        ]),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(
                            _vm._s(_vm.transferNum(item.returnMoney) || "-")
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { span: 12 },
                      },
                      [
                        _c("span", { staticClass: "key" }, [
                          _vm._v(
                            _vm._s(
                              item.returnType === 2 ? "退款日期" : "收款日期"
                            ) + ":"
                          ),
                        ]),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.returnDate || "-")),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { span: 12 },
                      },
                      [
                        _c("span", { staticClass: "key" }, [_vm._v("归属人:")]),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.belongUser || "-")),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { span: 12 },
                      },
                      [
                        _c("span", { staticClass: "key" }, [
                          _vm._v("归属月份:"),
                        ]),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.achievementBelongDate || "-")),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { span: 12 },
                      },
                      [
                        _c("span", { staticClass: "key" }, [
                          _vm._v("创建时间:"),
                        ]),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.createdAt || "-")),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c("span", { staticClass: "key" }, [_vm._v("备注:")]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(item.remark || "-")),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }