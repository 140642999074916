<template>
  <div
    class="body"
    :style="{ height: showSelect ? '600px' : '100vh' }"
    v-loading="loading"
  >
    <div class="header">
      <div class="title">
        <el-tag
          v-for="item in currentCheckObj"
          style="margin:0 5px 0 0"
          closable
          @close="closeTag(item)"
          >{{ item.contact | FormatText }}</el-tag
        >
      </div>

      <div v-dt-permission="'客户管理CRM-客户公海-联系人'">
        <el-tooltip
          placement="bottom"
          popper-class="popper"
          :visible-arrow="false"
        >
          <div slot="content" style="text-align: center;font-size: 14px">
            从系统提供的电话中转入
          </div>
          <el-button
            type="primary"
            size="mini"
            @click="tranfContact"
            class="fuck-btn"
          >
            转入联系人
          </el-button>
        </el-tooltip>
        <el-button type="primary" size="mini" @click="addContactDia"
          >新建联系人</el-button
        >
      </div>
    </div>
    <el-scrollbar
      class="main dt-dropdown-dt"
      v-if="contactList.length"
      :style="{
        height: showSelect ? 'calc( 100% - 100px )' : 'calc( 100% - 50px )'
      }"
    >
      <el-row :gutter="10">
        <el-col :span="12" v-for="item in contactList" :key="item.id">
          <div class="contact-item">
            <div class="contact-item-header">
              <div class="contact-name">
                <span
                  @click="
                    showErrorTip(
                      item,
                      !(
                        item.contact &&
                        item.position &&
                        (item.landLine || item.mobile)
                      )
                    )
                  "
                >
                  <el-checkbox
                    v-if="showSelect"
                    :value="item.selected"
                    :disabled="
                      permissionList.includes('客户管理CRM-客户公海-有效联系人')
                        ? !(
                            item.contact &&
                            item.position &&
                            (item.landLine || item.mobile)
                          )
                        : false
                    "
                    @change="selectChange(item)"
                  ></el-checkbox>
                </span>
                <el-badge
                  :value="item['isKp'] == 1 ? 'KP' : ''"
                  class="item"
                  type="primary"
                >
                  <span style="padding:0 10px 0 5px">
                    {{ item.contact | FormatText }}
                  </span>
                </el-badge>
                <span
                  :style="{ marginLeft: item['isKp'] == 1 ? '20px' : '-5px' }"
                  v-if="item.position"
                >
                  <el-tag size="mini">{{ item.position }}</el-tag>
                </span>
              </div>
              <div class="contact-icon">
                <span>
                  <i
                    class="el-icon-setting"
                    v-if="
                      ($store.getters.accountType == '1' || isActiveTab != 2) &&
                        !item.isMaiMai
                    "
                    @click="editContactDia(item)"
                  ></i>
                  <i class="el-icon-setting not-allowed-icon" v-else></i>
                </span>
                <span>
                  <i
                    class="el-icon-delete"
                    v-if="
                      ($store.getters.accountType == '1' || isActiveTab != 2) &&
                        !item.isMaiMai
                    "
                    @click="deleteCard(item)"
                  ></i>
                  <i class="el-icon-delete not-allowed-icon" v-else></i>
                </span>
              </div>
            </div>
            <div class="contact-item-li" v-for="_item in itemList">
              <span class="label">{{ _item.label }}</span>
              <el-tooltip
                placement="top"
                v-if="_item.key === 'remark' && item['remark']"
              >
                <div slot="content">{{ item[_item.key] || _item.default }}</div>
                <span class="value one-line">
                  {{ item[_item.key] || _item.default }}
                </span>
              </el-tooltip>

              <span
                class="value"
                v-if="
                  _item.key !== 'bindWechat' &&
                    _item.key !== 'landLine' &&
                    _item.key !== 'mobile' &&
                    _item.key !== 'score' &&
                    _item.key !== 'remark' &&
                    _item.key !== 'renew' &&
                    _item.key !== 'creater'
                "
              >
                {{ item[_item.key] || _item.default }}</span
              >
              <dt-score
                :score="item[_item.key] || _item.default"
                v-if="_item.key === 'score'"
                :key="scoreKey"
              ></dt-score>
              <span class="value" v-if="_item.key === 'renew' && item['renew']">
                {{
                  `${item[_item.key] || _item.default}&nbsp&nbsp${
                    item['updatedAt']
                  }`
                }}
              </span>
              <span class="value" v-if="_item.key === 'creater'">
                {{
                  `${item[_item.key] || _item.default}&nbsp&nbsp${item[
                    'createdAt'
                  ] || ''}`
                }}
              </span>
              <span
                class="value"
                v-if="_item.key === 'mobile' && item['mobile']"
              >
                {{ item[_item.key] || _item.default }}
                <span
                  class="callicon"
                  @click="call(item[_item.key], item.contact, companyName)"
                  v-if="isWaihu == '1'"
                ></span>
              </span>
              <span
                class="value"
                v-if="_item.key === 'landLine' && item['landLine']"
              >
                {{ item[_item.key] || _item.default }}
                <span
                  class="callicon"
                  @click="call(item[_item.key], item.contact, companyName)"
                  v-if="isWaihu == '1'"
                ></span>
              </span>
              <span class="value" v-if="_item.key === 'bindWechat'">
                {{ item[_item.key] ? '已关联' : '未关联' }}
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-scrollbar>
    <div
      class="main center"
      v-if="!contactList.length"
      :style="{
        height: showSelect ? 'calc( 100% - 100px )' : 'calc( 100% - 50px )'
      }"
    >
      暂无联系人
    </div>
    <div class="btn" v-if="showSelect">
      <span
        v-if="showSelect"
        class="add-tip"
        v-dt-permission="'客户管理CRM-客户公海-有效联系人'"
      >
        <span style="color:red;margin-right: 5px">*</span
        >只能选择姓名、电话、职位齐全的有效联系人
      </span>
      <el-button size="mini" @click="cancelBtn">取消</el-button>
      <el-button type="primary" size="mini" @click="confirmBtn">确定</el-button>
    </div>
    <dia-model
      @submitContBtn="addContactBtn"
      class="pond-form-input"
      ref="addContact"
      :btnLoading="btnLoading"
      :dialogStatus="addContactModel"
    >
      <div slot="modalCont" style="padding-top:20px;">
        <el-form
          ref="form"
          :model="formData"
          label-width="auto"
          :rules="createUserRules"
        >
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="姓名" prop="contact">
                <el-input
                  placeholder="请输入姓名"
                  v-model="formData.contact"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">身份</div>
                <el-select
                  v-model="formData.isKp"
                  placeholder="请选择"
                  style="width: 100%;"
                >
                  <el-option label="关键人" value="1"></el-option>
                  <el-option label="中间人" value="2"></el-option>
                  <el-option label="普通" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">部门</div>
                <el-input
                  placeholder="请输入部门"
                  v-model="formData.department"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">职位</div>
                <el-input
                  placeholder="请输入职位"
                  v-model="formData.position"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="mobile">
                <div slot="label" style="margin-left:10px;">手机</div>
                <el-input
                  placeholder="请输入手机号码"
                  v-model.trim="formData.mobile"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">座机</div>
                <el-input
                  placeholder="请输入座机号码"
                  v-model.trim="formData.landLine"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">微信号</div>
                <el-input
                  placeholder="请输入微信号"
                  style="ime-mode:disabled"
                  onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
                  onpaste="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
                  oncontextmenu="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
                  v-model="formData.wechat"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">邮箱</div>
                <el-input
                  placeholder="请输入邮箱"
                  v-model="formData.email"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24" v-if="false">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">状态</div>
                <el-select
                  v-model="formData.mark"
                  placeholder="请选择状态"
                  style="width: 100%"
                >
                  <el-option
                    :label="_item.name"
                    :value="_item.value"
                    v-for="_item in markDescList"
                    :key="_item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">备注</div>
                <el-input
                  type="textarea"
                  maxlength="100"
                  show-word-limit
                  placeholder="请输入备注"
                  v-model="formData.remark"
                  :autosize="{ minRows: 2, maxRows: 10 }"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </dia-model>

    <dia-model
      ref="deleteMark"
      :dialogStatus="dialogDelStatus"
      @submitContBtn="submitDelBtn"
    >
      <div slot="modalCont" style="margin: 20px 0;">
        确认删除 ？
      </div>
    </dia-model>

    <dia-model
      @submitContBtn="transContactBtn"
      class="pond-form-input"
      ref="tranfContact"
      :dialogStatus="tranfContactModel"
    >
      <div class="tranf-main" slot="modalCont">
        <div class="mark" v-if="!isGeted && phoneList.length">
          <el-button size="small" type="primary" @click="clickLq"
            >领取</el-button
          >
        </div>
        <div class="no-data-tr" v-if="!phoneList.length">
          暂无联系人
        </div>
        <el-scrollbar
          :style="{ filter: isGeted ? '' : 'blur(3px)' }"
          class="dt-dropdown-dt scroll"
          v-if="phoneList.length"
        >
          <el-row :gutter="20">
            <el-col
              :span="12"
              v-for="(item, index) in phoneList"
              :key="index"
              style="margin-bottom: 20px"
            >
              <div class="phone-item">
                <div>
                  <el-checkbox
                    class="check-box-dt"
                    :value="item.selected"
                    @change="contactCheck(item)"
                    :disabled="phoneListFilter.includes(item.phone)"
                  >
                    {{ item.phone }} {{ item.contact || '' }}
                    <span style="color:#41CABF;">{{
                      phoneListFilter.includes(item.phone) ? '(已转入)' : ''
                    }}</span>
                  </el-checkbox>
                </div>
                <div>
                  <el-tag
                    style="margin-right: 5px"
                    size="mini"
                    v-for="item in showTelStatus(item)"
                  >
                    {{ item }}
                  </el-tag>
                </div>
                <div class="follow-source">
                  来源：<span
                    :class="_item.url ? 'active-url' : 'url'"
                    @click="jumpToSource(_item.url)"
                    v-for="_item in item.fromToUrl"
                    >{{ _item.from }}</span
                  >
                </div>
              </div>
            </el-col>
          </el-row>
        </el-scrollbar>
      </div>
    </dia-model>
    <dia-model
      @submitContBtn="editContactBtn"
      class="pond-form-input"
      ref="editContact"
      :btnLoading="btnLoading"
      :dialogStatus="editContactModel"
    >
      <div slot="modalCont" style="padding-top:20px;">
        <el-form
          ref="formedit"
          :model="formData"
          label-width="auto"
          :rules="createUserRules"
        >
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="姓名" prop="contact">
                <el-input
                  placeholder="请输入姓名"
                  v-model="formData.contact"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">身份</div>
                <el-select
                  v-model="formData.isKp"
                  placeholder="请选择"
                  style="width: 100%;"
                >
                  <el-option label="关键人" value="1"></el-option>
                  <el-option label="中间人" value="2"></el-option>
                  <el-option label="普通" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">部门</div>
                <el-input
                  placeholder="请输入部门"
                  v-model="formData.department"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">职位</div>
                <el-input
                  placeholder="请输入职位"
                  v-model="formData.position"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="mobile">
                <div slot="label" style="margin-left:10px;">手机</div>
                <el-input
                  placeholder="请输入手机号码"
                  v-model.trim="formData.mobile"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">座机</div>
                <el-input
                  placeholder="请输入座机号码"
                  v-model.trim="formData.landLine"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">微信号</div>
                <el-input
                  placeholder="请输入微信号"
                  style="ime-mode:disabled"
                  onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
                  onpaste="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
                  oncontextmenu="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
                  v-model="formData.wechat"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">邮箱</div>
                <el-input
                  placeholder="请输入邮箱"
                  v-model="formData.email"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="false">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">状态</div>
                <el-select
                  v-model="formData.mark"
                  placeholder="请选择状态"
                  style="width: 100%"
                >
                  <el-option
                    :label="_item.name"
                    :value="_item.value"
                    v-for="_item in markDescList"
                    :key="_item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">备注</div>
                <el-input
                  type="textarea"
                  placeholder="请输入备注"
                  v-model="formData.remark"
                  maxlength="100"
                  show-word-limit
                  :autosize="{ minRows: 2, maxRows: 10 }"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </dia-model>
  </div>
</template>

<script>
import {
  coustomerAdd,
  coustomerDel,
  coustomerEdit,
  coustomerList
} from '@/api/user'
import { postConSum } from '@/api/subaccount'
import DtScore from '@/views/customerpond/dialog/score'
import diaModel from '@/components/Model'
import { getDetailAsignOpthon, getComDetailModel } from '@/api/thread'
import { isGeted, transContact } from '@/api/customerpond'
export default {
  name: 'contactList',
  components: {
    DtScore,
    diaModel
  },
  data() {
    let validateMobile = (rule, value, fcb) => {
      if (!value) {
        fcb()
      }
      if (
        value &&
        value.toString().length === 11 &&
        value.toString().startsWith('1')
      ) {
        fcb()
      } else {
        fcb(new Error('手机号格式错误'))
      }
    }
    return {
      markDescList: [],
      scoreKey: 0,
      itemList: [
        {
          key: 'score',
          label: '完善度',
          default: 0
        },
        {
          key: 'landLine',
          label: '座机',
          default: ''
        },
        {
          key: 'mobile',
          default: '',
          label: '手机'
        },
        {
          key: 'wechat',
          default: '',
          label: '微信'
        },
        {
          key: 'email',
          default: '',
          label: '邮箱'
        },
        {
          key: 'remark',
          default: '',
          label: '备注'
        },
        {
          key: 'bindWechat',
          default: '未关联',
          label: '企业微信'
        },
        {
          key: 'creater',
          default: '',
          label: '创建人'
        },
        {
          key: 'renew',
          default: '',
          label: '修改人'
        }
      ],
      contactList: [],
      addContactModel: {},
      tranfContactModel: {},
      formData: {},
      btnLoading: false,
      createUserRules: {
        mobile: [{ validator: validateMobile, trigger: 'blur' }],
        contact: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ]
      },
      dialogDelStatus: {},
      editContactModel: {},
      showSelect: true, //是否显示选择box
      currentCheckRow: [], //选择的列表
      currentCheckObj: {},
      loading: false,

      // touch转入联系人
      phoneList: [],
      isGeted: false,
      phoneListFilter: [], // 已经转入的联系人，需要置灰

      type: 'chance',
      isWaihu: '0'
    }
  },
  filters: {
    FormatText: function(val) {
      return val.length > 5 ? `${val.substring(0, 5)}...` : val
    }
  },
  computed: {
    showTelStatus(item) {
      return function(item) {
        const status = {
          ai_can_call: '电话可打通',
          phoneStatus: '空号',
          wuxiao: '无效',
          zhongjie: '疑似中介',
          ai_konghao: 'AI识别为空号',
          ai_tingji: 'AI识别为停机'
        }
        let res = []
        for (let i in status) {
          if (item[i]) {
            res.push(status[i])
          }
        }
        return res
      }
    },

    permissionList() {
      return this.$store.state.permission.permission
    }
  },

  props: {
    companyUuid: {
      type: String,
      default: ''
    },
    companyName: {
      type: String,
      default: ''
    },
    packageId: {
      type: [String, Number],
      default: ''
    },
    isActiveTab: {
      type: [String, Number],
      default: '1'
    },
    currentCheckRowProp: {
      type: [Object, Array],
      default: () => {
        return null
      }
    }
  },

  mounted() {
    let accountInfo = localStorage.getItem('accountInfo')
      ? JSON.parse(localStorage.getItem('accountInfo'))
      : {}
    this.isWaihu = accountInfo.isWaihu

    if (this.currentCheckRowProp) {
      this.currentCheckRowProp.forEach(item => {
        this.currentCheckObj[item.id] = item
      })
    }

    this.getContactList()
    this.getDetailAsignOpthon()
  },
  methods: {
    showErrorTip(data, showTip) {
      return
      if (!showTip) return
      let tip = ''
      if (!data.contact) tip = '联系人不能为空'
      if (!data.position) tip = '职位不能为空'
      if (!data.landLine && !data.mobile) tip = '座机和手机号不能同时为空'
      this.$store.dispatch('showTip', {
        text: tip,
        type: 'warning'
      })
    },

    jumpToSource(url) {
      if (url) {
        window.open(url)
      }
    },

    call(phone, contact, companyName) {
      console.log(222111)
      this.$refs.phonestr.callUp(phone, contact, companyName)
    },
    getDetailAsignOpthon() {
      getDetailAsignOpthon().then(res => {
        this.markDescList = res.data
      })
    },

    // 关闭弹框
    cancelBtn() {
      this.$emit('closeDialog')
    },

    confirmBtn() {
      this.$emit('getRes', Object.values(this.currentCheckObj))
    },

    selectChange(item) {
      const id = item.id
      const selected = item.selected
      // if(this.type === 'contract'){
      //   this.contactList.forEach((_item)=>{
      //     if (_item.id === id){
      //       selected ? _item.selected = false : _item.selected = true
      //       if(selected){
      //         _item.selected = false
      //         // 删除数据
      //         delete this.currentCheckObj[id]
      //       }else {
      //         _item.selected = true
      //         this.currentCheckObj[id] = _item
      //       }
      //     }
      //   })
      // }else {
      //   if(!this.currentCheckObj[id]){
      //     this.currentCheckObj = {}
      //     this.contactList.forEach(item=>{
      //       if (item.id === id){
      //         item.selected = true
      //         this.currentCheckObj[id] = item
      //       }else{
      //         item.selected = false
      //       }
      //     })
      //   }
      // }

      this.contactList.forEach(_item => {
        if (_item.id === id) {
          selected ? (_item.selected = false) : (_item.selected = true)
          if (selected) {
            _item.selected = false
            // 删除数据
            delete this.currentCheckObj[id]
          } else {
            _item.selected = true
            this.currentCheckObj[id] = _item
          }
        }
      })
      this.$forceUpdate()
    },

    // 删除以选中的联系人
    closeTag(item) {
      this.selectChange(item)
    },

    getContactList() {
      const params = {
        companyUuid: this.companyUuid
      }
      this.loading = true
      coustomerList(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.scoreKey += 1
          this.contactList = res.data != null ? res.data : []
          this.contactList.forEach(item => {
            if (this.currentCheckObj[item.id]) {
              item['selected'] = true
            } else {
              item['selected'] = false
            }
            // 更新以选中的数据
            if (this.showSelect && this.currentCheckObj[item.id]) {
              this.currentCheckObj[item.id] = item
            }
          })
          this.$forceUpdate()
        }
      })
    },
    addContactBtn() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.formData.wechat) {
            this.formData.wechat = this.formData.wechat.replace(
              /[\u4E00-\u9FA5]/g,
              ''
            )
          }
          if (!this.formData.contact) {
            this.$message({
              message: '请输入姓名',
              type: 'error'
            })
            return
          }
          const params = {
            companyUuid: this.companyUuid,
            contact: this.formData.contact.replace(/(^\s*)|(\s*$)/g, ''),
            mobile: this.formData.mobile.replace(/(^\s*)|(\s*$)/g, ''),
            landLine: this.formData.landLine.replace(/(^\s*)|(\s*$)/g, ''),
            position: this.formData.position.replace(/(^\s*)|(\s*$)/g, ''),
            department: this.formData.department.replace(/(^\s*)|(\s*$)/g, ''),
            remark: this.formData.remark.replace(/(^\s*)|(\s*$)/g, ''),
            wechat: this.formData.wechat.replace(/(^\s*)|(\s*$)/g, ''),
            email: this.formData.email.replace(/(^\s*)|(\s*$)/g, ''),
            isKp: this.formData.isKp,
            mark: this.formData.mark
          }
          this.btnLoading = true
          coustomerAdd(params).then(res => {
            this.btnLoading = false
            if (res.code === 60000) {
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: 'success'
              })
              this.getContactList()
              this.$refs.addContact.dialogVisible = false
            }
          })
        }
      })
    },

    // 转入联系人
    tranfContact() {
      let phoneList = []
      this.contactList.forEach(item => {
        if (item.mobile) {
          phoneList.push(item.mobile)
        }
        if (item.landLine) {
          phoneList.push(item.landLine)
        }
      })
      this.phoneListFilter = phoneList

      // 判断是否领取isGeted
      isGeted({ companyUuid: this.companyUuid }).then(res => {
        if (res.code === 60000) {
          this.isGeted = res.data
        }
      })
      const params = {
        module: 'touch',
        packageId: this.packageId || '0',
        companyUuid: this.companyUuid
      }
      // 查看touch模块联系人
      getComDetailModel(params)
        .then(res => {
          if (res.code === 60000) {
            if (res.data && res.data['电话']) {
              this.phoneList = res.data['电话']['list'].map(item => {
                item['selected'] = false
                return item
              })
            } else {
              this.phoneList = []
            }
            this.tranfContactModel = {
              dialogWidth: '800px',
              dialogTitle: '转入联系人',
              dialogTop: '250px',
              showFoot: true,
              singleBtn: false,
              dialogSurBtn: '确认'
            }
            this.$refs.tranfContact.dialogVisible = true
          } else {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'error'
            })
          }
        })
        .catch(() => {
          this.$store.dispatch('showTip', {
            text: '出错了',
            type: 'error'
          })
        })
    },
    contactCheck(e) {
      this.phoneList.forEach(item => {
        if (item.id === e.id) {
          item.selected = !item.selected
        }
      })
    },

    // 转入联系人
    transContactBtn() {
      let data = this.phoneList.filter(item => item.selected)
      data = data.map(_item => {
        return {
          contact: _item.contact,
          mobile: _item.phone
        }
      })
      if (!data.length) {
        this.$store.dispatch('showTip', {
          text: '请选择联系人',
          type: 'error'
        })
        return false
      }
      const params = {
        companyUuid: this.companyUuid,
        contactJson: JSON.stringify(data)
      }
      transContact(params)
        .then(res => {
          this.$refs.tranfContact.dialogVisible = false
          if (res.code === 60000) {
            this.getContactList()
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
          } else {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'error'
            })
          }
        })
        .catch(res => {
          this.$store.dispatch('showTip', {
            text: '转入失败',
            type: 'error'
          })
        })
    },

    // 领取
    clickLq() {
      const params = {
        packageId: this.packageId,
        companyUuid: this.companyUuid
      }
      postConSum(params).then(res => {
        if (res.code === 60000 && res.data) {
          this.isGeted = true

          this.$store.commit('update', {
            target: this.$store.state.plugin.modal.options,
            data: {
              refresh: true
            }
          })

          params['module'] = 'touch'
          getComDetailModel(params).then(_res => {
            if (_res.code === 60000) {
              if (_res.data && _res.data['电话']) {
                this.phoneList = _res.data['电话']['list'].map(__item => {
                  __item['selected'] = false
                  return __item
                })
              } else {
                this.phoneList = []
              }
            }
          })
        }
      })
    },

    addContactDia() {
      this.formData = {
        contact: '',
        mobile: '',
        landLine: '', //座机
        position: '',
        department: '',
        remark: '',
        wechat: '',
        email: '',
        isKp: '',
        mark: ''
      }
      this.addContactModel = {
        dialogWidth: '800px',
        dialogTitle: '新建联系人',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$refs.addContact.dialogVisible = true
    },
    deleteCard(item) {
      this.$store.dispatch('showMessageBox', {
        type: 'warning',
        text: `确认删除  ${item.contact}`,
        title: '提示',
        onClose: res => {
          if (res === 'confirm') {
            this.submitDelBtn(item.id)
          }
        }
      })
    },
    submitDelBtn(id) {
      coustomerDel({ id: id }).then(res => {
        if (res.code === 60000) {
          if (this.currentCheckObj[id]) {
            delete this.currentCheckObj[id]
          }
          this.getContactList()
          this.$refs.deleteMark.dialogVisible = false
        }
      })
    },
    editContactDia(item) {
      this.formData = { ...item }
      this.formData.isKp = this.formData.isKp ? this.formData.isKp + '' : ''
      this.editContactModel = {
        dialogWidth: '800px',
        dialogTitle: '编辑联系人',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$refs.editContact.dialogVisible = true
    },
    editContactBtn() {
      this.$refs['formedit'].validate(valid => {
        if (valid) {
          if (this.formData.wechat) {
            this.formData.wechat = this.formData.wechat.replace(
              /[\u4E00-\u9FA5]/g,
              ''
            )
          }
          this.btnLoading = true
          coustomerEdit(this.formData).then(res => {
            this.btnLoading = false
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
            if (res.code === 60000) {
              this.getContactList()
              this.$refs.editContact.dialogVisible = false
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.contact-item-header {
  .contact-name {
    .item {
      span {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
<style scoped>
.body {
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.01);
}
.header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header > div:first-child {
  font-size: 15px;
  font-weight: 700;
}
.main {
  height: calc(100% - 50px);
  width: 100%;
}
.contact-item {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #fff;
}
.contact-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 100%;
}
.contact-item-li {
  height: 30px;
  width: 100%;
  line-height: 30px;
  display: flex;
  align-items: center;
}
.contact-item-li span {
  font-size: 12px;
}
.label {
  color: #909399;
  width: 70px;
  /*text-align: left;*/
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
  position: relative;
  padding-right: 10px;
  box-sizing: border-box;
}
.label::after {
  content: ':';
  display: block;
  height: 100%;
  position: absolute;
  right: 3px;
  top: 0;
}
.value {
  color: rgba(0, 0, 0, 0.8);
}
.contact-name {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.contact-icon i {
  cursor: pointer;
  color: #41cabf;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #909399;
}
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: calc(100% - 50px);
}
.not-allowed-icon {
  color: rgba(0, 0, 0, 0.2) !important;
  cursor: not-allowed !important;
}
.btn {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.add-tip {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
.btn::before {
  content: '';
  display: block;
  height: 1px;
  width: calc(100% + 40px);
  position: absolute;
  top: 0;
  left: -20px;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
}
.tranf-main {
  height: 300px;
  width: 100%;
  position: relative;
}
.scroll {
  height: 100%;
  width: 100%;
}
.no-data-tr {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
}
.phone-item {
  height: 100px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}
.show-tip::before {
  content: '已转入';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #ffffff;
  background-color: #41cabf;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
}
.tel-status {
  color: #41cabf;
  margin-left: 5px;
  font-size: 13px;
}
.check-box-dt ::v-deep .el-checkbox__label {
  color: #606266 !important;
}
.icon-tip {
  font-weight: bold;
  font-size: 12px;
}
.callicon {
  display: inline-block;
  position: relative;
  top: 5px;
  width: 20px;
  height: 20px;
  background: url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/tel-sys.svg')
    no-repeat center center;
  background-size: 100% 100%;
  /*background-color: #1abc9c;*/
  border-radius: 5px;
  cursor: pointer;
}
.active-url {
  color: #0099cc !important;
  cursor: pointer;
}
.follow-source > span {
  position: relative;
  margin-right: 7px;
}
.follow-source > span:before {
  content: '';
  height: 12px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: -4px;
  top: 55%;
  transform: translateY(-50%);
  display: inline-block;
}
.follow-source > span:last-child:before {
  display: none;
}
</style>
