var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "article-page" }, [
    _c("div", { staticClass: "wrap" }, [
      _c(
        "div",
        { staticClass: "fl" },
        [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("PeSelect", {
                staticStyle: { width: "150px" },
                attrs: {
                  selectSize: "mini",
                  optionsList: _vm.optionsList,
                  defaultSelect: _vm.gptLabel,
                },
                on: { handleSelectChange: _vm.handleSelectChange },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleOpen },
                },
                [_vm._v("新建对话")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "h-list" },
            _vm._l(_vm.historyList, function (item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  class: _vm.currentIndex == index ? "active h-item" : "h-item",
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(item, index)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "first" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: item.gptTypeIcon },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "title f-item" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "result" }, [
                    _vm._v(_vm._s(item.result)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn-delete" },
                    [
                      _c(
                        "el-popconfirm",
                        {
                          attrs: { title: "删除后无法回复,是否删除" },
                          on: {
                            confirm: function ($event) {
                              return _vm.handleDelete(item)
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", size: "mini" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleDelStop.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              slot: "reference",
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { width: "30%", "margin-top": "10px" },
            attrs: {
              background: "",
              layout: "prev, pager, next",
              total: _vm.pePage.total,
              "page-size": _vm.pePage.pageSize,
            },
            on: { "current-change": _vm.handlePageIndex },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fr" },
        [
          _vm.info.id
            ? [
                _vm.info.title
                  ? _c("div", { staticClass: "panel" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.info.title)),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "panel" }, [
                  _c(
                    "div",
                    { ref: "scroll", staticClass: "t-list" },
                    _vm._l(_vm.aiList, function (item, index) {
                      return _c("div", { key: index, staticClass: "t-item" }, [
                        _c(
                          "div",
                          { staticClass: "it" },
                          [
                            _c("div", { staticClass: "first" }, [
                              _c("img", {
                                staticClass: "icon",
                                attrs: { src: item.avatarUrl },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.nickName))]),
                              _vm._v(" "),
                              item.role == "user"
                                ? _c("span", [_vm._v(_vm._s(item.content))])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            item.role == "assistant"
                              ? [
                                  _c("div", { staticClass: "result" }, [
                                    _vm._v(_vm._s(item.content)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "copy",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCopy(item.content)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/pe-img/copy.png"),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("复制")]),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            : _c("div", { staticClass: "robot" }, [
                _c("div", { staticClass: "r-top" }, [
                  _c("img", { attrs: { src: _vm.askIcon } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "hello" }, [
                    _vm._v("你好,我是AI助手"),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "typewriter",
            domProps: { innerHTML: _vm._s(_vm.renderResult) },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "panel r-panel" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("请输入指令")]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { "margin-top": "10px", "margin-bottom": "10px" },
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "可通过回车快捷提交，使用shift+回车换行",
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleEnter.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.textarea,
                  callback: function ($$v) {
                    _vm.textarea = $$v
                  },
                  expression: "textarea",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v("生成")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "desc" }, [
      _c("div", { staticClass: "zs" }),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n            作为你的智能伙伴，我既能写文案、想点子，又能陪你聊天、答疑解惑\n          "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }