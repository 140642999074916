<template>
  <div class="record-main">
    <div class="header">
      <div class="name-tag">
        <span class="name">
          {{ userData.contact }}
        </span>
        <span class="position" v-show="userData.position">
          {{ userData.position }}
        </span>
      </div>
      <div class="cname" v-if="userData.companyName">
        {{ userData.companyName }}
      </div>
    </div>

    <el-scrollbar
      class="main-record dt-dropdown-dt"
      :style="{
        height: permissionEditRecord
          ? 'calc( 100vh - 290px )'
          : 'calc( 100vh - 80px )'
      }"
    >
      <div
        class="main-row"
        v-loading="loading"
        v-if="permissionViewRecord"
        :style="{
          minHeight: permissionEditRecord
            ? 'calc( 100vh - 290px )'
            : 'calc( 100vh - 80px )'
        }"
      >
        <div v-if="pondCond && pondCond.length" class="time-line">
          <el-timeline v-for="item in pondCond" style="margin-bottom: 20px">
            <div class="time-header">
              <span class="time-week">{{ item.week }}</span
              ><span class="time-day">{{ item.day }}</span>
            </div>
            <el-timeline-item
              placement="top"
              v-for="follow in item.follows"
              :color="item.week === '今天' ? '#FF0000' : ''"
            >
              <div class="follow-time">
                {{ follow.time }}
              </div>
              <div class="right">
                <div class="record-name-type">
                  <span class="record-name">{{ follow.follower }}</span>
                  <span class="dot"></span>
                  <span class="record-type">{{ follow.type }}</span>
                </div>
                <div class="record-text">
                  <el-row>
                    <el-col :span="3" class="text" v-if="false">记录：</el-col>
                    <el-col :span="24" class="text text-bg">{{
                      follow.records
                    }}</el-col>
                  </el-row>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="empty" v-else>
          暂无数据
        </div>
      </div>
    </el-scrollbar>

    <div class="input" v-if="permissionEditRecord">
      <el-form
        :model="formData"
        label-width="auto"
        size="small"
        :rules="rules"
        ref="form"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="跟进方式" prop="type">
              <el-select
                v-model="formData.type"
                placeholder="请选择跟进方式"
                style="width: 100%"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跟进时间" prop="time">
              <el-date-picker
                style="width: 100%"
                v-model="formData.time"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4 }"
                placeholder="请输入内容"
                v-model="formData.text"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-item">
              <div style="display: flex;justify-content: flex-end">
                <el-button size="small" type="primary" @click="submit"
                  >提交</el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getFollowType, postFollowSaveRecords } from '@/api/table'
import { getCusFollowRecords } from '@/api/customerpond'

import moment from 'moment'

export default {
  name: 'record',
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      pondCond: [],
      loading: false,
      typeList: [],
      userData: {},
      majorId: null,
      formData: {
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: '',
        text: ''
      },
      rules: {
        type: [{ required: true, message: '请选择跟进方式', trigger: 'change' }]
      },
      tabType: ''
    }
  },
  mounted() {
    this.userData = this.$store.state.plugin.modal.options.userData
    this.tabType = this.$store.state.plugin.modal.options.type
    this.getFollowType()
    this.getRecord()
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    },

    permissionViewRecord() {
      if (this.tabType === '1') {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-我负责的-查看跟进记录'
        )
      }
      if (this.tabType === '2') {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-可跟进的-查看跟进记录'
        )
      }
      if (this.tabType === '3') {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-全公司的-查看跟进记录'
        )
      }
      if (this.tabType === '4') {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-草稿箱-查看跟进记录'
        )
      }
    },

    permissionEditRecord() {
      if (this.tabType === '1') {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-我负责的-填写跟进记录'
        )
      }
      if (this.tabType === '2') {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-可跟进的-填写跟进记录'
        )
      }
      if (this.tabType === '3') {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-全公司的-填写跟进记录'
        )
      }
      if (this.tabType === '4') {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-草稿箱-填写跟进记录'
        )
      }
    }
  },
  methods: {
    getRecord() {
      const params = {
        companyUuid: this.userData.companyUuid,
        contactId: this.userData.id
      }
      this.loading = true
      getCusFollowRecords(params).then(res => {
        this.loading = false
        if (res.code === 60000 && res.data) {
          this.pondCond = res.data
        }
      })
    },

    getFollowType() {
      getFollowType({ type: 'contactManage' }).then(res => {
        if (res.code === 60000 && res.data) {
          this.typeList = res.data
        }
      })
    },

    submit() {
      this.$refs['form'].validate(valid => {
        if (!valid) return
        const params = {
          id: this.userData.customerId, // 企业id
          followType: this.typeList.filter(
            item => item.value === this.formData.type
          )[0].name,
          followRecords: this.formData.text,
          date: this.formData.time,
          ids: this.userData.id
        }
        postFollowSaveRecords(params).then(res => {
          if (res.code === 60000) {
            this.$message({
              message: '提交成功！',
              type: 'success'
            })
            this.formData.text = ''
            this.formData.time = moment().format('YYYY-MM-DD HH:mm:ss')
            this.getRecord()
          }
        })
      })
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.record-main {
  height: 100%;
}
.header {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}
/*.header::before{*/
/*  content: '';*/
/*  display: inline-block;*/
/*  height: 1px;*/
/*  width: 100%;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  transform: scaleY(.5);*/
/*  position: absolute;*/
/*  background-color: rgba(0,0,0,.1);*/
/*}*/
.name-tag {
  display: flex;
  align-items: center;
}
.name {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}
.position {
  padding: 5px 10px;
  box-sizing: border-box;
  display: inline-block;
  background-color: #e7fffd;
  color: #41cabf;
  font-size: 12px;
  border-radius: 5px;
  margin-left: 10px;
}
.cname {
  color: #959595;
  font-size: 14px;
}
.main-record {
  height: calc(100vh - 290px);
  width: 100%;
}
.main-row {
  width: calc(100% - 40px);
  min-height: calc(100vh - 290px);
  height: auto;
  margin: 0 auto;
  position: relative;
}
.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #8c939d;
  font-size: 14px;
}
.input {
  height: 210px;
  overflow-y: auto;
  position: relative;
  padding: 10px 20px;
  box-sizing: border-box;
}

/*.input::before{*/
/*  content: '';*/
/*  display: inline-block;*/
/*  height: 1px;*/
/*  width: 100%;*/
/*  top: 0;*/
/*  left: 0;*/
/*  transform: scaleY(.5);*/
/*  position: absolute;*/
/*  background-color: rgba(0,0,0,.1);*/
/*}*/

.form-item {
  margin-bottom: 0 !important;
}

.time-line {
  padding: 20px 0 20px 80px;
  box-sizing: border-box;
}
.time-day,
.follow-time,
.record-name-type,
.record-text {
  font-size: 12px;
}
.record-text {
  color: #999999;
  margin-top: 20px;
}
.time-header {
  padding: 10px 0;
  box-sizing: border-box;
  transform: translate(-80px, -10px);
  position: relative;
}
.time-day {
  position: absolute;
  left: 108px;
  top: 50%;
  transform: translateY(-50%);
}
.time-week {
  font-size: 16px;
}
.follow-time {
  transform: translate(-110px, -10px);
}
.text {
  line-height: 150%;
  text-align: justify;
  padding: 5px;
  box-sizing: border-box;
}
.text-bg {
  /*background-color: rgba(0,0,0, .025);*/
}
.right {
  transform: translateY(-20px);
}
.dot {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 20px;
  transform: translateY(-5px);
}
.dot::before {
  content: '';
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #333333;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
