var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ai-addr-page" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "fl-panel",
          attrs: { "element-loading-text": "AI计算中..." },
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "AI推荐地址", name: "addr" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "fix-desc" }, [
                _vm._v("人口数为3公里内"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list" },
            [
              _vm.recommendList.length
                ? _vm._l(_vm.recommendList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(`${index + 1}.${item.name}`)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "address" }, [
                        _vm._v("地址:" + _vm._s(item.address)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "people" }, [
                        _c("span", [
                          _vm._v("工作人口:" + _vm._s(item.workPeopleCnt)),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("居住人口:" + _vm._s(item.livePeopleCnt)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "rate" },
                        [
                          _c("span", [_vm._v("评分:")]),
                          _vm._v(" "),
                          _c("el-rate", {
                            attrs: {
                              disabled: "",
                              "show-score": "",
                              "text-color": "#ff9900",
                              "score-template": "{value}",
                            },
                            model: {
                              value: item.rating,
                              callback: function ($$v) {
                                _vm.$set(item, "rating", $$v)
                              },
                              expression: "item.rating",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btn" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            },
                            [_vm._v("已入住商家")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAi(item)
                                },
                              },
                            },
                            [_vm._v("AI测评")]
                          ),
                        ],
                        1
                      ),
                    ])
                  })
                : _c("NoData"),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "map" },
        [
          _c("Map", {
            ref: "map",
            attrs: { mapLocate: _vm.location },
            on: { getLocateSucc: _vm.getLocateSucc },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "fr-panel" }, [
        _c("div", { staticClass: "tab" }, [
          _c("div", { staticClass: "title" }, [_vm._v("选址位置")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", { staticClass: "desc" }, [_vm._v("省份")]),
              _vm._v(" "),
              _c("PeSelect", {
                attrs: {
                  defaultSelect: _vm.params.province,
                  optionsList: _vm.provinceList,
                },
                on: { handleSelectChange: _vm.handleProvinceChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", { staticClass: "desc" }, [_vm._v("城市")]),
              _vm._v(" "),
              _c("PeSelect", {
                attrs: {
                  optionsList: _vm.cityList,
                  defaultSelect: _vm.params.city,
                },
                on: { handleSelectChange: _vm.handleCityChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", { staticClass: "desc" }, [_vm._v("区域")]),
              _vm._v(" "),
              _c("PeSelect", {
                attrs: {
                  defaultSelect: _vm.params.district,
                  optionsList: _vm.districtList,
                },
                on: { handleSelectChange: _vm.handleDistrictChange },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tab" }, [
          _c("div", { staticClass: "title" }, [_vm._v("行业")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", { staticClass: "desc" }, [_vm._v("一级")]),
              _vm._v(" "),
              _c("PeSelect", {
                attrs: {
                  defaultSelect: _vm.indusData.first,
                  optionsList: _vm.oneList,
                },
                on: { handleSelectChange: _vm.handleOneChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", { staticClass: "desc" }, [_vm._v("二级")]),
              _vm._v(" "),
              _c("PeSelect", {
                attrs: {
                  optionsList: _vm.secondList,
                  defaultSelect: _vm.indusData.two,
                },
                on: { handleSelectChange: _vm.handleSecondChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", { staticClass: "desc" }, [_vm._v("三级")]),
              _vm._v(" "),
              _c("PeSelect", {
                attrs: {
                  optionsList: _vm.threeList,
                  defaultSelect: _vm.indusData.three,
                },
                on: { handleSelectChange: _vm.handleThreeChange },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("商圈偏好")]),
            _vm._v(" "),
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.params.businessCircle,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "businessCircle", $$v)
                  },
                  expression: "params.businessCircle",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "0" } }, [
                  _vm._v("不限"),
                ]),
                _vm._v(" "),
                _c("el-radio-button", { attrs: { label: "1" } }, [
                  _vm._v("商圈"),
                ]),
                _vm._v(" "),
                _c("el-radio-button", { attrs: { label: "2" } }, [
                  _vm._v("商圈外"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tab" }, [
          _c("div", { staticClass: "title" }, [_vm._v("客户画像")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "people" },
            [
              _c("span", [_vm._v("性别")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.params.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "sex", $$v)
                    },
                    expression: "params.sex",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v("不限"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("男"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("女"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "people" },
            [
              _c("span", [_vm._v("年龄")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.params.ageStage,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "ageStage", $$v)
                    },
                    expression: "params.ageStage",
                  },
                },
                _vm._l(_vm.ageList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.value } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.name) + "\n          "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("人口偏好")]),
            _vm._v(" "),
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.params.peopleType,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "peopleType", $$v)
                  },
                  expression: "params.peopleType",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "0" } }, [
                  _vm._v("不限"),
                ]),
                _vm._v(" "),
                _c("el-radio-button", { attrs: { label: "1" } }, [
                  _vm._v("工作人口优先"),
                ]),
                _vm._v(" "),
                _c("el-radio-button", { attrs: { label: "2" } }, [
                  _vm._v("居住人口优先"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sure-btn" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  loading: _vm.loading,
                  size: "medium",
                  type: "primary",
                },
                on: { click: _vm.handleSure },
              },
              [_vm._v("开始智能选址")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "PeDraw",
        {
          attrs: { peDrawerShow: _vm.isShow },
          on: {
            handleDrawClose: function ($event) {
              _vm.isShow = false
            },
          },
        },
        [
          _c("div", { staticClass: "draw-shop-wrap" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.currentFloor)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search" },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入关键字搜索", clearable: "" },
                  model: {
                    value: _vm.floorSearch,
                    callback: function ($$v) {
                      _vm.floorSearch = $$v
                    },
                    expression: "floorSearch",
                  },
                }),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.handleInquire } }, [
                  _vm._v("查询"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "wrap" }, [
              _c(
                "div",
                { staticClass: "tab-wrap" },
                _vm._l(_vm.allFloor, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.floor,
                      class: _vm.currentIndex == index ? "active tab" : "tab",
                      on: {
                        click: function ($event) {
                          return _vm.handleTabClick(item, index)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.floor) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "classify" },
                [
                  _c(
                    "div",
                    { staticClass: "classify-list" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          on: { input: _vm.handleClass },
                          model: {
                            value: _vm.shopBigTypeCode,
                            callback: function ($$v) {
                              _vm.shopBigTypeCode = $$v
                            },
                            expression: "shopBigTypeCode",
                          },
                        },
                        _vm._l(_vm.classList, function (it, idx) {
                          return _c(
                            "el-radio-button",
                            { key: idx, attrs: { label: it.code } },
                            [_vm._v(_vm._s(_vm.handleClassTitle(it)))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-scrollbar",
                    { ref: "scrollbar", staticClass: "scroll-bar" },
                    [
                      _vm.storeList.length
                        ? _c(
                            "div",
                            { staticClass: "list" },
                            _vm._l(_vm.storeList, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "it" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "img-wrap" },
                                    [
                                      item.mallLogo
                                        ? _c("img", {
                                            staticClass: "img",
                                            attrs: { src: item.mallLogo },
                                          })
                                        : _c("Icon", {
                                            attrs: {
                                              type: "img",
                                              peClass: "brand_store",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "desc" }, [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "address" }, [
                                      _vm._v(_vm._s(item.address)),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("NoData"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }