var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "update-container" },
    [
      _c("Header"),
      _vm._v(" "),
      _c("div", { staticClass: "reser-title" }, [_vm._v("修改密码")]),
      _vm._v(" "),
      _c("div", { staticClass: "update-form" }, [
        _c("p", [_vm._v("请输入您的新密码，以进行密码重设")]),
        _vm._v(" "),
        _c("div", { staticClass: "reset-code" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.validCode,
                expression: "validCode",
              },
            ],
            class: _vm.error.errorCode ? "input-error" : "",
            attrs: {
              oninput: "value=value.replace(/[^\\d]/g,'')",
              placeholder: "验证码",
              type: "text",
              maxlength: "8",
            },
            domProps: { value: _vm.validCode },
            on: {
              change: _vm.codeChangeLa,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.validCode = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "getCodeBtn",
              staticStyle: { "text-align": "center" },
              on: { click: _vm.getPhoneCode },
            },
            [_vm._v("\n        " + _vm._s(_vm.getCodeNum) + "\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formInfo.newPwd,
              expression: "formInfo.newPwd",
            },
          ],
          staticClass: "update-input-phone",
          class: _vm.error.newPwd ? "input-error" : "",
          attrs: {
            type: "password",
            placeholder: "设置密码长度为6-20位，英文和数字和!@#$%^&*()_-组合",
            maxlength: "20",
          },
          domProps: { value: _vm.formInfo.newPwd },
          on: {
            blur: _vm.checkPswFirst,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.formInfo, "newPwd", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formInfo.newPwd2,
              expression: "formInfo.newPwd2",
            },
          ],
          staticClass: "update-code",
          class: _vm.error.newPwd2 ? "input-error" : "",
          attrs: {
            type: "password",
            oninput: "value=value.replace(/[\\W]/g,'')",
            placeholder: "确认密码",
            maxlength: "20",
          },
          domProps: { value: _vm.formInfo.newPwd2 },
          on: {
            blur: _vm.checkTwoPaw,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.formInfo, "newPwd2", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.errorMsg
          ? _c("p", { attrs: { id: "error" } }, [_vm._v(_vm._s(_vm.errorMsg))])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "update-bt",
            on: { click: _vm.updateBt },
          },
          [_vm._v("确认")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }