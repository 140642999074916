var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "VmiScroll-main", style: { height: _vm.scrollHeight } },
    [
      _c("el-scrollbar", { ref: "scrollbar", staticClass: "scroll-main" }, [
        _vm.subscribeScroll
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "infinite-scroll",
                    rawName: "v-infinite-scroll",
                    value: _vm.load,
                    expression: "load",
                  },
                ],
                staticClass: "slot-div",
                style: {
                  margin: "0 auto",
                  width: `calc( 100% - ${_vm.margin * 2}px )`,
                },
                attrs: {
                  "infinite-scroll-delay": 500,
                  "infinite-scroll-immediate": false,
                },
              },
              [_vm._t("default")],
              2
            )
          : _c(
              "div",
              {
                staticClass: "slot-div",
                style: {
                  margin: "0 auto",
                  width: `calc( 100% - ${_vm.margin * 2}px )`,
                },
              },
              [_vm._t("default")],
              2
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }