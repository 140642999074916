<template>
  <div class="screen-dialog" v-if="showScreen">
    <div class="screen-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScreenDialog',
  props: {
    showScreen: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.screen-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 444;
  .screen-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 620px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
</style>
