<template>
  <div class="user-manual-page">
    <div class="desc">
      <div class="item">1、访问领鸟AI，系统很卡，是什么原因？</div>
      <div class="item">
        下载测试工具到电脑桌面，双击后，截图发给领鸟客服或者销售顾问。工程师将根据截图显示的测试结果判断原因。
      </div>
      <el-button @click="handleDownload">文件下载</el-button>
      <img src="" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserManual',
  data() {
    return {}
  },
  mounted() {},
  methods: {
    handleDownload() {
      const url = 'https://web-app.dtbird.cn/files/user_self_check/speed.cmd'
      const fileName = '测网速'
      const el = document.createElement('a')
      el.style.display = 'none'
      el.setAttribute('target', '_blank')
      /**
       * download的属性是HTML5新增的属性
       * href属性的地址必须是非跨域的地址，如果引用的是第三方的网站或者说是前后端分离的项目(调用后台的接口)，这时download就会不起作用。
       * 此时，如果是下载浏览器无法解析的文件，例如.exe,.xlsx..那么浏览器会自动下载，但是如果使用浏览器可以解析的文件，比如.txt,.png,.pdf....浏览器就会采取预览模式
       * 所以，对于.txt,.png,.pdf等的预览功能我们就可以直接不设置download属性(前提是后端响应头的Content-Type: application/octet-stream，如果为application/pdf浏览器则会判断文件为 pdf ，自动执行预览的策略)
       */
      fileName && el.setAttribute('download', fileName)
      el.href = url
      console.log(el)
      document.body.appendChild(el)
      el.click()
      document.body.removeChild(el)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.user-manual-page {
  background-color: $used-color;
  margin: 10px 0 0 10px;
  .desc {
    line-height: 30px;
  }
}
</style>
