<template>
  <div class="body">
    <div>
      <el-form
        ref="form"
        :model="formData"
        label-width="auto"
        :rules="rules"
        :validate-on-rule-change="false"
        size="small"
        style="padding-right:15px; box-sizing: border-box;height: 400px;width: 100%;overflow-y:auto"
      >
        <el-row :gutter="10">
          <el-col :span="24">
            <el-row>
              <el-col :span="24">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="负责人">
                      <el-select
                        v-model="formData.userId"
                        :disabled="type == 'globCreate'"
                        placeholder="请选择负责人"
                        style="width: 100%"
                      >
                        <el-option
                          :label="item.name"
                          :value="item.id"
                          v-for="item in leaderList"
                          :key="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="协作人">
                      <template v-if="type == 'edit'">
                        <el-select
                          multiple
                          filterable
                          v-model="formData.cooperationUsers"
                          :disabled="type == 'globCreate'"
                          placeholder="请选择协作人"
                          style="width: 100%"
                        >
                          <el-option
                            :label="item.name"
                            :value="item.name"
                            v-for="item in leaderList"
                            :key="item.id"
                          >
                          </el-option>
                        </el-select>
                      </template>
                      <template v-else>
                        <el-select
                          multiple
                          filterable
                          v-model="formData.cooperationUserId"
                          :disabled="type == 'globCreate'"
                          placeholder="请选择协作人"
                          style="width: 100%"
                        >
                          <el-option
                            :label="item.name"
                            :value="item.id"
                            v-for="item in leaderList"
                            :key="item.id"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-form-item>
                    <el-form-item
                      label="退回理由"
                      v-if="
                        formData.userId == -1 &&
                          activeTabs != 2 &&
                          activeTabs != 4 &&
                          type == 'edit'
                      "
                      :required="
                        formData.userId == -1 &&
                          activeTabs != 2 &&
                          activeTabs != 4 &&
                          type == 'edit'
                      "
                      prop="returnType"
                    >
                      <el-select
                        v-model="formData.returnType"
                        placeholder="请选择退回理由"
                        style="width: 100%"
                      >
                        <el-option
                          :label="item.name"
                          :value="item.id"
                          v-for="item in allMessage"
                          :key="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="客户名称" prop="companyName">
                      <el-input
                        v-model="formData.companyName"
                        :disabled="type == 'globCreate' || disabled()"
                        placeholder="请输入客户名称"
                      >
                        <span
                          slot="suffix"
                          class="viewC"
                          v-if="!disabled()"
                          @click="checkName"
                        >
                          查重
                        </span>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="省市">
                      <el-select
                        v-model="formData.province"
                        @change="getAllCity"
                        placeholder="请选择省份"
                        style="width: 45%;box-sizing: border-box"
                      >
                        <el-option
                          :label="item.name"
                          :value="item.name"
                          v-for="item in allProvince"
                          :key="item.name"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-model="formData.city"
                        placeholder="请选择城市"
                        style="width: 45%;float: right"
                      >
                        <el-option
                          :label="item"
                          :value="item"
                          v-for="item in allCity"
                          :key="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="客户来源">
                      <el-select
                        v-model="formData.fromId"
                        :disabled="type == 'globCreate'"
                        placeholder="请选择客户来源"
                        style="width: 100%"
                      >
                        <el-option
                          :label="item.name"
                          :value="item.id"
                          v-for="item in allSource"
                          :key="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="线索等级">
                      <el-select
                        v-model="formData.clueGradeId"
                        placeholder="请选择线索等级"
                        style="width: 100%"
                      >
                        <el-option
                          :label="item.name"
                          :value="item.id"
                          v-for="item in allClue"
                          :key="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="客户销售团队规模"
                      prop="saleRange"
                      v-if="isLn"
                    >
                      <el-select
                        v-model="formData.saleRange"
                        placeholder="请选择客户销售团队规模"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in saleRangeList"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="客户等级">
                      <el-select
                        v-model="formData.gradeId"
                        placeholder="请选择客户等级"
                        style="width: 100%"
                      >
                        <el-option
                          :label="item.name"
                          :value="item.id"
                          v-for="item in allLevel"
                          :key="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="状态">
                      <el-select
                        v-model="formData.stateId"
                        placeholder="请选择客户状态"
                        style="width: 100%"
                      >
                        <el-option
                          :label="item.name"
                          :value="item.id"
                          v-for="item in allStatus"
                          :key="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label="企业标签">
                      <el-select
                        v-model="selectTags"
                        @change="selectChange"
                        ref="selectTag"
                        multiple
                        placeholder="请选择标签"
                        style="width: 100%"
                      >
                        <el-option-group
                          v-for="item in allPerTags"
                          :label="item.name"
                        >
                          <el-option
                            v-for="it in item.tags"
                            :key="it.id"
                            :label="it.name"
                            :value="it.id"
                          >
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </el-form-item>

                    <el-form-item label="个人标签">
                      <el-select
                        :multiple="personalPerTagsType"
                        v-model="personalSelectTag"
                        @change="selectChange"
                        ref="selectTag1"
                        placeholder="请选择标签"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in perAllPerTags"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      label="客户销售模式"
                      prop="saleModel"
                      v-if="isLn"
                    >
                      <el-select
                        v-model="formData.saleModel"
                        multiple
                        placeholder="请选择请选择客户销售模式"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in saleModelList"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="备注">
                      <el-input
                        v-model="formData.remark"
                        placeholder="请输入备注"
                        type="textarea"
                        maxlength="100"
                        show-word-limit
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <div class="select-targer">
            <span class="target">
              自定义字段
            </span>
            <span @click="setTarget" v-if="isAdmin">
              设置
            </span>
          </div>

          <el-col :span="24">
            <el-row>
              <el-col :span="12" v-for="item in targetList">
                <el-form-item :label="item.name">
                  <template v-if="item.type === 0">
                    <el-input
                      v-model="target[item.field]"
                      :placeholder="`请输入${item.name}`"
                    ></el-input>
                  </template>
                  <template v-if="item.type === 1">
                    <el-select
                      v-model="target[item.field]"
                      style="width: 100%"
                      :placeholder="`请选择${item.name}`"
                    >
                      <el-option
                        v-for="item in item.filters"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </template>
                  <template v-if="item.type === 2">
                    <el-date-picker
                      :editable="false"
                      style="width: 100%"
                      v-model="target[item.field]"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :placeholder="`请选择${item.name}`"
                    >
                    </el-date-picker>
                  </template>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>

          <div class="select" v-if="type === 'create' || type === 'globCreate'">
            <span @click="formData.haveContact = !formData.haveContact">
              联系人信息
            </span>
          </div>
          <el-col
            :span="24"
            v-if="
              formData.haveContact &&
                (type === 'create' || type === 'globCreate')
            "
          >
            <el-row>
              <el-col :span="24">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="姓名" prop="contact">
                      <el-input
                        v-model="formData.contact"
                        placeholder="请输入姓名"
                        :disabled="!formData.haveContact"
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>

                    <el-form-item>
                      <div slot="label" style="margin-left:10px;">身份</div>
                      <el-select
                        v-model="formData.isKp"
                        placeholder="请选择"
                        style="width: 100%;"
                      >
                        <el-option label="关键人" value="1"></el-option>
                        <el-option label="中间人" value="2"></el-option>
                        <el-option label="普通" value="3"></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label="部门">
                      <el-input
                        v-model="formData.department"
                        placeholder="请输入部门"
                        :disabled="!formData.haveContact"
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>

                    <el-form-item label="职位">
                      <el-input
                        v-model="formData.position"
                        placeholder="请输入职位"
                        :disabled="!formData.haveContact"
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="手机">
                      <el-input
                        v-model="formData.mobile"
                        placeholder="请输入手机号码"
                        :disabled="!formData.haveContact"
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>

                    <el-form-item label="座机">
                      <el-input
                        v-model="formData.landLine"
                        placeholder="请输入座机号码"
                        :disabled="!formData.haveContact"
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>

                    <el-form-item label="微信">
                      <el-input
                        v-model="formData.wechat"
                        placeholder="请输入微信"
                        :disabled="!formData.haveContact"
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>

                    <el-form-item label="邮箱">
                      <el-input
                        v-model="formData.email"
                        placeholder="请输入邮箱"
                        :disabled="!formData.haveContact"
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="备注">
                      <el-input
                        v-model="formData.contactRemark"
                        placeholder="请输入备注"
                        :disabled="!formData.haveContact"
                        type="textarea"
                        maxlength="100"
                        show-word-limit
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div class="footer">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="saveInput"
          v-loading="loading"
          >确认</el-button
        >
      </div>
    </div>

    <el-dialog
      class="el-dialog-dt"
      :visible.sync="showCheckRe"
      :modal="true"
      :width="'650px'"
      :top="'90px'"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <div slot="title" class="title">
        客户查重
      </div>
      <component
        v-bind:is="searchCompanyRep"
        :name="formData.companyName"
      ></component>
    </el-dialog>

    <DtDialog
      append-to-body
      :close-on-click-modal="false"
      dialog-width="800px"
      :top="'100px'"
      ref="dt-dialog"
      title="设置自定义字段"
    >
      <targetSetting
        ref="target"
        @closePop="closePop"
        @confirmPop="confirmPop"
      />
    </DtDialog>
  </div>
</template>

<script>
import searchCompanyRep from '@/views/customerpond/dialog/searchCompanyRep'
import DtScroll from '@/components/element/DtScroll'
import DtDialog from '@/components/element/DtDialog'
import targetSetting from '@/views/customerpond/dialog/targetSetting'

import {
  customize,
  postCreateCusInfo,
  putCustomerInfo
} from '@/api/customerpond'
import debounce from 'lodash/debounce'
import { getTagsList, getAddress, getAddress2, getMode } from '@/api/user'
import { getAccountInfo } from '@/api/usercenter'

export default {
  name: 'createCustomer',
  components: {
    DtScroll,
    DtDialog,
    targetSetting
  },
  data() {
    return {
      targetList: [],
      target: {},
      isLn: false,
      searchCompanyRep: searchCompanyRep,
      type: 'create',
      loading: false,
      formData: {
        userId: '', // 负责人id
        returnType: '', //退回原因
        companyName: '', //公司名称
        remark: '', // 备注
        gradeId: '', // 客户等级
        fromId: '', //来源id
        stateId: '', // 状态id
        clueGradeId: '', //线索等级Id
        haveContact: false, // 是否添加联系人信息
        contact: '', // 联系人
        isKp: '',
        mobile: '', //手机号
        landLine: '', // 座机号
        position: '', //职位
        wechat: '', //微信
        email: '', //邮箱
        department: '', //部门
        contactRemark: '', //联系人备注
        tagsId: '', //组合标签id
        customerId: '', //客户id
        province: '',
        city: '',
        saleRange: '',
        saleModel: []
      },
      saleRangeList: [],
      saleModelList: [],
      leaderList: [], //负责人列表
      allSource: [], //来源leaderList
      allProvince: [],
      allCity: [],
      allClue: [], //线索等级
      allLevel: [], //客户等级
      allStatus: [], //状态
      allPerTags: [], // 自定义标签
      perAllPerTags: [],
      perTagsType: true, //标签选择模式，false为单选，true为多选
      personalPerTagsType: true,
      allMessage: [], //退回理由
      activeTabs: '',
      selectTags: [],
      personalSelectTag: [],

      rules: {
        companyName: [
          { required: true, message: '请输入客户名称', trigger: 'blur' }
        ],
        returnType: [
          { required: true, message: '请选择退回理由', trigger: 'change' }
        ]
      },
      showCheckRe: false,
      searchName: '',
      packageId: ''
    }
  },
  watch: {
    'formData.haveContact'(val) {
      this.$refs['form'].clearValidate()
      if (val) {
        let validateMobile = (rule, value, fcb) => {
          if (!value) {
            fcb()
          }
          if (
            value &&
            value.toString().length === 11 &&
            value.toString().startsWith('1')
          ) {
            fcb()
          } else {
            fcb(new Error('手机号格式错误'))
          }
        }
        this.rules = {
          companyName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' }
          ],
          contact: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          mobile: [{ validator: validateMobile, trigger: 'blur' }]
        }
      } else {
        this.rules = {
          companyName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' }
          ]
        }
      }
    }
  },
  created() {
    getMode({ type: 'XS_01' }).then(res => {
      if (res.code === 60000) {
        this.saleRangeList = res.data['XS_01']
      }
    })
    getMode({ type: 'XS_MODEL_01' }).then(res => {
      if (res.code === 60000) {
        this.saleModelList = res.data['XS_MODEL_01']
      }
    })
  },
  mounted() {
    const modalOptions = this.$store.state.plugin.modal.options
    console.log(modalOptions, 112233)
    this.getCustomize(modalOptions.customizeField || {})
    getAccountInfo().then(res => {
      if (res.code === 60000) {
        this.isLn = res.data.versionNum === 'LN001'
      }
    })
    this.getTags().then(res => {
      console.log(this.perTagsType, 'u8wye7i86a7dgashjdfghj')
      if (this.perTagsType) {
        this.selectTags = modalOptions.selectTags || []
        console.log(this.selectTags)
      } else {
        this.selectTags = modalOptions.selectTags[0] || ''
      }

      if (this.personalPerTagsType) {
        this.personalSelectTag = modalOptions.selectedPrivateTags || []
      } else {
        this.personalSelectTag = modalOptions.selectedPrivateTags[0] || ''
      }
    })
    this.leaderList = modalOptions.leaderList.filter(i => {
      if (modalOptions.activeTabs != 2) {
        return i.id != -2
      } else {
        return i
      }
    })
    this.allSource = modalOptions.allSource
    this.allClue = modalOptions.allClue
    this.allLevel = modalOptions.allLevel
    this.allStatus = modalOptions.allStatus
    this.allPerTags = modalOptions.allPerTags
    this.perAllPerTags = modalOptions.perAllPerTags
    this.allMessage = modalOptions.allMessage
    this.getProvinceOption(1, 0).then(arr => {
      this.allProvince = arr
      if (modalOptions.type == 'edit' && modalOptions.formData.province) {
        this.formData.province = modalOptions.formData.province

        this.getCityOption(this.formData.province).then(arr => {
          this.allCity = arr
          if (modalOptions.city) {
            this.formData.city = modalOptions.formData.city
          }
        })
      }
    })
    console.log(this.selectTags)
    this.type = modalOptions.type || 'create'

    // if(!formData.userId){
    //   formData.userId = -1
    // }
    console.log(modalOptions)
    this.activeTabs = modalOptions.activeTabs
    if (this.type != 'edit') {
      this.formData.userId = modalOptions.userId
    }
    if (this.activeTabs === '4') {
      // this.formData.userId = -2
    }

    if (!modalOptions.userId && this.type != 'edit') {
      this.formData.userId = -1
    }

    if (modalOptions.hasOwnProperty('detail')) {
      this.formData.companyName = modalOptions.detail.company_name
      let obj = this.allSource.find(i => {
        return i.name == 'DTSales线索'
      })
      this.formData.fromId = obj.id
      this.packageId = modalOptions.packageId
    }

    let formData = modalOptions.formData
    if (this.activeTabs === '4') {
      Object.assign(formData, { userId: -2 })
    }
    this.$store.commit('update', {
      target: this.formData,
      data: formData
    })
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    },
    isAdmin() {
      return this.$store.getters.accountInfo?.role == 'admin'
    }
  },

  methods: {
    getCustomize(data) {
      typeof data === 'string' ? (data = JSON.parse(data)) : data
      customize().then(res => {
        if (res.code === 60000) {
          this.targetList = res?.data || []
          this.targetList.forEach(item => {
            if (!Object.keys(this.target).includes(item.field)) {
              if (item.type !== 1) {
                this.$set(
                  this.target,
                  item.field,
                  data[item.field] ? data[item.field] : item.initVal
                )
              } else {
                this.$set(
                  this.target,
                  item.field,
                  data[item.field] ? data[item.field] : ''
                )
              }
            }
          })
        }
      })
    },

    setTarget() {
      this.$refs['dt-dialog'].showDialog()
      customize().then(res => {
        if (res.code === 60000) {
          this.$refs.target.init(res.data)
        }
      })
    },
    closePop() {
      this.$refs['dt-dialog'].closeDialog()
    },
    confirmPop() {
      // todo 刷新自定义字段
      this.getCustomize()
    },

    getAllCity() {
      this.formData.city = ''
      if (!this.formData.province) {
        return
      }
      this.getCityOption(this.formData.province).then(arr => {
        this.allCity = arr
      })
    },
    getCityOption(name) {
      const params = {
        province: name
      }
      return new Promise((resolve, reject) => {
        getAddress2(params).then(res => {
          if (res.code === 60000) {
            resolve(res.data)
          }
        })
      })
    },
    getProvinceOption(lev, code) {
      const params = {
        level: lev,
        pCode: code
      }
      return new Promise((resolve, reject) => {
        getAddress(params).then(res => {
          if (res.code === 60000) {
            resolve(res.data)
          }
        })
      })
    },
    //查询标签框是否是多选
    async getTags() {
      const params = {
        mark: 1
      }
      await getTagsList(params).then(res => {
        if (res.code === 60000) {
          let obj = res.data.find(i => {
            return i.name === '企业标签'
          })
          if (obj.choice === 1) {
            this.perTagsType = false
          } else {
            this.perTagsType = true
          }

          let obj2 = res.data.find(i => {
            return i.name === '个人标签'
          })
          if (obj2.choice === 1) {
            this.personalPerTagsType = false
          } else {
            this.personalPerTagsType = true
          }
        }
      })
    },
    // select关闭下拉框
    selectChange() {
      // this.$refs['selectTag'].blur()
      this.$refs['selectTag1'].blur()
    },

    // 关闭弹框
    cancel() {
      this.$store.dispatch('hideModal')
    },

    // 是否允许编辑公司名
    disabled() {
      if (this.type === 'create') {
        return false
      }
      if (this.permissionList.includes('客户管理CRM-客户公海-客户名称编辑')) {
        return false
      }
      return true
    },

    // 查重
    checkName() {
      this.showCheckRe = true
    },

    // 提交保存内容
    saveInput: debounce(
      function() {
        if (this.loading) return
        this.$refs['form'].validate(validate => {
          if (!validate) {
            return false
          }
          this.type === 'create' || this.type === 'globCreate'
            ? this.addCustomer()
            : this.editCustomer()
        })
      },
      1000,
      { leading: true, trailing: false }
    ),

    // 编辑客户
    editCustomer() {
      // debugger
      this.loading = true
      let perTags = null
      let personalPerTags = null
      if (this.perTagsType) {
        if (this.selectTags.length > 0) {
          perTags = this.selectTags.join(',')
        }
      } else {
        perTags = this.selectTags
      }

      if (this.personalPerTagsType) {
        if (this.personalSelectTag.length) {
          personalPerTags = this.personalSelectTag.join(',')
        }
      } else {
        personalPerTags = this.personalSelectTag
      }
      const arr = []
      let flag = false
      if (this.formData.cooperationUsers.length > 0) {
        flag = true
      }
      this.leaderList.forEach(item => {
        if (flag) {
          this.formData.cooperationUsers.forEach(ele => {
            if (item.name == ele) {
              arr.push(item.id)
            }
          })
        }
      })
      let params = Object.assign({}, this.formData, {
        cooperationUserId: arr.join(),
        perTags: perTags,
        privateTags: personalPerTags,
        saleModel: this.formData.saleModel.join(','),
        customizeField: JSON.stringify(this.target)
      })
      putCustomerInfo(params)
        .then(res => {
          this.loading = false
          if (res && res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
            // 发送标志 刷新页面
            this.$store.commit('update', {
              target: this.$store.state.plugin.modal.options,
              data: {
                refresh: true
              }
            })
            this.$store.dispatch('hideModal')
          }
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch('showTip', {
            text: '编辑失败',
            type: 'success'
          })
        })
    },

    // 添加客户
    addCustomer() {
      this.loading = true
      let perTags = null
      let personalPerTags = null

      if (this.selectTags.length > 0) {
        perTags = this.selectTags.join(',')
      }

      if (this.personalPerTagsType) {
        if (this.personalSelectTag.length) {
          personalPerTags = this.personalSelectTag.join(',')
        }
      } else {
        personalPerTags = this.personalSelectTag
      }

      if (this.formData.cooperationUserId) {
        this.formData.cooperationUserId
      }
      let params = Object.assign({}, this.formData, {
        cooperationUserId: this.formData.cooperationUserId.join(),
        perTags: perTags,
        privateTags: personalPerTags,
        saleModel: this.formData.saleModel.join(','),
        customizeField: JSON.stringify(this.target)
      })
      if (this.packageId) {
        params = Object.assign(
          {},
          this.formData,
          { packageId: this.packageId },
          {
            perTags: perTags,
            privateTags: personalPerTags,
            customizeField: JSON.stringify(this.target)
          }
        )
      }
      // console.log(params)
      // return
      postCreateCusInfo(params)
        .then(res => {
          this.loading = false
          if (res && res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
            this.$store.commit('update', {
              target: this.$store.state.plugin.modal.options,
              data: {
                refresh: true
              }
            })
            this.$store.dispatch('hideModal')
          }
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch('showTip', {
            text: '添加失败',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style scoped>
.body {
  width: 100%;
  /*height: auto;*/
  padding: 10px;
  box-sizing: border-box;
}

.footer {
  width: 100%;
  display: flex;
  padding-top: 10px;
  box-sizing: border-box;
  justify-content: flex-end;
  border-top: 1px solid #e7e7e7;
}

.select,
.select-targer {
  padding-left: 90px;
  box-sizing: border-box;
  color: #41cabf;
  margin: 20px 0;
}

.select-targer > span:last-child {
  cursor: pointer;
  margin-left: 20px;
  color: #0099cc;
}

.select > span {
  cursor: pointer;
  text-decoration-line: underline;
  color: #41cabf;
}

.viewC {
  cursor: pointer;
  color: #41cabf;
}

.title {
  height: 50px;
  width: 100%;
  background-color: #41cabf;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.el-dialog-dt ::v-deep .el-dialog__header,
.el-drawer-dt ::v-deep .el-drawer__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}

.el-drawer-dt ::v-deep .el-drawer__header {
  margin-bottom: 0 !important;
}

.el-dialog-dt ::v-deep .el-dialog__close,
.el-drawer-dt ::v-deep .el-drawer__close {
  color: #fff;
}

.el-dialog-dt ::v-deep .el-dialog__body,
.el-drawer-dt ::v-deep .el-drawer__body {
  padding: 0 !important;
}
</style>
