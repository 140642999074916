<template>
  <div class="pro-main">
    <div class="btn-list">
      <div>
        <el-radio-group v-model="selectData" size="small" @change="changeRadio">
          <el-radio-button :label="item.value" v-for="item in btnList">{{ item.name }}</el-radio-button>
        </el-radio-group>
      </div>

      <div style="margin-left: 10px">
        <el-date-picker
          @change="getData"
          size="small"
          v-model="selectTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>

      <div style="margin-left: 10px">

<!--        <el-select v-model="selectUser" multiple filterable placeholder="请选择员工" size="small" @change="getData">-->
<!--          <el-option-group v-if="group in userList" :key="group.label" :label="group.label">-->
<!--            <el-option-->
<!--              v-for="item in group.options"-->
<!--              :key="item.id"-->
<!--              :label="item.name"-->
<!--              :value="item.id">-->
<!--            </el-option>-->
<!--          </el-option-group>-->
<!--        </el-select>-->

        <el-select v-model="selectUser" placeholder="请选择员工" multiple filterable size="small" @change="getData">
          <el-option-group
            v-for="group in userList"
            :key="group.label"
            :label="group.label">
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>

      </div>
    </div>
    <div class="main-row" v-dt-permission="'销售过程分析-触达过程分析-查看'">
      <div class="main-data">
        <div class="chart">
          <div class="chart-btn">
            <div>
              <i @click="changeChartType('bar')" :class="chartType === 'bar' ? 'active-histogram' : 'default-histogram' "/>
              <i @click="changeChartType('circle')" :class="chartType === 'circle' ? 'active-pie' : 'default-pie' "/>
            </div>
            <el-select v-model="selectType" size="small" @change="getData">
              <el-option
                v-for="item in selectTypeList"
                :key="item.name"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
          </div>
          <div class="chart-row" id="chart-row"></div>
        </div>

        <div class="z-center">
          <div style="margin-left: 20px">
            <el-select v-model="dataType" size="small" @change="getData">
              <el-option
                v-for="item in dataTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="table" style="padding: 0 20px;box-sizing: border-box">
          <el-table
            @sort-change="sortChange"
            :data="tableData"
            show-summary
            size="small" border>
            <el-table-column
              :sortable="item.type === 'sort' ? 'custom' : false"
              v-for="item in columns"
              :label="item.name"
              :prop="item.field" header-align="left">
              <template slot-scope="scope">
                <div class="input-row" v-if="item.format === 'RMB'">
                  {{ scope.row[item.field] | formatValue }}
                </div>
                <span v-else>
                  {{ scope.row[item.field] || 0 }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts'

import { dataPOptions, processTouch } from '@/api/workTable'

export default {
  name: 'pro',
  data () {
    return {
      chart: null,
      chartType: 'bar',

      selectData: 'm',
      selectTime: [],
      selectUser: [],
      dataType: '1',
      selectType: '电销接听',
      sort: '',
      sortOrder: '',
      tableData: [],
      columns: [],
      colorList:['#3a98ff','#7ecbe7','#37c4c4','#79dab6','#49c46a','#a3da79','#face37','#e79d6b','#f05c72'],

      dataTypeList: [
        {
          name: '按客户数',
          value: '1'
        },
        {
          name: '按次数',
          value: '2'
        }
      ],

      btnList: [
        {
          name: '今天',
          value: 'n'
        },
        {
          name: '昨天',
          value: 'y'
        },
        {
          name: '最近七天',
          value: 's'
        },
        {
          name: '最近三十天',
          value: 't'
        },
        {
          name: '本月',
          value: 'm'
        },
        {
          name: '本季度',
          value: 'q'
        },
        {
          name: '全部',
          value: 'a'
        },
      ],
      userList: [],
      selectTypeList: [
        {
          id: 1,
          name: '电销接听'
        },
        {
          id: 2,
          name: '跟进客户'
        },
        {
          id: 3,
          name: '产品演示'
        },
        {
          id: 4,
          name: '当面拜访'
        },
        {
          id: 5,
          name: '报备商机'
        },
      ]
    }
  },
  mounted () {
    this.getUserList()
    let cache = localStorage.getItem('pro-cache')
    if(cache){
      cache = JSON.parse(cache)
      this.selectData = cache.selectData
      this.selectTime = cache.selectTime
      this.selectUser = cache.selectUser
      this.selectType = cache.selectType
      this.dataType =  cache.dataType
      this.sort = cache.sort
      this.sortOrder = cache.sortOrder
    }
    this.formatTime()
    window.onresize = () => {
      this.chart ? this.chart.resize() : ''
    }
  },
  filters: {
    formatValue(val){
      const value = isNaN(parseInt(val)) ? 0 : parseInt(val)
      return value.toLocaleString()
    }
  },
  computed: {},
  methods: {

    sortChange(data){
      const field = this.columns.filter(i => i.field === data.prop)[0]
      const order = data.order
      const map = {
        ascending: 'asc',
        descending: 'desc'
      }
      this.sort = field.alias ? field.alias : field.field
      this.sortOrder = map[order]
      this.getData()
    },

    getUserList(){
      dataPOptions({ pageId:14001, isDemo: 0  }).then(res => {
        if(res.code === 60000){
          this.userList = [{
            label: '在职',
            options: res.data.filter( i => i.active === 1)
          }, {
            label: '离职',
            options: res.data.filter( i => i.active !== 1).map(item => {
              item.name += '(离职)'
              return item
            })
          }]
        }
      })
    },

    changeRadio(){
      this.formatTime()
    },

    formatTime(){
      if(this.selectData === 'n'){
        this.selectTime = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      }
      if(this.selectData === 'y'){
        this.selectTime = [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      }
      if(this.selectData === 's'){
        this.selectTime = [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      }
      if(this.selectData === 't'){
        this.selectTime = [moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      }
      if(this.selectData === 'm'){
        this.selectTime = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      }
      if(this.selectData === 'q'){
        this.selectTime = [moment().startOf('quarter').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      }
      if(this.selectData === 'a'){
        this.selectTime = ['', moment().format('YYYY-MM-DD')]
      }
      this.getData()
    },

    setCache(){
      const cache = {
        selectData: this.selectData,
        selectUser: this.selectUser,
        selectType: this.selectType,
        dataType: this.dataType,
        sort: this.sort,
        sortOrder: this.sortOrder,
      }
      localStorage.setItem('pro-cache', JSON.stringify(cache))
    },

    getData(){
      const params = {
        type: this.dataType,
        pdas: this.selectUser.join(','),
        pageId: 14001,
        endDate: this.selectTime[1],
        startDate: this.selectTime[0],
        sort: this.sort,
        sortOrder: this.sortOrder
      }
      processTouch(params).then(res => {
        if(res.code === 60000){
          this.setCache()
          this.drawChart(res.data)
          this.drawTable(res.data)
        }
      })
    },

    drawChart(data){
      if(!this.chart){
        const chart = document.getElementById('chart-row')
        this.chart = echarts.init(chart)
        if(this.chartType === 'bar') this.drawBar(data)
        if(this.chartType === 'circle') this.drawCircle(data)
      }else {
        if(this.chartType === 'bar') this.drawBar(data)
        if(this.chartType === 'circle') this.drawCircle(data)
      }
    },

    drawCircle (data) {
      let option = {
        tooltip: {
          trigger: 'item'
        },
        color: this.colorList,
        series: [
          {
            name: this.selectType,
            type: 'pie',
            radius: '50%',
            data: this.getChartCircleData(data),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label:{
              normal: {
                formatter: (e)=>{
                  if(e.value){
                    let newStr = " ";
                    let start,end;
                    let name_len = `${ e.name }${e.value}(${e.percent}%)`.length;
                    let max_name = 4;
                    let new_row = Math.ceil(name_len / max_name);
                    if(name_len > max_name){
                      for(let i=0; i<new_row; i++){
                        let old = '';
                        start = i * max_name;
                        end = start + max_name;
                        if(i === new_row - 1){
                          old = e.name.substring(start);
                        }else{
                          old = e.name.substring(start,end) + "\n";
                        }
                        newStr += old;
                      }
                    }else{
                      newStr = e.name;
                    }
                    return `${ newStr }${e.value}(${e.percent}%)`
                  }
                  return `${ e.name }(0)`
                },
                textStyle : {
                  fontWeight : 'normal',
                  fontSize : 12,
                  align:"center",
                  baseline:"middle",
                }
              }
            },
          }
        ],
      };
      this.chart.setOption(option, true)
    },

    drawBar(data){
      let option = {
        tooltip: {
          show: true
        },
        color: ['#41cabf'],
        xAxis: {
          type: 'category',
          data: this.getChartData(data).map(i => i.name),
          axisLabel:{
            interval: 'auto',
            rotate:45,
          }
        },
        dataZoom: [{
          type: 'inside'
        }, {
          type: 'slider'
        }],
        grid: {
          containLabel: true,
          left: '100px',
          right: '100px',
          bottom: '3%',
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: this.getChartData(data).map(i => i.value),
          type: 'bar',
          barMaxWidth: 50,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                color: '#000000'
              }
            }
          }
        }]
      }
      this.chart.setOption(option, true)
    },

    changeChartType(type){
      this.chartType = type
      this.getData()
    },

    getChartData(data){
      const target = data.columns.filter(i => i.name === this.selectType)[0]['field']
      return  data.list.map(item => {
        return {
          name: item.followName,
          value: isNaN(parseInt(item[target])) ? 0 : parseInt(item[target])
        }
      }).sort((a, b) => b.value - a.value)

    },

    getChartCircleData(data){
      const target = data.columns.filter(i => i.name === this.selectType)[0]['field']
      return data.list.map(item => {
        let value = isNaN(parseInt(item[target])) ? 0 : parseInt(item[target])
        return {
          name: item.followName,
          value: value,
          labelLine: { show: !!value },
          label: { show: !!value }
        }
      })
    },

    drawTable(data){
      this.columns = data.columns
      this.tableData = data.list
    },
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.pro-main{
  height: 100%;
}
.main-row{
  height: calc( 100% - 60px );
  overflow-y: auto;
}
.btn-list{
  margin-bottom: 10px;
  height: 50px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}
.main-data{
  min-height: calc( 100% - 60px );
  height: auto;
  background-color: #fff;
}
.chart{
  height: 500px;
  width: 100%;
}
.chart-btn{
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  box-sizing: border-box;
}
.chart-row{
  height: 450px;
  width: calc( 100% - 20px );
}
.z-center{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.default-histogram{
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url("../../assets/call_shell.svg") no-repeat center center;
}

.active-histogram{
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url("../../assets/call_shell_active.svg") no-repeat center center;

}
.default-pie{
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url("../../assets/call_cake.svg") no-repeat center center;
}

.active-pie{
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url("../../assets/call_cake_active.svg") no-repeat center center;
}
.input-row{
  padding-left: 22px;
  box-sizing: border-box;
  background: url("../../assets/rmb.svg") no-repeat left center;
  background-size: 20px 20px;
}

</style>
