var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "software-main" },
    [
      _c("DtScroll", { attrs: { margin: "0" } }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("软件企业洞察")]),
            _vm._v(" "),
            _vm.IsAccess == 0 || _vm.IsAccess == 1
              ? _c(
                  "span",
                  {
                    staticClass: "normal-data",
                    on: { click: _vm.handleNormalData },
                  },
                  [_vm._v(_vm._s(_vm.$updateText))]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter" },
            [
              _c("SoftwareFilter", {
                attrs: { softwareFlag: _vm.softwareFlag, count: _vm.count },
                on: { selectChange: _vm.selectChange },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "main-row" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.tabClick },
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "数据明细", name: "2" } }, [
                  _c(
                    "div",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c(
                        "el-badge",
                        {
                          staticClass: "item",
                          attrs: {
                            value:
                              _vm.message.software > 0
                                ? _vm.message.software
                                : "",
                          },
                        },
                        [_c("span", [_vm._v("数据明细")])]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.personalRecommend == 1
                  ? _c(
                      "el-tab-pane",
                      { attrs: { label: "个性化推荐", name: "3" } },
                      [
                        _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                          _c("span", [_vm._v("个性化推荐")]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.activeTab === "2" || _vm.activeTab == "3"
              ? _c("main", [
                  _c(
                    "div",
                    {
                      ref: "keyList",
                      staticClass: "key-list",
                      style: { minHeight: `${_vm.listHeight}px` },
                    },
                    [
                      _c("SoftwareList", {
                        attrs: {
                          activeTab: _vm.activeTab,
                          package: _vm.currentPackage,
                          "min-height": _vm.listHeight,
                          params: _vm.filterOp,
                        },
                        on: {
                          count: _vm.setCount,
                          handleSoftwareReset: _vm.handleSoftwareReset,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }