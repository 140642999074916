<template>
  <div class="productRelationGraph-main">
    <div class="main-graph" ref="myPage">
      <RelationGraph
        ref="seeksRelationGraph"
        :options="graphOptions"
        :on-node-click="onNodeClick"
      >
        <div v-if="node" slot="node" slot-scope="{ node }">
          <div class="icon" @click="testNode(node)">
            {{ node.data.cname }}
          </div>
          <div class="node-class" @click="jumpCompany(node.id)">
            {{ node.data.cname }}
          </div>
        </div>

        <div slot="bottomPanel" class="bottom-panel">
          <span class="current-company">当前公司</span>
          <span class="current-customer">客户</span>
          <span class="current-boss">供应商</span>
        </div>
      </RelationGraph>
    </div>

    <div
      class="touch-no-receive"
      v-if="!otherParams.isReceive && otherParams.shade"
      v-loading="getLoading"
    >
      <div class="touch-no-cont">
        <div class="touch-no-icon">
          <span
            style="color: #008CD9;text-decoration: underline;cursor:pointer;"
            @click="getReveiveKey('phone')"
            >领取</span
          >
          <span>以查看所有信息</span>
        </div>
      </div>
    </div>
    <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showDetail">
      <div slot="modalCont">
        <p v-html="recruitStr" style="padding: 20px 0;"></p>
      </div>
      <div slot="submit">
        <div class="grasp_go_pay">
          <span @click="graspGoPay">关闭</span>
        </div>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import Mixin from './mixin'
import RelationGraph from 'relation-graph'
import { getComDetailModel } from '@/api/thread'
import { throttle } from 'lodash'

export default {
  name: 'cloudList',
  components: {
    diaModel,
    RelationGraph
  },
  mixins: [Mixin],
  data() {
    return {
      showDetail: false,
      graspData: {},
      recruitStr: '',
      graphOptions: {
        allowSwitchLineShape: true,
        allowSwitchJunctionPoint: true,
        defaultJunctionPoint: 'border',
        allowShowMiniToolBar: true,
        defaultNodeShape: 0,
        defaultNodeBorderWidth: 0,
        defaultLineWidth: 3,
        layouts: [
          {
            layoutName: 'force',
            layoutClassName: 'seeks-layout-center',
            defaultExpandHolderPosition: 'hide',
            defaultJunctionPoint: 'border',
            defaultNodeShape: 2,
            defaultLineShape: 2,
            min_per_width: '200',
            min_per_height: '300'
          }
        ]
      },

      isAddId: []
    }
  },
  props: {
    moduleDetail: {
      type: [Array, Object],
      default: () => {
        return []
      }
    },
    otherParams: {
      type: [Array, Object],
      default: () => {
        return {}
      }
    },
    packageId: {
      type: [String, Number],
      default: 0
    }
  },

  created() {
    this.onNodeClick = throttle(
      (data, event) => {
        this._onNodeClick(data, event)
      },
      200,
      { trailing: false }
    )
  },

  mounted() {
    this.$nextTick(() => {
      this.showSeeksGraph(this.moduleDetail)
    })
  },

  computed: {
    isJump() {
      const perList = this.$store.state.permission.permission
      return perList.includes('系统管理-非菜单权限-企业详情页-跳转')
    }
  },

  methods: {
    jumpCompany(uuid) {
      const url = `/#/keydetail/keydetail?companyUuid=${uuid}&packageId=${
        this.packageId
      }&showCompanyNameList=false`
      this.isJump && window.open(url)
    },

    testNode(data) {
      console.log(data)
    },

    graspGoPay() {
      this.showDetail = false
    },

    showSeeksGraph(sourceData) {
      // 查找主节点
      const mainId = sourceData?.rootId
      sourceData?.nodes?.forEach(i => {
        if (i.id === mainId) {
          i.color = '#43a2f1'
          i.borderColor = 'yellow'
          i.height = 150
          i.width = 150
        } else {
          i.height = 80
          i.width = 80
        }
        i.data = {
          cname: i.companyName,
          hot: i.hot,
          csp: i.csp
        }
      })
      this.$refs.seeksRelationGraph.setJsonData(sourceData)
    },

    _onNodeClick(nodeObject, $event) {
      console.log('onNodeClick:', nodeObject)
      const uuid = nodeObject.id
      if (uuid !== this.moduleDetail.rootId && !this.isAddId.includes(uuid)) {
        const params = {
          module: 'productRelationGraph',
          packageId: this.packageId,
          companyUuid: uuid
        }
        getComDetailModel(params).then(res => {
          if (res.code === 60000 && res.data) {
            // 追加数据
            let data = res.data['产业链关系图']
            data.rootId = this.moduleDetail.rootId
            data.nodes?.forEach(i => {
              i.data = {
                cname: i.companyName,
                hot: i.hot,
                csp: i.csp
              }
              i.height = 80
              i.width = 80
            })
            this.addData(data, uuid)
          }
        })
      }
    },

    addData(data, uuid) {
      if (this.isAddId.includes(uuid)) return
      this.isAddId.push(uuid)
      this.$refs.seeksRelationGraph.appendJsonData(data, () => {
        // 修改二级节点颜色
        this.$refs.seeksRelationGraph.getNodeById(uuid).color = '#43a2f1'
        // 刷新节点
        this.$refs.seeksRelationGraph.refresh()
      })
    }
  }
}
</script>

<style scoped>
.main-graph {
  height: 500px;
  width: 100%;
}

.node-class {
  color: forestgreen;
  font-size: 12px;
  position: absolute;
  left: 0;
  bottom: -55px;
  width: 100%;
  min-height: 25px;
  height: auto;
  /*text-align: center;*/
  text-align: left;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-filter {
  filter: blur(1.5px);
}
.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 100;
}

.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}

.touch-no-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-text {
  margin: 20px 0;
  font-size: 15px;
  padding-left: 2em;
  box-sizing: border-box;
}
.bottom-panel span {
  height: 13px;
  display: inline-block;
  margin: 0 15px;
  position: relative;
  color: #8a8a8a;
}
.bottom-panel span:before {
  content: '';
  display: inline-block;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: #41cabf;
  position: absolute;
  left: -15px;
  top: 0;
}
.current-company:before {
  background-color: #43a2f1 !important;
}
.current-customer:before {
  background-color: #e6a23c !important;
}
.current-boss:before {
  background-color: #41cabf !important;
}
</style>
