var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nature-btn-wrapper" },
    [
      _c(
        "el-button",
        {
          attrs: { size: "small", type: "primary" },
          on: { click: _vm.handleNature },
        },
        [_vm._v("加入培育")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { size: "small", type: "primary" },
          on: { click: _vm.handleRemove },
        },
        [_vm._v("移除培育")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { size: "small", type: "primary" },
          on: { click: _vm.handleTransfer },
        },
        [_vm._v("转成客户")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }