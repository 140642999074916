<template>
  <div class="item-main">
    <el-row :gutter="10">
      <el-col :span="8">
        <el-cascader
          filterable
          popper-class="popper-class-el-cascader-menu"
          :key="keyRefresh"
          :options="filterOneSourceData"
          size="small"
          style="width: 100%"
          :props="{
            value: 'filterName',
            label: 'filterName',
            children: 'child',
            expandTrigger: 'hover'
          }"
          v-model="filterOneSelectData"
          @change="changeFilterOne"
        >
        </el-cascader>
      </el-col>

      <el-col :span="4">
        <el-select
          @change="changeFilterTwo"
          v-model="filterTwoSelectData"
          :placeholder="filterTwoTip"
          size="small"
          style="width: 100%;"
        >
          <el-option
            :placeholder="filterTwoTip"
            v-for="item in filterTwoSourceData"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="12">
        <search-item-components
          :key="selectKey"
          :input-type="inputType"
          :drop-data="allData.selectList"
          :tip="allData.filterTips"
          :render-data="filterThreeSelectData"
          ref="threeInputRef"
        >
        </search-item-components>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { heightSearchFilter } from '@/api/thread'
import searchItemComponents from '@/views/search/search-plus/searchComponent/searchItemComponents'
export default {
  name: 'afterSalesSearchItemNew',
  components: {
    searchItemComponents
  },
  data() {
    return {
      filterOneSelectData: [], // 第一个输入框的数据
      filterTwoSelectData: '', // 第二个输入框的数据
      filterThreeSelectData: {}, // 第三个输入框的数据
      options: [],
      selectKey: 0,
      allData: {},
      inputTwoType: '',
      filterTwoSourceData: ['选择'],
      keyRefresh: 0,
      isChange: 0
    }
  },

  methods: {
    // 更改第一个筛选项 联动更改剩余筛选项初始化
    changeFilterOne() {
      this.filterTwoSelectData = '选择'
      this.filterThreeSelectData = {}
      this.isChange += 1
      this.selectKey += 1
    },

    changeFilterTwo() {
      this.selectKey += 1
      this.filterThreeSelectData = {}
    },

    // 父组件通过调用此方法获取筛选条件
    getResult() {
      // 获取第三个筛选框的数据
      let { res, saveData, isOk } = this.$refs.threeInputRef.getData()
      if (!isOk) {
        return { res, saveData, isOk }
      }
      isOk = res && !!res.length && this.filterTwoSelectData
      let _res = {
        words: res,
        category1: this.filterOneSelectData?.[0] || '',
        category2: this.filterOneSelectData?.[1] || '',
        category3: this.filterOneSelectData?.[2] || '',
        operate: this.operate
      }
      saveData['filterTwoSelectData'] = this.filterTwoSelectData
      saveData['filterOneSelectData'] = this.filterOneSelectData
      return { res: _res, saveData, isOk }
    },

    log() {
      console.log('******')
      console.log(this.filterOneSelectData)
      console.log(this.filterTwoSelectData)
      console.log(this.filterThreeSelectData)
      console.log('******')
    }
  },

  props: {
    sourceData: {
      type: [Array, Object],
      default: () => []
    },

    renderData: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    inputType: function() {
      this.selectKey += 1
    },

    // 数据回填
    renderData: {
      handler: function(val) {
        if (val && val.filterThreeSelectData) {
          this.filterOneSelectData = val.filterOneSelectData.filter(i => i)
          this.filterTwoSelectData = val.filterTwoSelectData
          this.filterThreeSelectData = val.filterThreeSelectData
          this.keyRefresh += 1
        }
      },
      immediate: true,
      deep: true
    },
    filterOneSelectData: {
      handler: async function(val) {
        if (val && val.length) {
          let result = ['选择']
          const category1 = val?.[0]
          const category2 = val?.[1]
          const category3 = val?.[2]
          const res = await heightSearchFilter({
            category1,
            category2,
            category3
          })
          if (res.code === 60000 && res.data) {
            // debugger
            this.allData = res.data
            const type = res.data.operators[0]
            this.inputTwoType = type
            if (this.isChange > 0) {
              this.filterTwoSelectData = type === 'normal' ? '包含任一' : '选择'
            } else {
              this.selectKey += 1
            }
            console.log(this.filterOneSelectData)
            if (type === 'normal') {
              if (
                this.filterOneSelectData[0] == '常用' &&
                this.filterOneSelectData[1] == '标签'
              ) {
                result = ['包含任一', '不包含所有']
              } else {
                result = ['包含任一', '不包含所有', '选择']
              }
            }
          }
          this.filterTwoSourceData = result
        }
      },
      deep: true,
      immediate: true
    }
  },

  computed: {
    // 第一个输入框的数据
    filterOneSourceData() {
      return this.sourceData
    },

    inputType() {
      if (this.inputTwoType !== 'normal') return this.inputTwoType

      if (this.filterTwoSelectData === '选择') return 'onlyChoice'
      if (
        this.filterTwoSelectData !== '选择' &&
        !this.allData.selectList.length
      ) {
        return 'input'
      }
      if (
        this.filterTwoSelectData !== '选择' &&
        this.allData.selectList.length
      ) {
        return 'selectMultiple'
      }
    },

    operate() {
      if (this.inputTwoType === 'normal') {
        if (this.filterTwoSelectData === '不包含所有') return 'all_not'
        if (this.filterTwoSelectData === '选择') return 'onlyChoice'
        if (this.filterTwoSelectData === '包含任一') return 'or'
      }
      return this.inputTwoType
    },

    // 第一个输入框的提示数据
    filterOneTip() {
      return '请选择'
    },

    // 第二个输入框的提示数据
    filterTwoTip() {
      return '请选择'
    }
  }
}
</script>

<style scoped>
.el-select-dt-hs /deep/ .el-input__suffix {
  display: none !important;
}
</style>
