<template>
  <div class="JcL-main">
    <el-row :gutter="10" align="middle" type="flex">
      <el-col :span="5">
        <el-select v-if="status === 'edit' " v-model="formData.kpId" placeholder="请选择kp" size="small">
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.contact"
            :value="item.id">
            <div class="se-option">
              <div>{{ item.contact }}</div>
              <div style="display: flex;align-items: center">
                <el-image style="width: 30px; height: 20px" v-if="item.wechatType === 1"  :src="require('../../../assets/weixin3.png')"></el-image>
                <el-image style="width: 30px; height: 20px" v-else-if="item.wechatType === 2"  :src="require('../../../assets/qywx2.png')"></el-image>
              </div>
            </div>
          </el-option>
        </el-select>
        <span v-else> {{  kpId || '-' }}
        </span>
      </el-col>
      <el-col :span="5">
        <el-select v-if="status === 'edit'" v-model="formData.reporterId" placeholder="请选择汇报人" size="small">
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.contact"
            :value="item.id">
            <div class="se-option">
              <div>{{ item.contact }}</div>
              <div style="display: flex;align-items: center">
                <el-image style="width: 30px; height: 20px" v-if="item.wechatType === 1"  :src="require('../../../assets/weixin3.png')"></el-image>
                <el-image style="width: 30px; height: 20px" v-else  :src="require('../../../assets/qywx2.png')"></el-image>
              </div>
            </div>
          </el-option>
        </el-select>
        <span v-else> {{ reporterId || '-' }}</span>
      </el-col>
      <el-col :span="5">
        <el-input v-if="status === 'edit'" v-model="formData.obstacle" placeholder="请输入" size="small"></el-input>
        <span v-else> {{ formData.obstacle || '-' }} </span>
      </el-col>
      <el-col :span="5">
        <el-input v-if="status === 'edit'" v-model="formData.measure" placeholder="请输入" size="small"></el-input>
        <span v-else> {{ formData.measure || '-' }} </span>
      </el-col>
      <el-col :span="4">
        <template v-if="status === 'edit'">
          <i class="el-icon-check" @click="confirmClick"></i>
          <i class="el-icon-close" @click="delClick"></i>
        </template>
        <template v-else>
          <i class="el-icon-edit" @click="editClick"></i>
          <i class="el-icon-close" @click="delClick"></i>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { createOrEditJcl, delJcl  } from '@/api/pool'
export default {
  name: 'JcL',
  data () {
    return {
      status: 'edit',
      formData: {
        kpId: '',
        reporterId: '',
        obstacle: '',
        measure: ''
      }
    }
  },
  props: {
    userList: {
      type: Array,
      default: () => []
    },
    canDel: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: () => {}
    },
    companyUuid: {
      type:String,
      default: ''
    }
  },
  watch: {
    detail: {
      handler: function (val) {
        this.formData.kpId = val.kpId
        this.formData.reporterId = val.reporterId
        this.formData.measure = val.measure
        this.formData.obstacle = val.obstacle
        if (Object.keys(val).length > 1) this.status = 'view'
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {},
  computed: {
    kpId() {
      return this.userList.find(item => item.id === this.formData.kpId)?.contact || ''
    },
    reporterId() {
      return this.userList.find(item => item.id === this.formData.reporterId)?.contact || ''
    }
  },
  methods: {
    confirmClick() {
      createOrEditJcl(Object.assign({}, this.formData, { id: this.detail.id, companyUuid: this.companyUuid})).then(res => {
        if (res.code === 60000) {
          this.status = '!edit'
        }
      })
    },
    editClick() {
      this.status = 'edit'
    },
    delClick() {
      if (this.detail.id) {
        delJcl({ id: this.detail.id }).then(res => {
          if (res.code === 60000) {
            this.$emit('del', this.detail.index)
          }
        })
      } else {
        this.$emit('del', this.detail.index)
      }
    },
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.JcL-main{
  margin-top: 10px;
}
i{
  font-size: 14px;
  /*font-weight: bolder;*/
  color: #41cabf;
  cursor: pointer;
}
span{
  color: #7a7a7a;
  font-size: 12px;
}
.se-option{
  display: flex;
}
</style>
