<template>
  <div class="park-map-info-window" v-loading="loading">
    <div class="name">
      <div class="left">{{ detailDesc.parkName }}</div>
      <div class="right" @click="viewClick('c')">查看入驻企业</div>
    </div>
    <div class="item">
      <div class="label" style="width: 40px">地址：</div>
      <div style="flex: 1">
        <div v-for="i in addressList">{{ i }}</div>
      </div>
    </div>
    <div class="item">
      官网：<span class="website" @click="viewClick('w')">{{
        detailDesc.parkUrl
      }}</span>
    </div>
    <div class="item">电话：{{ detailDesc.telephone }}</div>
    <div class="item">行业：{{ detailDesc.parkIndustry }}</div>
    <div class="item">
      面积：<span v-show="detailDesc.area">{{ detailDesc.area }}亩</span>
    </div>
    <div class="item">
      企业数：<span v-show="detailDesc.companyCounts"
        >{{ detailDesc.companyCounts }}家</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tip from '@/views/insight/tip'
import { parkDetailDesc } from '@/api/customerpond'

export default {
  name: 'infoWindow',
  data() {
    return {
      loading: true,
      detailDesc: {}
    }
  },
  props: {
    infoData: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    infoData: {
      handler: function(data) {
        this.getDetailDesc()
      },
      deep: true
    }
  },
  computed: {
    address() {
      if (this.detailDesc.district) {
        return `${this.detailDesc.province}-${this.detailDesc.city}-${
          this.detailDesc.district
        }`
      } else if (this.detailDesc.city) {
        return `${this.detailDesc.province}-${this.detailDesc.city}`
      } else if (this.detailDesc.province) {
        return `${this.detailDesc.province}`
      } else {
        return ''
      }
    },
    addressList() {
      if (this.address) {
        return [
          this.address,
          ...(this.detailDesc.parkAddress?.split('|') || [])
        ]
      }
      return this.detailDesc.parkAddress?.split('|') || []
    },
    ...mapGetters(['accountInfo']),
    canViewDetail() {
      return !!this.accountInfo.parkInsightDetailsOpen
    }
  },
  methods: {
    getDetailDesc() {
      parkDetailDesc({ parkUuid: this.infoData.parkUuid }).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.detailDesc = res?.data || {}
        }
      })
    },

    viewClick(type) {
      if (type === 'w') {
        let url = this.infoData.url
        if (
          !(
            url.substr(0, 7).toLowerCase() === 'http://' ||
            url.substr(0, 8).toLowerCase() === 'https://'
          )
        ) {
          url = 'http://' + url
        }
        window.open(url)
      } else if (type === 'c') {
        if (!this.canViewDetail) {
          this.$store.dispatch('showModal', {
            title: '提示',
            view: tip,
            size: '600px'
          })
        } else {
          const { href } = this.$router.resolve({
            path: '/insight/park/detail',
            query: { id: this.infoData.parkUuid }
          })
          window.open(href, '_blank')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.park-map-info-window {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  width: 300px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  .left {
    flex: 1;
  }
}

.park-map-info-window > .name {
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.park-map-info-window .right {
  font-size: 12px;
  width: 100px;
  color: cornflowerblue;
  text-align: right;
  cursor: pointer;
}

.park-map-info-window .item {
  line-height: 150%;
  font-size: 12px;
  display: flex;
}

.park-map-info-window .website {
  color: cornflowerblue;
  cursor: pointer;
}
</style>
