<template>
  <div class="visual-case-page" @keydown.enter="handleEnterKey">
    <div class="crumb">可视化案例</div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in visualList"
        :key="index"
        @click="handleDetail(item)"
      >
        <div class="top">
          <div>{{ item.name }}</div>
          <!-- <div>{{ `${item.width} * ${item.height}` }}</div> -->
        </div>

        <img :src="item.abbreviationsImgUrl" class="img-default" />
      </div>
    </div>

    <PeDialog
      :pe-dialog-show="show"
      :pe-full-screen="true"
      :pe-dialog-custom="true"
      @handlePeDialogClose="show = false"
      pe-dialog-class="screen-dialog"
      pe-dialog-title="模板预览"
    >
      <div class="dialog-wrap">
        <el-button
          size="mini"
          class="icon-fl"
          circle
          type="primary"
          plain
          icon="el-icon-arrow-left"
          :disabled="leftDisable"
          @click="handleLeft"
        ></el-button>
        <img :src="currentModule.imgUrl" height="100%" width="95%" />
        <el-button
          size="mini"
          circle
          class="icon-fr"
          type="primary"
          plain
          icon="el-icon-arrow-right"
          :disabled="rightDisable"
          @click="handleRight"
        ></el-button>
      </div>
    </PeDialog>
  </div>
</template>

<script>
import { getVisualTemplate } from '@/api/module'
import PeDialog from '@/components/Peculiar/dialog/index'
export default {
  name: 'VisualTemp',

  data() {
    return {
      visualList: [],
      show: false,
      currentModule: {},
      currentIndex: 0
    }
  },

  components: {
    PeDialog
  },

  computed: {
    leftDisable() {
      return this.currentIndex == 0
    },

    rightDisable() {
      return this.currentIndex == this.visualList.length - 1
    },

    isFullScreen() {
      return this.$store.state.settings.isFullScreen
    }
  },

  mounted() {
    this.getList()
    window.addEventListener('keydown', this.handleKeyDown) //监听鼠标按下
  },

  methods: {
    handleKeyDown(e) {
      if (e.keyCode == 39) {
        this.handleRight()
      }
      if (e.keyCode == 37) {
        this.handleLeft()
      }
    },

    handleDetail(data) {
      console.log(data, 'curre')
      this.currentModule = data
      this.currentIndex = data.index
      this.show = true
    },

    handleEnterKey(e) {
      console.log(e, 3322)
    },

    handleLeft() {
      const { index } = this.currentModule
      console.log(this.currentModule, 'left1233')
      if (index <= 0) {
        this.currentIndex == 0
      } else {
        this.currentIndex--
        this.currentModule = this.visualList[this.currentIndex]
      }
    },

    handleRight() {
      const last = this.visualList.length - 1
      if (this.currentIndex >= last) {
        this.currentIndex == last
      } else {
        this.currentIndex++
        this.currentModule = this.visualList[this.currentIndex]
      }
    },

    async getList() {
      const list = []
      const { data } = await getVisualTemplate()
      data.forEach((item, index) => {
        item.index = index
        list.push(item)
      })
      this.visualList = list
    }
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }
}
</script>
<style lang="scss">
.visual-case-page {
  .mode-image {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
  }

  .el-dialog__body {
    max-height: inherit;
    padding: 10px 40px;
  }

  .screen-dialog {
    background: #333;
  }

  .el-dialog__footer {
    display: none;
  }

  .dialog-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-fl {
    position: fixed;
    top: 50%;
    left: 1.5%;
    transform: translateY(-50%);
  }

  .icon-fr {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 2.5%;
  }
}
</style>
<style lang="scss" scoped>
.visual-case-page {
  .crumb {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px;
  }
  .list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 0;
    .item {
      margin: 0 0.5% 10px;
      width: 24%;
      background-color: #fff;
      height: 240px;
      padding: 10px;
      border: 2px solid transparent;
      position: relative;
      overflow: hidden;
      .img-default {
        width: 100%;
        height: 85%;
      }
      .top {
        display: flex;
        flex-direction: column;
      }
      &:hover {
        cursor: pointer;
        border: 2px solid #41cabf;
        background: rgba(0, 0, 0, 0.01);
        &::after {
          content: '预览';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: #41cabf;
          color: #fff;
          padding: 5px 10px;
        }
      }
    }
  }
}
</style>
