<template>
  <div>
    <div v-if="sub" class="user_count_show">
      <div class="count_show_cont">
        <div class="account_user_cont child_recharge">
          <span>子账号数量： {{ childAccNum.childNumber }}</span>
          <span style="display:inline-block;"
            >剩余子账号数量： {{ childAccNum.remainNumber }}</span
          >
        </div>
      </div>

      <div class="count_show_cont">
        <div class="account_user_cont flow_recharge">
          <span
            >【线索包】剩余可用点数：{{ currentKeyNum.companyAvailableFree }}
          </span>
          <span
            >【企业查询/高级查找/自助找客】剩余可用点数：{{
              currentKeyNum.ziZhuAvailableFree
            }}
          </span>
          <span
            >【其他配额】剩余可用点数：{{ currentKeyNum.companyAvailable }}
          </span>
        </div>
      </div>
    </div>

    <dia-model
      ref="showUpTip"
      :dialogStatus="dialogStatus"
      @submitContBtn="submitContBtn"
    >
      <div slot="modalCont" style="line-height:40px;">
        <p>{{ showUpCont }}</p>
        <img
          style="width:140px;height:140px;"
          v-if="currentClick === 'd'"
          src="../../assets/no-kp.png"
        />
      </div>
    </dia-model>
  </div>
</template>

<script>
import { getAccEarn, getKeyNum } from '@/api/subaccount'
import diaModel from '@/components/Model'
export default {
  props: {
    sub: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userAccEarn: {},
      childAccNum: {},
      currentAccType: '',
      dialogStatus: {},
      showUpCont: '',
      currentKeyNum: {},
      currentClick: ''
    }
  },
  components: {
    diaModel
  },
  created() {
    this.currentAccType = this.$store.getters.accountType
    this.getAccEarn()
    this.getKeyNum()
  },
  methods: {
    // 获取账户余额
    getAccEarn() {
      getAccEarn({}).then(res => {
        if (res.code === 60000) {
          if (res.data.childUser) {
            this.childAccNum = res.data.childUser
            this.userAccEarn = res.data
          } else {
            this.userAccEarn = res.data
          }
          console.log(res.data)
        }
      })
    },
    // 获取线索公海可用线索剩余
    getKeyNum() {
      getKeyNum({}).then(res => {
        if (res.code === 60000) {
          this.currentKeyNum = res.data
          console.log(res.data)
          // this.authSub = this.$store.getters.sub
        }
      })
    },
    goBuyProduct() {
      this.$emit('goBuyProduct')
    },
    submitContBtn() {
      if (this.currentClick === 'd') {
        this.$refs.showUpTip.subimtCancle()
        return
      }
      // 升级套餐
      this.$router.push({
        path: '/buypay',
        query: {
          buypayParams: '826d7ebe97b9f0b69d7906872bcfa48c'
        }
      })
    },
    goUpTip() {
      this.showUpCont = '当前套餐为个人版，请先升级套餐。'
      this.dialogStatus = {
        dialogWidth: '350px',
        dialogTitle: '提示',
        showFoot: true,
        dialogTop: '200px',
        singleBtn: false,
        dialogSurBtn: '前往升级'
      }
      this.$nextTick(() => {
        this.$refs.showUpTip.init()
      })
    },
    // 打开支付
    setMealPay(val) {
      this.currentClick = val
      if (!this.sub && val !== 'e') {
        this.$store.dispatch('setGoBuyMeal', true)
        return
      }
      if (val === 'a') {
        this.$store.dispatch('changeUserBuyType', '1')
        this.$store.dispatch('changeUserBuy', true)
      }
      if (val === 'b') {
        if (this.$store.getters.userInfo.version === 's7') {
          this.goUpTip()
          return
        }
        this.$store.dispatch('changeUserBuyType', '2')
        this.$store.dispatch('changeUserBuy', true)
      }
      if (val === 'c') {
        if (this.$store.getters.userInfo.version === 's7') {
          this.goUpTip()
          return
        }
        this.$store.dispatch('changeUserBuyType', '3')
        this.$store.dispatch('changeUserBuy', true)
      }
      if (val === 'd') {
        this.showUpCont = '查KP余额暂不支持线上充值，请联系管理员：'
        this.dialogStatus = {
          dialogWidth: '450px',
          dialogTitle: '提示',
          showFoot: true,
          dialogTop: '200px',
          singleBtn: true,
          dialogSurBtn: '确定'
        }
        this.$nextTick(() => {
          this.$refs.showUpTip.init()
        })
      }
      if (val === 'e') {
        this.$store.dispatch('changeUserBuyType', '666')
        this.$store.dispatch('changeUserBuy', true)
      }
    }
  }
}
</script>

<style scoped>
.youhuijuan::before {
  content: '';
  width: 35px;
  height: 33px;
  display: inline-block;
  position: absolute;
  top: 20px;
  left: -50px;
  background: url('../../assets/yhj.svg') no-repeat;
  background-size: 100% 100%;
}
</style>
