<template>
  <div class="targetSetting-main">
    <div class="table">
      <DtScroll :margin="10">
        <el-table :data="list" border size="small" style="margin-top: 10px">
          <el-table-column label="控件类型" header-align="center" prop="type" width="150">
            <template slot-scope="scope">
              <el-select v-model="scope.row.type" size="mini" style="width: 100%" placeholder="请选择控件类型">
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="标题" header-align="center" prop="name">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name" size="mini" clearable placeholder="请输入标题"/>
            </template>
          </el-table-column>
          <el-table-column label="初始值" header-align="center" prop="initVal">
            <template slot-scope="scope">
              <el-input
                v-if="scope.row.type !== 2"
                v-model="scope.row.initVal"
                size="mini"
                clearable
                :placeholder="scope.row.type === 1 ? '请输入初始值（多个逗号分割）':'请输入初始值'"/>
              <el-date-picker
                style="width: 100%"
                v-else
                size="mini"
                v-model="scope.row.initVal"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column align="center" width="150">
            <template slot-scope="scope">
                <span class="del" @click="delTarget( scope.row, scope.$index )">
                  删除
                </span>
            </template>
          </el-table-column>
        </el-table>
      </DtScroll>
    </div>
    <div class="add-icon">
        <span @click="addTarget">
        +新增
        </span>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancelClick">取消</el-button>
      <el-button size="small" type="primary" @click="confirmClick">确定</el-button>
    </div>
  </div>
</template>

<script>
  import DtScroll from '@/components/element/DtScroll'
  import { customize, customizeDel, customizePost } from '@/api/customerpond'

  export default {
    name: 'targetSetting',
    components: {
      DtScroll
    },
    data () {
      return {
        list: [],

        typeList: [
          {
            value: 0,
            name: '文本框'
          },
          {
            value: 1,
            name: '单选下拉'
          },
          {
            value: 2,
            name: '日期'
          }
        ]
      }
    },
    watch: {},
    beforeDestroy () {},
    created () {},
    mounted () {},
    computed: {},
    methods: {
      init (list) {
        this.list = list
      },

      addTarget () {
        this.list.push({
          type: 0,
          name: '',
          initVal: null
        })
      },

      delTarget (data, index) {
        this.$store.dispatch('showMessageBox', {
          title: '提示',
          text: '删除并保存后，将不再展示对应字段，系统中保存的对应字段数据也会被清空',
          type: 'error',
          onClose: res => {
            if (res === 'confirm') {
              if (data.id) {
                customizeDel({ id: data.id }).then(res => {
                  if (res.code === 60000) {
                    this.list.splice(index, 1)
                  }
                })
              } else {
                this.list.splice(index, 1)
              }
            }
          }
        })
      },

      cancelClick () {
        this.$emit('closePop')
      },

      confirmClick () {
        const params = this.list
        customizePost(params).then(res => {
          if (res.code === 60000) {
            this.$emit('confirmPop')
            this.cancelClick()
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .targetSetting-main {
    .table {
      height: 300px;

      .del {
        color: #41CABF;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
      }
    }

    .add-icon {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      box-sizing: border-box;

      & > span {
        cursor: pointer;
        user-select: none;
        font-size: 12px;
        color: #0099CC;
      }
    }

    .footer {
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      padding-right: 10px;

      &::after {
        content: '';
        display: inline-block;
        height: 1px;
        width: 100%;
        background-color: rgba(0, 0, 0, .1);
        transform: scaleY(.5);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
</style>
