var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "productRelationGraph-main",
      attrs: { "element-loading-text": "加载中" },
    },
    [
      _vm.noData
        ? _c("div", { staticClass: "no-data-data" }, [
            _vm._v("\n    暂无数据\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-checkbox-group",
        {
          staticClass: "check",
          on: { change: _vm.getGraph },
          model: {
            value: _vm.links,
            callback: function ($$v) {
              _vm.links = $$v
            },
            expression: "links",
          },
        },
        _vm._l(_vm.typeList, function (item, index) {
          return _c("el-checkbox", { key: index, attrs: { label: item.key } }, [
            _vm._v(_vm._s(item.name)),
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "myPage", staticClass: "main-graph" },
        [
          _c("RelationGraph", {
            ref: "seeksRelationGraph",
            attrs: {
              options: _vm.graphOptions,
              "on-node-click": _vm.onNodeClick,
            },
            scopedSlots: _vm._u([
              {
                key: "node",
                fn: function ({ node }) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticClass: "icon",
                        on: {
                          click: function ($event) {
                            return _vm.testNode(node)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(node.data.cname) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "node-class",
                        on: {
                          click: function ($event) {
                            return _vm.jumpCompany(node.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(node.data.cname) +
                            "\n          "
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "margin-top": "3px",
                              "font-size": "12px",
                            },
                          },
                          [
                            _c("p", [_vm._v("热度：" + _vm._s(node.data.hot))]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "云服务商：" + _vm._s(node.data.csp || "其他")
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }