import defaultSettings from '@/settings'

const { showSettings, fixedHeader, sidebarLogo } = defaultSettings

const state = {
  financeLoading: false,
  financeTotal: 0,
  showSettings,
  fixedHeader,
  sidebarLogo,
  isFullScreen: false, //地图模块控制全屏,
  isOn: false, //领取数据监听
  callMobile: false,
  isRouterAlive: true,
  changeColor: 0,
  changeCount: true,
  axiosPromiseArr: [], //异步请求中断
  touchIndex: 0,
  errorMessage: '',
  touchModuleList: [],
  isMapFull: false,
  chartsFull: false, //echarts 是否全品展示
  filterList: {}
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  setFullScreen(state) {
    console.log(state, 'setSetting')
  },
  pushAxiosPromiseArr(state, value) {
    state.axiosPromiseArr.push(value)
  },
  setAxiosPromiseArr(state, value) {
    state.axiosPromiseArr = value
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
