var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "chanceView-main",
    },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", [_vm._v("\n      商机信息\n    ")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.createChance },
              },
              [_vm._v("新建商机")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { key: _vm.refreshKey, staticClass: "main dt-dropdown-dt" },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.chanceList && !_vm.chanceList.length,
                  expression: "chanceList && !chanceList.length",
                },
              ],
              staticClass: "no-data",
            },
            [_vm._v("暂无数据")]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.load,
                  expression: "load",
                },
              ],
              staticClass: "row-main",
            },
            _vm._l(_vm.chanceList, function (item) {
              return _c(
                "el-col",
                { key: item.id, staticClass: "row-item", attrs: { span: 24 } },
                [
                  _c(
                    "div",
                    { staticClass: "list-item" },
                    [
                      _c(
                        "el-row",
                        {
                          attrs: { gutter: 20, align: "middle", type: "flex" },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { gutter: 10 },
                                },
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("span", { staticClass: "key" }, [
                                      _vm._v("商机名称:"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(item.nicheName)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("span", { staticClass: "key" }, [
                                      _vm._v("预估签单金额:"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(item.predictBillMoney)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("span", { staticClass: "key" }, [
                                      _vm._v("商机阶段:"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(item.nicheStateDesc)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { gutter: 10 },
                                },
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("span", { staticClass: "key" }, [
                                      _vm._v("签单决策人:"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatContactList")(
                                            item.contactList
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("span", { staticClass: "key" }, [
                                      _vm._v("预估签单日期:"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(item.predictBillDate)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("span", { staticClass: "key" }, [
                                      _vm._v("负责人:"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(item.followName)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { gutter: 10 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 10 } },
                                        [
                                          _c("el-col", { attrs: { span: 2 } }, [
                                            _c(
                                              "span",
                                              { staticClass: "key1" },
                                              [_vm._v("备注:")]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 22 } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "value" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.nicheRemark)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c("span", { staticClass: "remove" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "edit",
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(item)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "line" }, [
                                _vm._v("|"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "edit",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delChance(item)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "line" }, [
                                _vm._v("|"),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }