<template>
  <div>
    <p
      class="company-info-header company-info-text"
      style="background:#fff;margin-top: 10px;"
    >
      <span class="header-left-name">账号配置</span>
    </p>
    <div class="user_count_show">
      <div class="count_show_cont">
        <div class="account_user_cont follow_time">
          <span>客户可跟进时间:{{ userInfoObj.enterprise.protectDay }} 天</span>
          <span class="recharge_use" @click="goUpTip('1')">配置</span>
        </div>
      </div>

      <div class="count_show_cont">
        <div class="account_user_cont period">
          <span>保护期:{{ userInfoObj.enterprise.cusProDay }}天</span>
          <span
            style="display:inline-block;"
            class="recharge_use"
            @click="goUpTip('2')"
            >配置</span
          >
        </div>
      </div>

      <div class="count_show_cont">
        <div class="account_user_cont personnum">
          <span>负责客户数:{{ userInfoObj.enterprise.customersLimit }}家</span>
          <span class="recharge_use" @click="goUpTip('3')">配置</span>
        </div>
      </div>
    </div>
    <dia-model
      ref="showUpTip"
      :dialogStatus="dialogStatus"
      @submitContBtn="submitContBtn"
    >
      <div slot="modalCont" style="line-height:40px;margin-top: 30px;">
        <p>
          <el-radio v-model="configType" label="1">
            <el-input
              v-model="optionNum"
              placeholder="请输入"
              style="width: 180px;"
              type="number"
            ></el-input>
            <span>{{ configState == 3 ? '家' : '天' }}</span>
            <span v-if="configState != 3">销售负责客户释放</span>
          </el-radio>
        </p>
        <p v-if="configState != 3">
          <el-radio v-model="configType" label="2">销售负责客户不释放</el-radio>
        </p>
        <p v-if="configState != 3" style="margin-top:10px">
          <span>到期后退还</span>
          <el-select v-model="releaseStr" clearable placeholder="请选择">
            <el-option
              v-for="item in strOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </p>
      </div>
    </dia-model>
  </div>
</template>

<script>
import { getAccEarn, getKeyNum, setCustomerConfig } from '@/api/subaccount'
import { getCustomerReceivers } from '@/api/customerpond'
import diaModel from '@/components/Model'
export default {
  name: 'UserConfigPage',
  props: {
    userInfoObj: {
      default: () => {},
      type: Object
    },
    sub: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      releaseStr: '',
      strOptions: [],
      userAccEarn: {},
      childAccNum: {},
      currentAccType: '',
      dialogStatus: {},
      showUpCont: '',
      currentKeyNum: {},
      currentClick: '',
      configType: '1',
      optionNum: 30,
      configState: null
    }
  },
  components: {
    diaModel
  },
  created() {
    this.currentAccType = this.$store.getters.accountType
    this.getAccEarn()
    this.getKeyNum()
  },
  methods: {
    // 获取账户余额
    getAccEarn() {
      getAccEarn({}).then(res => {
        if (res.code === 60000) {
          if (res.data.childUser) {
            this.childAccNum = res.data.childUser
            this.userAccEarn = res.data
          } else {
            this.userAccEarn = res.data
          }
          console.log(res.data)
        }
      })
    },
    // 获取线索公海可用线索剩余
    getKeyNum() {
      getKeyNum({}).then(res => {
        if (res.code === 60000) {
          this.currentKeyNum = res.data
          console.log(res.data)
          // this.authSub = this.$store.getters.sub
        }
      })
    },
    submitContBtn() {
      let params = {}

      if (this.configType == 1) {
        if (
          (!this.optionNum && this.optionNum != 0) ||
          !/^(0|[1-9][0-9]*)$/.test(this.optionNum)
        ) {
          this.$message({
            message: '格式错误',
            type: 'error'
          })
          return
        }
        if (this.configState == 1) {
          params = { protectDay: this.optionNum }
        }
        if (this.configState == 2) {
          params = { cusProday: this.optionNum }
        }
        if (this.configState == 3) {
          params = { customersLimit: this.optionNum }
        }
      } else {
        if (this.configState == 1) {
          params = { protectDay: 0 }
        }
        if (this.configState == 2) {
          params = { cusProday: 0 }
        }
        if (this.configState == 3) {
          params = { customersLimit: 500000 }
        }
      }
      params.protectReturnUser = this.releaseStr

      setCustomerConfig(params).then(res => {
        if (res.code === 60000) {
          console.log(res)
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.$emit('getCompanyInfo')
          this.$refs.showUpTip.subimtCancle()
        }
      })
    },
    goUpTip(i) {
      this.configState = i
      // this.optionNum = 30
      let text = ''
      if (i == 1) {
        text = '剩余时间'
      }
      if (i == 2) {
        text = '保护期'
      }
      if (i == 3) {
        text = '负责客户数'
      }
      this.dialogStatus = {
        dialogWidth: '600px',
        dialogTitle: '配置' + text,
        showFoot: true,
        dialogTop: '200px',
        singleBtn: false,
        dialogSurBtn: '确定'
      }
      this.$nextTick(async () => {
        this.$refs.showUpTip.init()
        const { data } = await getCustomerReceivers({
          type: 3,
          pageId: 2001
        })
        const list = [{ id: 0, name: '创建人' }]
        this.strOptions = data.concat(list).map(item => ({
          label: item.name,
          value: item.id
        }))
        console.log(data)
      })
    },
    // 打开支付
    setMealPay(val) {
      this.currentClick = val
      if (!this.sub && val !== 'e') {
        this.$store.dispatch('setGoBuyMeal', true)
        return
      }
      if (val === 'a') {
        this.$store.dispatch('changeUserBuyType', '1')
        this.$store.dispatch('changeUserBuy', true)
      }
      if (val === 'b') {
        if (this.$store.getters.userInfo.version === 's7') {
          this.goUpTip()
          return
        }
        this.$store.dispatch('changeUserBuyType', '2')
        this.$store.dispatch('changeUserBuy', true)
      }
      if (val === 'c') {
        if (this.$store.getters.userInfo.version === 's7') {
          this.goUpTip()
          return
        }
        this.$store.dispatch('changeUserBuyType', '3')
        this.$store.dispatch('changeUserBuy', true)
      }
      if (val === 'd') {
        this.showUpCont = '查KP余额暂不支持线上充值，请联系管理员：'
        this.dialogStatus = {
          dialogWidth: '450px',
          dialogTitle: '提示',
          showFoot: true,
          dialogTop: '200px',
          singleBtn: true,
          dialogSurBtn: '确定'
        }
        this.$nextTick(() => {
          this.$refs.showUpTip.init()
        })
      }
      if (val === 'e') {
        this.$store.dispatch('changeUserBuyType', '666')
        this.$store.dispatch('changeUserBuy', true)
      }
    }
  }
}
</script>

<style scoped>
.youhuijuan::before {
  content: '';
  width: 35px;
  height: 33px;
  display: inline-block;
  position: absolute;
  top: 20px;
  left: -50px;
  background: url('../../assets/yhj.svg') no-repeat;
  background-size: 100% 100%;
}
</style>
