<template>
  <span class="ScrollLoad-main" v-loading="loading">
    <span class="before" @click="showBeforeKey">上一条</span
    ><span @click="showNextKey" class="last">下一条</span>
  </span>
</template>

<script>
import { throttle } from 'lodash'
import { getSearchList, package_detail_condition } from '@/api/thread'
import { getEsSearch, getHeiSearch } from '@/api/user'
import { getCusPondList } from '@/api/customerpond'
import { searchCompanyForMap } from '@/api/highsearch'

export default {
  name: 'ScrollLoad',
  data() {
    return {
      dataZero: 40,
      currentIndex: 0, // 当前公司的index
      dataList: [],
      loading: false,
      // 初始化数据
      keyList: [],
      keyIndex: 0,
      pageNo: null
    }
  },
  watch: {
    dataList: function(val) {
      // 处理源数据
      if (val.length >= this.dataZero) {
        const current = val[this.currentIndex]
        this.dataList = this.dataList.slice(-30)
        this.currentIndex = this.dataList.findIndex(
          i => i.companyUuid === current.companyUuid
        )
      }
    },

    currentIndex: function(val) {
      if (val === this.dataList.length - 1) {
        this.getDataList()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('keydown', this.onkeydown)
    })
  },
  created() {
    this.showNextKey = throttle(
      () => {
        this._showNextKey()
      },
      1000,
      { trailing: true }
    )
    this.showBeforeKey = throttle(
      () => {
        this._showBeforeKey()
      },
      1000,
      { trailing: true }
    )
  },
  computed: {},
  methods: {
    // todo 获取当前线索的列表和当前key的位置 调用时机：线索详情也波打电话 进行初始化数据
    getCurrentKeyListAndIndex() {
      this.keyIndex = Number(localStorage.getItem('currSindex'))
      this.keyList = JSON.parse(localStorage.getItem('cuttentList'))
      let sourceData = this.keyList.slice(this.keyIndex).map(item => {
        return {
          companyName: item.companyName || item.company_name,
          companyUuid: item.companyUuid || item.company_uuid,
          packageId: item.packageId || this.$route.query.packageId || 0
        }
      })
      // 分页数据大于20 将会出现性能问题 这里将数据限制到20
      // 请求数据是需要将pageSize强制设置到20
      if (sourceData.length > 20) {
        sourceData = sourceData.slice(0, 20)
      }
      this.dataList = sourceData
      this.currentIndex = 0
      // 请求第一次数据
      this.$emit('loadFirstData', sourceData[0])
    },

    // 键盘事件
    onkeydown(target) {
      console.log(target)
      if (this.loading) return
      if (target.keyCode === 39) this.showNextKey()
      if (target.keyCode === 37) this.showBeforeKey()
      if (target.keyCode === 27) this.closePlate()
    },

    closePlate() {
      this.$bus.$emit('closePlate')
    },

    _showBeforeKey() {
      // todo 获取上一条线索的pid、cid、cname
      if (this.currentIndex !== 0) {
        const packageId = this.dataList[this.currentIndex - 1].packageId
        const companyName = this.dataList[this.currentIndex - 1].companyName
        const companyUuid = this.dataList[this.currentIndex - 1].companyUuid
        this.$emit('showKey', packageId, companyName, companyUuid)
        this.currentIndex -= 1
      }
    },

    _showNextKey() {
      if (this.loading) return
      // todo 获取上一条线索的pid、cid、cname
      const dataLength = this.dataList.length
      if (this.currentIndex + 1 <= dataLength - 1) {
        const packageId = this.dataList[this.currentIndex + 1].packageId
        const companyName = this.dataList[this.currentIndex + 1].companyName
        const companyUuid = this.dataList[this.currentIndex + 1].companyUuid
        this.$emit('showKey', packageId, companyName, companyUuid)
        this.currentIndex += 1
      }
    },

    // todo 加载下一页数据
    getDataList() {
      const fromPath = localStorage.getItem('fromPath')
      this.loading = true
      if (fromPath === '/' || fromPath === '/package') this.getKeyList()
      if (fromPath === '/globalquery/query') this.getSearchList()
      if (fromPath === '/globalquery/query2') this.getHeightSearchList()
      if (fromPath === '/customerpond/customerpond') this.getCustomerpondList()
      if (fromPath === '/booklist/booklist') this.getPeiYuList()
      if (fromPath === '/globalquery/map') this.getMapList()
      if (fromPath === '/preview/list') this.getPreviewList()
    },

    getKeyList() {
      const packageId = this.$route.query.packageId
      let data = JSON.parse(sessionStorage.getItem(`KeyParams${packageId}`))
      this.pageNo ? (this.pageNo = data.pageNo) : ''
      let whetherClick = 0
      if (data.clicked && !data.showHadCheck) whetherClick = 1
      if (!data.clicked && data.showHadCheck) whetherClick = -1
      let consumption = 0
      if (data.consumption && !data.isConsumption) consumption = -1
      if (!data.consumption && data.isConsumption) consumption = 1
      let params = {
        packageId: data.packageId,
        pageNo: this.pageNo + 1,
        pageSize: 20,
        companyName: data.companyName,
        condition: data.currentCondParam,
        tag: data.productTag,
        sort: data.currentSortParam,
        show: !data.showCollent ? 0 : 1,
        phone: !data.showHadPhone ? 0 : 1,
        websiteAccess: !data.websiteAccess ? 0 : 1,
        isJob: !data.isJob ? 0 : 1,
        upGongyingshang: !data.showgongyingshang ? 0 : 1,
        newClues: !data.newClues ? 0 : 1,
        customer: !data.showNotTrans ? 0 : 1,
        followUserId: data.followUserIds,
        qualityCustomer: data.showGoodCustomer ? 1 : 0,
        whetherClick: whetherClick,
        consumption: consumption
      }
      package_detail_condition(params).then(res => {
        if (res.code === 60000) {
          this.loading = false
          this.pageNo += 1
          const list = res.data.list.map(item => {
            return {
              companyName: item.companyName || item.company_name,
              companyUuid: item.companyUuid || item.company_uuid,
              packageId: item.packageId || this.$route.query.packageId || 0
            }
          })
          this.dataList = [...this.dataList, ...list]
        }
      })
    },

    // 企业查询
    getSearchList() {
      const data = JSON.parse(localStorage.getItem('globalParams'))
      this.pageNo ? (this.pageNo = data.pageNo) : ''
      const params = {
        website: data.website,
        keywords: data.keywords,
        city: data.city,
        registeredCapital: data.registeredCapital,
        industry: data.industry,
        registeredTime: data.registeredTime,
        employeeScale: data.employeeScale,
        phone: data.phone,
        sort: data.sort,
        pageNo: this.pageNo + 1,
        pageSize: 20,
        searchChange: data.searchChange,
        searchContent: data.searchContent,
        searchPrivnce: data.searchPrivnce,
        vc: data.vc,
        noCustomer: Boolean(data.noCustomer),
        noCollection: Boolean(data.noCollection),
        noConsumption: Boolean(data.noConsumption),
        noMark: Boolean(data.noMark),
        noMyMark: Boolean(data.noMyMark),
        searchType: data.searchType || 'ai'
      }
      getEsSearch(params).then(res => {
        if (res.code === 60000) {
          this.loading = false
          this.pageNo += 1
          const list = res.data.list.map(item => {
            return {
              companyName: item.companyName || item.company_name,
              companyUuid: item.companyUuid || item.company_uuid,
              packageId: item.packageId || this.$route.query.packageId || 0
            }
          })
          this.dataList = [...this.dataList, ...list]
        }
      })
    },

    // 高级查找
    getHeightSearchList() {
      const data = JSON.parse(localStorage.getItem('heiSearchParams'))
      this.pageNo ? (this.pageNo = data.pageNo) : ''
      const params = {
        condition: data.condition,
        sort: data.sort,
        pageNo: this.pageNo + 1,
        searchChange: data.searchChange,
        pageSize: 20
      }
      getHeiSearch(params).then(res => {
        if (res.code === 60000) {
          this.loading = false
          this.pageNo += 1
          const list = res.data.list.map(item => {
            return {
              companyName: item.companyName || item.company_name,
              companyUuid: item.companyUuid || item.company_uuid,
              packageId: item.packageId || this.$route.query.packageId || 0
            }
          })
          this.dataList = [...this.dataList, ...list]
        }
      })
    },

    // 客户公海
    getCustomerpondList() {
      const Id = localStorage.getItem('activeTabs')
      const data = JSON.parse(sessionStorage.getItem(`CusPondParamsNew${Id}`))
      this.pageNo ? (this.pageNo = data.pageNo) : ''
      const params = {
        type: data.type,
        pageNo: this.pageNo + 1,
        pageSize: 20,
        companyName: data.companyName,
        condition: data.condition,
        userId: data.userId,
        selectType: 1,
        profileMatch: data.profileMatch,
        websiteAccess: data.websiteAccess,
        isJob: data.isJob,
        newAdd: data.newAdd,
        canCall: data.canCall,
        sort: data.sort,
        sortOrder: data.sortOrder,
        pageId: '2001',
        remark: data.remark
      }
      getCusPondList(params).then(res => {
        if (res.code === 60000) {
          this.loading = false
          this.pageNo += 1
          const list = res.data.list.map(item => {
            return {
              companyName: item.companyName || item.company_name,
              companyUuid: item.companyUuid || item.company_uuid,
              packageId: item.packageId || this.$route.query.packageId || 0
            }
          })
          this.dataList = [...this.dataList, ...list]
        }
      })
    },

    // 培育列表
    getPeiYuList() {
      const favoriteId = sessionStorage.getItem('favoriteId')
      const data = JSON.parse(
        localStorage.getItem(`collectParams${favoriteId}`)
      )
      this.pageNo ? (this.pageNo = data.pageNo) : ''
      const params = {
        companyName: data.searchInput,
        pageNo: this.pageNo + 1,
        pageSize: 20,
        favoriteId: data.favoriteId,
        hasConsumption: data.hasConsumption,
        hasCustomer: data.hasCustomer,
        hasJob: data.hasJob,
        hasWebsite: data.hasWebsite,
        noCustomer: data.noCustomer,
        profileMatch: data.profileMatch,
        searchInput: data.searchInput,
        condition: data.condition
      }
      getSearchList(params).then(res => {
        if (res.code === 60000) {
          this.loading = false
          this.pageNo += 1
          const list = res.data.list.map(item => {
            return {
              companyName: item.companyName || item.company_name,
              companyUuid: item.companyUuid || item.company_uuid,
              packageId: item.packageId || this.$route.query.packageId || 0
            }
          })
          this.dataList = [...this.dataList, ...list]
        }
      })
    },

    getMapList() {
      const data = JSON.parse(localStorage.getItem('globalMapParams'))
      this.pageNo ? (this.pageNo = data.pageNo) : ''
      const params = {
        keywords: data.keywords,
        registeredCapital: data.registeredCapital,
        industry: data.industry,
        registeredTime: data.registeredTime,
        employeeScale: data.employeeScale,
        phone: data.phone,
        pageNo: this.pageNo + 1,
        pageSize: 20,
        searchContent: data.searchContent,
        searchPrivnce: data.searchPrivnce,
        vc: data.vc,
        radius: data.radius,
        longitude: data.longitude,
        latitude: data.latitude,
        searchAddress: this.searchAdd,
        cloud: data.cloud,
        cloudStage: data.cloudStage
      }
      searchCompanyForMap(params).then(res => {
        if (res.code === 60000) {
          this.loading = false
          this.pageNo += 1
          const list = res.data.list.map(item => {
            return {
              companyName: item.companyName || item.company_name,
              companyUuid: item.companyUuid || item.company_uuid,
              packageId: item.packageId || this.$route.query.packageId || 0
            }
          })
          this.dataList = [...this.dataList, ...list]
        }
      })
    },

    getPreviewList() {
      let Id = localStorage.getItem('currentPackId')
      let data = JSON.parse(sessionStorage.getItem(`previewKey`))
      this.pageNo ? (this.pageNo = data.pageNo) : ''
      let params = {
        packageId: data.packageId,
        pageNo: this.pageNo + 1,
        pageSize: 20,
        sort: data.sort, // province:升序
        condition: data.condition,
        preview: data.preview
      }
      package_detail_condition(params).then(res => {
        if (res.code === 60000) {
          this.loading = false
          this.pageNo += 1
          const list = res.data.list.map(item => {
            return {
              companyName: item.companyName || item.company_name,
              companyUuid: item.companyUuid || item.company_uuid,
              packageId: item.packageId || this.$route.query.packageId || 0
            }
          })
          this.dataList = [...this.dataList, ...list]
        }
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onkeydown)
  }
}
</script>

<style scoped>
.ScrollLoad-main span {
  font-size: 12px;
  color: #0099cc;
  cursor: pointer;
}
.ScrollLoad-main {
  display: inline-block;
  padding-right: 50px;
  box-sizing: border-box;
}
.before {
  margin-right: 10px;
}
.before,
.last {
  position: relative;
}
.before:before {
  content: '键盘左键';
  display: inline-block;
  font-size: 12px;
  color: #8a8a8a;
  opacity: 0.7;
  position: absolute;
  left: -50px;
  top: 50%;
  width: 50px;
  transform: translateY(-50%) scale(0.8);
}
.last:before {
  content: '键盘右键';
  display: inline-block;
  font-size: 12px;
  color: #8a8a8a;
  opacity: 0.7;
  position: absolute;
  top: 50%;
  right: -50px;
  width: 50px;
  transform: translateY(-50%) scale(0.8);
}
</style>
