<script>
import footerPage from '@/components/Footerpage/index'
import Bus from '@/utils/bus'
import bindMobile from '@/views/usercenter/dialog/bindMobile'
import { getComDetailModel } from '@/api/thread'
export default {
  name: 'ModuleTableData',
  props: {
    moduleDetail: {
      default: () => {},
      type: Object
    },
    otherParams: {
      default: () => {},
      type: Object
    },
    moduleParam: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    footerPage
  },

  data() {
    return {
      pageStatus: {
        total: 0,
        pageSize: 10,
        pageNo: 1
      },
      isHaveData: true,
      pageSizes: [10, 20, 50],
      getLoading: false,
      kpTypeCnt: '',
      xiaoShouTypeCnt: '',
      yanFaTypeCnt: '',
      guanLiTypeCnt: '',
      otherTypeCnt: ''
    }
  },
  created() {
    this.$bus.$on('PeStopLoading', () => {
      this.getLoading = false
    })
  },
  methods: {
    getReveiveKey() {
      if (
        this.$store.state.plugin.env === 'dd' &&
        !this.$store.state.user.accountInfo.mobile
      ) {
        this.$store.dispatch('showModal', {
          title: '绑定手机号',
          view: bindMobile,
          size: '600px'
        })
        return
      }

      //预览数据不可领取
      if (localStorage.getItem('isPreview') == true) {
        this.$store.dispatch('showTip', {
          text: '样例数据不可领取',
          type: 'error'
        })
        return
      }
      this.getLoading = true
      this.$store.state.settings.isOn = true
      Bus.$emit('getContactType', true)
    },
    init() {
      console.log(this.moduleDetail, 'init123')
      this.headerArr = this.moduleDetail.columns
      if (!this.otherParams.pageSize) {
        this.tableArr = this.moduleDetail.list
        return
      }
      if (this.otherParams.isScroll) {
        this.tableArr = this.moduleDetail.list
        return
      }
      if (
        Object.keys(this.moduleDetail).length !== 0 &&
        this.moduleDetail.list
      ) {
        this.pageStatus.total = this.moduleDetail.page?.total
      }
      this.decesidePage()
    },
    async handleCurrentChange(val) {
      this.showTableLoading = true
      this.pageStatus.pageNo = val
      const { data } = await getComDetailModel({
        module: this.moduleParam.module_api,
        companyUuid: this.moduleParam.companyUuid,
        packageId: this.moduleParam.packageId,
        param: {
          branch: this.moduleDetail.branch,
          pageNo: this.pageStatus.pageNo,
          pageSize: this.pageStatus.pageSize
        }
      })
      console.log(this.moduleParam, data, 'data123')
      if (this.moduleParam.module_tab == 0) {
        this.tableArr = data[this.moduleParam.module_title]?.list
      } else {
        this.tableArr = data[this.moduleDetail.branch]?.list
      }
      this.showTableLoading = false

      console.log(data, this.moduleParam, this.moduleDetail, 'click')
    },
    goNumPage(val) {
      this.pageStatus.pageNo = Number(val)
      this.handleCurrentChange(this.pageStatus.pageNo)
    },
    decesidePage() {
      console.log(this.pageStatus)
      const start = (this.pageStatus.pageNo - 1) * this.pageStatus.pageSize
      const end = this.pageStatus.pageNo * this.pageStatus.pageSize
      if (
        Object.keys(this.moduleDetail).length !== 0 &&
        this.moduleDetail.list
      ) {
        this.tableArr = [...this.moduleDetail.list.slice(start, end)]
      }
    }
  },
  beforeDestroy() {
    this.$store.state.settings.isOn = false
  }
}
</script>

<style scoped>
.module-cont-filter {
  filter: blur(2px);
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
</style>
