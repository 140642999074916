<template>
  <div class="ddScrmLogin-main">
    123
  </div>
</template>

<script>
export default {
  name: 'ddScrmLogin',
  data () {
    return {}
  },
  mounted () {},
  computed: {},
  methods: {},
  beforeDestroy () {}
}
</script>

<style scoped>

</style>
