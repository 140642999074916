<template>
  <div class="content">
    <div class="evaluate-textarea">
      <el-form ref="form" :rules="rule" :model="formData" size="small" label-width="auto">
        <el-form-item prop="commitContent" label="错误内容">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="formData.commitContent"
            :maxlength="1000"
          ></el-input>
        </el-form-item>

        <el-form-item prop="commitPhone" label="联系方式">
          <el-input
            v-model="formData.commitPhone"
            class="search-input"
            placeholder="请留下您的联系方式"
            :maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancelBtn">取消</el-button>
      <el-button size="small" type="primary" @click="confirmBtn">确认</el-button>
    </div>
  </div>
</template>

<script>
  import { error_commit } from '@/api/thread'

  export default {
    name: 'reportErrorData',
    data(){
      return {
        formData: {
          commitContent: '',
          commitPhone: ''
        },
        rule:{
          commitContent:[
            { required: true, message: '请填写内容', trigger: 'blur' }
          ],
          commitPhone:[
            { required: true, message: '请填写您的联系方式', trigger: 'blur' }
          ]
        }
      }
    },

    methods:{
      cancelBtn(){
        this.$store.dispatch('hideModal')
      },

      confirmBtn(){
        this.$refs['form'].validate(valid=>{
          if(valid){
            this.errorCommit()
          }
        })
      },

      errorCommit(){
        const params = {
          content: this.formData.commitContent,
          mobile: this.formData.commitPhone
        };
        error_commit(params).then(res => {
          if (res.code == 60000) {
            this.$message({
              message: "提交成功",
              type: "success"
            });
          }
          this.cancelBtn()
        })
      }
    }
  }
</script>

<style scoped>
.content{
  padding: 20px;
  box-sizing: border-box;
}
  .footer{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
</style>
