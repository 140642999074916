import Vue from 'vue'

const state = {
  wsObj: null,
  ws: 'ws://127.0.0.1:8800/',
  simStatus: '10086',
  openWs: true,
  num: 0,
  simIndex: 0,
  close: false,

  eventType: null,
  sessionId: ''
}

const mutations = {
  update: (state, { target, data }) => {
    for (let name in data) {
      Vue.set(target, name, data[name])
    }
  },

  savePanelList: (state, data) => {
    state.panelList = data
  }
}

function createSocket(store) {
  const { state, rootState } = store
  if (state.wsObj && state.wsObj.readyState === 1) return
  console.log('话机开始连接')
  state.num += 1
  state.wsObj = new WebSocket(state.ws)
  state.wsObj.onopen = () => {
    if (state.wsObj.readyState === 1) {
      console.log('话机连接成功')
      state.wsObj.send('{"cmd":"LINK"}')
      const userId = rootState?.user?.accountInfo?.id
      const name = rootState?.user?.accountInfo?.username
      const isDev = process.env.NODE_ENV === 'development'
      const url = isDev
        ? 'https://api-test.dtbird.cn/api/waihu/open/addsim'
        : 'https://api.dtbird.cn/api/waihu/open/addsim'
      const uplaod = isDev
        ? `https://api-test.dtbird.cn/api/waihu/open/recordPhone`
        : `https://api.dtbird.cn/api/waihu/open/recordPhone`
      const cmmd =
        '{"cmd": "setCallrecord","pid": "' +
        name +
        '","userWid":"' +
        userId +
        '","callrecordUrl":"' +
        url +
        '","recordFileUrl":"' +
        uplaod +
        '","heartbeatUrl":""}'
      state.wsObj.send(cmmd)

      // const timeObj = setInterval(()=>{
      //   if(state.wsObj && state.wsObj.readyState === 1){
      //     state.wsObj.send('{"cmd":"READSTATUS"}')
      //   }else {
      //     clearInterval(timeObj)
      //     if(state.wsObj.readyState === 3){
      //       store.commit('update', {
      //         target: state,
      //         data: {
      //           wsObj: null
      //         }
      //       })
      //       createSocket(state, rootState)
      //     }
      //   }
      // }, 1000)
    }
  }

  state.wsObj.onerror = () => {
    let accountInfo = localStorage.getItem('accountInfo')
      ? JSON.parse(localStorage.getItem('accountInfo'))
      : {}
    if (
      state.wsObj.readyState === 3 &&
      accountInfo.bandWaihuType === 'simPhone'
    ) {
      store.dispatch('showNotice', {
        title: '提示',
        text:
          '话机连接失败，请确保中间件与话机已经建立连接且SIM卡状态正常后点击右上角重新连接按钮重新连接',
        type: 'error'
      })
    }
    store.commit('update', {
      target: state,
      data: {
        simStatus: '-00',
        simIndex: 0
      }
    })
    // 自动重试5次
    // if(state.wsObj && state.wsObj.readyState === 3 && state.num <= 5){
    //   console.log('话机断开连接')
    //   store.commit('update', {
    //     target: state,
    //     data: {
    //       wsObj: null
    //     }
    //   })
    //   rootState?.user?.accountInfo?.bandWaihuType === 'simPhone' && state.openWs ? createSocket(store) : ''
    // }
  }

  state.wsObj.onmessage = data => {
    const msg = JSON.parse(data.data)

    if (msg.cmd === 'getSimIndex') {
      state.simIndex = msg.value
    }

    if (msg.cmd === 'READSTATUS') {
      store.commit('update', {
        target: state,
        data: {
          simStatus: msg.status
        }
      })
    }
    if (msg.cmd === 'USB') {
      if (msg.connected) {
        let accountInfo = localStorage.getItem('accountInfo')
          ? JSON.parse(localStorage.getItem('accountInfo'))
          : {}
        if (accountInfo?.bandWaihuType === 'simPhone') {
          store.dispatch('showNotice', {
            title: '提示',
            text: '话机连接成功',
            type: 'success',
            duration: 3000
          })
        }
        store.commit('update', {
          target: state,
          data: {
            simStatus: '0'
          }
        })
        const _code = '{"cmd": "getSimIndex"}'
        state.wsObj.send(_code)
      } else {
        store.commit('update', {
          target: state,
          data: {
            simStatus: '10086'
          }
        })
      }
    }
  }
}

const actions = {
  createSocket: store => {
    // 判断是否存在socket 存在则直接返回
    createSocket(store)
  },

  closePhoneSocket: ({ state }) => {
    if (state.wsObj) {
      state.wsObj.close()
      state.wsObj = null
      state.openWs = false
    }
  }
}

export default {
  state,
  actions
}
