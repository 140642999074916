var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-container" },
    [
      _c("Header"),
      _vm._v(" "),
      _c("div", { staticClass: "reser-title" }, [
        _vm._v("欢迎注册领鸟云数据平台"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "register-form" }, [
        _c("div", { staticClass: "business-title" }, [
          _vm._v("\n      企业信息录取\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "business-name",
            staticStyle: { "margin-top": "80px" },
          },
          [
            _c("label", [_vm._v("公司名称")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.registerInfo.businessname,
                  expression: "registerInfo.businessname",
                },
              ],
              staticClass: "business-name-input",
              class: _vm.errorMsg.nameMsg ? "input-error" : "",
              attrs: { maxlength: "20", placeholder: "请输入公司名称" },
              domProps: { value: _vm.registerInfo.businessname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.registerInfo,
                    "businessname",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _vm.errorMsg.nameMsg
              ? _c(
                  "p",
                  {
                    staticClass: "error",
                    staticStyle: { "margin-left": "38px" },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.errorMsg.nameMsg) + "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "business-product" }, [
          _c(
            "div",
            { staticClass: "business-input-product" },
            [
              _c("label", [_vm._v("销售团队规模")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: {
                    width: "571px",
                    height: "40px",
                    "margin-left": "24px",
                  },
                  attrs: { placeholder: "请选择销售团队规模" },
                  model: {
                    value: _vm.registerInfo.industry,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerInfo, "industry", $$v)
                    },
                    expression: "registerInfo.industry",
                  },
                },
                _vm._l(_vm.products.XS_01, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.value,
                      attrs: { label: item.name, value: item.value },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#8492a6",
                            "font-size": "13px",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _vm.errorMsg.industryMsg || _vm.errorMsg.productMsg
                ? _c(
                    "p",
                    {
                      staticClass: "error",
                      staticStyle: { "margin-left": "38px" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.errorMsg.industryMsg || _vm.errorMsg.productMsg
                          ) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.errorMsg.tipMsg
          ? _c(
              "div",
              {
                staticClass: "error",
                staticStyle: { "margin-top": "20px", "margin-left": "390px" },
              },
              [
                _c("span", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.errorMsg.tipMsg) + "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom",
                      width: "150",
                      trigger: "click",
                    },
                  },
                  [
                    _c("div", { staticClass: "er" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "text-decoration": "underline",
                          cursor: "pointer",
                          "padding-bottom": "3px",
                        },
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [_vm._v("请联系客服")]
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "register-bt",
            on: { click: _vm.registerBt },
          },
          [_vm._v("\n      下一步\n    ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }