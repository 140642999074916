<template>
  <div class="pe-panel-page">
    <div v-show="form.store" class="l-panel">
      <div :class="numClass">
        <div class="l-item">
          <div class="l-top">
            <p>招商城市</p>
            <span class="dot city"></span>
          </div>

          <span class="notice"> {{ currentCity }}</span>
        </div>
      </div>
      <div :class="numClass">
        <div class="l-item">
          <div class="l-top">
            <p>招商中</p>
            <span class="dot zs"></span>
          </div>
          <span class="notice">{{ pointData.investmentPromotion }}</span>
        </div>
      </div>
      <div :class="numClass">
        <div class="l-item">
          <div class="l-top">
            <p>营业中</p>
            <span class="dot yy"></span>
          </div>

          <span class="notice"> {{ pointData.operating }}</span>
        </div>
      </div>
      <div :class="numClass">
        <div class="l-item">
          <div class="l-top">
            <p>装修中</p>
            <span class="dot zx"></span>
          </div>

          <span class="notice"> {{ pointData.decoration }}</span>
        </div>
      </div>
    </div>
    <div v-if="panelType == 'store'" class="right-panel">
      <div :class="model ? 'dark-panel' : 'panel'">
        <div class="operate-panel">
          <div class="border-list">
            <div class="border-item">
              <div class="item-spec">
                <div class="label">热力图</div>
                <div class="item">
                  <span>客流</span>
                  <el-switch
                    v-model="form.scatter"
                    @change="handleDistribute"
                  ></el-switch>
                </div>

                <div v-show="form.scatter" class="item">
                  <PeSelect
                    :clearable="false"
                    style="margin-left:0"
                    selectSize="mini"
                    :popperClass="getPopperClass"
                    :class="selectStyle"
                    :optionsList="schemaList"
                    @handleSelectChange="handleSchema"
                  />
                </div>
              </div>
            </div>

            <div class="border-item border-top">
              <div class="item-spec">
                <div class="item-brand">
                  <div class="label">竞争品牌</div>
                  <el-switch
                    v-model="form.compete"
                    @change="handleCompeteSwitch"
                  ></el-switch>
                </div>
              </div>
              <div v-show="form.compete">
                <div class="item">
                  <PeSelect
                    style="margin-left:0"
                    :popperClass="getPopperClass"
                    :class="selectStyle"
                    :optionsList="competeList"
                    :isMultiple="true"
                    selectSize="mini"
                    ref="compete"
                    placeholder="请选择竞争品牌"
                    @handleSelectChange="handleCompete"
                    @handleVisibleChange="handleCompeteVisible"
                  />
                </div>
                <div class="item">
                  <span>服务半径</span>
                  <PeSelect
                    style="margin-left:0;width:90px"
                    :popperClass="getPopperClass"
                    :class="selectStyle"
                    :optionsList="rangeList"
                    :defaultSelect="initCompete"
                    selectSize="mini"
                    @handleSelectChange="handleCompeteChange"
                  />
                </div>
              </div>
            </div>

            <div class="border-item  border-top">
              <div class="item-spec">
                <div class="item-brand">
                  <div class="label">跟随品牌</div>
                  <el-switch
                    v-model="form.follow"
                    @change="handleFollowSwitch"
                  ></el-switch>
                </div>
              </div>
              <div v-show="form.follow">
                <div class="item">
                  <PeSelect
                    style="margin-left:0"
                    :popperClass="getPopperClass"
                    :class="selectStyle"
                    :optionsList="followList"
                    :isMultiple="true"
                    ref="follow"
                    placeholder="请选择跟随品牌"
                    selectSize="mini"
                    @handleVisibleChange="handleFollowVisible"
                    @handleSelectChange="handleFollow"
                  />
                </div>
                <div class="item">
                  <span>服务半径</span>
                  <PeSelect
                    style="margin-left:0;width:90px"
                    :popperClass="getPopperClass"
                    :class="selectStyle"
                    :optionsList="rangeList"
                    :defaultSelect="initFollow"
                    selectSize="mini"
                    @handleSelectChange="handleFollowChange"
                  />
                </div>
              </div>
            </div>

            <div class="border-item border-top">
              <div class="item-spec">
                <div class="item-brand">
                  <div class="label">招商中的点位</div>
                  <el-switch
                    v-model="form.invest"
                    @change="handleInvestSwitch"
                  ></el-switch>
                </div>
              </div>
            </div>

            <div class="border-item border-top">
              <div class="item-spec">
                <div class="item-brand">
                  <div class="label">营业中的点位</div>
                  <el-switch
                    v-model="form.business"
                    @change="handleBusinessSwitch"
                  ></el-switch>
                </div>
              </div>
            </div>

            <div class="border-item border-top">
              <div class="item-spec">
                <div class="item-brand">
                  <div class="label">装修中的点位</div>
                  <el-switch
                    v-model="form.fitment"
                    @change="handleFitmentSwitch"
                  ></el-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAccountInfo } from '@/api/usercenter'
import PeAngle from '@/components/Peculiar/angle/angle'
import {
  getRankDetail,
  getConsumeList,
  getBrandDetail,
  getIndustryClassify
} from '@/api/module'
import ScreenDialog from '@/components/Peculiar/screen/dialog'
import PeSelect from '@/components/Peculiar/select'
export default {
  name: 'PeCommonPanel',

  props: {
    currentCity: {
      type: String,
      default: ''
    },

    pointData: {
      type: Object,
      default: {}
    },

    model: {
      type: Boolean,
      default: true
    },

    cityType: {
      type: String,
      default: ''
    },

    panelType: {
      type: String,
      default: 'common'
    },

    mapClick: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      competeVisible: false,
      followVisible: false,
      oneVisible: false,
      twoVisible: false,
      threeVisible: false,
      schemaList: [],
      competeList: [],
      followList: [],
      oneList: [],
      twoList: [],
      threeList: [],
      oneInit: '',
      twoInit: '',
      threeInit: '',

      rangeList: [
        {
          label: '50m',
          value: 50
        },
        {
          label: '500m',
          value: 500
        },
        {
          label: '1000m',
          value: 1000
        },
        {
          label: '2000m',
          value: 2000
        },
        {
          label: '3000m',
          value: 3000
        },
        {
          label: '5000m',
          value: 5000
        }
      ],
      form: {
        area: false,
        circle: false,
        store: true,
        scatter: false,
        compete: false,
        follow: false,
        business: true,
        invest: true,
        fitment: true
      },
      initStore: '3000m',
      initCompete: '3000m',
      initFollow: '3000m',
      sortBy: 0,
      cityWord: '门店数',
      currentIndex: 0,
      showDialog: false,
      rankTab: [
        {
          id: 0,
          name: '省份排名',
          sort: 0
        },
        {
          id: 1,
          name: '城市排名',
          sort: 1
        }
      ],
      wordList: [
        {
          id: 0,
          name: '地域'
        },
        {
          id: 1,
          name: '占比'
        },
        {
          id: 2,
          name: '门店数'
        }
      ],
      cityDescList: [
        {
          id: 0,
          name: '城市类型'
        },
        {
          id: 1,
          name: '占比'
        },
        {
          id: 2,
          name: '门店数'
        }
      ],
      rankList: [],
      cityList: [],
      selectCity: '',
      brandId: '',
      threeTypeCode: '',
      dialogList: []
    }
  },

  computed: {
    numClass() {
      return this.model ? 'dark-panel mt-10' : 'panel mt-10'
    },

    getDialogClass() {
      return this.model ? 'dark-dialog' : 'light-dialog'
    },

    getBtnClass() {
      return this.model ? 'dark-btn' : 'light-btn'
    },

    getPopperClass() {
      return this.model ? 'active-popper' : 'default-popper'
    },

    selectStyle() {
      return this.model ? 'active-select' : 'default-select'
    }
  },

  mounted() {
    this.handleAccountInfo()
    this.getSchemaData()
    this.getIndustry()
  },

  watch: {
    mapClick(val) {
      if (this.competeVisible) {
        console.log(this.$refs.compete)
        this.$refs.compete.blur()
      }

      if (this.followVisible) {
        this.$refs.follow.blur()
      }

      if (this.oneVisible) {
        this.$refs.one.blur()
      }

      if (this.twoVisible) {
        this.$refs.two.blur()
      }

      if (this.threeVisible) {
        this.$refs.three.blur()
      }

      console.log(val, 'click-maps')
    }
  },

  methods: {
    handleBusinessSwitch() {
      this.$emit('handleBusinessSwitch', this.form.business)
    },

    handleInvestSwitch() {
      this.$emit('handleInvestSwitch', this.form.invest)
    },

    handleFitmentSwitch() {
      this.$emit('handleFitmentSwitch', this.form.fitment)
    },

    handleOneVisible(e) {
      this.oneVisible = e
    },

    handleTwoVisible(e) {
      this.twoVisible = e
    },

    handleThreeVisible(e) {
      this.threeVisible = e
    },

    async handleAccountInfo() {
      const { data } = await getAccountInfo()
      this.brandId = data.brandId
      this.getDetailData()
      console.log(data, 'accountInfo')
    },

    handleCompeteVisible(e) {
      this.competeVisible = e
    },

    handleFollowVisible(e) {
      this.followVisible = e
    },

    async getIndustry() {
      const { data } = await getIndustryClassify()
      this.oneList = data.map(item => ({
        label: item.category,
        value: item.typeCode
      }))
      console.log(data, 1133)
    },

    async handleOneChange(e) {
      this.handleOneData(e)
      this.bigType = e.label
      this.threeList = []
      this.twoInit = ''
      this.threeInit = ''
      this.$emit('handleTypeCode', '')
      console.log(e, 'one')
    },

    async handleOneData(e) {
      const { data } = await getIndustryClassify({
        bigCategory: e.label,
        midCategory: ''
      })
      this.twoList = data.map(item => ({
        label: item.category,
        value: item.typeCode
      }))
    },

    async handleTwoChange(e) {
      this.handleTwoData(e)
      this.threeInit = ''
      this.$emit('handleTypeCode', '')
      console.log(e, 'two')
    },

    async handleTwoData(e) {
      const { data } = await getIndustryClassify({
        bigCategory: this.bigType,
        midCategory: e.label
      })
      this.threeList = data.map(item => ({
        label: item.category,
        value: item.typeCode
      }))
    },

    handleThreeChange(e) {
      this.threeTypeCode = e.value
      this.threeInit = e.label
      this.$emit('handleTypeCode', e.value)
      console.log(e, 'three')
    },

    handleFollowSwitch() {
      this.$emit('handleFollowSwitch', this.form.follow)
    },

    handleCompeteSwitch() {
      this.$emit('handleCompeteSwitch', this.form.compete)
    },

    async getDetailData() {
      const { data } = await getBrandDetail({
        brandId: this.brandId
      })
      this.competeList = data?.competitionBrandList?.map(item => ({
        label: item.brandName,
        value: item.brandId
      }))
      this.followList = data?.recommendBrandList?.map(item => ({
        label: item.brandName,
        value: item.brandId
      }))
      this.$emit('handleBrandDetail', data.brandName)
    },

    handleCompeteChange(e) {
      this.$emit('handleCompeteScan', e.value)
      console.log(e, 'compete-change')
    },

    handleFollowChange(e) {
      this.$emit('handleFollowScan', e.value)
      console.log(e, 'follow-change')
    },

    handleCompete(e) {
      const params = e.map(item => item.value).join()
      this.$emit('handleCompete', params)
      console.log(e, 'compete')
    },

    handleFollow(e) {
      const params = e.map(item => item.value).join()
      this.$emit('handleFollow', params)
    },

    handleRange(e) {
      this.$emit('handleRangeChange', e)
      console.log(e, 'range-change')
    },

    handleDistribute() {
      this.$emit('handleDistribute', this.form.scatter)
    },

    handleSchema(e) {
      this.$emit('handleScatterChange', e)
      console.log(e, 'hot-map')
    },

    async getSchemaData() {
      const { data } = await getConsumeList()
      this.schemaList = data.data.map(item => ({
        label: item.tagName,
        value: item
      }))
    },

    handleRadioChange() {
      this.$emit('handleCircleSwitch', this.form.circle)
    },
    handleStoreChange() {
      this.$emit('handleStoreSwitch', this.form.store)
    }
  },

  components: {
    PeSelect,
    PeAngle,
    ScreenDialog
  }
}
</script>
<style lang="scss">
.border-list {
  .label {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    margin-left: 10px;
    &::after {
      content: '';
      position: absolute;
      left: -10px;
      top: -1px;
      width: 6px;
      height: 18px;
      border-radius: 4px;
      background-color: #41cabf;
    }
  }
  .item-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  .border-item {
    padding: 10px;
    border-radius: 4px;
    &:last-child {
      border: none;
    }
    .item-spec {
      display: flex;
      flex-direction: column;
    }
  }

  .border-top {
    margin-top: 10px;
  }
}
.dark-panel {
  color: #fff;
  .border-item {
    background: rgba(255, 255, 255, 0.2);
  }
}
.panel {
  color: #333;
  .border-item {
    background: rgba(255, 255, 255, 0.2);
  }
}
.mt-10 {
  margin: 10px;
}

.pe-panel-page {
  .l-panel {
    width: 200px !important;
    position: absolute;
    top: 60px;
    left: 10px;
    font-size: 14px;

    .l-item {
      display: flex;
      flex-direction: column;
      .l-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .dot {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        .zs {
          background-color: orange;
        }
        .yy {
          background-color: green;
        }
        .zx {
          background-color: red;
        }
      }
      .notice {
        font-size: 40px;
        font-weight: bold;
        margin: 15px auto 0;
      }
    }
  }
  .right-panel {
    width: 240px;
    padding: 10px;
    position: absolute;
    top: 50px;
    right: 0;
    .chart-item {
      .title {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .pe-charts {
        width: 100%;
        height: 320px;
      }
    }
  }

  .panel {
    padding: 10px;
    background: linear-gradient(
      132deg,
      rgba(255, 255, 255, 0.77) 0%,
      rgba(255, 255, 255, 0.84) 100%
    );
    border-radius: 4px;
    border: 2px solid;
    border-image: linear-gradient(
        180deg,
        rgba(161, 255, 247, 1),
        rgba(201, 230, 228, 1)
      )
      2 2;
    backdrop-filter: blur(2px);
  }
  .dark-panel {
    padding: 10px;
    background: linear-gradient(
      180deg,
      rgba(110, 110, 110, 0.3) 0%,
      rgba(0, 117, 120, 0.2) 100%
    );
    border-radius: 4px;
    border: 2px solid rgba(65, 202, 191, 0.46);
    backdrop-filter: blur(2px);
  }
  .city-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    margin: 10px 0 0 0;
  }
  .light-dialog {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    background: #fff;
  }
  .dark-dialog {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #142929;
    border-radius: 4px;
  }
  .dark-btn {
    background: linear-gradient(
      180deg,
      rgba(110, 110, 110, 0.3) 0%,
      rgba(0, 117, 120, 0.2) 100%
    );
    box-shadow: inset 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    border: 2px solid rgba(65, 202, 191, 0.46);
    backdrop-filter: blur(5px);
    color: #fff;
  }
  .light-btn {
    background: transparent;
    color: #636363;
  }
  .close-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .active-popper {
    background: linear-gradient(
      180deg,
      rgba(99, 255, 250, 0.08) 0%,
      rgba(0, 117, 120, 0.2) 100%
    );
    box-shadow: inset 0px -5px 19px 9px rgba(255, 255, 255, 0.09);
    backdrop-filter: blur(5px);
    border: 2px solid rgba(65, 202, 191, 0.46);
    .el-select-dropdown__list {
      background: none !important;
    }
    .el-select-dropdown__item {
      color: #fff;
    }
    .hover {
      background: none;
      color: none;
      cursor: pointer;
    }
    .selected {
      background: none !important;
      color: #02a7f0 !important;
    }
  }
  .active-select {
    margin-left: 10px;
    .el-input__inner {
      background: linear-gradient(
        180deg,
        rgba(99, 255, 250, 0.08) 0%,
        rgba(0, 117, 120, 0.2) 100%
      );
      box-shadow: inset 0px -5px 19px 9px rgba(255, 255, 255, 0.09);
      backdrop-filter: blur(5px);
      color: #fff;
      border: 2px solid rgba(65, 202, 191, 0.46);
    }
    .el-tag--info {
      background: none;
      border: none;
      color: #fff;
    }
  }
}
</style>
