<template>
  <div class="main">
    <div v-if="currentTagName" class="opera">
      <el-link icon="el-icon-close" @click="removeTag()" class="opera-f"
        >删除
      </el-link>
      <el-popover
        @show="popShow"
        ref="popover"
        :disabled="false"
        placement="right-start"
        width="200"
        trigger="click"
      >
        <el-link slot="reference" icon="el-icon-edit-outline" class="opera-f"
          >重命名</el-link
        >
        <div class="pop">
          <el-input
            v-model="newTagName"
            size="mini"
            placeholder="请输入标签"
            autofocus
          ></el-input>
          <div class="pop-btn">
            <el-button size="mini" @click="closePop">
              取消
            </el-button>
            <el-button size="mini" type="primary" @click="reNameTag()">
              确认
            </el-button>
          </div>
        </div>
      </el-popover>
    </div>

    <div class="saved-search" v-if="tagList.length && false">
      <el-tag
        v-for="item in tagList"
        :effect="
          item.name === (currentTagName ? currentTagName.name : '')
            ? 'dark'
            : 'plain'
        "
        class="tag"
        size="small"
        @click="renderFilter(item)"
      >
        {{ item.name }}
      </el-tag>
    </div>

    <div class="input-main">
      <el-row v-for="item in filterList" :key="item.id" class="el-row">
        <el-col :span="23">
          <search-item
            :source-data="source"
            :render-data="item.data"
            :ref="`filter${item.id}`"
          ></search-item>
        </el-col>
        <el-col :span="1">
          <div class="del-icon">
            <i class="el-icon-delete" @click="delFilter(item.id)"></i>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="add-filter">
      <i
        class="el-icon-circle-plus-outline add-icon"
        @click="addFilterItem"
      ></i>
    </div>
    <div class="footer">
      <!-- <div class="save-this-search">
          <span>是否保存常用查询</span><span><el-switch v-model="isSave"/></span>
          <el-input
            v-show="isSave"
            size="mini"
            placeholder="请输入标签名称"
            v-model="searchName"
            class="search-save-input">
          </el-input>
        </div> -->
      <div>
        <el-button size="small" @click="cancelBtn">取消</el-button>
        <el-button type="primary" size="small" @click="confirmBtn"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import searchItem from './searchItem'
import { getSearchPlus, setSearchPlus } from '@/api/user'
export default {
  name: 'searchPanel',
  components: {
    searchItem
  },

  watch: {
    isSave: function(val) {
      if (val && this.currentTagName) {
        this.searchName = this.currentTagName.name
      }
    }
  },

  data() {
    /**
     * 筛选类型type
     * booklist -> 培育池
     */
    return {
      isSave: false, //是否保存查询条件
      searchName: '', //保存的查询名称
      index: 10086, //默认查询条件列表的id
      tagList: [], //已经保存的搜索项
      source: [], //所有filter的原始数据
      filterList: [
        {
          id: -1,
          data: {
            filterOneSelectData: '', // 第一个输入框的数据
            filterTwoSelectData: '', // 第二个输入框的数据
            filterThreeSelectData: [] // 第三个输入框的数据
          }
        }
      ], //每一项filter 需要缓存回显
      addMax: 9, // 最大筛选项
      type: 'customerView', // 筛选类型 默认是线索培育
      currentTagName: null, // 当前选中的标签
      newTagName: '', // 重命名标签
      cacheType: 108 // 缓存的类型
    }
  },
  mounted() {
    const modalOptions = this.$store.state.plugin.modal.options
    this.source = modalOptions.source // 获取原始数据
    this.addMax = modalOptions.addMax ? modalOptions.addMax : 9 // 获取最大支持筛选项
    this.type = modalOptions.type ? modalOptions.type : 'customerView' // 获取筛选类型
    this.getTagList()
    // const params = localStorage.getItem('filterList')
    // if(params){
    //   this.filterList = JSON.parse(params)
    // }
  },
  methods: {
    getTagList() {
      getSearchPlus({ type: this.cacheType }).then(res => {
        if (res.code === 60000) {
          if (res.data && JSON.parse(res.data) instanceof Array) {
            this.tagList = JSON.parse(res.data)
          }
        }
      })
    },

    popShow() {
      this.newTagName = this.currentTagName ? this.currentTagName.name : '' //信息回填
    },

    closePop() {
      const popover = this.$refs['popover']
      if (popover) {
        popover.doClose()
      }
    },

    // 关闭弹框
    cancelBtn() {
      this.$store.dispatch('hideModal')
    },

    // 关闭弹框获取数据
    confirmBtn() {
      let result = []
      let saveDataList = []
      this.filterList.forEach(item => {
        const refs = this.$refs[`filter${item.id}`]
        if (refs && refs[0]) {
          const { res, saveData, isOk } = refs[0].getResult()
          if (isOk) {
            result.push(res)
          }
          saveDataList.push(saveData)
          item['data'] = saveData
        }
      })
      // 保存搜索项 用于回显
      // localStorage.setItem('filterList', JSON.stringify(this.filterList))
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          searchFilter: result.join(';'),
          refresh: true
        }
      })

      if (this.isSave) {
        // 存在则新增 不存在则新建
        const tagNameList = this.tagList.map(item => item.name)
        if (!tagNameList.includes(this.searchName)) {
          this.tagList.push({
            name: this.searchName,
            data: saveDataList
          })
        } else {
          this.tagList.forEach(item => {
            if (item.name === this.searchName) {
              item.data = saveDataList
            }
          })
        }
        this.saveFilter()
      }
      this.$store.dispatch('hideModal')
    },

    // 保存筛选项
    saveFilter() {
      const params = {
        type: this.cacheType,
        cacheJson: JSON.stringify(this.tagList)
      }
      setSearchPlus(params)
    },

    // 渲染保存的数据项
    renderFilter(item) {
      this.currentTagName = item
      this.filterList = []
      item.data &&
        item.data.forEach((item, index) => {
          this.filterList.push({
            id: index,
            data: {
              filterOneSelectData: item.filterOneSelectData,
              filterTwoSelectData: item.filterTwoSelectData,
              filterThreeSelectData: item.filterThreeSelectData
            }
          })
        })
    },

    // 删除保存的数据项
    removeTag() {
      const name = this.currentTagName.name
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: `确认删除 '${name}' 常用查询`,
        onClose: res => {
          if (res === 'confirm') {
            this.tagList = this.tagList.filter(item => item.name !== name)
            this.saveFilter()
            this.currentTagName = null
          }
        },
        type: 'warning'
      })
    },

    // 重命名标签
    reNameTag() {
      const name = this.currentTagName.name
      if (!this.newTagName) {
        return
      }
      if (this.newTagName === name) {
        this.closePop()
        return
      }
      const _name = this.tagList.map(item => item.name)
      if (_name.includes(this.newTagName)) {
        this.$store.dispatch('showTip', {
          text: '已经存在的标签',
          type: 'error'
        })
        return
      }
      this.tagList.forEach(item => {
        if (item.name === name) {
          item.name = this.newTagName
        }
      })
      this.$forceUpdate()
      this.saveFilter()
      this.newTagName = ''
      this.closePop()
      this.$store.dispatch('showTip', {
        text: '修改成功',
        type: 'success'
      })
    },
    // 增加筛选项
    addFilterItem() {
      if (this.filterList.length >= this.addMax) {
        this.$store.dispatch('showTip', {
          text: `最多设置${this.addMax}个筛选项`,
          type: 'error'
        })
        return
      }
      let ids = this.filterList.map(item => item.id)
      let maxId = Math.max.apply(null, ids)
      this.index = maxId + 1
      this.filterList.push({
        id: this.index,
        data: {
          filterOneSelectData: '',
          filterTwoSelectData: '',
          filterThreeSelectData: []
        }
      })
      this.index += 1
    },

    // 删除筛选项
    delFilter(index) {
      if (this.filterList.length <= 1) {
        this.$store.dispatch('showTip', {
          text: '最少有一个筛选项',
          type: 'error'
        })
        return
      }
      this.filterList = this.filterList.filter(item => item.id !== index)
    }
  }
}
</script>

<style scoped>
.main {
  padding: 10px 20px 0 20px;
  box-sizing: border-box;
}
.tag {
  margin-bottom: 10px;
  min-width: 50px;
  text-align: center;
}
.input-main {
  height: auto;
}
.input-main > .el-row:last-child {
  margin-bottom: 0 !important;
}
.input-main > .el-row {
  margin-bottom: 10px;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: relative;
}
.footer::before {
  content: '';
  height: 1px;
  width: calc(100% + 40px);
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: -20px;
  position: absolute;
  transform: scaleY(0.5);
}
.add-filter {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 22px;
  color: #6062667d;
}
.add-icon:hover {
  color: #41cabf;
  cursor: pointer;
}
.del-icon {
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #6062667d;
  cursor: pointer;
}
.del-icon:hover {
  color: #41cabf;
}
.save-this-search {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.save-this-search > span:first-child {
  margin-right: 5px;
}
.search-save-input {
  width: 200px;
  margin-left: 10px;
}
.saved-search {
  width: 100%;
  background-color: #d8dcdc1f;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px 0 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tag {
  margin: 0 10px 10px 0;
  cursor: pointer;
}

.saved-search > .tag:last-child {
  margin-right: auto;
}
.opera {
  width: 100%;
  padding: 0 0 10px 10px;
  box-sizing: border-box;
}

.opera-f {
  margin-right: 10px;
  font-size: 12px;
}

.pop-btn {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  box-sizing: border-box;
}
</style>
