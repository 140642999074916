<template>
  <div class="chanceView-main" v-loading="loading">
    <div class="header">
      <div>
        商机信息
      </div>
      <div>
        <el-button type="primary" size="small" @click="createChance">新建商机</el-button>
      </div>
    </div>

    <el-scrollbar class="main dt-dropdown-dt" :key="refreshKey">
      <span class="no-data" v-show="chanceList && !chanceList.length">暂无数据</span>
      <el-row v-infinite-scroll="load" class="row-main">
        <el-col :span="24" v-for="item in chanceList" :key="item.id" class="row-item">
          <div class="list-item">
            <el-row :gutter="20" align="middle" type="flex">
              <el-col :span="20">
                <el-row :gutter="10" style="margin-bottom: 10px">
                  <el-col :span="8">
                    <span class="key">商机名称:</span><span class="value">{{ item.nicheName }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="key">预估签单金额:</span><span class="value">{{ item.predictBillMoney }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="key">商机阶段:</span><span class="value">{{ item.nicheStateDesc }}</span>
                  </el-col>
                </el-row>

                <el-row :gutter="10" style="margin-bottom: 10px">
                  <el-col :span="8">
                    <span class="key">签单决策人:</span><span class="value">{{ item.contactList | formatContactList }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="key">预估签单日期:</span><span class="value">{{ item.predictBillDate }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="key">负责人:</span><span class="value">{{ item.followName  }}</span>
                  </el-col>
                </el-row>

                <el-row :gutter="10" style="margin-bottom: 10px">
                  <el-col :span="24">
                    <el-row :gutter="10">
                      <el-col :span="2">
                        <span class="key1">备注:</span>
                      </el-col>
                      <el-col :span="22">
                        <span class="value">{{ item.nicheRemark }}</span>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="4">
                <span class="remove">
                  <span class="edit" @click="edit(item)">编辑</span>
                  <span class="line">|</span>
                  <span class="edit" @click="delChance(item)">删除</span>
                  <span class="line">|</span>
                </span>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-scrollbar>
  </div>
</template>

<script>
import { delNiche, nicheList } from '@/api/customerpond'
import createChance from '@/views/customerpond/chanceManage/dialog/createChance'
export default {
  name: 'chanceView',
  data () {
    return {
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: 0
      },
      chanceList: [],

      refreshKey: 0,
      loading: false
    }
  },
  props: {
    keyData: {
      type: Object,
      default: ()=>{}
    },
    activeTabs: {
      type: [ Number, String ],
      default: '1'
    }
  },
  filters:{
    formatContactList(val){
      if(val && val.length){
        return val.map(i=> i.contact).join(',')
      }
      return ''
    }
  },
  mounted () {
    this.getChanceList()
  },
  computed: {},
  methods: {

    load(){
      if(this.pageStatus.total > this.chanceList.length || this.pageStatus.total === 0){
        this.pageStatus.pageNo += 1
        this.getChanceList()
      }
    },

    getChanceList () {
      const params = {
        companyUuid: this.keyData.companyUuid,
        pageId: '2001',
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize
      }
      this.loading = true
      nicheList(params).then(res=>{
        this.loading = false
        if(res.code === 60000){
          this.chanceList = [...this.chanceList, ...res.data.data]
          this.pageStatus.total = res.data.page.total
        }
      })
    },

    reset(){
      this.pageStatus.pageNo = 1
      this.pageStatus.pageSize = 20
      this.pageStatus.total = 1
      this.refreshKey += 1
      this.chanceList = []
      this.getChanceList()
    },

    // 新建商机
    createChance(){
      this.$store.dispatch('showModal', {
        title: '新建商机',
        view: createChance,
        size: '800px',
        options: {
          type: 'create',
          isPond: true,
          cname: this.keyData.companyName,
          cuuid: this.keyData.companyUuid,
          activeTabs: this.activeTabs
        },
        onClose: res => {
          if(res.refresh){
            this.reset()
          }
        }
      })
    },

    edit(data){
      this.$store.dispatch('showModal', {
        title: '编辑商机',
        view: createChance,
        size: '800px',
        options: {
          type: 'edit',
          chanceData: data
        },
        onClose: res => {
          if(res.refresh){
            this.reset()
          }
        }
      })
    },

    delChance(data){
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: `确认删除 ${ data.nicheName } ？`,
        type: 'error',
        onClose: res => {
          if(res === 'confirm'){
            this.deleteChangeFunc(data.id)
          }
        }
      })
    },

    deleteChangeFunc(id){
      delNiche({id: id}).then(res=>{
        if(res.code === 60000){
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
          this.reset()
        }
      })
    },
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.chanceView-main{
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 20px 0;
  box-sizing: border-box;
}
.header{
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
}
.header::before{
  content: '';
  display: inline-block;
  height: 1px;
  width: calc( 100% + 40px );
  position: absolute;
  bottom: 0;
  left: -20px;
  transform: scaleY(.5);
  background-color: rgba(0,0,0,.1);
}
.main{
  width: 100%;
  height: calc( 100vh - 70px );
  position: relative;
}
.no-data{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #8c939d;
}
.key, .value{
  font-size: 12px;
}
.key{
  margin-right: 5px;
}
.key1{
  font-size: 12px;
}
.value{
  color: rgba(0,0,0,0.5);
}
.row-main{
  width: calc( 100% - 40px );
  margin: 0 auto;
}
.row-item{
  position: relative;
  padding: 10px 0;
  box-sizing: border-box;
}
.row-item::before{
  content: '';
  height: 1px;
  width: 100%;
  display: inline-block;
  transform: scaleY(.5);
  background-color: rgba(0,0,0,.1);
  position: absolute;
  bottom: 0;
  left: 0;
}
.edit{
  font-size: 12px;
  color: #41cabf;
  cursor: pointer;
}
.remove > span:last-child{
  display: none;
}
.line{
  color: rgba(0,0,0,.3);
}
</style>
