var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("span", [_vm._v("选择菜单栏：")]),
        _vm._v(" "),
        _c(
          "el-select",
          {
            attrs: { placeholder: "请选择客户状态" },
            on: { change: _vm.getTags },
            model: {
              value: _vm.mark,
              callback: function ($$v) {
                _vm.mark = $$v
              },
              expression: "mark",
            },
          },
          [
            _c("el-option", { attrs: { label: "客户公海", value: "1" } }),
            _vm._v(" "),
            _c("el-option", { attrs: { label: "联系人培育", value: "2" } }),
            _vm._v(" "),
            _c("el-option", { attrs: { label: "数据洞察", value: "3" } }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "dt-permission",
            rawName: "v-dt-permission",
            value: "系统管理-字段设置-查看字段",
            expression: "'系统管理-字段设置-查看字段'",
          },
        ],
        staticClass: "app-body",
        staticStyle: { "padding-bottom": "40px" },
      },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              "header-cell-style": { "text-align": "center" },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "name",
                align: "center",
                label: "分类",
                width: "200",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "chooseType",
                align: "center",
                label: "选择模式",
                width: "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.choice == 1 ? "单选" : "多选")),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "具体分类项" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.name == "企业标签"
                        ? [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "rgb(0, 153, 204)" },
                                attrs: { icon: "el-icon-plus", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAddTag("add", scope.row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "rgb(0, 153, 204)" },
                                  },
                                  [_vm._v("添加标签组")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _vm._l(scope.row.tags, function (item) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "tag-flex" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                              display: "inline-block",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                              color: "rgb(0 153 204)",
                                            },
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAddTag(
                                                  "edit",
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑标签组")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                              color: "rgb(0 153 204)",
                                            },
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editTagField(
                                                  item,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑标签")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "tag-wrapper" },
                                      _vm._l(item.tags, function (it) {
                                        return _c(
                                          "el-tag",
                                          {
                                            staticStyle: {
                                              margin: "10px 10px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              size: "mini",
                                              "disable-transitions": true,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(it.name) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        : [
                            _vm.showEdit || scope.row.name === "个人标签"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editField(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "rgb(0 153 204)",
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(scope.row.tags, function (item) {
                              return _c(
                                "el-tag",
                                {
                                  key: item.id,
                                  staticStyle: {
                                    margin: "10px 10px",
                                    cursor: "pointer",
                                  },
                                  attrs: {
                                    size: "mini",
                                    "disable-transitions": true,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.name) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                          ],
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "dia-model",
          {
            ref: "newCustomer",
            staticClass: "pond-form-input",
            attrs: { dialogStatus: _vm.tagEditModel },
            on: { submitContBtn: _vm.tagNameEdit },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  height: "200px",
                  "padding-left": "20px",
                  "padding-top": "20px",
                },
                attrs: { slot: "modalCont" },
                slot: "modalCont",
              },
              [
                _c(
                  "el-form",
                  { ref: "form", attrs: { "label-width": "80px" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "顺序" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入排序编号（1为最前）" },
                          model: {
                            value: _vm.editInfo.tagsSort,
                            callback: function ($$v) {
                              _vm.$set(_vm.editInfo, "tagsSort", $$v)
                            },
                            expression: "editInfo.tagsSort",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "分类内容" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入分类内容" },
                          model: {
                            value: _vm.editInfo.tagName,
                            callback: function ($$v) {
                              _vm.$set(_vm.editInfo, "tagName", $$v)
                            },
                            expression: "editInfo.tagName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }