var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "risk-tag-wrapper" },
    [
      _vm.getRisk(_vm.riskTag)
        ? [
            _c(
              "PePopover",
              { attrs: { PeHoverTitle: "风险" } },
              [
                _vm._l(_vm.getRiskTags(_vm.riskTag), function (item) {
                  return _c("template", { slot: "content" }, [
                    _c("p", { staticClass: "riskList" }, [
                      _vm._v(_vm._s(item)),
                    ]),
                  ])
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "risk-tag",
                    attrs: { slot: "txt" },
                    slot: "txt",
                  },
                  [_vm._v("风险")]
                ),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }