var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "company-group" },
    [
      _c("div", { staticClass: "title" }, [
        _c("span", { on: { click: _vm.handleBack } }, [_vm._v("企业族群")]),
        _vm._v(" "),
        _c("span", [_vm._v(">")]),
        _vm._v(" "),
        _c("span", [_vm._v("详情")]),
        _vm._v(" "),
        _vm.IsAccess == 0 || _vm.IsAccess == 1
          ? _c(
              "span",
              {
                staticClass: "normal-data",
                on: { click: _vm.handleNormalData },
              },
              [_vm._v(_vm._s(_vm.$updateText))]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "company-info" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.companyInfo.name)),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.groupList, function (item) {
            return item.show
              ? _c(
                  "li",
                  { key: item.id },
                  [
                    _c("p", [_vm._v(_vm._s(item.name) + ":")]),
                    _vm._v(" "),
                    _c("PeUnit", {
                      attrs: {
                        companyInfo: _vm.companyInfo,
                        unit: item.unit,
                        getKey: item.getKey,
                        unitColor: "#d9001b",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e()
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "main-company" }, [
        _c("span", [_vm._v("族群主公司:")]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "company-name-click",
            on: { click: _vm.handleMainEnter },
          },
          [_vm._v(_vm._s(_vm.companyInfo.mainCompanyName))]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "company-desc" }, [
        _vm._v(_vm._s(_vm.companyInfo.introduction)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "company-tabs" },
        _vm._l(_vm.tabs, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class:
                _vm.currentIndex == index ? "active not-active" : "not-active",
              on: {
                click: function ($event) {
                  return _vm.handleTableClick(item, index)
                },
              },
            },
            [
              _c("p", [_vm._v(_vm._s(item.name))]),
              _vm._v(" "),
              item.num
                ? _c("span", [_vm._v("(" + _vm._s(item.num) + ")")])
                : [
                    !_vm.loadingFlag
                      ? _c("span", [_vm._v("(" + _vm._s(item.num) + ")")])
                      : _c("i", { staticClass: "el-icon-loading" }),
                  ],
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "company-table" },
        [
          _c("PeTable", {
            attrs: {
              peTableData: _vm.tableList,
              peColumnList: _vm.tableColumn,
              pePage: _vm.tablePage,
              peLoading: _vm.peLoading,
              IsAccess: _vm.IsAccess,
            },
            on: {
              sortPeChange: _vm.handleSortPeChange,
              handleSelectCommand: _vm.handleSelectCommand,
              handleCurrentPePage: _vm.handleCurrentPePage,
              handleSizePeChange: _vm.handleSizePeChange,
            },
            scopedSlots: _vm._u([
              {
                key: "companyName",
                fn: function ({ scope }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "company-name-click",
                        on: {
                          click: function ($event) {
                            return _vm.handleEnter(scope.data, scope.index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.data.companyName))]
                    ),
                  ]
                },
              },
              {
                key: "appLogo",
                fn: function ({ scope }) {
                  return [
                    _c("div", { staticClass: "app-apply" }, [
                      _c("img", {
                        staticClass: "app-logo",
                        attrs: { src: scope.data.appLogo },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "app-fill" }, [
                        _c(
                          "p",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleAppDetail(scope.data)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.data.appName) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("span", [_vm._v(_vm._s(scope.data.appType))]),
                            _vm._v(" "),
                            scope.data.storeUrl
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "icon-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleOpenStore(
                                          scope.data.storeUrl
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("IconHover", {
                                      attrs: {
                                        iconClass: "app-share",
                                        hoverMessage: "APP发布网站",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "bdName",
                fn: function ({ scope }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "company-name-click",
                        on: {
                          click: function ($event) {
                            return _vm.handleEnter(scope.data, scope.index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.data.bdName))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "PeDialog",
        {
          attrs: {
            PeDialogTitle: "APP详情",
            PeDialogShow: _vm.PeDialogShow,
            PeDialogBtnCancel: "关闭",
            PeDialogShowSure: false,
          },
          on: {
            handlePeDialogClose: function ($event) {
              _vm.PeDialogShow = false
            },
          },
        },
        [
          _c("div", { staticClass: "app-content" }, [
            _c("img", {
              staticClass: "appLogo",
              attrs: { src: _vm.appDetail.appLogo },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "detail" }, [
              _c(
                "div",
                { staticClass: "top" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.appDetail.appName)),
                  ]),
                  _vm._v(" "),
                  _vm.appDetail.storeUrl
                    ? _c(
                        "el-button",
                        {
                          staticClass: "icon-btn",
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.handleOpenStore(_vm.appDetail.storeUrl)
                            },
                          },
                        },
                        [
                          _c("IconHover", {
                            attrs: {
                              iconClass: "app-share",
                              hoverMessage: "APP发布网站",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "introduce" }, [
                _vm._v(_vm._s(_vm.appDetail.appIntroduction)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("APP ID")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.bundleId)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("分类")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.appType)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("应用市场")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.appStore)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("价格")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.price)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("开发者")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.developer)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("开发者所属企业")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.companyName)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("发布地区")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.publishArea)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("综合评分")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.appScore)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("评分数/评论数")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.comments)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("下载量")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.totalDownloadNum)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("发布/上架日期")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.lastReleaseDate)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("最后版本更新")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.versionUpdatedTime)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("大小")]),
              _vm._v(" "),
              _c("div", { staticClass: "item-fr" }, [
                _vm._v(_vm._s(_vm.appDetail.appSize)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("包名")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-fr" },
                [
                  _c("HoverMsg", [
                    _vm._v(" " + _vm._s(_vm.appDetail.appPackage)),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("SDK")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-fr" },
                [_c("HoverMsg", [_vm._v(" " + _vm._s(_vm.appDetail.sdk))])],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-fl" }, [_vm._v("SDK服务商")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-fr" },
                [
                  _c("HoverMsg", [
                    _vm._v(" " + _vm._s(_vm.appDetail.sdkProvider)),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }