var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schema-group" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("div", { staticClass: "title-fl" }, [
            _c("span", { on: { click: _vm.handleBack } }, [
              _vm._v(_vm._s(_vm.cloudName)),
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(">")]),
            _vm._v(" "),
            _c("span", [_vm._v("详情")]),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.handleScreen },
            },
            [
              _c("i", { staticClass: "el-icon-full-screen el-icon--left" }),
              _vm._v("数据大屏"),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "company-info" }, [
        _c("div", { staticClass: "layout-wrapper" }, [
          _c("div", { staticClass: "top" }, [
            _c(
              "div",
              { staticClass: "top-fl" },
              [
                _vm.companyInfo.tagImgUrl
                  ? _c("img", {
                      staticClass: "pe-icon",
                      attrs: { src: _vm.companyInfo.tagImgUrl },
                    })
                  : _c("Icon", { attrs: { type: "img", peClass: "default" } }),
                _vm._v(" "),
                _vm.layoutAgency
                  ? _c("span", { staticClass: "schema" }, [
                      _vm._v(_vm._s(_vm.layoutAgency) + "赛道布局"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.layoutCompany
                  ? _c("span", { staticClass: "schema" }, [
                      _vm._v(_vm._s(_vm.layoutCompany)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "name" }, [
                  _vm._v(" " + _vm._s(_vm.companyInfo.tagName)),
                ]),
                _vm._v(" "),
                _c("svg-icon", {
                  staticStyle: { "font-size": "30px" },
                  attrs: { iconClass: "hot_schema" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "num-item" }, [
                  _c("div", { staticClass: "hot-value" }, [
                    _vm._v(_vm._s(_vm.companyInfo.hot)),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "top-fr" }, [
              _c("div", { staticClass: "num-item" }, [
                _c("div", { staticClass: "num-name" }, [_vm._v("企业数")]),
                _vm._v(" "),
                _c("div", { staticClass: "num-value-show" }, [
                  _vm._v(_vm._s(_vm.companyInfo.tagCnt)),
                ]),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "line" }),
              _vm._v(" "),
              _c("div", { staticClass: "num-item" }, [
                _c("div", { staticClass: "num-name" }, [_vm._v("投资事件")]),
                _vm._v(" "),
                _c("div", { staticClass: "num-value-show" }, [
                  _vm._v(_vm._s(_vm.companyInfo.vcEventCnt)),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "new-tab" }, [
            _c("div", { staticClass: "panel-fl" }, [
              _c("div", { staticClass: "tab-first" }, [
                _c(
                  "div",
                  { staticClass: "panel width-30" },
                  [
                    _c("div", { staticClass: "num-item" }, [
                      _c("div", { staticClass: "num-name" }, [
                        _vm._v("融资金额(￥亿元)"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "num-value" }, [
                        _vm._v(_vm._s(_vm.companyInfo.vcSumAmount)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("Icon", { attrs: { type: "img", peClass: "pe_money" } }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "panel width-30" },
                  [
                    _c("div", { staticClass: "num-item" }, [
                      _c("div", { staticClass: "num-name" }, [
                        _vm._v("上市企业市值(￥亿元)"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "num-value" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.companyInfo.marketAmount) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("Icon", {
                      attrs: { type: "img", peClass: "pe_market" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "panel tab-end" }, [
                _c("div", { staticClass: "progress-wrap" }, [
                  _c("div", {
                    ref: "qyChart",
                    staticStyle: { width: "100%", height: "280px" },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tab-list" }, [
                  _c("div", { staticClass: "tab-item" }, [
                    _c("div", { staticClass: "tab-fl" }, [_vm._v("A")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tab-fr" }, [
                      _c("div", { staticClass: "num-item" }, [
                        _c("div", { staticClass: "num-value" }, [
                          _vm._v(_vm._s(_vm.companyInfo.aGuCnt)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "num-name" }, [
                          _vm._v("A股企业数"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "tab-item" }, [
                    _c("div", { staticClass: "tab-fl" }, [_vm._v("港")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tab-fr" }, [
                      _c("div", { staticClass: "num-item" }, [
                        _c("div", { staticClass: "num-value" }, [
                          _vm._v(_vm._s(_vm.companyInfo.gGuCnt)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "num-name" }, [
                          _vm._v("港股企业数"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "tab-item" }, [
                    _c("div", { staticClass: "tab-fl" }, [_vm._v("科")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tab-fr" }, [
                      _c("div", { staticClass: "num-item" }, [
                        _c("div", { staticClass: "num-value" }, [
                          _vm._v(_vm._s(_vm.companyInfo.kcbGuCnt)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "num-name" }, [
                          _vm._v("科创板企业数"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "tab-item" }, [
                    _c("div", { staticClass: "tab-fl" }, [_vm._v("中")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tab-fr" }, [
                      _c("div", { staticClass: "num-item" }, [
                        _c("div", { staticClass: "num-value" }, [
                          _vm._v(_vm._s(_vm.companyInfo.zgGuCnt)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "num-name" }, [
                          _vm._v("中概股企业数"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "panel panel-fr" }, [
              _c("div", { staticClass: "chart-top" }, [
                _c(
                  "div",
                  { staticClass: "rank" },
                  [
                    _c("Icon", {
                      attrs: { peClass: "el-icon-s-data", size: "20" },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("地域企业数排名")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "more", on: { click: _vm.handleMore } },
                  [
                    _c("span", [_vm._v("查看更多")]),
                    _vm._v(" "),
                    _c("Icon", {
                      attrs: { peClass: "el-icon-video-play", size: "20" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                ref: "areaDom",
                staticClass: "area-chart",
                staticStyle: { width: "100%", height: "400px" },
              }),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "company-desc" }, [
        _vm._v(_vm._s(_vm.companyInfo.introduction)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "schema-menu" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "el-menu",
              {
                attrs: {
                  "default-active": _vm.defaultIndex,
                  "default-openeds": ["2"],
                },
                on: { select: _vm.handleTableClick },
              },
              [
                _vm._l(_vm.elMenuList, function (item, index) {
                  return [
                    !item.child
                      ? [
                          _c(
                            "el-menu-item",
                            { key: index, attrs: { index: item.id } },
                            [
                              _c("svg-icon", {
                                staticStyle: { "font-size": "20px" },
                                attrs: { iconClass: item.icon },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(" " + _vm._s(item.name))]),
                              _vm._v(" "),
                              item.count != null
                                ? [
                                    !_vm.loadingFlag
                                      ? _c("span", [
                                          _vm._v(
                                            "(" + _vm._s(item.count) + ")"
                                          ),
                                        ])
                                      : _c("i", {
                                          staticClass: "el-icon-loading",
                                        }),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              item.isVip
                                ? _c("span", [
                                    _c("img", {
                                      staticClass: "is-vip",
                                      attrs: {
                                        width: "15",
                                        height: "15",
                                        src: "https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/buyvip.png",
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      : [
                          _c(
                            "el-submenu",
                            { key: item.id, attrs: { index: item.id } },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("svg-icon", {
                                    staticStyle: { "font-size": "20px" },
                                    attrs: { iconClass: item.icon },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(item.child, function (it) {
                                return [
                                  _c(
                                    "el-menu-item",
                                    { key: it.id, attrs: { index: it.id } },
                                    [
                                      _c("span", [_vm._v(_vm._s(it.name))]),
                                      _vm._v(" "),
                                      it.count != null
                                        ? [
                                            !_vm.loadingFlag
                                              ? _c("span", [
                                                  _vm._v(
                                                    "(" + _vm._s(it.count) + ")"
                                                  ),
                                                ])
                                              : _c("i", {
                                                  staticClass:
                                                    "el-icon-loading",
                                                }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "current-name" }, [
            _vm._v(_vm._s(_vm.currentIndexName)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "schema-table" },
            [
              _vm.currentIndexName == "投资事件"
                ? [
                    _c("PeFinance", {
                      attrs: {
                        tableList: _vm.investList,
                        investProps: _vm.investProps,
                        peLoading: _vm.investLoading,
                        isMore: _vm.isMore,
                      },
                      on: {
                        handleMoreEvent: _vm.handleMoreEvent,
                        handleGroup: _vm.handleGroup,
                        handleCompanyDetail: _vm.handleCompanyDetail,
                      },
                    }),
                  ]
                : _vm.currentIndexName == "数据洞察"
                ? [
                    _c("PeDiagram", {
                      attrs: {
                        vcAgency: _vm.layoutAgency,
                        tagName: _vm.pageParams.tagName,
                      },
                      on: { handleChartClick: _vm.handleChartClick },
                    }),
                    _vm._v(" "),
                    _c(
                      "PeDraw",
                      {
                        attrs: { peDrawerShow: _vm.peChartShow },
                        on: { handleDrawClose: _vm.handleChartClose },
                      },
                      [
                        _c("PeFinance", {
                          attrs: {
                            peLoading: _vm.peChartLoading,
                            tableList: _vm.peChartList,
                            investProps: _vm.peChartProps,
                            isMore: _vm.isChartMore,
                          },
                          on: {
                            handleMoreEvent: _vm.handleChartEvent,
                            handleGroup: _vm.handleGroup,
                            handleCompanyDetail: _vm.handleCompanyDetail,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm.currentIndexName == "行业图谱"
                ? [
                    _c("PeChart", {
                      attrs: { parentName: _vm.layoutAgency },
                      on: { handleReplaceDetail: _vm.handleReplaceDetail },
                    }),
                  ]
                : [
                    _vm.currentIndexName == "未上市企业" ||
                    _vm.currentIndexName == "上市企业" ||
                    _vm.currentIndexName == "全部企业" ||
                    _vm.currentIndexName == "国外企业"
                      ? [
                          _c("PeClassify", {
                            attrs: {
                              filterList: _vm.filterList,
                              resetFlag: _vm.resetFlag,
                            },
                            on: {
                              handleGetFilter: _vm.handleGetFilter,
                              handleTime: _vm.handleTime,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "search-content" },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder: "输入公司名称查询",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.pageParams.searchVal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageParams, "searchVal", $$v)
                                  },
                                  expression: "pageParams.searchVal",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { margin: "0 10px" },
                                  attrs: { type: "primary", size: "mini" },
                                  on: { click: _vm.handleInquire },
                                },
                                [_vm._v("查询")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: { click: _vm.handleReset },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "PeDraw",
                      {
                        attrs: { peDrawerShow: _vm.peDrawerShow },
                        on: { handleDrawClose: _vm.handleDrawClose },
                      },
                      [
                        _c("PeFinance", {
                          attrs: {
                            tableList: _vm.investList,
                            peLoading: _vm.investLoading,
                            isMore: _vm.isMore,
                            investProps: _vm.investProps,
                          },
                          on: {
                            handleMoreEvent: _vm.handleMoreEvent,
                            handleGroup: _vm.handleGroup,
                            handleCompanyDetail: _vm.handleCompanyDetail,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "PeDraw",
                      {
                        attrs: { peDrawerShow: _vm.peSumShow },
                        on: { handleDrawClose: _vm.handleSumClose },
                      },
                      [
                        _c("PeFinance", {
                          attrs: {
                            tableList: _vm.peSumList,
                            peLoading: _vm.peSumLoading,
                            isMore: _vm.peSumMore,
                            investProps: _vm.peSumProps,
                          },
                          on: {
                            handleMoreEvent: _vm.handleMorePeSum,
                            handleGroup: _vm.handleGroup,
                            handleCompanyDetail: _vm.handleCompanyDetail,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("PeTable", {
                      attrs: {
                        peTableData: _vm.tableList,
                        peColumnList: _vm.tableColumn,
                        pePage: _vm.tablePage,
                        peLoading: _vm.peLoading,
                        IsAccess: _vm.IsAccess,
                      },
                      on: {
                        sortPeChange: _vm.handleSortPeChange,
                        handleSelectCommand: _vm.handleSelectCommand,
                        handleCurrentPePage: _vm.handleCurrentPePage,
                        handleSizePeChange: _vm.handleSizePeChange,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "company_name",
                          fn: function ({ scope }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "company-name-click",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEnter(
                                        scope.data,
                                        scope.index
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.data.company_name))]
                              ),
                              _vm._v(" "),
                              _c("Tags", {
                                attrs: { tagList: scope.data.tags },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "abnormal" },
                                [
                                  _c("PeRisk", {
                                    attrs: { riskTag: scope.data.risk },
                                  }),
                                  _vm._v(" "),
                                  scope.data.company_status == "注销"
                                    ? _c("span", { staticClass: "qy-cancel" }, [
                                        _vm._v("注销"),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "name",
                          fn: function ({ scope }) {
                            return [
                              _c(
                                "span",
                                {
                                  class: scope.data.agencyUuid
                                    ? "company-name-click"
                                    : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleGroup(scope.data)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.data.name))]
                              ),
                            ]
                          },
                        },
                        {
                          key: "investCnt",
                          fn: function ({ scope }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "company-name-click",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleInvest(scope.data)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.data.investCnt))]
                              ),
                            ]
                          },
                        },
                        {
                          key: "investSumCnt",
                          fn: function ({ scope }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "company-name-click",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleInvestSum(scope.data)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.data.investSumCnt))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "PeDraw",
        {
          attrs: { peDrawerShow: _vm.drawShow },
          on: {
            handleDrawOpen: _vm.handleDrawOpen,
            handleDrawClose: function ($event) {
              _vm.drawShow = false
            },
          },
        },
        [
          _c("div", { staticClass: "draw-title" }, [
            _vm._v(_vm._s(_vm.dialogObj.tagName)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc-wrap" }, [
            _c("p", { staticClass: "link", on: { click: _vm.handleList } }, [
              _vm._v("企业列表"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc", staticStyle: { margin: "5px 0" } }, [
              _vm._v("企业数:" + _vm._s(_vm.dialogObj.total) + "家"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v("总热度:" + _vm._s(_vm.dialogObj.hot)),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.insightList, function (item) {
            return [
              item.show
                ? _c("div", {
                    key: item.id,
                    ref: "peChartDom",
                    refInFor: true,
                    staticClass: "chart-draw",
                    staticStyle: { width: "100%", height: "400px" },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }