var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body" }, [
    _c("div", {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      ref: "myChart",
      staticClass: "body",
    }),
    _vm._v(" "),
    !_vm.chartData.length
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "body tip",
          },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
              },
              [_vm._v("\n      无数据\n    ")]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }