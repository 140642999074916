var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-msg" },
    [
      _c(
        "el-popover",
        { attrs: { placement: "top-start", trigger: "hover" } },
        [
          _vm._t("default", function () {
            return [_vm._v(" " + _vm._s(_vm.hoverMessage))]
          }),
          _vm._v(" "),
          _c("svg-icon", {
            attrs: { slot: "reference", "icon-class": _vm.iconClass },
            slot: "reference",
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }