var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticStyle: {
        width: "320px",
        "max-height": "100%",
        "overflow-x": "hidden",
        "overflow-y": "auto",
      },
    },
    _vm._l(_vm.list, function (node, nodeIndex) {
      return _c(
        "li",
        {
          key: _vm.guid(node),
          staticClass: "li-style",
          class: { "active-li": _vm.activeList[_vm.level - 1] === node.id },
          on: {
            click: function ($event) {
              return _vm.handleClick(node, nodeIndex, _vm.level)
            },
            mousemove: function ($event) {
              return _vm.handleMouseMove(node, nodeIndex, _vm.level)
            },
            mouseout: _vm.handleMouseOut,
          },
        },
        [
          _c(
            "p",
            {
              directives: [{ name: "toolTip", rawName: "v-toolTip" }],
              staticClass: "li-label-style",
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.onlyLast || (_vm.onlyLast && node.isLeaf),
                      expression: "!onlyLast || (onlyLast && node.isLeaf)",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("el-checkbox", {
                    attrs: { disabled: node.disabled },
                    on: {
                      change: function ($event) {
                        return _vm.handleCheck($event, node)
                      },
                    },
                    model: {
                      value: node.checked,
                      callback: function ($$v) {
                        _vm.$set(node, "checked", $$v)
                      },
                      expression: "node.checked",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { "margin-left": "5px" } }, [
                _vm._v(_vm._s(node[_vm.labelKey])),
              ]),
              _vm._v(" "),
              node.childNodes && node.childNodes.length > 0
                ? _c("i", { staticClass: "li-label-icon el-icon-arrow-right" })
                : _vm._e(),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }