<template>
    <div class="body-code">
        <div class="er-code" ref="code">

        </div>
    </div>
</template>

<script>
    import QRCode from "qrcodejs2"
    export default {
        name: "bindWechat",
        data(){
          return {
            url:''
          }
        },
        mounted() {
          const modalParams = this.$store.state.plugin.modal.options;
          this.url = modalParams.url;
          this.createCode()
        },
        methods:{
          createCode(){
            const codeEl = this.$refs['code']
            new QRCode(codeEl, this.url);
          }
        }
    }
</script>

<style scoped>
  .body-code{
    padding: 40px 0 70px 0;
    box-sizing: border-box;
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .er-code{
    height: 256px;
    width: 256px;
    position: relative;
  }
  .er-code::after{
    position: absolute;
    display: block;
    width: 100%;
    text-align: center;
    bottom: -40px;
    left: 0;
    font-size: 18px;
    content: '扫描绑定微信';
  }
</style>
