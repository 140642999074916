<template>
  <div class="app-container">
    <div class="thread-bag" v-dt-permission="'微营销管理-落地页管理-新建落地页'">
      <div
        class="search-botton"
        style="background:#78D9D1;float:right;"
        @click="createdNewLand"
      >
        新建落地页
      </div>
    </div>
    <div class="h-body">
      <land-list></land-list>
    </div>
  </div>
</template>

<script>
import LandList from "./landlist";
export default {
  data() {
    return {};
  },
  created() {},
  components: {
    LandList
  },
  methods: {
    createdNewLand() {
      localStorage.setItem("PageId", "");
      this.$router.push("/newlandpage/newlandpage");
    }
  }
};
</script>

<style scoped>
.thread-bag .search-botton {
  width: 70px;
  height: 30px;
  float: left;
  position: relative;
  top: 10px;
  margin-left: 20px;
  background-color: #78d9d1;
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
.thread-bag {
  width: 100%;
  /*min-width: 1200px;*/
  height: 50px;
  padding-right: 20px;
  background-color: #ffffff;
}
.h-body{
  min-height: calc( 100% - 60px );
  height: auto;
  background-color: #fff;
}
</style>
