var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body" }, [
    _c(
      "div",
      {
        staticStyle: {
          "min-height": "300px",
          "padding-top": "20px",
          "padding-bottom": "40px",
        },
        attrs: { slot: "modalCont" },
        slot: "modalCont",
      },
      [
        _c(
          "div",
          {
            staticClass: "global-input-btn auto-complate-cont",
            staticStyle: { "padding-left": "50px" },
          },
          [
            _c("el-input", {
              attrs: { autofocus: "", placeholder: "输入公司名称查询" },
              model: {
                value: _vm.searchCompanyName,
                callback: function ($$v) {
                  _vm.searchCompanyName = $$v
                },
                expression: "searchCompanyName",
              },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "global-search-btn",
              on: { click: _vm.searchCompanyList },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%", "margin-top": "30px" },
            attrs: {
              data: _vm.tableData,
              "max-height": "400",
              "empty-text": "查询无结果",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "companyName",
                label: "公司名称",
                "show-overflow-tooltip": "",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "createdAt", align: "center", label: "创建时间" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "followName", align: "center", label: "负责人" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.tableData.length != 0
          ? _c(
              "div",
              {
                staticStyle: {
                  color: "#7f7f7f",
                  margin: "40px 20px 20px 20px",
                },
              },
              [_vm._v("\n      注：查重结果最多显示10条\n    ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }