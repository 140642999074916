<template>
  <div class="grid-box-edit">
    <div id="chart-dom" class="box" :style="handleChartModify">
      <div class="header">
        <div class="tab-list">
          <div
            :class="
              currentActive == item.subPid ? 'active tab-item' : 'tab-item'
            "
            v-for="item in detailObj.subScreen"
            :key="item.subWorkId"
            @click="handleClick(item)"
          >
            {{ item.shortName }}
          </div>
        </div>
        <div class="panel-title">{{ detailObj.workName }}</div>
        <div class="r-panel">
          <PeSelect
            v-if="rightTab.length"
            :optionsList="rightTab"
            :clearable="false"
            popperClass="hx-screen-popper"
            class="r-select"
            placeholder="请选择版块"
            selectSize="mini"
            @handleSelectChange="handleTabChange"
          />
          <div class="screen-icon">
            <i
              class="el-icon-full-screen"
              style="font-size:24px"
              @click="handleScreen"
            />
            <i
              class="el-icon-refresh"
              style="color:#7dd1fd;font-size:24px"
              @click="initData"
            ></i>
          </div>
        </div>
      </div>

      <div class="chart-container">
        <!-- left -->
        <div class="chart-box">
          <pe-angle
            v-for="(item, index) in leftPanel"
            :key="index"
            :style="handleChartBox(item)"
          >
            <div class="operate">
              <div v-if="item.isTitleShow" class="chart-title">
                {{ item.title }}
              </div>
              <div class="edit-icon" @click="handleEdit(item)">
                <i class="el-icon-edit-outline" />
              </div>
            </div>
            <pe-chart :item="item" />
          </pe-angle>
        </div>

        <!-- middle -->
        <div class="chart-box">
          <pe-angle
            v-for="(item, index) in middlePanel"
            :key="index"
            :style="handleChartBox(item)"
          >
            <div class="operate">
              <div v-if="item.isTitleShow" class="chart-title">
                {{ item.title }}
              </div>
              <div class="edit-icon" @click="handleEdit(item)">
                <i class="el-icon-edit-outline" />
              </div>
            </div>

            <pe-chart :item="item" />
          </pe-angle>
        </div>

        <!-- right -->
        <div class="chart-box">
          <pe-angle
            v-for="(item, index) in rightPanel"
            :key="index"
            :style="handleChartBox(item)"
          >
            <div class="operate">
              <div v-if="item.isTitleShow" class="chart-title">
                {{ item.title }}
              </div>
              <div class="edit-icon" @click="handleEdit(item)">
                <i class="el-icon-edit-outline" />
              </div>
            </div>
            <pe-chart :item="item" />
          </pe-angle>
        </div>
      </div>
    </div>

    <chart-dialog
      :show="show"
      :screenId="workId"
      :chart-data="chartData"
      @handleClose="handleClose"
    />
  </div>
</template>

<script>
import {
  getScreenConfig,
  getChartsApiDetail,
  getVisualChild
} from '@/api/module'
import PeChart from '@/views/charts'
import PeAngle from '@/components/Peculiar/angle/angle'
import PeSelect from '@/components/Peculiar/select'
import ChartDialog from '@/views/charts/dialog'
import screenfull from 'screenfull'
export default {
  name: 'biEditPage',

  data() {
    return {
      leftPanel: [],
      middlePanel: [],
      rightPanel: [],
      show: false,
      detailObj: {},
      tabList: [],
      currentActive: 0,
      workId: '',
      rightTab: [],
      chartData: {},
      isScreen: false
    }
  },

  computed: {
    handleChartModify() {
      const { width, height } = this.detailObj
      return `width:${width}px;height:${height}px`
    }
  },

  components: {
    PeSelect,
    PeChart,
    PeAngle,
    ChartDialog
  },

  mounted() {
    this.workId = this.$route.query.screenId
    this.$store.state.settings.isFullScreen = true
    this.getDetail()
    this.initData()
  },

  methods: {
    handleChartBox(data) {
      return `
        width:${data.width}px;
        height:${data.height}px;
        margin:10px 0;
        position:relative;
        border: 2px solid #2c3a8b;
        padding:10px;
      `
    },

    handleClose(isSave) {
      this.show = false
      isSave && this.getConfig()
    },

    handleEdit(data) {
      console.log(data, 'edit')
      this.chartData = data
      this.show = true
    },

    handleScreen() {
      console.log(screenfull, 'screenfull123')
      if (screenfull.isEnabled) {
        screenfull.toggle()
        this.isScreen = !this.isScreen
      } else {
        // 全屏API不可用
        alert('你的浏览器不支持全屏')
      }
    },

    initData() {
      this.getConfig()

      this.getChildDetail()
    },

    handleTabChange(e) {
      console.log(e)
      this.workId = e.value
      this.getConfig()
    },

    handleResize() {
      const that = this
      // 大屏自适应
      function BigScreen(id) {
        // 获取dom元素
        const { width } = that.detailObj
        let app = document.querySelector(id) // 最好使用id 选择器
        // 设置缩放的位置
        app.style.transformOrigin = 'top left'
        function init() {
          const scale = innerWidth / width
          app.style.transform = `scale(${scale})`
          console.log(innerWidth, width, 'appStyle')
        }

        init()
        window.addEventListener('resize', () => {
          init()
        })
      }
      BigScreen('#chart-dom')
    },

    async getDetail() {
      const { data } = await getChartsApiDetail({
        pid: this.workId
      })
      this.detailObj = data
      this.currentActive = data?.subScreen[0].subPid
      this.handleResize()
    },

    async getChildDetail() {
      const { data } = await getVisualChild({
        pid: this.workId
      })
      this.rightTab = data.map(item => ({
        label: item.name,
        value: item.pid
      }))
    },

    async getConfig() {
      const { data } = await getScreenConfig({
        type: 'screen',
        pid: this.workId
      })
      this.leftPanel = data?.left
      this.middlePanel = data?.middle
      this.rightPanel = data?.right
    },

    handleClick(item) {
      this.currentActive = item.subPid
      this.workId = this.currentActive
      this.getChildDetail()
      this.getConfig()
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize')
    this.$store.state.settings.isFullScreen = false
  }
}
</script>

<style lang="scss">
.hx-screen-popper {
  background: #1b3f85;
  box-shadow: inset 0px -5px 19px 9px rgba(255, 255, 255, 0.09);
  backdrop-filter: blur(5px);
  border: 2px solid #1b3f85;

  .el-select-dropdown__list {
    background: none !important;
    border: none !important;
  }

  .el-select-dropdown__item {
    color: #fff;
  }

  .el-scrollbar__wrap {
    margin-bottom: -10px !important;
  }

  .hover {
    background: none;
    color: none;
    cursor: pointer;
  }

  .selected {
    background: none !important;
    color: #fff !important;
  }
}
</style>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.grid-box-edit {
  background-color: #09152a;
  padding: 0 !important;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #7dd1fd;
    height: 60px;
    width: 100%;
    line-height: 60px;
    padding: 0 10px;
    position: relative;
    background: url('../../../assets/pe-img/top_bg.png') no-repeat;
    background-size: 100% 100%;
    .h-img {
      position: absolute;
      left: 0;
      width: 100%;
      height: 60px;
      z-index: 111;
    }

    .tab-list {
      display: flex;
      align-items: center;
      color: #7dd1fd;
      position: relative;
      z-index: 222;

      .tab-item {
        text-align: center;
        margin: 0 20px;
        cursor: pointer;
      }
      .active {
        position: relative;
        color: #7dd1fd;
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50px;
          transform: translateX(-50%);
          width: 30px;
          height: 5px;
          border-radius: 5px;
          background-color: #7dd1fd;
        }
      }
    }

    .panel-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 36px;
      z-index: 222;
    }

    .r-panel {
      display: flex;
      align-items: center;

      .r-select {
        position: relative;
        z-index: 333;
        margin-right: 20px;
        ::v-deep .el-input__inner {
          border: none !important;
          background: #1b3f85 !important;
          color: #fff !important;
          &:focus {
            border: none !important;
          }
        }
      }

      .screen-icon {
        position: relative;
        z-index: 222;
        margin-top: 10px;
      }
    }
  }

  .chart-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .chart-box {
      display: flex;
      flex-direction: column;
    }
  }

  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chart-title {
      color: #30a2f8;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .edit-icon {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 111;
    font-size: 20px;
    color: #0096ff;
  }
}
</style>
