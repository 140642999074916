var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "el-form",
          { ref: "form", attrs: { model: _vm.form, "label-width": "auto" } },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              _vm._l(_vm.formAttr, function (item) {
                return _c(
                  "el-col",
                  {
                    key: item.key,
                    attrs: {
                      span:
                        item.key === "remark" || item.key === "mark" ? 24 : 12,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: item.label,
                          prop: item.key,
                          rules: item.rules,
                        },
                      },
                      [
                        item.key === "source"
                          ? _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择来源" },
                                model: {
                                  value: _vm.form.fromType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fromType", $$v)
                                  },
                                  expression: "form.fromType",
                                },
                              },
                              _vm._l(_vm.sourceList, function (_item) {
                                return _c("el-option", {
                                  key: _item.id,
                                  attrs: { label: _item.name, value: _item.id },
                                })
                              }),
                              1
                            )
                          : item.key === "isKp"
                          ? _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.form.isKp,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isKp", $$v)
                                  },
                                  expression: "form.isKp",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "关键人", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "中间人", value: "2" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "普通", value: "3" },
                                }),
                              ],
                              1
                            )
                          : item.key === "mark" && !_vm.isNewCreate
                          ? _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择状态" },
                                model: {
                                  value: _vm.form.mark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "mark", $$v)
                                  },
                                  expression: "form.mark",
                                },
                              },
                              _vm._l(_vm.markDescList, function (_item) {
                                return _c("el-option", {
                                  key: _item.value,
                                  attrs: {
                                    label: _item.name,
                                    value: _item.value,
                                  },
                                })
                              }),
                              1
                            )
                          : item.key === "companyName"
                          ? _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  "allow-create": "",
                                  remote: "",
                                  "default-first-option": "",
                                  clearable: "",
                                  placeholder: `请输入${item.label}`,
                                  "remote-method": _vm.getCompanyList,
                                },
                                model: {
                                  value: _vm.form.companyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "companyName", $$v)
                                  },
                                  expression: "form.companyName",
                                },
                              },
                              _vm._l(_vm.companyList, function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item },
                                })
                              }),
                              1
                            )
                          : _c("el-input", {
                              attrs: {
                                type: item.type,
                                placeholder: `请输入${
                                  item.label === "手机" || item.label === "座机"
                                    ? item.label + "号码"
                                    : item.label
                                }`,
                                maxlength: "100",
                                disabled:
                                  _vm.type === "update" &&
                                  item.key === "nickName",
                                "show-word-limit": item.type === "textarea",
                              },
                              model: {
                                value: _vm.form[item.key],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    item.key,
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form[item.key]",
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          { attrs: { type: "info", size: "small" }, on: { click: _vm.cancel } },
          [_vm._v("\n      取消\n    ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.save },
          },
          [_vm._v("\n      确认\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }