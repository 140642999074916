<template>
  <div
    class="overSeaChart-main"
    ref="chartMain"
    :style="{ height: height }"
    v-loading="loading"
  >
    <div ref="chart" class="chart"></div>
  </div>
</template>

<script>
import { applicationChart } from '@/api/customerpond'
const elementResizeDetectorMaker = require('element-resize-detector')
import * as echarts from 'echarts'
const watchFnc = elementResizeDetectorMaker()
export default {
  name: 'ApplicationChart',
  data() {
    return {
      height: window.innerHeight - 185 + 'px',
      chart: null,
      xAxisData: [],
      yAxisData: [],
      chartData: [],
      loading: false,
      sizeCof: 1
    }
  },
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      const el = this.$refs.chartMain
      watchFnc.listenTo(el, () => {
        this.chart?.resize()
      })
      this.height = `${window.innerHeight -
        this.$refs?.chartMain?.getBoundingClientRect()?.top ||
        window.innerHeight - 185}px`
      this.$nextTick(() => {
        this.chartInit(() => {
          this.updateChart()
        })
      })
    })
  },
  watch: {},
  computed: {
    colorList() {
      const baseColor = [
        '#768dd1',
        '#a7d691',
        '#fbd379',
        '#f18585',
        '#8fcde5',
        '#62b58e'
      ]
      const resColor = []
      this.yAxisData.forEach(item => {
        resColor.push(baseColor[Math.floor(Math.random() * 5)])
      })
      return resColor
    }
  },
  methods: {
    chartInit(fn) {
      const el = this.$refs.chart
      this.chart = echarts.init(el)
      typeof fn === 'function' ? fn() : ''
    },
    async updateChart() {
      let data = []
      try {
        data = await this.getData()
      } catch (e) {
        console.log(e)
      }
      const options = {
        grid: {
          left: 50,
          bottom: 50,
          right: 100,
          containLabel: true
        },
        tooltip: {
          position: 'top',
          formatter: params => {
            return `${this.xAxisData[params.value[0]]}-${
              this.yAxisData[params.value[1]]
            }-${params.value[2]}家公司`
          }
        },
        xAxis: {
          type: 'category',
          nameGap: 100,
          data: this.xAxisData,
          boundaryGap: true,
          splitLine: {
            show: false
          },
          position: 'top',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ddd'
            }
          },
          axisLabel: {
            color: '#333',
            fontSize: 14,
            margin: 25
          }
        },
        yAxis: {
          type: 'category',
          data: this.yAxisData,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ddd'
            }
          },
          axisLabel: {
            color: '#333',
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ddd'
            }
          }
        },
        series: [
          {
            name: 'Punch Card',
            type: 'scatter',
            itemStyle: {
              color: params => {
                return '#fbd379'
                const colorIndex = params.data[1]
                return this.colorList?.[colorIndex]
              }
            },
            symbolSize: val => {
              console.log(val)
              if (!val[2]) return 0
              const value = val[2] * this.sizeCof
              return value <= 10 ? 10 : value
            },
            data: this.chartData,
            animationDelay: function(idx) {
              return idx * 5
            }
          }
        ]
      }
      this.chart && this.chart.setOption(options)
    },

    getData() {
      this.xAxisData = []
      this.yAxisData = []
      this.chartData = []
      return new Promise((resolve, reject) => {
        this.loading = true
        applicationChart(this.params)
          .then(res => {
            this.loading = false
            if (res.code === 60000) {
              this.getChartData(res.data || [])
              resolve(res.data)
              return
            }
            resolve([])
          })
          .catch(() => {
            resolve([])
          })
      })
    },

    getChartData(data) {
      let x = []
      let y = []
      let d = []
      let maxValue = 0
      let isPushY = false
      data.forEach((item, index) => {
        x.push(item.key)
        let _data = (item.val || []).reverse()
        _data.forEach((i, _index) => {
          const value = Object.values(i)?.[0] || 0
          if (value > maxValue) maxValue = value
          d.push([index, _index, Object.values(i)?.[0] || 0])
          if (!isPushY) y.push(Object.keys(i)[0])
        })
        isPushY = true
      })
      this.sizeCof = 100 / maxValue > 1 ? 1 : 100 / maxValue
      console.log(maxValue, this.sizeCof)
      this.setHeight(y)
      this.$nextTick(() => {
        this.xAxisData = x
        this.yAxisData = y
        this.chartData = d
        this.chart?.resize()
      })
    },

    setHeight(data) {
      const height = data.length * 120 + 100
      this.height = height < 400 ? '400px' : `${height}px`
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped lang="scss">
.overSeaChart-main {
  width: 100%;
  .chart {
    width: 100%;
    height: 100%;
  }
}
</style>
