var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/miniMarket/name-card" } } },
                [_vm._v("微站管理")]
              ),
              _vm._v(" "),
              _c("el-breadcrumb-item", [_vm._v("编辑微站")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.saveMiniSite },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "create-row" }, [
      _c("div", { staticClass: "page-title" }, [
        true
          ? _c("div", { staticClass: "title-text" }, [
              _vm._v("\n        分享标题、简介与头图\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "share-show" }, [
          _c("div", { staticClass: "land-page-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.fontStyle.share.title || "请输入标题") +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "land-page-desc" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.fontStyle.share.desc || "请输入简介") +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-title-link" },
            [
              !_vm.fontStyle.share.img
                ? _c("i", { staticClass: "el-icon-link" })
                : _c("el-image", {
                    attrs: { src: _vm.fontStyle.share.img, fit: "fit" },
                  }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page-show" }, [
        _c(
          "div",
          { staticClass: "iphone" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "top-bar" }),
            _vm._v(" "),
            _c("el-scrollbar", { staticClass: "scroll" }, [
              _c("div", { staticClass: "iphone-screen" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.show.mp && _vm.fontStyle.barStatus.showMp,
                        expression: "show.mp && fontStyle.barStatus.showMp",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "name-card" },
                      [
                        _c("dt-card", {
                          attrs: {
                            type: _vm.fontStyle.cardType,
                            "company-logo": _vm.fontStyle.earnMsg.companyLogo,
                            address: _vm.fontStyle.earnMsg.address,
                            avatar: _vm.fontStyle.earnMsg.avatar,
                            "email-num": _vm.fontStyle.earnMsg.emailNum,
                            ercode: _vm.fontStyle.earnMsg.ercode,
                            "follow-name": _vm.fontStyle.earnMsg.followName,
                            "phone-num": _vm.fontStyle.earnMsg.phoneNum,
                            position: _vm.fontStyle.earnMsg.position,
                            website: _vm.fontStyle.earnMsg.website,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.fontStyle.show.video,
                                expression: "fontStyle.show.video",
                              },
                            ],
                            staticClass: "video-row",
                          },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.fontStyle.videoUrl,
                                  expression: "!fontStyle.videoUrl",
                                },
                              ],
                              staticClass: "el-icon-video-play",
                            }),
                            _vm._v(" "),
                            _c("video", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.fontStyle.videoUrl,
                                  expression: "fontStyle.videoUrl",
                                },
                              ],
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: _vm.fontStyle.videoUrl,
                                controls: "controls",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.fontStyle.show.photo
                      ? _c(
                          "div",
                          { staticClass: "image-row" },
                          [
                            _c(
                              "el-image",
                              { attrs: { src: _vm.fontStyle.uploadUrl } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-picture-outline",
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.fontStyle.show.content,
                            expression: "fontStyle.show.content",
                          },
                        ],
                        staticClass: "content-row",
                        style: {
                          fontFamily: _vm.fontStyle.fontFamily,
                          backgroundColor: _vm.fontStyle.textBg,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-content",
                            style: {
                              fontFamily: _vm.fontStyle.fontFamily,
                              fontSize: _vm.fontStyle.fontSize + "px",
                              fontWeight: _vm.fontStyle.fontWeight,
                            },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.fontStyle.textareaCont) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.show.yx && _vm.fontStyle.barStatus.showYx,
                        expression: "show.yx && fontStyle.barStatus.showYx",
                      },
                    ],
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c("iframe", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: _vm.landPageUrl,
                        sandbox: "",
                        frameborder: "0",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.show.gw && _vm.fontStyle.barStatus.showWs,
                        expression: "show.gw && fontStyle.barStatus.showWs",
                      },
                    ],
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c("iframe", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        sandbox: "allow-scripts",
                        src: _vm.fontStyle.barItem.websiteUrl,
                        frameborder: "0",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.showBar
                  ? _c("div", { staticClass: "bar" }, [
                      _vm.fontStyle.barStatus.showYx
                        ? _c(
                            "div",
                            {
                              staticClass: "yx-bar",
                              on: {
                                click: function ($event) {
                                  return _vm.showItem("yx")
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "30px",
                                      height: "30px",
                                    },
                                    attrs: {
                                      src: _vm.show.yx
                                        ? "https://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/image/dt-41cabf.svg"
                                        : "http://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/image/dt-1.svg",
                                      fit: "fit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.show.yx
                                      ? "#41cabf!important"
                                      : "#ccc",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  动态\n                "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.fontStyle.barStatus.showMp
                        ? _c(
                            "div",
                            {
                              staticClass: "mp-bar",
                              on: {
                                click: function ($event) {
                                  return _vm.showItem("mp")
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "30px",
                                      height: "30px",
                                    },
                                    attrs: {
                                      src: _vm.show.mp
                                        ? "https://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/image/mp-41cabf.svg"
                                        : "http://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/image/mp.svg",
                                      fit: "fit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.show.mp
                                      ? "#41cabf!important"
                                      : "#ccc",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  名片\n                "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.fontStyle.barStatus.showWs
                        ? _c(
                            "div",
                            {
                              staticClass: "gw-bar",
                              on: {
                                click: function ($event) {
                                  return _vm.showItem("gw")
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "30px",
                                      height: "30px",
                                    },
                                    attrs: {
                                      src: _vm.show.gw
                                        ? "https://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/image/gw-41cabf.svg"
                                        : "http://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/image/gw-1.svg",
                                      fit: "fit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.show.gw
                                      ? "#41cabf!important"
                                      : "#ccc",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  官网\n                "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "bottom-bar" }),
            _vm._v(" "),
            _vm._m(2),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-edit" },
        [
          _c("mini-web-edit", {
            on: { sendData: _vm.sendData, loadingVideo: _vm.loadingVideo },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "iphone-top" }, [
      _c("span", { staticClass: "camera" }),
      _vm._v(" "),
      _c("span", { staticClass: "sensor" }),
      _vm._v(" "),
      _c("span", { staticClass: "speaker" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "buttons" }, [
      _c("span", { staticClass: "on-off" }),
      _vm._v(" "),
      _c("span", { staticClass: "sleep" }),
      _vm._v(" "),
      _c("span", { staticClass: "up" }),
      _vm._v(" "),
      _c("span", { staticClass: "down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "iphone-bottom" }, [_c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }