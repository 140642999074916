<template>
  <div class="body">
    <el-form
      ref="form"
      :model="form"
      label-width="auto"
      :rules="rules"
      label-position="top"
    >
      <el-form-item prop="remark">
        <el-input
          type="textarea"
          @blur="onInputBlur"
          @focus="onInputFocus"
          placeholder="请填写备注信息"
          v-model="form.remark"
          :autosize="{ minRows: 4, maxRows: 10 }"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button size="small" @click="cancel">取消</el-button>
      <el-button type="primary" size="small" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
import { collection_comment } from '@/api/thread'

export default {
  name: 'addRemark',
  data() {
    return {
      form: {
        remark: ''
      },
      rules: {
        remark: [{ required: true, message: '请输入备注信息', trigger: 'blur' }]
      },
      isUpdate: false,
      companyUuid: '',
      oldRemark: ''
    }
  },
  mounted() {
    const modal = this.$store.state.plugin.modal.options
    this.isUpdate = modal.isUpdate
    this.companyUuid = modal.companyUuid
    if (this.isUpdate) {
      this.form.remark = modal.remark
      this.oldRemark = modal.remark
    }
  },
  methods: {
    // 失去焦点
    onInputBlur() {
      this.$store.commit('update', {
        target: this.$store.state.app,
        data: {
          canScroll: true
        }
      })
    },

    // 获取焦点
    onInputFocus() {
      this.$store.commit('update', {
        target: this.$store.state.app,
        data: {
          canScroll: false
        }
      })
    },

    cancel() {
      this.$store.dispatch('hideModal')
    },

    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.oldRemark.trim() === this.form.remark.trim()) {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
            this.$store.dispatch('hideModal')
          } else {
            this.createRemark()
          }
        }
      })
    },

    // 新建备注
    createRemark() {
      const params = {
        companyUuid: this.companyUuid,
        comment: this.form.remark
      }
      collection_comment(params).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
          this.$store.commit('update', {
            target: this.$store.state.plugin.modal.options,
            data: {
              refresh: true
            }
          })
          this.$store.dispatch('hideModal')
        }
      })
    }
  }
}
</script>

<style scoped>
.body {
  width: 100%;
  height: auto;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}
.footer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
}
.footer::before {
  content: '';
  display: inline-block;
  position: absolute;
  height: 1px;
  width: calc(100% + 40px);
  top: 0;
  left: -20px;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
}
</style>
