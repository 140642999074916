var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-main" },
    [
      _c("DtScroll", { attrs: { margin: "0" } }, [
        _c("div", { staticClass: "header" }, [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.push("/insight/park")
                },
              },
            },
            [_vm._v("园区洞察")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "da-code" }, [_vm._v(">")]),
          _vm._v(" "),
          _c("span", [_vm._v("园区详情")]),
          _vm._v(" "),
          _vm.IsAccess == 0 || _vm.IsAccess == 1
            ? _c(
                "span",
                {
                  staticClass: "normal-data",
                  on: { click: _vm.handleNormalData },
                },
                [_vm._v(_vm._s(_vm.$updateText))]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadDesc,
                expression: "loadDesc",
              },
            ],
            staticClass: "detail",
          },
          [
            _c("div", { staticClass: "c-name" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.detailDesc.parkName) + "\n        "
              ),
              _c("span", [_vm._v("共" + _vm._s(_vm.pageObj.total) + "家企业")]),
              _vm._v(" "),
              _c("span", [
                _vm._v("官网："),
                _c(
                  "span",
                  {
                    staticClass: "website",
                    on: {
                      click: function ($event) {
                        return _vm.jump(_vm.detailDesc.parkUrl)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.detailDesc.parkUrl))]
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("电话：" + _vm._s(_vm.detailDesc.telephone || "-")),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("行业：" + _vm._s(_vm.detailDesc.parkIndustry || "-")),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("面积：" + _vm._s(_vm.detailDesc.area || "-") + "亩"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "desc" }, [
              _c(
                "div",
                { staticClass: "label", staticStyle: { width: "40px" } },
                [_vm._v("\n          地址：\n        ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { flex: "1" } },
                _vm._l(_vm.addressList, function (add) {
                  return _c("div", [_vm._v(_vm._s(add))])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "desc-desc" }, [
              _c(
                "div",
                { staticClass: "label", staticStyle: { width: "40px" } },
                [_vm._v("\n          简介：\n        ")]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { flex: "1" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.detailDesc.introduction) +
                    "\n          "
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#0099CC",
                      cursor: "pointer",
                      "white-space": "nowrap",
                    },
                    on: { click: _vm.jumpToBd },
                  },
                  [_vm._v("百度百科")]
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter" },
          [
            _c("parkDetailFilter", {
              ref: "parkDetail",
              on: { selectChange: _vm.selectChange },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "table-row" }, [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("nurture-btn", {
                attrs: { selectionList: _vm.selectionList },
                on: { getListData: _vm.getList },
              }),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "input",
                staticStyle: { width: "300px", "margin-right": "10px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入公司名称查询",
                  clearable: "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.pageObj.pageNo = 1
                      _vm.getList()
                    },
                  },
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.reSet } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table list-table-app" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  key: _vm.tableKey,
                  ref: "table",
                  attrs: { border: "", data: _vm.list, size: "small" },
                  on: {
                    "sort-change": _vm.sortChange,
                    "selection-change": _vm.handleSelectionChange,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.columnsList, function (item, index) {
                    return _c("el-table-column", {
                      attrs: {
                        align: item.name === "公司名称" ? "left" : "center",
                        sortable: item.hasOrder === 0 ? null : "customer",
                        "show-tooltip-when-overflow": item.field !== "tags",
                        width: item.name === "公司名称" ? "200" : "auto",
                        prop: item.field,
                        label: item.name,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                item.type === "filter"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-dropdown",
                                          {
                                            attrs: {
                                              placement: "bottom",
                                              trigger: "click",
                                            },
                                            on: {
                                              command: (val) =>
                                                _vm.filterFunc(val, item),
                                            },
                                          },
                                          [
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-dropdown-link",
                                                  class: _vm.selectOptions[
                                                    item.field
                                                  ]
                                                    ? "d-active"
                                                    : "",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.name)),
                                                  ]),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-arrow-down el-icon--right",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-menu",
                                                {
                                                  attrs: { slot: "dropdown" },
                                                  slot: "dropdown",
                                                },
                                                [
                                                  _c("dt-drop-down", {
                                                    attrs: {
                                                      "source-list":
                                                        item.filterOptionList,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "el-scrollbar",
                                                                {
                                                                  staticClass:
                                                                    "dt-dropdown-dt",
                                                                },
                                                                [
                                                                  !row
                                                                    .filterList
                                                                    .length
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "dt-drop-down-no-data",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              无数据\n                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    row.filterList,
                                                                    function (
                                                                      _item
                                                                    ) {
                                                                      return _c(
                                                                        "el-dropdown-item",
                                                                        {
                                                                          class:
                                                                            _vm
                                                                              .selectOptions[
                                                                              item
                                                                                .field
                                                                            ] ===
                                                                            _item.value
                                                                              ? "d-active"
                                                                              : "",
                                                                          attrs:
                                                                            {
                                                                              command:
                                                                                _item.value,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              domProps:
                                                                                {
                                                                                  innerHTML:
                                                                                    _vm._s(
                                                                                      _item.htmlEl
                                                                                    ),
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("div", [_vm._v(_vm._s(item.name))]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                item.name === "公司名称"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "c-name",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goKeyDetail(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("Star", {
                                          attrs: {
                                            data: scope.row.sub,
                                            selectObj: scope.row,
                                          },
                                          on: { getListData: _vm.getList },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            " " + _vm._s(scope.row[item.field])
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : item.field === "tags"
                                  ? _c(
                                      "span",
                                      [
                                        _c("Tags", {
                                          attrs: { tagList: scope.row.tags },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row[item.field])),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "t-footer page-div page-footer-page page" },
            [
              _c("footer-page", {
                attrs: { loading: _vm.loading, pageStatus: _vm.pageObj },
                on: {
                  handleCurrentChange: _vm.handleCurrentChange,
                  goNumPage: _vm.goNumPage,
                  changePageSize: _vm.changePageSize,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }