import Vue from 'vue'
import { Store } from 'vuex'
const vue = new Vue()

const state = {
  env: 'desktop', //dd 钉钉  tt 飞书
  showWx: false,
  weChatPlugin: {
    appId: '',
    secret: '',
    agentId: ''
  },
  onlyShowPhone: false, //客户详情 只显示手机号
  onlyShowSale: false,
  onlyShowDev: false,
  onlyShowAdmin: false,
  onlyShowElse: false,
  onlyShowKP: false,
  appMarket: 0,
  modulePackList: [],
  selectList: [],
  cardObj: {},
  // 消息提示
  tip: {
    // 消息ID
    id: undefined,
    // 提示内容
    text: '',
    // 提示类型
    type: 'success',
    // 提示时长
    duration: 2000
  },

  // 模态框
  modal: {
    id: undefined,
    title: '',
    size: '600px',
    view: undefined,
    onClose: undefined,
    options: {
      filterQy: 1,
      dataRang: 0
    },
    isChild: false,
    center: false,
    type: 'dialog', // drawer or dialog
    showTitle: false
  },

  // 消息通知
  notice: {
    // 通知ID
    id: undefined,
    // 提示标题
    title: '',
    // 提示内容
    message: '',
    // 提示类型
    type: 'info',
    // 提示时长
    duration: 15000,
    // 关闭回调
    onClose: undefined
  },
  // 对话框
  messageBox: {
    id: undefined,
    title: '',
    message: '',
    type: 'info',
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonText: '取消',
    confirmButtonText: '确定',
    closeOnClickModal: false,
    closeOnPressEscape: false,
    callback: undefined,
    distinguishCancelAndClose: true
  },

  // loading遮罩
  loading: {
    show: false,
    text: '',
    run: undefined
  },

  openAutoCallTask: false, // 自动外呼
  openAutoCallTime: 10, // 外呼间隔时间
  autoCallCurrent: {}, // 当前进行的外呼
  waiHuFilter: {} // 外呼条件
}

const mutations = {
  update(state, { target, data }) {
    for (let name in data) {
      Vue.set(target, name, data[name])
    }
  }
}

const actions = {
  /**
   * 全局消息提醒
   * @param store
   * @param {string} text 需要提醒的内容
   * @param {number} duration 提醒时长
   * @param {string} type 提醒的风格，默认是info，可能的值有：success/warning/info/error
   */
  showTip(store, { text, type, duration }) {
    type = type || 'info'
    duration = duration || 2000

    store.commit('update', {
      target: store.state.tip,
      data: {
        text: text,
        type: type,
        duration: duration,
        id: Math.random()
      }
    })
  },

  /**
   * 展示模态框
   * @param store
   * @param {string} title 标题
   * @param {Component} view 内容，component实例
   * @param {string} size 大小
   * @param {function} onClose 关闭模态框回调
   * @param {$ObjMap} options，其他选项，在view组件中可以通过this.$store.state.modal.options获取
   *
   */

  showModal(
    store,
    {
      title = '',
      view,
      size = '600px',
      options = {},
      onClose,
      top = '50px',
      isChild,
      center,
      type = 'dialog',
      showTitle,
      hideClose
    }
  ) {
    store.commit('update', {
      target: store.state.modal,
      data: {
        type,
        title,
        showTitle,
        hideClose,
        view,
        size,
        onClose,
        options,
        top,
        isChild,
        center,
        id: Math.random()
      }
    })
  },

  /**
   * 隐藏模态框
   * version 1.0 手动调用该方法关闭模态框将不响应onClose方法
   * version 1.1 手动添加onClose事件
   * @param store
   */
  hideModal(store) {
    store.commit('update', {
      target: store.state.modal,
      data: {
        title: '',
        view: undefined,
        // size: '600px',
        id: undefined,
        isChild: false,
        center: false
      }
    })
    // vue.$bus.$emit('onClose', () => {}) //手动发送onClose事件
    // store.state.modal.onClose()
    console.log(store, 'hideModal')
  },

  /**
   * 显示通知信息
   * @param {object} store
   * @param {string} title 标题
   * @param {string} text 内容
   * @param {number} duration 时长
   * @param {string} type 类型
   * @param {function} onClose 关闭回调
   */
  showNotice(store, { title, text, duration, type, onClose }) {
    duration = duration || 15000
    type = type || 'info'
    title = title || '通知'
    store.commit('update', {
      target: store.state.notice,
      data: {
        id: Math.random(),
        title: title,
        message: text,
        duration: duration,
        type: type,
        onClose: onClose
      }
    })
  },

  /**
   * 显示对话框
   * @param {object} store
   * @param {string} title 标题
   * @param {string} text 内容
   * @param {string} type 类型
   * @param {function} onClose 关闭回调
   * @param {function} cancel 取消按钮文本
   * @param {function} confirm 确认按钮文本
   */
  showMessageBox(store, { title, text, type, onClose, cancel, confirm }) {
    type = type || 'info'
    title = title || '确认操作'

    cancel = cancel === undefined ? '取消' : cancel
    confirm = confirm === undefined ? '确认' : confirm
    onClose = typeof onClose === 'function' ? onClose : () => {}

    store.commit('update', {
      target: store.state.messageBox,
      data: {
        id: Math.random(),
        title: title,
        message: text,
        type: type,
        callback: onClose,
        showCancelButton: cancel !== '',
        showConfirmButton: confirm !== '',
        cancelButtonText: cancel,
        confirmButtonText: confirm
      }
    })
  },

  /**
   * 显示loading遮罩
   * @param {object} store
   * @param {string} text 提示内容
   * @param {function} run loading时需要执行的代码
   */
  showLoading(store, { text, run }) {
    text = text ? text : '正在加载'
    store.commit('update', {
      target: store.state.loading,
      data: {
        show: true,
        text: text,
        run: run
      }
    })
  },

  /**
   * 关闭loading遮罩
   * @param {object} store
   */
  hideLoading(store) {
    store.commit('update', {
      target: store.state.loading,
      data: {
        show: false,
        text: '',
        handle: undefined
      }
    })
  }
}

export default {
  state,
  mutations,
  actions
}
