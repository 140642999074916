var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reset-container" },
    [
      _c("Header"),
      _vm._v(" "),
      _c("div", { staticClass: "reser-title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "reset-form" },
        [
          _c("p", [_vm._v(_vm._s(_vm.titleDetail))]),
          _vm._v(" "),
          _c("valid-phone", {
            ref: "getCheckNext",
            attrs: { showCode: false },
            on: {
              startLoading: _vm.startLoading,
              phoneCodeIsSucc: _vm.phoneCodeIsSucc,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "reset-bt",
              on: { click: _vm.resetBt },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }