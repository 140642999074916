var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editPurchase-main" }, [
    _c(
      "div",
      { staticClass: "main-row" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.formData,
              "label-width": "auto",
              rules: _vm.rules,
              size: "small",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "采购编号", prop: "purchaseId" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "采购单编号（留空系统自动生成）",
                            disabled: _vm.type === "edit",
                          },
                          model: {
                            value: _vm.formData.purchaseId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "purchaseId", $$v)
                            },
                            expression: "formData.purchaseId",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "供应商", prop: "supplyId" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "请选择供应商",
                              "remote-method": _vm.getSupplyIdList,
                              loading: _vm.loading,
                            },
                            model: {
                              value: _vm.formData.supplyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "supplyId", $$v)
                              },
                              expression: "formData.supplyId",
                            },
                          },
                          _vm._l(_vm.supplyIdList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "采购类型", prop: "purchaseType" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择采购类型" },
                            model: {
                              value: _vm.formData.purchaseType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "purchaseType", $$v)
                              },
                              expression: "formData.purchaseType",
                            },
                          },
                          _vm._l(_vm.purchaseTypeList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.name, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "采购负责人", prop: "pda" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择采购负责人",
                              filterable: "",
                            },
                            model: {
                              value: _vm.formData.pda,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "pda", $$v)
                              },
                              expression: "formData.pda",
                            },
                          },
                          _vm._l(_vm.pdaList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "归属日期",
                          prop: "purchaseBelongDate",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "value-format": "yyyy-MM",
                            type: "month",
                            placeholder: "请选择归属日期",
                          },
                          model: {
                            value: _vm.formData.purchaseBelongDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "purchaseBelongDate", $$v)
                            },
                            expression: "formData.purchaseBelongDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "采购金额", prop: "purchaseMoney" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入采购金额" },
                          model: {
                            value: _vm.formData.purchaseMoney,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "purchaseMoney", $$v)
                            },
                            expression: "formData.purchaseMoney",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "合同", prop: "contractId" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              "popper-class": "mou-select",
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "请选择合同",
                              "remote-method": _vm.getContractIdList,
                              loading: _vm.loading,
                            },
                            model: {
                              value: _vm.formData.contractId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "contractId", $$v)
                              },
                              expression: "formData.contractId",
                            },
                          },
                          _vm._l(_vm.contractIdList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                attrs: {
                                  label: item.companyName,
                                  value: item.id,
                                },
                              },
                              [
                                _c("div", { staticStyle: { height: "50px" } }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        `${item.companyName}(合同金额：${item.contractAmount})`
                                      ) +
                                      "\n                  "
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "物流信息", prop: "logisticsInfo" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入物流信息" },
                          model: {
                            value: _vm.formData.logisticsInfo,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "logisticsInfo", $$v)
                            },
                            expression: "formData.logisticsInfo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "采购日期", prop: "purchaseDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "请选择采购日期",
                          },
                          model: {
                            value: _vm.formData.purchaseDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "purchaseDate", $$v)
                            },
                            expression: "formData.purchaseDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "采购内容", prop: "purchaseContent" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请选择采购内容",
                            type: "textarea",
                            maxlength: "500",
                            "show-word-limit": "",
                            autosize: { minRows: 4, maxRows: 10 },
                          },
                          model: {
                            value: _vm.formData.purchaseContent,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "purchaseContent", $$v)
                            },
                            expression: "formData.purchaseContent",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入备注",
                            type: "textarea",
                            maxlength: "500",
                            "show-word-limit": "",
                            autosize: { minRows: 4, maxRows: 10 },
                          },
                          model: {
                            value: _vm.formData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "remark", $$v)
                            },
                            expression: "formData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "附件" } },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadFileUrl,
                              "before-upload": _vm.beforeUpload,
                              accept: ".doc,.docx,.pdf,.jpg,.png,xls,.xlsx",
                              "http-request": _vm.uploadFileToOss,
                              "on-remove": _vm.handleRemove,
                              "on-preview": _vm.downLoadFile,
                              "file-list": _vm.fileList,
                              "auto-upload": true,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "trigger", size: "mini" },
                                slot: "trigger",
                              },
                              [_vm._v("选取文件")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [
                                _vm._v(
                                  "可上传多个。文件格式限制：doc、docx、pdf、jpg、png、xls、xlsx"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
          [_vm._v("取消")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.confirmBtn },
          },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }