<template>
  <div class="no-tab-cont dt-module-detail" v-if="showModule">
    <div class="title">{{ moduleParam.name }}</div>
    <component
      ref="computedComPic"
      id="computedComPic"
      @getComDetailModel="getComDetailModel"
      v-loading="refreshLoading"
      :is="noTabCont"
      :isScroll="isScroll"
      :otherParams="otherParams"
      :cardType="cardType"
      :detail="detail"
      :moduleDetail="moduleParam"
    ></component>
  </div>
</template>

<script>
import { getComDetailModel } from '@/api/thread'
import { isObjNull } from '@/utils/validate'
import Bus from '@/utils/bus'
import cardtouch from '@/components/ModulePack/cardtouch'
import touchTab from '@/views/querykp/touch'

export default {
  props: {
    moduleParam: {
      default: () => {},
      type: Object
    },
    detail: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      moduleDetail: {},
      cardType: '',
      isScroll: this.moduleParam.module_scroll === 1,
      otherParams: {},
      showModule: false,
      refreshLoading: false,
      testTime: null,
      cardtouch: cardtouch,
      touchTab: touchTab
    }
  },
  computed: {
    noTabCont() {
      if (
        this.moduleParam.module === 'cardtouch' ||
        this.moduleParam.module === 'social'
      ) {
        return this.touchTab
      }
      // if (this.moduleParam.module_name === 'social') {
      //   return this.socialcard
      // }
    }
  },
  mounted() {
    this.getComDetailModel()
  },
  beforeDestroy() {
    // Bus.$off('refreshModule' + this.moduleParam.module_api)
  },
  methods: {
    getComDetailModel(val) {
      const _this = this
      _this.dataParam()
      if (isObjNull(_this.moduleParam)) {
        _this.moduleDetail = {}
        _this.showModule = true
        _this.ObjectArr = _this.moduleParam
        _this.cardType = 'contact'
      } else {
        _this.showModule = false
        _this.moduleDetail = {}
        return
      }

      setTimeout(() => {
        _this.ModuleDom()
      }, 100)
    },
    ModuleDom() {
      if (this.$refs.computedComPic) {
        this.$refs.computedComPic.init()
      } else {
        setTimeout(() => {
          this.ModuleDom()
        }, 100)
      }
    },
    dataParam() {
      this.otherParams.pageSize = this.moduleParam.module_pageSize
      this.otherParams.isScroll = this.moduleParam.module_scroll === 1
      this.otherParams.isReceive = this.moduleParam.isReceive
      this.otherParams.shade = this.moduleParam.module_shade
    },
    windowWith() {}
  }
}
</script>

<style scoped>
.no-tab-cont {
  padding: 0 21px 38px;
  position: relative;
  top: 0;
}
.title {
  width: 100%;
  height: 59px;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #2e2e2e;
  line-height: 59px;
  padding-right: 36px;
  /*background-color: red;*/
  text-align: justify;
  position: relative;
  top: 0;
  left: 0;
}
</style>
