<template>
  <div class="data-permission" v-if="model && userList.length">
    <div class="type-select">
      <el-row :gutter="30">
        <el-col :span="8" v-for="item in scopeType" :key="item.scope">
          <el-radio
            :disabled="disabled"
            v-model="scope"
            :label="item.scope"
            class="change-radio"
            @change="handleRadioChange"
          >
            {{ item.name }}
          </el-radio>
        </el-col>
      </el-row>
    </div>
    <div class="user-select" v-show="scope === 3">
      <el-row :gutter="20">
        <el-col :span="8" v-for="user in userList" :key="user.id">
          <el-tooltip
            :disabled="
              (user.followName && user.followName.length < 8) ||
                !user.followName
            "
            :visible-arrow="false"
            popper-class="popper"
            effect="dark"
            :content="user.followName"
            placement="bottom"
          >
            <el-checkbox
              class="checkbox-dt"
              @change="change(user)"
              :checked="user.userId == userIdList[user.userId]"
              :disabled="disabled"
            >
              {{ user.followName }}
            </el-checkbox>
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dataPermission',
  data() {
    return {
      model: null,
      userIdList: {}, // 选中的user
      scope: 1,
      scopeType: [
        {
          name: '仅自己可见',
          scope: 1,
          checked: true
        },
        {
          name: '全公司可见',
          scope: 2,
          checked: false
        },
        {
          name: '自定义',
          scope: 3,
          checked: false
        }
      ], // 选择的类型
      permissionList: {}
    }
  },
  props: {
    userList: {
      type: Array,
      default: () => {
        return []
      }
    },
    dataModel: {
      type: Object,
      default: () => {
        return null
      }
    },

    // 是否禁止选择
    disabled: {
      type: Boolean,
      default: false
    },
    parentId: {
      type: Number,
      default: 0
    },
    pageId: {
      type: 0,
      default: 0
    }
  },

  mounted() {
    this.model = this.dataModel
    if (!this.model) {
      return
    }
    this.scope = this.model.scope
    if (this.scope == 3) {
      let userIds = this.model.user_ids
      userIds = userIds.split(',')
      userIds.forEach(id => {
        this.userIdList[id] = id
      })
    }
    //初始化
    // this.getResult()
  },

  methods: {
    // 选中用户
    change(user) {
      const uid = user.userId
      if (this.userIdList[uid]) {
        delete this.userIdList[uid]
        return
      }
      this.userIdList[user.userId] = user.userId
      this.getResult()
    },
    // 获取结果
    getResult() {
      let res = {
        parent_id: this.parentId,
        page_id: this.model.page_id,
        scope: this.scope,
        user_ids: ''
      }
      if (this.scope == 3) {
        res['user_ids'] = Object.keys(this.userIdList).join(',')
      }
      this.$bus.$emit('handleCreatedPermission', res)
    },
    handleRadioChange() {
      this.getResult()
    }
  }
}
</script>

<style scoped>
.data-permission {
  height: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.user-select {
  margin-top: 5px;
  padding: 5px;
  box-sizing: border-box;
}
</style>
