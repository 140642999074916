var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-container" },
    [
      _c("Header"),
      _vm._v(" "),
      _vm.reqResult
        ? _c("div", { staticClass: "user-content" }, [
            _c(
              "div",
              {
                staticClass: "pay-succ-page",
                staticStyle: { "min-width": "550px" },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "pay-pay-header" }, [
                  _c(
                    "div",
                    {
                      staticClass: "pay-header-cont",
                      staticStyle: { "padding-left": "77px" },
                    },
                    [
                      _c("p", [
                        _c("span", { staticClass: "pay-cont-name" }, [
                          _vm._v("商品订单:"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "pay-cont-num" }, [
                          _vm._v(_vm._s(_vm.resultData.orderNo)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { staticClass: "pay-cont-name" }, [
                          _vm._v("订单金额:"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "pay-cont-num" }, [
                          _vm._v("￥" + _vm._s(_vm.resultData.amount)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { staticClass: "pay-cont-name" }, [
                          _vm._v("创建时间:"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "pay-cont-num" }, [
                          _vm._v(_vm._s(_vm.resultData.createdAt)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "pay-footer-right",
                          staticStyle: { "margin-top": "81px" },
                          on: { click: _vm.goNextHome },
                        },
                        [_vm._v("返回")]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _c("div", { staticClass: "user-content" }, [
            _c(
              "div",
              {
                staticClass: "pay-succ-page",
                staticStyle: { "min-width": "550px" },
              },
              [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "pay-pay-header" }, [
                  _c(
                    "div",
                    {
                      staticClass: "pay-header-cont",
                      staticStyle: { "padding-left": "77px" },
                    },
                    [
                      _c("p", [
                        _c("span", { staticClass: "pay-cont-name" }, [
                          _vm._v("商品订单:"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "pay-cont-num" }, [
                          _vm._v(_vm._s(_vm.resultData.orderNo)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { staticClass: "pay-cont-name" }, [
                          _vm._v("订单金额:"),
                        ]),
                        _vm._v(" "),
                        _vm.resultData.amount
                          ? _c("span", { staticClass: "pay-cont-num" }, [
                              _vm._v("￥" + _vm._s(_vm.resultData.amount)),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { staticClass: "pay-cont-name" }, [
                          _vm._v("创建时间:"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "pay-cont-num" }, [
                          _vm._v(_vm._s(_vm.resultData.createdAt)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "pay-footer-right",
                          staticStyle: {
                            "margin-top": "81px",
                            width: "200px",
                            background: "#41CABF",
                          },
                          on: { click: _vm.goNextHome },
                        },
                        [_vm._v("返回")]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pay-succ-header" }, [
      _c("p", [_vm._v("支付成功!")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "span",
        {
          staticClass: "pay-cont-num",
          staticStyle: { display: "block", height: "35px" },
        },
        [_vm._v("若有疑问请与客服联系，我们将尽快为您提供服务。")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "pay-cont-num" }, [
        _vm._v("客服电话：400-9965711"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pay-error-header" }, [
      _c("p", [_vm._v("支付失败!")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "span",
        {
          staticClass: "pay-cont-num",
          staticStyle: { display: "block", height: "35px" },
        },
        [_vm._v("请与客服联系，我们将尽快为您提供服务。")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "pay-cont-num" }, [
        _vm._v("客服电话：400-9965711"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }