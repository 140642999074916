<template>
  <div class="searchDomain-main">
    <div class="header">
      <div class="title">
        <div class="left-1">扩展应用</div>
        <div class="left-2">查域名（专业版）</div>
        <div class="right-1">剩余查询点数 {{ remainPoint || '-' }}</div>
        <div class="right-2">
          <span @click="addCharge">充值点数</span>
        </div>
      </div>
      <div class="search-input">
        <el-input
          v-model="domain"
          placeholder="请输入域名"
          clearable
        ></el-input>
        <div class="search" @click="searchClick(domain, true)">搜索</div>
      </div>
    </div>
    <div class="main-row">
      <div class="left">
        <DtScroll>
          <div
            class="base-item domain-base-msg"
            data-title="域名基础信息"
            v-loading="loading"
            element-loading-text="加载中"
          >
            <div
              class="item"
              v-for="item in domainBaseMsg"
              :key="item.key"
              :style="{
                borderBottom:
                  item.key !== 'domainBeianCompany'
                    ? 'none'
                    : '1px solid rgba(0,0,0,.1)'
              }"
            >
              <div>{{ item.name }}</div>
              <template>
                <div v-if="item.key !== 'domainBeianCompany'">
                  {{ item.value }}
                </div>
                <div
                  v-else
                  style="color: #41CABF;cursor: pointer"
                  @click="jumpCompany()"
                >
                  {{ item.value }}
                </div>
              </template>
            </div>
          </div>
          <div
            class="base-item yun-service"
            data-title="云服务商"
            v-loading="yunLoading"
            element-loading-text="加载中"
          >
            <div class="filter">
              <div>运营商:</div>
              <div
                class="filter-item"
                :class="yunType === '全部' ? 'active' : ''"
                @click="yunType = '全部'"
              >
                全部
              </div>
              <div
                class="filter-item"
                :class="yunType === '电信' ? 'active' : ''"
                @click="yunType = '电信'"
              >
                电信
              </div>
              <div
                class="filter-item"
                :class="yunType === '移动' ? 'active' : ''"
                @click="yunType = '移动'"
              >
                移动
              </div>
              <div
                class="filter-item"
                :class="yunType === '联通' ? 'active' : ''"
                @click="yunType = '联通'"
              >
                联通
              </div>
            </div>
            <el-table :data="yunFilterList" size="small" border>
              <el-table-column
                header-align="center"
                align="center"
                show-tooltip-when-overflow
                v-for="item in columns"
                :label="item.name"
                :prop="item.prop"
              >
                <template slot-scope="scope">
                  <span v-if="item.prop === 'checkPoint'">
                    {{ `${scope.row.checkPoint} [${scope.row.type}]` }}
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            class="base-item yun-service"
            data-title="CDN服务商"
            v-loading="cdnLoading"
            element-loading-text="加载中"
          >
            <div class="filter">
              <div>运营商:</div>
              <div
                class="filter-item"
                :class="cdnType === '全部' ? 'active' : ''"
                @click="cdnType = '全部'"
              >
                全部
              </div>
              <div
                class="filter-item"
                :class="cdnType === '电信' ? 'active' : ''"
                @click="cdnType = '电信'"
              >
                电信
              </div>
              <div
                class="filter-item"
                :class="cdnType === '移动' ? 'active' : ''"
                @click="cdnType = '移动'"
              >
                移动
              </div>
              <div
                class="filter-item"
                :class="cdnType === '联通' ? 'active' : ''"
                @click="cdnType = '联通'"
              >
                联通
              </div>
            </div>
            <el-table :data="cdnFilterList" border size="small">
              <el-table-column
                header-align="center"
                align="center"
                show-tooltip-when-overflow
                v-for="item in columns"
                :label="item.name"
                :prop="item.prop"
              >
                <template slot-scope="scope">
                  <span v-if="item.prop === 'checkPoint'">
                    {{ `${scope.row.checkPoint} [${scope.row.type}]` }}
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </DtScroll>
      </div>
      <div
        class="right"
        v-loading="domainLoading"
        element-loading-text="加载中"
      >
        <div class="title">{{ cname }} 企业下属域名</div>
        <DtScroll height="calc( 100% - 50px )">
          <div
            v-if="!domainLoading && !domainList.length"
            class="no-domain-data"
          >
            暂无数据
          </div>
          <div class="main-list">
            <div class="list" v-for="i in domainList">
              <div @click="jump(i.domain)">{{ i.domain }}</div>
              <div @click="searchClick(i.domain, false)">详情</div>
            </div>
          </div>
        </DtScroll>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDomain,
  getCdnService,
  getYunService,
  searchDomainBase,
  getEarnNum
} from '@/api/customerpond'
import DtScroll from '@/components/element/DtScroll'
import recharge from '@/views/extendApp/dialog/recharge'
export default {
  name: 'SearchDomain',
  components: {
    DtScroll
  },
  data() {
    return {
      remainPoint: null,
      loading: false, // 设置基础设置loading
      yunLoading: false,
      cdnLoading: false,
      domainLoading: false,
      domain: '',
      cname: '',
      cuuid: '',
      domainBaseMsg: [
        {
          name: '网址',
          key: 'domain'
        },
        {
          name: '网站名称',
          key: 'domainWebsiteName'
        },
        {
          name: '备案号',
          key: 'domainBeianNum'
        },
        {
          name: '备案时间',
          key: 'domainBeianTime'
        },
        {
          name: '备案人',
          key: 'domainBeianPerson'
        },
        {
          name: '备案主体',
          key: 'domainBeianCompany'
        }
      ],
      yunList: [],
      cdnList: [],
      columns: [
        {
          name: '监测对象',
          prop: 'domain'
        },
        {
          name: '监测点',
          prop: 'checkPoint'
        },
        {
          name: '域名响应IP',
          prop: 'responseIp'
        },
        {
          name: '域名响应IP所在地',
          prop: 'responseAddress'
        },
        {
          name: '服务商',
          prop: 'serviceProvider'
        }
      ],
      domainList: [],
      yunType: '全部',
      cdnType: '全部',
      access: true
    }
  },
  computed: {
    cdnFilterList() {
      if (this.cdnType !== '全部') {
        return this.cdnList.filter(i => i.type === this.cdnType)
      }
      return this.cdnList
    },

    yunFilterList() {
      if (this.yunType !== '全部') {
        return this.yunList.filter(i => i.type === this.yunType)
      }
      return this.yunList
    }
  },
  watch: {
    $route: {
      handler: async function(val) {
        await this.getEarnNum(false)
        if (val?.query?.domain) {
          this.searchClick(val?.query?.domain, true)
        }
      },
      immediate: true
    }
  },
  methods: {
    getEarnNum(deduct = true) {
      return new Promise((resolve, reject) => {
        getEarnNum({ deduct: deduct }).then(res => {
          if (res.code === 60000) {
            this.remainPoint = res?.data?.remainPoint + ''
            this.access = res?.data?.access || false
            resolve()
          }
        })
      })
    },
    searchClick(data, canGetDomain) {
      this.domain = data
      data && this.init(canGetDomain)
    },
    jump(url) {
      window.open(`http://${url}`)
    },
    jumpCompany() {
      if (!this.cuuid) return
      this.$router.push(
        `/keydetail/keydetail?companyUuid=${this.cuuid}&packageId=0`
      )
    },
    init(canGetDomain) {
      if (!this.access) {
        this.$store.dispatch('showTip', {
          text: '没点数啦，快去充值吧',
          type: 'error'
        })
        return
      }
      this.getDomainBase(canGetDomain)
      this.getYunService()
      this.getCdnService()
    },
    getDomain() {
      this.domainLoading = true
      getDomain({ companyUuid: this.cuuid }).then(res => {
        this.domainLoading = false
        if (res.code === 60000) {
          this.domainList = res?.data || []
        }
      })
    },
    getDomainBase(canGetDomain = true) {
      if (!this.domain) return
      this.loading = true
      searchDomainBase({ url: this.domain }).then(res => {
        this.loading = false
        if (res.code === 60000 && res.data) {
          this.getEarnNum(true)
          this.cname = res?.data?.domainBeianCompany
          this.cuuid = res?.data?.domainBeianCompanyUuid
          this.domainBaseMsg.forEach(item => {
            item['value'] = res?.data?.[item.key]
          })
          this.$forceUpdate()
          if (canGetDomain) this.getDomain()
        } else {
          this.cname = ''
          this.cuuid = ''
        }
      })
    },
    getYunService(type = '全部') {
      if (!this.access || !this.domain) return
      this.yunLoading = true
      getYunService({ url: this.domain }).then(res => {
        this.yunLoading = false
        if (res.code === 60000) {
          this.yunList = res?.data || []
          // if (type !== '全部' && type) this.yunList = this.yunList.filter(i => i.type === type)
          // this.yunType = type
        } else {
          this.yunList = []
        }
      })
    },
    getCdnService(type) {
      if (!this.access || !this.domain) return
      this.cdnLoading = true
      getCdnService({ url: this.domain }).then(res => {
        if (res.code === 60000) {
          this.cdnLoading = false
          this.cdnList = res?.data || []
          // if (type !== '全部' && type) this.cdnList = this.cdnList.filter(i => i.type === type)
          // this.cdnType = type
        } else {
          this.cdnList = []
        }
      })
    },
    addCharge() {
      this.$store.dispatch('showModal', {
        title: '充值',
        view: recharge,
        size: '600px',
        onClose: res => {
          if (res.refresh) this.getEarnNum(false)
        }
      })
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped lang="scss">
.searchDomain-main {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .header {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    .title {
      user-select: none;
      height: 40px;
      font-size: 12px;
      position: relative;
      background-color: #abe3e3;
      & > div {
        height: 100%;
        position: absolute;
        top: 0;
      }
      .left-1 {
        left: 0;
        background-color: #00aaaa;
        width: 80px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .left-2 {
        left: 80px;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding-left: 20px;
      }
      .right-2 {
        right: 0;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & > span {
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          background-color: orange;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          color: #ffffff;
          height: 100%;
        }
      }
      .right-1 {
        right: 110px;
        display: flex;
        align-items: center;
        color: #8a8a8a;
        font-size: 14px;
      }
    }
    .search-input {
      width: 500px;
      position: relative;
      margin-top: 10px;
      .search {
        position: absolute;
        left: 500px;
        top: 0;
        width: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #41cabf;
        color: #ffffff;
        cursor: pointer;
        user-select: none;
      }
    }
  }
  .main-row {
    height: calc(100% - 120px - 10px);
    display: flex;
    & > div {
      height: 100%;
    }
    .left {
      width: calc(70% - 10px);
      margin-right: 10px;
      background-color: #fff;
      .base-item {
        padding: 50px 0 10px 0;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 20px;
        font-size: 12px !important;
        .item {
          display: flex;
          text-align: center;
          color: #8a8a8a;
          height: 40px;
          line-height: 40px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          font-size: 12px;
          & > div:first-child {
            width: 30%;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          }
          & > div:last-child {
            width: 70%;
          }
        }
        &::before {
          content: attr(data-title);
          display: inline-block;
          width: 100%;
          height: 50px;
          position: absolute;
          left: 0;
          top: 0;
          line-height: 50px;
          font-size: 16px;
          //font-weight: bold;
        }
        //&::after{
        //  content: '';
        //  display: inline-block;
        //  height: 1px;
        //  width: 100%;
        //  position: absolute;
        //  bottom: 0;
        //  left: 0;
        //  background-color: rgba(0,0,0,.1);
        //  transform: scaleY(.5);
        //}
      }
      .yun-service {
        .filter {
          display: flex;
          margin-bottom: 20px;
          & > div {
            margin-right: 10px;
            font-size: 12px;
            user-select: none;
          }
          .filter-item {
            cursor: pointer;
            color: #8a8a8a;
          }
          .active {
            color: #41cabf;
            text-decoration: underline;
          }
        }
      }
    }
    .right {
      width: 30%;
      background-color: #ffffff;
      .no-domain-data {
        height: 300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #909399;
      }
      .title {
        font-size: 16px;
        //font-weight: bold;
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        box-sizing: border-box;
        position: relative;
        &::after {
          content: '';
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.1);
          position: absolute;
          bottom: 0;
          left: 0;
          transform: scaleY(0.5);
        }
      }
      .main-list {
        margin: 10px 0;
        & > .list:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .list {
          display: flex;
          text-align: center;
          height: 40px;
          line-height: 40px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: none;
          font-size: 14px;
          color: #41cabf;
          & > div:first-child {
            width: 70%;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            text-align: left;
            padding-left: 10px;
            box-sizing: border-box;
            cursor: pointer;
          }
          & > div:last-child {
            width: 30%;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.searchDomain-main {
  .header {
    .search-input {
      .el-input__inner {
        height: 50px;
      }
    }
  }
}
</style>
