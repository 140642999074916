var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cloud-main" },
    [
      _vm._l(_vm.list, function (dataList) {
        return _c(
          "div",
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c("div", { staticClass: "header-text" }, [
                    _vm._v(_vm._s(dataList.type)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-row",
                      {
                        class:
                          !_vm.otherParams.isReceive && _vm.otherParams.shade
                            ? "bg-filter"
                            : "",
                        attrs: { gutter: 20 },
                      },
                      [
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c(
                            "div",
                            { staticClass: "icon-text" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticStyle: { width: "160px" },
                                  attrs: {
                                    "visible-arrow": false,
                                    "popper-class": "popper",
                                    effect: "dark",
                                    placement: "right-end",
                                  },
                                },
                                [
                                  dataList.tip
                                    ? _c(
                                        "span",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [_vm._v(_vm._s(dataList.tip))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "60px",
                                        margin: "0 auto",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "icon" },
                                        [
                                          _c(
                                            "el-image",
                                            {
                                              staticStyle: {
                                                width: "40px",
                                                height: "40px",
                                              },
                                              attrs: {
                                                src: dataList.icon,
                                                fit: "fill",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "error" },
                                                  slot: "error",
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    staticStyle: {
                                                      "font-size": "40px",
                                                    },
                                                    attrs: {
                                                      "icon-class": "err_cloud",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(_vm._s(dataList.name)),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      !_vm.otherParams.isReceive && _vm.otherParams.shade
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.getLoading,
                  expression: "getLoading",
                },
              ],
              staticClass: "touch-no-receive",
            },
            [
              _c("div", { staticClass: "touch-no-cont" }, [
                _c("div", { staticClass: "touch-no-icon" }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#008CD9",
                        "text-decoration": "underline",
                        cursor: "pointer",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getReveiveKey("phone")
                        },
                      },
                    },
                    [_vm._v("领取")]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(" 以查看所有信息")]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDetail
        ? _c(
            "dia-model",
            { ref: "graspTip", attrs: { dialogStatus: _vm.graspData } },
            [
              _c("div", { attrs: { slot: "modalCont" }, slot: "modalCont" }, [
                _c("p", {
                  staticStyle: { padding: "20px 0" },
                  domProps: { innerHTML: _vm._s(_vm.recruitStr) },
                }),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "submit" }, slot: "submit" }, [
                _c("div", { staticClass: "grasp_go_pay" }, [
                  _c("span", { on: { click: _vm.graspGoPay } }, [
                    _vm._v("关闭"),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }