<template>
  <div class="item-main">
    <el-row :gutter="10">
      <el-col :span="8">
        <el-select v-model="filterOneSelectData" :placeholder="filterOneTip" size="small" style="width: 100%;" @change="changeFilterOne">
          <el-option
            v-for="item in filterOneSourceData"
            :key="item.filterColumn"
            :label="item.filterName"
            :value="item.filterColumn">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-select
          v-model="filterTwoSelectData"
          :placeholder="filterTwoTip"
          size="small" style="width: 100%;">
          <el-option
            :placeholder="filterTwoTip"
            v-for="item in filterTwoSourceData"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="8">


        <el-select
          v-if="filterThreeSourceDataType === 'select'"
          v-model="filterThreeSelectData"
          multiple
          filterable
          :placeholder="filterThreeTip"
          size="small"
          style="width: 100%;">
          <el-option
            :placeholder="filterThreeTip"
            v-for="item in filterThreeSourceData"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>

        <el-input v-if="filterThreeSourceDataType === 'input'" v-model.number="filterInput" :placeholder="filterThreeTip" size="small">
        </el-input>

        <el-date-picker
          style="width: 100%"
          v-if="filterThreeSourceDataType === 'datePicker'"
          size="small"
          v-model="filterTimeInput"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :placeholder="filterThreeTip">
        </el-date-picker>

      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'afterSalesSearchItem',
  data(){
    return {
      filterOneSelectData: '', // 第一个输入框的数据
      filterTwoSelectData: '', // 第二个输入框的数据
      filterThreeSelectData: [], // 第三个输入框的数据
      filterInput: '',
      filterTimeInput: ''
    }
  },

  methods:{
    // 更改第一个筛选项 联动更改剩余筛选项初始化
    changeFilterOne(){
      this.filterTwoSelectData = ''
      this.filterThreeSelectData = []
      this.filterInput = ''
      this.filterTimeInput = ''
    },

    // 父组件通过调用此方法获取筛选条件
    getResult(){
      let isOk = true
      const lastData = this.filterThreeSourceDataType === 'input' ? this.filterInput : (this.filterThreeSourceDataType === 'datePicker' ? this.filterTimeInput : this.filterThreeSelectData.join(','))
      !lastData && (isOk = false)
      const res = `${ this.filterOneSelectData }:${ this.filterTwoSelectData }:${ lastData }`
      const saveData = {
        filterOneSelectData: this.filterOneSelectData,
        filterTwoSelectData: this.filterTwoSelectData,
        filterThreeSelectData: this.filterThreeSourceDataType === 'input' ? this.filterInput : (this.filterThreeSourceDataType === 'datePicker' ? this.filterTimeInput : this.filterThreeSelectData )
      }
      return { res, saveData, isOk }
    },

    selectTimeChange(val){
      this.filterThreeSelectData = [val]
    },
  },

  props: {
    sourceData: {
      type: [Array, Object],
      default: ()=>[]
    },

    renderData: {
      type: Object,
      default: ()=>{}
    }
  },

  watch:{
    // 数据回填
    renderData: {
      handler: function (val) {
        this.filterOneSelectData = val.filterOneSelectData
        this.filterTwoSelectData = val.filterTwoSelectData
        this.$nextTick(() => {
          if(this.filterThreeSourceDataType !== 'select'){
            this.filterTimeInput = val.filterThreeSelectData instanceof Array ? '' : val.filterThreeSelectData
            this.filterInput = val.filterThreeSelectData instanceof Array ? '' : val.filterThreeSelectData
          }else {
            this.filterThreeSelectData = val.filterThreeSelectData
          }
        })
      },
      immediate: true
    }
  },

  computed: {
    // 第一个输入框的数据
    filterOneSourceData(){
      return this.sourceData
    },

    // 第二个输入框的数据
    filterTwoSourceData(){
      // 通过第一个输入框的
      if(this.filterOneSelectData){
        const data = this.filterOneSourceData.filter(item=>item.filterColumn === this.filterOneSelectData)
        if(data.length){
          return data[0].operators
        }
        return []
      }
      return []
    },

    // 第三个输入框的数据
    filterThreeSourceData(){
      if(this.filterOneSelectData){
        const data = this.filterOneSourceData.filter(item=>item.filterColumn === this.filterOneSelectData)
        if(data.length){
          return data[0].optional
        }
        return []
      }
      return []
    },

    // 第三个输入框的数据
    filterThreeSourceDataType(){
      if(this.filterOneSelectData){
        const data = this.filterOneSourceData.filter(item=>item.filterColumn === this.filterOneSelectData)
        console.log(data)
        if(data.length){
          return data[0].type
        }
        return 'input'
      }
      return 'input'
    },

    // 第一个输入框的提示数据
    filterOneTip(){
      return '请选择'
    },

    // 第二个输入框的提示数据
    filterTwoTip(){
      if(this.filterOneSelectData){
        if(!this.filterTwoSelectData){
          const data = this.filterOneSourceData.filter(item=>item.filterColumn === this.filterOneSelectData)
          if(data.length){
            this.filterTwoSelectData = data[0].operators[0]
          }
          return this.filterTwoSelectData
        }
        return this.filterTwoSelectData
      }
      return '请选择'
    },

    // 第三个输入框的提示数据
    filterThreeTip(){
      if(this.filterOneSelectData){
        const data = this.filterOneSourceData.filter(item=>item.filterColumn === this.filterOneSelectData)
        if(data.length){
          return data[0].filterTips
        }
        return '请选择'
      }
      return '请选择'
    }
  },
}
</script>

<style scoped>

</style>

