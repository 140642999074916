<template>
  <div class="filterItem-main">
      <el-row>
        <el-col :span="2">
          <div class="text">
            过滤
          </div>
        </el-col>
        <el-col :span="9">
          <el-select v-model="filterType" placeholder="请选择" size="mini" style="width: 100%">
            <el-option
              v-for="item in filterList"
              :key="item.value"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <div class="text">
            中包含
          </div>
        </el-col>
        <el-col :span="9">
          <el-select
            class="el-select-dt-hs"
            size="mini"
            style="width: 100%;"
            v-model="filterData"
            v-el-select-input="setInputIndex"
            filterable
            multiple
            allow-create
            popper-class="dt-drop-hs"
            placeholder="请输入内容"
            default-first-option>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <div class="text">
            的公司
          </div>
        </el-col>
      </el-row>
  </div>
</template>

<script>
export default {
  name: 'filterItem',
  directives: {
    elSelectInput: {
      bind: function (el, binding) {
        if (el.tagName.toLowerCase() !== 'input') {
          el = el.getElementsByTagName('input')[0]
          el.setAttribute(
            'classname',
            document.getElementsByClassName('select-search').length
          )
        }
        el.addEventListener('blur', function () {
          let a = el.getAttribute('classname')
          binding.value(a, el.value)
        })
      }
    }
  },
  data () {
    return {
      filterList: [
        {
          label: '公司名称',
          value: 1
        },
        {
          label: '行业',
          value: 2
        },
        {
          label: '经营范围',
          value: 3
        },
        {
          label: '软件著作权',
          value: 4
        },
        {
          label: '资质证书',
          value: 5
        }
      ],
      filterType: '公司名称',
      filterData: [],
      options:[]
    }
  },
  props: {
    savedData: {
      type: Object,
      default: ()=>{}
    }
  },
  watch: {
    savedData: {
      handler:function (val){
        if (!val) return
        const params = {...val}
        this.filterType = params.name
        this.filterData = params.words
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {

  },
  computed: {},
  methods: {
    setInputIndex(index, value){
      if (value && this.filterData instanceof Array){
        this.filterData.push(value)
      }
    },

    getResult(){
        let target = Object.create(null)
        target['name'] = this.filterType
        target['words'] = this.filterData
        return [ target ]
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.filterItem-main{
  padding: 10px 0;
  box-sizing: border-box;
}
.text{
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-select-dt-hs /deep/ .el-input__suffix{
  display: none!important;
}
</style>
