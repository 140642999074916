<template>
  <div class="index-main">
    <div class="btn-list">
      <div class="input-row">
        <el-input
          style="width: 200px"
          v-model="search"
          size="mini"
          placeholder="输入客户名称查询"
        ></el-input>
        <div class="btn-row">
          <el-button type="primary" size="mini" @click="searchContact"
            >查询</el-button
          >
          <el-button size="mini" @click="reset">重置</el-button>
        </div>
        <div class="g-search" @click="searchPlus">
          高级筛选<svg-icon icon-class="filtrate" class="filtrate" />
        </div>
        <div class="condition" v-show="showCondition">
          <span class="condition-icon" @click="removeSearch">
            <i class="el-icon-circle-close"></i>
          </span>
          <el-input
            style="width: 200px"
            v-model="showCondition"
            size="mini"
          ></el-input>
        </div>
      </div>
      <div>
        <span
          v-show="contractTotalMoney"
          style="margin-right: 15px;font-size: 12px"
          >合同合计金额：{{ contractTotalMoney.toLocaleString() + '元' }}</span
        >
        <span
          v-show="contractArrestMoney"
          style="margin-right: 15px;font-size: 12px"
          >欠款金额：<span style="color:#f00">{{
            contractArrestMoney.toLocaleString() + '元'
          }}</span></span
        >
        <i
          class="el-icon-setting n-icon"
          @click="setColumns"
          title="列表设置"
        ></i>
        <el-button
          size="mini"
          type="primary"
          @click="contractCreate"
          v-dt-permission="'客户管理CRM-合同管理-新建合同'"
        >
          新建合同
        </el-button>
      </div>
    </div>
    <div class="table" v-loading="loading">
      <el-table
        ref="table"
        :height="h"
        @sort-change="sortChange"
        class="table-dt"
        :data="tableData"
        border
        stripe
        style="width: 100%; height: 100%; font-size: 12px;background-color:#fff;"
      >
        <el-table-column
          :width="item.field === 'companyName' ? 220 : 180"
          show-tooltip-when-overflow
          v-for="item in columnList"
          :fixed="
            item.field === 'companyName'
              ? 'left'
              : item.field === 'setting'
              ? 'right'
              : false
          "
          :prop="item.field"
          :align="item.field === 'companyName' ? 'left' : 'center'"
          :sortable="item.type === 'order' ? 'custom' : false"
          :label="item.name"
        >
          <template slot="header" slot-scope="scope">
            <span v-if="item.type === 'select'">
              <el-dropdown
                placement="bottom"
                trigger="click"
                @command="val => filterFunc(val, item)"
              >
                <template v-if="item.field === 'followName'">
                  <span
                    class="el-dropdown-link"
                    :class="userId ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <dt-drop-down :source-list="followUserList">
                      <template v-slot:default="row">
                        <el-scrollbar class="dt-dropdown-dt">
                          <div
                            class="dt-drop-down-no-data"
                            v-if="!row.filterList.length"
                          >
                            无数据
                          </div>
                          <el-dropdown-item
                            :command="_item.id"
                            v-for="_item in row.filterList"
                            :class="userId === _item.id ? 'active' : ''"
                          >
                            <span v-html="_item.htmlEl"></span>
                          </el-dropdown-item>
                        </el-scrollbar>
                      </template>
                    </dt-drop-down>
                  </el-dropdown-menu>
                </template>

                <template v-else-if="item.field === 'signName'">
                  <span
                    class="el-dropdown-link"
                    :class="signName ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <dt-drop-down :source-list="followUserList">
                      <template v-slot:default="row">
                        <el-scrollbar class="dt-dropdown-dt">
                          <div
                            class="dt-drop-down-no-data"
                            v-if="!row.filterList.length"
                          >
                            无数据
                          </div>
                          <el-dropdown-item
                            :command="_item.id"
                            v-for="_item in row.filterList"
                            :class="signName === _item.id ? 'active' : ''"
                          >
                            <span v-html="_item.htmlEl"></span>
                          </el-dropdown-item>
                        </el-scrollbar>
                      </template>
                    </dt-drop-down>
                  </el-dropdown-menu>
                </template>

                <template v-else-if="item.field === 'contractTypeDesc'">
                  <span
                    class="el-dropdown-link"
                    :class="contractType ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :command="_item.value"
                        :class="contractType === _item.value ? 'active' : ''"
                        v-for="_item in contractTypeList"
                        >{{ _item.name }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>

                <template v-else-if="item.field === 'productTypeDesc'">
                  <span
                    class="el-dropdown-link"
                    :class="productType ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :command="_item.value"
                        :class="productType === _item.value ? 'active' : ''"
                        v-for="_item in productTypeList"
                        >{{ _item.name }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>

                <template v-else-if="item.field === 'signChannelDesc'">
                  <span
                    class="el-dropdown-link"
                    :class="signChannel ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :command="_item.value"
                        :class="signChannel === _item.value ? 'active' : ''"
                        v-for="_item in signChannelList"
                        >{{ _item.name }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>

                <template v-else-if="item.field === 'cusIndustryDesc'">
                  <span
                    class="el-dropdown-link"
                    :class="cusIndustry ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :command="_item.value"
                        :class="cusIndustry === _item.value ? 'active' : ''"
                        v-for="_item in cusIndustryList"
                        >{{ _item.name }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>

                <template v-else-if="item.field === 'contractOriginStatusDesc'">
                  <span
                    class="el-dropdown-link"
                    :class="contractOriginStatus ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :command="_item.value"
                        :class="
                          contractOriginStatus === _item.value ? 'active' : ''
                        "
                        v-for="_item in contractOriginStatusList"
                        >{{ _item.name }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>

                <template v-else-if="item.field === 'invoiceStatusDesc'">
                  <span
                    class="el-dropdown-link"
                    :class="invoiceStatus ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :command="_item.value"
                        :class="invoiceStatus === _item.value ? 'active' : ''"
                        v-for="_item in invoiceStatusList"
                        >{{ _item.name }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>
            <span v-else>
              <span
                v-if="item.field === 'companyName'"
                style="padding-left: 22px"
              >
                {{ item.name }}
              </span>
              <span v-else>{{ item.name }}</span>
            </span>
          </template>

          <template slot-scope="scope">
            <span v-if="item.field === 'contractType'">
              {{ scope.row.contractType | formatContractType }}
            </span>

            <span v-else-if="item.field === 'setting'" class="set">
              <span
                class="set-btn"
                @click="editContract(scope.row)"
                v-dt-permission="'客户管理CRM-合同管理-编辑'"
              >
                查看
              </span>
              <span v-dt-permission="'客户管理CRM-合同管理-编辑'"> | </span>

              <span
                class="set-btn"
                @click="payBack(scope.row)"
                v-dt-permission="'客户管理CRM-收/退款管理-查看收款单'"
              >
                收/退款
              </span>
              <span v-dt-permission="'客户管理CRM-收/退款管理-查看收款单'">
                |
              </span>

              <span
                :class="scope.row.isEdit ? 'set-btn' : 'lock'"
                @click="deleteChance(scope.row)"
                v-dt-permission="'客户管理CRM-合同管理-删除'"
              >
                删除
              </span>
              <span v-dt-permission="'客户管理CRM-合同管理-删除'"> | </span>
            </span>

            <span v-else-if="item.field === 'companyName'">
              <span>
                <span class="lock-dt">
                  <i class="el-icon-lock" v-if="!scope.row.isEdit"></i>
                </span>

                <span
                  class="lock-dt"
                  v-dt-permission="'客户管理CRM-合同管理-查看附件'"
                >
                  <i
                    class="el-icon-link"
                    v-if="scope.row.accessory && scope.row.accessory !== '[]'"
                    title="附件"
                  ></i>
                </span>

                <span :class="isLink ? 'c-name' : ''" @click="jump(scope.row)">
                  {{ scope.row[item.field] }}
                </span>
              </span>
            </span>

            <span v-else-if="item.field === 'arrearsAmount'" style="color:#f00">
              {{ transferNum(scope.row[item.field]) }}
            </span>

            <span v-else>
              <template
                v-if="
                  item.field == 'contractAmount' || item.field == 'returnAmount'
                "
              >
                <span>{{ transferNum(scope.row[item.field]) }}</span>
              </template>
              <span v-else>{{ scope.row[item.field] }}</span>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page page-div page-1">
      <footer-page
        @handleCurrentChange="handleCurrentChange"
        @goNumPage="goNumPage"
        @changePageSize="changePageSize"
        :pageStatus="pageStatus"
      ></footer-page>
    </div>

    <el-drawer
      title="收退/款管理"
      size="60%"
      destroy-on-close
      :visible.sync="showPay"
      :with-header="false"
    >
      <div>
        <pay-manage
          :pay-data="currentContract"
          :follow-user-list="followUserList"
        />
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="showContract"
      size="50%"
      :before-close="handleDrawClose"
    >
      <template slot="title">
        <div class="first">
          <div class="layer">
            <span style="font-size:14px"
              >查看合同:{{ editData.companyName }}</span
            >
            <span class="lock-dt">
              <i class="el-icon-lock" v-if="!editData.isEdit"></i>
            </span>
            <span
              style="font-size:14px;position: absolute;  left: 20px;top: 52px;"
              >起止时间{{ editData.startDate }}至{{ editData.expireDate }}</span
            >
          </div>

          <div class="btn-right-contact">
            <el-button
              v-dt-permission="'客户管理CRM-合同管理-锁定/解锁合同'"
              type="primary"
              size="small"
              @click="handleBtnClick(editData, editData.isEdit)"
              >{{ editData.isEdit == 0 ? '解锁' : '锁定' }}</el-button
            >
            <el-button
              type="primary"
              :disabled="!editData.isEdit"
              size="small"
              style="margin:0"
              v-dt-permission="'客户管理CRM-合同管理-编辑'"
              @click="handleBtnClick(editData, 2)"
              >编辑</el-button
            >
            <el-popover placement="bottom" width="270" trigger="click">
              <el-select
                size="medium"
                v-model="previewDocument"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in previewList"
                  :key="item.uid"
                  :label="item.name"
                  :value="item.uid"
                >
                </el-option>
              </el-select>
              <el-button
                class="doc-sure"
                type="primary"
                size="small"
                @click="handlePreviewClick()"
                >确定</el-button
              >
              <el-button
                slot="reference"
                type="primary"
                size="small"
                v-dt-permission="'客户管理CRM-合同管理-查看附件'"
                >预览附件</el-button
              >
            </el-popover>

            <el-popover placement="bottom" width="270" trigger="click">
              <el-select
                size="medium"
                v-model="downloadDocument"
                placeholder="请选择"
                @blur="handleSelectBlur"
              >
                <el-option
                  v-for="(item, index) in previewList"
                  :key="item.uid"
                  :label="item.name"
                  :value="item.url"
                >
                </el-option>
              </el-select>
              <el-button
                class="doc-sure"
                type="primary"
                size="small"
                @click="handleDownloadClick"
                >确定</el-button
              >
              <el-button slot="reference" type="primary" size="small"
                >下载附件</el-button
              >
            </el-popover>
            <el-button
              type="primary"
              size="small"
              @click="handleBtnClick(editData, 3)"
              >更新记录</el-button
            >
          </div>
        </div>
      </template>
      <createContract
        :showContract="showContract"
        :modalOptions="modalOptions"
        :hasEditPermission="hasEditPermission"
      />
    </el-drawer>
    <!-- 文件预览 -->
    <DocumentPreview ref="preview" />
    <!-- 文件下载 -->
    <iframe
      v-if="showAction"
      :src="downloadDocument"
      width="100%"
      frameborder="1"
    />
    <PeDialog
      :PeDialogShow="wrapShow"
      PeDialogTitle="更新记录"
      @handlePeDialogSure="handleWrapClose"
      @handlePeDialogClose="handleWrapClose"
    >
      <el-table :data="tableListData" border style="width: 100%">
        <el-table-column prop="updatedAt" label="时间"> </el-table-column>
        <el-table-column prop="operationFollowName" label="操作人">
        </el-table-column>
        <el-table-column prop="date" label="操作详情">
          <template slot-scope="scope">
            <el-button type="text" @click="handleItemDetail(scope.row)"
              ><span style="color: #02A7F0;">修改详情</span></el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </PeDialog>
    <PeDialog
      @handlePeDialogClose="handleInnerClose"
      @handlePeDialogSure="handleInnerClose"
      :PeDialogShow="innerShow"
      PeDialogTitle="更新记录详情"
    >
      <el-table :data="innerTableData" border style="width: 100%">
        <el-table-column prop="name" label="字段" width="120px">
        </el-table-column>
        <el-table-column prop="previous" label="修改前">
          <template slot-scope="scope">
            <template v-if="scope.row.previous instanceof Array">
              <div class="list-ul" v-for="it in scope.row.previous">
                <div class="list-item">名称:{{ it.name }}</div>
                <div class="list-item">URL:{{ it.url }}</div>
              </div>
            </template>
            <div v-else>{{ scope.row.previous }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="later" label="修改后">
          <template slot-scope="scope">
            <template v-if="scope.row.later instanceof Array">
              <div v-for="it in scope.row.later">
                <div class="list-item">名称:{{ it.name }}</div>
                <div class="list-item">URL:{{ it.url }}</div>
              </div>
            </template>
            <div v-else>{{ scope.row.later }}</div>
          </template>
        </el-table-column>
      </el-table>
    </PeDialog>
  </div>
</template>

<script>
import FooterPage from '@/components/Footerpage/index'
import createContract from '@/views/contract/dialog/createContract'
// import editNewContract from '@/views/contract/dialog/editContract'
import payManage from '@/views/contract/dialog/payManage'
import DocumentPreview from '@/components/Peculiar/document-preview'
import SetColumn from '@/views/contract/dialog/SetColumn'
import { saveAs } from 'file-saver'
import DtDropDown from '@/components/element/DtDropDown'
import PeDialog from '@/components/Peculiar/dialog'
import { formatRegex } from '@/utils/helper'
import {
  contractSearch,
  lockContract,
  contractDel,
  contractFilter,
  contractLeaders,
  getMode,
  getConcatHistory
} from '@/api/user'
import searchContract from '@/views/contract/dialog/searchContract'
import downloadContract from '@/views/contract/dialog/downloadContract'
import { isJSON } from '@/utils/helper'
export default {
  name: 'contractManageIndex',
  components: {
    FooterPage,
    payManage,
    DtDropDown,
    PeDialog,
    createContract,
    DocumentPreview
  },
  data() {
    return {
      currentId: 0,
      modalOptions: {},
      tableListData: [],
      innerTableData: [],
      wrapShow: false,
      innerShow: false,
      hasEditPermission: true,
      showAction: false,
      PeDialogShow: false,
      PeDialogTitle: '',
      previewDocument: '',
      downloadDocument: '',
      editData: {},
      showContract: false,
      search: '',
      optionsList: [],
      hashMap: {},
      loading: false,
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: null
      },

      contractType: '', //合同类型
      productType: '', // 产品类型
      signChannel: '', // 签约渠道
      cusIndustry: '', // 客户行业
      contractOriginStatus: '', // 合同原件状态
      invoiceStatus: '', // 发票状态
      userId: '', // 负责人id
      order: '',
      signName: '',

      followUserList: [], // 负责人下拉筛选
      contractTypeList: [
        {
          name: '新购',
          value: 1
        },
        {
          name: '续费',
          value: 2
        },
        {
          name: '增购',
          value: 3
        }
      ],
      productTypeList: [],
      signChannelList: [],
      cusIndustryList: [
        {
          name: '云',
          value: 1
        },
        {
          name: '非云',
          value: 0
        }
      ],
      contractOriginStatusList: [],
      invoiceStatusList: [],

      showCondition: '',
      condition: '',
      tableData: [],
      columnList: [],
      h: 'calc( 100vh - 170px )',

      showPay: false,
      currentContract: null,
      contractTotalMoney: '',
      contractArrestMoney: '',
      previewList: [],
      editOrCreated: false
    }
  },
  filters: {
    formatContractType(val) {
      return { 1: '新购', 2: '续费', 3: '增购' }[val] || ''
    }
  },
  watch: {
    condition(val) {
      this.showCondition = this.formatCondition(val)
    },
    editData(val) {
      if (val) {
        this.initPreviewList(val)
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.h = window.innerHeight - 170 + 'px'
      this.$bus.$on('handleConcatDrawClose', () => {
        this.showContract = false
      })
      this.$bus.$on('addContactData', flag => {
        this.editOrCreated = flag
        this.getContractList(flag)
      })
    })

    window.onresize = () => {
      this.h = window.innerHeight - 170 + 'px'
    }
  },
  mounted() {
    this.getFollowUserList()
    this.getOptionsList()
    this.getTagList('HTO_01', 'contractOriginStatusList')
    this.getTagList('IS_01', 'invoiceStatusList')
    this.getTagList('CPT_01', 'productTypeList')
    this.getTagList('CSC_01', 'signChannelList')
    let cache = sessionStorage.getItem('contactCache')
    let hashMap = sessionStorage.getItem('optionsList-contract')
    if (hashMap) {
      this.hashMap = JSON.parse(hashMap)
    }
    if (cache) {
      cache = JSON.parse(cache)
      this.condition = cache.condition
      this.companyName = cache.companyName
      this.pageStatus.pageNo = cache.pageNo
      this.pageStatus.pageSize = cache.pageSize
      this.contractType = cache.contractType
      this.productType = cache.productType // 产品类型
      this.signChannel = cache.signChannel // 签约渠道
      this.cusIndustry = cache.cusIndustry // 客户行业
      this.contractOriginStatus = cache.contractOriginStatus // 合同原件状态
      this.invoiceStatus = cache.invoiceStatus // 发票状态
      this.userId = cache.userId
      this.signName = cache.signUserId
      this.order = cache.order
    }
    this.getContractList()
  },
  computed: {
    isLink() {
      const versionNum = this.$store.state.user.accountInfo.versionNum
      return versionNum.indexOf('008') < 0
    }
  },
  methods: {
    transferNum(num) {
      if (num) {
        return formatRegex(num)
      }
    },
    initPreviewList(val) {
      let previewList = []
      try {
        previewList = JSON.parse(val.accessory)
      } catch (error) {
        previewList = val.accessory
      }
      this.previewList = previewList
      const lastLen = this.previewList.length - 1
      if (lastLen >= 0) {
        this.downloadDocument = this.previewList[lastLen]?.url
        this.previewDocument = this.previewList[lastLen]?.uid
      }
    },
    handleItemDetail(data) {
      this.innerShow = true
      this.innerTableData = JSON.parse(data.compareResultJson)
      this.innerTableData.forEach(item => {
        item.previous = isJSON(item.previous)
          ? JSON.parse(item.previous)
          : item.previous
        item.later = isJSON(item.later) ? JSON.parse(item.later) : item.later
      })
      console.log(this.innerTableData)
    },
    handleInnerClose() {
      this.innerShow = false
    },
    handleWrapClose() {
      this.wrapShow = false
    },
    handleDownloadClick() {
      this.showAction = true
    },
    handleSelectBlur() {
      this.showAction = false
    },
    handlePreviewClick() {
      const v = this.previewList.find(item => item.uid === this.previewDocument)
      const k = this.previewList.findIndex(
        item => item.uid === this.previewDocument
      )
      console.log(this.previewList, this.previewDocument, v, k)
      this.$refs['preview'].handleOpen(v, this.previewList, k)
    },
    handleBtnClick(data, id) {
      console.log(data)
      switch (id) {
        case 0:
          lockContract({ id: this.editData.id, isEdit: 1 }).then(() => {
            this.editData.isEdit = 1
          })
          break
        case 1:
          lockContract({ id: this.editData.id, isEdit: 0 }).then(() => {
            this.editData.isEdit = 0
            this.hasEditPermission = true
          })
          break
        case 2:
          console.log('编辑')
          this.hasEditPermission = false
          break
        case 3:
          console.log('更新记录')
          this.wrapShow = true
          getConcatHistory({ id: this.editData.id }).then(res => {
            this.tableListData = res.data
            console.log(res, 1144)
          })
          // this.$refs['preview'].handleOpen(v, attachments, k)
          break
      }
    },
    handleDrawClose() {
      this.showContract = false
      this.hasEditPermission = true
    },
    setColumns() {
      this.$store.dispatch('showModal', {
        title: '列表设置',
        view: SetColumn,
        size: '625px',
        onClose: res => {
          if (res.refresh) {
            this.getContractList()
          }
        }
      })
    },

    formatCondition(val) {
      if (!val) return ''
      let data = val.split(';')
      return data
        .map(i => {
          const target = this.hashMap[i.split(':')[0]]
          return `${target}:${i.split(':')[1]}:${i.split(':')[2]}`
        })
        .join(';')
    },

    jump(val) {
      if (!this.isLink) return
      const uuid = val.companyUuid
      const pid = val.packageId || 0
      this.$router.push({
        path: '/keydetail/keydetail',
        query: {
          companyUuid: uuid,
          packageId: pid,
          showCompanyNameList: false
        }
      })
    },

    // 获取负责人列表
    getFollowUserList() {
      contractLeaders({ pageId: 2002 }).then(res => {
        if (res.code === 60000) {
          this.followUserList = res.data
        }
      })
    },

    getTagList(type) {
      getMode({ type: type }).then(res => {
        if (res.code === 60000) {
          if (type === 'HTO_01') {
            this.contractOriginStatusList = res.data['HTO_01']
          } else if (type === 'IS_01') {
            this.invoiceStatusList = res.data['IS_01']
          } else if (type === 'CPT_01') {
            this.productTypeList = res.data['CPT_01']
          } else if (type === 'CSC_01') {
            this.signChannelList = res.data['CSC_01']
          }
        }
      })
    },

    getOptionsList() {
      contractFilter({ pageId: 2002 }).then(res => {
        if (res.code === 60000) {
          this.optionsList = res.data
          this.optionsList.forEach(item => {
            this.hashMap[item.filterColumn] = item.filterName
          })
          sessionStorage.setItem(
            'optionsList-contract',
            JSON.stringify(this.hashMap)
          )
        }
      })
    },
    searchPlus() {
      this.$store.dispatch('showModal', {
        title: '高级筛选',
        view: searchContract,
        size: '800px',
        options: {
          source: this.optionsList,
          type: 'contract'
        },
        onClose: res => {
          if (res.refresh) {
            this.condition = res.searchFilter
            this.pageNo = 1
            this.pageSize = 20
            this.getContractList()
          }
        }
      })
    },

    removeSearch() {
      this.condition = ''
      this.getContractList()
    },

    sortChange(data) {
      const field = data.prop
      const order = data.order
      const map = {
        ascending: 'asc',
        descending: 'desc'
      }
      order ? (this.order = `${field}:${map[order]}`) : (this.order = '')
      this.getContractList()
    },

    filterFunc(val, item) {
      console.log(val)
      if (item.field === 'followName') {
        this.userId = val
      }
      if (item.field === 'contractTypeDesc') {
        this.contractType = val
      }
      if (item.field === 'productTypeDesc') {
        this.productType = val
      }
      if (item.field === 'signChannelDesc') {
        this.signChannel = val
      }
      if (item.field === 'cusIndustryDesc') {
        this.cusIndustry = val
      }
      if (item.field === 'contractOriginStatusDesc') {
        this.contractOriginStatus = val
      }
      if (item.field === 'invoiceStatusDesc') {
        this.invoiceStatus = val
      }
      if (item.field === 'signName') {
        this.signName = val
      }
      this.getContractList()
    },

    reset() {
      this.condition = ''
      this.search = ''
      this.contractType = ''
      this.productType = ''
      this.signChannel = ''
      this.cusIndustry = ''
      this.contractOriginStatus = ''
      this.invoiceStatus = ''
      this.userId = ''
      this.signName = ''
      this.order = ''

      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      this.getContractList()
    },

    searchContact() {
      if (!this.search) return
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      this.getContractList()
    },

    setCache(params) {
      sessionStorage.setItem('contactCache', params)
    },

    getContractList() {
      this.loading = true
      const params = {
        condition: this.condition,
        companyName: this.search,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        pageId: '2002',
        contractType: this.contractType, //合同类型
        productType: this.productType, // 产品类型
        signChannel: this.signChannel, // 签约渠道
        cusIndustry: this.cusIndustry, // 客户行业
        contractOriginStatus: this.contractOriginStatus, // 合同原件状态
        invoiceStatus: this.invoiceStatus, // 发票状态
        userId: this.userId,
        signUserId: this.signName,
        order: this.order
      }
      contractSearch(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.columnList = []
          this.setCache(JSON.stringify(params))
          this.tableData = res.data.list
          this.pageStatus.pageNo = res.data.page.pageNo
          this.pageStatus.total = res.data.page.total
          this.columnList = [
            ...res.data.columns,
            {
              field: 'setting',
              name: '操作'
            }
          ]
          this.contractTotalMoney = res?.data?.contractTotalMoney || ''
          this.contractArrestMoney = res?.data?.arrearsTotalMoney || ''
          this.$nextTick(() => {
            this.$refs['table'].doLayout()
            if (this.editOrCreated) {
              this.editData = this.tableData.find(
                item => item.id === this.currentId
              )
            }
            console.log(this.editData, '编辑得知')
          })
        }
      })
    },

    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.getContractList()
    },

    goNumPage(val) {
      this.pageStatus.pageNo = val
      this.getContractList()
    },

    changePageSize(val) {
      this.pageStatus.pageSize = val
      this.getContractList()
    },

    // 新建合同
    contractCreate() {
      this.$store.dispatch('showModal', {
        title: '新建合同',
        view: createContract,
        size: '800px',
        options: {
          type: 'create',
          optionsList: this.optionsList
        },
        onClose: res => {
          if (res.refresh) {
            this.getContractList()
          }
        }
      })
      this.$bus.$on('startCreateContact')
      // this.hasEditPermission = false
    },

    // 编辑合同
    editContract(data) {
      this.showContract = true
      this.editData = data
      this.modalOptions = {
        data,
        type: 'edit'
      }
      this.initPreviewList(data)
      //记录当前点击的id
      this.currentId = data.id
      this.previewList.forEach(item => {
        item.status = 'done'
        item.size = 28672
      })
    },

    // 删除合同
    deleteChance(data) {
      if (!data.isEdit) return // 锁定
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: `确认删除该条合同 ？`,
        type: 'warning',
        onClose: res => {
          if (res === 'confirm') {
            this.deleteContractFunc(data.id)
          }
        }
      })
    },

    deleteContractFunc(id) {
      contractDel({ id: id }).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
          this.getContractList()
        }
      })
    },

    // 锁定合同
    lockContract(data) {
      const isEdit = data.isEdit ? 0 : 1
      lockContract({ id: data.id, isEdit: isEdit }).then(res => {
        if (res.code === 60000) {
          this.$store.commit('update', {
            target: data,
            data: {
              isEdit: isEdit
            }
          })
        }
      })
    },

    payBack(params) {
      this.currentContract = params
      this.showPay = true
    },

    downloadContract(params) {
      let list = []
      if (params.accessory && params.accessory !== '[]')
        list = JSON.parse(params.accessory)
      if (!list.length) return
      if (list.length === 1) {
        let downUrl = list[0].url.replace('http://', 'https://')
        saveAs(downUrl, `${params.companyName}-${list[0].name}`)
      }
      if (list.length > 1) {
        this.$store.dispatch('showModal', {
          title: `附件列表 - ${params.companyName}`,
          view: downloadContract,
          size: '600px',
          options: {
            list: list,
            companyName: params.companyName
          }
        })
      }
    }
  },
  beforeDestroy() {
    this.$bus.$off()
  }
}
</script>

<style lang="scss">
.index-main {
  .el-button--primary.is-disabled {
    background: #d5d5d5;
    border-color: #d5d5d5;
    margin: 0;
  }
  .first {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn-right-contact {
    margin-right: 10px;
  }
  .dt-dropdown-dt {
    height: auto;
  }
}
</style>
<style scoped>
.index-main {
  height: 100%;
}
.list-ul {
  margin-top: 10px;
}
.list-item {
  border-bottom: 1px solid #ccc !important;
  padding: 5px 0;
}
.list-item:last-child {
  border-bottom: none !important;
}
.doc-sure {
  margin-top: 10px;
  margin-left: 188px;
}
.btn-list {
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
.input-row {
  display: flex;
  align-items: center;
}
.btn-row {
  margin-left: 10px;
  display: flex;
}
.table {
  margin-top: 10px;
  min-height: calc(100% - 60px - 40px);
  background-color: #fff;
}
.table ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
.table ::v-deep .el-table th,
.el-table tr {
  background-color: #fff;
}
.page-1 {
  background-color: #fff;
}
.set-btn {
  cursor: pointer;
  color: #0099cc;
}
.lock {
  cursor: not-allowed;
  color: #8c939d !important;
}
.set > span:last-child {
  display: none;
}
.el-dropdown-link {
  cursor: pointer;
  color: #909399;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.active {
  color: #41cabf !important;
}
.g-search {
  color: #41cabf;
  margin-left: 10px;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
}
.filtrate {
  margin-left: 2px;
}
.condition {
  position: relative;
  margin-left: 10px;
}
.condition /deep/ .el-input__inner {
  padding-right: 40px;
}
.condition-icon {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40px;
  z-index: 99;
  cursor: pointer;
  color: #41cabf;
  /*background-color: rosybrown;*/
}
.active {
  color: #41cabf !important;
}
.c-name {
  color: #0099cc;
  cursor: pointer;
}
.lock-dt {
  display: inline-block;
  width: 20px;
  color: #333333;
}
.n-icon {
  color: #41cabf;
  margin-right: 5px;
  font-size: 20px;
  cursor: pointer;
}
.set-btn-gray {
  color: rgba(0, 0, 0, 0.3) !important;
  cursor: not-allowed;
}
</style>
