<template>
  <div class="dt-module-detail" v-if="showModule" ref="moduleitem">
    <div v-if="moduleParam.module_api !== 'idc'">
      <template v-if="moduleParam.module_title === 'APP信息'">
        <div class="no-tab-cont">
          <div class="title">{{ moduleParam.module_title }}</div>
          <AppMarket :moduleParam="moduleParam"></AppMarket>
        </div>
      </template>
      <template v-else-if="moduleParam.module_title == '同行竞品'">
        <CompeteProduct
          :moduleDetail="moduleDetail"
          :moduleParam="moduleParam"
        />
      </template>
      <template v-else-if="moduleParam.module_title == '产业链关系图'">
        <div v-if="relationship" class="no-tab-cont">
          <div class="title">{{ moduleParam.module_title }}</div>
          <component
            ref="computedComPic"
            id="computedComPic"
            @getComDetailModel="getComDetailModel"
            v-loading="refreshLoading"
            :is="noTabCont"
            :isScroll="isScroll"
            :otherParams="otherParams"
            :moduleParam="moduleParam"
            :cardType="cardType"
            :moduleDetail="moduleDetail"
            :detail="detail"
            :packageId="packageId"
          >
          </component>
        </div>
      </template>
      <template v-else-if="moduleParam.module_title == '采购商机推荐'">
        <div v-if="isShowCloud" class="no-tab-cont">
          <div class="title">{{ moduleParam.module_title }}</div>
          <component
            ref="computedComPic"
            id="computedComPic"
            @getComDetailModel="getComDetailModel"
            v-loading="refreshLoading"
            :is="noTabCont"
            :isScroll="isScroll"
            :moduleParam="moduleParam"
            :otherParams="otherParams"
            :cardType="cardType"
            :moduleDetail="moduleDetail"
            :detail="detail"
            :packageId="packageId"
          >
          </component>
        </div>
      </template>

      <template v-else>
        <div class="no-tab-cont">
          <div class="title">{{ moduleParam.module_title }}</div>
          <component
            ref="computedComPic"
            id="computedComPic"
            @getComDetailModel="getComDetailModel"
            v-loading="refreshLoading"
            :is="noTabCont"
            :isScroll="isScroll"
            :moduleParam="moduleParam"
            :otherParams="otherParams"
            :cardType="cardType"
            :moduleDetail="moduleDetail"
            :detail="detail"
            :packageId="packageId"
          >
          </component>
        </div>
      </template>
    </div>

    <el-popover
      v-if="moduleParam.module_api === 'idc'"
      placement="bottom-start"
      width="400"
    >
      <div class="modul-table-list">
        <div style="font-size: 16px;height: 50px;margin-left: 20px">
          IDC变更
        </div>
        <div
          :class="
            !otherParams.isReceive && otherParams.shade ? 'bg-filter' : ''
          "
        >
          <div class="pond-cond-all">
            <el-scrollbar
              class="dt-dropdown-dt"
              style="width: 100%;height: 300px;"
            >
              <div v-for="(follow, ind) in moduleDetail.list">
                <div
                  :class="ind !== 0 ? 'pond-cond-item-m' : 'pond-cond-item'"
                  v-if="
                    ind == 0 ||
                      moduleDetail.list[ind - 1].timeAt !== follow.timeAt
                  "
                >
                  <div style="height: 30px;color: #333">
                    {{ follow.timeAt }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                      follow.gongyingshang
                    }}
                  </div>
                  <div style="height:auto;color: #aaa;padding-bottom: 4px">
                    {{ follow.isp }} - {{ follow.address }}
                  </div>
                </div>
                <div class="pond-cond-item-no" v-else>
                  <div style="height: auto;color: #aaa;padding-bottom: 4px">
                    {{ follow.isp }} - {{ follow.address }}
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>

        <div
          class="touch-no-receive"
          v-if="!otherParams.isReceive && otherParams.shade"
          v-loading="getLoading"
        >
          <div class="touch-no-cont">
            <div class="touch-no-icon">
              <span
                style="color: #008CD9;text-decoration: underline;cursor:pointer;"
                @click="getReveiveKey('phone')"
                >领取</span
              >
              <span>以查看所有信息</span>
            </div>
          </div>
        </div>
        <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showDetail">
          <div slot="modalCont">
            <p v-html="recruitStr" style="padding: 20px 0;"></p>
          </div>
          <div slot="submit">
            <div class="grasp_go_pay">
              <span @click="graspGoPay">关闭</span>
            </div>
          </div>
        </dia-model>
      </div>
      <div
        class="yun-icon"
        style="color: #1296db;text-decoration: underline;cursor: pointer;padding: 0 10px"
        slot="reference"
      >
        历史云服务商
      </div>
    </el-popover>
  </div>
</template>

<script>
import { getComDetailModel } from '@/api/thread'
import { isObjNull } from '@/utils/validate'
import Bus from '@/utils/bus'
import tableList from './tableList'
import faceplate from './faceplate'
import cardscroll from './cardscroll'
import cardtouch from './cardtouch'
import cloudList from './cloudList'
import productRelationGraph from './productRelationGraph'
import idctouch from './idctouch1'
import bindMobile from '@/views/usercenter/dialog/bindMobile'
import AppMarket from './components/appMarket'
import CompeteProduct from './components/competeProduct.vue'
export default {
  name: 'AppDetail',
  props: {
    moduleParam: {
      default: () => {},
      type: Object
    },
    detail: {
      default: () => {},
      type: Object
    },
    packageId: {
      type: [String, Number],
      default: 0
    }
  },
  components: {
    AppMarket,
    CompeteProduct
  },
  data() {
    return {
      moduleDetail: {},
      cardType: '',
      isScroll: this.moduleParam.module_scroll === 1,
      otherParams: {},
      showModule: false,
      refreshLoading: false,
      testTime: null,
      emp: true,
      tableList,
      faceplate,
      cardscroll,
      cardtouch,
      cloudList,
      idctouch,
      productRelationGraph: productRelationGraph
    }
  },
  computed: {
    isShowCloud() {
      return this.$store.state.user.accountInfo.cloudOpenList.some(
        item => item.name == '云行业'
      )
    },
    noTabCont() {
      if (this.moduleParam.module_name === 'panel_notab') {
        return this.cloudList
      }
      if (this.moduleParam.module_name === 'list') {
        return this.tableList
      }
      if (this.moduleParam.module_name === 'panel') {
        return this.faceplate
      }
      if (this.moduleParam.module_name === 'cardscroll') {
        return this.cardscroll
      }
      if (
        this.moduleParam.module_name === 'cardtouch' ||
        this.moduleParam.module_name === 'social'
      ) {
        return this.cardtouch
      }
      if (this.moduleParam.module_name === 'cloudServicesList') {
        return this.cloudList
      }
      if (this.moduleParam.module_name === 'graph') {
        return this.productRelationGraph
      }
      if (this.moduleParam.module_name === 'idctouch') {
        return this.tableList
      }
      console.log(this.moduleParam.module_name, 'this.moduleParam.module_name')
    },
    relationship() {
      return this.moduleDetail.links?.length && this.moduleDetail.nodes?.length
    }
  },
  mounted() {
    this.getComDetailModel()
  },
  methods: {
    getReveiveKey() {
      if (
        this.$store.state.plugin.env === 'dd' &&
        !this.$store.state.user.accountInfo.mobile
      ) {
        this.$store.dispatch('showModal', {
          title: '绑定手机号',
          view: bindMobile,
          size: '600px'
        })
        return
      }
      this.getLoading = true
      Bus.$emit('getContactType', true)
    },
    dataParam() {
      this.otherParams.pageSize = this.moduleParam.module_pageSize
      this.otherParams.isScroll = this.moduleParam.module_scroll === 1
      this.otherParams.isReceive = this.moduleParam.isReceive
      this.otherParams.shade = this.moduleParam.module_shade
    },
    getComDetailModel() {
      console.log(this.moduleParam, 9933)
      this.dataParam()
      this.refreshLoading = true
      const params = {
        module: this.moduleParam.module_api,
        packageId: this.moduleParam.packageId,
        companyUuid: this.moduleParam.companyUuid
      }
      if (!this.moduleParam.module_api) return
      getComDetailModel(params).then(res => {
        this.refreshLoading = false
        if (res.code === 60000) {
          if (isObjNull(res.data)) {
            console.log(res, 'uuu')
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                modulePackList: [
                  ...this.$store.state.plugin.modulePackList,
                  this.moduleParam
                ]
              }
            })
            this.showModule = true
            const modelData = Object.keys(res.data)
            this.$store.commit('update', {
              target: this,
              data: {
                moduleDetail: Object.assign({}, res.data[modelData[0]])
              }
            })
          } else {
            this.moduleDetail = {}
            this.showModule = false
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                modulePackList: this.$store.state.plugin.modulePackList.filter(
                  item => item.module_title !== this.moduleParam.module_title
                )
              }
            })
            return
          }
          // 判断 卡片判断logo
          if (this.moduleParam.module_name === 'cardscroll') {
            for (var item in this.moduleDetail.columns) {
              if (this.moduleDetail.columns[item].attr) {
                if (
                  this.moduleDetail.columns[item].attr
                    .split(',')
                    .indexOf('logo') > -1
                ) {
                  this.cardType = 'logo'
                  break
                }
                this.cardType = ''
              } else {
                this.cardType = ''
              }
            }
          }
          if (this.moduleParam.module_name === 'social') {
            this.cardType = 'contact'
          }
          if (this.moduleParam.module_name === 'cardtouch') {
            for (var item in this.moduleDetail.columns) {
              if (this.moduleDetail.columns[item].attr) {
                if (
                  this.moduleDetail.columns[item].attr
                    .split(',')
                    .indexOf('phone') > -1
                ) {
                  this.cardType = 'phone'
                  break
                }
                if (
                  this.moduleDetail.columns[item].attr
                    .split(',')
                    .indexOf('email') > -1
                ) {
                  this.cardType = 'email'
                  break
                }
                if (
                  this.moduleDetail.columns[item].attr
                    .split(',')
                    .indexOf('address') > -1
                ) {
                  this.cardType = 'address'
                  break
                }
                this.cardType = ''
              } else {
                this.cardType = ''
              }
            }
          }
          this.ModuleDom()
        }
      })
    },
    ModuleDom() {
      this.$nextTick(() => {
        typeof this.$refs.computedComPic?.init === 'function' &&
        this.moduleParam.module_name !== 'idc'
          ? this.$refs.computedComPic?.init()
          : ''
        if (this.moduleParam.module_name === 'cardscroll') {
          this.windowWith()
        }
      })
    },
    windowWith() {
      if (this.moduleParam.module_name === 'cardscroll') {
        const CCP = document.getElementById('computedComPic')
        const currentNum = Math.floor((CCP.clientWidth - 180) / 250)
        this.$refs.computedComPic.jianTingWidth(currentNum)
        const _this = this
        window.onresize = function() {
          if (CCP) {
            if (CCP.clientWidth >= 1100) {
              const CCP = document.getElementById('computedComPic')
              const Num = Math.floor((CCP.clientWidth - 180) / 250)
              _this.$refs.computedComPic.jianTingWidth(Num)
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-tab-cont {
  padding: 0 21px 38px;
  position: relative;
  top: 0;
}

.title {
  width: 100%;
  height: 59px;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #2e2e2e;
  line-height: 59px;
  padding-right: 36px;
  /*background-color: red;*/
  text-align: justify;
  position: relative;
  top: 0;
  left: 0;
}

.pond-cond-all {
  /*overflow: hidden;*/
  width: 100%;
  min-height: 200px;
  padding: 0 10px;
}

.pond-cond-item {
  width: 100%;
  padding-left: 15px;
  border-left: #d7d7d7 solid 1px;
  position: relative;
  margin-left: 4px;
}

.pond-cond-item-m {
  padding-top: 20px;
  width: 100%;
  padding-left: 15px;
  border-left: #d7d7d7 solid 1px;
  position: relative;
  margin-left: 4px;
}

.pond-cond-item-m::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  top: 24px;
  left: -4px;
  background: #e4e4e4;
  border-radius: 4px;
  z-index: 999;
}

.pond-cond-item-no {
  margin-left: 4px;
  width: 100%;
  padding-left: 15px;
  border-left: #d7d7d7 solid 1px;
  position: relative;
}

.pond-cond-item::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  top: 4px;
  left: -4px;
  background: #e4e4e4;
  border-radius: 4px;
  z-index: 999;
}

.pond-cond-left {
  float: left;
  width: 70px;
}

.pond-cond-left p {
  font-size: 12px;
  vertical-align: top;
}

.pond-cond-right {
  float: left;
  width: calc(100% - 70px);
  border-left: 1px solid #e4e4e4;
  padding-left: 20px;
  padding-right: 10px;
  position: relative;
}

.pond-cond-all {
  padding: 10px;
}

.bg-filter {
  filter: blur(1.5px);
}

.table-list-href {
  position: relative;
  left: 12px;
  color: #0077cc;
  cursor: pointer;
}

.modul-table-list {
  position: relative;
  margin-top: 18px;
}

.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 100;
}

.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}

.touch-no-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yun-icon7::before {
  content: '';
  width: 15px;
  height: 15px;
  left: 3px;
  top: 16px;
  display: inline-block;
  position: absolute;
  background: url('../../assets/allowpic.png') no-repeat;
  background-size: 100% 100%;
}

.yun-icon {
  font-size: 12px;
  display: inline-block;
  padding-left: 25px;
}

.modul-table-list ::v-deep .el-popover__reference {
  width: 50px;
}

.modul-table-list ::v-deep .el-table--border:last-child {
  border: none !important;
}

.el-table--border .filtrate {
  position: absolute;
  top: 16px;
  left: 3px;
}

.pond-cond-had {
  position: relative;
}

.pond-cond-had::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  right: 10px;
  top: 10px;
  background: #e4e4e4;
  border-radius: 4px;
}

.pond-cond-posi {
  position: relative;
}

.pond-cond-posi::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  top: 2px;
  left: -24px;
  background: #e4e4e4;
  border-radius: 4px;
}

.pond-no-first {
  padding-top: 40px;
}

.modul-table-list ::v-deep .el-table--border::after,
.el-table--group::after {
  top: 0;
  right: 0;
  width: 0px;
  height: 100%;
}
</style>
