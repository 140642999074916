<template>
  <div class="selectCompany-main">
    <div class="company-list">
      <div class="search-input">
        <div style="width: 50%">
          <el-input
            v-model="companyName"
            placeholder="请输入公司查询"
            size="small"
          ></el-input>
        </div>
        <div class="btn-list">
          <el-button size="small" type="primary" @click="searchCompany">
            查询
          </el-button>
          <el-button size="small" @click="reset"> 重置 </el-button>
        </div>
      </div>

      <div class="tip" v-if="selectCompany.companyName">
        <el-tag size="small" closable @close="delCompany">
          {{ selectCompany.companyName }}
        </el-tag>
      </div>

      <div class="table">
        <dt-table
          :data-total="pageStatus.total"
          hide-border
          :key="refreshKey"
          :is-add-earn-field="false"
          :is-count-height="false"
          :shift-field="shiftField"
          :column-width-list="{ select: 50 }"
          table-h="400px"
          :is-select="false"
          :column-list="columns"
          @sizeChange="sizeChange"
          @currentChange="currentChange"
          @singleSelectChange="singleSelectChange"
          :table-loading="loading"
          :table-data="companyList"
        >
          <template v-slot:selectRow="row">
            <span>
              <el-checkbox :value="row.scope.data.id === selectCompany.id" />
            </span>
          </template>
        </dt-table>
      </div>

      <div class="footer">
        <el-button size="small" @click="cancelCompany">取消</el-button>
        <el-button size="small" type="primary" @click="confirmCompany"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { contractCompanyList } from '@/api/user'
import DtTable from '@/components/element/DtTable'

export default {
  name: 'selectCompany',
  components: {
    DtTable
  },
  data() {
    return {
      refreshKey: 0,
      shiftField: [{ field: 'select', name: '' }],
      companyName: '',
      selectCompany: {},
      companyList: [],
      loading: false,
      pageStatus: {
        total: null,
        pageNo: 1,
        pageSize: 10
      },
      columns: [
        {
          field: 'companyName',
          name: '客户名称'
        },
        {
          field: 'createdAt',
          name: '创建时间'
        }
      ]
    }
  },
  mounted() {
    this.getCompanyList()
  },
  computed: {},
  methods: {
    sizeChange(val) {
      this.pageStatus.pageSize = val
      this.getCompanyList()
    },

    currentChange(val) {
      this.pageStatus.pageNo = val
      this.getCompanyList()
    },

    singleSelectChange(val) {
      if (!val) return
      this.selectCompany = val
    },

    searchCompany() {
      if (!this.companyName) return
      this.pageStatus.pageNo = 1
      this.pageStatus.pageSize = 10
      this.pageStatus.total = null
      this.getCompanyList()
    },

    getCompanyList() {
      this.loading = true
      const params = {
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        companyName: this.companyName,
        pageId: 2001
      }
      contractCompanyList(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.companyList = [...res.data.list]
          this.pageStatus = res.data.page
        }
      })
    },

    reset() {
      this.companyName = ''
      this.pageStatus = {
        pageSize: 10,
        pageNo: 1,
        total: 0
      }
      this.getCompanyList()
    },

    delCompany() {
      this.selectCompany = {}
      this.refreshKey += 1
    },

    confirmCompany() {
      if (!this.selectCompany.companyName) {
        this.$store.dispatch('showTip', {
          text: '请选择客户',
          type: 'warning'
        })
        return
      }
      this.$emit('selectCompany', this.selectCompany)
    },

    cancelCompany() {
      this.$emit('closeSelectCompany')
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.search-input {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;
}

.search-input::before {
  display: inline-block;
  content: '';
  height: 5px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  background-color: rgba(0, 0, 0, 0.03);
}

.tip {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.btn-list {
  margin-left: 20px;
}

.footer {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
}
.footer::before {
  content: '';
  display: inline-block;
  width: calc(100% + 40px);
  height: 1px;
  position: absolute;
  transform: scaleY(0.5);
  top: 0;
  left: -20px;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
