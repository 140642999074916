
<template>
  <div class="fapiao-main">
    <div>
<!--      <span class="edit-invoice" @click="editInvClick" :style="{ visibility: isEdit ? 'hidden' : '' }">修改发票信息</span>-->
      <div class="btn">
        <div class="c-name">{{ selectCompanyData.companyName }}</div>
        <el-button :style="{ visibility: isEdit ? 'hidden' : '' }" @click="editInvClick" type="primary" size="small">修改发票信息</el-button>
      </div>
      <el-form :model="invoiceFormData" size="small" label-width="120px" label-position="left" class="form-invoice" v-loading="invoiceLoading">
        <el-form-item label="开具类型">
          <div v-show="openType && invoiceFormData.openType " class="tip-text">{{ openType === '复制成功' ? '复制成功 !' : '点击复制' }}</div>
          <span class="value" @click="copyText(invoiceFormData.openType, 'openType')" v-if="!isEdit" @mouseenter="spanElStatus('openType', true)" @mouseleave="spanElStatus('openType', false)">{{ invoiceFormData.openType }}</span>
          <el-select v-model="invoiceFormData.openType" placeholder="请选择开具类型" style="width: 300px" v-else>
            <el-option
              v-for="item in openTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发票类型">
          <div v-show="invoiceType && invoiceFormData.invoiceType" class="tip-text">{{ invoiceType === '复制成功' ? '复制成功 !' : '点击复制' }}</div>
          <span class="value" @click="copyText(invoiceFormData.invoiceType, 'invoiceType')" @mouseenter="spanElStatus('invoiceType', true)" @mouseleave="spanElStatus('invoiceType', false)" v-if="!isEdit">{{ invoiceFormData.invoiceType }}</span>
          <el-select v-model="invoiceFormData.invoiceType" placeholder="请选择发票类型" style="width: 300px" v-else>
            <el-option
              v-for="item in invoiceTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发票抬头">
          <div v-show="invoiceTitle && invoiceFormData.invoiceTitle" class="tip-text">{{ invoiceTitle === '复制成功' ? '复制成功 !' : '点击复制' }}</div>
          <span class="value" @click="copyText(invoiceFormData.invoiceTitle, 'invoiceTitle')" v-if="!isEdit" @mouseenter="spanElStatus('invoiceTitle', true)" @mouseleave="spanElStatus('invoiceTitle', false)">{{ invoiceFormData.invoiceTitle }}</span>
          <el-input disabled v-model="invoiceFormData.invoiceTitle" v-else style="width: 300px" placeholder="请输入发票抬头"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号">
          <div v-show="taxpayerIdenifyCode && invoiceFormData.taxpayerIdenifyCode" class="tip-text">{{ taxpayerIdenifyCode === '复制成功' ? '复制成功 !' : '点击复制' }}</div>
          <span class="value" @click="copyText(invoiceFormData.taxpayerIdenifyCode, 'taxpayerIdenifyCode')" v-if="!isEdit" @mouseenter="spanElStatus('taxpayerIdenifyCode', true)" @mouseleave="spanElStatus('taxpayerIdenifyCode', false)">{{ invoiceFormData.taxpayerIdenifyCode }}</span>
          <el-input v-model="invoiceFormData.taxpayerIdenifyCode" v-else style="width: 300px" placeholder="请输入纳税人识别号"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <div v-show="address && invoiceFormData.address" class="tip-text">{{ address === '复制成功' ? '复制成功 !' : '点击复制' }}</div>
          <span class="value" @click="copyText(invoiceFormData.address, 'address')" v-if="!isEdit" @mouseenter="spanElStatus('address', true)" @mouseleave="spanElStatus('address', false)">{{ invoiceFormData.address }}</span>
          <el-input v-model="invoiceFormData.address" v-else style="width: 300px" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <div v-show="phone && invoiceFormData.phone" class="tip-text">{{ phone === '复制成功' ? '复制成功 !' : '点击复制' }}</div>
          <span class="value" @click="copyText(invoiceFormData.phone, 'phone')" v-if="!isEdit" @mouseenter="spanElStatus('phone', true)" @mouseleave="spanElStatus('phone', false)">{{ invoiceFormData.phone }}</span>
          <el-input v-model="invoiceFormData.phone" v-else style="width: 300px" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="开户行">
          <div v-show="openBank && invoiceFormData.openBank" class="tip-text">{{ openBank === '复制成功' ? '复制成功 !' : '点击复制' }}</div>
          <span class="value" @click="copyText(invoiceFormData.openBank, 'openBank')" v-if="!isEdit" @mouseenter="spanElStatus('openBank', true)" @mouseleave="spanElStatus('openBank', false)">{{ invoiceFormData.openBank }}</span>
          <el-input v-model="invoiceFormData.openBank" v-else style="width: 300px" placeholder="请输入开户行"></el-input>
        </el-form-item>
        <el-form-item label="银行账号">
          <div v-show="bankAccount && invoiceFormData.bankAccount" class="tip-text">{{ bankAccount === '复制成功' ? '复制成功 !' : '点击复制' }}</div>
          <span class="value" @click="copyText(invoiceFormData.bankAccount, 'bankAccount')" v-if="!isEdit" @mouseenter="spanElStatus('bankAccount', true)" @mouseleave="spanElStatus('bankAccount', false)">{{ invoiceFormData.bankAccount }}</span>
          <el-input v-model="invoiceFormData.bankAccount" v-else style="width: 300px" placeholder="请输入银行账号"></el-input>
        </el-form-item>
        <el-form-item label="邮寄地址">
          <div v-show="mailAddress && invoiceFormData.mailAddress" class="tip-text">{{ mailAddress === '复制成功' ? '复制成功 !' : '点击复制' }}</div>
          <span class="value" @click="copyText(invoiceFormData.mailAddress, 'mailAddress')" v-if="!isEdit" @mouseenter="spanElStatus('mailAddress', true)" @mouseleave="spanElStatus('mailAddress', false)">{{ invoiceFormData.mailAddress }}</span>
          <el-input v-model="invoiceFormData.mailAddress" v-else style="width: 300px" placeholder="请输入邮寄地址"></el-input>
        </el-form-item>
        <el-form-item v-if="isEdit">
          <div style="display: flex;justify-content: flex-end;padding-right: 140px">
            <el-button size="mini" @click="isEdit = false">
              取消
            </el-button>
            <el-button size="mini" type="primary" @click="editInvoice">
              保存
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { editInv, invDetail } from '@/api/user'

export default {
  name: 'fapiao',
  data () {
    return {
      invoiceLoading: false,
      isEdit: false, // 是否编辑发票
      invoiceFormData:{
        id: null, // 发票id
        openType: '', //开具类型
        invoiceType: '', // 发票类型
        invoiceTitle: '', // 发票抬头
        taxpayerIdenifyCode: '', // 纳税人识别号
        address: '', // 地址
        phone: '', // 电话
        openBank: '', // 开户行
        bankAccount: '', //银行账号
        mailAddress: '', // 邮寄地址
      },
      openTypeList: [
        {
          value: '企业',
          name: '企业'
        },
        {
          value: '个人',
          name: '个人'
        }
      ], // 开具类型列表
      invoiceTypeList: [
        {
          value: '增值税专用发票',
          name: '增值税专用发票'
        },
        {
          value: '增值税普通发票',
          name: '增值税普通发票'
        }
      ], // 发票类型列表
      activeTabs: 1,
      selectCompanyData: {},

      openType: false,
      invoiceType: false,
      invoiceTitle: false,
      taxpayerIdenifyCode: false,
      address: false,
      phone: false,
      openBank: false,
      bankAccount: false,
      mailAddress: false,

    }
  },
  mounted () {
    this.selectCompanyData = this.$store.state.plugin.modal.options.data
    this.activeTabs = this.$store.state.plugin.modal.options.activeTabs
    this.getInv()
  },
  computed: {},
  methods: {


    spanElStatus(type, status){
      this[type] = status
    },



    editInvClick(){
      if(this.selectCompanyData.companyName){
        this.isEdit = true
        return
      }
      this.$store.dispatch('showTip', {
        text: '客户信息不能为空',
        type: 'warning'
      })
    },

    // editInvoice(){
    getInv(){
      // 判断是否选则公司
      if(this.selectCompanyData.companyName){
        this.invoiceFormData.invoiceTitle = this.selectCompanyData.companyName
        // 获取发票信息
        this.invoiceLoading = true
        invDetail({companyUuid: this.selectCompanyData.companyUuid}).then(res=>{
          this.invoiceLoading = false
          if(res.code === 60000 && res.data){
            this.isEdit = false
            this.invoiceFormData = Object.assign({}, this.invoiceFormData, res.data)
          }
        })
      }
    },

    editInvoice(){
      if(this.selectCompanyData.companyName){
        this.invoiceLoading = true
        editInv(this.invoiceFormData).then(res=>{
          if(res.code === 60000){
            this.getInv()
          }
        })
      }
    },

    copyText(text, type){
      if(!text) return
      let oInput = document.createElement("input")
      oInput.value = text
      document.body.appendChild(oInput);
      if (oInput.createTextRange) {
        oInput.select()
      } else {
        oInput.setSelectionRange(0, oInput.value.length);
        oInput.focus()
      }
      document.execCommand("Copy")
      oInput.className = "oInput"
      oInput.style.display = "none"
      this[type] = '复制成功'
      // this.$store.dispatch('showTip', {
      //   text: `复制成功 ${ text }  `,
      //   type: 'success',
      //   duration: 500
      // })
    },
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.fapiao-main{
  padding: 20px;
  box-sizing: border-box;
}
.btn{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.form-invoice /deep/ .el-form-item{
  margin-bottom: 10px!important;
  min-height: 39px!important;
  height: auto!important;
}
.edit-invoice{
  font-size: 12px;
  color: #0099cc;
  cursor: pointer;
  display: inline-block;
  padding-bottom: 10px;
  box-sizing: border-box;
  user-select: none;
}
.value{
  /*user-select: none;*/
  color: #8c939d;
  display: inline-block;
  width: 300px;
  position: relative;
  /*height: 38px;*/
  /*line-height: 38px;*/
  cursor: pointer;
}
.value:hover{
  background-color: rgba(0,0,0,.03);
}

.value:before{
  content: '';
  height: 1px;
  width: 100%;
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.08);
  transform: scaleY(.5);
}
.c-name{
  display: flex;
  align-items: center;
}
.tip-text{
  position: absolute;
  border: #000000 solid 1px;
  font-size: 12px;
  top: -25px;
  padding: 15px 15px;
  height: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
}
</style>
