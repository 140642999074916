<template>
  <div class="oace-key-table">
    <el-table
      :data="companyList"
      border
      stripe
      resizable
      ref="multipleTable"
      size="small"
      @sort-change="handleSortChange"
      @cell-click="cellClick"
      v-loading="showTableLoading"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
      style="height:100%;"
    >
      <template slot="empty">
        <div style="min-height:400px;padding-top:100px;">
          <div class="no-data-icon-body">
            <svg-icon icon-class="noData" class="no-data-icon" />
            <div class="no-data-text">暂无数据</div>
          </div>
        </div>
      </template>
      <el-table-column
        type="selection"
        prop="collet"
        width="45"
      ></el-table-column>
      <el-table-column prop="collet" width="45">
        <template slot-scope="scope">
          <div
            class="w-3 company-list new-hover-type"
            v-dt-permission="'智能获客-推荐线索-加入培育'"
          >
            <svg-icon
              icon-class="collect2"
              class="star"
              @click="del_collect(scope.row.company_uuid)"
              v-if="scope.row.sub === 'true'"
            />
            <svg-icon
              icon-class="collect1"
              class="star"
              @click="add_collect(scope.row.company_uuid)"
              v-else
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="edit" width="1" label>
        <template slot-scope="scope">
          <span
            class="com-list-detail"
            @click="
              goKeyDetail(
                scope.row['company_uuid'],
                scope.$index,
                scope.row.followUserId
              )
            "
          ></span>
        </template>
      </el-table-column>
      <div v-if="!showTableLoading">
        <div v-for="(item, index) in companyColunes" :key="index + 's'">
          <el-table-column
            :show-overflow-tooltip="item.english !== 'company_name'"
            :prop="item.english"
            :align="item.english === 'company_name' ? 'left' : 'center'"
            :width="item.english === 'company_name' ? '300' : ''"
            :label="item.chinese"
          >
            <template slot="header" slot-scope="scope">
              <p class="sort_list_cont">
                <span
                  class="sort_list_name"
                  :class="{
                    'sort-icon-header': item.hasOrder,
                    active:
                      (item.english === 'follow_name' &&
                        followUserIds !== '') ||
                      (item.english === 'activity_class' &&
                        selectAc &&
                        selectAc !== '全部')
                  }"
                  :title="item.chinese"
                >
                  {{ item.chinese }}
                  <span class="sort_list_left" v-if="true">
                    <span class="sort_list_tip" v-if="item.hasOrder">
                      <i
                        class="sort_list_btn sort_up"
                        :class="item.showUp ? 'sort_up_choose' : 'sort_up'"
                        @click="sortListBtn('up', index, item.showUp)"
                      ></i>
                      <i
                        class="sort_list_btn"
                        :class="
                          item.showDown ? 'sort_down_choose' : 'sort_down'
                        "
                        @click="sortListBtn('down', index, item.showDown)"
                      ></i>
                    </span>
                  </span>
                </span>
                <el-dropdown
                  v-if="item.english === 'follow_name'"
                  trigger="click"
                  @command="handleCommand"
                >
                  <span class="el-dropdown-link" style="cursor:pointer;">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <dt-drop-down :source-list="followList" target-value="text">
                      <template v-slot:default="row">
                        <el-scrollbar
                          style="height: 200px;width: 100%"
                          class="dt-dropdown-dt"
                        >
                          <div
                            class="dt-drop-down-no-data"
                            v-if="!row.filterList.length"
                          >
                            无数据
                          </div>
                          <el-dropdown-item
                            v-for="(follow, ind) in row.filterList"
                            :key="ind"
                            :command="follow.value"
                            :class="{
                              active:
                                followUserIds !== 'aaa' &&
                                followUserIds &&
                                Number.parseInt(followUserIds) === follow.value
                            }"
                          >
                            <span v-html="follow.htmlEl"></span>
                          </el-dropdown-item>
                        </el-scrollbar>
                      </template>
                    </dt-drop-down>
                  </el-dropdown-menu>
                </el-dropdown>

                <el-dropdown
                  v-if="item.english === 'activity_class'"
                  trigger="click"
                  @command="handleActiveCommand"
                >
                  <span class="el-dropdown-link" style="cursor:pointer;">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(follow, ind) in ['全部', '高', '中高', '中']"
                      :key="ind"
                      :command="follow"
                      :class="follow === selectAc ? 'active' : ''"
                      >{{ follow }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </p>
            </template>

            <template slot-scope="scope">
              <span
                class="hover-tip-icon"
                v-if="
                  item.english === 'company_name' &&
                    currentHover === scope.$index
                "
                >{{ hoverText }}</span
              >

              <span
                v-if="item.english === 'company_name'"
                class="cus-name-hid"
                :style="
                  item.english === 'company_name'
                    ? 'cursor:pointer'
                    : 'cursor:default'
                "
                @click="
                  goKeyDetail(
                    scope.row['company_uuid'],
                    scope.$index,
                    scope.row.followUserId,
                    scope.row['company_name']
                  )
                "
                :class="{
                  list_opcity: scope.row.click === '1',
                  company_name: item.english === 'company_name',
                  newClues:
                    scope.row.newClues == 'true' &&
                    scope.row.click !== '1' &&
                    scope.row.sub === 'false'
                }"
                >{{ scope.row[item.english] }}</span
              >
              <span v-else>
                <span v-if="item.english === 'activity_class'">
                  <el-tag v-if="scope.row[item.english]" size="mini">{{
                    scope.row[item.english]
                  }}</el-tag>
                </span>
                <span v-else>
                  {{ scope.row[item.english] }}
                </span>
              </span>
            </template>
          </el-table-column>
        </div>
      </div>

      <div v-else>
        <div v-for="(item, index) in companyColunes" :key="index + 'b'">
          <el-table-column
            :show-overflow-tooltip="item.english !== 'company_name'"
            :prop="item.english"
            :align="item.english === 'company_name' ? 'left' : 'center'"
            :width="item.english === 'company_name' ? '250' : ''"
            :label="item.chinese"
          >
            <template slot="header">
              <p class="sort_list_cont">
                <span
                  class="sort_list_name"
                  :class="[item.hasOrder ? 'sort-icon-header' : '']"
                  :title="item.chinese"
                >
                  {{ item.chinese }}
                  <span class="sort_list_left">
                    <span class="sort_list_tip" v-if="item.hasOrder">
                      <i
                        class="sort_list_btn sort_up"
                        :class="item.showUp ? 'sort_up_choose' : 'sort_up'"
                        @click="sortListBtn('up', index, item.showUp)"
                      ></i>
                      <i
                        class="sort_list_btn"
                        :class="
                          item.showDown ? 'sort_down_choose' : 'sort_down'
                        "
                        @click="sortListBtn('down', index, item.showDown)"
                      ></i>
                    </span>
                  </span>
                </span>
              </p>
            </template>
            <template slot-scope="scope">
              <span
                class="company_had_time_list"
                :title="'当前线索已被领取' + scope.row.consumptionCount + '次'"
                v-if="
                  scope.row.consumptionCount !== '0' &&
                    item.english === 'company_name'
                "
                >{{ scope.row.consumptionCount }}</span
              >
              <span
                class="hover-tip-icon"
                v-if="
                  item.english === 'company_name' &&
                    currentHover === scope.$index
                "
                >{{ hoverText }}</span
              >

              <span
                v-if="item.english === 'company_name'"
                class="cus-name-hid"
                :style="
                  item.english === 'company_name'
                    ? 'cursor:pointer'
                    : 'cursor:default'
                "
                @click="copyComName(item.english, scope.$index, scope.row)"
                @mouseover="copyHoverIn(item.english, scope.$index)"
                @mouseout="copyHoverOut(item.english, scope.$index)"
                :class="scope.row.click === '1' ? 'list_opcity' : ''"
                >{{ scope.row[item.english] }}</span
              >

              <span
                v-else
                @click="
                  goKeyDetail(
                    scope.row['company_uuid'],
                    scope.$index,
                    scope.row.followUserId
                  )
                "
                style="cursor:pointer;"
                >{{ scope.row[item.english] }}</span
              >
            </template>
          </el-table-column>
        </div>
      </div>
    </el-table>
  </div>
</template>

<script>
import { getTransUserList } from '@/api/subaccount'
import DtDropDown from '@/components/element/DtDropDown'

export default {
  components: {
    DtDropDown
  },
  props: {
    companyList: {
      type: Array,
      default: () => []
    },
    companyColunes: {
      type: Array,
      default: () => []
    },
    checkAll: {
      type: Array,
      default: () => []
    },
    keyPackageList: {
      type: Array,
      default: () => []
    },
    followList: {
      type: Array,
      default: () => []
    },
    followUserIds: {
      type: String,
      default: 'aaa'
    },
    selectAc: {
      default: '全部'
    }
  },
  data() {
    return {
      currentHover: null,
      hoverText: '点击复制',
      sortOrders: [],
      showTableLoading: false,
      checkList: []
    }
  },
  created() {
    const SSname = `KeyParams${localStorage.getItem('currentPackageId')}`
    // -------------------------------------------KeyParam 存至sotrage1
    const haveCondition = sessionStorage.getItem(SSname)
    if (haveCondition) {
      const params = JSON.parse(haveCondition)
      if (params.followUserIds) {
        const num = params.followUserIds.split(',')
        this.checkList = []
        num.forEach(item => {
          if (item) {
            this.checkList.push(Number(item))
          }
        })
      } else {
        this.checkList = []
      }
    } else {
      this.checkList = []
    }
  },
  methods: {
    handleCommand(val) {
      console.log('ttt', this.companyColunes)
      console.log('ttt', this.followList)
      console.log('ttt', this.followUserIds)
      this.checkList = []
      if (val === 'aaa') {
        this.checkList.push(null)
      } else {
        this.checkList.push(val)
      }
      this.$emit('chooseSelectFollow', this.checkList)
    },

    handleActiveCommand(val) {
      this.$emit('chooseSelectAc', val)
    },
    searveList() {
      this.checkList = []
      this.$emit('chooseSelectFollow', this.checkList)
    },
    getFollowNameList(val) {
      if (val) {
      } else {
        this.checkList = []
      }
      this.$emit('chooseSelectFollow', this.checkList)
    },
    chooseSelectFollow(val) {
      this.checkList = []
      this.checkList.push(val.value)
    },
    getFollowList() {
      getTransUserList({}).then(res => {
        if (res.code === 60000) {
          this.followList = []
          res.data.forEach(item => {
            const obj = {}
            obj.text = item.name
            obj.value = item.userId
            this.followList.push(obj)
          })
        } else {
          this.followList = []
        }
      })
    },
    goPackMarket() {
      this.$router.push({
        path: '/market/market'
      })
    },
    openLoading() {
      this.showTableLoading = true
    },
    fuckCloseLoading() {
      this.showTableLoading = false
    },
    // 关闭就好好关闭 还写尼玛的逻辑啊 操
    closeLoading(val) {
      this.showTableLoading = false

      val.forEach((item, index) => {
        this.$set(this.companyColunes, index, item)
      })
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    cellClick(row, column, cell, e) {
      if (column.property === 'collet') {
        return
      }
    },
    goKeyDetail(id, index, followUserId, cname) {
      console.log('111', followUserId)
      const params = {
        id: id,
        followUserId,
        index: index,
        companyName: cname,
        back: true
      }
      this.$emit('goKeyDetail', params)
    },
    del_collect(val) {
      this.$emit('del_collect', val)
    },
    add_collect(val) {
      this.$emit('add_collect', val)
    },
    sortListBtn(val, index, type) {
      const currCom = this.companyColunes[index]
      if (val === 'up') {
        this.$set(currCom, 'showDown', !type)
        if (!type) {
          this.$set(currCom, 'showUp', false)
        }
      }
      if (val === 'down') {
        this.$set(currCom, 'showDown', !type)
        if (!type) {
          this.$set(currCom, 'showUp', false)
        }
      }
      const params = {
        type: val,
        index: index,
        showType: type
      }
      this.$emit('sortListBtn', params)
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    toggleSelection(rows) {
      this.$refs.multipleTable.clearSelection()
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSortChange(sortValue) {},
    copyComName(val, index, item) {
      if (val === 'company_name') {
        event.stopPropagation()
        var oInput = document.createElement('input')
        oInput.value = item[val]
        document.body.appendChild(oInput)
        this.selectText(oInput)
        document.execCommand('Copy')
        oInput.className = 'oInput'
        oInput.style.display = 'none'
        this.hoverText = '复制成功!'
        setTimeout(() => {
          this.currentHover = null
        }, 500)
      }
    },
    selectText(oInput) {
      if (oInput.createTextRange) {
        //ie
        oInput.select()
      } else {
        //firefox/chrome
        oInput.setSelectionRange(0, oInput.value.length)
        oInput.focus()
      }
    },
    copyHoverIn(val, index) {
      if (val === 'company_name') {
        this.currentHover = index
        this.hoverText = '点击复制'
      }
    },
    copyHoverOut(val, index) {
      if (val === 'company_name') {
        this.currentHover = null
      }
    }
  }
}
</script>

<style scoped>
.di-in-line {
  display: inline-block;
}
.new-select-ad {
  text-align: center;
  font-size: 13px;
  cursor: pointer;
}
.el-checkbox__label {
  color: #41cabf !important;
}
.new-select-ad:hover {
  color: #41cabf;
}
.no-data-icon-body {
  width: 100%;
  height: 50px;
  margin: 0 auto;
}
.no-data-icon {
  height: 40px;
  width: 35px;
}
.cus-name-hid {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}
.sort_down {
  top: 16px;
  background: url('../../assets/no-down.png') no-repeat;
  background-size: 100% 100%;
  background-position: top;
}
.sort_down_choose {
  top: 16px;
  background: url('../../assets/choose-down.png') no-repeat;
  background-size: 100% 100%;
  background-position: top;
}
.sort_up {
  top: 5px;
  background: url('../../assets/no-up.png') no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}
.sort_up_choose {
  top: 5px;
  background: url('../../assets/choose-up.png') no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}
.sort_list_btn {
  height: 8px;
  width: 11px;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  left: 0;
  cursor: pointer;
}
.sort_list_tip {
  display: inline-block;
  width: 12px;
  height: 40px;
  position: relative;
}
.sort_list_left {
  position: absolute;
  right: 0px;
  top: -4px;
  display: inline-block;
}
.sort-icon-header {
  position: relative;
  padding-right: 20px;
}
.com-list-detail {
  color: #0099cc;
  cursor: pointer;
}
.list_opcity.company_name {
  /* background: #E7FFFD; */
  color: #b0b1b3;
}
.company_name {
  color: #0099cc;
}
.company_had_time_list {
  position: absolute;
  left: 0;
  top: 0px;
  display: inline-block;
  height: 15px;
  border-radius: 8px;
  background: #41cabf;
  color: white;
  padding: 0 5px 0 5px;
  line-height: 15px;
  text-align: center;
  font-size: 12px;
}
.hover-tip-icon {
  position: absolute;
  display: inline-block;
  width: 60px;
  text-align: center;
  height: 30px;
  border: 1px solid #979797;
  border-radius: 5px;
  left: 20px;
  top: -20px;
  color: #979797;
  line-height: 28px;
  font-size: 12px;
  background: white;
}
.sort_list_cont {
  height: 100%;
  margin: 0 auto;
  position: relative;
  /* display: inline-block;
        width: 80%; */
}
.sort_list_name {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  overflow: hidden;
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.oace-key-table {
  padding-top: 10px;
}
.oace-key-table ::v-deep .el-table--border th {
  border-right: 1px solid #ebeef5;
  height: 50px;
}
.oace-key-table ::v-deep .el-table--border th:nth-child(1) {
  height: 50px;
}
.oace-key-table ::v-deep .el-table--border th:nth-child(2) {
  height: 50px;
}
.oace-key-table
  ::v-deep
  .el-table--enable-row-transition
  .el-table__body
  tbody
  tr
  td {
  border-right: none;
}
.oace-key-table ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
.active {
  color: #41cabf;
}

.newClues::after {
  content: '';
  width: 45px;
  height: 30px;
  right: 9px;
  top: -4px;
  display: inline-block;
  position: relative;
  background: url('../../assets/new.svg') no-repeat;
  background-size: 100% 100%;
}
</style>
