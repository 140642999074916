<template>
  <div class="body" :id="mapId" />
</template>

<script>
export default {
  name: 'dtMap',
  data() {
    return {
      map: null,
      renderOptions: {
        featureStyleByLevel: {
          country: {
            fillStyle: 'rgba(237, 237, 237, 0.1)'
          },
          province: {
            fillStyle: 'rgba(237, 237, 237, 0.1)'
          },
          //市
          city: {
            fillStyle: 'rgba(237, 237, 237, 0.1)'
          },
          //区县
          district: {
            fillStyle: 'rgba(237, 237, 237, 0.1)'
          }
        },
        featureStyle: {
          lineWidth: 0.5, //描边线宽
          strokeStyle: 'rgba(0, 0, 0, .2)' //描边色
        },

        featureEventSupport: true,
        clusterMarkerEventSupport: true,
        //标注信息Marker上需要监听的事件
        clusterMarkerEventNames: ['click']
      }
    }
  },
  props: {
    mapId: {
      type: String,
      default: 'dt-map'
    },
    mapData: {
      type: Array,
      default: () => {
        return []
      }
    },
    excludedAdCodes: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted() {
    this.mapInit()
  },
  beforeDestroy() {
    this.map.destroy()
  },
  methods: {
    mapInit() {
      this.map = new window.dtMap.Map(`${this.mapId}`, { zoom: 1 })
      window['dtMapUi'].load(
        ['ui/geo/DistrictCluster', 'lib/$'],
        (DistrictCluster, $) => {
          this.initPage(DistrictCluster, $)
        }
      )

      window.dtMap.plugin(['AMap.ToolBar'], () => {
        // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
        this.map.addControl(new window.dtMap.ToolBar())
      })
    },

    initPage(DistrictCluster, $) {
      let distCluster = new DistrictCluster({
        map: this.map,
        zIndex: 11,
        excludedAdcodes: this.excludedAdCodes,
        getPosition: item => {
          if (!item) {
            return null
          }
          let parts = item.split(',')
          return [parseFloat(parts[0]), parseFloat(parts[1])]
        },
        renderOptions: this.renderOptions
      })

      // 抛异常代码
      try {
        distCluster.setData(this.mapData)
        console.log(this.mapData,'this.mapData');
        this.checkEmit(distCluster)
      } catch (e) {
        this.checkEmit(distCluster)
        this.map.setZoom(4)
      }
    },

    setZoom() {
      const currentZoom = this.map.getZoom()
      this.map.setZoom(currentZoom)
    },

    checkEmit(distCluster) {
      console.log(distCluster)
      this.$emit('lineDataReady', 'all') // 查看全部数据}
      // let _status = true
      // this.map.on('zoomend', ()=>{
      //   if(!_status){
      //     return
      //   }
      //   if(this.map.getZoom() > 7 && [... new Set(this.mapData)].length < 2){
      //     this.map.setZoom(7)
      //   }
      //   _status = false
      // })
      distCluster.on('clusterMarkerClick', (e, record) => {
        console.log(record.feature.properties.level)
        // 清除存在的事件
        this.map.off('zoomstart', this.setZoom)
        // 取消city和district的点击事件引起的点分散
        const clickType = record.feature.properties.level
        if (clickType === 'city' || clickType === 'district') {
          this.map.on('zoomstart', this.setZoom)
        }

        if (record.adcode === 100000) {
          // 点击全国 刷新组件
          this.$emit('refreshMap')
        } else {
          this.$emit('lineDataReady', record.feature.properties.name) // 查看全部数据
        }
      })
    }
  }
}
</script>

<style scoped>
.body {
  height: 100%;
  width: 100%;
}
</style>
