var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "downloadContract-main" },
    [
      _vm._l(_vm.list, function (item) {
        return _c(
          "div",
          {
            staticClass: "item",
            on: {
              click: function ($event) {
                return _vm.downloadContract(item)
              },
            },
          },
          [
            _c("div", [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]),
            _vm._v(" "),
            _c("div", { staticClass: "download" }, [
              _vm._v("\n      下载\n    "),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$store.dispatch("hideModal")
                },
              },
            },
            [_vm._v("\n      关闭\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }