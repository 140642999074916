<template>
  <div class="software-main">
    <DtScroll margin="0">
      <div class="header">
        <div class="title">
          <span>软件企业洞察</span>
          <span
            v-if="IsAccess == 0 || IsAccess == 1"
            class="normal-data"
            @click="handleNormalData"
            >{{ $updateText }}</span
          >
        </div>

        <div class="filter">
          <SoftwareFilter
            @selectChange="selectChange"
            :softwareFlag="softwareFlag"
            :count="count"
          ></SoftwareFilter>
        </div>
      </div>
      <div class="main-row">
        <el-tabs v-model="activeTab" @tab-click="tabClick">
          <el-tab-pane label="数据明细" name="2">
            <div slot="label">
              <el-badge
                :value="message.software > 0 ? message.software : ''"
                class="item"
              >
                <span>数据明细</span>
              </el-badge>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="personalRecommend == 1"
            label="个性化推荐"
            name="3"
          >
            <div slot="label">
              <span>个性化推荐</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <main v-if="activeTab === '2' || activeTab == '3'">
          <div
            class="key-list"
            ref="keyList"
            :style="{ minHeight: `${listHeight}px` }"
          >
            <SoftwareList
              @count="setCount"
              @handleSoftwareReset="handleSoftwareReset"
              :activeTab="activeTab"
              :package="currentPackage"
              :min-height="listHeight"
              :params="filterOp"
            />
          </div>
        </main>
      </div>
    </DtScroll>
  </div>
</template>

<script>
import DtScroll from '@/components/element/DtScroll'
import SoftwareList from '@/views/insight/software/softwareList'
import address from '@/views/customerpond/map/address'
import SoftwareFilter from '@/views/insight/software/softwareFilter'
import { insightMould, insightSearch } from '@/api/customerpond'
import { withOutAccess } from '@/utils/helper'
export default {
  name: 'SoftWare',
  components: {
    DtScroll,
    SoftwareList,
    SoftwareFilter
  },
  data() {
    return {
      listHeight: 0,
      key: 0,
      activeTab: localStorage.softwareClick || '2',
      currentPackage: {},
      packageList: [],
      chartList: [],
      filterList: [],
      filterOp: {},
      count: 0,
      softwareFlag: false
    }
  },
  async mounted() {
    this.key = 0
    this.currentPackage = {}
    this.packageList = []
    this.chartList = []
    await this.getMouldList()
    if (
      localStorage.getItem('beforePath').indexOf('/keydetail/keydetail') >= 0
    ) {
      this.activeTab = '2'
      this.chartInit()
    }
  },
  computed: {
    packId() {
      return this.$store.state.user.accountInfo.packId
    },
    message() {
      return this.$store.state.permission.messageNum
    },
    personalRecommend() {
      return this.$store.state.user.accountInfo.personalDeveloperOpen
    },
    IsAccess() {
      return this.$store.state.user.accountInfo.softwareInsightDetailsOpen
    }
  },
  methods: {
    handleNormalData() {
      withOutAccess()
    },
    setCount(value) {
      this.count = value
    },
    selectChange(list) {
      this.filterOp = list
    },
    handleSoftwareReset() {
      this.softwareFlag = true
    },
    chartInit() {
      this.chartList.forEach(item => {
        item.list.forEach(item => {
          this.$set(item, 'loading', true)
          const type = item.btnList.find(i => i.active)?.type || 0
          this.getChartData(item.key, type, item)
        })
      })
    },

    tabClick(e) {
      this.$nextTick(() => {
        const top = this.$refs.keyList?.getBoundingClientRect()?.top || 353
        this.listHeight = window.innerHeight - top - 10
      })
      localStorage.setItem('softwareClick', e.name)
    },

    getIcon(name) {
      const icon = {
        bar_graph: 'el-icon-s-data',
        pie_chart: 'el-icon-pie-chart',
        line_graph: 'el-icon-s-data'
      }
      return icon[name] || ''
    },

    getMouldList() {
      return new Promise((resolve, reject) => {
        insightMould({ packId: this.packId }).then(res => {
          if (res.code === 60000) {
            let list = (res?.data || []).map(item => {
              return {
                name: item.classification,
                list: item.module.map(item => {
                  return {
                    key: item.module_api,
                    name: item.module_title,
                    _name: item.module_name,
                    btnList: (item.chart?.split(',') || []).map(i => {
                      return {
                        type: i,
                        active: i === item.default_chart,
                        icon: this.getIcon(i)
                      }
                    }),
                    loading: true,
                    isMap: item.default_chart === 'map',
                    direction: item.direction,
                    data:
                      item.default_chart === 'map'
                        ? {
                            mapData: [],
                            excludedAdCodes: [],
                            nullNum: 0
                          }
                        : []
                  }
                })
              }
            })
            this.chartList = list
            resolve()
          }
        })
      })
    },

    changeType(type, target, key) {
      target.btnList.find(item => item.type === type).active = true
      target.btnList.find(item => item.type !== type).active = false
      this.$forceUpdate()
    },

    getChartData(key, type, item, fn) {
      const params = {
        packageTable: this.currentPackage?.packageTable || null,
        item: key
      }

      insightSearch(Object.assign({}, params, this.filterOp))
        .then(res => {
          if (res.code === 60000) {
            if (item.isMap) {
              item.data.mapData = res?.data || []
            } else {
              item.data = this.formatData(type, res?.data || [], item)
            }
            item.loading = false
            this.$forceUpdate()
            typeof fn === 'function' ? fn() : ''
          }
        })
        .catch(err => {
          this.$store.dispatch('showTip', {
            text: '出错了',
            type: 'error'
          })
        })
    },

    formatData(type, data, target) {
      console.log(target)
      const name = target._name
      console.log(target._name)
      if (name.indexOf('enterprise') > -1) {
        return data?.rzEnterprise?.map(item => {
          return {
            key: item.item,
            value: parseInt(item.sum)
          }
        })
      } else if (name.indexOf('amount') > -1) {
        return data?.rzAmount?.map(item => {
          return {
            key: item.item,
            value: (parseInt(item.amount) / 10000).toFixed(2),
            name: '未披露'
          }
        })
      } else {
        return data?.map(item => {
          return {
            key: item.item,
            value: parseInt(item.amount)
          }
        })
      }
    },

    handleRemoveMarkerNull(data, target) {
      const codeList = Object.keys(address)
      codeList.splice(0, 1)
      target.excludedAdCodes = codeList
        .filter(item => !data.includes(item.substring(0, 2)))
        .map(_item => {
          return parseInt(_item)
        })
      this.$forceUpdate()
    },

    changePackage(item) {
      this.currentPackage = item
      this.$forceUpdate()
      this.key += 1
      this.$nextTick(() => {
        this.chartInit()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.software-main {
  height: 100%;
  width: 100%;
  .search {
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 10px 15px 10px;
    box-sizing: border-box;

    & > div:nth-child(2n) {
      margin: 0 10px;
    }
  }
  .header {
    background-color: #fff;
    padding: 0 10px 0 10px;
    margin-bottom: 10px;
    box-sizing: border-box;

    .title {
      user-select: none;
      height: 40px;
      font-size: 16px;
      position: relative;
      display: flex;
      align-items: center;
      &:after {
        content: '';
        display: inline-block;
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: scaleY(0.5);
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .package {
      padding-top: 20px;
      box-sizing: border-box;

      .name {
        font-size: 16px;
        padding-bottom: 10px;
        box-sizing: border-box;
      }

      .list {
        width: 100%;
        overflow-x: auto;
        padding: 0 20px;
        box-sizing: border-box;
        white-space: nowrap;
        height: 120px;

        & > .item:last-child {
          margin-right: 20px !important;
        }

        .no-package-data {
          width: 80% !important;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.3);
          display: flex !important;
          align-items: center;
          justify-content: center;
          border: none !important;
        }

        .item {
          display: inline-block;
          width: 300px;
          height: 100px;
          margin: 0 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          //margin-bottom: 10px;
          .item-main-row {
            height: 100%;
            width: 100%;
            display: flex;
            cursor: pointer;

            .icon {
              width: 70px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 35px;
              color: rgba(0, 0, 0, 0.1);
            }

            .desc {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-right: 10px;
              box-sizing: border-box;

              .name {
                width: 100%;
                font-size: 16px;
                font-weight: bolder;
                white-space: pre-wrap;
                word-break: break-word;
              }

              .num {
                font-size: 12px;
                font-weight: bolder;
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
      }
    }
  }

  .main-row {
    background-color: #fff;

    .key-list {
      background-color: #fff;
      height: auto;
    }

    .chart {
      padding: 0 10px;
      box-sizing: border-box;

      .chart-header {
        display: flex;
        align-items: center;

        .name {
          font-size: 16px;
        }

        .icon {
          font-size: 20px;
          color: #8a8a8a;
          cursor: pointer;
        }

        .active-color {
          color: #41cabf;
        }

        & > div {
          margin-right: 10px;
        }
      }

      .item {
        height: 300px;
        position: relative;

        .null-data {
          position: absolute;
          z-index: 99;
          right: 0;
          bottom: 0;
          font-size: 12px;
          color: #fff;
          padding: 10px;
          box-sizing: border-box;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.software-main {
  .main-row {
    .el-card__header {
      border-bottom: none !important;
    }
    .card {
      .el-card {
        border-bottom: none !important;
      }
    }
    .el-tabs__item {
      height: 50px !important;
      width: 150px !important;
      text-align: center;
      line-height: 50px !important;
      font-size: 16px !important;
      user-select: none !important;
    }

    .el-tabs__active-bar {
      width: 150px !important;
    }

    .el-card__body {
      padding: 0 !important;
    }

    .el-card__header {
      padding: 20px !important;
    }
    .el-badge__content.is-fixed {
      top: 7px !important;
    }
  }
}
</style>
