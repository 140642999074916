<template>
  <div class="auto-send" v-loading="listLoading">
    <div class="creat-btn">
      <el-button v-if="false" size="small" type="primary"> </el-button>
      <el-button
        @click="createSendTarget"
        type="primary"
        size="small"
        v-show="list.length"
      >
        新建转派任务
      </el-button>
    </div>
    <el-scrollbar class="send-row dt-dropdown-dt">
      <div class="no-list" v-if="!list.length">
        <span>
          <span class="no-data-tip">暂无转派任务</span
          ><span class="new-task-tip" @click="createSendTarget">点击新建</span>
        </span>
      </div>
      <div v-infinite-scroll="load">
        <div class="item" v-for="item in list" :key="item.id">
          <el-row :gutter="20" type="flex" align="middle">
            <el-col :span="18">
              <el-row :gutter="20" class="row">
                <el-col
                  v-for="(col, key) in columns"
                  :key="key"
                  :span="col.span"
                  class="col"
                >
                  <el-row :gutter="20">
                    <el-col :span="col.span === 24 ? 2 : 4">
                      <span class="label">{{ col.label }}</span>
                    </el-col>
                    <el-col :span="col.span === 24 ? 22 : 20">
                      <span class="value">{{ item[key] }}</span>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6">
              <el-row justify="center" type="flex">
                <el-col :span="12">
                  <div style="text-align: center;user-select: none">
                    <span @click="changeSwitch(item)">
                      <el-switch :value="item.onOff === 'on'" />
                    </span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="setting">
                    <span @click="editTask(item)">编辑</span
                    ><span class="line">|</span
                    ><span @click="removeTask(item)">删除</span><span>|</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-scrollbar>

    <el-dialog
      class="el-dialog-dt"
      :show-close="false"
      append-to-body
      :visible.sync="showCreate"
      :close-on-click-modal="false"
      destroy-on-close
      top="90px"
      width="700px"
    >
      <div slot="title" class="title">
        <span>
          {{ type === 'create' ? '新建转派任务' : '编辑转派任务' }}
        </span>
        <i class="el-icon-close" @click="cancelBtn"></i>
      </div>
      <div
        class="create-row"
        v-loading="loading"
        loading-text="正在新建转派任务"
      >
        <el-scrollbar class="form-row dt-dropdown-dt">
          <el-form
            :model="formData"
            label-width="60px"
            hide-required-asterisk
            ref="form"
            label-position="left"
            class="form-el"
          >
            <el-form-item
              v-for="(item, index) in formList"
              :key="index"
              :label="item.label"
              :prop="index"
              :rules="item.rule"
            >
              <template v-if="item.type === 'input'">
                <el-input
                  v-model.number="formData[index]"
                  :placeholder="item.tip"
                ></el-input>
              </template>

              <template v-if="item.type === 'select'">
                <el-select
                  style="width: 100%"
                  @change="e => selectChange(item, index, e)"
                  v-model="formData[index]"
                  :multiple="item.selectMore"
                  collapse-tags
                  :placeholder="item.tip"
                >
                  <el-option
                    v-for="_item in item.options"
                    :key="_item.value"
                    :label="_item.name"
                    :value="_item.value"
                  >
                  </el-option>
                </el-select>
              </template>

              <template v-if="item.type === 'checkbox'">
                <template v-if="index === 'assignPackIds'">
                  <el-radio-group v-model="assignPackIds">
                    <el-radio label="-1">全部</el-radio>
                    <el-radio label="2">自定义</el-radio>
                  </el-radio-group>
                  <div class="pack-list" v-show="assignPackIds === '2'">
                    <el-row :gutter="10">
                      <el-col
                        :span="12"
                        v-for="_item in item.options"
                        :key="_item.value"
                      >
                        <el-checkbox
                          class="checkbox-dt-dt"
                          @change="changeAssignPackIds(_item)"
                          :value="formData.assignPackIds.includes(_item.value)"
                        >
                          {{ _item.name }}
                        </el-checkbox>
                      </el-col>
                    </el-row>
                  </div>
                </template>
                <template v-if="index === 'assignUserIds'">
                  <el-radio-group v-model="assignUserIds">
                    <el-radio label="-1">全部</el-radio>
                    <el-radio label="2">自定义</el-radio>
                  </el-radio-group>
                  <div class="pack-list" v-show="assignUserIds === '2'">
                    <el-row :gutter="10">
                      <el-col :span="6" v-for="_item in item.options">
                        <el-checkbox
                          :title="_item.name"
                          class="checkbox-dt-dt"
                          @change="changeAssignUserIds(_item)"
                          :value="formData.assignUserIds.includes(_item.value)"
                        >
                          {{ _item.name | formatName }}
                        </el-checkbox>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </template>
            </el-form-item>
            <el-form-item
              label="截止"
              v-if="formData.expireStr === 2"
              prop="_expireStr"
              :rules="[{ required: true, message: '请设置自定义有效期' }]"
              ref="scrollObj"
            >
              <el-date-picker
                :editable="false"
                style="width: 100%"
                v-model="formData._expireStr"
                type="date"
                :picker-options="startTime"
                value-format="yyyy-MM-dd"
                placeholder="请设置自定义有效期"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </el-scrollbar>
        <div class="footer">
          <el-button size="small" @click="cancelBtn">取消</el-button>
          <el-button type="primary" size="small" @click="confirmBtn"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { throttle } from 'lodash'
import {
  autoTransferList,
  selectTransfer,
  addTranffer,
  updateTransfer,
  deleteTransfer
} from '@/api/workTable'
export default {
  name: 'autoSend',
  data() {
    return {
      startTime: {
        disabledDate: time => {
          return time.getTime() < Date.now()
        }
      },
      type: 'create',
      throttleTime: 500,
      value: true,
      showCreate: false,
      loading: false,
      listLoading: false,
      list: [],
      pageObj: {
        pageNo: 0,
        pageSize: 20,
        total: 0
      },
      columns: {
        execTimeValue: {
          label: '时间',
          span: 12,
          defaultValue: '-'
        },
        assignNum: {
          label: '数量',
          span: 12,
          defaultValue: '-'
        },
        assignModeValue: {
          label: '方式',
          span: 12,
          defaultValue: '-'
        },
        expireValue: {
          label: '有效期',
          span: 12,
          defaultValue: '-'
        },
        assignUserIdsValue: {
          label: '对象',
          span: 24,
          defaultValue: '-'
        },
        assignPackIdsValue: {
          label: '线索包',
          span: 24,
          defaultValue: '-'
        }
      },
      formList: {
        execTime: {
          label: '时间',
          rule: [
            { required: true, message: '请选择转派时间', trigger: 'change' }
          ],
          options: [],
          type: 'select',
          selectMore: false,
          tip: '请选择转派时间'
        },
        assignPackIds: {
          label: '线索包',
          rule: [
            { required: true, message: '请选择线索包', trigger: 'change' }
          ],
          options: [],
          type: 'checkbox',
          selectMore: true,
          tip: '请选择线索包'
        },
        assignUserIds: {
          label: '对象',
          rule: [
            { required: true, message: '请选择转派对象', trigger: 'change' }
          ],
          options: [],
          type: 'checkbox',
          selectMore: true,
          tip: '请选择转派对象'
        },
        assignNum: {
          label: '数量',
          rule: [
            { required: true, message: '请输入数量' },
            { type: 'number', min: 1, message: '数量必须为非零整数' }
          ],
          type: 'input',
          selectMore: false,
          tip: '请输入数量'
        },
        assignMode: {
          label: '方式',
          rule: [
            { required: true, message: '请选择转派方式', trigger: 'change' }
          ],
          options: [],
          type: 'select',
          selectMore: false,
          tip: '请选择转派方式'
        },
        expireStr: {
          label: '有效期',
          rule: [
            { required: true, message: '请选择有效期', trigger: 'change' }
          ],
          options: [],
          type: 'select',
          selectMore: false,
          tip: '请选择有效期'
        }
      },
      formData: {
        execTime: '',
        assignPackIds: [-1],
        assignUserIds: [-1],
        assignNum: '',
        assignMode: '',
        expireStr: '',
        _expireStr: '',
        onOff: 'on',
        id: null
      },
      assignPackIds: '-1',
      assignUserIds: '-1',
      userIdList: []
    }
  },

  computed: {},

  created() {
    this.changeSwitch = throttle(
      item => {
        this._changeSwitch(item)
      },
      this.throttleTime,
      { trailing: false }
    )
    this.createSendTarget = throttle(
      () => {
        this._createSendTarget()
      },
      this.throttleTime,
      { trailing: false }
    )
    this.confirmBtn = throttle(
      () => {
        this._confirmBtn()
      },
      this.throttleTime,
      { trailing: false }
    )
    this.editTask = throttle(
      item => {
        this._editTask(item)
      },
      1000,
      { trailing: false }
    )
    this.removeTask = throttle(
      item => {
        this._removeTask(item)
      },
      1000,
      { trailing: false }
    )
  },

  mounted() {
    this.getTransferDrop()
  },

  filters: {
    formatName(name) {
      const _name = name.substr(0, 6)
      return name === _name ? name : `${_name}...`
    }
  },

  methods: {
    selectChange(item, key, e) {
      if (key === 'expireStr' && e === 2) {
        this.$nextTick(() => {
          const scrollObj = this.$refs.scrollObj
          scrollObj && scrollObj.$el.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },

    changeAssignPackIds(item) {
      if (this.formData.assignPackIds.includes(item.value)) {
        this.formData.assignPackIds = this.formData.assignPackIds.filter(
          _item => _item != item.value
        )
      } else {
        this.formData.assignPackIds.push(item.value)
      }
    },

    changeAssignUserIds(item) {
      if (this.formData.assignUserIds.includes(item.value)) {
        this.formData.assignUserIds = this.formData.assignUserIds.filter(
          _item => _item != item.value
        )
      } else {
        this.formData.assignUserIds.push(item.value)
      }
    },

    getTransferDrop() {
      selectTransfer().then(res => {
        if (res.code === 60000) {
          this.formList.execTime.options = res.data.zpTimeList
          this.formList.assignPackIds.options = res.data.packList
          this.formList.assignUserIds.options = res.data.userList
          this.formList.assignMode.options = res.data.zpModeList
          this.formList.expireStr.options = res.data.zpExList
          this.userIdList = res.data.userList.map(item => item.value)
        }
      })
    },

    load() {
      if (this.pageObj.total > this.list.length || this.pageObj.total === 0) {
        this.pageObj.pageNo += 1
        if (this.pageObj.pageNo === 1) {
          this.listLoading = true
        }
        this.getTransferList()
      }
    },

    getTransferList() {
      autoTransferList(this.pageObj).then(res => {
        this.listLoading = false
        if (res.code === 60000) {
          this.list = [...this.list, ...res.data.voList]
          this.pageObj.total = res.data.page.total
        }
      })
    },

    // 新建转派任务
    _createSendTarget() {
      this.showCreate = true
      if (this.type === 'create') {
        this.formData._expireStr = ''
        this.assignPackIds = '-1'
        this.assignUserIds = '-1'
      }
    },

    _editTask(item) {
      this.type = 'update'
      if (item.assignPackIds !== '-1') {
        this.assignPackIds = '2'
      }
      if (item.assignUserIds !== '-1') {
        this.assignUserIds = '2'
      }
      const execTime = item.execTime
      const assignPackIds = item.assignPackIds.split(',').map(item => +item)
      const assignUserIds = item.assignUserIds.split(',').map(item => +item)
      const assignNum = item.assignNum
      const assignMode = item.assignMode
      const expireStr = item.expire
      const onOff = item.onOff
      const _expireStr = expireStr === 1 ? '' : item.expireValue
      const id = item.id
      this.formData = {
        execTime,
        assignPackIds,
        assignUserIds,
        assignNum,
        assignMode,
        expireStr,
        _expireStr,
        onOff,
        id
      }
      this.$forceUpdate()
      this._createSendTarget()
    },

    _removeTask(item) {
      this.$store.dispatch('showMessageBox', {
        title: '删除自动转派任务',
        text: '确认删除此条任务？',
        type: 'error',
        onClose: res => {
          if (res === 'confirm') {
            deleteTransfer({ id: item.id }).then(res => {
              if (res.code === 60000) {
                this.pageObj.pageNo = 1
                this.pageObj.total = 0
                this.list = []
                this.getTransferList()
                this.tip(res.msg)
              }
            })
          }
        }
      })
    },

    _confirmBtn() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.type === 'create') {
            this.loading = true
            const params = {
              execTime: this.formData.execTime,
              assignPackIds:
                this.assignPackIds === '-1'
                  ? '-1'
                  : this.formData.assignPackIds
                      .filter(item => item !== -1)
                      .join(','),
              assignUserIds:
                this.assignUserIds === '-1'
                  ? '-1'
                  : this.formData.assignUserIds
                      .filter(
                        item => item !== -1 && this.userIdList.includes(item)
                      )
                      .join(','),
              assignNum: this.formData.assignNum,
              assignMode: this.formData.assignMode,
              expireStr:
                this.formData.expireStr === 1 ? 1 : this.formData._expireStr,
              onOff: this.formData.onOff
            }
            addTranffer(params).then(res => {
              this.loading = false
              if (res.code === 60000) {
                this.cancelBtn()
                this.pageObj.pageNo = 1
                this.pageObj.total = 0
                this.list = []
                this.getTransferList()
                this.tip(res.msg)
              }
            })
          }
          if (this.type === 'update') {
            const params = {
              id: this.formData.id,
              execTime: this.formData.execTime,
              assignPackIds:
                this.assignPackIds === '-1'
                  ? '-1'
                  : this.formData.assignPackIds
                      .filter(item => item !== -1)
                      .join(','),
              assignUserIds:
                this.assignUserIds === '-1'
                  ? '-1'
                  : this.formData.assignUserIds
                      .filter(
                        item => item !== -1 && this.userIdList.includes(item)
                      )
                      .join(','),
              assignNum: this.formData.assignNum,
              assignMode: this.formData.assignMode,
              expireStr:
                this.formData.expireStr === 1 ? 1 : this.formData._expireStr,
              onOff: this.formData.onOff
            }
            updateTransfer(params).then(res => {
              if (res.code === 60000) {
                this.cancelBtn()
                this.pageObj.pageNo = 1
                this.pageObj.total = 0
                this.list = []
                this.getTransferList()
                this.tip(res.msg)
              }
            })
          }
        }
      })
    },

    _changeSwitch(item) {
      this.setTask(item)
    },

    setTask(item) {
      const params = {
        id: item.id,
        execTime: item.execTime,
        assignPackIds: item.assignPackIds,
        assignUserIds: item.assignUserIds,
        assignNum: item.assignNum,
        assignMode: item.assignMode,
        expireStr: item.expire,
        onOff: item.onOff === 'on' ? 'off' : 'on'
      }
      updateTransfer(params).then(res => {
        if (res.code === 60000) {
          item.onOff = item.onOff === 'on' ? 'off' : 'on'
          this.list.forEach(_item => {
            if (_item.id === item.id) {
              _item = item
              this.$forceUpdate()
            }
          })
          this.tip(res.msg)
        }
      })
    },

    cancelBtn() {
      this.closeModal()
      // this.formData = this.$options.data().formData
      this.formData = {
        execTime: '',
        assignPackIds: [-1],
        assignUserIds: [-1],
        assignNum: '',
        assignMode: '',
        expireStr: '',
        _expireStr: '',
        onOff: 'on',
        id: null
      }
      this.assignPackIds = '-1'
      this.assignUserIds = '-1'
    },

    closeModal() {
      this.showCreate = false
      this.type = 'create'
    },

    tip(msg = '操作成功') {
      this.$store.dispatch('showTip', {
        text: msg,
        type: 'success'
      })
    }
  }
}
</script>

<style scoped>
.auto-send {
  height: 450px;
  width: 100%;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.send-row {
  height: calc(100% - 52px);
  width: 100%;
}
.item {
  margin-bottom: 40px;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
}
.item::before {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -20px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
}
.send-row .item:last-child {
  margin-bottom: 0 !important;
}
.send-row .item:last-child::before {
  display: none !important;
}
.label {
  display: inline-block;
  width: 50px;
  font-size: 12px;
}
.value {
  color: #000000;
  line-height: 180%;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
.setting {
  color: #41cabf;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  user-select: none;
}
.line {
  margin: 0 5px;
}
.setting > span:last-child {
  display: none;
}
.creat-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.new-task {
  color: #41cabf;
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
}
.title {
  height: 50px;
  width: 100%;
  background-color: #41cabf;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title i {
  cursor: pointer;
}

.el-dialog-dt ::v-deep .el-dialog__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}
.el-dialog-dt ::v-deep .el-dialog__body {
  padding: 0 !important;
}

.create-row {
  width: 100%;
}
.footer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 20px;
  box-sizing: border-box;
}
.footer::before {
  content: '';
  display: inline-block;
  position: absolute;
  height: 1px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
}
.no-list {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.no-data-tip {
  margin-right: 3px;
}
.new-task-tip {
  color: #2b8dfd;
  text-decoration-line: underline;
  cursor: pointer;
}
.form-row {
  height: 375px;
  width: 100%;
}
.form-el {
  width: calc(100% - 80px);
  margin: 20px auto 0 auto;
}
.pack-list {
  /*padding: 0 20px;*/
  box-sizing: border-box;
}
.checkbox-dt-dt /deep/ .el-checkbox__label {
  color: #606266 !important;
}
</style>
