var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flexible-charts-page" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "fl" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", icon: "el-icon-pie-chart" },
                on: {
                  click: function ($event) {
                    _vm.drawShow = true
                  },
                },
              },
              [_vm._v("添加图表")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "panel" },
              [
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { size: "mini", placeholder: "数据大屏简称" },
                  model: {
                    value: _vm.companyInfo.workName,
                    callback: function ($$v) {
                      _vm.$set(_vm.companyInfo, "workName", $$v)
                    },
                    expression: "companyInfo.workName",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "time" }, [
                  _vm._v("最后更新时间" + _vm._s(_vm.companyInfo.updatedAt)),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "fr" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.handleAllSave },
              },
              [_vm._v("保存")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.handlePreview },
              },
              [_vm._v("预览")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container", attrs: { id: "content" } },
        [
          _c(
            "grid-layout",
            {
              ref: "gridlayout",
              attrs: {
                layout: _vm.layoutList,
                "col-num": 60,
                "row-height": 30,
              },
              on: {
                "update:layout": function ($event) {
                  _vm.layoutList = $event
                },
              },
            },
            _vm._l(_vm.layoutList, function (item, index) {
              return _c(
                "grid-item",
                {
                  key: index,
                  attrs: {
                    x: item.x,
                    y: item.y,
                    w: item.w,
                    h: item.h,
                    i: item.i,
                  },
                  on: { resized: _vm.resizedEvent, moved: _vm.movedEvent },
                },
                [
                  _c(
                    "div",
                    { staticClass: "btn-operate" },
                    [
                      _c("el-button", {
                        staticClass: "c-edit",
                        attrs: {
                          icon: "el-icon-edit",
                          circle: "",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(item)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("el-button", {
                        staticClass: "c-delete",
                        attrs: {
                          icon: "el-icon-delete",
                          circle: "",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(item.data, index)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  item.data.isTitleShow
                    ? _c("div", { staticClass: "chart-title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.data.title) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("pe-chart", {
                    attrs: {
                      item: item.data,
                      screenId: _vm.screenId,
                      chartChange: _vm.chartChange,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "pe-draw",
        {
          attrs: {
            "pe-drawer-show": _vm.drawShow,
            "pe-draw-size": "300px",
            direction: "ltr",
          },
          on: {
            handleDrawClose: function ($event) {
              _vm.drawShow = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "charts-flexible-wrap" },
            _vm._l(_vm.chartsList, function (item, index) {
              return _c("div", { key: index, staticClass: "wrap-item" }, [
                _c("div", { staticClass: "group-title" }, [
                  _vm._v(_vm._s(item.groupName)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inner" },
                  _vm._l(item.groupArr, function (it, idx) {
                    return _c(
                      "div",
                      {
                        key: idx,
                        staticClass: "inner-item",
                        attrs: { draggable: "true", unselectable: "on" },
                        on: {
                          dragend: function ($event) {
                            return _vm.handleCharts(it)
                          },
                        },
                      },
                      [
                        _c("PeTool", [
                          _c(
                            "div",
                            {
                              attrs: { slot: "txt-content" },
                              slot: "txt-content",
                            },
                            [_vm._v(_vm._s(it.fullTitle))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "tool-desc",
                              attrs: { slot: "txt-btn" },
                              slot: "txt-btn",
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-pie-chart icon-chart",
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(it.title))]),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c("pe-chart-dialog", {
        attrs: {
          show: _vm.show,
          screenId: _vm.screenId,
          chartData: _vm.fullChartData,
        },
        on: { handleClose: _vm.handleClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }