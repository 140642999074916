export const registeredCapital = [
  {
    name: '500万以下',
    value: 1
  },
  {
    name: '500-1000万',
    value: 2
  },
  {
    name: '1000-5000万',
    value: 3
  },
  {
    name: '5000万以上',
    value: 4
  }
]

export const registeredTime = [
  {
    name: '1年内',
    value: 1
  },
  {
    name: '1-3年',
    value: 2
  },
  {
    name: '3-5年',
    value: 3
  },
  {
    name: '5-10年',
    value: 4
  },
  {
    name: '10年以上',
    value: 5
  }
]

export const employeeScale = [
  {
    name: '0-49人',
    value: 1
  },
  {
    name: '50-99人',
    value: 2
  },
  {
    name: '100-499人',
    value: 3
  },
  {
    name: '500-999人',
    value: 4
  },
  {
    name: '1000-4999人',
    value: 5
  },
  {
    name: '5000以上',
    value: 6
  }
]

export const globalPhone = [
  {
    name: '有手机号',
    value: 1
  },
  {
    name: '有座机',
    value: 0
  }
]
