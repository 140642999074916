import { render, staticRenderFns } from "./searchFollowRecord.vue?vue&type=template&id=4053bd7e&scoped=true"
import script from "./searchFollowRecord.vue?vue&type=script&lang=js"
export * from "./searchFollowRecord.vue?vue&type=script&lang=js"
import style0 from "./searchFollowRecord.vue?vue&type=style&index=0&id=4053bd7e&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4053bd7e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Project\\dtbird-web-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4053bd7e')) {
      api.createRecord('4053bd7e', component.options)
    } else {
      api.reload('4053bd7e', component.options)
    }
    module.hot.accept("./searchFollowRecord.vue?vue&type=template&id=4053bd7e&scoped=true", function () {
      api.rerender('4053bd7e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/followRecord/dialog/searchFollowRecord.vue"
export default component.exports