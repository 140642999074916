var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "update-div",
    },
    [
      _c("permission", {
        key: _vm.key,
        attrs: {
          name: _vm.name,
          "permission-list": _vm.permissionList,
          "update-permission": _vm.updatePermission,
          type: "编辑角色",
        },
        on: { save: _vm.save },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }