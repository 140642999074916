var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "RunIn-main" }, [
    _vm.pathUrl
      ? _c("span", { staticClass: "a-link", on: { click: _vm.goPath } }, [
          _vm._v("\n    " + _vm._s(_vm.dataNum || 0) + "\n  "),
        ])
      : _c("span", [_vm._v("\n    " + _vm._s(_vm.dataNum || 0) + "\n  ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }