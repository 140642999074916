<template>
  <div class="task-list-main" ref="main" v-loading="loading">
    <div class="header"></div>
    <div class="row" ref="row">
      <span class="header-tip">* 导出将会自动进行，您可以关闭此弹框</span>
      <dt-table
        :show-overflow-tooltip="false"
        :is-count-height="false"
        :dt-height="550"
        :table-h="tableHeight"
        :table-data="tableData"
        :is-select="false"
        :data-total="count"
        @sizeChange="sizeChange"
        @currentChange="currentChange"
        :column-list="columnList"
        :hide-border="true"
        :page-size="[10]"
        :column-width-list="{ downloadName: 300 }"
      >
        <template v-slot:setTingRow="row">
          <span>
            <span
              class="success"
              v-if="
                row.scope.data.downloadStatus === 1 &&
                  row.scope.data.downloadUrl
              "
            >
              <a :href="row.scope.data.downloadUrl" download="download">
                下载文件
              </a>
            </span>
            <span v-else class="error">
              --
            </span>
          </span>
        </template>

        <template v-slot:downloadPercentRow="row">
          <span v-if="row.scope.data.downloadStatus === 0">
            <el-progress
              :percentage="row.scope.data.downloadPercent"
              :stroke-width="8"
              class="layui-progress-bar"
            ></el-progress>
          </span>
          <span v-else>
            {{ row.scope.data.downloadStatus === -1 ? '导出失败' : '导出成功' }}
          </span>
        </template>

        <template v-slot:typeRow="row">
          <span>
            {{ row.scope.data.type === 1 ? '培育池' : '' }}
            {{ row.scope.data.type === 2 ? '客户公海' : '' }}
            {{ row.scope.data.type === 3 ? '通话记录' : '' }}
          </span>
        </template>
      </dt-table>
    </div>
  </div>
</template>

<script>
import DtTable from '@/components/element/DtTable'
import { taskList } from '@/api/workTable'
// downloadStatus  0:正在下载， 1： 下载成功, -1: 下载失败
export default {
  name: 'list',
  components: {
    DtTable
  },
  data() {
    return {
      tableHeight: '490px',
      loading: false,
      tableData: [],
      count: 0,
      columnList: [
        {
          field: 'downloadName',
          name: '文件名称'
        },
        {
          field: 'type', // 1培育池，2客户公海
          name: '来源'
        },
        {
          field: 'downloadPercent',
          name: '状态'
        },
        {
          field: 'createdAt',
          name: '创建时间'
        }
      ],
      pageObj: {
        pageNo: 1,
        pageSize: 10
      },
      timeObj: null
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.startTask()
    })
  },

  beforeDestroy() {
    this.$store.commit('update', {
      target: this.$store.state.plugin.modal.options,
      data: {
        timeObj: this.timeObj
      }
    })
    if (this.timeObj) {
      clearInterval(this.timeObj)
      this.timeObj = null
    }
  },

  methods: {
    startTask() {
      if (this.timeObj) {
        clearInterval(this.timeObj)
      }
      this.refreshTask(true)
      this.timeObj = setInterval(() => {
        this.refreshTask(false)
      }, 5 * 1000)
    },

    sizeChange(e) {
      this.pageObj.pageSize = e
      this.pageObj.pageNo = 1
      this.startTask()
    },

    currentChange(e) {
      this.pageObj.pageNo = e
      this.startTask()
    },

    // 刷新下载列表
    refreshTask(isLoding = false) {
      this.loading = isLoding
      taskList(this.pageObj).then(res => {
        this.loading = false
        if (res.code === 60000 && res.data && res.data.data) {
          this.tableData = res.data.data
          this.count = res.data.total
          // 判断是否关闭轮询 当列表返回的没有正在下载的任务时 关闭轮询
          let isStopRequest = true
          this.tableData.forEach(item => {
            if (item.downloadStatus === 0) {
              isStopRequest = false
            }
          })
          if (isStopRequest && this.timeObj) {
            clearInterval(this.timeObj)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.task-list-main {
  padding: 10px;
  box-sizing: border-box;
  user-select: none;
}
.header {
  /*height: 30px;*/
  height: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.row {
  /*height: calc( 100% - 30px );*/
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: relative;
}
.success {
  color: #0099cc;
  cursor: pointer;
}
.error {
  color: #909399;
  cursor: not-allowed;
  margin: 0;
}
.refresh {
  cursor: pointer;
  font-size: 18px;
}
.anio {
  animation-name: refreshanio;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
@keyframes refreshanio {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.header-tip {
  font-size: 10px;
  color: #909399;
  position: absolute;
  bottom: 15px;
  left: 0;
}

.layui-progress-bar ::v-deep .el-progress-bar__inner:before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  animation: move 1s linear infinite;
}
@keyframes move {
  to {
    background: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.3) 0,
        rgba(255, 255, 255, 0.3) 12.5%,
        transparent 0,
        transparent 25%
      )
      0/60px 60px;
  }
  from {
    background: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.3) 12.5%,
        rgba(255, 255, 255, 0.3) 25%,
        transparent 0%,
        transparent 37.5%
      )
      0/60px 60px;
  }
}

.layui-progress-bar ::v-deep .el-progress__text {
  font-size: 10px !important;
}
</style>
