<template>
  <div class="batchEdit-main">
    <div class="form-main-row">
      <el-form label-width="auto" :model="formData">
        <el-form-item label="客户来源">
          <el-select v-model="formData.source" placeholder="请选择" style="width: 100%">
            <el-option
              v-for="item in sourceList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户池">
          <el-select v-model="formData.customerType" placeholder="请选择" style="width: 100%">
            <el-option-group
              v-for="group in customerTypeList"
              :key="group.id"
              :label="group.name">
              <el-option
                v-for="item in group.pages"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            placeholder="请输入备注"
            v-model="formData.remark"
            maxlength="500"
            show-word-limit
            :autosize="{ minRows: 4, maxRows: 10 }">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <el-button size="small" @click="closePop">取消</el-button>
      <el-button type="primary" size="small" @click="confirmClick">确认</el-button>
    </div>
  </div>
</template>

<script>
import { getCusTagsDdic } from '@/api/customerpond'
import { batchPool } from '@/api/pool'
import { setPermission } from '@/cores/RouteAndPermission'

export default {
  name: 'batchEdit',
  data () {
    return {
      formData: {
        source: '', // 客户来源
        remark: '', // 备注
        customerType: ''
      },
      sourceList: [],
      loading: false
    }
  },
  mounted () {
    this.getOptions()
  },
  computed: {
    customerTypeList() {
      return this.$store.state.permission.menuList
    },
    modalOptions() {
      return this.$store.state.plugin.modal.options
    }
  },
  methods: {
    getOptions() {
      const params = {
        type: '6'
      }
      getCusTagsDdic(params).then(res => {
        if (res.code === 60000) {
          this.sourceList = res.data;
        }
      })
    },
    closePop () {
      this.$store.dispatch('hideModal')
    },
    confirmClick () {
      const params = {
        ids: this.modalOptions.ids.join(','),
        tagId: this.formData.customerType,
        fromType: this.formData.source,
        remark: this.formData.remark
      }
      if (this.loading) return
      this.loading = true
      batchPool(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          setPermission()
          this.$store.commit('update', {
            target: this.$store.state.plugin.modal.options,
            data: {
              refresh: true
            }
          })
          this.closePop()
        }
      }).catch(() => {
        this.loading = false
      })
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.form-main-row {
  padding: 10px;
  box-sizing: border-box;
}

.footer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
}

.footer::before {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .1);
  transform: scaleY(.5);
}
</style>
