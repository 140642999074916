import Driver from 'driver.js'
import Router from '../router'
import Store from '../store'

const driver = new Driver({
  nextBtnText: '下一步',
  prevBtnText: '',
  closeBtnText: '关闭',
  doneBtnText: '完成',
  allowClose: false
})

const newDriver = arr => {
  if (arr && arr.length) {
    const ele = []
    arr.forEach(arritem => {
      const item = {}
      item.element = arritem.id
      item.popover = {}
      item.popover.title = '`'
      item.popover.description = arritem.cont
      item.popover.position = arritem.position
      if (arritem.nextBtnText) {
        item.popover.nextBtnText = '关闭'
      }
      if (arritem.onReset) {
        item.onReset = () => {
          Store.dispatch('setShowGuide', 'false')
        }
      }
      if (arritem.next) {
        item.onNext = () => {
          if (!Store.getters.sub) {
            Router.push({ path: arritem.path })
          } else {
            Store.dispatch('setShowGuide', 'false')
          }
        }
      }
      ele.push(item)
      return
    })
    driver.defineSteps(ele)

    driver.start()
  } else {
    return
  }
}

export default newDriver
