<template>
  <div class="qy-risk-page">
    <div class="search">
      <div class="band">
        <p>风险等级</p>
        <pe-select
          :optionsList="filterBand"
          select-size="mini"
          style="width:120px"
          @handleSelectChange="handleSelectChange"
        />
      </div>
      <div class="operate">
        <el-input
          v-model="peParams.keywords"
          size="mini"
          style="width:300px;margin-right:20px"
          placeholder="请输入关键字"
        />
        <el-button size="mini" type="primary" @click="handleInquire"
          >查询</el-button
        >
        <el-button size="mini" @click="handleReset">重置</el-button>
        <p>共{{ peParams.total }}条结果</p>
      </div>
    </div>
    <PeTable
      :peColumnList="peColumnList"
      :peTableData="peTableData"
      :peLoading="peLoading"
      :pePage="peParams"
      @sortPeChange="handleSortChange"
      @handleCurrentPePage="handleCurrentChange"
      @handleSizePeChange="handleSizeChange"
      :current-column-width="{
        companyName: 300,
        eventTime: 120,
        eventType: 120,
        eventNature: 120
      }"
    >
      <template v-slot:companyName="{ scope }">
        <div>
          <div>
            <span
              class="c-name"
              @click="handleEnter(scope.data, scope.index)"
              >{{ scope.data.companyName }}</span
            >
            <span v-if="scope.data.newTag == 1" class="new-add">新增</span>
          </div>
          <Tags :tagList="scope.data.tags" />
          <div class="abnormal">
            <PeRisk :riskTag="scope.data.risk" />
            <span v-if="scope.data.company_status == '注销'" class="qy-cancel"
              >注销</span
            >
          </div>
        </div>
      </template>
    </PeTable>
  </div>
</template>

<script>
import { getMonitorList, getQyTagFilter } from '@/api/module'
import PeTable from '@/components/Peculiar/table'
import PeRisk from '@/components/Peculiar/risk'
import PeSelect from '@/components/Peculiar/select'
import { withOutAccess, enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'EnterpriseRisk',

  props: {
    tab: {
      type: Number,
      default: 0
    },
    packageTable: {
      type: [Number, String],
      default: ''
    },

    packageFilterId: {
      type: Number,
      default: 0
    }
  },

  watch: {
    packageTable: {
      handler(val) {
        this.peParams.packageTable = val
        this.getList()
      },
      immediate: true
    }
  },

  components: {
    PeTable,
    PeRisk,
    PeSelect
  },

  data() {
    return {
      filterBand: [],
      listData: [],
      currentIndex: 0,
      peTableData: [],
      peColumnList: [],
      peTags: [],
      peParams: {
        tab: 1,
        keywords: '',
        tags: [],
        sortField: '',
        sortOrder: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      peLoading: false
    }
  },

  mounted() {
    this.getFilter()
  },

  methods: {
    handleNormalData() {
      withOutAccess()
    },

    handleSelectChange({ value }) {
      Object.assign(this.peParams, {
        riskLevel: value
      })
      this.getList()
    },

    async getFilter() {
      const { data } = await getQyTagFilter({
        packageId: this.packageFilterId,
        tab: this.tab
      })
      this.filterBand = data
        .find(it => it.field == 'riskLevel')
        .filterOptionList.map(el => ({
          label: el.name,
          value: el.value
        }))
    },

    async getList(flag = false) {
      this.peLoading = true
      let objParams = { tab: this.tab }
      if (!flag) {
        objParams = {
          tags: this.peParams.tags,
          keywords: this.peParams.keywords
        }
      }
      Object.assign(this.peParams, objParams)
      const { data } = await getMonitorList(this.peParams)
      this.peColumnList = data?.columns
      this.peTableData = data?.datas
      const pageParams = {
        total: data.page.total
      }
      this.peParams = Object.assign(this.peParams, pageParams)
      this.peLoading = false
    },

    handleInquire() {
      this.peParams.pageNo = 1
      this.peParams.pageSize = 10
      this.getList()
    },

    handleReset() {
      for (let key in this.peParams) {
        if (key != 'pageNo' || key != 'pageSize' || key != 'total') {
          this.peParams[key] = ''
        }
        this.peParams.packageTable = this.packageTable
        const pageParams = {
          pageNo: 1,
          pageSize: 10,
          total: 0
        }
        this.peTags = []
        this.peParams.tags = []
        this.peParams.tab = 1
        Object.assign(this.peParams, pageParams)
      }
      this.getList(true)
    },
    handleSortChange(...params) {
      const objParams = {
        sortField: params[2].sortField,
        sortOrder: params[2].sortOrder
      }
      this.peParams = Object.assign(this.peParams, objParams)
      this.getList()
    },
    handleCurrentChange(e) {
      this.peParams.pageNo = e
      this.getList()
    },
    handleSizeChange(e) {
      this.peParams.pageNo = 1
      this.peParams.pageSize = e
      this.getList()
    },

    handleEnter(data, index) {
      enterCompanyDetail(data, this.peTableData, index, 0)
    }
  }
}
</script>
<style lang="scss">
.qy-risk-page {
  background-color: #fff;
  margin: 10px 0 0 10px;
  font-size: 14px;
  .title {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .time {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
    .name {
      margin-left: 20px;
      cursor: pointer;
    }
    .active-time {
      color: #41cabf;
    }
  }

  .search {
    display: flex;
    align-items: center;
    .band {
      display: flex;
      align-items: center;
      font-size: 14px;
      .el-input {
        margin-left: 20px;
      }
      .el-select__tags {
        left: 22px;
      }
    }
    .operate {
      display: flex;
      align-items: center;
      margin-left: 50px;
    }
    p {
      font-size: 14px;
      color: #a8a8a8;
      margin-left: 10px;
    }
  }
  .c-name {
    color: #02a7f0;
    cursor: pointer;
  }
  .new-add {
    color: #f00;
  }
  .abnormal {
    display: flex;
    align-items: center;
  }
  .qy-cancel {
    background: red;
    color: #fff;
    padding: 0 4px;
    transform: scale(0.8);
    display: inline-block;
  }
}
</style>
