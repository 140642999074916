<template>
  <div class="register-main">
    <div class="header" @click="$router.replace('/login')"></div>
    <div class="main">
      <div class="wel-come">欢迎注册领鸟云数据平台</div>
      <div
        :style="{
          width: isPc ? '1100px' : '100%',
          padding: isPc ? '50px 100px' : '50px'
        }"
        class="form-row"
        v-loading="loading"
        element-loading-text="正在注册"
        element-loading-spinner="el-icon-loading"
      >
        <el-form
          ref="form"
          :label-width="isPc ? 'auto' : 100"
          size="medium"
          class="el-form-dt"
          :model="formData"
          :rules="rules"
        >
          <el-form-item label="公司名称" prop="companyName">
            <el-input
              placeholder="请输入公司名称"
              v-model="formData.companyName"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司规模" prop="companySize">
            <el-select
              v-model="formData.companySize"
              placeholder="请选择公司规模"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.value"
                v-for="item in companyGuimo"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名" prop="userName">
            <el-input
              placeholder="请输入用户名"
              v-model="formData.userName"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              placeholder="请输入密码"
              type="password"
              v-model="formData.password"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="margin-top:70px"
            label="确认密码"
            prop="rePassword"
          >
            <el-input
              placeholder="请确认密码"
              type="password"
              v-model="formData.rePassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="mobile">
            <el-input
              placeholder="请输入手机号"
              v-model.number="formData.mobile"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input placeholder="请输入验证码" v-model.number="formData.code">
              <template slot="append">
                <span class="code" @click="sendSms">{{
                  time !== '获取验证码' ? `${time} 秒` : time
                }}</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="agree">
            <el-checkbox class="check-box" v-model="formData.agree"
              >我已阅读并且同意
              <span style="color: #0099CC" @click="goXieYi"
                >《领鸟云平台账号登录》</span
              ></el-checkbox
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn-el" @click="register">
              同意协议并提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer">
        <div>
          <span>已有领鸟云数据平台账号，</span
          ><span class="login" @click="goLogin">点击登录</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMode, register } from '@/api/user'
import { throttle } from 'lodash'
import { getSmsSend } from '@/api/usercenter'
import { pwd, isMobile, isPC } from '@/utils/validate'
import { validatePassword } from '@/utils/helper'
import store from '@/store'
import { ResetAuthUserMeal, ResetSystemConfig } from '@/utils/auth'
import registerTip from '@/views/login/register/registerTip'
import RegisterNotice from '../dialog/notice'
export default {
  name: 'register',
  data() {
    var validateAgree = (rule, value, callback) => {
      if (value === false) {
        callback(new Error('请勾选并且同意'))
      } else {
        callback()
      }
    }

    var validatePass = (rule, value, callback) => {
      const msg = validatePassword(value)
      msg ? callback(new Error(msg)) : callback()
    }

    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formData.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    var validateMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else if (!isMobile(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      formData: {
        agree: true, // 同意协议
        companyName: '', // 公司名称
        companySize: '', // 公司规模
        userName: '', // 用户名
        password: '', // 密码
        rePassword: '', // 确认密码
        mobile: '', // 手机号
        code: '' // 验证码
      },
      companyGuimo: [],
      time: '获取验证码',
      timeObj: null,
      rules: {
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ],
        agree: [{ validator: validateAgree, trigger: 'change' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        rePassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        companySize: [
          { required: true, message: '请选择公司规模', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  beforeDestroy() {
    if (this.timeObj) clearInterval(this.timeObj)
  },
  created() {
    this.sendSms = throttle(
      () => {
        this._sendSms()
      },
      500,
      { trailing: false }
    )
  },
  mounted() {
    this.getCompanySize()
  },
  computed: {
    isPc() {
      return isPC()
    }
  },
  methods: {
    goXieYi() {
      this.$router.push('/role')
    },

    register() {
      // this.$store.dispatch('showModal', {
      //   title: '提示',
      //   view: RegisterNotice,
      //   size: '800px',
      //   hideClose: true
      // })
      this.$refs.form.validate(valid => {
        if (!valid) return
        const registerEnterpriseVo = {
          enterpriseName: this.formData.companyName,
          saleNumber: this.formData.companySize,
          superEnterpriseKcId: this.$route.query.shareId
        }
        const registerUserVo = {
          userName: this.formData.userName,
          passWord: this.formData.password,
          mobile: this.formData.mobile,
          smsCode: this.formData.code,
          from: '',
          secret: ''
        }
        const params = {
          registerEnterpriseVo: registerEnterpriseVo,
          registerUserVo: registerUserVo
        }
        this.loading = true
        register(params)
          .then(res => {
            if (res.code === 60000) {
              if (this.isPc) {
                this.tip(res.msg)
                this.$store.dispatch('showModal', {
                  title: '提示',
                  view: RegisterNotice,
                  size: '800px',
                  hideClose: true
                })
                // this.startLogin(res)
              } else {
                this.$store.dispatch('showModal', {
                  title: '提示',
                  view: registerTip,
                  size: '300px',
                  hideClose: true
                })
              }
            } else if (res.code === 61005) {
              this.loading = false
              this.tip(res.msg, 'error')
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
            this.tip('注册失败', 'error')
          })
      })
    },

    _sendSms() {
      this.$refs.form.validateField('mobile')
      if (!this.formData.mobile) return
      if (!isMobile(this.formData.mobile)) return
      if (this.time !== '获取验证码') return
      if (this.timeObj) clearInterval(this.timeObj)
      getSmsSend({ mobile: this.formData.mobile }).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('showTip', {
            text: '验证码发送成功',
            type: 'success'
          })
          this.time = 60
          this.timeObj = setInterval(() => {
            if (this.time > 1) {
              this.time -= 1
            } else {
              this.time = '获取验证码'
              clearInterval(this.timeObj)
            }
          }, 1000)
        }
      })
    },

    getCompanySize() {
      getMode({ type: 'XS_01' }).then(res => {
        if (res.code == 60000) {
          this.companyGuimo = res.data['XS_01']
        }
      })
    },

    goLogin() {
      this.$router.replace('/login')
    },

    startLogin(res) {
      localStorage.clear()
      sessionStorage.clear()
      const token = res.data ? res.data.token : ''
      const step = 2
      const accountType = res.data ? res.data.type : ''
      store.commit('setToken', token)
      store.dispatch('changeStep', step)
      store.dispatch('setName', this.formData.userName)
      localStorage.setItem('setAccountType', accountType + '')
      localStorage.setItem('dtBird_user_step', step)
      localStorage.setItem('setName', this.formData.userName)
      localStorage.setItem('userLoginTime', new Date().getTime())
      ResetAuthUserMeal()
      ResetSystemConfig() //获取系统信息
      this.loading = false

      // this.$router.replace('/')
    },

    tip(msg, type = 'success') {
      this.$store.dispatch('showTip', {
        text: msg,
        type: type
      })
    }
  }
}
</script>
<style lang="scss">
.register-main {
  .el-form-item__error {
    line-height: 20px;
  }
}
</style>

<style scoped>
.register-main {
  width: 100%;
  min-height: 100%;
  height: auto;
}

.header {
  height: 80px;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  background: #f5f6f7 url(../../../../src/assets/logo_same.png) no-repeat left
    58px center;
  background-size: 148px 36px;
}
.main {
  height: calc(100% - 80px);
  width: 100%;
}
.wel-come {
  height: 134px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #494949;
  font-weight: 600;
}
.form-row {
  min-height: calc(100% - 134px - 134px);
  height: auto;
  margin: 0 auto;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.el-form-dt {
  width: 450px;
}
.el-form-dt /deep/ .el-input--medium .el-input__inner {
  height: 50px;
  line-height: 50px;
}
.el-form-dt /deep/ .el-form-item__label-wrap .el-form-item__label {
  line-height: 50px;
}
.footer {
  height: 134px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8a8a8a;
  font-weight: 550;
  font-size: 14px;
}
.login {
  cursor: pointer;
  color: #41cabf;
}
.check-box /deep/ .el-checkbox__label {
  font-size: 14px;
  color: #8a8a8a !important;
  font-weight: 550;
}
.btn-el {
  width: 100%;
  height: 45px;
}
.code {
  cursor: pointer;
  user-select: none;
  display: inline-block;
  min-width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
</style>
