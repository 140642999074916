<template>
  <div class="article-page">
    <div class="wrap">
      <div class="fl">
        <div class="top">
          <PeSelect
            selectSize="mini"
            style="width:150px"
            :optionsList="optionsList"
            :defaultSelect="gptLabel"
            @handleSelectChange="handleSelectChange"
          />
          <el-button size="mini" type="primary" @click="handleOpen"
            >新建对话</el-button
          >
        </div>
        <div class="h-list">
          <div
            :class="currentIndex == index ? 'active h-item' : 'h-item'"
            v-for="(item, index) in historyList"
            :key="item.id"
            @click="handleClick(item, index)"
          >
            <div class="first">
              <img class="icon" :src="item.gptTypeIcon" />
              <div class="title f-item">{{ item.title }}</div>
            </div>
            <div class="result">{{ item.result }}</div>
            <div class="btn-delete">
              <el-popconfirm
                title="删除后无法回复,是否删除"
                @confirm="handleDelete(item)"
              >
                <el-button
                  slot="reference"
                  size="mini"
                  @click.stop="handleDelStop"
                  >删除</el-button
                >
              </el-popconfirm>
            </div>
          </div>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pePage.total"
          style="width:30%;margin-top:10px"
          :page-size="pePage.pageSize"
          @current-change="handlePageIndex"
        >
        </el-pagination>
      </div>
      <div class="fr">
        <template v-if="info.id">
          <div class="panel" v-if="info.title">
            <div class="title">{{ info.title }}</div>
          </div>
          <div class="panel">
            <div class="t-list" ref="scroll">
              <div class="t-item" v-for="(item, index) in aiList" :key="index">
                <div class="it">
                  <div class="first">
                    <img class="icon" :src="item.avatarUrl" />
                    <span>{{ item.nickName }}</span>
                    <span v-if="item.role == 'user'">{{ item.content }}</span>
                  </div>
                  <template v-if="item.role == 'assistant'">
                    <div class="result">{{ item.content }}</div>
                    <div class="copy" @click="handleCopy(item.content)">
                      <img src="../../assets/pe-img/copy.png" />
                      <span>复制</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div v-else class="robot">
          <div class="r-top">
            <img :src="askIcon" />
            <div class="hello">你好,我是AI助手</div>
          </div>
          <div class="desc">
            <div class="zs"></div>
            <div>
              作为你的智能伙伴，我既能写文案、想点子，又能陪你聊天、答疑解惑
            </div>
          </div>
        </div>

        <div class="typewriter" v-html="renderResult"></div>

        <div class="panel r-panel">
          <div class="title">请输入指令</div>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="可通过回车快捷提交，使用shift+回车换行"
            v-model="textarea"
            style="margin-top:10px;margin-bottom:10px"
            @keydown.enter.native="handleEnter"
          >
          </el-input>
          <div class="footer">
            <el-button
              type="primary"
              size="small"
              @click="handleCreate"
              :loading="loading"
              >生成</el-button
            >

            <!-- <el-button
              type="primary"
              size="small"
              style="width:200px"
              @click="handleSave"
              >保存</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAiMyList,
  getMassAiList,
  getAiCreated,
  getAiSaved,
  getAiDelete,
  getAiPrintResult
} from '@/api/module'
import PeSelect from '@/components/Peculiar/select'
import { fetchEventSource } from '@microsoft/fetch-event-source'
export default {
  name: 'textOptimize',
  data() {
    return {
      printLoading: false,
      renderResult: '',
      optionsList: [
        {
          label: '文心一言',
          value: 0
        },
        {
          label: 'ChatGPT',
          value: 1
        }
      ],
      currentIndex: -1,
      askIcon: '',
      wxIcon: 'https://web-app.dtbird.cn/images/ai_words/yiyan.jpg',
      gptIcon: 'https://web-app.dtbird.cn/images/ai_words/chatgpt.jpg',
      textarea: '',
      historyList: [],
      articleList: [
        {
          id: 0,
          name: '如何一夜暴富,为您独家揭秘'
        },
        {
          id: 1,
          name: '30岁了你是否已经存款100万?'
        },
        {
          id: 2,
          name: '当代年轻人为啥都不愿意生孩子了?'
        },
        {
          id: 3,
          name: '在大城市工作,你感觉到快乐吗?'
        }
      ],
      loading: false,
      info: {},
      pePage: {
        filterImage: true,
        pageNo: 1,
        pageSize: 20,
        tab: 1,
        total: 0
      },
      gptValue: sessionStorage.gptValue || 0,
      gptLabel: sessionStorage.gptLabel || '文心一言',
      aiList: []
    }
  },

  components: {
    PeSelect
  },

  mounted() {
    this.askIcon = this.gptValue == 0 ? this.wxIcon : this.gptIcon
    this.handleArticleList()
  },

  methods: {
    handleOpen() {
      this.info = {}
      this.textarea = ''
      this.currentIndex = -1
    },

    handleSelectChange(e) {
      this.gptValue = e.value
      this.gptLabel = e.label
      sessionStorage.setItem('gptLabel', e.label)
      sessionStorage.setItem('gptValue', e.value)
      this.askIcon = e.value == 0 ? this.wxIcon : this.gptIcon
    },

    selectText(oInput) {
      if (oInput.createTextRange) {
        oInput.select()
      } else {
        oInput.setSelectionRange(0, oInput.value.length)
        oInput.focus()
      }
    },

    handleCopy(text) {
      const oInput = document.createElement('input')
      oInput.value = text
      document.body.appendChild(oInput)
      this.selectText(oInput)
      document.execCommand('Copy')
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this.$store.dispatch('showTip', {
        text: '复制成功',
        type: 'success',
        duration: 800
      })
    },

    async handleArticleList() {
      const { data } = await getAiMyList(this.pePage)
      this.historyList = data?.datas
      this.pePage.total = data?.page?.total
    },

    handleDelStop() {
      console.log('clear')
    },

    async handleDelete(e) {
      const { data } = await getAiDelete({
        articleId: e.id
      })
      console.log(data)
      this.handleArticleList()
      this.currentIndex = -1
    },

    async handleMassAiList() {
      const { data } = await getMassAiList({
        articleId: this.info.id
      })
      this.aiList = data
      // 等待DOM更新完成后再进行滚动操作
      this.$nextTick(() => {
        this.$refs.scroll.scrollTo({
          top: this.$refs.scroll.scrollHeight,
          behavior: 'auto'
        })
      })
      // const content = this.$refs.scroll
      // content.scrollIntoView(false)
    },

    async handleClick(e, index) {
      this.currentIndex = index
      this.info = e
      this.gptValue = e.gptType
      this.pePage.pageNo = 1
      this.handleMassAiList()
    },

    handlePageIndex(e) {
      this.pePage.pageNo = e
      this.handleArticleList()
    },

    handleEnter(event) {
      if (!event.shiftKey) {
        // 如果没有按下组合键shift，则会阻止默认事件
        event.preventDefault()
        this.handleCreate()
      }
    },
    async handleCreate() {
      // const that = this
      // const token = this.$store.state.permission.token
      // const ctrl = new AbortController()
      // that.printLoading = true
      // that.renderResult = ``
      // fetchEventSource('https://api2.dtbird.cn/api/ai/wenan/azure/talk', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${token}`
      //   },
      //   body: JSON.stringify({
      //     keywords: this.textarea
      //   }),
      //   signal: ctrl.signal,
      //   openWhenHidden: true,
      //   onmessage(event) {
      //     that.printLoading = false
      //     console.log(event, '开始发送')
      //     const data = event.data
      //     const chars = data.split('')
      //     chars.forEach((char, index) => {
      //       console.log(char, 'char')
      //       setTimeout(() => {
      //         that.renderResult += char
      //       }, index * 1000) // 调整这里的延时以实现不同的打字速度
      //     })
      //   },
      //   onclose(e) {
      //     console.log(e, '关闭')
      //   },
      //   onerror(err) {
      //     console.log(err, '出错了')
      //   }
      // })
      // this.textarea = ''

      if (!this.textarea) {
        this.$message.error('请输入关键字')
        return
      }
      try {
        this.loading = true
        const { data } = await getAiCreated({
          keywords: this.textarea,
          articleId: this.info.id,
          gptType: this.gptValue
        })
        this.loading = false
        this.info.id = data?.id
        this.textarea = ''
        this.handleArticleList()
        this.handleMassAiList()
      } catch (err) {
        console.log(err)
      }
    },

    async handleSave() {
      const data = await getAiSaved({
        articleId: this.info.id
      })
      if (data.code == 60000) {
        this.$message.success('保存成功')
      }
    }
  }
}
</script>
<style lang="scss">
.article-page {
  .title {
    font-weight: bold;
  }

  .wrap {
    display: flex;
    justify-content: space-between;

    .fl {
      background: #fff;
      width: 300px;
      padding: 10px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
      }
      .h-list {
        max-height: 80vh;
        overflow-y: auto;
        .active {
          box-sizing: border-box;
          border: 2px solid #41cabf !important;
          border-radius: 4px;
        }
        .h-item {
          width: 95%;
          box-sizing: border-box;
          border: 2px solid transparent;
          box-shadow: 1px 1px 5px #dbdbdb;
          padding: 5px 10px;
          cursor: pointer;
          .first {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .icon {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            .f-item {
              margin-left: 10px;
            }
          }

          &:not(:first-child) {
            margin: 20px 0;
          }

          .btn-delete {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          .result {
            color: #888;
            font-size: 14px;
            line-height: 21px;
            margin: 4px 0;
            white-space: pre-wrap;
          }
        }
      }
    }
    .fr {
      background: #fff;
      width: 80%;
      padding: 10px;
      margin: 0 auto;
      .panel {
        background: #fff;
        padding: 10px;
        border-radius: 4px;
        position: relative;

        .footer {
          position: absolute;
          right: 16px;
          bottom: 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .list {
        .item {
          margin-top: 10px;
        }
      }
    }
  }

  .t-list {
    max-height: 60vh;
    overflow-y: scroll;
    .t-item {
      color: #888;
      &:not(:first-child) {
        margin: 5px 0;
      }

      .it {
        .first {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          span {
            margin-left: 10px;
          }
        }
        .result {
          white-space: pre-wrap;
          background: rgba(0, 0, 0, 0.8);
          padding: 10px;
          border-radius: 4px;
          color: #fff;
        }
        .copy {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 98%;
          cursor: pointer;
          margin-top: 10px;
          img {
            width: 20px;
            height: 20px;
          }
          span {
            margin-left: 5px;
            font-size: 16px;
            color: #666;
            font-weight: normal;
          }
        }
      }
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .typewriter {
    overflow: hidden;
    border-right: none;
    font-family: monospace;
    white-space: pre-wrap; /* 保留空格和换行 */
  }

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: black;
    }
  }
  .r-panel {
    margin-top: 20px;
  }

  .robot {
    padding: 10px;
    .r-top {
      display: flex;
      align-items: center;
      .hello {
        font-size: 24px;
        font-weight: bold;
        margin-left: 10px;
      }
    }
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .desc {
      .zs {
        margin: 10px 0;
      }
    }
  }
}
</style>
