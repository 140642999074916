<template>
  <div class="editSupply-main">
    <div class="main-row">
        <el-form :model="formData" :rules="rules" label-width="auto" ref="form">
          <el-form-item label="编号" prop="sysNum">
            <el-input v-model="formData.sysNum" placeholder="供应商编号（留空系统自动生成）" :disabled="type === 'edit'"></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input v-model="formData.name" placeholder="请输入供应商名称"></el-input>
          </el-form-item>
          <el-form-item label="通讯地址" prop="address">
            <el-input v-model="formData.address" placeholder="请输入供应商通讯地址"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contact">
            <el-input v-model="formData.contact" placeholder="请输入供应商联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系人电话" prop="phone">
            <el-input v-model="formData.phone" placeholder="请输入供应商联系人电话"></el-input>
          </el-form-item>

          <el-form-item label="采购主体" prop="content">
            <el-input v-model="formData.content" placeholder="请输入我方采购主体"></el-input>
          </el-form-item>
          <el-form-item label="采购负责人" prop="userId">
            <el-select v-model="formData.userId" placeholder="请选择我方采购负责人" style="width: 100%" filterable>
              <el-option
                v-for="item in pdaList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              placeholder="请输入备注"
              v-model="formData.remark"
              maxlength="100"
              show-word-limit
              :autosize="{ minRows: 4, maxRows: 10 }">
            </el-input>
          </el-form-item>

        </el-form>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancelBtn">取消</el-button>
      <el-button size="small" type="primary" @click="confirmBtn" v-loading="loading">确认</el-button>
    </div>
  </div>
</template>

<script>
import { supplierNum, supplierEdit, supplierAdd, getAllSales } from '@/api/thread'
import moment from 'moment'
export default {
  name: 'editSupply',
  data () {
    var validateMobile = ( rule, value, callback ) => {
      if(value === ''){
        callback();
      } else if (isNaN(Number(value))){
        callback(new Error('请输入正确的联系人电话'));
      } else {
        callback();
      }
    }
    return {
      formData: {
        id: '',
        sysNum: `S${ moment().unix() }${ Math.floor(Math.random () * 900) + 100 }`,
        name: '',
        address: '',
        contact: '',
        phone: '',
        remark:'',
        content: '',
        userId: '',
      },
      pdaList: [],
      rules: {
        sysNum: [
          { required: true, message: '请输入编号（提交时可自动生成）', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '请选择我方采购负责人', trigger: 'blur' }
        ],
        phone: [
          { validator: validateMobile, trigger: 'blur' }
        ]
      },
      type: 'create',
      loading: false
    }
  },
  mounted () {
    this.getAllSales()
    const options = this.$store.state.plugin.modal.options
    this.type = options.type
    if(this.type === 'edit'){
      Object.assign(this.formData, options.params)
    }
    console.log(this.formData)
  },
  computed: {},
  methods: {

    getAllSales(){
      getAllSales().then(res => {
        if(res.code === 60000){
          this.pdaList = res.data
        }
      })
    },


    cancelBtn(){
      this.$store.dispatch('hideModal')
    },
    async confirmBtn(){
      if(this.loading) return
      if(!this.formData.sysNum){
        this.loading = true
        const res =  await supplierNum()
        this.loading = false
        if (res.code=== 60000){
          this.formData.sysNum = res.data
          this.$refs['form'].clearValidate('sysNum')
        }
      }
      this.$refs['form'].validate(valid => {
        if(!valid) return
        this.loading = true
        if(this.type === 'edit'){
          supplierEdit(this.formData).then(res => {
            this.loading = false
            if(res.code === 60000){
              this.$store.commit('update', {
                target: this.$store.state.plugin.modal.options,
                data: { refresh: true }
              })
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: 'success'
              })
              this.cancelBtn()
            }
          })
        }else {
          supplierAdd(this.formData).then(res => {
            this.loading = false
            if(res.code === 60000){
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: 'success'
              })
              this.$store.commit('update', {
                target: this.$store.state.plugin.modal.options,
                data: { refresh: true }
              })
              this.cancelBtn()
            }
          })
        }
      })
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.editSupply-main{
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}
.footer{
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width:  calc( 100% + 20px );
  background-color: rgba(0,0,0,.1);
  position: absolute;
  top: 0;
  left: -10px;
  transform: scaleY(.5);
}
</style>
