var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customerView-main" },
    [
      _c("DtScroll", { attrs: { margin: "0" } }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "title" }, [_vm._v("应用排名上升监控")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter" },
            [
              _c("appFilter", {
                ref: "appFilter",
                on: { selectChange: _vm.selectChange },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "main-row" }, [
          _c(
            "div",
            { ref: "keyList", staticClass: "key-list" },
            [
              _c("appList", {
                attrs: { params: _vm.select },
                on: { reset: _vm.reSet },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }