<template>
  <div class="step-main">
    <div class="title">{{ title }}</div>
    <div class="er-code" id="wx_regs" v-show="step === 1"></div>
    <div class="btn" @click="confirmClick" v-show="step !== 1">确定</div>
  </div>
</template>

<script>
import { bingWx, wxLogin } from '@/api/user'
import { getAccountInfo } from '@/api/usercenter'
export default {
  name: 'step',
  data() {
    return {
      step: 1
    }
  },
  watch: {
    step: {
      handler: function(val) {
        if (val !== 1) return
        this.$nextTick(() => {
          this.login()
        })
      },
      immediate: true
    }
  },
  computed: {
    modalOption() {
      return this.$store.state.plugin.modal?.options?.data
    },
    title() {
      if (this.step === 1) {
        return this.modalOption.title
      }
      return `恭喜，已添加应用 ${
        this.type === 'yun' ? '【查云服务商（专业版）】' : '查域名（专业版）'
      }`
    },
    type() {
      return this.modalOption?.type
    }
  },
  methods: {
    confirmClick() {
      if (this.type === 'khdc') {
        this.$router.push('/app/pack')
        return
      }
      this.type === 'yun'
        ? this.$router.push('/globalquery/query')
        : this.$router.push('/app/domain')
      this.$store.dispatch('hideModal')
    },

    getCode(res) {
      const code = (res?.data?.url || '')?.split('code=')?.[1]?.split('&')?.[0]
      if (code) this.bindAccount(code)
    },

    bindAccount(code) {
      this.login()
      wxLogin({ code }).then(r => {
        console.log(r, 111)
        if (r.code != 60000) {
          this.$message.error(r?.msg)
          return
        }
        const openid = r?.data?.openid
        bingWx({ openid, token: this.token }).then(async res => {
          if (res.code === 60000) {
            this.step = 3
            getAccountInfo(params).then(_res => {
              console.log('dialog123')
              this.$store.dispatch('setAccountInfo', _res.data)
            })
          } else {
            this.$message.error(res?.msg)
          }
        })
      })
    },

    login() {
      window.removeEventListener('message', this.getCode)
      const isDev = process.env.NODE_ENV === 'development'
      const uri = isDev ? 'https://test.dtbird.cn' : 'https://ai.dtbird.cn'
      var obj = new WxLogin({
        self_redirect: true,
        id: 'wx_regs',
        appid: 'wx0e7b6e5c3e1c1321',
        scope: 'snsapi_login',
        redirect_uri: uri,
        state: '',
        style: '',
        href: 'https://yx.file.dtbird.cn/weixincss.css'
      })
      window.addEventListener('message', this.getCode)
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.getCode)
  }
}
</script>

<style scoped lang="scss">
.step-main {
  min-height: 200px;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  box-sizing: border-box;
  .title {
    color: #333333;
    font-size: 15px;
    font-weight: bold;
    margin: 20px 0;
  }
  .er-code {
    height: 320px;
    width: 200px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(40px);
  }
  .btn {
    width: 90%;
    height: 50px;
    border-radius: 3px;
    background-color: #41cabf;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .code {
    position: absolute;
    right: 10px;
    color: #41cabf;
    cursor: pointer;
    display: inline-block;
    height: 100%;
  }
}
</style>
