<template>
  <div>
    <div class="touch-cont-mang p-b-38">
      <div class="touch-tab-cont">
        <div :class="!detail.isReceive ? 'module-cont-filter' : ''">
          <div
            class="showPhone"
            v-if="cardType === 'phone' || cardType === 'contact'"
          >
            <div v-if="cardType === 'phone'">
              <el-checkbox
                style="margin-right: 10px;"
                @change="changeShowPhone"
                v-model="showPhone"
                v-if="cardType === 'phone'"
              >
                只显示手机号
              </el-checkbox>
            </div>

            <div style="width: 70%" v-if="cardType === 'contact'">
              <el-checkbox
                style="margin-right: 20px;width: 70px"
                @change="changeShowKP"
                v-model="showKP"
                v-if="cardType === 'contact'"
              >
                KP{{
                  moduleDetail.kpTypeCnt && moduleDetail.kpTypeCnt != 0
                    ? '(' + moduleDetail.kpTypeCnt + ')'
                    : ''
                }}
              </el-checkbox>

              <el-checkbox
                style="margin-right: 20px;width: 70px"
                @change="changeShowSale"
                v-model="showSale"
                v-if="cardType === 'contact'"
              >
                销售岗{{
                  moduleDetail.xiaoShouTypeCnt &&
                  moduleDetail.xiaoShouTypeCnt != 0
                    ? '(' + moduleDetail.xiaoShouTypeCnt + ')'
                    : ''
                }}
              </el-checkbox>
              <el-checkbox
                style="margin-right: 20px;width: 70px"
                @change="changeShowDev"
                v-model="showDev"
                v-if="cardType === 'contact'"
              >
                研发岗{{
                  moduleDetail.yanFaTypeCnt && moduleDetail.yanFaTypeCnt != 0
                    ? '(' + moduleDetail.yanFaTypeCnt + ')'
                    : ''
                }}
              </el-checkbox>
              <el-checkbox
                style="margin-right: 20px;width: 70px"
                @change="changeShowAdmin"
                v-model="showAdmin"
                v-if="cardType === 'contact'"
              >
                管理岗{{
                  moduleDetail.guanLiTypeCnt && moduleDetail.guanLiTypeCnt != 0
                    ? '(' + moduleDetail.guanLiTypeCnt + ')'
                    : ''
                }}
              </el-checkbox>
              <el-checkbox
                style="margin-right: 40px;width: 70px"
                @change="changeShowElse"
                v-model="showElse"
                v-if="cardType === 'contact'"
              >
                其他{{
                  moduleDetail.otherTypeCnt && moduleDetail.otherTypeCnt != 0
                    ? '(' + moduleDetail.otherTypeCnt + ')'
                    : ''
                }}
              </el-checkbox>
            </div>

            <div style="height: 50px;width: 30%" v-if="cardType === 'contact'">
              <el-button
                size="small"
                type="primary"
                style="float: right"
                @click="setKPWord"
                >设置KP关键词
              </el-button>
            </div>
          </div>
          <div>
            <div v-if="cardType === 'contact'">
              <div class="dt-dropdown-dt" v-if="filterTableArr1()">
                <div
                  class="contact-list-item"
                  v-for="(item, index) in filterTableArr1()"
                  :key="index"
                >
                  <div style="width: 40px;height: 100px;padding-top: 30px;">
                    <img
                      :src="attachImageUrl(item.maiMaiAvatar)"
                      width="40"
                      height="40"
                      alt="暂无图片"
                      referrerPolicy="no-referrer"
                      style="border-radius: 50%;"
                    />
                  </div>
                  <div
                    style="width: 500px;height: 100px;padding-top: 10px;margin-left: 20px"
                  >
                    <div class="con-info">
                      <el-badge :value="item.kp ? 'KP' : ''" class="item">
                        {{ item.maiMaiPersonName }} · {{ item.maiMaiPosition }}
                      </el-badge>
                    </div>
                    <div class="con-info" style="color: #999;">
                      {{ item.maimaiTeam }} | {{ item.maiMaiDept }}
                    </div>
                  </div>
                  <div style="width: 290px;height: 100px;position: relative">
                    <div
                      v-dt-permission="'智能获客-推荐线索-脉脉触达'"
                      class="yun-icon"
                      style="color: rgb(158, 209, 101);"
                      @click="jumpMaiMai(item.maiMaiPersonName)"
                    ></div>
                    <div
                      v-dt-permission="'智能获客-推荐线索-脉脉触达'"
                      class="yun-icon1"
                      style="color: rgb(158, 209, 101);"
                      @click="jumpLingYing(item.maiMaiPersonName)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="cardType === 'phone'">
              <div class="dt-dropdown-dt" v-if="filterTableArr1()">
                <div
                  class="touch-list-item"
                  v-for="(item, index) in filterTableArr1()"
                >
                  <div class="i-top">
                    <div class="cus-info">
                      <div style="padding: 0 8px 0 0;font-size: 14px;">
                        {{ item.phone }}
                      </div>
                      <div style="padding: 0 8px">{{ item.contact }}</div>

                      <el-popover
                        popper-class="el-popper-dt-dt"
                        placement="bottom-end"
                        width="500"
                        trigger="click"
                      >
                        <div
                          slot="reference"
                          style="padding: 0 8px;color: #0099cc;cursor: pointer"
                          v-if="item.callCnt"
                        >
                          {{ `已拨打${item.callCnt}次` }}
                        </div>
                        <div class="time-line">
                          <el-timeline
                            v-for="(timeLine, index) in item.callRecords"
                          >
                            <div class="line-top">
                              <span>{{ timeLine.week }}</span
                              ><span>{{ timeLine.day }}</span>
                            </div>
                            <el-timeline-item
                              :color="timeLine.week === '今天' ? '#FF0000' : ''"
                              v-for="(activity, index) in timeLine.follows"
                              :key="index"
                            >
                              <div class="line-content">
                                <span>
                                  <span class="import-text">
                                    {{ activity.account }}</span
                                  >
                                  <span> 发起外呼，</span>
                                </span>

                                <span>
                                  <span>通话状态</span>
                                  <span class="import-text"
                                    >{{ activity.phoneStatus }}
                                  </span>
                                </span>
                                <span v-show="activity.callDuration">
                                  <span>通话时长</span>
                                  <span class="import-text">{{
                                    activity.callDuration
                                  }}</span>
                                  <span
                                    class="play-record"
                                    @click="playRecord(activity.ossUrl)"
                                    v-show="
                                      activity.callDuration && activity.ossUrl
                                    "
                                    v-dt-permission="
                                      '智能获客-推荐线索-播放录音'
                                    "
                                  >
                                    播放录音
                                  </span>
                                </span>
                              </div>
                              <div class="time-line-date">
                                {{ activity.time }}
                              </div>
                            </el-timeline-item>
                          </el-timeline>
                        </div>
                      </el-popover>
                    </div>
                    <div class="cus-info">
                      <i
                        class="card-phone-icon"
                        @click="
                          call(item.phone, item.contact, detail.company_name)
                        "
                      ></i>
                      <el-dropdown
                        placement="bottom-start"
                        trigger="click"
                        @command="changeAsign($event, item)"
                      >
                        <i
                          class="card-phone-edit-icon"
                          style="margin-left: 8px;"
                        ></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            :command="item.value"
                            v-for="item in asignOption"
                            >{{ item.name }}
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <i class="add-waihu" @click="addWaiHu(item)"></i>
                    </div>
                  </div>
                  <div class="i-tag">
                    <div class="cus-tag">
                      <div v-if="item.ai_konghao" class="tag-i">空号</div>
                      <div v-if="item.ai_tingji" class="tag-i">停机</div>
                      <div v-if="item.wuxiao" class="tag-i">无效</div>
                      <div v-if="item.zhongjie" class="tag-i">疑似中介</div>
                      <div v-if="item.phoneStatus" class="tag-i">
                        {{ item.phoneStatus }}
                      </div>
                      <div v-if="item.recommend" class="tag-i">推荐</div>
                      <div v-if="item.ai_can_call" class="tag-i">
                        电话可打通
                      </div>
                    </div>
                    <div class="cus-info">
                      <div
                        :class="
                          i.url == ' ' || i.url == ''
                            ? 'cus-href cus-href-i'
                            : 'cus-href'
                        "
                        v-for="i in item.fromToUrl"
                        @click="openUrl(i.url)"
                      >
                        <span style="position: relative;top:-3px">{{
                          i.from
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="i-sign">
                    <div class="sign-con">
                      <span v-if="item.phoneMarkList">
                        {{ item['phoneMarkList'][0].follows[0].markDetails }}
                      </span>
                    </div>

                    <el-popover placement="left-end" width="600">
                      <div
                        v-for="(i, index) in item.phoneMarkList"
                        :key="index"
                        class="pond-cond-all"
                      >
                        <p style="margin-bottom:20px;">
                          <span style="display:inline-block;width:70px;">{{
                            i.week
                          }}</span>
                          <span style="font-size:12px;">{{ i.day }}</span>
                        </p>
                        <div
                          style="overflow: hidden;"
                          v-for="(follow, ind) in i.follows"
                          :key="ind + 'c'"
                        >
                          <div
                            class="pond-cond-left"
                            :class="ind !== 0 ? 'pond-no-first' : ''"
                          >
                            <p>{{ follow.time }}</p>
                          </div>
                          <div
                            class="pond-cond-right"
                            :class="ind !== 0 ? 'pond-no-first' : ''"
                          >
                            <p>
                              <span
                                style="color:#999999;"
                                :class="
                                  i.week === '今天'
                                    ? 'pond-cond-posi'
                                    : 'pond-cond-had'
                                "
                                v-html="follow.markSummary"
                              ></span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card-edit-asign" slot="reference">
                        共{{ item.cnt }}条标记
                      </div>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="cardType === 'email'">
              <div class="dt-dropdown-dt" v-if="filterTableArr1()">
                <div
                  class="touch-email-item"
                  v-for="(item, index) in filterTableArr1()"
                >
                  <div class="i-top">
                    <div
                      class="cus-info"
                      style="position: relative;width: 100%;line-height: 20px"
                    >
                      <div style="padding: 0 8px;width: 40%;">
                        {{ item.email }}
                      </div>
                      <div style="padding: 0 8px;width: 30%;">
                        {{ item.contact }}
                      </div>
                      <div
                        style="padding: 0 8px;width:20%;position: absolute;right: 8px;text-align: right"
                      >
                        {{ item.from }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="cardType === 'address'">
              <div class="dt-dropdown-dt" v-if="filterTableArr1()">
                <div
                  class="touch-email-item"
                  v-for="(item, index) in filterTableArr1()"
                >
                  <div class="i-top">
                    <div
                      class="cus-info"
                      style="position:relative;height: auto;line-height: 20px;width: 100%;"
                    >
                      <div style="padding: 0 8px;width: 70%">
                        {{ item.address }}
                      </div>
                      <div
                        style="padding: 0 8px;width: 30%;position: absolute;right: 8px;text-align: right"
                      >
                        {{ item.from }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="touch-no-receive"
          v-if="!detail.isReceive"
          v-loading="getLoading"
        >
          <div class="touch-no-cont">
            <div class="touch-no-icon">
              <span
                style="color: #008CD9;text-decoration: underline;cursor:pointer;"
                @click="getReveiveKey('phone')"
                >领取</span
              >
              <span>以查看所有信息</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from './mixin'
import PhoneStrip from '@/components/PhoneStrip'
import { getDetailAsignOpthon, postDetailPhoneAsign } from '@/api/thread'
import setkpword from '@/components/ModulePack/setkpword'
import { linkedinAdd, maimaiAdd } from '@/api/customerpond'
import editCompanyOrUser from '@/components/ModulePack/dialog/editCompanyOrUser'
import editUser from '@/components/ModulePack/dialog/editUser'
import MobileAudio from '@/views/callsystem/mobileaudio'

export default {
  name: 'NewTouchModule',
  mixins: [Mixin],
  props: {
    cardType: {
      type: String,
      default: ''
    },
    moduleParam: {
      default: () => {},
      type: Object
    },
    detail: {
      default: () => {},
      type: Object
    },
    packageId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      supplierObj: {},
      tableArr: [],
      headerArr: [],
      chooseComId: [],
      showPhone: false,
      asignOption: [],
      showSale: false,
      showDev: false,
      showAdmin: false,
      showElse: false,
      showKP: false
    }
  },
  components: {
    PhoneStrip
  },
  watch: {
    '$store.state.settings.touchModuleList': {
      handler() {
        this.tableArr = this.$store.state.settings.touchModuleList
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.$bus.$on('getComDetailModel', () => {
      this.getComDetailModel()
    })
    this.$emit('ModuleDom')
    this.showPhone = this.$store.state.plugin.onlyShowPhone
    this.showSale = this.$store.state.plugin.onlyShowSale
    this.showDev = this.$store.state.plugin.onlyShowDev
    this.showAdmin = this.$store.state.plugin.onlyShowAdmin
    this.showElse = this.$store.state.plugin.onlyShowElse
    this.showKP = this.$store.state.plugin.onlyShowKP

    if (this.cardType == 'phone') {
      this.getDetailAsignOpthon()
    }
  },
  beforeDestroy() {
    this.$bus.$off('getComDetailModel')
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    }
  },
  methods: {
    attachImageUrl(srcUrl) {
      if (srcUrl !== undefined) {
        return srcUrl.replace(
          /http\w{0,1}:\/\/p/g,
          'https://i9.taou.com/maimai/p?url=p'
        )
      }
    },
    playRecord(ossUrl) {
      this.$store.dispatch('showModal', {
        title: '播放录音',
        view: MobileAudio,
        size: '600px',
        options: {
          row: {
            ossUrl: ossUrl,
            type: 'record'
          }
        }
      })
    },

    addWaiHu(data) {
      this.$bus.$emit('addWaiHu', { type: 'one', data: data })
    },

    openUrl(url) {
      if (url && url !== ' ') {
        window.open(url)
      }
    },
    jumpMaiMai(name) {
      const params = {
        companyUuid: this.$route.query.companyUuid
      }
      maimaiAdd(params).then(res => {
        if (res.code === 60000) {
          window.open(
            'https://maimai.cn/web/search_center?type=contact&query=' +
              this.moduleParam.companyName +
              ' ' +
              name +
              '&highlight=true'
          )
        }
      })
    },
    jumpLingYing(name) {
      const params = {
        companyUuid: this.moduleParam.companyUuid
      }
      linkedinAdd(params).then(res => {
        if (res.code === 60000) {
          window.open(
            'https://www.linkedin.com/company/' +
              this.moduleParam.companyName +
              ' ' +
              name +
              '/people/'
          )
        }
      })
    },
    jumpToSource(source, url) {
      if (source === 'phone' && url) {
        window.open(url)
      }
    },

    filterTableArr1() {
      console.log(this.cardType, 'this.cardType')
      // debugger
      if (this.cardType == 'contact') {
        let that = this
        const concatList = this.tableArr?.filter(i => {
          if (
            (i?.type?.indexOf('销售岗') > -1 && that.showSale) ||
            (i?.type?.indexOf('研发岗') > -1 && that.showDev) ||
            (i?.type?.indexOf('管理岗') > -1 && that.showAdmin) ||
            (i?.type?.indexOf('其他') > -1 && that.showElse) ||
            (i.kp == true && that.showKP) ||
            (!that.showSale &&
              !that.showDev &&
              !that.showAdmin &&
              !that.showElse &&
              !that.showKP)
          ) {
            return true
          } else {
            return false
          }
        })
        console.log(concatList, 'concatList')
        return concatList
      } else if (this.cardType == 'phone') {
        return this.tableArr?.filter(i => {
          if (i.hasOwnProperty('phone') && this.showPhone) {
            let reg = /^[1][3,4,5.6,7,8,9][0-9]{9}$/
            if (reg.test(i.phone)) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        })
      } else {
        return this.tableArr
      }
    },
    filterTableArr(item1) {
      if (this.cardType == 'contact') {
        return true
      } else {
        return this.tableArr
      }
    },
    setKPWord() {
      this.$store.dispatch('showModal', {
        title: '设置KP关键词',
        size: '800px',
        view: setkpword,
        // options: {
        //   remark: val,
        //   isUpdate: true,
        //   companyUuid: this.$route.query.companyUuid,
        // },
        onClose: res => {
          if (res.refresh) {
            this.$emit('getComDetailModel')
          }
        }
      })
    },
    call(phone, contact, companyName) {
      this.$bus.$emit('callUpNew', phone, contact, companyName, this.packageId)
    },
    getDetailAsignOpthon() {
      getDetailAsignOpthon().then(res => {
        this.asignOption = res.data.map(item => {
          if (item.name === '意向') item.name = '意向 (自动转入客户公海)'
          if (item.name === '有效') item.name = '有效（电话可打通）'
          return item
        })
      })
    },
    changeAsign(mark, i) {
      console.log(this.detail)
      console.log(this.packageId)
      const params = {
        mark,
        phone: i.phone,
        companyName: this.detail.company_name,
        contact: i.contact,
        packageId: this.packageId,
        klc: this.permissionList.includes('客户培育-联系人培育-新建联系人'), // '客户培育-联系人培育-新建联系人'
        kkx: this.permissionList.includes('客户管理CRM-客户公海-新建客户'), // '客户管理CRM-客户公海-新建客户'
        kkl: this.permissionList.includes('客户管理CRM-客户公海-联系人'), // '客户管理CRM-客户公海-联系人'
        pageId: 2001
      }
      postDetailPhoneAsign(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success',
            duration: 500
          })
          if (mark === 7) {
            this.$store.state.user.customerInfoMap.inPool = true
            this.$store.state.user.customerInfoMap.desc =
              '客户当前位置222：客户公海-' + this.$store.state.user.name
            const hasShowModalPermission = this.permissionList.includes(
              '智能获客-推荐线索-转客户强弹窗'
            )
            if (!hasShowModalPermission) {
              this.$emit('getComDetailModel')
              return
            }
            if (res.data.customer && res.data.contact) {
              this.$store.dispatch('showModal', {
                title: '编辑',
                size: '900px',
                options: {
                  formData: res.data.customer,
                  userFormData: res.data.contact,
                  activeTab: res.data.customer ? res.data.customer.type : ''
                },
                view: editCompanyOrUser
              })
            }

            if (res.data.contact && !res.data.customer) {
              this.$store.dispatch('showModal', {
                title: '编辑',
                size: '600px',
                options: {
                  userFormData: res.data.contact
                },
                view: editUser
              })
            }
          }

          this.$emit('getComDetailModel')
        }
      })
    },
    getComDetailModel() {
      // const params = {
      //   module: 'touch',
      //   packageId: this.moduleParam.packageId,
      //   companyUuid: this.moduleParam.companyUuid
      // }
      // getComDetailModel(params).then(res => {
      // })
      this.$emit('getComDetailModel')
    },

    changeShowPhone(e) {
      this.showPhone = e
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowPhone: e
        }
      })
    },
    changeShowKP(e) {
      this.showKP = e
      this.$refs.carousel.setActiveItem(0)
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowKP: e
        }
      })
    },
    changeShowSale(e) {
      this.showSale = e
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowSale: e
        }
      })
    },
    changeShowDev(e) {
      this.showDev = e
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowDev: e
        }
      })
    },
    changeShowAdmin(e) {
      this.showAdmin = e
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowAdmin: e
        }
      })
    },
    changeShowElse(e) {
      this.showElse = e
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowElse: e
        }
      })
    },
    changeVisiable(i) {
      if (i.hasOwnProperty('phone') && this.showPhone) {
        let reg = /^[1][3,4,5.6,7,8,9][0-9]{9}$/
        if (reg.test(i.phone)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    changeVisiable1(i) {
      if (
        (i.type.indexOf('销售岗') > -1 && this.showSale) ||
        (i.type.indexOf('研发岗') > -1 && this.showDev) ||
        (i.type.indexOf('管理岗') > -1 && this.showAdmin) ||
        (i.type.indexOf('其他') > -1 && this.showElse) ||
        (i.kp == true && this.showKP) ||
        (!this.showSale &&
          !this.showDev &&
          !this.showAdmin &&
          !this.showElse &&
          !this.showKP)
      ) {
        return true
      } else {
        return false
      }
    },
    haveAttr(val, lag) {
      if (val) {
        const arr = val.split(',')
        if (arr?.indexOf(lag) > -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>
<style scoped>
.module-cont-filter {
  filter: blur(1.5px);
  padding-top: 20px;
}

.dt-dropdown-dt {
  overflow: auto;
  width: 100%;
  height: 350px;
}

.card-card-cont {
  display: inline-block;
}

.card-name-cont {
  display: inline-block;
  /*width: 40px;*/
  width: auto;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custo-phone-show {
  display: inline-block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.phone-card-list ::v-deep .el-checkbox__label {
  display: none;
}

.phone-card-list ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #41cabf;
  border-color: #41cabf;
}

.phone-card-list ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #41cabf;
}

.phone-card-list ::v-deep .el-checkbox__inner:hover {
  border-color: #78d9d1;
}

.card-tag-name {
  display: inline-block;
  height: 17px;
  background: #ebfaf9;
  padding: 0 10px;
  color: #41cabf;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-left: 12px;
  vertical-align: top;
}

.card-f14-txt {
  font-size: 14px;
  color: #41cabf;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  margin-left: 6px;
}

.card-phone-name {
  font-size: 12px;
  color: #2e2e2e;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  vertical-align: top;
}

.card-edit-time {
  font-size: 12px;
  color: #aaa;
  line-height: 20px;
}

.card-edit-asign {
  width: 60px;
  font-size: 12px;
  color: #0099cc;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  vertical-align: top;
  text-decoration: underline;
}

.card-cont-txt {
  height: 35px;
  width: 100%;
}

.card-cont-txt ::v-deep .el-checkbox {
  margin-right: 6px;
}

.touch-phone-card {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

.phone-card-list {
  display: inline-block;
  /*margin: 5px 15px;*/
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11);
  padding-left: 10px;
  height: 160px;
  padding-top: 10px;
  width: 20%;
  margin: 5px;
  min-width: 300px;
  padding-right: 6px;
}

.phone-card-list-phone {
  position: relative;
  top: 0;
  left: 0;
  /*height: 140px;*/
}

.is-tuijian {
  background-image: url('../../assets/tj.svg');
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: bottom 0 right 0;
}

.touch-card-title {
  font-weight: 400;
}

.touch-tab-cont {
  width: 100%;
  height: 400px;
  position: relative;
}

.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  /*opacity:0.8;*/
  z-index: 100;
}

.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}

.touch-no-cont {
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.touch-cont-mang {
  width: 100%;
  /*min-width: 1200px;*/
  height: 400px;
  background-color: #ffffff;
  margin-top: 4px;
}

.touch-cont-mang .title {
  width: 100%;
  height: 59px;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #2e2e2e;
  line-height: 59px;
  padding-left: 21px;
  padding-right: 36px;
}

.touch-cont-mang ::v-deep .el-tabs__item {
  border: none;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  width: 180px;
  text-align: center;
  color: #8a8a8a;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}

.touch-cont-mang ::v-deep .el-tabs__item.is-active {
  color: #41cabf;
  background: white;
  border-bottom: 1px solid white;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-top: 1px solid #41cabf;
  width: 180px;
}

.touch-cont-mang ::v-deep .el-tabs__item:first-child {
  border-left: none;
}

.touch-cont-mang ::v-deep .el-tabs__item:last-child::after {
  content: '';
  width: 2000px;
  height: 1px;
  background: #e6e6e6;
  top: -1px;
  left: 180px;
  position: absolute;
  display: inline-block;
}

.touch-cont-mang ::v-deep .el-tabs__item:last-child::before {
  content: '';
  width: 2000px;
  height: 1px;
  background: #e6e6e6;
  bottom: -1px;
  left: 180px;
  position: absolute;
  display: inline-block;
}

.touch-cont-mang ::v-deep .el-tabs__item:hover {
  color: #41cabf;
}

.touch-cont-mang ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-radius: 0;
  border: none;
}

.touch-cont-mang ::v-deep .el-tabs__nav-scroll {
  background: #fcfcfc;

  height: 50px;
}

.card-phone-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url('../../assets/touch-phone-icon.png') no-repeat;
  background-size: 100% 100%;
  margin-top: 3px;
  cursor: pointer;
}

.add-waihu {
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url('../../assets/add-waihu.svg') no-repeat;
  background-size: 100% 100%;
  margin-top: 3px;
  margin-left: 8px;
  cursor: pointer;
}

.card-phone-edit-icon {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 3px;
  background: url('../../assets/keydetail_edit.svg') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.card-email-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url('../../assets/touch-email-icon.png') no-repeat;
  background-size: 100% 100%;
  right: 14px;
  top: 11px;
}

.cont-adr-touch {
  padding-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  height: 90px;
  -webkit-box-orient: vertical;
}

.origin-tip span {
  line-height: 16px;
  height: 22px;
}

.showPhone {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  line-height: 22px;
  border-bottom: #f2f2f2 solid 1px;
}

.pond-cond-all {
  overflow: hidden;
  margin: 10px;
}

.pond-cond-left {
  float: left;
  width: 70px;
}

.pond-cond-left p {
  font-size: 12px;
  vertical-align: top;
}

.pond-cond-right {
  float: left;
  width: calc(100% - 70px);
  border-left: 1px solid #e4e4e4;
  padding-left: 20px;
  padding-right: 10px;
  position: relative;
}

.pond-cond-posi {
  position: relative;
}

.pond-cond-had {
  position: relative;
}

.pond-cond-had::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #e4e4e4;
  top: 2px;
  left: -24px;
  border-radius: 4px;
}

.pond-cond-posi::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: red;
  top: 2px;
  left: -24px;
  border-radius: 4px;
}

.pond-no-first {
  padding-top: 40px;
}

.pond-cond-had::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #e4e4e4;
  top: 2px;
  left: -24px;
  border-radius: 4px;
}

.cvatar {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
}

.touch-tab-cont ::v-deep .el-carousel__container {
  display: block;
  min-height: 400px !important;
  margin-top: 30px;
  /*overflow-y: scroll!important;*/
  /*min-height: 400px!important;*/
  /*width: 1599px!important;*/
}

.touch-tab-cont ::v-deep .el-badge__content {
  background-color: #41cabf !important;
}

.arrow ::v-deep .el-carousel__arrow {
  display: none !important;
}

.jumpToSource {
  color: #0099cc !important;
  cursor: pointer;
}

.tag-faren {
  height: 16px;
  padding: 0 2px;
  background: rgba(231, 255, 253, 1);
  border-radius: 2px;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(65, 202, 191, 1);
  line-height: 17px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
}

.touch-list {
  width: 100%;
  padding: 0 15px;
}

.touch-list-item {
  border-bottom: #f2f2f2 solid 1px;
  padding: 4px 10px;
}
.touch-email-item {
  width: 100%;
  min-height: 50px;
  border-bottom: #f2f2f2 solid 1px;
  padding: 15px 10px 0 10px;
}

.contact-list-item {
  width: 100%;
  min-height: 130px;
  border-bottom: #f2f2f2 solid 1px;
  padding: 15px 10px 0 10px;
  display: flex;
}

.con-info {
  font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  height: 30px;
  width: 100%;
  line-height: 50px;
  color: #333;
}

.i-top {
  width: 100%;
  min-height: 20px;
  display: flex;
  justify-content: space-between;
}

.i-tag {
  margin-top: 5px;
  width: 100%;
  height: 18px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.cus-info {
  height: 100%;
  display: flex;
  font-size: 12px;
  color: #000;
}

.cus-tag {
  height: 100%;
  display: flex;
  font-size: 14px;
}

.tag-i {
  height: 100%;
  padding: 0 15px;
  line-height: 20px;
  background: #e7fffd;
  color: #41cabf;
  margin-right: 8px;
  font-size: 12px;
}

.cus-href {
  color: #41cabf;
  border-left: #333 solid 1px;
  height: 10px;
  margin-top: 4px;
  line-height: normal;
  padding: 0 2px;
  cursor: pointer;
}
.cus-href-i {
  color: #333;
  cursor: default;
}

.cus-info .cus-href:first-child {
  border: none;
}
.i-sign {
  margin-top: 5px;
  width: 100%;
  min-height: 20px;
  display: flex;
  justify-content: space-between;
}

.sign-con {
  width: 80%;
  color: #aaa;
  font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  line-height: normal;
}

.touch-list ::v-deep .el-scrollbar__wrap {
  width: 100%;
  overflow-x: hidden;
}
.yun-icon {
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url('../../assets/maimai_logo.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;

  top: 20px;
  right: 38px;
}
.yun-icon1 {
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url('../../assets/lingying.jpg') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 20px;
  right: 10px;
  border-radius: 5px;
}

.time-line {
  padding: 20px 20px 20px 100px;
  box-sizing: border-box;
}
.time-line /deep/ .el-timeline-item {
  padding-bottom: 0 !important;
}
.time-line /deep/ .el-timeline-item__node--normal {
  left: 1px !important;
  width: 8px !important;
  height: 8px !important;
}
.line-content {
  color: #8a8a8a;
  font-size: 12px;
}
.import-text {
  color: #333333 !important;
}
.line-top {
  margin-bottom: 10px;
  position: relative;
}
.line-top > span:first-child {
  transform: translateX(-70px);
  position: absolute;
}
.time-line-date {
  position: relative;
  transform: translate(-100px, -20px);
}
.play-record {
  color: #0099cc;
  cursor: pointer;
}
</style>
