<template>
  <div class="VmiDialog-main">
    <el-dialog
      v-bind="$attrs"
      v-on="$listeners"
      :visible.sync="show"
      :show-close="false"
      :modal-append-to-body="false"
      :width="dialogWidth"
      destroy-on-close
      custom-class="dialog-class"
    >
      <div slot="title" class="title">
        <span>{{ title }}</span>
        <i class="el-icon-close" @click="closeDialog"></i>
      </div>
      <slot></slot>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DtDialog',
  inheritAttrs: false,
  data() {
    return {
      show: false
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogWidth: {
      type: String,
      default: '50%'
    }
  },
  methods: {
    showDialog() {
      this.show = true
    },
    closeDialog() {
      this.show = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/variables.scss';
.dialog-class {
  .el-dialog__header {
    padding: 0 !important;
    color: #fff;
    font-size: 14px;
    .title {
      height: 50px;
      width: 100%;
      background-color: $main-color;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      i {
        cursor: pointer;
      }
    }
  }
  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>
