<template>
  <div class="tip-main">
    <div class="tip-text">{{ title }}</div>
    <div class="er-code"></div>
  </div>
</template>

<script>
export default {
  name: 'Tip',
  computed: {
    title() {
      const title = this.$store.state.plugin.modal.options.title
      return title || '该服务为增值扩展功能，请扫码联系客服订阅'
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('hideModal')
    }
  }
}
</script>

<style scoped>
.tip-main {
  width: 100%;
  height: auto;
  padding: 0 10px 20px 10px;
  box-sizing: border-box;
}
.er-code {
  height: 240px;
  width: 100%;
  background: url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lner.png')
    no-repeat top center;
  background-size: auto 240px;
}
.er-code-tt {
  height: 140px;
  width: 100%;
  background: url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/qrcode.png')
    no-repeat top center;
  background-size: auto 120px;
}
.tip-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  user-select: none;
}
.dt-modal-footer {
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.dt-modal-footer::before {
  content: '';
  display: inline-block;
  height: 1px;
  width: calc(100% + 20px);
  position: absolute;
  top: 0;
  left: -10px;
  transform: scaleY(0.5);
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
