var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pe-map-info" }, [
    _c("div", { staticClass: "name" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.infoData.companyName ||
              _vm.infoData.circleName ||
              _vm.infoData.name
          ) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "label" }, [
      _c("span", [_vm._v("地址:" + _vm._s(_vm.infoData.address))]),
    ]),
    _vm._v(" "),
    _vm.infoData.linkData
      ? _c(
          "div",
          { staticClass: "brand-img" },
          [
            _c("span", [_vm._v("重点品牌:")]),
            _vm._v(" "),
            _vm._l(_vm.infoData.linkData, function (item) {
              return _c(
                "div",
                { key: item.id },
                [
                  _c("PeTool", [
                    _c(
                      "div",
                      { attrs: { slot: "txt-content" }, slot: "txt-content" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.show ? "已入驻" : "未入驻") +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("img", {
                      style: !item.show
                        ? "filter: grayscale(100%);opacity:0.2"
                        : "border:2px solid #f00",
                      attrs: { slot: "txt-btn", src: item.brandLogo },
                      slot: "txt-btn",
                    }),
                  ]),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }