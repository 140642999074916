var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bindWeChart-main" },
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { placeholder: "请输入手机号" },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "span",
                { staticClass: "sms-code", on: { click: _vm.getSmsCode } },
                [
                  _vm._v(
                    _vm._s(_vm.num === 0 ? "获取验证码" : `${_vm.num} s`) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "请输入验证码" },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "bottom-last" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.bindUser } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }