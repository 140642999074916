<template>
  <div class="dt-data">
    <div class="dt-data">
      <div class="dt-data-header">
        <!--        <el-button-group>-->
        <!--          <el-button size="small" @click="selectTimeClick(1)" :type=" selectBtn === 1 ? 'primary' : '' "-->
        <!--                     :plain="selectBtn === 1">今天-->
        <!--          </el-button>-->
        <!--          <el-button size="small" @click="selectTimeClick(-1)" :type=" selectBtn === -1 ? 'primary' : '' "-->
        <!--                     :plain="selectBtn === -1">昨天-->
        <!--          </el-button>-->
        <!--          <el-button size="small" @click="selectTimeClick(-7)" :type=" selectBtn === -7 ? 'primary' : '' "-->
        <!--                     :plain="selectBtn === -7">最近七天-->
        <!--          </el-button>-->
        <!--          <el-button size="small" @click="selectTimeClick(-30)" :type=" selectBtn === -30 ? 'primary' : '' "-->
        <!--                     :plain="selectBtn === -30">最近30天-->
        <!--          </el-button>-->
        <!--          <el-button size="small" @click="selectTimeClick(0)" :type=" selectBtn === 0 ? 'primary' : '' "-->
        <!--                     :plain="selectBtn === 0">全部-->
        <!--          </el-button>-->
        <!--        </el-button-group>-->
        <el-popover placement="bottom-start" width="500" trigger="click">
          <div class="j-input" slot="reference">
            <span v-if="!checkedUserNames.length">
              请选择成员
            </span>
            <span v-else-if="checkedUserNames.length <= 2">
              {{ checkedUserNames.join() }}
            </span>
            <span
              v-else-if="
                userList.length && checkedUserNames.length == userList.length
              "
            >
              全公司
            </span>
            <span v-else>
              {{
                checkedUserNames.slice(0, 2).join() +
                  '等' +
                  checkedUserNames.length +
                  '人'
              }}
            </span>
          </div>

          <div class="user-list">
            <el-row :gutter="20">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                style="margin: 0 0 10px 10px;"
                >全公司
              </el-checkbox>
              <el-checkbox-group v-model="checkedUsers" @change="chooseUsers">
                <el-col
                  :span="8"
                  v-for="user in userList"
                  :key="user.id"
                  style="margin-bottom: 10px"
                >
                  <el-checkbox
                    class="checkbox-dt check-black"
                    :key="user.id"
                    :label="user.id"
                  >
                    {{ user.name }}
                  </el-checkbox>
                </el-col>
              </el-checkbox-group>
            </el-row>
          </div>
        </el-popover>
        <el-select
          v-model="value2"
          placeholder="请选择"
          style="padding: 0 10px"
          size="small"
          @change="selectTimeClick"
        >
          <el-option
            v-for="item in dateOptions"
            :key="item.id"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="dt-select-time">
          <el-date-picker
            v-if="selectBtn == '2'"
            :picker-options="viewDemo ? startDateLimit : startDateLimit2"
            :editable="false"
            @change="selectTimeClickAuto"
            :clearable="false"
            size="small"
            v-model="selectTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <span class="reset" @click="reset">重置</span>
          <el-checkbox
            class="check-black"
            v-model="excludeHoliday"
            @change="checkChange"
            >排除周末节假日</el-checkbox
          >
        </div>
        <div style="position: absolute; right: 20px">
          <el-button
            :class="viewDemo ? '' : ''"
            autofocus
            size="small"
            :key="viewDemo"
            :type="viewDemo ? '' : 'primary'"
            @click="changeViewDemoType"
            >{{ viewDemo ? '关闭示例' : '查看示例' }}
          </el-button>
        </div>
      </div>
      <el-scrollbar class="dt-data-row dt-dropdown-dt" ref="scrollbar">
        <div>
          <div class="data-1" v-if="false">
            <div
              class="data-1-item"
              v-for="(key, index) in dataOneData.config"
              :key="index"
            >
              <div class="label">
                {{ key.label }}
              </div>
              <div class="value" v-if="key.key !== 'contractMoneySum'">
                {{ dataOneData.data[key.key] || '0' }}
              </div>
              <div v-else class="value">
                {{ (dataOneData.data[key.key] || '0').toLocaleString() }}
              </div>
            </div>
          </div>

          <!--          <div class="data-1-new" ref="scroll">-->
          <!--            <el-row :gutter="10">-->
          <!--              <el-col :span="24">-->
          <!--                <div class="data-1-new-left" v-loading="loadingDataLd">-->
          <!--                  <div style="height: 100%; width: 50%" id="data-1-new-left"></div>-->
          <!--                  <div class="data-title">-->
          <!--                    转化漏斗-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </el-col>-->
          <!--              <el-col :span="12" v-if="false">-->
          <!--                <div class="data-1-new-right">-->
          <!--                  <div class="data-title">-->
          <!--                    阶段客户画像-->
          <!--                  </div>-->
          <!--                  <div class="data-1-new-right-tip">-->
          <!--                    敬请期待...-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </el-col>-->
          <!--            </el-row>-->
          <!--          </div>-->
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="data-2-item" ref="znhk">
                <el-row
                  :gutter="20"
                  v-loading="loadingDataOne"
                  style="height: 300px;width: 90%;margin:0 auto;border-bottom:#f5f5f6 solid 1px"
                >
                  <el-col :span="6">
                    <div
                      style="box-sizing: border-box;height: 100px;margin-top: 100px"
                    >
                      <div class="value">
                        <i class="count"></i>
                        <span
                          style="width: 100px;display: inline-block;text-align: left"
                        >
                          {{ callInfoData.callCnt }}
                        </span>
                      </div>
                      <div class="label">
                        通话总数
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div style="height: 100px;margin-top: 100px">
                      <div class="value">
                        <i class="precent"></i>
                        <span
                          style="width: 100px;display: inline-block;text-align: left;"
                        >
                          {{ callInfoData.dealingPrecentDesc }}
                        </span>
                      </div>
                      <div class="label">
                        接通率
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div style="height: 100px;margin-top: 100px">
                      <div class="value">
                        <i class="time"></i>
                        <span
                          style="width: 100px;display: inline-block;text-align: left;"
                        >
                          {{ callInfoData.callDurationDesc }}
                        </span>
                      </div>
                      <div class="label">
                        通话时长
                      </div>
                    </div>
                  </el-col>

                  <el-col :span="6">
                    <div style="height: 100px;margin-top: 100px">
                      <div class="value">
                        <i class="time"></i>
                        <span
                          style="width: 100px;display: inline-block;text-align: left;"
                        >
                          {{ callInfoData.avgCallDuration }}
                        </span>
                      </div>
                      <div class="label">
                        平均通话时长
                      </div>
                    </div>
                  </el-col>
                </el-row>

                <el-row
                  :gutter="20"
                  v-loading="loadingDataEnd || loadingDataFive"
                  style="height: 100px;width: 90%;margin:0 auto;"
                >
                  <el-col :span="8">
                    <div
                      style="padding: 20px; box-sizing: border-box;height: 50px;margin-top: 30px"
                    >
                      <div class="label">
                        已接听
                      </div>
                      <div class="label">
                        {{ callInfoData.dealingCnt }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div
                      style="padding: 20px; box-sizing: border-box;height: 50px;margin-top: 30px"
                    >
                      <div class="label">
                        振铃未接听
                      </div>
                      <div class="label">
                        {{ callInfoData.notDealCnt }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div
                      style="padding: 20px; box-sizing: border-box;height: 50px;margin-top: 30px"
                    >
                      <div class="label">
                        <span style="position: relative">
                          ivr
                          <el-Popover
                            class="item"
                            effect="dark"
                            trigger="hover"
                            placement="top"
                          >
                            <div>
                              1.互动式语音问答，常见于集团业务，属于非人为的应答<br />
                              2.主动挂断（含因线路方规则导致的无法呼出）
                            </div>
                            <i class="ivr" slot="reference"></i>
                          </el-Popover>
                        </span>
                      </div>
                      <div class="label">
                        {{ callInfoData.ivrCnt }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="data-4">
                <div class="select-data-3">
                  <div style="padding: 0 0 10px 0;box-sizing: border-box">
                    趋势图
                  </div>
                  <el-select
                    v-model="value1"
                    placeholder="请选择"
                    size="mini"
                    @change="selectChange1"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>

                  <el-select
                    v-if="false"
                    multiple
                    collapse-tags
                    v-model="selectUserList"
                    placeholder="请选择成员"
                    size="mini"
                    @change="selectUserChange"
                  >
                    <el-option
                      v-for="item in userList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="data-4-item"
                  id="data-4-item"
                  v-loading="loadingDataFour"
                  ref="dataFourRef"
                ></div>
              </div>
            </el-col>
          </el-row>

          <div class="data-3" v-if="showTable && false">
            <div class="select-data-3-table">
              <div class="control-lab">
                <div
                  :class="{ table: true, 'table-active': activeType == 1 }"
                  @click="changeLab('1')"
                ></div>
                <div
                  :class="{ shell: true, 'shell-active': activeType == 2 }"
                  @click="changeLab('2')"
                ></div>
                <div
                  :class="{ cake: true, 'cake-active': activeType == 3 }"
                  @click="changeLab('3')"
                ></div>
              </div>
              <el-checkbox
                class="check-black"
                v-model="excludeHaveRecord"
                @change="checkHaveRecord"
                style="margin: 10px 0 0 20px"
                >只看有通话记录
              </el-checkbox>
            </div>
            <div style="float: right">
              <el-button
                size="small"
                style="position: absolute;top: 20px;right: 50px;z-index: 99;"
                type="primary"
                @click="exportCallList"
                v-if="activeType == '1'"
                >导出
              </el-button>
            </div>
            <div class="data-3-item">
              <div
                class="data-3-item-table"
                v-show="activeType == '1'"
                v-loading="loadingDataTwo"
              >
                <div class="table-row">
                  <el-table
                    :data="dataTwoData1"
                    v-if="activeType == '1'"
                    style="width: 100%; height: 100%; font-size: 12px"
                    border
                    stripe
                    @sort-change="sortchange"
                    ref="table"
                  >
                    <el-table-column
                      :label="item.name"
                      :prop="item.field"
                      v-for="item in colums"
                      show-overflow-tooltip
                      :sortable="item.name == '账号' ? false : 'custom'"
                      :key="item.field"
                    >
                      <template slot="header" slot-scope="scope">
                        <span
                          v-if="item.name == 'ivr'"
                          style="position: relative"
                        >
                          ivr
                          <el-popover
                            class="item"
                            effect="dark"
                            trigger="hover"
                            placement="top"
                          >
                            <div>
                              1.互动式语音问答，常见于集团业务，属于非人为的应答<br />
                              2.主动挂断（含因线路方规则导致的无法呼出）
                            </div>
                            <i class="ivr-table" slot="reference"></i>
                          </el-popover>
                        </span>
                        <span v-else>
                          {{ item.name }}
                        </span>
                      </template>
                      <template slot-scope="scope">
                        <div>
                          <span>
                            {{ scope.row[item.field] }}
                          </span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="page page-div" v-show="!viewDemo">
                    <footer-page
                      @handleCurrentChange="handleCurrentChange"
                      @goNumPage="goNumPage"
                      @changePageSize="changePageSize"
                      :pageStatus="pageStatus"
                    ></footer-page>
                  </div>
                </div>
              </div>
              <div
                class="data-3-item-left"
                v-show="activeType == '2'"
                id="data-3-left"
                v-loading="loadingDataTwo"
              ></div>
              <div
                class="data-3-item-right"
                v-show="activeType == '3'"
                id="data-3-right"
                v-loading="loadingDataTwo"
              ></div>
            </div>
          </div>

          <div class="achievement">
            <div class="btn-table-chart">
              <div class="btn-chart">
                <span style="display: inline-block;height: 28px;">
                  <i
                    @click="changeAchievementType('table')"
                    :class="
                      achievementType === 'table'
                        ? 'active-table'
                        : 'default-table'
                    "
                  />
                  <i
                    @click="changeAchievementType('histogram')"
                    :class="
                      achievementType === 'histogram'
                        ? 'active-histogram'
                        : 'default-histogram'
                    "
                  />
                  <i
                    @click="changeAchievementType('pie')"
                    :class="
                      achievementType === 'pie' ? 'active-pie' : 'default-pie'
                    "
                  />
                </span>
                <el-select
                  v-model="currentSelectType"
                  placeholder="请选择"
                  size="mini"
                  @change="newSelectChange"
                  v-show="achievementType !== 'table'"
                  style="transform: translate(3px,-3px)"
                >
                  <el-option
                    v-for="item in newOptions"
                    :key="item.field"
                    :label="item.name"
                    :value="item.field"
                  >
                  </el-option>
                </el-select>
                <el-checkbox
                  class="check-black-new"
                  v-model="excludeHaveRecord"
                  @change="checkHaveRecord"
                  v-show="achievementType === 'table'"
                  >只看有通话记录</el-checkbox
                >

                <!--                <span style="height: 28px;line-height: 28px;display: inline-block;transform: translate(5px,-5px)">-->
                <!--                  <el-checkbox class="check-black" v-model="excludeHaveRecord" @change="checkHaveRecord" v-show="achievementType === 'table'">只看有通话记录</el-checkbox>-->
                <!--                </span>-->
              </div>
              <div class="table-chart">
                <template v-if="achievementType === 'table'">
                  <div class="table-new">
                    <el-table
                      class="table-dt"
                      :data="tableData"
                      @sort-change="sortChange"
                      show-summary
                      border
                      stripe
                      style="width: 100%; height: 100%; font-size: 12px;background-color:#fff;"
                    >
                      <el-table-column
                        show-tooltip-when-overflow
                        v-for="item in columnList"
                        :prop="item.field"
                        align="center"
                        :sortable="item.type === 'sort' ? 'custom' : false"
                        :label="item.name"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
                <template v-if="achievementType === 'histogram'">
                  <div class="histogram" id="histogram"></div>
                </template>
                <template v-if="achievementType === 'pie'">
                  <div class="pie" id="pie"></div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts'
import FooterPage from '@/components/Footerpage/index'
import {
  dataOne,
  dataPOptions,
  dataHk,
  dataWz,
  dataPy,
  dataZh,
  dataLd
} from '@/api/workTable'
import {
  callInfo,
  callOption,
  callDataChart,
  callDataTable,
  exportCallList
} from '@/api/callboard'
import taskList from '@/views/collect/dialog/taskList'

export default {
  name: 'callboard',
  components: {
    FooterPage
  },
  data() {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const startDate = `${year}-${month < 10 ? '0' + month : month}-${
      day < 10 ? '0' + day : day
    }`
    const endDate = `${year}-${month < 10 ? '0' + month : month}-${
      day < 10 ? '0' + day : day
    }`
    return {
      startDateLimit: {
        disabledDate: time => {
          const s = new Date('2020-08-31').getTime()
          const e = new Date('2020-09-30').getTime()
          return !(time >= s && time <= e)
        }
      },
      startDateLimit2: {
        disabledDate: time => {
          return false
        }
      },
      viewDemo: false,
      loadingDataOne: false,
      loadingDataTwo: false,
      loadingDataThree: false,
      loadingDataFour: false,
      loadingDataFive: false,
      loadingDataSix: false,
      loadingDataEnd: false,
      loadingDataOld: false,
      loadingDataLd: false,
      excludeHaveRecord: true,
      selectBtn: 1,
      colorList: [
        '#3a98ff',
        '#7ecbe7',
        '#37c4c4',
        '#79dab6',
        '#49c46a',
        '#a3da79',
        '#face37',
        '#e79d6b',
        '#f05c72'
      ],
      userIdList: {},
      userIdListRe: {},

      value: '',
      value1: '',
      selectUserList: [],
      cardHeight: '200px', // 统计面板的高度
      excludeHoliday: true, // 是否排除节假日

      selectTime: [startDate, endDate], // 筛选的时间

      myChartBar: null, // 分布图-柱状图
      myChartCircular: null, // 分布图-扇形图
      myChartTrend: null, // 趋势图
      myChartLd: null, //漏斗图

      startDate: startDate,
      endDate: endDate,

      options: [],
      userList: [],
      userListRe: [],

      dataOneData: {
        config: {},
        data: {}
      },

      dataTwoData: {},
      dataTwoData1: [],
      dataThreeData: [],

      dataFourData: {},
      showTrend: true,

      dataFiveData: {
        config: [],
        data: {}
      },
      dataSixData: {
        config: [],
        data: {}
      },
      dataEndData: {
        config: [],
        data: {}
      },
      dataOldData: {
        config: [],
        data: {}
      },

      // 漏斗
      dataLdData: {},
      activeType: '1',
      value2: 3,
      dateOptions: [
        {
          name: '今天',
          value: 1
        },
        {
          name: '昨天',
          value: -1
        },
        {
          name: '最近7天',
          value: -7
        },
        {
          name: '最近30天',
          value: -30
        },
        {
          name: '本月',
          value: 3
        },
        {
          name: '全时期',
          value: 0
        },
        {
          name: '自定义',
          value: 2
        }
      ],
      checkedUsers: [],
      isIndeterminate: false,
      checkAll: false,
      callInfoData: {
        callCnt: 0,
        dealingPrecent: 0,
        callDuration: 0,
        dealingCnt: 0,
        notDealCnt: 0,
        ivrCnt: 0
      },
      colums: [],
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: null
      },
      sort: '',
      checkedUserNames: [],
      showTable: false,

      // 新图
      achievementType: 'table', // table:'图表', Histogram: '柱状图', pie: '饼图'
      tableData: [], // 销售图表数据
      histogramData: {}, // 销售柱状图数据（all）
      pieData: {}, // 销售饼图数据（all）
      newOptions: [],
      currentSelectType: '',
      columnList: [],
      sortNew: '',
      sortOrder: '',
      histogramChart: null,
      pieChart: null
    }
  },

  computed: {
    env() {
      return this.$store.state.plugin.env
    }
  },

  async mounted() {
    // 同步获取下拉框数据
    await this.getOptions()
    // this.getDtData()
    if (localStorage.getItem('callParams')) {
      let params = JSON.parse(localStorage.getItem('callParams'))
      this.startDate = params.startDate
      this.endDate = params.endDate
      this.checkedUsers =
        params.userIds !== ''
          ? params.userIds.split(',').map(i => {
              return Number(i)
            })
          : []
      this.checkedUserNames =
        params.checkedUserNames !== '' ? params.checkedUserNames.split(',') : []
      this.excludeHaveRecord = params.hasCallRecord
      this.excludeHoliday = params.noWeek
      this.activeType = params.type
      this.pageStatus.pageNo = params.pageNo
      this.pageStatus.pageSize = params.pageSize
      this.value1 = params.value1
      this.value2 = params.value2
      this.selectTimeClick(params.value2)
      this.checkAll = params.checkAll
      this.isIndeterminate = params.isIndeterminate
    } else {
      this.selectTimeClick(3) // 默认全部
    }

    this.$nextTick(() => {
      // 监听回到顶部事件 //emit在topLeftContent.vue组件内部
      this.$bus.$on('toTop', () => {
        if (!this.$refs['scroll']) {
          return
        }
        this.$refs['scroll'].scrollIntoView
          ? this.$refs['scroll'].scrollIntoView({ behavior: 'smooth' })
          : (this.$refs['scrollbar'].$el.scrollTop = 0)
      })
      window.onresize = () => {
        this.myChartBar ? this.myChartBar.resize() : ''
        this.myChartCircular ? this.myChartCircular.resize() : ''
        this.myChartTrend ? this.myChartTrend.resize() : ''
        this.myChartLd ? this.myChartLd.resize() : ''
        this.histogramChart ? this.histogramChart.resize() : ''
        this.pieChart ? this.pieChart.resize() : ''
      }
    })
  },

  methods: {
    getYj(setDefaultOption = false) {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate ? this.endDate : moment().format('YYYY-MM-DD'),
        userIds: this.checkedUsers.join(','),
        hasCallRecord: this.excludeHaveRecord,
        pageId: 501,
        sort: this.sortOrder ? `${this.sortNew}:${this.sortOrder}` : '',
        noWeek: this.excludeHoliday,
        isDemo: this.viewDemo ? 1 : 0,
        type: 2
      }
      this.echarts ? this.echarts.showLoading() : ''
      callDataTable(params)
        .then(res => {
          this.echarts ? this.echarts.hideLoading() : ''
          if (res.code === 60000) {
            const notDrawData = [
              'dealingPrecentDesc',
              'intentionRatio',
              'callDurationDesc',
              'avgCallDuration',
              'userName'
            ]
            this.tableData = res.data.data
            this.columnList = res.data.columns
            this.newOptions = this.columnList.filter(
              i => !notDrawData.includes(i.field)
            )
            this.getHistogramData(res.data.data)
            this.getPieData(res.data.data)
            if (setDefaultOption)
              this.currentSelectType = this.newOptions[0].field
            this.newSelectChange(this.currentSelectType)
          }
        })
        .catch(() => {
          this.echarts ? this.echarts.hideLoading() : ''
        })
    },

    getHistogramData(sourceData) {
      this.histogramData = {}
      let deepSourceData = JSON.parse(JSON.stringify(sourceData))
      this.newOptions.forEach(item => {
        deepSourceData = deepSourceData.sort((a, b) => {
          let _a = a[item.field] || 0
          let _b = b[item.field] || 0
          return _a - _b
        })
        this.histogramData[item.field] = {
          labelData: deepSourceData.map(i => i.userName),
          data: deepSourceData.map(i => i[item.field])
        }
        this.$forceUpdate()
      })
    },

    getPieData(sourceData) {
      this.pieData = {}
      this.newOptions.forEach(item => {
        this.pieData[item.field] = {
          labelData: sourceData.map(i => i.userName),
          data: sourceData.map(i => {
            return {
              userName: i.userName,
              count: i[item.field]
            }
          })
        }
        this.$forceUpdate()
      })
    },

    sortChange(data) {
      const map = {
        ascending: '升序',
        descending: '降序'
      }
      data.order ? (this.sortOrder = map[data.order]) : (this.sortOrder = null)
      this.sortNew = data.prop
      this.getYj(false)
    },

    newSelectChange(e) {
      this.currentSelectType = e
      if (this.achievementType === 'histogram') {
        this.changeAchievementType(this.achievementType)
      }
      if (this.achievementType === 'pie') {
        this.changeAchievementType(this.achievementType)
      }
    },

    changeAchievementType(type) {
      this.achievementType = type
      // 销毁图表
      this.histogramChart ? this.histogramChart.dispose() : ''
      this.pieChart ? this.pieChart.dispose() : ''
      this.histogramChart = null
      this.pieChart = null
      this.$nextTick(() => {
        // todo 绘制图表
        if (type === 'histogram') {
          const option = {
            grid: {
              left: '10px',
              top: '30px',
              right: '200px',
              bottom: '10px',
              containLabel: true
            },
            xAxis: { show: true },
            yAxis: {
              type: 'category',
              data: this.histogramData[this.currentSelectType].labelData,
              axisLabel: {
                textStyle: {
                  fontSize: '12'
                }
              }
            },

            series: [
              {
                type: 'bar',
                data: this.histogramData[this.currentSelectType].data,
                color: '#3a98ff',
                barMaxWidth: 30,
                markLine: {
                  data: [{ type: 'average', name: '平均值' }],
                  label: {
                    formatter: '平均值: {c} '
                  },
                  precision: 0
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      position: 'right',
                      textStyle: {
                        color: 'black',
                        fontSize: 16
                      }
                    }
                  }
                }
              }
            ],
            tooltip: {}
          }

          if (this.histogramChart) {
            this.histogramChart.dispose()
            this.histogramChart = null
          }
          this.histogramChart = echarts.init(
            document.getElementById('histogram')
          )
          this.histogramChart.setOption(option)
        }
        if (type === 'pie') {
          const option = {
            tooltip: false,
            legend: {
              left: 'center',
              bottom: 0,
              show: false,
              type: 'scroll',
              data: this.pieData[this.currentSelectType].labelData
            },
            color: this.colorList,
            grid: {
              top: '100px',
              bottom: '100px',
              containLabel: true
            },
            series: [
              {
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: true,
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '15',
                    fontWeight: 'bold'
                  }
                },
                label: {
                  normal: {
                    formatter: e => {
                      if (e.value) {
                        let newStr = ' '
                        let start, end
                        let name_len = `${e.name}${e.value}(${e.percent}%)`
                          .length
                        let max_name = 4
                        let new_row = Math.ceil(name_len / max_name)
                        if (name_len > max_name) {
                          for (let i = 0; i < new_row; i++) {
                            let old = ''
                            start = i * max_name
                            end = start + max_name
                            if (i === new_row - 1) {
                              old = e.name.substring(start)
                            } else {
                              old = e.name.substring(start, end) + '\n'
                            }
                            newStr += old
                          }
                        } else {
                          newStr = e.name
                        }
                        return `${newStr}${e.value}(${e.percent}%)`
                      }
                      return '0%'
                    },
                    textStyle: {
                      fontWeight: 'normal',
                      fontSize: 12,
                      align: 'center',
                      baseline: 'middle'
                    }
                  }
                },
                labelLine: {
                  show: this.formatCircular2()[0].name !== 'none'
                },
                data: this.formatCircular2()
              }
            ]
          }

          if (this.pieChart) {
            this.pieChart.dispose()
            this.pieChart = null
          }
          this.pieChart = echarts.init(document.getElementById('pie'))
          this.pieChart.setOption(option)
        }
      })
    },

    formatCircular2() {
      let res = []
      let data = this.pieData[this.currentSelectType].data
      res = data.map(item => {
        return {
          value: item.count || 0,
          name: item.userName
        }
      })
      res.sort((a, b) => {
        return b.value - a.value
      })
      let _res = res.filter(item => item.value)
      return _res.length ? _res : [{ name: 'none', value: 0 }]
    },

    // 新图表结束
    reset() {
      this.checkedUsers = []
      this.handleCheckAllChange(true)
      this.value2 = 3
      this.excludeHoliday = true
      this.sort = ''
      this.sortNew = ''
      this.sortOrder = ''
      this.excludeHaveRecord = true
      this.selectTimeClick(3)
    },
    exportCallList() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate ? this.endDate : moment().format('YYYY-MM-DD'),
        userIds: this.checkedUsers.join(','),
        hasCallRecord: this.excludeHaveRecord,
        pageId: 501,
        sort: this.sort,
        noWeek: this.excludeHoliday
      }
      exportCallList(params).then(res => {
        if (res.code === 60000 && res.data) {
          window.open(res.data)
        }
      })
    },
    exportList() {
      this.$store.dispatch('showModal', {
        title: '导出列表',
        view: taskList,
        size: '900px'
      })
    },
    getCallList() {
      this.$store.dispatch('showModal', {
        title: '导出列表',
        view: taskList,
        size: '900px'
      })
    },
    sortchange(column) {
      console.log(column)
      if (column.order == 'ascending') {
        this.sort = column.prop + ':升序'
      } else if (column.order == 'descending') {
        this.sort = column.prop + ':降序'
      } else {
        this.sort = ''
      }
      this.dataTwo()
    },
    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.dataTwo()
    },
    goNumPage(val) {
      this.pageStatus.pageNo = val
      this.dataTwo()
    },
    changePageSize(val) {
      this.pageStatus.pageSize = val
      this.dataTwo()
    },
    changeLab(i) {
      this.activeType = i
      this.$nextTick(() => {
        this.dataTwo()
      })
    },
    async changeViewDemoType() {
      this.excludeHoliday = true
      this.viewDemo = !this.viewDemo
      await this.getOptions()
      this.selectTimeClick(0)
    },

    selectChange(e) {
      this.value = e
      this.dataTwo()
    },
    selectChange1(e) {
      this.value1 = e
      this.callData2()
    },

    selectUserChange(e) {
      this.dataFour()
    },

    checkChange(e) {
      this.excludeHoliday = e
      this.getDtData()
    },
    checkHaveRecord(e) {
      this.excludeHaveRecord = e
      this.dataTwo()
      this.getYj(false)
    },
    handleCheckAllChange(val) {
      if (val) {
        this.checkedUsers = this.userList.map(i => {
          return i.id
        })
      } else {
        this.checkedUsers = []
      }
      let arr = []
      this.checkedUsers.forEach(i => {
        this.userList.forEach(j => {
          if (i == j.id) {
            arr.push(j.name)
          }
        })
      })
      this.checkedUserNames = arr
      this.isIndeterminate = false
      this.dataTwo()
      this.callInfo()
      this.callData2()
    },
    chooseUsers(val) {
      let arr = []
      this.checkedUsers.forEach(i => {
        this.userList.forEach(j => {
          if (i == j.id) {
            arr.push(j.name)
          }
        })
      })
      this.checkedUserNames = arr

      let checkedCount = val.length
      this.checkAll = checkedCount === this.checkedUsers.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.userList.length
      this.dataTwo()
      this.callInfo()
      this.callData2()
      this.getYj(false)
    },

    // 获取下拉框数据
    getOptions() {
      callOption().then(res => {
        if (res.code === 60000) {
          this.options = res.data
          this.value1 = this.options[0].value
        }
      })
      return new Promise(resolve => {
        callOption({ isDemo: this.viewDemo ? 1 : 0 }).then(res => {
          if (res.code === 60000) {
            this.options = res.data
            this.value = this.options[2].value
            this.value1 = this.options[2].value
            dataPOptions({ pageId: 501, isDemo: this.viewDemo ? 1 : 0 }).then(
              _res => {
                if (res.code === 60000) {
                  this.userIdList = {}
                  this.userIdListRe = {}
                  this.userList = _res.data
                  this.checkedUsers = this.userList.map(i => {
                    return i.id
                  })
                  this.checkedUserNames = this.userList.map(i => {
                    return i.name
                  })
                  this.checkAll = true
                  this.userListRe = _res.data
                  const accountId = this.$store.state.user.accountInfo.id
                  if (_res.data && _res.data.length) {
                    if (
                      _res.data.map(res => res.id).includes(accountId) &&
                      !this.viewDemo
                    ) {
                      this.userIdList[accountId] = accountId
                    } else {
                      this.userIdList[_res.data[0].id] = _res.data[0].id
                    }
                  }
                  resolve(res)
                }
              }
            )
          }
        })
      })
    },

    getTime(dayNum = 0) {
      if (this.viewDemo) {
        if (dayNum === 0) {
          this.startDate = '2020-09-30'
          this.endDate = '2020-09-30'
        }
        if (dayNum === 1) {
          this.startDate = '2020-09-29'
          this.endDate = '2020-09-29'
        }
        if (dayNum === 3) {
          this.startDate = '2020-09-01'
          this.endDate = '2020-09-29'
        }
        if (dayNum === 7) {
          this.startDate = '2020-09-23'
          this.endDate = '2020-09-30'
        }
        if (dayNum === 30) {
          this.startDate = '2020-09-01'
          this.endDate = '2020-09-30'
        }
        if (dayNum === 365 * 5) {
          this.startDate = '2020-09-01'
          this.endDate = '2020-09-30'
        }
        this.selectTime = [this.startDate, this.endDate]
      } else {
        const date = new Date().getTime() - 24 * 60 * 60 * 1000 * dayNum
        const yesterday = new Date(date)
        const year = yesterday.getFullYear()
        const month =
          yesterday.getMonth() + 1 >= 10
            ? yesterday.getMonth() + 1
            : '0' + (yesterday.getMonth() + 1)
        const day =
          yesterday.getDate() >= 10
            ? yesterday.getDate()
            : '0' + yesterday.getDate()
        this.startDate = `${year}-${month}-${day}`
        if (dayNum === 365 * 5) {
          this.startDate = ''
        }
        if (dayNum === 3) {
          this.startDate = moment().format('YYYY-MM') + '-01'
          this.endDate = moment().format('YYYY-MM-DD')
        }
        if (dayNum === 1) {
          this.endDate = this.startDate
        } else {
          this.endDate = moment().format('YYYY-MM-DD')
        }
        this.selectTime = [this.startDate, this.endDate]
      }
    },

    selectTimeClick(val) {
      this.selectBtn = val
      if (val == 2) {
        this.selectTime = []
        return
      }
      if (val == 3) {
        this.getTime(3)
      }
      if (val === 1) {
        this.getTime(0)
      }
      if (val === -1) {
        this.getTime(1)
      }
      if (val === -7) {
        this.getTime(7)
      }
      if (val === -30) {
        this.getTime(30)
      }
      if (val === 0) {
        this.getTime(365 * 5)
      }
      // 请求数据
      this.getDtData()
    },

    selectTimeClickAuto(e) {
      this.startDate = e[0]
      this.endDate = e[1]
      this.getDtData()
    },

    //获取所有统计数据
    getDtData() {
      this.dataTwo()
      this.callData2()
      this.callInfo()
      this.getYj(true)
    },

    dataLd() {
      // dataLdData
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 101,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataLd = true
      dataLd(params).then(res => {
        this.loadingDataLd = false
        // if(this.viewDemo){
        //   res = getSpData('ldData', this.selectBtn)
        // }
        if (res.code === 60000 && res.data) {
          this.dataLdOptions(res.data)
        } else {
          this.dataLdOptions({})
        }
      })
      // this.dataLdOptions()
    },

    dataLdOptions(data) {
      let res = []
      let dict = {
        viewCnt: {
          name: '查看线索',
          _value: '',
          value: 120,
          color: 'rgba(58, 152, 255, 1)'
        },
        consumptionCnt: {
          name: '领取线索',
          _value: '',
          value: 100,
          color: 'rgba(58, 152, 255, .8)'
        },
        markPhoneCnt: {
          name: '标记线索',
          _value: 0,
          value: 80,
          color: 'rgba(58, 152, 255,  .6)'
        },
        tagIntentionCnt: {
          name: '意向客户',
          _value: 0,
          value: 60,
          color: 'rgba(58, 152, 255, .4)'
        },
        tagOkCnt: {
          name: '成交客户',
          _value: 0,
          value: 40,
          color: 'rgba(58, 152, 255, .2)'
        },
        contractAmountSum: {
          name: '成交金额(元)',
          _value: 0,
          value: 20,
          color: 'rgba(58, 152, 255, .1)'
        }
      }
      for (let i in data) {
        let _res = dict[i]
        _res['_value'] = data[i] || 0
        res.push(_res)
      }
      const option = {
        calculable: true,
        series: [
          {
            clickable: false,
            hoverAnimation: true,
            legendHoverLink: true,
            type: 'funnel',
            x: 'center',
            y: 'center',
            width: '50%',
            height: '70%',
            minSize: '20%',
            maxSize: '100%',
            label: {
              show: true,
              position: 'left',
              formatter: function(val) {
                return `${val.name} ${val.data._value}`
              },
              color: '#3a98ff'
            },
            itemStyle: {
              color: function(res) {
                return res.data.color
              }
            },
            gap: 0,
            data: res
          }
        ]
      }
      if (this.myChartLd) {
        this.myChartLd.dispose()
        this.myChartLd = null
      }
      this.myChartLd = echarts.init(document.getElementById('data-1-new-left'))
      this.myChartLd.setOption(option)
    },
    callInfo() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate ? this.endDate : moment().format('YYYY-MM-DD'),
        pageId: 501,
        isDemo: this.viewDemo ? 1 : 0,
        noWeek: this.excludeHoliday,
        userIds: this.checkedUsers.join(',')
      }
      let callParams = {
        startDate: this.startDate,
        endDate: this.endDate ? this.endDate : moment().format('YYYY-MM-DD'),
        userIds: this.checkedUsers.join(','),
        checkedUserNames: this.checkedUserNames.join(','),
        hasCallRecord: this.excludeHaveRecord,
        noWeek: this.excludeHoliday,
        type: this.activeType,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        value1: this.value1,
        value2: this.value2,
        checkAll: this.checkAll,
        isIndeterminate: this.isIndeterminate
      }
      if (this.viewDemo != 1) {
        localStorage.setItem('callParams', JSON.stringify(callParams))
      }
      this.loadingDataOne = true
      callInfo(params).then(res => {
        this.loadingDataOne = false
        if (res.code === 60000) {
          this.callInfoData = res.data.data
        }
      })
    },
    dataOne() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 101,
        isDemo: this.viewDemo ? 1 : 0
      }
      dataOne(params).then(res => {
        if (res.code === 60000) {
          this.dataOneData.config = res.data.config
            .map(_res => {
              let con = Object.values(_res)
              return {
                label: Object.keys(_res)[0],
                key: con[0].col,
                show: con[0].show
              }
            })
            .filter(item => item.show)
          this.dataOneData.data = res.data.data
        }
      })
    },

    dataTwo() {
      return
      const params = {
        startDate: this.startDate,
        endDate: this.endDate ? this.endDate : moment().format('YYYY-MM-DD'),
        userIds: this.checkedUsers.join(','),
        hasCallRecord: this.excludeHaveRecord,
        pageId: 501,
        sort: this.sort,
        noWeek: this.excludeHoliday,
        isDemo: this.viewDemo ? 1 : 0,
        type: this.activeType,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize
      }
      let callParams = {
        startDate: this.startDate,
        endDate: this.endDate ? this.endDate : moment().format('YYYY-MM-DD'),
        userIds: this.checkedUsers.join(','),
        checkedUserNames: this.checkedUserNames.join(','),
        hasCallRecord: this.excludeHaveRecord,
        noWeek: this.excludeHoliday,
        type: this.activeType,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        value1: this.value1,
        value2: this.value2,
        checkAll: this.checkAll,
        isIndeterminate: this.isIndeterminate
      }
      if (this.viewDemo != 1) {
        localStorage.setItem('callParams', JSON.stringify(callParams))
      }

      this.loadingDataTwo = true
      callDataTable(params).then(res => {
        this.loadingDataTwo = false
        if (res.data === null) {
          this.showTable = false
        }
        if (res.code === 60000 && res.data) {
          this.showTable = true
          if (this.activeType == '1') {
            if (this.env === 'dd' || this.env === 'tt') {
              this.colums = res.data.colums.filter(
                i => i.field !== 'bindWechatCnt'
              )
            } else {
              this.colums = res.data.colums
            }
            this.pageStatus = res.data.page
            this.dataTwoData = res.data
            this.dataTwoData1 = res.data.data
          }

          if (this.activeType == '2' || this.activeType == '3') {
            let o = {}
            o = JSON.parse(JSON.stringify(res.data))
            this.drawDd(o)
            this.$nextTick(() => {
              // this.dataTwoOptions()
              // this.dataThreeOptions()
            })
          }
        }
      })
    },

    // 号码标记 跟进记录作图
    drawDd(chartData) {
      chartData.data.reverse()
      const bingRes = []
      const userCountObj = {}
      // 设置饼图
      if (this.activeType == '3') {
        for (let i in chartData.data) {
          let userName = chartData.data[i].userName
          let count = 0
          // chartData.data[i] && chartData.data[i].callCnt.forEach(item => {
          //   count += (isNaN(parseInt(item.cnt)) ? 0 : parseInt(item.cnt))
          // })

          bingRes.push({
            userName: userName,
            count: chartData.data[i].callCnt
          })
          userCountObj[userName] = count
        }
        this.dataTwoData = bingRes
        this.$nextTick(() => {
          this.dataThreeOptions()
        })
        return
      }
      let isAllNull = true
      let ddOptions = []
      let yName = []
      let ddData = []
      // 解析堆叠项
      ddOptions = chartData.colums.map(item => item.name)
      ddData = chartData.data
      for (let i in chartData.data) {
        // if (chartData[i] && chartData[i] instanceof Array && chartData[i].length) {
        // isAllNull = false

        // yName.push(i)
        // for(let j in chartData.colums){
        //   yName.push({
        //     userName:chartData.data[i].userName,
        //     name: chartData.colums[j].name,
        //     count: chartData.data[i][chartData.colums[j].field]
        //   })
        // }

        // yName.push({
        //   userName:chartData.data[i].userName,
        //   name: '通话总数',
        //   count: chartData.data[i].callCnt,
        // })
        // yName.push({
        //   userName:chartData.data[i].userName,
        //   name: '接通率',
        //   count: chartData.data[i].dealingPrecent,
        // })
        // yName.push({
        //   userName:chartData.data[i].userName,
        //   name: '通话时长',
        //   count: chartData.data[i].callDuration,
        // })
        yName.push({
          userName: chartData.data[i].userName,
          name: '已接听',
          count: chartData.data[i].dealingCnt
        })
        yName.push({
          userName: chartData.data[i].userName,
          name: '振铃未接听',
          count: chartData.data[i].notDealCnt
        })
        yName.push({
          userName: chartData.data[i].userName,
          name: 'ivr',
          count: chartData.data[i].ivrCnt
        })
        // }
      }

      // if (isAllNull) return
      let resData = {}
      ddOptions.forEach(item => {
        if (
          item != '账号' &&
          item != '通话总数' &&
          item != '通话时长' &&
          item != '接通率'
        ) {
          resData[item] = yName.filter(_item => _item.name === item)
        }
      })
      // 获取数据
      yName = yName.map(item => item.userName)
      yName = yName.filter(function(item, index) {
        return yName.indexOf(item) === index
      })
      let resSeries = [] // series数据
      for (let i in resData) {
        // 对resData[i]进行排序 排序对应y轴
        const _resDataI = []
        yName.forEach(item => {
          resData[i].forEach(_item => {
            if (_item.userName === item) {
              _resDataI.push(_item)
            }
          })
        })
        resSeries.push({
          name: i,
          type: 'bar',
          stack: 'all',
          barMaxWidth: 30,
          label: {
            show: true,
            position: 'insideRight',
            formatter: function(res) {
              return res.data || ''
            }
          },
          data: _resDataI.map(item => item.count || 0)
        })
      }
      // 绘制堆叠图
      const option = {
        color: [
          '#3a98ff',
          '#7ecbe7',
          '#37c4c4',
          '#79dab6',
          '#49c46a',
          '#a3da79',
          '#face37',
          '#e79d6b',
          '#f05c72'
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ddOptions,
          bottom: '10px'
        },
        grid: {
          top: '100px',
          left: '10px',
          right: '4%',
          bottom: '40px',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: yName //设置排序
        },
        series: resSeries
      }
      if (this.myChartBar) {
        this.myChartBar.dispose()
        this.myChartBar = null
      }
      this.$nextTick(() => {
        this.myChartBar = echarts.init(document.getElementById('data-3-left'))
        this.myChartBar.setOption(option)
      })
    },

    formatDataTwoSort() {
      let res = this.dataTwoData.sort((a, b) => {
        let _a = a.count || 0
        let _b = b.count || 0
        return _a - _b
      })
      return {
        name: res.map(item => item.userName),
        data: res.map(item => item.count)
      }
    },

    dataTwoOptions() {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        color: ['#41a2ff'],
        grid: {
          top: '100px',
          left: '10px',
          right: '4%',
          bottom: '20px',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          // data: this.dataTwoData.map(res=>res.userName)
          data: this.formatDataTwoSort().name
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            // data: this.dataTwoData.map(res=>res.count||0),
            data: this.formatDataTwoSort().data,
            markLine: {
              data: [{ type: 'average', name: '平均值' }],
              label: {
                formatter: '平均值: {c} '
              },
              precision: 0
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'right'
                }
              }
            }
          }
        ]
      }
      if (this.myChartBar) {
        this.myChartBar.dispose()
        this.myChartBar = null
      }
      this.myChartBar = echarts.init(document.getElementById('data-3-left'))
      this.myChartBar.setOption(option)
    },

    dataThreeOptions() {
      const option = {
        tooltip: false,
        legend: {
          left: 'center',
          bottom: 0,
          show: false,
          type: 'scroll',
          data: this.dataTwoData.map(item => item.userName)
        },
        color: this.colorList,
        // grid: {
        //   containLabel: true
        // },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
            label: {
              normal: {
                formatter: e => {
                  if (e.value) {
                    let newStr = ' '
                    let start, end
                    let name_len = `${e.name}${e.value}(${e.percent}%)`.length
                    let max_name = 4
                    let new_row = Math.ceil(name_len / max_name)
                    if (name_len > max_name) {
                      for (let i = 0; i < new_row; i++) {
                        let old = ''
                        start = i * max_name
                        end = start + max_name
                        if (i === new_row - 1) {
                          old = e.name.substring(start)
                        } else {
                          old = e.name.substring(start, end) + '\n'
                        }
                        newStr += old
                      }
                    } else {
                      newStr = e.name
                    }
                    return `${newStr}${e.value}(${e.percent}%)`
                  }
                  return ''
                },
                textStyle: {
                  fontWeight: 'normal',
                  fontSize: 12,
                  align: 'center',
                  baseline: 'middle'
                }
              }
            },
            labelLine: {
              show: this.formatCircular()[0].name !== 'none'
            },
            data: this.formatCircular()
          }
        ]
      }

      if (this.myChartCircular) {
        this.myChartCircular.dispose()
        this.myChartCircular = null
      }
      this.myChartCircular = echarts.init(
        document.getElementById('data-3-right')
      )
      this.myChartCircular.setOption(option)
    },

    formatCircular() {
      let res = []
      res = this.dataTwoData.map(item => {
        return {
          value: item.count || 0,
          name: item.userName
        }
      })

      res.sort((a, b) => {
        return b.value - a.value
      })
      let _res = res.filter(item => item.value)
      return _res.length ? _res : [{ name: 'none', value: 0 }]
    },

    dataThree() {},

    callData2() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate ? this.endDate : moment().format('YYYY-MM-DD'),
        item: this.value1,
        userIds: this.checkedUsers.join(','),
        noWeek: this.excludeHoliday,
        pageId: 501,
        isDemo: this.viewDemo ? 1 : 0
      }
      let callParams = {
        startDate: this.startDate,
        endDate: this.endDate ? this.endDate : moment().format('YYYY-MM-DD'),
        userIds: this.checkedUsers.join(','),
        checkedUserNames: this.checkedUserNames.join(','),
        hasCallRecord: this.excludeHaveRecord,
        noWeek: this.excludeHoliday,
        type: this.activeType,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        value1: this.value1,
        value2: this.value2,
        checkAll: this.checkAll,
        isIndeterminate: this.isIndeterminate
      }
      if (this.viewDemo != 1) {
        localStorage.setItem('callParams', JSON.stringify(callParams))
      }
      // myChartTrend
      this.loadingDataFour = true
      callDataChart(params).then(res => {
        this.loadingDataFour = false
        if (res.code === 60000 && res.data) {
          this.dataFourData = res.data
          this.showTrend = true
          this.$nextTick(() => {
            this.dataFourOption()
          })
        } else {
          if (this.myChartTrend) {
            this.dataFourData = {}
            this.$nextTick(() => {
              this.dataFourOption()
            })
          }
        }
      })
    },

    formatData() {
      let res = []
      let _res = {
        type: 'line'
      }
      const keys = Object.keys(this.dataFourData)
      if (!keys.length) {
        return res
      }
      keys.forEach(item => {
        let itemRes = []
        this.dataFourData[item].forEach(_item => {
          itemRes.push(_item.count || 0)
        })
        res.push({
          type: 'line',
          data: itemRes,
          name: item,
          smooth: true,
          markLine: {
            data: [{ type: 'average', name: '平均值' }],
            label: {
              formatter:
                this.value1 !== 3
                  ? '平均值: {c}'
                  : params => {
                      const sum = itemRes.reduce(function(
                        accumulator,
                        currentValue
                      ) {
                        return accumulator + currentValue
                      })
                      return ((sum / itemRes.length) * 100).toFixed(2) + '%'
                    }
            },
            precision: this.value1 !== 3 ? 0 : 2
          }
        })
      })
      return res
    },

    dataFourOption() {
      let timeList = []
      // 使用后端返回的时间
      const keys = Object.keys(this.dataFourData)
      if (!keys.length) return
      timeList = this.dataFourData[keys[0]].map(res => res.summaryDate)
      let that = this
      const option = {
        color: this.colorList,
        grid: {
          left: '50px',
          top: '100px',
          right: '80px',
          bottom: timeList.length > 31 ? '80px' : '50px',
          containLabel: true,
          position: 'outer',
          alignTo: 'edge'
        },
        tooltip: {
          trigger: 'axis',
          show: true,
          formatter: function(params, ticket, callback) {
            if (that.value1 == 3) {
              return (Number(params[0].value) * 100).toFixed(2) + '%'
            } else if (that.value1 == 4 || that.value1 == 5) {
              return params[0].value + '秒'
            } else {
              return params[0].value
            }
          }
        },
        dataZoom:
          timeList.length > 31
            ? [
                {
                  type: 'slider',
                  start: timeList.length < 31 ? 100 : 50,
                  end: 100,
                  handleIcon:
                    'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                  handleSize: '80%',
                  handleStyle: {
                    color: '#fff',
                    shadowBlur: 3,
                    shadowColor: 'rgba(0, 0, 0, 0.6)',
                    shadowOffsetX: 2,
                    shadowOffsetY: 2
                  },
                  bottom: 50,
                  zoomOnMouseWheel: false
                }
              ]
            : [],

        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: timeList,
          axisLabel: {
            interval: 'auto',
            rotate: 45
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(value, index) {
              if (that.value1 == 3) {
                return (value * 100).toFixed(2) + '%'
              } else if (that.value1 == 4 || that.value1 == 5) {
                return value + '秒'
              } else {
                return value
              }
            }
          }
        },
        series: this.formatData(timeList)
      }
      if (this.myChartTrend) {
        this.myChartTrend.dispose()
        this.myChartTrend = null
      }
      this.myChartTrend = echarts.init(document.getElementById('data-4-item'))
      this.myChartTrend.setOption(option)
    },

    formatTime(timeList) {
      if (this.excludeHoliday) {
        return timeList.filter(item => {
          const isWeek = moment(item).weekday()
          return isWeek !== 6 && isWeek !== 0
        })
      }
      return timeList
    },

    async requestFe() {
      await this.dataFive()
      await this.dataEnd()

      const n = this.dataFiveData.config.length - this.dataEndData.config.length
      if (n > 0) {
        for (let i = 0; i < n; i++) {
          this.dataEndData.config.push({
            label: '',
            key: 'none',
            show: true
          })
        }
      }
      if (n < 0) {
        for (let i = 0; i < Math.abs(n); i++) {
          this.dataFiveData.config.push({
            label: '',
            key: 'none',
            show: true
          })
        }
      }
    },

    async requestSo() {
      await this.dataSix()
      await this.dataOld()
      const n = this.dataSixData.config.length - this.dataOldData.config.length
      if (n > 0) {
        for (let i = 0; i < n; i++) {
          this.dataOldData.config.push({
            label: '',
            key: 'none',
            show: true
          })
        }
      }
      if (n < 0) {
        for (let i = 0; i < Math.abs(n); i++) {
          console.log(i)
          this.dataSixData.config.push({
            label: '',
            key: 'none',
            show: true
          })
        }
      }
    },

    dataFive() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 101,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataFive = true

      return new Promise(resolve => {
        dataHk(params).then(res => {
          this.loadingDataFive = false
          if (res.code === 60000) {
            this.dataFiveData.config = res.data.config
              .map(_res => {
                let con = Object.values(_res)
                return {
                  label: Object.keys(_res)[0],
                  key: con[0].col,
                  show: con[0].show
                }
              })
              .filter(item => item.show)
            this.dataFiveData.data = res.data.data
            resolve(true)
          }
        })
      })
    },
    dataEnd() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 101,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataEnd = true
      return new Promise(resolve => {
        dataPy(params).then(res => {
          this.loadingDataEnd = false
          if (res.code === 60000) {
            this.dataEndData.config = res.data.config
              .map(_res => {
                let con = Object.values(_res)
                return {
                  label: Object.keys(_res)[0],
                  key: con[0].col,
                  show: con[0].show
                }
              })
              .filter(item => item.show)
            this.dataEndData.data = res.data.data
            resolve(true)
          }
        })
      })
    },

    dataSix() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 101,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataSix = true
      return new Promise(resolve => {
        dataWz(params).then(res => {
          this.loadingDataSix = false
          if (res.code === 60000) {
            this.dataSixData.config = res.data.config
              .map(_res => {
                let con = Object.values(_res)
                return {
                  label: Object.keys(_res)[0],
                  key: con[0].col,
                  show: con[0].show
                }
              })
              .filter(item => item.show)
            this.dataSixData.data = res.data.data
            resolve(true)
          }
        })
      })
    },
    dataOld() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 101,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataOld = true
      return new Promise(resolve => {
        dataZh(params).then(res => {
          this.loadingDataOld = false
          if (res.code === 60000) {
            this.dataOldData.config = res.data.config
              .map(_res => {
                let con = Object.values(_res)
                return {
                  label: Object.keys(_res)[0],
                  key: con[0].col,
                  show: con[0].show
                }
              })
              .filter(item => item.show)
            this.dataOldData.data = res.data.data
            resolve(true)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.dt-data {
  height: 100%;
}

.dt-data-header {
  height: 70px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  /*box-shadow: rgba(0,0,0,.1) 0 0 1px 1px;*/
  position: relative;
}

.dt-data-row {
  height: calc(100% - 70px);
  width: 100%;
  /*box-shadow: rgba(0,0,0,.1) 0 0 1px 1px;*/
}

.dt-select-time {
  padding-left: 10px;
  box-sizing: border-box;
}

.data-1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  margin-bottom: 10px;
  min-height: 150px;
  height: auto;
}

.data-1-new {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

.data-1-new-left,
.data-1-new-right {
  height: 450px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  /*position: relative;*/

  display: flex;
  align-items: center;
  justify-content: center;
}

.data-title {
  position: absolute;
  left: 20px;
  top: 20px;
}

.data-1-new-left::before {
  content: '转化漏斗';
  position: absolute;
  display: block;
  left: 20px;
  top: 20px;
}

.data-1-new-right-tip {
  color: #8a8a8a;
}

.data-1-item {
  width: 100px;
  height: 150px;
  /*background-color: red;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label {
  /*margin-bottom: 10px;*/
  font-size: 14px;
  color: #909399;
  text-align: center;
  margin-top: 10px;
}

.value {
  font-size: 26px;
  font-weight: 500;
  /*cursor: pointer;*/
  text-align: center;
  position: relative;
}

.value:hover {
  /*transform: scale(1.5);*/
}

.data-2-item {
  height: 450px;
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
}

.data-2-item-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
  min-height: 240px;
}

.data-2-item-item > .data-2-item-item-item:last-child {
  margin-right: auto;
}

.data-2-item-item-item {
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.data-3 {
  height: 450px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #fff;
  position: relative;
}

.select-data-2 {
  min-height: 50px;
  height: auto;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.select-data-3 {
  position: absolute;
  min-height: 50px;
  height: auto;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  left: 0;
  top: 0;
  z-index: 9;
}

.select-data-3-table {
  position: absolute;
  min-height: 50px;
  height: auto;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
}

.data-3-item {
  height: calc(100% - 0px);
  min-height: 450px;
  width: 100%;
}

.data-3-item > div {
  min-height: 450px;
  width: 100%;
  height: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #909399;
}

.data-4 {
  height: 450px;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  background-color: #fff;
}

.data-4-item {
  height: 100%;
  width: 100%;
}

.j-input {
  white-space: nowrap;
  display: inline-block;
  width: 174px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dcdfe6;
  cursor: pointer;
  font-size: 12px;
  color: #606266;
  transform: translateY(1px);
  padding-left: 15px;
  box-sizing: border-box;
  margin-top: 1px;
  overflow: hidden;
}

/*.check-black ::v-deep .el-checkbox__label{*/
/*  color: #606266!important;*/
/*}*/
.button-show {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-duration: 800ms;
  animation-name: show;
}

@keyframes show {
  0% {
    background-color: #fff;
    color: #ffffff;
  }
  100% {
    background-color: #41cabf;
    color: #ffffff;
  }
}

.reset {
  display: inline-block;
  padding: 0 10px;
  color: #41cabf;
  cursor: pointer;
  font-size: 14px;
}

.control-lab {
  width: 99px;
  height: 33px;
  display: flex;
}

.table {
  width: 33px;
  height: 33px;
  display: block;
  background: url('../../assets/call_table.svg') no-repeat;
  background-size: 100% 100%;
}

.table-active {
  background: url('../../assets/call_table_active.svg') no-repeat;
}

.shell {
  width: 33px;
  height: 33px;
  display: block;
  background: url('../../assets/call_shell.svg') no-repeat;
  background-size: 100% 100%;
}

.shell-active {
  background: url('../../assets/call_shell_active.svg') no-repeat;
}

.cake {
  width: 33px;
  height: 33px;
  display: block;
  background: url('../../assets/call_cake.svg') no-repeat;
  background-size: 100% 100%;
}

.cake-active {
  background: url('../../assets/call_cake_active.svg') no-repeat;
}

.data-3-item-left {
  height: 450px;
  width: 100%;
}

.data-3-item-table {
  height: 450px;
  width: 100%;
}

.data-3-item-right {
  height: 450px;
  width: 100%;
}

.table-row {
  min-height: calc(100% - 120px);
  height: auto;
  padding: 20px 20px 50px 20px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  top: 50px;
  background-color: #fff;
}
.count::before {
  content: '';
  display: inline-block;
  height: 30px;
  width: 30px;
  position: relative;
  top: 4px;
  background: url('../../assets/call_count.svg') no-repeat center center;
  background-size: 100% 100%;
}
.precent::before {
  content: '';
  display: inline-block;
  height: 30px;
  width: 30px;
  position: relative;
  top: 4px;
  background: url('../../assets/call_precent.svg') no-repeat center center;
  background-size: 100% 100%;
}
.time::before {
  content: '';
  display: inline-block;
  height: 30px;
  width: 30px;
  position: relative;
  top: 4px;
  background: url('../../assets/call_time.svg') no-repeat center center;
  background-size: 100% 100%;
}
.ivr {
  display: inline-block;
  height: 15px;
  width: 15px;
  position: relative;
  top: 4px;
  background: url('../../assets/call_ivr.svg') no-repeat center center;
  background-size: 100% 100%;
}
.ivr-table {
  display: inline-block;
  height: 15px;
  width: 15px;
  position: relative;
  top: 4px;
  right: 0px;
  background: url('../../assets/call_ivr.svg') no-repeat center center;
  background-size: 100% 100%;
}

.achievement {
  min-height: 500px;
  height: auto;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.table-chart {
  margin-top: 10px;
}
.table-new {
  min-height: 300px;
  height: auto;
}
.histogram,
.pie {
  height: 300px;
  width: 100%;
}
.tab /deep/ .el-tabs__nav-wrap::after {
  display: none !important;
  content: '';
}

.default-table {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url('../../assets/call_table.svg') no-repeat center center;
}
.active-table {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: url('../../assets/call_table_active.svg') no-repeat center center;
  cursor: pointer;
}

.default-histogram {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url('../../assets/call_shell.svg') no-repeat center center;
}

.active-histogram {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url('../../assets/call_shell_active.svg') no-repeat center center;
}

.default-pie {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url('../../assets/call_cake.svg') no-repeat center center;
}

.active-pie {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url('../../assets/call_cake_active.svg') no-repeat center center;
}
.check-black-new {
  transform: translate(5px, -3px);
}
</style>
