<template>
  <div class="index-main">
    <div class="tab-div">
      <div style="width: 50%">
        <el-tabs v-model="tabType" class="dt-tab" @tab-click="tabClick">
          <el-tab-pane label="进行中" name="0" />
          <el-tab-pane label="已完成" name="1" />
        </el-tabs>
      </div>
      <div>
        <el-button size="mini" type="primary" @click="settingWaiHu"
          >设置</el-button
        >
        <el-button size="mini" type="primary" @click="importTask"
          >导入</el-button
        >
        <el-button size="mini" type="primary" @click="openUploadList"
          >导入列表</el-button
        >
      </div>
    </div>
    <div class="btn-list">
      <div class="input-row">
        <el-input
          v-model.trim="word"
          size="mini"
          placeholder="输入公司名/手机号查询"
        ></el-input>
        <div class="btn-row">
          <el-button type="primary" size="mini" @click="searchAgent"
            >查询</el-button
          >
          <el-button size="mini" @click="reset">重置</el-button>
        </div>
      </div>
    </div>

    <div class="btn-list" style="margin-top: 10px">
      <div>
        <el-button
          size="mini"
          :type="openAutoCallTask ? '' : 'primary'"
          @click="openAutoCall"
          v-if="tabType === '0'"
        >
          {{ openAutoCallTask ? '关闭自动呼叫' : '开启自动呼叫' }}
        </el-button>

        <el-button
          size="mini"
          type="primary"
          @click="addWaiHuTask"
          v-if="tabType === '1'"
        >
          加入外呼任务
        </el-button>
        <el-button size="mini" type="primary" @click="delWaiHu">
          删除
        </el-button>
      </div>
    </div>

    <div class="table" v-loading="loading">
      <el-table
        :key="resetKey"
        class="table-dt"
        :data="tableData"
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
        border
        stripe
        style="width: 100%; height: 100%; font-size: 12px;background-color:#fff;"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          show-tooltip-when-overflow
          v-for="item in columnList"
          :prop="item.field"
          align="center"
          :width="item.field === 'setting' ? '150px' : ''"
          :sortable="item.type === 'sort' ? 'custom' : false"
          :label="item.name"
        >
          <template slot="header" slot-scope="scope">
            <span v-if="item.type === 'normal'">
              {{ item.name }}
            </span>

            <span v-else-if="item.type === 'filter'">
              <el-dropdown
                placement="bottom"
                trigger="click"
                @command="val => filterFunc(val, item)"
              >
                <template v-if="item.field === 'from'">
                  <span class="el-dropdown-link" :class="from ? 'active' : ''">
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="el-dropdown-link">
                      <el-dropdown-item
                        :command="_item"
                        v-for="(_item, key) in item.filterOption"
                        :class="from === _item ? 'active' : ''"
                        >{{ key }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>

                <template v-else-if="item.field === 'phone_status'">
                  <span
                    class="el-dropdown-link"
                    :class="phone_status ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :command="_item"
                        v-for="(_item, key) in item.filterOption"
                        :class="phone_status === _item ? 'active' : ''"
                        >{{ key }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>
            <span v-else-if="item.type === 'sort'">{{ item.name }}</span>
            <span v-else>{{ item.name }}</span>
          </template>

          <template slot-scope="scope">
            <company-link
              v-if="item.field === 'company_name'"
              :company-name="scope.row.company_name || scope.row.company_name"
              :company-uuid="scope.row.companyUuid || scope.row.company_uuid"
              :package-id="scope.row.packageId || scope.row.package_id"
            />
            <span v-else> {{ scope.row[item.field] }} </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page page-div page-1">
      <footer-page
        @handleCurrentChange="handleCurrentChange"
        @goNumPage="goNumPage"
        @changePageSize="changePageSize"
        :pageStatus="pageStatus"
      ></footer-page>
    </div>
  </div>
</template>

<script>
import FooterPage from '@/components/Footerpage/index'
import { autoWaiHuList, waiHuSetting, callDel } from '@/api/customerpond'
import createUploadTask from './dialog/createUploadTask'
import uploadTask from './dialog/uploadList'
import settingWaiHu from './dialog/setingWaihu'
import keyNoGet from '@/views/mykey/addWaiHu/keyNoGet'
import { setTimeout } from 'timers'
import filterWaiHu from '@/views/mykey/addWaiHu/filterWaiHu'
import companyLink from '@/components/companyLink/companyLink'

export default {
  name: 'autoCallList',
  components: {
    FooterPage,
    companyLink
  },
  data() {
    return {
      resetKey: 0,
      tabType: '0',
      loading: false,
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: null
      },
      tableData: [],
      columnList: [],
      selectList: [],

      from: '',
      sort: '',
      sortOrder: '',
      word: '',
      phone_status: null
    }
  },
  watch: {
    openAutoCallTask(val) {
      if (!val) {
        this.$bus.$emit('closePanel')
      }
    },
    autoCallCurrentId() {
      this.reset()
    }
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.tabType = sessionStorage.getItem('autoWaiHuType') || '0'
    let cache = sessionStorage.getItem(`autoWaiHuList${this.tabType}`)
    if (cache) {
      cache = JSON.parse(cache)
      this.from = cache.from
      this.sort = cache.sort
      this.sortOrder = cache.sortOrder
      this.word = cache['word']
      this.pageStatus.pageSize = cache.pageSize
      this.pageStatus.pageNo = cache.pageNo
      this.phone_status = cache.phone_status || null
    }
    this.getWaiHuList()
    waiHuSetting().then(res => {
      if (res.code === 60000) {
        this.$store.commit('update', {
          target: this.$store.state.plugin,
          data: {
            openAutoCallTime: res.data.callInterval || 10
          }
        })
      }
    })
  },
  computed: {
    openAutoCallTask() {
      return this.$store.state.plugin.openAutoCallTask
    },

    isWaiHu() {
      return this.$store.state.user.accountInfo.isWaihu
    },

    waiHuType() {
      return this.$store.state.user.accountInfo.bandWaihuType
    },

    autoCallCurrent() {
      return this.$store.state.plugin.autoCallCurrent
    },

    autoCallCurrentId() {
      return this.$store.state.plugin.autoCallCurrent.id
    }
  },
  methods: {
    openAutoCall() {
      if (this.isWaiHu !== 1) {
        this.$store.dispatch('showTip', {
          text: '当前账号尚未配置外呼',
          type: 'error'
        })
        return
      }

      if (this.waiHuType !== 'webrtc' && this.waiHuType !== 'gateway') {
        this.$store.dispatch('showTip', {
          text: '自动呼叫只支持绑定方式为电脑的外呼系统',
          type: 'error'
        })
        return
      }
      let cache = sessionStorage.getItem(`autoWaiHuList0`)
      if (cache) {
        cache = JSON.parse(cache)
      }
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          waiHuFilter: cache
        }
      })

      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          openAutoCallTask: !this.openAutoCallTask
        }
      })
    },

    reset() {
      this.resetKey += 1
      this.from = ''
      this.sort = ''
      this.sortOrder = ''
      this.word = ''
      this.phone_status = null
      this.selectList = []
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      this.getWaiHuList()
    },

    searchAgent() {
      if (!this.word) {
        this.$store.dispatch('showTip', {
          text: '请输入搜索内容',
          type: 'warning'
        })
        return
      }
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      this.getWaiHuList()
    },

    setCache(params) {
      const cacheName = `autoWaiHuList${this.tabType}`
      sessionStorage.setItem(cacheName, JSON.stringify(params))
      sessionStorage.setItem('autoWaiHuType', this.tabType)
    },

    getWaiHuList() {
      this.loading = true
      const params = {
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        word: this.word,
        from: this.from,
        type: this.tabType,
        sort: this.sort,
        sortOrder: this.sortOrder,
        phone_status: this.phone_status
      }
      autoWaiHuList(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.setCache(params)
          this.tableData = res.data.list
          this.columnList = res.data.columns
          this.pageStatus.pageNo = res.data.page.pageNo
          this.pageStatus.total = res.data.page.total
        }
      })
    },

    filterFunc(val, item) {
      if (item.field === 'from') {
        this.from = val
      }
      if (item.field === 'phone_status') {
        this.phone_status = val
      }
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      this.getWaiHuList()
    },

    sortChange(data) {
      const map = {
        ascending: 'asc',
        descending: 'desc'
      }
      data.order ? (this.sortOrder = map[data.order]) : (this.sortOrder = null)
      this.sort = data.prop
      this.getWaiHuList()
    },

    handleSelectionChange(val) {
      this.selectList = val
    },

    delWaiHu() {
      if (!this.selectList.length) {
        this.$store.dispatch('showTip', {
          text: '请选择要删除的数据',
          type: 'warning'
        })
      } else {
        const ids = this.selectList.map(i => i.id).join(',')
        callDel({ ids: ids }).then(res => {
          if (res.code === 60000) {
            this.selectList = []
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
            this.getWaiHuList()
          }
        })
      }
    },

    addWaiHuTask() {
      if (!this.selectList.length) {
        this.$store.dispatch('showTip', {
          text: '请选择要重新加入外呼任务的数据',
          type: 'warning'
        })
      } else {
        this.filterWaiHu(false, 'autoList')
      }
    },

    filterWaiHu(isGet = false, type = 'one', params = {}) {
      this.$store.dispatch('showModal', {
        title: '提示',
        view: filterWaiHu,
        options: {
          consumption: false,
          autoIds: this.selectList.map(i => i.id).join(','),
          type: type
        },
        size: '600px',
        onClose: res => {
          if (res.isOk) {
            this.selectList = []
          }
        }
      })
    },

    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.getWaiHuList()
    },

    goNumPage(val) {
      this.pageStatus.pageNo = val
      this.getWaiHuList()
    },

    changePageSize(val) {
      this.pageStatus.pageSize = val
      this.getWaiHuList()
    },

    tabClick() {
      this.reset()
    },

    importTask() {
      this.$store.dispatch('showModal', {
        title: '导入外呼任务',
        view: createUploadTask,
        size: '600px',
        onClose: res => {
          if (res.isOk) {
            this.openUploadList()
          }
        }
      })
    },

    openUploadList() {
      this.$store.dispatch('showModal', {
        title: '导入列表',
        view: uploadTask,
        size: '900px'
      })
    },

    settingWaiHu() {
      this.$store.dispatch('showModal', {
        title: '外呼配置',
        view: settingWaiHu,
        size: '600px'
      })
    }
  }
}
</script>

<style scoped>
.index-main {
  height: 100%;
}
.btn-list {
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
.input-row {
  display: flex;
  align-items: center;
}
.btn-row {
  margin-left: 10px;
  display: flex;
}
.table {
  margin-top: 10px;
  min-height: calc(100% - 220px);
  background-color: #fff;
}
.table ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
.table ::v-deep .el-table th,
.el-table tr {
  background-color: #fff;
}
.page-1 {
  background-color: #fff;
}
.set {
  cursor: pointer;
  color: #0099cc;
  user-select: none;
}
.set > span:last-child {
  display: none;
}
.el-dropdown-link {
  cursor: pointer;
  color: #909399;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.tab-div {
  height: 50px;
  width: 100%;
  background-color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dt-tab /deep/ .el-tabs__nav-wrap::after {
  display: none !important;
}
.active {
  color: #41cabf !important;
}
.c-name {
  color: #0099cc;
  cursor: pointer;
}
</style>
