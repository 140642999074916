<template>
  <div class="sign-main">
    <div class="row-main">
      <div class="header">
        <el-date-picker
          @change="getViewData"
          size="small"
          v-model="year"
          type="year"
          value-format="yyyy"
          placeholder="选择年">
        </el-date-picker>

<!--        <el-select v-model="selectUserList" placeholder="请选择员工" filterable multiple size="small" style="margin-left: 20px;width: 400px" @change="getViewData">-->
<!--          <el-option-->
<!--            v-for="item in userList"-->
<!--            :key="item.id"-->
<!--            :label="item.name"-->
<!--            :value="item.id">-->
<!--          </el-option>-->
<!--        </el-select>-->
        <el-select v-model="selectUserList" placeholder="请选择员工" multiple filterable size="small" @change="getViewData" style="margin-left: 20px;width: 400px">
          <el-option-group
            v-for="group in userList"
            :key="group.label"
            :label="group.label">
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>
      </div>

      <div class="btn-list">
        <el-button size="small" type="primary" v-if="type === 'view'" @click="showEdit" v-loading="loading" v-dt-permission="'绩效目标设置-收款目标设置-编辑'">
          编辑
        </el-button>
        <div v-else>
          <el-button size="small" @click="cancelEdit" v-dt-permission="'绩效目标设置-收款目标设置-编辑'">取消</el-button>
          <el-button size="small" type="primary" @click="confirmBtn" v-loading="loading" v-dt-permission="'绩效目标设置-收款目标设置-编辑'">确定</el-button>
        </div>
      </div>

      <div class="table" v-loading="loadingData" v-dt-permission="'绩效目标设置-收款目标设置-查看'">
        <el-table :data="tableData" show-summary border size="small" :summary-method="getSummaries" :key="key">
          <el-table-column v-for="item in columns" :label="item.name" :prop="item.field">
            <template slot-scope="scope">
              <div v-if="item.field !== 'userName' && item.field !== 'count' && type === 'edit'" class="input-row" @mouseenter="mouseenter" @mouseleave="mouseleave">
                <el-input
                  @input="isNaN(parseInt(scope.row[item.field])) || scope.row[item.field].indexOf('0') === 0 ? scope.row[item.field] = '' : ''"
                  size="mini"
                  v-model="scope.row[item.field]"
                  placeholder="请填写金额"></el-input>
              </div>
              <div v-else-if="item.field === 'count'" class="input-row">
                {{  tableData[scope.$index] | countFormat  }}
              </div>
              <span v-else-if="item.field === 'userName'">
                {{ scope.row[item.field] }}
              </span>
              <span v-else>
                {{ isNaN(parseInt(scope.row[item.field])) ? '-' : parseInt(scope.row[item.field]).toLocaleString() }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div style="height: 50px"></div>
        <el-table :data="quarterTableData" show-summary border size="small" :summary-method="getSummaries" :key="key + 1">
          <el-table-column v-for="item in quarterColumns" :label="item.name" :prop="item.field">
            <template slot-scope="scope">
              <div v-if="item.field !== 'name' && item.field !== 'count' && type === 'edit'" class="input-row" @mouseenter="mouseenter" @mouseleave="mouseleave">
                <el-input
                  @input="isNaN(parseInt(scope.row[item.field])) || scope.row[item.field].indexOf('0') === 0 ? scope.row[item.field] = '' : ''"
                  size="mini"
                  v-model="scope.row[item.field]"
                  placeholder="请填写金额"></el-input>
              </div>
              <div v-else-if="item.field === 'count'" class="input-row">
                {{  quarterTableData[scope.$index] | countFormat  }}
              </div>
              <span v-else-if="item.field === 'name'">
                {{ scope.row[item.field] }}
              </span>
              <span v-else>
                {{   isNaN(parseInt(scope.row[item.field])) ? '-' : parseInt(scope.row[item.field]).toLocaleString() }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { targetList, updateTargetList, dataPOptions } from '@/api/workTable'

export default {
  name: 'rec',
  data () {
    return {
      key: 0,
      type: 'view',
      loading: false,
      loadingData: false,

      year: '2021',
      selectUserList: [],
      userList: [],

      tableData: [],
      columns: [],

      // 季度
      quarterColumns: [],
      quarterTableData: [],
    }
  },
  filters: {
    countFormat(val){
      const canSumTarget = [
        'quarter1', 'quarter2', 'quarter3', 'quarter4',
        'januaryCnt', 'februaryCnt', 'marchCnt', 'aprilCnt',
        'mayCnt', 'juneCnt', 'julyCnt', 'augustCnt', 'septemberCnt',
        'octoberCnt', 'novemberCnt', 'decemberCnt'
      ]
      let sum = 0
      for (let i in val){
        if(canSumTarget.includes(i) && val[i]){
          let value = isNaN(parseInt(val[i])) ? 0 : parseInt(val[i])
          sum += parseInt(value)
        }
      }
      return sum ? sum.toLocaleString() : '-'
    },
  },
  mounted () {
    let cache = localStorage.getItem('rec-cache')
    if(cache){
      cache = JSON.parse(cache)
      this.year = cache.year
      this.selectUserList = cache.selectUserList
    }
    this.getUserList()
  },
  computed: {},
  methods: {

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if(!sums[index]){
            sums[index] = '-'
          }
        } else {
          sums[index] = '-';
        }
      });

      let count = sums.slice(1, sums.length -1 ).reduce((prev, curr) => {
        return (isNaN(parseInt(curr)) ? 0 : parseInt(curr)) + (isNaN(parseInt(prev)) ? 0 : parseInt(prev))
      }, 0)

      sums[sums.length - 1] = count ? count : '-'

      console.log(sums)

      return sums.map(i => i.toLocaleString());
    },

    getUserList(){
      dataPOptions({ pageId:15002, isDemo: 0  }).then(res => {
        if(res.code === 60000){
          console.clear()
          console.log(res.data)
          this.userList = this.userList = [{
            label: '在职',
            options: res.data.filter( i => i.active === 1)
          }, {
            label: '离职',
            options: res.data.filter( i => i.active !== 1).map(item => {
              item.name += '(离职)'
              return item
            })
          }]
          this.getViewData()
        }
      })
    },


    showEdit(){
      if(this.loading) return
      this.loading = true
      this.loadingData = true
      const t = setTimeout(() => {
        this.type = 'edit'
        this.loading = false
        this.loadingData = false
        clearTimeout(t)
      }, 300)
    },

    cancelEdit(){
      this.getViewData(() => {
        this.type = 'view'
      })
    },

    confirmBtn(){
      if (this.loading) return
      this.putData(()=> {
        this.type = 'view'
        this.getViewData()
      })
    },

    setCache(){
      const cache = {
        selectUserList: this.selectUserList,
        year: this.year
      }
      localStorage.setItem('rec-cache', JSON.stringify(cache))
    },

    getViewData(fn){
      if(this.loadingData) return
      this.loadingData = true
      const params = {
        pageId: 15002,
        year: this.year,
        type: 2,
        pdas: this.selectUserList.join(',')
      }
      targetList(params).then(res => {
        this.loadingData = false
        if(res.code === 60000){
          this.setCache()
          this.columns = [...res.data.columns, {field: 'count', name: '合计'}]
          this.tableData = res.data.data
          this.formatQuarterData()
          typeof fn === 'function' ? fn() : ''
        }
      })
    },
    formatQuarterData(){
      this.quarterColumns = [
        {
          field: 'name',
          name: '姓名'
        },
        {
          field: 'quarter1',
          name: `${ this.year }第1季度`
        },
        {
          field: 'quarter2',
          name: `${ this.year }第2季度`
        },
        {
          field: 'quarter3',
          name: `${ this.year }第3季度`
        },
        {
          field: 'quarter4',
          name: `${ this.year }第4季度`
        },
        {
          field: 'count',
          name: '合计'
        }
      ]
      this.quarterTableData = this.tableData.map(item => {
        return {
          name: item.userName,
          userId: item.userId,
          quarter1: this.formatNum(item.oneQuarter),
          quarter2: this.formatNum(item.twoQuarter),
          quarter3: this.formatNum(item.threeQuarter),
          quarter4: this.formatNum(item.fourQuarter)
        }
      })

      this.key += 1
    },

    formatNum(data){
      return isNaN(parseInt(data)) ? 0 : parseInt(data)
    },

    putData(fn){
      if(this.loading) return
      this.loading = true
      this.loadingData = true
      const mouthList = [
        `${ this.year }-01-01`, `${ this.year }-02-01`, `${ this.year }-03-01`, `${ this.year }-04-01`,
        `${ this.year }-05-01`, `${ this.year }-06-01`, `${ this.year }-07-01`, `${ this.year }-08-01`,
        `${ this.year }-09-01`, `${ this.year }-10-01`, `${ this.year }-11-01`, `${ this.year }-12-01`
      ]
      const cnt = {
        1: 'januaryCnt', 2: 'februaryCnt', 3: 'marchCnt', 4: 'aprilCnt',
        5: 'mayCnt', 6: 'juneCnt', 7: 'julyCnt', 8: 'augustCnt',
        9: 'septemberCnt', 10: 'octoberCnt', 11: 'novemberCnt', 12: 'decemberCnt'
      }
      let data = this.tableData.map(item => {
        return {
          userId: item.userId,
          summaryYear: this.year,
          type: 2,
          dateToAmounts: mouthList.map((i, index) => {
            return {
              monthDate: i,
              amount: isNaN(parseInt(item[cnt[index + 1]])) ? 0 : parseInt(item[cnt[index + 1]]),
              amountQuarter: this.getQuData(index + 1, item)
            }
          })
        }
      })
      const params = {
        target: JSON.stringify(data)
      }
      updateTargetList(params).then(res => {
        this.loading = false
        this.loadingData = false
        if(res.code === 60000){
          typeof fn === 'function' ? fn() : ''
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
        }
      }).catch(() => {
        this.loading = false
        this.loadingData = false
      })
    },
    getQuData(index, data){
      const userId = data.userId
      const _data = this.quarterTableData.find(i => i.userId === userId)
      if (!_data) return ''
      if(index === 1){
        return this.formatNum(_data.quarter1)
      }
      if(index === 4){
        return this.formatNum(_data.quarter2)
      }
      if(index === 7){
        return this.formatNum(_data.quarter3)
      }
      if(index === 10){
        return this.formatNum(_data.quarter4)
      }
      return ''
    },
    mouseenter(e){
      e.currentTarget.className = 'input-ddt input-row'
    },
    mouseleave(e){
      e.currentTarget.className = 'input-row'
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.sign-main{
  height: 100%;
}
.row-main{
  height: 100%;
  width: 100%;
}
.header{
  height: 50px;
  margin-bottom: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
}
.table{
  min-height: calc( 100% - 120px );
  height: auto;
  width: 100%;
  background-color: #fff;
  padding: 5px;
  box-sizing: border-box;
}
.input-row{
  padding-left: 22px;
  box-sizing: border-box;
  background: url("../../assets/rmb.svg") no-repeat left center;
  background-size: 20px 20px;
}
.btn-list{
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  justify-content: flex-end;
  padding: 0 20px;
  box-sizing: border-box;
}
.input-ddt{
  width: 200px!important;
  height: 50px;
  background-color: rgba(0,0,0,.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 5px 20px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
</style>
