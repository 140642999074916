<template>
  <div class="pe-custom-tab" :style="mapStyle">
    <div
      v-for="(item, index) in tabData"
      :key="index"
      :style="iconClass(item)"
      :class="activeClass(index)"
      @click="tabClick(index, item)"
    >
      <span>{{ item }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PeCustomTab',
  props: {
    tabData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkedIndex: 0,
      vipIcon: 'https://web-app.dtbird.cn/web_icon/pro.png',
      mapStyle: ''
    }
  },
  mounted() {
    if (this.tabData.length >= 2) {
      this.checkedIndex = 1
    } else {
      this.checkedIndex = 0
    }
  },
  methods: {
    tabClick(index, val) {
      this.checkedIndex = index
      this.$emit('tabChange', val, index)
    },
    iconClass(item) {
      const list = ['云行业', '连锁行业', '企业出海']
      if (list.includes(item)) {
        return `
                background:url(${this.vipIcon});
                background-repeat:no-repeat;
                background-size:50px 15px;
                background-position:right top
              `
      } else {
        return null
      }
    },
    activeClass(index) {
      if (index == this.checkedIndex) {
        return `tab-item tab-item-checked`
      } else {
        return `tab-item tab-item-unchecked`
      }
    }
  },
  watch: {
    '$store.state.settings.isMapFull': {
      handler(val) {
        if (val) {
          this.mapStyle = 'z-index:0'
        } else {
          this.mapStyle = 'z-index: 999'
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.pe-custom-tab {
  position: relative;
  background: #fcfcfc;
  color: #8a8a8a;
  font-size: 16px;
  display: flex;

  .tab-item {
    width: 180px;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .tab-item-checked {
    border-top: 1px solid $main-color;
    border-right: 1px solid #e6e6e6;
    background: #fff;
    color: $main-color;
  }

  .tab-item-unchecked {
    border-top: 1px solid #e6e6e6;
    background: #fcfcfc;
  }
}
</style>
