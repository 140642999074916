var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chain-radar-page" },
    [
      _c(
        "div",
        { staticClass: "search-schema" },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "行业关键字搜素", clearable: "" },
              on: { clear: _vm.handleClear },
              model: {
                value: _vm.searchWords,
                callback: function ($$v) {
                  _vm.searchWords = $$v
                },
                expression: "searchWords",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.handleSearch },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "PeScroll",
        {
          attrs: {
            scrollLoading: _vm.mainLoading,
            isScrollTop: _vm.isScrollTop,
          },
        },
        [
          _vm.brandList.length
            ? _c(
                "div",
                { staticClass: "list" },
                _vm._l(_vm.brandList, function (item, index) {
                  return _c("div", { key: index, staticClass: "item" }, [
                    _c("div", { staticClass: "top" }, [
                      _c("div", { staticClass: "company-name" }, [
                        _vm._v(_vm._s(item.brandName)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "top-fr" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleScreen(item)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-full-screen el-icon--left",
                              }),
                              _vm._v("数据大屏"),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tag-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "tag-fl" },
                        [
                          item.brandLogo
                            ? _c("img", {
                                staticClass: "tag-img",
                                attrs: { src: item.brandLogo },
                              })
                            : _c("Icon", {
                                staticClass: "tag-img",
                                attrs: { type: "img", peClass: "default" },
                              }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "tag-fr" }, [
                        _c(
                          "div",
                          { staticClass: "num-list" },
                          [
                            _vm._l(item.countList, function (it, idx) {
                              return [
                                it.show
                                  ? _c(
                                      "div",
                                      { key: idx, staticClass: "num-item" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "num-item-name" },
                                          [_vm._v(_vm._s(it.name))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "num-item-value" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "count" },
                                              [_vm._v(_vm._s(it.count))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "unit" },
                                              [_vm._v(" " + _vm._s(it.unit))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ])
                }),
                0
              )
            : _c("NoData"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }