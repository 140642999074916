<template>
  <div class="cloud-main">
    <div v-for="dataList in list">
      <el-row>
        <el-col :span="24">
          <div class="header-text">{{ dataList.type }}</div>
        </el-col>
        <el-col :span="24">
          <el-row
            :gutter="20"
            :class="
              !otherParams.isReceive && otherParams.shade ? 'bg-filter' : ''
            "
          >
            <el-col :span="4">
              <div class="icon-text">
                <el-tooltip
                  :visible-arrow="false"
                  popper-class="popper"
                  effect="dark"
                  placement="right-end"
                  style="width:160px"
                >
                  <span v-if="dataList.tip" slot="content">{{
                    dataList.tip
                  }}</span>

                  <div style="width: 60px; margin: 0 auto">
                    <div class="icon">
                      <el-image
                        style="width: 40px; height: 40px"
                        :src="dataList.icon"
                        fit="fill"
                      >
                        <span slot="error">
                          <svg-icon
                            style="font-size:40px"
                            icon-class="err_cloud"
                          ></svg-icon>
                        </span>
                      </el-image>
                    </div>
                    <div class="text">{{ dataList.name }}</div>
                  </div>
                </el-tooltip>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <div
      class="touch-no-receive"
      v-if="!otherParams.isReceive && otherParams.shade"
      v-loading="getLoading"
    >
      <div class="touch-no-cont">
        <div class="touch-no-icon">
          <span
            style="color: #008CD9;text-decoration: underline;cursor:pointer;"
            @click="getReveiveKey('phone')"
            >领取</span
          >
          <span> 以查看所有信息</span>
        </div>
      </div>
    </div>
    <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showDetail">
      <div slot="modalCont">
        <p v-html="recruitStr" style="padding: 20px 0;"></p>
      </div>
      <div slot="submit">
        <div class="grasp_go_pay">
          <span @click="graspGoPay">关闭</span>
        </div>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import Mixin from './mixin'
export default {
  name: 'cloudList',
  components: {
    diaModel
  },
  mixins: [Mixin],
  data() {
    return {
      showDetail: false,
      graspData: {},
      recruitStr: ''
    }
  },
  props: {
    moduleDetail: {
      type: [Array, Object],
      default: () => {
        return []
      }
    },
    otherParams: {
      type: [Array, Object],
      default: () => {
        return {}
      }
    },
    packageId: {
      type: [String, Number, Object],
      default: ''
    }
  },

  mounted() {
    console.log(this.packageId)
  },

  computed: {
    columnList() {
      let res = {}
      let data = this.moduleDetail.columns
      data.forEach(item => {
        res[item.field] = item.name
      })
      return res
    },

    isZs() {
      return this.$store.state.user.accountInfo.versionNum.indexOf('003') > -1
    },

    list() {
      return this.moduleDetail.list
    }
  },
  methods: {
    graspGoPay() {
      this.showDetail = false
    }
  }
}
</script>
<style scoped>
.cloud-main {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.icon-text {
  height: 80px;
  margin-bottom: 20px;
  /*cursor: pointer;*/
}
.icon {
  height: 60px;
  width: 60px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  height: 20px;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-filter {
  filter: blur(1.5px);
}
.touch-no-receive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}

.touch-no-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-text {
  margin: 20px 0;
  font-size: 15px;
  box-sizing: border-box;
}
</style>
