<template>
  <div class="packageKeyList-main">
    <div class="search">
      <div class="input">
        <el-input
          clearable
          size="small"
          v-model="word"
          placeholder="请输入企业、产品名称"
        ></el-input>
      </div>
      <div class="btn">
        <el-button type="primary" size="small" @click="getList">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="table list-table-app">
      <el-table
        :key="tableKey"
        border
        ref="table"
        @sort-change="sortChange"
        :data="list"
        size="small"
        v-loading="loading"
      >
        <el-table-column
          :align="item.name === '公司名称' ? 'left' : 'center'"
          :sortable="item.hasOrder === 0 ? null : 'customer'"
          :show-tooltip-when-overflow="item.name !== '应用名称'"
          :width="item.name === '应用名称' ? '300' : 'auto'"
          v-for="item in columns"
          :prop="item.field"
          :label="item.name"
          :default-sort="{ prop: 'totalRankingIncr', order: 'asc' }"
        >
          <template slot-scope="scope">
            <div class="app-name" v-if="item.name === '应用名称'">
              <template v-if="canViewDetail">
                <div class="app-img">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="scope.row['appImageUrl']"
                    fit="fit"
                  ></el-image>
                </div>
                <div class="app-desc">
                  <p>{{ scope.row[item.field] }}</p>
                  <p>{{ scope.row['developer'] }}</p>
                </div>
              </template>
              <template v-else>
                <p>{{ scope.row[item.field] }}</p>
              </template>
            </div>

            <div v-else-if="item.name === '排名升降'" class="totalRankingIncr">
              <i
                :class="
                  scope.row[item.field] > 0
                    ? 'el-icon-caret-top'
                    : 'el-icon-caret-bottom'
                "
              ></i>
              {{ Math.abs(scope.row[item.field]) }}
            </div>

            <div
              v-else-if="item.name === '公司中文名称'"
              class="c-name"
              @click="goKeyDetail(scope.row, scope.$index)"
            >
              {{ scope.row[item.field] }}
            </div>

            <div v-else>
              {{ scope.row[item.field] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="t-footer page-div page-footer-page page">
      <footer-page
        @handleCurrentChange="handleCurrentChange"
        @goNumPage="goNumPage"
        @changePageSize="changePageSize"
        :loading="loading"
        :pageStatus="pageObj"
      ></footer-page>
    </div>
  </div>
</template>

<script>
import { appInsightList } from '@/api/customerpond'
import { initSortClass } from '@/utils/helper'
import footerPage from '@/components/Footerpage/oace'
import tip from '@/views/insight/tip'
import { mapGetters } from 'vuex'
import { enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'AppList',
  components: {
    footerPage
  },
  data() {
    return {
      pageObj: {
        pageNo: 1,
        pageSize: 20,
        total: 0
      },
      list: [],
      columns: [],
      loading: false,
      word: '',
      sortField: 'totalRankingIncr',
      sortOrder: 'desc',
      tableKey: 0
    }
  },
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    params: function(val) {
      if (val.count > 0) {
        this.pageObj.pageNo = 1
      }
      console.log(val, 4455)
      this.getList(true)
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(['accountInfo']),
    canViewDetail() {
      return !!this.accountInfo.appInsightDetailsOpen
    }
  },
  methods: {
    reset() {
      this.word = ''
      this.sortOrder = null
      this.sortField = null
      this.pageObj.pageNo = 1
      this.pageObj.pageSize = 20
      this.tableKey += 1
      this.getList()
      this.$emit('reset')
    },
    getList(flag) {
      const params = {
        appName: this.word,
        pageNo: this.pageObj.pageNo,
        pageSize: this.pageObj.pageSize,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        type: 1
      }
      const _params = Object.assign({}, params, this.params)
      this.loading = true
      appInsightList(_params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          console.log(res)
          this.columns = res?.data?.columns || []
          this.list = res?.data?.list || []
          this.pageObj.total = res?.data?.page?.total || 0
          if (flag) {
            initSortClass(this.$refs.table?.$el, 1, 'descending')
          }
        }
      })
    },
    handleCurrentChange(val) {
      this.pageObj.pageNo = val
      this.getList()
    },
    goNumPage(val) {
      this.pageObj.pageNo = Number(val)
      this.getList()
    },
    changePageSize(val) {
      this.pageObj.pageSize = val
      const N = Math.ceil(this.pageObj.total / this.pageObj.pageSize)
      if (Number(this.pageObj.pageNo) > N) {
        this.pageObj.pageNo = N
      }
      this.getList()
    },

    sortChange(data) {
      console.log(data)
      const map = {
        ascending: 'asc',
        descending: 'desc'
      }
      const alias = this.columns.filter(i => i.field === data.prop)?.[0]?.alias
      this.sortField = alias ? alias : data.prop
      this.sortOrder = data.order ? map[data.order] : ''
      this.getList()
    },

    goKeyDetail(data, index) {
      if (!this.canViewDetail) {
        this.$store.dispatch('showModal', {
          title: '提示',
          view: tip,
          size: '600px'
        })
        return
      }
      const params = Object.assign({}, this.params, {
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        pageSize: this.pageObj.pageSize,
        pageNo: this.pageObj.pageNo,
        appName: this.word
      })
      sessionStorage.setItem('appInsightParams', JSON.stringify(params))
      enterCompanyDetail(data, this.list, index)
    }
  }
}
</script>

<style scoped lang="scss">
.packageKeyList-main {
  padding: 0 10px;
  box-sizing: border-box;
  .search {
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 10px;
    box-sizing: border-box;
    &::after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      transform: scaleY(0.5);
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .input,
    .btn {
      margin-right: 15px;
    }
    .input {
      width: 350px;
    }
    .filter {
      color: #78d9d1;
      font-size: 14px;
      text-decoration: underline;
      font-weight: 400;
      cursor: pointer;
      display: flex;
    }
  }
  .table {
    .c-name {
      color: #0099cc;
      cursor: pointer;
    }
    .app-name {
      display: flex;
      .app-img {
        height: 44px;
        width: 44px;
      }
      .app-desc {
        flex: 1;
        text-align: left;
        padding-left: 10px;
        & > p:first-child {
          -webkit-tap-highlight-color: transparent;
          outline: none;
          color: #111;
          white-space: pre-wrap;
          word-break: break-word;
          font-weight: 600;
        }
      }
    }
    .totalRankingIncr {
      color: red;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss">
.packageKeyList-main {
  .list-table-app {
    .el-table--enable-row-transition .el-table__body tbody tr td {
      border-right: none;
    }
    .el-table--border .el-table__body tbody tr td {
      border-right: none;
    }
  }
}
</style>
