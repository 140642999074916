const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  buyMeal: state => state.user.buyMeal,
  InvalidLogin: state => state.user.InvalidLogin,
  accountType: state => state.user.accountType,
  buyMealType: state => state.user.buyMealType,
  goBuyMeal: state => state.user.goBuyMeal,
  menus: state => state.user.menus,
  name: state => state.user.name,
  currHsearch: state => state.user.currHsearch,
  registStep: state => state.user.registStep,
  sub: state => state.user.sub,
  isAdmin: state => state.user.isAdmin,
  showExportLoad: state => state.user.showExportLoad,
  showGuide: state => state.user.showGuide,
  userInfo: state => state.user.userInfo,
  accountInfo: state => state.user.accountInfo,
  sysConfig: state => state.user.sysConfig,
  axiosPromiseArr: state => state.settings.axiosPromiseArr
}
export default getters
