var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "new-page-cont clearfix searchTop",
          staticStyle: { "padding-left": "0" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.activeChange },
                  model: {
                    value: _vm.activeTabs,
                    callback: function ($$v) {
                      _vm.activeTabs = $$v
                    },
                    expression: "activeTabs",
                  },
                },
                _vm._l(_vm.tabs, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item.name, name: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pond-tab-right" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "dt-permission",
                    rawName: "v-dt-permission",
                    value: _vm.permissionCreate(),
                    expression: "permissionCreate()",
                  },
                ],
                staticClass: "search-botton",
                staticStyle: { "margin-left": "10px" },
                on: { click: _vm.creatNewCustomer },
              },
              [_vm._v("\n        新建客户\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "dt-permission",
                    rawName: "v-dt-permission",
                    value: _vm.permissionInput(),
                    expression: "permissionInput()",
                  },
                ],
                staticClass: "search-botton",
                staticStyle: { "margin-left": "10px" },
                on: { click: _vm.exportEnterCus },
              },
              [_vm._v("\n        导入客户\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "dt-permission",
                    rawName: "v-dt-permission",
                    value: _vm.permissionView(),
                    expression: "permissionView()",
                  },
                ],
                staticClass: "search-botton",
                staticStyle: { "margin-left": "10px" },
                on: {
                  click: function ($event) {
                    return _vm.searchCompanyInfo()
                  },
                },
              },
              [_vm._v("\n        客户查重\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "dt-permission",
                    rawName: "v-dt-permission",
                    value: "客户管理CRM-客户公海-导出客户",
                    expression: "'客户管理CRM-客户公海-导出客户'",
                  },
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingExport,
                    expression: "loadingExport",
                  },
                ],
                staticClass: "search-botton",
                staticStyle: { "margin-left": "10px" },
                on: {
                  click: function ($event) {
                    return _vm.startExportTask()
                  },
                },
              },
              [_vm._v("\n        导出客户\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "dt-permission",
                    rawName: "v-dt-permission",
                    value: "客户管理CRM-客户公海-导出客户",
                    expression: "'客户管理CRM-客户公海-导出客户'",
                  },
                ],
                staticClass: "search-botton",
                staticStyle: { "margin-left": "10px" },
                on: {
                  click: function ($event) {
                    return _vm.getTaskList()
                  },
                },
              },
              [_vm._v("\n        导出列表\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "dt-permission",
                    rawName: "v-dt-permission",
                    value: _vm.permissionInput(),
                    expression: "permissionInput()",
                  },
                ],
                staticClass: "search-botton",
                staticStyle: { "margin-left": "10px" },
                on: {
                  click: function ($event) {
                    return _vm.openUploadList()
                  },
                },
              },
              [_vm._v("\n        导入列表\n      ")]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "new-page-cont search-input-cla m-t-10",
          staticStyle: { position: "relative" },
          style: { marginBottom: _vm.isWarp ? "0px" : "4px" },
        },
        [
          _c(
            "div",
            {
              ref: "erdBox",
              staticClass: "auto-div",
              staticStyle: { "min-width": "200px", width: "auto" },
            },
            [
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "输入公司名称查询" },
                model: {
                  value: _vm.searchInput,
                  callback: function ($$v) {
                    _vm.searchInput = $$v
                  },
                  expression: "searchInput",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    height: "100%",
                    "line-height": "50px",
                    float: "left",
                    "margin-left": "20px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchCompanyBtn },
                    },
                    [_vm._v("\n          查询\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.resetCompanyBtn },
                    },
                    [_vm._v("\n          重置\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "search-senior",
                  on: { click: _vm.openHighCond },
                },
                [_vm._v("高级筛选")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "filtrate-body",
                  on: { click: _vm.openHighCond },
                },
                [
                  _c("svg-icon", {
                    staticClass: "filtrate",
                    attrs: { "icon-class": "filtrate" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.currentCondValue
                ? _c(
                    "el-input",
                    {
                      staticClass: "input w-320 m-l-26",
                      staticStyle: { "margin-left": "10px" },
                      model: {
                        value: _vm.currentCondValue,
                        callback: function ($$v) {
                          _vm.currentCondValue = $$v
                        },
                        expression: "currentCondValue",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-circle-close" },
                        on: {
                          click: function ($event) {
                            return _vm.clearCurrentPond()
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "showMapBtn",
              staticClass: "showMapBtn",
              style: { visibility: _vm.isWarp ? "hidden" : "" },
            },
            [
              _vm.activeTabs == "2"
                ? _c(
                    "span",
                    { staticStyle: { "margin-right": "10px" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "pic-match",
                          on: { change: _vm.havePicMatch },
                          model: {
                            value: _vm.newAdd,
                            callback: function ($$v) {
                              _vm.newAdd = $$v
                            },
                            expression: "newAdd",
                          },
                        },
                        [_vm._v("7日新增")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { "margin-right": "10px" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "pic-match",
                      on: { change: _vm.havePicMatch },
                      model: {
                        value: _vm.hasKpContact,
                        callback: function ($$v) {
                          _vm.hasKpContact = $$v
                        },
                        expression: "hasKpContact",
                      },
                    },
                    [_vm._v("有KP")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { "margin-right": "10px" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "pic-match",
                      on: { change: _vm.havePicMatch },
                      model: {
                        value: _vm.hasContact,
                        callback: function ($$v) {
                          _vm.hasContact = $$v
                        },
                        expression: "hasContact",
                      },
                    },
                    [_vm._v("有联系方式")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-客户公海-号码能打通",
                      expression: "'客户管理CRM-客户公海-号码能打通'",
                    },
                  ],
                  staticStyle: { "margin-right": "10px" },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "pic-match",
                      on: { change: _vm.havePicMatch },
                      model: {
                        value: _vm.canCall,
                        callback: function ($$v) {
                          _vm.canCall = $$v
                        },
                        expression: "canCall",
                      },
                    },
                    [_vm._v("号码能打通")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { "margin-right": "10px" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "pic-match",
                      on: { change: _vm.havePicMatch },
                      model: {
                        value: _vm.isJob,
                        callback: function ($$v) {
                          _vm.isJob = $$v
                        },
                        expression: "isJob",
                      },
                    },
                    [_vm._v("有招聘信息")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { "margin-right": "10px" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "pic-match",
                      on: { change: _vm.havePicMatch },
                      model: {
                        value: _vm.websiteAccess,
                        callback: function ($$v) {
                          _vm.websiteAccess = $$v
                        },
                        expression: "websiteAccess",
                      },
                    },
                    [_vm._v("网站可访问")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showThisBtn
                ? _c(
                    "span",
                    { staticStyle: { "margin-right": "10px" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "pic-match",
                          on: { change: _vm.havePicMatch },
                          model: {
                            value: _vm.picMatch,
                            callback: function ($$v) {
                              _vm.picMatch = $$v
                            },
                            expression: "picMatch",
                          },
                        },
                        [_vm._v("客户画像匹配")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("span", { on: { click: _vm.showMapFnc } }, [
                _c("i", { staticClass: "el-icon-map-location" }),
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "12px", "margin-left": "5px" },
                  },
                  [_vm._v("客户地图")]
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isWarp,
              expression: "isWarp",
            },
          ],
          staticClass: "new-page-cont search-input-cla",
        },
        [
          _c("div", { staticClass: "new-sha" }, [
            _vm.activeTabs == "2"
              ? _c(
                  "span",
                  { staticStyle: { "margin-right": "10px" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "pic-match",
                        on: { change: _vm.havePicMatch },
                        model: {
                          value: _vm.newAdd,
                          callback: function ($$v) {
                            _vm.newAdd = $$v
                          },
                          expression: "newAdd",
                        },
                      },
                      [_vm._v("7日新增")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              { staticStyle: { "margin-right": "10px" } },
              [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "pic-match",
                    on: { change: _vm.havePicMatch },
                    model: {
                      value: _vm.hasKpContact,
                      callback: function ($$v) {
                        _vm.hasKpContact = $$v
                      },
                      expression: "hasKpContact",
                    },
                  },
                  [_vm._v("有KP")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticStyle: { "margin-right": "10px" } },
              [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "pic-match",
                    on: { change: _vm.havePicMatch },
                    model: {
                      value: _vm.hasContact,
                      callback: function ($$v) {
                        _vm.hasContact = $$v
                      },
                      expression: "hasContact",
                    },
                  },
                  [_vm._v("有联系方式")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "dt-permission",
                    rawName: "v-dt-permission",
                    value: "客户管理CRM-客户公海-号码能打通",
                    expression: "'客户管理CRM-客户公海-号码能打通'",
                  },
                ],
                staticStyle: { "margin-right": "10px" },
              },
              [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "pic-match",
                    on: { change: _vm.havePicMatch },
                    model: {
                      value: _vm.canCall,
                      callback: function ($$v) {
                        _vm.canCall = $$v
                      },
                      expression: "canCall",
                    },
                  },
                  [_vm._v("号码能打通")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticStyle: { "margin-right": "10px" } },
              [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "pic-match",
                    on: { change: _vm.havePicMatch },
                    model: {
                      value: _vm.isJob,
                      callback: function ($$v) {
                        _vm.isJob = $$v
                      },
                      expression: "isJob",
                    },
                  },
                  [_vm._v("有招聘信息")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticStyle: { "margin-right": "10px" } },
              [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "pic-match",
                    on: { change: _vm.havePicMatch },
                    model: {
                      value: _vm.websiteAccess,
                      callback: function ($$v) {
                        _vm.websiteAccess = $$v
                      },
                      expression: "websiteAccess",
                    },
                  },
                  [_vm._v("网站可访问")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.showThisBtn
              ? _c(
                  "span",
                  { staticStyle: { "margin-right": "10px" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "pic-match",
                        on: { change: _vm.havePicMatch },
                        model: {
                          value: _vm.picMatch,
                          callback: function ($$v) {
                            _vm.picMatch = $$v
                          },
                          expression: "picMatch",
                        },
                      },
                      [_vm._v("客户画像匹配")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("span", { on: { click: _vm.showMapFnc } }, [
              _c("i", { staticClass: "el-icon-map-location" }),
              _c(
                "span",
                { staticStyle: { "font-size": "12px", "margin-left": "5px" } },
                [_vm._v("客户地图")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showMap
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showMapLoading,
                  expression: "showMapLoading",
                },
              ],
              staticClass: "m-t-10 map-row",
              attrs: { "element-loading-text": "努力加载中" },
            },
            [
              _vm.mapShow
                ? _c("div", { staticClass: "map-left" }, [
                    _c(
                      "div",
                      { staticClass: "title" },
                      [
                        _vm._v("\n        客户分布\n        "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              "visible-arrow": false,
                              "popper-class": "popper",
                              effect: "dark",
                              content: "重置图表",
                              placement: "right-start",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-refresh",
                              on: { click: _vm.refreshMap },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "map-content tip-map" },
                      [
                        _vm.mapDataNullNum
                          ? _c("div", { staticClass: "null-data" }, [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.mapDataNullNum) + " 家未知 "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("dt-map", {
                          key: _vm.mapRefresh,
                          attrs: {
                            "map-data": _vm.mapData,
                            "excluded-ad-codes": _vm.excludedAdCodes,
                          },
                          on: {
                            lineDataReady: _vm.lineDataReady,
                            refreshMap: _vm.refreshMap,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.mapShow
                ? _c("div", { staticStyle: { width: "10px", height: "100%" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.mapShow
                ? _c("div", { staticClass: "map-right" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("地区排行")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "map-content" },
                      [
                        _c("dt-chart", {
                          key: _vm.chartKey,
                          attrs: {
                            "chart-data": _vm.chartData,
                            loading: _vm.chartLoading,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "exin",
          staticClass: "thread-bag m-t-10",
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c(
            "div",
            [
              _vm.activeTabs != 5
                ? [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "dt-permission",
                            rawName: "v-dt-permission",
                            value: _vm.permissionSend(),
                            expression: "permissionSend()",
                          },
                        ],
                        staticClass: "search-botton",
                        staticStyle: { "margin-left": "10px" },
                        on: { click: _vm.sendUserBtn },
                      },
                      [_vm._v("\n          发送至\n        ")]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-客户公海-批量编辑",
                      expression: "'客户管理CRM-客户公海-批量编辑'",
                    },
                  ],
                  staticClass: "search-botton",
                  staticStyle: { "margin-left": "10px" },
                  on: { click: _vm.customerBatchEdit },
                },
                [_vm._v("\n        批量编辑\n      ")]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "level-tab" },
            [
              _c("span", { staticClass: "sale" }, [_vm._v("销售漏斗")]),
              _vm._v(" "),
              _c("PeSelect", {
                attrs: { optionsList: _vm.levelList },
                on: { handleSelectChange: _vm.handleLevelClick },
              }),
              _vm._v(" "),
              _c("div", { on: { click: _vm.setColumns } }, [
                _c("i", {
                  staticClass: "el-icon-setting n-icon",
                  attrs: { title: "列表设置" },
                }),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "open_table" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "720px",
                top: "25vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("满足下列所有条件")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _vm._l(_vm.savedSearchObj[this.activeTabs], function (item) {
                    return _c(
                      "el-tag",
                      {
                        staticStyle: {
                          cursor: "pointer",
                          "margin-right": "5px",
                        },
                        attrs: { closable: "" },
                        on: {
                          click: function ($event) {
                            return _vm.quickSearch(item.data)
                          },
                          close: function ($event) {
                            return _vm.deleteSearchTag(item.name)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.currentCondition, function (item, index) {
                    return _c(
                      "div",
                      { key: index + 1, staticClass: "select-tag" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            on: {
                              change: function ($event) {
                                return _vm.getChangeField(
                                  item.chooFilterIndex,
                                  index
                                )
                              },
                            },
                            model: {
                              value: item.chooFilterIndex,
                              callback: function ($$v) {
                                _vm.$set(item, "chooFilterIndex", $$v)
                              },
                              expression: "item.chooFilterIndex",
                            },
                          },
                          _vm._l(_vm.filter_field, function (item, inde) {
                            return _c("el-option", {
                              key: inde + 1,
                              attrs: { label: item.filterName, value: inde },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            on: {
                              change: function ($event) {
                                return _vm.getChangeChild(item.optional, index)
                              },
                            },
                            model: {
                              value: item.chooFilterCond,
                              callback: function ($$v) {
                                _vm.$set(item, "chooFilterCond", $$v)
                              },
                              expression: "item.chooFilterCond",
                            },
                          },
                          _vm._l(item.chooFilterArr, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        item.chooFilterCond == "选择" &&
                        item.chooFilterEng == "tag"
                          ? _c("el-cascader", {
                              ref: "elCascaderTag",
                              refInFor: true,
                              staticClass:
                                "filter-mult-coll select-industry-one",
                              attrs: {
                                options: _vm.selectMoreSec,
                                props: _vm.props,
                                getCheckedNodes: true,
                                placeholder: item.chooFilterTip,
                                "show-all-levels": false,
                                "collapse-tags": "",
                                clearable: "",
                                filterable: "",
                              },
                              on: { change: _vm.selectTagSecond },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ node, data }) {
                                      return [
                                        _c("span", [_vm._v(_vm._s(data.name))]),
                                        _vm._v("F\n              "),
                                        !node.isLeaf
                                          ? _c("span", [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(data.children.length) +
                                                  ")"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.chooFilterInput,
                                callback: function ($$v) {
                                  _vm.$set(item, "chooFilterInput", $$v)
                                },
                                expression: "item.chooFilterInput",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.chooFilterCond == "选择" &&
                        item.chooFilterEng !== "tag" &&
                        item.chooFilterEng !== "remark"
                          ? _c(
                              "el-select",
                              {
                                staticClass:
                                  "filter-mult-coll select-industry-one",
                                attrs: {
                                  multiple: "",
                                  "collapse-tags": "",
                                  filterable: "",
                                  "reserve-keyword": "",
                                  placeholder: item.chooFilterTip,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.secondChoose(
                                      item.chooFilterInput
                                    )
                                  },
                                  clickSelectNum: function ($event) {
                                    return _vm.clickSelectNum(_vm.val)
                                  },
                                },
                                model: {
                                  value: item.chooFilterInput,
                                  callback: function ($$v) {
                                    _vm.$set(item, "chooFilterInput", $$v)
                                  },
                                  expression: "item.chooFilterInput",
                                },
                              },
                              _vm._l(item.chooseFilterChoo, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.name,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.chooFilterCond === "选择" &&
                        item.chooFilterEng === "remark"
                          ? _c(
                              "el-select",
                              {
                                staticClass:
                                  "filter-mult-coll select-industry-one",
                                attrs: {
                                  multiple: "",
                                  filterable: "",
                                  remote: "",
                                  "collapse-tags": "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入备注",
                                  "remote-method": _vm.searchRemark,
                                  loading: _vm.loading,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.secondChoose(
                                      item.chooFilterInput
                                    )
                                  },
                                  clickSelectNum: function ($event) {
                                    return _vm.clickSelectNum(_vm.val)
                                  },
                                },
                                model: {
                                  value: item.chooFilterInput,
                                  callback: function ($$v) {
                                    _vm.$set(item, "chooFilterInput", $$v)
                                  },
                                  expression: "item.chooFilterInput",
                                },
                              },
                              _vm._l(_vm.remarkList, function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.chooFilterCond == "是否"
                          ? _c(
                              "el-select",
                              {
                                staticClass: "filter-mult-coll",
                                attrs: { placeholder: item.chooFilterTip },
                                on: {
                                  change: function ($event) {
                                    return _vm.secondChoose(item.optional)
                                  },
                                },
                                model: {
                                  value: item.chooFilterInput,
                                  callback: function ($$v) {
                                    _vm.$set(item, "chooFilterInput", $$v)
                                  },
                                  expression: "item.chooFilterInput",
                                },
                              },
                              _vm._l(item.chooseFilterChoo, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.name,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.chooFilterCond !== "是否" &&
                        item.chooFilterCond !== "选择" &&
                        (item.chooFilterCond + "").indexOf("等于") == -1
                          ? _c("el-input", {
                              staticClass: "search-input filter-mult-coll",
                              attrs: { placeholder: item.chooFilterTip },
                              model: {
                                value: item.chooFilterInput,
                                callback: function ($$v) {
                                  _vm.$set(item, "chooFilterInput", $$v)
                                },
                                expression: "item.chooFilterInput",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        (item.chooFilterCond + "").indexOf("等于") != -1
                          ? _c("el-date-picker", {
                              staticClass: "filter-mult-coll",
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: item.chooFilterTip,
                              },
                              model: {
                                value: item.chooFilterInput,
                                callback: function ($$v) {
                                  _vm.$set(item, "chooFilterInput", $$v)
                                },
                                expression: "item.chooFilterInput",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("svg-icon", {
                              staticClass: "filtrate",
                              attrs: { "icon-class": "delete" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteTag(index)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  (
                    _vm.currentCondition && _vm.currentCondition.length
                      ? _vm.currentCondition.length < 9
                      : true
                  )
                    ? _c("div", {
                        staticClass: "add_tag_icon",
                        on: {
                          click: function ($event) {
                            return _vm.addTag()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "footer",
                  staticStyle: {
                    width: "100%",
                    height: "50px",
                    "padding-left": "50px",
                  },
                },
                [
                  true
                    ? _c(
                        "div",
                        {
                          staticClass: "footer-right-cont",
                          attrs: { slot: "footerRight" },
                          slot: "footerRight",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "save_tag_cont select-tag" },
                            [
                              _c("span", { staticClass: "save_tag_label" }, [
                                _vm._v("是否保存常用查询"),
                              ]),
                              _vm._v(" "),
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#41CABF",
                                  "inactive-color": "#909399",
                                },
                                model: {
                                  value: _vm.chooseSave,
                                  callback: function ($$v) {
                                    _vm.chooseSave = $$v
                                  },
                                  expression: "chooseSave",
                                },
                              }),
                              _vm._v(" "),
                              _vm.chooseSave
                                ? _c("el-input", {
                                    staticClass: "search-input",
                                    class: _vm.tagNameErrorMsg
                                      ? "border-color"
                                      : "",
                                    staticStyle: { width: "250px" },
                                    attrs: { placeholder: "输入标签名称" },
                                    model: {
                                      value: _vm.saveTagName,
                                      callback: function ($$v) {
                                        _vm.saveTagName = $$v
                                      },
                                      expression: "saveTagName",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { float: "right", "margin-top": "15px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleClose()
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.proFclick,
                            type: "primary",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitPondFilter()
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.showGetListLoading,
              expression: "showGetListLoading",
            },
            {
              name: "dt-permission",
              rawName: "v-dt-permission",
              value: _vm.viewCustomerPermission,
              expression: "viewCustomerPermission",
            },
          ],
          staticClass: "line-body h-body",
          staticStyle: {
            "margin-top": "10px",
            "padding-bottom": "0",
            "box-sizing": "border-box",
          },
        },
        [
          _c("oace-key", {
            ref: "oaceKeyTable",
            attrs: {
              companyList: _vm.companyList,
              companyColunes: _vm.companyColunes,
              followList: _vm.followList,
              activeTabs: _vm.activeTabs,
              resModule: _vm.resModule,
              "dep-id": _vm.depId,
              chooseUserId: _vm.chooseUserId,
            },
            on: {
              sortList: _vm.sortList,
              openMicro: _vm.openMicro,
              editCusPond: _vm.editCusPond,
              delCusPond: _vm.delCusPond,
              openCusPondDetail: _vm.openCusPondDetail,
              openContact: _vm.openContact,
              openProfile: _vm.openProfile,
              openPondContacts: _vm.openPondContacts,
              handleSelectionChange: _vm.handleSelectionChange,
              chooseSelectFollow: _vm.chooseSelectFollow,
              filterTable: _vm.filterTable,
              filterDep: _vm.filterDep,
              handleEnterpriseOpen: _vm.handleEnterpriseOpen,
            },
          }),
          _vm._v(" "),
          _vm.pageStatus.total
            ? _c("div", { staticStyle: { height: "40px" } }, [
                _c(
                  "div",
                  { staticClass: "page-div" },
                  [
                    _c("footer-page", {
                      attrs: { pageStatus: _vm.pageStatus },
                      on: {
                        handleCurrentChange: _vm.handleCurrentChange,
                        goNumPage: _vm.goNumPage,
                        changePageSize: _vm.changePageSize,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "trans_send",
          attrs: { dialogStatus: _vm.sendStatus, btnLoading: _vm.btnLoading },
          on: { submitContBtn: _vm.transInfoToUserBtn },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "padding-left": "30px", margin: "20px 0 40px" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "120px" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [_vm._v("发送给")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            "default-first-option": "",
                            filterable: "",
                            placeholder: "选择线索接收人",
                          },
                          model: {
                            value: _vm.currentTransUserId,
                            callback: function ($$v) {
                              _vm.currentTransUserId = $$v
                            },
                            expression: "currentTransUserId",
                          },
                        },
                        _vm._l(_vm.allSendUser, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  (
                    _vm.currentTransUserId == "-1" && _vm.activeTabs != "4"
                      ? true
                      : false
                  )
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "退回理由", required: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择退回理由" },
                              model: {
                                value: _vm.returnType,
                                callback: function ($$v) {
                                  _vm.returnType = $$v
                                },
                                expression: "returnType",
                              },
                            },
                            _vm._l(_vm.allMessage, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "micro",
          attrs: {
            dialogStatus: _vm.microStatus,
            showHeaderBorder: false,
            contentStyle: "padding: 0",
          },
          on: { submitContBtn: _vm.confirmMaterial },
        },
        [
          _c(
            "div",
            { attrs: { slot: "modalCont" }, slot: "modalCont" },
            [
              _c(
                "el-scrollbar",
                {
                  staticClass: "dt-dropdown-dt",
                  staticStyle: { height: "300px", width: "100%" },
                },
                [
                  _c(
                    "ul",
                    {
                      staticClass: "list-material",
                      staticStyle: { "list-style-type": "none" },
                    },
                    _vm._l(_vm.materialList, function (item, key) {
                      return _c(
                        "li",
                        { key: key },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: item.id },
                              model: {
                                value: _vm.microStatus.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.microStatus, "value", $$v)
                                },
                                expression: "microStatus.value",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  item.formName.length > 30
                                    ? `${item.formName.slice(0, 30)}...`
                                    : item.formName
                                ) + "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#0099cc",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.prevwLandPage(item)
                                },
                              },
                            },
                            [_vm._v("预览")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.pageStatus1.total
                    ? _c("div", { staticStyle: { height: "40px" } }, [
                        _c("div", { staticClass: "page-div" }, [
                          _c(
                            "div",
                            [
                              _c("footer-page", {
                                attrs: {
                                  pageStatus: _vm.pageStatus1,
                                  pageSizes: [10],
                                },
                                on: {
                                  handleCurrentChange:
                                    _vm.handleCurrentChange_land,
                                  goNumPage: _vm.goNumPage_land,
                                  changePageSize: _vm.changePageSize_land,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "link",
          attrs: {
            dialogStatus: _vm.linkStatus,
            contentStyle: "padding-top: 30px",
          },
          on: { submitContBtn: _vm.closeMaterial },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.linkLoading,
                  expression: "linkLoading",
                },
              ],
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.linkLoading,
                      expression: "linkLoading",
                    },
                  ],
                  staticClass: "qr-code-cont",
                },
                [
                  _c("div", { ref: "qrCodeDiv", attrs: { id: "qrCode" } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.linkLoading,
                          expression: "!linkLoading",
                        },
                      ],
                      staticStyle: { "margin-top": "10px" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "qr-code-txt qr-code-ac",
                          staticStyle: { "font-size": "12px" },
                          on: { click: _vm.handleCopy },
                        },
                        [_vm._v("复制链接")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "qr-code-txt qr-code-ac",
                          staticStyle: { "font-size": "12px" },
                          on: { click: _vm.handleDown },
                        },
                        [_vm._v("下载二维码")]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                ref: "newImgCode",
                staticStyle: { display: "none" },
                attrs: { id: "newImg" },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "newCustomer",
          staticClass: "pond-form-input",
          attrs: { dialogStatus: _vm.customerStatus },
          on: { submitContBtn: _vm.submitCreatCusBtn },
        },
        [
          _c(
            "div",
            {
              staticStyle: { height: "auto", "padding-top": "20px" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { "margin-top": "30px" },
                  attrs: { "label-width": "auto" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "负责人" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择负责人" },
                                  model: {
                                    value: _vm.creatLeader,
                                    callback: function ($$v) {
                                      _vm.creatLeader = $$v
                                    },
                                    expression: "creatLeader",
                                  },
                                },
                                _vm._l(_vm.allLeaders, function (item, index) {
                                  return _c("el-option", {
                                    key: index + "a",
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.creatLeader == "-1" &&
                          _vm.activeTabs != 2 &&
                          this.customerStatus.dialogTitle != "新建客户"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "退回理由" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请选择退回理由" },
                                      model: {
                                        value: _vm.returnType,
                                        callback: function ($$v) {
                                          _vm.returnType = $$v
                                        },
                                        expression: "returnType",
                                      },
                                    },
                                    _vm._l(_vm.allMessage, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "pond-form-had" }, [
                                    _vm._v("*"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: {
                                  required: "",
                                  placeholder: "请输入公司名称",
                                  disabled: _vm.disabled(),
                                },
                                model: {
                                  value: _vm.creatName,
                                  callback: function ($$v) {
                                    _vm.creatName = $$v
                                  },
                                  expression: "creatName",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "-5px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _c("span", [_vm._v("公司名称")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "dt-permission",
                                      rawName: "v-dt-permission",
                                      value: "客户管理CRM-客户公海-客户查重",
                                      expression:
                                        "'客户管理CRM-客户公海-客户查重'",
                                    },
                                  ],
                                  class: !_vm.showContact ? "set-position" : "",
                                  staticStyle: {
                                    color: "#41CABF",
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: "105px",
                                    top: "0",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.searchCompanyInfo(
                                        _vm.creatName
                                      )
                                    },
                                  },
                                },
                                [_vm._v("查重")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "来源" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择客户来源" },
                                  model: {
                                    value: _vm.creatSource,
                                    callback: function ($$v) {
                                      _vm.creatSource = $$v
                                    },
                                    expression: "creatSource",
                                  },
                                },
                                _vm._l(_vm.allSource, function (item, index) {
                                  return _c("el-option", {
                                    key: index + "c",
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "线索等级" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "未知" },
                                  model: {
                                    value: _vm.creatClue,
                                    callback: function ($$v) {
                                      _vm.creatClue = $$v
                                    },
                                    expression: "creatClue",
                                  },
                                },
                                _vm._l(_vm.allClue, function (item, index) {
                                  return _c("el-option", {
                                    key: index + "e",
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户等级" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择客户等级" },
                                  model: {
                                    value: _vm.creatLevel,
                                    callback: function ($$v) {
                                      _vm.creatLevel = $$v
                                    },
                                    expression: "creatLevel",
                                  },
                                },
                                _vm._l(_vm.allLevel, function (item, index) {
                                  return _c("el-option", {
                                    key: index + "b",
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择客户状态" },
                                  model: {
                                    value: _vm.creatStatus,
                                    callback: function ($$v) {
                                      _vm.creatStatus = $$v
                                    },
                                    expression: "creatStatus",
                                  },
                                },
                                _vm._l(_vm.allStatus, function (item, index) {
                                  return _c("el-option", {
                                    key: index + "d",
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入备注",
                                  maxlength: "100",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.creatRemark,
                                  callback: function ($$v) {
                                    _vm.creatRemark = $$v
                                  },
                                  expression: "creatRemark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { span: 12 },
                        },
                        [
                          _vm.showContact
                            ? _c(
                                "span",
                                {
                                  staticClass: "view show_collent_tip",
                                  staticStyle: {
                                    height: "100%",
                                    left: "70px",
                                    position: "absolute",
                                    transform: "translateY(-25px)",
                                  },
                                  attrs: { id: "driver_show_collect" },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: { change: _vm.checkContact },
                                      model: {
                                        value: _vm.haveContact,
                                        callback: function ($$v) {
                                          _vm.haveContact = $$v
                                        },
                                        expression: "haveContact",
                                      },
                                    },
                                    [_vm._v("联系人信息")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showContact
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "margin-left": "-5px" },
                                      attrs: { slot: "label" },
                                      slot: "label",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("姓名\n              "),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入姓名",
                                      disabled: !_vm.haveContact,
                                    },
                                    model: {
                                      value: _vm.contact,
                                      callback: function ($$v) {
                                        _vm.contact = $$v
                                      },
                                      expression: "contact",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showContact
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "部门" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入部门",
                                      disabled: !_vm.haveContact,
                                    },
                                    model: {
                                      value: _vm.department,
                                      callback: function ($$v) {
                                        _vm.department = $$v
                                      },
                                      expression: "department",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showContact
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "职位" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入职位",
                                      disabled: !_vm.haveContact,
                                    },
                                    model: {
                                      value: _vm.position,
                                      callback: function ($$v) {
                                        _vm.position = $$v
                                      },
                                      expression: "position",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showContact
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "电话" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入电话",
                                      disabled: !_vm.haveContact,
                                    },
                                    model: {
                                      value: _vm.mobile,
                                      callback: function ($$v) {
                                        _vm.mobile = $$v
                                      },
                                      expression: "mobile",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showContact
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "微信" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入微信",
                                      disabled: !_vm.haveContact,
                                    },
                                    model: {
                                      value: _vm.wechat,
                                      callback: function ($$v) {
                                        _vm.wechat = $$v
                                      },
                                      expression: "wechat",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showContact
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "邮箱" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入邮箱",
                                      disabled: !_vm.haveContact,
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showContact
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "备注" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入备注",
                                      disabled: !_vm.haveContact,
                                    },
                                    model: {
                                      value: _vm.remark,
                                      callback: function ($$v) {
                                        _vm.remark = $$v
                                      },
                                      expression: "remark",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showUpload
        ? _c(
            "dia-model",
            {
              ref: "uploadUserData",
              attrs: {
                dialogStatus: _vm.uploadDataModel,
                btnLoading: _vm.uploadLoading,
              },
              on: { submitContBtn: _vm.submitUploadBtn },
            },
            [
              _c("div", { attrs: { slot: "modalCont" }, slot: "modalCont" }, [
                _c(
                  "div",
                  { staticClass: "upload_cont_out" },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "uploadListInput",
                        staticClass: "upload-demo",
                        attrs: {
                          drag: "",
                          "on-change": _vm.uploadFileChange,
                          "before-upload": _vm.beforeUpload,
                          headers: _vm.uploadHeaders,
                          action: _vm.actionUrl,
                          accept: ".xlsx",
                          "file-list": _vm.fileList,
                          limit: 2,
                          "auto-upload": false,
                          "on-success": _vm.uploadSuccess,
                          "on-error": _vm.uploadError,
                          multiple: "",
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-upload" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "el-upload__text" }, [
                          _vm._v(
                            "\n            将文件拖到此处，或\n            "
                          ),
                          _c("em", [_vm._v("点击导入")]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            staticStyle: { color: "red" },
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [
                            _vm._v(
                              "\n            仅支持导入xlsx文件\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "down_load_demo" }, [
                      _c("p", { staticClass: "down_load_title" }, [
                        _vm._v("下载模板"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "down_load_xlsx",
                          on: {
                            click: function ($event) {
                              return _vm.goDownFile("xlsx")
                            },
                          },
                        },
                        [_vm._v("\n            xlsx文件模板\n          ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "searchCompanyRep",
          attrs: { dialogStatus: _vm.searchCompanyModel },
          on: { submitContBtn: _vm.submitUploadBtn },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "min-height": "300px",
                "padding-top": "20px",
                "padding-bottom": "40px",
              },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c(
                "div",
                {
                  staticClass: "global-input-btn auto-complate-cont",
                  staticStyle: { "padding-left": "20px" },
                },
                [
                  _c("el-input", {
                    attrs: { autofocus: "", placeholder: "输入公司名称查询" },
                    model: {
                      value: _vm.searchCompanyName,
                      callback: function ($$v) {
                        _vm.searchCompanyName = $$v
                      },
                      expression: "searchCompanyName",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "global-search-btn",
                    on: { click: _vm.searchCompanyList },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: _vm.permissionSend(),
                      expression: "permissionSend()",
                    },
                  ],
                  staticClass: "search-botton",
                  on: { click: _vm.sendUserBtnRepead },
                },
                [_vm._v("\n        发送至\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticClass: "seachRepead",
                  staticStyle: { width: "100%", "margin-top": "50px" },
                  attrs: {
                    data: _vm.tableData,
                    "max-height": "400",
                    "empty-text": "查询无结果",
                  },
                  on: { select: _vm.userChoose },
                },
                [
                  _c("el-table-column", {
                    attrs: { selectable: _vm.selectable, type: "selection" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "companyName",
                      label: "公司名称",
                      "show-overflow-tooltip": "",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdAt",
                      align: "center",
                      label: "创建时间",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "followName",
                      align: "center",
                      label: "负责人",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.tableData.length != 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#7f7f7f",
                        margin: "40px 20px 20px 20px",
                      },
                    },
                    [_vm._v("\n        注：查重结果最多显示10条\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "center" },
                },
                [
                  _vm.tableData.length == 0
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "dt-permission",
                              rawName: "v-dt-permission",
                              value: "客户管理CRM-客户公海-新建客户",
                              expression: "'客户管理CRM-客户公海-新建客户'",
                            },
                          ],
                          staticClass: "search-company-botton",
                          on: { click: _vm.addNewCompany },
                        },
                        [_vm._v("\n          新建客户\n        ")]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "deleteMark",
          attrs: { dialogStatus: _vm.dialogDelStatus },
          on: { submitContBtn: _vm.submitDelBtn },
        },
        [
          _c(
            "div",
            {
              staticStyle: { margin: "20px 0" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [_vm._v("确认删除 ？")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "我是标题",
            visible: _vm.openDrawer,
            size: "40%",
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openDrawer = $event
            },
          },
        },
        [
          _vm.openDrawer
            ? _c("pond-detail", {
                attrs: {
                  source: "costomerpond",
                  customerInfoMap: _vm.currentCheckRow,
                  id: _vm.rowComId,
                  "company-uuid": _vm.companyUuid,
                  "package-id": _vm.packageId,
                  rowCont: _vm.currentCheckRow,
                  svg: "",
                  majorId: _vm.majorId,
                  isActiveTab1: _vm.activeTabs,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.openDrawer1
        ? _c(
            "el-drawer",
            {
              staticStyle: { "overflow-y": "scroll" },
              attrs: {
                title: "联系人信息",
                visible: _vm.openDrawer1,
                "before-close": _vm.closeContact,
                size: "1600px",
                "with-header": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.openDrawer1 = $event
                },
              },
            },
            [
              _c("pond-detail1", {
                attrs: {
                  isActiveTab: _vm.activeTabs,
                  rowCont: _vm.currentCheckRow,
                  companyUuid: _vm.companyUuid,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.openContactsDrawer,
            isActiveTab: _vm.activeTabs,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openContactsDrawer = $event
            },
          },
        },
        [_c("pond-contacts")],
        1
      ),
      _vm._v(" "),
      _c("land-preview", {
        ref: "userPrevw",
        attrs: { currentId: _vm.currentId, formHtml: _vm.formHtml },
      }),
      _vm._v(" "),
      _vm.userProState
        ? _c("user-profile", {
            ref: "userPro",
            attrs: { customerInfo: _vm.customerInfo, title: _vm.selectTitle },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }