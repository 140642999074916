var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-main" },
    [
      _c("div", { staticClass: "btn-list" }, [
        _c(
          "div",
          { staticClass: "input-row" },
          [
            _c("el-input", {
              staticStyle: { width: "200px" },
              attrs: { size: "mini", placeholder: "输入客户名称查询" },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-row" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.searchContact },
                  },
                  [_vm._v("查询")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.reset } },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "g-search", on: { click: _vm.searchPlus } },
              [
                _vm._v("\n        高级筛选"),
                _c("svg-icon", {
                  staticClass: "filtrate",
                  attrs: { "icon-class": "filtrate" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCondition,
                    expression: "showCondition",
                  },
                ],
                staticClass: "condition",
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "condition-icon",
                    on: { click: _vm.removeSearch },
                  },
                  [_c("i", { staticClass: "el-icon-circle-close" })]
                ),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.showCondition,
                    callback: function ($$v) {
                      _vm.showCondition = $$v
                    },
                    expression: "showCondition",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.contractTotalMoney,
                    expression: "contractTotalMoney",
                  },
                ],
                staticStyle: { "margin-right": "15px", "font-size": "12px" },
              },
              [
                _vm._v(
                  "合同合计金额：" +
                    _vm._s(_vm.contractTotalMoney.toLocaleString() + "元")
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.contractArrestMoney,
                    expression: "contractArrestMoney",
                  },
                ],
                staticStyle: { "margin-right": "15px", "font-size": "12px" },
              },
              [
                _vm._v("欠款金额："),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v(
                    _vm._s(_vm.contractArrestMoney.toLocaleString() + "元")
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("i", {
              staticClass: "el-icon-setting n-icon",
              attrs: { title: "列表设置" },
              on: { click: _vm.setColumns },
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "dt-permission",
                    rawName: "v-dt-permission",
                    value: "客户管理CRM-合同管理-新建合同",
                    expression: "'客户管理CRM-合同管理-新建合同'",
                  },
                ],
                attrs: { size: "mini", type: "primary" },
                on: { click: _vm.contractCreate },
              },
              [_vm._v("\n        新建合同\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "table",
        },
        [
          _c(
            "el-table",
            {
              ref: "table",
              staticClass: "table-dt",
              staticStyle: {
                width: "100%",
                height: "100%",
                "font-size": "12px",
                "background-color": "#fff",
              },
              attrs: {
                height: _vm.h,
                data: _vm.tableData,
                border: "",
                stripe: "",
              },
              on: { "sort-change": _vm.sortChange },
            },
            _vm._l(_vm.columnList, function (item) {
              return _c("el-table-column", {
                attrs: {
                  width: item.field === "companyName" ? 220 : 180,
                  "show-tooltip-when-overflow": "",
                  fixed:
                    item.field === "companyName"
                      ? "left"
                      : item.field === "setting"
                      ? "right"
                      : false,
                  prop: item.field,
                  align: item.field === "companyName" ? "left" : "center",
                  sortable: item.type === "order" ? "custom" : false,
                  label: item.name,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          item.type === "select"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-dropdown",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        trigger: "click",
                                      },
                                      on: {
                                        command: (val) =>
                                          _vm.filterFunc(val, item),
                                      },
                                    },
                                    [
                                      item.field === "followName"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.userId
                                                  ? "active"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c("dt-drop-down", {
                                                  attrs: {
                                                    "source-list":
                                                      _vm.followUserList,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (row) {
                                                          return [
                                                            _c(
                                                              "el-scrollbar",
                                                              {
                                                                staticClass:
                                                                  "dt-dropdown-dt",
                                                              },
                                                              [
                                                                !row.filterList
                                                                  .length
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "dt-drop-down-no-data",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          无数据\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm._l(
                                                                  row.filterList,
                                                                  function (
                                                                    _item
                                                                  ) {
                                                                    return _c(
                                                                      "el-dropdown-item",
                                                                      {
                                                                        class:
                                                                          _vm.userId ===
                                                                          _item.id
                                                                            ? "active"
                                                                            : "",
                                                                        attrs: {
                                                                          command:
                                                                            _item.id,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            domProps:
                                                                              {
                                                                                innerHTML:
                                                                                  _vm._s(
                                                                                    _item.htmlEl
                                                                                  ),
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : item.field === "signName"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.signName
                                                  ? "active"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c("dt-drop-down", {
                                                  attrs: {
                                                    "source-list":
                                                      _vm.followUserList,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (row) {
                                                          return [
                                                            _c(
                                                              "el-scrollbar",
                                                              {
                                                                staticClass:
                                                                  "dt-dropdown-dt",
                                                              },
                                                              [
                                                                !row.filterList
                                                                  .length
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "dt-drop-down-no-data",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          无数据\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm._l(
                                                                  row.filterList,
                                                                  function (
                                                                    _item
                                                                  ) {
                                                                    return _c(
                                                                      "el-dropdown-item",
                                                                      {
                                                                        class:
                                                                          _vm.signName ===
                                                                          _item.id
                                                                            ? "active"
                                                                            : "",
                                                                        attrs: {
                                                                          command:
                                                                            _item.id,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            domProps:
                                                                              {
                                                                                innerHTML:
                                                                                  _vm._s(
                                                                                    _item.htmlEl
                                                                                  ),
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : item.field === "contractTypeDesc"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.contractType
                                                  ? "active"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-scrollbar",
                                                  {
                                                    staticClass:
                                                      "dt-dropdown-dt",
                                                  },
                                                  _vm._l(
                                                    _vm.contractTypeList,
                                                    function (_item) {
                                                      return _c(
                                                        "el-dropdown-item",
                                                        {
                                                          class:
                                                            _vm.contractType ===
                                                            _item.value
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            command:
                                                              _item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_item.name)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : item.field === "productTypeDesc"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.productType
                                                  ? "active"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-scrollbar",
                                                  {
                                                    staticClass:
                                                      "dt-dropdown-dt",
                                                  },
                                                  _vm._l(
                                                    _vm.productTypeList,
                                                    function (_item) {
                                                      return _c(
                                                        "el-dropdown-item",
                                                        {
                                                          class:
                                                            _vm.productType ===
                                                            _item.value
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            command:
                                                              _item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_item.name)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : item.field === "signChannelDesc"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.signChannel
                                                  ? "active"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-scrollbar",
                                                  {
                                                    staticClass:
                                                      "dt-dropdown-dt",
                                                  },
                                                  _vm._l(
                                                    _vm.signChannelList,
                                                    function (_item) {
                                                      return _c(
                                                        "el-dropdown-item",
                                                        {
                                                          class:
                                                            _vm.signChannel ===
                                                            _item.value
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            command:
                                                              _item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_item.name)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : item.field === "cusIndustryDesc"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.cusIndustry
                                                  ? "active"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-scrollbar",
                                                  {
                                                    staticClass:
                                                      "dt-dropdown-dt",
                                                  },
                                                  _vm._l(
                                                    _vm.cusIndustryList,
                                                    function (_item) {
                                                      return _c(
                                                        "el-dropdown-item",
                                                        {
                                                          class:
                                                            _vm.cusIndustry ===
                                                            _item.value
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            command:
                                                              _item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_item.name)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : item.field ===
                                          "contractOriginStatusDesc"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.contractOriginStatus
                                                  ? "active"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-scrollbar",
                                                  {
                                                    staticClass:
                                                      "dt-dropdown-dt",
                                                  },
                                                  _vm._l(
                                                    _vm.contractOriginStatusList,
                                                    function (_item) {
                                                      return _c(
                                                        "el-dropdown-item",
                                                        {
                                                          class:
                                                            _vm.contractOriginStatus ===
                                                            _item.value
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            command:
                                                              _item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_item.name)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : item.field === "invoiceStatusDesc"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.invoiceStatus
                                                  ? "active"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-scrollbar",
                                                  {
                                                    staticClass:
                                                      "dt-dropdown-dt",
                                                  },
                                                  _vm._l(
                                                    _vm.invoiceStatusList,
                                                    function (_item) {
                                                      return _c(
                                                        "el-dropdown-item",
                                                        {
                                                          class:
                                                            _vm.invoiceStatus ===
                                                            _item.value
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            command:
                                                              _item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_item.name)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _c("span", [
                                item.field === "companyName"
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "padding-left": "22px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.name) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _c("span", [_vm._v(_vm._s(item.name))]),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.field === "contractType"
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("formatContractType")(
                                        scope.row.contractType
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : item.field === "setting"
                            ? _c("span", { staticClass: "set" }, [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "dt-permission",
                                        rawName: "v-dt-permission",
                                        value: "客户管理CRM-合同管理-编辑",
                                        expression:
                                          "'客户管理CRM-合同管理-编辑'",
                                      },
                                    ],
                                    staticClass: "set-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editContract(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("\n              查看\n            ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "dt-permission",
                                        rawName: "v-dt-permission",
                                        value: "客户管理CRM-合同管理-编辑",
                                        expression:
                                          "'客户管理CRM-合同管理-编辑'",
                                      },
                                    ],
                                  },
                                  [_vm._v(" | ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "dt-permission",
                                        rawName: "v-dt-permission",
                                        value:
                                          "客户管理CRM-收/退款管理-查看收款单",
                                        expression:
                                          "'客户管理CRM-收/退款管理-查看收款单'",
                                      },
                                    ],
                                    staticClass: "set-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.payBack(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              收/退款\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "dt-permission",
                                        rawName: "v-dt-permission",
                                        value:
                                          "客户管理CRM-收/退款管理-查看收款单",
                                        expression:
                                          "'客户管理CRM-收/退款管理-查看收款单'",
                                      },
                                    ],
                                  },
                                  [_vm._v("\n              |\n            ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "dt-permission",
                                        rawName: "v-dt-permission",
                                        value: "客户管理CRM-合同管理-删除",
                                        expression:
                                          "'客户管理CRM-合同管理-删除'",
                                      },
                                    ],
                                    class: scope.row.isEdit
                                      ? "set-btn"
                                      : "lock",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteChance(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("\n              删除\n            ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "dt-permission",
                                        rawName: "v-dt-permission",
                                        value: "客户管理CRM-合同管理-删除",
                                        expression:
                                          "'客户管理CRM-合同管理-删除'",
                                      },
                                    ],
                                  },
                                  [_vm._v(" | ")]
                                ),
                              ])
                            : item.field === "companyName"
                            ? _c("span", [
                                _c("span", [
                                  _c("span", { staticClass: "lock-dt" }, [
                                    !scope.row.isEdit
                                      ? _c("i", { staticClass: "el-icon-lock" })
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "dt-permission",
                                          rawName: "v-dt-permission",
                                          value:
                                            "客户管理CRM-合同管理-查看附件",
                                          expression:
                                            "'客户管理CRM-合同管理-查看附件'",
                                        },
                                      ],
                                      staticClass: "lock-dt",
                                    },
                                    [
                                      scope.row.accessory &&
                                      scope.row.accessory !== "[]"
                                        ? _c("i", {
                                            staticClass: "el-icon-link",
                                            attrs: { title: "附件" },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      class: _vm.isLink ? "c-name" : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.jump(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(scope.row[item.field]) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : item.field === "arrearsAmount"
                            ? _c("span", { staticStyle: { color: "#f00" } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.transferNum(scope.row[item.field])
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _c(
                                "span",
                                [
                                  item.field == "contractAmount" ||
                                  item.field == "returnAmount"
                                    ? [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.transferNum(
                                                scope.row[item.field]
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    : _c("span", [
                                        _vm._v(_vm._s(scope.row[item.field])),
                                      ]),
                                ],
                                2
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page page-div page-1" },
        [
          _c("footer-page", {
            attrs: { pageStatus: _vm.pageStatus },
            on: {
              handleCurrentChange: _vm.handleCurrentChange,
              goNumPage: _vm.goNumPage,
              changePageSize: _vm.changePageSize,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "收退/款管理",
            size: "60%",
            "destroy-on-close": "",
            visible: _vm.showPay,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPay = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("pay-manage", {
                attrs: {
                  "pay-data": _vm.currentContract,
                  "follow-user-list": _vm.followUserList,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.showContract,
            size: "50%",
            "before-close": _vm.handleDrawClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showContract = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "first" }, [
              _c("div", { staticClass: "layer" }, [
                _c("span", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v("查看合同:" + _vm._s(_vm.editData.companyName)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "lock-dt" }, [
                  !_vm.editData.isEdit
                    ? _c("i", { staticClass: "el-icon-lock" })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "14px",
                      position: "absolute",
                      left: "20px",
                      top: "52px",
                    },
                  },
                  [
                    _vm._v(
                      "起止时间" +
                        _vm._s(_vm.editData.startDate) +
                        "至" +
                        _vm._s(_vm.editData.expireDate)
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-right-contact" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "dt-permission",
                          rawName: "v-dt-permission",
                          value: "客户管理CRM-合同管理-锁定/解锁合同",
                          expression: "'客户管理CRM-合同管理-锁定/解锁合同'",
                        },
                      ],
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleBtnClick(
                            _vm.editData,
                            _vm.editData.isEdit
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.editData.isEdit == 0 ? "解锁" : "锁定"))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "dt-permission",
                          rawName: "v-dt-permission",
                          value: "客户管理CRM-合同管理-编辑",
                          expression: "'客户管理CRM-合同管理-编辑'",
                        },
                      ],
                      staticStyle: { margin: "0" },
                      attrs: {
                        type: "primary",
                        disabled: !_vm.editData.isEdit,
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleBtnClick(_vm.editData, 2)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        width: "270",
                        trigger: "click",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "medium", placeholder: "请选择" },
                          model: {
                            value: _vm.previewDocument,
                            callback: function ($$v) {
                              _vm.previewDocument = $$v
                            },
                            expression: "previewDocument",
                          },
                        },
                        _vm._l(_vm.previewList, function (item, index) {
                          return _c("el-option", {
                            key: item.uid,
                            attrs: { label: item.name, value: item.uid },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "doc-sure",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreviewClick()
                            },
                          },
                        },
                        [_vm._v("确定")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "dt-permission",
                              rawName: "v-dt-permission",
                              value: "客户管理CRM-合同管理-查看附件",
                              expression: "'客户管理CRM-合同管理-查看附件'",
                            },
                          ],
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            size: "small",
                          },
                          slot: "reference",
                        },
                        [_vm._v("预览附件")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        width: "270",
                        trigger: "click",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "medium", placeholder: "请选择" },
                          on: { blur: _vm.handleSelectBlur },
                          model: {
                            value: _vm.downloadDocument,
                            callback: function ($$v) {
                              _vm.downloadDocument = $$v
                            },
                            expression: "downloadDocument",
                          },
                        },
                        _vm._l(_vm.previewList, function (item, index) {
                          return _c("el-option", {
                            key: item.uid,
                            attrs: { label: item.name, value: item.url },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "doc-sure",
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.handleDownloadClick },
                        },
                        [_vm._v("确定")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            size: "small",
                          },
                          slot: "reference",
                        },
                        [_vm._v("下载附件")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleBtnClick(_vm.editData, 3)
                        },
                      },
                    },
                    [_vm._v("更新记录")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("createContract", {
            attrs: {
              showContract: _vm.showContract,
              modalOptions: _vm.modalOptions,
              hasEditPermission: _vm.hasEditPermission,
            },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("DocumentPreview", { ref: "preview" }),
      _vm._v(" "),
      _vm.showAction
        ? _c("iframe", {
            attrs: {
              src: _vm.downloadDocument,
              width: "100%",
              frameborder: "1",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "PeDialog",
        {
          attrs: { PeDialogShow: _vm.wrapShow, PeDialogTitle: "更新记录" },
          on: {
            handlePeDialogSure: _vm.handleWrapClose,
            handlePeDialogClose: _vm.handleWrapClose,
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableListData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "updatedAt", label: "时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "operationFollowName", label: "操作人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "date", label: "操作详情" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleItemDetail(scope.row)
                              },
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#02A7F0" } }, [
                              _vm._v("修改详情"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "PeDialog",
        {
          attrs: { PeDialogShow: _vm.innerShow, PeDialogTitle: "更新记录详情" },
          on: {
            handlePeDialogClose: _vm.handleInnerClose,
            handlePeDialogSure: _vm.handleInnerClose,
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.innerTableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "字段", width: "120px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "previous", label: "修改前" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.previous instanceof Array
                          ? _vm._l(scope.row.previous, function (it) {
                              return _c("div", { staticClass: "list-ul" }, [
                                _c("div", { staticClass: "list-item" }, [
                                  _vm._v("名称:" + _vm._s(it.name)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "list-item" }, [
                                  _vm._v("URL:" + _vm._s(it.url)),
                                ]),
                              ])
                            })
                          : _c("div", [_vm._v(_vm._s(scope.row.previous))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "later", label: "修改后" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.later instanceof Array
                          ? _vm._l(scope.row.later, function (it) {
                              return _c("div", [
                                _c("div", { staticClass: "list-item" }, [
                                  _vm._v("名称:" + _vm._s(it.name)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "list-item" }, [
                                  _vm._v("URL:" + _vm._s(it.url)),
                                ]),
                              ])
                            })
                          : _c("div", [_vm._v(_vm._s(scope.row.later))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }