var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "body",
    },
    [
      _c(
        "el-scrollbar",
        {
          staticClass: "dt-dropdown-dt",
          staticStyle: { height: "570px", width: "100%" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                "label-width": "auto",
                rules: _vm.rules,
                "validate-on-rule-change": false,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "负责人" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请选择负责人" },
                                      model: {
                                        value: _vm.formData.userId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "userId", $$v)
                                        },
                                        expression: "formData.userId",
                                      },
                                    },
                                    _vm._l(_vm.leaderList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "协作人" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        multiple: "",
                                        filterable: "",
                                        placeholder: "请选择协作人",
                                      },
                                      model: {
                                        value: _vm.formData.cooperationUserId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "cooperationUserId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.cooperationUserId",
                                      },
                                    },
                                    _vm._l(_vm.leaderList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.formData.userId == -1 && _vm.activeTabs != 2
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "退回理由",
                                        required:
                                          _vm.formData.userId == -1 &&
                                          _vm.activeTabs != 2,
                                        prop: "returnType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请选择退回理由",
                                          },
                                          model: {
                                            value: _vm.formData.returnType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "returnType",
                                                $$v
                                              )
                                            },
                                            expression: "formData.returnType",
                                          },
                                        },
                                        _vm._l(_vm.allMessage, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "客户来源" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请选择客户来源" },
                                      model: {
                                        value: _vm.formData.fromId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "fromId", $$v)
                                        },
                                        expression: "formData.fromId",
                                      },
                                    },
                                    _vm._l(_vm.allSource, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "线索等级" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请选择线索等级" },
                                      model: {
                                        value: _vm.formData.clueGradeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "clueGradeId",
                                            $$v
                                          )
                                        },
                                        expression: "formData.clueGradeId",
                                      },
                                    },
                                    _vm._l(_vm.allClue, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "客户等级" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请选择客户等级" },
                                      model: {
                                        value: _vm.formData.gradeId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "gradeId", $$v)
                                        },
                                        expression: "formData.gradeId",
                                      },
                                    },
                                    _vm._l(_vm.allLevel, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "状态" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请选择客户状态" },
                                      model: {
                                        value: _vm.formData.stateId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "stateId", $$v)
                                        },
                                        expression: "formData.stateId",
                                      },
                                    },
                                    _vm._l(_vm.allStatus, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "标签" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      ref: "selectTag",
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        multiple: "",
                                        placeholder: "请选择标签",
                                      },
                                      on: { change: _vm.selectChange },
                                      model: {
                                        value: _vm.selectTags,
                                        callback: function ($$v) {
                                          _vm.selectTags = $$v
                                        },
                                        expression: "selectTags",
                                      },
                                    },
                                    _vm._l(_vm.allPerTags, function (item) {
                                      return _c(
                                        "el-option-group",
                                        { attrs: { label: item.name } },
                                        _vm._l(item.tags, function (it) {
                                          return _c("el-option", {
                                            key: it.id,
                                            attrs: {
                                              label: it.name,
                                              value: it.id,
                                            },
                                          })
                                        }),
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tag",
                                    {
                                      style: {
                                        color:
                                          this.flag == "1" ? "#fff" : "#41cabf",
                                      },
                                      attrs: {
                                        color:
                                          this.flag == "1" ? "#41cabf" : "",
                                        size: "small",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeFlag("1")
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-plus" })]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tag",
                                    {
                                      style: {
                                        color:
                                          this.flag == "0" ? "#fff" : "#41cabf",
                                      },
                                      attrs: {
                                        color:
                                          this.flag == "0" ? "#41cabf" : "",
                                        size: "small",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeFlag("0")
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-minus" })]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请输入备注",
                                      type: "textarea",
                                      maxlength: "100",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.formData.remark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "remark", $$v)
                                      },
                                      expression: "formData.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveInput },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }