<template>
  <div class="SetColumn-main">
    <div class="main" v-loading="loadingHeader">
      <el-transfer
        class="trans-dt"
        :props="{ key: 'field',label: 'name'}"
        target-order="push"
        v-model="value"
        :titles="['表头显示字段', '表头隐藏字段']"
        :data="columns" >
      </el-transfer>
    </div>
    <div class="footer">
      <div>
        <el-button size="small" @click="reset">恢复默认</el-button>
      </div>
      <div>
        <el-button size="small" @click="cancelBtn">取消</el-button>
        <el-button size="small" type="primary" @click="confirmBtn" v-loading="loading">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { contractHeaderShow, contractHeaderUpdate, customerColumn, editCustomerColumn  } from '@/api/thread'
export default {
  name: 'SetColumn',
  data () {
    return {
      columns: [],
      value: [],
      loading: false,
      loadingHeader: false,
      type: ''
    }
  },
  mounted () {
    const modalOption = this.$store.state.plugin.modal.options
    this.type = modalOption.type || 'contract'
    this.getHeader()
  },
  computed: {},
  methods: {
    getHeader(){
      this.loadingHeader = true
      if(this.type === 'contract'){
        contractHeaderShow().then(res => {
          this.headerInit(res)
        })
      }else if(this.type === 'customerSea'){
       // todo 自定义客户公海columns
        customerColumn({ item: '客户公海' }).then(res => {
          this.headerInit(res)
        })
      }
    },

    // 初始化header数据
    headerInit(res){
      this.loadingHeader = false
      if(res.code === 60000){
        if(res.data.hiddenField) {
          this.columns = JSON.parse(res.data.hiddenField)
          this.value = this.columns.map(i => i.field)
        }
        if (res.data.showField){
          this.columns = [...this.columns, ...JSON.parse(res.data.showField)]
        }
      }
    },

    // 设置header数据
    handleHeader(res){
      this.loading = false
      if(res.code === 60000){
        this.$store.dispatch('showTip', {
          text: res.msg,
          type: 'success'
        })
        this.$store.commit('update', {
          target: this.$store.state.plugin.modal.options,
          data:{
            refresh: true
          }
        })
        this.$store.dispatch('hideModal')
      }
    },

    confirmBtn(){
      if(this.loading) return
      this.loading = true
      let params = {
        showList: JSON.stringify(
          this.columns.filter(
            i => !this.value.includes(i.field)).map(
              item => {
                return { field: item.field, name: item.name }
              }
            )
        ),
        hiddenList: JSON.stringify(
          this.columns.filter(
            i => this.value.includes(i.field)).map(
              item => {
                return { field: item.field, name: item.name }
              }
            )
        )
      }
      console.log(params)
      if(this.type === 'contract'){
        contractHeaderUpdate(params).then(res => {
          this.handleHeader(res)
        })
      }else if(this.type === 'customerSea'){
        // 设置客户公海header
        params['item'] = '客户公海'
        editCustomerColumn(params).then(res => {
          this.handleHeader(res)
        })
      }
    },

    cancelBtn(){
      this.$store.dispatch('hideModal')
    },

    reset(){
      // this.value = this.columns.map(i => i.field)
      this.value = []
    },

    setDefault(){
      if(this.type === 'contract'){
        this.value = this.columns.map(i => i.field)
      }else if(this.type === 'customerSea'){
        // todo
      }
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.SetColumn-main{
}
.main{
  padding: 20px 20px 10px 20px;
  box-sizing: border-box;
}
.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,.1);
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleY(.5);
}
.trans-dt /deep/ .el-transfer-panel__header .el-checkbox__label{
  font-size: 14px!important;
  color: #333333!important;
}
.trans-dt /deep/ .el-transfer-panel:last-child .el-transfer-panel__list .el-checkbox__label{
  color: #8A8A8A!important;
}
</style>
