<template>
  <div class="new_page_cont clearfix">
    <div class="new_go" v-if="pageStatus.total && isVip !== 1">
      <el-input
        placeholder
        v-model="goNum"
        @change="handleInputChange"
        onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
        class="new_page-input"
      ></el-input>
      <span @click="goPageNum" class="page-go">GO</span>
    </div>
    <div class="new_page_footer" v-if="pageStatus.total">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageStatus.pageNo"
        :page-sizes="[10, 20, 50, 100, 200, 300]"
        :page-size="pageStatus.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="pageStatus.total"
        ref="pageCheck"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageStatus: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      pageNo: 1,
      pageSize: 20,
      total: 0,
      input: '',
      goNum: '',
      isVip: 2, //默认完全开放,
      vipIcon:
        'https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/buyvip.png'
    }
  },
  updated() {
    this.$nextTick(() => {
      this.reactiveData()
    })
  },
  methods: {
    init() {
      this.dialogVisible = true
    },
    handleSizeChange(val) {
      if (this.loading) {
        return
      }
      this.$emit('changePageSize', val, this.isVip)
    },
    handleInputChange(e) {
      this.goNum = Number(e)
    },
    goPageNum() {
      if (this.loading || !this.goNum) {
        return
      }
      this.pageStatus.pageNo = this.goNum
      this.$emit('goNumPage', this.goNum, this.isVip)
    },
    reactiveData() {
      const path = this.$route.path
      const data = this.$store.getters.accountInfo
      const element = this.$refs?.pageCheck?.$el
      const child = Array.from(element?.getElementsByClassName('number') || []) //集合
      const child2 = Array.from(
        element?.getElementsByClassName('el-select-dropdown__item' || [])
      )
      // 出海企业试用
      if (
        data.overseasInsightDetailsOpen == 1 &&
        path === '/insight/overseas'
      ) {
        this.initVip(child, child2)
      }
      // 应用开发者试用
      else if (
        data.developerInsightDetailsOpen == 1 &&
        path === '/insight/developers'
      ) {
        this.initVip(child, child2)
      }
      // 园区洞察试用
      else if (data.parkInsightDetailsOpen == 1 && path === '/insight/park') {
        this.initVip(child, child2)
      }
      //园区洞察详情试用
      else if (
        data.parkInsightDetailsOpen == 1 &&
        path === '/insight/park/detail'
      ) {
        this.initVip(child, child2)
      }
      // 融资试用
      else if (
        data.vcInsightDetailsOpen == 1 &&
        path.includes('/insight/finance')
      ) {
        this.initVip(child, child2)
      }
      //软件洞察试用
      else if (
        data.softwareInsightDetailsOpen == 1 &&
        path === '/insight/software'
      ) {
        this.initVip(child, child2)
      } else {
        child?.forEach(it => {
          it.style.background = 'none'
        })
      }
    },
    handleCurrentChange(index) {
      if (this.loading) {
        return
      }
      this.$emit('handleCurrentChange', index, this.isVip)
    },
    initVip(child, child2) {
      for (let i = 1; i < child.length; i++) {
        const it = child[i]
        it.style.background = `url(${this.vipIcon}) no-repeat`
        it.style.backgroundSize = '10px 10px'
        it.style.backgroundPosition = 'right top'
        it.style.color = 'rgb(149 149 149)'
      }
      child[0].className = 'number active'
      for (let i = 2; i < child2.length; i++) {
        const it = child2[i]
        it.style.background = `url(${this.vipIcon}) no-repeat`
        it.style.backgroundSize = '10px 10px'
        it.style.backgroundPosition = 'right top'
      }
      this.isVip = 1
    }
  }
}
</script>
<style scoped>
.loading-footer-page {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/loading.png') no-repeat;
  background-size: 100% 100%;
  left: 0;
  top: 5px;
  animation: Scroll 1s linear infinite;
}
@keyframes Scroll {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    transition: all 4s;
  }
}
.new_page_cont {
  width: 100%;
  height: 40px;
  min-width: 450px;
}
.new_page_footer {
  float: right;
  height: 100%;
  padding-top: 4px;
  position: relative;
}
.show_total_num {
  position: absolute;
  left: -90px;
  top: 15px;
  font-size: 13px;
  text-align: center;
}
.new_page_footer ::v-deep .el-input--mini .el-input__inner {
  height: 30px;
  line-height: 30px;
}

.new_go {
  float: right;
  width: 105px;
  height: 100%;
}
.new_go .new_page-input {
  width: 37px;
}
.new_go .new_page-input ::v-deep .el-input__inner {
  width: 50px;
  height: 30px;
  padding: 4px;
  margin-top: 6px;
  text-align: center;
}
@import '../../styles/threadpx.scss';
.common-pagina {
  background-color: white;
}
.common-pagina .page-bar {
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  min-width: 0;
}
.common-pagina .page-bar .total {
  width: 160px;
}
.page-div .el-pagination li {
  color: #f00 !important;
}
</style>
