<template>
  <div class="ContactDetail-main" @click="selectContact">
    <div>
      <span class="phone">
        {{ contactData.phone }}
      </span>
      <span class="name">
        {{ contactData.callName }}
      </span>

      <span class="icon-click">
        <i class="card-phone-icon" @click="call"></i>
        <el-dropdown
          placement="bottom-start"
          trigger="click"
          @command="changeAssign($event, contactData)"
        >
          <i class="card-phone-edit-icon"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item.value" v-for="item in assignOption"
              >{{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
    </div>
    <div class="last">
      <div class="all-mark">
        {{ contactData.companyName }}
      </div>
      <div class="from"></div>
    </div>
  </div>
</template>

<script>
import MobileAudio from '@/views/callsystem/mobileaudio'
import { postDetailPhoneAsign } from '@/api/thread'
import editCompanyOrUser from '@/components/ModulePack/dialog/editCompanyOrUser'
import editUser from '@/components/ModulePack/dialog/editUser'

export default {
  name: 'ContactDetail',
  data() {
    return {}
  },
  props: {
    contactData: {
      type: Object,
      default: () => {}
    },
    assignOption: {
      type: Array,
      default: () => []
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {},
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    }
  },
  methods: {
    playRecord(ossUrl) {
      this.$store.dispatch('showModal', {
        title: '播放录音',
        view: MobileAudio,
        size: '600px',
        options: {
          row: {
            ossUrl: ossUrl,
            type: 'record'
          }
        }
      })
    },

    changeAssign(mark, i) {
      const params = {
        mark,
        phone: i.phone,
        companyName: contactData.companyName,
        contact: i.account,
        packageId: this.packageId,
        klc: this.permissionList.includes('客户培育-联系人培育-新建联系人'), // '客户培育-联系人培育-新建联系人'
        kkx: this.permissionList.includes('客户管理CRM-客户公海-新建客户'), // '客户管理CRM-客户公海-新建客户'
        kkl: this.permissionList.includes('客户管理CRM-客户公海-联系人'), // '客户管理CRM-客户公海-联系人'
        pageId: 2001
      }
      postDetailPhoneAsign(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success',
            duration: 500
          })
          if (mark === 7) {
            this.$store.state.user.customerInfoMap.inPool = true
            this.$store.state.user.customerInfoMap.desc =
              '客户当前位置：客户公海-' + this.$store.state.user.name
            const hasShowModalPermission = this.permissionList.includes(
              '智能获客-推荐线索-转客户强弹窗'
            )
            if (!hasShowModalPermission) {
              return
            }
            if (res.data.customer && res.data.contact) {
              this.$store.dispatch('showModal', {
                title: '编辑',
                size: '900px',
                options: {
                  formData: res.data.customer,
                  userFormData: res.data.contact,
                  activeTab: res.data.customer ? res.data.customer.type : ''
                },
                view: editCompanyOrUser
              })
            }

            if (res.data.contact && !res.data.customer) {
              this.$store.dispatch('showModal', {
                title: '编辑',
                size: '600px',
                options: {
                  userFormData: res.data.contact
                },
                view: editUser
              })
            }
          }
          // this.$emit('refresh')
        }
      })
    },

    call() {
      const phone = this.contactData.phone
      const contact = this.contactData.account
      const companyName = this.contactData.companyName
      const packageId = 0
      this.$bus.$emit('callup', phone, contact, companyName, packageId)
    },

    selectContact() {
      this.$emit('selectContact', this.contactData)
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.ContactDetail-main {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  box-sizing: border-box;
  font-size: 12px;
  position: relative;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}
.all-mark {
  /*color: #0099CC;*/
  cursor: pointer;
}
.from {
  color: #41cabf;
}
.last {
  display: flex;
  justify-content: space-between;
}
.call-num {
  color: #41cabf;
}
.tag {
  padding: 0 5px;
  box-sizing: border-box;
}
.icon-click {
  position: absolute;
  top: 10px;
  right: 10px;
}
.card-phone-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url('../../assets/touch-phone-icon.png') no-repeat;
  background-size: 100% 100%;
  margin-top: 3px;
  cursor: pointer;
}
.card-phone-edit-icon {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 3px;
  background: url('../../assets/keydetail_edit.svg') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.time-line {
  padding: 20px 20px 20px 100px;
  box-sizing: border-box;
}
.time-line /deep/ .el-timeline-item {
  padding-bottom: 0 !important;
}
.time-line /deep/ .el-timeline-item__node--normal {
  left: 1px !important;
  width: 8px !important;
  height: 8px !important;
}
.line-content {
  color: #8a8a8a;
  font-size: 12px;
}
.import-text {
  color: #333333 !important;
}
.line-top {
  margin-bottom: 10px;
  position: relative;
}
.line-top > span:first-child {
  transform: translateX(-70px);
  position: absolute;
}
.time-line-date {
  position: relative;
  transform: translate(-100px, -20px);
}
.play-record {
  color: #0099cc;
  cursor: pointer;
}
</style>
