var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "user_count_show" }, [
        _c("div", { staticClass: "count_show_cont" }, [
          _c("div", { staticClass: "account_user_cont follow_time" }, [
            _c("span", [
              _vm._v(
                "客户可跟进时间:" +
                  _vm._s(_vm.userInfoObj.enterprise.protectDay) +
                  " 天"
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "recharge_use",
                on: {
                  click: function ($event) {
                    return _vm.goUpTip("1")
                  },
                },
              },
              [_vm._v("配置")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count_show_cont" }, [
          _c("div", { staticClass: "account_user_cont period" }, [
            _c("span", [
              _vm._v(
                "保护期:" + _vm._s(_vm.userInfoObj.enterprise.cusProDay) + "天"
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "recharge_use",
                staticStyle: { display: "inline-block" },
                on: {
                  click: function ($event) {
                    return _vm.goUpTip("2")
                  },
                },
              },
              [_vm._v("配置")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count_show_cont" }, [
          _c("div", { staticClass: "account_user_cont personnum" }, [
            _c("span", [
              _vm._v(
                "负责客户数:" +
                  _vm._s(_vm.userInfoObj.enterprise.customersLimit) +
                  "家"
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "recharge_use",
                on: {
                  click: function ($event) {
                    return _vm.goUpTip("3")
                  },
                },
              },
              [_vm._v("配置")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "showUpTip",
          attrs: { dialogStatus: _vm.dialogStatus },
          on: { submitContBtn: _vm.submitContBtn },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "line-height": "40px", "margin-top": "30px" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c(
                "p",
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.configType,
                        callback: function ($$v) {
                          _vm.configType = $$v
                        },
                        expression: "configType",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { placeholder: "请输入", type: "number" },
                        model: {
                          value: _vm.optionNum,
                          callback: function ($$v) {
                            _vm.optionNum = $$v
                          },
                          expression: "optionNum",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.configState == 3 ? "家" : "天")),
                      ]),
                      _vm._v(" "),
                      _vm.configState != 3
                        ? _c("span", [_vm._v("销售负责客户释放")])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.configState != 3
                ? _c(
                    "p",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.configType,
                            callback: function ($$v) {
                              _vm.configType = $$v
                            },
                            expression: "configType",
                          },
                        },
                        [_vm._v("销售负责客户不释放")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.configState != 3
                ? _c(
                    "p",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c("span", [_vm._v("到期后退还")]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.releaseStr,
                            callback: function ($$v) {
                              _vm.releaseStr = $$v
                            },
                            expression: "releaseStr",
                          },
                        },
                        _vm._l(_vm.strOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      {
        staticClass: "company-info-header company-info-text",
        staticStyle: { background: "#fff", "margin-top": "10px" },
      },
      [_c("span", { staticClass: "header-left-name" }, [_vm._v("账号配置")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }