<template>
  <div class="index-main">
    <div class="btn-list">
      <div class="input-row">
        <el-input
          style="width: 200px"
          v-model="search"
          size="mini"
          placeholder="输入跟进记录/跟进客户/跟进对象"
        ></el-input>
        <div class="btn-row">
          <el-button type="primary" size="mini" @click="searchFollowRecord"
            >查询</el-button
          >
          <el-button size="mini" @click="reset">重置</el-button>
        </div>
        <div class="g-search" @click="searchPlus">
          高级筛选<svg-icon icon-class="filtrate" class="filtrate" />
        </div>
        <div class="condition" v-show="condition">
          <span class="condition-icon" @click="removeSearch">
            <i class="el-icon-circle-close"></i>
          </span>
          <el-input
            style="width: 200px"
            v-model="showCondition"
            size="mini"
          ></el-input>
        </div>
      </div>
      <el-button
        size="mini"
        type="primary"
        @click="createFollowRecord"
        v-dt-permission="'客户管理CRM-跟进记录-新建跟进记录'"
      >
        新建跟进记录
      </el-button>
    </div>
    <div
      class="table"
      v-loading="loading"
      v-dt-permission="'客户管理CRM-跟进记录-新建跟进记录'"
    >
      <el-table
        class="table-dt"
        :key="tableKey"
        :data="tableData"
        @sort-change="sortChange"
        border
        stripe
        style="width: 100%; height: 100%; font-size: 12px;background-color:#fff;"
      >
        <el-table-column
          show-tooltip-when-overflow
          v-for="(item, index) in columnList"
          :key="index"
          :prop="item.field"
          align="center"
          :sortable="item.type === 'sort' ? 'custom' : false"
          :label="item.name"
        >
          <template slot="header" slot-scope="scope">
            <span v-if="item.type === 'filter'">
              <el-dropdown
                placement="bottom"
                trigger="click"
                @command="val => filterFunc(val, item)"
              >
                <template v-if="item.field === 'followName'">
                  <span
                    class="el-dropdown-link"
                    :class="followUserId ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <dt-drop-down :source-list="followUserList">
                      <template v-slot:default="row">
                        <el-scrollbar class="dt-dropdown-dt">
                          <div
                            class="dt-drop-down-no-data"
                            v-if="!row.filterList.length"
                          >
                            无数据
                          </div>
                          <el-dropdown-item
                            :command="_item.id"
                            :class="followUserId === _item.id ? 'active' : ''"
                            v-for="_item in row.filterList"
                          >
                            <span v-html="_item.htmlEl"></span
                          ></el-dropdown-item>
                        </el-scrollbar>
                      </template>
                    </dt-drop-down>
                  </el-dropdown-menu>
                </template>

                <template v-else-if="item.field === 'followType'">
                  <span
                    class="el-dropdown-link"
                    :class="followType ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :command="_item.name"
                        :class="followType === _item.name ? 'active' : ''"
                        v-for="_item in followTypeList"
                        >{{ _item.name }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>

            <span v-else-if="item.type === 'sort'"> {{ item.name }} </span>
            <span v-else> {{ item.name }}</span>
          </template>

          <template slot-scope="scope">
            <company-link
              v-if="item.field === 'companyName' && isLink"
              :company-name="scope.row.companyName || scope.row.company_name"
              :company-uuid="scope.row.companyUuid || scope.row.company_uuid"
              :package-id="scope.row.packageId || scope.row.package_id"
              :companyList="tableData"
              :companyIndex="index"
            />
            <span v-else> {{ scope.row[item.field] }} </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page page-div page-1">
      <footer-page
        @handleCurrentChange="handleCurrentChange"
        @goNumPage="goNumPage"
        @changePageSize="changePageSize"
        :pageStatus="pageStatus"
      ></footer-page>
    </div>
  </div>
</template>

<script>
import FooterPage from '@/components/Footerpage/index'
import {
  followRecordList,
  getCustomerLeaders,
  followRecordFilter
} from '@/api/customerpond'
import searchFollowRecord from './dialog/searchFollowRecord'
import createFollowRecord from './dialog/createFollowRecord'
import { getFollowType } from '@/api/table'
import companyLink from '@/components/companyLink/companyLink'
import DtDropDown from '@/components/element/DtDropDown'

export default {
  name: 'follow-record-list',
  components: {
    FooterPage,
    companyLink,
    DtDropDown
  },
  data() {
    return {
      tableKey: 0,
      search: '',
      optionsList: [],
      hashMap: {},
      showCondition: '',
      followUserList: [],
      followTypeList: [],
      loading: false,
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: null
      },
      followType: '', // 跟进方式
      followUserId: '', //跟进人id
      sortOrder: null,
      sort: null,
      condition: '',
      tableData: [],
      columnList: []
    }
  },
  watch: {
    condition(val) {
      this.showCondition = this.formatCondition(val)
    }
  },
  mounted() {
    this.getOptionsList()
    this.getFollowUserList()
    this.getFollowType()
    let hashMap = sessionStorage.getItem('optionsList-followList')
    if (hashMap) {
      this.hashMap = JSON.parse(hashMap)
    }
    let cache = sessionStorage.getItem('followRecordCache')
    if (cache) {
      cache = JSON.parse(cache)
      this.condition = cache.condition
      this.companyName = cache.companyName
      this.pageStatus.pageNo = cache.pageNo
      this.pageStatus.pageSize = cache.pageSize
      this.followType = cache.followType
      this.followUserId = cache.followUserId
      this.sortOrder = cache.sortOrder
    }
    this.getFollowRecordList()
  },
  computed: {
    isLink() {
      const versionNum = this.$store.state.user.accountInfo.versionNum
      return versionNum.indexOf('008') < 0
    }
  },
  methods: {
    formatCondition(val) {
      if (!val) return ''
      let data = val.split(';')
      return data
        .map(i => {
          const target = this.hashMap[i.split(':')[0]]
          return `${target}:${i.split(':')[1]}:${i.split(':')[2]}`
        })
        .join(';')
    },

    getFollowType() {
      getFollowType({}).then(res => {
        if (res.code === 60000 && res.data) {
          this.followTypeList = res.data
        }
      })
    },

    // 获取负责人列表
    getFollowUserList() {
      getCustomerLeaders({ pageId: 2001, type: 3 }).then(res => {
        if (res.code === 60000) {
          this.followUserList = res.data.filter(i => i.name !== '可跟进的')
        }
      })
    },
    getOptionsList() {
      followRecordFilter({ pageId: 2002 }).then(res => {
        if (res.code === 60000) {
          this.optionsList = res.data
          this.optionsList.forEach(item => {
            this.hashMap[item.filterColumn] = item.filterName
          })
          sessionStorage.setItem(
            'optionsList-followList',
            JSON.stringify(this.hashMap)
          )
        }
      })
    },

    sortChange(data) {
      const map = {
        ascending: 'asc',
        descending: 'desc'
      }
      data.order ? (this.sortOrder = map[data.order]) : (this.sortOrder = null)
      this.sort = data.prop
      this.getFollowRecordList()
    },

    searchPlus() {
      this.$store.dispatch('showModal', {
        title: '高级筛选',
        view: searchFollowRecord,
        size: '800px',
        options: {
          source: this.optionsList,
          type: 'followRecord'
        },
        onClose: res => {
          if (res.refresh) {
            this.condition = res.searchFilter
            this.pageNo = 1
            this.pageSize = 20
            this.getFollowRecordList()
          }
        }
      })
    },

    removeSearch() {
      this.condition = ''
      this.getFollowRecordList()
    },

    filterFunc(val, item) {
      if (item.field === 'followName') {
        this.followUserId = val
      }
      if (item.field === 'followType') {
        this.followType = val
      }
      this.getFollowRecordList()
    },

    reset() {
      this.condition = ''
      this.search = ''
      this.followType = ''
      this.followUserId = ''
      this.sortOrder = null
      this.sort = null
      this.tableKey += 1
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      this.getFollowRecordList()
    },

    searchFollowRecord() {
      // if (!this.search) return;
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      this.getFollowRecordList()
    },

    setCache(params) {
      sessionStorage.setItem('followRecordCache', params)
    },

    getFollowRecordList() {
      this.loading = true
      const params = {
        condition: this.condition,
        word: this.search,
        followType: this.followType,
        followUserId: this.followUserId,
        sortOrder: this.sortOrder,
        sort: this.sort,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        pageId: '2002'
      }
      followRecordList(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.setCache(JSON.stringify(params))
          this.tableData = res.data.list
          this.pageStatus.pageNo = res.data.page.pageNo
          this.pageStatus.total = res.data.page.total
          this.columnList = [...res.data.columns]
        }
      })
    },

    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.getFollowRecordList()
    },

    goNumPage(val) {
      this.pageStatus.pageNo = val
      this.getFollowRecordList()
    },

    changePageSize(val) {
      this.pageStatus.pageSize = val
      this.getFollowRecordList()
    },

    // 新建跟进记录
    createFollowRecord() {
      this.$store.dispatch('showModal', {
        title: '新建跟进记录',
        view: createFollowRecord,
        size: '700px',
        options: {
          followTypeList: this.followTypeList
        },
        onClose: res => {
          if (res.refresh) {
            this.reset()
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.index-main {
  height: 100%;
}
.btn-list {
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
.input-row {
  display: flex;
  align-items: center;
}
.btn-row {
  margin-left: 10px;
  display: flex;
}
.table {
  margin-top: 10px;
  min-height: calc(100% - 60px - 40px);
  background-color: #fff;
}
.table ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
.table ::v-deep .el-table th,
.el-table tr {
  background-color: #fff;
}
.page-1 {
  background-color: #fff;
}
.set-btn {
  cursor: pointer;
  color: #0099cc;
}
.set > span:last-child {
  display: none;
}
.el-dropdown-link {
  cursor: pointer;
  color: #909399;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.active {
  color: #41cabf !important;
}
.g-search {
  color: #41cabf;
  margin-left: 10px;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
}
.filtrate {
  margin-left: 2px;
}
.condition {
  position: relative;
  margin-left: 10px;
}
.condition /deep/ .el-input__inner {
  padding-right: 40px;
}
.condition-icon {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40px;
  z-index: 99;
  cursor: pointer;
  color: #41cabf;
  /*background-color: rosybrown;*/
}
</style>
