<template>
  <div
    class="productRelationGraph-main"
    v-loading="loading"
    element-loading-text="加载中"
  >
    <div v-if="noData" class="no-data-data">
      暂无数据
    </div>
    <el-checkbox-group v-model="links" class="check" @change="getGraph">
      <el-checkbox
        v-for="(item, index) in typeList"
        :key="index"
        :label="item.key"
        >{{ item.name }}</el-checkbox
      >
    </el-checkbox-group>
    <div class="main-graph" ref="myPage">
      <RelationGraph
        ref="seeksRelationGraph"
        :options="graphOptions"
        :on-node-click="onNodeClick"
      >
        <div slot="node" slot-scope="{ node }">
          <div class="icon" @click="testNode(node)">
            {{ node.data.cname }}
          </div>
          <div class="node-class" @click="jumpCompany(node.id)">
            {{ node.data.cname }}
            <div style="text-align: left;margin-top: 3px;font-size: 12px;">
              <p>热度：{{ node.data.hot }}</p>
              <p>云服务商：{{ node.data.csp || '其他' }}</p>
            </div>
          </div>
        </div>
      </RelationGraph>
    </div>
  </div>
</template>

<script>
import RelationGraph from 'relation-graph'
import { getGraph } from '@/api/customerpond'
import { throttle } from 'lodash'

export default {
  name: 'cloudList',
  components: {
    RelationGraph
  },
  data() {
    return {
      showDetail: false,
      graspData: {},
      recruitStr: '',
      links: ['supplier', 'customer'],
      loading: false,
      graphOptions: {
        allowSwitchLineShape: true,
        allowSwitchJunctionPoint: true,
        defaultJunctionPoint: 'border',
        allowShowMiniToolBar: true,
        defaultNodeShape: 0,
        defaultNodeBorderWidth: 0,
        defaultLineWidth: 3,
        disableZoom: true,
        layouts: [
          {
            layoutName: 'force',
            layoutClassName: 'seeks-layout-center',
            defaultExpandHolderPosition: 'hide',
            defaultJunctionPoint: 'border',
            defaultNodeShape: 2,
            defaultLineShape: 2,
            min_per_width: '200',
            min_per_height: '300'
          }
        ]
      },

      isAddId: [],
      moduleDetail: {}
    }
  },
  props: {
    packageId: {
      type: [String, Number, Object],
      default: ''
    },
    type: {
      type: [String, Number],
      default: 1
    }
  },

  computed: {
    noData() {
      return !this?.moduleDetail?.nodes?.length && !this.loading
    },
    typeList() {
      if (this.type === '1') {
        return [
          {
            name: '供应商',
            key: 'supplier'
          },
          {
            name: '客户',
            key: 'customer'
          }
        ]
      } else {
        return [
          {
            name: '下级公司',
            key: 'subInvestment'
          },
          {
            name: '上级公司',
            key: 'superInvestment'
          }
        ]
      }
    }
  },

  created() {
    this.onNodeClick = throttle(
      (data, event) => {
        this._onNodeClick(data, event)
      },
      200,
      { trailing: false }
    )
  },

  methods: {
    init() {
      if (this.type === '1') {
        this.links = ['supplier', 'customer']
      } else {
        this.links = ['subInvestment', 'superInvestment']
      }
      this.getGraph()
    },

    getGraph() {
      const params = {
        companyUuid: this.$route.query.companyUuid,
        links: this.links.join(',')
      }
      this.loading = true
      getGraph(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.moduleDetail = res.data
          if (res.data) {
            this.showSeeksGraph(res.data)
          }
        }
      })
    },

    jumpCompany(uuid) {
      const isDev = process.env.NODE_ENV === 'development'
      const baseUrl = isDev ? 'https://ai2.dtbird.cn' : 'https://ai.dtbird.cn'
      const URL = `${baseUrl}/#/keydetail/keydetail?companyUuid=${uuid}&packageId=${this
        .packageId || 0}&showCompanyNameList=false`

      window.open(URL)
    },

    testNode(data) {
      console.log(data)
    },

    graspGoPay() {
      this.showDetail = false
    },

    showSeeksGraph(sourceData) {
      // 查找主节点
      const mainId = sourceData?.rootId
      sourceData?.nodes?.forEach(i => {
        if (i.id === mainId) {
          i.color = '#43a2f1'
          i.borderColor = 'yellow'
          i.height = 150
          i.width = 150
        } else {
          i.height = 80
          i.width = 80
        }
        i.data = {
          cname: i.companyName,
          hot: i.hot,
          csp: i.csp
        }
      })
      this.$refs.seeksRelationGraph.setJsonData(sourceData)
    },

    _onNodeClick(nodeObject, $event) {
      console.log('onNodeClick:', nodeObject)
      const uuid = nodeObject.id
      if (uuid !== this.moduleDetail.rootId && !this.isAddId.includes(uuid)) {
        const params = {
          links: this.links.join(','),
          companyUuid: uuid
        }
        getGraph(params).then(res => {
          if (res.code === 60000 && res.data) {
            // 追加数据
            let data = res.data
            data.rootId = this.moduleDetail.rootId
            data.nodes.forEach(i => {
              i.data = {
                cname: i.companyName,
                hot: i.hot,
                csp: i.csp
              }
              i.height = 80
              i.width = 80
            })
            this.addData(data, uuid)
          }
        })
      }
    },

    addData(data, uuid) {
      if (this.isAddId.includes(uuid)) return
      this.isAddId.push(uuid)
      this.$refs.seeksRelationGraph.appendJsonData(data, () => {
        // 修改二级节点颜色
        this.$refs.seeksRelationGraph.getNodeById(uuid).color = '#43a2f1'
        // 刷新节点
        this.$refs.seeksRelationGraph.refresh()
      })
    }
  }
}
</script>

<style scoped>
@import '~@/styles/variables.scss';
.productRelationGraph-main {
  height: 100%;
  width: 100%;
  position: relative;
}
.no-data-data {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  font-size: 12px;
  color: #8a8a8a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-graph {
  height: 100%;
  width: 100%;
}

.node-class {
  color: forestgreen;
  font-size: 12px;
  position: absolute;
  left: 0;
  bottom: -55px;
  width: 100%;
  min-height: 25px;
  height: auto;
  /*text-align: center;*/
  text-align: left;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-filter {
  filter: blur(1.5px);
}
.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 100;
}

.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.touch-no-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-text {
  margin: 20px 0;
  font-size: 15px;
  padding-left: 2em;
  box-sizing: border-box;
}
.bottom-panel span {
  height: 13px;
  display: inline-block;
  margin: 0 15px;
  position: relative;
  color: #8a8a8a;
}
.bottom-panel span:before {
  content: '';
  display: inline-block;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: $main-color;
  position: absolute;
  left: -15px;
  top: 0;
}
.current-company:before {
  background-color: #43a2f1 !important;
}
.current-customer:before {
  background-color: #e6a23c !important;
}
.current-boss:before {
  background-color: $main-color !important;
}
.check {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
