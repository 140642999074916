<template>
  <div>
    <dia-model
      ref="scribesDialog"
      :btnLoading="btnLoading"
      :dialogStatus="dialogStatus"
      v-if="showModel"
      @submitContBtn="submitContBtn"
    >
      <div
        slot="modalCont"
        v-loading="getNumLoading"
        :element-loading-text="keyScribesLoading"
      >
        <div class=" clearfix scri-input-dq" style="width:100%;">
          <div class="page-cont-title" style="color:#8F8F8F ">选择培育池:</div>
          <div class="select cont-title-select new_select_icon">
            <el-select
              v-model="currentDqValue"
              default-first-option
              filterable
              placeholder="请选择选择培育池"
              @change="getScribesAdrNum"
            >
              <el-option
                v-for="item in bookMarkList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                v-if="item.id !== currentId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          class="scribe-tip-dq"
          v-if="currentDqValue && currentComNum !== ''"
        >
          共找到
          <span style="color:#FF6E6E"> {{ currentComNum }} </span>
          条线索，
        </div>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import { getFavoritesList, postMoveFavoritesGroup } from '@/api/table'
import { getMode, getBookingComNum, getPackBookingMore } from '@/api/user.js'
export default {
  props: {
    currentId: {
      type: Number,
      default: null
    },
    currentTitle: {
      type: String,
      default: ''
    },
    chooseArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showModel: false,
      dialogCont: '',
      showCancelBtn: true,
      bookMarkList: [],
      currentDqValue: '',
      currentComNum: '',
      btnLoading: false,
      dialogStatus: {},
      getNumLoading: false,
      keyScribesLoading: '',
      chooseMoveArr: []
    }
  },
  components: {
    diaModel
  },
  created() {},
  methods: {
    getFavoritesList() {
      getFavoritesList({}).then(res => {
        if (res.code === 60000) {
          this.bookMarkList = res.data
        }
      })
    },
    getScribesAdrNum() {
      if (!this.currentDqValue) {
        this.dialogStatus.noAllow = true
        return
      }
      this.dialogStatus.noAllow = false
    },
    initScribes(val) {
      this.chooseMoveArr = []
      this.chooseArr.forEach(item => {
        this.chooseMoveArr.push(item.companyUuid)
      })
      const _this = this
      _this.showModel = true
      _this.getNumLoading = false
      _this.currentDqValue = ''
      _this.getFavoritesList()
      _this.$nextTick(() => {
        _this.dialogStatus = {
          dialogWidth: '450px',
          dialogTitle: '改变分组',
          dialogTop: '250px',
          showFoot: true,
          singleBtn: false,
          noAllow: true,
          dialogSurBtn: '确认'
        }
        if (_this.currentComNum === 0) {
          _this.dialogStatus.noAllow = true
        }
        _this.$refs.scribesDialog.init()
      })
    },
    // 改变分组
    submitContBtn() {
      this.getNumLoading = true
      this.dialogStatus.noAllow = true
      this.keyScribesLoading = '移动中...'
      this.postMoveFavoritesGroup()
    },
    postMoveFavoritesGroup(val) {
      const params = {
        newfavoritesId: this.currentDqValue,
        oldfavoritesId: this.currentId,
        companyUuids: this.chooseMoveArr.toString()
      }
      postMoveFavoritesGroup(params).then(res => {
        this.getNumLoading = false
        this.dialogStatus.noAllow = false
        if (res.code === 60000) {
          this.showModel = false
          this.$emit('removeSuccess')
        }
      })
    }
  }
}
</script>

<style scoped>
@import '../../styles/threadpx.scss';
</style>
<style scoped>
.scri-input-dq ::v-deep .el-input__inner {
  height: 30px;
  width: 250px;
}
.scribe-tip-dq {
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(46, 46, 46, 1);
  line-height: 12px;
  margin: 13px 0 20px;
}
.scribe-tip-tip {
  width: 100%;
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(46, 46, 46, 0.6);
  line-height: 12px;
  margin: 13px 0 20px;
}
.plus_minus_meal {
  height: 25px;
  line-height: 25px;
  display: inline-block;
}
.card_up_down {
  width: 25px;
  height: 25px;
  display: inline-block;
  border: 1px solid #cccbcb;
  vertical-align: top;
  padding: 5px;
  cursor: pointer;
}
.card_icon_plus {
  background: url('../../assets/minus-num-no.png') no-repeat;
  background-size: 100% 100%;
}
.card_icon_plusenter {
  background: url('../../assets/minus-num.png') no-repeat;
  background-size: 100% 100%;
}
.card_icon_minus {
  background: url('../../assets/plus-num-no.png') no-repeat;
  background-size: 100% 100%;
}
.card_icon_minusenter {
  background: url('../../assets/plus-num.png') no-repeat;
  background-size: 100% 100%;
}
.card_icon {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.num_plus {
  border-right: none !important;
}
.num_minus {
  border-left: none !important;
}
.card_num_input {
  display: inline-block;
  width: 40px;
  height: 25px;
  border: 1px solid #cccbcb;
  color: #2e2e2e;
  line-height: 25px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  user-select: none;
}
.valign {
  vertical-align: middle;
}
.valign-t {
  vertical-align: top;
}

.new-meal-bottom {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
</style>
