<template>
  <div class="wheel-card">
    <ul class="ul-nums">
      <li v-for="(item, index) in list" :key="index">
        <div class="con">
          <div class="t">{{ item.name }}</div>
          <div class="num">{{ item.value }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'WheelCard',

  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
.wheel-card {
  .ul-nums {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
    padding-bottom: 14px;
    margin-top: 20px;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 120px;
      background: url(../theme/images/bg2.png) no-repeat center;
      background-size: contain;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      pointer-events: none;
    }

    .con {
      position: relative;
      text-align: center;
      min-width: 170px;
      padding-bottom: 30px;

      .num {
        font-size: 36px;
        font-family: DIN;
        font-weight: bold;
      }

      &:before {
        content: '';
        display: block;
        width: 120px;
        height: 69px;
        background: url(../theme/images/dz1.png) no-repeat center;
        background-size: contain;
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        pointer-events: none;
      }
    }
  }
}
</style>
