var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pro-wrap" }, [
    _c("div", { staticClass: "title", on: { click: _vm.handleVip } }, [
      _vm._v("开通会员 解锁更多功能"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "panel" }, [
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.listData, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              class: _vm.currentIndex == index ? "active item" : "item",
              on: {
                click: function ($event) {
                  return _vm.handleClick(index)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("img", { staticClass: "img", attrs: { src: _vm.imgSrc } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }