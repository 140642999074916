<template>
  <div class="dt-menu">
    <el-menu
      class="dt-menu"
      mode="vertical"
      text-color="#8a8a8a"
      :default-openeds="openeds"
      :default-active="currentPage"
      :collapse="open"
      :router="false"
    >
      <template v-for="menu in routeList">
        <template v-if="menu.pages_count == 1 && menu.menu_uri">
          <el-menu-item
            :index="menu.id + ''"
            :key="menu.id"
            @click="setPageId(menu)"
          >
            <svg-icon :icon-class="getIcon(menu)" class="icon"></svg-icon>
            <span slot="title">{{ menu.menu_name }}</span>
          </el-menu-item>
        </template>
        <template v-else>
          <el-submenu :index="menu.id.toString()" :key="menu.id">
            <template slot="title">
              <svg-icon :icon-class="getIcon(menu)" class="icon"></svg-icon>
              <span>{{ menu.name }}</span>
            </template>

            <template v-for="page in menu.pages">
              <el-menu-item
                v-if="page.uri"
                :index="page.uri"
                :key="page.uri"
                @click="setPageId(page, menu)"
              >
                <span slot="title"
                  >{{ page.name }}
                  <el-badge
                    :value="messageNum(page.name)"
                    class="item"
                  ></el-badge>
                  <span
                    v-if="page.name === '电销外呼'"
                    class="new-icon"
                    style="position: relative;top:-2px;"
                  >
                  </span>
                </span>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { maidianMap } from '@/api/workTable'
import { getCloudList } from '@/api/module'
import store from '@/store'
import { config } from '@/config'
export default {
  name: 'DtMenu',

  props: {
    open: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      openeds: config.defaultOpen,
      menuTypeList: [
        'waihu',
        'proxy',
        'scrm',
        'after-sale',
        'land',
        'supply',
        'huawei',
        'sys',
        'hkfa',
        'app'
      ],
      pageNo: 1
    }
  },

  methods: {
    getSingleMenu(menu) {
      return menu.pages[0]?.uri
    },

    messageNum(name) {
      if (name === '融资企业洞察')
        return this.message?.Trz === 0 ? undefined : this.message?.Trz
      if (name === '地域KA')
        return this.message?.overseas === 0 ? undefined : this.message?.overseas
      if (name === '情报消息推送')
        return this.message?.hasView === 0 ? undefined : this.message?.hasView
      if (name === '应用开发者洞察')
        return this.message?.developer === 0
          ? undefined
          : this.message?.developer
      if (name === '软件企业洞察')
        return this.message?.software === 0 ? undefined : this.message?.software
      if (name === '榜单KA')
        return this.message?.industryList === 0
          ? undefined
          : this.message?.industryList
    },

    async setPageId(i, menu) {
      this.$store.state.user.pageId = i.id
      localStorage.setItem('pageId', i.id)
      console.log(i, menu, 'menu123')
      this.$store.state.permission.isRefreshAuth = false

      if (i.id == 470) {
        window.open(`/#${i.menu_uri}`)
      }
      // console.log(i.menu_uri)

      const investList = ['投融资洞察', '投资机构', '投资事件', '科技KA']
      if (investList.includes(menu?.name)) {
        const list = i.uri.split('/')
        const id = list[list.length - 1]
        this.$bus.$emit('handlePageChange', i?.name, id)
      }

      if (i.type == 'more') {
        const menuList = this.$store.state.permission.menuList
        const openId = menu.id
        const initList = menuList.find(item => item.id == openId)?.pages
        console.log(initList, 'initList')
        this.pageNo = i.pageNo || menu.page.pageNo
        this.pageNo++
        if ((this.pageNo - 1) * 10 > menu.page.total) {
          return
        }
        const { data } = await getCloudList({
          parentId: openId,
          pageNo: this.pageNo,
          pageSize: 10
        })
        const addListItem = data.find(item => item.id == openId)
        addListItem.pages.pageNo = this.pageNo
        initList.splice((this.pageNo - 1) * 10, 1)
        // initList.splice((this.pageNo - 1) * 10, 0, ...addListItem.pages)
        addListItem.pages.push({
          type: 'more',
          name: '查看更多',
          parentId: addListItem.id,
          pageNo: addListItem.page.pageNo
        })
        initList.push(...addListItem.pages)
        this.$store.commit('saveMenuList', menuList)
        console.log(initList, this.pageNo, menuList, 'addList')
      }

      if (menu?.name == '行业图谱') {
        this.$bus.$emit('handleSchemaChange', i.value)
      }

      if (menu?.name == '连锁网络规划') {
        this.$bus.$emit('handleBrandChange', i.name)
      }

      if (menu?.name == '选址推荐') {
        this.$bus.$emit('handleSiteChange', i.name)
      }

      if (i?.name === '商机云图' && this.currentPage !== '/globalquery/map') {
        maidianMap().then()
      }

      this.$router.push({
        path: i?.uri || i?.menu_uri
      })
    },

    getIcon(menu) {
      return menu.page ? 'cloud' : config.menuIcon[(menu?.id)]
    }
  },

  computed: {
    message() {
      return this.$store.state.permission.messageNum
    },

    //记录惨菜单栏高亮
    currentPage() {
      const path = this.$route.path
      const id = this.$store.state.user.pageId
      const paramsId = this.$route.params.id

      if (path == '/insight/park/detail') {
        return '/insight/park'
      }
      if (path === '/run-in/list') {
        return '/dt-index'
      }
      if (path === '/booklist/booklist') {
        return '/collection/collection'
      }
      if (path === '/userstay/userstay') {
        return '/landpage/landpage'
      }
      if (path === '/miniMarket/name-card-create') {
        return '/miniMarket/name-card'
      }
      if (path === '/permission/view' || path === '/permission/update') {
        return '/permission/permission'
      }

      if (path === '/preview/list') {
        return '/insight/model'
      }
      if (path === '/preview/list') {
        return '/insight/model/booking'
      }

      if (path == '/') {
        return '/package'
      }

      if (path === '/keydetail/keydetail') {
        switch (id) {
          case 17001:
            return '/insight/finance'
          case 19001:
            return '/insight/overseas'
          case 21001:
            return '/insight/apps'
          case 21002:
            return '/insight/developers'
          case 18001:
            return '/insight/park'
          case 1001:
            return '/package'
          case 1006:
            return '/globalquery/query'
          case 1007:
            return '/globalquery/query2'
          case 1008:
            return '/globalquery/map'
          case 6001:
            return '/collection/collection'
          case 502:
            return '/callsystem/callhistory'
          case 504:
            return '/callsystem/autocall'
          case 2001:
            return `/customerpond/customerpond`
          case 2002:
            return `/contract/contract`
          case 2003:
            return `/contact/contact`
          case 2004:
            return `/chance/chance`
          case 2005:
            return `/follow-record/list`
          case 2006:
            return `/pay-back/list`
          case 23001:
            return `/insight/software`
          case id:
            return `/collection/contact/${id}`
        }
      }
      if (path.startsWith('/dong-cha/')) {
        return `/dong-cha/list/${paramsId}`
      }
      return this.$route.path
    },

    //自定义菜单栏初始化显示
    menuList() {
      return this.$store.state.permission.menuList
    },

    env() {
      return store.state.plugin.env
    },

    isScrm() {
      return this.$store.state.user.isScrm
    },

    // dd去除销售战略决策
    routeList() {
      if (this.env === 'dd' || this.env === 'tt') {
        return JSON.parse(JSON.stringify(this.menuList)).filter(item => {
          return item.id !== 40
        })
      }
      return JSON.parse(JSON.stringify(this.menuList))
    },

    menuType() {
      return this.$store.state.permission.menuType
    }
  },

  beforeDestroy() {
    this.$bus.$off()
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';

.dt-menu {
  .new-icon {
    display: inline-block;
    height: 80%;
    width: 40px;
    background: url('../../assets/new-icon.svg') no-repeat center center;
    background-size: 70% 70%;
    transform: translate(-5px, 0);
  }

  .icon {
    font-size: 20px;
  }
}
</style>
