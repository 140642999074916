<template>
  <div
    class="recom-list-cont"
    v-if="recomListArr && recomListArr.length"
    v-loading="showRecommend"
  >
    <p class="recom-title-cont">
      <span class="recom-tag-tag">新进注册企业</span>
    </p>
    <div style="padding: 0 38px;">
      <div
        class="recom-list-card"
        v-for="(item, index) in recomListArr"
        :key="index"
        @click="goRecommendPage(item)"
      >
        <p class="crad-title over-text">{{ item.company_name }}</p>
        <div class="card-tag-com" style="float:right;">
          <span
            class="card-tag-all"
            v-for="(tag, ind) in item.tags"
            :key="ind + 'tag'"
            >{{ tag }}</span
          >
        </div>
        <div class="card-detail-cont">
          <span v-if="item.registered_time"
            >注册时间:{{ item.registered_time }}</span
          ><br v-if="item.registered_time" />
          <span v-if="item.registered_capital"
            >注册资本:{{ item.registered_capital }}</span
          ><br v-if="item.registered_capital" />
          <span v-if="item.employe_scale"
            >人员规模:{{ item.employe_scale }}</span
          ><br v-if="item.employe_scale" />
          <span
            v-if="item.industry"
            :class="
              !item.registered_time ||
              !item.registered_capital ||
              !item.employe_scale
                ? ''
                : 'over-industry'
            "
            >行业:{{ item.industry }}</span
          ><br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEsRecommend } from '../../api/user'
export default {
  name: 'RecommendList',
  props: {
    searchStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      recomListArr: [],
      showRecommend: false,
      packageId: 0
    }
  },
  created() {
    this.getEsRecommend()
  },
  methods: {
    getEsRecommend() {
      if (!this.searchStatus) {
        return
      }
      this.showRecommend = true
      getEsRecommend({}).then(res => {
        this.showRecommend = false
        if (res.code === 60000) {
          this.recomListArr = res.data.companies
          this.packageId = res.data.packageId
        } else {
          this.recomListArr = []
        }
      })
    },
    goRecommendPage(val) {
      this.$router.push({
        path: '/keydetail/keydetail',
        query: {
          companyUuid: val.company_uuid,
          packageId: this.packageId,
          showCompanyNameList: false
        }
      })
    }
  }
}
</script>
<style scoped>
.recom-list-cont {
  height: 100%;
  width: 100%;
}
.recom-list-card {
  width: 223px;
  height: 140px;
  /* box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.11); */
  border: 1px solid rgba(0, 0, 0, 0.11);
  display: inline-block;
  margin: 20px 0 20px 50px;
  padding: 9px 11px 0 11px;
  cursor: pointer;
  vertical-align: top;
}
.recom-title-cont {
  height: 55px;
  line-height: 65px;
  position: relative;
}
.recom-title-cont::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 16px;
  background: url('../../assets/recom_tag.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 25px;
  left: 18px;
}
.recom-title-cont span {
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  height: 55px;
  padding-left: 40px;
}
.crad-title {
  height: 17px;
  font-size: 12px;
  font-weight: 600;
  color: #2e2e2e;
  line-height: 17px;
}
.over-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-tag-com {
  margin-top: 4px;
  height: 16px;
  width: 100%;
  overflow: hidden;
}
.card-tag-com {
  display: inline-block;
}
.card-tag-all {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  padding: 0 6px;
  background: #ebfaf9;
  border-radius: 2px;
  color: #41cabf;
  margin-right: 5px;
}
.card-detail-cont {
  color: #8a8a8a;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 7px;
  margin-right: 5px;
}
.over-industry {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
}
</style>
