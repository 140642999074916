var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "new_page_cont clearfix" }, [
    _vm.pageStatus.total && _vm.isVip !== 1
      ? _c(
          "div",
          { staticClass: "new_go" },
          [
            _c("el-input", {
              staticClass: "new_page-input",
              attrs: {
                placeholder: "",
                onkeyup: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
              },
              on: { change: _vm.handleInputChange },
              model: {
                value: _vm.goNum,
                callback: function ($$v) {
                  _vm.goNum = $$v
                },
                expression: "goNum",
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "page-go", on: { click: _vm.goPageNum } },
              [_vm._v("GO")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.pageStatus.total
      ? _c(
          "div",
          { staticClass: "new_page_footer" },
          [
            _c("el-pagination", {
              ref: "pageCheck",
              attrs: {
                "current-page": _vm.pageStatus.pageNo,
                "page-sizes": [10, 20, 50, 100, 200, 300],
                "page-size": _vm.pageStatus.pageSize,
                layout: "total, sizes, prev, pager, next",
                total: _vm.pageStatus.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }