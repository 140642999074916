var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.previsionArr && _vm.previsionArr.length
      ? _c(
          "div",
          { staticClass: "choose-version-cont" },
          _vm._l(_vm.previsionArr, function (item, index) {
            return item.hide === 0
              ? _c("div", { key: index, staticStyle: { flex: "1" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "new-meal-type",
                      on: {
                        click: function ($event) {
                          return _vm.chooseMealBuy(item)
                        },
                      },
                    },
                    [
                      item.recommend
                        ? _c("i", { staticClass: "meal-commend-tag" })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "meal-buy-icon",
                        class:
                          _vm.chooseMeal === item.version
                            ? "meal-buy-yes"
                            : "meal-buy-no",
                      }),
                      _vm._v(" "),
                      item.version === "s7"
                        ? _c(
                            "div",
                            {
                              staticClass: "new-simple-header",
                              class: _vm.buyType ? "filter-not-allowed" : "",
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.versionTitle) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.version === "s8"
                        ? _c(
                            "div",
                            { staticClass: "new-simple-header new-company-bg" },
                            [
                              _c("p", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.versionTitle) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.version === "s9"
                        ? _c(
                            "div",
                            { staticClass: "new-simple-header new-super-bg" },
                            [
                              _c("p", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.versionTitle) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "new-version-cont",
                          class:
                            _vm.buyType && item.version === "s7"
                              ? "filter-not-allowed"
                              : "",
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "\n                        首充赠送条数: " +
                                _vm._s(item.firstFree) +
                                " 条\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                        月套餐: " +
                                _vm._s(item.monthFree) +
                                " 条/月\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.cards) +
                                " 张订阅卡\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          item.accounts
                            ? _c("p", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.accounts) +
                                    " 个子账号\n                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.recommend
                            ? _c("p", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.recommend) +
                                    " 个定制线索包\n                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "version-price-cont" }, [
                            _c("p", { staticClass: "version-old-price" }, [
                              item.originalPrice
                                ? _c("span", [
                                    _vm._v(_vm._s(item.originalPrice) + "RMB"),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "version-new-price" }, [
                              _c(
                                "span",
                                { staticStyle: { color: "#FE6A5F" } },
                                [_vm._v(_vm._s(item.price) + "RMB")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { color: "#525252" } },
                                [
                                  _vm._v(
                                    "/" +
                                      _vm._s(
                                        item.unit === "month" ? "月" : "年"
                                      )
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e()
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }