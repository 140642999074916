var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "common-valid-phone" }, [
    _c("div", { staticClass: "reset-phone" }, [
      _c("div", { staticClass: "reset-icon" }, [_vm._v("+86")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.validPhone,
            expression: "validPhone",
          },
        ],
        staticClass: "reset-input-phone",
        class: _vm.error.errorPhone ? "input-error" : "",
        attrs: {
          oninput: "value=value.replace(/[^\\d]/g,'')",
          placeholder: "输入手机号码",
          maxlength: "11",
        },
        domProps: { value: _vm.validPhone },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.validPhone = $event.target.value
            },
            _vm.phoneChangeLa,
          ],
        },
      }),
    ]),
    _vm._v(" "),
    _vm.showCode
      ? _c("div", { staticClass: "reset-code" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.validCode,
                expression: "validCode",
              },
            ],
            class: _vm.error.errorCode ? "input-error" : "",
            attrs: {
              oninput: "value=value.replace(/[^\\d]/g,'')",
              placeholder: "验证码",
              maxlength: "8",
            },
            domProps: { value: _vm.validCode },
            on: {
              change: _vm.codeChangeLa,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.validCode = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "getCodeBtn",
              staticStyle: { "text-align": "center" },
              on: { click: _vm.getPhoneCode },
            },
            [_vm._v("\n      " + _vm._s(_vm.getCodeNum) + "\n    ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.errorMsg
      ? _c("p", { attrs: { id: "error" } }, [_vm._v(_vm._s(_vm.errorMsg))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }