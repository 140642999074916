import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

// 系统配置
export const getSystemConfig = async params => {
  const api = API('GET_SYSTEM_CONFIG')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const saveErrorHandel = async params => {
  const api = API('errorHandel')
  return await BASE_REQUEST({ api: api, opt: params })
}
