var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "SetColumn-main" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loadingHeader,
            expression: "loadingHeader",
          },
        ],
        staticClass: "main",
      },
      [
        _c("el-transfer", {
          staticClass: "trans-dt",
          attrs: {
            props: { key: "field", label: "name" },
            "target-order": "push",
            titles: ["表头显示字段", "表头隐藏字段"],
            data: _vm.columns,
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "div",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.reset } },
            [_vm._v("恢复默认")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }