<template>
  <div class="DtDropDown-main">
    <el-container>
      <el-header>
        <div class="search">
          <el-input
            size="mini"
            v-model.trim="search"
            placeholder="快捷搜索"
            @input="changeInput"
            autofocus
          ></el-input>
        </div>
      </el-header>
      <el-main>
        <slot v-bind:filterList="filterList"></slot>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'DtDropDown',
  data() {
    return {
      search: '',
      filterList: []
    }
  },
  props: {
    sourceList: {
      type: Array,
      default: () => []
    },
    targetValue: {
      type: String,
      default: 'name'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.filterList = [...this.sourceList].map(i => {
        i['htmlEl'] = i[this.targetValue]
        return i
      })
    })
  },
  methods: {
    changeInput() {
      this.filterList = [
        ...this.sourceList
          .filter(i => i[this.targetValue].indexOf(this.search) !== -1)
          .map(item => {
            item.htmlEl = item[this.targetValue].replaceAll(
              this.search,
              `<span style="color: red">${this.search}</span>`
            )
            return item
          })
      ]
    }
  }
}
</script>

<style scoped>
.search {
  height: 100%;
  padding: 0 10px 10px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
</style>
