<template>
  <span></span>
</template>

<script>
import { Loading } from 'element-ui'
export default {
  name: 'DtLoading',
  computed: {
    loadingShow() {
      return this.$store.state.plugin.loading.show
    },
    loadingText() {
      return this.$store.state.plugin.loading.text
    },
    loadingRun() {
      return this.$store.state.plugin.loading.run
    }
  },
  data() {
    return {
      loading: undefined
    }
  },
  methods: {
    close() {
      typeof this.loading === 'object' ? this.loading.close() : ''
      this.$store.dispatch('hideLoading')
    }
  },
  watch: {
    loadingShow(show) {
      if (show) {
        this.loading = Loading.service({
          text: this.loadingText
        })
        // 已服务运行的loading需要异步关闭
        this.$nextTick(() => {
          typeof this.loadingRun === 'function'
            ? this.loadingRun(this.close)
            : ''
        })
      }
    }
  }
}
</script>

<style scoped></style>
