var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "store-map-info" }, [
    _c("div", { staticClass: "name" }, [
      _vm._v(_vm._s(_vm.infoData.companyName)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "label" }, [
      _c("span", [_vm._v("地址")]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.infoData.address))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }