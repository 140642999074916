<template>
  <div class="user-container">
    <Header></Header>
    <div class="user-content" v-if="reqResult">
      <div class="pay-succ-page" style="min-width:550px">
        <div class="pay-succ-header">
          <p>支付成功!</p>
        </div>
        <div class="pay-pay-header">
          <div class="pay-header-cont" style="padding-left:77px;">
            <p>
              <span class="pay-cont-name">商品订单:</span>
              <span class="pay-cont-num">{{ resultData.orderNo }}</span>
            </p>
            <p>
              <span class="pay-cont-name">订单金额:</span>
              <span class="pay-cont-num">￥{{ resultData.amount }}</span>
            </p>
            <p>
              <span class="pay-cont-name">创建时间:</span>
              <span class="pay-cont-num">{{ resultData.createdAt }}</span>
            </p>
            <p>
              <span class="pay-cont-num" style="display:block;height:35px;"
                >若有疑问请与客服联系，我们将尽快为您提供服务。</span
              >
              <span class="pay-cont-num">客服电话：400-9965711</span>
            </p>
            <span
              class="pay-footer-right"
              @click="goNextHome"
              style="margin-top: 81px;"
              >返回</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="user-content" v-else>
      <div class="pay-succ-page" style="min-width:550px">
        <div class="pay-error-header">
          <p>支付失败!</p>
        </div>
        <div class="pay-pay-header">
          <div class="pay-header-cont" style="padding-left:77px;">
            <p>
              <span class="pay-cont-name">商品订单:</span>
              <span class="pay-cont-num">{{ resultData.orderNo }}</span>
            </p>
            <p>
              <span class="pay-cont-name">订单金额:</span>
              <span class="pay-cont-num" v-if="resultData.amount"
                >￥{{ resultData.amount }}</span
              >
            </p>
            <p>
              <span class="pay-cont-name">创建时间:</span>
              <span class="pay-cont-num">{{ resultData.createdAt }}</span>
            </p>
            <p>
              <span class="pay-cont-num" style="display:block;height:35px;"
                >请与客服联系，我们将尽快为您提供服务。</span
              >
              <span class="pay-cont-num">客服电话：400-9965711</span>
            </p>
            <!-- <span class="pay-footer-right" @click="goNextHome" style="margin-top: 81px;width:200px;background:#909399;">重新购买</span> -->
            <span
              class="pay-footer-right"
              @click="goNextHome"
              style="margin-top: 81px;width:200px;background:#41CABF;"
              >返回</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/views/login/header'
import { getPayResult } from '@/api/pay'
export default {
  data() {
    return {
      reqResult: false,
      resultData: {},
      orderNo: ''
    }
  },
  components: {
    Header
  },
  mounted() {},
  created() {
    const localId = localStorage.getItem('DTbirdOrder')
    if (localId) {
      this.orderNo = localId
    }
    this.getPayResult()
  },
  methods: {
    getPayResult() {
      const params = {
        orderNo: this.orderNo
      }
      getPayResult(params).then(res => {
        if (res.code === 60000) {
          if (res.data.status) {
            this.reqResult = true
          } else {
            this.reqResult = false
          }
          this.resultData = res.data.order
        }
      })
    },
    goNextHome() {
      this.$router.push({ path: '/user-center/user-center' })
      localStorage.removeItem('DTbirdOrder')
    }
  }
}
</script>

<style scoped>
@import '../../styles/buypay.scss';
.content-title ::v-deep .el-loading-spinner .circular {
  width: 22px !important;
  height: 22px !important;
}
.go_next_page {
  height: 79px;
  line-height: 89px;
  display: inline-block;
  margin-left: 20px;
  color: #8a8a8a;
  cursor: pointer;
}
</style>
