<template>
  <div class="body">
    <div class="row">
      <el-form :model="form" ref="form" label-width="auto">
        <el-row :gutter="20">
          <el-col :span=" (item.key === 'remark' || item.key ==='mark') ? 24 : 12" v-for="item in formAttr"
                  :key="item.key">
            <el-form-item :label="item.label" :prop="item.key" :rules="item.rules">
              <el-select v-model="form.fromType" placeholder="请选择来源" v-if="item.key === 'source'" style="width: 100%">
                <el-option :label="_item.name" :value="_item.id" v-for="_item in sourceList"
                           :key="_item.id"></el-option>
              </el-select>
              <el-select v-model="form.isKp" placeholder="请选择" v-else-if="item.key === 'isKp'" style="width: 100%;">
                <el-option label="关键人" value="1"></el-option>
                <el-option label="中间人" value="2"></el-option>
                <el-option label="普通" value="3"></el-option>
              </el-select>
              <el-select v-model="form.mark" placeholder="请选择状态" v-else-if="item.key === 'mark' && !isNewCreate"
                         style="width: 100%">
                <el-option :label="_item.name" :value="_item.value" v-for="_item in markDescList"
                           :key="_item.value"></el-option>
              </el-select>
              <el-select filterable allow-create remote
                         default-first-option
                         clearable
                         :placeholder="`请输入${ item.label }`"
                         :remote-method="getCompanyList"
                         v-model="form.companyName"
                         v-else-if="item.key ==='companyName'"
                         style="width: 100%">
                <el-option
                  v-for="item in companyList"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <el-input :type="item.type"
                        v-else
                        v-model.trim="form[item.key]"
                        :placeholder="`请输入${ (item.label === '手机' || item.label === '座机') ?  item.label + '号码' : item.label }`"
                        maxlength="100"
                        :disabled="type === 'update' && item.key ==='nickName' "
                        :show-word-limit="item.type === 'textarea'">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="btn">
      <el-button type="info" @click="cancel" size="small">
        取消
      </el-button>
      <el-button type="primary" @click="save" size="small">
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import { poolContactCreate, poolContactUpdate, companyList, getJmPhone } from '@/api/pool'
import debounce from 'lodash/debounce'
import { getDetailAsignOpthon } from '@/api/thread'
import { getCusTagsDdic } from '@/api/customerpond'

export default {
  name: 'createContact',
  data () {

    let validateMobile = (rule, value, fcb) => {
      if (!value) {
        fcb()
      }
      if (value && value.toString().length === 11 && value.toString().startsWith('1')) {
        fcb()
      } else {
        fcb(new Error('手机号格式错误'))
      }
    }

    return {
      companyList: [],
      sourceList: [],
      markDescList: [],
      markDescListDict: {},
      form: {
        contact: '',
        isKp: '',
        department: '',
        position: '',
        mobile: '',
        landLine: '',
        wechat: '',
        remark: '',
        companyName: '',
        fromType: '',
        fromTypeDesc: '',
        nickName: '',
        companyUuid: '',
        score: 0,
        mark: '',
        gradeId: '',
        stateId: ''
      },
      formAttr: [
        {
          label: '姓名',
          key: 'contact',
          type: 'text',
          rules: [
            { required: true, message: '请输入姓名', trigger: 'change' },
            { max: 20, message: '最多 20 个字符', trigger: 'change' }
          ]
        },
        {
          label: '昵称',
          key: 'nickName',
          type: 'text',
          rules: [
            { max: 30, message: '最多 10 个字符', trigger: 'change' }
          ]
        },
        {
          label: '身份',
          key: 'isKp',
          type: 'select',
          rules: []
        },
        {
          label: '来源',
          key: 'source',
          type: 'select',
          rules: []
        },
        {
          label: '部门',
          key: 'department',
          type: 'text',
          rules: [
            { max: 20, message: '最多 20 个字符', trigger: 'change' }
          ]
        },
        {
          label: '职位',
          key: 'position',
          type: 'text',
          rules: [
            { max: 20, message: '最多 20 个字符', trigger: 'change' }
          ]
        },
        {
          label: '座机',
          key: 'landLine',
          type: 'text',
          rules: [
            { max: 20, message: '座机错误', trigger: 'blur' }
          ]
        },
        {
          label: '手机',
          key: 'mobile',
          type: 'text',
          rules: [
            { validator: validateMobile, trigger: 'blur' }
          ]
        },
        {
          label: '微信',
          key: 'wechat',
          type: 'text',
          rules: [
            { max: 20, message: '最多 20 个字符', trigger: 'change' }
          ]
        },
        {
          label: '归属企业',
          key: 'companyName',
          type: 'text',
          rules: [
            { max: 20, message: '最多 20 个字符', trigger: 'change' }
          ]
        }
      ],

      type: 'create',
      id: null
    }
  },

  watch: {
    // 'form.companyName':{
    //   handler: function(val){
    //     this.getCompanyList(val)
    //   },
    //   immediate: true
    // }
  },

  mounted () {
    const contactData = this.$store.state.plugin.modal.options.contactData
    if (this.$store.state.plugin.modal.options.isNewCreate) {
      this.formAttr = [...this.formAttr,
        {
          label: '备注',
          key: 'remark',
          type: 'textarea',
          rules: [
            { max: 255, message: '最多 255 个字符', trigger: 'change' }
          ]
        },]
    } else {

      if (contactData) {
        this.formAttr = [...this.formAttr,
          {
            label: '状态',
            key: 'mark',
            type: 'select',
            rules: []
          },
          {
            label: '备注',
            key: 'remark',
            type: 'textarea',
            rules: [
              { max: 255, message: '最多 255 个字符', trigger: 'change' }
            ]
          },
        ]
      } else {
        this.formAttr = [...this.formAttr,
          {
            label: '状态',
            key: 'mark',
            type: 'select',
            rules: []
          },
          {
            label: '备注',
            key: 'remark',
            type: 'textarea',
            rules: [
              { max: 255, message: '最多 255 个字符', trigger: 'change' }
            ]
          },
        ]
      }
    }
    this.sourceList = this.$store.state.plugin.modal.options.sourceList.filter(res => res.name !== '全部')
    this.getDetailAsignOpthon()
    // this.getCompanyList('');
    if (contactData) {
      this.getSelectOptions()
      this.form.contact = contactData.contact
      this.form.isKp = contactData.isKp ? contactData.isKp + '' : ''
      this.form.department = contactData.department
      this.form.position = contactData.position
      this.getMobile(contactData.id, (mobile) => {
        this.form.mobile =  mobile
      })
      this.form.wechat = contactData.wechat
      this.form.remark = contactData.remark
      this.form.companyName = contactData.companyName
      this.form.nickName = contactData.nickName
      this.form.fromType = contactData.fromType
      this.form.companyUuid = contactData.companyUuid
      this.form.landLine = contactData.landLine
      this.form.mark = contactData.mark
      this.form.gradeId = contactData.gradeId
      this.form.stateId = contactData.stateId
      this.id = contactData.id
      this.type = 'update'
    }
  },

  methods: {

    getMobile(id, fn) {
      getJmPhone({ id: id }).then(res => {
        if (res.code === 60000) {
          if (res.data) {
            typeof fn === 'function' ? fn(parseInt(res.data)) : ''
          } else {
            typeof fn === 'function' ? fn('') : ''
          }
        } else {
          typeof fn === 'function' ? fn('') : ''
        }
      })
    },

    getSelectOptions () {
      getCusTagsDdic({ type: 1 }).then(res => {
        if (res.code === 60000) {
          this.allLevel = res.data
        }
      })
      getCusTagsDdic({ type: 3 }).then(res => {
        if (res.code === 60000) {
          this.allStatus = res.data
        }
      })
    },
    getDetailAsignOpthon () {
      getDetailAsignOpthon().then(res => {
        if (res.code === 60000 && res.data && res.data instanceof Array) {
          this.markDescList = res.data
          this.markDescList.forEach(item => {
            this.markDescListDict[item.id] = item.name
          })
        }
      })
    },
    selectCompany (e) {
      console.log(e)
    },

    getCompanyList (val) {
      console.log(val)
      if (!val) {
        return
      }

      this.companyList = [val]
      this.form.companyName = this.companyList[0]
      const params = {
        pageId: 6002,
        companyName: val
      }
      companyList(params).then((res) => {
        console.log(res)
        if (res.code === 60000) {
          this.companyList = [val, ...res.data.filter(item => item)]
        }
      })
    },

    cancel () {
      if (this.type === 'update') {
        this.$emit('cancelPop', true)
      } else {
        this.$store.dispatch('hideModal')
      }
    },

    save: debounce(function () {
      this.$refs['form'].validate((validate) => {
        if (!validate) {
          return false
        }
        if (this.type === 'update') {
          console.clear()
          console.log(this.markDescList)

          poolContactUpdate(Object.assign({}, this.form, { id: this.id })).then((res) => {
            if (res && res.code === 60000) {
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: 'success',
                duration: 2000
              })
              if (this.form.fromType) {
                const formType = this.sourceList.find((i) => {return i.id == this.form.fromType})
                this.form.fromTypeDesc = formType ? formType.name : ''
              }

              if (res.data) {
                this.form.companyUuid = res.data.companyUuid
                this.form['score'] = res.data.score
                this.form['bindWechat'] = res.data.bindWechat
                this.form['updateUser'] = res.data.updateUser
                this.form['updatedAt'] = res.data.updatedAt
                this.form['markDesc'] = res.data.markDesc
              }
              console.log(this.form)
              this.$bus.$emit('closeChild', this.form)
            }
          }).catch(() => {
            this.$bus.$off()
            this.$store.dispatch('showTip', {
              text: '出错了',
              type: 'error',
              duration: 2000
            })
          })
          return false
        }
        poolContactCreate(this.form).then((res) => {
          if (res && res.code === 60000) {
            //成功 提示
            this.$store.dispatch('showTip', {
              text: '新建成功',
              type: 'success',
              duration: 1000
            })
            // 更新数据
            this.$store.commit('update', {
              target: this.$store.state.plugin.modal.options,
              data: {
                refresh: true
              }
            })
            //关闭modal
            this.$store.dispatch('hideModal')
          }
        }).catch((error) => {
          this.$store.dispatch('showTip', {
            text: '出错了, 请稍后重试',
            type: 'error',
            duration: 1000
          })
        })
      })
    }, 1000, { trailing: false, leading: true }),
  }
}
</script>

<style scoped>
.body {
  height: auto;
  width: 100%;
  padding: 30px 40px 20px 40px;
  box-sizing: border-box;
}

.row {
  height: auto;
  width: 100%;
}

.btn {
  padding-top: 0;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
</style>
