var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "robot-index-main" }, [
    _c("div", { staticClass: "robot-main" }, [
      _c(
        "div",
        { staticStyle: { width: "100%" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 50 } },
            _vm._l(_vm.list, function (item) {
              return _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "item", attrs: { shadow: "hover" } },
                    [
                      _c(
                        "div",
                        { staticClass: "image" },
                        [
                          _c("el-image", {
                            staticClass: "images",
                            attrs: { src: item.image, fit: "contain" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "desc" }, [
                        _vm._v(_vm._s(item.desc)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btn" },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v(
                              "\n                申请试用\n              "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }