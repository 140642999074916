<template>
  <div class="update-container">
    <Header></Header>
    <div class="reser-title" v-if="!checkCode">验证手机验证码</div>
    <div class="update-form" style="position:relative;" v-if="checkCode">
      <p>您的用户名为：</p>
      <p style="font-size: 20px;margin-top:50px;color:black;font-weight:bold;">{{userName}}</p>
      <div class="update-bt new_btn_sty" v-loading="loading" @click="goLogin">去登录</div>
    </div>
    <div class="update-form" style="position:relative;padding-top: 60px;" v-else>
      <div class="reset-code">
        <input :class="error.errorCode ? 'input-error':''"
                oninput="value=value.replace(/[^\d]/g,'')" 
                placeholder="验证码" 
                type="text"
                @change="codeChangeLa"
                v-model="validCode"
                maxlength="8"/>
        <div style="text-align:center;" ref="getCodeBtn" @click="getPhoneCode">{{getCodeNum}}</div>
      </div>
      <p v-if="errorMsg" id="error">{{errorMsg}}</p>
      <div class="update-bt" style="margin-top:60px;" v-loading="loading" @click="getUserName">确认</div>
    </div>
  </div>
</template>

<script>
  import Header from '../header.vue'
  import {getUserName, getSmsSend} from '@/api/usercenter'
  export default{
    components:{
      Header
    },
    data(){
      return{
        loading:false,
        userName: '',
        checkCode: false,
        phone:'',
        error:{
          errorCode:false
        },
        errorMsg: '',
        getCodeNum: 59,
        validCode: null,
        
      }
    },
    created() {
      const userPhone = sessionStorage.getItem('needPhone')
      if (!userPhone && !this.$route.params.phone) {
        this.$router.push({path: '/resetPwd', query: {type: 'usn'}})
        return
      }
      if (userPhone) {
        this.phone = userPhone
      } else {
        sessionStorage.setItem('needPhone', this.$route.params.phone)
        this.phone = this.$route.params.phone
      }

      const setTime = sessionStorage.getItem('getCodeNum')
      if (setTime === '获取验证码') {
        this.getCodeNum = setTime
        return
      } else {
        this.getCodeNum = Number(setTime)
        this.startTime()
      }
    },
    mounted() {
      
      // if(!this.phone){
      //   this.$router.replace({path:'/login'})
      // }
    },
    methods:{
        // 获取手机验证码
      getPhoneCode() {
        if (this.getCodeNum !== '获取验证码') {
          return
        }
        const params = {
          mobile: this.phone
        }
        getSmsSend(params).then(res => {
          if (res.code === 60000) {
            this.getCodeNum = 60
            this.startTime()
            this.$message({
               message: '获取验证码成功',
               type: 'success'
            })
            sessionStorage.removeItem('needPhone')
            this.$refs.getCodeBtn.classList.add('daojishi')
          }
        })
      },
      codeChangeLa() {
        this.error.errorCode = false
        this.errorMsg = ''
      },
      // 开始计时
      startTime() {
        if (this.getCodeNum == 0) {
          this.getCodeNum = '获取验证码'
          sessionStorage.setItem('getCodeNum', this.getCodeNum)
          this.$refs.getCodeBtn.classList.remove('daojishi')
          return
        } else {
          this.getCodeNum--
          sessionStorage.setItem('getCodeNum', Number(this.getCodeNum))
        }
        setTimeout(() => {
          this.startTime()
        }, 1000)
      },
      goLogin() {
        this.$router.replace({path:'/login'})
      },
      getUserName() {
        this.errorMsg = ''
        this.error.errorCode = false
        if (!this.validCode) {
          this.errorMsg = "请输入验证码"
          this.error.errorCode = true
          return
        }
        if (this.validCode.length !== 6) {
          this.errorMsg = "请输入6位数字验证码"
          this.error.errorCode = true
          return
        }
        this.loading = true
        const params = {
          mobile: this.phone,
          code: this.validCode
        }
        getUserName(params).then(res => {
          this.loading = false
          if (res.code === 60000) {
              this.checkCode = true
              this.userName = res.data.userName
          }
        })
      },
    }
    
  }
</script>

<style scoped>
.daojishi {
  background: #F5F6F7;
}
    .reset-code{
    display: flex;
    margin-top: 26px;
    width: 350px;
    justify-content: space-between;
  }
  
  .reset-code input{
    width: 170px;
    height: 46px;
    line-height: 46px;
    background:none;  
    outline:none;  
    border:0px;
    /* 输入框默认边框 */
    border:1px solid #C0BEBE;
    padding:0 20px;
    color: #2E2E2E;
  }
  
 .reset-code input:focus{
    border-color:#41CABF;
  }
  
  .reset-code div{
    width: 170px;
    line-height: 44px;
    /* 输入框默认边框 */
    border:1px solid #41CABF;
    padding:0 20px;
    color: #41CABF;
    font-size: 14px;
    cursor: pointer;
  }
  .input-error{
    border-color: #FF0000 !important;
  }
  .update-container{
    width: 100%;
    height: 100%;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #F5F6F7;
    overflow-x: hidden;
  }
  .reser-title{
    margin-top: 50px;
    font-size: 30px;
    color: #494949;
    font-weight: 600px;
  }

  .update-form{
    display: flex;
    width: 1100px;
    height: 400px;
    min-width: 500px;
    min-height: 400px;
    margin-top: 49px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .update-form p{
    color: #8A8A8A;
    font-size: 16px;
    font-weight: 600px;
    margin-top: 56px;
  }
  .new_btn_sty {
    position: absolute;
    bottom: 100px;
  }
  
  .update-bt{
    width:350px;
    line-height: 46px;
    background: #41CABF;
    color: #FFFFFF;
    margin-top: 26px;
    cursor: pointer;
  }
    #error{
    color: #FF0000;
    margin-top: 10px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    width: 350px;
  }
</style>
