var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "notice-main" }, [
    _c("div", { staticClass: "tip" }, [
      _vm._v(
        "\n    恭喜您，账号已注册成功。产品顾问将根据您的行业和业务需求，为您开通系统权限。扫码联系小助手[领鸟云晓晓]，获取产品使用帮助\n  "
      ),
    ]),
    _vm._v(" "),
    _c("img", { staticClass: "registerImg", attrs: { src: _vm.Img } }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.handleLogin },
          },
          [_vm._v("\n      返回\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }