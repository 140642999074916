<template>
  <div class="body">
    <div v-if="currentTagName" class="opera">
      <el-link
        icon="el-icon-close"
        @click="delTag(currentTagName.data, currentTagName.name)"
        class="opera-f"
        >删除
      </el-link>
      <el-popover
        @show="popShow"
        ref="popover"
        :disabled="false"
        placement="right-start"
        width="200"
        trigger="click"
      >
        <el-link slot="reference" icon="el-icon-edit-outline" class="opera-f"
          >重命名</el-link
        >
        <div class="pop">
          <el-input
            v-model="newTagName"
            size="mini"
            placeholder="请输入标签"
            autofocus
          ></el-input>
          <div class="pop-btn">
            <el-button size="mini" @click="closePop">
              取消
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="reNameTag(currentTagName.data, currentTagName.name)"
            >
              确认
            </el-button>
          </div>
        </div>
      </el-popover>
    </div>

    <div class="saved-search" v-if="savedSearchList.length">
      <el-tag
        v-for="item in savedSearchList"
        :effect="
          item.name === (currentTagName ? currentTagName.name : '')
            ? 'dark'
            : 'plain'
        "
        class="tag"
        size="small"
        @click="searchPlusHandel(item.data, item.name)"
      >
        {{ item.name }}
      </el-tag>
    </div>
    <el-row :gutter="10">
      <div v-for="item in filterList" :key="item.id">
        <el-col :span="23" class="filter-item">
          <search-item
            :drop-data="item"
            :follow-user="followUser"
            :tab-type="type"
            :ref="`filter${item.id}`"
            :filter-field="filterField"
            :filter-id="item.id"
          >
          </search-item>
        </el-col>
        <el-col :span="1">
          <div class="del-icon">
            <i class="el-icon-delete" @click="delFilter(item.id)"></i>
          </div>
        </el-col>
      </div>

      <el-col :span="24">
        <div>
          <i
            class="el-icon-circle-plus-outline add-icon"
            @click="addFilter()"
          ></i>
        </div>
      </el-col>
    </el-row>
    <div class="footer">
      <div class="save-this-search">
        <span>是否保存常用查询 </span>
        <span><el-switch v-model="isSave"/></span>
        <el-input
          v-show="isSave"
          size="small"
          placeholder="请输入标签名称"
          v-model="searchName"
          class="search-save-input"
        ></el-input>
      </div>
      <div>
        <el-button size="small" @click="closedDialog">取消</el-button>
        <el-button type="primary" size="small" @click="confirm">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// localStorage:: searchPlus保存的高级筛选项
// savedSearchPlus1、savedSearchPlus2、savedSearchPlus3、savedSearchPlus4 保存的上次搜索
import searchItem from '@/views/customerpond/dialog/searchItem'
import { getSearchPlus, setSearchPlus } from '@/api/user'

export default {
  name: 'searchPlus',
  components: {
    searchItem
  },
  data() {
    return {
      index: 10086,
      filterList: [
        {
          id: -1
        }
      ],
      filterField: [],
      savedSearchList: [], // 当前被选择的保存的搜索项目
      isSave: false,
      searchName: '',
      type: 1, // tab的类型
      oldName: '',
      followUser: '',

      currentTagName: null,
      newTagName: '',
      searchPlus: []
    }
  },
  props: {
    addMax: {
      type: Number,
      default: 9
    },
    // 是否保存当前搜索
    isSaveCurrent: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    const options = this.$store.state.plugin.modal.options
    console.log(options)
    this.filterField = options.filterField
    this.type = options.type
    this.followUser = options.followUser
    let that = this
    this.getSearchPlus().then(() => {
      // let searchPlus = localStorage.getItem('searchPlus');
      if (that.searchPlus.length) {
        that.savedSearchList = that.searchPlus.filter(
          item => parseInt(item.type) === parseInt(this.type)
        )
      }
    })

    if (this.isSaveCurrent) {
      let saveSearchPlus = localStorage.getItem(`savedSearchPlus${this.type}`)
      if (saveSearchPlus && saveSearchPlus.startsWith('[')) {
        const res = JSON.parse(saveSearchPlus)
        if (res instanceof Array && res.length) {
          this.filterList = JSON.parse(saveSearchPlus)
        }
      }
    }
  },
  methods: {
    async getSearchPlus() {
      const params = {
        type: this.type
      }
      await getSearchPlus(params).then(res => {
        console.log(res)
        this.searchPlus = res.data ? JSON.parse(res.data) : []
      })
    },
    async setSearchPlus(str) {
      const params = {
        type: this.type,
        cacheJson: str
      }
      await setSearchPlus(params).then(res => {
        console.log(res)
      })
    },
    closedDialog() {
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          refresh: false
        }
      })
      this.$store.dispatch('hideModal')
    },
    confirm() {
      if (this.isSave && !this.searchName) {
        this.$store.dispatch('showTip', {
          text: '请输入标签',
          type: 'warning'
        })
        return
      }
      let result = []
      let saveResult = []
      let remark = ''
      let showShiftData = []

      // 处理搜索结果
      this.filterList.forEach(item => {
        const refs = this.$refs[`filter${item.id}`]
        if (refs && refs[0]) {
          let res = refs[0].getResult()
          let fuckData = refs[0].getFuck()
          if (res.data) {
            res.type === 'remark' ? (remark = res.data) : result.push(res.data)
          }
          if (fuckData.data) {
            showShiftData.push(fuckData.data)
          }
          let saveRes = refs[0].getSaveData()
          saveRes ? saveResult.push(saveRes) : ''
        }
      })
      if (this.isSave && !this.saveData(saveResult)) {
        return
      }

      // 更新modal数据
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          searchFilter: result.join(';'),
          remark: remark,
          refresh: true,
          shiftData: showShiftData
        }
      })
      if (this.isSaveCurrent) {
        // 将本次筛选保存
        localStorage.setItem(
          `savedSearchPlus${this.type}`,
          JSON.stringify(saveResult)
        )
      }
      // 关闭modal弹框
      this.$store.dispatch('hideModal')
    },

    // 存在的搜索项目点击事件
    searchPlusHandel(data, name) {
      this.currentTagName = {
        name: name,
        data: data
      }
      this.filterList = JSON.parse(JSON.stringify(data))
    },

    // 增加搜索项
    addFilter() {
      if (this.filterList.length >= this.addMax) {
        this.$store.dispatch('showTip', {
          text: `最多设置${this.addMax}个筛选项`,
          type: 'error'
        })
        return
      }

      let ids = this.filterList.map(item => item.id)
      let maxId = Math.max.apply(null, ids)
      this.index = maxId + 1

      this.filterList.push({
        id: this.index
      })
      this.index += 1
    },

    // 删除搜索项
    delFilter(index) {
      if (this.filterList.length <= 1) {
        this.$store.dispatch('showTip', {
          text: '最少有一个筛选项',
          type: 'error'
        })
        return
      }
      this.filterList = this.filterList.filter(item => item.id !== index)
    },

    // 保存数据
    saveData(data) {
      if (data instanceof Array) {
        if (this.searchPlus.length) {
          const name = this.searchPlus.map(item => item.name)
          if (name.includes(this.searchName)) {
            this.$store.dispatch('showTip', {
              text: '已经存在的标签',
              type: 'error'
            })
            return false
          }

          if (this.searchPlus instanceof Array) {
            let _res = {
              name: this.searchName,
              data: data,
              type: this.type
            }
            this.searchPlus = this.searchPlus.filter(
              item => item.name !== this.oldName
            )
            this.searchPlus.push(JSON.parse(JSON.stringify(_res)))
            this.setSearchPlus(JSON.stringify(this.searchPlus))
            // localStorage.setItem('searchPlus', JSON.stringify(searchPlus))
          }
        } else {
          let _res = [
            {
              name: this.searchName,
              data: data,
              type: this.type
            }
          ]
          this.setSearchPlus(JSON.stringify(_res))
          // localStorage.setItem('searchPlus', JSON.stringify(_res))
        }
      }
      return true
    },

    delTag(data, name) {
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: `确认删除 '${name}' 常用查询`,
        onClose: res => {
          if (res === 'confirm') {
            this.searchPlus = this.searchPlus.filter(item => item.name !== name)
            this.savedSearchList = this.savedSearchList.filter(
              item => item.name !== name
            )
            this.setSearchPlus(JSON.stringify(this.searchPlus))
            this.currentTagName = null
          }
        },
        type: 'warning'
      })
    },

    reNameTag(data, name) {
      if (!this.newTagName) {
        return
      }
      if (this.newTagName === name) {
        this.closePop()
        return
      }
      const _name = this.searchPlus.map(item => item.name)
      if (_name.includes(this.newTagName)) {
        this.$store.dispatch('showTip', {
          text: '已经存在的标签',
          type: 'error'
        })
        return
      }
      this.searchPlus.forEach(item => {
        if (item.name === name) {
          item.name = this.newTagName
        }
      })
      this.setSearchPlus(JSON.stringify(this.searchPlus))
      this.currentTagName = {
        name: this.newTagName,
        data: data
      }
      this.newTagName = ''
      this.closePop()
      this.$store.dispatch('showTip', {
        text: '修改成功',
        type: 'success'
      })
    },

    // 关闭重命名弹框
    closePop() {
      const popover = this.$refs['popover']
      if (popover) {
        popover.doClose()
      }
    },

    popShow() {
      this.newTagName = this.currentTagName ? this.currentTagName.name : '' //信息回填
    }
  }
}
</script>

<style scoped>
.body {
  height: auto;
  width: 100%;
  padding: 10px 20px 0 20px;
  box-sizing: border-box;
}

/*.footer {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*}*/
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: relative;
}
.footer::before {
  content: '';
  height: 1px;
  width: calc(100% + 40px);
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: -20px;
  position: absolute;
  transform: scaleY(0.5);
}

.saved-search {
  width: 100%;
  background-color: #d8dcdc1f;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px 0 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tag {
  margin: 0 10px 10px 0;
  cursor: pointer;
}

.saved-search > .tag:last-child {
  margin-right: auto;
}

.save-this-search {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.save-this-search > span:first-child {
  margin-right: 10px;
}

.search-save-input {
  width: 200px;
  margin-left: 10px;
}

.add-icon {
  font-size: 22px;
  color: #6062667d;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.add-icon:hover {
  color: #41cabf;
}

.del-icon {
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #6062667d;
  cursor: pointer;
}

.del-icon:hover {
  color: #41cabf;
}

.filter-item {
  margin-bottom: 10px;
}

.opera {
  width: 100%;
  padding: 0 0 10px 10px;
  box-sizing: border-box;
}

.opera-f {
  margin-right: 10px;
  font-size: 12px;
}

.pop-btn {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  box-sizing: border-box;
}
</style>
