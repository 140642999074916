<template>
  <div class="companyLink-main">
    <span class="company-name" @click="clickCompany">
      {{ companyName }}
    </span>
  </div>
</template>

<script>
import { enterCompanyDetail } from '@/utils/helper'
export default {
  // 公司名点击跳转到详情
  name: 'companyLink',
  props: {
    companyName: {
      type: String,
      default: ''
    },
    companyUuid: {
      type: String,
      default: ''
    },
    packageId: {
      type: [String, Number],
      default: '0'
    },
    companyList: {
      type: Array,
      default: () => []
    },
    companyIndex: {
      type: [Number, String],
      default: 0
    }
  },

  methods: {
    clickCompany() {
      // console.log(this.companyIndex, 'index123')

      this.$router.push({
        path: '/keydetail/keydetail',
        query: {
          companyName: this.companyName,
          companyUuid: this.companyUuid,
          activeIndex: this.companyIndex,
          packageId: this.packageId,
          showCompanyNameList: false
        }
      })
    }
  }
}
</script>

<style scoped>
.company-name {
  color: #0099cc;
  cursor: pointer;
}
</style>
