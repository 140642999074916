<template>
  <div class="Filter-main" v-loading="loading">
    <div class="filter-item" style="display: flex">
      <div
        v-for="item in filterList.filter(i =>
          ['应用市场', '榜单分类', '榜单类型'].includes(i.name)
        )"
        style="display: flex;margin-right: 50px"
      >
        <div class="title">{{ item.name }}</div>
        <div class="item">
          <el-radio-group v-model="selectRadio[item.field]" size="small">
            <el-radio-button
              :label="_item.name"
              v-for="_item in item.filterOptionList"
            ></el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div
      class="filter-item"
      v-for="item in filterList.filter(i => ['发布地区'].includes(i.name))"
    >
      <div class="title">{{ item.name }}</div>
      <div class="item">
        <el-radio-group
          v-model="selectRadio[item.field]"
          size="small"
          @change="handleAreaChange"
        >
          <el-radio-button
            :label="_item.name"
            v-for="_item in item.filterOptionList"
          ></el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div
      class="filter-item"
      v-for="item in filterList.filter(
        i => !['应用市场', '榜单分类', '榜单类型', '发布地区'].includes(i.name)
      )"
    >
      <div class="title">{{ item.name }}</div>
      <template v-if="item.name === '开发者地区' && !item.filterOptionList">
        <div
          style="display: flex;align-items: center;width: 100%;color: rgba(0,0,0,.4);cursor: pointer"
          @click="tipClick"
        >
          未配置地域权限，请联系客服
        </div>
      </template>
      <div class="item">
        <template v-if="item._type === 'radio'">
          <el-radio-group v-model="selectRadio[item.field]" size="small">
            <el-radio-button
              :label="_item.name"
              v-for="_item in item.filterOptionList"
            ></el-radio-button>
          </el-radio-group>
        </template>
        <template v-else>
          <span
            v-for="btn in item.filterOptionList"
            :class="btn.checked ? 'active' : ''"
            @click="userClick(btn, item)"
          >
            {{ btn.name }}
          </span>
          <el-date-picker
            v-if="item.name === '发布时间'"
            :editable="false"
            :clearable="false"
            size="small"
            v-model="fbTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </template>
      </div>
    </div>
    <div class="filter-item">
      <div class="title">
        已选条件
      </div>
      <div class="item">
        <el-tag v-for="select in selectRadio">
          {{ select }}
        </el-tag>
        <el-tag
          @close="removeSelect(select)"
          v-for="select in selectList"
          closable
        >
          {{ select.name }}
        </el-tag>
        <template v-if="selectList.length">
          <div
            @click="removeAll"
            class="remove"
            style="display: flex;align-items: center;height: 25px;font-size: 12px;background-color:rgba(0,0,0,.3);color: #ffffff;padding: 0 5px;box-sizing: border-box;cursor: pointer"
          >
            清除已选
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { appFilter, appFilterNiuBe } from '@/api/customerpond'
import tip from '@/views/insight/tip'
import { debounce } from 'lodash'
export default {
  name: 'AppFilter',
  data() {
    return {
      loading: true,
      filterList: [],
      selectRadio: {},
      fbTime: [],
      areaCount: 0
    }
  },

  props: {
    count: {
      type: [String, Number],
      default: 0
    }
  },

  created() {
    this.getFilter()
  },

  computed: {
    selectList() {
      let res = []
      const radioList = ['应用市场', '榜单分类', '榜单类型', '发布地区']
      this.filterList.forEach(item => {
        if (!radioList.includes(item.name)) {
          ;(item?.filterOptionList || []).forEach(_item => {
            if (_item.checked) res.push(_item)
          })
        }
      })
      return res
    },

    watchFnc() {
      return [this.selectRadio, this.selectList]
    },

    amAndBD() {
      return [this.selectRadio.appMarket, this.selectRadio.bangDan]
    }
  },

  watch: {
    amAndBD: {
      handler: function() {
        // console.log(val)
        // if (val.appMarket === old.appMarket && val.bangDan === old.bangDan && val.bandType === old.bandType) return
        if (this.selectRadio.appMarket && this.selectRadio.bangDan) {
          const params = {
            market: this.selectRadio.appMarket,
            band: this.selectRadio.bangDan
          }
          appFilterNiuBe(params).then(res => {
            if (res.code === 60000) {
              // console.log(res)
              const list = res?.data || []
              this.filterList.forEach((item, index) => {
                if (item.name === '应用分类') {
                  this.$set(item, 'filterOptionList', list)
                  item.filterOptionList.forEach((_item, _index) => {
                    this.$set(_item, 'pName', item.name)
                    this.$set(_item, 'checked', false)
                    this.$set(_item, 'canDel', true)
                    this.$set(_item, 'id', `${index + 1}-${_index + 1}`)
                  })
                }
              })
            }
          })
        }
      },
      deep: true,
      immediate: true
    },

    watchFnc: {
      handler: debounce(function() {
        this.$emit('selectChange', this.getRes())
      }, 500),
      deep: true
    }
  },

  methods: {
    getRes() {
      return {
        appMarket: this.selectRadio['appMarket'],
        bangDan: this.filterList
          .find(i => i.name === '榜单分类')
          ?.filterOptionList?.find(
            ii => ii.name === this.selectRadio['bangDan']
          )?.value,
        bandType: this.filterList
          .find(i => i.name === '榜单类型')
          ?.filterOptionList?.find(
            ii => ii.name === this.selectRadio['bandType']
          )?.value,
        appClassify:
          this.filterList
            .find(i => i.name === '应用分类')
            ?.filterOptionList?.filter(ii => ii.checked)
            ?.map(iii => iii.val)
            ?.join(',') || null,
        openArea:
          this.filterList
            .find(i => i.name === '开发者地区')
            ?.filterOptionList?.filter(ii => ii.checked)
            ?.map(iii => iii.name)
            ?.join(',') || null,
        publishArea: this.selectRadio['publishArea'],
        count: this.areaCount,
        publishTime: this.fbTime.join(',') || null
      }
    },

    removeAll() {
      this.filterList.forEach(item => {
        ;(item?.filterOptionList || []).forEach(_item => {
          if (_item.canDel) this.$set(_item, 'checked', false)
          this.fbTime = []
        })
      })
    },

    removeSelect(select) {
      const id = select.id
      if (select.pName === '发布时间') this.fbTime = []
      this.filterList.forEach(item => {
        ;(item?.filterOptionList || []).forEach(_item => {
          if (_item.id === id) this.$set(_item, 'checked', false)
        })
      })
    },

    tipClick() {
      this.$store.dispatch('showModal', {
        title: '提示',
        view: tip,
        size: '600px',
        options: {
          title: '扫码联系客服'
        }
      })
    },

    getFilter() {
      appFilter({ type: 1 }).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.filterList = res?.data || []
          this.formatData()
        }
      })
    },

    formatData() {
      const radioList = ['应用市场', '榜单分类', '榜单类型', '发布地区']
      this.filterList.forEach((item, index) => {
        this.$set(item, 'id', `${index + 1}`)
        if (radioList.includes(item.name)) {
          this.$set(item, '_type', 'radio')
        } else {
          this.$set(item, '_type', 'checkbox')
        }
        ;(item?.filterOptionList || []).forEach((_item, _index) => {
          this.$set(_item, 'id', `${index + 1}-${_index + 1}`)
          this.$set(_item, 'pName', item.name)
          // radio不允许删除
          radioList.includes(item.name)
            ? this.$set(_item, 'canDel', false)
            : this.$set(_item, 'canDel', true)
          if (
            _item.name === 'App Store' ||
            _item.name === '总榜' ||
            _item.name === '免费榜' ||
            _item.name === '中国'
          ) {
            this.$set(_item, 'checked', true)
            this.selectRadio = {
              appMarket: 'App Store',
              bangDan: '总榜',
              bandType: '免费榜',
              publishArea: '中国'
            }
          } else {
            this.$set(_item, 'checked', false)
          }
        })
      })
    },

    userClick(btn, source) {
      // 单选
      if (source.name === '发布时间') {
        ;(source?.filterOptionList || []).forEach(item => {
          if (item.name === btn.name) {
            this.$set(item, 'checked', true)
          } else {
            this.$set(item, 'checked', false)
          }
        })

        if (source.name === '发布时间') this.formatSelectTime(btn, source)
      } else {
        this.$set(btn, 'checked', !btn.checked)
      }
    },

    formatSelectTime(btn, source) {
      if (btn.name === '今天') {
        this.fbTime = [
          moment()
            .startOf('day')
            .format('YYYY-MM-DD'),
          moment()
            .endOf('day')
            .format('YYYY-MM-DD')
        ]
      }
      if (btn.name === '近7天') {
        this.fbTime = [
          moment()
            .subtract(7, 'day')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
      }
      if (btn.name === '近30天') {
        this.fbTime = [
          moment()
            .subtract(30, 'day')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
      }
      if (btn.name === '近90天') {
        this.fbTime = [
          moment()
            .subtract(90, 'day')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
      }
    },

    handleAreaChange() {
      this.areaCount++
    }
  }
}
</script>

<style scoped lang="scss">
.Filter-main {
  min-height: 200px;
  .count {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    color: #7a7a7a;
  }
  .filter-item {
    display: flex;
    font-size: 14px;
    padding: 20px 0;
    box-sizing: border-box;
    position: relative;
    &:after {
      content: '';
      display: inline-block;
      height: 1px;
      width: calc(100% - 100px);
      position: absolute;
      left: 100px;
      bottom: 0;
      transform: scaleY(0.5);
      background-color: rgba(0, 0, 0, 0.1);
    }
    .title {
      width: 100px;
      height: 25px;
      line-height: 25px;
    }
    .all {
      width: 50px;
      text-align: center;
      height: 25px;
      line-height: 25px;
      color: #41cabf;
      cursor: pointer;
    }
    .item {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      & > span {
        display: inline-block;
        min-width: 80px;
        width: auto;
        height: 25px;
        line-height: 25px;
        //background-color: forestgreen;
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        border-radius: 3px;
      }
      .active {
        color: #ffffff;
        background-color: #41cabf;
      }
    }
  }
}
</style>
