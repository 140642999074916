<template>
  <div class="qy-financing-page">
    <!-- <div
      v-for="item in chartList"
      :key="item.id"
      v-loading="loading"
      class="chart-dom"
      :id="item.id"
    ></div> -->
    <!-- 图表点击 -->
    <!-- <pe-draw
      :pe-drawer-show="peChartShow"
      @handleDrawClose="peChartShow = false"
    >
      <pe-finance
        :peLoading="peChartLoading"
        :tableList="peChartList"
        :investProps="peChartProps"
        :isMore="isChartMore"
        currentPage="hxSchema"
        @handleMoreEvent="handleChartEvent"
        @handleGroup="handleGroup"
        @handleCompanyDetail="handleCompanyDetail"
      />
    </pe-draw> -->
    <pe-finance
      :tableList="investList"
      :investProps="investProps"
      :peLoading="investLoading"
      :isMore="isMore"
      currentPage="hxSchema"
      @handleMoreEvent="handleMoreEvent"
      @handleGroup="handleGroup"
      @handleCompanyDetail="handleCompanyDetail"
    />
  </div>
</template>

<script>
import { getScreenTagChart, getScreenFinanceList } from '@/api/module'
import * as echarts from 'echarts'
import PeDraw from '@/components/Peculiar/drawer'
import PeFinance from '@/components/Peculiar/financing'
import { enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'QyFinancing',
  data() {
    return {
      loading: false,
      peChartShow: false,
      peChartLoading: false,
      peChartName: '',
      peChartProps: {
        flag: false
      },
      chartPageNo: 1,
      investPageNo: 1,
      isChartMore: false,
      peChartList: [],
      investList: [],
      investProps: {
        flag: false
      },
      isMore: false,
      chartList: [
        {
          id: 'event',
          params: 'investAgency',
          type: 'bar',
          sort: 'tagEventCnt:降序'
        },
        {
          id: 'yer',
          type: 'line',
          params: 'yearTrend',
          sort: ''
        }
      ],
      investLoading: false
    }
  },

  props: {
    packageTable: {
      type: [Number, String],
      default: ''
    },

    packageId: {
      type: Number,
      default: 0
    }
  },

  watch: {
    packageId: {
      handler() {
        this.initData()
      },
      immediate: true
    }
  },

  components: {
    PeFinance,
    PeDraw
  },

  methods: {
    initData() {
      // this.handleChartData()
      this.getChartList()
      this.getInvestList()
    },

    handleChartData() {
      console.log(44899)
      this.chartList.forEach(item => {
        this.$nextTick(async () => {
          const dom = document.getElementById(item.id)
          const peChart = echarts.init(dom)
          const { data } = await getScreenTagChart({
            chartItem: item.params,
            packageTable: this.packageTable
          })
          console.log(data, 223344)
          this.handleChartsData(item, data, peChart)
        })
      })
    },

    handleMoreEvent() {
      this.investPageNo++
      this.getInvestList(true)
    },

    handleChartsData(item, data, chart) {
      this.loading = true
      const type = item.type
      const list = []
      data.forEach(el => {
        list.push({
          name: el.chartName,
          value: el.tagEventCnt,
          tagInvestAmount: el.tagInvestAmount,
          tagInvestCnt: el.tagInvestCnt,
          tagEventCnt: el.tagEventCnt
        })
      })

      if (type == 'bar') {
        list.sort((a, b) => a.value - b.value)
        const option = {
          title: {
            text: 'TOP30投资机构排名'
          },
          tooltip: {
            formatter: function(params) {
              const index = params.dataIndex
              const obj = {
                amount: list[index]?.tagInvestAmount,
                cnt: list[index]?.tagInvestCnt,
                event: list[index]?.tagEventCnt
              }
              return `${params.name}</br>投资金额:${
                obj.amount
              }亿元人民币</br>投资事件:${obj.event}次</br>被投企业:${obj.cnt}家`
            }
          },
          grid: {
            top: '10%',
            left: '15%'
          },
          xAxis: {
            type: 'value'
          },
          yAxis: {
            type: 'category',
            data: list.map(item => item.name)
          },
          series: [
            {
              type: 'bar',
              itemStyle: {
                color: '#68a5ff'
              },
              data: list.map(item => item.value)
            }
          ]
        }
        chart.setOption(option)
      }

      if (type == 'line') {
        const option = {
          title: {
            text: '年度趋势'
          },
          tooltip: {
            trigger: 'axis',
            formatter: function(params) {
              return `${params[0]?.seriesName}:${params[0]?.value}次`
            }
          },
          grid: {
            left: '10%',
            top: '20%',
            bottom: '10%'
          },
          legend: {
            data: ['事件']
          },
          xAxis: {
            type: 'category',
            data: list.map(item => item.name)
          },
          yAxis: [
            {
              type: 'value',
              name: '投资事件数(次)'
            }
          ],
          series: [
            {
              name: '事件',
              type: 'line',
              data: list.map(item => item.tagEventCnt),
              smooth: true
            }
          ]
        }
        chart.setOption(option)
      }

      if (type != 'line') {
        chart.off('click')
        chart.on('click', params => {
          console.log(params, 222)
          this.handleChartClick(params)
        })
      }

      this.loading = false
    },

    handleChartClick(params) {
      console.log(params)
      this.peChartShow = true
      this.peChartName = params.name
      this.peChartProps = {
        name: this.peChartName,
        tag: this.parentName
      }
      this.chartPageNo = 1
      this.isChartMore = true
      this.getChartList()
    },

    async getChartList(flag = false) {
      this.peChartLoading = true
      const { data } = await getScreenFinanceList({
        packageTable: this.packageTable,
        pageNo: this.chartPageNo,
        pageSize: 20
      })
      if (flag) {
        this.peChartList = this.peChartList.concat(data?.list)
      } else {
        this.peChartList = data?.list
      }
      this.isChartMore = data.list.length != 0
      this.peChartProps.money = data?.sumVcAmount
      this.peChartProps.count = data?.page?.total
      this.peChartLoading = false
    },

    async getInvestList(flag = false) {
      this.investLoading = true
      const { data } = await getScreenFinanceList({
        packageTable: this.packageTable,
        pageNo: this.investPageNo,
        pageSize: 20
      })

      console.log(data, '774433')
      if (flag) {
        this.investList = this.investList.concat(data?.list)
      } else {
        this.investList = data?.list
      }
      this.investProps = {
        money: data.sumVcAmount
      }
      this.isMore = data.list.length != 0
      this.investLoading = false
    },

    handleChartEvent() {
      this.chartPageNo++
      this.getChartList(true)
    },

    handleGroup(data) {
      if (data.agencyUuid) {
        sessionStorage.setItem('agencyUuid', data.agencyUuid)
        this.$router.push({
          path: '/insight/vc/0/detail',
          query: {
            agencyUuid: data.agencyUuid
          }
        })
      }
    },

    handleCompanyDetail(data, index) {
      // console.log(this.packageId, 1122)
      enterCompanyDetail(data, this.peChartList, index, 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.qy-financing-page {
  .chart-dom {
    width: 100%;
    height: 600px;
  }
}
</style>
