var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "dt-permission",
            rawName: "v-dt-permission",
            value: "微营销管理-落地页管理-新建落地页",
            expression: "'微营销管理-落地页管理-新建落地页'",
          },
        ],
        staticClass: "thread-bag",
      },
      [
        _c(
          "div",
          {
            staticClass: "search-botton",
            staticStyle: { background: "#78D9D1", float: "right" },
            on: { click: _vm.createdNewLand },
          },
          [_vm._v("\n      新建落地页\n    ")]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "h-body" }, [_c("land-list")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }