<template>
  <div class="update-container">
    <Header></Header>
    <div class="reser-title">修改密码</div>
    <div class="update-form">
      <p>请输入您的新密码，以进行密码重设</p>
      <div class="reset-code">
        <input
          :class="error.errorCode ? 'input-error' : ''"
          oninput="value=value.replace(/[^\d]/g,'')"
          placeholder="验证码"
          type="text"
          @change="codeChangeLa"
          v-model="validCode"
          maxlength="8"
        />
        <div style="text-align:center;" ref="getCodeBtn" @click="getPhoneCode">
          {{ getCodeNum }}
        </div>
      </div>
      <input
        class="update-input-phone"
        @blur="checkPswFirst"
        :class="error.newPwd ? 'input-error' : ''"
        type="password"
        v-model="formInfo.newPwd"
        placeholder="设置密码长度为6-20位，英文和数字和!@#$%^&*()_-组合"
        maxlength="20"
      />
      <input
        class="update-code"
        @blur="checkTwoPaw"
        :class="error.newPwd2 ? 'input-error' : ''"
        type="password"
        oninput="value=value.replace(/[\W]/g,'')"
        v-model="formInfo.newPwd2"
        placeholder="确认密码"
        maxlength="20"
      />
      <p v-if="errorMsg" id="error">{{ errorMsg }}</p>
      <div class="update-bt" v-loading="loading" @click="updateBt">确认</div>
    </div>
  </div>
</template>

<script>
import Header from '../header.vue'
import { userPwdUpdate } from '@/api/user'
import { getSmsSend } from '@/api/usercenter'
import { pwd } from '@/utils/validate'
export default {
  components: {
    Header
  },
  data() {
    return {
      loading: false,
      phone: '',
      error: {
        newPwd: false,
        newPwd: false,
        errorCode: false
      },
      errorMsg: '',
      formInfo: {
        newPwd: '',
        newPwd2: ''
      },
      getCodeNum: 59,
      validCode: null
    }
  },
  created() {
    const userPhone = sessionStorage.getItem('needPhone')
    if (!userPhone && !this.$route.params.phone) {
      this.$router.push({ path: '/resetPwd', query: { type: 'psw' } })
      return
    }
    if (userPhone) {
      this.phone = userPhone
    } else {
      sessionStorage.setItem('needPhone', this.$route.params.phone)
      this.phone = this.$route.params.phone
    }

    const setTime = sessionStorage.getItem('getCodeNum')
    if (setTime === '获取验证码') {
      this.getCodeNum = setTime
      return
    } else {
      this.getCodeNum = Number(setTime)
      this.startTime()
    }
  },
  methods: {
    // 获取手机验证码
    getPhoneCode() {
      if (this.getCodeNum !== '获取验证码') {
        return
      }
      const params = {
        mobile: this.phone
      }
      getSmsSend(params).then(res => {
        if (res.code === 60000) {
          this.getCodeNum = 60
          this.startTime()
          this.$refs.getCodeBtn.classList.add('daojishi')
        }
      })
    },
    codeChangeLa() {
      this.error.errorCode = false
      this.errorMsg = ''
    },
    // 开始计时
    startTime() {
      if (this.getCodeNum == 0) {
        this.getCodeNum = '获取验证码'
        sessionStorage.setItem('getCodeNum', this.getCodeNum)
        this.$refs.getCodeBtn.classList.remove('daojishi')
        return
      } else {
        this.getCodeNum--
        sessionStorage.setItem('getCodeNum', Number(this.getCodeNum))
      }
      setTimeout(() => {
        this.startTime()
      }, 1000)
    },
    checkPswFirst() {
      if (!this.formInfo.newPwd) {
        this.errorMsg = ''
        this.error.newPwd = false
        return
      }
      if (!pwd(this.formInfo.newPwd)) {
        this.errorMsg = '请输入长度为6-20位，英文和数字组合'
        this.error.newPwd = true
        return
      } else {
        this.errorMsg = ''
        this.error.newPwd = false
      }
    },
    checkTwoPaw() {
      if (!this.formInfo.newPwd2) {
        this.error.newPwd2 = false
        return
      }
      if (this.formInfo.newPwd != this.formInfo.newPwd2) {
        this.errorMsg = '两次密码不一致'
        this.error.newPwd2 = true
      } else {
        this.errorMsg = ''
        this.error.newPwd2 = false
      }
    },
    updateBt() {
      this.errorMsg = ''
      this.newPwd = {}

      if (!this.validCode) {
        this.errorMsg = '请输入验证码'
        this.error.errorCode = true
        return
      }
      if (this.validCode.length !== 6) {
        this.errorMsg = '请输入6位数字验证码'
        this.error.errorCode = true
        return
      }
      if (!this.formInfo.newPwd) {
        this.errorMsg = '请输入新密码'
        this.error.newPwd = true
        return
      }

      if (!this.formInfo.newPwd2) {
        this.errorMsg = '请输入再次输入新密码'
        this.error.newPwd2 = true
        return
      }

      if (this.formInfo.newPwd !== this.formInfo.newPwd2) {
        this.errorMsg = '两次密码输入的不一致'
        return
      }

      if (!pwd(this.formInfo.newPwd)) {
        this.errorMsg = '输入密码格式不正确，长度为6-20位，英文和数字组合'
        return
      }
      let data = {
        passWord: this.formInfo.newPwd,
        mobile: this.phone,
        code: this.validCode
      }
      this.loading = true
      userPwdUpdate(data)
        .then(res => {
          this.loading = false
          if (res.code == '60000') {
            this.$message({
              message: '修改成功，请重新登录',
              type: 'success'
            })
            this.$router.replace({ path: '/login' })
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.daojishi {
  background: #f5f6f7;
}
.reset-code {
  display: flex;
  margin-top: 26px;
  width: 350px;
  justify-content: space-between;
}

.reset-code input {
  width: 170px;
  height: 46px;
  line-height: 46px;
  background: none;
  outline: none;
  border: 0px;
  /* 输入框默认边框 */
  border: 1px solid #c0bebe;
  padding: 0 20px;
  color: #2e2e2e;
}

.reset-code input:focus {
  border-color: #41cabf;
}

.reset-code div {
  width: 170px;
  line-height: 44px;
  /* 输入框默认边框 */
  border: 1px solid #41cabf;
  padding: 0 20px;
  color: #41cabf;
  font-size: 14px;
  cursor: pointer;
}
.update-container {
  width: 100%;
  height: 100%;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f5f6f7;
  overflow-x: hidden;
}
.reser-title {
  margin-top: 50px;
  font-size: 30px;
  color: #494949;
  font-weight: 600px;
}

.update-form {
  display: flex;
  width: 1100px;
  height: 400px;
  min-width: 500px;
  min-height: 400px;
  margin-top: 49px;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.update-form p {
  color: #8a8a8a;
  font-size: 16px;
  font-weight: 600px;
  margin-top: 56px;
}

.update-input-phone {
  width: 350px;
  height: 46px;
  background: none;
  outline: none;
  border: 0px;
  /* 输入框默认边框 */
  border: 1px solid #c0bebe;
  padding: 0 20px;
  color: #2e2e2e;
  margin-top: 26px;
}

input:focus {
  /* 输入框获取焦点边框 */
  border-color: #41cabf;
}

.input-error {
  border-color: #ff0000 !important;
}

.update-code {
  margin-top: 26px;
  width: 350px;
  height: 46px;
  background: none;
  outline: none;
  border: 0px;
  /* 输入框默认边框 */
  border: 1px solid #c0bebe;
  padding: 0 20px;
  color: #2e2e2e;
}

/*  .update-code input:focus{
    border-color:#41CABF;
  } */

.update-code div {
  width: 170px;
  line-height: 44px;
  /* 输入框默认边框 */
  border: 1px solid #41cabf;
  padding: 0 20px;
  color: #41cabf;
  font-size: 14px;
}

#error {
  color: #ff0000;
  margin-top: 10px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  width: 350px;
}

.update-bt {
  width: 350px;
  line-height: 46px;
  background: #41cabf;
  color: #ffffff;
  margin-top: 26px;
  cursor: pointer;
}
</style>
