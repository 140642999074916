var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-popover",
        { attrs: { title: "上云阶段定义", width: "500", trigger: "hover" } },
        [
          _c("i", {
            staticClass: "el-icon-info",
            staticStyle: { color: "#8a8a8a" },
            attrs: { slot: "reference" },
            slot: "reference",
          }),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.cloudList, size: "mini", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "上云阶段" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "hotDesc", label: "热度" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "note", label: "建议商务方式" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }