var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-main" }, [
    _c("div", { staticClass: "market-index" }, [
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.marketType,
                callback: function ($$v) {
                  _vm.marketType = $$v
                },
                expression: "marketType",
              },
            },
            _vm._l(_vm.marketTypeList, function (item) {
              return _c("el-tab-pane", {
                key: item.value,
                attrs: { label: item.name, name: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      Object.keys(this.marketList).length
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "raw-main",
            },
            [
              _c(
                "DtScroll",
                { attrs: { margin: "20" } },
                _vm._l(_vm.marketList, function (item, name) {
                  return _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "item-name" }, [
                      _vm._v("\n            " + _vm._s(name) + "\n          "),
                    ]),
                    _vm._v(" "),
                    item && item.length
                      ? _c(
                          "div",
                          { staticClass: "item-list" },
                          _vm._l(item, function (packageItem) {
                            return _c(
                              "div",
                              { staticClass: "item-list-item" },
                              [
                                _c("div", { staticClass: "package-item" }, [
                                  _c("div", { staticClass: "icon-name" }, [
                                    _c(
                                      "div",
                                      { staticClass: "icon" },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "35px",
                                            height: "35px",
                                          },
                                          attrs: {
                                            src: packageItem.image
                                              ? packageItem.image
                                              : require("@/assets/pack-mark.png"),
                                            fit: "contain",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(packageItem.name) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "btn" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "btn-active",
                                            staticStyle: {
                                              "margin-right": "5px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewDemo(packageItem)
                                              },
                                            },
                                          },
                                          [_vm._v("预览")]
                                        ),
                                        _vm._v(" "),
                                        packageItem.bookingStatus === 0
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "btn-active",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.subscribe(
                                                        packageItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("订阅")]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        packageItem.bookingStatus === 1
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "btn-no-active",
                                                },
                                                [_vm._v("申请中")]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        packageItem.bookingStatus === 2
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "btn-no-active",
                                                },
                                                [_vm._v("已订阅")]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "desc",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        packageItem.description
                                      ),
                                    },
                                  }),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _c("div", { staticClass: "empty-package" }, [
                          _vm._v("\n            暂无数据\n          "),
                        ]),
                  ])
                }),
                0
              ),
            ],
            1
          )
        : _c("div", { staticClass: "raw-main empty-main" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.loading ? "" : "暂无数据") + "\n    "
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }