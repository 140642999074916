var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-panel-page" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.form.store,
              expression: "form.store",
            },
          ],
          staticClass: "left-panel",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tabLoading,
                  expression: "tabLoading",
                },
              ],
              class: _vm.model ? "dark-panel" : "panel",
            },
            [
              _c(
                "div",
                { staticClass: "tab" },
                _vm._l(_vm.rankTab, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      class:
                        _vm.currentIndex == index
                          ? "active tab-item"
                          : "tab-item",
                      on: {
                        click: function ($event) {
                          return _vm.handleTabClick(item, index)
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "word" },
                _vm._l(_vm.wordList, function (item) {
                  return _c("div", { key: item.id, staticClass: "word-item" }, [
                    _c("span", [_vm._v(_vm._s(item.name))]),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list" },
                [
                  _vm.rankList.length
                    ? _vm._l(_vm.rankList, function (item, index) {
                        return _c("div", { key: index, staticClass: "item" }, [
                          _c("div", { staticClass: "it-fl" }, [
                            _c("span", { staticClass: "it" }, [
                              _vm._v(_vm._s(_vm.computedRank(index))),
                            ]),
                            _vm._v(" "),
                            _c("span", { class: index > 2 ? "init" : "dot" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "it company-name-click",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCity(item, "area")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.chartItem))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "shrink shrink-spec" }, [
                            _vm._v(_vm._s(item.percent)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "it-fr" }, [
                            _vm._v(_vm._s(item.count)),
                          ]),
                        ])
                      })
                    : _c("NoData"),
                ],
                2
              ),
              _vm._v(" "),
              _c("PeAngle"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.model ? "dark-panel city-panel" : "panel city-panel" },
            [
              _c(
                "div",
                { staticClass: "word" },
                _vm._l(_vm.cityDescList, function (item) {
                  return _c("div", { key: item.id, staticClass: "word-item" }, [
                    _c("span", [_vm._v(_vm._s(item.name))]),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list" },
                [
                  _vm.cityList.length
                    ? _vm._l(_vm.cityList, function (item, index) {
                        return _c("div", { key: index, staticClass: "item" }, [
                          _c("div", { staticClass: "it-fl" }, [
                            _c("span", { staticClass: "it" }, [
                              _vm._v(_vm._s(_vm.computedRank(index))),
                            ]),
                            _vm._v(" "),
                            _c("span", { class: index > 2 ? "init" : "dot" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "it company-name-click",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCity(item, "city")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " + _vm._s(item.chartItem)
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "shrink" }, [
                            _vm._v(_vm._s(item.percent)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "it-fr" }, [
                            _vm._v(_vm._s(item.count)),
                          ]),
                        ])
                      })
                    : _c("NoData"),
                ],
                2
              ),
              _vm._v(" "),
              _c("PeAngle"),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.panelType == "store"
        ? _c("div", { staticClass: "right-panel" }, [
            _c("div", { class: _vm.model ? "dark-panel" : "panel" }, [
              _c("div", { staticClass: "operate-panel" }, [
                _c("div", { staticClass: "border-list" }, [
                  _c("div", { staticClass: "border-item" }, [
                    _c("div", { staticClass: "item-spec" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("热力图")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("商圈")]),
                          _vm._v(" "),
                          _c("el-switch", {
                            on: { change: _vm.handleRadioChange },
                            model: {
                              value: _vm.form.circle,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "circle", $$v)
                              },
                              expression: "form.circle",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("客流")]),
                          _vm._v(" "),
                          _c("el-switch", {
                            on: { change: _vm.handleDistribute },
                            model: {
                              value: _vm.form.scatter,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "scatter", $$v)
                              },
                              expression: "form.scatter",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.scatter,
                              expression: "form.scatter",
                            },
                          ],
                          staticClass: "item",
                        },
                        [
                          _c("PeSelect", {
                            class: _vm.selectStyle,
                            staticStyle: { "margin-left": "0" },
                            attrs: {
                              clearable: false,
                              selectSize: "mini",
                              popperClass: _vm.getPopperClass,
                              optionsList: _vm.schemaList,
                            },
                            on: { handleSelectChange: _vm.handleSchema },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "border-item border-top" }, [
                    _c(
                      "div",
                      { staticClass: "item-brand" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("自有品牌"),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          on: { change: _vm.handleStoreChange },
                          model: {
                            value: _vm.form.store,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "store", $$v)
                            },
                            expression: "form.store",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("PeSelect", {
                          ref: "one",
                          class: _vm.selectStyle,
                          staticStyle: { "margin-left": "0" },
                          attrs: {
                            placeholder: "一级行业",
                            selectSize: "mini",
                            popperClass: _vm.getPopperClass,
                            defaultSelect: _vm.oneInit,
                            optionsList: _vm.oneList,
                          },
                          on: {
                            handleSelectChange: _vm.handleOneChange,
                            handleVisibleChange: _vm.handleOneVisible,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("PeSelect", {
                          ref: "two",
                          class: _vm.selectStyle,
                          staticStyle: { "margin-left": "0" },
                          attrs: {
                            placeholder: "二级行业",
                            selectSize: "mini",
                            popperClass: _vm.getPopperClass,
                            optionsList: _vm.twoList,
                            defaultSelect: _vm.twoInit,
                          },
                          on: {
                            handleSelectChange: _vm.handleTwoChange,
                            handleVisibleChange: _vm.handleTwoVisible,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item pad-bottom" },
                      [
                        _c("PeSelect", {
                          ref: "three",
                          class: _vm.selectStyle,
                          staticStyle: { "margin-left": "0" },
                          attrs: {
                            placeholder: "三级行业",
                            popperClass: _vm.getPopperClass,
                            selectSize: "mini",
                            optionsList: _vm.threeList,
                            defaultSelect: _vm.threeInit,
                          },
                          on: {
                            handleSelectChange: _vm.handleThreeChange,
                            handleVisibleChange: _vm.handleThreeVisible,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.store,
                            expression: "form.store",
                          },
                        ],
                        staticClass: "item",
                      },
                      [
                        _c("span", [_vm._v("服务半径")]),
                        _vm._v(" "),
                        _c("PeSelect", {
                          class: _vm.selectStyle,
                          staticStyle: { "margin-left": "0", width: "90px" },
                          attrs: {
                            selectSize: "mini",
                            popperClass: _vm.getPopperClass,
                            optionsList: _vm.rangeList,
                            defaultSelect: _vm.initStore,
                          },
                          on: { handleSelectChange: _vm.handleRange },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "border-item border-top" }, [
                    _c("div", { staticClass: "item-spec" }, [
                      _c(
                        "div",
                        { staticClass: "item-brand" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("竞争品牌"),
                          ]),
                          _vm._v(" "),
                          _c("el-switch", {
                            on: { change: _vm.handleCompeteSwitch },
                            model: {
                              value: _vm.form.compete,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "compete", $$v)
                              },
                              expression: "form.compete",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.compete,
                            expression: "form.compete",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("PeSelect", {
                              ref: "compete",
                              class: _vm.selectStyle,
                              staticStyle: { "margin-left": "0" },
                              attrs: {
                                popperClass: _vm.getPopperClass,
                                optionsList: _vm.competeList,
                                isMultiple: true,
                                selectSize: "mini",
                                placeholder: "请选择竞争品牌",
                              },
                              on: {
                                handleSelectChange: _vm.handleCompete,
                                handleVisibleChange: _vm.handleCompeteVisible,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("span", [_vm._v("服务半径")]),
                            _vm._v(" "),
                            _c("PeSelect", {
                              class: _vm.selectStyle,
                              staticStyle: {
                                "margin-left": "0",
                                width: "90px",
                              },
                              attrs: {
                                popperClass: _vm.getPopperClass,
                                optionsList: _vm.rangeList,
                                defaultSelect: _vm.initCompete,
                                selectSize: "mini",
                              },
                              on: {
                                handleSelectChange: _vm.handleCompeteChange,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "border-item border-top" }, [
                    _c("div", { staticClass: "item-spec" }, [
                      _c(
                        "div",
                        { staticClass: "item-brand" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("跟随品牌"),
                          ]),
                          _vm._v(" "),
                          _c("el-switch", {
                            on: { change: _vm.handleFollowSwitch },
                            model: {
                              value: _vm.form.follow,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "follow", $$v)
                              },
                              expression: "form.follow",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.follow,
                            expression: "form.follow",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("PeSelect", {
                              ref: "follow",
                              class: _vm.selectStyle,
                              staticStyle: { "margin-left": "0" },
                              attrs: {
                                popperClass: _vm.getPopperClass,
                                optionsList: _vm.followList,
                                isMultiple: true,
                                placeholder: "请选择跟随品牌",
                                selectSize: "mini",
                              },
                              on: {
                                handleVisibleChange: _vm.handleFollowVisible,
                                handleSelectChange: _vm.handleFollow,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("span", [_vm._v("服务半径")]),
                            _vm._v(" "),
                            _c("PeSelect", {
                              class: _vm.selectStyle,
                              staticStyle: {
                                "margin-left": "0",
                                width: "90px",
                              },
                              attrs: {
                                popperClass: _vm.getPopperClass,
                                optionsList: _vm.rangeList,
                                defaultSelect: _vm.initFollow,
                                selectSize: "mini",
                              },
                              on: {
                                handleSelectChange: _vm.handleFollowChange,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("ScreenDialog", { attrs: { showScreen: _vm.showDialog } }, [
        _c("div", { class: _vm.getDialogClass }, [
          _c("div", { staticClass: "area" }, [
            _vm._v(_vm._s(_vm.selectCity) + "地域排名分布"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "city-desc" }, [
            _c("span", [_vm._v("城市")]),
            _vm._v(" "),
            _c("span", [_vm._v("占比")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(this.cityWord))]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list" },
            [
              _vm.dialogList.length
                ? _vm._l(_vm.dialogList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("div", { staticClass: "it-fl" }, [
                        _c("span", { staticClass: "it" }, [
                          _vm._v(_vm._s(_vm.computedRank(index))),
                        ]),
                        _vm._v(" "),
                        _c("span", { class: index > 2 ? "init" : "dot" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "it" }, [
                          _vm._v(_vm._s(item.chartItem)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "it-mid" }, [
                        _vm._v(_vm._s(item.percent)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "it-fr" }, [
                        _vm._v(_vm._s(item.count)),
                      ]),
                    ])
                  })
                : _c("NoData"),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "close-footer" },
            [
              _c(
                "el-button",
                {
                  class: _vm.getBtnClass,
                  attrs: { size: "small", icon: "el-icon-error" },
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }