var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editUser-main" }, [
    _c(
      "div",
      { staticClass: "edit-main" },
      [
        _c(
          "el-form",
          {
            ref: "userForm",
            attrs: { rules: _vm.userRules, "label-width": "auto" },
            model: {
              value: _vm.userFormData,
              callback: function ($$v) {
                _vm.userFormData = $$v
              },
              expression: "userFormData",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 10 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "姓名", prop: "contact" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入姓名" },
                          model: {
                            value: _vm.userFormData.contact,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "contact", $$v)
                            },
                            expression: "userFormData.contact",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "身份" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.userFormData.isKp,
                              callback: function ($$v) {
                                _vm.$set(_vm.userFormData, "isKp", $$v)
                              },
                              expression: "userFormData.isKp",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "关键人", value: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "中间人", value: "2" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "普通", value: "3" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "部门" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入部门" },
                          model: {
                            value: _vm.userFormData.department,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "department", $$v)
                            },
                            expression: "userFormData.department",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "职位" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入职位" },
                          model: {
                            value: _vm.userFormData.position,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "position", $$v)
                            },
                            expression: "userFormData.position",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入手机号码" },
                          model: {
                            value: _vm.userFormData.mobile,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.userFormData,
                                "mobile",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "userFormData.mobile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "座机" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入座机号码" },
                          model: {
                            value: _vm.userFormData.landLine,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.userFormData,
                                "landLine",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "userFormData.landLine",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "微信号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入微信号" },
                          model: {
                            value: _vm.userFormData.wechat,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "wechat", $$v)
                            },
                            expression: "userFormData.wechat",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "邮箱" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入邮箱" },
                          model: {
                            value: _vm.userFormData.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "email", $$v)
                            },
                            expression: "userFormData.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择状态" },
                            model: {
                              value: _vm.userFormData.mark,
                              callback: function ($$v) {
                                _vm.$set(_vm.userFormData, "mark", $$v)
                              },
                              expression: "userFormData.mark",
                            },
                          },
                          _vm._l(_vm.markDescList, function (_item) {
                            return _c("el-option", {
                              key: _item.value,
                              attrs: { label: _item.name, value: _item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "请输入联系人备注",
                            maxlength: "100",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.userFormData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.userFormData, "remark", $$v)
                            },
                            expression: "userFormData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "div",
        [
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.notShow,
                callback: function ($$v) {
                  _vm.notShow = $$v
                },
                expression: "notShow",
              },
            },
            [_vm._v("不再显示")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }