var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "record-main" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "name-tag" }, [
          _c("span", { staticClass: "name" }, [
            _vm._v("\n        " + _vm._s(_vm.option.companyName) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.option.from
              ? _c("el-tag", { attrs: { size: "mini" } }, [
                  _vm._v(_vm._s(_vm.option.from)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.option.grade
              ? _c("el-tag", { attrs: { size: "mini" } }, [
                  _vm._v(_vm._s(_vm.option.grade)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.option.state
              ? _c("el-tag", { attrs: { size: "mini" } }, [
                  _vm._v(_vm._s(_vm.option.state)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.option.customerGrade
              ? _c("el-tag", { attrs: { size: "mini" } }, [
                  _vm._v(_vm._s(_vm.option.customerGrade)),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "time-c-u" }, [
          _c("div", [
            _c("span", [_vm._v("创建人：")]),
            _c("span", [_vm._v(_vm._s(_vm.option.createUserName))]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("span", [_vm._v("创建时间：")]),
            _c("span", [_vm._v(_vm._s(_vm.option.createdAt))]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.activeChange },
              model: {
                value: _vm.tabType,
                callback: function ($$v) {
                  _vm.tabType = $$v
                },
                expression: "tabType",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "全部", name: "all" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "跟进记录", name: "first" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "系统记录", name: "second" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "客户动态", name: "third" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        {
          staticClass: "main-record dt-dropdown-dt",
          style: {
            height:
              _vm.tabType !== "third" &&
              _vm.tabType !== "second" &&
              _vm.hasPermission
                ? ""
                : "calc( 100vh - 150px )",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "main-row",
              style: {
                minHeight:
                  _vm.tabType !== "third" && _vm.tabType !== "second"
                    ? ""
                    : "calc( 100vh - 150px )",
              },
            },
            [
              _vm.pondCond && _vm.pondCond.length
                ? _c(
                    "div",
                    { staticClass: "time-line" },
                    _vm._l(_vm.pondCond, function (item) {
                      return _c(
                        "el-timeline",
                        { staticStyle: { "margin-bottom": "20px" } },
                        [
                          _c("div", { staticClass: "time-header" }, [
                            _c("span", { staticClass: "time-week" }, [
                              _vm._v(_vm._s(item.week)),
                            ]),
                            _c("span", { staticClass: "time-day" }, [
                              _vm._v(_vm._s(item.day)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm._l(item.follows, function (follow) {
                            return _c(
                              "el-timeline-item",
                              {
                                attrs: {
                                  placement: "top",
                                  color: item.week === "今天" ? "#FF0000" : "",
                                },
                              },
                              [
                                _c("div", { staticClass: "follow-time" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(follow.time) +
                                      "\n            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "right" }, [
                                  _c(
                                    "div",
                                    { staticClass: "record-name-type" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "record-name" },
                                        [_vm._v(_vm._s(follow.follower))]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "dot" }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "record-type" },
                                        [_vm._v(_vm._s(follow.opSummary))]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "record-text" },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          false
                                            ? _c(
                                                "el-col",
                                                {
                                                  staticClass: "text",
                                                  attrs: { span: 3 },
                                                },
                                                [_vm._v("记录：")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "text text-bg",
                                              attrs: { span: 24 },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  follow.records ||
                                                    follow.opDetails
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          follow.imagesUrl
                                            ? _c("el-image", {
                                                attrs: {
                                                  src: follow.imagesUrl,
                                                  fit: "cover",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    1
                  )
                : _c("div", { staticClass: "empty" }, [
                    _vm._v("\n        暂无数据\n      "),
                  ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.tabType !== "third" && _vm.tabType !== "second" && _vm.hasPermission
        ? _c(
            "div",
            { staticClass: "input" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    "label-width": "auto",
                    size: "small",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "跟进方式", prop: "type" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择跟进方式" },
                                  model: {
                                    value: _vm.formData.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "type", $$v)
                                    },
                                    expression: "formData.type",
                                  },
                                },
                                _vm._l(_vm.typeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "跟进时间", prop: "time" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetime",
                                  placeholder: "选择日期时间",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "picker-options": _vm.pickerOptions,
                                },
                                model: {
                                  value: _vm.formData.time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "time", $$v)
                                  },
                                  expression: "formData.time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "跟进对象", prop: "followUser" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mask mask-user",
                                  on: { click: _vm.selectUser },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "select-tag" },
                                    _vm._l(_vm.selectUserList, function (item) {
                                      return _c(
                                        "el-tag",
                                        {
                                          staticClass: "tag",
                                          attrs: { closable: "", size: "mini" },
                                          on: {
                                            close: function ($event) {
                                              return _vm.editSelectUser(item)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.contact))]
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n                选择\n              "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.selectUserList.length
                                    ? ""
                                    : "请选择跟进对象",
                                },
                                model: {
                                  value: _vm.formData.followUser,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "followUser", $$v)
                                  },
                                  expression: "formData.followUser",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "跟进记录", prop: "text" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 4, maxRows: 4 },
                                  placeholder: "请输入内容",
                                },
                                model: {
                                  value: _vm.formData.text,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "text", $$v)
                                  },
                                  expression: "formData.text",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-form-item", { staticClass: "form-item" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "flex-end",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: { click: _vm.submit },
                                  },
                                  [_vm._v("提交")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            width: "850px",
            top: "90px",
            visible: _vm.showSelectUser,
            "show-close": false,
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectUser = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slot-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v("\n        选择跟进对象\n      ")]),
              _vm._v(" "),
              _c("div", { on: { click: _vm.cancelSelectUser } }, [
                _c("i", { staticClass: "el-icon-close close" }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-list" },
            [
              _c("selectBillKeyPersion", {
                key: _vm.refreshSelectKey,
                attrs: {
                  "company-name": _vm.option.companyName,
                  "is-active-tab": _vm.activeTabs,
                  "current-check-row-prop": _vm.selectUserList,
                  "package-id": _vm.option.packageId,
                  "company-uuid": _vm.option.companyUuid,
                },
                on: { closeDialog: _vm.closeDialog, getRes: _vm.getSelectUser },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }