var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "keyNoGet-main" }, [
    _c("div", { staticClass: "keyNoGet-main-dialog" }, [
      _c(
        "span",
        [
          _vm._v("\n          只能添加已领取的线索。"),
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.checked,
                callback: function ($$v) {
                  _vm.checked = $$v
                },
                expression: "checked",
              },
            },
            [_vm._v("一键领取")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
          [_vm._v("取消")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.confirmBtn },
          },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }