<template>
  <div class="body" ref="table">
    <el-table
      :highlight-current-row="singleTable"
      ref="dtTable"
      :max-height="dtHeight"
      v-loading="tableLoading"
      :size="tableSize"
      stripe
      :border="true"
      :data="tableData"
      @select="select"
      @select-all="selectAll"
      @selection-change="selectChange"
      @sort-change="sortChange"
      @current-change="singleSelectChange"
      style="width: 100%;font-size: 12px;padding-bottom: 10px;"
    >
      <el-table-column
        :header-align="algin"
        :align="algin"
        v-if="tableData.length && isSelect"
        type="selection"
        width="40"
      >
      </el-table-column>
      <template v-for="column in columnLists">
        <el-table-column
          :header-align="algin"
          :sortable="column.sort"
          :align="algin"
          :show-overflow-tooltip="
            column.name === '公司名称' ? false : showOverflowTooltip
          "
          :prop="column[columnLabelValueAttr.labelKey]"
          :label="column[columnLabelValueAttr.valueKey]"
          :width="columnWidthList[column[columnLabelValueAttr.labelKey]]"
        >
          <template slot="header" slot-scope="scope">
            <slot
              :name="`${column[columnLabelValueAttr.labelKey]}Header`"
              v-bind:scope="{
                data: { index: scope.$index, column: scope.column },
                column: column
              }"
              >{{ column[columnLabelValueAttr.valueKey] }}
            </slot>
          </template>

          <template slot-scope="scope">
            <slot
              :name="`${column[columnLabelValueAttr.labelKey]}Row`"
              v-bind:scope="{
                data: scope.row,
                column: column,
                index: scope.$index
              }"
              >{{ scope.row[column[columnLabelValueAttr.labelKey]] }}
            </slot>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <div class="page" v-show="isShowPage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :key="pageKey"
        :page-sizes="pageSize"
        :page-size="pageSizes"
        background
        :pager-count="5"
        layout="total, sizes, prev, pager, next"
        :total="dataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DtTable',
  data() {
    return {
      tableHeight: '500',
      pageKey: '0'
    }
  },
  props: {
    dtHeight: {
      type: String,
      default: 'auto'
    },
    // 单选
    singleTable: {
      type: Boolean,
      default: false
    },

    showOverflowTooltip: {
      type: Boolean,
      default: true
    },
    algin: {
      type: String,
      default: 'left'
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    // 表头数据
    columnList: {
      type: Array,
      default: () => {
        return []
      }
    },

    // 表格内容
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },

    // 表格的尺寸
    tableSize: {
      type: String,
      default: 'medium' // small、mini
    },

    // 是否显示选择框
    isSelect: {
      type: Boolean,
      default: true
    },

    // 用于分页的数据总数
    dataTotal: {
      type: [Number, String],
      default: 0
    },

    // 分页的大小
    pageSize: {
      type: Array,
      default: () => {
        return [20, 50, 100, 200, 300]
      }
    },

    pageSizes: {
      type: [Number, String],
      default: 20
    },

    // 页码
    pageNum: {
      type: [Number, String],
      default: 1
    },

    // 需要添加在尾部的额外字段
    appendField: {
      type: Array,
      default: () => {
        return [
          {
            field: 'setTing',
            name: '操作'
          }
        ]
      }
    },

    // 需要添加在头部的额外字段 传入该字段就会被添加到每行的头部
    shiftField: {
      type: Array,
      default: () => {
        return []
      }
    },

    // 是否添加额外字段
    isAddEarnField: {
      type: Boolean,
      default: true
    },

    isAddShiftField: {
      type: Boolean,
      default: true
    },

    // column的对应的宽度的对象
    columnWidthList: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // column的字段
    columnLabelValueAttr: {
      type: Object,
      default: () => {
        return {
          labelKey: 'field',
          valueKey: 'name'
        }
      }
    },

    isCountHeight: {
      type: Boolean,
      default: true
    },

    isShowPage: {
      type: Boolean,
      default: true
    },

    hideBorder: {
      type: Boolean,
      default: false
    },

    tableH: {
      type: [Number, String],
      default: window.innerHeight - 200
    }
  },
  watch: {
    tableData: {
      handler(val) {
        console.log(val, 'son-listen')
      },
      immediate: true,
      deep: true
    },
    pageNum(val) {
      this.pageKey = val
      console.log(val, 'pageNum')
    }
  },
  updated() {
    // 给表格设定固定高度 ####缺点：表头无法固定
    if (this.isCountHeight) {
      this.$nextTick(() => {
        const el = this.$refs['table']
        this.tableHeight =
          window.innerHeight - el.getBoundingClientRect().top - 60
      })
    } else {
      this.tableHeight = this.tableH
    }
    const tableRef = this.$refs['dtTable']
    this.$emit('ready', tableRef) //将table组件绑定到tableRef上 方便从外部获取table实例 执行ready回调
  },
  computed: {
    columnLists() {
      if (this.isAddEarnField && this.isAddShiftField) {
        return [...this.shiftField, ...this.columnList, ...this.appendField]
      }
      if (this.isAddShiftField && !this.isAddEarnField) {
        return [...this.shiftField, ...this.columnList]
      }
      return this.columnList
    }
  },
  methods: {
    sortChange(e) {
      this.$emit('sortChange', e)
    },

    // 选择事件
    select(selection, row) {
      this.$emit('select', { selection, row })
    },

    // 全选事件
    selectAll(selection) {
      this.$emit('selectAll', selection)
    },

    // select改变事件
    selectChange(selection) {
      this.$emit('selectChange', selection)
    },

    // 单选事件
    singleSelectChange(val) {
      this.$emit('singleSelectChange', val)
    },

    // 页面size改变事件
    handleSizeChange(pageSize) {
      this.$emit('sizeChange', pageSize)
    },

    // 页码改变事件
    handleCurrentChange(pageNum) {
      this.$emit('currentChange', pageNum)
    },

    // 获取table组件
    getTableRef() {
      return this.$refs['dtTable']
    }
  }
}
</script>
<style scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: auto;
}

.page {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fff;
  z-index: 1;
}

::v-deep td {
  border-right: none;
}
</style>
