var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customerView-main" },
    [
      _c(
        "DtScroll",
        {
          directives: [
            {
              name: "dt-permission",
              rawName: "v-dt-permission",
              value: "扩展应用-客群洞察-查看",
              expression: "'扩展应用-客群洞察-查看'",
            },
          ],
          attrs: { margin: "0" },
        },
        [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", { staticClass: "left-1" }, [_vm._v("扩展应用")]),
              _vm._v(" "),
              _c("div", { staticClass: "left-2" }, [_vm._v("客群洞察")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "package" }, [
              _c("div", { staticClass: "name" }, [_vm._v("目标客群")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list" },
                [
                  _vm._l(_vm.packageList, function (item) {
                    return _c(
                      "div",
                      {
                        staticClass: "item",
                        style: {
                          borderColor:
                            _vm.currentPackage.id === item.id
                              ? "#41cabf"
                              : "rgba(0,0,0,.1)",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changePackage(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "item-main-row" }, [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [
                              _c("svg-icon", {
                                staticClass: "icon",
                                attrs: {
                                  "icon-class":
                                    _vm.currentPackage.id === item.id
                                      ? "khdc-ac"
                                      : "khdc",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "desc" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.packageName)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "num" }, [
                              _vm._v("共" + _vm._s(item.count) + "家企业"),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  !_vm.packageList.length
                    ? _c("div", { staticClass: "item no-package-data" }, [
                        _vm._v("\n            暂无数据\n          "),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main-row" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.tabClick },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "客户洞察", name: "1" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "客群明细", name: "2" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.activeTab === "1"
                ? _c("main", [
                    _c(
                      "div",
                      { key: _vm.key, staticClass: "chart" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 10 } },
                          _vm._l(_vm.chartListObj, function (item, key) {
                            return _c(
                              "el-col",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { span: 12 },
                              },
                              [
                                _c("el-card", { attrs: { shadow: "hover" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chart-header",
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(item.btnList, function (i) {
                                        return _c(
                                          "div",
                                          {
                                            class: i.active
                                              ? "icon active-color"
                                              : "icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeType(
                                                  i.type,
                                                  item,
                                                  key
                                                )
                                              },
                                            },
                                          },
                                          [_c("i", { class: i.icon })]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "item" },
                                    [
                                      key === "region"
                                        ? [
                                            _c("dtMap", {
                                              attrs: {
                                                "map-data": item.data.mapData,
                                                "excluded-ad-codes":
                                                  item.data.excludedAdCodes,
                                              },
                                            }),
                                            _vm._v(" "),
                                            item.data.nullNum
                                              ? _c(
                                                  "div",
                                                  { staticClass: "null-data" },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.data.nullNum
                                                          ) +
                                                          " 家未知 "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : [
                                            _c("dtChart", {
                                              attrs: {
                                                "chart-data": item.data,
                                                direction: item.direction,
                                                "chart-type": item.btnList.find(
                                                  (item) => item.active
                                                ).type,
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.activeTab === "2"
                ? _c("main", [
                    _c(
                      "div",
                      {
                        ref: "keyList",
                        staticClass: "key-list",
                        style: { minHeight: `${_vm.listHeight}px` },
                      },
                      [
                        _c("packageKeyList", {
                          attrs: {
                            package: _vm.currentPackage,
                            "min-height": _vm.listHeight,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }