<template>
  <div class="SetTing-main">
    <template v-if="dataType === 'customerPond'">
      <span class="remove-ii">
        <span
          style="height: 50px;display: inline-block;line-height: 50px"
          v-for="item in btnList.slice(0, 2)"
          :key="item.name"
          class="com-list-detail opea"
          @click="item.click(dataSource.row, dataSource.$index)"
        >
          <span v-if="item.name === '联系人'">
            <el-badge
              :value="
                dataSource.row.isKp === '1' ? 'KP' : dataSource.row.contactCount
              "
              class="badge-item"
              type="primary"
              v-if="dataSource.row.contactCount"
              >联系人</el-badge
            >
            <span v-else>联系人</span>
          </span>
          <span v-else>
            {{ item.name }}
          </span>
        </span>
      </span>
      <span style="cursor: pointer" v-if="showDropDown">
        <el-dropdown @command="jump">
          <span class="el-dropdown-link">
            <i
              class="el-icon-arrow-down el-icon--right"
              style="color: #0099CC"
            ></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in btnList.slice(2)"
              :key="item.name"
              :command="{
                item: item,
                row: dataSource.row,
                index: dataSource.$index
              }"
            >
              {{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
      <el-drawer
        title="我是标题"
        :visible.sync="openDrawer"
        size="600px"
        :with-header="false"
        append-to-body
      >
        <pond-detail
          source="costomerpond"
          :customerInfoMap="dataSource.row"
          :id="dataSource.row.companyUuid"
          :company-uuid="dataSource.row.companyUuid"
          :package-id="dataSource.row.packageId"
          :rowCont="dataSource.row"
          svg
          :majorId="dataSource.row.id"
          v-if="openDrawer"
          :isActiveTab1="dataSource.row.type + ''"
        ></pond-detail>
      </el-drawer>
      <user-profile
        ref="userPro"
        :customerInfo="dataSource.row"
        :title="selectTitle"
        v-if="userProState"
      />
      <el-drawer
        title="商机信息"
        :show-close="false"
        append-to-body
        :visible.sync="showChance"
        :with-header="false"
        size="700px"
        destroy-on-close
        @close="showChance = false"
        direction="rtl"
      >
        <chance-view
          :key-data="dataSource.row"
          :active-tabs="dataSource.row.type"
        >
        </chance-view>
      </el-drawer>
    </template>

    <template v-if="dataType === 'contract'">
      <span class="remove-i">
        <span
          class="set-btn"
          @click="lockContract"
          v-dt-permission="'客户管理CRM-合同管理-锁定/解锁合同'"
        >
          {{ dataSource.row.isEdit ? '锁定' : '解锁' }}
        </span>
        <span v-dt-permission="'客户管理CRM-合同管理-锁定/解锁合同'"> | </span>
        <span
          :class="dataSource.row.isEdit ? 'set-btn' : 'lock'"
          @click="editContract"
          v-dt-permission="'客户管理CRM-合同管理-编辑'"
        >
          编辑
        </span>
        <span v-dt-permission="'客户管理CRM-合同管理-编辑'"> | </span>
        <span
          class="set-btn"
          @click="payBack"
          v-dt-permission="'客户管理CRM-收款管理-查看收款单'"
        >
          收款
        </span>
        <span v-dt-permission="'客户管理CRM-收款管理-查看收款单'"> | </span>
        <span
          :class="dataSource.row.isEdit ? 'set-btn' : 'lock'"
          @click="deleteChance"
          v-dt-permission="'客户管理CRM-合同管理-删除'"
        >
          删除
        </span>
        <span v-dt-permission="'客户管理CRM-合同管理-删除'"> | </span>
      </span>
      <el-drawer
        title="收款管理"
        size="40%"
        destroy-on-close
        append-to-body
        :visible.sync="showPay"
        :with-header="false"
      >
        <div>
          <pay-manage
            :pay-data="dataSource.row"
            :follow-user-list="followUserList"
          />
        </div>
      </el-drawer>
    </template>

    <template v-if="dataType === 'callHistory'">
      <span
        class="click"
        v-if="dataSource.row.ossUrl"
        v-dt-permission="'电销外呼-通话记录-播放录音'"
        @click="showAudio"
        >播放录音</span
      >
      <span
        class="click"
        v-else
        v-dt-permission="'电销外呼-通话记录-播放录音'"
        style="color: #c0c4cc"
        >播放录音</span
      >
    </template>

    <template v-if="dataType === 'followRecord'"> </template>

    <template v-if="dataType === 'payBack'">
      <span class="remove-i">
        <span
          class="set-btn"
          @click="editPayBack"
          v-dt-permission="'客户管理CRM-收款管理-编辑'"
          >编辑</span
        >
        <span v-dt-permission="'客户管理CRM-收款管理-编辑'"> | </span>
        <span
          class="set-btn"
          @click="delPayBack"
          v-dt-permission="'客户管理CRM-收款管理-删除'"
          >删除</span
        >
        <span v-dt-permission="'客户管理CRM-收款管理-删除'"> | </span>
      </span>
    </template>

    <template v-if="dataType === 'wc'">
      <span class="remove-i">
        <span
          @click="getAccessory(dataSource.row)"
          :class="
            dataSource.row.purchaseAccessory &&
            dataSource.row.purchaseAccessory !== '[]'
              ? 'set-btn'
              : 'set-btn-gray set-btn'
          "
        >
          附件
        </span>
        <span>|</span>

        <span
          class="set-btn"
          @click="editPurchase(dataSource.row)"
          v-dt-permission="'供应商-采购管理-编辑'"
        >
          编辑
        </span>
        <span>|</span>
        <span
          class="set-btn"
          @click="delPurchase(dataSource.row)"
          v-dt-permission="'供应商-采购管理-删除'"
        >
          删除
        </span>
        <span>|</span>
      </span>
    </template>
  </div>
</template>

<script>
import payManage from '@/views/contract/dialog/payManage'
import pondDetail from '@/views/customerpond/pondDetail'
import contactList from '@/views/customerpond/dialog/contactList'
import userProfile from '@/views/customerpond/userProfile'
import chanceView from '@/views/customerpond/dialog/chanceView'
import {
  delCustomerInfo,
  delPayBack,
  getCustomerInfoId,
  getCustomerLeaders
} from '@/api/customerpond'
import vote from '@/views/customerpond/dialog/fapiao'
import createCustomer from '@/views/customerpond/dialog/createCustomer'
import { contractDel, lockContract } from '@/api/user'
import createContract from '@/views/contract/dialog/createContract'
import MobileAudio from '@/views/callsystem/mobileaudio'
import createOrEditPayBack from '@/views/contract/dialog/createOrEditPayBack'
import marketList from '@/components/runIn/marketList'
import earnLink from '@/components/runIn/earnLink'
import downloadContract from '@/views/contract/dialog/downloadContract'
import editPurchase from '@/views/supplier/dialog/editPurchase'
import { purchaseDel } from '@/api/thread'

export default {
  name: 'SetTing',
  components: {
    pondDetail,
    userProfile,
    chanceView,
    payManage
  },
  data() {
    return {
      btnList: [],
      showDropDown: true,
      openDrawer: false,
      userProState: false,
      selectTitle: '',
      showChance: false,

      pageStatus: {
        pageNo: 1,
        pageSize: 10,
        total: null
      },

      // 编辑
      allLeaders: [],

      // 合同
      showPay: false
    }
  },
  props: {
    dataSource: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    allSource: {
      type: Array,
      default: () => []
    },
    allClue: {
      type: Array,
      default: () => []
    },
    allLevel: {
      type: Array,
      default: () => []
    },
    allMessage: {
      type: Array,
      default: () => []
    },
    allStatus: {
      type: Array,
      default: () => []
    },
    allPerTags: {
      type: Array,
      default: () => []
    },
    followUserList: {
      type: Array,
      default: () => []
    },
    perAllPerTags: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    if (this.dataType === 'customerPond') {
      this.setBtn()
      // this.getCustomerLeaders()
    }
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    },
    permissionEdit() {
      const type = this.dataSource.row.type
      if (type === 1) {
        return '客户管理CRM-客户公海-我负责的-编辑'
      }
      if (type === 3) {
        return '客户管理CRM-客户公海-可跟进的-编辑'
      }
      if (type === 2) {
        return '客户管理CRM-客户公海-全公司的-编辑'
      }
      if (type === 4) {
        return '客户管理CRM-客户公海-草稿箱-编辑'
      }
      return 'not allow'
    },
    permissionVote() {
      return '客户管理CRM-客户公海-发票'
    },
    permissionRecord() {
      const type = this.dataSource.row.type

      if (type === 1) {
        return '客户管理CRM-客户公海-我负责的-查看跟进记录'
      }
      if (type === 3) {
        return '客户管理CRM-客户公海-可跟进的-查看跟进记录'
      }
      if (type === 2) {
        return '客户管理CRM-客户公海-全公司的-查看跟进记录'
      }
      if (type === 4) {
        return '客户管理CRM-客户公海-草稿箱-查看跟进记录'
      }
      return 'not allow'
    },
    permissionYx() {
      const type = this.dataSource.row.type
      if (type === 1) {
        return '客户管理CRM-客户公海-我负责的-微营销'
      }
      if (type === 3) {
        return '客户管理CRM-客户公海-可跟进的-微营销'
      }
      if (type === 2) {
        return '客户管理CRM-客户公海-全公司的-微营销'
      }
      if (type === 4) {
        return '客户管理CRM-客户公海-草稿箱-微营销'
      }
      return 'not allow'
    },
    permissionContact() {
      return '客户管理CRM-客户公海-联系人'
    },
    permissionDel() {
      const type = this.dataSource.row.type
      if (type === 1) {
        return '客户管理CRM-客户公海-我负责的-删除'
      }
      if (type === 3) {
        return '客户管理CRM-客户公海-可跟进的-删除'
      }
      if (type === 2) {
        return '客户管理CRM-客户公海-全公司的-删除'
      }
      if (type === 4) {
        return '客户管理CRM-客户公海-草稿箱-删除'
      }
      return 'not allow'
    },
    permissionNiche() {
      const type = this.dataSource.row.type
      if (type === 1) {
        return '客户管理CRM-客户公海-我负责的-商机'
      }
      if (type === 3) {
        return '客户管理CRM-客户公海-可跟进的-商机'
      }
      if (type === 2) {
        return '客户管理CRM-客户公海-全公司的-商机'
      }
      if (type === 4) {
        return '客户管理CRM-客户公海-草稿箱-商机'
      }
      return 'not allow'
    },
    permissionPro() {
      const type = this.dataSource.row.type
      if (type === 1) {
        return '客户管理CRM-客户公海-我负责的-智能画像分析'
      }
      if (type === 3) {
        return '客户管理CRM-客户公海-可跟进的-智能画像分析'
      }
      if (type === 2) {
        return '客户管理CRM-客户公海-全公司的-智能画像分析'
      }
      if (type === 4) {
        return '客户管理CRM-客户公海-草稿箱-智能画像分析'
      }
      return 'not allow'
    },

    dataType() {
      if (this.type === '电销接听') return 'callHistory' // 电销系统-通话记录
      if (this.type === '跟进客户') return 'customerPond' // 客户管理系统-客户公海
      if (this.type === '成交') return 'customerPond' // 客户管理系统-客户公海
      if (this.type === '产品演示') return 'followRecord' // 客户管理系统-跟进记录
      if (this.type === '当面拜访') return 'followRecord' // 客户管理系统-跟进记录
      if (this.type === '合同金额') return 'contract' // 客户管理系统-合同管理
      if (this.type === '收款金额') return 'payBack' // 客户管理系统-收款管理
      if (this.type === '外采金额') return 'wc'
    }
  },
  methods: {
    // 外采金额
    getAccessory(params) {
      // 下载附件
      let list = []
      if (params.purchaseAccessory && params.purchaseAccessory !== '[]')
        list = JSON.parse(params.purchaseAccessory)
      if (!list.length) return
      if (list.length === 1) {
        let downUrl = list[0].url.replace('http', 'https')
        saveAs(downUrl, `${params.purchaseId}-${list[0].name}`)
      }
      if (list.length > 1) {
        this.$store.dispatch('showModal', {
          title: `附件列表 - ${params.purchaseId}`,
          view: downloadContract,
          size: '600px',
          options: {
            list: list,
            companyName: params.purchaseId
          }
        })
      }
    },

    editPurchase(data) {
      this.$store.dispatch('showModal', {
        view: editPurchase,
        size: '800px',
        title: '编辑采购单',
        options: {
          type: 'edit',
          params: data
        },
        onClose: res => {
          if (res.refresh) this.$emit('refresh')
        }
      })
    },

    delPurchase(data) {
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: `确认删除${data.purchaseId}？`,
        type: 'warning',
        onClose: res => {
          if (res === 'confirm') {
            purchaseDel({ id: data.id }).then(res => {
              if (res.code === 60000) {
                this.$emit('refresh')
                this.$store.dispatch('showTip', {
                  text: res.msg,
                  type: 'success'
                })
              }
            })
          }
        }
      })
    },

    // 客户公海
    setBtn() {
      let btnList = [
        {
          name: '跟进记录',
          click: (a, b) => {
            this.openCusPondDetail(a, b)
          },
          permission: this.permissionRecord,
          show: this.permissionList.includes(this.permissionRecord)
        },
        {
          name: '联系人',
          click: (a, b) => {
            this.openContact(a, b)
          },
          permission: this.permissionContact,
          show: this.permissionList.includes(this.permissionContact)
        },
        {
          name: '微营销',
          click: (a, b) => {
            this.handleMicro(a, b)
          },
          permission: this.permissionYx,
          show: this.permissionList.includes(this.permissionYx)
        },
        {
          name: '智能画像分析',
          click: (a, b) => {
            this.openProfile(a, b)
          },
          permission: this.permissionPro,
          show: this.permissionList.includes(this.permissionPro)
        },
        {
          name: '商机',
          click: (a, b) => {
            this.viewNiche(a, b)
          },
          permission: this.permissionNiche,
          show: this.permissionList.includes(this.permissionNiche)
        },
        {
          name: '编辑',
          click: (a, b) => {
            this.editCusPond(a, b)
          },
          permission: this.permissionEdit,
          show: this.permissionList.includes(this.permissionEdit)
        },
        {
          name: '发票',
          click: (a, b) => {
            this.getVote(a, b)
          },
          permission: this.permissionVote,
          show: this.permissionList.includes(this.permissionVote)
        },
        {
          name: '删除',
          click: (a, b) => {
            this.delCusPond(a, b)
          },
          permission: this.permissionDel,
          show: this.permissionList.includes(this.permissionDel)
        }
      ]
      // this.btnList = btnList.filter(item => item.show);
      this.btnList = btnList
      if (this.btnList.length <= 2) {
        this.showDropDown = false
      }
    },
    // 跟进记录
    openCusPondDetail() {
      this.openDrawer = true
    },
    // 联系人
    openContact() {
      this.$store.dispatch('showModal', {
        title: '联系人详情',
        view: contactList,
        size: '900px',
        options: {
          companyUuid: this.dataSource.row.company_uuid,
          companyName: this.dataSource.row.company_name,
          packageId: this.dataSource.row.packageId || '0',
          showSelect: false
        },
        type: 'drawer',
        onClose: () => {
          this.$emit('refresh')
        }
      })
    },
    // 客户画像
    openProfile() {
      this.selectTitle = '智能画像分析-' + this.dataSource.row.companyName
      this.userProState = false
      Object.assign(this.dataSource.row, { pathType: 1 })
      this.$nextTick(() => {
        this.userProState = true
        this.$nextTick(() => {
          this.$refs.userPro.init()
        })
      })
    },
    // 删除
    delCusPond() {
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: `删除客户同时会删除联系人、跟进记录等与该客户相关的所有信息，是否确认？`,
        type: 'warning',
        onClose: res => {
          if (res === 'confirm') {
            const params = {
              id: this.dataSource.row.id
            }
            delCustomerInfo(params).then(res => {
              if (res.code === 60000) {
                this.$emit('refresh')
                this.$message({
                  type: 'success',
                  message: '删除成功'
                })
              }
            })
          }
        }
      })
    },
    // 商机
    viewNiche() {
      this.showChance = true
    },
    // 发票
    getVote(val) {
      this.$store.dispatch('showModal', {
        view: vote,
        size: '600px',
        options: {
          data: val,
          activeTabs: this.dataSource.row.type
        },
        type: 'drawer'
      })
    },

    // 微营销
    handleMicro() {
      this.$store.dispatch('showModal', {
        view: marketList,
        size: '600px',
        title: '选择素材',
        onClose: res => {
          if (res.refresh) {
            const _t = setTimeout(() => {
              this.earnLink(res.id)
              clearTimeout(_t)
            }, 100)
          }
        }
      })
    },

    earnLink(id) {
      this.$store.dispatch('showModal', {
        view: earnLink,
        size: '600px',
        title: '生成转发链接',
        options: {
          id: id,
          uuid:
            this.dataSource.row.companyUuid || this.dataSource.row.company_uuid
        }
      })
    },

    // 编辑
    editCusPond(val) {
      const params = {
        id: this.dataSource.row.id
      }
      getCustomerInfoId(params).then(res => {
        if (res.code === 60000) {
          this.editCusModel(
            Object.assign(res.data, {
              province: this.dataSource.row.province,
              city: this.dataSource.row.city
            })
          )
        }
      })
    },

    editCusModel1(params) {
      const formData = {
        userId: params.userId || '',
        companyName: params.companyName || '',
        remark: params.remark || '',
        gradeId: params.gradeId || '',
        fromId: params.fromId || '',
        stateId: params.stateId || '',
        clueGradeId: params.clueGradeId || '',
        tagsId: params.tagsId || '',
        customerId: params.id || '',
        province: params.province,
        city: params.city
      }
      let selectedTags = []
      let selectedPrivateTags = []
      if (params.perTagsId) {
        selectedTags = params.perTagsId
          .toString()
          .split(',')
          .map(item => Number(item))
      }
      if (params.privateTagsId) {
        selectedPrivateTags = params.privateTagsId
          .toString()
          .split(',')
          .map(item => Number(item))
      }
      this.$store.dispatch('showModal', {
        title: params.companyName,
        size: '850px',
        top: '90px',
        view: createCustomer,
        options: {
          leaderList: this.allLeaders,
          userId: '',
          allSource: this.allSource,
          allClue: this.allClue,
          allLevel: this.allLevel,
          allMessage: this.allMessage,
          allStatus: this.allStatus,
          allPerTags: this.allPerTags,
          perAllPerTags: this.perAllPerTags,
          selectTags: selectedTags,
          selectedPrivateTags: selectedPrivateTags,
          formData: formData,
          activeTabs: this.dataSource.row.type + '',
          type: 'edit'
        },
        onClose: res => {
          if (res.refresh) {
            // 刷新列表
            this.$emit('refresh')
          }
        }
      })
    },

    editCusModel(params) {
      if (this.allLeaders.length) {
        this.editCusModel1(params)
      } else {
        const data = {
          pageId: 2001,
          type: this.dataSource.row.type
        }
        getCustomerLeaders(data).then(res => {
          if (res.code === 60000) {
            this.allLeaders = res.data
            this.editCusModel1(params)
          }
        })
      }
    },

    getCustomerLeaders() {
      const params = {
        pageId: 2001,
        type: this.dataSource.row.type
      }
      getCustomerLeaders(params).then(res => {
        if (res.code === 60000) {
          this.allLeaders = res.data
        }
      })
    },
    jump(res) {
      res.item.click(res.row, res.index)
    },

    //合同
    lockContract() {
      const isEdit = this.dataSource.row.isEdit ? 0 : 1
      lockContract({ id: this.dataSource.row.id, isEdit: isEdit }).then(res => {
        if (res.code === 60000) {
          this.$store.commit('update', {
            target: this.dataSource.row,
            data: {
              isEdit: isEdit
            }
          })
        }
      })
    },

    editContract() {
      if (!this.dataSource.row.isEdit) return // 锁定
      this.$store.dispatch('showModal', {
        title: '编辑合同',
        view: createContract,
        size: '800px',
        options: {
          type: 'edit',
          data: this.dataSource.row
        },
        onClose: res => {
          if (res.refresh) {
            this.$emit('refresh')
          }
        }
      })
    },

    payBack() {
      this.showPay = true
    },

    deleteChance() {
      if (!this.dataSource.row.isEdit) return // 锁定
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: `确认删除该条合同 ？`,
        type: 'warning',
        onClose: res => {
          if (res === 'confirm') {
            this.deleteContractFunc(this.dataSource.row.id)
          }
        }
      })
    },
    deleteContractFunc(id) {
      contractDel({ id: id }).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
          this.$emit('refresh')
        }
      })
    },

    // 通话记录
    showAudio() {
      this.$store.dispatch('showModal', {
        title: '播放录音',
        view: MobileAudio,
        size: '600px',
        options: {
          row: this.dataSource.row
        }
      })
    },

    // 收款
    editPayBack() {
      this.$store.dispatch('showModal', {
        view: createOrEditPayBack,
        size: '600px',
        title: '编辑收款单',
        options: {
          userList: this.followUserList,
          type: 'update',
          data: this.dataSource.row
        },
        onClose: res => {
          if (res.refresh) {
            this.$emit('refresh')
          }
        }
      })
    },

    delPayBack() {
      const { id, contractReturnNumber } = this.dataSource.row
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: `确认删除 ${contractReturnNumber}? `,
        onClose: res => {
          if (res === 'confirm') {
            delPayBack({ id }).then(res => {
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: res.code === 60000 ? 'success' : 'error'
              })
              res.code === 60000 ? this.$emit('refresh') : ''
            })
          }
        },
        type: 'warning'
      })
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.remove-i > .opea:last-child {
  margin-right: 0 !important;
}

.remove-i > .opea:last-child::after {
  display: none;
}

.remove-i > span:last-child {
  display: none !important;
}

.remove-ii > .opea:last-child {
  margin-right: 0 !important;
}

.remove-ii > .opea:last-child::after {
  display: none;
}

.com-list-detail {
  color: #0099cc;
  cursor: pointer;
}

.opea {
  margin-right: 6px;
  position: relative;
}

.opea::after {
  content: '';
  display: inline-block;
  height: 10px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  right: -3px;
  top: 20px;
}
.badge-item ::v-deep .el-badge__content.is-fixed {
  top: 10px !important;
}
.el-drawer__body {
  overflow: auto;
}
.el-drawer__container ::-webkit-scrollbar {
  display: none;
}
.set-btn {
  cursor: pointer;
  color: #0099cc;
}
.lock {
  cursor: not-allowed;
  color: #8c939d !important;
}
.click {
  color: #0099cc;
  cursor: pointer;
}
.set-btn-gray {
  color: rgba(0, 0, 0, 0.3) !important;
  cursor: not-allowed;
}
</style>
