<template>
  <div class="pe-angle">
    <div class="box">
      <Icon type="img" peClass="angle_1" class="angle angle_1" />
      <Icon type="img" peClass="angle_2" class="angle angle_2" />
      <Icon type="img" peClass="angle_3" class="angle angle_3" />
      <Icon type="img" peClass="angle_4" class="angle angle_4" />

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'anglePanel',
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.pe-angle {
  .box {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .angle {
    position: absolute;
    width: 20px;
    height: 20px;
  }
  .angle_1 {
    left: -5px;
    top: -5px;
  }
  .angle_2 {
    left: -5px;
    bottom: -5px;
  }
  .angle_3 {
    right: -5px;
    top: -5px;
  }
  .angle_4 {
    right: -5px;
    bottom: -5px;
  }
}
</style>
