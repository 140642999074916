
<template>
  <div class="monitorDetail-main">
    <el-scrollbar class="main dt-dropdown-dtt" v-loading="loading">
      <div class="main-row" v-show="list && list.length">
<!--        <el-row :gutter="20" style="margin: 0 0 40px 0" v-show="false">-->
<!--          <el-col :span="4"> <div class="header">日期</div> </el-col>-->
<!--          <el-col :span="4"> <div class="header">事件</div> </el-col>-->
<!--          <el-col :span="2"> <div class="header">图标</div> </el-col>-->
<!--          <el-col :span="14"> <div class="header">内容</div> </el-col>-->
<!--        </el-row>-->
        <el-row :gutter="20" style="margin: 20px 0" v-for="item in list">
          <el-col :span="5"> <div class="value">{{ item.created_at }}</div> </el-col>
          <el-col :span="4"> <div class="value">{{ item.quota }}</div> </el-col>
          <el-col :span="2">
            <div class="value">
              <el-image style="width: 30px; height: 30px" v-if="item.illustration" :src="item.illustration"></el-image>
              <div>-</div>
            </div>
          </el-col>
          <el-col :span="13"> <div class="value">{{ item.change_desc }} </div> </el-col>
        </el-row>
      </div>
      <span class="center" v-show="!list || ( list && list.length === 0 )">
        暂无数据
      </span>
    </el-scrollbar>
    <div class="footer">
      <el-button type="primary" size="small" @click="confirmBtn">确定</el-button>
    </div>
  </div>
</template>

<script>
import { monitorDetail } from '@/api/thread'
export default {
  name: 'monitorDetail',
  data () {
    return {
      list: [],
      companyUuid: '',
      loading: true
    }
  },
  mounted () {
    this.companyUuid = this.$store.state.plugin.modal.options.companyUuid
    this.getMonitorDetail()
  },
  computed: {},
  methods: {
    confirmBtn(){
      this.$store.dispatch('hideModal')
    },
    getMonitorDetail(){
      const params = {
        companyUuid: this.companyUuid
      }
      monitorDetail(params).then(res=>{
        this.loading = false
        if(res.code === 60000){
          this.list = res.data.list
        }
      })
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.main{
  height: 400px;
  width: 100%;
}
.main-row{
  width: calc( 100% - 20px );
  margin: 10px auto;
}
.footer{
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.1);
  transform: scaleY(.5);
}
.header{
  font-size: 15px;
  color: red;
  font-weight: bold;
}
.value{
  line-height: 150%;
  font-size: 12px;
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
