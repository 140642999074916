<template>
  <div class="software-list-main">
    <div class="search">
      <nurture-btn
        :selectionList="selectionList"
        @getListData="getList"
      ></nurture-btn>
      <div class="input">
        <el-input
          clearable
          size="small"
          v-model="word"
          placeholder="请输入公司名称查询"
        ></el-input>
      </div>
      <div class="btn">
        <el-button type="primary" size="small" @click="getList">查询</el-button>
        <el-button size="small" @click="handleSoftwareReset">重置</el-button>
      </div>
    </div>
    <div
      class="table list-table-app"
      :style="{ minHeight: `${minHeight - 100}px` }"
    >
      <el-table
        border
        ref="table"
        @sort-change="sortChange"
        :data="list"
        size="small"
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>

        <el-table-column
          :align="item.name === '公司名称' ? 'left' : 'center'"
          :sortable="item.hasOrder === 0 ? false : 'customer'"
          :show-overflow-tooltip="computedOverflow(item)"
          :width="computerWidth(item)"
          v-for="(item, index) in columns"
          :key="index"
          :label="item.name"
        >
          <template slot="header">
            <span v-if="item.name === '活跃度'">
              <draw-down-menu
                @handleCommand="handleCommand"
                :drawList="item.filterOptionList"
              ></draw-down-menu>
            </span>
          </template>
          <template slot-scope="scope">
            <span class="c-name" v-if="item.name === '公司名称'">
              <Star
                :data="scope.row.sub"
                :selectObj="scope.row"
                @getListData="getList"
              ></Star>
              <span @click="goKeyDetail(scope.row, scope.$index)">
                {{ scope.row[item.field] }}</span
              >
              <span v-if="scope.row.newTag === 1" style="color:#f00">新增</span>
              <span
                v-else-if="scope.row.updateTag === 1"
                style="color:orange;font-size:10px"
                >更新</span
              >
            </span>
            <span v-else-if="item.field === 'qcc_vc_amount'">
              {{
                scope.row[item.field] === 0 ? '未披露' : scope.row[item.field]
              }}
            </span>
            <span v-else-if="item.field === 'tags'">
              <Tags :tagList="scope.row.tags"></Tags>
            </span>
            <span v-else>{{ scope.row[item.field] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="t-footer page-div page-footer-page page">
      <footer-page
        @handleCurrentChange="handleCurrentChange"
        @goNumPage="goNumPage"
        @changePageSize="changePageSize"
        :loading="loading"
        :pageStatus="pageObj"
      ></footer-page>
    </div>
  </div>
</template>

<script>
import { package_filter } from '@/api/thread'
import DrawDownMenu from '@/components/Peculiar/draw-down'
import { softwareList } from '@/api/customerpond'
import footerPage from '@/components/Footerpage/oace'
import searchBreed from '@/views/extendApp/search/searchPanel'
import tip from '@/views/insight/tip'
import { mapGetters } from 'vuex'
import NurtureBtn from '@/components/Peculiar/nurture-btn'
import { enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'SoftwareList',
  components: {
    footerPage,
    NurtureBtn,
    DrawDownMenu
  },
  data() {
    return {
      selectionList: [],
      pageObj: {
        pageNo: 1,
        pageSize: localStorage.getItem('pageSize') || 20,
        total: 0
      },
      list: [],
      columns: [],
      loading: false,
      word: '',
      condition: '',
      tag: '',
      sort: '',
      show: 0,
      websiteAccess: 0,
      isJob: 0,
      phone: 0,
      upGongyingshang: 0,
      newClues: 0,
      customer: 0,
      followUserId: '',
      qualityCustomer: 0,
      whetherClick: 0,
      consumption: 0,
      unCalled: false,
      readOnlyExcept: false,
      resetFlag: false
    }
  },
  props: {
    package: {
      type: Object,
      default: () => {}
    },
    minHeight: {
      type: Number,
      default: 0
    },
    params: {
      type: Object,
      default: () => {}
    },
    activeTab: {
      type: String,
      default: '1'
    }
  },
  watch: {
    params: function() {
      this.reset()
    },
    activeTab(e) {
      console.log(e, 9900)
      if (e == 2 || e == 3) {
        this.getList()
      }
    }
  },
  mounted() {
    let pageSize = localStorage.getItem('pageSize')
    if (pageSize) pageSize = parseInt(pageSize)
    this.pageObj.pageSize = pageSize || 20
    this.reset()
  },
  computed: {
    ...mapGetters(['accountInfo']),
    canViewDetail() {
      return !!this.accountInfo.softwareInsightDetailsOpen
    }
  },
  methods: {
    computerWidth(item) {
      if (item.name === '公司名称') {
        return '300px'
      } else if (item.name === '标签') {
        return '180px'
      } else {
        return 'auto'
      }
    },
    computedOverflow(item) {
      if (item.name === '标签') {
        return false
      } else {
        return true
      }
    },
    reset() {
      this.list = []
      this.pageObj.pageNo = 1
      this.pageObj.total = 0
      this.word = ''
      this.condition = ''
      this.tag = ''
      this.sort = ''
      this.show = 0
      this.websiteAccess = 0
      this.isJob = 0
      this.phone = 0
      this.upGongyingshang = 0
      this.newClues = 0
      this.customer = 0
      this.followUserId = ''
      this.qualityCustomer = 0
      this.whetherClick = 0
      this.consumption = 0
      this.unCalled = false
      this.readOnlyExcept = false
      this.oneIndusty = ''
      this.$nextTick(() => {
        this.getList()
      })
    },
    handleSoftwareReset() {
      this.$bus.$emit('handleSoftwareChange')
    },
    getList() {
      const params = {
        oneIndusty: '',
        vc: '',
        area: '',
        companyName: this.word,
        htTime: '',
        establishmentTime: '',
        personal: 0,
        pageNo: this.pageObj.pageNo,
        pageSize: this.pageObj.pageSize,
        condition: this.condition,
        sort: this.sort,
        personal: this.activeTab == 3 ? 1 : 0
      }
      this.loading = true
      softwareList(Object.assign({}, params, this.params))
        .then(res => {
          if (res.code === 60000) {
            this.pageObj.total = parseInt(res?.data?.page?.total || '0')
            this.loading = false
            this.columns = res?.data?.columns || []
            this.list = res?.data?.list || []
            this.packId = res?.data.packageId
            this.$emit('count', this.pageObj.total)
          } else {
            this.loading = false
            this.$emit('count', 0)
          }
        })
        .catch(() => {
          this.$emit('count', 0)
        })
    },
    handleCurrentChange(val, isVip) {
      if (isVip == 1 && val > 1) {
        this.$store.dispatch('showModal', {
          title: '提示',
          view: tip,
          size: '600px'
        })
        return
      }
      this.pageObj.pageNo = val
      this.getList()
    },
    goNumPage(val, isVip) {
      if (isVip == 1 && val > 1) {
        this.$store.dispatch('showModal', {
          title: '提示',
          view: tip,
          size: '600px'
        })
        return
      }
      this.pageObj.pageNo = Number(val)
      this.getList()
    },
    changePageSize(val, isVip) {
      console.log(val, 'valChange')
      if (isVip == 1 && val > 20) {
        this.$store.dispatch('showModal', {
          title: '提示',
          view: tip,
          size: '600px'
        })
        return
      }
      this.pageObj.pageNo = 1
      this.pageObj.pageSize = val
      const N = Math.ceil(this.pageObj.total / this.pageObj.pageSize)
      if (Number(this.pageObj.pageNo) > N) {
        this.pageObj.pageNo = N
      }
      this.getList()
    },
    sortChange(data) {
      const item = this.columns.find(item => item.name === data.column.label)
      console.log(data, 'itemData')
      const map = {
        ascending: '升序',
        descending: '降序'
      }
      const orderType = data.order ? map[data.order] : ''
      this.sort = `${item.field}:${orderType}`
      this.getList()
    },
    openSearch() {
      package_filter({ packageId: this.package.id }).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('showModal', {
            title: '高级筛选',
            view: searchBreed,
            size: '800px',
            options: {
              source: res.data,
              type: 'customerView'
            },
            onClose: res => {
              if (res.refresh) {
                this.pageObj.pageNo = 1
                this.pageObj.pageSize = 20
                this.condition = res.searchFilter
                this.getList()
              }
            }
          })
        }
      })
    },
    goKeyDetail(data, index) {
      if (!this.canViewDetail) {
        this.$store.dispatch('showModal', {
          title: '提示',
          view: tip,
          size: '600px'
        })
        return
      }

      const _params = {
        packId: this.packId,
        pageNo: this.pageObj.pageNo,
        pageSize: this.pageObj.pageSize,
        companyName: this.word,
        condition: this.condition,
        tag: this.tag,
        sort: this.sort,
        show: this.show,
        websiteAccess: this.websiteAccess,
        isJob: this.isJob,
        phone: this.phone,
        upGongyingshang: this.upGongyingshang,
        newClues: this.newClues,
        customer: this.customer,
        followUserId: this.followUserId,
        qualityCustomer: this.qualityCustomer,
        whetherClick: this.whetherClick,
        consumption: this.consumption,
        unCalled: this.unCalled,
        readOnlyExcept: this.readOnlyExcept
      }

      sessionStorage.setItem('KeyParams', Object.assign(_params, this.params))
      enterCompanyDetail(data, this.list, index, this.packId, {
        followUserId: data.followUserId
      })
    },

    handleSelectionChange(e) {
      this.selectionList = e
    },
    handleCommand(e) {
      this.condition = `activity_class:选择:${e}`
      this.getList()
    }
  },
  beforeDestroy() {
    this.$bus.$off()
  }
}
</script>

<style lang="scss">
.software-list-main {
  .wrap {
    display: flex;
    align-items: center;
    span {
      &:first-child {
        transform: scale(0.8);
        color: #909399;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.software-list-main {
  padding: 0 10px;
  box-sizing: border-box;

  .search {
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 10px;
    box-sizing: border-box;
    &::after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      transform: scaleY(0.5);
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .input {
      margin-left: 10px;
    }
    .btn {
      margin-right: 15px;
    }
    .input {
      width: 350px;
    }
    .filter {
      color: #78d9d1;
      font-size: 14px;
      text-decoration: underline;
      font-weight: 400;
      cursor: pointer;
      display: flex;
    }
  }
  .table {
    .c-name {
      color: #0099cc;
      cursor: pointer;
      .new {
        display: inline-block;
        height: 20px;
        width: 40px;
        color: #41cabf;
        background: url('../../../assets/new.svg') no-repeat center center;
        background-size: 40px 40px;
      }
    }
  }
  .tag-content {
    display: flex;
    align-items: center;
    .el-tag {
      margin: 0 2px;
      display: inline;
    }
  }
  .more-show ::v-deep .el-tag {
    display: flex;
    align-items: center;
    margin: 2px;
  }
}
</style>
<style lang="scss">
.software-list-main {
  .list-table-app {
    // padding-bottom: 10px;
    .el-table--enable-row-transition .el-table__body tbody tr td {
      border-right: none;
    }
    .el-table--border .el-table__body tbody tr td {
      border-right: none;
    }
  }
}
</style>
