<template>
  <small></small>
</template>

<script>
export default {
  name: 'DtMessageBox',
  computed: {
    messageBox() {
      return this.$store.state.plugin.messageBox
    },
    messageBoxId() {
      return this.$store.state.plugin.messageBox.id
    }
  },
  watch: {
    messageBoxId() {
      this.$confirm(
        this.messageBox.message,
        this.messageBox.title,
        Object.assign({}, this.messageBox)
      )
    }
  }
}
</script>

<style scoped></style>
