<!--<template>-->
<!--  <div class="predict-main">-->

<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: 'predict',-->
<!--  data () {-->
<!--    return {}-->
<!--  },-->
<!--  mounted () {},-->
<!--  computed: {},-->
<!--  methods: {},-->
<!--  beforeDestroy () {}-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->

<template>
  <div class="dt-data">
    <div class="dt-data" v-dt-permission="'销售过程分析-业绩预测-查看'">
      <div class="dt-data-header">
        <el-button-group>
          <el-button
            size="small"
            @click="selectTimeClick(1)"
            :type="selectBtn === 1 ? 'primary' : ''"
            :plain="selectBtn === 1"
            >今天
          </el-button>
          <el-button
            size="small"
            @click="selectTimeClick(-1)"
            :type="selectBtn === -1 ? 'primary' : ''"
            :plain="selectBtn === -1"
            >昨天
          </el-button>
          <el-button
            size="small"
            @click="selectTimeClick(-7)"
            :type="selectBtn === -7 ? 'primary' : ''"
            :plain="selectBtn === -7"
            >最近七天
          </el-button>
          <el-button
            size="small"
            @click="selectTimeClick(-30)"
            :type="selectBtn === -30 ? 'primary' : ''"
            :plain="selectBtn === -30"
            >最近30天
          </el-button>
          <el-button
            size="small"
            @click="selectTimeClick('m')"
            :type="selectBtn === 'm' ? 'primary' : ''"
            :plain="selectBtn === 'm'"
            >本月
          </el-button>
          <el-button
            size="small"
            @click="selectTimeClick('jd')"
            :type="selectBtn === 'jd' ? 'primary' : ''"
            :plain="selectBtn === 'jd'"
            >本季度
          </el-button>
          <el-button
            size="small"
            @click="selectTimeClick(0)"
            :type="selectBtn === 0 ? 'primary' : ''"
            :plain="selectBtn === 0"
            >全部
          </el-button>
        </el-button-group>

        <div class="dt-select-time">
          <el-date-picker
            :picker-options="viewDemo ? startDateLimit : startDateLimit2"
            :editable="false"
            @change="selectTimeClickAuto"
            :clearable="false"
            size="small"
            v-model="selectTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div style="position: absolute; right: 20px" v-if="false">
          <el-button
            :class="viewDemo ? '' : ''"
            autofocus
            size="small"
            :key="viewDemo"
            :type="viewDemo ? '' : 'primary'"
            @click="changeViewDemoType"
            >{{ viewDemo ? '关闭示例' : '查看示例' }}</el-button
          >
        </div>
      </div>
      <el-scrollbar
        class="dt-data-row dt-dropdown-dt"
        ref="scrollbar"
        style="background-color:#fff;"
      >
        <div style="height: 100%">
          <div class="data-1" v-loading="loadingDataOne" v-if="false">
            <div
              class="data-1-item"
              v-for="(key, index) in dataOneData.config"
              :key="index"
            >
              <div class="label">
                {{ key.label }}
              </div>
              <div class="value" v-if="key.key !== 'contractMoneySum'">
                {{ dataOneData.data[key.key] || '0' }}
              </div>
              <div v-else class="value">
                {{ (dataOneData.data[key.key] || '0').toLocaleString() }}
              </div>
            </div>
          </div>

          <div v-if="false" class="data-1-new" ref="scroll">
            <div class="data-title" style="z-index: 99">
              转化漏斗
            </div>
            <el-row :gutter="10">
              <el-col :span="24">
                <div class="data-1-new-left" v-loading="loadingDataLd">
                  <div
                    style="height: 100%; width: 50%"
                    id="data-1-new-left"
                  ></div>
                </div>
              </el-col>
              <el-col :span="12" v-if="false">
                <div class="data-1-new-right">
                  <div class="data-title">
                    阶段客户画像
                  </div>
                  <div class="data-1-new-right-tip">
                    敬请期待...
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="achievement">
            <div
              style="padding: 0 0 10px 0;box-sizing: border-box"
              v-if="false"
            >
              业绩分析
            </div>
            <div v-show="isLn && false">
              <el-tabs v-model="tabType" @tab-click="tabClick" class="tab">
                <el-tab-pane label="销售过程分析" name="xs" />
                <el-tab-pane label="签约类型分析" name="qy" />
                <el-tab-pane label="回款分析" name="hk" />
                <el-tab-pane label="业绩预测" name="yj" />
              </el-tabs>
            </div>
            <div class="btn-table-chart">
              <div class="btn-chart">
                <span style="display: inline-block;height: 28px;">
                  <i
                    @click="changeAchievementType('table')"
                    :class="
                      achievementType === 'table'
                        ? 'active-table'
                        : 'default-table'
                    "
                  />
                  <i
                    @click="changeAchievementType('histogram')"
                    :class="
                      achievementType === 'histogram'
                        ? 'active-histogram'
                        : 'default-histogram'
                    "
                  />
                  <i
                    @click="changeAchievementType('pie')"
                    :class="
                      achievementType === 'pie' ? 'active-pie' : 'default-pie'
                    "
                  />
                </span>
                <el-select
                  v-model="currentSelectType"
                  placeholder="请选择"
                  size="mini"
                  @change="newSelectChange"
                  v-show="achievementType !== 'table'"
                  style="transform: translate(3px,-3px)"
                >
                  <el-option
                    v-for="item in newOptions"
                    :key="item.field"
                    :label="item.name"
                    :value="item.field"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="table-chart">
                <template v-if="achievementType === 'table'">
                  <div class="table">
                    <el-table
                      class="table-dt"
                      :data="tableData"
                      @sort-change="sortChange"
                      show-summary
                      border
                      stripe
                      style="width: 100%; height: 100%; font-size: 12px;background-color:#fff;"
                    >
                      <el-table-column
                        show-tooltip-when-overflow
                        v-for="item in columnList"
                        :prop="item.field"
                        align="center"
                        :sortable="item.type === 'sort' ? 'custom' : false"
                        :label="item.name"
                      >
                        <template slot-scope="scope">
                          <run-in
                            :data-num="scope.row[item.field]"
                            :data-type="item.field"
                            :data-name="item.name"
                            :data-format="
                              `${scope.row['userName']} ${formatFilter} ${
                                item.name
                              }`
                            "
                            :data-filter="{
                              startDate,
                              endDate,
                              userId: scope.row['userId']
                            }"
                          >
                          </run-in>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
                <template v-if="achievementType === 'histogram'">
                  <div class="histogram" id="histogram"></div>
                </template>
                <template v-if="achievementType === 'pie'">
                  <div class="pie" id="pie"></div>
                </template>
              </div>
            </div>
          </div>

          <div
            class="data-3"
            v-dt-permission="'数据看板-数据看板-分布图'"
            v-if="false"
          >
            <div class="select-data-3">
              <div style="padding: 0 0 10px 0;box-sizing: border-box">
                分布图
              </div>
              <el-select
                v-model="value"
                placeholder="请选择"
                size="mini"
                @change="selectChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="data-3-item">
              <div
                class="data-3-item-left"
                id="data-3-left"
                v-loading="loadingDataTwo"
              ></div>
              <div
                class="data-3-item-right"
                id="data-3-right"
                v-loading="loadingDataTwo"
              ></div>
            </div>
          </div>

          <div
            class="data-4"
            v-dt-permission="'数据看板-数据看板-趋势图'"
            v-if="false"
          >
            <div class="select-data-3">
              <div style="padding: 0 0 10px 0;box-sizing: border-box">
                趋势图
              </div>
              <el-select
                v-model="value1"
                placeholder="请选择"
                size="mini"
                @change="selectChange1"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <el-select
                v-if="false"
                multiple
                collapse-tags
                v-model="selectUserList"
                placeholder="请选择成员"
                size="mini"
                @change="selectUserChange"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <el-popover placement="bottom" width="400" trigger="click">
                <div class="j-input" slot="reference">
                  请选择成员
                </div>

                <div class="user-list">
                  <el-row :gutter="20">
                    <el-col
                      :span="8"
                      v-for="user in userList"
                      :key="user.id"
                      style="margin-bottom: 10px"
                    >
                      <el-tooltip
                        :disabled="
                          (user.name && user.name.length < 8) || !user.name
                        "
                        :visible-arrow="false"
                        popper-class="popper"
                        effect="dark"
                        :content="user.name"
                        placement="bottom"
                      >
                        <el-checkbox
                          class="checkbox-dt check-black"
                          @change="change(user)"
                          :checked="user.id == userIdList[user.id]"
                        >
                          {{ user.name }}
                        </el-checkbox>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                </div>
              </el-popover>
              <el-checkbox
                class="check-black"
                v-model="excludeHoliday"
                @change="checkChange"
                >排除周末节假日</el-checkbox
              >
            </div>

            <div
              class="data-4-item"
              id="data-4-item"
              v-loading="loadingDataFour"
              ref="dataFourRef"
            ></div>
          </div>

          <div class="data-2" v-if="false">
            <el-row :gutter="10">
              <el-col :span="12" v-dt-permission="'数据看板-数据看板-智能获客'">
                <div class="data-2-item" ref="znhk">
                  <el-card
                    header="智能获客"
                    shadow="hover"
                    style="height: 100%"
                  >
                    <el-row
                      :gutter="20"
                      v-loading="loadingDataEnd || loadingDataFive"
                      :style="{ minHeight: cardHeight }"
                    >
                      <el-col
                        :span="6"
                        v-for="(key, index) in dataFiveData.config"
                        :key="index"
                      >
                        <div
                          style="padding: 20px; box-sizing: border-box;height: 100px"
                        >
                          <div class="label">
                            {{ key.label }}
                          </div>
                          <div class="value">
                            {{
                              key.key === 'none'
                                ? ''
                                : (
                                    dataFiveData.data[key.key] || '0'
                                  ).toLocaleString()
                            }}
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </div>
              </el-col>

              <el-col :span="12" v-dt-permission="'数据看板-数据看板-客户培育'">
                <div class="data-2-item" ref="khpy">
                  <el-card
                    header="客户培育"
                    shadow="hover"
                    style="height: 100%"
                  >
                    <el-row
                      :gutter="20"
                      v-loading="loadingDataEnd || loadingDataFive"
                      :style="{ minHeight: cardHeight }"
                    >
                      <el-col
                        :span="6"
                        v-for="(key, index) in dataEndData.config"
                        :key="index"
                      >
                        <div
                          style="padding: 20px; box-sizing: border-box;height: 100px"
                        >
                          <div class="label">
                            {{ key.label }}
                          </div>
                          <div class="value">
                            {{
                              key.key === 'none'
                                ? ''
                                : (
                                    dataEndData.data[key.key] || '0'
                                  ).toLocaleString()
                            }}
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </div>
              </el-col>

              <el-col :span="12" v-dt-permission="'数据看板-数据看板-微站'">
                <div class="data-2-item" ref="wz">
                  <el-card header="微站" shadow="hover" style="height: 100%">
                    <el-row
                      :gutter="20"
                      v-loading="loadingDataOld || loadingDataSix"
                      :style="{ minHeight: cardHeight }"
                    >
                      <el-col
                        :span="6"
                        v-for="(key, index) in dataSixData.config"
                        :key="index"
                      >
                        <div
                          style="padding: 20px; box-sizing: border-box;height: 100px"
                        >
                          <div class="label">
                            {{ key.label }}
                          </div>
                          <div class="value">
                            {{
                              key.key === 'none'
                                ? ''
                                : (
                                    dataSixData.data[key.key] || '0'
                                  ).toLocaleString()
                            }}
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </div>
              </el-col>

              <el-col
                :span="12"
                v-dt-permission="'数据看板-数据看板-客户管理CRM'"
              >
                <div class="data-2-item" ref="khzh">
                  <el-card
                    header="客户转化"
                    shadow="hover"
                    style="height: 100%"
                  >
                    <el-row
                      :gutter="20"
                      v-loading="loadingDataOld || loadingDataSix"
                      :style="{ minHeight: cardHeight }"
                    >
                      <el-col
                        :span="6"
                        v-for="(key, index) in dataOldData.config"
                        :key="index"
                      >
                        <div
                          style="padding: 20px; box-sizing: border-box;height: 100px"
                        >
                          <div class="label">
                            {{ key.label }}
                          </div>
                          <div class="value">
                            {{
                              key.key === 'none'
                                ? ''
                                : (
                                    dataOldData.data[key.key] || '0'
                                  ).toLocaleString()
                            }}
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  dataOne,
  dataOptions,
  dataChartBar,
  dataChartTrend,
  dataPOptions,
  dataHk,
  dataWz,
  dataPy,
  dataZh,
  dataLd,
  tableDtData
} from '@/api/workTable.js'
import * as echarts from 'echarts'
import RunIn from '@/components/runIn/RunIn'
export default {
  name: 'predict',
  components: {
    RunIn
  },
  computed: {
    formatFilter() {
      if (this.selectBtn === 1) return '今天'
      if (this.selectBtn === -1) return '昨天'
      if (this.selectBtn === -7) return '最近7天'
      if (this.selectBtn === -30) return '最近30天'
      if (this.selectBtn === 'm') return '本月'
      if (this.selectBtn === 'jd') return '本季度'
      if (this.selectBtn === 0) return '全部'
    }
  },
  data() {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const startDate = `${year}-${month < 10 ? '0' + month : month}-${
      day < 10 ? '0' + day : day
    }`
    const endDate = `${year}-${month < 10 ? '0' + month : month}-${
      day < 10 ? '0' + day : day
    }`
    return {
      startDateLimit: {
        disabledDate: time => {
          const s = new Date('2020-08-31').getTime()
          const e = new Date('2020-09-30').getTime()
          return !(time >= s && time <= e)
        }
      },
      startDateLimit2: {
        disabledDate: time => {
          return false
        }
      },
      viewDemo: false,
      loadingDataOne: false,
      loadingDataTwo: false,
      loadingDataThree: false,
      loadingDataFour: false,
      loadingDataFive: false,
      loadingDataSix: false,
      loadingDataEnd: false,
      loadingDataOld: false,
      loadingDataLd: false,

      selectBtn: 1,
      colorList: [
        '#3a98ff',
        '#7ecbe7',
        '#37c4c4',
        '#79dab6',
        '#49c46a',
        '#a3da79',
        '#face37',
        '#e79d6b',
        '#f05c72'
      ],
      userIdList: {},
      userIdListRe: {},

      value: '',
      value1: '',
      selectUserList: [],
      cardHeight: '200px', // 统计面板的高度
      excludeHoliday: true, // 是否排除节假日

      selectTime: [startDate, endDate], // 筛选的时间

      myChartBar: null, // 分布图-柱状图
      myChartCircular: null, // 分布图-扇形图
      myChartTrend: null, // 趋势图
      myChartLd: null, //漏斗图

      startDate: startDate,
      endDate: endDate,

      options: [],
      userList: [],
      userListRe: [],

      dataOneData: {
        config: {},
        data: {}
      },

      dataTwoData: [],
      dataThreeData: [],

      dataFourData: {},
      showTrend: true,

      dataFiveData: {
        config: [],
        data: {}
      },
      dataSixData: {
        config: [],
        data: {}
      },
      dataEndData: {
        config: [],
        data: {}
      },
      dataOldData: {
        config: [],
        data: {}
      },

      // 漏斗
      dataLdData: {},

      tabType: 'yj', // xs:销售 qy：签约, hk：回款
      achievementType: 'table', // table:'图表', Histogram: '柱状图', pie: '饼图'

      tableData: [], // 销售图表数据
      histogramData: {}, // 销售柱状图数据（all）
      pieData: {}, // 销售饼图数据（all）

      newOptions: [],
      currentSelectType: '',
      columnList: [],
      sort: '',
      sortOrder: '',

      histogramChart: null,
      pieChart: null,
      isLn: true
    }
  },
  async mounted() {
    // 同步获取下拉框数据
    this.selectTimeClick(-30) // 默认请求近三十天的数据
    this.$nextTick(() => {
      // 监听回到顶部事件 //emit在topLeftContent.vue组件内部
      this.$bus.$on('toTop', () => {
        if (!this.$refs['scroll']) {
          return
        }
        this.$refs['scroll'].scrollIntoView
          ? this.$refs['scroll'].scrollIntoView({ behavior: 'smooth' })
          : (this.$refs['scrollbar'].$el.scrollTop = 0)
      })
      window.onresize = () => {
        this.myChartBar ? this.myChartBar.resize() : ''
        this.myChartCircular ? this.myChartCircular.resize() : ''
        this.myChartTrend ? this.myChartTrend.resize() : ''
        this.myChartLd ? this.myChartLd.resize() : ''
        this.histogramChart ? this.histogramChart.resize() : ''
        this.pieChart ? this.pieChart.resize() : ''
      }
    })
  },

  methods: {
    tabClick(tab) {
      this.achievementType = 'table'
      this.histogramChart ? this.histogramChart.dispose() : ''
      this.pieChart ? this.pieChart.dispose() : ''
      this.histogramChart = null
      this.pieChart = null
      this.sort = ''
      this.sortOrder = ''
      this.getYj(true)
    },

    getYj(setDefaultOption = false) {
      const hashMap = {
        xs: 1,
        qy: 2,
        hk: 3,
        yj: 4
      }
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: '14003',
        isDemo: this.viewDemo ? 1 : 0,
        type: hashMap[this.tabType],
        sort: this.sort,
        sortOrder: this.sortOrder
      }
      this.echarts ? this.echarts.showLoading() : ''
      tableDtData(params)
        .then(res => {
          this.echarts ? this.echarts.hideLoading() : ''
          if (res.code === 60000) {
            this.tableData = res.data.list
            this.columnList = res.data.columns
            this.newOptions = this.columnList.filter(
              i => i.field !== 'userName'
            )
            this.getHistogramData(res.data.list)
            this.getPieData(res.data.list)
            if (setDefaultOption)
              this.currentSelectType = this.newOptions[0].field
            // warning! warning! warning! all in this line code
            // 东特挨滴特，马斯特比凯儿否
            this.newSelectChange(this.currentSelectType)
          }
        })
        .catch(() => {
          this.echarts ? this.echarts.hideLoading() : ''
        })
    },

    getHistogramData(sourceData) {
      this.histogramData = {}
      let deepSourceData = JSON.parse(JSON.stringify(sourceData))
      this.newOptions.forEach(item => {
        deepSourceData = deepSourceData.sort((a, b) => {
          let _a = a[item.field] || 0
          let _b = b[item.field] || 0
          return _a - _b
        })
        this.histogramData[item.field] = {
          labelData: deepSourceData.map(i => i.userName),
          data: deepSourceData.map(i => i[item.field])
        }
        this.$forceUpdate()
      })
    },

    getPieData(sourceData) {
      this.pieData = {}
      this.newOptions.forEach(item => {
        this.pieData[item.field] = {
          labelData: sourceData.map(i => i.userName),
          data: sourceData.map(i => {
            return {
              userName: i.userName,
              count: i[item.field]
            }
          })
        }
        this.$forceUpdate()
      })
    },

    sortChange(data) {
      const map = {
        ascending: 'asc',
        descending: 'desc'
      }
      data.order ? (this.sortOrder = map[data.order]) : (this.sortOrder = null)
      this.sort = data.prop
      this.getYj(false)
    },

    changeAchievementType(type) {
      this.achievementType = type
      // 销毁图表
      this.histogramChart ? this.histogramChart.dispose() : ''
      this.pieChart ? this.pieChart.dispose() : ''
      this.histogramChart = null
      this.pieChart = null
      this.$nextTick(() => {
        // todo 绘制图表
        if (type === 'histogram') {
          const option = {
            grid: {
              left: '10px',
              top: '30px',
              right: '200px',
              bottom: '10px',
              containLabel: true
            },
            xAxis: { show: true },
            yAxis: {
              type: 'category',
              data: this.histogramData[this.currentSelectType].labelData,
              axisLabel: {
                textStyle: {
                  fontSize: '12'
                }
              }
            },

            series: [
              {
                type: 'bar',
                data: this.histogramData[this.currentSelectType].data,
                color: '#3a98ff',
                barMaxWidth: 30,
                markLine: {
                  data: [{ type: 'average', name: '平均值' }],
                  label: {
                    formatter: '平均值: {c} '
                  },
                  precision: 0
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      position: 'right',
                      textStyle: {
                        color: 'black',
                        fontSize: 16
                      }
                    }
                  }
                }
              }
            ],
            tooltip: {}
          }

          if (this.histogramChart) {
            this.histogramChart.dispose()
            this.histogramChart = null
          }
          this.histogramChart = echarts.init(
            document.getElementById('histogram')
          )
          this.histogramChart.setOption(option)
        }
        if (type === 'pie') {
          const option = {
            tooltip: false,
            legend: {
              left: 'center',
              bottom: 0,
              show: false,
              type: 'scroll',
              data: this.pieData[this.currentSelectType].labelData
            },
            color: this.colorList,
            grid: {
              top: '100px',
              bottom: '100px',
              containLabel: true
            },
            series: [
              {
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: true,
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '15',
                    fontWeight: 'bold'
                  }
                },
                label: {
                  normal: {
                    formatter: e => {
                      if (e.value) {
                        let newStr = ' '
                        let start, end
                        let name_len = `${e.name}${e.value}(${e.percent}%)`
                          .length
                        let max_name = 4
                        let new_row = Math.ceil(name_len / max_name)
                        if (name_len > max_name) {
                          for (let i = 0; i < new_row; i++) {
                            let old = ''
                            start = i * max_name
                            end = start + max_name
                            if (i === new_row - 1) {
                              old = e.name.substring(start)
                            } else {
                              old = e.name.substring(start, end) + '\n'
                            }
                            newStr += old
                          }
                        } else {
                          newStr = e.name
                        }
                        return `${newStr}${e.value}(${e.percent}%)`
                      }
                      return '0%'
                    },
                    textStyle: {
                      fontWeight: 'normal',
                      fontSize: 12,
                      align: 'center',
                      baseline: 'middle'
                    }
                  }
                },
                labelLine: {
                  show: this.formatCircular2()[0].name !== 'none'
                },
                data: this.formatCircular2()
              }
            ]
          }

          if (this.pieChart) {
            this.pieChart.dispose()
            this.pieChart = null
          }
          this.pieChart = echarts.init(document.getElementById('pie'))
          this.pieChart.setOption(option)
        }
      })
    },

    async changeViewDemoType() {
      this.excludeHoliday = true
      this.viewDemo = !this.viewDemo

      await this.getOptions()

      this.userList = this.userListRe
      this.userIdList = this.userIdListRe
      // if(this.viewDemo){

      // this.userIdList = {1:1};
      // this.userList = [
      //   {
      //     id: 1,
      //     isAdmin: 0,
      //     name: '张三'
      //   },
      //   {
      //     id: 2,
      //     isAdmin: 0,
      //     name: '李四'
      //   },
      //   {
      //     id: 3,
      //     isAdmin: 0,
      //     name: '王五'
      //   },
      //   {
      //     id: 4,
      //     isAdmin: 0,
      //     name: '小明'
      //   },
      //   {
      //     id: 5,
      //     isAdmin: 0,
      //     name: '小红'
      //   },
      //   {
      //     id: 6,
      //     isAdmin: 0,
      //     name: '小白'
      //   },
      // ]
      // }else {
      //
      // }
      this.selectTimeClick(0)
    },

    goTop() {
      // scroll
      const scroll = this.$refs
      console.log(scroll)
    },

    selectChange(e) {
      this.value = e
      this.dataTwo()
    },

    newSelectChange(e) {
      this.currentSelectType = e
      if (this.achievementType === 'histogram') {
        this.changeAchievementType(this.achievementType)
      }
      if (this.achievementType === 'pie') {
        this.changeAchievementType(this.achievementType)
      }
    },

    selectChange1(e) {
      this.value1 = e
      this.dataFour()
    },

    selectUserChange(e) {
      this.dataFour()
    },

    checkChange(e) {
      this.excludeHoliday = e
      this.dataFour()
    },

    change(user) {
      const uid = user.id
      if (this.userIdList[uid]) {
        delete this.userIdList[uid]
        this.dataFour()
        return
      }
      this.userIdList[user.id] = user.id
      this.dataFour()
    },

    // 获取下拉框数据
    getOptions() {
      return new Promise(resolve => {
        dataOptions({ isDemo: this.viewDemo ? 1 : 0 }).then(res => {
          if (res.code === 60000) {
            this.options = res.data
            this.value = this.options[2].value
            this.value1 = this.options[2].value
            dataPOptions({ pageId: 14003, isDemo: this.viewDemo ? 1 : 0 }).then(
              _res => {
                if (res.code === 60000) {
                  this.userIdList = {}
                  this.userIdListRe = {}
                  this.userList = _res.data
                  this.userListRe = _res.data
                  const accountId = this.$store.state.user.accountInfo.id
                  if (_res.data && _res.data.length) {
                    if (
                      _res.data.map(res => res.id).includes(accountId) &&
                      !this.viewDemo
                    ) {
                      this.userIdList[accountId] = accountId
                      this.userIdListRe = this.userIdList
                    } else {
                      this.userIdList[_res.data[0].id] = _res.data[0].id
                      this.userIdListRe = this.userIdList
                    }
                  }
                  resolve(res)
                }
              }
            )
          }
        })
      })
    },

    getTime(dayNum = 0) {
      if (this.viewDemo) {
        if (dayNum === 0) {
          this.startDate = '2020-09-30'
          this.endDate = '2020-09-30'
        }
        if (dayNum === 1) {
          this.startDate = '2020-09-29'
          this.endDate = '2020-09-29'
        }
        if (dayNum === 7) {
          this.startDate = '2020-09-23'
          this.endDate = '2020-09-30'
        }
        if (dayNum === 30) {
          this.startDate = '2020-09-01'
          this.endDate = '2020-09-30'
        }
        if (dayNum === 365 * 5) {
          this.startDate = '2020-09-01'
          this.endDate = '2020-09-30'
        } else {
          this.startDate = '2020-09-01'
          this.endDate = '2020-09-30'
        }
        this.selectTime = [this.startDate, this.endDate]
      } else {
        if (dayNum === 'm') {
          this.startDate = moment()
            .startOf('month')
            .format('YYYY-MM-DD')
          this.endDate = moment().format('YYYY-MM-DD')
          this.selectTime = [this.startDate, this.endDate]
        } else if (dayNum === 'jd') {
          let currentYear = moment().year()
          let currentQuarter = moment().quarter()
          this.startDate = moment(moment(currentYear + '-01-01').toDate())
            .quarter(currentQuarter)
            .format('YYYY-MM-DD')
          this.endDate = moment().format('YYYY-MM-DD')
          this.selectTime = [this.startDate, this.endDate]
        } else {
          const date = new Date().getTime() - 24 * 60 * 60 * 1000 * dayNum
          const yesterday = new Date(date)
          const year = yesterday.getFullYear()
          const month =
            yesterday.getMonth() + 1 >= 10
              ? yesterday.getMonth() + 1
              : '0' + (yesterday.getMonth() + 1)
          const day =
            yesterday.getDate() >= 10
              ? yesterday.getDate()
              : '0' + yesterday.getDate()
          this.startDate = `${year}-${month}-${day}`
          if (dayNum === 365 * 5) {
            this.startDate = ''
          }
          if (dayNum === 1) {
            this.endDate = this.startDate
          } else {
            this.endDate = moment().format('YYYY-MM-DD')
          }
          this.selectTime = [this.startDate, this.endDate]
        }
      }
    },

    selectTimeClick(val) {
      // if(this.selectBtn === val){
      //   return
      // }
      this.selectBtn = val
      if (val === 1) {
        this.getTime(0)
      }
      if (val === -1) {
        this.getTime(1)
      }
      if (val === -7) {
        this.getTime(7)
      }
      if (val === -30) {
        this.getTime(30)
      }
      if (val === 0) {
        this.getTime(365 * 5)
      }
      if (val === 'm') {
        this.getTime('m')
      }
      if (val === 'jd') {
        this.getTime('jd')
      }
      // 请求数据
      this.getDtData()
    },

    selectTimeClickAuto(e) {
      this.startDate = e[0]
      this.endDate = e[1]
      this.selectBtn = 'none'
      this.getDtData()
    },

    //获取所有统计数据
    getDtData() {
      // this.dataOne()
      // this.dataTwo()
      // this.dataThree()
      // this.dataFour()
      // this.requestFe()
      // this.requestSo()
      // this.dataLd()
      this.getYj(true)
    },

    dataLd() {
      // dataLdData
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 14003,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataLd = true
      dataLd(params).then(res => {
        this.loadingDataLd = false
        // if(this.viewDemo){
        //   res = getSpData('ldData', this.selectBtn)
        // }
        if (res.code === 60000 && res.data) {
          this.dataLdOptions(res.data)
        } else {
          this.dataLdOptions({})
        }
      })
      // this.dataLdOptions()
    },

    dataLdOptions(data) {
      let res = []
      let dict = {
        viewCnt: {
          name: '查看线索',
          _value: '',
          value: 120,
          color: 'rgba(58, 152, 255, 1)'
        },
        consumptionCnt: {
          name: '领取线索',
          _value: '',
          value: 100,
          color: 'rgba(58, 152, 255, .8)'
        },
        markPhoneCnt: {
          name: '标记线索',
          _value: 0,
          value: 80,
          color: 'rgba(58, 152, 255,  .6)'
        },
        tagIntentionCnt: {
          name: '意向客户',
          _value: 0,
          value: 60,
          color: 'rgba(58, 152, 255, .4)'
        },
        tagOkCnt: {
          name: '成交客户',
          _value: 0,
          value: 40,
          color: 'rgba(58, 152, 255, .2)'
        },
        contractAmountSum: {
          name: '成交金额(元)',
          _value: 0,
          value: 20,
          color: 'rgba(58, 152, 255, .1)'
        }
      }
      for (let i in data) {
        let _res = dict[i]
        _res['_value'] = data[i] || 0
        res.push(_res)
      }
      const option = {
        calculable: true,
        series: [
          {
            clickable: false,
            hoverAnimation: true,
            legendHoverLink: true,
            type: 'funnel',
            x: 'center',
            y: 'center',
            width: '50%',
            height: '70%',
            minSize: '20%',
            maxSize: '100%',
            label: {
              show: true,
              position: 'left',
              formatter: function(val) {
                return `${val.name} ${val.data._value}`
              },
              color: '#3a98ff'
            },
            itemStyle: {
              color: function(res) {
                return res.data.color
              }
            },
            gap: 0,
            data: res
          }
        ]
      }
      if (this.myChartLd) {
        this.myChartLd.dispose()
        this.myChartLd = null
      }
      this.myChartLd = echarts.init(document.getElementById('data-1-new-left'))
      this.myChartLd.setOption(option)
    },

    dataOne() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 14003,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataOne = true
      dataOne(params).then(res => {
        this.loadingDataOne = false
        if (res.code === 60000) {
          this.dataOneData.config = res.data.config
            .map(_res => {
              let con = Object.values(_res)
              return {
                label: Object.keys(_res)[0],
                key: con[0].col,
                show: con[0].show
              }
            })
            .filter(item => item.show)
          this.dataOneData.data = res.data.data
        }
      })
    },

    dataTwo() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        item: this.value,
        pageId: 14003,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataTwo = true
      dataChartBar(params).then(res => {
        this.loadingDataTwo = false

        // 演示数据
        // if (this.viewDemo){
        //   res = getSpData('fbData', this.selectBtn, [], false, this.value)
        // }

        if (res.code === 60000 && res.data) {
          if (this.value === 3 || this.value === 11) {
            this.drawDd(res.data)
            return
          }
          this.dataTwoData = res.data
          this.$nextTick(() => {
            this.dataTwoOptions()
            this.dataThreeOptions()
          })
        }
      })
    },

    // 号码标记 跟进记录作图
    drawDd(chartData) {
      const bingRes = []
      const userCountObj = {}
      // 设置饼图
      for (let i in chartData) {
        let userName = i
        let count = 0
        chartData[i] &&
          chartData[i].forEach(item => {
            count += isNaN(parseInt(item.cnt)) ? 0 : parseInt(item.cnt)
          })
        bingRes.push({
          userName: userName,
          count: count
        })
        userCountObj[userName] = count
      }
      this.dataTwoData = bingRes
      this.$nextTick(() => {
        this.dataThreeOptions()
      })
      let isAllNull = true
      let ddOptions = []
      let yName = []
      let ddData = []
      // 解析堆叠项
      for (let i in chartData) {
        if (
          chartData[i] &&
          chartData[i] instanceof Array &&
          chartData[i].length
        ) {
          isAllNull = false
          ddOptions = chartData[i].map(item => item.name)
          // yName.push(i)
          yName.push({ name: i, count: userCountObj[i] })
          ddData = [...ddData, ...chartData[i]]
        }
      }
      yName = yName
        .sort((a, b) => {
          return a.count - b.count
        })
        .map(item => item.name)
      if (isAllNull) return
      let resData = {}
      ddOptions.forEach(item => {
        resData[item] = ddData.filter(_item => _item.name === item)
      })
      // 获取数据
      let resSeries = [] // series数据
      for (let i in resData) {
        // 对resData[i]进行排序 排序对应y轴
        const _resDataI = []
        yName.forEach(item => {
          resData[i].forEach(_item => {
            if (_item.userName === item) {
              _resDataI.push(_item)
            }
          })
        })
        resSeries.push({
          name: i,
          type: 'bar',
          stack: 'all',
          barMaxWidth: 30,
          label: {
            show: true,
            position: 'insideRight',
            formatter: function(res) {
              return res.data || ''
            }
          },
          data: _resDataI.map(item => item.cnt || 0)
        })
      }

      // 绘制堆叠图
      const option = {
        color: [
          'rgba(58, 152, 255, 1)',
          'rgba(58, 152, 255, .9)',
          'rgba(58, 152, 255, .8)',
          'rgba(58, 152, 255, .7)',
          'rgba(58, 152, 255, .6)',
          'rgba(58, 152, 255, .5)',
          'rgba(58, 152, 255, .4)'
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ddOptions,
          bottom: '10px'
        },
        grid: {
          top: '100px',
          left: '10px',
          right: '4%',
          bottom: '40px',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: yName //设置排序
        },
        series: resSeries
      }
      if (this.myChartBar) {
        this.myChartBar.dispose()
        this.myChartBar = null
      }
      this.myChartBar = echarts.init(document.getElementById('data-3-left'))
      this.myChartBar.setOption(option)
    },

    formatDataTwoSort() {
      let res = this.dataTwoData.sort((a, b) => {
        let _a = a.count || 0
        let _b = b.count || 0
        return _a - _b
      })
      return {
        name: res.map(item => item.userName),
        data: res.map(item => item.count)
      }
    },

    dataTwoOptions() {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        color: ['#41a2ff'],
        grid: {
          top: '100px',
          left: '10px',
          right: '4%',
          bottom: '20px',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          // data: this.dataTwoData.map(res=>res.userName)
          data: this.formatDataTwoSort().name
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            // data: this.dataTwoData.map(res=>res.count||0),
            data: this.formatDataTwoSort().data,
            markLine: {
              data: [{ type: 'average', name: '平均值' }],
              label: {
                formatter: '平均值: {c} '
              },
              precision: 0
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'right'
                }
              }
            }
          }
        ]
      }
      if (this.myChartBar) {
        this.myChartBar.dispose()
        this.myChartBar = null
      }
      this.myChartBar = echarts.init(document.getElementById('data-3-left'))
      this.myChartBar.setOption(option)
    },

    dataThreeOptions() {
      const option = {
        tooltip: false,
        legend: {
          left: 'center',
          bottom: 0,
          show: false,
          type: 'scroll',
          data: this.dataTwoData.map(item => item.userName)
        },
        color: this.colorList,
        grid: {
          containLabel: true
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
            label: {
              normal: {
                formatter: e => {
                  if (e.value) {
                    let newStr = ' '
                    let start, end
                    let name_len = `${e.name}${e.value}(${e.percent}%)`.length
                    let max_name = 4
                    let new_row = Math.ceil(name_len / max_name)
                    if (name_len > max_name) {
                      for (let i = 0; i < new_row; i++) {
                        let old = ''
                        start = i * max_name
                        end = start + max_name
                        if (i === new_row - 1) {
                          old = e.name.substring(start)
                        } else {
                          old = e.name.substring(start, end) + '\n'
                        }
                        newStr += old
                      }
                    } else {
                      newStr = e.name
                    }
                    return `${newStr}${e.value}(${e.percent}%)`
                  }
                  return ''
                },
                textStyle: {
                  fontWeight: 'normal',
                  fontSize: 12,
                  align: 'center',
                  baseline: 'middle'
                }
              }
            },
            labelLine: {
              show: this.formatCircular()[0].name !== 'none'
            },
            data: this.formatCircular()
          }
        ]
      }

      if (this.myChartCircular) {
        this.myChartCircular.dispose()
        this.myChartCircular = null
      }
      this.myChartCircular = echarts.init(
        document.getElementById('data-3-right')
      )
      this.myChartCircular.setOption(option)
    },

    formatCircular() {
      let res = []
      res = this.dataTwoData.map(item => {
        return {
          value: item.count || 0,
          name: item.userName
        }
      })
      res.sort((a, b) => {
        return b.value - a.value
      })
      let _res = res.filter(item => item.value)
      return _res.length ? _res : [{ name: 'none', value: 0 }]
    },

    formatCircular2() {
      let res = []
      let data = this.pieData[this.currentSelectType].data
      res = data.map(item => {
        return {
          value: item.count || 0,
          name: item.userName
        }
      })
      res.sort((a, b) => {
        return b.value - a.value
      })
      let _res = res.filter(item => item.value)
      return _res.length ? _res : [{ name: 'none', value: 0 }]
    },

    dataThree() {},

    dataFour() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        item: this.value1,
        userIds: Object.keys(this.userIdList).join(','),
        noWeek: this.excludeHoliday,
        pageId: 14003,
        isDemo: this.viewDemo ? 1 : 0
      }
      // myChartTrend
      this.loadingDataFour = true
      dataChartTrend(params).then(res => {
        this.loadingDataFour = false

        // if (this.viewDemo){
        //   console.clear()
        //   console.log(Object.keys(this.userIdList))
        //   console.log(this.excludeHoliday)
        //   res = getSpData('qsData', this.selectBtn, Object.keys(this.userIdList), this.excludeHoliday)
        // }

        if (res.code === 60000 && res.data) {
          this.dataFourData = res.data
          this.showTrend = true
          this.$nextTick(() => {
            this.dataFourOption()
          })
        } else {
          if (this.myChartTrend) {
            this.dataFourData = {}
            this.$nextTick(() => {
              this.dataFourOption()
            })
          }
        }
      })
    },

    formatData(timeList) {
      let res = []
      let _res = {
        type: 'line'
      }
      const keys = Object.keys(this.dataFourData)
      if (!keys.length) {
        return res
      }
      keys.forEach(item => {
        let itemRes = []
        this.dataFourData[item].forEach(_item => {
          itemRes.push(_item.count)
        })
        res.push({
          type: 'line',
          data: itemRes,
          name: item,
          smooth: true
        })
      })
      return res
    },

    dataFourOption() {
      let timeList = []
      // 使用后端返回的时间
      const keys = Object.keys(this.dataFourData)
      if (!keys.length) return
      timeList = this.dataFourData[keys[0]].map(res => res.summaryDate)

      const option = {
        tooltip: {
          trigger: 'axis'
        },
        color: this.colorList,
        legend: {
          data: Object.keys(this.dataFourData),
          left: 'center',
          bottom: 10
        },
        grid: {
          left: '50px',
          top: '100px',
          right: '50px',
          bottom: timeList.length > 31 ? '80px' : '50px',
          containLabel: true
        },

        dataZoom:
          timeList.length > 31
            ? [
                {
                  type: 'slider',
                  start: timeList.length < 31 ? 100 : 50,
                  end: 100,
                  handleIcon:
                    'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                  handleSize: '80%',
                  handleStyle: {
                    color: '#fff',
                    shadowBlur: 3,
                    shadowColor: 'rgba(0, 0, 0, 0.6)',
                    shadowOffsetX: 2,
                    shadowOffsetY: 2
                  },
                  bottom: 50,
                  zoomOnMouseWheel: false
                }
              ]
            : [],

        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: timeList,
          axisLabel: {
            interval: 'auto',
            rotate: 45
          }
        },
        yAxis: {
          type: 'value'
        },
        series: this.formatData(timeList)
      }

      if (this.myChartTrend) {
        this.myChartTrend.dispose()
        this.myChartTrend = null
      }
      this.myChartTrend = echarts.init(document.getElementById('data-4-item'))
      this.myChartTrend.setOption(option)
    },

    formatTime(timeList) {
      if (this.excludeHoliday) {
        return timeList.filter(item => {
          const isWeek = moment(item).weekday()
          return isWeek !== 6 && isWeek !== 0
        })
      }
      return timeList
    },

    async requestFe() {
      await this.dataFive()
      await this.dataEnd()

      const n = this.dataFiveData.config.length - this.dataEndData.config.length
      if (n > 0) {
        for (let i = 0; i < n; i++) {
          this.dataEndData.config.push({
            label: '',
            key: 'none',
            show: true
          })
        }
      }
      if (n < 0) {
        for (let i = 0; i < Math.abs(n); i++) {
          this.dataFiveData.config.push({
            label: '',
            key: 'none',
            show: true
          })
        }
      }
    },

    async requestSo() {
      await this.dataSix()
      await this.dataOld()
      const n = this.dataSixData.config.length - this.dataOldData.config.length
      if (n > 0) {
        for (let i = 0; i < n; i++) {
          this.dataOldData.config.push({
            label: '',
            key: 'none',
            show: true
          })
        }
      }
      if (n < 0) {
        for (let i = 0; i < Math.abs(n); i++) {
          console.log(i)
          this.dataSixData.config.push({
            label: '',
            key: 'none',
            show: true
          })
        }
      }
    },

    dataFive() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 14003,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataFive = true

      return new Promise(resolve => {
        dataHk(params).then(res => {
          this.loadingDataFive = false
          // if(this.viewDemo){
          //   res = getSpData('hkData', this.selectBtn)
          // }
          if (res.code === 60000) {
            this.dataFiveData.config = res.data.config
              .map(_res => {
                let con = Object.values(_res)
                return {
                  label: Object.keys(_res)[0],
                  key: con[0].col,
                  show: con[0].show
                }
              })
              .filter(item => item.show)
            this.dataFiveData.data = res.data.data
            resolve(true)
          }
        })
      })
    },
    dataEnd() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 14003,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataEnd = true
      return new Promise(resolve => {
        dataPy(params).then(res => {
          this.loadingDataEnd = false
          // if (this.viewDemo){
          //   res = getSpData('pyData', this.selectBtn)
          // }
          if (res.code === 60000) {
            this.dataEndData.config = res.data.config
              .map(_res => {
                let con = Object.values(_res)
                return {
                  label: Object.keys(_res)[0],
                  key: con[0].col,
                  show: con[0].show
                }
              })
              .filter(item => item.show)
            this.dataEndData.data = res.data.data
            resolve(true)
          }
        })
      })
    },

    dataSix() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 14003,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataSix = true
      return new Promise(resolve => {
        dataWz(params).then(res => {
          this.loadingDataSix = false
          // if(this.viewDemo){
          //   res = getSpData('wzData', this.selectBtn)
          // }
          if (res.code === 60000) {
            this.dataSixData.config = res.data.config
              .map(_res => {
                let con = Object.values(_res)
                return {
                  label: Object.keys(_res)[0],
                  key: con[0].col,
                  show: con[0].show
                }
              })
              .filter(item => item.show)
            this.dataSixData.data = res.data.data
            resolve(true)
          }
        })
      })
    },
    dataOld() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        pageId: 14003,
        isDemo: this.viewDemo ? 1 : 0
      }
      this.loadingDataOld = true
      return new Promise(resolve => {
        dataZh(params).then(res => {
          this.loadingDataOld = false

          // if(this.viewDemo){
          //   res = getSpData('zhData', this.selectBtn)
          // }

          if (res.code === 60000) {
            this.dataOldData.config = res.data.config
              .map(_res => {
                let con = Object.values(_res)
                return {
                  label: Object.keys(_res)[0],
                  key: con[0].col,
                  show: con[0].show
                }
              })
              .filter(item => item.show)
            this.dataOldData.data = res.data.data
            resolve(true)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.dt-data {
  height: 100%;
}
.dt-data-header {
  height: 70px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  /*box-shadow: rgba(0,0,0,.1) 0 0 1px 1px;*/
  /*position: relative;*/
}
.dt-data-row {
  height: calc(100% - 70px);
  width: 100%;
  /*box-shadow: rgba(0,0,0,.1) 0 0 1px 1px;*/
}
.dt-select-time {
  padding-left: 10px;
  box-sizing: border-box;
}

.data-1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  margin-bottom: 10px;
  min-height: 150px;
  height: auto;
}

.data-1-new {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}
.data-1-new-left,
.data-1-new-right {
  height: 450px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  /*position: relative;*/

  display: flex;
  align-items: center;
  justify-content: center;
}

.data-title {
  position: absolute;
  left: 20px;
  top: 20px;
}

/*.data-1-new-left::before{*/
/*  content: '转化漏斗';*/
/*  position: absolute;*/
/*  display: block;*/
/*  left: 20px;*/
/*  top: 20px;*/
/*}*/

.data-1-new-right-tip {
  color: #8a8a8a;
}

.data-1-item {
  width: 100px;
  height: 150px;
  /*background-color: red;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.label {
  /*margin-bottom: 10px;*/
  font-size: 14px;
  color: #909399;
  text-align: center;
}
.value {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
  /*cursor: pointer;*/
  text-align: center;
}
.value:hover {
  /*transform: scale(1.5);*/
}
.data-2-item {
  height: auto;
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
}
.data-2-item-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
  min-height: 240px;
}
.data-2-item-item > .data-2-item-item-item:last-child {
  margin-right: auto;
}
.data-2-item-item-item {
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.data-3 {
  height: 450px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #fff;
  position: relative;
}

.select-data-3 {
  position: absolute;
  min-height: 50px;
  height: auto;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  left: 0;
  top: 0;
  z-index: 9;
}
.data-3-item {
  height: calc(100% - 0px);
  width: 100%;
  display: flex;
}
.data-3-item > div {
  width: 50%;
  height: 100%;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #909399;
}
.data-4 {
  height: 750px;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  background-color: #fff;
}
.data-4-item {
  height: 100%;
  width: 100%;
}
.j-input {
  display: inline-block;
  width: 174px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #dcdfe6;
  cursor: pointer;
  font-size: 12px;
  color: #606266;
  transform: translateY(1px);
  padding-left: 15px;
  box-sizing: border-box;
}
.check-black ::v-deep .el-checkbox__label {
  color: #606266 !important;
}
.button-show {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-duration: 800ms;
  animation-name: show;
}

@keyframes show {
  0% {
    background-color: #fff;
    color: #ffffff;
  }
  100% {
    background-color: #41cabf;
    color: #ffffff;
  }
}

.achievement {
  min-height: 500px;
  height: auto;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.table-chart {
  margin-top: 10px;
}
.table {
  min-height: 300px;
  height: auto;
}
.histogram,
.pie {
  height: 500px;
  width: 100%;
}
.tab /deep/ .el-tabs__nav-wrap::after {
  display: none !important;
  content: '';
}

.default-table {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url('../../assets/call_table.svg') no-repeat center center;
}
.active-table {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: url('../../assets/call_table_active.svg') no-repeat center center;
  cursor: pointer;
}

.default-histogram {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url('../../assets/call_shell.svg') no-repeat center center;
}

.active-histogram {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url('../../assets/call_shell_active.svg') no-repeat center center;
}

.default-pie {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url('../../assets/call_cake.svg') no-repeat center center;
}

.active-pie {
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url('../../assets/call_cake_active.svg') no-repeat center center;
}
</style>
