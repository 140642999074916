<template>
  <div class="header">
    <img :src="headerBg" class="bg" />
    <div class="h1">{{ headerTitle }}</div>
    <div class="r">
      <div
        class="fullpage_btn"
        :class="{ active: isFullscreen }"
        @click.stop="screenfull"
      ></div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
export default {
  name: 'ChartHeader',
  props: {
    headerBg: {
      type: String,
      default: ''
    },

    headerTitle: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isFullscreen: false
    }
  },
  methods: {
    init() {
      if (screenfull.isEnabled) {
        // this.iconclass = this.fullscreen;
        screenfull.on('change', this.change)
      }
    },

    change() {
      if (screenfull.isFullscreen) {
        // this.iconclass = this.exitFullscreen;
        this.isFullscreen = true
      } else {
        // this.iconclass = this.fullscreen;
        this.isFullscreen = false
      }
    },
    screenfull() {
      if (!screenfull.isEnabled) {
        alert('当前浏览器不支持全屏API')
        return false
      }
      screenfull.toggle()
    },
    handelKeydown(e) {
      if (e.keyCode === 122) {
        e.preventDefault()
        screenfull.toggle()
      }
    }
  },
  mounted() {
    this.init()
    window.addEventListener('keydown', this.handelKeydown, true)
  },
  beforeDestroy() {
    // 清理监听器
    document.removeEventListener('keydown', () => {})
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 100px;
  position: relative;
  z-index: 10;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    pointer-events: none;
  }

  .h1 {
    text-align: center;
    font-size: 36px;
    background-image: linear-gradient(to bottom, #fff, #bad5ee);
    -webkit-background-clip: text;
    color: transparent;
    position: relative;
    z-index: 2;
    line-height: 62px;
    font-weight: bold;
    letter-spacing: 3px;
    // text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-family: CKTKingKong;

    img {
      display: inline-block;
      vertical-align: middle;
      height: 44px;
    }
  }

  .fullpage_btn {
    cursor: pointer;
    width: 32px;
    height: 32px;
    position: absolute;
    bottom: 25px;
    right: 25px;
    background: url(../theme/images/full.png) no-repeat center;
    background-size: contain;
    z-index: 12;
    &.active {
      background-image: url(../theme/images/sx.png);
    }
  }
}
</style>
