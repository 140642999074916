<template>
  <div class="visual-work-page">
    <div class="crumb-wrap">
      <div>可视化作品</div>
      <el-button type="primary" size="mini" @click="handleAdd"
        >新建作品</el-button
      >
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in visualList" :key="index">
        <div class="top">
          <div>{{ item.workName }}</div>
          <div class="size">
            {{ `${item.templateWidth} * ${item.templateHeight}` }}
          </div>
        </div>

        <img
          v-if="item.abbreviationsImgUrl"
          :src="item.abbreviationsImgUrl"
          class="img-default"
        />
        <div v-else class="img-empty"></div>
        <div class="btn-footer">
          <el-button size="mini" @click="handleCheck(item)">查看</el-button>
          <el-button size="mini" @click="handleEdit(item)">编辑</el-button>
          <el-button size="mini" @click="handleSet(item)">属性</el-button>
          <el-popconfirm title="确定删除吗？" @confirm="handleDelete(item)">
            <el-button slot="reference" size="mini" style="margin-left:10px"
              >删除</el-button
            >
          </el-popconfirm>
        </div>
      </div>
    </div>

    <PeDialog
      :pe-dialog-show="show"
      pe-dialog-width="600px"
      pe-dialog-class="screen-dialog"
      :pe-dialog-title="dialogTitle"
      @handlePeDialogClose="show = false"
      @handlePeDialogSure="handleSure"
    >
      <div class="dialog-wrap">
        <div class="item">
          <span>大屏标题</span>
          <el-input v-model="screenForm.name" />
        </div>
        <div class="item">
          <span>首屏简称</span>
          <el-input v-model="screenForm.shortName" />
        </div>
        <div class="item">
          <span>模板选择</span>
          <PeSelect
            :optionsList="templateList"
            :defaultSelect="defaultSelect"
            :selectDisable="disabled"
            @handleSelectChange="handleChange"
          />
        </div>
        <div class="item">
          <span>模板宽度</span>
          <el-input
            :disabled="!disabled"
            placeholder="输入宽度"
            v-model="screenForm.width"
          />
        </div>
        <div class="item">
          <span>模板高度</span>
          <el-input
            placeholder="输入高度"
            :disabled="!disabled"
            v-model="screenForm.height"
          />
        </div>
        <div class="item">
          <span>浏览权限</span>
          <el-radio-group
            v-model="screenForm.isAuth"
            style="margin-left: 10px;"
          >
            <el-radio
              v-for="item in browseList"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="item">
          <span>缩略简图</span>
          <PeUpload
            style="margin-left: 10px;"
            :fileList="fileList"
            @handleUploadSucc="handleUploadSucc"
          />
        </div>
      </div>
    </PeDialog>
  </div>
</template>

<script>
import {
  getVisualWorks,
  getTempAddScreen,
  getVisualList,
  getTempCreateScreen,
  getWorksDelete
} from '@/api/module'
import PeDialog from '@/components/Peculiar/dialog'
import PeSelect from '@/components/Peculiar/select'
import PeUpload from '@/components/Peculiar/upload'
export default {
  name: 'VisualWorks',

  data() {
    return {
      defaultSelect: '',
      visualList: [],
      show: false,
      browseList: [
        {
          label: '登录',
          value: 0
        },
        {
          label: '开放浏览',
          value: 1
        },
        {
          label: '临时密码',
          value: 2
        }
      ],
      screenForm: {
        isAuth: 0,
        name: '',
        shortName: '',
        templateId: '',
        width: '',
        height: '',
        imgUrl: ''
      },
      templateList: [],
      operateType: 'add',
      fileList: []
    }
  },

  components: {
    PeDialog,
    PeSelect,
    PeUpload
  },

  computed: {
    disabled() {
      return this.operateType == 'edit'
    },
    dialogTitle() {
      return this.operateType == 'edit' ? '作品编辑' : '新建作品'
    }
  },

  mounted() {
    this.getList()
    this.getTemplate()
  },

  methods: {
    async handleDelete(data) {
      try {
        await getWorksDelete({ pid: data.pid })
        this.getList()
      } catch (err) {
        console.log(err)
      }
    },

    handleUploadSucc(options) {
      this.screenForm.imgUrl = options.url
      console.log(options, 'options')
    },

    handleAdd() {
      this.show = true
      this.operateType = 'add'
      this.screenForm = {
        isAuth: 0,
        name: '',
        shortName: '',
        templateId: '',
        width: '',
        height: '',
        imgUrl: ''
      }
      this.defaultSelect = ''
      this.fileList = []
    },

    async getTemplate() {
      const { data } = await getVisualList()
      this.templateList = data.map(item => ({
        label: item.name,
        value: item
      }))
    },

    handleChange(e) {
      const { id, width, height } = e.value
      this.screenForm.templateId = id
      this.screenForm.width = width
      this.screenForm.height = height
      this.defaultSelect = e.label
      console.log(e)
    },

    async handleSure() {
      const isAdd = this.operateType == 'add'
      let result = null
      try {
        if (isAdd) {
          result = await getTempAddScreen(this.screenForm)
        } else {
          result = await getTempCreateScreen(this.screenForm)
        }
        console.log(result, 'result')
        if (result.code == 60000) {
          this.$message.success('设置成功')
          this.show = false
          this.getList()
        }
      } catch (err) {
        console.log(err)
      }
    },

    handleCheck(data) {
      const id = data.pid
      if (data.isLayout == 1) {
        window.open(`/#/bi/view?id=${id}`)
      } else if (data.isLayout == 2) {
        window.open(`/#/bi/theme?id=${id}`)
      } else {
        window.open(`/#/bi/screen/view?screenId=${id}`)
      }
    },

    handleEdit(data) {
      const id = data.pid
      if (data.isLayout == 1) {
        window.open(`/#/bi/edit?id=${id}`)
      } else if (data.isLayout == 2) {
        window.open(`/#/bi/theme?id=${id}`)
      } else {
        window.open(`/#/bi/screen/edit?screenId=${id}`)
      }
    },

    handleSet(data) {
      console.log(data, 'curre')
      const {
        workName,
        shortName,
        pid,
        isAuth,
        templateWidth,
        templateHeight,
        abbreviationsImgUrl,
        imgUrl
      } = data
      if (abbreviationsImgUrl) {
        this.fileList = [{ url: imgUrl, name: 'img' }]
      }

      this.show = true
      this.operateType = 'edit'
      this.screenForm = {
        name: workName,
        shortName,
        isAuth,
        pid,
        width: templateWidth,
        height: templateHeight,
        imgUrl
      }
    },

    async getList() {
      const { data } = await getVisualWorks()
      console.log(data, 4433)
      data.forEach((item, index) => {
        item.index = index
      })
      this.visualList = data
    }
  }
}
</script>
<style lang="scss">
.visual-work-page {
  .dialog-wrap {
    .item {
      display: flex;
      align-items: center;
      margin: 15px 0;
      .el-input {
        width: 80%;
        margin: 0 10px;
      }
    }
  }
  .upload-demo {
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
.visual-work-page {
  .crumb-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 5px 10px;
  }
  .list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 0;
    .item {
      margin: 0 0.5% 10px;
      width: 24%;
      background-color: #fff;
      height: 280px;
      padding: 10px;
      border: 2px solid transparent;
      position: relative;
      overflow: hidden;
      .img-default {
        width: 100%;
        height: 70%;
      }
      .img-empty {
        width: 100%;
        height: 70%;
        background-color: #030327;
      }
      .top {
        display: flex;
        flex-direction: column;
        .size {
          margin: 5px 0;
        }
      }

      .btn-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5px;
      }
      &:hover {
        cursor: pointer;
        border: 2px solid #41cabf;
        background: rgba(0, 0, 0, 0.01);
      }
    }
  }
}
</style>
