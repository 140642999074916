var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "pond-detail-drawr",
    },
    [
      _c("div", { staticClass: "header-drawer" }, [
        _c("div", { staticClass: "left-header" }, [
          _vm.path !== "keydetail"
            ? _c("p", { staticStyle: { "font-size": "14px" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.rowCont.companyName) + "\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.path === "keydetail"
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    height: "40px",
                    "line-height": "40px",
                    padding: "0 20px",
                  },
                },
                [_vm._v("\n        跟进记录\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _vm.rowCont.from
                ? _c("el-tag", { attrs: { size: "mini" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.rowCont.from) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.rowCont.grade
                ? _c("el-tag", { attrs: { size: "mini" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.rowCont.grade) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.rowCont.state
                ? _c("el-tag", { attrs: { size: "mini" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.rowCont.state) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.rowCont.customerGrade
                ? _c("el-tag", { attrs: { size: "mini" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.rowCont.customerGrade) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right-header" }, [
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.rowCont.createUserName,
                  expression: "rowCont.createUserName",
                },
              ],
            },
            [
              _vm._v(
                "\n        创建人：" +
                  _vm._s(_vm.rowCont.createUserName) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.rowCont.createdAt,
                  expression: "rowCont.createdAt",
                },
              ],
            },
            [_vm._v("创建时间：" + _vm._s(_vm.rowCont.createdAt))]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            _vm._l(_vm.tags, function (tag) {
              return _c(
                "el-tag",
                {
                  staticStyle: { margin: "3px 5px 0 0" },
                  attrs: { size: "mini" },
                },
                [_vm._v("\n        " + _vm._s(tag) + "\n      ")]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            _vm._l(_vm.privateTags, function (tag) {
              return _c(
                "el-tag",
                {
                  staticStyle: { margin: "3px 5px 0 0", color: "#FFFFFF" },
                  attrs: { size: "mini", color: "orange" },
                },
                [_vm._v("\n        " + _vm._s(tag) + "\n      ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "searchTop" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.activeChange },
              model: {
                value: _vm.activeTabs,
                callback: function ($$v) {
                  _vm.activeTabs = $$v
                },
                expression: "activeTabs",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { name: "all", label: "全部" } },
                [
                  _vm.pondCond.length && _vm.hasViewPermission
                    ? _c(
                        "el-scrollbar",
                        {
                          staticClass: "xxx-scroll",
                          style: {
                            height:
                              _vm.activeTabs != "third" &&
                              _vm.activeTabs != "second" &&
                              _vm.hasEditPermission
                                ? "calc( 100vh - 50px - 50px - 350px - 50px)"
                                : "calc( 100vh - 50px - 200px )",
                            with: "100%",
                            overflowX: "hidden",
                          },
                        },
                        _vm._l(_vm.pondCond, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "pond-cond-all" },
                            [
                              item.week
                                ? [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "70px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.week))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.day))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      item.follows,
                                      function (follow, ind) {
                                        return _c(
                                          "div",
                                          {
                                            key: ind + "c",
                                            staticStyle: { overflow: "hidden" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "pond-cond-left",
                                                class:
                                                  ind !== 0
                                                    ? "pond-no-first"
                                                    : "",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(_vm._s(follow.time)),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "pond-cond-right",
                                                class:
                                                  ind !== 0
                                                    ? "pond-no-first"
                                                    : "",
                                              },
                                              [
                                                follow.type == 1
                                                  ? _c("p", [
                                                      follow.follower
                                                        ? _c(
                                                            "span",
                                                            {
                                                              class:
                                                                item.week ===
                                                                "今天"
                                                                  ? "pond-cond-posi"
                                                                  : "pond-cond-had",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  follow.follower
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v("    .    "),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            follow.opSummary
                                                          )
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    style: {
                                                      "margin-top":
                                                        follow.type == 1
                                                          ? "20px"
                                                          : "0",
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      class:
                                                        follow.type != 1
                                                          ? item.week === "今天"
                                                            ? "pond-cond-posi"
                                                            : "pond-cond-had"
                                                          : "",
                                                      staticStyle: {
                                                        color: "#999999",
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          follow.opDetails
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "previewClose",
                                                  },
                                                  [
                                                    follow.imagesUrl != "" &&
                                                    follow.imagesUrl != null
                                                      ? _c("img", {
                                                          staticClass:
                                                            "previewImg",
                                                          attrs: {
                                                            src: follow.imagesUrl,
                                                            alt: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.previewImg(
                                                                follow.imagesUrl
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.showViewer
                                                      ? _c("el-image-viewer", {
                                                          attrs: {
                                                            "on-close":
                                                              _vm.closeViewer,
                                                            "url-list": [
                                                              _vm.srcList,
                                                            ],
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                follow.type === -1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "phone-record",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                follow.userWaihuRecordData
                                                                  ? follow
                                                                      .userWaihuRecordData
                                                                      .account
                                                                  : ""
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                      向\n                    "
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                follow.userWaihuRecordData
                                                                  ? follow
                                                                      .userWaihuRecordData
                                                                      .phone
                                                                  : ""
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    follow.userWaihuRecordData &&
                                                                    follow
                                                                      .userWaihuRecordData
                                                                      .callName,
                                                                  expression:
                                                                    "\n                          follow.userWaihuRecordData &&\n                            follow.userWaihuRecordData.callName\n                        ",
                                                                },
                                                              ],
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  follow
                                                                    .userWaihuRecordData
                                                                    .callName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                      发起外呼，\n                    "
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  follow.userWaihuRecordData &&
                                                                  follow
                                                                    .userWaihuRecordData
                                                                    .phoneStatus,
                                                                expression:
                                                                  "\n                        follow.userWaihuRecordData &&\n                          follow.userWaihuRecordData.phoneStatus\n                      ",
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "\n                        通话状态\n                      "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    follow.userWaihuRecordData
                                                                      ? follow
                                                                          .userWaihuRecordData
                                                                          .phoneStatus
                                                                      : ""
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("br"),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  follow.userWaihuRecordData &&
                                                                  follow
                                                                    .userWaihuRecordData
                                                                    .callDuration,
                                                                expression:
                                                                  "\n                        follow.userWaihuRecordData &&\n                          follow.userWaihuRecordData.callDuration\n                      ",
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "\n                        ，通话时长\n                      "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    follow.userWaihuRecordData
                                                                      ? follow
                                                                          .userWaihuRecordData
                                                                          .callDuration
                                                                      : ""
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "dt-permission",
                                                                rawName:
                                                                  "v-dt-permission",
                                                                value:
                                                                  "客户管理CRM-客户公海-播放录音",
                                                                expression:
                                                                  "'客户管理CRM-客户公海-播放录音'",
                                                              },
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  follow.userWaihuRecordData &&
                                                                  follow
                                                                    .userWaihuRecordData
                                                                    .callDuration &&
                                                                  follow
                                                                    .userWaihuRecordData
                                                                    .ossUrl,
                                                                expression:
                                                                  "\n                        follow.userWaihuRecordData &&\n                          follow.userWaihuRecordData.callDuration &&\n                          follow.userWaihuRecordData.ossUrl\n                      ",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "play-record",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.playRecord(
                                                                  follow
                                                                    .userWaihuRecordData
                                                                    .ossUrl
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      播放录音\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { name: "first", label: "跟进记录" } },
                [
                  _vm.pondCond && _vm.pondCond.length && _vm.hasViewPermission
                    ? _c(
                        "el-scrollbar",
                        {
                          staticClass: "xxx-scroll",
                          style: {
                            height:
                              _vm.activeTabs != "third" &&
                              _vm.activeTabs != "second" &&
                              _vm.hasEditPermission
                                ? "calc( 100vh - 50px - 50px - 350px - 50px )"
                                : "calc( 100vh - 50px - 200px )",
                            with: "100%",
                            overflowX: "hidden",
                          },
                        },
                        _vm._l(_vm.pondCond, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "pond-cond-all" },
                            [
                              item.week
                                ? [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "70px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.week))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.day))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      item.follows,
                                      function (follow, ind) {
                                        return _c(
                                          "div",
                                          {
                                            key: ind + "c",
                                            staticStyle: { overflow: "hidden" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "pond-cond-left",
                                                class:
                                                  ind !== 0
                                                    ? "pond-no-first"
                                                    : "",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(_vm._s(follow.time)),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "pond-cond-right",
                                                class:
                                                  ind !== 0
                                                    ? "pond-no-first"
                                                    : "",
                                              },
                                              [
                                                _c("p", [
                                                  _c(
                                                    "span",
                                                    {
                                                      class:
                                                        item.week === "今天"
                                                          ? "pond-cond-posi"
                                                          : "pond-cond-had",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(follow.follower)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("    .    "),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(_vm._s(follow.type)),
                                                  ]),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "20px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#999999",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "记录：" +
                                                            _vm._s(
                                                              follow.records
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "previewClose",
                                                  },
                                                  [
                                                    follow.imagesUrl != "" &&
                                                    follow.imagesUrl != null
                                                      ? _c("img", {
                                                          staticClass:
                                                            "previewImg",
                                                          attrs: {
                                                            src: follow.imagesUrl,
                                                            alt: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.previewImg(
                                                                follow.imagesUrl
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.showViewer
                                                      ? _c("el-image-viewer", {
                                                          attrs: {
                                                            "on-close":
                                                              _vm.closeViewer,
                                                            "url-list": [
                                                              _vm.srcList,
                                                            ],
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { name: "second", label: "系统记录" } },
                [
                  _vm.pondCond && _vm.pondCond.length && _vm.hasViewPermission
                    ? _c(
                        "el-scrollbar",
                        {
                          staticClass: "xxx-scroll",
                          style: {
                            height:
                              _vm.activeTabs != "third" &&
                              _vm.activeTabs != "second" &&
                              _vm.hasEditPermission
                                ? "calc( 100vh - 50px - 50px - 50px - 350px )"
                                : "calc( 100vh - 50px - 200px )",
                            with: "100%",
                            overflowX: "hidden",
                          },
                        },
                        _vm._l(_vm.pondCond, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "pond-cond-all" },
                            [
                              _c(
                                "p",
                                { staticStyle: { "margin-bottom": "20px" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "70px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.week))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(item.day))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(item.follows, function (follow, ind) {
                                return _c(
                                  "div",
                                  {
                                    key: ind + "c",
                                    staticStyle: { overflow: "hidden" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pond-cond-left",
                                        class: ind !== 0 ? "pond-no-first" : "",
                                      },
                                      [_c("p", [_vm._v(_vm._s(follow.time))])]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pond-cond-right",
                                        class: ind !== 0 ? "pond-no-first" : "",
                                      },
                                      [
                                        _c("p", [
                                          _c("span", {
                                            class:
                                              item.week === "今天"
                                                ? "pond-cond-posi"
                                                : "pond-cond-had",
                                            staticStyle: { color: "#999999" },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                follow.opDetails
                                              ),
                                            },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "previewClose" },
                                          [
                                            follow.imagesUrl != "" &&
                                            follow.imagesUrl != null
                                              ? _c("img", {
                                                  staticClass: "previewImg",
                                                  attrs: {
                                                    src: follow.imagesUrl,
                                                    alt: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.previewImg(
                                                        follow.imagesUrl
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.showViewer
                                              ? _c("el-image-viewer", {
                                                  attrs: {
                                                    "on-close": _vm.closeViewer,
                                                    "url-list": [_vm.srcList],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        follow.type === -1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "phone-record",
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        follow.userWaihuRecordData
                                                          ? follow
                                                              .userWaihuRecordData
                                                              .account
                                                          : ""
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                    向\n                  "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        follow.userWaihuRecordData
                                                          ? follow
                                                              .userWaihuRecordData
                                                              .phone
                                                          : ""
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            follow.userWaihuRecordData &&
                                                            follow
                                                              .userWaihuRecordData
                                                              .callName,
                                                          expression:
                                                            "\n                        follow.userWaihuRecordData &&\n                          follow.userWaihuRecordData.callName\n                      ",
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          follow
                                                            .userWaihuRecordData
                                                            .callName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                    发起外呼，\n                  "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          follow.userWaihuRecordData &&
                                                          follow
                                                            .userWaihuRecordData
                                                            .phoneStatus,
                                                        expression:
                                                          "\n                      follow.userWaihuRecordData &&\n                        follow.userWaihuRecordData.phoneStatus\n                    ",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                      通话状态\n                    "
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            follow.userWaihuRecordData
                                                              ? follow
                                                                  .userWaihuRecordData
                                                                  .phoneStatus
                                                              : ""
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          follow.userWaihuRecordData &&
                                                          follow
                                                            .userWaihuRecordData
                                                            .callDuration,
                                                        expression:
                                                          "\n                      follow.userWaihuRecordData &&\n                        follow.userWaihuRecordData.callDuration\n                    ",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                      ，通话时长\n                    "
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            follow.userWaihuRecordData
                                                              ? follow
                                                                  .userWaihuRecordData
                                                                  .callDuration
                                                              : ""
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "dt-permission",
                                                        rawName:
                                                          "v-dt-permission",
                                                        value:
                                                          "客户管理CRM-客户公海-播放录音",
                                                        expression:
                                                          "'客户管理CRM-客户公海-播放录音'",
                                                      },
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          follow.userWaihuRecordData &&
                                                          follow
                                                            .userWaihuRecordData
                                                            .callDuration &&
                                                          follow
                                                            .userWaihuRecordData
                                                            .ossUrl,
                                                        expression:
                                                          "\n                      follow.userWaihuRecordData &&\n                        follow.userWaihuRecordData.callDuration &&\n                        follow.userWaihuRecordData.ossUrl\n                    ",
                                                      },
                                                    ],
                                                    staticClass: "play-record",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.playRecord(
                                                          follow
                                                            .userWaihuRecordData
                                                            .ossUrl
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    播放录音\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { name: "third", label: "客户动态" } },
                [
                  _vm.pondCond && _vm.pondCond.length && _vm.hasViewPermission
                    ? _c(
                        "el-scrollbar",
                        {
                          staticClass: "xxx-scroll",
                          style: {
                            height:
                              _vm.activeTabs != "third" &&
                              _vm.activeTabs != "second" &&
                              _vm.hasEditPermission
                                ? "calc( 100vh - 50px - 50px - 50px - 350px )"
                                : "calc( 100vh - 50px - 200px )",
                            with: "100%",
                            overflowX: "hidden",
                          },
                        },
                        _vm._l(_vm.pondCond, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "pond-cond-all" },
                            [
                              _c(
                                "p",
                                { staticStyle: { "margin-bottom": "20px" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "70px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.week))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(item.day))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(item.follows, function (follow, ind) {
                                return _c(
                                  "div",
                                  {
                                    key: ind + "c",
                                    staticStyle: { overflow: "hidden" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pond-cond-left",
                                        class: ind !== 0 ? "pond-no-first" : "",
                                      },
                                      [_c("p", [_vm._v(_vm._s(follow.time))])]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pond-cond-right",
                                        class: ind !== 0 ? "pond-no-first" : "",
                                      },
                                      [
                                        _c("p", [
                                          _c("span", {
                                            class:
                                              item.week === "今天"
                                                ? "pond-cond-posi"
                                                : "pond-cond-had",
                                            staticStyle: { color: "#999999" },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                follow.opDetails
                                              ),
                                            },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "previewClose" },
                                          [
                                            follow.imagesUrl != "" &&
                                            follow.imagesUrl != null
                                              ? _c("img", {
                                                  staticClass: "previewImg",
                                                  attrs: {
                                                    src: follow.imagesUrl,
                                                    alt: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.previewImg(
                                                        follow.imagesUrl
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.showViewer
                                              ? _c("el-image-viewer", {
                                                  attrs: {
                                                    "on-close": _vm.closeViewer,
                                                    "url-list": [_vm.srcList],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.activeTabs != "third" &&
          _vm.activeTabs != "second" &&
          _vm.hasEditPermission &&
          _vm.inPool
            ? _c(
                "div",
                {
                  staticClass: "pond-form",
                  staticStyle: { width: "100%", height: "320px" },
                },
                [
                  _c(
                    "el-form",
                    { ref: "form", attrs: { "label-width": "85px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "跟进方式", required: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "90%" },
                              attrs: { placeholder: "", size: "small" },
                              model: {
                                value: _vm.creatFlow,
                                callback: function ($$v) {
                                  _vm.creatFlow = $$v
                                },
                                expression: "creatFlow",
                              },
                            },
                            _vm._l(_vm.allFlow, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跟进对象" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass:
                                "filter-mult-coll select-industry-one xxx",
                              staticStyle: { width: "90%" },
                              attrs: {
                                disabled: "",
                                size: "small",
                                placeholder: "",
                                multiple: "",
                                "collapse-tags": "",
                                filterable: "",
                                "reserve-keyword": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.secondChoose()
                                },
                              },
                              model: {
                                value: _vm.chooFilterInput,
                                callback: function ($$v) {
                                  _vm.chooFilterInput = $$v
                                },
                                expression: "chooFilterInput",
                              },
                            },
                            _vm._l(_vm.contactList, function (item) {
                              return _c("el-option", {
                                key: item._id,
                                attrs: {
                                  label: item.position
                                    ? item.contact + "·" + item.position
                                    : item.contact,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "dt-permission",
                                  rawName: "v-dt-permission",
                                  value: "客户管理CRM-客户公海-联系人",
                                  expression: "'客户管理CRM-客户公海-联系人'",
                                },
                              ],
                              staticClass: "select-btn",
                              on: { click: _vm.addContactDia },
                            },
                            [_vm._v("\n            选择\n          ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跟进时间", required: "" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              type: "datetime",
                              size: "small",
                              "picker-options": _vm.pickerOptions,
                              format: "yyyy-MM-dd HH:mm",
                              "value-format": "yyyy-MM-dd HH:mm",
                              placeholder: "选择日期时间",
                            },
                            model: {
                              value: _vm.chooFilterTime,
                              callback: function ($$v) {
                                _vm.chooFilterTime = $$v
                              },
                              expression: "chooFilterTime",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: { position: "absolute", top: "5px" },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "upload",
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action:
                                      "https://jsonplaceholder.typicode.com/posts/",
                                    "show-file-list": false,
                                    "before-upload": _vm.beforeUpload,
                                    limit: 1,
                                    "auto-upload": true,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "trigger" },
                                      slot: "trigger",
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "uploadSvg",
                                        attrs: { "icon-class": "uploadImg" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        float: "left",
                        height: "30px",
                        "line-height": "30px",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      false
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "dt-permission",
                                  rawName: "v-dt-permission",
                                  value: "客户管理CRM-客户公海-联系人",
                                  expression: "'客户管理CRM-客户公海-联系人'",
                                },
                              ],
                              staticClass: "search-botton",
                              staticStyle: {
                                "margin-left": "10px",
                                width: "100px",
                              },
                              on: { click: _vm.addContactDia },
                            },
                            [_vm._v("\n          新建联系人\n        ")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "editor" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { type: "textarea", placeholder: "请输入内容" },
                        on: { blur: _vm.onInputBlur, focus: _vm.onInputFocus },
                        model: {
                          value: _vm.followRecords,
                          callback: function ($$v) {
                            _vm.followRecords = $$v
                          },
                          expression: "followRecords",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "submit-btn" },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingBtn,
                                  expression: "loadingBtn",
                                },
                              ],
                              staticStyle: { "margin-top": "10px" },
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.submintBtn },
                            },
                            [_vm._v("提交")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.uploadUrl
                    ? _c("img", {
                        staticClass: "pond-avatar",
                        attrs: { src: _vm.uploadUrl },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "addContact",
          staticClass: "pond-form-input",
          attrs: { dialogStatus: _vm.addContactModel },
          on: { submitContBtn: _vm.addContactBtn },
        },
        [
          _c(
            "div",
            {
              staticStyle: { padding: "20px", "box-sizing": "border-box" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { "label-width": "auto", model: _vm.formData },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名", required: "" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入姓名" },
                                model: {
                                  value: _vm.formData.contact,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "contact", $$v)
                                  },
                                  expression: "formData.contact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v("身份")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formData.isKp,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "isKp", $$v)
                                    },
                                    expression: "formData.isKp",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "关键人", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "中间人", value: "2" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "普通", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v("部门")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入部门" },
                                model: {
                                  value: _vm.formData.department,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "department", $$v)
                                  },
                                  expression: "formData.department",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "职位" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入职位" },
                                model: {
                                  value: _vm.formData.position,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "position", $$v)
                                  },
                                  expression: "formData.position",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "手机",
                                prop: "mobile",
                                rules: _vm.rules,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入手机号码" },
                                model: {
                                  value: _vm.formData.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "mobile", $$v)
                                  },
                                  expression: "formData.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "座机" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入座机号码" },
                                model: {
                                  value: _vm.formData.landLine,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "landLine", $$v)
                                  },
                                  expression: "formData.landLine",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "微信号" } },
                            [
                              _c("el-input", {
                                staticStyle: { "ime-mode": "disabled" },
                                attrs: { placeholder: "请输入微信号" },
                                model: {
                                  value: _vm.formData.wechat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "wechat", $$v)
                                  },
                                  expression: "formData.wechat",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "邮箱" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入邮箱" },
                                model: {
                                  value: _vm.formData.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "email", $$v)
                                  },
                                  expression: "formData.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("状态")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择状态" },
                                  model: {
                                    value: _vm.formData.mark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "mark", $$v)
                                    },
                                    expression: "formData.mark",
                                  },
                                },
                                _vm._l(_vm.markDescList, function (_item) {
                                  return _c("el-option", {
                                    key: _item.value,
                                    attrs: {
                                      label: _item.name,
                                      value: _item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: "24" } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("备注")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入备注",
                                  maxlength: "100",
                                  "show-word-limit": "",
                                  autosize: { minRows: 2, maxRows: 10 },
                                },
                                model: {
                                  value: _vm.formData.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "remark", $$v)
                                  },
                                  expression: "formData.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }