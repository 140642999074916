var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tag-cloud-page" },
    [
      _vm.origin == "page"
        ? _c("div", { attrs: { id: "tag-page" } })
        : _c("div", { attrs: { id: "tag-update" } }),
      _vm._v(" "),
      _c("c-dialog", {
        attrs: { show: _vm.visible, companyInfo: _vm.companyInfo },
        on: {
          handleClose: function ($event) {
            _vm.visible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }