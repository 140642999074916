var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sign-main" }, [
    _c("div", { staticClass: "row-main" }, [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("el-date-picker", {
            attrs: {
              size: "small",
              type: "year",
              "value-format": "yyyy",
              placeholder: "选择年",
            },
            on: { change: _vm.getViewData },
            model: {
              value: _vm.year,
              callback: function ($$v) {
                _vm.year = $$v
              },
              expression: "year",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { "margin-left": "20px", width: "400px" },
              attrs: {
                placeholder: "请选择员工",
                multiple: "",
                filterable: "",
                size: "small",
              },
              on: { change: _vm.getViewData },
              model: {
                value: _vm.selectUserList,
                callback: function ($$v) {
                  _vm.selectUserList = $$v
                },
                expression: "selectUserList",
              },
            },
            _vm._l(_vm.userList, function (group) {
              return _c(
                "el-option-group",
                { key: group.label, attrs: { label: group.label } },
                _vm._l(group.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn-list" },
        [
          _vm.type === "view"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "绩效目标设置-签约目标设置-编辑",
                      expression: "'绩效目标设置-签约目标设置-编辑'",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.showEdit },
                },
                [_vm._v("\n        编辑\n      ")]
              )
            : _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "dt-permission",
                          rawName: "v-dt-permission",
                          value: "绩效目标设置-签约目标设置-编辑",
                          expression: "'绩效目标设置-签约目标设置-编辑'",
                        },
                      ],
                      attrs: { size: "small" },
                      on: { click: _vm.cancelEdit },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                        {
                          name: "dt-permission",
                          rawName: "v-dt-permission",
                          value: "绩效目标设置-签约目标设置-编辑",
                          expression: "'绩效目标设置-签约目标设置-编辑'",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.confirmBtn },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingData,
              expression: "loadingData",
            },
            {
              name: "dt-permission",
              rawName: "v-dt-permission",
              value: "绩效目标设置-签约目标设置-查看",
              expression: "'绩效目标设置-签约目标设置-查看'",
            },
          ],
          staticClass: "table",
        },
        [
          _c(
            "el-table",
            {
              key: _vm.key,
              attrs: {
                data: _vm.tableData,
                "show-summary": "",
                border: "",
                size: "small",
                "summary-method": _vm.getSummaries,
              },
            },
            _vm._l(_vm.columns, function (item) {
              return _c("el-table-column", {
                attrs: { width: "auto", label: item.name, prop: item.field },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.field !== "userName" &&
                          item.field !== "count" &&
                          _vm.type === "edit"
                            ? _c(
                                "div",
                                {
                                  staticClass: "input-row",
                                  on: {
                                    mouseenter: _vm.mouseenter,
                                    mouseleave: _vm.mouseleave,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请填写金额",
                                    },
                                    on: {
                                      input: function ($event) {
                                        isNaN(
                                          parseInt(scope.row[item.field])
                                        ) ||
                                        scope.row[item.field].indexOf("0") === 0
                                          ? (scope.row[item.field] = "")
                                          : ""
                                      },
                                    },
                                    model: {
                                      value: scope.row[item.field],
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, item.field, $$v)
                                      },
                                      expression: "scope.row[item.field]",
                                    },
                                  }),
                                ],
                                1
                              )
                            : item.field === "count"
                            ? _c("div", { staticClass: "input-row" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("countFormat")(
                                        _vm.tableData[scope.$index]
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : item.field === "userName"
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row[item.field]) +
                                    "\n            "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      isNaN(parseInt(scope.row[item.field]))
                                        ? "-"
                                        : parseInt(
                                            scope.row[item.field]
                                          ).toLocaleString()
                                    ) +
                                    "\n            "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "50px" } }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: _vm.key + 1,
              attrs: {
                data: _vm.quarterTableData,
                "show-summary": "",
                border: "",
                size: "small",
                "summary-method": _vm.getSummaries,
              },
            },
            _vm._l(_vm.quarterColumns, function (item) {
              return _c("el-table-column", {
                attrs: { label: item.name, prop: item.field },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.field !== "name" &&
                          item.field !== "count" &&
                          _vm.type === "edit"
                            ? _c(
                                "div",
                                {
                                  staticClass: "input-row",
                                  on: {
                                    mouseenter: _vm.mouseenter,
                                    mouseleave: _vm.mouseleave,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请填写金额",
                                    },
                                    on: {
                                      input: function ($event) {
                                        isNaN(
                                          parseInt(scope.row[item.field])
                                        ) ||
                                        scope.row[item.field].indexOf("0") === 0
                                          ? (scope.row[item.field] = "")
                                          : ""
                                      },
                                    },
                                    model: {
                                      value: scope.row[item.field],
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, item.field, $$v)
                                      },
                                      expression: "scope.row[item.field]",
                                    },
                                  }),
                                ],
                                1
                              )
                            : item.field === "count"
                            ? _c("div", { staticClass: "input-row" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("countFormat")(
                                        _vm.quarterTableData[scope.$index]
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : item.field === "name"
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row[item.field]) +
                                    "\n            "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      isNaN(parseInt(scope.row[item.field]))
                                        ? "-"
                                        : parseInt(
                                            scope.row[item.field]
                                          ).toLocaleString()
                                    ) +
                                    "\n            "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }