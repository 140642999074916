<template>
  <div class="RunIn-main">
    <span v-if="pathUrl" class="a-link" @click="goPath">
      {{ dataNum || 0 }}
    </span>
    <span v-else>
      {{ dataNum || 0 }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'RunIn',
  props: ['dataNum', 'dataType', 'dataFilter', 'dataName', 'dataFormat'],
  computed: {
    pathUrl() {
      const runInList = [
        '电销接听',
        '跟进客户',
        '成交',
        '产品演示',
        '当面拜访',
        '合同金额',
        '收款金额',
        '业绩金额',
        '外采金额'
      ]
      return runInList.includes(this.dataName) ? '/run-in/list' : ''
    }
  },
  methods: {
    goPath() {
      this.$router.push({
        path: this.pathUrl,
        query: {
          path: this.dataType,
          params: JSON.stringify(this.dataFilter),
          text: this.dataFormat,
          name: this.dataName
        }
      })
    }
  }
}
</script>

<style scoped>
.a-link {
  color: #0099cc;
  cursor: pointer;
}
</style>
