var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "weixinLogin-main",
    },
    [_vm.show ? _c("div", { attrs: { id: "wx-login-3" } }) : _vm._e()]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }