import { render, staticRenderFns } from "./DtDialog.vue?vue&type=template&id=4803025b"
import script from "./DtDialog.vue?vue&type=script&lang=js"
export * from "./DtDialog.vue?vue&type=script&lang=js"
import style0 from "./DtDialog.vue?vue&type=style&index=0&id=4803025b&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Project\\dtbird-web-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4803025b')) {
      api.createRecord('4803025b', component.options)
    } else {
      api.reload('4803025b', component.options)
    }
    module.hot.accept("./DtDialog.vue?vue&type=template&id=4803025b", function () {
      api.rerender('4803025b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/element/DtDialog.vue"
export default component.exports