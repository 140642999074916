var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-table" },
    [
      _c(
        "div",
        { staticClass: "table-header" },
        _vm._l(_vm.tableColumn, function (item, index) {
          return _c("div", { key: index, staticClass: "header-item" }, [
            _vm._v("\n      " + _vm._s(item) + "\n    "),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { on: { click: _vm.handleItemClick } },
        [
          _c(
            "vue-seamless-scroll",
            {
              staticClass: "roll-table",
              attrs: { data: _vm.tableList, "class-option": _vm.classOption },
            },
            [
              _c(
                "ul",
                _vm._l(_vm.tableList, function (el, idx) {
                  return _c(
                    "li",
                    {
                      key: idx,
                      style: `border-bottom: 1px solid ${el.border};`,
                    },
                    _vm._l(_vm.tableColumn, function (it, ix) {
                      return _c("div", { key: ix, staticClass: "item" }, [
                        _c("div", { staticClass: "table-dot-flex" }, [
                          ix == 0
                            ? _c("span", {
                                staticClass: "table-dot",
                                style: `background:${el.background}`,
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: _vm.handleTableClass(el, it),
                              attrs: {
                                "data-type": _vm.handleTableType(el, it),
                                "data-url": _vm.handleTableUrl(el, it),
                                "data-item": _vm.handleTableAlert(el, it),
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.handleTableData(el, it)) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    }),
                    0
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("c-dialog", {
        attrs: { show: _vm.visible, companyInfo: _vm.companyInfo },
        on: {
          handleClose: function ($event) {
            _vm.visible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }