<template>
  <div>
    <div
      v-for="(moduleParam, index) in moduleParams"
      style="position: relative"
      :class="listshow(moduleParam)"
      v-if="columType(moduleParam)"
      :key="index"
      :id="`scrollto-${moduleParam.module_title}`"
    >
      <div
        :class="listshowMao(moduleParam)"
        style="position: absolute;top:-42px"
      ></div>
      <module-pack
        ref="modulePackList"
        :moduleParam="moduleParam"
        :isReceive="detail.show"
        :detail="detail"
        :package-id="packageId"
      ></module-pack>
    </div>
  </div>
</template>

<script>
import ModulePack from '@/components/ModulePack'

export default {
  name: 'ModulePackArea',
  props: {
    moduleParams: {
      type: Array,
      default: () => []
    },
    detail: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    packageId: {
      type: [String, Number],
      default: 0
    }
  },
  components: {
    ModulePack
  },
  data() {
    return {
      status: false
    }
  },

  methods: {
    listshow(moduleParams) {
      if (moduleParams.module_branch == '基础信息') {
        return 'listshow0'
      }
      if (moduleParams.module_branch == '产品及服务') {
        return 'listshow1'
      }
      if (moduleParams.module_branch == '企业人员') {
        return 'listshow2'
      }
      if (moduleParams.module_branch == '其他信息') {
        return 'listshow3'
      }
    },
    listshowMao(moduleParams) {
      if (moduleParams.module_branch == '基础信息') {
        return 'listshowMao0'
      }
      if (moduleParams.module_branch == '产品及服务') {
        return 'listshowMao1'
      }
      if (moduleParams.module_branch == '企业人员') {
        return 'listshowMao2'
      }
      if (moduleParams.module_branch == '其他信息') {
        return 'listshowMao3'
      }
    },
    columType(i) {
      console.log(this.type, 'this.type')
      if (
        this.type === '' &&
        i.module_api !== 'touch' &&
        i.module_api !== 'idc' &&
        i.module_api !== 'social'
      ) {
        return true
      }
      if (this.type === 'touch' && i.module_api === 'touch') {
        return true
      }
      if (this.type === 'idc' && i.module_api === 'idc') {
        return true
      }
      return false
    }
  }
}
</script>
<style scoped></style>
