<template>
  <div class="update-div" v-loading="loading">
    <permission
      :key="key"
      :name="name"
      :permission-list="permissionList"
      :update-permission="updatePermission"
      @save="save"
      type="编辑角色"
    />
  </div>
</template>

<script>
import { getPanelList, roleEdit, saveDataPermission } from '@/api/permission'
import permission from './component/permission'
export default {
  components: {
    permission
  },
  data() {
    return {
      updatePermission: [], //要更新的permission数据
      loading: false,
      roleId: null,
      key: 0, //初始化组件
      name: ''
    }
  },
  created() {
    this.roleId = localStorage.getItem('roleId')
    this.name = localStorage.getItem('roleName')
    this.init()
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.panelList
    }
  },
  methods: {
    // 处理数据权限
    handelDataPermission(list) {
      // debugger
      const result = []
      list.forEach(menu => {
        const pages = menu.pages
        pages.forEach(page => {
          if (page.dataPermissionModel) {
            result.push({
              page_id: page.id,
              scope: page.dataPermissionModel['scope'],
              user_ids: page.dataPermissionModel['user_ids']
            })
          }
        })
      })
      console.log(list, 'result')
      return JSON.stringify(result)
    },

    tip(msg, type = 'success', time = 800) {
      this.$message({
        message: msg,
        type: type,
        duration: time
      })
    },

    async init() {
      this.loading = true
      try {
        const res = await getPanelList({ roleId: this.roleId })
        if (res && res.code === 60000 && res.data && res.data.code === 60000) {
          this.updatePermission = res.data.data
          // this.key = this.key + 1; //获取到权限列表后 初始化组件
        } else {
          this.tip('出错了', 'error')
          this.$router.replace('/permission/permission')
        }
      } catch (e) {
        this.tip('出错了', 'error')
        this.$router.replace('/permission/permission')
      }
      this.loading = false
    },

    async save(data) {
      // return
      // debugger
      this.loading = true
      const params = {
        roleId: this.roleId,
        roleName: data.name,
        rolePermission: JSON.stringify(data.data)
      }
      try {
        const res = await roleEdit(params)
        if (res && res.code === 60000 && res.data && res.data.code === 60000) {
          const dataRes = await this.createDataPermission({
            roleId: this.roleId,
            dataPermission: this.handelDataPermission(data.data)
          })
          if (dataRes) {
            this.tip('编辑成功')
            this.$router.replace(`/permission/view?roleId=${this.roleId}`)
          } else {
            this.tip('编辑失败')
          }
        } else {
          this.tip(res.data.code !== 60000 ? res.data.msg : res.msg, 'error')
        }
      } catch (e) {
        this.tip('编辑失败', 'error')
      }
      this.loading = false
    },

    createDataPermission(params) {
      return new Promise(resolve => {
        saveDataPermission(params)
          .then(res => {
            if (res && res.code === 60000) {
              resolve(true)
              return
            }
            resolve(false)
          })
          .catch(() => {
            resolve(false)
          })
      })
    }
  },

  beforeDestroy() {
    const beforePath = localStorage.getItem('beforePath')
    if (beforePath === '/permission/permission') {
      localStorage.removeItem('roleId')
      localStorage.removeItem('roleName')
    }
  }
}
</script>

<style scoped>
.update-div {
  /*height: 100%;*/
  width: 100%;
}
.update-div ::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
