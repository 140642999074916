var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-dialog" },
    [
      _c(
        "el-dialog",
        {
          ref: "dialog",
          attrs: {
            visible: _vm.PeDialogShow,
            width: _vm.PeDialogWidth,
            "before-close": _vm.handleClose,
            fullscreen: _vm.PeFullScreen,
            "modal-append-to-body": _vm.PeDialogInner,
            "custom-class": _vm.PeDialogClass,
          },
          on: {
            "update:visible": function ($event) {
              _vm.PeDialogShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "el-dialog__title pe-custom-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.PeDialogTitle)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right-slot" },
                [
                  _vm._t("right-btn"),
                  _vm._v(" "),
                  _c("svg-icon", {
                    staticClass: "svg-close",
                    attrs: { "icon-class": "dialog_close" },
                    on: { click: _vm.handleClose },
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.peDialogCustom
                ? _c("div", [_vm._t("footer")], 2)
                : _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.handleClose },
                        },
                        [_vm._v(_vm._s(_vm.PeDialogBtnCancel))]
                      ),
                      _vm._v(" "),
                      _vm.PeDialogShowSure
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.handleSure },
                            },
                            [_vm._v(_vm._s(_vm.PeDialogBtnSure))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }