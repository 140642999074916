<template>
  <div
    :class="
      active
        ? 'ContactDetail-main-border ContactDetail-main'
        : 'ContactDetail-main'
    "
    @click="selectContact"
  >
    <div>
      <span class="phone">
        {{ contactData.phone }}
      </span>
      <span class="name">
        {{ contactData.contact }}
      </span>
      <span class="name">
        {{ contactData.position }}
      </span>
      <span class="tag">
        <el-tag v-if="contactData.ai_konghao" size="mini">空号</el-tag>
        <el-tag v-if="contactData.ai_tingji" size="mini">停机</el-tag>
        <el-tag v-if="contactData.wuxiao" size="mini">无效</el-tag>
        <el-tag v-if="contactData.zhongjie" size="mini">疑似中介</el-tag>
        <el-tag v-if="contactData.phoneStatus" size="mini">{{
          contactData.phoneStatus
        }}</el-tag>
        <el-tag v-if="contactData.recommend" size="mini">推荐</el-tag>
        <el-tag v-if="contactData.ai_can_call" size="mini">电话可打通</el-tag>
      </span>
      <span class="call-num" v-show="contactData.callCnt">
        <el-popover
          popper-class="el-popper-dt-dt"
          placement="bottom-start"
          width="500"
          trigger="click"
        >
          <span
            slot="reference"
            style="padding:8px;color: #0099cc;cursor: pointer;"
            v-if="contactData.callCnt"
            >{{ `已拨打${contactData.callCnt}次` }}</span
          >
          <el-scrollbar
            style="height: 500px;width: 100%"
            class="dt-dropdown-dt"
          >
            <div class="time-line">
              <el-timeline v-for="(timeLine, index) in contactData.callRecords">
                <div class="line-top">
                  <span>{{ timeLine.week }}</span
                  ><span>{{ timeLine.day }}</span>
                </div>
                <el-timeline-item
                  :color="timeLine.week === '今天' ? '#FF0000' : ''"
                  v-for="(activity, index) in timeLine.follows"
                  :key="index"
                >
                  <div class="line-content">
                    <span>
                      <span class="import-text">
                        {{ activity.account }}
                      </span>
                      <span>
                        发起外呼，
                      </span>
                    </span>
                    <span>
                      <span>
                        通话状态
                      </span>
                      <span class="import-text">
                        {{ activity.phoneStatus }}
                      </span>
                    </span>
                    <span v-show="activity.callDuration">
                      <span>
                        ，通话时长
                      </span>
                      <span class="import-text">
                        {{ activity.callDuration }}
                      </span>
                      <span
                        class="play-record"
                        @click="playRecord(activity.ossUrl)"
                        v-show="activity.callDuration && activity.ossUrl"
                        v-dt-permission="'智能获客-推荐线索-播放录音'"
                      >
                        播放录音
                      </span>
                    </span>
                  </div>
                  <div class="time-line-date">
                    {{ activity.time }}
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </el-scrollbar>
        </el-popover>
      </span>
      <span class="icon-click">
        <i class="card-phone-icon" @click="call"></i>
        <el-dropdown
          placement="bottom-start"
          trigger="click"
          @command="changeAssign($event, contactData)"
        >
          <i class="card-phone-edit-icon"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item.value" v-for="item in assignOption"
              >{{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
    </div>
    <div class="last">
      <div class="all-mark">
        <el-popover
          v-if="contactData.phoneMarkList && contactData.phoneMarkList.length"
          popper-class="el-popper-dt-dt"
          placement="bottom-start"
          width="600"
          trigger="click"
        >
          <span
            slot="reference"
            style="color: #0099cc;cursor: pointer;padding: 8px 0"
            >共{{ contactData.cnt || 0 }}条标记</span
          >
          <el-scrollbar
            style="height: 500px;width: 100%"
            class="dt-dropdown-dt"
          >
            <div class="time-line">
              <el-timeline
                v-for="(timeLine, index) in contactData.phoneMarkList"
              >
                <div class="line-top">
                  <span>{{ timeLine.week }}</span
                  ><span>{{ timeLine.day }}</span>
                </div>
                <el-timeline-item
                  :color="timeLine.week === '今天' ? '#FF0000' : ''"
                  v-for="(activity, index) in timeLine.follows"
                  :key="index"
                >
                  <div class="line-content">
                    {{ activity.markSummary }}
                  </div>
                  <div class="time-line-date">
                    {{ activity.time }}
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </el-scrollbar>
        </el-popover>
      </div>
      <div class="from" @click.stop="jump()">
        <span
          v-for="item in contactData.fromToUrl"
          @click="jump(item.url)"
          style="margin-right: 3px"
          class="l-span"
        >
          <span
            :style="{ color: item.url && item.url.trim() ? '' : '#8a8a8a' }"
          >
            {{ item.from }}
          </span>
          <span>|</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MobileAudio from '@/views/callsystem/mobileaudio'
import { postDetailPhoneAsign } from '@/api/thread'
import editCompanyOrUser from '@/components/ModulePack/dialog/editCompanyOrUser'
import editUser from '@/components/ModulePack/dialog/editUser'

export default {
  name: 'ContactDetail',
  data() {
    return {}
  },
  props: {
    contactData: {
      type: Object,
      default: () => {}
    },
    assignOption: {
      type: Array,
      default: () => []
    },
    detail: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  mounted() {},
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    }
  },
  methods: {
    jump(url) {
      if (url && url.trim()) window.open(url)
    },

    playRecord(ossUrl) {
      this.$store.dispatch('showModal', {
        title: '播放录音',
        view: MobileAudio,
        size: '600px',
        options: {
          row: {
            ossUrl: ossUrl,
            type: 'record'
          }
        }
      })
    },

    changeAssign(mark, i) {
      const params = {
        mark,
        phone: i.phone,
        companyName: this.detail.company_name,
        contact: i.contact,
        packageId: this.packageId,
        klc: this.permissionList.includes('客户培育-联系人培育-新建联系人'), // '客户培育-联系人培育-新建联系人'
        kkx: this.permissionList.includes('客户管理CRM-客户公海-新建客户'), // '客户管理CRM-客户公海-新建客户'
        kkl: this.permissionList.includes('客户管理CRM-客户公海-联系人'), // '客户管理CRM-客户公海-联系人'
        pageId: 2001
      }
      postDetailPhoneAsign(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success',
            duration: 500
          })
          if (mark === 7) {
            this.$store.state.user.customerInfoMap.inPool = true
            this.$store.state.user.customerInfoMap.desc =
              '客户当前位置：客户公海-' + this.$store.state.user.name
            const hasShowModalPermission = this.permissionList.includes(
              '智能获客-推荐线索-转客户强弹窗'
            )
            if (!hasShowModalPermission) {
              return
            }
            if (res.data.customer && res.data.contact) {
              this.$store.dispatch('showModal', {
                title: '编辑',
                size: '900px',
                options: {
                  formData: res.data.customer,
                  userFormData: res.data.contact,
                  activeTab: res.data.customer ? res.data.customer.type : ''
                },
                view: editCompanyOrUser
              })
            }

            if (res.data.contact && !res.data.customer) {
              this.$store.dispatch('showModal', {
                title: '编辑',
                size: '600px',
                options: {
                  userFormData: res.data.contact
                },
                view: editUser
              })
            }
          }
          this.$emit('refresh')
        }
      })
    },

    call() {
      this.$emit('call', this.contactData)
    },

    selectContact() {
      this.$emit('selectContact', this.contactData)
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.ContactDetail-main {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 12px;
  position: relative;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.ContactDetail-main-border {
  border: 1px solid orange;
}
.all-mark {
  color: #0099cc;
  cursor: pointer;
}
.from {
  color: #41cabf;
}
.last {
  display: flex;
  justify-content: space-between;
}
.call-num {
  color: #41cabf;
}
.tag {
  padding: 0 5px;
  box-sizing: border-box;
}
.icon-click {
  position: absolute;
  top: 10px;
  right: 10px;
}
.card-phone-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url('../../assets/touch-phone-icon.png') no-repeat;
  background-size: 100% 100%;
  margin-top: 3px;
  cursor: pointer;
}
.card-phone-edit-icon {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 3px;
  background: url('../../assets/keydetail_edit.svg') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.time-line {
  padding: 20px 20px 20px 100px;
  box-sizing: border-box;
  height: 500px;
}
.time-line /deep/ .el-timeline-item {
  padding-bottom: 0 !important;
}
.time-line /deep/ .el-timeline-item__node--normal {
  left: 1px !important;
  width: 8px !important;
  height: 8px !important;
}
.line-content {
  color: #8a8a8a;
  font-size: 12px;
}
.import-text {
  color: #333333 !important;
}
.line-top {
  margin-bottom: 10px;
  position: relative;
}
.line-top > span:first-child {
  transform: translateX(-70px);
  position: absolute;
}
.time-line-date {
  position: relative;
  transform: translate(-100px, -20px);
}
.play-record {
  color: #0099cc;
  cursor: pointer;
}
.from > .l-span:last-child > span:last-child {
  display: none;
}
</style>
