<template>
    <div>
        <dia-model ref="addBook"
                   :dialogStatus="dialogAddBookStatus"
                   :showCheck='showCheck'
                   :btnLoading="btnLoading"
                   @handleCheckAllChange="handleCheckAllChange"
                   @submitContBtn="addBookBtn">
            <div slot="modalCont" style="padding-bottom:20px;" >
                <p class="created-new-book" @click="addNewBook" v-dt-permission="'客户培育-线索培育-新建培育池'">新建培育池</p>
                <div class=" add-collect-select">
                    <div v-for="(item, index) in BookArr" :key="index" class="book-list-type">
                        <el-radio v-model="selectBook" :label="item.id"  @change="setIsDefault(item)">{{item.name}}</el-radio>
                        <!-- <span @click="setIsDefault(item)" class="book-type-defau" :class="item.isDefault ? 'is-default':''">{{item.isDefault ? '默认收藏夹':'设为默认'}}</span> -->
                    </div>
                </div>
                <!-- <div class="botton-body line-15 m-l-18 m-t-10 now-all-type" style="border:none;margin-left:0!important;margin-top:30px!important;">
                    <el-checkbox
                    v-model="checked"
                    @change="handleCheckAllChange"
                    >下次不再显示</el-checkbox>
                </div> -->
            </div>
        </dia-model>
        <add-book ref="addBookModel" @addBookSuccess="addBookSuccess"></add-book>
    </div>
</template>

<script>
    import diaModel from '@/components/Model'
    import addBook from '../collect/addBook'
    import { getFavoritesAdd } from '@/api/table'
    import { getMarketingLandList, postFavoritesUpdate } from '@/api/table'
    export default {
        props: {
            BookArr: {
                default: () => [],
                type: Array
            }
        },
        data() {
            return {
                dialogAddBookStatus: {},
                bookName: '',
                bookRemark: '',
                btnLoading: false,
                showName: true,
                showMark: true,
                selectBook: '',
                showCheck: true
            }
        },
        components: {
            diaModel,
            addBook
        },
        methods: {
            addNewBook() {
                this.$refs.addBookModel.init()
                },
            setInit(val) {
                this.selectBook = val
            },
            addBookSuccess() {
                this.$emit('changeBookList', this.selectBook)
            },
            closeModel() {
                this.$refs.addBook.subimtCancle()
            },
            init() {
                // this.checked = false
                this.dialogAddBookStatus = {
                    dialogWidth: '600px',
                    dialogTitle: '选择培育池',
                    showFoot: true,
                    dialogTop: '200px',
                    singleBtn: false,
                    dialogSurBtn: '确认'
                }

                this.$nextTick(() => {
                    this.BookArr.forEach(item => {
                        if (item.isDefault === 1) {
                            this.selectBook = item.id
                        }
                    })
                    this.$refs.addBook.init()
                })
            },
            handleCheckAllChange(val) {
                this.checked = val
                if (this.checked) {
                    localStorage.setItem('isShowSelectBook', 1)
                } else {
                    localStorage.setItem('isShowSelectBook', 0)
                }
            },
            setIsDefault(val) {
                if (val.isDefault === 1) {
                    return
                }
                const params = {
                    id: val.id,
                    name: val.name,
                    isDefault: 1
                }
                // this.postFavoritesUpdate(params)
            },
            postFavoritesUpdate(params) {
                postFavoritesUpdate(params).then(res => {
                    if (res.code === 60000) {
                        this.$emit('changeBookList')
                    }
                })
            },
            closeAddBook() {
                this.$refs.addBook.subimtCancle()
            },
            addBookBtn() {
                this.$emit('sureSubmitCollect', this.selectBook)

            },
            getFavoritesAdd() {
                this.btnLoading = true
                const params = {
                    name: this.bookName,
                    note: this.bookRemark
                }
                getFavoritesAdd(params).then(res => {
                    this.btnLoading = false
                    if (res.code === 60000) {
                        this.$message({
                            message: '新建成功',
                            type: 'success'
                        })
                        this.$refs.addBook.subimtCancle()
                        this.$emit('addBookSuccess')
                    }
                })
            }
        }
    }
</script>

<style scoped>
.add-collect-select {
    max-height: 200px;
    overflow-y: scroll;
    margin-left: 20px;
}
.add-collect-select::-webkit-scrollbar {
    width: 4px;
    background: white;
    border-radius: 2px;
}
.add-collect-select::-webkit-scrollbar-thumb {
    height: 30px;
    width: 4px;
    border-radius: 2px;
    background: #E4E7ED;
}
.book-list-type {
    margin-top: 10px;
}
.book-list-type span {
    display: inline-block;
    margin-bottom: 10px;
}
.created-new-book {
    cursor: pointer;
    color: #41cabf;
    margin: 20px;
}
.is-default {
    color: #C0C4CC;
}
.book-type-defau {
    float: right;
    cursor: pointer;
}
.book-list-type {
    border-bottom: 1px solid #E4E7ED;
    margin-top: 30px;
    padding-bottom: 10px;
}
</style>
