<template>
  <div class="card-common">
    <div v-if="item.title" class="g-title">
      <div class="tit">{{ item.title }}</div>
    </div>
    <div v-if="handleIsBorder(item)" :style="boxStyle" class="g-box">
      <div class="dot tl"></div>
      <div class="dot tr"></div>
      <div class="dot bl"></div>
      <div class="dot br"></div>
      <div class="edit-icon" @click="handleEdit(item)">
        <i class="el-icon-edit-outline" />
      </div>
      <slot />
    </div>

    <div v-else :style="boxStyle" class="no-border">
      <div class="edit-icon" @click="handleEdit(item)">
        <i class="el-icon-edit-outline" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardCommon',

  props: {
    item: {
      type: Object,
      default: () => {}
    },

    boxStyle: {
      type: String,
      default: ''
    }
  },

  methods: {
    handleEdit(item) {
      this.$emit('handleEdit', item)
    },

    handleIsBorder(data) {
      return data.style.border
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.card-common {
  margin-bottom: 10px;
  overflow: hidden;
  .g-title {
    height: 40px;
    padding-left: 34px;
    font-size: 16px;
    color: #fff;
    background: url(../theme/images/g-tbg.png) no-repeat left center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 8px;

    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(../theme/images/g-i1.png) no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }

  .g-box {
    position: relative;
    border: 1px solid #014274;
    padding: 5px;
    .dot {
      position: absolute;
      width: 58px;
      height: 58px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      &.tl {
        background-image: url(../theme/images/tl.png);
        top: -28px;
        left: -28px;
      }

      &.tr {
        background-image: url(../theme/images/tr.png);
        top: -28px;
        right: -28px;
      }

      &.bl {
        background-image: url(../theme/images/bl.png);
        bottom: -28px;
        left: -28px;
      }

      &.br {
        background-image: url(../theme/images/br.png);
        bottom: -28px;
        right: -28px;
      }
    }
  }

  .edit-icon {
    position: relative;
    z-index: 222;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    color: #0096ff;
  }

  .no-border {
    overflow: hidden;
  }

  .tit {
    font-size: 16px;
    color: $used-color;
    font-weight: bold;
  }

  .inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>
