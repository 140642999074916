<template>
  <div class="reset-container">
    <Header></Header>
    <div class="reser-title">{{title}}</div>
    <div class="reset-form">
      <p>{{titleDetail}}</p>
      <valid-phone ref="getCheckNext" :showCode="false" @startLoading="startLoading" @phoneCodeIsSucc="phoneCodeIsSucc"></valid-phone>
      <!-- <div class="reset-phone">
        <div class="reset-icon">+86</div> 
        <input 
           class="reset-input-phone" 
           :class="error.errorPhone ? 'input-error':''" oninput="value=value.replace(/[^\d]/g,'')" v-model="formInfo.phone" placeholder="输入手机号码" maxlength="11"/>
      </div>
      <div class="reset-code">
        <input :class="error.errorCode ? 'input-error':''" oninput="value=value.replace(/[^\d]/g,'')" v-model="formInfo.code" placeholder="验证码" maxlength="8"/>
        <div>获取验证码</div>
      </div>
      <p v-if="errorMsg" id="error">{{errorMsg}}</p> -->
      <div class="reset-bt" v-loading="loading" @click="resetBt">确认</div>
    </div>
  </div>
</template>

<script>
  import Header from '../header.vue'
  import validPhone from '@/components/Validphone'
  import {checkPhoneCode,getUserName} from '@/api/usercenter'
  export default{
    data(){
      return{
        loading:false,
        error:{
          errorPhone:false,
          errorCode:false
        },
        title: '忘记密码',
        titleDetail: '请输入您的手机验证码，以进行密码重设',
        errorMsg:"",
        formInfo:{
          phone:'',
          code:''
        }
      }
    },
    created() {
      if (this.$route.query.type === 'psw') {
        this.title = '忘记密码'
        this.titleDetail = '请输入您的手机验证码，以进行密码重设'
      } else {
        this.title = '忘记用户名'
        this.titleDetail = '请输入您的手机验证码，以进行找回用户名'
      }
    },
    components:{
      Header,
      validPhone
    },
    methods:{
      resetBt(){
        this.$refs.getCheckNext.checkPhoneGetCode('T')
      },
      startLoading(val) {
        this.loading = val
      },
      getUserName(val) {
        const params = {
          mobile: val.mobile
        }
        getUserName(params).then(res => {
          if (res.code === 60000) {
              this.$router.replace({
                name: '/backName',
                params: {
                  name: res.data.userName
                }
              })
          }
        })
      },
      phoneCodeIsSucc(params) {
        this.loading = false
        sessionStorage.setItem('getCodeNum', 59)
        sessionStorage.setItem('needPhone', params)
        if (this.$route.query.type === 'usn') {
          this.$router.replace({
            name: '/backName',
            params: {
              phone: params
            }
          })
        } else {
          this.$router.replace({
            name: '/updatePwd',
            params: {
              phone: params
            }
          })
        }
        
        return
        this.loading = true
        checkPhoneCode(params).then((res) => {
          this.loading = false
          if(res.code == 60000){
            if (this.$route.query.type === 'usn') {
              this.getUserName(params)
            } else {
              this.$router.replace({
                name: '/updatePwd',
                params: {
                  phone: params.mobile
                }
              })
            }
          }
        }).catch(() => {
          this.loading = false
        })
      }
    },
    computed:{
    }
  }
</script>

<style scoped>
  .reset-container{
    height: 100%;
    min-height: 100%;
    width: 100%;
    background-color: #F5F6F7;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
  }
  .reser-title{
    margin-top: 50px;
    font-size: 30px;
    color: #494949;
    font-weight: 600px;
  }

  .reset-form{
    display: flex;
    width: 1100px;
    height: 400px;
    min-width: 500px;
    min-height: 400px;
    margin-top: 49px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .reset-form p{
    color: #8A8A8A;
    font-size: 16px;
    font-weight: 600px;
    margin-top: 56px;
  }
  
  .reset-phone{
    display: flex;
    align-items: center;
    margin-top: 26px;
  }
  
  .reset-input-phone{
    width: 304px;
    height: 46px;
    background:none;  
    outline:none;  
    border:0px;
   /* 输入框默认边框 */
    border:1px solid #C0BEBE;
    padding:0 20px;
    color: #2E2E2E;
  }
  
  input:focus{
    /* 输入框获取焦点边框 */
    border-color:#41CABF;
  }
  
  .input-error{
    border-color: #FF0000 !important;
  }
  
  .reset-icon{
    width: 46px;
    line-height: 44px;
    border: 1px solid #C0BEBE;
    color: #8F8F8F;
  }
  
  .reset-code{
    display: flex;
    margin-top: 26px;
    width: 350px;
    justify-content: space-between;
  }
  
  .reset-code input{
    width: 170px;
    height: 46px;
    background:none;  
    outline:none;  
    border:0px;
    /* 输入框默认边框 */
    border:1px solid #C0BEBE;
    padding:0 20px;
    color: #2E2E2E;
  }
  
 .reset-code input:focus{
    border-color:#41CABF;
  }
  
  .reset-code div{
    width: 170px;
    line-height: 44px;
    /* 输入框默认边框 */
    border:1px solid #41CABF;
    padding:0 20px;
    color: #41CABF;
    font-size: 14px;
    cursor: pointer;
  }
  
  #error{
    color: #FF0000;
    margin-top: 10px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    width: 350px;
  }
  
  .reset-bt{
    width:350px;
    line-height: 46px;
    background: #41CABF;
    color: #FFFFFF;
    margin-top: 26px;
    cursor: pointer;
  }
</style>
