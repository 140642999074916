<template>
  <div class="dt-button-main" @click="click">
    <el-button
      :type="type"
      :size="size"
      :plain="plain"
      :round="round"
      :circle="circle"
      :loading="loading"
      :disabled="disabled"
      :icon="icon"
      :autofocus="autofocus"
      :native-type="nativeType"
      :key="key"
    >
      <slot />
    </el-button>
  </div>
</template>

<script>
import { throttle } from 'lodash'
export default {
  name: 'dt-button',
  data() {
    return {
      key: 0
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small'
    },
    plain: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    round: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: '-'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    nativeType: {
      type: String,
      default: 'button'
    }
  },
  created() {
    this.click = throttle(
      () => {
        this.btnClick()
      },
      500,
      { trailing: false }
    )
  },
  methods: {
    btnClick() {
      this.$emit('dtclick')
      this.key++
    }
  }
}
</script>

<style scoped></style>
