<template>
  <div style="padding-bottom: 50px;box-sizing: border-box;">
    <div class="line-body m-t-10 oace-key-table" style="padding: 1px 5px;">
      <el-table
        :data="listinfo"
        border
        v-loading="getListLoading"
        ref="myCollectSelect"
        @selection-change="selectionChange"
        empty-text="暂无数据"
        style="width: 100%; font-size: 12px;margin-top:10px;"
        v-dt-permission="'微营销管理-落地页管理-查看落地页'"
      >
        <el-table-column
          prop="formName"
          show-overflow-tooltip
          label="落地页名称"
          width="350"
        >
          <template slot-scope="scope">
            <span
              class="form-name1"
              @click="userclickInfo(scope.row, scope.column)"
              >{{ scope.row.formName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="clickNum"
          show-overflow-tooltip
          label="点击数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="uvNum"
          show-overflow-tooltip
          label="独立访客数"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="forwardNum"
          show-overflow-tooltip
          label="转发数"
          align="center"
        />
        <el-table-column
          prop="leaveNum"
          show-overflow-tooltip
          label="留资数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createdBy"
          show-overflow-tooltip
          label="创建人"
          align="center"
        />
        <el-table-column
          show-overflow-tooltip
          label="全公司可见"
          width="100"
          align="center"
          v-if="permissionList.includes('微营销管理-落地页管理-设置可见性')"
        >
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.isOpen === 1"
              @change="handleShow(scope.row)"
              active-color="#78d9d1"
              :disabled="createBtnStyle(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="action"
          width="400"
          show-overflow-tooltip
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <div class="remove-i">
              <span
                @click="userclickInfo(scope.row, scope.column)"
                class="btn-list"
                v-dt-permission="'微营销管理-落地页管理-留资信息'"
                >留资信息</span
              >
              <span v-dt-permission="'微营销管理-落地页管理-留资信息'">|</span>
              <span
                @click="cellClick(scope.row)"
                class="btn-list"
                v-if="scope.row.userId === userId || isAdmin"
                v-dt-permission="'微营销管理-落地页管理-编辑'"
                >编辑</span
              >
              <span
                v-dt-permission="'微营销管理-落地页管理-编辑'"
                v-if="scope.row.userId === userId || isAdmin"
                >|</span
              >
              <span
                class="btn-list"
                @click="prevwLandPage(scope.row)"
                v-dt-permission="'微营销管理-落地页管理-预览'"
                >预览</span
              >
              <span v-dt-permission="'微营销管理-落地页管理-预览'">|</span>
              <span
                class="btn-list"
                @click="publicLandPage(scope.row)"
                v-dt-permission="'微营销管理-落地页管理-查看链接'"
                >查看链接</span
              >
              <span v-dt-permission="'微营销管理-落地页管理-查看链接'">|</span>
              <span
                class="btn-list"
                @click="viewRecordClick(scope.row)"
                v-dt-permission="'微营销管理-落地页管理-客户雷达'"
                >客户雷达</span
              >
              <span v-dt-permission="'微营销管理-落地页管理-客户雷达'">|</span>
              <span
                class="btn-list"
                @click="copyLandPage(scope.row)"
                v-dt-permission="'微营销管理-落地页管理-复制'"
                >复制</span
              >
              <span v-dt-permission="'微营销管理-落地页管理-复制'">|</span>
              <span
                class="btn-list"
                @click="delLandPage(scope.row)"
                v-dt-permission="'微营销管理-落地页管理-删除'"
                v-if="scope.row.userId === userId || isAdmin"
                >删除</span
              >
              <span
                v-dt-permission="'微营销管理-落地页管理-删除'"
                v-if="scope.row.userId === userId || isAdmin"
                >|</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <dia-model
      ref="deleteMark"
      :dialogStatus="dialogStatus"
      @submitContBtn="submitContBtn"
    >
      <div slot="modalCont" style="margin: 20px 0;" v-if="currentType === '1'">
        该落地页相关联的所有历史数据都都将被删除，是否确认？
      </div>
      <div slot="modalCont" style="margin: 20px 0;" v-if="currentType === '2'">
        <el-input
          v-model="resetName"
          placeholder="输入表单名称"
          class="input"
        ></el-input>
      </div>
      <div slot="modalCont" style="margin: 20px 0;" v-if="currentType === '3'">
        <el-input
          v-model="resetName"
          placeholder="输入表单名称"
          class="input"
        ></el-input>
      </div>
      <div slot="modalCont" style="margin: 20px 0;" v-if="currentType === '4'">
        确认发布？
      </div>
    </dia-model>
    <qc-code ref="userQcCode" :acCodeUrl="acCodeUrl"></qc-code>
    <land-prevw
      ref="userPrevw"
      :currentId="currentId"
      :formHtml="formHtml"
    ></land-prevw>
    <el-drawer
      class="xxx"
      size="700px"
      title="客户雷达"
      :visible.sync="viewRecord"
      :destroy-on-close="true"
      :with-header="false"
    >
      <view-record :view-id="viewId" />
    </el-drawer>
    <div
      class="page-div page"
      v-if="pageStatus.total"
      v-dt-permission="'微营销管理-落地页管理-查看落地页'"
    >
      <div style="float:right;">
        <footer-page
          @handleCurrentChange="handleCurrentChange"
          @goNumPage="goNumPage"
          @changePageSize="changePageSize"
          :pageStatus="pageStatus"
        ></footer-page>
      </div>
    </div>
  </div>
</template>

<script>
import viewRecord from '@/views/landpage/viewRecord'
import footerPage from '@/components/Footerpage/index'
import {
  getMarketingLandList,
  getPreview,
  enableView,
  delMarketLandPage,
  postMarketLandpageCopy,
  putMarketPageRename,
  postMarketingPublish,
  getMarketLandPageUpdate,
  getMarketlandPageDetail
} from '@/api/table'
import diaModel from '@/components/Model'
import LandPrevw from './landprevw'
import { Base64 } from 'js-base64'
import QcCode from './qccode'

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      listinfo: [],
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: null
      },
      userId: '',
      formHtml: '',
      getListLoading: false,
      isIndeterminate: false,
      dialogStatus: {},
      currentType: '1',
      resetName: '',
      currentTable: {},
      pageDetail: {},
      acCodeUrl: '',
      currentId: 0,
      checked1: false,
      fontStyle: {},
      viewRecord: false, //显示客户雷达
      viewId: null
    }
  },
  created() {
    const { id } = this.$store.getters.accountInfo
    this.userId = id
    this.getMarketingLandList()
  },
  components: {
    footerPage,
    diaModel,
    QcCode,
    LandPrevw,
    viewRecord
  },

  computed: {
    permissionList: {
      get() {
        return this.$store.state.permission.permission
      },
      set() {
        return
      }
    },

    isAdmin() {
      return this.$store.state.user.isAdmin
    },
    user() {
      return this.$store.state.user.accountInfo
    }
  },

  methods: {
    createBtnStyle(row) {
      if (this.isAdmin) return false
      if (row.userId === this.userId) return false
      return true
    },
    async handleShow(row) {
      let result = await enableView({
        packageId: row.id,
        isOpen: row.isOpen === 0 ? 1 : 0
      })
      if (result.code === 60000) {
        this.$message({
          message: result.msg,
          type: 'success'
        })
      }
      this.getMarketingLandList()
    },
    cellClick(val) {
      localStorage.setItem('PageId', val.id)
      this.$router.push('/newlandpage/newlandpage')
    },
    userclickInfo(row) {
      localStorage.setItem('PageId', row.id)
      sessionStorage.setItem('pageName', row.formName)
      this.$router.push('/userstay/userstay')
    },

    deserveCont(val) {
      return val.replace(/\n/g, '<br>')
    },
    async prevwLandPage(val) {
      let result = await getPreview({
        packageId: val.id
      })
      if (result.code === 60000) {
        this.currentTable = val
        this.currentId = val.id
        this.formHtml = result.data.form_html
        this.$refs.userPrevw.init(val)
      }
    },
    async copyLandPage(val) {
      const data = new Date()
      this.resetName =
        val.formName +
        '_' +
        data.getFullYear() +
        (data.getMonth() + 1) +
        data.getDate()
      this.currentTable = val
      this.currentType = '3'
      let result = await getPreview({
        packageId: val.id
      })
      const formParam = JSON.parse(result.data.form_param)
      this.fontStyle = Object.assign(
        {},
        { share: { title: '', desc: '', img: '' } },
        formParam
      )
      this.fontStyle.share.title = this.resetName
      const { length } = val.formName
      const title =
        length > 15 ? `${val.formName.slice(0, 15)}...` : val.formName
      this.dialogStatus = {
        dialogWidth: '350px',
        dialogTitle: `复制表单 -- ${title}`,
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$refs.deleteMark.init()
    },

    //客户雷达
    viewRecordClick(val) {
      if ((val && val.id) || val.id === 0) {
        this.viewId = val.id
        this.viewRecord = true
        return
      }
      this.$message({
        message: '参数错误',
        type: 'error',
        duration: 800
      })
    },

    publicLandPage(val) {
      this.currentTable = val
      this.currentType = '4'
      this.dialogStatus = {
        dialogWidth: '350px',
        dialogTitle: `发布 -- ${val.formName}`,
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.getMarketlandPageDetail()
    },
    getMarketlandPageDetail() {
      const params = {
        id: this.currentTable.id
      }
      getMarketlandPageDetail(params).then(res => {
        console.log(res)
        if (res.code === 60000) {
          this.fontStyle = JSON.parse(res.data.formParam)
          this.$refs.deleteMark.subimtCancle()
          const pageObj = this.user.lanSite
          const pageUrl = pageObj.pageUrl
          const siteId = pageObj.id
          const landPageId = res.data.id
          const userId = this.user.id
          this.acCodeUrl = `${pageUrl}?siteId=${siteId}&userId=${userId}&landPageId=${landPageId}`
          console.log(this.acCodeUrl)
          this.$refs.userQcCode.init()
        }
      })
    },
    getWxCode() {
      return () => {
        const baseUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?'
        const appid = 'wx947ce8f2ed9ad3fc'
        const path = location.origin + location.pathname
        function getQueryVariable(variable) {
          var query = window.location.search.substring(1)
          var vars = query.split('&')
          for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=')
            if (pair[0] == variable) {
              return pair[1]
            }
          }
          return false
        }
        //设置cookie
        function setCookie(c_name, value, expiredays) {
          var exdate = new Date()
          exdate.setDate(exdate.getDate() + expiredays)
          document.cookie =
            c_name +
            '=' +
            escape(value) +
            (expiredays == null ? '' : ';expires=' + exdate.toGMTString())
        }

        //取回cookie
        function getCookie(c_name) {
          if (document.cookie.length > 0) {
            c_start = document.cookie.indexOf(c_name + '=')
            if (c_start != -1) {
              c_start = c_start + c_name.length + 1
              c_end = document.cookie.indexOf(';', c_start)
              if (c_end == -1) c_end = document.cookie.length
              return unescape(document.cookie.substring(c_start, c_end))
            }
          }
          return false
        }
        function clearCookie() {
          document.cookie =
            'originOpenid=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
        }
        if (!getQueryVariable('code')) {
          localStorage.clear()
          const res_type = 'response_type=code'
          const scope = 'scope=snsapi_userinfo&state=STATE#wechat_redirect'
          if (getQueryVariable('userId')) {
            localStorage.setItem('userId', getQueryVariable('userId'))
          }
          if (getQueryVariable('companyUuid')) {
            let companyUuid = getQueryVariable('companyUuid')
            let landPageId = getQueryVariable('landPageId')
            let userId = getQueryVariable('userId')
            localStorage.setItem('companyUuid', companyUuid)
            localStorage.setItem('landPageId', landPageId)
            localStorage.setItem('marketId', getQueryVariable('marketId') || '')
            shareKey = `&companyUuid=${companyUuid}&landPageId=${landPageId}&userId=${userId}`
          }
          if (getQueryVariable('originOpenid')) {
            localStorage.setItem(
              'originOpenid',
              getQueryVariable('originOpenid')
            )
          }
          if (getQueryVariable('originOpenid')) {
            originOpenid = `&originOpenid=${getQueryVariable('originOpenid')}`
          }

          const currPath = `${baseUrl}&appid=${appid}&redirect_uri=${encodeURIComponent(
            path
          )}&${res_type}&${scope}`
          window.location.href = currPath
        } else {
          const code = getQueryVariable('code')
          localStorage.setItem('code', code)
          localStorage.setItem('userId', code)
          return {
            appid,
            code,
            getCookie,
            getQueryVariable
          }
        }
      }
    },
    getMarketLandPageUpdate(val) {
      const base = Base64.encode(val)
      const param = new FormData()
      param.append('id', this.currentTable.id)
      param.append('param', JSON.stringify(this.fontStyle))
      param.append('page', base)
      const params = {
        id: this.currentTable.id,
        param: JSON.stringify(this.fontStyle),
        page: base
      }
      getMarketLandPageUpdate(params).then(res => {
        if (res.code === 60000) {
          this.getMarketingLandList()
        }
      })
    },
    growHtml() {
      const Style =
        '<style>' +
        '.page-cont-title {width: 100%;}' +
        '.page-cont-title p {text-align: center;font-size: 18px;font-weight: bold;margin: 10px;color:black;}' +
        '.page-img-upload {display: block;width: 100%;}' +
        '.page-submit-btn p {text-align: center;color: white;line-height: 30px;}' +
        '.input-txt-list span {display: inline-block;width: 60px;font-size: 14px;}' +
        '.input-txt-list input {outline: none;font-size: 12px;height: 25px;width: 200px;border: none;padding-left: 20px;}' +
        '.input-txt-list {height: 45px;line-height: 45px;}' +
        '.page-show-cont {width: 100%;margin: 0 auto;border-radius: 5px;background: white;}' +
        '.page-cont-cont {margin: 0 auto;background: white;}' +
        '.page-img-cont {width: 100%;background: white;}' +
        '.page-content-txt {width: 100%;padding: 30px 10px;background: #D8D8D8;box-sizing: border-box;}' +
        '.page-input-txt {width: 100%;padding: 30px 10px;background: #D8D8D8;box-sizing: border-box;}' +
        '.page-submit-btn {width: 100%;height: 30px;background: #33CB98;cursor: pointer;}' +
        '#page-have-done {width: 100%;display:none;background:#D8D8D8; height: 30px;line-height: 30px;color:white;text-align:center;}' +
        'body {margin: 0}' +
        'label {width: 70px;text-align: right;margin-right: 5px}' +
        "label::before {content: '*';color: #f56c6c;margin-right: 4px}" +
        '</style>'
      const HeadHtml = `
        <!DOCTYPE html>
        <html>
          <head>
          <title>${this.fontStyle.share.title}<\/title>
          <meta charset='utf-8'>
          <link rel='shortcut icon' href='https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/favicon.ico' \/>
          <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, height=device-height'>
          <meta name='description' content="${this.fontStyle.share.desc}">
          <meta property="og:title" content="${this.fontStyle.share.title}">
          ${Style}
          <script src='http://res.wx.qq.com/open/js/jweixin-1.6.0.js'><\/script>
          <script src='https://cdn.bootcdn.net/ajax/libs/jquery/3.5.1/jquery.min.js'><\/script>
          <\/head>
      `
      const video =
        this.fontStyle.show['video'] && this.fontStyle.videoUrl !== ''
          ? "<div class='page-img-cont'>" +
            "<video class='page-img-upload' src='" +
            this.fontStyle.videoUrl +
            "' alt='' controls='controls' x5-video-player-type='h5' x5-video-player-fullscreen='true' webkit-playsinline='true' x-webkit-airplay='true' playsinline='true' x5-playsinline>" +
            '</div>'
          : ''
      const photo =
        this.fontStyle.show['photo'] && this.fontStyle.uploadUrl !== ''
          ? '' +
            "<img class='page-img-upload' src='" +
            this.fontStyle.uploadUrl +
            "' alt=''>" +
            ''
          : ''
      const contentText = this.fontStyle.show['content']
        ? "<div class='page-content-txt' style='background:" +
          this.fontStyle.textBg +
          "'>" +
          "<span style='color:" +
          this.fontStyle.fontColor +
          ';' +
          'font-weight:' +
          this.fontStyle.fontWeight +
          ';' +
          'font-size:' +
          this.fontStyle.fontSize +
          'px;' +
          'font-family:' +
          this.fontStyle.fontFamily +
          ';' +
          "'>" +
          this.deserveCont(this.fontStyle.textareaCont) +
          '</span>' +
          '</div>'
        : ''
      const checks =
        this.fontStyle.show['input'] && this.fontStyle.checkedArr.length > 0
          ? "<div class='page-input-txt' style='background:" +
            this.fontStyle.pagraBg +
            "'>" +
            "<div class='input-txt-list' style='display:" +
            (this.fontStyle.checkedArr.indexOf('name') > -1
              ? 'block;'
              : 'none;') +
            "'>" +
            "<label style='display: inline-block'>姓名</label>" +
            "<input id='input-name' type='text'>" +
            '</div>' +
            "<div class='input-txt-list' style='display:" +
            (this.fontStyle.checkedArr.indexOf('phone') > -1
              ? 'block;'
              : 'none;') +
            "'>" +
            "<label style='display: inline-block'>手机号</label>" +
            "<input id='input-phone' type='text'>" +
            '</div>' +
            "<div class='input-txt-list' style='display:" +
            (this.fontStyle.checkedArr.indexOf('company') > -1
              ? 'block;'
              : 'none;') +
            "'>" +
            "<label style='display: inline-block'>公司</label>" +
            "<input id='input-company' type='text'>" +
            '</div>' +
            '</div>'
          : ''
      const button =
        this.fontStyle.show['button'] && this.fontStyle.checkedArr.length > 0
          ? "<div class='page-submit-btn' id='page-no-done2'>" +
            '<p>' +
            this.fontStyle.buttonText +
            '</p>' +
            '</div>'
          : ''
      const BodyHtml =
        '<body>' +
        "<div class='page-show-cont'>" +
        "<div class='page-cont-cont'>" +
        video +
        photo +
        contentText +
        checks +
        button +
        "<div id='page-have-done'>" +
        '<p>提交成功</p>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</body>'
      const Script = `<script>
        const getWxCode = ${this.getWxCode()};
        let getValue = getWxCode();
        let appid,code,getCookie,getQueryVariable;
        if(getValue) {
          appid = getValue.appid;
          code = getValue.code;
        }
        let landPageId = localStorage.getItem('landPageId');
        let companyUuid = localStorage.getItem('companyUuid') || ''
        let userId = localStorage.getItem('userId');
        console.log(userId)
        let marketId = '';
        let openId = '';
        let saveId = '';
        const token = 'xP!!Szlw9BuRIh#fUjBpnMj*G5D%H@m!';
        function getWxConfig(data,appId) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature,// 必填，签名
            jsApiList: ['updateAppMessageShareData','checkJsApi','onMenuShareAppMessage','updateTimelineShareData','onMenuShareTimeline'] // 必填，需要使用的JS接口列表
          });
          wx.ready(() => {
             let canUse = false
              wx.checkJsApi({
                jsApiList: ['updateAppMessageShareData','onMenuShareAppMessage','updateTimelineShareData','onMenuShareTimeline'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                success: function(res) {
                  const {checkResult} = res;
                  const {updateAppMessageShareData} = checkResult
                  canUse = updateAppMessageShareData
                }
              })
              let shareKey = ''
              const userKey = '&userId=' + localStorage.getItem('userId');
              if(localStorage.getItem('companyUuid')) {
                const companyUuidKey = '&companyUuid=' + localStorage.getItem('companyUuid');
                const landPageKey = '&landPageId=' + localStorage.getItem('landPageId');
                shareKey = companyUuidKey + userKey + landPageKey
              }
              const shareOpenId = '&originOpenid='+data.openId
              const desc = "${this.fontStyle.share.desc.replace(/[\r\n]/g, '')}"
              const link = location.origin + location.pathname + '?' + shareOpenId +  shareKey
              const title = "${this.fontStyle.share.title}";
              const imgUrl = "${this.fontStyle.share.img}";
              if(canUse) {
                wx.updateAppMessageShareData({
                  title, // 分享标题
                  desc, // 分享描述
                  imgUrl,
                  link
                })
                wx.updateTimelineShareData({
                  title, // 分享标题
                  desc, // 分享描述
                  imgUrl,
                  link
                })
              } else {
                wx.onMenuShareAppMessage({   //即将废弃
                  title, // 分享标题
                  desc, // 分享描述
                  imgUrl,
                  link
                });
                wx.onMenuShareTimeline({
                  title, // 分享标题
                  desc, // 分享描述
                  imgUrl,
                  link
                })
              }

          })
        }
        function handleShare() {
          if(localStorage.getItem('originOpenid')) {
            console.log('openId',openId)
            $.ajax({
              url: '${this.baseUrl}/api/auth/open/marketing/forward',
              type: 'post',
              dataType: 'text',
               headers: {
                'X-DT-Key': token
              },
              data: {
                originOpenid:localStorage.getItem('originOpenid'),
                currentOpenid:openId,
                companyUuid: companyUuid,
                userId: userId ? Number.parseInt(userId) : '',
                landPageId: localStorage.getItem('landPageId') || ${
                  this.currentTable.id
                }
              },
              success: function(res) {
                console.log('转发成功',res)
              },
              error: function(err) {
                console.log('转发失败',err)
              }
            })
          }
        };
        function getWxId(code) {
          $.ajax({
            url: "${this.baseUrl}/api/auth/open/marketing/save/code",
            type: "post",
            dataType: "text",
            headers: {
              'X-DT-Key': token
            },
            data: {
              code,
              url: encodeURIComponent(location.href.split("#")[0]),
              companyUuid: companyUuid,
              userId: userId ? Number.parseInt(userId) : '',
              landPageId: landPageId ? Number.parseInt(landPageId) : ${
                this.currentTable.id
              }
            },
            success: function(res) {
              const result = JSON.parse(res);
              const {data,code} = result;
              if(code === 60000) {
                openId = data.openId;
                saveId = data.id;
                getWxConfig(data,appid)
                handleShare(marketId)
              }
            },
            error: function(err) {
              console.log(err);
            }
          });
        }
        let stayTime = 0;   //停留时间
        let playTime = 0;   //播放视频时间
        function postTime(async) {
          localStorage.setItem('isOut', true)
          $.ajax({
            type: 'post',
            url: '${this.baseUrl}/api/marketing/feedback/save/view',
            dataType: 'text',
            headers: {
              'X-DT-Key': token
            },
            async,
            data: {
              openid: openId,
              landPageId: landPageId ? Number.parseInt(landPageId) : ${
                this.pageId
              },
              companyUuid: companyUuid,
              userId: userId ? Number.parseInt(userId) : '',
              pageStay: stayTime,
              viewStay: playTime
            },
            success: function(result) {
              localStorage.setItem('result', result)
            },
            error: function(err) {
              localStorage.setItem('err', JSON.stringify(err))
            }
          });
        }
        function saveTime() {
          let id = setInterval(() => {
           stayTime += 1000
          },1000)
          let playInterId = null;
          let video = $('.video').get(0);
          if(video) {
            video.onplay = function() {
              playInterId = setInterval(() => {
                playTime += 1000
              },1000)
            }
            video.onpause = function() {
              clearInterval(playInterId)
            }
          }
          if(navigator.userAgent.match('iPhone')) {
            window.addEventListener('pagehide', function () {
              clearInterval(id);
              clearInterval(playInterId);
              postTime(false);
            })
          } else {
            window.onunload = function() {
              localStorage.setItem('onunload', "onunload")
              clearInterval(id);
              clearInterval(playInterId);
              postTime(false);
            }
          }
        }
        if(code) {

          getWxId(code);
          saveTime();
        }
        function feedbackClick() {
          const click = localStorage.getItem('noFirstClick');
          if (click == ${this.currentTable.id}) return;
          const url = '${
            process.env.VUE_APP_BASE_API
          }/api/marketing/feedback/click';
          $.ajax({type: 'GET',contentType: 'application/json;charset=UTF-8',url: url,data: {id: ${
            this.currentTable.id
          }}
            ,success: function(result) {
              localStorage.setItem('noFirstClick', ${this.currentTable.id})
            }
          })
        };
        // feedbackClick();
        function feedbackLeave() {
           const click = localStorage.getItem('haveFeedLeave');
           const name = $('#input-name').val();
           const phone = $('#input-phone').val();
           const company = $('#input-company').val();
           ${
             this.fontStyle.checkedArr.includes('name')
               ? "if (!name) { alert('请输入姓名'); return };"
               : ''
           }
           ${
             this.fontStyle.checkedArr.includes('phone')
               ? "if (!phone) { alert('请输入手机'); return };"
               : ''
           }
           ${
             this.fontStyle.checkedArr.includes('company')
               ? "if (!company) { alert('请输入公司'); return };"
               : ''
           }
           if (click == ${
             this.currentTable.id
           }) { alert('不能重复提交'); return };
           const url = '${
             //  process.env.VUE_APP_BASE_API
             this.baseUrl
           }/api/marketing/feedback/leave';
           $.ajax({
             type: 'post',
             dataType: 'text',
             headers: {
              'X-DT-Key': token
             },
             url: url,
             data: {
              openid: openId,
              contact: $('#input-name').val() || '',
              phone: $('#input-phone').val() || '',
              company: $('#input-company').val() || '',
              companyUuid: companyUuid,
              userId: userId ? Number.parseInt(userId) : '',
              landPageId: landPageId ? Number.parseInt(landPageId) : ${
                this.currentTable.id
              }
             },
             success: function(result) {
              $('#page-no-done1').css('display','none');
              $('#page-no-done2').css('display','none');
              $('#page-have-done').css('display','block');
              alert('提交成功');
              localStorage.setItem('haveFeedLeave', ${this.currentTable.id});
             }});
           };
           $('.page-submit-btn').click(function(){feedbackLeave()});
           const click = localStorage.getItem('haveFeedLeave');
           if (click == ${this.currentTable.id} ) {
             $('#page-no-done1').css('display','none');
             $('#page-no-done2').css('display','none');
             $('#page-have-done').css('display','block');
           } else {
             $('#page-no-done1').css('display','block');
             $('#page-no-done2').css('display','block');
             $('#page-have-done').css('display','none');
           };
        <\/script>
      `

      const FooterHmtl = '</html>'
      return HeadHtml + BodyHtml + Script + FooterHmtl
    },
    postMarketingPublish(val) {
      const base = Base64.encode(val.formHtml)
      const param = new FormData()
      param.append('id', this.currentTable.id)
      param.append('name', this.currentTable.formName)
      param.append('param', JSON.stringify(this.fontStyle))
      param.append('page', base)
      postMarketingPublish(param).then(res => {
        if (res.code === 60000) {
          this.$refs.deleteMark.subimtCancle()
          this.acCodeUrl = res.data.pageUrl
          this.$refs.userQcCode.init()
        }
      })
    },
    resetLandName(val) {
      const formParam = this.listinfo.find(v => v.id === val.id).formParam
      this.fontStyle = JSON.parse(formParam)
      this.resetName = val.formName
      this.currentTable = val
      this.currentType = '2'
      this.dialogStatus = {
        dialogWidth: '350px',
        dialogTitle: `重命名 -- ${val.formName}`,
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.deleteMark.init()
      })
    },
    duodeBtn() {
      if (this.checked1) {
        this.listinfo.forEach((item, index) => {
          this.$refs.myCollectSelect.toggleRowSelection(item, true)
        })
      } else {
        this.listinfo.forEach((item, index) => {
          this.$refs.myCollectSelect.toggleRowSelection(item, false)
        })
      }
    },
    resetLandPage(val) {
      localStorage.setItem('PageId', val.id)
      this.$router.push('/newlandpage/newlandpage')
    },
    delLandPage(val) {
      if (val.userId === this.userId || this.isAdmin) {
        this.currentTable = val
        this.openSubmit(val)
      }
    },
    openSubmit(val) {
      this.currentTable = val
      this.currentType = '1'
      const { length } = val.formName
      const title =
        length > 15 ? `${val.formName.slice(0, 15)}...` : val.formName
      this.dialogStatus = {
        dialogWidth: '450px',
        dialogTitle: `删除 -- ${title}`,
        dialogTop: '250px',
        delModel: true,
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.deleteMark.init()
      })
    },
    submitContBtn() {
      if (this.currentType === '1') {
        this.delMarketLandPage()
      } else if (this.currentType === '2') {
        if (!this.resetName) {
          this.$message({
            type: 'warning',
            message: '名称不能为空'
          })
          return
        }
        this.putMarketPageRename()
      } else if (this.currentType === '3') {
        if (!this.resetName) {
          this.$message({
            type: 'warning',
            message: '名称不能为空'
          })
          return
        }
        this.postMarketLandpageCopy()
      }
    },
    postMarketLandpageCopy() {
      const page = Base64.encode(this.growHtml())
      const param = new FormData()
      param.append('name', this.resetName)
      param.append('id', this.currentTable.id)
      param.append('page', page)
      postMarketLandpageCopy(param).then(res => {
        if (res.code === 60000) {
          this.$refs.deleteMark.subimtCancle()
          this.currentTable = res.data
          this.fontStyle = JSON.parse(res.data.formParam)
          this.getMarketingLandList()
          this.$message({
            type: 'success',
            message: '复制成功'
          })
        }
      })
    },
    putMarketPageRename() {
      const page = Base64.encode(this.growHtml())
      const params = {
        id: this.currentTable.id,
        name: this.resetName,
        page
      }
      putMarketPageRename(params).then(res => {
        if (res.code === 60000) {
          this.$refs.deleteMark.subimtCancle()
          this.$message({
            type: 'success',
            message: '重命名成功'
          })
          this.getMarketingLandList()
        }
      })
    },
    delMarketLandPage() {
      const params = {
        id: this.currentTable.id
      }
      delMarketLandPage(params).then(res => {
        if (res.code === 60000) {
          this.$refs.deleteMark.subimtCancle()
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.getMarketingLandList()
        }
      })
    },
    getMarketingLandList() {
      this.getListLoading = true
      const params = {
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize
      }
      getMarketingLandList(params).then(res => {
        this.getListLoading = false
        if (res.code === 60000) {
          this.listinfo = res.data.list
          this.pageStatus.pageNo = Number(res.data.page.pageNo)
          this.pageStatus.pageSize = Number(res.data.page.pageSize)
          this.pageStatus.total = Number(res.data.page.total)
        }
      })
    },
    // 选择表格
    selectionChange(val, $event) {
      this.currentSelectData = val
      if (val.length === this.listinfo.length) {
        this.isIndeterminate = false
        this.checked1 = true
        return
      }
      if (val.length && val) {
        if (val.length < this.listinfo.length) {
          this.isIndeterminate = true
          this.checked1 = false
          return
        }
      } else {
        this.isIndeterminate = false
        this.checked1 = false
      }
    },
    // 跳转页数
    goNumPage(val) {
      this.pageStatus.pageNo = Number(val)
      this.getMarketingLandList()
    },
    // 改变页数大小
    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.getMarketingLandList()
    },
    changePageSize(val) {
      this.pageStatus.pageSize = val
      this.getMarketingLandList()
    }
  }
}
</script>
<style>
/* 解决element-ui的table表格控件表头与内容列不对齐问题 */

.el-table th.gutter {
  display: table-cell !important;
}
</style>
<style scoped>
@import '../../styles/threadpx.scss';
.el-table th.gutter {
  display: table-cell !important;
}
.detail-title {
  width: 100%;
  min-width: 1200px;
  height: 50px;
  background-color: #ffffff;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  line-height: 50px;
  padding-left: 21px;
  padding-right: 20px;
  color: #8a8a8a;
  margin-bottom: 4px;
}
.form-name {
  cursor: pointer;
  color: #0099cc;
}
.btn_title_upload span {
  display: inline-block;
  margin-left: 1px;
}
.down_load_demo {
  position: absolute;
  width: 150px;
  top: 0;
  right: 0;
}
.down_load_title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.down_load_xlsx {
  margin: 20px 0;
  text-align: center;
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.down_load_txt {
  margin: 20px 0;
  text-align: center;
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.upload_cont_out {
  position: relative;
}
.upload_cont_out ::v-deep .el-upload-dragger {
  border: 1px dashed rgba(65, 202, 191, 1) !important;
}
.show_call_time {
  font-size: 20px;
  color: black;
  margin-top: 20px;
}
.show_call_tip {
  font-size: 16px;
  color: red;
}
.el-table ::v-deep .el-table__empty-block {
  height: 410px !important;
  position: relative;
}
.el-table ::v-deep .el-table__body-wrapper {
  min-height: 440px !important;
}
.el-table ::v-deep .el-table__body td {
  padding: 0 !important;
  height: 41px;
}
.mark-show-elp {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.now-all-type ::v-deep .el-checkbox__label {
  font-size: 12px !important;
  color: #78d9d1 !important;
}
.call-change-status {
  position: absolute;
  padding: 10px 14px 15px 12px;
  border: 1px solid #0099cc;
  height: 80px;
  background: white;
  width: 180px;
  top: 50px;
  z-index: 2000;
}
.check-change-mark {
  position: absolute;
  padding: 10px 14px 15px 12px;
  border: 1px solid #0099cc;
  height: 120px;
  background: white;
  width: 200px;
  top: 50px;
  z-index: 2000;
}
.check-change-mark p {
  font-size: 12px;
  color: #8a8a8a;
  height: 18px;
  line-height: 18px;
}
.call-change-status p {
  font-size: 12px;
  color: #8a8a8a;
  height: 18px;
  line-height: 18px;
}
.line-body-nodata {
  display: inline-block;
  width: 50px;
  height: 58px;
  background: url('../../assets/no-data.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 170px;
  left: 48%;
  z-index: 1;
}
.line-body {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.hover-go-detail {
  cursor: pointer;
  display: inline-block;
  height: 100%;
}
.grasp_phone {
  display: inline-block;
  font-size: 10px;
  color: white;
  background: #0099cc;
  height: 20px;
  width: 40px;
  line-height: 20px;
  border-radius: 4px;
  text-align: center;
  margin-left: 4px;
  cursor: pointer;
}
.show_grasp_text {
  font-size: 14px;
  color: #8f8f8f;
  height: 60px;
  padding-top: 10px;
}
.grasp_go_pay {
  height: 68px;
  border-top: 1px solid #d8d8d8;
  line-height: 68px;
}
.grasp_go_pay span {
  display: inline-block;
  width: 70px;
  height: 34px;
  background: rgba(120, 217, 209, 1);
  border-radius: 4px;
  line-height: 34px;
  margin-left: 27px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.btn-list {
  cursor: pointer;
  color: #0099cc;
}
.btn-list.disable-btn-list {
  color: #8f8f8f;
}
.oace-key-table ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
.xxx ::v-deep .el-drawer__body {
  overflow-y: auto;
}
.remove-i span:last-child {
  display: none;
}
.page {
  position: fixed;
  bottom: 10px;
  right: 25px;
  z-index: 99;
  background-color: #fff;
  padding: 0 !important;
  box-sizing: border-box;
}
</style>
