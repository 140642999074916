var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "proxy-create-main" }, [
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "tip" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v("\n        1、"),
          _c("span", { on: { click: _vm.copy } }, [_vm._v("点击复制")]),
          _vm._v("专属链接，发给代理商\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text" }, [
          _vm._v("\n        2、代理商通过此链接完成注册\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text" }, [
          _vm._v("\n        3、向代理商分配线索及资源\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          staticClass: "link",
          on: { click: _vm.copy },
        },
        [_vm._v("\n      " + _vm._s(_vm.shareUrl) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              attrs: { size: "small" },
              on: { click: _vm.cancelBtn },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }