<template>
  <el-scrollbar style="height: calc( 100vh - 180px )" class="hide-x">
    <div class="page-show-edit">
      <div class="info-share">
        <div class="item-share">
          <label>头图</label>
          <el-upload
            action
            accept=".jpg, .png, .jpeg"
            :before-upload="saveFile('thumbnail')"
            :show-file-list="false"
            :http-request="uploadThumbnailToOss"
            :auto-upload="true"
          >
            <div
              slot="trigger"
              class="upload-share"
              v-if="htmlStyle.share['img'] === ''"
            >
              +
            </div>
            <div slot="trigger" v-else class="img-upload-share">
              <img :src="htmlStyle.share['img']" />
            </div>
          </el-upload>
          <span
            style="font-size: 13px; color: rgb(170, 170, 170);margin-left: 10px"
            >(大小限制500kb)</span
          >
        </div>
        <div class="item-share">
          <label class="must-item">标题</label>
          <el-input
            v-model="htmlStyle.share['title']"
            placeholder="请输入落地页标题"
            maxlength="50"
            show-word-limit
            @input="changeShareTitle"
          />
        </div>
        <div class="item-share">
          <label>简介</label>
          <el-input
            type="textarea"
            v-model="htmlStyle.share['desc']"
            maxlength="120"
            :rows="3"
            placeholder="请输入落地页简介"
            show-word-limit
            @input="handleChange"
          />
        </div>
      </div>
      <div style="display: flex;align-items: center" class="page-edit-title">
        <el-checkbox v-model="htmlStyle.show['video']" @change="changeShow"
          >视频</el-checkbox
        >
      </div>
      <div>
        <el-upload
          class="upload-demo"
          ref="upload"
          action
          accept=".mp4, .mov, .avi, .flv, .wmv, .mpeg, .rm"
          :on-preview="handlePreview"
          :show-file-list="false"
          :before-upload="saveFile('video')"
          :http-request="uploadFileToOss"
          :auto-upload="true"
          :disabled="!htmlStyle.show['video']"
        >
          <div
            slot="trigger"
            :class="{
              'upload-btn': true,
              'upload-btn-disabled': !htmlStyle.show.video
            }"
          >
            +上传视频
            <span style="font-size: 13px; color: #aaa">(大小限制200M)</span>
          </div>
        </el-upload>
      </div>
      <div style="display: flex;align-items: center" class="page-edit-title">
        <el-checkbox v-model="htmlStyle.show.photo" @change="changeShow"
          >图片</el-checkbox
        >
      </div>
      <div>
        <el-upload
          class="upload-demo"
          ref="upload"
          action
          :on-preview="handlePreview"
          accept=".jpg, .png, .jpeg"
          :show-file-list="false"
          :before-upload="saveFile('photo')"
          :http-request="uploadPhotoToOss"
          :auto-upload="true"
          :disabled="!htmlStyle.show['photo']"
        >
          <div
            slot="trigger"
            :class="{
              'upload-btn': true,
              'upload-btn-disabled': !htmlStyle.show.photo
            }"
          >
            +上传图片
            <span style="font-size: 13px;color: #aaa">(大小限制10M)</span>
          </div>
        </el-upload>
      </div>
      <div style="display: flex;align-items: center" class="page-edit-title">
        <el-checkbox v-model="htmlStyle.show['content']" @change="changeShow"
          >内容</el-checkbox
        >
      </div>
      <div>
        <el-select
          v-model="htmlStyle.fontFamily"
          placeholder="字体"
          @change="chooseRegisterIndustry"
          style="height:40px;margin-bottom:10px;"
          :disabled="!htmlStyle.show['content']"
        >
          <el-option
            v-for="item in allIndustryArr"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
            <span
              style="color: #8492a6; font-size: 13px"
              :style="{ fontFamily: item.value }"
              >{{ item.name }}</span
            >
          </el-option>
        </el-select>
        <el-input-number
          v-model="htmlStyle.fontSize"
          class="edit-font-size"
          controls-position="right"
          @change="handleChange"
          label="px"
          :min="12"
          :disabled="!htmlStyle.show['content']"
        />
        <div
          :class="{
            'edit-color-bold': true,
            disabled: !htmlStyle.show['content']
          }"
        >
          <div class="edit-flex edit-flex-color">
            <span class="edit-color-name">颜色</span>
            <input
              v-model="htmlStyle.fontColor"
              @change="colorChange"
              class="edit-choose-color"
              type="color"
              :disabled="!htmlStyle.show['content']"
            />
          </div>
          <div class="edit-flex">
            <p class="edit-font-weight" @click="changeFontWeight">
              <i :style="{ fontWeight: htmlStyle.fontWeight }">B</i>
            </p>
          </div>
        </div>
        <div
          :class="{
            'edit-color-bold': true,
            disabled: !htmlStyle.show['content']
          }"
        >
          <div class="edit-flex edit-flex-color">
            <span class="edit-color-name">背景颜色</span>
            <input
              v-model="htmlStyle.textBg"
              @change="colorChange"
              :default-color="htmlStyle.bgColor"
              class="edit-choose-color"
              type="color"
            />
          </div>
        </div>
      </div>
      <div>
        <el-input
          type="textarea"
          :rows="4"
          @input="editContChange"
          class="edit-text-area"
          placeholder="请输入内容"
          v-model="htmlStyle.textareaCont"
          :disabled="!htmlStyle.show['content']"
        ></el-input>
      </div>
      <div style="display: flex;align-items: center" class="page-edit-title">
        <el-checkbox v-model="htmlStyle.show['input']" @change="changeShow"
          >字段</el-checkbox
        >
      </div>
      <div class="page-show-para">
        <el-checkbox-group
          v-model="htmlStyle.checkedArr"
          @change="editContChange"
          :disabled="!htmlStyle.show['input']"
        >
          <el-checkbox label="name" value="1">姓名</el-checkbox>
          <el-checkbox label="phone" value="2">手机号</el-checkbox>
          <el-checkbox label="company" value="3">公司</el-checkbox>
        </el-checkbox-group>
        <div
          :class="{
            'edit-color-bold': true,
            disabled: !htmlStyle.show['input']
          }"
          style="margin-top:10px;"
        >
          <div
            :class="{
              'edit-flex': true,
              'edit-flex-color': true
            }"
          >
            <span class="edit-color-name">背景颜色</span>
            <input
              v-model="htmlStyle.pagraBg"
              @change="colorChange"
              :default-color="htmlStyle.bgColor"
              class="edit-choose-color"
              type="color"
              :disabled="!htmlStyle.show['input']"
            />
          </div>
        </div>
      </div>
      <div style="display: flex;align-items: center" class="page-edit-title">
        <el-checkbox v-model="htmlStyle.show['button']" @change="changeShow"
          >按钮</el-checkbox
        >
      </div>
      <el-input
        @input="editContChange"
        class="edit-text-area"
        placeholder="请输入按钮文案"
        maxlength="12"
        show-word-limit
        v-model="htmlStyle.buttonText"
        :disabled="!htmlStyle.show['button']"
      />

      <div class="name-card" v-if="false">
        <div class="page-edit-title">
          <el-checkbox v-model="htmlStyle.show['nameCard']" @change="changeShow"
            >微名片</el-checkbox
          >
        </div>

        <div class="upload-card">
          <div class="header-card">
            <div style="font-size: 14px">
              头像
            </div>
            <div>
              <!--              <el-upload-->
              <!--                :disabled="!htmlStyle.show['nameCard']"-->
              <!--                class="avatar-uploader"-->
              <!--                :before-upload="saveFile('headerImg')"-->
              <!--                :show-file-list="false"-->
              <!--                :http-request="uploadImg('headerImg')">-->
              <!--                <img v-if="htmlStyle.nameCard.headerImg" :src="htmlStyle.nameCard.headerImg" class="avatar">-->
              <!--                <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
              <!--              </el-upload>-->

              <el-upload
                :disabled="!htmlStyle.show['nameCard']"
                action
                accept=".jpg, .png, .jpeg"
                :before-upload="saveFile('headerImg')"
                :show-file-list="false"
                :http-request="uploadHeaderImgToOss"
                :auto-upload="true"
              >
                <img
                  v-if="htmlStyle.nameCard['headerImg']"
                  :src="htmlStyle.nameCard['headerImg']"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="ercode-card">
            <div style="font-size: 14px">
              二维码
            </div>
            <div>
              <el-upload
                :disabled="!htmlStyle.show['nameCard']"
                action
                accept=".jpg, .png, .jpeg"
                :before-upload="saveFile('erCodeImg')"
                :show-file-list="false"
                :http-request="uploadErCodeImgToOss"
                :auto-upload="true"
              >
                <img
                  v-if="htmlStyle.nameCard['erCodeImg']"
                  :src="htmlStyle.nameCard['erCodeImg']"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </div>

        <div class="name-card-input">
          <el-form label-width="auto" :disabled="!htmlStyle.show['nameCard']">
            <el-form-item label="姓名">
              <el-input
                type="text"
                placeholder="请输入姓名"
                v-model="htmlStyle.nameCard.username"
                @input="handleChange"
              />
            </el-form-item>
            <el-form-item label="公司">
              <el-input
                type="text"
                placeholder="请输入公司"
                v-model="htmlStyle.nameCard.companyName"
                @input="handleChange"
              />
            </el-form-item>
            <el-form-item label="职位">
              <el-input
                type="text"
                placeholder="请输入职位"
                v-model="htmlStyle.nameCard.position"
                @input="handleChange"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { postMarketUpload, getStsToken } from '@/api/table'
import { isObjNull } from '@/utils/validate'
import { getMarketlandPageDetail } from '@/api/table'
import OSS from 'ali-oss'
export default {
  props: {
    paramss: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      imageUrl: '',
      file: {
        video: '',
        photo: '',
        headerImg: '',
        erCodeImg: ''
      },
      fontFamily: '微软雅黑',
      editCont: '',
      aliOssConfig: {
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        region: 'oss-cn-shanghai',
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录 https://ram.console.aliyun.com 创建RAM账号。
        accessKeyId: '',
        accessKeySecret: '',
        stsToken: '',
        bucket: 'dtbird-yingxiao'
      },
      allIndustryArr: [
        {
          name: '楷体',
          value: 'KaiTi'
        },
        {
          name: '微软雅黑体',
          value: 'Microsoft YaHei'
        },
        {
          name: '黑体',
          value: 'SimHei'
        },
        {
          name: '宋体',
          value: 'SimSun'
        },
        {
          name: '新宋体',
          value: 'NSimSun'
        },
        {
          name: '仿宋',
          value: 'FangSong'
        }
      ],
      htmlStyle: {
        videoStatus: '',
        photoStatus: '',
        bgColor: '#ffffff',
        pagraBg: '#FFFFFF',
        textBg: '#D8D8D8',
        uploadUrl: '',
        editCont: '',
        fontWeight: 'normal',
        fontFamily: 'Microsoft YaHei',
        fontColor: '#FFFFFF',
        textareaCont: '',
        fontSize: 14,
        buttonText: '',
        checkedArr: [],
        share: {
          title: '',
          desc: '',
          img: ''
        },
        show: {
          video: true,
          photo: true,
          content: true,
          input: true,
          button: true,
          nameCard: false
        },
        nameCard: {
          headerImg: '',
          erCodeImg: '',
          username: '',
          companyName: '',
          position: ''
        }
      },
      pageId: localStorage.getItem('PageId') || ''
    }
  },
  created() {
    if (this.pageId) {
      this.getMarketlandPageDetail()
    }
    this.$emit('getUploadUrl', this.htmlStyle)
  },
  mounted() {
    // 解决上传文件跨域问题
    const { origin } = window.location
    if (origin.match('ai.dtbird.cn')) {
      document.domain = 'dtbird.cn'
    }
  },
  methods: {
    changeShow() {
      this.$emit('getUploadUrl', this.htmlStyle)
    },
    getMarketlandPageDetail() {
      const params = {
        id: this.pageId
      }
      getMarketlandPageDetail(params).then(res => {
        if (res.code === 60000) {
          const requetParam = JSON.parse(res.data.formParam)
          Object.assign(
            this.htmlStyle,
            {
              share: {
                title: res.data.formName, //旧落地页无该属性，加入可解决编辑进来标题为空现象
                desc: '',
                img: ''
              }
            },
            requetParam
          )
          this.$emit('getUploadUrl', this.htmlStyle)
        }
      })
    },
    handlePreview() {},
    chooseRegisterIndustry() {
      this.$emit('getUploadUrl', this.htmlStyle)
    },
    changeShareTitle() {
      this.$emit('changeFormName', this.htmlStyle.share['title'])
      this.$emit('getUploadUrl', this.htmlStyle)
    },
    handleChange() {
      this.$emit('getUploadUrl', this.htmlStyle)
    },
    editContChange(val) {
      this.$emit('getUploadUrl', this.htmlStyle)
    },
    changeFontWeight() {
      if (this.htmlStyle.fontWeight === 'bold') {
        this.htmlStyle.fontWeight = 'normal'
      } else {
        this.htmlStyle.fontWeight = 'bold'
      }

      this.$emit('getUploadUrl', this.htmlStyle)
    },
    colorChange() {
      this.$emit('getUploadUrl', this.htmlStyle)
    },
    postMarketUpload(file) {
      const param = new FormData()
      param.append('file', this.file['photo'])
      param.append('enctype', 'multipart/form-data')
      param.append('type', 'image')
      this.htmlStyle['photoStatus'] = 'uploading'
      this.handleChange()
      postMarketUpload(param).then(res => {
        this.file['photo'] = null
        if (res.code === 60000) {
          this.htmlStyle.uploadUrl = res.data.url
        } else {
          this.$message({
            type: 'error',
            message: '上传失败'
          })
        }
        this.htmlStyle['photoStatus'] = "'"
        this.$emit('getUploadUrl', this.htmlStyle)
      })
    },
    saveFile(name) {
      return file => {
        this.file[name] = file
      }
    },
    uploadVideo(file) {
      const param = new FormData()
      console.log('file', this.file)
      param.append('file', this.file['video'])
      param.append('enctype', 'multipart/form-data')
      param.append('type', 'video')
      this.htmlStyle['videoStatus'] = 'uploading'
      this.handleChange()
      postMarketUpload(param).then(res => {
        this.file['video'] = null
        if (res.code === 60000) {
          this.htmlStyle.videoUrl = res.data.url
          this.htmlStyle['videoStatus'] = ''
          this.$emit('getUploadUrl', this.htmlStyle)
        } else {
          this.htmlStyle['videoStatus'] = ''
          this.$emit('getUploadUrl', this.htmlStyle)
        }
      })
    },
    async uploadFileToOss() {
      this.htmlStyle['videoStatus'] = 'uploading'
      this.handleChange()
      const _res = await this.getStsToken()
      if (!_res) {
        return
      }
      const client = new OSS(this.aliOssConfig)

      let mathNum = Math.floor(Math.random() * 100000)
      let newName = Number(new Date()).toString()
      const aliObjName = mathNum + newName + this.file['video'].name
      let result = await client.multipartUpload(
        '/yingxiao-video/' + aliObjName,
        this.file['video']
      )
      console.log(result)
      this.file['video'] = null
      const url =
        'http://' +
        result.bucket +
        '.oss-cn-shanghai.aliyuncs.com/' +
        result.name
      const { res } = result
      this.htmlStyle.videoUrl = url
      this.htmlStyle['videoStatus'] = ''
      this.$emit('getUploadUrl', this.htmlStyle)
    },
    async uploadThumbnailToOss() {
      this.handleChange()
      if (this.file['thumbnail'].size < 1024 * 500) {
        await this.getStsToken()
        const client = new OSS(this.aliOssConfig)
        let mathNum = Math.floor(Math.random() * 100000)
        let newName = Number(new Date()).toString()
        const aliObjName = mathNum + newName + this.file['thumbnail'].name
        let result = await client.put(
          '/yingxiao-img/' + aliObjName,
          this.file['thumbnail']
        )
        console.log(result)
        this.file['thumbnail'] = null
        const { res, url } = result
        this.htmlStyle.share['img'] = url
        this.$emit('getUploadUrl', this.htmlStyle)
      } else {
        this.$message.error('头图不能大于500kb')
      }
    },

    async uploadHeaderImgToOss() {
      this.handleChange()
      if (this.file['headerImg'].size < 1024 * 500) {
        await this.getStsToken()
        const client = new OSS(this.aliOssConfig)
        let mathNum = Math.floor(Math.random() * 100000)
        let newName = Number(new Date()).toString()
        const aliObjName = mathNum + newName + this.file['headerImg'].name
        let result = await client.put(
          '/yingxiao-img/' + aliObjName,
          this.file['headerImg']
        )
        console.log(result)
        this.file['headerImg'] = null
        const { res, url } = result
        this.htmlStyle.nameCard['headerImg'] = url
        this.$emit('getUploadUrl', this.htmlStyle)
      } else {
        this.$message.error('头图不能大于500kb')
      }
    },

    async uploadErCodeImgToOss() {
      this.handleChange()
      if (this.file['erCodeImg'].size < 1024 * 500) {
        await this.getStsToken()
        const client = new OSS(this.aliOssConfig)
        let mathNum = Math.floor(Math.random() * 100000)
        let newName = Number(new Date()).toString()
        const aliObjName = mathNum + newName + this.file['erCodeImg'].name
        let result = await client.put(
          '/yingxiao-img/' + aliObjName,
          this.file['erCodeImg']
        )
        console.log(result)
        this.file['erCodeImg'] = null
        const { res, url } = result
        this.htmlStyle.nameCard['erCodeImg'] = url
        this.$emit('getUploadUrl', this.htmlStyle)
      } else {
        this.$message.error('头图不能大于500kb')
      }
    },

    getStsToken() {
      return new Promise(resolve => {
        getStsToken({})
          .then(res => {
            if (res && res.code === 60000) {
              this.aliOssConfig.accessKeyId = res.data.accessKeyId
              this.aliOssConfig.accessKeySecret = res.data.accessKeySecret
              this.aliOssConfig.stsToken = res.data.securityToken
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    async uploadPhotoToOss() {
      this.htmlStyle['photoStatus'] = 'uploading'
      this.handleChange()
      await this.getStsToken()
      const client = new OSS(this.aliOssConfig)
      console.log(client)
      let mathNum = Math.floor(Math.random() * 100000)
      let newName = Number(new Date()).toString()
      const aliObjName = mathNum + newName + this.file['photo'].name
      let result = await client.put(
        '/yingxiao-img/' + aliObjName,
        this.file['photo']
      )
      console.log(result)
      this.file['photo'] = null
      const { res, url } = result
      this.htmlStyle.uploadUrl = url
      this.htmlStyle['photoStatus'] = ''
      this.$emit('getUploadUrl', this.htmlStyle)
    }

    // handleUpload(file, fileList) {
    //   console.log("file", file);
    //   console.log("fileList", fileList);
    //   this.htmlStyle["videoStatus"] = file.status;
    //   this.handleChange();
    //   // if(file.status === 'uploading') {
    //   //   this.htmlStyle['videoStatus'] =
    //   // }else if(file.status === 'success') {

    //   // } else {

    //   // }
    // }
  }
}
</script>

<style scoped>
.page-show-edit {
  padding-right: 20px;
}
.page-show-edit ::v-deep .el-upload,
.page-show-edit ::v-deep .el-select,
.page-show-edit ::v-deep .edit-font-size {
  width: 100%;
}
.info-share .item-share {
  display: flex;
  margin-bottom: 10px;
}
.info-share .item-share ::v-deep .el-input,
.info-share .item-share ::v-deep .el-textarea {
  flex: 1;
}
.info-share .item-share label {
  width: 50px;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
}
.must-item::before {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}
.upload-share {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  border: 1px solid #e6e6e6;
}
.img-upload-share {
  position: relative;
}
.img-upload-share::before {
  content: '+';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  line-height: 60px;
  font-size: 30px;
  background: rgba(0, 0, 0, 0.3);
}
.img-upload-share img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.page-show-para {
  margin-bottom: 10px;
}
.edit-text-area {
  margin-bottom: 10px;
}
.edit-font-weight {
  text-align: center;
}
.edit-choose-color {
  border: none;
  border-radius: 10px;
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  outline: none;
}
.edit-color-name {
  font-size: 12px;
  color: #2e2e2e;
}
.edit-flex-color {
  border-right: 1px solid #e6e6e6;
  padding-left: 20px;
}
.edit-flex {
  flex: 1;
  cursor: pointer;
}
.edit-color-bold {
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  display: flex;
  margin-bottom: 10px;
}
.edit-font-size {
  margin-bottom: 10px;
}
.edit-font-size ::v-deep input {
  text-align: left;
}
.page-show-edit {
  width: 100%;
  margin: 0 auto;
}
.upload-btn {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #0099cc;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  margin-bottom: 14px;
}
.upload-btn.upload-btn-disabled,
.edit-color-bold.upload-btn-disabled,
.disabled {
  color: #c0c4cc;
  background: #f5f7fa;
}
.disabled .edit-color-name {
  color: #c0c4cc;
}
.page-edit-title {
  height: 22px;
  line-height: 22px;
  font-size: 16px;
  margin-bottom: 14px;
  font-weight: bold;
}
.page-edit-title ::v-deep .el-checkbox .el-checkbox__label {
  color: #000 !important;
}
.name-card {
  height: auto;
  width: 100%;
}
.upload-card {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.header-card,
.ercode-card {
  display: flex;
  justify-content: flex-start;
}

.header-card div:first-child,
.ercode-card div:first-child {
  margin-right: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.avatar {
  width: 60px;
  height: 60px;
  display: block;
}
.name-card-input {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.hide-x ::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
