var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body-code" }, [
    _c("div", { ref: "code", staticClass: "er-code" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }