<template>
  <div class="body" v-loading="loading">
    <el-scrollbar style="height: 570px;width: 100%" class="dt-dropdown-dt">
      <el-form
        ref="form"
        :model="formData"
        label-width="auto"
        :rules="rules"
        :validate-on-rule-change="false"
      >
        <el-row :gutter="10">
          <el-col :span="24">
            <el-row>
              <el-col :span="24">
                <el-form-item label="负责人">
                  <el-select
                    v-model="formData.userId"
                    placeholder="请选择负责人"
                    style="width: 100%"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="item in leaderList"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="协作人">
                  <el-select
                    multiple
                    filterable
                    v-model="formData.cooperationUserId"
                    placeholder="请选择协作人"
                    style="width: 100%"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="item in leaderList"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="退回理由"
                  v-if="formData.userId == -1 && activeTabs != 2"
                  :required="formData.userId == -1 && activeTabs != 2"
                  prop="returnType"
                >
                  <el-select
                    v-model="formData.returnType"
                    placeholder="请选择退回理由"
                    style="width: 100%"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="item in allMessage"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="客户来源">
                  <el-select
                    v-model="formData.fromId"
                    placeholder="请选择客户来源"
                    style="width: 100%"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="item in allSource"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="线索等级">
                  <el-select
                    v-model="formData.clueGradeId"
                    placeholder="请选择线索等级"
                    style="width: 100%"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="item in allClue"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="客户等级">
                  <el-select
                    v-model="formData.gradeId"
                    placeholder="请选择客户等级"
                    style="width: 100%"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="item in allLevel"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="状态">
                  <el-select
                    v-model="formData.stateId"
                    placeholder="请选择客户状态"
                    style="width: 100%"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="item in allStatus"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="标签">
                  <el-select
                    v-model="selectTags"
                    @change="selectChange"
                    ref="selectTag"
                    multiple
                    placeholder="请选择标签"
                    style="width: 80%"
                  >
                    <el-option-group
                      v-for="item in allPerTags"
                      :label="item.name"
                    >
                      <el-option
                        v-for="it in item.tags"
                        :key="it.id"
                        :label="it.name"
                        :value="it.id"
                      >
                      </el-option>
                    </el-option-group>
                  </el-select>
                  <el-tag
                    :color="this.flag == '1' ? '#41cabf' : ''"
                    :style="{ color: this.flag == '1' ? '#fff' : '#41cabf' }"
                    size="small"
                    @click="changeFlag('1')"
                    ><i class="el-icon-plus"></i
                  ></el-tag>
                  <el-tag
                    :color="this.flag == '0' ? '#41cabf' : ''"
                    :style="{ color: this.flag == '0' ? '#fff' : '#41cabf' }"
                    size="small"
                    @click="changeFlag('0')"
                    ><i class="el-icon-minus"></i
                  ></el-tag>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input
                    v-model="formData.remark"
                    placeholder="请输入备注"
                    type="textarea"
                    maxlength="100"
                    show-word-limit
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div class="footer">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="saveInput"
          >确认</el-button
        >
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { customerLoteEdit } from '@/api/customerpond'
export default {
  name: 'createCustomer',
  data() {
    return {
      type: 'create',
      loading: false,
      formData: {
        userId: '', // 负责人id
        companyName: '', //公司名称
        returnType: '', //退回原因
        remark: '', // 备注
        gradeId: '', // 客户等级
        fromId: '', //来源id
        stateId: '', // 状态id
        clueGradeId: '', //线索等级Id
        tagsId: '', //组合标签id
        customerId: '', //客户id
        idAndName: ''
      },
      flag: [],
      chooseCustomerList: [], //选中客户列表
      leaderList: [], //负责人列表
      allSource: [], //来源
      allClue: [], //线索等级
      allLevel: [], //客户等级
      allStatus: [], //状态
      allPerTags: [], // 自定义标签

      allMessage: [], //退回理由
      selectTags: [],
      activeTabs: '',
      showCheckRe: false,
      searchName: '',
      rules: {
        returnType: [
          { required: true, message: '请选择退回理由', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    const modalOptions = this.$store.state.plugin.modal.options
    console.log(modalOptions)
    this.leaderList = modalOptions.leaderList
    this.allSource = modalOptions.allSource
    this.allMessage = modalOptions.allMessage
    this.allClue = modalOptions.allClue
    this.allLevel = modalOptions.allLevel
    this.allStatus = modalOptions.allStatus
    this.allPerTags = modalOptions.allPerTags
    this.chooseCustomerList = modalOptions.chooseCustomerList
    this.activeTabs = modalOptions.activeTabs
    this.formData.idAndName = ''
    for (let i in modalOptions.chooseCustomerList) {
      this.formData.idAndName +=
        modalOptions.chooseCustomerList[i].id +
        '-' +
        modalOptions.chooseCustomerList[i].companyName +
        ','
    }
    this.formData.idAndName = this.formData.idAndName.slice(0, -1)
    this.type = modalOptions.type || 'create'
    let formData = modalOptions.formData
    // if(!formData.userId){
    //   formData.userId = -1
    // }
    this.$store.commit('update', {
      target: this.formData,
      data: formData
    })
  },

  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    }
  },

  methods: {
    changeFlag(e) {
      this.flag = e == this.flag ? '' : e
    },
    // select关闭下拉框
    selectChange() {
      // this.$refs['selectTag'].blur()
    },

    // 关闭弹框
    cancel() {
      this.$store.dispatch('hideModal')
    },

    // 是否允许编辑公司名
    disabled() {
      if (this.type === 'create') {
        return false
      }
      if (this.permissionList.includes('客户管理CRM-客户公海-客户名称编辑')) {
        return false
      }
      return true
    },

    // 查重
    checkName() {
      this.showCheckRe = true
    },

    // 提交保存内容
    saveInput() {
      this.$refs['form'].validate(validate => {
        if (!validate) {
          return false
        }
        this.editCustomer()
      })
    },

    // 编辑客户
    editCustomer() {
      this.loading = true
      let perTags = null
      if (this.selectTags.length > 0) {
        perTags = this.selectTags.join(',')
      }
      let flag = ''
      if (this.flag.length) {
        flag = this.flag[0]
      }

      let params = Object.assign({}, this.formData, {
        cooperationUserId: this.formData.cooperationUserId.join(),
        perTags: perTags,
        flag: flag
      })

      // console.log(params)
      // return
      customerLoteEdit(params)
        .then(res => {
          this.loading = false
          if (res && res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
            // 发送标志 刷新页面
            this.$store.commit('update', {
              target: this.$store.state.plugin.modal.options,
              data: {
                refresh: true
              }
            })
            this.$store.dispatch('hideModal')
          }
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch('showTip', {
            text: '编辑失败',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style scoped>
.body {
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.select {
  padding-left: 90px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.select span {
  cursor: pointer;
  text-decoration-line: underline;
  color: #41cabf;
}
.viewC {
  cursor: pointer;
  color: #41cabf;
}
.title {
  height: 50px;
  width: 100%;
  background-color: #41cabf;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.el-dialog-dt ::v-deep .el-dialog__header,
.el-drawer-dt ::v-deep .el-drawer__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}
.el-drawer-dt ::v-deep .el-drawer__header {
  margin-bottom: 0 !important;
}

.el-dialog-dt ::v-deep .el-dialog__close,
.el-drawer-dt ::v-deep .el-drawer__close {
  color: #fff;
}

.el-dialog-dt ::v-deep .el-dialog__body,
.el-drawer-dt ::v-deep .el-drawer__body {
  padding: 0 !important;
}
</style>
