var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "companyLink-main" }, [
    _c(
      "span",
      { staticClass: "company-name", on: { click: _vm.clickCompany } },
      [_vm._v("\n    " + _vm._s(_vm.companyName) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }