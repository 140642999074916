<template>
  <div>
    <iframe
      style="width:100vw;height:100vh"
      border="0"
      frameborder="none"
      ref="doc"
      id="iframe"
      src="https://open.dtbird.cn/"
    />
  </div>
</template>

<script>
export default {
  name: 'OpenDocument',
  mounted() {
    // const dom = window.document.getElementById('iframe').contentWindow
    // const cls = dom.document.getElementsByClassName('ant-layout-content')
    // console.log(cls)
  }
}
</script>
<style lang="scss" scoped></style>
