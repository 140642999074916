<template>
  <div class="modul-table-list">
    <div
      :class="!otherParams.isReceive && otherParams.shade ? 'bg-filter' : ''"
    >
      <el-table
        v-show="showTable"
        :key="refreshKey"
        :data="tableArr"
        :span-method="objectSpanMethod"
        resizable
        border
        ref="multipleTable"
        v-loading="showTableLoading"
        :class="
          !otherParams.isReceive && otherParams.shade
            ? 'module-cont-filter'
            : ''
        "
      >
        <template slot="empty" v-if="!showTableLoading">
          <div
            style="min-height:400px;padding-top:100px;"
            v-loading="showTableLoading"
          ></div>
        </template>
        <el-table-column width="1px"></el-table-column>
        <div v-for="column in headerArr" :key="column.field">
          <el-table-column
            :show-overflow-tooltip="showLoadLine(column)"
            :width="!haveAttr(column.attr, 'width') ? '' : column.width"
          >
            <template slot="header">
              <p class="sort_list_cont">
                <span> {{ column.name }}</span>
              </p>
            </template>
            <template slot-scope="scope">
              <el-tooltip
                :visible-arrow="false"
                popper-class="popper"
                effect="dark"
                :content="scope.row['access'] ? '网站可访问' : '网站不可访问'"
                placement="bottom-start"
              >
                <div style="display: inline">
                  <span
                    class="yun-icon7"
                    v-if="
                      column.href &&
                        !column.modal &&
                        scope.row['access'] &&
                        column.field == 'homePage'
                    "
                    style="color: rgb(158, 209, 101);"
                  ></span>
                  <svg-icon
                    icon-class="notaccess"
                    class="filtrate"
                    v-if="
                      column.href &&
                        !column.modal &&
                        !scope.row['access'] &&
                        column.field == 'homePage'
                    "
                  />
                </div>
              </el-tooltip>
              <el-popover placement="top-start" trigger="hover">
                <img
                  :src="scope.row[column.href]"
                  width="220"
                  height="220"
                  alt=""
                  v-if="column.href && !column.modal && column.name == '二维码'"
                />
                <div slot="reference">
                  <span
                    v-if="
                      column.href && !column.modal && column.name == '二维码'
                    "
                    @click="goPageUrl(scope.row[column.href])"
                    class="table-list-href"
                    v-html="scope.row[column.field]"
                  ></span>
                </div>
              </el-popover>

              <span
                v-if="column.href && !column.modal && column.name != '二维码'"
                @click="goPageUrl(scope.row[column.href])"
                class="table-list-href"
                v-html="scope.row[column.field]"
              ></span>
              <span
                v-if="column.modal && column.href && scope.row[column.href]"
                @click="
                  openModal(
                    scope.row[column.href],
                    scope.row[headerArr[0].field]
                  )
                "
                class="table-list-href"
                >{{ column.modal }}</span
              >
              <template v-if="!column.href && !column.modal">
                <span v-html="scope.row[column.field]"></span>
              </template>

              <span
                v-if="column.jump"
                @click="goPageUrl(scope.row[column.jump])"
                class="table-list-href"
                >{{ column.modal }}</span
              >
              <template v-if="column.type == 'custom'">
                <template v-for="(it, index) in scope.row.agencyList">
                  <span
                    :class="it.agencyUuid ? 'company-name-click' : ''"
                    @click="handleInvestClick(it)"
                    >{{ it.agencyName }}</span
                  >
                  <span v-if="it.isMain" class="ling_tou">(领投)</span>
                  <span
                    v-if="index != scope.row.agencyList.length - 1"
                    style="color:#0077cc;"
                    >、</span
                  >
                </template>
                <template v-if="scope.row.fa">
                  <div class="fa-wrap">
                    <div class="fa-item" v-for="it in scope.row.faAgencyList">
                      <div
                        :class="it.agencyUuid ? 'company-name-click' : ''"
                        @click="handleInvestClick(it)"
                      >
                        <span> {{ it.agencyName }}</span>
                        <span class="fa">(FA)</span>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </el-table-column>
        </div>
      </el-table>
      <div class="page-footer" v-if="pageStatus.total">
        <footer-page
          @handleCurrentChange="handleCurrentChange"
          @goNumPage="goNumPage"
          :pageSizes="[10, 20, 50]"
          :pageStatus="pageStatus"
        >
        </footer-page>
      </div>
    </div>

    <div
      class="touch-no-receive"
      v-if="!otherParams.isReceive && otherParams.shade"
      v-loading="getLoading"
    >
      <div class="touch-no-cont">
        <div class="touch-no-icon">
          <span
            style="color: #008CD9;text-decoration: underline;cursor:pointer;"
            @click="getReveiveKey('phone')"
            >领取</span
          >
          <span> 以查看所有信息</span>
        </div>
      </div>
    </div>
    <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showDetail">
      <div slot="modalCont">
        <p v-html="recruitStr" style="padding: 20px 0;"></p>
      </div>
      <div slot="submit">
        <div class="grasp_go_pay">
          <span @click="graspGoPay">关闭</span>
        </div>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import Mixin from './mixin'

export default {
  name: 'ActiveTable',
  mixins: [Mixin],
  data() {
    return {
      headerArr: [],
      tableArr: [],
      showTableLoading: false,
      graspData: {},
      showDetail: false,
      recruitStr: '',
      spanArr: [],
      refreshKey: 0
    }
  },
  watch: {
    headerArr: {
      handler: function() {
        this.refreshKey += 1
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    showTable() {
      return this.tableArr?.length > 0
    },

    isJump() {
      const perList = this.$store.state.permission.permission
      return perList.includes('系统管理-非菜单权限-企业详情页-跳转')
    }
  },
  mounted() {
    this.$emit('ModuleDom')
  },
  components: {
    diaModel
  },
  methods: {
    handleInvestClick(data) {
      if (this.isJump) {
        const agencyUuid = data?.agencyUuid
        sessionStorage.setItem('agencyUuid', agencyUuid)
        this.$router.push({
          name: 'investGroupDetail',
          query: { agencyUuid }
        })
      }
    },

    getSpanArr(data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].name === data[i - 1].name) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }
        }
      }
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // debugger
      if (this.headerArr[columnIndex] && this.headerArr[columnIndex].span) {
        let arr = []
        let that = this
        arr = this.tableArr.filter((ele, index, farr) => {
          return ele.timeAt === that.tableArr[rowIndex].timeAt
        })
        if (arr.length == 1) {
          return {
            rowspan: 1,
            colspan: 1
          }
        }
        if (
          arr.length != 1 &&
          rowIndex ==
            this.tableArr.findIndex(value => value.timeAt == row.timeAt)
        ) {
          return {
            rowspan: arr.length,
            colspan: 1
          }
        }
        if (
          arr.length != 1 &&
          rowIndex !=
            this.tableArr.findIndex(value => value.timeAt == row.timeAt)
        ) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    goPageUrl(val) {
      console.log(val, 8855)
      if (this.isJump) {
        window.open(val)
      }
    },
    openModal(val, name) {
      if (!val) {
        this.$message({
          type: 'warning',
          message: '暂无详情'
        })
        return
      }
      this.goRecruitDetail(val, name)
    },
    goRecruitDetail(val, name) {
      this.recruitStr = val
      this.recruitStr = this.recruitStr.replace(/；/g, '；<br/>')
      this.recruitStr = this.recruitStr.replace(/：/g, '：<br/>')
      this.recruitStr = this.recruitStr.replace(/。/g, '。<br/>')
      this.recruitStr =
        '<div' +
        ' style=' +
        'word-wrap:break-word' +
        '>' +
        this.recruitStr +
        '</div>'
      this.showDetail = true
      this.graspData = {
        dialogWidth: '800px',
        dialogTitle: name,
        dialogTop: '250px',
        showFoot: false,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.graspTip.init()
      })
    },
    graspGoPay() {
      this.showDetail = false
    },
    haveAttr(val, lag) {
      if (val) {
        const arr = val.split(',')
        if (arr.indexOf(lag) > -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    showLoadLine(column) {
      const columnNameList = [
        '榜单名称',
        '岗位',
        '被投资企业名称',
        '投资方',
        '标题'
      ]
      if (columnNameList.includes(column.name)) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}
.bg-filter {
  filter: blur(1.5px);
}
.page-footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.table-list-href {
  position: relative;
  left: 12px;
  color: #0077cc;
  cursor: pointer;
}

.modul-table-list {
  position: relative;
  margin-top: 18px;
}

.touch-no-receive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}

.touch-no-cont {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yun-icon7::before {
  content: '';
  width: 15px;
  height: 15px;
  left: 3px;
  top: 16px;
  display: inline-block;
  position: absolute;
  background: url('../../assets/allowpic.png') no-repeat;
  background-size: 100% 100%;
}

.yun-icon {
  font-size: 12px;
  display: inline-block;
  padding-left: 25px;
}

::v-deep table {
  border: none;
}

::v-deep thead th:not(.is-hidden):last-child {
  border: none;
}

::v-deep .el-table__row {
  td:not(.is-hidden):last-child {
    border: none;
  }
}
::v-deep .el-popover__reference {
  width: 50px;
}
::v-deep .el-table--border:last-child {
  // border: none !important;
}
.el-table--border .filtrate {
  position: absolute;
  top: 16px;
  left: 3px;
}
::v-deep .el-table--border::after,
.el-table--group::after {
  top: 0;
  right: 0;
  width: 0px;
  height: 100%;
}
</style>
