var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "batchEdit-main" }, [
    _c(
      "div",
      { staticClass: "form-main-row" },
      [
        _c(
          "el-form",
          { attrs: { "label-width": "auto", model: _vm.formData } },
          [
            _c(
              "el-form-item",
              { attrs: { label: "客户来源" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.formData.source,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "source", $$v)
                      },
                      expression: "formData.source",
                    },
                  },
                  _vm._l(_vm.sourceList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "客户池" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.formData.customerType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "customerType", $$v)
                      },
                      expression: "formData.customerType",
                    },
                  },
                  _vm._l(_vm.customerTypeList, function (group) {
                    return _c(
                      "el-option-group",
                      { key: group.id, attrs: { label: group.name } },
                      _vm._l(group.pages, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "备注" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入备注",
                    maxlength: "500",
                    "show-word-limit": "",
                    autosize: { minRows: 4, maxRows: 10 },
                  },
                  model: {
                    value: _vm.formData.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "remark", $$v)
                    },
                    expression: "formData.remark",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.closePop } },
          [_vm._v("取消")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.confirmClick },
          },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }