var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-main" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "el-form",
          { staticClass: "el-form-dt", attrs: { size: "small", inline: "" } },
          [
            _c(
              "el-form-item",
              [
                _c("el-input", {
                  attrs: { placeholder: "敏感词" },
                  model: {
                    value: _vm.name,
                    callback: function ($$v) {
                      _vm.name = $$v
                    },
                    expression: "name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "敏感类型" },
                    model: {
                      value: _vm.formInline,
                      callback: function ($$v) {
                        _vm.formInline = $$v
                      },
                      expression: "formInline",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "删除", value: "1" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { label: "拉黑", value: "0" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "是否误报" },
                    model: {
                      value: _vm.formInline,
                      callback: function ($$v) {
                        _vm.formInline = $$v
                      },
                      expression: "formInline",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "是", value: "1" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { label: "否", value: "0" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary", icon: "el-icon-search" } },
                  [_vm._v("搜索")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "chat-table" },
      [
        _c("dt-table", {
          attrs: {
            "hide-border": true,
            "data-total": 2,
            "table-loading": _vm.loading,
            "table-data": _vm.tableData,
            "is-select": false,
            "page-size": [20],
            "column-list": _vm.columnList,
          },
          on: { sizeChange: _vm.sizeChange, currentChange: _vm.currentChange },
          scopedSlots: _vm._u([
            {
              key: "ciRow",
              fn: function (row) {
                return [
                  _c("el-tag", { attrs: { type: "danger", size: "mini" } }, [
                    _vm._v(
                      "\n          " + _vm._s(row.scope.data.ci) + "\n        "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "nameRow",
              fn: function (row) {
                return [
                  _vm._v(
                    "\n        " + _vm._s(row.scope.data.name) + "\n      "
                  ),
                ]
              },
            },
            {
              key: "opeaRow",
              fn: function (row) {
                return [
                  _c("el-tag", { attrs: { size: "mini" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(row.scope.data.opea) +
                        "\n        "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "setTingRow",
              fn: function (row) {
                return [
                  _c(
                    "el-button",
                    { attrs: { size: "mini", type: "primary" } },
                    [_vm._v("\n          设为误报\n        ")]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }