var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "park-map-info-window",
    },
    [
      _c("div", { staticClass: "name" }, [
        _c("div", { staticClass: "left" }, [
          _vm._v(_vm._s(_vm.detailDesc.parkName)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "right",
            on: {
              click: function ($event) {
                return _vm.viewClick("c")
              },
            },
          },
          [_vm._v("查看入驻企业")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "label", staticStyle: { width: "40px" } }, [
          _vm._v("地址："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { flex: "1" } },
          _vm._l(_vm.addressList, function (i) {
            return _c("div", [_vm._v(_vm._s(i))])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _vm._v("\n    官网："),
        _c(
          "span",
          {
            staticClass: "website",
            on: {
              click: function ($event) {
                return _vm.viewClick("w")
              },
            },
          },
          [_vm._v(_vm._s(_vm.detailDesc.parkUrl))]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _vm._v("电话：" + _vm._s(_vm.detailDesc.telephone)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _vm._v("行业：" + _vm._s(_vm.detailDesc.parkIndustry)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _vm._v("\n    面积："),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.detailDesc.area,
                expression: "detailDesc.area",
              },
            ],
          },
          [_vm._v(_vm._s(_vm.detailDesc.area) + "亩")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _vm._v("\n    企业数："),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.detailDesc.companyCounts,
                expression: "detailDesc.companyCounts",
              },
            ],
          },
          [_vm._v(_vm._s(_vm.detailDesc.companyCounts) + "家")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }