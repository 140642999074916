var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-div" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "dt-permission",
                rawName: "v-dt-permission",
                value: "系统管理-客户画像设置-新建客户画像",
                expression: "'系统管理-客户画像设置-新建客户画像'",
              },
            ],
            staticClass: "create-btn",
            on: { click: _vm.toAddPro },
          },
          [_vm._v("\n      新建客户画像\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "dt-permission",
              rawName: "v-dt-permission",
              value: "系统管理-客户画像设置-查看客户画像",
              expression: "'系统管理-客户画像设置-查看客户画像'",
            },
          ],
          staticClass: "content",
        },
        [
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { data: _vm.tableData },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "客户画像名称",
                      width: "300",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(scope.row["name"])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "keywords",
                      label: "关键词",
                      "min-width": "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(scope.row["keywords"])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "dt-permission",
                                      rawName: "v-dt-permission",
                                      value: "系统管理-客户画像设置-编辑",
                                      expression:
                                        "'系统管理-客户画像设置-编辑'",
                                    },
                                  ],
                                  staticClass: "edit",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toEditPro(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "dt-permission",
                                      rawName: "v-dt-permission",
                                      value: "系统管理-客户画像设置-删除",
                                      expression:
                                        "'系统管理-客户画像设置-删除'",
                                    },
                                  ],
                                  staticClass: "del",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeRole(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page" }),
        ]
      ),
      _vm._v(" "),
      _vm.dialogVisiable
        ? _c(
            "dia-model",
            {
              ref: "addProfile",
              attrs: { dialogStatus: _vm.dialogAddStatus },
              on: { submitContBtn: _vm.submitAddBtn },
            },
            [
              _c(
                "div",
                {
                  staticClass: "adddia",
                  staticStyle: {
                    height: "auto",
                    "padding-left": "50px",
                    "padding-top": "20px",
                  },
                  attrs: { slot: "modalCont" },
                  slot: "modalCont",
                },
                [
                  _c(
                    "el-form",
                    { ref: "form", attrs: { "label-width": "120px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户画像名称", required: "" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入客户画像名称",
                              maxlength: "80",
                            },
                            model: {
                              value: _vm.addForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "name", $$v)
                              },
                              expression: "addForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键词", required: "" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入关键词。多个关键词用逗号隔开",
                              autosize: true,
                              maxlength: "1000",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.addForm.keywords,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addForm,
                                  "keywords",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addForm.keywords",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }