<template>
  <div class="invest-group-detail">
    <p>投资机构详情</p>
    <div v-if="investEvent" class="container">
      <div class="info">
        <div class="name">{{ agencyInfo.name }}</div>
        <div class="list">
          <p class="column-style" style="padding: 18px 6px;">投资</p>
          <div class="item" v-for="item in agencyList" :key="item.id">
            <p>{{ item.name }}</p>
            <span
              v-if="dataAccess == 0 || dataAccess == 1"
              @click="handleNormalData"
              class="vip"
              >VIP功能权限</span
            >
            <span v-else>{{ item.value }}</span>
          </div>
        </div>
        <div v-if="FAEvent" class="list">
          <p class="row-style" style="padding: 28px 2px;">FA</p>
          <div class="item" v-for="item in FAList" :key="item.id">
            <p>{{ item.name }}</p>
            <span
              v-if="faAccess == 0 || faAccess == 1"
              @click="handleNormalData"
              class="vip"
              >VIP功能权限</span
            >
            <span v-else>{{ item.value }}</span>
          </div>
        </div>
      </div>

      <div class="detail">{{ agencyInfo.introduction }}</div>
    </div>
    <div class="invest-view">
      <div class="left">
        <el-menu
          :default-active="defaultIndex"
          @select="handleMenuSelect"
          :default-openeds="['0', '1']"
        >
          <el-submenu index="0" v-if="investEvent">
            <template slot="title">
              <span slot="title">投资数据</span>
            </template>
            <el-menu-item index="0">投资事件</el-menu-item>
            <el-menu-item index="1">投资企业</el-menu-item>
          </el-submenu>
          <el-submenu index="1" v-if="FAEvent">
            <template slot="title">
              <span slot="title">FA数据</span>
            </template>
            <el-menu-item index="2">FA事件</el-menu-item>
            <el-menu-item index="3">FA企业</el-menu-item>
          </el-submenu>
          <el-menu-item index="4">热门赛道布局</el-menu-item>
        </el-menu>
      </div>
      <div class="right">
        <p class="current-name">{{ currentName }}</p>
        <template v-if="currentType == 'table'">
          <PeClassify
            :filterList="filterList"
            :resetFlag="resetFlag"
            @handleGetFilter="handleGetFilter"
            @handleTime="handleTime"
          />
          <div class="search-content">
            <el-input
              v-model="pageParams.keywords"
              placeholder="输入公司名称查询"
              style="width:400px"
              size="mini"
            ></el-input>
            <el-button
              type="primary"
              style="margin:0 10px"
              size="mini"
              @click="handleInquire"
              >查询</el-button
            >
            <el-button size="mini" @click="handleReset">重置</el-button>
          </div>
          <PeTable
            :peTableData="tableList"
            :peColumnList="tableColumn"
            :pePage="tablePage"
            :peLoading="peLoading"
            :IsAccess="IsAccess"
            @sortPeChange="handleSortPeChange"
            @handleSelectCommand="handleSelectCommand"
            @handleCurrentPePage="handleCurrentPePage"
            @handleSizePeChange="handleSizePeChange"
          >
            <template v-slot:company_name="{ scope }">
              <span
                class="company-name-click"
                @click="handleEnter(scope.data, scope.index)"
                >{{ scope.data.company_name }}</span
              >
              <Tags :tagList="scope.data.tags" />
              <div class="abnormal">
                <PeRisk :riskTag="scope.data.risk" />
                <span
                  v-if="scope.data.company_status == '注销'"
                  class="qy-cancel"
                  >注销</span
                >
              </div>
            </template>
            <template v-slot:vc_agency_list="{ scope }">
              <template v-for="(it, idx) in scope.data.vc_agency_list">
                <span
                  :class="it.agencyUuid ? 'company-name-click' : ''"
                  @click="handleGroup(it.agencyUuid, it.agencyName)"
                >
                  {{ it.agencyName }}
                </span>
                <span v-if="it.isMain" class="ling_tou">(领投)</span>
                <span
                  v-if="idx != scope.data.vc_agency_list.length - 1"
                  style="color:#0077cc"
                  >、</span
                >
              </template>
              <template v-if="scope.data.fa">
                <div class="fa-wrap">
                  <div
                    class="company-name-click"
                    @click="handleGroup(scope.data.agencyUuid, scope.data.fa)"
                  >
                    {{ scope.data.fa }}
                  </div>
                  <span class="fa">(FA)</span>
                </div>
              </template>
            </template>
          </PeTable>
        </template>
        <template v-else-if="currentType == 'list'">
          <PeTrack :agencyName="agencyInfo.name" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  investGroupDetail,
  investDetailFilter,
  investDetailList,
  getInvestEventList
} from '@/api/module'
import PeClassify from '@/components/Peculiar/classify'
import PeRisk from '@/components/Peculiar/risk'
import PeTable from '@/components/Peculiar/table'
import PeTrack from '@/components/Peculiar/track'
import { getMemoryParams, setMemoryParams } from '@/api/thread'
import { withOutAccess, formatRegex, enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'InvestGroupDetail',
  data() {
    return {
      tabInvest: '0',
      defaultIndex: '0',
      currentName: '投资事件',
      currentType: 'table',
      agencyInfo: {},
      agencyList: [],
      investEvent: false,
      FAEvent: false,
      FAList: [],
      tableList: [],
      tableColumn: [],
      tablePage: {
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200],
        showTotal: 0,
        total: 0
      },
      peLoading: false,

      filterList: [],
      resetFlag: false,
      pageParams: {
        pageNo: 1,
        pageSize: 10,
        investAgency: '',
        sort: '',
        keywords: '',
        isFa: false
      },
      paramsObj: {},
      currentIndex: 0,
      IsAccess: 0,
      isEvent: true,
      mapData: [
        {
          index: 0,
          isFa: false,
          isEvent: true,
          isAccess: 'dataAccess',
          name: '投资事件',
          type: 'table'
        },
        {
          index: 1,
          isFa: false,
          isEvent: false,
          isAccess: 'dataAccess',
          name: '投资企业',
          type: 'table'
        },
        {
          index: 2,
          isFa: true,
          isEvent: true,
          isAccess: 'faAccess',
          name: 'FA事件',
          type: 'table'
        },
        {
          index: 3,
          isFa: true,
          isEvent: false,
          isAccess: 'faAccess',
          name: 'FA企业',
          type: 'table'
        },
        {
          index: 4,
          isFa: false,
          isEvent: false,
          isAccess: null,
          name: '热门赛道布局',
          type: 'list'
        }
      ]
    }
  },
  components: {
    PeClassify,
    PeTable,
    PeRisk,
    PeTrack
  },
  computed: {
    dataAccess() {
      return this.$store.state.user.accountInfo.vcInsightDetailsOpen
    },
    faAccess() {
      return this.$store.state.user.accountInfo.faInsightDetailsOpen
    }
  },
  async mounted() {
    const id = this.$route.query.agencyUuid || sessionStorage.agencyUuid
    await this.getInfo(id)
    await this.getFilter(id)
    await this.getList()
  },
  methods: {
    handleMenuSelect(index) {
      const params = this.mapData.find(item => item.index == index)
      this.pageParams.isFa = params.isFa
      this.isEvent = params.isEvent
      this.IsAccess = this[params.isAccess]
      this.currentName = params.name
      this.currentType = params.type
      if (this.currentType == 'table') {
        this.getList()
      }
    },
    handleGroup(agencyUuid, agencyName) {
      if (agencyUuid) {
        sessionStorage.setItem('agencyUuid', agencyUuid)
        this.$router.replace({
          path: '/insight/vc/0/detail',
          query: {
            agencyUuid
          }
        })
        this.getInfo(agencyUuid)
        this.pageParams.investAgency = agencyName
        this.getList()
      }
    },
    handleNormalData() {
      withOutAccess()
    },
    handleTabClick(index) {
      this.currentIndex = index
      this.getList()
      console.log(this.tabInvest)
    },
    async getInfo(agencyUuid) {
      const { data } = await investGroupDetail({ agencyUuid })
      this.agencyInfo = data
      this.agencyList = [
        {
          id: 0,
          name: '企业数(家)',
          value: formatRegex(data.investCnt)
        },
        {
          id: 1,
          name: '投资事件(次)',
          value: formatRegex(data.eventCnt)
        },
        {
          id: 2,
          name: '投资金额(RMB亿元)',
          value: data.investAmountDesc
        }
      ]

      this.FAList = [
        {
          id: 0,
          name: 'FA企业(家)',
          value: formatRegex(data.faInvestCnt)
        },
        {
          id: 1,
          name: 'FA事件(次)',
          value: formatRegex(data.faEventCnt)
        },
        {
          id: 2,
          name: 'FA金额(RMB亿元)',
          value: data.faInvestAmountDesc
        },
        {
          id: 3,
          name: 'FA佣金收入(RMB万元)',
          value: formatRegex((data.faInvestAmount * 0.03).toFixed(2))
        }
      ]
      this.investEvent = data.eventCnt > 0
      this.FAEvent = data.faEventCnt > 0
      if (this.FAEvent && !this.investEvent) {
        this.pageParams.isFa = true
        this.IsAccess = this.faAccess
        this.currentName = 'FA事件'
        this.defaultIndex = '2'
      } else {
        this.IsAccess = this.dataAccess
      }
      this.pageParams.investAgency = data.name
    },
    handleEnter(data, index) {
      enterCompanyDetail(data, this.tableList, index, this.packageId)
    },
    async getFilter(agencyUuid) {
      const { data } = await investDetailFilter({ agencyUuid })
      data.forEach(item => {
        item.filterOptionList.forEach(el => {
          this.$set(el, 'checked', false)
        })
        item.filterOptionList.unshift({
          name: '全部',
          type: '',
          value: ''
        })
      })
      data
        .filter(item => item.type == 'time')
        .forEach((el, idx) => {
          this.$set(el, 'index', idx)
        })
      this.filterList = data
      // console.log(data)
    },
    handleGetFilter(params) {
      for (let key in params) {
        this.paramsObj[key] = params[key]
        this.pageParams[key] = params[key]
      }
      this.getList()
    },
    handleTime(res, type) {
      this.paramsObj[type] = res.join()
      this.pageParams[type] = res.join()
      this.getList()
    },
    handleInquire() {
      this.getList()
    },
    handleReset() {
      this.filterList.forEach(item => {
        item.filterOptionList.forEach(el => {
          this.$set(el, 'checked', false)
        })
      })
      for (let key in this.paramsObj) {
        this.pageParams[key] = ''
      }
      this.pageParams.keywords = ''
      this.$bus.$emit('handleResetEmpty')
      this.getList()
    },
    handleSortPeChange(...params) {
      const map = [
        {
          key: 'desc',
          value: '降序'
        },
        {
          key: 'asc',
          value: '升序'
        }
      ]
      const value = map.find(item => item.key == params[2].sortOrder)?.value
      if (params[2].sortAlias) {
        this.pageParams.sort = `${params[2].sortAlias}:${value}`
      } else {
        this.pageParams.sort = `${params[2].sortField}:${value}`
      }
      this.getList()
    },
    handleSelectCommand(...params) {
      this.pageParams.activityClass = `${params[1]}`
      this.getList()
    },
    handleCurrentPePage(e) {
      this.pageParams.pageNo = e
      this.getList()
    },
    handleSizePeChange(e) {
      this.pageParams.pageSize = e
      this.getList()
    },
    async getList(flag = false) {
      this.peLoading = true
      let api = ''
      if (!this.isEvent) {
        api = investDetailList
      } else {
        api = getInvestEventList
      }
      const { data } = await api(this.pageParams)
      if (flag) {
        this.tableList = this.tableList.concat(data?.list)
      } else {
        this.tableList = data?.list
      }
      this.tableList?.forEach((item = {}) => {
        item.hot = formatRegex(item?.hot)
        item.investCnt = formatRegex(item?.investCnt)
        item.investSumAmount = formatRegex(item?.investSumAmount)
        item.investSumCnt = formatRegex(item?.investSumCnt)
        item.investAmount = formatRegex(item?.investAmount)
      })
      this.tableColumn = data?.columns
      this.packageId = data?.packageId || 0
      this.tablePage = data?.page
      this.tablePage.total = data?.page?.total
      this.peLoading = false
    },
    async getParams() {
      const { data } = await getMemoryParams({
        router: 'schemaDetailRouter'
      })
      this.pageParams = data?.filterParam
    }
  },
  beforeDestroy() {
    this.$bus.$off()
  }
}
</script>
<style lang="scss" scoped>
.invest-group-detail {
  background-color: #fff;
  margin: 10px;
  .container {
    background-color: rgba(129, 211, 248, 0.2);
    padding: 10px;
    .info {
      display: flex;
      align-items: center;
      .name {
        font-size: 28px;
        color: #02a7f0;
      }
      .css-style {
        width: 100%;
        background: #02a7f0;
        color: #fff;
        padding: 5px;
      }
    }

    .list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 10px;
      border: 1px solid #d7d7d7;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        &:not(:first-child) {
          margin-left: 20px;
        }

        p {
          font-size: 16px;
          font-weight: bold;
        }
        span {
          margin-top: 10px;
        }
      }
    }
    .detail {
      margin-top: 10px;
      font-weight: 400;
      font-size: 13px;
      color: #333;
    }
  }

  .el-divider--horizontal {
    margin: 10px 0;
  }
  .search-content {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .invest-list {
    display: flex;
    align-items: center;
    .invest-item {
      position: relative;
      margin: 20px 0 0 20px;
      &:after {
        content: '';
        width: 4px;
        height: 24px;
        background: #41cabf;
        position: absolute;
        left: -10px;
        top: -2px;
      }
    }
  }
  .tabs {
    ul {
      display: flex;
      align-items: center;
      background: #41cabf;
      font-size: 12px;
      .li {
        cursor: pointer;
        margin: 0 10px;
        padding: 15px 10px;
        position: relative;
      }
      .active {
        color: #fff;
        &::after {
          content: '';
          width: 40px;
          height: 4px;
          position: absolute;
          background-color: #fff;
          left: 50%;
          transform: translateX(-50%);
          top: 35px;
          border-radius: 4px;
        }
      }
    }
  }

  .vip {
    color: #1e98d7;
    background: #fff;
    padding: 4px;
    cursor: pointer;
    font-size: 12px;
  }

  .invest-view {
    display: flex;
    .left {
      border: 1px solid #c7c7c7;
      margin: 10px 10px 0 0;
    }
    .right {
      width: 100%;
      overflow: hidden;
    }
    .el-menu {
      width: 160px;
      overflow: hidden;
    }
  }
  .current-name {
    padding: 10px;
    color: #000;
    background: #f2f2f2;
    margin-top: 10px;
  }
}
</style>

<style lang="scss">
.invest-group-detail {
  .el-tabs__item {
    font-size: 16px;
  }
  .custom-scroll {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    right: 5px !important;
    bottom: 0 !important;
    height: 69vh !important;
  }
  .search-schema {
    margin-top: 10px;
  }
}
</style>
