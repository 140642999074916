var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "swiper-wrap" },
    [
      _c(
        "swiper",
        {
          ref: "mySwiper",
          staticClass: "swiper",
          attrs: { options: _vm.swiperOption },
          on: { slideChange: _vm.handleSlideChange },
        },
        [
          _vm._l(_vm.scrollList, function (item, index) {
            return _c("swiper-slide", { key: index }, [
              _c("div", {
                staticClass: "render-name",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.deserveStr(item.company_name || item.companyName)
                  ),
                },
              }),
            ])
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { slot: "button-prev" },
            slot: "button-prev",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-next",
            attrs: { slot: "button-next" },
            slot: "button-next",
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }