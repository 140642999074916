
<template>
  <div class="filterWaiHu-main">
    <div class="filterWaiHu-main-dialog">
      <div class="tip">
        请选择异常号码处理方式：
      </div>
      <div class="row-dialog">
        <el-form label-width="100px" size="small" label-position="left">
          <el-form-item label="无效">
            <el-checkbox v-model="filterData.filterInvalid">过滤</el-checkbox>
          </el-form-item>
          <el-form-item label="重复" v-if="type !== 'one'">
            <el-checkbox v-model="filterData.filterRepeat">过滤</el-checkbox>
          </el-form-item>
          <el-form-item label="被我打过">
            <el-checkbox v-model="filterData.filterMeCalled">过滤</el-checkbox>
          </el-form-item>
          <el-form-item label="被他人打过">
            <el-checkbox v-model="filterData.filterOtherCalled">过滤</el-checkbox>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancelBtn">取消</el-button>
      <el-button size="small" type="primary" @click="confirmBtn" v-loading="loading">确认</el-button>
    </div>
  </div>
</template>

<script>
import {addWaHuTaskFromKey} from '@/api/customerpond'
export default {
  name: 'filterWaiHu',
  data () {
    return {
      loading: false,
      type: 'more',
      filterData: {
        filterInvalid: false,
        filterRepeat: false,
        filterMeCalled: false,
        filterOtherCalled: false
      },
      consumption: true,
      companyUuids: '',
      packageId: 0,
      phone: undefined,
      collectionIds: undefined, // 从培育池转入
      autoIds: '' // 外呼任务 已完成中重新转入
    }
  },
  mounted () {
    const modalParams = this.$store.state.plugin.modal.options
    this.consumption = modalParams.consumption
    this.companyUuids = modalParams.companyUuid
    this.packageId = modalParams.packageId
    this.type = modalParams.type
    if(this.type === 'one') this.phone = modalParams.phone
    if(this.type === 'bookList') this.collectionIds = modalParams.collectionIds
    if(this.type === 'autoList') this.autoIds = modalParams.autoIds
  },
  computed: {},
  methods: {
    /**
     * @param cancelTipText 提示的文字
     * @param {string} tipType 提示的类型
     * @param {Boolean} isClose 是否关闭modal
     * @param {Boolean} showTip 是否显示提示
     */
    cancelBtn({cancelTipText=null, tipType='success', isClose=true, showTip= false}){
      if(showTip || cancelTipText) {
        this.$store.dispatch('showTip', {
          text: cancelTipText,
          type: tipType
        })
      }
      if(isClose) this.$store.dispatch('hideModal')
    },
    confirmBtn(){
      let params  = {
        packageId: this.packageId,
        companyUuids: this.companyUuids,
        phone: this.phone,
        consumption: this.consumption,
        collectionIds: this.collectionIds,
        waihuTaskIds: this.autoIds
      }
      params = Object.assign({}, params, this.filterData)
      this.loading = true
      addWaHuTaskFromKey(params).then(res => {
        this.loading = false
        if(res.code === 60000){
          this.$store.commit('update', {
            target: this.$store.state.plugin.modal.options,
            data: { isOk: true }
          })
          this.cancelBtn({ cancelTipText: res.msg })
        }
      }).catch((err) => {
        this.loading = false
        this.cancelBtn({ cancelTipText: err.message, tipType: 'error', isClose: false })
      })
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.filterWaiHu-main-dialog{
  padding: 10px 20px 0 20px;
  box-sizing: border-box;
}
.footer{
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding-right: 10px;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,.1);
  transform: scaleY(.5);
  position: absolute;
  top: 0;
  left: 0;
}
.tip{
  padding: 10px 0;
  box-sizing: border-box;
}
.row-dialog{
  padding-left: 20px;
  box-sizing: border-box;
}
</style>
