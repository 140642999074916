var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dt-module-detail" },
    [
      _c(_vm.modulCont, {
        tag: "component",
        attrs: {
          moduleParam: _vm.moduleParam,
          packageId: _vm.packageId,
          detail: _vm.detail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }