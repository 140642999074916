import Vue from 'vue'
import { saveErrorHandel } from '../api/system'
import cookie from 'js-cookie'
let userInfo = localStorage.getItem('accountInfo')
if (userInfo && typeof userInfo === 'string' && userInfo.startsWith('{')) {
  userInfo = JSON.parse(userInfo)
  if (userInfo.landSite) {
    delete userInfo.landSite
  }
  if (userInfo.landCard) {
    delete userInfo.landCard
  }
} else {
  userInfo = {}
}
if (process.env.NODE_ENV !== 'development') {
  Vue.config.errorHandler = (error, vm, info) => {
    const errors = {
      level:
        error.message && typeof error.message === 'string'
          ? error.message.indexOf('[ElementForm]unpected width') > -1
            ? '低'
            : '高'
          : '低',
      postTime: getTime(new Date().getTime()),
      loginTime: getTime(localStorage.getItem('userLoginTime')),
      hook: info,
      post: {
        message: error.message
      },
      browserEnv: {
        browser: navigator.appVersion,
        cookieEnabled: navigator.cookieEnabled,
        platform: navigator.platform,
        userAgent: navigator.userAgent
      },
      path: window.location.href,
      user: Object.assign(
        {},
        { id: userInfo.id },
        { token: cookie.get('token') }
      )
    }
    if (errors.level !== '低' && process.env.NODE_ENV !== 'development') {
      saveErrorHandel({ error: JSON.stringify(errors) }).then(() => {})
    } else {
      console.error(errors)
    }
  }

  window.onerror = (message, source, lineno, colno, error) => {
    const errors = {
      level: message === 'ResizeObserver loop limit exceeded' ? '低' : '高',
      postTime: getTime(new Date().getTime()),
      loginTime: getTime(localStorage.getItem('userLoginTime')),
      browserEnv: {
        browser: navigator.appVersion,
        cookieEnabled: navigator.cookieEnabled,
        platform: navigator.platform,
        userAgent: navigator.userAgent
      },
      path: window.location.href,
      message: message,
      source: source,
      lineNumber: `第${lineno}行`,
      columnNumber: `第${colno}列`,
      post: {
        message: error ? error.message : ''
      },
      user: Object.assign(
        {},
        { id: userInfo.id },
        { token: cookie.get('token') }
      )
    }
    if (errors.level !== '低' && process.env.NODE_ENV !== 'development') {
      saveErrorHandel({ error: JSON.stringify(errors) }).then(() => {})
    } else {
      console.error(errors)
    }
  }
}

function getTime(time) {
  if (!time) {
    return ''
  }
  time = new Date(parseInt(time))
  return `${time.getFullYear()}-${time.getMonth() +
    1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`
}
