var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pond-contacts-drawr" }, [
      _c("div", { staticClass: "pond-contacts-top" }, [
        _c("p", { staticStyle: { "font-size": "14px" } }, [
          _vm._v("联系人信息"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "search-botton",
            staticStyle: { "margin-left": "10px" },
          },
          [_vm._v("添加联系人")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }