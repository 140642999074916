var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-upload-oss" },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-demo",
          attrs: {
            action: _vm.uploadFileUrl,
            "before-upload": _vm.beforeUpload,
            accept: ".doc,.docx,.pdf,.jpg,.png,.gif,.pages",
            "http-request": _vm.uploadFileToOss,
            "on-remove": _vm.handleRemove,
            "file-list": _vm.fileList,
            "auto-upload": true,
          },
        },
        [
          _c(
            "el-button",
            { attrs: { slot: "trigger", size: "small" }, slot: "trigger" },
            [_vm._v("选取文件")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }