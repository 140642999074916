<template>
  <div class="pond-detail-drawr" v-loading="allLoading">
    <div class="header-drawer">
      <div class="left-header">
        <p style="font-size:14px;" v-if="path !== 'keydetail'">
          {{ rowCont.companyName }}
        </p>
        <div
          style="width: 100%;height: 40px;line-height:40px;padding: 0 20px"
          v-if="path === 'keydetail'"
        >
          跟进记录
        </div>
        <div style="margin-top:10px;">
          <el-tag v-if="rowCont.from" size="mini">
            {{ rowCont.from }}
          </el-tag>
          <el-tag size="mini" v-if="rowCont.grade">
            {{ rowCont.grade }}
          </el-tag>
          <el-tag size="mini" v-if="rowCont.state">
            {{ rowCont.state }}
          </el-tag>
          <el-tag size="mini" v-if="rowCont.customerGrade">
            {{ rowCont.customerGrade }}
          </el-tag>
        </div>
      </div>
      <div class="right-header">
        <p v-show="rowCont.createUserName">
          创建人：{{ rowCont.createUserName }}
        </p>
        <p v-show="rowCont.createdAt">创建时间：{{ rowCont.createdAt }}</p>
      </div>
    </div>

    <el-row>
      <el-col :span="24">
        <el-tag v-for="tag in tags" size="mini" style="margin: 3px 5px 0 0">
          {{ tag }}
        </el-tag>
      </el-col>
      <el-col :span="24">
        <el-tag
          v-for="tag in privateTags"
          size="mini"
          color="orange"
          style="margin: 3px 5px 0 0;color: #FFFFFF"
        >
          {{ tag }}
        </el-tag>
      </el-col>
    </el-row>

    <div class="searchTop">
      <el-tabs @tab-click="activeChange" v-model="activeTabs">
        <el-tab-pane name="all" label="全部">
          <el-scrollbar
            class="xxx-scroll"
            :style="{
              height:
                activeTabs != 'third' &&
                activeTabs != 'second' &&
                hasEditPermission
                  ? 'calc( 100vh - 50px - 50px - 350px - 50px)'
                  : 'calc( 100vh - 50px - 200px )',
              with: '100%',
              overflowX: 'hidden'
            }"
            v-if="pondCond.length && hasViewPermission"
          >
            <div
              v-for="(item, index) in pondCond"
              :key="index"
              class="pond-cond-all"
            >
              <template v-if="item.week">
                <p style="margin-bottom:20px;">
                  <span style="display:inline-block;width:70px;">{{
                    item.week
                  }}</span>
                  <span style="font-size:12px;">{{ item.day }}</span>
                </p>

                <div
                  style="overflow: hidden"
                  v-for="(follow, ind) in item.follows"
                  :key="ind + 'c'"
                >
                  <div
                    class="pond-cond-left"
                    :class="ind !== 0 ? 'pond-no-first' : ''"
                  >
                    <p>{{ follow.time }}</p>
                  </div>
                  <div
                    class="pond-cond-right"
                    :class="ind !== 0 ? 'pond-no-first' : ''"
                  >
                    <p v-if="follow.type == 1">
                      <span
                        :class="
                          item.week === '今天'
                            ? 'pond-cond-posi'
                            : 'pond-cond-had'
                        "
                        v-if="follow.follower"
                        >{{ follow.follower }}</span
                      >
                      <span
                        >&nbsp;&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;&nbsp;</span
                      >
                      <span>{{ follow.opSummary }}</span>
                    </p>

                    <p
                      :style="{ 'margin-top': follow.type == 1 ? '20px' : '0' }"
                    >
                      <span
                        style="color:#999999;"
                        :class="
                          follow.type != 1
                            ? item.week === '今天'
                              ? 'pond-cond-posi'
                              : 'pond-cond-had'
                            : ''
                        "
                        v-html="follow.opDetails"
                      ></span>
                    </p>
                    <div class="previewClose">
                      <img
                        v-if="
                          follow.imagesUrl != '' && follow.imagesUrl != null
                        "
                        :src="follow.imagesUrl"
                        alt
                        @click="previewImg(follow.imagesUrl)"
                        class="previewImg"
                      />
                      <el-image-viewer
                        v-if="showViewer"
                        :on-close="closeViewer"
                        :url-list="[srcList]"
                      />
                    </div>

                    <div v-if="follow.type === -1" class="phone-record">
                      <span>
                        {{
                          follow.userWaihuRecordData
                            ? follow.userWaihuRecordData.account
                            : ''
                        }}
                      </span>
                      <span>
                        向
                      </span>
                      <span>
                        {{
                          follow.userWaihuRecordData
                            ? follow.userWaihuRecordData.phone
                            : ''
                        }}
                        <span
                          v-show="
                            follow.userWaihuRecordData &&
                              follow.userWaihuRecordData.callName
                          "
                          >{{ follow.userWaihuRecordData.callName }}</span
                        >
                      </span>
                      <span>
                        发起外呼，
                      </span>
                      <span
                        v-show="
                          follow.userWaihuRecordData &&
                            follow.userWaihuRecordData.phoneStatus
                        "
                      >
                        <span>
                          通话状态
                        </span>
                        <span>
                          {{
                            follow.userWaihuRecordData
                              ? follow.userWaihuRecordData.phoneStatus
                              : ''
                          }}
                        </span>
                      </span>
                      <br />
                      <span
                        v-show="
                          follow.userWaihuRecordData &&
                            follow.userWaihuRecordData.callDuration
                        "
                      >
                        <span>
                          ，通话时长
                        </span>
                        <span>
                          {{
                            follow.userWaihuRecordData
                              ? follow.userWaihuRecordData.callDuration
                              : ''
                          }}
                        </span>
                      </span>

                      <span
                        class="play-record"
                        v-dt-permission="'客户管理CRM-客户公海-播放录音'"
                        @click="playRecord(follow.userWaihuRecordData.ossUrl)"
                        v-show="
                          follow.userWaihuRecordData &&
                            follow.userWaihuRecordData.callDuration &&
                            follow.userWaihuRecordData.ossUrl
                        "
                      >
                        播放录音
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </el-scrollbar>
        </el-tab-pane>

        <el-tab-pane name="first" label="跟进记录" v-cloak>
          <el-scrollbar
            class="xxx-scroll"
            :style="{
              height:
                activeTabs != 'third' &&
                activeTabs != 'second' &&
                hasEditPermission
                  ? 'calc( 100vh - 50px - 50px - 350px - 50px )'
                  : 'calc( 100vh - 50px - 200px )',
              with: '100%',
              overflowX: 'hidden'
            }"
            v-if="pondCond && pondCond.length && hasViewPermission"
          >
            <div
              v-for="(item, index) in pondCond"
              :key="index"
              class="pond-cond-all"
            >
              <template v-if="item.week">
                <p style="margin-bottom:20px;">
                  <span style="display:inline-block;width:70px;">{{
                    item.week
                  }}</span>
                  <span style="font-size:12px;">{{ item.day }}</span>
                </p>

                <div
                  style="overflow: hidden;"
                  v-for="(follow, ind) in item.follows"
                  :key="ind + 'c'"
                >
                  <div
                    class="pond-cond-left"
                    :class="ind !== 0 ? 'pond-no-first' : ''"
                  >
                    <p>{{ follow.time }}</p>
                  </div>
                  <div
                    class="pond-cond-right"
                    :class="ind !== 0 ? 'pond-no-first' : ''"
                  >
                    <p>
                      <span
                        :class="
                          item.week === '今天'
                            ? 'pond-cond-posi'
                            : 'pond-cond-had'
                        "
                        >{{ follow.follower }}</span
                      >
                      <span
                        >&nbsp;&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;&nbsp;</span
                      >
                      <span>{{ follow.type }}</span>
                    </p>
                    <p style="margin-top:20px;">
                      <span style="color:#999999;"
                        >记录：{{ follow.records }}</span
                      >
                    </p>
                    <div class="previewClose">
                      <img
                        v-if="
                          follow.imagesUrl != '' && follow.imagesUrl != null
                        "
                        :src="follow.imagesUrl"
                        alt
                        @click="previewImg(follow.imagesUrl)"
                        class="previewImg"
                      />
                      <el-image-viewer
                        v-if="showViewer"
                        :on-close="closeViewer"
                        :url-list="[srcList]"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </el-scrollbar>
        </el-tab-pane>

        <el-tab-pane name="second" label="系统记录" v-cloak>
          <el-scrollbar
            class="xxx-scroll"
            :style="{
              height:
                activeTabs != 'third' &&
                activeTabs != 'second' &&
                hasEditPermission
                  ? 'calc( 100vh - 50px - 50px - 50px - 350px )'
                  : 'calc( 100vh - 50px - 200px )',
              with: '100%',
              overflowX: 'hidden'
            }"
            v-if="pondCond && pondCond.length && hasViewPermission"
          >
            <div
              v-for="(item, index) in pondCond"
              :key="index"
              class="pond-cond-all"
            >
              <p style="margin-bottom:20px;">
                <span style="display:inline-block;width:70px;">{{
                  item.week
                }}</span>
                <span style="font-size:12px;">{{ item.day }}</span>
              </p>

              <div
                style="overflow: hidden;"
                v-for="(follow, ind) in item.follows"
                :key="ind + 'c'"
              >
                <div
                  class="pond-cond-left"
                  :class="ind !== 0 ? 'pond-no-first' : ''"
                >
                  <p>{{ follow.time }}</p>
                </div>
                <div
                  class="pond-cond-right"
                  :class="ind !== 0 ? 'pond-no-first' : ''"
                >
                  <p>
                    <span
                      style="color:#999999;"
                      :class="
                        item.week === '今天'
                          ? 'pond-cond-posi'
                          : 'pond-cond-had'
                      "
                      v-html="follow.opDetails"
                    ></span>
                  </p>
                  <div class="previewClose">
                    <img
                      v-if="follow.imagesUrl != '' && follow.imagesUrl != null"
                      :src="follow.imagesUrl"
                      alt
                      @click="previewImg(follow.imagesUrl)"
                      class="previewImg"
                    />
                    <el-image-viewer
                      v-if="showViewer"
                      :on-close="closeViewer"
                      :url-list="[srcList]"
                    />
                  </div>
                  <div
                    v-if="follow.type === -1"
                    class="phone-record"
                    style="margin-left: 10px"
                  >
                    <span>
                      {{
                        follow.userWaihuRecordData
                          ? follow.userWaihuRecordData.account
                          : ''
                      }}
                    </span>
                    <span>
                      向
                    </span>
                    <span>
                      {{
                        follow.userWaihuRecordData
                          ? follow.userWaihuRecordData.phone
                          : ''
                      }}
                      <span
                        v-show="
                          follow.userWaihuRecordData &&
                            follow.userWaihuRecordData.callName
                        "
                        >{{ follow.userWaihuRecordData.callName }}</span
                      >
                    </span>
                    <span>
                      发起外呼，
                    </span>
                    <span
                      v-show="
                        follow.userWaihuRecordData &&
                          follow.userWaihuRecordData.phoneStatus
                      "
                    >
                      <span>
                        通话状态
                      </span>
                      <span>
                        {{
                          follow.userWaihuRecordData
                            ? follow.userWaihuRecordData.phoneStatus
                            : ''
                        }}
                      </span>
                    </span>
                    <br />

                    <span
                      v-show="
                        follow.userWaihuRecordData &&
                          follow.userWaihuRecordData.callDuration
                      "
                    >
                      <span>
                        ，通话时长
                      </span>
                      <span>
                        {{
                          follow.userWaihuRecordData
                            ? follow.userWaihuRecordData.callDuration
                            : ''
                        }}
                      </span>
                    </span>

                    <span
                      class="play-record"
                      v-dt-permission="'客户管理CRM-客户公海-播放录音'"
                      @click="playRecord(follow.userWaihuRecordData.ossUrl)"
                      v-show="
                        follow.userWaihuRecordData &&
                          follow.userWaihuRecordData.callDuration &&
                          follow.userWaihuRecordData.ossUrl
                      "
                    >
                      播放录音
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </el-tab-pane>

        <el-tab-pane name="third" label="客户动态" v-cloak>
          <el-scrollbar
            class="xxx-scroll"
            :style="{
              height:
                activeTabs != 'third' &&
                activeTabs != 'second' &&
                hasEditPermission
                  ? 'calc( 100vh - 50px - 50px - 50px - 350px )'
                  : 'calc( 100vh - 50px - 200px )',
              with: '100%',
              overflowX: 'hidden'
            }"
            v-if="pondCond && pondCond.length && hasViewPermission"
          >
            <div
              v-for="(item, index) in pondCond"
              :key="index"
              class="pond-cond-all"
            >
              <p style="margin-bottom:20px;">
                <span style="display:inline-block;width:70px;">{{
                  item.week
                }}</span>
                <span style="font-size:12px;">{{ item.day }}</span>
              </p>

              <div
                style="overflow: hidden;"
                v-for="(follow, ind) in item.follows"
                :key="ind + 'c'"
              >
                <div
                  class="pond-cond-left"
                  :class="ind !== 0 ? 'pond-no-first' : ''"
                >
                  <p>{{ follow.time }}</p>
                </div>
                <div
                  class="pond-cond-right"
                  :class="ind !== 0 ? 'pond-no-first' : ''"
                >
                  <p>
                    <span
                      style="color:#999999;"
                      :class="
                        item.week === '今天'
                          ? 'pond-cond-posi'
                          : 'pond-cond-had'
                      "
                      v-html="follow.opDetails"
                    ></span>
                  </p>
                  <div class="previewClose">
                    <img
                      v-if="follow.imagesUrl != '' && follow.imagesUrl != null"
                      :src="follow.imagesUrl"
                      alt
                      @click="previewImg(follow.imagesUrl)"
                      class="previewImg"
                    />
                    <el-image-viewer
                      v-if="showViewer"
                      :on-close="closeViewer"
                      :url-list="[srcList]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>

      <!-- isActiveTab为1时tab状态为我负责，查看跟进记录展示跟进记录提交的状态 -->

      <div
        v-if="
          activeTabs != 'third' &&
            activeTabs != 'second' &&
            hasEditPermission &&
            inPool
        "
        style="width:100%;height: 320px"
        class="pond-form"
      >
        <el-form ref="form" label-width="85px">
          <el-form-item label="跟进方式" required>
            <el-select
              placeholder
              v-model="creatFlow"
              size="small"
              style="width: 90%"
            >
              <el-option
                :label="item.name"
                :value="item.name"
                v-for="(item, index) in allFlow"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跟进对象">
            <el-select
              disabled
              class="filter-mult-coll select-industry-one xxx"
              style="width: 90%"
              size="small"
              placeholder=""
              v-model="chooFilterInput"
              @change="secondChoose()"
              multiple
              collapse-tags
              filterable
              reserve-keyword
            >
              <el-option
                v-for="item in contactList"
                :key="item._id"
                :label="
                  item.position
                    ? item.contact + '·' + item.position
                    : item.contact
                "
                :value="item.id"
              ></el-option>
            </el-select>
            <span
              v-dt-permission="'客户管理CRM-客户公海-联系人'"
              @click="addContactDia"
              class="select-btn"
            >
              选择
            </span>
          </el-form-item>
          <el-form-item label="跟进时间" required>
            <el-date-picker
              v-model="chooFilterTime"
              type="datetime"
              size="small"
              :picker-options="pickerOptions"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="选择日期时间"
              style="width:70%;"
            >
            </el-date-picker>
            <span style="position: absolute;top:5px;">
              <el-upload
                class="upload-demo"
                ref="upload"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :limit="1"
                :auto-upload="true"
              >
                <div slot="trigger">
                  <svg-icon icon-class="uploadImg" class="uploadSvg" />
                </div>
              </el-upload>
            </span>
          </el-form-item>
        </el-form>
        <div
          style="float: left;height: 30px;line-height: 30px;margin-bottom: 10px;"
        >
          <div
            class="search-botton"
            style="margin-left:10px;width: 100px"
            @click="addContactDia"
            v-dt-permission="'客户管理CRM-客户公海-联系人'"
            v-if="false"
          >
            新建联系人
          </div>
        </div>
        <div class="editor">
          <el-input
            type="textarea"
            @blur="onInputBlur"
            @focus="onInputFocus"
            placeholder="请输入内容"
            v-model="followRecords"
            style="width: 100%;"
          ></el-input>
          <div class="submit-btn">
            <el-button
              style="margin-top:10px"
              type="primary"
              size="mini"
              @click="submintBtn"
              v-loading="loadingBtn"
              >提交</el-button
            >
          </div>
        </div>

        <img v-if="uploadUrl" :src="uploadUrl" class="pond-avatar" />
      </div>
    </div>
    <dia-model
      @submitContBtn="addContactBtn"
      class="pond-form-input"
      ref="addContact"
      :dialogStatus="addContactModel"
    >
      <div slot="modalCont" style="padding: 20px;box-sizing: border-box">
        <el-form ref="form" label-width="auto" :model="formData">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="姓名" required>
                <el-input
                  placeholder="请输入姓名"
                  v-model="formData.contact"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label">身份</div>
                <el-select
                  v-model="formData.isKp"
                  placeholder="请选择"
                  style="width: 100%;"
                >
                  <el-option label="关键人" value="1"></el-option>
                  <el-option label="中间人" value="2"></el-option>
                  <el-option label="普通" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label">部门</div>
                <el-input
                  placeholder="请输入部门"
                  v-model="formData.department"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职位">
                <el-input
                  placeholder="请输入职位"
                  v-model="formData.position"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机" prop="mobile" :rules="rules">
                <el-input
                  placeholder="请输入手机号码"
                  v-model="formData.mobile"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="座机">
                <el-input
                  placeholder="请输入座机号码"
                  v-model="formData.landLine"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信号">
                <el-input
                  placeholder="请输入微信号"
                  style="ime-mode:disabled"
                  v-model="formData.wechat"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱">
                <el-input
                  placeholder="请输入邮箱"
                  v-model="formData.email"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">状态</div>
                <el-select
                  v-model="formData.mark"
                  placeholder="请选择状态"
                  style="width: 100%"
                >
                  <el-option
                    :label="_item.name"
                    :value="_item.value"
                    v-for="_item in markDescList"
                    :key="_item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="24">
              <el-form-item>
                <div slot="label" style="margin-left:10px;">备注</div>
                <el-input
                  type="textarea"
                  placeholder="请输入备注"
                  v-model="formData.remark"
                  maxlength="100"
                  show-word-limit
                  :autosize="{ minRows: 2, maxRows: 10 }"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </dia-model>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { customerPermission } from '@/utils/helper'
import {
  postMarketLoadRecords,
  postFollowSaveRecords,
  getFollowType
} from '@/api/table'
import { getCusFollowRecords } from '@/api/customerpond'
import {
  coustomerAdd,
  coustomerList,
  recordAll,
  recordCustomer
} from '@/api/user'
import diaModel from '@/components/Model/index'
import { getDetailAsignOpthon } from '@/api/thread'
import contactList from '@/views/customerpond/dialog/contactList'
import MobileAudio from '@/views/callsystem/mobileaudio'
export default {
  name: 'PondDetail',
  props: {
    path: {
      type: String,
      default: ''
    },
    customerInfoMap: {
      type: Object,
      default: () => {}
    },
    isActiveTab1: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: 'detail'
    }
  },
  data() {
    let validateMobile = (rule, value, fcb) => {
      if (!value) {
        fcb()
      }
      if (
        value &&
        value.toString().length === 11 &&
        value.toString().startsWith('1')
      ) {
        fcb()
      } else {
        fcb(new Error('手机号格式错误'))
      }
    }
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      allLoading: false,
      loadingBtn: false,
      markDescList: [],
      role: this.$store.getters.accountInfo.role,
      pondCond: [],
      creatFlow: '', // 跟进方式
      allFlow: [], // 后台返回跟进方式
      uploadUrl: '', // 返回图片地址
      followRecords: '', // 跟进记录文本
      activeTabs: 'all',
      srcList: '',
      showViewer: false, // 显示查看器
      chooFilterInput: [],
      chooFilterTime: this.formatDate(),
      formData: {
        mobile: '',
        contact: '',
        landLine: '',
        position: '',
        department: '',
        remark: '',
        wechat: '',
        email: '',
        mark: ''
      },
      addContactModel: {},
      contactList: [],
      rules: [{ validator: validateMobile, trigger: 'blur' }],
      selectedContactList: [],
      isActiveTab: null,
      packageId: 0,
      companyUuid: '',
      rowCont: {
        companyName: '',
        createUserName: '',
        createdAt: ''
      },
      majorId: 0,
      id: '',
      inPool: false
    }
  },
  components: {
    ElImageViewer,
    diaModel
  },
  created() {
    if (this.customerInfoMap.appCustomers) {
      this.id = this.customerInfoMap.appCustomers.companyUuid
      this.companyUuid = this.customerInfoMap.appCustomers.companyUuid
      this.packageId = this.customerInfoMap.appCustomers.packageId
      this.majorId = this.customerInfoMap.appCustomers.id
      this.rowCont = this.customerInfoMap.appCustomers
    }
    this.inPool = this.customerInfoMap.inPool
    this.isActiveTab = this.customerInfoMap.poolPosition + ''
    if (this.customerInfoMap.companyName) {
      this.id = this.customerInfoMap.companyUuid
      this.companyUuid = this.customerInfoMap.companyUuid
      this.packageId = this.customerInfoMap.packageId
      this.majorId = this.customerInfoMap.id
      this.rowCont = this.customerInfoMap
      this.inPool = true
      this.isActiveTab = this.isActiveTab1
    }
  },

  mounted() {
    this.activeChange()
    this.getFollowType()
    this.getContactList()
    this.$nextTick(() => {
      getDetailAsignOpthon().then(res => {
        this.markDescList = res.data
      })
    })
  },

  computed: {
    tags() {
      if (this.rowCont['perTags']) {
        return this.rowCont['perTags'].split(',')
      }
      return []
    },

    privateTags() {
      if (this.rowCont['privateTags']) {
        return this.rowCont['privateTags'].split(',')
      }
      return []
    },

    permissionList() {
      return this.$store.state.permission.permission
    },
    hasEditPermission() {
      let type = parseInt(this.customerInfoMap.poolPosition || 1)
      if (this.source === 'costomerpond') {
        type = parseInt(this.isActiveTab1 || 1)
      }
      return this.permissionList.includes(
        customerPermission(type, '填写跟进记录', this.source)
      )
    },

    hasViewPermission() {
      // debugger
      let type = parseInt(this.customerInfoMap.poolPosition || 1)
      if (this.source === 'costomerpond') {
        type = parseInt(this.isActiveTab1 || 1)
      }
      return this.permissionList.includes(
        customerPermission(type, '查看跟进记录', this.source)
      )
    }
  },

  methods: {
    handleMess(type) {
      if (type === 1) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-我负责的-查看跟进记录'
        )
      }
      if (type === 2) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-全公司的-查看跟进记录'
        )
      }
      if (type === 3) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-可跟进的-查看跟进记录'
        )
      }
      if (type === 4) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-草稿箱-查看跟进记录'
        )
      }
      if (type === 5) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-我协作的-查看跟进记录'
        )
      }
      return false
    },
    playRecord(ossUrl) {
      this.$store.dispatch('showModal', {
        title: '播放录音',
        view: MobileAudio,
        size: '600px',
        options: {
          row: {
            ossUrl: ossUrl,
            type: 'record'
          }
        }
      })
    },

    disabledDate(time) {
      return time.getTime() < Data.now() - 8.64e7
    },
    formatDate() {
      let date = new Date()
      let year = date.getFullYear() // 年
      let month = date.getMonth() + 1 // 月
      month = month < 10 ? '0' + month : month // 如果只有一位，则前面补零
      let day = date.getDate() // 日
      day = day < 10 ? '0' + day : day // 如果只有一位，则前面补零
      let week = date.getDay() // 星期
      let weekArr = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六'
      ]
      let hour = date.getHours() // 时
      hour = hour < 10 ? '0' + hour : hour // 如果只有一位，则前面补零
      let minute = date.getMinutes() // 分
      minute = minute < 10 ? '0' + minute : minute // 如果只有一位，则前面补零
      let second = date.getSeconds() // 秒
      second = second < 10 ? '0' + second : second // 如果只有一位，则前面补零
      return `${year}-${month}-${day} ${hour}:${minute}`
    },
    secondChoose() {
      console.log(this.chooFilterInput)
    },
    getContactList(fn) {
      const params = {
        companyUuid: this.id
      }
      coustomerList(params).then(res => {
        if (res.code === 60000) {
          this.contactList = res.data != null ? res.data : []
          typeof fn === 'function' ? fn() : ''
        }
      })
    },
    addContactBtn() {
      this.formData.wechat = this.formData.wechat.replace(
        /[\u4E00-\u9FA5]/g,
        ''
      )
      if (!this.formData.contact) {
        this.$message({
          message: '请输入姓名',
          type: 'error'
        })
        return
      }
      const params = {
        companyUuid: this.id,
        contact: this.formData.contact.replace(/(^\s*)|(\s*$)/g, ''),
        mobile: this.formData.mobile.replace(/(^\s*)|(\s*$)/g, ''),
        landLine: this.formData.landLine.replace(/(^\s*)|(\s*$)/g, ''),
        position: this.formData.position.replace(/(^\s*)|(\s*$)/g, ''),
        department: this.formData.department.replace(/(^\s*)|(\s*$)/g, ''),
        remark: this.formData.remark.replace(/(^\s*)|(\s*$)/g, ''),
        wechat: this.formData.wechat.replace(/(^\s*)|(\s*$)/g, ''),
        email: this.formData.email.replace(/(^\s*)|(\s*$)/g, ''),
        isKp: this.formData.isKp,
        mark: this.formData.mark
      }
      coustomerAdd(params).then(res => {
        if (res.code === 60000) {
          this.$refs.addContact.dialogVisible = false
          this.$message({
            message: '新建成功',
            type: 'success'
          })
          this.getContactList()
        }
      })
    },
    addContactDia() {
      // debugger
      this.$store.dispatch('showModal', {
        title: '选择联系人',
        view: contactList,
        size: '900px',
        options: {
          companyUuid: this.companyUuid,
          packageId: this.packageId,
          showSelect: true,
          isActiveTab: false,
          type: 'gjjl'
        },
        onClose: res => {
          console.log(res, 'res123')
          this.getContactList(() => {
            if (res.selectedList && res.selectedList.length) {
              this.selectedContactList = res.selectedList
              this.chooFilterInput = res.selectedList.map(item => item.id)
            }
          })
        }
      })
    },
    activeChange() {
      this.pondCond = []
      if (!this.id) {
        return
      }
      if (this.activeTabs == 'first') {
        this.getCusFollowRecords()
      }
      if (this.activeTabs == 'all') {
        this.getRecordAll()
      }
      if (this.activeTabs == 'second') {
        this.getRecordSystem()
      }
      if (this.activeTabs == 'third') {
        this.getRecordCustomer()
      }
    },
    previewImg(val) {
      this.showViewer = true
      this.srcList = val
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
    hasPermission(type) {
      if (this.role === 'admin') return true
      if (this.isActiveTab === '2') return false
      if (this.isActiveTab === '1') return true
    },
    getCusFollowRecords() {
      const params = {
        companyUuid: this.id
      }
      getCusFollowRecords(params).then(res => {
        if (res.code === 60000) {
          this.pondCond = res.data
          // console.log(this.pondCond)
        } else {
          this.pondCond = []
        }
      })
    },
    getRecordAll() {
      const params = {
        companyUuid: this.id
      }
      this.allLoading = true
      recordAll(params).then(res => {
        if (res.code === 60000) {
          this.pondCond = res.data
          // console.log(this.pondCond)
        } else {
          this.pondCond = []
        }
        this.allLoading = false
      })
    },
    getRecordSystem() {
      const params = {
        type: 2,
        companyUuid: this.id
      }
      recordCustomer(params).then(res => {
        if (res.code === 60000) {
          this.pondCond = res.data
        } else {
          this.pondCond = []
        }
      })
    },
    getRecordCustomer() {
      const params = {
        type: 3,
        companyUuid: this.id
      }
      recordCustomer(params).then(res => {
        if (res.code === 60000) {
          this.pondCond = res.data
        } else {
          this.pondCond = []
        }
      })
    },
    // 跟进记录上传图片
    postMarketLoadRecords(file) {
      const param = new FormData()
      param.append('file', file)
      param.append('enctype', 'multipart/form-data')
      const params = {
        file: file,
        type: 'image'
      }
      postMarketLoadRecords(param).then(res => {
        if (res.code === 60000) {
          this.uploadUrl = res.data.url
        }
      })
    },
    beforeUpload(file) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg'
      ) {
        this.postMarketLoadRecords(file)
      } else {
        this.$message({
          message: '只能上传png、jpeg、jpg格式图片',
          type: 'warning'
        })
      }

      return false
    },
    // 跟进记录提交
    submintBtn() {
      if (this.loadingBtn) return

      console.log(this.$store.getters.accountInfo)

      if (this.creatFlow === '') {
        return this.$message({
          message: '跟进方式不能为空',
          type: 'warning'
        })
      }
      if (!this.chooFilterTime) {
        return this.$message({
          message: '跟进时间不能为空',
          type: 'warning'
        })
      }
      if (this.followRecords === '') {
        return this.$message({
          message: '跟进记录不能为空',
          type: 'warning'
        })
      }

      const ids = this.selectedContactList.map(item => item.id).join(',')
      const params = {
        id: this.majorId,
        imageUrl: this.uploadUrl,
        followType: this.creatFlow,
        followRecords: this.followRecords,
        date: this.chooFilterTime + ':00',
        ids: ids
      }
      this.loadingBtn = true
      postFollowSaveRecords(params)
        .then(res => {
          this.loadingBtn = false
          if (res.code === 60000) {
            this.$message({
              message: '提交成功！',
              type: 'success'
            })
            this.uploadUrl = ''
            this.creatFlow = ''
            this.followRecords = ''
            this.chooFilterInput = []
            this.activeChange()
          }
        })
        .catch(() => {
          this.loadingBtn = false
        })
    },

    // 获取跟进类型
    getFollowType() {
      getFollowType({}).then(res => {
        if (res.code === 60000) {
          this.allFlow = res.data
          this.creatFlow = res.data[0]['name']
        }
      })
    },

    // 失去焦点
    onInputBlur() {
      this.$store.commit('update', {
        target: this.$store.state.app,
        data: {
          canScroll: true
        }
      })
    },

    // 获取焦点
    onInputFocus() {
      this.$store.commit('update', {
        target: this.$store.state.app,
        data: {
          canScroll: false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.searchTop {
  margin-top: 10px;
}

.pond-form-had {
  color: red;
}

.header-drawer {
  display: flex;
  justify-content: space-between;
  min-height: 45px;
  height: auto;
}

.search-botton {
  float: left;
  width: 80px;
  height: 30px;
  padding: 0 10px;
  color: $main-color;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}

.right-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #aaa;
}

.right-header p {
  font-size: 13px;
  height: 20px;
  line-height: 20px;
}

.right-header p:first-child {
  margin-top: 5px;
  margin-bottom: -12px;
}

.pond-cond-all {
  overflow: hidden;
  margin-top: 30px;
}

.pond-cond-right p span {
  font-size: 12px;
  vertical-align: top;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
}

.pond-cond-left {
  float: left;
  width: 70px;
}

.pond-cond-left p {
  font-size: 12px;
  vertical-align: top;
}

.pond-cond-right {
  float: left;
  width: calc(100% - 70px);
  border-left: 1px solid #e4e4e4;
  padding-left: 20px;
  padding-right: 10px;
  position: relative;
}

.pond-no-first {
  padding-top: 40px;
}

.pond-cond-posi {
  position: relative;
}

.pond-cond-had {
  position: relative;
}

.pond-cond-had::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #e4e4e4;
  top: 2px;
  left: -24px;
  border-radius: 4px;
}

.pond-cond-posi::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: red;
  top: 2px;
  left: -24px;
  border-radius: 4px;
}

.new_card_tag {
  display: inline-block;
  height: 16px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 16px;
  color: $main-color;
  margin-right: 10px;
  margin-top: 3px;
  background-color: #e7fffd;
  cursor: pointer;
}

.pond-detail-drawr {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.pond-from-flow {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.editor {
  width: 95%;
  margin-top: 10px;
}

.submitBtn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pond-avatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 10px;
}

.submit-btn {
  display: flex;
  justify-content: flex-end;
}

.previewImg {
  width: 100px;
  height: 100px;
  margin-top: 20px;
  object-fit: cover;
}

.uploadSvg {
  width: 30px;
  height: 30px;
  float: left;
  margin-left: 10px;
  object-fit: cover;
}

.pondDetail-cont {
  width: 100%;
  height: 30vh;
  margin-bottom: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.select-btn {
  position: absolute;
  color: $main-color;
  font-size: 12px;
  right: 70px;
  cursor: pointer;
  z-index: 99;
}

.phone-record {
  font-size: 12px;
  color: #999999;
}

.play-record {
  color: #0099cc;
  cursor: pointer;
}
</style>
