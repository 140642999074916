var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tools-main" },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "dt-permission",
              rawName: "v-dt-permission",
              value: "华为云代理商工具包-常用工具-查看",
              expression: "'华为云代理商工具包-常用工具-查看'",
            },
          ],
          attrs: { gutter: 40 },
        },
        _vm._l(_vm.list, function (item, index) {
          return _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { class: _vm.current === item.name ? "item current" : "item" },
              [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jump(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.name) + "\n          "
                    ),
                  ]
                ),
              ]
            ),
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c("a", {
        ref: "aLink",
        staticStyle: { display: "none" },
        attrs: { id: "c_order", href: "javascript:void(0)" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }