var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-manual-page" }, [
    _c(
      "div",
      { staticClass: "desc" },
      [
        _c("div", { staticClass: "item" }, [
          _vm._v("1、访问领鸟AI，系统很卡，是什么原因？"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _vm._v(
            "\n      下载测试工具到电脑桌面，双击后，截图发给领鸟客服或者销售顾问。工程师将根据截图显示的测试结果判断原因。\n    "
          ),
        ]),
        _vm._v(" "),
        _c("el-button", { on: { click: _vm.handleDownload } }, [
          _vm._v("文件下载"),
        ]),
        _vm._v(" "),
        _c("img", { attrs: { src: "", alt: "" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }