<template>
  <div class="store-map-info">
    <div class="name">{{ infoData.companyName }}</div>
    <div class="label">
      <span>地址</span>
      <span>{{ infoData.address }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PeStoreInfo',
  props: {
    infoData: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.store-map-info {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  width: 300px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #7e7e7e;

  .name {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
</style>
