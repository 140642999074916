<template>
  <div class="Filter-main" v-loading="loading">
    <div class="filter-item" v-for="item in filterList">
      <template v-if="item.field === 'parkRegion'">
        <div class="title">{{ item.name }}</div>
        <template v-if="!item.filterOptionList.length">
          <div
            style="display: flex;align-items: center;width: 100%;color: rgba(0,0,0,.4);padding-left: 70px;cursor: pointer"
            @click="tipClick"
          >
            未配置地域权限，请联系客服
          </div>
        </template>

        <template v-else>
          <div class="all" @click="selectAll(item)">全部</div>
          <div class="item">
            <span
              v-for="btn in item.filterOptionList"
              :class="btn.checked ? 'active' : ''"
              @click="userClick(btn, item)"
            >
              {{ btn.name }}
            </span>
          </div>
        </template>
      </template>

      <template v-else>
        <div class="title">{{ item.name }}</div>
        <div
          class="all"
          @click="selectAll(item)"
          v-show="item.field !== 'companyNum' && item.field !== 'area'"
        >
          全部
        </div>
        <div
          class="all"
          v-show="item.field === 'area' || item.field === 'companyNum'"
        ></div>
        <div class="item">
          <span
            v-for="btn in item.filterOptionList"
            :class="btn.checked ? 'active' : ''"
            @click="userClick(btn, item)"
          >
            {{ btn.name }}
          </span>
          <template v-if="item.field === 'area'">
            <div class="filter-input">
              <el-input
                v-model="areaNumLeft"
                @input="inputHandel('areaNumLeft')"
                size="small"
                placeholder="最小值"
              ></el-input>
              <div class="line-input"></div>
              <el-input
                v-model="areaNumRight"
                @input="inputHandel('areaNumRight')"
                size="small"
                placeholder="最大值"
              ></el-input>
              <el-button
                type="primary"
                size="small"
                style="margin-left: 10px"
                @click="confirmClick(item)"
                >确定</el-button
              >
            </div>
          </template>

          <template v-else-if="item.field === 'companyNum'">
            <div class="filter-input">
              <el-input
                v-model="cNumLeft"
                size="small"
                @input="inputHandel('cNumLeft')"
                placeholder="最小值"
              ></el-input>
              <div class="line-input"></div>
              <el-input
                v-model="cRight"
                size="small"
                @input="inputHandel('cRight')"
                placeholder="最大值"
              ></el-input>
              <el-button
                type="primary"
                size="small"
                style="margin-left: 10px"
                @click="confirmClick(item)"
                >确定</el-button
              >
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="filter-item">
      <div class="title">
        已选条件
      </div>
      <div class="item">
        <template v-for="(select, source) in selectList">
          <el-tag
            @close="removeSelect(btn, source)"
            v-for="btn in select"
            closable
          >
            {{ btn.name }}
          </el-tag>
        </template>
        <template
          v-if="
            Object.keys(selectList).length &&
              Object.values(selectList).flat().length
          "
        >
          <div
            @click="removeAll"
            class="remove"
            style="display: flex;align-items: center;height: 25px;font-size: 12px;background-color:rgba(0,0,0,.3);color: #ffffff;padding: 0 5px;box-sizing: border-box;cursor: pointer"
          >
            清除已选
          </div>
        </template>
      </div>
    </div>
    <div class="count" v-if="count">共{{ count }}家园区</div>
  </div>
</template>

<script>
import { parkInsightFilter } from '@/api/customerpond'
import moment from 'moment'
import tip from '@/views/insight/tip'
export default {
  name: 'ParkIndexFilter',
  data() {
    let _data = localStorage.getItem('ParkIndexFilter')
    if (_data) _data = JSON.parse(_data)
    return Object.assign(
      {
        loading: false,
        filterList: [],
        selectList: {},
        htTime: '',
        clTime: '',

        areaNumLeft: '',
        areaNumRight: '',
        cNumLeft: '',
        cRight: ''
      },
      _data || {},
      { loading: false }
    )
  },
  props: {
    count: {
      type: [String, Number],
      default: 0
    }
  },
  created() {
    let _data = localStorage.getItem('ParkIndexFilter')
    if (_data) _data = JSON.parse(_data)
    this.loading = false
    if (!_data || !_data?.filterList?.length) {
      this.getFilter()
    }
  },
  mounted() {},
  computed: {},
  methods: {
    inputHandel(type) {
      this[type] =
        this?.[type]?.replace(/\D/g, '')?.replace(/^0{1,}/g, '') || ''
    },

    tipClick() {
      this.$store.dispatch('showModal', {
        title: '提示',
        view: tip,
        size: '600px',
        options: {
          title: '扫码联系客服'
        }
      })
    },

    getRes() {
      const res = [].concat.apply([], Object.values(this.selectList))
      console.log(res)
      localStorage.setItem('ParkIndexFilter', JSON.stringify(this.$data))
      return {
        params: {
          parkIndusty:
            res
              .filter(i => i.key === 'parkIndusty')
              ?.map(ii => ii.value)
              ?.join(',') || null,
          parkRegion:
            res
              .filter(i => i.key === 'parkRegion')
              ?.map(ii => ii.name)
              ?.join(',') || null,
          area:
            this.areaNumRight && this.areaNumLeft
              ? `${this.areaNumLeft}-${this.areaNumRight}`
              : null,
          companyNum:
            this.cRight && this.cNumLeft
              ? `${this.cNumLeft}-${this.cRight}`
              : null
        },
        list: this.selectList
      }
    },

    getFilter() {
      this.loading = true
      parkInsightFilter()
        .then(res => {
          if (res.code === 60000) {
            this.filterList = res?.data || []
            this.filterList.forEach(item => {
              if (item.field === 'parkRegion') {
                this.$set(
                  item,
                  'filterOptionList',
                  item.filterOption?.['中国'] || []
                )
              }
              item.filterOptionList?.forEach(i => {
                i['key'] = item.field
              })
            })
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },

    selectAll(item) {
      // if (item.name === '获投时间' || item.name === '成立时间') return
      // this.selectList[item.name] = [...item.filterOptionList]
      // item.filterOptionList.forEach(item => {
      //   item['checked'] = true
      // })
      // this.$forceUpdate()
      // this.$emit('selectChange', this.getRes())
      this.selectList[item.name] = []
      delete this.selectList[item.name]
      item.filterOptionList.forEach(item => {
        item['checked'] = false
      })
      if (item.name === '获投时间' || item.name === '成立时间') {
        this.clTime = ''
        this.htTime = ''
      }
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    timeChange(value, item) {
      if (this.selectList[item.name]) {
        this.selectList[item.name][0].value = value
      } else {
        this.selectList[item.name] = [
          {
            checked: true,
            key: item.field,
            name: '自定义时间',
            value: value
          }
        ]
      }
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    confirmClick(item) {
      if (item.field === 'area') {
        if (!this.areaNumRight && !this.areaNumLeft) {
          this.$store.dispatch('showTip', { text: '请输入最大值或最小值' })
          return
        }
      } else if (item.field === 'companyNum') {
        if (!this.cRight && !this.cNumLeft) {
          this.$store.dispatch('showTip', { text: '请输入最大值或最小值' })
          return
        }
      }

      this.selectList[item.name] = [
        {
          checked: true,
          key: item.field,
          name: `最小${item.name}：${this[
            item.field === 'area' ? 'areaNumLeft' : 'cNumLeft'
          ] || '-'}，最大${item.name}：${this[
            item.field === 'area' ? 'areaNumRight' : 'cRight'
          ] || '-'}`,
          value: ''
        }
      ]

      this.$forceUpdate()

      this.$emit('selectChange', Object.assign({}, this.getRes()))
    },

    userClick(btn, source) {
      const hasOb = this.selectList[source.name]
      if (hasOb && hasOb.find(item => item.name === btn.name)) {
        this.selectList[source.name] = hasOb?.filter(i => i.name !== btn.name)
        btn.checked = false
      } else {
        // 判断是不是时间单选
        if (source.name === '获投时间' || source.name === '成立时间') {
          if (btn.name === '近三个月') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(3, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(3, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '最近半年') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(6, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(6, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '最近一年') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(1, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(1, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '最近三年') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(3, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(3, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '2022') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment('2022-01-01').format('YYYY-MM-DD'),
                  moment('2022-12-31').format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment('2022-01-01').format('YYYY-MM-DD'),
                  moment('2022-12-31').format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '2021') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment('2021-01-01').format('YYYY-MM-DD'),
                  moment('2021-12-31').format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment('2021-01-01').format('YYYY-MM-DD'),
                  moment('2021-12-31').format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '2020') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment('2020-01-01').format('YYYY-MM-DD'),
                  moment('2020-12-31').format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment('2020-01-01').format('YYYY-MM-DD'),
                  moment('2020-12-31').format('YYYY-MM-DD')
                ])
          }
          btn.value = source.name === '获投时间' ? this.htTime : this.clTime
          this.selectList[source.name] = [btn]
          this.filterList.forEach(i => {
            if (i.name === source.name) {
              i.filterOptionList.forEach(item => {
                if (item.name !== btn.name) item.checked = false
              })
            }
          })
          this.$forceUpdate()
        } else {
          this.selectList[source.name] = hasOb ? [...hasOb, btn] : [btn]
        }
        btn.checked = true
      }
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    removeSelect(select, name) {
      if (select.key !== 'area' || select.key !== 'companyNum') {
        this.selectList[name] = this.selectList[name].filter(
          i => i.name !== select.name
        )
      } else {
        this.$delete(this.selectList, name)
        // delete this.selectList[name]
      }
      if (select.key === 'htTime') this.htTime = null
      if (select.key === 'establishmentTime') this.clTime = null
      if (select.key === 'area') {
        this.areaNumRight = ''
        this.areaNumLeft = ''
      }
      if (select.key === 'companyNum') {
        this.cRight = ''
        this.cNumLeft = ''
      }
      select.checked = false
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    removeAll() {
      this.getFilter()
      this.selectList = {}
      this.htTime = null
      this.clTime = null
      this.areaNumLeft = ''
      this.areaNumRight = ''
      this.cRight = ''
      this.cNumLeft = ''
      this.filterList.forEach(item => {
        item.filterOptionList?.forEach(item => {
          item['checked'] = false
        })
      })
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped lang="scss">
.Filter-main {
  min-height: 200px;
  .count {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    color: #7a7a7a;
  }
  .filter-item {
    display: flex;
    font-size: 14px;
    padding: 10px 0;
    box-sizing: border-box;
    position: relative;
    &:after {
      content: '';
      display: inline-block;
      height: 1px;
      width: calc(100% - 150px);
      position: absolute;
      left: 150px;
      bottom: 0;
      transform: scaleY(0.5);
      background-color: rgba(0, 0, 0, 0.1);
    }
    .title {
      width: 100px;
      height: 25px;
      line-height: 25px;
    }
    .all {
      width: 50px;
      text-align: center;
      height: 25px;
      line-height: 25px;
      color: #41cabf;
      cursor: pointer;
    }
    .item {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .filter-input {
        margin-left: 20px;
        display: flex;
        align-items: center;
        .line-input {
          margin: 0 10px;
          height: 1px;
          width: 20px;
          background-color: gray;
          transform: scaleY(0.5);
        }
      }
      & > span {
        display: inline-block;
        min-width: 80px;
        width: auto;
        height: 25px;
        line-height: 25px;
        //background-color: forestgreen;
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        border-radius: 3px;
      }
      .active {
        color: #ffffff;
        background-color: #41cabf;
      }
    }
  }
}
</style>
