<template>
  <div class="register">
    <Header></Header>
    <div class="reser-title">欢迎注册领鸟云数据平台</div>
    <div class="register-form">
      <div class="business-title">
        企业客户画像<span
          style="color: #8A8A8A;font-size: 16px;margin-left: 20px;"
          >谁是你的客户？</span
        >
      </div>
      <div class="register-input1">
        <div style="width:209px;display:flex">
          <span style="display: inline-block;font-size:12px;color:red;">* </span
          >公司详情
        </div>
        <div class="input-form">
          <div class="input-form-line1">
            <el-select
              v-model="registerInfo.industry"
              @change="chooseRegisterIndustry"
              placeholder="行业"
              style="width:268px;height:40px;"
            >
              <el-option
                v-for="item in allIndustryArr"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
                <span style="color: #8492a6; font-size: 13px">{{
                  item.name
                }}</span>
              </el-option>
            </el-select>
            <!-- <el-input v-model="registerInfo.product" placeholder="产品" style="width:268px;height:40px;margin-left: 35px;" /> -->
            <span
              class="error"
              v-if="errorMsg.industryMsg || errorMsg.productMsg"
              >{{ errorMsg.industryMsg || errorMsg.productMsg }}</span
            >
          </div>

          <!--          <div style="margin-top:32px" class="input-form-line3">
            <el-select v-model="registerInfo.persons" placeholder="公司人数" style="width:168px;height:40px;">
              <el-option
                v-for="item in ddic.SN_01"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
                <span style="color: #8492a6; font-size: 13px">{{ item.name }}</span>
              </el-option>
            </el-select>
            <el-select v-model="registerInfo.scale" placeholder="业务类型" style="width:168px;height:40px;margin-left: 34px;">
              <el-option
                v-for="item in ddic.BS_01"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
                <span style="color: #8492a6; font-size: 13px">{{ item.name }}</span>
              </el-option>
            </el-select>
            <el-select v-model="registerInfo.mode" placeholder="融资规模" style="height:40px;width:168px;margin-left: 34px;">
              <el-option
                v-for="item in ddic.VC_01"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
                <span style="color: #8492a6; font-size: 13px">{{ item.name }}</span>
              </el-option>
            </el-select>
            <span class="error" v-if="errorMsg.scaleMsg || errorMsg.modeMsg || errorMsg.personsMsg">
                  {{errorMsg.scaleMsg || errorMsg.modeMsg || errorMsg.personsMsg}}</span>
          </div> -->
        </div>
      </div>
      <div
        style="margin-top:20px;margin-left:300px;"
        v-if="industryList && industryList.length"
      >
        <div style="width:571px;height:94px;overflow:hidden;">
          <div class="sub-class-cont">
            <table class="indury-sub-class">
              <tr v-for="(item, index) in industryList" :key="index">
                <td
                  v-for="(ite, ind) in item"
                  :key="ind"
                  class="choose-sub-class"
                  @click="chooseSubBtn(ite.code)"
                >
                  <span>{{ ite.name }}</span>
                  <i
                    :class="
                      chooseSubClass.indexOf(ite.code) >= 0
                        ? 'choose-sub-iocn'
                        : ''
                    "
                  ></i>
                </td>
                <!-- <td >
                  
                </td>
                <td>3</td>
                <td>4</td>
                <td>5</td> -->
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="register-input2">
        <div style="width:209px">
          <span style="display: inline-block;font-size:12px;color:red;">* </span
          >客户特征补充
        </div>
        <el-input
          class="no-change-color"
          type="textarea"
          v-model="registerInfo.characte"
          :rows="2"
          placeholder="客户特征补充"
          style="width:571px;height:80px;margin-left:20px;"
        ></el-input>
        <span class="error" v-if="errorMsg.characteMsg">{{
          errorMsg.characteMsg
        }}</span>
      </div>
      <div class="register-input2" style="margin-top: 39px;">
        <div style="width:209px">
          <span style="display: inline-block;font-size:12px;color:red;">* </span
          >请列举2-3个你的成交客户
        </div>
        <el-input
          class="no-change-color"
          type="textarea"
          v-model="registerInfo.example"
          :rows="2"
          placeholder="请列举2-3个你的成交客户"
          style="width:571px;height:80px;margin-left:20px;"
        ></el-input>
        <span class="error" v-if="errorMsg.exampleMsg">{{
          errorMsg.exampleMsg
        }}</span>
      </div>
      <div class="register-input2" style="margin-top: 35px;">
        <div style="width:209px">
          <span style="display: inline-block;font-size:12px;color:red;">* </span
          >客户聚集平台链接
        </div>
        <el-input
          placeholder="例如：1688.com"
          v-model="registerInfo.link"
          style="width:571px;margin-left:20px;"
        ></el-input>
        <span class="error" v-if="errorMsg.linkMsg">{{
          errorMsg.linkMsg
        }}</span>
      </div>
      <div class="register-bt">
        <el-button
          type="primary"
          @click="registerBt"
          v-loading="loading"
          style="width:350px;height:46px;bacground:#41CABF;font-size:14px;"
          >提交注册</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../header.vue'
import { getAddress, userCompanyCoustomer, getDiccIndustry } from '@/api/user'
import { setUserStep } from '@/utils/auth'
export default {
  components: {
    Header
  },
  data() {
    return {
      loading: false,
      errorMsg: {
        industryMsg: '',
        productMsg: '',
        characteMsg: '',
        exampleMsg: '',
        linkMsg: ''
      },
      registerInfo: {
        industry: '',
        product: '',
        characte: '',
        example: '',
        link: ''
      },
      ddic: {},
      checkSubClass: [],
      allIndustryArr: [],
      industryList: [],
      chooseSubClass: []
    }
  },
  mounted() {
    this.getDiccIndustry()
  },
  methods: {
    // 点击选中
    chooseSubBtn(val) {
      if (this.chooseSubClass.indexOf(val) >= 0) {
        this.chooseSubClass.splice(this.chooseSubClass.indexOf(val), 1)
      } else {
        this.chooseSubClass.push(val)
      }
    },
    // 选择行业触发行业字典、
    chooseRegisterIndustry(val) {
      this.chooseSubClass = []
      for (var i in this.allIndustryArr) {
        if (this.allIndustryArr[i].code === val) {
          const arr = this.allIndustryArr[i].children
          if (arr && arr.length) {
            this.industryList = []
            for (var j = 0; j < arr.length; j += 5) {
              this.industryList.push(arr.slice(j, j + 5))
            }
          } else {
            this.industryList = []
          }
        }
      }
    },

    // 获取客户行业字典
    getDiccIndustry() {
      getDiccIndustry({}).then(res => {
        if (res.code === 60000) {
          this.allIndustryArr = res.data
        }
      })
    },
    registerBt() {
      this.errorMsg = {}

      if (!this.registerInfo.industry) {
        this.errorMsg.industryMsg = '请选择行业'
        return
      }

      // if(!this.registerInfo.product){
      //   this.errorMsg.productMsg = "请输入产品"
      //   return;
      // }

      if (!this.registerInfo.characte) {
        this.errorMsg.characteMsg = '请填写客户特征'
        return
      }

      if (!this.registerInfo.example) {
        this.errorMsg.exampleMsg = '请填写成交的客户'
        return
      }

      if (!this.registerInfo.link) {
        this.errorMsg.linkMsg = '客户聚集平台链接'
        return
      }

      this.loading = true

      let data = {
        industryId: this.registerInfo.industry,
        // product:this.registerInfo.product,
        feature: this.registerInfo.characte,
        exampleCustomer: this.registerInfo.example,
        platform: this.registerInfo.link,
        classificationIds: this.chooseSubClass.toString()
      }

      userCompanyCoustomer(data)
        .then(res => {
          this.loading = false
          if (res.code == 60000) {
            this.$message({
              message: '企业画像录入成功',
              type: 'success'
            })
            this.$store.dispatch('changeStep', 3)
            setUserStep(3)
            this.$router.replace({ path: '/buypay' })
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
.choose-sub-class {
  position: relative;
}
.choose-sub-iocn {
  display: inline-block;
  position: absolute;
  width: 22px;
  height: 20px;
  background: url('../../../assets/pay-icon.png') no-repeat;
  background-size: 100% 100%;
  bottom: 0;
  right: 0;
}
.sub-class-cont {
  width: 582px;
  /* height: 100%; */
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* border-left: 1px solid #C1BFBF; */
}
.indury-sub-class {
  width: 571px;
  border: 1px solid red;
  border-collapse: collapse;
}
.indury-sub-class tr {
  height: 30px;
  padding: 0 10px;
}
.indury-sub-class td {
  cursor: pointer;
  text-align: center;
  width: 100px;
  color: #41cabf;
  border: 1px solid #c1bfbf;
}
.choose-sub-class span {
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100px;
  height: 100%;
  margin-top: 3px;
}
.register ::v-deep .el-register__body {
  padding: 0px;
}
.register ::v-deep .el-input__inner {
  border-radius: 0;
  padding: 0 21px;
  border: 1px solid #c0bebe;
  color: #2e2e2e;
}
.register ::v-deep .el-button {
  border-radius: 0;
}
.register ::v-deep .el-textarea__inner {
  border-radius: 0;
  height: 80px;
  padding: 5px 21px !important;
  border: 1px solid #c0bebe;
  color: #2e2e2e;
}

.register ::v-deep .el-input__inner:focus,
.register ::v-deep .el-textarea__inner:focus,
input:focus {
  border-color: #41cabf;
}

.register ::v-deep .el-input__inner::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #8f8f8f;
}

.register ::v-deep .el-input__inner:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #8f8f8f;
}
.register ::v-deep .el-input__inner::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #8f8f8f;
}
.register ::v-deep .el-input__inner:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #8f8f8f;
}

.register {
  height: 100%;
  min-height: 100%;
  width: 100%;
  background-color: #f5f6f7;
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
}
.reser-title {
  width: 100%;
  margin-top: 50px;
  font-size: 30px;
  color: #494949;
  font-weight: 600;
  text-align: center;
}

.register-form {
  width: 1200px;
  background: #ffffff;
  margin-top: 50px;
  margin-bottom: 39px;
}
.business-title {
  font-size: 20px;
  font-weight: 550;
  color: #494949;
  margin: 0 50px 0 50px;
  padding: 32px 0 32px 20px;
  border-bottom: 1px solid #c1bfbf;
  display: flex;
  align-items: center;
}
.register-input1,
.register-input2 {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-top: 35px;
  padding-left: 70px;
}

.register-input2 {
  margin-top: 41px !important;
}

.input-form {
  margin-left: 20px;
  font-size: 14px;
}

.input-form-line1,
.input-form-line2,
.input-form-line3 {
  display: flex;
}

.error {
  font-size: 14px;
  color: #ff0000;
  margin-left: 40px;
  line-height: 40px;
  font-weight: 550;
}

.register-bt {
  text-align: center;
  margin-top: 51px;
  padding-bottom: 30px;
}

.register-bt ::v-deep .el-button--primary {
  background-color: #41cabf;
  border-color: #41cabf;
}

button:active {
  opacity: 0.8;
}
</style>
