<template>
  <div class="customerView-main">
    <DtScroll margin="0" v-dt-permission="'扩展应用-客群洞察-查看'">
      <div class="header">
        <div class="title">
          <div class="left-1">扩展应用</div>
          <div class="left-2">客群洞察</div>
        </div>
        <div class="package">
          <div class="name">目标客群</div>
          <div class="list">
            <div
              class="item"
              @click="changePackage(item)"
              v-for="item in packageList"
              :style="{
                borderColor:
                  currentPackage.id === item.id ? '#41cabf' : 'rgba(0,0,0,.1)'
              }"
            >
              <div class="item-main-row">
                <div class="icon">
                  <svg-icon
                    :icon-class="
                      currentPackage.id === item.id ? 'khdc-ac' : 'khdc'
                    "
                    class="icon"
                  ></svg-icon>
                </div>
                <div class="desc">
                  <div class="name">{{ item.packageName }}</div>
                  <div class="num">共{{ item.count }}家企业</div>
                </div>
              </div>
            </div>
            <div class="item no-package-data" v-if="!packageList.length">
              暂无数据
            </div>
          </div>
        </div>
      </div>
      <div class="main-row">
        <el-tabs v-model="activeTab" @tab-click="tabClick">
          <el-tab-pane label="客户洞察" name="1" />
          <el-tab-pane label="客群明细" name="2" />
        </el-tabs>
        <main v-if="activeTab === '1'">
          <div class="chart" :key="key">
            <el-row :gutter="10">
              <el-col
                :span="12"
                v-for="(item, key) in chartListObj"
                style="margin-bottom: 10px"
              >
                <el-card shadow="hover">
                  <div slot="header" class="chart-header">
                    <div class="name">{{ item.name }}</div>
                    <div
                      :class="i.active ? 'icon active-color' : 'icon'"
                      @click="changeType(i.type, item, key)"
                      v-for="i in item.btnList"
                    >
                      <i :class="i.icon"></i>
                    </div>
                  </div>
                  <div class="item">
                    <template v-if="key === 'region'">
                      <dtMap
                        :map-data="item.data.mapData"
                        :excluded-ad-codes="item.data.excludedAdCodes"
                      ></dtMap>
                      <div class="null-data" v-if="item.data.nullNum">
                        <span> {{ item.data.nullNum }} 家未知 </span>
                      </div>
                    </template>
                    <template v-else>
                      <dtChart
                        :chart-data="item.data"
                        :direction="item.direction"
                        :chart-type="
                          item.btnList.find(item => item.active).type
                        "
                      ></dtChart>
                    </template>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </main>
        <main v-if="activeTab === '2'">
          <div
            class="key-list"
            ref="keyList"
            :style="{ minHeight: `${listHeight}px` }"
          >
            <packageKeyList
              :package="currentPackage"
              :min-height="listHeight"
            />
          </div>
        </main>
      </div>
    </DtScroll>
  </div>
</template>

<script>
import DtScroll from '@/components/element/DtScroll'
import dtMap from '@/views/extendApp/dialog/dtMap'
import dtChart from '@/views/extendApp/dialog/dtChart'
import packageKeyList from '@/views/extendApp/dialog/packageKeyList'
import address from '@/views/customerpond/map/address'

import { packageInsight, insightMould } from '@/api/customerpond'
import { package_list } from '@/api/thread'

export default {
  name: 'customerView',
  components: {
    DtScroll,
    dtMap,
    dtChart,
    packageKeyList
  },
  data() {
    return {
      listHeight: 0,
      key: 0,
      activeTab: '1',
      currentPackage: {},
      packageList: [],
      chartList: []
    }
  },
  async mounted() {
    await this.getPackageList()
    await this.getMouldList()
    if (localStorage.getItem('beforePath').indexOf('/keydetail/keydetail') >= 0)
      this.activeTab = '2'
    this.chartInit()
  },
  computed: {
    chartListObj() {
      const res = Object.create(null)
      this.chartList.forEach(item => {
        res[item.key] = item
      })
      return res
    }
  },
  methods: {
    chartInit() {
      this.chartList.forEach(item => {
        if (item.key !== 'region') {
          const type = item.btnList.find(i => i.active)?.type || 0
          this.getChartData(item.key, type, item)
        }
      })
    },

    listInit() {},

    tabClick() {
      this.activeTab === '1' ? this.chartInit() : this.listInit()
      this.$nextTick(() => {
        const top = this.$refs.keyList?.getBoundingClientRect()?.top || 353
        this.listHeight = window.innerHeight - top - 10
      })
    },

    getPackageList() {
      return new Promise((resolve, reject) => {
        package_list({ insight: 1 }).then(res => {
          if (res.code === 60000) {
            this.packageList = res?.data || []
            this.currentPackage = this.packageList?.[0] || {}
            if (this.packageList.length) {
              resolve()
            }
          } else {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'error'
            })
          }
        })
      })
    },

    getIcon(name) {
      const icon = {
        bar_graph: 'el-icon-s-data',
        pie_chart: 'el-icon-pie-chart',
        line_graph: 'el-icon-s-data'
      }
      return icon[name] || ''
    },

    getMouldList() {
      return new Promise((resolve, reject) => {
        insightMould({ packId: this.currentPackage.id }).then(res => {
          if (res.code === 60000) {
            console.log(res)
            let hasRegion = false
            let list = (res?.data || []).map(item => {
              if (item.module_api === 'province') hasRegion = true
              return {
                name: item.module_title,
                key: item.module_api,
                btnList: (item.chart?.split(',') || []).map(i => {
                  return {
                    type: i,
                    active: i === item.default_chart,
                    icon: this.getIcon(i)
                  }
                }),
                direction: item.direction,
                data: []
              }
            })
            if (hasRegion) {
              list = [
                {
                  name: '地域分布',
                  key: 'region',
                  btnList: [],
                  data: {
                    mapData: [],
                    excludedAdCodes: [],
                    nullNum: 0
                  }
                },
                ...list
              ]
            }
            this.chartList = list
            resolve()
          }
        })
      })
    },

    changeType(type, target, key) {
      this.getChartData(key, type, target, () => {
        target.btnList.find(item => item.type === type).active = true
        target.btnList.find(item => item.type !== type).active = false
      })
    },

    getChartData(key, type, item, fn) {
      const params = {
        packageTable: this.currentPackage?.packageTable || null,
        type: key
      }
      packageInsight(params)
        .then(res => {
          if (res.code === 60000) {
            item.data = this.formatData(type, res?.data || [])
            if (key === 'province') {
              const target = this.chartList.find(i => i.key === 'region').data
              target.mapData = res?.data || []
            }
            this.$forceUpdate()
            typeof fn === 'function' ? fn() : ''
          }
        })
        .catch(err => {
          this.$store.dispatch('showTip', {
            text: '出错了',
            type: 'error'
          })
        })
    },

    handleRemoveMarkerNull(data, target) {
      const codeList = Object.keys(address)
      codeList.splice(0, 1)
      target.excludedAdCodes = codeList
        .filter(item => !data.includes(item.substring(0, 2)))
        .map(_item => {
          return parseInt(_item)
        })
      this.$forceUpdate()
    },

    formatData(type, data) {
      return data
        .map(item => {
          return {
            key: item.name,
            value: parseInt(item.sum)
          }
        })
        .sort((a, b) => {
          return b.value - a.value
        })
    },

    changePackage(item) {
      this.currentPackage = item
      this.$forceUpdate()
      this.key += 1
      this.$nextTick(() => {
        this.chartInit()
      })
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped lang="scss">
.customerView-main {
  height: 100%;
  width: 100%;

  .header {
    background-color: #fff;
    padding: 10px 10px 0 10px;
    margin-bottom: 10px;
    box-sizing: border-box;

    .title {
      user-select: none;
      height: 40px;
      font-size: 12px;
      position: relative;
      background-color: #abe3e3;

      & > div {
        height: 100%;
        position: absolute;
        top: 0;
      }

      .left-1 {
        left: 0;
        background-color: #00aaaa;
        width: 80px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left-2 {
        left: 80px;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding-left: 20px;
      }
    }

    .package {
      padding-top: 20px;
      box-sizing: border-box;

      .name {
        font-size: 16px;
        padding-bottom: 10px;
        box-sizing: border-box;
      }

      .list {
        width: 100%;
        overflow-x: auto;
        padding: 0 20px;
        box-sizing: border-box;
        white-space: nowrap;
        height: 120px;

        & > .item:last-child {
          margin-right: 20px !important;
        }

        .no-package-data {
          width: 80% !important;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.3);
          display: flex !important;
          align-items: center;
          justify-content: center;
          border: none !important;
        }

        .item {
          display: inline-block;
          width: 300px;
          height: 100px;
          margin: 0 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          //margin-bottom: 10px;
          .item-main-row {
            height: 100%;
            width: 100%;
            display: flex;
            cursor: pointer;

            .icon {
              width: 70px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 35px;
              color: rgba(0, 0, 0, 0.1);
            }

            .desc {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-right: 10px;
              box-sizing: border-box;

              .name {
                width: 100%;
                font-size: 16px;
                font-weight: bolder;
                white-space: pre-wrap;
                word-break: break-word;
              }

              .num {
                font-size: 12px;
                font-weight: bolder;
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
      }
    }
  }

  .main-row {
    background-color: #fff;

    .key-list {
      background-color: #fff;
      height: auto;
    }

    .chart {
      padding: 0 10px;
      box-sizing: border-box;

      .chart-header {
        display: flex;
        align-items: center;

        .name {
          font-size: 16px;
        }

        .icon {
          font-size: 20px;
          color: #8a8a8a;
          cursor: pointer;
        }

        .active-color {
          color: #41cabf;
        }

        & > div {
          margin-right: 10px;
        }
      }

      .item {
        height: 300px;
        position: relative;

        .null-data {
          position: absolute;
          z-index: 99;
          right: 0;
          bottom: 0;
          font-size: 12px;
          color: #fff;
          padding: 10px;
          box-sizing: border-box;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.customerView-main {
  .main-row {
    .el-tabs__item {
      height: 50px !important;
      width: 150px !important;
      text-align: center;
      line-height: 50px !important;
      font-size: 16px !important;
      user-select: none !important;
    }

    .el-tabs__active-bar {
      width: 150px !important;
    }

    .el-card__body {
      padding: 0 !important;
    }

    .el-card__header {
      padding: 10px 20px !important;
    }
  }
}
</style>
