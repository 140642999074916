<template>
  <div id="waihu-panel">
    <Workflow>
      <div slot="waiHu" slot-scope="scope">
        <template v-if="bandWaihuType === 'webrtc'">
          <iframe
            :src="
              '/edb/html/webmain.html?loginName=' +
                bandWaihuAccount +
                '&_t=' +
                _t +
                '&isAutoCall=' +
                openAutoCallTask +
                '&waihuAccountDomain=' +
                waihuAccountDomain +
                '&password=' +
                bandWaihuPassword +
                '&loginType=sip&agentStatus=0&asignOption=' +
                encodeURIComponent(JSON.stringify(asignOption)) +
                '&phone=' +
                phone
            "
            frameborder="0"
            ref="webmain"
            id="webmain"
            width="100%"
            style="height: 230px"
            v-if="bandWaihuType == 'webrtc'"
          ></iframe>
        </template>

        <template v-else-if="bandWaihuType === 'simPhone'">
          <div class="sim-phone">
            <div class="no-call" v-show="!showJt && simStatus == 0">
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-input
                    size="small"
                    placeholder="请输入号码"
                    v-model="simPhone"
                  ></el-input>
                </el-col>
                <el-col :span="4">
                  <el-button
                    icon="el-icon-phone"
                    circle
                    type="primary"
                    @click="simPhoneCall(simPhone, '', '')"
                  ></el-button>
                </el-col>
                <el-col :span="4" v-show="showHangUp">
                  <el-button
                    icon="el-icon-phone"
                    circle
                    type="danger"
                    @click="hangUpSim"
                  ></el-button>
                </el-col>
              </el-row>
            </div>
            <div class="call-ing" v-show="!showJt && simStatus == 3">
              <el-row :gutter="20">
                <el-col :span="16">
                  <span class="phone-num">{{ simPhone }}</span>
                  <span v-if="contact" class="phone-name">{{ contact }}</span>
                  <span class="phone-time">
                    {{ phoneTime ? `通话中:${phoneTime}` : '00:00:00' }}</span
                  >
                </el-col>
                <el-col :span="4">
                  <el-button
                    icon="el-icon-phone"
                    circle
                    type="danger"
                    @click="hangUpSim"
                  ></el-button>
                </el-col>
                <el-col :span="4">
                  <el-dropdown
                    placement="bottom-start"
                    trigger="click"
                    @command="changeAsign($event)"
                  >
                    <el-button size="small" type="primary">标记</el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        :command="item.value"
                        v-for="item in asignOption"
                        >{{ item.name }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <div v-show="showJt" class="call-jt">
              <el-button
                size="small"
                type="primary"
                @click="simJt"
                :disabled="btnLoading"
              >
                接听
              </el-button>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="sim-phone">
            <div class="no-call" v-show="state === '0'">
              <el-row :gutter="20">
                <el-col :span="20">
                  <el-input
                    size="small"
                    placeholder="请输入号码"
                    v-model="simPhone"
                  ></el-input>
                </el-col>
                <el-col :span="4">
                  <el-button
                    icon="el-icon-phone"
                    circle
                    type="primary"
                    @click="callUp(simPhone, '', '')"
                  ></el-button>
                </el-col>
              </el-row>
            </div>
            <div class="call-ing" v-show="state === '1'">
              <el-row :gutter="20">
                <el-col :span="20">
                  <span class="phone-num">{{ simPhone }}</span>
                  <span v-if="contact" class="phone-name">{{ contact }}</span>
                  <span class="phone-time">{{ callStatus }}</span>
                </el-col>
                <el-col :span="4">
                  <el-button
                    icon="el-icon-phone"
                    circle
                    type="danger"
                    @click="hangUp('0')"
                  ></el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </template>

        <dia-model
          ref="showmsg"
          :dialogStatus="dialogStatus"
          @submitContBtn="submitContBtn"
          style="z-index: 2010"
        >
          <div slot="modalCont" style="height:40px;line-height:40px;">
            {{ showmsg }}
          </div>
        </dia-model>
      </div>
    </Workflow>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import PayCard from '@/components/PayCard/index'
import DtPlugins from '@/components/element/DtPlugins'
import { postWebrtc, postSim, canCall, callSlot } from '@/api/thread'
import { newPhoneHangUp } from '@/api/call'
import Workflow from '@/components/PhoneStrip/Workflow'
export default {
  name: 'webrtc',
  props: {
    asignOption: {
      type: Array,
      default: () => []
    },
    state: {
      type: String,
      default: '0'
    },
    callStatus: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
    companyName: {
      type: String,
      default: ''
    }
  },
  components: {
    diaModel,
    Workflow
  },
  data() {
    return {
      input: '',
      bandWaihuAccount: '',
      bandWaihuPassword: '',
      waihuAccountDomain: '', // add new.
      bandWaihuType: '',
      webmain: {},
      loadState: false,
      dialogStatus: {},
      showmsg: '',
      _t: 10,

      simPhone: '',
      // wsObj: null,
      wsAddress: 'ws://127.0.0.1:8800/',
      wsTimeObj: null,
      phoneTime: '',
      timer: null,
      btnLoading: false,
      ready: false,
      companyNameSim: '',
      contact: '',
      showJt: false,
      t: 0,
      showCount: false,

      showHangUp: false
    }
  },
  computed: {
    simStatusText() {
      const map = {
        '10086': '初始化中',
        '1': '来电',
        '3': '接通',
        '-12': '设备断开连接',
        '10010': '已连接',
        '0': '已连接',
        '-00': '连接中断'
      }
      return map[this.simStatus.toString()] || ''
    },
    openAutoCallTask() {
      return this.$store.state.plugin.openAutoCallTask
    },
    autoCallCurrent() {
      return this.$store.state.plugin.autoCallCurrent
    },

    openAutoCallTime() {
      return this.$store.state.plugin.openAutoCallTime || 10
    },

    accountInfo() {
      return this.$store.state.user.accountInfo || {}
    },

    wsObj() {
      return this.$store.state.phoneSocket.wsObj
    },

    simStatus() {
      return this.$store.state.phoneSocket.simStatus
    },
    simIndex() {
      return this.$store.state.phoneSocket.simIndex
    },
    eventType() {
      return this.$store.state.phoneSocket.eventType
    },
    sessionId() {
      return this.$store.state.phoneSocket.sessionId
    }
  },
  created() {
    this._t = this.$store.state.plugin.openAutoCallTime
    this.t = this._t
    let accountInfo = localStorage.getItem('accountInfo')
      ? JSON.parse(localStorage.getItem('accountInfo'))
      : {}
    this.bandWaihuAccount = accountInfo.bandWaihuAccount
      ? accountInfo.bandWaihuAccount.trim()
      : ''
    this.bandWaihuPassword = accountInfo.bandWaihuPassword
      ? accountInfo.bandWaihuPassword.trim()
      : ''
    this.bandWaihuType = accountInfo.bandWaihuType
    this.waihuAccountDomain = accountInfo.waihuAccountDomain
      ? accountInfo.waihuAccountDomain.trim()
      : '' // add new
    console.log(accountInfo)
    window.addEventListener('message', this.handleMessage)
  },
  mounted() {
    this.$nextTick(() => {
      // this.dragFunc()
      if (this.bandWaihuType == 'simPhone') {
        this.createWsSocket()
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
    if (this.wsTimeObj) {
      clearInterval(this.wsTimeObj)
      this.wsTimeObj = null
    }
  },
  methods: {
    dragFunc() {
      let Drag = document.getElementById('waihu-panel')
      Drag.onmousedown = function(event) {
        let ev = event || window.event
        event.stopPropagation()
        let disX = ev.clientX - Drag.offsetLeft
        let disY = ev.clientY - Drag.offsetTop
        document.onmousemove = function(event) {
          let ev = event || window.event
          Drag.style.left = ev.clientX - disX + 'px'
          Drag.style.top = ev.clientY - disY + 'px'
          Drag.style.cursor = 'move'
        }
      }
      Drag.onmouseup = function() {
        document.onmousemove = null
        this.style.cursor = 'default'
      }
    },
    closePanel() {
      this.$bus.$emit('closePanel')
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          openAutoCallTask: false
        }
      })
    },
    sendCall(phone, companyName, contact) {
      if (this.$refs.webmain) {
        this.webmain = this.$refs.webmain.contentWindow
        let that = this
        if (phone) {
          if (this.loadState) {
            that.webmain.postMessage(
              {
                cmd: 'sendCall',
                params: {
                  phone,
                  companyName,
                  contact
                }
              },
              '*'
            )
          } else {
            setTimeout(() => {
              this.sendCall(phone, companyName, contact)
            }, 400)
          }
        }
      }
    },
    handleMessage(event) {
      const data = event.data
      switch (data.cmd) {
        case 'returnData':
          this.loadState = true
          break
        case 'sendbj':
          // console.log("123",data.params)
          this.$emit(
            'changeAsign',
            data.params.id,
            data.params.phone,
            data.params.companyName
          )
          break
        case 'callId':
          this.$emit(
            'postCallId',
            data.params.data,
            data.params.phone,
            data.params.companyname,
            data.params.contact
          )
          break
        case 'showmsg':
          if (data.params.msg == '当前会话已失效') {
            this.openModel(data.params.msg)
          } else {
            this.$message({
              message: data.params.msg,
              type: 'error'
            })
          }
          break
        case 'startNext':
          this.$bus.$emit('autoCallTask', {})
      }
    },
    openModel(msg = '您的『精准查KP』余额不足，请联系管理员。') {
      this.showmsg = msg
      this.dialogStatus = {
        dialogWidth: '350px',
        dialogTitle: '提示',
        showFoot: true,
        dialogTop: '200px',
        singleBtn: true,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.showmsg.init()
      })
    },
    submitContBtn() {
      this.closePanel()
    },
    callUp() {
      this.$emit('callUp', this.simPhone)
    },
    changeAsign(mark) {
      if (this.bandWaihuType == 'simPhone') {
        this.phone = this.simPhone
        this.companyName = this.companyNameSim
      }
      this.$emit('changeAsign', mark, this.simPhone, this.companyName)
    },
    hangUp() {
      if (this.accountInfo?.bandWaihuType === 'newLocal') {
        newPhoneHangUp({ sessionId: this.sessionId }).then(res => {
          if (res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: '挂机指令已发出',
              type: 'success'
            })
          }
        })
        // if(this.eventType === 'answer'){
        //   // todo 挂机操作
        //   newPhoneHangUp({sessionId: this.sessionId}).then(res => {
        //     if(res.code === 60000){
        //       this.$store.dispatch('showTip', {
        //         text: '挂机指令已发出',
        //         type: 'success'
        //       })
        //     }
        //   })
        // }else {
        //   this.$store.dispatch('showTip', {
        //     text: '当前状态不可挂机',
        //     type: 'error'
        //   })
        // }
        return
      }
      this.$emit('hangUp')
    },

    connectWs() {
      this.wsTimeObj = null
      this.timer = null

      this.$store.commit('update', {
        target: this.$store.state.phoneSocket,
        data: {
          simStatus: '10086',
          wsObj: null
        }
      })

      // this.createWsSocket()
      this.$store.dispatch('createSocket')
    },

    createWsSocket(fn) {
      this.wsObj.onmessage = data => {
        const msg = JSON.parse(data.data)

        console.log(msg)

        if (msg.cmd === 'USB') {
          msg.connected ? (this.ready = true) : (this.ready = false)
          if (msg.connected) {
            this.$store.commit('update', {
              target: this.$store.state.phoneSocket,
              data: {
                simStatus: '0'
              }
            })
          } else {
            this.$store.commit('update', {
              target: this.$store.state.phoneSocket,
              data: {
                simStatus: '10086'
              }
            })
          }
        }

        if (msg.cmd === 'getSimIndex') {
          this.$store.commit('update', {
            target: this.$store.state.phoneSocket,
            data: {
              simIndex: msg.value
            }
          })
        }

        if (msg.cmd === 'endChgSim') {
          this.$store.commit('update', {
            target: this.$store.state.phoneSocket,
            data: {
              simIndex: msg.simIndex
            }
          })
        }

        if (msg.cmd === 'changeSim') {
          if (!msg.success) {
            this.$store.dispatch('showTip', {
              text: '切换sim卡失败，请确认该卡槽sim卡安装是否正确',
              type: 'error'
            })
            this.$store.commit('update', {
              target: this.$store.state.phoneSocket,
              data: {
                close: Math.random()
              }
            })
          } else {
            this.$store.dispatch('showTip', {
              text: '切换sim卡指令已成功发出，请耐心等待话机切换',
              type: 'success'
            })
          }
        }

        if (msg.cmd === 'CORG') {
          this.simPhone = msg.number
          const callId = msg.callId
          this.postCallId(callId, '', '')
          // todo 开始呼叫
          this.btnLoading = true
          // this.$store.commit('update', {
          //   target: this.$store.state.phoneSocket,
          //   data: {
          //     simStatus: '3'
          //   }
          // })
          // this.countTime()
          // this.showJt = false
        }

        if (msg.cmd === 'CBEGIN') {
          this.$store.commit('update', {
            target: this.$store.state.phoneSocket,
            data: {
              simStatus: '3'
            }
          })
          this.countTime()
          this.showJt = false
        }

        // if(msg.cmd === 'CEND'){
        //   //todo 呼叫结束
        //   if(this.timer) clearInterval(this.timer)
        //   this.timer = null
        //   this.phoneTime = ''
        //   this.showJt = false
        //   this.btnLoading = false
        //   if(this.openAutoCallTask){
        //     this.showCount = true
        //     this.t = this.openAutoCallTime
        //     const _t = setInterval(()=>{
        //       this.t = this.t - 1
        //       if(this.t === 0){
        //         this.showCount = false
        //         this.$bus.$emit('autoCallTask', {}) // 添加自动外呼
        //         clearInterval(_t)
        //       }
        //     }, 1000)
        //   }
        //   this.$store.commit('update', {
        //     target: this.$store.state.phoneSocket,
        //     data: {
        //       simStatus: '0'
        //     }
        //   })
        // }

        // if( msg.cmd === 'ATH' ) 主动挂断会有问题
        if (msg.cmd === 'CEND') {
          //todo 呼叫结束
          this.showHangUp = false
          if (this.timer) clearInterval(this.timer)
          this.timer = null
          this.phoneTime = ''
          this.showJt = false
          this.btnLoading = false
          if (this.openAutoCallTask) {
            this.showCount = true
            this.t = this.openAutoCallTime
            const _t = setInterval(() => {
              this.t = this.t - 1
              if (this.t === 0) {
                this.showCount = false
                this.$bus.$emit('autoCallTask', {}) // 添加自动外呼
                clearInterval(_t)
              }
            }, 1000)
          }
          this.$store.commit('update', {
            target: this.$store.state.phoneSocket,
            data: {
              simStatus: '0'
            }
          }) //todo 呼叫结束
          if (this.timer) clearInterval(this.timer)
          this.timer = null
          this.phoneTime = ''
          this.showJt = false
          this.btnLoading = false
          if (this.openAutoCallTask) {
            this.showCount = true
            this.t = this.openAutoCallTime
            const _t = setInterval(() => {
              this.t = this.t - 1
              if (this.t === 0) {
                this.showCount = false
                this.$bus.$emit('autoCallTask', {}) // 添加自动外呼
                clearInterval(_t)
              }
            }, 1000)
          }
          this.$store.commit('update', {
            target: this.$store.state.phoneSocket,
            data: {
              simStatus: '0'
            }
          })
        }

        if (msg.cmd === 'CALLING') {
          this.showJt = true
          this.simPhone = msg.number
        }
      }
    },

    postCallId(data) {
      let params = {
        callSheetId: data,
        phone: this.simPhone,
        companyName: this.companyNameSim,
        contact: this.contact,
        callSlot: this.simIndex
      }
      if (this.openAutoCallTask) {
        params = Object.assign({}, params, {
          inTaskId: this.autoCallCurrent.id
        })
      }
      postWebrtc(params)
        .then(() => {})
        .catch(() => {
          this.companyNameSim = ''
          this.contact = ''
        })
    },

    canCall(phone) {
      return new Promise((resolve, reject) => {
        canCall({ phone: phone })
          .then(res => {
            resolve(res.data)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },

    //todo 拨打sim话机
    async simPhoneCall(phone = '', companyName = '', contact = '') {
      if (this.btnLoading) {
        await this.$store.dispatch('showTip', {
          text: '拨打中，请稍后',
          type: 'error'
        })
        return
      }
      if (!this.wsObj) {
        await this.$store.dispatch('showTip', {
          text: '话机正在初始化,请稍后重试',
          type: 'error'
        })
        return
      }
      if (this.wsObj && this.wsObj.readyState !== 1) {
        await this.$store.dispatch('showTip', {
          text: '话机连接失败,请确认中间件已经开启，且和话机处于连接状态',
          type: 'error'
        })
        return
      }
      if (this.simStatus.toString() === '10086') {
        await this.$store.dispatch('showTip', {
          text: '设备离线,请确认话机是否在线',
          type: 'error'
        })
        return
      }

      // 是否超限

      try {
        const canCall = await callSlot({ callSlot: this.simIndex || 0 })
        if (canCall.code !== 60000) {
          await this.$store.dispatch('showMessageBox', {
            title: '提示',
            text: '该号卡已达到呼出上限， 请切换号卡',
            type: 'error',
            cancel: ''
          })
          return
        }
        this.btnLoading = true
        this.companyNameSim = companyName
        this.contact = contact
        this.simPhone = phone
        if (!this.simPhone) {
          this.btnLoading = false
          return
        }
        this.simPhone = this.simPhone.toString().replace('-', '') // 适配话机 sim话机不允许带 "-" 导致无法呼出
        const code =
          '{"cmd":"ATD","number":"' + this.simPhone + '","hidden":"1"}'
        const _code = '{"cmd": "getSimIndex"}'
        this.showHangUp = true
        this.wsObj.send(code)
        this.wsObj.send(_code)
      } catch (e) {
        this.btnLoading = true
        this.companyNameSim = companyName
        this.contact = contact
        this.simPhone = phone
        if (!this.simPhone) {
          this.btnLoading = false
          return
        }
        this.simPhone = this.simPhone.toString().replace('-', '') // 适配话机 sim话机不允许带 "-" 导致无法呼出
        const code =
          '{"cmd":"ATD","number":"' + this.simPhone + '","hidden":"1"}'
        const _code = '{"cmd": "getSimIndex"}'
        this.wsObj.send(code)
        this.wsObj.send(_code)
      }
    },

    simJt() {
      if (this.wsObj) {
        // const callId = 'sim' + new Date().getTime()
        // this.wsObj.send('{"cmd":"ATA", "callid":'+ callId +'"}')
        // const callId = 'sim'
        this.wsObj.send('{"cmd":"ATA"}')
      }
    },

    //todo 关断sim话机
    hangUpSim() {
      const code = '{"cmd":"ATH"}'
      this.wsObj.send(code)
    },

    showNum(num) {
      if (num < 10) {
        return '0' + num
      }
      return num
    },

    countTime() {
      // todo 计算时间
      if (this.timer) return
      let count = 0
      this.timer = setInterval(() => {
        count++
        const s = this.showNum(count % 60)
        const m = this.showNum(parseInt(count / 60) % 60)
        const h = this.showNum(parseInt(count / 60 / 60))
        this.phoneTime = `${h}:${m}:${s}`
      }, 1000)
    }
  }
}
</script>

<style scoped>
.con {
  width: 250px;
  height: 330px;
  background: white;
  /*box-shadow: 0px 0px 3px 1px #aaa;*/
  position: relative;
  padding-top: 30px;
}
.con /deep/ .el-card__body {
  height: 330px;
}
.panel {
  width: 100%;
  height: 100%;
}
.phone {
  width: 180px;
  margin: 0 auto;
  height: 60px;
  padding-top: 30px;
}
.btnList {
  position: absolute;
  width: 180px;
  height: 50px;
  bottom: 0;
  left: 35px;
}
.callBtn {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #41cabf;
  font-size: 13px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.signBtn {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.hangupBtn {
  width: 40%;
  border-radius: 5px;
  background: red;
  font-size: 13px;
  color: #fff;
  line-height: 30px;
  text-align: center;
  height: 30px;
  cursor: pointer;
}
.SnBtn {
  width: 100%;
  border-radius: 5px;
  background: #41cabf;
  font-size: 13px;
  color: #fff;
  line-height: 30px;
  text-align: center;
  height: 30px;
  cursor: pointer;
}
.con ::v-deep .el-dropdown-menu {
  z-index: 10000;
}
.state {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
}
.callclose {
  background: url('../../assets/call-close.svg') no-repeat;
  background-size: 80% 80%;
  background-position: center;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
}
.con ::v-deep .el-dialog__headerbtn {
  display: none !important;
}
.simPhone {
  display: flex;
  flex-direction: column;
  height: 260px;
}
.sim-button {
  display: flex;
  justify-content: center;
  height: 50px;
  line-height: 50px;
}
.sim-input {
  height: 50px;
}
.sim-main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
}
.sim-status {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  color: #8a8a8a;
}
.re-connect {
  color: #0099cc;
  font-size: 12px;
  cursor: pointer;
}
/*电销*/

.sim-phone {
  height: 100px;
  width: 100%;
}
.no-call {
  line-height: 100px;
  padding: 0 30px;
  box-sizing: border-box;
}
.call-ing {
  font-size: 14px;
  line-height: 100px;
  padding: 0 30px;
  box-sizing: border-box;
}
.phone-num,
.phone-name,
.phone-time {
  margin-right: 10px;
}
.call-jt {
  font-size: 14px;
  line-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  box-sizing: border-box;
}
</style>
