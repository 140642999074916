var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-main" }, [
    _c(
      "div",
      { staticClass: "btn-list" },
      [
        _c(
          "div",
          { staticClass: "input-row" },
          [
            _c("el-input", {
              attrs: { size: "mini", placeholder: "输入代理商名称查询" },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-row" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.searchAgent },
                  },
                  [_vm._v("查询")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.reset } },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "mini", type: "primary" },
            on: { click: _vm.proxyCreate },
          },
          [_vm._v("\n      邀请代理商\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "table",
      },
      [
        _c(
          "el-table",
          {
            staticClass: "table-dt",
            staticStyle: {
              width: "100%",
              height: "100%",
              "font-size": "12px",
              "background-color": "#fff",
            },
            attrs: { data: _vm.tableData, border: "", stripe: "" },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "agentName", label: "代理商名称" },
            }),
            _vm._v(" "),
            _c(
              "el-table-column",
              { attrs: { align: "center", label: "资源分配情况" } },
              [
                _c("el-table-column", {
                  attrs: { align: "center", label: "子账号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                `${scope.row.consumeAccountLimit - 1 || 0}/${
                                  scope.row.accountLimit - 1 || 0
                                }`
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "packageIds",
                    label: "线索包",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.packageIds === "ALL"
                                  ? "全部"
                                  : scope.row.packageIds
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "clueTransmitTotal",
                    label: "线索数",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: { prop: "date", align: "center", label: "资源消耗情况" },
              },
              [
                _c("el-table-column", {
                  attrs: { align: "center", label: "推荐线索" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                `${
                                  scope.row.consumeCompanyAvailableFree || 0
                                }/${scope.row.companyAvailableFree || 0}`
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "searchKey",
                    label: "自助找客",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                `${scope.row.consumeZizhuAvailableFree || 0}/${
                                  scope.row.zizhuAvailableFree || 0
                                }`
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { align: "center", prop: "msg", label: "独家信息" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                `${
                                  scope.row.consumeExclusiveAvailableFree || 0
                                }/${scope.row.exclusiveAvailableFree || 0}`
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "clueConsumptionTotal",
                    label: "总领取条数",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { align: "center", prop: "date", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "set",
                          on: {
                            click: function ($event) {
                              return _vm.setting(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n            分配\n          ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page page-div page-1" },
      [
        _c("footer-page", {
          attrs: { pageStatus: _vm.pageStatus },
          on: {
            handleCurrentChange: _vm.handleCurrentChange,
            goNumPage: _vm.goNumPage,
            changePageSize: _vm.changePageSize,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }