<template>
    <div>
        <div style="position:relative;">
            <div :class="(!otherParams.isReceive && otherParams.shade) ? 'module-cont-filter':''">
                <div class="face-plate-cont" v-for="(column, index) in headerArr" :key="index">
                    <div class="info-title">{{column.name}}：</div>
                    <div v-if="column.href" class="c-0077CC info-content" @click="toShop(tableArr[0][column.href])" >{{tableArr[0][column.field]}}</div>
                    <div v-else class="info-content">{{tableArr[0][column.field] || ''}}</div>
                </div>
            </div>
            <div class="touch-no-receive" v-if="!otherParams.isReceive && otherParams.shade"  v-loading="getLoading">
                <div class="touch-no-cont">
                    <div class="touch-no-icon">
                        <span style="color: #008CD9;text-decoration: underline;cursor:pointer;" @click="getReveiveKey('phone')">领取</span>
                        <span> 以查看所有信息</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isObjNull } from '@/utils/validate'
import Mixin from './mixin'
export default {
    mixins: [Mixin],
    props:{
        isReceive: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            supplierObj: {},
            headerArr: [],
            tableArr: [],
        }
    },
    methods: {
        stopLoading() {
            this.getLoading = false
        },
        userGraspPhone() {
            this.$emit('userGraspPhone', true)
        },
        toShop(url){
            window.open(url)
        },
    }
}
</script>
<style scoped>
.face-plate-cont {
    width: 100%;
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    line-height: 30px;
}
.face-plate-cont .info-title {
    text-align: left;
    color: #2E2E2E;
    width: 90px;
    display: inline-block;
    vertical-align: top;
}
.face-plate-cont .info-content {
    width: calc(100% - 113px);
    text-align: left;
    color: #8A8A8A;
    display: inline-block;
}
.modul-table-list {
    position: relative;
}
    .touch-no-receive {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:rgba(255,255,255,1);
    opacity:0.8;
    z-index: 100;
}
.touch-no-icon {
    width: 150px;
    height: 40px;
    margin-top: 100px;
    position: relative;
    font-size: 14px;
    color: #8F8F8F;

}
.touch-no-icon::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 41px;
    height: 22px;
    top: -50px;
    left: 42px;
    background: url('../../assets/no-receive.png') no-repeat;
    background-size: 100% 100%;
}
.touch-no-cont {
    position: relative;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>

