<template>
  <div>
    <dia-model
      ref="addBook"
      :dialogStatus="dialogAddBookStatus"
      :btnLoading="btnLoading"
      @submitContBtn="addBookBtn"
    >
      <div slot="modalCont">
        <div class="book-list-type" v-if="showName">
          <span v-if="showName && showMark">名称：</span>
          <el-input
            v-model="bookName"
            maxlength="100"
            @input="nameChange"
            placeholder="输入名称(最多100个字符，只能输入英文、汉字、数字)"
            class="input"
          ></el-input>
        </div>
        <div class="book-list-type" v-if="showMark">
          <span v-if="showName && showMark">备注：</span>
          <el-input
            v-model="bookRemark"
            :rows="4"
            type="textarea"
            maxlength="100"
            placeholder="输入备注"
            class="input"
            show-word-limit
          ></el-input>
          <!--                    <span style="color:red;font-size:12px;margin:0;">剩余{{25 - bookRemark.length}}字</span>-->
        </div>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from "@/components/Model";
import { getFavoritesAdd } from "@/api/table";
export default {
  props: {
    BookStatus: {
      default: () => {},
      type: Object
    },
    currentId: {
      default: null,
      type: Number
    }
  },
  data() {
    return {
      dialogAddBookStatus: {},
      bookName: "",
      bookRemark: "",
      btnLoading: false,
      showName: true,
      showMark: true
    };
  },
  components: {
    diaModel
  },
  methods: {
    nameChange(val) {
      this.bookName = val.replace(/[^a-z0-9\u4e00-\u9fa5]+$/i, "");
      if (val.length >= 25) {
        this.bookName = this.bookName.substr(0, 24);
      }
    },
    init() {
      this.bookRemark = "";
      this.bookName = "";
      if (this.currentId !== null) {
        this.bookName = this.BookStatus.name;
        this.bookRemark = this.BookStatus.note;
        this.showName = this.BookStatus.showName;
        this.showMark = this.BookStatus.showMark;
        this.dialogAddBookStatus = {
          dialogWidth: "650px",
          showFoot: true,
          dialogTop: "200px",
          singleBtn: false,
          dialogSurBtn: "确认"
        };
        if (this.showMark) {
          this.dialogAddBookStatus.dialogTitle = `编辑 -- ${
            this.BookStatus.name
          }`;
        } else {
          this.dialogAddBookStatus.dialogTitle = `重命名 -- ${
            this.BookStatus.name
          }`;
        }
      } else {
        this.dialogAddBookStatus = {
          dialogWidth: "650px",
          dialogTitle: "新建培育池",
          showFoot: true,
          dialogTop: "200px",
          singleBtn: false,
          dialogSurBtn: "确认"
        };
      }

      this.$nextTick(() => {
        this.$refs.addBook.init();
      });
    },
    closeAddBook() {
      this.$refs.addBook.subimtCancle();
    },
    addBookBtn() {
      if (this.showName) {
        if (!this.bookName) {
          this.$message({
            message: "请输入培育池名称",
            type: "warning"
          });
          return;
        }
      }
      if (this.currentId !== null) {
        // if (!this.showMark) {
        this.$emit("updateMarkName", this.bookName, this.bookRemark);
        // } else {
        //     this.$emit('updateMarkRemark',this.bookName,this.bookRemark)
        // }
      } else {
        this.getFavoritesAdd();
      }
    },
    getFavoritesAdd() {
      this.btnLoading = true;
      const params = {
        name: this.bookName,
        note: this.bookRemark
      };
      getFavoritesAdd(params).then(res => {
        this.btnLoading = false;
        if (res.code === 60000) {
          this.$message({
            message: "新建成功",
            type: "success"
          });
          this.$refs.addBook.subimtCancle();
          this.$emit("addBookSuccess");
        }
      });
    }
  }
};
</script>

<style scoped>
.book-list-type {
  margin-top: 10px;
}
.book-list-type span {
  display: inline-block;
  margin-bottom: 10px;
}
</style>
