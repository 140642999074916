var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "searchTop",
      class: _vm.searchStatus ? "global-container" : "search-status",
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          false
            ? _c(
                "el-tab-pane",
                { attrs: { label: "企业查询", name: "first" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "auto-complate-cont",
                      class: _vm.searchStatus
                        ? "global-input-btn"
                        : "global-input-list",
                    },
                    [
                      _vm.$store.getters.sub
                        ? _c("el-autocomplete", {
                            attrs: {
                              "fetch-suggestions": _vm.querySearchAsync,
                              placeholder: "输入公司名称查询",
                            },
                            on: {
                              focus: _vm.mouseFocus,
                              change: _vm.kongInput,
                              blur: _vm.mouseBlur,
                              select: _vm.handleSelectSugg,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ item }) {
                                    return [
                                      _c("div", {
                                        staticClass: "name",
                                        domProps: {
                                          innerHTML: _vm._s(item.company_name),
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              352309198
                            ),
                            model: {
                              value: _vm.searchInput,
                              callback: function ($$v) {
                                _vm.searchInput = $$v
                              },
                              expression: "searchInput",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$store.getters.sub
                        ? _c("span", {
                            staticClass: "global-search-btn",
                            on: { click: _vm.toSearchCompany },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$store.getters.sub
                        ? _c("el-input", {
                            attrs: {
                              autofocus: "",
                              placeholder: "输入公司名称查询",
                            },
                            on: {
                              focus: _vm.mouseFocus,
                              change: _vm.kongInput,
                              blur: _vm.mouseBlur,
                            },
                            model: {
                              value: _vm.searchInput,
                              callback: function ($$v) {
                                _vm.searchInput = $$v
                              },
                              expression: "searchInput",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$store.getters.sub
                        ? _c("span", {
                            staticClass: "global-search-btn",
                            on: { click: _vm.toSearchCompany },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.searchStatus
                    ? _c(
                        "div",
                        {
                          staticClass: "global-search-give",
                          style: { border: _vm.showRecommed ? "" : "none" },
                        },
                        [
                          _vm.showRecommed
                            ? _c("recom-list", {
                                attrs: { searchStatus: _vm.searchStatus },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.searchStatus
                    ? _c(
                        "div",
                        { staticClass: "high-search-cont" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "high-search-header",
                              class: _vm.openCloseHigh ? "b-b-none" : "",
                            },
                            [
                              _c("span", { staticClass: "high-name-title" }, [
                                _vm._v("筛选条件"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "high-header-left" },
                                [
                                  _vm._l(
                                    _vm.chooseHeaderArr,
                                    function (item, index) {
                                      return item.cont
                                        ? _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "choose-name-close",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.title) + ":"
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(_vm._s(item.cont)),
                                              ]),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "close-choose-icon",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.colseItemName(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.searchPrivnce ||
                                  _vm.searchCity ||
                                  _vm.searchRegister.length ||
                                  _vm.searchVc.length ||
                                  _vm.searchIndustry.length ||
                                  _vm.searchRegiTime.length ||
                                  _vm.searchEmpScale.length ||
                                  _vm.searchHasPhone.length
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "high-clear-search",
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearChooseHigh()
                                            },
                                          },
                                        },
                                        [_vm._v("清空")]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-collapse-transition", [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.openCloseHigh,
                                    expression: "!openCloseHigh",
                                  },
                                ],
                              },
                              [
                                _c("div", { staticClass: "high-search-hang" }, [
                                  _c(
                                    "div",
                                    { staticClass: "high-search-name" },
                                    [_vm._v("地域:")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "hige-search-right" },
                                    [
                                      _vm._l(_vm.regionList, function (it, i) {
                                        return _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "175px",
                                              float: "left",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  "value-key": "code",
                                                  placeholder: "请选择",
                                                },
                                                on: {
                                                  change: (e) =>
                                                    _vm.choosePrivence(e, i),
                                                },
                                                model: {
                                                  value: it.province,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      it,
                                                      "province",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "it.province",
                                                },
                                              },
                                              _vm._l(
                                                _vm.provinceData,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.code,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                            _vm._v(" "),
                                            it.cityList.length
                                              ? _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder: "请选择",
                                                    },
                                                    on: {
                                                      change: (e) =>
                                                        _vm.chooseCity(e, i),
                                                    },
                                                    model: {
                                                      value: it.city,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          it,
                                                          "city",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "it.city",
                                                    },
                                                  },
                                                  _vm._l(
                                                    it.cityList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.name,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.name,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { on: { click: _vm.addRegionOption } },
                                        [_vm._v("新增")]
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "high-search-hang" }, [
                                  _c(
                                    "div",
                                    { staticClass: "high-search-name" },
                                    [_vm._v("注册资本:")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "hige-search-right" },
                                    _vm._l(
                                      _vm.registeredCapital,
                                      function (item, index) {
                                        return _c(
                                          "span",
                                          {
                                            key: index,
                                            class:
                                              _vm.searchRegister.indexOf(
                                                item.value
                                              ) >= 0
                                                ? "choose-register-money"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.chooseRegister(
                                                  item,
                                                  1
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "high-search-hang" }, [
                                  _c(
                                    "div",
                                    { staticClass: "high-search-name" },
                                    [_vm._v("融资轮次:")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "hige-search-right" },
                                    _vm._l(_vm.vcArr, function (item, index) {
                                      return _c(
                                        "span",
                                        {
                                          key: index,
                                          class:
                                            _vm.searchVc.indexOf(item.code) >= 0
                                              ? "choose-register-money"
                                              : "",
                                          on: {
                                            click: function ($event) {
                                              return _vm.chooseVC(item, 2)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "high-search-lei" }, [
                                  _c(
                                    "div",
                                    { staticClass: "high-search-name" },
                                    [_vm._v("行业分类:")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "hang-right-more",
                                      class: _vm.industryStatus
                                        ? "hige-search-right"
                                        : "hige-search-industry",
                                    },
                                    [
                                      _vm._l(_vm.industryArr, function (item) {
                                        return _c(
                                          "span",
                                          {
                                            key: item.code,
                                            class:
                                              _vm.searchIndustry.indexOf(
                                                item.code
                                              ) >= 0
                                                ? "choose-register-money"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.chooseIndustry(
                                                  item,
                                                  3
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "hang-more-btn",
                                          class: _vm.industryStatus
                                            ? "industry-down-icon"
                                            : "industry-up-icon",
                                          on: {
                                            click: function ($event) {
                                              _vm.industryStatus =
                                                !_vm.industryStatus
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.industryStatus
                                                ? "更多"
                                                : "收起"
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "high-search-hang" }, [
                                  _c(
                                    "div",
                                    { staticClass: "high-search-name" },
                                    [_vm._v("注册时长:")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "hige-search-right" },
                                    _vm._l(
                                      _vm.registeredTime,
                                      function (item, index) {
                                        return _c(
                                          "span",
                                          {
                                            key: index,
                                            class:
                                              _vm.searchRegiTime.indexOf(
                                                item.value
                                              ) >= 0
                                                ? "choose-register-money"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.chooseRegiTime(
                                                  item,
                                                  4
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "high-search-hang" }, [
                                  _c(
                                    "div",
                                    { staticClass: "high-search-name" },
                                    [_vm._v("人员规模:")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "hige-search-right" },
                                    _vm._l(
                                      _vm.employeeScale,
                                      function (item, index) {
                                        return _c(
                                          "span",
                                          {
                                            key: index,
                                            class:
                                              _vm.searchEmpScale.indexOf(
                                                item.name
                                              ) >= 0
                                                ? "choose-register-money"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.chooseEmpScale(
                                                  item,
                                                  5
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "high-search-hang" }, [
                                  _c(
                                    "div",
                                    { staticClass: "high-search-name" },
                                    [_vm._v("电话号码:")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "hige-search-right" },
                                    _vm._l(
                                      _vm.globalPhone,
                                      function (item, index) {
                                        return _c(
                                          "span",
                                          {
                                            key: index,
                                            class:
                                              _vm.searchHasPhone.indexOf(
                                                item.value
                                              ) > -1
                                                ? "choose-register-money"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.chooseHasPhone(
                                                  item,
                                                  6
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { width: "100%", height: "8px" },
                                }),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.searchStatus
                    ? _c(
                        "div",
                        {
                          staticClass: "up-down-btn",
                          on: {
                            click: function ($event) {
                              _vm.openCloseHigh = !_vm.openCloseHigh
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "new-updown-btn",
                              class: _vm.openCloseHigh
                                ? "open-close-down"
                                : "open-close-up",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.openCloseHigh ? "展开" : "合起")
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "高级查找", name: "second" } }, [
            _c("div", { staticClass: "high-search-cont" }, [
              _c("div", { staticClass: "advanced-seach" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                  },
                  _vm._l(_vm.searchList, function (item) {
                    return _c(
                      "el-tag",
                      {
                        staticClass: "tag",
                        attrs: {
                          effect:
                            item.name === _vm.currentTagName.name
                              ? "dark"
                              : "plain",
                          closable: "",
                          size: "small",
                        },
                        on: {
                          close: function ($event) {
                            return _vm.delTag(item)
                          },
                          click: function ($event) {
                            return _vm.searchPlusHandel(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.name) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { "font-size": "12px" } }, [
                  _vm._v("满足下列"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "advanced-select" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "选择", size: "small" },
                        model: {
                          value: _vm.creatSelect,
                          callback: function ($$v) {
                            _vm.creatSelect = $$v
                          },
                          expression: "creatSelect",
                        },
                      },
                      _vm._l(_vm.allSelect, function (item, index) {
                        return _c("el-option", {
                          key: index + "a",
                          attrs: { label: item.name, value: item.name },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { "font-size": "12px" } }, [
                  _vm._v("条件"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "advanced-cont",
                    staticStyle: { "margin-top": "26px" },
                  },
                  [
                    _c("search-panel", {
                      ref: "searchPanel",
                      attrs: { source: _vm.onetitle },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "high-search-hang",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("div", { staticClass: "high-search-name" }, [
                          _vm._v("数据去重:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "hige-search-right" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                on: { change: _vm.handleLqLogic },
                                model: {
                                  value: _vm.globalChecked,
                                  callback: function ($$v) {
                                    _vm.globalChecked = $$v
                                  },
                                  expression: "globalChecked",
                                },
                              },
                              [_vm._v("未领取")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.toSearchSave },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.searchStatus ? "global-cont" : "global-cont-list" },
        [
          _vm.searchStatus
            ? _c("div", { staticClass: "search-input-logo" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.searchStatus
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.showLoading,
                      expression: "showLoading",
                    },
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "智能获客-高级查找-查看线索",
                      expression: "'智能获客-高级查找-查看线索'",
                    },
                  ],
                  staticClass: "global-list-cont list-cont",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "global-list-header",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        padding: "10px 20px",
                        "box-sizing": "border-box",
                      },
                    },
                    [
                      _vm.pageStatus.total
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "dt-permission",
                                      rawName: "v-dt-permission",
                                      value: "智能获客-高级查找-加入培育",
                                      expression:
                                        "'智能获客-高级查找-加入培育'",
                                    },
                                  ],
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.allCollectData },
                                },
                                [_vm._v("加入培育")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "dt-permission",
                                      rawName: "v-dt-permission",
                                      value: "智能获客-高级查找-加入培育",
                                      expression:
                                        "'智能获客-高级查找-加入培育'",
                                    },
                                  ],
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.allConcalData },
                                },
                                [_vm._v("移除培育")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "dt-permission",
                                      rawName: "v-dt-permission",
                                      value: "智能获客-高级查找-转成客户",
                                      expression:
                                        "'智能获客-高级查找-转成客户'",
                                    },
                                  ],
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.openTransCusModel },
                                },
                                [_vm._v("转成客户")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pageStatus.showTotal
                        ? _c("div", [
                            _c("span", [
                              _vm._v("共搜索到"),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#c00",
                                    margin: "0 3px",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.pageStatus.showTotal))]
                              ),
                              _vm._v("家企业\n          "),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("dt-table", {
                    key: _vm.refreshTableKey,
                    ref: "dtTable",
                    attrs: {
                      "show-overflow-tooltip": true,
                      "is-count-height": false,
                      "table-data": _vm.tableData,
                      "is-select": true,
                      "data-total": _vm.count,
                      "page-sizes": _vm.pageStatus.pageSize,
                      "page-num": this.pageStatus.pageNo,
                      algin: "left",
                      "hide-border": true,
                      "column-list": _vm.columnList,
                      "is-add-earn-field": false,
                      "column-width-list": { company_name: 300 },
                      "table-loading": _vm.loading,
                    },
                    on: {
                      sizeChange: _vm.sizeChange,
                      currentChange: _vm.currentChange,
                      select: _vm.tableSelect,
                      selectAll: _vm.selectAll,
                      sortChange: _vm.sortChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "company_nameRow",
                          fn: function (row) {
                            return [
                              _c(
                                "div",
                                { staticClass: "c-name-f" },
                                [
                                  _c("Star", {
                                    attrs: {
                                      data: row.scope.data.sub,
                                      selectObj: row.scope.data,
                                    },
                                    on: { getListData: _vm.save },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "c-name",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        row.scope.data.company_name
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goDetailPage(
                                          row.scope.data,
                                          row.scope.index
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  row.scope.data.hot === 0
                                    ? _c(
                                        "span",
                                        { staticClass: "company-status" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.scope.data.operating_status
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("Tags", {
                                    attrs: { tagList: row.scope.data.tags },
                                  }),
                                  _vm._v(" "),
                                  row.scope.data["highList"] &&
                                  row.scope.data["highList"].length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "add-filter-text",
                                          staticStyle: {
                                            width: "100%!important",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-one" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                    display: "inline-block",
                                                    width: "100%",
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "global-list-txt-1",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.addClass(
                                                          row.scope.data[
                                                            "highList"
                                                          ][0]
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      attrs: {
                                                        placement: "top-start",
                                                        title: "",
                                                        width: "600",
                                                        trigger: "hover",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        _vm._l(
                                                          row.scope.data[
                                                            "highList"
                                                          ],
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c("p", {
                                                              staticClass:
                                                                "global-list-txt-dd",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.addClass(
                                                                      item
                                                                    )
                                                                  ),
                                                              },
                                                            })
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                      _vm._v(" "),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-search",
                                                        staticStyle: {
                                                          color: "#ccc",
                                                          position: "absolute",
                                                          top: "10px",
                                                          right: "0",
                                                        },
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "activity_classHeader",
                          fn: function (header) {
                            return [
                              _c("span", [_vm._v("活跃度")]),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                {
                                  attrs: { trigger: "click" },
                                  on: { command: _vm.handleActiveClick },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _vm._l(_vm.activeList, function (it) {
                                        return [
                                          _c(
                                            "el-dropdown-item",
                                            { attrs: { command: it.name } },
                                            [_vm._v(_vm._s(it.name))]
                                          ),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "distanceRow",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDistance")(
                                      row.scope.data.distance
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      133262397
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showModel
        ? _c(
            "dia-model",
            {
              ref: "user_model",
              attrs: { dialogStatus: _vm.dialogStatus },
              on: { submitContBtn: _vm.submitContBtn },
            },
            [
              _c("div", { attrs: { slot: "modalCont" }, slot: "modalCont" }, [
                _vm._v(_vm._s(_vm.dialogCont)),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "transToCus",
          attrs: { dialogStatus: _vm.transToCusStatus },
          on: { submitContBtn: _vm.submitTransBtn },
        },
        [
          _c("div", { attrs: { slot: "modalCont" }, slot: "modalCont" }, [
            _c(
              "div",
              {
                staticStyle: { margin: "20px 0 40px", "padding-left": "30px" },
              },
              [
                _c("span", [_vm._v("转至：")]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: {
                      "default-first-option": "",
                      filterable: "",
                      placeholder: "选择客户接收人",
                    },
                    model: {
                      value: _vm.currentTransCusUserId,
                      callback: function ($$v) {
                        _vm.currentTransCusUserId = $$v
                      },
                      expression: "currentTransCusUserId",
                    },
                  },
                  _vm._l(_vm.transCus, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("select-book", {
        ref: "selectBook",
        attrs: { BookArr: _vm.bookArr },
        on: {
          changeBookList: _vm.changeBookList,
          sureSubmitCollect: _vm.sureSubmitCollect,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }