<template>
  <div class="visual-temp-page">
    <div class="crumb-wrap">
      <div>可视化模板</div>
    </div>

    <div class="list">
      <div
        v-for="(item, index) in templateList"
        :key="index"
        class="item"
        @click="handleDetail(item)"
      >
        <div class="top">
          <div>{{ item.name }}</div>
        </div>

        <img :src="item.abbreviationsImgUrl" class="img-default" />
      </div>
    </div>
  </div>
</template>

<script>
import { getVisualList } from '@/api/module'
export default {
  name: 'VisualTemp',

  data() {
    return {
      templateList: [],
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    handleDetail(data) {
      if(data.isLayout) {
        window.open(`/#/bi/edit`)
      } else {
        window.open(`/#/bi/view?screenId=${data.id}`)
      }
    },

    async getList() {
      const { data } = await getVisualList()
      data.forEach((item, index) => {
        item.index = index
      })
      this.templateList = data
    }
  }
}
</script>


</style>
<style lang="scss" scoped>
.visual-temp-page {
  .crumb-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding:10px;
  }

  .list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 0;
    .item {
      margin: 0 0.5% 10px;
      width: 24%;
      background-color: #fff;
      height: 240px;
      padding: 10px;
      border: 2px solid transparent;
      position: relative;
      overflow: hidden;
      .img-default {
        width: 100%;
        height: 85%;
      }

      .top {
        display: flex;
        flex-direction: column;
      }

      &:hover {
        cursor: pointer;
        border: 2px solid #41cabf;
        background: rgba(0, 0, 0, 0.01);
        &::after {
          content: '查看';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: #41cabf;
          color: #fff;
          padding: 5px 10px;
        }
      }
    }
  }
}
</style>
