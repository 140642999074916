<template>
  <div class="filter-main">
    <div
      :class="isOpen ? 'filter-open filter-line' : 'filter-close filter-line'"
    >
      <div
        :class="isOpen ? 'change-btn-opens' : 'change-btn-close'"
        @click="isOpen = !isOpen"
      ></div>
      <div v-show="isOpen">
        <div class="close-top">
          <el-button icon="el-icon-close" size="mini" @click="handlePanelClose"
            >关闭</el-button
          >
        </div>

        <el-row class="select-wrapper">
          <el-col :span="3" class="hasSelect">已选条件</el-col>
          <template v-for="it in moreFilterList">
            <template v-for="(ele, idx) in it.currentSelectName">
              <el-tag
                class="filter-tag"
                effect="dark"
                closable
                size="mini"
                @close="handleTagClick(it, ele, idx)"
              >
                {{ ele }}
              </el-tag>
            </template>
          </template>
          <span class="clear-select" @click="handleClear">清除已选</span>
        </el-row>

        <template>
          <el-row
            :gutter="20"
            class="filter-item"
            v-for="(item, index) in moreFilterList"
            :key="index"
          >
            <el-col :span="3">
              <span class="filter-title">{{ item.name }}</span>
              <div v-if="item.name === '上云阶段'" class="info-icon">
                <cloud-table :cloudList="cloudList"></cloud-table>
              </div>
            </el-col>
            <el-col :span="21">
              <div
                v-for="(_item, idx) in item.target"
                :class="
                  item.currentSelect.includes(_item[item.id])
                    ? 'filter-raw-active'
                    : 'filter-raw'
                "
                @click.stop="selectFilter(item, _item, index)"
              >
                <span v-if="item.name !== '成立时间'"> {{ _item.name }}</span>
                <template v-else>
                  <span>{{ _item.name }}</span>
                  <template v-if="item.target.length - 1 === idx">
                    <el-date-picker
                      :editable="false"
                      :clearable="false"
                      size="mini"
                      @change="value => timeChange(value, item)"
                      v-model="htTime"
                      type="daterange"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </template>
                </template>
              </div>
            </el-col>
          </el-row>
          <div class="data-repeat">
            <span>数据去重</span>
            <div class="r-box">
              <el-checkbox v-model="getChecked" @change="getCompanyList"
                >未领取</el-checkbox
              >
            </div>
          </div>
        </template>
      </div>
    </div>

    <div :class="isOpen ? 'company-list-open' : 'company-list-close'">
      <div class="btn-raw">
        <div class="left">
          <el-button
            type="primary"
            size="mini"
            @click="addPeiYu(selectionList)"
            v-dt-permission="'智能获客-推荐线索-加入培育'"
            >加入培育</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="removePeiYu(selectionList)"
            v-dt-permission="'智能获客-推荐线索-加入培育'"
            >移除培育</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="transCustomer"
            v-dt-permission="'智能获客-推荐线索-转成客户'"
            >转成客户</el-button
          >
          <div class="search-content">
            <el-input
              size="mini"
              v-model="keyWords"
              placeholder="请输入企业名称"
              @keydown.enter.native="searchKey"
            >
            </el-input>
            <el-button
              @click="searchKey"
              class="inquire"
              size="mini"
              type="primary"
              >查询</el-button
            >
            <el-button size="mini" @click="handleClear">重置</el-button>
          </div>
        </div>
        <div class="right">
          <span class="tip-num"
            >共搜索到
            <span class="count">{{ count > 100000 ? '10万+' : count }}</span
            >家企业</span
          >
        </div>
      </div>

      <dt-table
        ref="dtTable"
        style="margin-bottom: 30px;"
        dtHeight="400"
        :key="refreshTableKey"
        :show-overflow-tooltip="true"
        :is-count-height="false"
        :table-data="companyList"
        :data-total="totalCount"
        :page-sizes="pageObj.pageSize"
        :page-num="this.pageObj.pageNo"
        algin="left"
        @select="tableSelect"
        @sizeChange="sizeChange"
        @currentChange="currentChange"
        @selectAll="selectAll"
        @sortChange="sortChange"
        :hide-border="true"
        :column-list="columnList"
        :is-add-earn-field="false"
        :column-width-list="{ company_name: 240 }"
        :table-loading="loading"
      >
        <template v-slot:company_nameRow="row" :show-overflow-tooltip="false">
          <div class="c-name-f">
            <span
              v-show="row.scope.data.sub === 'true'"
              v-dt-permission="'智能获客-推荐线索-加入培育'"
              class="c-sub"
              title="移除培育"
              @click="removePeiYu([row.scope.data])"
            >
              <i class="el-icon-star-on" style="color: #fc8e1c"></i>
            </span>
            <span
              v-show="row.scope.data.sub === 'false'"
              v-dt-permission="'智能获客-推荐线索-加入培育'"
              class="c-sub"
              title="加入培育"
              style="color: #d9d9d9"
              @click="addPeiYu([row.scope.data])"
            >
              <i class="el-icon-star-on"></i>
            </span>
            <span
              v-html="row.scope.data.company_name"
              @click="goDetail(row.scope.data, row.scope.$index)"
              class="c-name"
            ></span>
            <span v-if="row.scope.data.hot === 0" class="company-status">{{
              row.scope.data.operating_status
            }}</span>
            <div>{{ row.scope.data.distance | formatDistance }}</div>
            <Tags :tagList="row.scope.data.tags"></Tags>

            <div
              class="add-filter-text"
              style="width: 100%!important"
              v-if="
                row.scope.data['highList'] && row.scope.data['highList'].length
              "
            >
              <div class="item-one">
                <span
                  style="position: relative;display: inline-block;width: 100%"
                >
                  <span
                    class="global-list-txt-1"
                    v-html="addClass(row.scope.data['highList'][0])"
                  >
                  </span>
                  <el-popover
                    placement="top-start"
                    title=""
                    width="600"
                    trigger="hover"
                  >
                    <div>
                      <p
                        class="global-list-txt-dd"
                        v-for="(item, index) in row.scope.data['highList']"
                        v-html="addClass(item)"
                      ></p>
                    </div>
                    <i
                      slot="reference"
                      style="color:#ccc;position:absolute;top:10px;right: 0;"
                      class="el-icon-search"
                    ></i>
                  </el-popover>
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:activity_classHeader="header">
          <draw-down-menu
            @handleCommand="handleActiveClick"
            :drawList="header.scope.column.filterOptionList"
          ></draw-down-menu>
        </template>
      </dt-table>
    </div>
  </div>
</template>

<script>
import { searchCompanyForMap } from '@/api/highsearch'
import CloudTable from '@/components/Peculiar/cloud'
import { formatTime, flatTime } from '@/utils/helper'
import { collection_add, collection_remove } from '@/api/thread'
import DtTable from '@/components/element/DtTable'
import PeiYuList from '@/views/map/dialog/PeiYuList'
import TransCustomer from '@/views/map/dialog/TransCustomer'
import { ResetSystemConfig } from '@/utils/auth'
import DrawDownMenu from '@/components/Peculiar/draw-down'

import { loadTime, enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'searchFilterForMap',
  components: {
    DtTable,
    DrawDownMenu,
    CloudTable
  },

  data() {
    return {
      totalCount: 0,
      getChecked: false,
      selectionList: [], // 选择的列表
      keyCtrlDown: false,
      refreshTableKey: 1,
      isOpen: true,
      htTime: [],
      keyWords: '',
      sortParams: '',
      selectClass: '',
      pageObj: {
        pageNo: 1,
        pageSize: 20
      },
      activeList: [],
      count: 0,
      companyList: [],
      columnList: [
        {
          field: 'company_name',
          name: '公司名称'
        },
        {
          field: 'distance',
          name: '距离'
        },
        {
          field: 'industry',
          name: '行业'
        },
        {
          field: 'employe_scale',
          name: '人员规模'
        },
        {
          field: 'activity_class',
          name: '活跃度'
        },
        {
          field: 'hot',
          name: '热度'
        },
        {
          field: 'gongyingshang',
          name: '云服务商'
        },
        {
          field: 'cdn_sp',
          name: 'CDN服务商'
        }
      ],
      loading: false,
      sortType: '',
      moreFilterList: {
        location: {
          name: '距离',
          paramsName: '距离',
          id: 'radius',
          target: [
            { id: 0, name: '50m以内', radius: 50 },
            { id: 1, name: '100m以内', radius: 100 },
            { id: 2, name: '300m以内', radius: 300 },
            { id: 3, name: '1km以内', radius: 1000 },
            { id: 4, name: '5km以内', radius: 5000 },
            { id: 5, name: '10km以内', radius: 10000 },
            { id: 6, name: '20km以内', radius: 20000 },
            { id: 7, name: '50km以内', radius: 50000 },
            { id: 8, name: '100km以内', radius: 100000 }
          ],
          currentSelect: [5000],
          currentSelectName: ['5km以内']
        },
        capital: {
          name: '注册资本',
          paramsName: '注册资本',
          target: [],
          id: 'value',
          currentSelect: [],
          currentSelectName: []
        },
        financing: {
          name: '融资轮次',
          paramsName: '融资轮次',
          target: [],
          id: 'code',
          currentSelect: [],
          currentSelectName: []
        },
        industry: {
          name: '行业分类',
          paramsName: '行业分类',
          target: [],
          id: 'code',
          currentSelect: [],
          currentSelectName: []
        },
        registerTime: {
          name: '注册时长',
          paramsName: '注册时长',
          target: [],
          id: 'value',
          currentSelect: [],
          currentSelectName: []
        },
        establishTime: {
          name: '成立时间',
          paramsName: '成立时间',
          target: [],
          id: 'time',
          currentSelect: [],
          currentSelectName: []
        },
        staffSize: {
          name: '人员规模',
          paramsName: '人员规模',
          target: [],
          id: 'name',
          currentSelect: [],
          currentSelectName: []
        },
        phoneStatus: {
          name: '电话号码',
          paramsName: '手机号',
          target: [{ id: 1, name: '有手机' }, { id: 0, name: '有座机' }],
          id: 'id',
          currentSelect: [],
          currentSelectName: []
        },
        businessStatus: {
          name: '经营状态',
          paramsName: '经营状态',
          id: 'status',
          target: [
            { id: 0, name: '正常', status: true },
            { id: 1, name: '注销', status: false }
          ],
          currentSelect: [],
          currentSelectName: []
        },
        onCloud: {
          name: '云服务商',
          paramsName: '云服务商',
          target: [],
          id: 'code',
          currentSelect: [],
          currentSelectName: []
        },
        cloudStage: {
          name: '上云阶段',
          paramsName: '上云阶段',
          target: [],
          id: 'id',
          currentSelect: [],
          currentSelectName: []
        }
      },
      showFilter: true,
      selectValue: '',
      cloudList: []
    }
  },

  mounted() {
    if (Object.keys(this.moreFilterLists).length) {
      this.moreFilterList = this.moreFilterLists
    }

    document.onkeydown = e => {
      if (e.keyCode === 17 || e.ctrlKey) {
        this.keyCtrlDown = true
      }
    }

    document.onkeyup = e => {
      if (e.keyCode === 17 || e.ctrlKey) {
        this.keyCtrlDown = false
      }
    }
  },

  computed: {
    tableHeight() {
      if (this.isOpen) {
        return window.innerHeight - 358 - 50 - 70 - 30 + 'px'
      } else {
        return window.innerHeight - 40 - 50 - 70 - 30 + 'px'
      }
    },
    // 同时监听经纬度变化事件
    watchChange() {
      return this.latitude + this.longitude + this.keywords
    },
    isShowSelectBook() {
      return this.$store.state.user.sysConfig.isShowSelectBook
    },
    defaultFavoriteId() {
      return this.$store.state.user.sysConfig.defaultFavoriteId
    }
  },

  watch: {
    '$store.state.settings.filterList': {
      handler(filterList) {
        console.log(filterList, 'filterList')
        const cloudOpen = this.$store.state.user.accountInfo.cloudOpenList.some(
          item => item.name == '云行业'
        )
        if (!cloudOpen) {
          this.moreFilterList.onCloud = {}
          this.moreFilterList.cloudStage = {}
        } else {
          this.moreFilterList.onCloud.target = filterList.onCloud
          this.moreFilterList.cloudStage.target = filterList.cloudStage
        }
        this.moreFilterList.capital.target = filterList.capital
        this.moreFilterList.staffSize.target = filterList.staffSize
        this.moreFilterList.registerTime.target = filterList.registerTime
        this.moreFilterList.financing.target = filterList.financing
        this.moreFilterList.industry.target = filterList.industry
        this.moreFilterList.establishTime.target = filterList.establishTime
      },
      immediate: true,
      deep: true
    },
    isOpen: function() {
      this.refreshTableKey += 1
      this.$nextTick(() => {
        const table = this.$refs['dtTable'].getTableRef()
        this.selectionList.forEach(item => {
          table.toggleRowSelection(item)
        })
      })
    },

    // 监听经纬度变化
    watchChange: function() {
      console.log('search123')
      this.pageObj.pageNo = 1
      const t = setTimeout(() => {
        this.getCompanyList()
        clearTimeout(t)
      }, 100)
    },

    pageNo: function(value) {
      if (value) {
        this.pageObj.pageNo = value
        this.refreshTableKey += 1
      }
    },

    pageSize: function(value) {
      if (value) {
        this.pageObj.pageSize = value
        this.keyWords = this.keyword
      }
    },

    isOpens: function(val) {
      this.isOpen = val
    }
  },

  filters: {
    formatDistance(val) {
      if (!val) {
        return '0m'
      }
      if (val >= 1000) {
        return (val / 1000).toFixed(2) + 'km'
      }
      return parseInt(val) + 'm'
    }
  },

  props: {
    searchAdd: {
      type: String,
      default: ''
    },

    filterList: {
      type: Object,
      default: () => {
        return {}
      }
    },

    longitude: {
      type: [String, Number],
      default: 0
    },

    latitude: {
      type: [Number, String],
      default: 0
    },

    requestFirst: {
      type: Boolean,
      default: false
    },

    pageNo: {
      type: Number,
      default: 0
    },

    pageSize: {
      type: Number,
      default: 0
    },

    keyword: {
      type: String,
      default: ''
    },

    map: {
      type: Object,
      default: () => {
        return {}
      }
    },

    moreFilterLists: {
      type: Object,
      default: () => {
        return {}
      }
    },

    isOpens: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    handlePanelClose() {
      this.$emit('handleBtnClose')
    },

    addClass(val) {
      return `<span class="line-1"> ${val} </span>`
    },

    expendBtn(e) {
      const companyUuid = e.company_uuid
      this.companyList.forEach(item => {
        if (item.company_uuid === companyUuid) {
          item['expended'] = !item['expended']
        }
      })
      this.$forceUpdate()
    },

    selectFilter(source, data, type) {
      const value = data[source.id]
      console.log(source, data, type, value, 'gghhkk')
      if (
        source.name === '距离' ||
        source.name === '成立时间' ||
        source.name == '数据去重' ||
        source.name == '经营状态'
      ) {
        source.name === '距离' && this.$emit('radiusChange', value)
        if (source.currentSelect.includes(value)) {
          return
        } else {
          source.currentSelect = [value]
          source.currentSelectName = [data.name]
          this.moreFilterList[type] = source
          if (source.name === '成立时间') {
            const time = formatTime(data.name)
            const startTime = flatTime(time[0])
            const endTime = flatTime(time[1])
            this.htTime = [startTime, endTime]
          }
        }
      } else {
        if (source.currentSelect.includes(value)) {
          const index = source.currentSelect.indexOf(value)
          const nameIndex = source.currentSelectName.indexOf(data.name)
          source.currentSelectName.splice(nameIndex, 1)
          source.currentSelect.splice(index, 1)
          this.moreFilterList[type] = source
        } else {
          source.currentSelect.push(value)
          source.currentSelectName.push(data.name)
          this.moreFilterList[type] = source
        }
      }
      this.pageObj.pageNo = 1
      this.getCompanyList()
    },

    getCompanyList(refreshTable = true) {
      console.log(this.moreFilterList.dataRepeat)
      this.loading = true
      if (refreshTable) {
        this.refreshTableKey += 1
      }
      this.selectionList = []
      const params = {
        searchContent: this.formatParams(),
        keywords: this.keyWords,
        radius: this.moreFilterList.location.currentSelect.join(','),
        longitude: this.longitude,
        latitude: this.latitude,
        vc: this.moreFilterList.financing.currentSelect.join(','),
        registeredCapital: this.moreFilterList.capital.currentSelect.join(','),
        industry: this.moreFilterList.industry.currentSelect.join(','),
        registeredTime: this.moreFilterList.registerTime.currentSelect.join(
          ','
        ),
        employeeScale: this.moreFilterList.staffSize.currentSelect.join(','),
        phone: this.moreFilterList.phoneStatus.currentSelect.join(','),
        pageNo: this.pageObj.pageNo,
        pageSize: this.pageObj.pageSize,
        searchAddress: this.searchAdd,
        establishTime: this.htTime.join(),
        sort: this.sortParams,
        // noConsumption: this.moreFilterList.dataRepeat.currentSelect.join(','),
        noConsumption: this.getChecked,
        activityClass: this.selectClass,
        bussinessStatus: this.moreFilterList.businessStatus.currentSelect.join(
          ','
        )
      }

      const arr = []
      this.moreFilterList.cloudStage?.currentSelectName?.forEach(item => {
        this.filterList.cloudStage.forEach(el => {
          if (item === el.name) {
            arr.push(`${el.minHot}-${el.mxHot}`)
          }
        })
      })

      const mapParams = {
        cloud: this.moreFilterList.onCloud?.currentSelectName?.join(','),
        cloudStage: arr.join(',')
      }
      const resultMapParams = Object.assign(mapParams, params)
      // this.$emit("changeFilter", params);
      this.$emit('changeFilter', resultMapParams)
      searchCompanyForMap(resultMapParams).then(res => {
        this.loading = false
        if (res.code === 60000) {
          const paramsData = JSON.stringify(
            Object.assign({}, resultMapParams, {
              keyTotal: parseInt(res.data?.pagination?.total)
            })
          )
          localStorage.setItem('globalMapParams', paramsData)
          this.companyList = res.data?.list?.map(item => {
            item['expended'] = false
            return item
          })
          this.columnList = res?.data?.columns?.map(item => ({
            alias: item.alias,
            name: item.name,
            field: item.field,
            type: item.type,
            filterOptionList: item.filterOptionList,
            sort: item.type === 'sort' ? 'custom' : false
          }))
          this.activeList = res?.data?.columns?.find(
            item => item.field === 'activity_class'
          ).filterOptionList
          this.count = res.data?.pagination?.showTotal
          this.totalCount = parseInt(res.data?.pagination?.total)
        }
      })
    },

    searchKey() {
      this.sortParams = ''
      this.getCompanyList()
    },

    sizeChange(e) {
      this.pageObj.pageSize = e
      this.pageObj.pageNo = 1
      this.getCompanyList()
    },

    currentChange(e) {
      this.pageObj.pageNo = e
      this.getCompanyList()
    },

    formatParams() {
      let res = '地域:/'
      for (let i in this.moreFilterList) {
        // paramsName
        console.log(i)
        const value = this.moreFilterList[i]
        const data = `${value.paramsName}:${value.currentSelectName?.join(
          ','
        )}/`
        res = res + data
      }
      res = res.substr(0, res.length - 1)
      return res
    },

    goDetail(data, index) {
      const zoom = this.map.getZoom()
      // 保存经纬度 缩放等级 moreFilterList
      const saveParams = {
        lng: this.longitude,
        lat: this.latitude,
        moreFilterList: this.moreFilterList,
        pageNo: this.pageObj.pageNo,
        pageSize: this.pageObj.pageSize,
        zoom,
        keywords: this.keyWords,
        isOpen: this.isOpen,
        searchAddress: this.searchAdd
      }
      sessionStorage.setItem('mapParams', JSON.stringify(saveParams))
      if (this.keyCtrlDown) {
        window.open(
          `/#/keydetail/keydetail?companyUuid=${
            data?.company_uuid
          }&followUserId=${data?.followUserId}&packageId=0`,
          '_blank'
        )
      } else {
        enterCompanyDetail(data, this.companyList, index, 0, {
          followUserId: data.followUserId
        })
      }
      this.keyCtrlDown = false
    },

    tableSelect(data) {
      this.selectionList = data.selection
    },

    selectAll(data) {
      this.selectionList = data
    },

    addPeiYu(selectionList = this.selectionList) {
      if (!selectionList.length) {
        this.$store.dispatch('showTip', {
          text: '请选择要加入培育的线索',
          type: 'error'
        })
        return
      }
      if (this.isShowSelectBook) {
        this.$store.dispatch('showModal', {
          title: '选择培育池',
          view: PeiYuList,
          size: '650px',
          onClose: res => {
            if (!res.refresh) {
              return
            }
            const params = {
              packageId: 0,
              companyUuids: selectionList
                .map(item => item.company_uuid)
                .join(','),
              favoriteId: res.favoriteObj.id,
              isShowSelectBook: res.isShowSelectBook ? 1 : 0
            }
            const loading = loadTime()
            collection_add(params).then(res => {
              if (res.code === 60000) {
                loading.close()
                this.getCompanyList(false)
                ResetSystemConfig()
                this.$store.dispatch('showTip', {
                  text: res.msg,
                  type: 'success'
                })
              }
            })
          }
        })
      } else {
        const params = {
          packageId: 0,
          companyUuids: selectionList.map(item => item.company_uuid).join(','),
          favoriteId: this.defaultFavoriteId,
          isShowSelectBook: this.isShowSelectBook
        }
        const loading = loadTime()
        collection_add(params).then(res => {
          if (res.code === 60000) {
            loading.close()
            this.getCompanyList(false)
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
          }
        })
      }
    },

    removePeiYu(selectionList = this.selectionList) {
      if (!selectionList.length) {
        this.$store.dispatch('showTip', {
          text: '请选择要移除培育的线索',
          type: 'error'
        })
        return
      }
      this.$store.dispatch('showMessageBox', {
        title: '移除培育',
        text: '确定移除选中的线索？',
        type: 'error',
        onClose: res => {
          if (res === 'confirm') {
            this.removePeiYuFnc(selectionList)
          }
        }
      })
    },

    transCustomer() {
      if (!this.selectionList.length) {
        this.$store.dispatch('showTip', {
          text: '请选择要转成客户的线索',
          type: 'error'
        })
        return
      }

      this.$store.dispatch('showModal', {
        title: '转成客户',
        view: TransCustomer,
        size: '600px',
        options: {
          selectionList: this.selectionList
        },
        onClose: res => {
          if (res.refresh) {
            this.getCompanyList(false)
          }
        }
      })
    },

    removePeiYuFnc(selectionList) {
      if (!selectionList.length) {
        this.$store.dispatch('showTip', {
          text: '请选择要移除培育的线索',
          type: 'error'
        })
        return
      }
      const packageIdAndcompanyuuid = selectionList.map(item => {
        return {
          packageId: 0,
          companyUuid: item.company_uuid
        }
      })
      const params = {
        packageIdAndcompanyuuid: JSON.stringify(packageIdAndcompanyuuid)
      }
      collection_remove(params).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
          this.getCompanyList(false)
        }
      })
    },

    // handleSelectChange() {
    //   this.showFilter = !this.showFilter
    // },

    handleTagClick(wrap, inner, index) {
      if (wrap.name === '距离') {
        this.$message.error('距离选项必填')
        return
      }
      if (wrap.name === '成立时间') {
        this.htTime = []
      }
      const filterObj = this.moreFilterList
      for (let key in filterObj) {
        if (filterObj[key].id === wrap.id) {
          filterObj[key].currentSelectName.splice(index, 1)
          filterObj[key].currentSelect.splice(index, 1)
        }
      }
      this.pageObj.pageNo = 1
      this.getCompanyList()
      console.log(wrap, inner, index, this.moreFilterList, 'moreFilterList')
    },

    handleClear() {
      const filterObj = this.moreFilterList
      for (let key in filterObj) {
        filterObj[key].currentSelectName = []
        filterObj[key].currentSelect = []
      }
      filterObj['location'].currentSelect = [5000]
      filterObj['location'].currentSelectName = ['5km以内']
      this.pageObj.pageNo = 1
      this.getChecked = false
      this.keyWords = ''
      this.sortParams = ''
      this.selectClass = ''
      this.currentStatus = '正常'
      this.getCompanyList()
    },

    timeChange(start, end) {
      console.log(this.htTime)
    },

    sortChange(data) {
      console.log(data, this.$refs.dtTable, 112233)
      const sort = data.order === 'descending' ? '降序' : '升序'
      const props = data.prop
      const item = this.columnList.find(it => it.field === props)
      this.sortParams = `${item.alias || item.field}:${sort}`
      this.getCompanyList(false)
    },

    handleActiveClick(data) {
      this.selectClass = data
      this.pageObj.pageNo = 1
      this.getCompanyList()
    }
  }
}
</script>
<style lang="scss">
.close-top {
  position: fixed;
  top: 60px;
  right: 26px;
  display: flex;
  justify-content: flex-end;
  background: #fff;
  z-index: 111;
}
.data-repeat {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  .r-list {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .r-box {
    display: flex;
    align-items: center;
    margin-left: 28px;
    .active {
      color: #41cabf;
    }
    span {
      cursor: pointer;
      font-size: 12px;
    }
  }
}
.el-checkbox {
  display: flex;
  align-items: center;
}
.el-checkbox__label {
  font-size: 12px;
}
.filter-main {
  .wrap {
    display: flex;
    align-items: center;
    span {
      &:first-child {
        transform: scale(0.8);
        color: #909399;
      }
    }
  }
  .el-select-dropdown__empty {
    display: none;
  }
  .el-range-editor--mini.el-input__inner {
    width: 240px;
  }
  .el-range-separator {
    width: 15%;
  }
  .company-status {
    display: inline-block;
    background-color: red;
    color: #fff;
    transform: scale(0.6);
    letter-spacing: 2px;
    padding: 0 4px;
  }
  .el-popover__reference {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tag-wrap {
    margin: 4px;
  }
  .info-icon {
    position: absolute;
    top: 4px;
    left: 60px;
  }
}

.filter-tag {
  margin: 5px;
}
.clear-select {
  display: inline-block;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.3);
  color: rgb(255, 255, 255);
  padding: 8px;
  text-align: center;
  line-height: 10px;
  transform: scale(0.8);
  box-sizing: border-box;
  cursor: pointer;
}
</style>
<style scoped lang="scss">
.filter-main {
  height: 100%;
  width: 700px;
  background-color: #ffffff;
  color: #606266;
}
.select-wrapper {
  .hasSelect {
    font-size: 12px;
    margin: 10px 0;
  }
}

.filter-open {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  background-color: #ffffff;
  position: relative;
  height: auto;
  transition: height 300ms;
  z-index: 99;
}

.filter-close {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  background-color: #ffffff;
  position: relative;
  height: 30px;
  overflow-y: hidden;
  transition: height 300ms;
}

.change-btn-open {
  height: 30px;
  width: 50px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  cursor: pointer;
  background: url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/open.svg')
    no-repeat center center;
  background-size: 40px 15px;
}

.change-btn-close {
  height: 30px;
  width: 50px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  cursor: pointer;
  background: url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/close.svg')
    no-repeat center center;
  background-size: 40px 15px;
  z-index: 999;
}

.filter-item {
  display: flex;
  align-items: center;
  .item {
    font-size: 12px;
  }
}
.company-list-open {
  width: 100%;
  background-color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
}
.company-list-close {
  position: relative;
  /*height: calc( 100% - 40px );*/
  width: 100%;
  background-color: #fff;
  padding: 30px 10px 0 10px;
  box-sizing: border-box;
}
.filter-title {
  font-size: 12px;
  user-select: none;
}
.filter-raw {
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  user-select: none;
  display: inline-block;
}
.filter-raw-active {
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  color: #41cabf;
}
.filter-line {
  position: relative;
}
.filter-line::before {
  content: '';
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
}
.c-name:hover {
  font-weight: bold;
}
.c-name {
  cursor: pointer;
  font-weight: bold;
}
.add-filter-text {
  width: 60%;
  height: auto;
  padding: 0 10px 0 0;
  box-sizing: border-box;
}
.item-one > span:last-child {
  color: #0099cc;
  cursor: pointer;
  margin-left: 10px;
}
.global-list-txt-1 {
  width: 90%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.global-list-txt-dd {
  width: 100%;
  /*white-space: wrap;*/
}
.expend-btn {
  color: #0099cc;
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 0;
}
.tip-num {
  font-size: 12px;
  .count {
    color: #cc0000;
    font-weight: bold;
    margin: 0 2px;
  }
}
.btn-raw {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .el-btn {
    font-size: 12px;
  }
  .left {
    display: flex;
    align-items: center;
    .search-content {
      display: flex;
      align-items: center;
      margin-left: 10px;
      .inquire {
        margin-left: 6px;
      }
    }
  }
}
.c-name-f {
  position: relative;
  padding-left: 10px;
  box-sizing: border-box;
}
.c-sub {
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
}
</style>
