<template>
  <div class="createPayBack-main">
    <div class="form-main">
      <el-form
        :model="formData"
        label-width="auto"
        size="small"
        :rules="rules"
        ref="form"
      >
        <el-form-item prop="userId" label="款项">
          <el-select
            v-model="formData.returnPayMent"
            placeholder="请选择款项"
            style="width: 100%"
            filterable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in returnPayMentList"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="contractId" label="关联合同">
          <el-input
            v-model="formData.contractId"
            placeholder="请选择关联合同"
          ></el-input>
          <div :class="type === 'create' ? 'input-mask' : 'input-mask'">
            <span>
              {{ cname }}
            </span>
            <span
              :class="type === 'create' ? 'create-edit-btn' : 'create-edit-btn'"
              @click="selectHeTong"
            >
              选择
            </span>
          </div>
        </el-form-item>

        <el-form-item prop="returnId" label="编号">
          <el-input
            v-model="formData.returnId"
            placeholder="请输入编号(选择关联合同后可自动生成)"
          ></el-input>
        </el-form-item>

        <el-form-item prop="returnMoney" label="金额">
          <el-input
            v-model="formData.returnMoney"
            placeholder="请输入金额"
          ></el-input>
        </el-form-item>

        <el-form-item prop="userId" label="业绩归属人">
          <el-select
            v-model="formData.userId"
            placeholder="请选择业绩归属人"
            style="width: 100%"
            filterable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in userList"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="returnDate" label="日期">
          <el-date-picker
            @change="returnDateChange"
            placeholder="年 / 月 / 日"
            style="width: 100%"
            v-model="formData.returnDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="achievementBelongDate" label="业绩归属月份">
          <el-date-picker
            placeholder="年 / 月"
            style="width: 100%"
            v-model="formData.achievementBelongDate"
            type="month"
            format="yyyy-MM"
            value-format="yyyy-MM"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="remark" label="备注">
          <el-input
            type="textarea"
            placeholder="请输入备注"
            v-model="formData.remark"
            maxlength="500"
            show-word-limit
            :autosize="{ minRows: 4, maxRows: 10 }"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancelBtn">取消</el-button>
      <el-button size="small" type="primary" @click="confirmBtn"
        >确认</el-button
      >
    </div>

    <el-dialog
      class="el-dialog-dt"
      width="850px"
      top="90px"
      :visible.sync="showSelectHeTong"
      :show-close="false"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
    >
      <div slot="title" class="slot-title">
        <div>
          关联合同
        </div>
        <div @click="showSelectHeTong = false">
          <i class="el-icon-close close"></i>
        </div>
      </div>

      <div>
        <select-he-tong
          @closeSelectCompany="showSelectHeTong = false"
          @selectCompany="selectCompany"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import selectHeTong from '@/views/contract/dialog/selectHeTong'
import {
  getPayBackNum,
  addPayBack,
  editPayBack,
  removeBackPayNum
} from '@/api/customerpond'
export default {
  name: 'createOrEditReturnBack',
  components: {
    selectHeTong
  },
  data() {
    const validateContractAmount = (rule, value, callback) => {
      let reg = /^[+]?(|([1-9]\d*))(\.\d+)?$/g
      if (value === '') {
        callback(new Error('请输入金额'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的金额，不能为零和负数'))
      } else {
        callback()
      }
    }
    return {
      type: 'create',
      formData: {
        returnId: '',
        contractId: '',
        returnMoney: '',
        userId: '',
        returnDate: '',
        achievementBelongDate: '',
        returnPayMent: 1,
        returnType: 2, // 退款
        remark: ''
      },
      returnPayMentList: [
        {
          id: 1,
          name: '正常'
        },
        {
          id: 2,
          name: '押金'
        }
      ],
      rules: {
        returnPayMent: [
          { required: true, message: '请选择款项类型', trigger: 'change' }
        ],
        returnId: [
          { required: true, message: '请输入编号', trigger: 'change' }
        ],
        contractId: [
          { required: true, message: '请选择关联合同', trigger: 'change' }
        ],
        returnMoney: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { validator: validateContractAmount, trigger: 'blur' }
        ],
        userId: [{ required: true, message: '请选择归属人', trigger: 'blur' }],
        returnDate: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        achievementBelongDate: [
          { required: true, message: '请选择业绩归属月份', trigger: 'blur' }
        ]
      },
      userList: [],
      showSelectHeTong: false,
      cname: '',
      id: ''
    }
  },

  watch: {
    'formData.achievementBelongDate'(val) {
      const date = val.split('-')
      if (date.length !== 3) {
        this.formData.achievementBelongDate = `${date[0]}-${date[1]}-01`
      }
    }
  },

  mounted() {
    const modalOptions = this.$store.state.plugin.modal.options
    this.userList = modalOptions.userList
    this.type = modalOptions.type || 'create'
    if (this.type === 'update') {
      const {
        id,
        achievementBelongDate,
        companyName,
        returnPayment,
        remark,
        contractId,
        contractReturnNumber,
        returnDate,
        returnMoney,
        userId
      } = modalOptions.data
      this.id = id
      this.formData.returnId = contractReturnNumber
      this.formData.contractId = contractId
      this.formData.returnMoney = returnMoney
      this.formData.userId = userId
      this.formData.returnDate = returnDate
      this.formData.achievementBelongDate = achievementBelongDate
      this.formData.returnPayMent = returnPayment ? parseInt(returnPayment) : 1
      this.formData.remark = remark
      this.cname = companyName
    }
    if (this.type === 'contractUpdate') {
      const { contractId, companyName } = modalOptions.contractData
      this.formData.contractId = contractId
      this.cname = companyName
      getPayBackNum({ contractId: contractId, returnType: 2 }).then(res => {
        if (res.code === 60000) {
          this.formData.returnId = res.data
        }
      })
    }
  },
  computed: {},
  methods: {
    selectCompany(val) {
      this.showSelectHeTong = false
      this.formData.contractId = val.id
      this.cname = val.companyName
      if (this.formData.returnId) return
      // 生成合同编号
      getPayBackNum({ contractId: val.id, returnType: 2 }).then(res => {
        if (res.code === 60000) {
          this.formData.returnId = res.data
        } else {
          // removeBackPayNum({contractId: val.id}).then()
        }
      })
    },

    selectHeTong() {
      // if(this.type !== 'create') return
      this.showSelectHeTong = true
    },

    returnDateChange(value) {
      this.formData.achievementBelongDate = value
    },

    cancelBtn() {
      this.$store.dispatch('hideModal')
    },

    confirmBtn() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        if (this.type !== 'update') {
          addPayBack(this.formData).then(res => {
            if (res.code === 60000) {
              this.$store.commit('update', {
                target: this.$store.state.plugin.modal.options,
                data: {
                  refresh: true
                }
              })
              this.cancelBtn()
            }
          })
        } else {
          editPayBack(Object.assign({}, this.formData, { id: this.id })).then(
            res => {
              if (res.code === 60000) {
                this.$store.commit('update', {
                  target: this.$store.state.plugin.modal.options,
                  data: {
                    refresh: true
                  }
                })
                this.cancelBtn()
              }
            }
          )
        }
      })
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.footer {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
}
.footer:before {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
}
.form-main {
  padding: 10px;
  box-sizing: border-box;
}
.input-mask {
  position: absolute;
  background-color: #fff;
  z-index: 9999;
  left: 1px;
  top: 1px;
  height: calc(100% - 3px);
  width: calc(100% - 2px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.edit-btn {
  color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}
.create-edit-btn {
  color: #41cabf;
  cursor: pointer;
}

.slot-title {
  height: 50px;
  background-color: #41cabf;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.el-dialog-dt ::v-deep .el-dialog__header,
.el-drawer-dt ::v-deep .el-drawer__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}

.el-dialog-dt ::v-deep .el-dialog__close,
.el-drawer-dt ::v-deep .el-drawer__close {
  color: #fff;
}

.el-dialog-dt ::v-deep .el-dialog__body,
.el-drawer-dt ::v-deep .el-drawer__body {
  padding: 0 !important;
}
.close {
  cursor: pointer;
}
.disable {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
