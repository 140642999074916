var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showModule
    ? _c("div", { ref: "moduleitem" }, [
        _c(
          "div",
          {
            ref: "computedComPic",
            staticClass: "title",
            attrs: { id: "computedComPic" },
          },
          [
            _vm._v("\n    " + _vm._s(_vm.moduleParam.module_title) + "\n    "),
            _vm.moduleParam.module_title == "触达信息" && false
              ? _c("i", {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "智能获客-推荐线索-脉脉触达",
                      expression: "'智能获客-推荐线索-脉脉触达'",
                    },
                  ],
                  staticClass: "maimialogo",
                  on: { click: _vm.openmaimai },
                })
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.refreshLoading,
                expression: "refreshLoading",
              },
            ],
            staticClass: "touch-cont-mang p-b-38",
          },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              _vm._l(_vm.tabArr, function (item, index) {
                return _c(
                  "el-tab-pane",
                  { key: index + item, attrs: { label: item, name: item } },
                  [
                    _c(
                      "div",
                      { staticClass: "touch-tab-cont" },
                      [
                        _c(_vm.modulCont, {
                          key: item,
                          ref: "comModuleCont",
                          refInFor: true,
                          tag: "component",
                          attrs: {
                            otherParams: _vm.otherParams,
                            cardType: _vm.cardType,
                            detail: _vm.detail,
                            moduleParam: _vm.moduleParam,
                            moduleDetail: _vm.moduleDetail,
                            packageId: _vm.packageId,
                          },
                          on: { getComDetailModel: _vm.getComDetailModel },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }