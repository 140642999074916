var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container land-page-app" }, [
    _c(
      "div",
      {
        staticClass: "land-page-header detail-title",
        staticStyle: { "font-size": "14px", "line-height": "50px" },
      },
      [
        _c(
          "span",
          {
            staticClass: "color-41CABF click",
            on: {
              click: function ($event) {
                return _vm.goBackPage()
              },
            },
          },
          [_vm._v("营销表单管理")]
        ),
        _vm._v(" "),
        _c("span", [_vm._v(">")]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.navName))]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "line-body m-t-4",
        staticStyle: { "margin-top": "4px", padding: "0px 0" },
      },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.getListLoading,
                expression: "getListLoading",
              },
            ],
            ref: "myCollectSelect",
            staticStyle: {
              width: "100%",
              "font-size": "12px",
              padding: "0 20px",
            },
            attrs: { data: _vm.listinfo, "empty-text": "暂无数据" },
            on: { "selection-change": _vm.selectionChange },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "formName",
                "show-overflow-tooltip": "",
                label: "公司",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.info.company) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "clickNum",
                "show-overflow-tooltip": "",
                label: "姓名",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.info.contact) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "leaveNum",
                "show-overflow-tooltip": "",
                label: "手机号",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.info.phone) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.pageStatus.total
          ? _c("div", { staticClass: "page-div" }, [
              _c(
                "div",
                { staticStyle: { float: "right" } },
                [
                  _c("footer-page", {
                    attrs: { pageStatus: _vm.pageStatus },
                    on: {
                      handleCurrentChange: _vm.handleCurrentChange,
                      goNumPage: _vm.goNumPage,
                      changePageSize: _vm.changePageSize,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }