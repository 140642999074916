var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "earnLink-main" }, [
    _c("div", { staticClass: "qr-code-cont" }, [
      _c("div", { ref: "qrCode", attrs: { id: "qrCode" } }),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "10px" } }, [
        _c(
          "span",
          {
            staticClass: "qr-code-txt qr-code-ac",
            staticStyle: { "font-size": "12px" },
            on: { click: _vm.handleCopy },
          },
          [_vm._v("复制链接")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "qr-code-txt qr-code-ac",
            staticStyle: { "font-size": "12px" },
            on: { click: _vm.handleDown },
          },
          [_vm._v("下载二维码")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$store.dispatch("hideModal")
              },
            },
          },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }