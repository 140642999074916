import { API, BASE_REQUEST } from '../_dtbird_api/api_service'


// 通话数据
export const callInfo = async(params) => {
  const api = API('callInfoPath')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 通话数据下拉选项
export const callOption = async(params) => {
  const api = API('callBoardOption')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 通话数据下拉选项
export const callDataChart = async(params) => {
  const api = API('callDataChart')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 通话数据表
export const callDataTable = async(params) => {
  const api = API('callDataTable')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const exportCallList = async(params) => {
  const api = API('exportCallList')
  return await BASE_REQUEST({ api: api, opt: params })
}

