var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-custom-tab", style: _vm.mapStyle },
    _vm._l(_vm.tabData, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          class: _vm.activeClass(index),
          style: _vm.iconClass(item),
          on: {
            click: function ($event) {
              return _vm.tabClick(index, item)
            },
          },
        },
        [_c("span", [_vm._v(_vm._s(item))])]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }