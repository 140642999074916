var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "register-main" }, [
    _c("div", {
      staticClass: "header",
      on: {
        click: function ($event) {
          return _vm.$router.replace("/login")
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "wel-come" }, [
        _vm._v("\n      欢迎注册领鸟云数据平台\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "form-row",
          attrs: {
            "element-loading-text": "正在注册",
            "element-loading-spinner": "el-icon-loading",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "el-form-dt",
              attrs: {
                "label-width": "auto",
                size: "medium",
                model: _vm.formData,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "companyName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入公司名称" },
                    model: {
                      value: _vm.formData.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "companyName", $$v)
                      },
                      expression: "formData.companyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公司规模", prop: "companySize" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择公司规模" },
                      model: {
                        value: _vm.formData.companySize,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "companySize", $$v)
                        },
                        expression: "formData.companySize",
                      },
                    },
                    _vm._l(_vm.companyGuimo, function (item) {
                      return _c("el-option", {
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户名", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入客户用户名" },
                    model: {
                      value: _vm.formData.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "userName", $$v)
                      },
                      expression: "formData.userName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入客户密码", type: "password" },
                    model: {
                      value: _vm.formData.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "password", $$v)
                      },
                      expression: "formData.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号码", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.formData.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "mobile", _vm._n($$v))
                      },
                      expression: "formData.mobile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "密钥", prop: "secret" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入密钥", type: "password" },
                    model: {
                      value: _vm.formData.secret,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "secret", $$v)
                      },
                      expression: "formData.secret",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-el",
                      attrs: { type: "primary" },
                      on: { click: _vm.register },
                    },
                    [_vm._v("\n            提交注册\n          ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("div", [
          _c("span", [_vm._v("已有领鸟云数据平台账号，")]),
          _c("span", { staticClass: "login", on: { click: _vm.goLogin } }, [
            _vm._v("点击登录"),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }