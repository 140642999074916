var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-main" }, [
    _c(
      "div",
      { staticClass: "tab-div" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "dt-tab",
            on: { "tab-click": _vm.tabClick },
            model: {
              value: _vm.tabType,
              callback: function ($$v) {
                _vm.tabType = $$v
              },
              expression: "tabType",
            },
          },
          [
            _vm.permissionList.includes(
              "客户管理CRM-客户公海-我负责的-查看客户"
            )
              ? _c("el-tab-pane", { attrs: { label: "我负责的", name: "1" } })
              : _vm._e(),
            _vm._v(" "),
            _vm.permissionList.includes(
              "客户管理CRM-客户公海-全公司的-查看客户"
            )
              ? _c("el-tab-pane", { attrs: { label: "全公司的", name: "3" } })
              : _vm._e(),
            _vm._v(" "),
            _vm.permissionList.includes(
              "客户管理CRM-客户公海-可跟进的-查看客户"
            )
              ? _c("el-tab-pane", { attrs: { label: "可跟进的", name: "2" } })
              : _vm._e(),
            _vm._v(" "),
            _vm.permissionList.includes("客户管理CRM-客户公海-草稿箱-查看客户")
              ? _c("el-tab-pane", { attrs: { label: "草稿箱", name: "4" } })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "dt-permission-or",
            rawName: "v-dt-permission-or",
            value: [
              "客户管理CRM-客户公海-我负责的-查看客户",
              "客户管理CRM-客户公海-全公司的-查看客户",
              "客户管理CRM-客户公海-可跟进的-查看客户",
              "客户管理CRM-客户公海-草稿箱-查看客户",
            ],
            expression:
              "[\n      '客户管理CRM-客户公海-我负责的-查看客户',\n      '客户管理CRM-客户公海-全公司的-查看客户',\n      '客户管理CRM-客户公海-可跟进的-查看客户',\n      '客户管理CRM-客户公海-草稿箱-查看客户'\n    ]",
          },
        ],
        staticClass: "btn-list",
      },
      [
        _c(
          "div",
          { staticClass: "input-row" },
          [
            _c("el-input", {
              attrs: {
                size: "mini",
                placeholder: "输入姓名/公司名/手机号查询",
              },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "search",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-row" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.searchAgent },
                  },
                  [_vm._v("查询")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.reset } },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "dt-permission",
                rawName: "v-dt-permission",
                value: "客户管理CRM-联系人管理-新建联系人",
                expression: "'客户管理CRM-联系人管理-新建联系人'",
              },
            ],
            attrs: { size: "mini", type: "primary" },
            on: { click: _vm.contactCreate },
          },
          [_vm._v("\n      新建联系人\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
          {
            name: "dt-permission-or",
            rawName: "v-dt-permission-or",
            value: [
              "客户管理CRM-客户公海-我负责的-查看客户",
              "客户管理CRM-客户公海-全公司的-查看客户",
              "客户管理CRM-客户公海-可跟进的-查看客户",
              "客户管理CRM-客户公海-草稿箱-查看客户",
            ],
            expression:
              "[\n      '客户管理CRM-客户公海-我负责的-查看客户',\n      '客户管理CRM-客户公海-全公司的-查看客户',\n      '客户管理CRM-客户公海-可跟进的-查看客户',\n      '客户管理CRM-客户公海-草稿箱-查看客户'\n    ]",
          },
        ],
        staticClass: "table",
      },
      [
        _c(
          "el-table",
          {
            key: _vm.refreshKey,
            attrs: { data: _vm.tableData, stripe: "", border: "" },
            on: { "sort-change": _vm.sortChange },
          },
          _vm._l(_vm.columnList, function (item) {
            return _c(
              "el-table-column",
              {
                key: item.field,
                attrs: {
                  "show-tooltip-when-overflow": "",
                  prop: item.field,
                  align: "center",
                  width: _vm.columnWidth(item),
                  label: item.name,
                  sortable: item.type === "sort" ? "custom" : false,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.field === "setting"
                            ? _c("span", { staticClass: "set" }, [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "dt-permission",
                                        rawName: "v-dt-permission",
                                        value: _vm.permissionRecord,
                                        expression: "permissionRecord",
                                      },
                                    ],
                                    staticClass: "set-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getRecord(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              跟进记录\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.tabName == "我负责的",
                                        expression: "tabName == '我负责的'",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "set-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editUser(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                编辑\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "set-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delUser(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                删除\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.tabName == "全公司的" ||
                                          _vm.tabName == "可跟进的" ||
                                          _vm.tabName == "草稿箱",
                                        expression:
                                          "\n                tabName == '全公司的' ||\n                  tabName == '可跟进的' ||\n                  tabName == '草稿箱'\n              ",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "dt-permission",
                                            rawName: "v-dt-permission",
                                            value: _vm.permissionEdit,
                                            expression: "permissionEdit",
                                          },
                                        ],
                                        staticClass: "set-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editUser(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                编辑\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "dt-permission",
                                            rawName: "v-dt-permission",
                                            value: _vm.permissionDel,
                                            expression: "permissionDel",
                                          },
                                        ],
                                        staticClass: "set-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delUser(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                删除\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : item.field === "companyName"
                            ? _c(
                                "span",
                                {
                                  class: _vm.isLink ? "c-name" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jump(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row[item.field]) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : item.field === "bindWechatStr"
                            ? _c(
                                "span",
                                [
                                  scope.row[item.field] === "已关联"
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              已关联\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : item.field === "landLine" ||
                              item.field === "mobile"
                            ? _c("span", [
                                scope.row[item.field] && _vm.isWaiHu
                                  ? _c("span", {
                                      staticClass: "call-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.call(
                                            scope.row[item.field],
                                            scope.row["contact"],
                                            scope.row["companyName"],
                                            scope.row,
                                            item.field
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row[item.field] &&
                                scope.row[item.field].includes("*")
                                  ? _c(
                                      "span",
                                      { staticClass: "hoverMsg" },
                                      [_c("HoverMsg")],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", { staticClass: "num" }, [
                                  _vm._v(_vm._s(scope.row[item.field])),
                                ]),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row[item.field]) +
                                    "\n          "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c("template", { slot: "header" }, [
                  item.type !== "filter"
                    ? _c("span", [_vm._v(" " + _vm._s(item.name) + " ")])
                    : _c(
                        "span",
                        [
                          _c(
                            "el-dropdown",
                            {
                              key: _vm.refreshKey,
                              attrs: { placement: "bottom", trigger: "click" },
                              on: {
                                command: (val) => _vm.filterFunc(val, item),
                              },
                            },
                            [
                              item.field === "category"
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-dropdown-link",
                                        class: _vm.category ? "active" : "",
                                      },
                                      [
                                        _c("span", [_vm._v(_vm._s(item.name))]),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-scrollbar",
                                          { staticClass: "dt-dropdown-dt" },
                                          _vm._l(
                                            _vm.categoryList,
                                            function (_item) {
                                              return _c(
                                                "el-dropdown-item",
                                                {
                                                  class:
                                                    _vm.category === _item.value
                                                      ? "active"
                                                      : "",
                                                  attrs: {
                                                    command: _item.value,
                                                  },
                                                },
                                                [_vm._v(_vm._s(_item.name))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : item.field === "bindWechatStr"
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-dropdown-link",
                                        class: _vm.bindWechat ? "active" : "",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: _vm.bindWechat
                                              ? "active"
                                              : "",
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-scrollbar",
                                          { staticClass: "dt-dropdown-dt" },
                                          _vm._l(
                                            _vm.bindWechatList,
                                            function (_item) {
                                              return _c(
                                                "el-dropdown-item",
                                                {
                                                  class:
                                                    _vm.bindWechat ===
                                                    _item.value
                                                      ? "active"
                                                      : "",
                                                  attrs: {
                                                    command: _item.value,
                                                  },
                                                },
                                                [_vm._v(_vm._s(_item.name))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-dropdown-link",
                                        class: _vm.followUserId ? "active" : "",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: _vm.followUserId
                                              ? "active"
                                              : "",
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-scrollbar",
                                          { staticClass: "dt-dropdown-dt" },
                                          _vm._l(
                                            _vm.followUserIdList,
                                            function (_item) {
                                              return _c(
                                                "el-dropdown-item",
                                                {
                                                  class:
                                                    _vm.followUserId ===
                                                    _item.id
                                                      ? "active"
                                                      : "",
                                                  attrs: { command: _item.id },
                                                },
                                                [_vm._v(_vm._s(_item.name))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                ]),
              ],
              2
            )
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "dt-permission-or",
            rawName: "v-dt-permission-or",
            value: [
              "客户管理CRM-客户公海-我负责的-查看客户",
              "客户管理CRM-客户公海-全公司的-查看客户",
              "客户管理CRM-客户公海-可跟进的-查看客户",
              "客户管理CRM-客户公海-草稿箱-查看客户",
            ],
            expression:
              "[\n      '客户管理CRM-客户公海-我负责的-查看客户',\n      '客户管理CRM-客户公海-全公司的-查看客户',\n      '客户管理CRM-客户公海-可跟进的-查看客户',\n      '客户管理CRM-客户公海-草稿箱-查看客户'\n    ]",
          },
        ],
        staticClass: "page page-div page-1",
      },
      [
        _c("footer-page", {
          attrs: { pageStatus: _vm.pageStatus },
          on: {
            handleCurrentChange: _vm.handleCurrentChange,
            goNumPage: _vm.goNumPage,
            changePageSize: _vm.changePageSize,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }