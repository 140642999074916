<template>
  <div
    class="proxy-setting-main"
    v-loading="loading"
    element-loading-text="加载中"
  >
    <el-scrollbar class="dt-dropdown-dt scroll">
      <div class="main">
        <el-form label-width="85px" :rules="rules" ref="form" :model="formData">
          <el-form-item label="线索包">
            <el-radio v-model="packageType" label="all">全部</el-radio>
            <el-radio v-model="packageType" label="userSelect">自定义</el-radio>
            <el-row :gutter="10" v-if="packageType === 'userSelect'">
              <el-col
                :span="12"
                v-for="item in packageAllList"
                :key="item.id"
                class="col"
              >
                <el-checkbox
                  :title="item.packageName"
                  class="checkbox-dt-dt send_check_btn"
                  @change="changePackage(item.id)"
                  :value="selectPackageList.includes(item.id)"
                >
                  {{ item.packageName }}
                </el-checkbox>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="线索可见性">
            <span slot="label" class="label">
              线索可见性
            </span>
            <el-switch v-model="formData.isViewKey"></el-switch>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="18">
              <el-form-item label="子账号数" prop="accountLimitBalance">
                <el-input
                  v-model.number="formData.accountLimitBalance"
                  placeholder="请输入账号数"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <span class="tip">
                {{ tip.accountLimitBalance }}
              </span>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="18">
              <el-form-item prop="companyAvailableFreeBalance">
                <span slot="label" class="label"> 推荐线索<br />领取条数 </span>
                <el-input
                  v-model.number="formData.companyAvailableFreeBalance"
                  placeholder="请输入推荐线索领取条数"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <span class="tip">
                {{ tip.companyAvailableFreeBalance }}
              </span>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="18">
              <el-form-item prop="zizhuAvailableFreeBalance">
                <span slot="label" class="label"> 自主找客<br />领取条数 </span>
                <el-input
                  v-model.number="formData.zizhuAvailableFreeBalance"
                  placeholder="请输入自主找客领取条数"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <span class="tip">
                {{ tip.zizhuAvailableFreeBalance }}
              </span>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="18">
              <el-form-item prop="exclusiveAvailableFreeBalance">
                <span slot="label" class="label"> 独家信息<br />领取条数 </span>
                <el-input
                  v-model.number="formData.exclusiveAvailableFreeBalance"
                  placeholder="请输入独家信息领取条数"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <span class="tip">
                {{ tip.exclusiveAvailableFreeBalance }}
              </span>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-scrollbar>
    <div class="footer">
      <div>
        <el-button size="small" @click="cancelBtn">取消</el-button>
        <el-button size="small" type="primary" @click="confirmBtn"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { packageAll } from '@/api/subaccount'
import { agentDetail, agentEdit } from '@/api/user'

export default {
  name: 'proxy-setting',
  data() {
    return {
      id: null,
      packageAllList: [], //线索包列表
      selectPackageList: [],
      packageType: 'all',
      loading: false,
      formData: {
        isViewKey: false,
        accountLimitBalance: null,
        companyAvailableFreeBalance: null,
        zizhuAvailableFreeBalance: null,
        exclusiveAvailableFreeBalance: null
      },
      rules: {
        accountLimitBalance: [
          { type: 'number', message: '账号数必须为数字值' }
        ],
        companyAvailableFreeBalance: [
          { type: 'number', message: '推荐线索领取条数必须为数字值' }
        ],
        zizhuAvailableFreeBalance: [
          { type: 'number', message: '自主找客领取条数必须为数字值' }
        ],
        exclusiveAvailableFreeBalance: [
          { type: 'number', message: '独家信息领取条数必须为数字值' }
        ]
      },

      tip: {
        accountLimitBalance: '',
        companyAvailableFreeBalance: '',
        zizhuAvailableFreeBalance: '',
        exclusiveAvailableFreeBalance: ''
      }
    }
  },
  watch: {
    // 清空输入框校验bug
    'formData.accountLimitBalance': function(val) {
      val === '' ? (this.formData.accountLimitBalance = null) : ''
    },
    'formData.companyAvailableFreeBalance': function(val) {
      val === '' ? (this.formData.companyAvailableFreeBalance = null) : ''
    },
    'formData.zizhuAvailableFreeBalance': function(val) {
      val === '' ? (this.formData.zizhuAvailableFreeBalance = null) : ''
    },
    'formData.exclusiveAvailableFreeBalance': function(val) {
      val === '' ? (this.formData.exclusiveAvailableFreeBalance = null) : ''
    }
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    const modalOption = this.$store.state.plugin.modal.options
    this.id = modalOption.data.id
    this.formData.accountLimitBalance = modalOption.data.accountLimit - 1 || 0
    this.formData.companyAvailableFreeBalance =
      modalOption.data.companyAvailableFree || 0
    this.formData.zizhuAvailableFreeBalance =
      modalOption.data.zizhuAvailableFree || 0
    this.formData.exclusiveAvailableFreeBalance =
      modalOption.data.exclusiveAvailableFree || 0
    this.getValidator()
    this.getPackageAllList()
  },
  computed: {},
  methods: {
    getValidator() {
      agentDetail({ id: this.id }).then(res => {
        if (res.code === 60000 && res.data) {
          const data = res.data
          this.packageType = data.packageIds === 'ALL' ? 'all' : 'userSelect'
          this.tip.accountLimitBalance = `剩余可分配${
            data.accountLimitBalance
          }个`
          this.tip.companyAvailableFreeBalance = `剩余可分配${
            data.companyAvailableFreeBalance
          }条`
          this.tip.zizhuAvailableFreeBalance = `剩余可分配${
            data.zizhuAvailableFreeBalance
          }条`
          this.tip.exclusiveAvailableFreeBalance = `剩余可分配${
            data.exclusiveAvailableFreeBalance
          }条`
          this.formData.isViewKey = !!data.visibility
          if (data.packageIds !== 'ALL' && data.packageIdList) {
            this.selectPackageList = res.data.packageIdList
          }
          this.addRules(
            data.accountLimitBalance,
            data.companyAvailableFreeBalance,
            data.zizhuAvailableFreeBalance,
            data.exclusiveAvailableFreeBalance
          )
        }
      })
    },

    addRules(
      accountLimitBalance = 0,
      companyAvailableFreeBalance = 0,
      zizhuAvailableFreeBalance = 0,
      exclusiveAvailableFreeBalance = 0
    ) {
      this.rules.accountLimitBalance.push({
        type: 'number',
        min: 0,
        max: accountLimitBalance,
        message: `剩余可分配 ${accountLimitBalance} 个`,
        trigger: 'change'
      })
      this.rules.companyAvailableFreeBalance.push({
        type: 'number',
        min: 0,
        max: companyAvailableFreeBalance,
        message: `剩余可分配 ${companyAvailableFreeBalance} 条`,
        trigger: 'change'
      })
      this.rules.zizhuAvailableFreeBalance.push({
        type: 'number',
        min: 0,
        max: zizhuAvailableFreeBalance,
        message: `剩余可分配 ${zizhuAvailableFreeBalance} 条`,
        trigger: 'change'
      })
      this.rules.exclusiveAvailableFreeBalance.push({
        type: 'number',
        min: 0,
        max: exclusiveAvailableFreeBalance,
        message: `剩余可分配 ${exclusiveAvailableFreeBalance} 条`,
        trigger: 'change'
      })
    },

    cancelBtn() {
      this.$store.dispatch('hideModal')
    },

    confirmBtn() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        const params = {
          id: this.id,
          visibility: this.formData.isViewKey ? 1 : 0,
          accountLimit: this.formData.accountLimitBalance || 0,
          packageIds:
            this.packageType === 'all'
              ? 'ALL'
              : this.selectPackageList.join(','),
          companyAvailableFree: this.formData.companyAvailableFreeBalance || 0,
          zizhuAvailableFree: this.formData.zizhuAvailableFreeBalance || 0,
          exclusiveAvailableFree:
            this.formData.exclusiveAvailableFreeBalance || 0
        }
        agentEdit(params)
          .then(res => {
            if (res.code === 60000) {
              this.tipTip(res.msg)
              this.$store.commit('update', {
                target: this.$store.state.plugin.modal.options,
                data: { refresh: true }
              })
              this.$store.dispatch('hideModal')
            } else {
              this.tipTip(res.msg, 'error')
            }
          })
          .catch()
      })
    },

    tipTip(msg, type = 'success') {
      this.$store.dispatch('showTip', {
        text: msg,
        type: type
      })
    },

    getPackageAllList() {
      packageAll().then(res => {
        if (res.code === 60000 && res.data) {
          this.packageAllList = res.data
        }
      })
    },

    changePackage(id) {
      if (this.selectPackageList.includes(id)) {
        this.selectPackageList = this.selectPackageList.filter(
          item => item !== id
        )
      } else {
        this.selectPackageList.push(id)
      }
    }
  }
}
</script>

<style scoped>
.proxy-setting-main {
  width: 100%;
  box-sizing: border-box;
}
.scroll {
  height: 405px;
  width: 100%;
}
.main {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 10px;
  box-sizing: border-box;
}
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  position: relative;
  padding-right: 20px;
  box-sizing: border-box;
}
.footer::before {
  content: '';
  height: 1px;
  width: calc(100% + 40px);
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: -20px;
  position: absolute;
  transform: scaleY(0.5);
}
.label {
  line-height: 18px !important;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: flex-end;
}
.send_check_btn {
  margin-right: 5px;
}
.send_check_btn ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #78d9d1;
}
.send_check_btn ::v-deep .el-checkbox__inner {
  border-color: #78d9d1;
}
.checkbox-dt-dt /deep/ .el-checkbox__label {
  color: #606266 !important;
}
.col {
  line-height: 30px;
}
.tip {
  font-size: 10px;
  display: flex;
  height: 40px;
  align-items: center;
}
</style>
