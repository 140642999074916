var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mapLoading,
          expression: "mapLoading",
        },
      ],
      ref: "pointMap",
      staticClass: "screenClass",
      style: _vm.mapFull,
      attrs: { id: "screenMap" },
    },
    [
      _c("div", { ref: "info", staticClass: "map-info" }, [
        _c("div", [_vm._v(_vm._s(_vm.infoWindowData.chartName))]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.infoWindowData.hot))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }