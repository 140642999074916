var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "list-div" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "dt-permission",
              rawName: "v-dt-permission",
              value: "系统管理-角色与权限-新建角色",
              expression: "'系统管理-角色与权限-新建角色'",
            },
          ],
          staticClass: "create-btn",
          on: { click: _vm.createRule },
        },
        [_vm._v("\n      新建角色\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "dt-permission",
            rawName: "v-dt-permission",
            value: "系统管理-角色与权限-查看角色",
            expression: "'系统管理-角色与权限-查看角色'",
          },
        ],
        staticClass: "content",
      },
      [
        _c("el-scrollbar", { staticClass: "scroll" }, [
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { data: _vm.roleList },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "roleName",
                      label: "角色名",
                      width: "width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(scope.row["roleName"])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "prop", label: "操作", width: "width" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.handleRole(scope.row.roleName)
                              ? _c("div", [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "dt-permission",
                                          rawName: "v-dt-permission",
                                          value: "系统管理-角色与权限-编辑",
                                          expression:
                                            "'系统管理-角色与权限-编辑'",
                                        },
                                      ],
                                      staticClass: "operate edit",
                                      attrs: { title: "编辑角色" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editRole(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "dt-permission",
                                          rawName: "v-dt-permission",
                                          value: "系统管理-角色与权限-删除",
                                          expression:
                                            "'系统管理-角色与权限-删除'",
                                        },
                                      ],
                                      staticClass: "operate del",
                                      attrs: { title: "删除角色" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeRole(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "dt-permission",
                                          rawName: "v-dt-permission",
                                          value: "系统管理-角色与权限-查看",
                                          expression:
                                            "'系统管理-角色与权限-查看'",
                                        },
                                      ],
                                      staticClass: "view",
                                      attrs: { title: "查看角色" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewRole(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }