<template>
  <div class="chat-main">
    <div class="header">
      <el-form size="small" inline class="el-form-dt">
        <el-form-item>
          <el-input placeholder="群名称" v-model="name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formInline" placeholder="是否已经删除">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">选择员工</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">同步群聊</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="chat-table">
      <dt-table
        :hide-border="true"
        :is-add-earn-field="false"
        :data-total="tableData.length"
        :table-loading="loading"
        :table-data="tableData"
        @sizeChange="sizeChange"
        @currentChange="currentChange"
        :page-size="[20]"
        :column-list="columnList"
      >
        <template v-slot:nameRow="row">
          {{ row.scope.data.name }}
        </template>
        <template v-slot:userRow="row">
          <el-button size="mini">
            <i class="el-icon-user" style="color: #41CABF"></i>
            {{ row.scope.data.user }}
          </el-button>
        </template>
      </dt-table>
    </div>
  </div>
</template>

<script>
import DtTable from '@/components/element/DtTable'
export default {
  components: {
    DtTable
  },
  name: 'chatmanage',
  data() {
    return {
      formInline: '',
      name: '',
      columnList: [
        {
          field: 'name',
          name: '群组名称'
        },
        {
          field: 'user',
          name: '群主'
        },
        {
          field: 'status',
          name: '群状态'
        },
        {
          field: 'num',
          name: '群人数'
        },
        {
          field: 'numDay',
          name: '今日入群'
        },
        {
          field: 'numleave',
          name: '今日退群人数'
        },
        {
          field: 'createAt',
          name: '创建时间'
        }
      ],
      tableData: [],
      loading: false
    }
  },
  mounted() {
    this.init(20)
  },
  methods: {
    sizeChange() {
      this.init(20)
    },
    currentChange() {
      this.init(20)
    },
    init(pageNum = 20) {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.tableData = [
          {
            name: '上海齐材电子商务@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '3',
            numDay: '0',
            numleave: '0',
            createAt: '2020-06-03 19:58:32'
          },
          {
            name: '深圳市华海技术@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '2',
            numDay: '0',
            numleave: '0',
            createAt: '2020-06-03 14:18:12'
          },
          {
            name: '深圳市伊爱@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '4',
            numDay: '0',
            numleave: '0',
            createAt: '2020-06-05 19:22:30'
          },
          {
            name: '苏州天梯卓越科技@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '9',
            numDay: '0',
            numleave: '0',
            createAt: '2020-11-05 10:10:40'
          },
          {
            name: '杭州田螺科技@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '6',
            numDay: '0',
            numleave: '0',
            createAt: '2020-03-05 09:10:20'
          },
          {
            name: '南京汉道网@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '10',
            numDay: '1',
            numleave: '0',
            createAt: '2020-12-01 08:10:22'
          },
          {
            name: '广东悦丰科技@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '8',
            numDay: '2',
            numleave: '0',
            createAt: '2020-11-30 08:11:24'
          },
          {
            name: '杭州两平米智能@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '6',
            numDay: '1',
            numleave: '0',
            createAt: '2020-10-30 08:22:24'
          },
          {
            name: '拉萨隆达科技@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '10',
            numDay: '0',
            numleave: '0',
            createAt: '2020-08-30 09:21:20'
          },
          {
            name: '上海享推信息@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '15',
            numDay: '0',
            numleave: '0',
            createAt: '2020-10-30 08:15:20'
          },
          {
            name: '上海汇金科技@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '18',
            numDay: '0',
            numleave: '0',
            createAt: '2020-01-30 10:15:30'
          },
          {
            name: '艾莱斯曼（深圳）科技@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '20',
            numDay: '0',
            numleave: '0',
            createAt: '2020-03-20 11:20:38'
          },
          {
            name: '北京科天科技@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '16',
            numDay: '0',
            numleave: '0',
            createAt: '2020-05-12 14:28:10'
          },
          {
            name: '西安归毂智能@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '28',
            numDay: '2',
            numleave: '0',
            createAt: '2020-03-08 15:10:20'
          },
          {
            name: '上海玉月网络@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '20',
            numDay: '0',
            numleave: '0',
            createAt: '2020-08-08 10:20:30'
          },
          {
            name: '深圳智联@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '10',
            numDay: '0',
            numleave: '0',
            createAt: '2020-01-08 13:22:11'
          },
          {
            name: '上海乐坤网络@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '20',
            numDay: '0',
            numleave: '0',
            createAt: '2020-10-08 12:22:20'
          },
          {
            name: '合肥中鼎信息@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '10',
            numDay: '2',
            numleave: '0',
            createAt: '2020-03-08 11:20:32'
          },
          {
            name: '北京攸乐@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '40',
            numDay: '2',
            numleave: '0',
            createAt: '2019-10-08 10:58:30'
          },
          {
            name: '深圳市探究科技@领鸟云交流群',
            user: '领鸟云小依',
            status: '正常',
            num: '21',
            numDay: '1',
            numleave: '0',
            createAt: '2020-04-08 19:38:20'
          }
        ]
      }, 1000)
    }
  }
}
</script>

<style scoped>
.chat-main {
  height: 100%;
  width: 100%;
}
.header {
  height: 50px;
  width: 100%;
  background-color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.el-form-dt ::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0 !important;
}
.chat-table {
  height: calc(100% - 50px);
  width: 100%;
  background-color: #fff;
  padding: 0 5px;
  box-sizing: border-box;
}
.name {
  display: flex;
}
</style>
