<template>
  <div class="tools-main">
    <el-row :gutter="40" v-dt-permission="'华为云代理商工具包-常用工具-查看'">
      <el-col :span="12" v-for="(item, index) in list">
          <div :class=" current === item.name ? 'item current' :'item'">
            <span  @click="jump(item)">
              {{ item.name }}
            </span>
          </div>
      </el-col>
    </el-row>
    <a id="c_order" href="javascript:void(0)" style="display:none;" ref="aLink"></a>
  </div>
</template>

<script>
export default {
  name: 'tools',
  data () {
    return {
      list: [
        {
          name: '合作伙伴官网（日常操作网址）',
          url: 'https://partner.huawei.com/web/china'
        },
        {
          name: '企业业务官网',
          url: 'https://e.huawei.com/cn/'
        },
        {
          name: '经销商注册操作指南',
          url: 'https://partner.huawei.com/university/webui/activities/showActivites.html?courseId=b9f11b3ef1b64268aa9eafcb164e8f6d'
        },
        {
          name: '华为生态大学',
          url: 'https://partner.huawei.com/university/webui/home/home.html'
        },
        {
          name: '华为合作伙伴APP使用指导',
          url: 'https://partner.huawei.com/university/webui/activities/showActivites.html?courseId=c23493fa8d7044ea93e8d07560faf348'
        },
        {
          name: '综合学院（涵盖渠道政策、高管培训和销售微认证等丰富内容）',
          url: 'https://partner.huawei.com/university/webui/college/comprehensive/comprehensiveMain.html'
        },
        {
          name: '售前解决方案学院',
          url: 'https://partner.huawei.com/university/webui/college/solution/solutionMain.html'
        },
        {
          name: '售后服务学院',
          url: 'https://partner.huawei.com/university/webui/college/service/serviceMain.html'
        },
        {
          name: '商务学院（面向合作伙伴销售、商务、财经、供应链人员提供华为商务交易知识）',
          url: 'https://partner.huawei.com/university/webui/college/business/businessMain.html'
        },
        {
          name: '华为认证考试体系（含售前、售后、商务、销售各类认证，以及技能评估考试）',
          url: 'https://partner.huawei.com/university/webui/cert/certMore.html?certType=7&type=c#tabPanel1'
        },
        {
          name: '售前认证体系介绍',
          url: 'https://partner.huawei.com/university/webui/college/solution/certs.html'
        },
        {
          name: '售后认证体系介绍',
          url: 'https://partner.huawei.com/university/webui/college/service/certMore1.html'
        },
        {
          name: '认证与能力查询',
          url: 'https://partner.huawei.com/group/china/channel-capacity-map'
        },
        {
          name: '认证证书真伪验证',
          url: 'https://e.huawei.com/cn/talent/#/cert/certificate-verification'
        },
        {
          name: '认证能力管理及员工证书关联指导',
          url: 'https://partner.huawei.com/university/webui/activities/showActivites.html?courseId=32307de17edf4ec886c342cd5489321c'
        },
        {
          name: 'CSP认证标准及认证流程指导',
          url: 'https://partner.huawei.com/university/webui/activities/showActivites.html?courseId=e82d71688dac471f997e2556404deb8e'
        },
        {
          name: '合作伙伴解决方案展示网址',
          url: 'https://e.huawei.com/cn/marketplace'
        },{
          name: '项目报备',
          url: 'https://partner.huawei.com/group/china/project-submission'
        },{
          name: '经营管理（激励与业绩查询）',
          url: 'https://partner.huawei.com/group/china/project-submission'
        },{
          name: '经营管理（协议签署、投诉举报）',
          url: 'https://partner.huawei.com/group/china/project-submission'
        },{
          name: '合作伙伴培训奖励券使用指南',
          url: 'https://partner.huawei.com/university/webui/activities/showActivites.html?courseId=94aaaef62dd4452fba6b67db8ac315d8'
        },{
          name: '精英俱乐部积分兑换指导书',
          url: 'https://partner.huawei.com/university/webui/activities/showActivites.html?courseId=22c7a60325ad4e8f9a8672e2d0e3269a'
        },

      ],
      current: ''
    }
  },
  mounted () {},
  computed: {},
  methods: {
    jump(item){
      this.current = item.name
      const win = window.open('', 'right')
      const link = this.$refs.aLink
      link.target = 'right'
      link.href = item.url
      link.rel = "noreferrer"
      link.click()
      win.focus()
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.tools-main{
  height: calc( 100% - 10px );
  width: calc( 100% - 20px );
  background-color: #fff;
  margin: 10px 0 0 10px;
  box-sizing: border-box;
}
.item{
  color: #8A8A8A;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
.item > span{
  cursor: pointer;
}
.item:hover{
  color: #0099CC;
  background-color: rgba(0,0,0,.1);
}
.current{
  color: #0099CC;
  background-color: rgba(0,0,0,.1);
}
</style>
