var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "detail-wrap" }, [
        _c(
          "span",
          {
            staticClass: "key-title",
            on: {
              click: function ($event) {
                return _vm.goBackPage()
              },
            },
          },
          [_vm._v(_vm._s(_vm.currentTitle))]
        ),
        _vm._v(" "),
        _c("span", [_vm._v(">")]),
        _vm._v(" "),
        _c("span", [_vm._v("详情")]),
      ]),
      _vm._v(" "),
      _vm.showCompanyNameList && _vm.showScroll
        ? _c("pe-swiper", {
            ref: "swiper",
            attrs: { scrollList: _vm.scrollList },
            on: { refreshDetail: _vm.refreshDetail },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          { style: _vm.handleFlexStyle },
          [
            _c(
              "div",
              { staticClass: "new-cus-tomer clearfix detail-company" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.detail.jingpin,
                        expression: "detail.jingpin",
                      },
                    ],
                    staticClass: "jing-pin",
                  },
                  [_c("span", [_vm._v("禁止")]), _c("span", [_vm._v("呼叫")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "company-name" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "dt-permission",
                            rawName: "v-dt-permission",
                            value: "智能获客-推荐线索-加入培育",
                            expression: "'智能获客-推荐线索-加入培育'",
                          },
                        ],
                        staticClass: "company-list",
                      },
                      [
                        _vm.detail.sub == "true"
                          ? _c("svg-icon", {
                              staticClass: "star",
                              attrs: { "icon-class": "collect2" },
                              on: {
                                click: function ($event) {
                                  return _vm.del_collect(
                                    _vm.detail.company_uuid
                                  )
                                },
                              },
                            })
                          : _c("svg-icon", {
                              staticClass: "star",
                              attrs: { "icon-class": "collect1" },
                              on: {
                                click: function ($event) {
                                  return _vm.add_collect(
                                    _vm.detail.company_uuid,
                                    true
                                  )
                                },
                              },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "pack-name-tip",
                        on: {
                          click: function ($event) {
                            return _vm.copyComName(_vm.detail.company_name)
                          },
                          mouseover: function ($event) {
                            return _vm.copyHoverIn()
                          },
                          mouseout: function ($event) {
                            return _vm.copyHoverOut()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.detail.company_name))]
                    ),
                    _vm._v(" "),
                    _vm.currentHover
                      ? _c("span", { staticClass: "hover-tip-icon" }, [
                          _vm._v(_vm._s(_vm.hoverText)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.detail.isReceive
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.lingqu },
                          },
                          [_vm._v("领取")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detail.product_name
                      ? _c("p", { staticClass: "company_brief" }, [
                          _vm._v(
                            "\n            品牌简称:" +
                              _vm._s(_vm.detail.product_name) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detail.vc_step
                      ? _c("p", { staticClass: "vc" }, [
                          _vm._v(_vm._s(_vm.detail.vc_step)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detail.company_status
                      ? _c("p", { style: _vm.isStatus() }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.detail.company_status) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.textTool, function (ele, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("PeTool", [
                            _c(
                              "div",
                              {
                                attrs: { slot: "txt-content" },
                                slot: "txt-content",
                              },
                              [_vm._v(_vm._s(ele.content))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { attrs: { slot: "txt-btn" }, slot: "txt-btn" },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: ele.show,
                                      expression: "ele.show",
                                    },
                                  ],
                                  class: ele.class,
                                  attrs: { src: ele.src },
                                  on: { click: ele.click },
                                }),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.customerInfoMap.inPool,
                            expression: "customerInfoMap.inPool",
                          },
                        ],
                        staticClass: "company_desc",
                      },
                      [_vm._v(_vm._s(_vm.customerInfoMap.desc))]
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("p", { staticClass: "brief-desc" }, [
                  _vm._v(_vm._s(_vm.detail.brand_slogan)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "dt-permission",
                        rawName: "v-dt-permission",
                        value: "系统管理-非菜单权限-企业详情页-显示卡片",
                        expression: "'系统管理-非菜单权限-企业详情页-显示卡片'",
                      },
                    ],
                    staticClass: "desc-list",
                  },
                  [
                    _vm._l(_vm.descList, function (item, index) {
                      return [
                        item.show
                          ? _c("div", { key: index }, [
                              _c(
                                "div",
                                { staticClass: "left" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "icon",
                                    attrs: { "icon-class": item.icon },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("div", { staticClass: "cloud-add" }, [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _vm._v(" "),
                                    item.remain && _vm.isShowCloud
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "remain",
                                            on: { click: _vm.handleHistory },
                                          },
                                          [_vm._v(_vm._s(item.remain))]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("PePopover", [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.name) +
                                            ": " +
                                            _vm._s(_vm.detail[item.key])
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "detail-desc",
                                        attrs: { slot: "txt" },
                                        slot: "txt",
                                      },
                                      [
                                        item.transfer
                                          ? _c("span", [
                                              _vm._v(_vm._s(item.value)),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.detail[item.key])
                                              ),
                                            ]),
                                        _vm._v(" "),
                                        _vm.showLogic(item)
                                          ? _c(
                                              "span",
                                              { staticClass: "index-desc" },
                                              [
                                                _vm._v(
                                                  "截止到:" +
                                                    _vm._s(item.dateValue)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "new-qy-tag" },
                  [
                    _vm._l(_vm.getTags, function (it, index) {
                      return [
                        _c("div", { key: index, staticClass: "flex-tag" }, [
                          _c("p", { staticClass: "tag-classify" }, [
                            _vm._v(_vm._s(index)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class:
                                index == "榜单标签" ? "bandTag" : "new-tag",
                            },
                            [
                              [
                                _c(
                                  "div",
                                  { staticClass: "other-wrapper" },
                                  [
                                    _vm._l(it, function (el, idx) {
                                      return [
                                        _c(
                                          "el-tag",
                                          {
                                            key: idx,
                                            staticClass: "other-tag",
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleBandDetail(
                                                  el,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(el.tagName))]
                                        ),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                            ],
                            2
                          ),
                        ]),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                !_vm.isPrivate
                  ? _c("div", { staticClass: "flex-hx" }, [
                      _vm.handleHxQyTz
                        ? _c("div", { staticClass: "flex-hx-item" }, [
                            _c("p", [_vm._v("企业特征")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "h-it" }, [
                              _vm._v(_vm._s(_vm.handleHxQyTz)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.handleTax
                        ? _c("div", { staticClass: "flex-hx-item" }, [
                            _c("p", [_vm._v("属地落税")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "h-it" }, [
                              _vm._v(_vm._s(_vm.handleTax)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.handleBlock
                        ? _c("div", { staticClass: "flex-hx-item" }, [
                            _c("p", [_vm._v("区域版块")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "h-it" }, [
                              _vm._v(_vm._s(_vm.handleBlock)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.handleScore
                        ? _c("div", { staticClass: "flex-hx-item" }, [
                            _c("p", [_vm._v("质量评分")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "h-it" }, [
                              _vm._v(_vm._s(_vm.handleScore)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.handleLevel
                        ? _c("div", { staticClass: "flex-hx-item" }, [
                            _c("p", [_vm._v("质量等级")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "h-it" }, [
                              _vm._v(_vm._s(_vm.handleLevel)),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingDetail,
                        expression: "loadingDetail",
                      },
                    ],
                  },
                  [
                    _vm.detail.aiScore
                      ? _c("div", { staticClass: "company-item" }, [
                          _c("span", { staticClass: "h-title" }, [
                            _vm._v("AI评级"),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.detail.aiScore))]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detail.aiScoreDate
                      ? _c("div", { staticClass: "company-item" }, [
                          _c("span", { staticClass: "h-title" }, [
                            _vm._v(" AI评级时间"),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.detail.aiScoreDate))]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detail.aiReport
                      ? _c("div", { staticClass: "company-item" }, [
                          _c("span", { staticClass: "h-title" }, [
                            _vm._v(" AI评级报告"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "website",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDownload(_vm.detail.aiReport)
                                },
                              },
                            },
                            [_vm._v("下载")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detail.website
                      ? _c("div", { staticClass: "company-item" }, [
                          _c("span", { staticClass: "h-title" }, [
                            _vm._v("公司网址"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "website",
                              on: {
                                click: function ($event) {
                                  return _vm.toShop(_vm.detail.website)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.detail.website) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detail.introduce
                      ? _c("div", { staticClass: "company-item" }, [
                          _c("span", { staticClass: "h-title" }, [
                            _vm._v("简介"),
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.detail.introduce),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detail.comment
                      ? _c("div", { staticClass: "company-item" }, [
                          _c("span", { staticClass: "h-title" }, [
                            _vm._v("备注"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm.detail.comment
                              ? _c(
                                  "div",
                                  { staticClass: "key-remark" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          "visible-arrow": false,
                                          "popper-class": "popper",
                                          effect: "dark",
                                          content: _vm.detail.comment,
                                          placement: "bottom-start",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "detail-comment" },
                                          [_vm._v(_vm._s(_vm.detail.comment))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "dt-permission",
                                          rawName: "v-dt-permission",
                                          value:
                                            "客户培育-线索培育-培育线索-编辑",
                                          expression:
                                            "'客户培育-线索培育-培育线索-编辑'",
                                        },
                                      ],
                                      staticClass:
                                        "el-icon-edit collect_remark_detail_new",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editRemark(
                                            _vm.detail.comment
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "dt-permission",
                                        rawName: "v-dt-permission",
                                        value:
                                          "客户培育-线索培育-培育线索-编辑",
                                        expression:
                                          "'客户培育-线索培育-培育线索-编辑'",
                                      },
                                    ],
                                    staticClass: "remark-button",
                                    staticStyle: {
                                      color: "#0099cc",
                                      cursor: "pointer",
                                      width: "50px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addRemark()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                添加\n              "
                                    ),
                                  ]
                                ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isShowRisk
                      ? _c("div", { staticClass: "key-detail-risk" }, [
                          _c("p", [_vm._v("风险")]),
                          _vm._v(" "),
                          _c("ul", [
                            _vm.detail.risk.unpromise
                              ? _c("li", [_vm._v("失信被执行人")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.detail.risk.reorganization
                              ? _c("li", [_vm._v("破产重组")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.detail.risk.limit_consumption
                              ? _c("li", [_vm._v("限制高消费")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.detail.risk.debetor
                              ? _c("li", [_vm._v("被执行人")])
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            [
              _c("Tab", {
                attrs: { tabData: _vm.newTabData },
                on: { tabChange: _vm.tabChange },
              }),
            ],
            _vm._v(" "),
            _vm.selectVersion == "企业信息"
              ? [
                  _c("PeBase", { attrs: { detail: _vm.detail } }),
                  _vm._v(" "),
                  _vm.showModule
                    ? _c("module-para", {
                        ref: "Mparams",
                        attrs: {
                          moduleParams: _vm.moduleParams,
                          detail: _vm.detail,
                        },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.selectVersion == "云行业"
              ? [
                  _c("PeQy", { attrs: { companyDetail: _vm.detail } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "map-title" }, [
                    _vm._v("算力节点监测"),
                  ]),
                  _vm._v(" "),
                  _c("PeMap", {
                    attrs: { companyInfoUuid: _vm.detail.company_uuid },
                  }),
                  _vm._v(" "),
                  _vm.isShowNetList
                    ? [
                        _c(
                          "div",
                          { staticClass: "net-wrap" },
                          [
                            _c("span", { staticClass: "net-title" }, [
                              _vm._v("安全检测"),
                            ]),
                            _vm._v(" "),
                            _c("PeTable", {
                              staticClass: "net-table",
                              attrs: {
                                peTableData: _vm.netList,
                                peColumnList: _vm.netColumn,
                                pePage: _vm.netPage,
                                peLoading: _vm.netLoading,
                                peFooter: false,
                              },
                              on: {
                                handleCurrentPePage: _vm.handleCurrentPePage,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "domain",
                                    fn: function ({ scope }) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "company-name-click",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleOpen(
                                                  scope.data
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.data.domain))]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "access",
                                    fn: function ({ scope }) {
                                      return [
                                        scope.data.access == 1
                                          ? _c("div", [
                                              _c("i", {
                                                staticClass: "el-icon-success",
                                                staticStyle: {
                                                  color: "$main-color",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("是")]),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.data.access == 0
                                          ? _c("div", [
                                              _c("i", {
                                                staticClass: "el-icon-error",
                                                staticStyle: { color: "#f00" },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("否")]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                  {
                                    key: "dataCheck",
                                    fn: function ({ scope }) {
                                      return [
                                        scope.data.dataCheck == 1
                                          ? _c("div", [
                                              _c("i", {
                                                staticClass: "el-icon-success",
                                                staticStyle: {
                                                  color: "$main-color",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("已使用SSL证书"),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.data.dataCheck == 0
                                          ? _c("div", [
                                              _c("i", {
                                                staticClass: "el-icon-warning",
                                                staticStyle: { color: "#f00" },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("未使用SSL证书"),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                  {
                                    key: "contentCheck",
                                    fn: function ({ scope }) {
                                      return [
                                        scope.data.contentCheck == 1
                                          ? _c("div", [
                                              _c("i", {
                                                staticClass: "el-icon-success",
                                                staticStyle: {
                                                  color: "$main-color",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("正常")]),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.data.contentCheck == 0
                                          ? _c("div", [
                                              _c("i", {
                                                staticClass: "el-icon-warning",
                                                staticStyle: { color: "#f00" },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "存在违法内容,可能被劫持"
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1406301864
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showModule
                    ? _c("module-para", {
                        ref: "Mparams",
                        attrs: {
                          moduleParams: _vm.recommendParams,
                          detail: _vm.detail,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("extendApp", { attrs: { proDetail: _vm.detail } }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.selectVersion == "连锁行业"
              ? [
                  _c("PeQy", { attrs: { companyDetail: _vm.detail } }),
                  _vm._v(" "),
                  _c("PeStore", {
                    attrs: { companyUuid: _vm.detail.company_uuid },
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.selectVersion == "企业出海"
              ? [
                  _c("PeQy", { attrs: { companyDetail: _vm.detail } }),
                  _vm._v(" "),
                  _c("PeOverSea", {
                    attrs: { companyUuid: _vm.detail.company_uuid },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "dt-permission",
                rawName: "v-dt-permission",
                value: "系统管理-非菜单权限-企业详情页-右侧操作区",
                expression: "'系统管理-非菜单权限-企业详情页-右侧操作区'",
              },
            ],
            staticClass: "right-content",
            staticStyle: {
              width: "40%",
              "margin-left": "8px",
              "margin-top": "8px",
              "line-height": "32px",
            },
          },
          [
            _c("div", { staticClass: "subbar" }, [
              _c(
                "div",
                [
                  _c("el-switch", {
                    attrs: { "active-text": " 每次领取线索弹窗确认" },
                    on: { change: _vm.handlePyCheck },
                    model: {
                      value: _vm.capture2,
                      callback: function ($$v) {
                        _vm.capture2 = $$v
                      },
                      expression: "capture2",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "dt-permission",
                          rawName: "v-dt-permission",
                          value: "智能获客-推荐线索-加入或移除监控",
                          expression: "'智能获客-推荐线索-加入或移除监控'",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        size: "mini",
                        type: _vm.isMonitored ? "" : "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addMonitor(_vm.detail)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.isMonitored ? "移除监控" : "加入监控") +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.fromPath !== "/customerpond/customerpond" &&
                            !_vm.customerInfoMap.inPool,
                          expression:
                            "\n              fromPath !== '/customerpond/customerpond' &&\n                !customerInfoMap.inPool\n            ",
                        },
                        {
                          name: "dt-permission",
                          rawName: "v-dt-permission",
                          value: "智能获客-推荐线索-转成客户",
                          expression: "'智能获客-推荐线索-转成客户'",
                        },
                      ],
                      staticStyle: {
                        "min-width": "80px",
                        "margin-left": "10px",
                      },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.trans_client },
                    },
                    [_vm._v("转成客户\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.fromPath !== "/customerpond/customerpond" &&
                            _vm.customerInfoMap.inPool,
                          expression:
                            "\n              fromPath !== '/customerpond/customerpond' &&\n                customerInfoMap.inPool\n            ",
                        },
                      ],
                      staticStyle: {
                        "min-width": "80px",
                        "margin-left": "10px",
                      },
                      attrs: {
                        type: "primary",
                        size: "mini",
                        disabled: !_vm.customerInfoMap.showTransform,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getCustomerReceivers("3")
                        },
                      },
                    },
                    [_vm._v("发送至\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fromPath === "/customerpond/customerpond",
                          expression:
                            "fromPath === '/customerpond/customerpond'",
                        },
                      ],
                      staticStyle: {
                        "min-width": "80px",
                        "margin-left": "10px",
                      },
                      attrs: {
                        type: "primary",
                        size: "mini",
                        disabled: !_vm.customerInfoMap.showTransform,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getCustomerReceivers(_vm.activeTabs)
                        },
                      },
                    },
                    [_vm._v("发送至\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "min-width": "80px",
                        "margin-left": "10px",
                      },
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.editCusPond()
                        },
                      },
                    },
                    [_vm._v("编辑客户\n          ")]
                  ),
                  _vm._v(" "),
                  _vm.fromPath !== "/globalquery/globalquery" &&
                  _vm.fromPath !== "/globalquery/query" &&
                  _vm.fromPath !== "/globalquery/query2" &&
                  _vm.fromPath !== "/customerpond/customerpond"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.trans_btn_loading,
                              expression: "trans_btn_loading",
                            },
                            {
                              name: "dt-permission",
                              rawName: "v-dt-permission",
                              value: "智能获客-推荐线索-转派线索",
                              expression: "'智能获客-推荐线索-转派线索'",
                            },
                          ],
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.trans_company()
                            },
                          },
                        },
                        [_vm._v("线索转派\n          ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subcon" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "系统管理-非菜单权限-企业详情页-显示触达",
                      expression: "'系统管理-非菜单权限-企业详情页-显示触达'",
                    },
                  ],
                },
                [
                  _vm.showTouch
                    ? _c("touchModule", {
                        key: _vm.refreshTouch,
                        attrs: {
                          moduleParams: _vm.moduleParams,
                          detail: _vm.detail,
                          packageId: _vm.detail.packageId,
                        },
                      })
                    : [
                        _c("p", [_vm._v("触达模块")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "vip-click" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.handleVip },
                              },
                              [_vm._v("开通会员 解锁更多功能")]
                            ),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "dt-permission",
                    rawName: "v-dt-permission",
                    value: "系统管理-非菜单权限-企业详情页-显示跟进记录",
                    expression: "'系统管理-非菜单权限-企业详情页-显示跟进记录'",
                  },
                ],
                staticClass: "module1",
                staticStyle: {
                  width: "100%",
                  "min-height": "900px",
                  "margin-top": "8px",
                  background: "#fff",
                },
              },
              [
                _c("pond-detail", {
                  attrs: {
                    path: "keydetail",
                    customerInfoMap: _vm.customerInfoMap,
                    svg: "",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "open_table evaluate-table" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogVisible,
                width: "600px",
                top: "25vh",
                "close-on-click-modal": false,
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "title",
                  staticStyle: { "background-color": "#41cabf", color: "#fff" },
                },
                [_vm._v("\n        备注\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%", "margin-top": "20px" },
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      maxlength: "250",
                      placeholder: "请输入内容(最多填写250个字符)",
                    },
                    model: {
                      value: _vm.textarea,
                      callback: function ($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c("el-button", { on: { click: _vm.submitAppraise } }, [
                    _vm._v("提交"),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showGrasp
        ? _c(
            "dia-model",
            { ref: "graspTip", attrs: { dialogStatus: _vm.graspData } },
            [
              _c("div", { attrs: { slot: "modalCont" }, slot: "modalCont" }, [
                _c("div", { staticClass: "show_grasp_text" }, [
                  _c("span", [
                    _vm._v(
                      "本月赠送条数剩余：" +
                        _vm._s(_vm.accEarnNum.mounth) +
                        " 条;"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "不限时条数: " + _vm._s(_vm.accEarnNum.permanent) + " 条;"
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "submit" }, slot: "submit" }, [
                _c("div", { staticClass: "grasp_go_pay" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.graspGoPay("a")
                        },
                      },
                    },
                    [_vm._v("充值")]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showGraspC
        ? _c(
            "dia-model",
            { ref: "graspTipC", attrs: { dialogStatus: _vm.graspData } },
            [
              _c("div", { attrs: { slot: "modalCont" }, slot: "modalCont" }, [
                _c("div", { staticClass: "show_grasp_text" }, [
                  _c("span", [
                    _vm._v(
                      "本月赠送条数剩余：" +
                        _vm._s(_vm.accEarnNum.mounth) +
                        " 条;"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "不限时条数: " + _vm._s(_vm.accEarnNum.permanent) + " 条;"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "red",
                        display: "inline-block",
                        "margin-top": "20px",
                      },
                    },
                    [_vm._v("请联系主管理员充值")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "submit" }, slot: "submit" }, [
                _c("div", { staticClass: "grasp_go_pay" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.graspGoPay("c")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "transToCus",
          attrs: { dialogStatus: _vm.transToCusStatus },
          on: { submitContBtn: _vm.submitTransBtn },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showTransResult,
                  expression: "showTransResult",
                },
              ],
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _vm.transToCusStatus["template"]
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        margin: "20px 0 40px",
                        "padding-left": "30px",
                      },
                    },
                    [
                      _c("span", [_vm._v("转至：")]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            "default-first-option": "",
                            filterable: "",
                            placeholder: "选择客户接收人",
                          },
                          model: {
                            value: _vm.currentTransCusUserId,
                            callback: function ($$v) {
                              _vm.currentTransCusUserId = $$v
                            },
                            expression: "currentTransCusUserId",
                          },
                        },
                        _vm._l(_vm.transCus, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "trans-to-cus" },
                    [
                      _c("p", [_vm._v("请选择转客户模式：")]),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.chooseTransType,
                            callback: function ($$v) {
                              _vm.chooseTransType = $$v
                            },
                            expression: "chooseTransType",
                          },
                        },
                        [
                          _vm._v(
                            "模式一：转到对应跟进人名下，无跟进人转至公海\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.chooseTransType,
                            callback: function ($$v) {
                              _vm.chooseTransType = $$v
                            },
                            expression: "chooseTransType",
                          },
                        },
                        [_vm._v("模式二：全部转到自己名下\n        ")]
                      ),
                    ],
                    1
                  ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.sureTip
        ? _c(
            "dia-model",
            {
              ref: "sureSubTip",
              attrs: {
                dialogStatus: _vm.sureSubData,
                "on-close": _vm.subimtCancle,
              },
              on: {
                subimtCancle: _vm.subimtCancle,
                submitContBtn: _vm.submitContBtn,
              },
            },
            [
              _c("div", { attrs: { slot: "modalCont" }, slot: "modalCont" }, [
                _c("div", { staticClass: "show_grasp_text" }, [
                  _c(
                    "span",
                    { staticClass: "pySetting", on: { click: _vm.handlePy } },
                    [_vm._v("设置默认培育池")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { margin: "30px 0" } }, [
                    _c(
                      "div",
                      [
                        _c("el-checkbox", {
                          model: {
                            value: _vm.addCollectionOpenVal,
                            callback: function ($$v) {
                              _vm.addCollectionOpenVal = $$v
                            },
                            expression: "addCollectionOpenVal",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "font-size": "12px" } }, [
                          _vm._v(
                            "\n              领取，并加入默认培育池【培育池名称】（提供导出功能）"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "show_com_cont" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                top: _vm.comContType,
                visible: _vm.showCompanyContant,
                width: "464px",
                modal: false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showCompanyContant = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "show_phone_list" },
                _vm._l(_vm.companyAllPhone, function (item, index) {
                  return _c(
                    "span",
                    { key: index + 1, staticClass: "show_phone_name" },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "show_remark_cont" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                top: _vm.comContType,
                visible: _vm.showUserRemark,
                width: "220px",
                height: "120px",
                modal: false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showUserRemark = $event
                },
              },
            },
            [
              _c("div", [
                _c("div", { staticClass: "show_remark_list" }, [
                  _c("span", { staticClass: "show_remark_name" }, [
                    _vm._v(_vm._s(_vm.detail.comment)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "edit_remark_user",
                      on: { click: _vm.reverseRemark },
                    },
                    [_vm._v("修改备注")]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("select-book", {
        ref: "selectBook",
        attrs: { BookArr: _vm.bookArr },
        on: {
          changeBookList: _vm.changeBookList,
          sureSubmitCollect: _vm.sureSubmitCollect,
        },
      }),
      _vm._v(" "),
      _vm.showModel
        ? _c(
            "dia-model",
            {
              ref: "trans_send",
              attrs: { dialogStatus: _vm.sendStatus },
              on: { submitContBtn: _vm.transInfoToUserBtn },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "padding-left": "30px",
                    margin: "20px 0 40px",
                  },
                  attrs: { slot: "modalCont" },
                  slot: "modalCont",
                },
                [
                  _c("span", [_vm._v("转派给：")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "default-first-option": "",
                        filterable: "",
                        placeholder: "选择线索接收人",
                      },
                      model: {
                        value: _vm.currentTransUserId,
                        callback: function ($$v) {
                          _vm.currentTransUserId = $$v
                        },
                        expression: "currentTransUserId",
                      },
                    },
                    _vm._l(_vm.transUsers, function (item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: { label: item.name, value: item.userId },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  !(_vm.transUsers && _vm.transUsers.length)
                    ? _c("p", { staticClass: "tip-trans-tip" }, [
                        _c("span", [_vm._v("线索接收人未设置，请前往")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#78d9d1",
                              "text-decoration": "underline",
                              cursor: "pointer",
                            },
                            on: { click: _vm.goSubPage },
                          },
                          [_vm._v("子账户管理中心")]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("设置")]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "trans_send1",
          attrs: { dialogStatus: _vm.sendStatus1 },
          on: { submitContBtn: _vm.transInfoToUserBtn1 },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "padding-left": "30px", margin: "20px 0 40px" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: "", "label-width": "100px", size: "small" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { label: "发送给" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "default-first-option": "",
                            filterable: "",
                            placeholder: "选择线索接收人",
                          },
                          model: {
                            value: _vm.currentTransUserId1,
                            callback: function ($$v) {
                              _vm.currentTransUserId1 = $$v
                            },
                            expression: "currentTransUserId1",
                          },
                        },
                        _vm._l(_vm.allSendUser, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.currentTransUserId1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "退回理由", required: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "default-first-option": "",
                                filterable: "",
                                placeholder: "选择线索接收人",
                              },
                              model: {
                                value: _vm.currentTransUserId1,
                                callback: function ($$v) {
                                  _vm.currentTransUserId1 = $$v
                                },
                                expression: "currentTransUserId1",
                              },
                            },
                            _vm._l(_vm.allSendUser, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.userProState
        ? _c("user-profile", {
            ref: "userPro",
            attrs: { customerInfo: _vm.customerInfo, title: _vm.selectTitle },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "pe-dialog",
        {
          attrs: {
            PeDialogTitle: "行业图谱",
            PeDialogShow: _vm.schemaVisible,
            PeDialogShowSure: false,
            PeDialogWidth: "480px",
          },
          on: {
            handlePeDialogClose: function ($event) {
              _vm.schemaVisible = false
            },
          },
        },
        [
          _c("div", { staticClass: "key-detail-company" }, [
            _vm._v(_vm._s(_vm.detail.company_name)),
          ]),
          _vm._v(" "),
          _vm.detail.tagItem
            ? _c(
                "el-button-group",
                { staticClass: "el-btn-group" },
                [
                  _vm._l(_vm.detail.tagItem, function (item) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-s-data" },
                          on: {
                            click: function ($event) {
                              return _vm.handleSchema(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.tagName))]
                      ),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "pe-draw",
        {
          attrs: { peDrawerShow: _vm.showDraw },
          on: {
            handleDrawClose: function ($event) {
              _vm.showDraw = false
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.refreshIdcLoading,
                  expression: "refreshIdcLoading",
                },
              ],
            },
            [
              _c(
                "div",
                { staticStyle: { margin: "10px 0 0 10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "18px",
                      },
                    },
                    [_vm._v("历史云服务商")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-right",
                        type: "plain",
                        size: "small",
                      },
                      on: { click: _vm.handleRefreshIdc },
                    },
                    [_vm._v("刷新")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("yunServiceHistoryList", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingYunService,
                    expression: "loadingYunService",
                  },
                ],
                attrs: { "service-data": _vm.yunServiceList, showLimit: true },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }