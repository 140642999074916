var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cloud-choose-page" },
    [
      _c("div", { staticClass: "ai-panel" }, [
        _c("div", { staticClass: "distance" }, [
          _c("div", { staticClass: "tab" }, [
            _c("span", { staticClass: "title" }, [_vm._v("选址位置")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "all-address" },
              [
                _c("span", { staticClass: "addr" }, [
                  _vm._v(" " + _vm._s(_vm.searchValue)),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: { click: _vm.handleSelectFocus },
                  },
                  [_vm._v("选择")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.showHistory
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "history" },
                      _vm._l(_vm.historyList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class:
                              _vm.currentHistory == index
                                ? "active item"
                                : "item",
                            on: {
                              click: function ($event) {
                                return _vm.handleHistory(item, index)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(`${index + 1}.${item.address}`) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("el-pagination", {
                      staticClass: "el-page",
                      attrs: {
                        background: "",
                        layout: "prev, pager, next",
                        total: _vm.pePage.total,
                        "page-size": _vm.pePage.pageSize,
                      },
                      on: { "current-change": _vm.handlePageNoChange },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tab" }, [
            _c("span", { staticClass: "title" }, [_vm._v("距离")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "list" },
              _vm._l(_vm.distanceData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    class: _vm.currentIndex == index ? "active item" : "item",
                    on: {
                      click: function ($event) {
                        return _vm.handleDistance(item, index)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.name) + "\n          "
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tab" },
            [
              _c("span", { staticClass: "title" }, [_vm._v("购买力")]),
              _vm._v(" "),
              _c("PeSelect", {
                class: _vm.selectStyle,
                attrs: {
                  popperClass: _vm.getPopperClass,
                  optionsList: _vm.schemaList,
                },
                on: { handleSelectChange: _vm.handleSchema },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.nearLoading,
                expression: "nearLoading",
              },
            ],
            staticClass: "nearby",
            attrs: { "element-loading-text": "AI计算中..." },
          },
          _vm._l(_vm.nearByList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                class:
                  _vm.nearIndex == index ? "active near-item" : "near-item",
                on: {
                  click: function ($event) {
                    return _vm.handleDetail(item, index)
                  },
                },
              },
              [
                _c("span", [_vm._v(" " + _vm._s(item.name))]),
                _vm._v(" "),
                _c("span", [_vm._v(" " + _vm._s(item.count))]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "point-img",
                  attrs: { src: item.icon },
                }),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("Map", { ref: "map" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.nearLoading,
              expression: "nearLoading",
            },
          ],
          staticClass: "count-panel",
          attrs: { "element-loading-text": "AI计算中..." },
        },
        [
          _c("div", { staticClass: "radius-desc" }, [
            _vm._v(
              "\n      半径" +
                _vm._s(_vm.selectRadius < 1000 ? 1000 : _vm.selectRadius) +
                "m范围内人口\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "people" }, [
            _c("p", [_vm._v("工作人口数:")]),
            _vm._v(" "),
            _c("span", [_vm._v(" " + _vm._s(_vm.massData.workPeopleCnt))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "people" }, [
            _c("p", [_vm._v("居住人口数:")]),
            _vm._v(" "),
            _c("span", [_vm._v(" " + _vm._s(_vm.massData.livePeopleCnt))]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detail-panel" },
        [
          _c(
            "el-tabs",
            {
              ref: "tabCard",
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "测评", name: "address" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "周边", name: "store" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "重点", name: "important" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "出租", name: "shop" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list-wrap" },
            [
              _vm.tabName == "address"
                ? [
                    _c("div", { staticClass: "chart-wrap" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.nearLoading,
                              expression: "nearLoading",
                            },
                          ],
                          attrs: { "element-loading-text": "AI计算中..." },
                        },
                        [
                          _c("div", { staticClass: "radius-desc" }, [
                            _vm._v(
                              "\n              半径" +
                                _vm._s(
                                  _vm.selectRadius < 1000
                                    ? 1000
                                    : _vm.selectRadius
                                ) +
                                "m范围内人口\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "people" }, [
                            _c("p", [_vm._v("工作人口数:")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.massData.workPeopleCnt)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "people" }, [
                            _c("p", [_vm._v("居住人口数:")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.massData.livePeopleCnt)),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "chart-dom" },
                        _vm._l(_vm.sexChartConfig, function (item) {
                          return _c("div", {
                            key: item.id,
                            ref: "aiDom",
                            refInFor: true,
                            staticClass: "ai-chart",
                          })
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.codeImg
                        ? _c("div", { staticClass: "qr-wrap" }, [
                            _c("div", [_vm._v("扫码查看门店详情")]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "code-img",
                              attrs: { src: _vm.codeImg },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.tabName == "store"
                ? [
                    _c("div", { staticClass: "radius-desc" }, [
                      _vm._v(
                        "半径" + _vm._s(_vm.selectRadius) + "m范围内业态信息"
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.pointList.length
                      ? _vm._l(_vm.pointList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "item" },
                            [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(`${index + 1}.${item.name}`)),
                              ]),
                              _vm._v(" "),
                              item.photo
                                ? _c("div", { staticClass: "img-wrap" }, [
                                    _c("img", {
                                      staticClass: "brand-logo",
                                      attrs: { src: item.photo },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "img-block" }),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "item-bottom" }, [
                                _c("div", { staticClass: "term" }, [
                                  _vm._v(
                                    "距离中心点:" + _vm._s(item.distance) + "m"
                                  ),
                                ]),
                                _vm._v(" "),
                                item.brandCount > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "term",
                                        staticStyle: { color: "orange" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                品牌连锁" +
                                            _vm._s(item.brandCount) +
                                            "家\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "address" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        item.province +
                                          item.city +
                                          item.district +
                                          item.address
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        })
                      : _c("NoData"),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.tabName == "important"
                ? [
                    _c("div", { staticClass: "radius-desc" }, [
                      _vm._v(
                        "\n          半径" +
                          _vm._s(_vm.selectRadius) +
                          "m范围内重点关注门店\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("PeSelect", {
                      attrs: {
                        optionsList: _vm.brandList,
                        isMultiple: true,
                        defaultSelect: _vm.defaultValueList,
                        selectSize: "small",
                        isShopCustom: true,
                      },
                      on: {
                        handleSelectChange: _vm.handleBrandChange,
                        handleRemoveTag: _vm.handleRemoveTag,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.importLoading,
                            expression: "importLoading",
                          },
                        ],
                      },
                      [
                        _vm.importantList.length
                          ? _vm._l(_vm.importantList, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item" },
                                [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(_vm._s(`${index + 1}.${item.name}`)),
                                  ]),
                                  _vm._v(" "),
                                  item.photo
                                    ? _c("div", { staticClass: "img-wrap" }, [
                                        _c("img", {
                                          staticClass: "brand-logo",
                                          attrs: { src: item.photo },
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "img-block" }),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "item-bottom" }, [
                                    _c("div", { staticClass: "term" }, [
                                      _vm._v(
                                        "距离中心点:" +
                                          _vm._s(item.distance) +
                                          "m"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    item.brandCount > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "term",
                                            staticStyle: { color: "orange" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  品牌连锁" +
                                                _vm._s(item.brandCount) +
                                                "家\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "address" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            item.province +
                                              item.city +
                                              item.district +
                                              item.address
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            })
                          : _c("NoData"),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.tabName == "shop"
                ? [
                    _c("div", { staticClass: "price" }, [
                      _c("div", { staticClass: "radius-desc" }, [
                        _vm._v(
                          "\n            半径" +
                            _vm._s(_vm.selectRadius) +
                            "m范围内商铺出租信息\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.shopData.total > 0
                        ? _c("div", [
                            _vm._v(
                              "\n            共" +
                                _vm._s(_vm.shopData.total) +
                                "条,均价:" +
                                _vm._s(_vm.shopData.rentAvg) +
                                "元/天/平米\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.shopList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "shop-list" },
                          _vm._l(_vm.shopList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "shop-item" },
                              [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(`${index + 1}.${item.name}`)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "address" }, [
                                  _vm._v("地址:" + _vm._s(item.address)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "area" }, [
                                  _vm._v("面积:" + _vm._s(item.area)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "total" }, [
                                  _vm._v("总价:" + _vm._s(item.rent)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "unit" }, [
                                  _vm._v(
                                    "单价:" + _vm._s(item.rent_unit_price)
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _c("NoData"),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.tabName != "address"
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      small: true,
                      "current-page": _vm.pointPage.pageNo,
                      layout: "prev,pager,next",
                      total: _vm.pointPage.total,
                      "page-size": _vm.pointPage.pageSize,
                      "hide-on-single-page": true,
                    },
                    on: { "current-change": _vm.handleCurrentChange },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }