<template>
  <div>
    <el-popover placement="bottom-start" width="500">
      <div class="modul-table-list">
        <div>idc变更</div>
        <div
          :class="
            !otherParams.isReceive && otherParams.shade ? 'bg-filter' : ''
          "
        >
          <div class="pond-cond-all">
            <div v-for="(follow, ind) in tableArr">
              <div
                class="pond-cond-left"
                :class="ind !== 0 ? 'pond-no-first' : ''"
              >
                <p>{{ follow.timeAt }}</p>
              </div>
              <div
                class="pond-cond-right"
                :class="ind !== 0 ? 'pond-no-first' : ''"
              >
                <p>
                  <span style="color:#999999;" class="pond-cond-posi">{{
                    follow.gongyingshang
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="touch-no-receive"
          v-if="!otherParams.isReceive && otherParams.shade"
          v-loading="getLoading"
        >
          <div class="touch-no-cont">
            <div class="touch-no-icon">
              <span
                style="color: #008CD9;text-decoration: underline;cursor:pointer;"
                @click="getReveiveKey('phone')"
                >领取</span
              >
              <span> 以查看所有信息</span>
            </div>
          </div>
        </div>
        <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showDetail">
          <div slot="modalCont">
            <p v-html="recruitStr" style="padding: 20px 0;"></p>
          </div>
          <div slot="submit">
            <div class="grasp_go_pay">
              <span @click="graspGoPay">关闭</span>
            </div>
          </div>
        </dia-model>
      </div>
      <div
        class="yun-icon"
        style="color: #1296db;text-decoration: underline;cursor: pointer"
        slot="reference"
      >
        历史云服务商
      </div>
    </el-popover>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import Mixin from './mixin'

export default {
  name: 'ModuleIdc',
  mixins: [Mixin],
  data() {
    return {
      headerArr: [],
      tableArr: [],
      showTableLoading: false,
      graspData: {},
      showDetail: false,
      recruitStr: '',
      spanArr: []
    }
  },
  created() {},
  mounted() {
    this.$emit('ModuleDom')
  },
  components: {
    diaModel
  },
  methods: {
    // getSpanArr (data) {
    //   for (var i = 0; i < data.length; i++) {
    //     if (i === 0) {
    //       this.spanArr.push(1)
    //       this.pos = 0
    //     } else {
    //       // 判断当前元素与上一个元素是否相同
    //       if (data[i].name === data[i - 1].name) {
    //         this.spanArr[this.pos] += 1
    //         this.spanArr.push(0)
    //       } else {
    //         this.spanArr.push(1)
    //         this.pos = i
    //       }
    //     }
    //   }
    // },
    // objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
    //   if (this.headerArr[columnIndex] && this.headerArr[columnIndex].span) {
    //     let arr = []
    //     let that = this
    //     arr = this.tableArr.filter((ele, index, farr) => {
    //       return ele.timeAt === that.tableArr[rowIndex].timeAt
    //     })
    //     if (arr.length == 1) {
    //       return {
    //         rowspan: 1,
    //         colspan: 1
    //       }
    //     }
    //     if (arr.length != 1 && rowIndex == this.tableArr.findIndex((value) => value.timeAt == row.timeAt)) {
    //       return {
    //         rowspan: arr.length,
    //         colspan: 1
    //       }
    //     }
    //     if (arr.length != 1 && rowIndex != this.tableArr.findIndex((value) => value.timeAt == row.timeAt)) {
    //       return {
    //         rowspan: 0,
    //         colspan: 0
    //       }
    //     }
    //     // if (columnIndex === 0) {
    //     //   const _row = this.spanArr[rowIndex];
    //     //   // const _col = _row > 0 ? 1 : 0;
    //     //   return {
    //     //     rowspan: _row,
    //     //     colspan: _col
    //     //   }
    //     // }
    //   }
    //
    // },
    goPageUrl(val) {
      window.open(val)
    },
    openModal(val, name) {
      if (!val) {
        this.$message({
          type: 'warning',
          message: '暂无详情'
        })
        return
      }
      this.goRecruitDetail(val, name)
    },
    goRecruitDetail(val, name) {
      this.recruitStr = val
      this.recruitStr = this.recruitStr.replace(/；/g, '；<br/>')
      this.recruitStr = this.recruitStr.replace(/：/g, '：<br/>')
      this.recruitStr = this.recruitStr.replace(/。/g, '。<br/>')
      this.recruitStr =
        '<div' +
        ' style=' +
        'word-wrap:break-word' +
        '>' +
        this.recruitStr +
        '</div>'
      this.showDetail = true
      this.graspData = {
        dialogWidth: '800px',
        dialogTitle: name,
        dialogTop: '250px',
        showFoot: false,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.graspTip.init()
      })
    },
    graspGoPay() {
      this.showDetail = false
    },
    haveAttr(val, lag) {
      if (val) {
        const arr = val.split(',')
        if (arr.indexOf(lag) > -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>
<style>
.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}
</style>
<style scoped>
.pond-cond-all {
  overflow: hidden;
  margin: 10px;
}

.pond-cond-left {
  float: left;
  width: 70px;
}

.pond-cond-left p {
  font-size: 12px;
  vertical-align: top;
}

.pond-cond-right {
  float: left;
  width: calc(100% - 70px);
  border-left: 1px solid #e4e4e4;
  padding-left: 20px;
  padding-right: 10px;
  position: relative;
}

.pond-cond-all {
  overflow: hidden;
  margin: 10px;
}

.bg-filter {
  filter: blur(1.5px);
}

.table-list-href {
  position: relative;
  left: 12px;
  color: #0077cc;
  cursor: pointer;
}

.modul-table-list {
  position: relative;
  margin-top: 18px;
}

.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 100;
}

.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}

.touch-no-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yun-icon7::before {
  content: '';
  width: 15px;
  height: 15px;
  left: 3px;
  top: 16px;
  display: inline-block;
  position: absolute;
  background: url('../../assets/allowpic.png') no-repeat;
  background-size: 100% 100%;
}

.yun-icon {
  font-size: 12px;
  display: inline-block;
  padding-left: 25px;
}

.modul-table-list ::v-deep .el-popover__reference {
  width: 50px;
}

.modul-table-list ::v-deep .el-table--border:last-child {
  border: none !important;
}

.el-table--border .filtrate {
  position: absolute;
  top: 16px;
  left: 3px;
}

.pond-cond-had {
  position: relative;
}

.pond-cond-had::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  right: 10px;
  top: 10px;
  background: #e4e4e4;
  border-radius: 4px;
}
.pond-cond-posi {
  position: relative;
}
.pond-cond-posi::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  top: 2px;
  left: -24px;
  background: #e4e4e4;
  border-radius: 4px;
}

.pond-no-first {
  padding-top: 40px;
}

.modul-table-list ::v-deep .el-table--border::after,
.el-table--group::after {
  top: 0;
  right: 0;
  width: 0px;
  height: 100%;
}
</style>
