<template>
  <div>
    <dia-model
      ref="scribesDialog"
      :dialogStatus="dialogStatus"
      @submitContBtn="submitContBtn"
    >
      <div slot="modalCont">
        <el-scrollbar style="width: 100%;height: 450px;position: relative" class="dt-dropdown-dt">
          <div class="h-html" v-html="pageDetail" style="width: 500px; margin: 0 auto;position: relative"></div>
        </el-scrollbar>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from "@/components/Model";
import { getMarketlandPageDetail } from "@/api/table";
export default {
  props: {
    currentId: {
      default: "",
      type: [Number, String]
    },
    formHtml: {
      default: "",
      type: String
    },
    fontStyle: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      dialogStatus: {},
      pageDetail: ""
    };
  },
  components: {
    diaModel
  },
  created() {
    console.log(this.fontStyle)
  },
  methods: {
    getMarketlandPageDetail() {
      const params = {
        id: this.currentId
      };
      getMarketlandPageDetail(params).then(res => {
        if (res.code === 60000) {
          this.pageDetail = res.data.formHtml;
        }
      });
    },
    deserveCont(val) {
      return val.replace(/\n/g, "<br>");
    },
    init(val) {
      let title = val.formName;
      if(title && title.length > 20){
        title = `${title.slice(0, 20)}...`
      }
      this.dialogStatus = {
        dialogWidth: "700px",
        dialogTitle: `预览---${ title }`,
        dialogTop: "180px",
        showFoot: true,
        singleBtn: true,
        dialogSurBtn: "确认"
      };
      this.$nextTick(() => {
        this.$refs.scribesDialog.init();
        this.loadingCode = true;
        // if (this.currentId) {
        //   this.getMarketlandPageDetail();
        // } else {

        if (this.formHtml) {
          this.pageDetail = this.formHtml;
        } else {
          this.growHtml();
        }
        // }
      });
    },

    submitContBtn() {
      this.$refs.scribesDialog.subimtCancle();
    },

    growHtml(val) {
      const Style =
        "<style>" +
        ".page-cont-title {width: 100%;}" +
        ".page-cont-title p {text-align: center;font-size: 18px;font-weight: bold;margin: 10px;color:black;}" +
        ".page-img-upload {display: block;height: 100%;width: 100%;}" +
        ".page-submit-btn p {text-align: center;color: white;line-height: 30px;}" +
        ".input-txt-list span {display: inline-block;width: 60px;font-size: 14px;}" +
        ".input-txt-list input {outline: none;font-size: 12px;height: 25px;width: 200px;border: none;padding-left: 20px;}" +
        ".input-txt-list {height: 45px;line-height: 45px;padding-left: 20px;}" +
        ".page-show-cont {width: 100%;margin: 0 auto;border-radius: 5px;background: white;height: 100vh;position: relative;padding-bottom: 140px;box-sizing: border-box}" +
        ".page-cont-cont {margin: 0 auto;background: white;height: calc( 100vh - 140px ); overflow-y: auto } " +
        ".page-img-cont {width: 100%;min-height: 150px;background: white;}" +
        ".page-content-txt {width: 100%;padding: 30px 10px;background: #D8D8D8;box-sizing: border-box;}" +
        ".page-input-txt {width: 100%;padding: 30px 10px;background: #D8D8D8;box-sizing: border-box;}" +
        ".page-submit-btn {width: 100%;height: 30px;background: #33CB98;cursor: pointer;}" +
        ".page-name-card { background-color:#fff; z-index: 99; position: absolute; left: 0; bottom: 0;height: 120px;width: 100%; padding: 10px 20px;box-sizing: border-box; box-shadow:rgba(0,0,0,.08) 0 0 1px 1px } " +
        ".card-name-title { height: 20px;font-size: 12px;display: flex;align-items: center;justify-content: flex-start; }  " +
        ".card-img-input-photo{width: 100%;height: 80px;display: flex;align-items: center;justify-content: flex-start; }  " +
        ".card-photo { height: 100%;width: 80px;display: flex;align-items: center;justify-content: center;} " +
        ".card-photo-img {height: 80%;width: 80%;border-radius: 50%; }" +
        ".card-name-position-company{font-size: 12px;width: calc( 100% - 160px )} " +
        ".card-position{ margin-left: 10px; }  " +
        ".card-photo-code{ height: 80%;width: 80%; }  " +
        ".card-photo-co{ height: 100%;width: 80px;display: flex;flex-direction: column;align-items: center;justify-content: space-between; }" +
        ".zixun{font-size: 10px; text-align: center; color: rgba(0,0,0,.3);}" +
        "<\/style>";
      const HeadHtml =
        "<!DOCTYPE html>" +
        "<html>" +
        "<head>" +
        "<title>领鸟云，为中国7000万销售员量身打造的AI营销工具<\/title>" +
        "<meta charset='utf-8'>" +
        "<link rel='shortcut icon' href='https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/favicon.ico' \/>" +
        "<meta name='viewport' content='width=device-width, initial-scale=1, height=device-height'>" +
        Style +
        "<script src='https://apps.bdimg.com/libs/jquery/2.1.4/jquery.min.js'><\/script>" +
        "<\/head>";
      const video =
        this.fontStyle.show["video"] && this.fontStyle.videoUrl !== ""
          ? "<div class='page-img-cont'>" +
            "<video class='page-img-upload' src='" +
            this.fontStyle.videoUrl +
            "' alt='' controls='controls'>" +
            "<\/div>"
          : "";
      const photo =
        this.fontStyle.show["photo"] && this.fontStyle.uploadUrl !== ""
          ? "<div class='page-img-cont'>" +
            "<img class='page-img-upload' src='" +
            this.fontStyle.uploadUrl +
            "' alt=''>" +
            "<\/div>"
          : "";
      const contentText = this.fontStyle.show["content"]
        ? "<div class='page-content-txt' style='background:" +
          this.fontStyle.textBg +
          "'>" +
          "<span style='color:" +
          this.fontStyle.fontColor +
          ";" +
          "font-weight:" +
          this.fontStyle.fontWeight +
          ";" +
          "font-size:" +
          this.fontStyle.fontSize +
          "px;" +
          "font-family:" +
          this.fontStyle.fontFamily +
          ";" +
          "'>" +
          this.deserveCont(this.fontStyle.textareaCont) +
          "<\/span>" +
          "<\/div>"
        : "";
      const checks =
        this.fontStyle.show["input"] && this.fontStyle.checkedArr.length > 0
          ? "<div class='page-input-txt' style='background:" +
            this.fontStyle.pagraBg +
            "'>" +
            "<div class='input-txt-list' style='display:" +
            (this.fontStyle.checkedArr.indexOf("name") > -1
              ? "block;"
              : "none;") +
            "'>" +
            "<span>姓名</span>" +
            "<input id='input-name' type='text'>" +
            "<\/div>" +
            "<div class='input-txt-list' style='display:" +
            (this.fontStyle.checkedArr.indexOf("phone") > -1
              ? "block;"
              : "none;") +
            "'>" +
            "<span>手机号</span>" +
            "<input id='input-phone' type='text'>" +
            "<\/div>" +
            "<div class='input-txt-list' style='display:" +
            (this.fontStyle.checkedArr.indexOf("company") > -1
              ? "block;"
              : "none;") +
            "'>" +
            "<span>公司</span>" +
            "<input id='input-company' type='text'>" +
            "<\/div>" +
            "<\/div>"
          : "";
      const button =
        this.fontStyle.show["button"] && this.fontStyle.checkedArr.length > 0
          ? "<div class='page-submit-btn'>" +
            "<p>" +
            this.fontStyle.buttonText +
            "<\/p>" +
            "<\/div>" +
            "<\/div>" +
            "<\/div>"
          : "";

      const nameCard = this.fontStyle.show['nameCard'] ?
        `<div class='page-name-card'>
        <div class='card-img-input-photo'>
            <div class='card-photo'>
                <img class='card-photo-img' src=${this.fontStyle.nameCard.headerImg}>
            </div>
            <div class='card-name-position-company'>
                <p>
                    <span>${this.fontStyle.nameCard.username}</span><span class='card-position'>${this.fontStyle.nameCard.position}</span>
                </p>
                <p>
                    ${this.fontStyle.nameCard.companyName}
                </p>
            </div>
            <div class='card-photo-co'>
                <img class='card-photo-code' src=${this.fontStyle.nameCard.erCodeImg}>
                <div class='zixun'>扫码咨询</div>
            </div>
        </div>
    </div>` : '';

      const BodyHtml =
        "<body>" +
        "<div class='page-show-cont'>" +
        "<div class='page-cont-cont'>" +
        video +
        photo +
        contentText +
        checks +
        button +
        nameCard +
        "<\/body>";
      const Script = "";

      const FooterHmtl = "<\/html>";
      this.pageDetail = HeadHtml + BodyHtml + Script + FooterHmtl;
    }
  }
};
</script>

<style scoped>
@import "../../styles/threadpx.scss";
</style>
<style scoped>
.qr-code-cont {
  margin: 29px 0;
  height: 127px;
}
.qr-code-txt {
  margin-top: 11px;
  font-size: 14px;
  color: #8f8f8f;
  line-height: 20px;
  position: relative;
}
.qr-code-ac {
  color: #0099cc;
  cursor: pointer;
}
.hover-tip-icon {
  position: absolute;
  display: inline-block;
  width: 60px;
  text-align: center;
  height: 30px;
  border: 1px solid #979797;
  border-radius: 5px;
  left: 0px;
  top: -40px;
  color: #979797;
  line-height: 28px;
  font-size: 12px;
  background: white;
}
#qrCode {
  display: inline-block;
  margin: 0 20px 0 0;
}
.h-html /deep/ .page-cont-cont{
  overflow-y: inherit!important;
}
</style>
