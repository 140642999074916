<template>
  <div class="earnLink-main">
    <div class="qr-code-cont">
      <div id="qrCode" ref="qrCode"></div>
      <div style="margin-top: 10px">
        <span @click="handleCopy" class="qr-code-txt qr-code-ac" style="font-size:12px;">复制链接</span>
        <span @click="handleDown" class="qr-code-txt qr-code-ac" style="font-size:12px;">下载二维码</span>
      </div>
    </div>

    <div class="footer">
      <el-button size="small" type="primary" @click="$store.dispatch('hideModal')">确定</el-button>
    </div>
  </div>
</template>

<script>
import QrCode from 'qrcodejs2'

export default {
  name: 'earnLink',
  data () {
    return {
      landId: '',
      uuid: '',
      link: ''
    }
  },
  mounted () {
    this.landId = this.$store.state.plugin.modal.options.id
    this.uuid = this.$store.state.plugin.modal.options.uuid
    this.init()
  },
  computed: {
    user(){
      return this.$store.state.user.accountInfo;
    }
  },
  methods: {
    init(){
      const landPageObj = this.user?.lanSite
      const userId = this.user.id
      const siteId = landPageObj?.id
      const companyUuid = this.uuid
      const url = landPageObj.pageUrl
      this.link = `${ url }?landPageId=${this.landId}&companyUuid=${companyUuid}&userId=${ userId }&siteId=${ siteId }`
      this.$refs.qrCode.innerHTML = ''
      this.$nextTick(() => {
        new QrCode(this.$refs.qrCode, {
          text: this.link,
          width: 200,
          height: 200,
          colorDark: '#333333',
          colorLight: '#ffffff',
          correctLevel: QrCode.CorrectLevel.H,
          render: 'canvas'
        })
      })
    },
    handleCopy () {
      event.stopPropagation()
      var oInput = document.createElement('input')
      oInput.value = this.link
      document.body.appendChild(oInput)
      this.selectText(oInput)
      document.execCommand('Copy')
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this.hoverText = '复制成功!'
      this.$store.dispatch('showTip', {
        text: '复制成功',
        type: 'success',
        duration: 800
      })
    },
    selectText (oInput) {
      if (oInput.createTextRange) {
        oInput.select()
      } else {
        oInput.setSelectionRange(0, oInput.value.length)
        oInput.focus()
      }
    },
    handleDown () {
      const drawing = this.$refs.qrCode.childNodes[0]
      if (drawing.getContext) {
        const context = drawing.getContext('2d')
        const imgURI = drawing.toDataURL('image/png')
        const image = document.createElement('img')
        image.src = imgURI
        const a = document.createElement('a')
        const event = new MouseEvent('click')
        a.download = 'code'
        a.href = imgURI
        a.dispatchEvent(event)
      }
    },
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.earnLink-main{
  /*height: 250px;*/
  width: 100%;
  padding: 20px 10px 0 10px;
  box-sizing: border-box;
}
.footer{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.footer:before{
  content: '';
  height: 1px;
  width: calc( 100% + 20px );
  background-color: rgba(0,0,0,.1);
  display: inline-block;
  transform: scaleY(.5);
  position: absolute;
  top: 0;
  left: -20px;
}
.qr-code-cont {
  margin: 29px 0;
  height: 200px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.qr-code-txt {
  margin-top: 11px;
  font-size: 14px;
  color: #8f8f8f;
  line-height: 20px;
  position: relative;
}
.qr-code-ac {
  color: #0099cc;
  cursor: pointer;
}
</style>
