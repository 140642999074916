var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tip-main" }, [
    _c("div", { staticClass: "tip-text" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$store.state.settings.errorMessage) + "\n  "
      ),
    ]),
    _vm._v(" "),
    _vm.env === "tt"
      ? _c("div", { staticClass: "er-code-tt" })
      : _c("div", { staticClass: "er-code" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dt-modal-footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "mini" },
            on: { click: _vm.closeModal },
          },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }