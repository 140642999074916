var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-scrollbar",
    {
      staticClass: "dt-dropdown-dt",
      staticStyle: { height: "100%", width: "100%" },
    },
    [
      _c("div", { staticClass: "main", style: { height: _vm.height } }, [
        _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main-row",
          attrs: { id: "myChart" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }