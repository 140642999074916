<template>
  <div class="app-market">
    <template v-if="isBlur">
      <div class="touch-no-receive" v-loading="getLoading">
        <div class="touch-no-cont">
          <div class="touch-no-icon">
            <span
              style="color: #008CD9;text-decoration: underline;cursor:pointer;"
              @click="getReceiveData('phone')"
              >领取</span
            >
            <span>以查看联系方式</span>
          </div>
        </div>
      </div>
    </template>
    <div class="app-wrapper" :style="blurObj">
      <div class="apply-list">
        <template v-for="(item, index) in tabList">
          <div
            :class="currentIndex === index ? 'active item' : 'item'"
            @click="handleTabClick(index)"
          >
            <span> {{ item.name }}</span>
            <span v-if="item.count">({{ item.count }})</span>
          </div>
        </template>
      </div>
      <el-table
        :data="tableData"
        v-loading="load"
        stripe
        style="width: 100%"
        @sort-change="sortChange"
        border
      >
        <template v-for="item in ColumnList">
          <el-table-column
            :width="item.field === 'appLogo' ? '240px' : 'auto'"
            :label="item.name"
            :sortable="item.sort"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <template v-if="item.field === 'appLogo'">
                <div class="apply-wrapper">
                  <img :src="scope.row.appLogo" class="logoImg" />
                  <div class="right">
                    <div class="layer">
                      <span
                        class="app-link"
                        @click="handleOpenDialog(scope.row)"
                        >{{ scope.row.appName }}</span
                      >
                    </div>
                    <div class="flex-classify">
                      <span class="app-classify">{{ scope.row.appType }}</span>
                      <el-button
                        class="icon-btn"
                        v-if="scope.row.storeUrl"
                        size="mini"
                        @click="handleOpenStore(scope.row.storeUrl)"
                      >
                        <IconHover
                          iconClass="app-share"
                          hoverMessage="APP发布网站"
                        >
                        </IconHover>
                      </el-button>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="item.field === 'appStore'">
                  <p>{{ formatCompany(scope.row['appStore'])[0] }}</p>
                  <p>{{ formatCompany(scope.row['appStore'])[1] }}</p>
                </div>
                <span v-else-if="item.field === 'totalDownloadNum'">
                  {{ formatCount(scope.row['totalDownloadNum']) }}
                </span>
                <span v-else-if="item.field === 'comments'">
                  {{ formatCount(scope.row['comments']) }}
                </span>
                <span v-else> {{ scope.row[item.field] }}</span>
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="page-footer">
        <FooterPage
          @handleCurrentChange="handleCurrentChange"
          @changePageSize="goNumPage"
          :pageSizes="[10, 20, 50]"
          :pageStatus="pageStatus"
        >
        </FooterPage>
      </div>

      <Dialog
        PeDialogTitle="APP详情"
        :PeDialogShow="PeDialogShow"
        PeDialogBtnCancel="关闭"
        :PeDialogShowSure="false"
        @handlePeDialogClose="handlePeDialogClose"
      >
        <div class="app-content">
          <img :src="appDetail.appLogo" class="appLogo" />
          <div class="detail">
            <div class="top">
              <div class="title">{{ appDetail.appName }}</div>
              <el-button
                class="icon-btn"
                v-if="appDetail.storeUrl"
                size="mini"
                @click="handleOpenStore(appDetail.storeUrl)"
              >
                <IconHover iconClass="app-share" hoverMessage="APP发布网站">
                </IconHover>
              </el-button>
            </div>
            <div class="introduce">{{ appDetail.appIntroduction }}</div>
          </div>
        </div>
        <div class="list">
          <div class="item">
            <div class="item-fl">APP ID</div>
            <div class="item-fr">{{ appDetail.bundleId }}</div>
          </div>
          <div class="item">
            <div class="item-fl">分类</div>
            <div class="item-fr">{{ appDetail.appType }}</div>
          </div>
          <div class="item">
            <div class="item-fl">应用市场</div>
            <div class="item-fr">{{ appDetail.appStore }}</div>
          </div>
          <div class="item">
            <div class="item-fl">价格</div>
            <div class="item-fr">{{ appDetail.price }}</div>
          </div>
          <div class="item">
            <div class="item-fl">开发者</div>
            <div class="item-fr">{{ appDetail.developer }}</div>
          </div>
          <div class="item">
            <div class="item-fl">开发者所属企业</div>
            <div class="item-fr">{{ appDetail.companyName }}</div>
          </div>
          <div class="item">
            <div class="item-fl">发布地区</div>
            <div class="item-fr">{{ appDetail.publishArea }}</div>
          </div>
          <div class="item">
            <div class="item-fl">综合评分</div>
            <div class="item-fr">{{ appDetail.appScore }}</div>
          </div>
          <div class="item">
            <div class="item-fl">评分数/评论数</div>
            <div class="item-fr">{{ appDetail.comments }}</div>
          </div>
          <div class="item">
            <div class="item-fl">下载量</div>
            <div class="item-fr">{{ appDetail.totalDownloadNum }}</div>
          </div>
          <div class="item">
            <div class="item-fl">发布/上架日期</div>
            <div class="item-fr">{{ appDetail.lastReleaseDate }}</div>
          </div>
          <div class="item">
            <div class="item-fl">最后版本更新</div>
            <div class="item-fr">{{ appDetail.versionUpdatedTime }}</div>
          </div>
          <div class="item">
            <div class="item-fl">大小</div>
            <div class="item-fr">{{ appDetail.appSize }}</div>
          </div>
          <div class="item">
            <div class="item-fl">包名</div>
            <div class="item-fr">
              <HoverMsg> {{ appDetail.appPackage }}</HoverMsg>
            </div>
          </div>
          <div class="item">
            <div class="item-fl">SDK</div>
            <div class="item-fr">
              <HoverMsg> {{ appDetail.sdk }}</HoverMsg>
            </div>
          </div>
          <div class="item">
            <div class="item-fl">SDK服务商</div>
            <div class="item-fr">
              <HoverMsg> {{ appDetail.sdkProvider }}</HoverMsg>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<script>
import HoverMsg from '@/components/Peculiar/popover'
import FooterPage from '@/components/Footerpage/index'
import Dialog from '@/components/Peculiar/dialog'
import { getAppDetail, getComDetailModel } from '@/api/thread'
import IconHover from '@/components/Peculiar/icon-hover'
import bindMobile from '@/views/usercenter/dialog/bindMobile'
import { formatRegex } from '@/utils/helper'
export default {
  name: 'MarketData',
  props: {
    moduleParam: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      getLoading: false,
      tableData: [],
      isReceive: true,
      PeDialogShow: false,
      appDetail: {},
      load: true,
      setTime: '',
      lastTime: '',
      tabList: [
        {
          id: 0,
          name: 'app store'
        },
        {
          id: 1,
          name: 'google play'
        },
        {
          id: 2,
          name: '其他应用市场'
        }
      ],
      mapList: ['apple store', 'google play', '其他应用市场'],
      currentIndex: 0,
      pageStatus: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        appStore: 'apple store',
        sortField: '',
        sortOrder: ''
      },
      ColumnList: []
    }
  },
  watch: {
    moduleParam: {
      handler(val) {
        console.log(val, 'moduleParam123')
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isBlur() {
      return !this.moduleParam.isReceive && this.moduleParam.module_shade
    },
    blurObj() {
      const blur = !this.moduleParam.isReceive && this.moduleParam.module_shade
      if (blur) {
        return `filter:blur(1.5px)`
      } else {
        return ''
      }
    }
  },
  components: {
    FooterPage,
    Dialog,
    IconHover,
    HoverMsg
  },
  mounted() {
    this.getList(false)
  },
  methods: {
    formatCount(num) {
      if (num) {
        return formatRegex(num)
      }
    },
    formatCompany(str) {
      if (str) {
        const list = str.split('-')
        return list
      }
      return []
    },
    handleTime(str) {
      // debugger
      let res = str.split('-').join('')
      console.log(res)
      return str.split('-').join('')
    },
    handleTabClick(index) {
      this.currentIndex = index
      this.pageStatus.appStore = this.mapList[index]
      this.pageStatus.pageNo = 1
      this.getList(true)
    },
    sortChange(e) {
      const columnName = e.column.label
      const order = e.order
      const listMap = [
        {
          label: '下载量',
          value: 'totalDownloadNum'
        },
        {
          label: '最新版本时间',
          value: 'versionUpdatedTime'
        },
        {
          label: '评论数',
          value: 'comments'
        }
      ]
      const sortOrder = order === 'ascending' ? 'asc' : 'desc'
      const findItem = listMap.find(item => item.label === columnName)
      this.pageStatus.sortField = findItem.value
      this.pageStatus.sortOrder = sortOrder
      this.getList(true)
    },
    getInitData(AppData) {
      this.tabList[0].count = AppData.list?.['apple store']?.length
      this.tabList[1].count = AppData.list?.['google play']?.length
      this.tabList[2].count = AppData.list?.['其他应用市场']?.length
      this.tableData = AppData?.list?.[this.mapList[this.currentIndex]]?.slice(
        0,
        10
      )
    },
    async getList(flag) {
      const params = {
        module: this.moduleParam.module_api,
        packageId: this.moduleParam.packageId,
        companyUuid: this.moduleParam.companyUuid,
        param: flag ? this.pageStatus : ''
      }
      // debugger
      const { data } = await getComDetailModel(params)
      const AppData = data?.['App信息'] || {}
      this.ColumnList = AppData?.columns
      if (!flag) {
        this.getInitData(AppData)
      } else {
        this.tableData = AppData?.list?.[this.mapList[this.currentIndex]]
      }

      this.load = false
      this.pageStatus.total = this.tabList[this.currentIndex].count
    },
    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.getList(true)
    },
    goNumPage(val) {
      this.pageStatus.pageSize = val
      this.pageStatus.pageNo = 1
      this.getList(true)
    },
    handleOpenStore(url) {
      window.open(url)
    },
    async handleOpenDialog(res) {
      this.PeDialogShow = true
      const params = {
        id: res.id
      }
      const { data } = await getAppDetail(params)
      this.appDetail = data
      this.appDetail.totalDownloadNum = formatRegex(
        this.appDetail.totalDownloadNum
      )
      this.appDetail.comments = formatRegex(this.appDetail.comments)
    },
    handlePeDialogClose() {
      this.PeDialogShow = false
    },
    getReceiveData() {
      if (
        this.$store.state.plugin.env === 'dd' &&
        !this.$store.state.user.accountInfo.mobile
      ) {
        this.$store.dispatch('showModal', {
          title: '绑定手机号',
          view: bindMobile,
          size: '600px'
        })
        return
      }

      //预览数据不可领取
      if (localStorage.getItem('isPreview') == true) {
        this.$store.dispatch('showTip', {
          text: '样例数据不可领取',
          type: 'error'
        })
        return
      }

      // this.getLoading = true
      this.$store.state.settings.isOn = true
      this.$bus.$emit('getContactType', true)
    }
  }
}
</script>
<style lang="scss">
.app-market {
  .icon-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
<style lang="scss" scoped>
.app-market {
  position: relative;
  .logoImg {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
  .page-footer {
    margin-top: 10px;
  }
  .appLogo {
    border-radius: 4px;
    width: 140px;
    height: 140px;
  }
  .flex-classify {
    display: flex;
    align-items: center;
  }
  .apply-list {
    display: flex;
    align-items: center;
    margin: 10px 0 20px 0;
    .item {
      font-size: 14px;
      cursor: pointer;
      &:nth-child(2) {
        margin: 0 20px;
      }
    }
    .active {
      color: #41cabf;
    }
  }
  .app-link {
    color: #1e98d7;
    cursor: pointer;
  }
  .apply-wrapper {
    display: flex;
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      font-size: 13px;

      .app-classify {
        color: #aaa;
      }
    }
  }
  .app-content {
    display: flex;
    align-items: center;
    font-size: 12px;
    .detail {
      display: flex;
      flex-direction: column;
      height: 140px;
      margin-left: 20px;
      width: 100%;
      .top {
        display: flex;
        align-items: center;
        .title {
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .introduce {
        margin-top: 10px;
        line-height: 20px;
        overflow: auto;
      }
    }
  }
  .list {
    margin-top: 20px;
    height: 350px;
    overflow: auto;
    .item {
      display: flex;
      align-items: center;
      border-top: 1px solid #b4bbc5;
      border-left: 1px solid #b4bbc5;
      border-right: 1px solid #b4bbc5;

      &:first-child {
        border-top: 1px solid #b4bbc5;
        border-left: 1px solid #b4bbc5;
        border-right: 1px solid #b4bbc5;
        border-bottom: none;
      }
      &:last-child {
        border-bottom: 1px solid #b4bbc5;
      }
      .item-fl {
        width: 30%;
        margin-left: 10px;
        height: 40px;
        line-height: 40px;
      }
      .item-fr {
        width: 70%;
        position: relative;
        padding-left: 10px;
        border-left: 1px solid #b4bbc5;
        height: 40px;
        line-height: 40px;
        overflow: hidden;
      }
    }
  }
}
.touch-no-receive {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 11;
  .touch-no-cont {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    .touch-no-icon {
      width: 150px;
      height: 40px;
      margin-top: 100px;
      position: relative;
      font-size: 14px;
      color: #8f8f8f;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 41px;
        height: 22px;
        top: -50px;
        left: 42px;
        background: url('../../../assets/no-receive.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
