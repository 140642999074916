<template>
    <div class="bind-main">
      <el-form :model="formData" label-position="left" label-width="auto" :rules="rules" hide-required-asterisk ref="form">
        <el-row>
          <el-col :span="24">
            <el-form-item label="手机号" prop="mobile">
                <el-input v-model="formData.mobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-row>
              <el-col :span="24">
                <el-form-item label="验证码" prop="smsCode">
                  <el-input v-model="formData.smsCode">
                    <template slot="append">
                      <el-button @click="sendSms">{{ time !== '获取验证码' ? `${ time } S` : time }}</el-button>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div class="footer">
        <el-button size="small" @click="cancelBtn">取消</el-button>
        <el-button size="small" type="primary" @click="confirmBtn">确定</el-button>
      </div>
    </div>
</template>

<script>
  // 此组件只能用在钉钉环境
  import { getSmsSend, changePhone, getAccountInfo } from '@/api/usercenter'
  import { ResetSystemConfig, ResetAuthUserMeal } from '@/utils/auth'
  export default {
    name: 'bindMobile',
    data(){
      return {
        time: '获取验证码',
        formData: {
          mobile: '',
          smsCode: '',
        },
        rules: {
          mobile:[
            { required: true, message: '请输入手机号码', trigger: 'blur' }
          ],
          smsCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        }
      }
    },
    methods:{
      cancelBtn(){
        this.$store.dispatch('hideModal')
      },

      sendSms(){
        if(this.time !== '获取验证码'){
          return
        }
        const mobile = this.formData.mobile
        if(!mobile){
          this.$store.dispatch('showTip', {
            text: '请输入手机号',
            type: 'error'
          })
          return
        }
        getSmsSend({mobile: mobile}).then(res => {
          if (res.code === 60000) {
            this.time = 60
            let time = setInterval(()=>{
              if(this.time >= 1 ){
                this.time -= 1
              }else {
                clearInterval(time)
                this.time = '获取验证码'
              }
            }, 1000)
          }else {
            this.time = '获取验证码'
          }
        })
      },

      confirmBtn(){
        this.$refs.form.validate(valid=>{
          if(!valid) return
          const params = {
            mobile: this.formData.mobile,
            code: this.formData.smsCode
          }
          changePhone(params).then(res=>{
            if(res.code === 60000){
              this.$store.commit('setToken', res.data) //更新token
              ResetSystemConfig()
              ResetAuthUserMeal()
              getAccountInfo({}).then(res => {
                if(res.code === 60000){
                  this.$store.dispatch('setAccountInfo', res.data)
                  this.$store.dispatch('hideModal')
                }
              })
            }
          })
        })
      }
    }
  }
</script>

<style scoped>
  .bind-main{
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
  }
  .footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    position: relative;
  }
  .footer::before{
    content: '';
    height: 1px;
    width: calc( 100% + 40px );
    background-color: rgba(0,0,0,.3);
    top: 0;
    left: -20px;
    position: absolute;
    transform: scaleY(.5);
  }
</style>
