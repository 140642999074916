<template>
  <div class="notice-main">
    <div class="tip">
      恭喜您，账号已注册成功。产品顾问将根据您的行业和业务需求，为您开通系统权限。扫码联系小助手[领鸟云晓晓]，获取产品使用帮助
    </div>
    <img :src="Img" class="registerImg" />
    <div class="footer">
      <el-button type="primary" size="small" @click="handleLogin">
        返回
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterNotice',
  data() {
    return {
      Img:
        'https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lner.png'
    }
  },
  methods: {
    handleLogin() {
      this.$router.replace('/login')
      this.$store.dispatch('hideModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.registerImg {
  width: 300px;
  height: 300px;
  display: block;
  margin: 0 auto;
}
.tip {
  margin: 20px auto;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  width: 80%;
}
.link {
  color: #0099cc;
  text-decoration: underline;
}
.footer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
}
.footer:before {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
  position: absolute;
  top: 0;
  left: 0;
}
</style>
