var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dt-data" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "dt-permission",
            rawName: "v-dt-permission",
            value: "数据看板-数据看板-查看数据看板",
            expression: "'数据看板-数据看板-查看数据看板'",
          },
        ],
        staticClass: "dt-data",
      },
      [
        _c(
          "div",
          { staticClass: "dt-data-header" },
          [
            _c(
              "el-button-group",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: _vm.selectBtn === 1 ? "primary" : "",
                      plain: _vm.selectBtn === 1,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTimeClick(1)
                      },
                    },
                  },
                  [_vm._v("今天\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: _vm.selectBtn === -1 ? "primary" : "",
                      plain: _vm.selectBtn === -1,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTimeClick(-1)
                      },
                    },
                  },
                  [_vm._v("昨天\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: _vm.selectBtn === -7 ? "primary" : "",
                      plain: _vm.selectBtn === -7,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTimeClick(-7)
                      },
                    },
                  },
                  [_vm._v("最近七天\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: _vm.selectBtn === -30 ? "primary" : "",
                      plain: _vm.selectBtn === -30,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTimeClick(-30)
                      },
                    },
                  },
                  [_vm._v("最近30天\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: _vm.selectBtn === "m" ? "primary" : "",
                      plain: _vm.selectBtn === "m",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTimeClick("m")
                      },
                    },
                  },
                  [_vm._v("本月\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: _vm.selectBtn === "jd" ? "primary" : "",
                      plain: _vm.selectBtn === "jd",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTimeClick("jd")
                      },
                    },
                  },
                  [_vm._v("本季度\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: _vm.selectBtn === 0 ? "primary" : "",
                      plain: _vm.selectBtn === 0,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTimeClick(0)
                      },
                    },
                  },
                  [_vm._v("全部\n        ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dt-select-time" },
              [
                _c("el-date-picker", {
                  attrs: {
                    "picker-options": _vm.viewDemo
                      ? _vm.startDateLimit
                      : _vm.startDateLimit2,
                    editable: false,
                    clearable: false,
                    size: "small",
                    type: "daterange",
                    "value-format": "yyyy-MM-dd",
                    "range-separator": "-",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                  },
                  on: { change: _vm.selectTimeClickAuto },
                  model: {
                    value: _vm.selectTime,
                    callback: function ($$v) {
                      _vm.selectTime = $$v
                    },
                    expression: "selectTime",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { position: "absolute", right: "20px" } },
              [
                _c(
                  "el-button",
                  {
                    key: _vm.viewDemo,
                    class: _vm.viewDemo ? "" : "",
                    attrs: {
                      autofocus: "",
                      size: "small",
                      type: _vm.viewDemo ? "" : "primary",
                    },
                    on: { click: _vm.changeViewDemoType },
                  },
                  [_vm._v(_vm._s(_vm.viewDemo ? "关闭示例" : "查看示例"))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-scrollbar",
          { ref: "scrollbar", staticClass: "dt-data-row dt-dropdown-dt" },
          [
            _c("div", [
              false
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingDataOne,
                          expression: "loadingDataOne",
                        },
                      ],
                      staticClass: "data-1",
                    },
                    _vm._l(_vm.dataOneData.config, function (key, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "data-1-item" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(key.label) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          key.key !== "contractMoneySum"
                            ? _c("div", { staticClass: "value" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.dataOneData.data[key.key] || "0"
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _c("div", { staticClass: "value" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      (
                                        _vm.dataOneData.data[key.key] || "0"
                                      ).toLocaleString()
                                    ) +
                                    "\n            "
                                ),
                              ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { ref: "scroll", staticClass: "data-1-new" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "data-title",
                      staticStyle: { "z-index": "99" },
                    },
                    [_vm._v("\n            转化漏斗\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingDataLd,
                                expression: "loadingDataLd",
                              },
                            ],
                            staticClass: "data-1-new-left",
                          },
                          [
                            _c("div", {
                              staticStyle: { height: "100%", width: "50%" },
                              attrs: { id: "data-1-new-left" },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      false
                        ? _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "data-1-new-right" }, [
                              _c("div", { staticClass: "data-title" }, [
                                _vm._v(
                                  "\n                  阶段客户画像\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "data-1-new-right-tip" },
                                [
                                  _vm._v(
                                    "\n                  敬请期待...\n                "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "achievement" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      padding: "0 0 10px 0",
                      "box-sizing": "border-box",
                    },
                  },
                  [_vm._v("\n            业绩分析\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLn,
                        expression: "isLn",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-tabs",
                      {
                        staticClass: "tab",
                        on: { "tab-click": _vm.tabClick },
                        model: {
                          value: _vm.tabType,
                          callback: function ($$v) {
                            _vm.tabType = $$v
                          },
                          expression: "tabType",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "销售过程分析", name: "xs" },
                        }),
                        _vm._v(" "),
                        _c("el-tab-pane", {
                          attrs: { label: "签约类型分析", name: "qy" },
                        }),
                        _vm._v(" "),
                        _c("el-tab-pane", {
                          attrs: { label: "回款分析", name: "hk" },
                        }),
                        _vm._v(" "),
                        _c("el-tab-pane", {
                          attrs: { label: "业绩预测", name: "yj" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "btn-table-chart" }, [
                  _c(
                    "div",
                    { staticClass: "btn-chart" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            height: "28px",
                          },
                        },
                        [
                          _c("i", {
                            class:
                              _vm.achievementType === "table"
                                ? "active-table"
                                : "default-table",
                            on: {
                              click: function ($event) {
                                return _vm.changeAchievementType("table")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("i", {
                            class:
                              _vm.achievementType === "histogram"
                                ? "active-histogram"
                                : "default-histogram",
                            on: {
                              click: function ($event) {
                                return _vm.changeAchievementType("histogram")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("i", {
                            class:
                              _vm.achievementType === "pie"
                                ? "active-pie"
                                : "default-pie",
                            on: {
                              click: function ($event) {
                                return _vm.changeAchievementType("pie")
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.achievementType !== "table",
                              expression: "achievementType !== 'table'",
                            },
                          ],
                          staticStyle: { transform: "translate(3px,-3px)" },
                          attrs: { placeholder: "请选择", size: "mini" },
                          on: { change: _vm.newSelectChange },
                          model: {
                            value: _vm.currentSelectType,
                            callback: function ($$v) {
                              _vm.currentSelectType = $$v
                            },
                            expression: "currentSelectType",
                          },
                        },
                        _vm._l(_vm.newOptions, function (item) {
                          return _c("el-option", {
                            key: item.field,
                            attrs: { label: item.name, value: item.field },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-chart" },
                    [
                      _vm.achievementType === "table"
                        ? [
                            _c(
                              "div",
                              { staticClass: "table" },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticClass: "table-dt",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                      "font-size": "12px",
                                      "background-color": "#fff",
                                    },
                                    attrs: {
                                      data: _vm.tableData,
                                      "show-summary": "",
                                      border: "",
                                      stripe: "",
                                    },
                                    on: { "sort-change": _vm.sortChange },
                                  },
                                  _vm._l(_vm.columnList, function (item) {
                                    return _c("el-table-column", {
                                      attrs: {
                                        "show-tooltip-when-overflow": "",
                                        prop: item.field,
                                        align: "center",
                                        sortable:
                                          item.type === "sort"
                                            ? "custom"
                                            : false,
                                        label: item.name,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("run-in", {
                                                  attrs: {
                                                    "data-num":
                                                      scope.row[item.field],
                                                    "data-type": item.field,
                                                    "data-name": item.name,
                                                    "data-format": `${scope.row["userName"]} ${_vm.formatFilter} ${item.name}`,
                                                    "data-filter": {
                                                      startDate: _vm.startDate,
                                                      endDate: _vm.endDate,
                                                      userId:
                                                        scope.row["userId"],
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.achievementType === "histogram"
                        ? [
                            _c("div", {
                              staticClass: "histogram",
                              attrs: { id: "histogram" },
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.achievementType === "pie"
                        ? [
                            _c("div", {
                              staticClass: "pie",
                              attrs: { id: "pie" },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "数据看板-数据看板-分布图",
                      expression: "'数据看板-数据看板-分布图'",
                    },
                  ],
                  staticClass: "data-3",
                },
                [
                  _c(
                    "div",
                    { staticClass: "select-data-3" },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "0 0 10px 0",
                            "box-sizing": "border-box",
                          },
                        },
                        [_vm._v("\n              分布图\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", size: "mini" },
                          on: { change: _vm.selectChange },
                          model: {
                            value: _vm.value,
                            callback: function ($$v) {
                              _vm.value = $$v
                            },
                            expression: "value",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "data-3-item" }, [
                    _c("div", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingDataTwo,
                          expression: "loadingDataTwo",
                        },
                      ],
                      staticClass: "data-3-item-left",
                      attrs: { id: "data-3-left" },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingDataTwo,
                          expression: "loadingDataTwo",
                        },
                      ],
                      staticClass: "data-3-item-right",
                      attrs: { id: "data-3-right" },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "数据看板-数据看板-趋势图",
                      expression: "'数据看板-数据看板-趋势图'",
                    },
                  ],
                  staticClass: "data-4",
                },
                [
                  _c(
                    "div",
                    { staticClass: "select-data-3" },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "0 0 10px 0",
                            "box-sizing": "border-box",
                          },
                        },
                        [_vm._v("\n              趋势图\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", size: "mini" },
                          on: { change: _vm.selectChange1 },
                          model: {
                            value: _vm.value1,
                            callback: function ($$v) {
                              _vm.value1 = $$v
                            },
                            expression: "value1",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                "collapse-tags": "",
                                placeholder: "请选择成员",
                                size: "mini",
                              },
                              on: { change: _vm.selectUserChange },
                              model: {
                                value: _vm.selectUserList,
                                callback: function ($$v) {
                                  _vm.selectUserList = $$v
                                },
                                expression: "selectUserList",
                              },
                            },
                            _vm._l(_vm.userList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            width: "400",
                            trigger: "click",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "j-input",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                "\n                请选择成员\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "user-list" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                _vm._l(_vm.userList, function (user) {
                                  return _c(
                                    "el-col",
                                    {
                                      key: user.id,
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: { span: 8 },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            disabled:
                                              (user.name &&
                                                user.name.length < 8) ||
                                              !user.name,
                                            "visible-arrow": false,
                                            "popper-class": "popper",
                                            effect: "dark",
                                            content: user.name,
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              staticClass:
                                                "checkbox-dt check-black",
                                              attrs: {
                                                checked:
                                                  user.id ==
                                                  _vm.userIdList[user.id],
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.change(user)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(user.name) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "check-black",
                          on: { change: _vm.checkChange },
                          model: {
                            value: _vm.excludeHoliday,
                            callback: function ($$v) {
                              _vm.excludeHoliday = $$v
                            },
                            expression: "excludeHoliday",
                          },
                        },
                        [_vm._v("排除周末节假日")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingDataFour,
                        expression: "loadingDataFour",
                      },
                    ],
                    ref: "dataFourRef",
                    staticClass: "data-4-item",
                    attrs: { id: "data-4-item" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "data-2" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "dt-permission",
                              rawName: "v-dt-permission",
                              value: "数据看板-数据看板-智能获客",
                              expression: "'数据看板-数据看板-智能获客'",
                            },
                          ],
                          attrs: { span: 12 },
                        },
                        [
                          _c(
                            "div",
                            { ref: "znhk", staticClass: "data-2-item" },
                            [
                              _c(
                                "el-card",
                                {
                                  staticStyle: { height: "100%" },
                                  attrs: {
                                    header: "智能获客",
                                    shadow: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value:
                                            _vm.loadingDataEnd ||
                                            _vm.loadingDataFive,
                                          expression:
                                            "loadingDataEnd || loadingDataFive",
                                        },
                                      ],
                                      style: { minHeight: _vm.cardHeight },
                                      attrs: { gutter: 20 },
                                    },
                                    _vm._l(
                                      _vm.dataFiveData.config,
                                      function (key, index) {
                                        return _c(
                                          "el-col",
                                          { key: index, attrs: { span: 6 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  padding: "20px",
                                                  "box-sizing": "border-box",
                                                  height: "100px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(key.label) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "value" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          key.key === "none"
                                                            ? ""
                                                            : (
                                                                _vm.dataFiveData
                                                                  .data[
                                                                  key.key
                                                                ] || "0"
                                                              ).toLocaleString()
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "dt-permission",
                              rawName: "v-dt-permission",
                              value: "数据看板-数据看板-客户培育",
                              expression: "'数据看板-数据看板-客户培育'",
                            },
                          ],
                          attrs: { span: 12 },
                        },
                        [
                          _c(
                            "div",
                            { ref: "khpy", staticClass: "data-2-item" },
                            [
                              _c(
                                "el-card",
                                {
                                  staticStyle: { height: "100%" },
                                  attrs: {
                                    header: "客户培育",
                                    shadow: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value:
                                            _vm.loadingDataEnd ||
                                            _vm.loadingDataFive,
                                          expression:
                                            "loadingDataEnd || loadingDataFive",
                                        },
                                      ],
                                      style: { minHeight: _vm.cardHeight },
                                      attrs: { gutter: 20 },
                                    },
                                    _vm._l(
                                      _vm.dataEndData.config,
                                      function (key, index) {
                                        return _c(
                                          "el-col",
                                          { key: index, attrs: { span: 6 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  padding: "20px",
                                                  "box-sizing": "border-box",
                                                  height: "100px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(key.label) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "value" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          key.key === "none"
                                                            ? ""
                                                            : (
                                                                _vm.dataEndData
                                                                  .data[
                                                                  key.key
                                                                ] || "0"
                                                              ).toLocaleString()
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "dt-permission",
                              rawName: "v-dt-permission",
                              value: "数据看板-数据看板-微站",
                              expression: "'数据看板-数据看板-微站'",
                            },
                          ],
                          attrs: { span: 12 },
                        },
                        [
                          _c(
                            "div",
                            { ref: "wz", staticClass: "data-2-item" },
                            [
                              _c(
                                "el-card",
                                {
                                  staticStyle: { height: "100%" },
                                  attrs: { header: "微站", shadow: "hover" },
                                },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value:
                                            _vm.loadingDataOld ||
                                            _vm.loadingDataSix,
                                          expression:
                                            "loadingDataOld || loadingDataSix",
                                        },
                                      ],
                                      style: { minHeight: _vm.cardHeight },
                                      attrs: { gutter: 20 },
                                    },
                                    _vm._l(
                                      _vm.dataSixData.config,
                                      function (key, index) {
                                        return _c(
                                          "el-col",
                                          { key: index, attrs: { span: 6 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  padding: "20px",
                                                  "box-sizing": "border-box",
                                                  height: "100px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(key.label) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "value" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          key.key === "none"
                                                            ? ""
                                                            : (
                                                                _vm.dataSixData
                                                                  .data[
                                                                  key.key
                                                                ] || "0"
                                                              ).toLocaleString()
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "dt-permission",
                              rawName: "v-dt-permission",
                              value: "数据看板-数据看板-客户管理CRM",
                              expression: "'数据看板-数据看板-客户管理CRM'",
                            },
                          ],
                          attrs: { span: 12 },
                        },
                        [
                          _c(
                            "div",
                            { ref: "khzh", staticClass: "data-2-item" },
                            [
                              _c(
                                "el-card",
                                {
                                  staticStyle: { height: "100%" },
                                  attrs: {
                                    header: "客户转化",
                                    shadow: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value:
                                            _vm.loadingDataOld ||
                                            _vm.loadingDataSix,
                                          expression:
                                            "loadingDataOld || loadingDataSix",
                                        },
                                      ],
                                      style: { minHeight: _vm.cardHeight },
                                      attrs: { gutter: 20 },
                                    },
                                    _vm._l(
                                      _vm.dataOldData.config,
                                      function (key, index) {
                                        return _c(
                                          "el-col",
                                          { key: index, attrs: { span: 6 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  padding: "20px",
                                                  "box-sizing": "border-box",
                                                  height: "100px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(key.label) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "value" },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          key.key === "none"
                                                            ? ""
                                                            : (
                                                                _vm.dataOldData
                                                                  .data[
                                                                  key.key
                                                                ] || "0"
                                                              ).toLocaleString()
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }