var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-custom-page" },
    [
      _c("div", { staticClass: "tag-title" }, [_vm._v(_vm._s(_vm.tagName))]),
      _vm._v(" "),
      _c("PeClassify", {
        attrs: { filterList: _vm.filterList },
        on: {
          handleGetFilter: _vm.handleGetFilter,
          handleTime: _vm.handleTime,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-content" },
        [
          _c("el-input", {
            staticStyle: { width: "400px" },
            attrs: { placeholder: "输入公司名称查询", size: "mini" },
            model: {
              value: _vm.pageParams.searchVal,
              callback: function ($$v) {
                _vm.$set(_vm.pageParams, "searchVal", $$v)
              },
              expression: "pageParams.searchVal",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { margin: "0 10px" },
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.getList },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleReset } },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("PeTable", {
        attrs: {
          peTableData: _vm.tableList,
          peColumnList: _vm.tableColumn,
          pePage: _vm.tablePage,
          peLoading: _vm.peLoading,
        },
        on: {
          sortPeChange: _vm.handleSortPeChange,
          handleSelectCommand: _vm.handleSelectCommand,
          handleCurrentPePage: _vm.handleCurrentPePage,
          handleSizePeChange: _vm.handleSizePeChange,
        },
        scopedSlots: _vm._u([
          {
            key: "company_name",
            fn: function ({ scope }) {
              return [
                _c(
                  "span",
                  {
                    staticClass: "company-name-click",
                    on: {
                      click: function ($event) {
                        return _vm.handleEnter(scope.data, scope.index)
                      },
                    },
                  },
                  [_vm._v(_vm._s(scope.data.company_name))]
                ),
                _vm._v(" "),
                _c("Tags", { attrs: { tagList: scope.data.tags } }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "abnormal" },
                  [
                    _c("PeRisk", { attrs: { riskTag: scope.data.risk } }),
                    _vm._v(" "),
                    scope.data.company_status == "注销"
                      ? _c("span", { staticClass: "qy-cancel" }, [
                          _vm._v("注销"),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }