var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.refreshLoading,
          expression: "refreshLoading",
        },
      ],
      ref: "moduleitem",
    },
    [
      _c(
        "div",
        {
          ref: "computedComPic",
          staticClass: "title",
          attrs: { id: "computedComPic" },
        },
        [_vm._v("\n    " + _vm._s(_vm.moduleParam.module_title) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "touch-cont-mang p-b-38" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.tabArr, function (item, index) {
              return _c(
                "el-tab-pane",
                {
                  key: index + item,
                  attrs: {
                    label: `${item}(${_vm.handleTabData(item)})`,
                    name: item,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "touch-tab-cont" },
                    [
                      _c(_vm.modulCont, {
                        key: item,
                        ref: "comModuleCont",
                        refInFor: true,
                        tag: "component",
                        attrs: {
                          otherParams: _vm.otherParams,
                          cardType: _vm.cardType,
                          detail: _vm.detail,
                          moduleParam: _vm.moduleParam,
                          moduleDetail: _vm.moduleDetail,
                          packageId: _vm.packageId,
                        },
                        on: { getComDetailModel: _vm.getComDetailModel },
                      }),
                      _vm._v(" "),
                      _vm.activeName != "人脉"
                        ? _c("el-pagination", {
                            ref: "pageCheck",
                            refInFor: true,
                            attrs: {
                              "current-page": _vm.pePage.pageNo,
                              "page-sizes": [10],
                              "page-size": _vm.pePage.pageSize,
                              layout: "total, sizes, prev, pager, next,slot",
                              total: _vm.pePage.total,
                            },
                            on: { "current-change": _vm.handleCurrentChange },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }