<template>
  <div class="bindPackage-main">
    <div class="select">
      <el-form>
        <el-form-item label="画像分类" label-width="70px">
          <el-select v-model="value" placeholder="请选择" style="width: 100%">
            <el-option
              v-for="item in list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancelClick">取消</el-button>
      <el-button size="small" type="primary" @click="confirmClick"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import { getCusTagsDdic, insightBind } from '@/api/customerpond'
export default {
  name: 'bindPackage',
  data() {
    return {
      list: [],
      value: ''
    }
  },
  mounted() {
    getCusTagsDdic({ type: 12 }).then(res => {
      if (res.code === 60000) {
        this.list = [{ id: -1, name: '全部' }, ...(res?.data || [])]
      }
    })
  },
  computed: {},
  methods: {
    cancelClick() {
      this.$store.dispatch('hideModal')
    },
    confirmClick() {
      const params = {
        packId: this.$store.state.plugin.modal.options.pid,
        tagId: this.value
      }
      insightBind(params).then(res => {
        if (res.code === 60000) {
          this.$store.commit('update', {
            target: this.$store.state.plugin.modal.options,
            data: {
              refresh: true
            }
          })
          this.cancelClick()
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
        } else {
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'error'
          })
        }
      })
    }
  },
  beforeDestroy() {}
}
</script>

<style lang="scss">
.bindPackage-main {
  .el-form-item {
    margin-bottom: 0 !important;
  }
}
</style>

<style scoped lang="scss">
.bindPackage-main {
  .select {
    padding: 10px;
    box-sizing: border-box;
  }
  .footer {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      transform: scaleY(0.5);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>
