var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-popover" },
    [
      _c(
        "el-popover",
        {
          ref: "popRef",
          attrs: {
            placement: "top-start",
            title: _vm.PeHoverTitle,
            width: _vm.PeHoverWidth,
            trigger: "hover",
            "popper-options": {
              strategy: "fixed",
            },
          },
          on: { show: _vm.handleShow },
        },
        [
          _vm._t("content"),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "reference" }, slot: "reference" },
            [_vm._t("txt")],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }