var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%" } },
    [
      _c(
        "el-select",
        _vm._b(
          {
            staticStyle: { width: "100%", height: "100%" },
            attrs: {
              multiple: "",
              "popper-class": _vm.innerPopperClass,
              filterable: _vm.filterable,
              "filter-method": _vm.innerFilterMethod,
              "reserve-keyword": _vm.reserveKeyword,
              "allow-create": false,
            },
            on: {
              "remove-tag": _vm.removeOne,
              change: _vm.changeLabel,
              blur: _vm.handleBlur,
              "visible-change": _vm.visibleChange,
              focus: _vm.handleFocus,
              clear: _vm.handleClear,
            },
            model: {
              value: _vm.selectedLabels,
              callback: function ($$v) {
                _vm.selectedLabels = $$v
              },
              expression: "selectedLabels",
            },
          },
          "el-select",
          _vm.$attrs,
          false
        ),
        [
          _vm.$slots.prefix
            ? _c(
                "span",
                { attrs: { slot: "prefix" }, slot: "prefix" },
                [_vm._t("prefix")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isSearching
            ? [
                _c("el-option", { attrs: { value: "__root" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "ground",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _c("render-list", {
                        attrs: {
                          "only-last": _vm.onlyLast,
                          list: _vm.root.childNodes,
                          level: 1,
                          "active-list": _vm.activeList,
                          "label-key": _vm.labelKey,
                          "expand-trigger": _vm.expandTrigger,
                        },
                        on: {
                          "handle-click": _vm.handleClick,
                          "handle-check": _vm.handleCheck,
                        },
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.maxLevellist, function (item) {
                        return [
                          item.rendered
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.activeList.length >= item.id,
                                      expression:
                                        "activeList.length >= item.id",
                                    },
                                  ],
                                  key: item.id,
                                  class: `floor-item floor-position-left-${
                                    item.id + 1
                                  }`,
                                },
                                [
                                  _c("render-list", {
                                    attrs: {
                                      "only-last": _vm.onlyLast,
                                      list: _vm.showData[item.id],
                                      level: item.id + 1,
                                      "active-list": _vm.activeList,
                                      "label-key": _vm.labelKey,
                                      "expand-trigger": _vm.expandTrigger,
                                    },
                                    on: {
                                      "handle-click": _vm.handleClick,
                                      "handle-check": _vm.handleCheck,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isSearching
            ? _vm._l(_vm.searchResult, function (item) {
                return _c(
                  "el-option",
                  { key: _vm.getKey(item), attrs: { value: item.showLabel } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "margin-left": "20px",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.selectOne(item)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { class: item.checked ? "label-fill" : "label-init" },
                          [_vm._v("\n            " + _vm._s(item.totalLabel))]
                        ),
                      ]
                    ),
                  ]
                )
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }