var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "full-screen-page",
      style: _vm.isFull ? "position:fixed" : "position:absolute",
    },
    [
      _c(
        "div",
        { staticClass: "icon-wrap", on: { click: _vm.handleFullMap } },
        [
          _vm.isFull
            ? _c("img", {
                staticClass: "icon",
                attrs: { src: require("../../../assets/pe-img/shrink.png") },
              })
            : _c("img", {
                staticClass: "icon",
                attrs: { src: require("../../../assets/pe-img/full.png") },
              }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }