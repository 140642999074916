var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cell-lib-page" }, [
    _c("div", { staticClass: "crumb" }, [_vm._v("组件库")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-style" },
      _vm._l(_vm.panelList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "card-item" },
          [
            _c("div", { staticClass: "chart-title" }, [
              _vm._v(_vm._s(item.title)),
            ]),
            _vm._v(" "),
            _c("pe-chart", { attrs: { item: item } }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }