<template>
  <div class="showTip-main" ref="showExportData">
    <div class="tip-row">
      <div class="export-title">
        <span>智能过滤</span>
        <div>
          <span>数据范围</span>
          <span v-if="isShowSelect">培育池列表已勾选数据</span>
          <el-radio-group v-else v-model="dataRang" @change="handleRadioChange">
            <el-radio :label="0">培育池全部(已领取+未领取)</el-radio>
            <el-radio :label="1">培育池已领取</el-radio>
          </el-radio-group>
        </div>
        <div>
          <span>过滤企业</span>
          <el-select
            v-model="selectValue"
            size="mini"
            @change="handleSelectChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>过滤号码</span>
          <el-checkbox v-model="checked"></el-checkbox>
          <span>过滤空号、疑似中介等无效号码</span>
        </div>
        <div>
          <span>过滤状态</span>
          <el-checkbox
            v-model="status"
            @change="handleStatusChange"
          ></el-checkbox>
          <span>过滤注销/状态异常企业</span>
        </div>
      </div>
      <div class="showTip-desc">
        <div class="item">
          当前账号每天导出额度:{{
            accountInfo.initExportColAvailableFree
          }}条,今天剩余<span>{{ accountInfo.exportColAvailableFree }}</span
          >条
        </div>
        <div class="item">
          当前培育池线索数:{{ totalPage }}条,"智能过滤"后可导出线索数<span>{{
            filterNum
          }}</span
          >条
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="data-pe" @click="handleOpen">查看数据配额</div>
      <div>
        <el-button size="mini" @click="cancelBtn">取消</el-button>
        <el-button
          v-if="filterNum"
          type="primary"
          size="mini"
          @click="confirmBtn"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AutoFilterData } from '@/api/thread'
import { getKeyNum } from '@/api/subaccount'
export default {
  name: 'ShowTip',

  data() {
    return {
      inner: false,
      currentKeyNum: {},
      show: false,
      checked: false,
      status: false,
      selectValue: localStorage.exportQy || '1',
      dataRang: 0,
      filterNum: 0,
      options: [
        {
          value: '1',
          label: '过滤本账号已导出企业'
        },
        {
          value: '2',
          label: '过滤本公司已导出企业'
        },
        {
          value: '0',
          label: '不做过滤导出企业'
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['accountInfo']),
    isShowSelect() {
      return this.$store.state.plugin.modal.options.count
    },
    favoriteId() {
      return this.$store.state.plugin.modal.options.favoriteId
    },
    currentSelectData() {
      return this.$store.state.plugin.modal.options.currentSelectData
    },
    totalPage() {
      return this.$store.state.plugin.modal.options.total
    }
  },

  mounted() {
    this.getFilterData()
    this.getKeyPoint()
  },

  methods: {
    handleOpen() {
      this.$alert(
        ` <div>【线索包】剩余可用点数： ${
          this.currentKeyNum.companyAvailableFree
        }</div>
        <div>【企业查询/高级查找/自助找客】剩余可用点数：${
          this.currentKeyNum.ziZhuAvailableFree
        }</div>
        <div>【其他配额】剩余可用点数：${
          this.currentKeyNum.companyAvailable
        }</div>`,
        '提示',
        {
          dangerouslyUseHTMLString: true
        }
      )
    },

    handleStatusChange() {
      this.getFilterData()
    },

    handleClose() {
      this.show = false
      this.inner = true
    },

    getKeyPoint() {
      getKeyNum({}).then(res => {
        if (res.code === 60000) {
          this.currentKeyNum = res.data
        }
      })
    },

    async getFilterData() {
      const arr = this.currentSelectData.map(item => {
        return {
          packageId: item.packageId,
          companyUuid: item.companyUuid,
          favoriteId: this.favoriteId
        }
      })
      if (this.currentSelectData.length) {
        this.dataRang = 2
      }
      const params = {
        favoriteId: this.favoriteId,
        dataRange: this.dataRang,
        packageIdAndcompanyuuid: arr.length ? JSON.stringify(arr) : '',
        filterHasExport: this.selectValue,
        filterAbnormal: this.status
      }
      const data = await AutoFilterData(params)
      if (data.code === 60000) {
        this.filterNum = data.data
      }
      console.log(data, 3344)
    },

    confirmBtn() {
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          confirm: true,
          selectVal: this.selectValue,
          checked: this.checked,
          status: this.status,
          radio: this.dataRang
        }
      })
      this.cancelBtn()
    },

    cancelBtn() {
      this.$store.dispatch('hideModal')
    },

    handleSelectChange() {
      this.getFilterData()
      localStorage.setItem('exportQy', this.selectValue)
    },

    handleRadioChange() {
      this.getFilterData()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.showTip-main {
  box-sizing: border-box;
  width: 98%;
  margin: 0 auto;

  ::v-deep .content {
    line-height: 30px;
  }
}

.data-pe {
  color: $main-color;
  cursor: pointer;
}

.export-title {
  display: flex;
  flex-direction: column;
  line-height: 35px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 10px;
  span {
    display: inline-block;
    margin-right: 10px;
  }
}

.showTip-desc {
  margin-top: 20px;
  line-height: 30px;
  span {
    color: #f00;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: relative;
}

.tip-row {
  width: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon-color {
  color: #e6a23c;
  font-size: 18px;
}

.ivr-table {
  display: inline-block;
  height: 15px;
  width: 15px;
  position: relative;
  cursor: pointer;
  top: 4px;
  right: 0px;
  background: url('../../../assets/call_ivr.svg') no-repeat center center;
  background-size: 100% 100%;
}
</style>
