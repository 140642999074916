var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-main" },
    [
      _c(
        "div",
        { staticClass: "tab-div" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "dt-tab",
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.tabType,
                callback: function ($$v) {
                  _vm.tabType = $$v
                },
                expression: "tabType",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "全部", name: "0" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "新签60天内", name: "1" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "60天内到期", name: "2" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "超期未续费", name: "3" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "btn-list" }, [
        _c(
          "div",
          { staticClass: "input-row" },
          [
            _c("el-input", {
              staticStyle: { width: "200px" },
              attrs: { size: "mini", placeholder: "输入公司名查询" },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-row" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.searchAfterSales },
                  },
                  [_vm._v("查询")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.reset } },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "g-search", on: { click: _vm.searchPlus } },
              [
                _vm._v("\n        高级筛选"),
                _c("svg-icon", {
                  staticClass: "filtrate",
                  attrs: { "icon-class": "filtrate" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.condition,
                    expression: "condition",
                  },
                ],
                staticClass: "condition",
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "condition-icon",
                    on: { click: _vm.removeSearch },
                  },
                  [_c("i", { staticClass: "el-icon-circle-close" })]
                ),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.showCondition,
                    callback: function ($$v) {
                      _vm.showCondition = $$v
                    },
                    expression: "showCondition",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "table",
        },
        [
          _c(
            "el-table",
            {
              key: _vm.refreshKey,
              staticClass: "table-dt",
              staticStyle: {
                width: "100%",
                height: "100%",
                "font-size": "12px",
                "background-color": "#fff",
              },
              attrs: { data: _vm.tableData, border: "", stripe: "" },
              on: { "sort-change": _vm.sortChange },
            },
            _vm._l(_vm.columnList, function (item) {
              return _c("el-table-column", {
                attrs: {
                  "show-tooltip-when-overflow": "",
                  prop: item.field,
                  width: item.field === "setting" ? 260 : "",
                  sortable: item.type === "order" ? "custom" : false,
                  align: item.field === "companyName" ? "left" : "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          item.type === "select"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-dropdown",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        trigger: "click",
                                      },
                                      on: {
                                        command: (val) =>
                                          _vm.filterFunc(val, item),
                                      },
                                    },
                                    [
                                      item.field === "leader"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.userId
                                                  ? "active"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c("dt-drop-down", {
                                                  attrs: {
                                                    "source-list":
                                                      _vm.followList,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (row) {
                                                          return [
                                                            _c(
                                                              "el-scrollbar",
                                                              {
                                                                staticClass:
                                                                  "dt-dropdown-dt",
                                                              },
                                                              [
                                                                !row.filterList
                                                                  .length
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "dt-drop-down-no-data",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          无数据\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm._l(
                                                                  row.filterList,
                                                                  function (
                                                                    _item
                                                                  ) {
                                                                    return _c(
                                                                      "el-dropdown-item",
                                                                      {
                                                                        class:
                                                                          _vm.userId ===
                                                                          _item.id
                                                                            ? "active"
                                                                            : "",
                                                                        attrs: {
                                                                          command:
                                                                            _item.id,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            domProps:
                                                                              {
                                                                                innerHTML:
                                                                                  _vm._s(
                                                                                    _item.htmlEl
                                                                                  ),
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : item.field === "from"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.from ? "active" : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-scrollbar",
                                                  {
                                                    staticClass:
                                                      "dt-dropdown-dt",
                                                  },
                                                  _vm._l(
                                                    _vm.sourceList,
                                                    function (_item) {
                                                      return _c(
                                                        "el-dropdown-item",
                                                        {
                                                          class:
                                                            _vm.from ===
                                                            _item.id
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            command: _item.id,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_item.name)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : item.type === "order"
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.name) +
                                    "\n          "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.name) +
                                    "\n          "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.field === "setting"
                            ? _c("span", { staticClass: "set" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "set-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.followRecord(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              跟进记录\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(" | ")]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "set-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.contactDetail(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              联系人\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(" | ")]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "set-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkHealthy(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              健康度检测\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(" | ")]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "set-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteAfterSales(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("\n              删除\n            ")]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(" | ")]),
                              ])
                            : item.field === "companyName"
                            ? _c("company-link", {
                                attrs: {
                                  "company-name":
                                    scope.row.companyName ||
                                    scope.row.company_name,
                                  "company-uuid":
                                    scope.row.companyUuid ||
                                    scope.row.company_uuid,
                                  "package-id":
                                    scope.row.packageId || scope.row.package_id,
                                },
                              })
                            : _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row[item.field]) +
                                    "\n          "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page page-div page-1" },
        [
          _c("footer-page", {
            attrs: { pageStatus: _vm.pageStatus },
            on: {
              handleCurrentChange: _vm.handleCurrentChange,
              goNumPage: _vm.goNumPage,
              changePageSize: _vm.changePageSize,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "我是标题",
            visible: _vm.openDrawer,
            size: "600px",
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openDrawer = $event
            },
          },
        },
        [
          _vm.openDrawer
            ? _c("pond-detail", {
                attrs: {
                  source: "costomerpond",
                  customerInfoMap: _vm.currentCheckRow,
                  id: _vm.rowComId,
                  "company-uuid": _vm.companyUuid,
                  "package-id": _vm.packageId,
                  rowCont: _vm.currentCheckRow,
                  svg: "",
                  majorId: _vm.majorId,
                  isActiveTab1: "3",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }