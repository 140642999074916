var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "createPayBack-main" },
    [
      _c(
        "div",
        { staticClass: "form-main" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                "label-width": "auto",
                size: "small",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "userId", label: "款项" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择款项", filterable: "" },
                      model: {
                        value: _vm.formData.returnPayMent,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "returnPayMent", $$v)
                        },
                        expression: "formData.returnPayMent",
                      },
                    },
                    _vm._l(_vm.returnPayMentList, function (item) {
                      return _c("el-option", {
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "contractId", label: "关联合同" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请选择关联合同" },
                    model: {
                      value: _vm.formData.contractId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "contractId", $$v)
                      },
                      expression: "formData.contractId",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class:
                        _vm.type === "create" ? "input-mask" : "input-mask",
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n            " + _vm._s(_vm.cname) + "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          class:
                            _vm.type === "create"
                              ? "create-edit-btn"
                              : "create-edit-btn",
                          on: { click: _vm.selectHeTong },
                        },
                        [_vm._v("\n            选择\n          ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "returnId", label: "编号" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入编号(选择关联合同后可自动生成)",
                    },
                    model: {
                      value: _vm.formData.returnId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "returnId", $$v)
                      },
                      expression: "formData.returnId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "returnMoney", label: "金额" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入金额" },
                    model: {
                      value: _vm.formData.returnMoney,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "returnMoney", $$v)
                      },
                      expression: "formData.returnMoney",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "userId", label: "业绩归属人" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择业绩归属人",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formData.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "userId", $$v)
                        },
                        expression: "formData.userId",
                      },
                    },
                    _vm._l(_vm.userList, function (item) {
                      return _c("el-option", {
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "returnDate", label: "日期" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "年 / 月 / 日",
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                    },
                    on: { change: _vm.returnDateChange },
                    model: {
                      value: _vm.formData.returnDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "returnDate", $$v)
                      },
                      expression: "formData.returnDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "achievementBelongDate",
                    label: "业绩归属月份",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "年 / 月",
                      type: "month",
                      format: "yyyy-MM",
                      "value-format": "yyyy-MM",
                    },
                    model: {
                      value: _vm.formData.achievementBelongDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "achievementBelongDate", $$v)
                      },
                      expression: "formData.achievementBelongDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "remark", label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入备注",
                      maxlength: "500",
                      "show-word-limit": "",
                      autosize: { minRows: 4, maxRows: 10 },
                    },
                    model: {
                      value: _vm.formData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "remark", $$v)
                      },
                      expression: "formData.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            width: "850px",
            top: "90px",
            visible: _vm.showSelectHeTong,
            "show-close": false,
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectHeTong = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slot-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v("\n        关联合同\n      ")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSelectHeTong = false
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-close close" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("select-he-tong", {
                on: {
                  closeSelectCompany: function ($event) {
                    _vm.showSelectHeTong = false
                  },
                  selectCompany: _vm.selectCompany,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }