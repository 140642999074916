var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "enterprise-dialog" }, [
    _c(
      "div",
      { staticClass: "item" },
      [
        _c("span", { staticStyle: { width: "90px" } }, [_vm._v("标签组名称")]),
        _vm._v(" "),
        _c("el-input", {
          attrs: { placeholder: "长度20个汉字以内", maxlength: "20" },
          model: {
            value: _vm.tagName,
            callback: function ($$v) {
              _vm.tagName = $$v
            },
            expression: "tagName",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "item" },
      [
        _c("span", { staticStyle: { width: "90px" } }, [_vm._v("标签组排序")]),
        _vm._v(" "),
        _c("el-input", {
          attrs: {
            type: "number",
            placeholder: "数字小的排在最上面，数字相同按创建时间倒序",
          },
          model: {
            value: _vm.tagSort,
            callback: function ($$v) {
              _vm.tagSort = $$v
            },
            expression: "tagSort",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer-group" },
      [
        _vm.editType
          ? _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.handleDelete } },
              [_vm._v("删除")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.cancelBtn } },
              [_vm._v("取消")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.handleSure },
              },
              [_vm._v("确认")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }