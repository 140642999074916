var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "ScrollLoad-main",
    },
    [
      _c("span", { staticClass: "before", on: { click: _vm.showBeforeKey } }, [
        _vm._v("上一条"),
      ]),
      _c("span", { staticClass: "last", on: { click: _vm.showNextKey } }, [
        _vm._v("下一条"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }