var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "body" },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: {
                "padding-right": "15px",
                "box-sizing": "border-box",
                height: "400px",
                width: "100%",
                "overflow-y": "auto",
              },
              attrs: {
                model: _vm.formData,
                "label-width": "auto",
                rules: _vm.rules,
                "validate-on-rule-change": false,
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "负责人" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled:
                                                  _vm.type == "globCreate",
                                                placeholder: "请选择负责人",
                                              },
                                              model: {
                                                value: _vm.formData.userId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "userId",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.userId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.leaderList,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "协作人" } },
                                        [
                                          _vm.type == "edit"
                                            ? [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      multiple: "",
                                                      filterable: "",
                                                      disabled:
                                                        _vm.type ==
                                                        "globCreate",
                                                      placeholder:
                                                        "请选择协作人",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .cooperationUsers,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "cooperationUsers",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.cooperationUsers",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.leaderList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.name,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            : [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      multiple: "",
                                                      filterable: "",
                                                      disabled:
                                                        _vm.type ==
                                                        "globCreate",
                                                      placeholder:
                                                        "请选择协作人",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .cooperationUserId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "cooperationUserId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.cooperationUserId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.leaderList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _vm.formData.userId == -1 &&
                                      _vm.activeTabs != 2 &&
                                      _vm.activeTabs != 4 &&
                                      _vm.type == "edit"
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "退回理由",
                                                required:
                                                  _vm.formData.userId == -1 &&
                                                  _vm.activeTabs != 2 &&
                                                  _vm.activeTabs != 4 &&
                                                  _vm.type == "edit",
                                                prop: "returnType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请选择退回理由",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.returnType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "returnType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.returnType",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.allMessage,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "客户名称",
                                            prop: "companyName",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.type == "globCreate" ||
                                                  _vm.disabled(),
                                                placeholder: "请输入客户名称",
                                              },
                                              model: {
                                                value: _vm.formData.companyName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "companyName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.companyName",
                                              },
                                            },
                                            [
                                              !_vm.disabled()
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "viewC",
                                                      attrs: { slot: "suffix" },
                                                      on: {
                                                        click: _vm.checkName,
                                                      },
                                                      slot: "suffix",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        查重\n                      "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "省市" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: {
                                                width: "45%",
                                                "box-sizing": "border-box",
                                              },
                                              attrs: {
                                                placeholder: "请选择省份",
                                              },
                                              on: { change: _vm.getAllCity },
                                              model: {
                                                value: _vm.formData.province,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "province",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.province",
                                              },
                                            },
                                            _vm._l(
                                              _vm.allProvince,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.name,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.name,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: {
                                                width: "45%",
                                                float: "right",
                                              },
                                              attrs: {
                                                placeholder: "请选择城市",
                                              },
                                              model: {
                                                value: _vm.formData.city,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "city",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.city",
                                              },
                                            },
                                            _vm._l(
                                              _vm.allCity,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item,
                                                  attrs: {
                                                    label: item,
                                                    value: item,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "客户来源" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled:
                                                  _vm.type == "globCreate",
                                                placeholder: "请选择客户来源",
                                              },
                                              model: {
                                                value: _vm.formData.fromId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "fromId",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.fromId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.allSource,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "线索等级" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请选择线索等级",
                                              },
                                              model: {
                                                value: _vm.formData.clueGradeId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "clueGradeId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.clueGradeId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.allClue,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.isLn
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "客户销售团队规模",
                                                prop: "saleRange",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请选择客户销售团队规模",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.saleRange,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "saleRange",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.saleRange",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.saleRangeList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "客户等级" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请选择客户等级",
                                              },
                                              model: {
                                                value: _vm.formData.gradeId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "gradeId",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.gradeId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.allLevel,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "状态" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请选择客户状态",
                                              },
                                              model: {
                                                value: _vm.formData.stateId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "stateId",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.stateId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.allStatus,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "企业标签" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              ref: "selectTag",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                multiple: "",
                                                placeholder: "请选择标签",
                                              },
                                              on: { change: _vm.selectChange },
                                              model: {
                                                value: _vm.selectTags,
                                                callback: function ($$v) {
                                                  _vm.selectTags = $$v
                                                },
                                                expression: "selectTags",
                                              },
                                            },
                                            _vm._l(
                                              _vm.allPerTags,
                                              function (item) {
                                                return _c(
                                                  "el-option-group",
                                                  {
                                                    attrs: { label: item.name },
                                                  },
                                                  _vm._l(
                                                    item.tags,
                                                    function (it) {
                                                      return _c("el-option", {
                                                        key: it.id,
                                                        attrs: {
                                                          label: it.name,
                                                          value: it.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "个人标签" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              ref: "selectTag1",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                multiple:
                                                  _vm.personalPerTagsType,
                                                placeholder: "请选择标签",
                                              },
                                              on: { change: _vm.selectChange },
                                              model: {
                                                value: _vm.personalSelectTag,
                                                callback: function ($$v) {
                                                  _vm.personalSelectTag = $$v
                                                },
                                                expression: "personalSelectTag",
                                              },
                                            },
                                            _vm._l(
                                              _vm.perAllPerTags,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.isLn
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "客户销售模式",
                                                prop: "saleModel",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    multiple: "",
                                                    placeholder:
                                                      "请选择请选择客户销售模式",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.saleModel,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "saleModel",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.saleModel",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.saleModelList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "备注" } },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请输入备注",
                                              type: "textarea",
                                              maxlength: "100",
                                              "show-word-limit": "",
                                            },
                                            model: {
                                              value: _vm.formData.remark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "remark",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.remark",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "select-targer" }, [
                    _c("span", { staticClass: "target" }, [
                      _vm._v("\n            自定义字段\n          "),
                    ]),
                    _vm._v(" "),
                    _vm.isAdmin
                      ? _c("span", { on: { click: _vm.setTarget } }, [
                          _vm._v("\n            设置\n          "),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        _vm._l(_vm.targetList, function (item) {
                          return _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: item.name } },
                                [
                                  item.type === 0
                                    ? [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: `请输入${item.name}`,
                                          },
                                          model: {
                                            value: _vm.target[item.field],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.target,
                                                item.field,
                                                $$v
                                              )
                                            },
                                            expression: "target[item.field]",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.type === 1
                                    ? [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: `请选择${item.name}`,
                                            },
                                            model: {
                                              value: _vm.target[item.field],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.target,
                                                  item.field,
                                                  $$v
                                                )
                                              },
                                              expression: "target[item.field]",
                                            },
                                          },
                                          _vm._l(item.filters, function (item) {
                                            return _c("el-option", {
                                              key: item,
                                              attrs: {
                                                label: item,
                                                value: item,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.type === 2
                                    ? [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            editable: false,
                                            type: "date",
                                            "value-format": "yyyy-MM-dd",
                                            placeholder: `请选择${item.name}`,
                                          },
                                          model: {
                                            value: _vm.target[item.field],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.target,
                                                item.field,
                                                $$v
                                              )
                                            },
                                            expression: "target[item.field]",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.type === "create" || _vm.type === "globCreate"
                    ? _c("div", { staticClass: "select" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                _vm.formData.haveContact =
                                  !_vm.formData.haveContact
                              },
                            },
                          },
                          [_vm._v("\n            联系人信息\n          ")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formData.haveContact &&
                  (_vm.type === "create" || _vm.type === "globCreate")
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "姓名",
                                                prop: "contact",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请输入姓名",
                                                  disabled:
                                                    !_vm.formData.haveContact,
                                                },
                                                model: {
                                                  value: _vm.formData.contact,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "contact",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData.contact",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [_vm._v("身份")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value: _vm.formData.isKp,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "isKp",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "formData.isKp",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "关键人",
                                                      value: "1",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "中间人",
                                                      value: "2",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "普通",
                                                      value: "3",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "部门" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请输入部门",
                                                  disabled:
                                                    !_vm.formData.haveContact,
                                                },
                                                model: {
                                                  value:
                                                    _vm.formData.department,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "department",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData.department",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "职位" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请输入职位",
                                                  disabled:
                                                    !_vm.formData.haveContact,
                                                },
                                                model: {
                                                  value: _vm.formData.position,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "position",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData.position",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "手机" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请输入手机号码",
                                                  disabled:
                                                    !_vm.formData.haveContact,
                                                },
                                                model: {
                                                  value: _vm.formData.mobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "mobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formData.mobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "座机" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请输入座机号码",
                                                  disabled:
                                                    !_vm.formData.haveContact,
                                                },
                                                model: {
                                                  value: _vm.formData.landLine,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "landLine",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData.landLine",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "微信" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请输入微信",
                                                  disabled:
                                                    !_vm.formData.haveContact,
                                                },
                                                model: {
                                                  value: _vm.formData.wechat,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "wechat",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formData.wechat",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "邮箱" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请输入邮箱",
                                                  disabled:
                                                    !_vm.formData.haveContact,
                                                },
                                                model: {
                                                  value: _vm.formData.email,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "email",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formData.email",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "备注" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请输入备注",
                                                  disabled:
                                                    !_vm.formData.haveContact,
                                                  type: "textarea",
                                                  maxlength: "100",
                                                  "show-word-limit": "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.formData.contactRemark,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "contactRemark",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData.contactRemark",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveInput },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            visible: _vm.showCheckRe,
            modal: true,
            width: "650px",
            top: "90px",
            "close-on-click-modal": false,
            "destroy-on-close": true,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCheckRe = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [_vm._v("\n      客户查重\n    ")]
          ),
          _vm._v(" "),
          _c(_vm.searchCompanyRep, {
            tag: "component",
            attrs: { name: _vm.formData.companyName },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "DtDialog",
        {
          ref: "dt-dialog",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            "dialog-width": "800px",
            top: "100px",
            title: "设置自定义字段",
          },
        },
        [
          _c("targetSetting", {
            ref: "target",
            on: { closePop: _vm.closePop, confirmPop: _vm.confirmPop },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }