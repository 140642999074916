<template>
  <div class="flex-table">
    <div class="table-header">
      <div
        class="header-item"
        v-for="(item, index) in tableColumn"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div @click="handleItemClick">
      <vue-seamless-scroll
        :data="tableList"
        class="roll-table"
        :class-option="classOption"
      >
        <ul>
          <li
            v-for="(el, idx) in tableList"
            :key="idx"
            :style="`border-bottom: 1px solid ${el.border};`"
          >
            <div v-for="(it, ix) in tableColumn" :key="ix" class="item">
              <div class="table-dot-flex">
                <span
                  v-if="ix == 0"
                  class="table-dot"
                  :style="`background:${el.background}`"
                ></span>

                <div
                  :class="handleTableClass(el, it)"
                  :data-type="handleTableType(el, it)"
                  :data-url="handleTableUrl(el, it)"
                  :data-item="handleTableAlert(el, it)"
                >
                  {{ handleTableData(el, it) }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>

    <c-dialog
      :show="visible"
      :companyInfo="companyInfo"
      @handleClose="visible = false"
    >
    </c-dialog>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import CDialog from '@/views/charts/components/click-dialog'
export default {
  name: 'FlexTable',

  data() {
    return {
      visible: false,
      show: false,
      companyInfo: {}
    }
  },

  props: {
    tableList: {
      type: Array,
      default: () => []
    },

    tableColumn: {
      type: Array,
      default: () => []
    },

    classOption: {
      type: Object,
      default: () => ({ autoPlay: false })
    }
  },

  methods: {
    handleTableType(el, it) {
      return el[it]?.type
    },

    handleTableUrl(el, it) {
      return el[it]?.url
    },

    handleTableAlert(el, it) {
      return JSON.stringify(el[it]?.alert)
    },

    handleItemClick(e) {
      const value = e.target.dataset
      const { item, type, url } = value
      const data = JSON.parse(item)
      if (type == 'url' && url) {
        window.open(url)
      }
      if (type == 'alert' && data) {
        this.visible = true
        this.companyInfo = data
      }
    },

    handleTableData(item, type) {
      const value = item[type]?.val
      return value ? value : item[type]
    },

    handleTableClass(item, type) {
      return item[type]?.type ? 'link' : ''
    }
  },

  components: {
    vueSeamlessScroll,
    CDialog
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
::v-deep .el-tag {
  margin: 5px;
  color: #fff;
  border-color: transparent;
  background: $main-color;
}

.flex-table {
  height: 100%;
  overflow: hidden;
  .table-dot-flex {
    display: flex;
    align-items: center;
    .table-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  .dialog-content {
    line-height: 25px;
  }

  .card {
    color: #888;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 5px;
    margin-top: 5px;
  }

  .c-name {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 600;
  }

  .title {
    color: #2e2e2e;
    font-weight: 600;
    font-size: 14px;
  }

  ul {
    li {
      display: flex;
      font-size: 16px;
      color: #c1bfcc;
      height: 42px;
      line-height: 42px;
      .item {
        flex: 1;
        -webkit-line-clamp: 1; /* 限制在一个块元素显示的文本的行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .table-header {
    display: flex;
    align-items: center;
    .header-item {
      flex: 1;
      padding: 10px 0;
      color: #c1bfcc;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .roll-table {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .link {
    color: $link-color;
    cursor: pointer;
  }
}
</style>
