<template>
  <div class="bindWeChart-main">
    <el-form>
      <el-form-item>
        <el-input v-model="phone" placeholder="请输入手机号"></el-input>
      </el-form-item>

      <el-form-item>
        <span class="sms-code" @click="getSmsCode"
          >{{ num === 0 ? '获取验证码' : `${num} s` }}
        </span>
        <el-input v-model="code" placeholder="请输入验证码"> </el-input>
      </el-form-item>

      <el-form-item class="bottom-last">
        <el-button type="primary" @click="bindUser">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { isMobile } from '@/utils/validate'
import { sendPcCode } from '@/_dtbird_api/auth'
import { loginBySmS, bingWx } from '@/api/user'
import store from '@/store'
import { ResetAuthUserMeal, ResetSystemConfig } from '@/utils/auth'
import { AppWebSockt } from '@/utils/websocket'
import { setPermission } from '@/cores/RouteAndPermission'
export default {
  name: 'BindWeChart',
  data() {
    return {
      num: 0,
      code: '',
      phone: ''
    }
  },
  computed: {
    openId() {
      return this.$store.state.plugin.modal.options?.opId
    }
  },
  methods: {
    getSmsCode() {
      if (!this.phone) {
        this.$store.dispatch('showTip', { text: '请输入手机号', type: 'error' })
        return
      }
      if (this.phone && !isMobile(this.phone)) {
        this.$store.dispatch('showTip', {
          text: '请输入正确的手机号',
          type: 'error'
        })
        return
      }
      if (this.num === 0) {
        const params = {
          mobile: this.phone.trim()
        }
        sendPcCode(params).then(res => {
          if (res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: '验证码发送成功',
              type: 'success'
            })
            this.num = 60
            const t = setInterval(() => {
              if (this.num !== 0) {
                this.num = this.num - 1
              } else {
                clearInterval(t)
              }
            }, 1000)
          } else {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'error'
            })
            this.num = 0
          }
        })
      }
    },

    bindUser() {
      const params = {
        userName: this.phone,
        smsCode: this.code
      }
      loginBySmS(params).then(r => {
        if (r.code === 60000) {
          const token = r?.data?.token
          bingWx({ openid: this.openId, token: token }).then(async res => {
            if (res.code === 60000) {
              localStorage.clear()
              sessionStorage.clear()
              const token = res.data.token
              const step = res.data.step
              const accountType = res.data.type
              const userName = res.data.followName || ''
              store.commit('setToken', token)
              await store.dispatch('changeStep', step)
              await store.dispatch('setName', userName)
              localStorage.setItem('setAccountType', accountType + '')
              localStorage.setItem('dtBird_user_step', step)
              localStorage.setItem('setName', userName)
              localStorage.setItem('userLoginTime', new Date().getTime())
              if (step != 1) {
                ResetAuthUserMeal()
              }
              ResetSystemConfig() //获取系统信息
              AppWebSockt('kick_user_offline')
              await setPermission()
              localStorage.setItem(
                'permissionObj',
                JSON.stringify(store.state.permission.permission)
              )
              localStorage.setItem(
                'menuList',
                JSON.stringify(store.state.permission.menuList)
              )
              localStorage.setItem(
                'panelList',
                JSON.stringify(store.state.permission.panelList)
              )
              this.$store.dispatch('hideModal')
              this.$router.replace('/dt-index')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bindWeChart-main {
  padding: 20px;
  box-sizing: border-box;
  .sms-code {
    width: 100px;
    height: 100%;
    text-align: center;
    display: inline-block;
    color: #0099cc;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    cursor: pointer;
    user-select: none;
  }
}
</style>

<style lang="scss">
.bindWeChart-main {
  .bottom-last {
    margin-bottom: 0 !important;
    button {
      width: 100% !important;
    }
  }
}
</style>
