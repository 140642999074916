<template>
  <div class="company-base-info">
    <div class="base-title">基础信息</div>
    <div class="base-wrap">
      <div class="base-item">
        <div class="title">法人:</div>
        <div class="base-right">{{ detail.legal_name }}</div>
      </div>
      <div class="base-item">
        <div class="title">人员规模:</div>
        <div class="base-right">{{ detail.employe_scale }}</div>
      </div>
      <div class="base-item">
        <div class="title">参保人数:</div>
        <div class="base-right">{{ detail.employe_number }}</div>
      </div>
      <div class="base-item">
        <div class="title">注册地址:</div>
        <div class="base-right">
          <span v-if="detail.isVirtual" class="virtual-address"
            >疑似虚拟注册地址</span
          >
          <span v-else>{{ detail.registered_address }}</span>
        </div>
      </div>
      <div class="base-item">
        <div class="title">营业期限:</div>
        <div class="base-right">{{ detail.period }}</div>
      </div>
      <div class="base-item">
        <div class="title">纳税人识别号:</div>
        <div class="base-right">{{ detail.company_number }}</div>
      </div>
      <div class="base-item">
        <div class="title">注册资本:</div>
        <div class="base-right">{{ detail.registered_capital }}</div>
      </div>
      <div class="base-item">
        <div class="title">经营状态:</div>
        <div class="base-right" :style="isLogOut">
          {{ detail.company_status }}
        </div>
      </div>
      <div class="base-item">
        <div class="title">公司类型:</div>
        <div class="base-right">{{ detail.company_type }}</div>
      </div>
      <div class="base-item">
        <div class="title">行业:</div>
        <div class="base-right">{{ detail.industry }}</div>
      </div>
      <div class="base-item">
        <div class="title">注册时间:</div>
        <div class="base-right">{{ detail.registered_time }}</div>
      </div>
    </div>
    <div class="last-wrap">
      <div class="last-fl">经营范围:</div>
      <div class="last-fr">{{ detail.business_scope }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PeBase',
  props: {
    detail: {
      type: Object,
      default: {}
    }
  },
  computed: {
    isLogOut() {
      return this.detail.company_status == '注销' ? 'color:#f00' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.company-base-info {
  background: #fff;
  box-sizing: border-box;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 400;
  .title {
    color: #2e2e2e;
  }
  .base-right {
    color: #8a8a8a;
    position: absolute;
    left: 100px;
  }
  .base-title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: #2e2e2e;
  }
  .base-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .base-item {
      display: flex;
      align-items: center;
      position: relative;
      width: 50%;
      margin: 8px 0;
      .virtual-address {
        color: $main-color;
      }
    }
  }
  .last-wrap {
    display: flex;
    .last-fl {
      color: #2e2e2e;
      width: 100px;
    }
    .last-fr {
      color: #8a8a8a;
      line-height: 20px;
      width: 90%;
    }
  }
}
</style>
