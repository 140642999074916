<template>
  <div class="enter-name-input">
    <div class="content">
      <draggable
        v-model="tags"
        @update="datadragEnd"
        filter=".forbid"
        handle=".move"
        :preventOnFilter="false"
        animation="300"
      >
        <template v-for="(item, index) in tags">
          <div class="drag-content">
            <el-input
              clearable
              class="input-new-tag forbid"
              placeholder="输入标签名称"
              v-model="item.name"
              style="width:80%;margin-top:10px"
            >
            </el-input>

            <div class="drag-icon">
              <svg-icon style="margin:0 10px" iconClass="sort" class="move"
                >排序</svg-icon
              >
              <svg-icon
                iconClass="close"
                class="forbid"
                @click.native="handleDelete(item, index)"
              >
                删除标签
              </svg-icon>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div slot="footer" class="footer">
      <el-button icon="el-icon-plus" type="text" @click="handleAdd"
        >添加</el-button
      >
      <div>
        <el-button @click="cancelBtn" size="mini">取消</el-button>
        <el-button type="primary" size="mini" @click="handleSure"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { isObjNull } from '@/utils/validate'
import { getTagsEdit } from '@/api/user'
import IconHover from '@/components/Peculiar/icon-hover'
import draggable from 'vuedraggable'
export default {
  name: 'InputDialog',
  components: {
    draggable,
    IconHover
  },
  data() {
    return {
      tags: [],
      groupId: '',
      isDrag: false,
      type: 'edit',
      modalOptions: {}
    }
  },
  mounted() {
    this.handleOptions()
  },
  methods: {
    handleInputChange() {
      this.$forceUpdate()
    },
    handleOptions() {
      this.modalOptions = this.$store.state.plugin.modal.options
      this.tags = this.modalOptions.row.tags
      this.groupId = this.modalOptions.row.id
      console.log(this.modalOptions, 'modalOptions123')
    },
    cancelBtn() {
      this.$store.dispatch('hideModal')
      this.isDrag = false
      this.type = 'edit'
    },
    async handleSure() {
      //编辑tag
      if (this.type == 'edit') {
        // debugger
        const obj = {
          choice: this.modalOptions.wrap.choice,
          type: this.modalOptions.wrap.type,
          tags: this.tags,
          deleteTags: []
        }
        const params = {
          tagJson: encodeURIComponent(JSON.stringify(obj))
        }
        await getTagsEdit(params)
      } else {
        //新增标签
        const flag = this.tags.some(item => !isObjNull(item.name))
        if (flag) {
          this.$message.error('输入值必填')
          return
        }
        const obj = {
          choice: this.modalOptions.wrap.choice,
          type: this.modalOptions.wrap.type,
          tags: this.tags,
          deleteTags: []
        }
        const params = {
          tagJson: encodeURIComponent(JSON.stringify(obj))
        }
        await getTagsEdit(params)
      }
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          refresh: true,
          isDrag: this.isDrag,
          tags: this.tags
        }
      })

      this.cancelBtn()
    },

    datadragEnd(evt) {
      evt.preventDefault()
      console.log('拖动前的索引 :' + evt.oldIndex)
      console.log('拖动后的索引 :' + evt.newIndex)
      this.isDrag = true
      console.log(this.tags)
    },
    handleAdd() {
      this.type = 'add'
      const list = [
        {
          groupId: this.groupId
        }
      ]
      this.tags = this.tags.concat(list)
    },
    handleDelete(data, index) {
      this.$confirm('确定要删除该标签吗?', '提示', {
        type: 'warning'
      })
        .then(async res => {
          // await checkField({ id: data.id })
          const deleteTags = []
          deleteTags.push(this.tags[index])
          this.tags.splice(index, 1)
          const obj = {
            choice: data.choice,
            type: data.type,
            tags: this.tags,
            deleteTags
          }
          const params = {
            tagJson: encodeURIComponent(JSON.stringify(obj))
          }
          await getTagsEdit(params)
          console.log(res, 'resdidi')
        })
        .catch(() => {
          console.log('cancel')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.enter-name-input {
  padding: 20px 20px 50px;
  height: 400px;
  overflow: scroll;
  position: relative;
  .footer {
    position: fixed;
    top: 460px;
    width: 550px;
    background: #fff;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tagItem {
    margin: 6px;
  }

  .drag-content {
    display: flex;
    align-items: center;
    .input-new-tag {
      width: 20%;
    }
    .drag-icon {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
  }
}
</style>
