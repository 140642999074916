var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container land-page-app" },
    [
      _c("div", { staticClass: "land-page-header" }, [
        _c("div", { staticClass: "detail-title-land" }, [
          _c(
            "span",
            {
              staticClass: "color-41CABF click",
              on: {
                click: function ($event) {
                  return _vm.goBackPage()
                },
              },
            },
            [_vm._v("营销落地页管理")]
          ),
          _vm._v(" "),
          _c("span", [_vm._v(">")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.currentName))]),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { float: "right" } }, [
          _c(
            "div",
            {
              staticClass: "search-botton",
              staticStyle: { "margin-left": "10px" },
              on: { click: _vm.previewLand },
            },
            [_vm._v("预览")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "search-botton",
              staticStyle: { "margin-left": "10px" },
              on: { click: _vm.saveLandPage },
            },
            [_vm._v("保存")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "land-page-cont" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 16 } }, [
                _c(
                  "div",
                  { staticClass: "land-page-left" },
                  [
                    _c("page-show", {
                      ref: "pageShow",
                      attrs: {
                        pageDetail: _vm.landDetail,
                        acType: _vm.acType,
                        formName: _vm.formName,
                        fontStyle: _vm.fontStyle,
                      },
                      on: {
                        savePageSuc: _vm.savePageSuc,
                        publicPageUrl: _vm.publicPageUrl,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "land-page-right" },
                  [
                    _c("edit-land", {
                      on: {
                        getUploadUrl: _vm.getUploadUrl,
                        changeFormName: _vm.changeFormName,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("qc-code", { ref: "userQcCode", attrs: { acCodeUrl: _vm.acCodeUrl } }),
      _vm._v(" "),
      _c("land-prevw", {
        ref: "userPrevw",
        attrs: { fontStyle: _vm.fontStyle, currentId: _vm.currentId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }