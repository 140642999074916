<template>
  <div class="appStore-main">
    <div class="search-service" v-dt-permission="'扩展应用-应用市场-查看'">
      <div class="top">
        <div class="left">
          <i class="el-icon-search"></i>
        </div>
        <div class="right">
          <div class="name">查云服务商（专业版）</div>
          <div class="desc">查询企业的云服务商当前信息<br />及历史信息</div>
        </div>
      </div>
      <div class="btn">
        <div @click="userSubmit('yun')">打开</div>
      </div>
    </div>
    <div
      class="search-domain"
      v-dt-permission="'扩展应用-查域名（专业版）-查看'"
    >
      <div class="top">
        <div class="left">
          <i class="el-icon-search"></i>
        </div>
        <div class="right">
          <div class="name">查域名（专业版）</div>
          <div class="desc">
            查询域名的ISP\CDN信息及<br />同企业下其他域名信息
          </div>
        </div>
      </div>
      <div class="btn">
        <div @click="userSubmit('domain')">打开</div>
      </div>
    </div>

    <div class="search-domain" v-dt-permission="'扩展应用-客群洞察-查看'">
      <div class="top">
        <div class="left">
          <i class="el-icon-odometer"></i>
        </div>
        <div class="right">
          <div class="name">客群洞察</div>
          <div class="desc">洞察客群分布规律</div>
        </div>
      </div>
      <div class="btn">
        <div @click="userSubmit('khdc')">打开</div>
      </div>
    </div>
  </div>
</template>

<script>
import step from '@/views/extendApp/dialog/step'

export default {
  name: 'AppStore',
  data() {
    return {}
  },
  watch: {
    $route: {
      handler: function(val) {
        if (val.query.code) this.showModal(2, '')
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    bindWx() {
      return this.$store.state.user.accountInfo.bindWechat
    }
  },
  methods: {
    userSubmit(type) {
      if (type === 'khdc') {
        this.$router.push('/app/pack')
      } else if (type === 'yun') {
        this.$router.push('/globalquery/query')
      } else {
        this.$router.push('/app/domain')
      }
    },

    showModal(index, title, type) {
      this.$store.dispatch('showModal', {
        view: step,
        size: '600px',
        options: {
          data: {
            step: index,
            title: title,
            type: type
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.appStore-main {
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  overflow-y: auto;
  margin: 10px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  & > div {
    width: 400px;
    height: 300px;
    border: #41cabf solid 3px;
    margin-right: 10px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
  }
  .search-service,
  .search-domain {
    padding: 20px;
    box-sizing: border-box;
    .top {
      display: flex;
      .left {
        height: 100px;
        width: 100px;
        background-color: #41cabf;
        margin-right: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        & > i {
          color: #ffffff;
          font-size: 75px;
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .name {
          font-size: 16px;
          font-weight: bolder;
          margin-bottom: 20px;
        }
        .desc {
          color: #8a8a8a;
          font-size: 12px;
        }
      }
    }
    .btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        font-size: 12px;
        height: 40px;
        width: 200px;
        border: 1px solid #41cabf;
        border-radius: 5px;
        background-color: #41cabf;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bolder;
        cursor: pointer;
      }
    }
  }
}
</style>
