var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticStyle: { position: "relative" } }, [
      _c(
        "div",
        {
          class:
            !_vm.otherParams.isReceive && _vm.otherParams.shade
              ? "module-cont-filter"
              : "",
        },
        _vm._l(_vm.headerArr, function (column, index) {
          return _c("div", { key: index, staticClass: "face-plate-cont" }, [
            _c("div", { staticClass: "info-title" }, [
              _vm._v(_vm._s(column.name) + "："),
            ]),
            _vm._v(" "),
            column.href
              ? _c(
                  "div",
                  {
                    staticClass: "c-0077CC info-content",
                    on: {
                      click: function ($event) {
                        return _vm.toShop(_vm.tableArr[0][column.href])
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.tableArr[0][column.field]))]
                )
              : _c("div", { staticClass: "info-content" }, [
                  _vm._v(_vm._s(_vm.tableArr[0][column.field] || "")),
                ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      !_vm.otherParams.isReceive && _vm.otherParams.shade
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.getLoading,
                  expression: "getLoading",
                },
              ],
              staticClass: "touch-no-receive",
            },
            [
              _c("div", { staticClass: "touch-no-cont" }, [
                _c("div", { staticClass: "touch-no-icon" }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#008CD9",
                        "text-decoration": "underline",
                        cursor: "pointer",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getReveiveKey("phone")
                        },
                      },
                    },
                    [_vm._v("领取")]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(" 以查看所有信息")]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }