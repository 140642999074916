import Cookies from 'js-cookie'
import { getAuthUserMeal } from '@/api/usercenter'
import store from '@/store'
import { getSystemConfig } from '@/api/system'
import { closeWebsocket } from '@/utils/websocket'
const TOKEN_KEY = 'dtBird_token'
const EXPIRES_IN_KEY = 'dtBird_expires_in'
// 获取token时间
const TOKEN_SAVED_AT = 'dtBird_token_saved_at'
const REFRESH_TOKEN_KEY = 'dtBird_refresh_token'
const REFRESH_EXPIRES_IN_KEY = 'dtBird_refresh_expires_in'
const CURR_USER_STEP = 'dtBird_user_step'

const setStorageItem = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch {
    Cookies.set(key, value)
  }
}

const getStorageItem = key => {
  try {
    const json = window.localStorage.getItem(key)
    const value = JSON.parse(json)
    return value
  } catch {
    return Cookies.get(key)
  }
}

const delStorageItem = key => {
  try {
    window.localStorage.removeItem(key)
  } catch {
    Cookies.remove(key)
  }
}

export const getToken = () => getStorageItem(TOKEN_KEY)
export const getExpiresIn = () => getStorageItem(EXPIRES_IN_KEY)
export const getRefreshToken = () => getStorageItem(REFRESH_TOKEN_KEY)
export const getRefreshExpiresIn = () => getStorageItem(REFRESH_EXPIRES_IN_KEY)
export const getTokenSavedAt = () => getStorageItem(TOKEN_SAVED_AT)
export const getUserStep = () => getStorageItem(CURR_USER_STEP)

export const setToken = ({ token, expiresIn }) => {
  setStorageItem(TOKEN_KEY, token)
  setStorageItem(TOKEN_SAVED_AT, new Date().getTime())
  setStorageItem(EXPIRES_IN_KEY, expiresIn * 1000)
}

export const setRefreshToken = ({ refreshToken, refreshExpiresIn }) => {
  setStorageItem(REFRESH_TOKEN_KEY, refreshToken)
  setStorageItem(REFRESH_EXPIRES_IN_KEY, refreshExpiresIn * 1000)
}

export const setUserStep = step => {
  setStorageItem(CURR_USER_STEP, step)
}

export const removeToken = () => {
  delStorageItem(TOKEN_KEY)
  delStorageItem(EXPIRES_IN_KEY)
  delStorageItem(REFRESH_TOKEN_KEY)
  delStorageItem(REFRESH_EXPIRES_IN_KEY)
  delStorageItem(TOKEN_SAVED_AT)
  delStorageItem(CURR_USER_STEP)
  closeWebsocket()
}

export const ResetAuthUserMeal = () => {
  return getAuthUserMeal().then(res => {
    if (res.code === 60000) {
      let userInfo = res.data.service
      console.log(userInfo, 'userInfo')
      userInfo.isAdmin = res.data.isAdmin
      store.dispatch('setIsAdmin', res.data.isAdmin)
      store.dispatch('setUserInfo', userInfo)
      store.dispatch('setShowGuide', 'false')
      store.dispatch('setAccSub', res.data.service.hasBase)
      store.dispatch('setAccountType', res.data.isAdmin)
      return userInfo
    }
  })
}

// 获取系统配置
export const ResetSystemConfig = () => {
  getSystemConfig({}).then(res => {
    if (res.code === 60000) {
      console.log(res.data)
      if (res.data && res.data.contantPoolWechat) {
        store.commit('update', {
          target: store.state.plugin.weChatPlugin,
          data: {
            appId: res.data.contantPoolWechat.corpId,
            secret: res.data.contantPoolWechat.agentSecret,
            agentId: res.data.contantPoolWechat.agentId
          }
        })
      }

      store.dispatch('setSysConfig', res.data)
      if (res.data.isShowSelectBook === 0) {
        localStorage.setItem('isShowSelectBook', res.data.isShowSelectBook)
      }
    }
  })
}
