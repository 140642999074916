var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dia-model",
        {
          ref: "addBook",
          attrs: {
            dialogStatus: _vm.dialogAddBookStatus,
            btnLoading: _vm.btnLoading,
          },
          on: { submitContBtn: _vm.addBookBtn },
        },
        [
          _c("div", { attrs: { slot: "modalCont" }, slot: "modalCont" }, [
            _vm.showName
              ? _c(
                  "div",
                  { staticClass: "book-list-type" },
                  [
                    _vm.showName && _vm.showMark
                      ? _c("span", [_vm._v("名称：")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "input",
                      attrs: {
                        maxlength: "100",
                        placeholder:
                          "输入名称(最多100个字符，只能输入英文、汉字、数字)",
                      },
                      on: { input: _vm.nameChange },
                      model: {
                        value: _vm.bookName,
                        callback: function ($$v) {
                          _vm.bookName = $$v
                        },
                        expression: "bookName",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showMark
              ? _c(
                  "div",
                  { staticClass: "book-list-type" },
                  [
                    _vm.showName && _vm.showMark
                      ? _c("span", [_vm._v("备注：")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "input",
                      attrs: {
                        rows: 4,
                        type: "textarea",
                        maxlength: "100",
                        placeholder: "输入备注",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.bookRemark,
                        callback: function ($$v) {
                          _vm.bookRemark = $$v
                        },
                        expression: "bookRemark",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }