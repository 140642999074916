var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-card" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right" },
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index, staticClass: "line" }, [
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
          _vm._v(" "),
          _c("div", { staticClass: "num" }, [_vm._v(_vm._s(item.value))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "icon" }, [
        _c("img", {
          attrs: { src: require("@/views/charts/theme/images/i1.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }