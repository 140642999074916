var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "booking-main" }, [
    _c("div", { staticClass: "title" }, [_vm._v("数据融合")]),
    _vm._v(" "),
    _c("div", { staticClass: "main" }, [
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.packageList, function (item) {
          return _c("div", { staticClass: "item" }, [
            _c(
              "div",
              { staticClass: "item-main-row" },
              [
                _c("svg-icon", {
                  staticClass: "icon",
                  attrs: { "icon-class": "khdc", className: "icon-active" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "desc" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.packageName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "num" }, [
                    _vm._v("共" + _vm._s(item.bookingCount) + "家企业"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn" }, [
                    item.insightDesc
                      ? _c("div", { staticClass: "btn-desc" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.insightDesc) +
                              "\n              "
                          ),
                        ])
                      : _c("div"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.bindPac(item)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }