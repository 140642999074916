<template>
  <div
    id="screenMap"
    class="screenClass"
    v-loading="mapLoading"
    ref="pointMap"
    :style="mapFull"
  >
    <div ref="info" class="map-info">
      <div>{{ infoWindowData.chartName }}</div>
      <div>{{ infoWindowData.hot }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchemaMap',
  props: {
    rankList: {
      type: Array,
      default: () => []
    },
    model: {
      type: Boolean,
      default: false
    },
    isFull: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      map: {},
      mapHeight: window.screen.height,
      mapLoading: false,
      mapMarker: {},
      infoWindow: {},
      infoWindowData: [],
      mapFull: ''
    }
  },
  watch: {
    rankList: {
      handler() {
        this.$nextTick(async () => {
          await this.initMap()
          await this.handleTheme()
        })
      },
      immediate: true,
      deep: true
    },
    model(val) {
      sessionStorage.setItem('theme', val)
      this.handleTheme(val)
    },
    isFull(val) {
      if (val) {
        this.mapFull = 'position:fixed'
      } else {
        this.mapFull = 'position:absolute'
      }
    }
  },
  methods: {
    handleTheme() {
      if (sessionStorage.theme == 'true') {
        this.map?.setMapStyle('amap://styles/dark')
      } else {
        this.map?.setMapStyle('amap://styles/normal')
      }
    },
    async initMap() {
      console.log(window.dtMap, 'initMap')
      //创建地图
      this.map = new window.dtMap.Map('screenMap', {
        resizeEnable: true,
        zoom: 4,
        mapStyle: `amap://styles/normal`,
        viewMode: '2D' //设置地图模式
      })
      await this.addMapMarker(this.map)
    },
    addMapMarker(map) {
      const that = this
      map.clearMap() // 清除地图覆盖物
      // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
      this.rankList.forEach((marker, index) => {
        const mark = new AMap.Marker({
          content: this.getContent(index),
          position: [marker.longitude, marker.latitude],
          offset: new window.dtMap.Pixel(-13, -10)
        })
        map.add(mark)
        mark.on('mouseover', () => {
          that.showInfo(marker)
        })
        mark.on('mouseout', () => {
          that.infoWindow?.close()
        })
      })
    },
    showInfo(data) {
      const el = this.$refs.info
      this.infoWindow = new AMap.InfoWindow({
        map: this.map,
        isCustom: true, //使用自定义窗体
        content: el,
        offset: new AMap.Pixel(-13, -10),
        autoMove: true
      })
      this.infoWindowData = data
      this.infoWindow.open(this.map, [data.longitude, data.latitude])
    },
    getContent() {
      return `<div class='screen-circle'>
                 <div class='screen-num'></div>
              </div>`
    }
  },
  beforeDestroy() {
    this.map.off('zoomend')
    sessionStorage.setItem('theme', false)
    this.map.destroy()
  }
}
</script>
<style lang="scss">
.screenClass {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  .screen-circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #ff8d00;
    cursor: pointer;
  }
  .screen-num {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      opacity: 1;
      background: #ff8d00;
      box-shadow: 0 0 3px #ff8d00;
      animation: scales 3s infinite;
    }
  }
  .map-info {
    background: #fff;
    color: #1f6cdde6;
    border-radius: 10px;
    font-size: 12px;
    padding: 10px;
    line-height: 20px;
  }

  @keyframes scales {
    0% {
      transform: scale(1);
    }
    50%,
    75% {
      transform: scale(1.5);
    }
    78%,
    100% {
      opacity: 0;
    }
  }
}
</style>
