var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "CustomerReceiver-main" }, [
    _c(
      "div",
      { staticClass: "c-r-main" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              "label-width": "auto",
              model: _vm.formData,
              rules: _vm.rules,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "选择发送对象", prop: "customer" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { filterable: "", placeholder: "请选择发送对象" },
                    model: {
                      value: _vm.formData.customer,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "customer", $$v)
                      },
                      expression: "formData.customer",
                    },
                  },
                  _vm._l(_vm.list, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
          [_vm._v("取消")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.confirmBtn },
          },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }