<template>
  <div class="h-div">
    <div class="title">
      <div>客户雷达</div>
      <div class="bar">
        <!--          <el-tooltip-->
        <!--            :visible-arrow="false"-->
        <!--            popper-class="popper"-->
        <!--            effect="dark"-->
        <!--            content="按时间"-->
        <!--            placement="right-start">-->
        <!--            <div class="left-bar" @click="type=0">-->
        <!--              <i class="icon-liebiaoliulanmoshi iconfont ri" :class="type === 0 ? 'b-active':''"></i>-->
        <!--            </div>-->
        <!--          </el-tooltip>-->

        <!--          <el-tooltip-->
        <!--            :visible-arrow="false"-->
        <!--            popper-class="popper"-->
        <!--            effect="dark"-->
        <!--            content="按访客"-->
        <!--            placement="right-start">-->
        <!--            <div class="right-bar" @click="type=1">-->
        <!--              <i class="icon-qiapianliulanmoshi iconfont le" :class="type !== 0 ? 'b-active':''"></i>-->
        <!--            </div>-->
        <!--          </el-tooltip>-->

        <div class="left-bar" @click="type = 0" title="按时间">
          <i
            class="icon-liebiaoliulanmoshi iconfont ri"
            :class="type === 0 ? 'b-active' : ''"
          ></i>
        </div>
        <div class="right-bar" @click="type = 1" title="按访客">
          <i
            class="icon-qiapianliulanmoshi iconfont le"
            :class="type !== 0 ? 'b-active' : ''"
          ></i>
        </div>
      </div>
    </div>
    <div class="content-p" v-loading="loading">
      <div class="filter">
        <span class="fk"
          >总访客 <span>{{ count }}</span>
        </span>
      </div>
      <div v-if="type === 0">
        <el-scrollbar class="content" v-show="recordObj && recordObj.length">
          <div class="line" ref="line">
            <template v-for="(item, index) in recordObj">
              <div class="week" :key="index + 'a'">
                <span>{{ item.day }}</span
                ><span>{{ item.week }}</span>
              </div>
              <el-timeline :key="index + 'b'">
                <el-timeline-item
                  size="normal"
                  v-for="(activity, index) in formatFollows(item.follows)"
                  :key="index"
                  :color="activity.createdAt === nowTime ? 'red' : ''"
                >
                  <div class="con-a">
                    <div class="photo" @click="showDetail(activity.contactId)">
                      <el-avatar :src="activity.imagesUrl"></el-avatar>
                      <p>{{ activity.visitsNumber }}</p>
                    </div>
                    <div
                      v-html="
                        siteId ? activity.opSiteDetails : activity.opDetails
                      "
                      class="time-text"
                    ></div>
                  </div>
                  <div class="time">
                    {{ activity.time }}
                  </div>
                </el-timeline-item>
              </el-timeline>
            </template>
          </div>
        </el-scrollbar>
        <div style="display: flex;justify-content: flex-end">
          <el-pagination
            v-show="recordObj && recordObj.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo.pageNum"
            :page-sizes="[10, 20, 30, 50, 100]"
            background
            :pager-count="5"
            layout="total, sizes, prev, pager, next"
            :total="pageCount"
          >
          </el-pagination>
        </div>
        <div class="content empty-tip" v-show="recordObj && !recordObj.length">
          暂无数据
        </div>
      </div>
      <div v-else style="calc(100% - 50px)">
        <div class="content1" v-if="recordObj2 && recordObj2.length">
          <dt-table
            :v-if="dtKey"
            :page-num="pageNo.pageNum"
            :page-size="[10, 20, 30, 50, 100]"
            :is-select="false"
            :table-data="recordObj2"
            :is-show-page="true"
            :is-add-earn-field="false"
            :data-total="pageCount"
            :is-count-height="false"
            hide-border
            @sizeChange="handleSizeChange"
            @currentChange="handleCurrentChange"
            @sortChange="sortChange"
            :column-list="column"
          >
            <template v-slot:imagesUrlRow="row">
              <div
                @click="showDetail(row.scope.data.contactId)"
                style="cursor: pointer"
              >
                <el-avatar :src="row.scope.data.imagesUrl"></el-avatar>
              </div>
              <div style="width: 40px;text-align: center">
                {{ row.scope.data.nickName }}
              </div>
            </template>
            <template v-slot:imagesUrlHeader="row">
              <span></span>
            </template>
          </dt-table>
        </div>
        <div class="content empty-tip" v-show="recordObj && !recordObj.length">
          暂无数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { recordRadar } from '@/api/user'
import { customerDt } from '@/api/pool'
import DtTable from '@/components/element/DtTable'
import contactDetail from '@/views/contactBreed/dialog/contactDetail'

export default {
  name: 'viewRecord',
  components: {
    DtTable
  },
  data() {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day
    let now = `${year}-${month}-${day}`
    return {
      pageNo: {
        pageSize: 10,
        pageNum: 1
      },
      dtKey: false,
      count: 0,
      pageCount: 0,
      recordObj: null,
      recordObj2: null,
      filter: false,
      loading: false,
      nowTime: now,
      type: 0,
      column: [
        {
          name: '用户',
          field: 'imagesUrl'
        },
        {
          name: '总访问',
          field: 'allCount',
          sort: 'customer'
        },
        {
          name: '近30天访问',
          field: 'thirtyRecord',
          sort: 'customer'
        },
        {
          name: '近7天访问',
          field: 'sevenRecord',
          sort: 'customer'
        },
        {
          name: '最近访问',
          field: 'recentTime'
        }
      ],
      allCount: null,
      thirtyRecord: null,
      sevenRecord: null
    }
  },
  props: {
    // 素材id
    viewId: {
      type: [String, Number],
      default: ''
    },

    // 微站id
    siteId: {
      type: [String, Number],
      default: ''
    }
  },

  watch: {
    type: {
      handler: function(val) {
        this.pageNo.pageNum = 1
        this.pageNo.pageSize = 10
        this.getRecord()
      },
      immediate: true
    }
  },

  methods: {
    sortChange(e) {
      console.log(e)
      let order = null
      if (e.order === 'ascending') {
        order = 1
      }
      if (e.order === 'descending') {
        order = 0
      }
      this[e.prop] = order
      if (e.prop === 'allCount') {
        this.thirtyRecord = null
        this.sevenRecord = null
      }
      if (e.prop === 'thirtyRecord') {
        this.sevenRecord = null
        this.allCount = null
      }
      if (e.prop === 'sevenRecord') {
        this.allCount = null
        this.thirtyRecord = null
      }
      this.getRecord()
    },

    handleSizeChange(e) {
      this.pageNo.pageSize = e
      this.getRecord()
    },

    handleCurrentChange(e) {
      this.pageNo.pageNum = e
      this.getRecord()
    },

    dtSort(data) {},

    formatFollows(data) {
      return data
      // if(data instanceof Array && data.length){
      //   return data.filter(item=>item.contactId)
      // }
    },

    getRecord() {
      this.loading = true
      let params = {
        // videoStayTime: this.filter ? '5' : '',
        type: this.type,
        pageNo: this.pageNo.pageNum,
        pageSize: this.pageNo.pageSize,
        AllCount: this.allCount,
        thirtyRecord: this.thirtyRecord,
        sevenRecord: this.sevenRecord
      }
      if (this.viewId) {
        params['id'] = parseInt(this.viewId)
      }
      if (this.siteId) {
        params['siteId'] = parseInt(this.siteId)
      }
      recordRadar(Object.assign({}, params)).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.count = res.data.count
          this.pageCount = res.data.pageCount
          this.resetScroll()
          // this.type === 0 ? this.recordObj = res.data.record : this.recordObj2 = this.sortRecordObj(res.data.follows) ;
          this.type === 0
            ? (this.recordObj = res.data.record)
            : (this.recordObj2 = res.data.follows)
        } else {
          this.type === 0 ? (this.recordObj = []) : (this.recordObj2 = [])
        }
      })
    },

    sortRecordObj(data) {
      console.log(data)
      if (data instanceof Array && data.length) {
        data.forEach(item => {
          item['_recentTime'] = this.formatTime(item.recentTime.split('前')[0])
        })

        data.sort((a, b) => {
          return a._recentTime - b._recentTime
        })

        return data
      }
      return []
    },

    formatTime(hTime) {
      if (hTime.indexOf('年') > -1) {
        let timeNum = parseInt(hTime.split('年')[0])
        return 365 * 24 * 60 * 60 * timeNum // 返回秒数
      }

      if (hTime.indexOf('月') > -1) {
        let timeNum = parseInt(hTime.split('月')[0])
        return 30 * 24 * 60 * 60 * timeNum // 返回秒数
      }

      //格式化天
      if (hTime.indexOf('天') > -1) {
        let timeNum = parseInt(hTime.split('天')[0])
        return 24 * 60 * 60 * timeNum // 返回秒数
      }

      if (hTime.indexOf('小时') > -1) {
        let timeNum = parseInt(hTime.split('小时')[0])
        return 60 * 60 * timeNum // 返回秒数
      }

      if (hTime.indexOf('分钟') > -1) {
        let timeNum = parseInt(hTime.split('分钟')[0])
        return 60 * timeNum // 返回秒数
      }

      if (hTime.indexOf('秒') > -1) {
        return parseInt(hTime.split('秒')[0])
      }
    },

    resetScroll() {
      const ref = this.$refs['line']
      if (ref) {
        ref.scrollTo(0, 0)
      }
    },

    // 获取联系人详情
    getCustomerDetail(id, fn) {
      this.loading = true
      customerDt({ id: id, pageId: 3002 }).then(res => {
        this.loading = false
        if (res.code === 60000) {
          const data = res.data.info
          typeof fn === 'function' ? fn(data) : ''
        }
      })
    },

    showDetail(id) {
      if (!id) {
        return
      }
      this.getCustomerDetail(id, res => {
        this.$store.dispatch('showModal', {
          title: '联系人详情',
          view: contactDetail,
          size: '700px',
          options: {
            contactDetail: res
          },
          isChild: true,
          type: 'drawer',
          onClose: res => {}
        })
      })
    }
  }
}
</script>

<style scoped>
@import '//at.alicdn.com/t/font_2061443_mfv8cbq888r.css';
.h-div {
  height: 100%;
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
}
.fk {
  font-size: 12px;
  margin-right: 5px;
}
.bar i {
  font-size: 16px;
  cursor: pointer;
  color: #8a8a8a;
}
.bar {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}
.bar::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.left-bar {
  margin-top: 3px;
  margin-left: 5px;
  padding-right: 4px;
  box-sizing: border-box;
}
.right-bar {
  margin-right: 5px;
  margin-bottom: 3px;
  margin-top: 3px;
  padding-left: 4px;
  box-sizing: border-box;
}
.b-active {
  color: #333 !important;
}
.title {
  height: 50px;
  width: 100%;
  padding: 10px 25px 0 25px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.content {
  width: 100%;
  height: calc(100vh - 191px);
  overflow-y: auto;
}
.content1 {
  width: 100%;
  height: calc(100vh - 150px);
  overflow-y: auto;
}
.content ::v-deep .el-scrollbar__wrap {
  overflow-x: hidden !important;
}
.content-p {
  width: 100%;
  height: calc(100% - 40px);
}
.empty-tip {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.3);
  font-size: 15px;
}
.filter {
  height: 50px;
  width: 100%;
  padding-left: 25px;
  box-sizing: border-box;
}
.filter-tip {
  color: #999;
  font-size: 12px;
}
.line {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 100px;
  box-sizing: border-box;
}
.line ::v-deep .el-timeline-item__node--normal {
  height: 8px;
  width: 8px;
  transform: translateX(2px);
}
.time-text {
  font-size: 12px;
  color: #999999;
  line-height: 20px;
  max-width: 248px;
}

.week {
  padding-left: 28px;
  margin-bottom: 8px;
  box-sizing: border-box;
  color: #333;
  font-size: 13px;
  position: relative;
}
.week span:first-child {
  margin-right: 10px;
}
.week span:last-child {
  position: absolute;
  left: -70px;
}
.time {
  color: #999;
  font-size: 14px;
  position: absolute;
  transform: translate(-100px, 0);
  top: 0;
}
.con-a {
  display: flex;
  height: auto;
  align-items: center;
}
.photo {
  text-align: center;
  width: 80px;
  cursor: pointer;
}
.photo p {
  max-width: 80px;
  text-align: center;
  word-wrap: break-word;
  color: #999999;
  font-size: 12px;
  margin-top: 3px;
}
.avatar-dt {
  display: flex;
  justify-content: left;
  cursor: pointer;
}
.sort {
  cursor: pointer;
}
</style>
