var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-over-sea" },
    [
      _vm.showList
        ? _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.list, function (item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c("div", { staticClass: "classify" }, [
                  _c("span", [_vm._v(" " + _vm._s(item.tagName))]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "共" + _vm._s(_vm.totalCountry(item)) + "个国家和地区"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "child-wrap" },
                  _vm._l(item.list, function (it, idx) {
                    return _c(
                      "div",
                      { key: idx, staticClass: "child" },
                      [
                        it.logo
                          ? _c("img", { attrs: { src: it.logo } })
                          : _c("Icon", {
                              attrs: {
                                peClass: "el-icon-picture-outline-round",
                              },
                            }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(it.countryName))]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            }),
            0
          )
        : _c("NoData"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }