import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

// 新建联系人
export const poolContactCreate = async params => {
  const api = API('poolContactCreate')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 联系人列表
export const poolContactList = async params => {
  const api = API('poolContactList')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 转成客户
export const poolContactTransform = async params => {
  const api = API('poolContactTransform')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 删除联系人
export const poolContactDelete = async params => {
  const api = API('poolContactDelete')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 编辑联系人
export const poolContactUpdate = async params => {
  const api = API('poolContactUpdate')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 校验企业微信参数
export const weChatCheck = async params => {
  const api = API('weChatCheck')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 导入微信联系人
export const addWeChatContact = async params => {
  const api = API('addWeChatContact')
  return await BASE_REQUEST({ api: api, opt: params })
}

//获取联系人详情
export const customerDt = async params => {
  const api = API('customerDt')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const companyList = async params => {
  const api = API('companyList')
  return await BASE_REQUEST({ api: api, opt: params })
}
//高级筛选
export const getBreedHeiOption = async params => {
  const api = API('GETBREEDHEIOPTION')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getJmPhone = async params => {
  const api = API('getJmPhone')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const batchPool = async params => {
  const api = API('batchPool')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const createOrEditJcl = async params => {
  const api = API('createOrEditJcl')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const delJcl = async params => {
  const api = API('delJcl')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const jclList = async params => {
  const api = API('jclList')
  return await BASE_REQUEST({ api: api, opt: params })
}
