var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("iframe", {
      ref: "doc",
      staticStyle: { width: "100vw", height: "100vh" },
      attrs: {
        border: "0",
        frameborder: "none",
        id: "iframe",
        src: "https://open.dtbird.cn/",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }