<template>
  <div class="chain-radar-page">
    <div class="search-schema">
      <el-input
        placeholder="行业关键字搜素"
        @clear="handleClear"
        clearable
        v-model="searchWords"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button
      ></el-input>
    </div>
    <PeScroll :scrollLoading="mainLoading" :isScrollTop="isScrollTop">
      <div v-if="brandList.length" class="list">
        <div class="item" v-for="(item, index) in brandList" :key="index">
          <div class="top">
            <div class="company-name">{{ item.brandName }}</div>
            <div class="top-fr">
              <el-button type="primary" size="mini" @click="handleScreen(item)"
                ><i
                  class="el-icon-full-screen el-icon--left"
                />数据大屏</el-button
              >
            </div>
          </div>
          <div class="tag-wrap">
            <div class="tag-fl">
              <img
                v-if="item.brandLogo"
                :src="item.brandLogo"
                class="tag-img"
              />
              <Icon v-else type="img" peClass="default" class="tag-img" />
            </div>
            <div class="tag-fr">
              <div class="num-list">
                <template v-for="(it, idx) in item.countList">
                  <div class="num-item" v-if="it.show" :key="idx">
                    <div class="num-item-name">{{ it.name }}</div>
                    <div class="num-item-value">
                      <span class="count">{{ it.count }}</span>
                      <span class="unit"> {{ it.unit }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoData v-else />
    </PeScroll>
  </div>
</template>

<script>
import { getChainRadarList } from '@/api/module'
import PeScroll from '@/components/Peculiar/scroll'
import { enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'BrandRadarPage',
  data() {
    return {
      searchWords: '',
      isScrollTop: false,
      brandList: [],
      currentIndex: 0,
      count: 0,
      currentBrand: '连锁',
      tabList: [],
      pePageNo: 1,
      pePageTotal: 0,
      mainLoading: false
    }
  },
  components: {
    PeScroll
  },
  mounted() {
    this.$bus.$on('handleBrandChange', value => {
      sessionStorage.setItem('currentBrand', value)
      this.currentBrand = value
      // this.pePageNo = 1
      this.brandList = []
      this.getListData(false)
    })
    this.getListData(false)
  },
  methods: {
    handleScreen(item) {
      this.$router.push({
        name: 'radarScreen',
        query: {
          brandName: item.brandName,
          brandId: item.id,
          companyUuid: item.companyUuid
        }
      })
    },
    handleSearch() {
      this.pePageNo = 1
      this.getListData(false)
    },
    handleClear() {
      this.pePageNo = 1
      this.searchWords = ''
      this.getListData(false)
    },

    async getListData(flag) {
      this.mainLoading = true
      const { data } = await getChainRadarList({
        brandName: this.searchWords,
        companyTag: this.currentBrand
      })
      if (flag) {
        this.brandList = this.brandList.concat(data?.datas)
      } else {
        this.brandList = data
      }

      this.brandList.map(item => {
        item.countList = [
          {
            name: '门店总数',
            count: item.countDisplay,
            show: item.count > 0
          },
          {
            name: '直营门店数',
            count: item.directlyCountDisplay,
            show: item.directlyCount > 0
          },
          {
            name: '加盟门店数',
            count: item.joinCountDisplay,
            show: item.joinCount > 0
          },
          {
            name: '覆盖城市数',
            count: item.cityCountDisplay,
            show: item.cityCount > 0
          },
          {
            name: '今年新开店数',
            count: item.openCountDisplay,
            show: item.openCount > 0
          },
          {
            name: '今年关店数',
            count: item.closeCountDisplay,
            show: item.closeCount > 0
          },
          {
            name: '同比去年净增门店数',
            count: item.increaseCountDisplay,
            show: item.increaseCount > 0
          },
          {
            name: '营收',
            count: item.income,
            show: item.income > 0,
            unit: item.incomeUnit
          },
          {
            name: '坪效',
            count: item.flatRate,
            show: item.flatRate > 0,
            unit: '元/平米'
          },

          {
            name: '会员数量',
            count: item.memberCountDisplay,
            show: item.memberCount > 0,
            unit: item.memberCountUnit
          },
          {
            name: '年度净利润率',
            count: item.netProfitYear,
            show: item.netProfitYear > 0,
            unit: '%'
          },
          {
            name: '年度毛利润率',
            count: item.grossProfitYear,
            show: item.grossProfitYear > 0,
            unit: '%'
          },
          {
            name: '人均消费',
            count: item.perConsumption,
            show: item.perConsumption > 0,
            unit: '元'
          },
          {
            name: '翻台率',
            count: item.returnRate,
            show: item.returnRate > 0,
            unit: '次/天'
          }
        ]
      })
      this.mainLoading = false
    },

    handleDetail(data) {
      const list = []
      list.push(data)
      enterCompanyDetail(data, list)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.chain-radar-page {
  .search-schema {
    display: flex;
    margin-bottom: 10px;
    ::v-deep .el-input {
      width: 30%;
    }
  }
  ::v-deep .el-scrollbar {
    position: absolute;
    left: 10px;
    top: 60px;
    right: 5px;
    bottom: 0;
  }

  .item {
    border: 2px solid #f1f1f1;
    padding: 10px;
    background: #fff;
    &:not(:first-child) {
      margin: 10px 0;
    }
    &:hover {
      border: 2px solid $main-color;
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .company-name {
        font-size: 20px;
        font-weight: bold;
      }
      .top-fr {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          color: $main-color;
        }
        ::v-deep .pe-icon {
          color: $main-color;
          margin-left: 10px;
        }
      }

      .name {
        font-weight: 700;
        font-style: normal;
        font-size: 20px;
        color: #000;
      }
    }
    .tag-wrap {
      display: flex;
      .tag-img {
        width: 180px;
        height: 180px;
      }
      .tag-fr {
        margin-left: 30px;
      }
    }

    .desc {
      font-size: 13px;
      margin-top: 10px;
    }
    .num-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: -30px;
      .num-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        margin: 10px 30px;
        &:not(:last-child) ::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -30px;
          width: 2px;
          height: 30px;
          background: #d8d8d8;
        }

        .num-item-name {
          font-size: 16px;
          font-weight: 500;
        }
        .num-item-value {
          display: flex;
          margin-top: 5px;
          .count {
            font-size: 30px;
          }
          .unit {
            font-size: 16px;
            font-weight: 400;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
