var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-no-data" },
    [
      _c("el-empty", {
        attrs: {
          description: "暂无数据",
          image: _vm.dataImg,
          "image-size": 20,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }