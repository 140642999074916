var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "targetSetting-main" }, [
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "DtScroll",
          { attrs: { margin: 10 } },
          [
            _c(
              "el-table",
              {
                staticStyle: { "margin-top": "10px" },
                attrs: { data: _vm.list, border: "", size: "small" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "控件类型",
                    "header-align": "center",
                    prop: "type",
                    width: "150",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                placeholder: "请选择控件类型",
                              },
                              model: {
                                value: scope.row.type,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "type", $$v)
                                },
                                expression: "scope.row.type",
                              },
                            },
                            _vm._l(_vm.typeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "标题",
                    "header-align": "center",
                    prop: "name",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              clearable: "",
                              placeholder: "请输入标题",
                            },
                            model: {
                              value: scope.row.name,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "name", $$v)
                              },
                              expression: "scope.row.name",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "初始值",
                    "header-align": "center",
                    prop: "initVal",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type !== 2
                            ? _c("el-input", {
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder:
                                    scope.row.type === 1
                                      ? "请输入初始值（多个逗号分割）"
                                      : "请输入初始值",
                                },
                                model: {
                                  value: scope.row.initVal,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "initVal", $$v)
                                  },
                                  expression: "scope.row.initVal",
                                },
                              })
                            : _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: "mini",
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                },
                                model: {
                                  value: scope.row.initVal,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "initVal", $$v)
                                  },
                                  expression: "scope.row.initVal",
                                },
                              }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { align: "center", width: "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "del",
                              on: {
                                click: function ($event) {
                                  return _vm.delTarget(scope.row, scope.$index)
                                },
                              },
                            },
                            [_vm._v("\n                删除\n              ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "add-icon" }, [
      _c("span", { on: { click: _vm.addTarget } }, [
        _vm._v("\n      +新增\n      "),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.cancelClick } },
          [_vm._v("取消")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.confirmClick },
          },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }