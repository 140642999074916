<template>
  <div class="body">
    <div
      slot="modalCont"
      style="min-height:300px;padding-top:20px;padding-bottom: 40px;"
    >
      <div
        class="global-input-btn auto-complate-cont"
        style="padding-left:50px;"
      >
        <el-input
          v-model="searchCompanyName"
          autofocus
          placeholder="输入公司名称查询"
        ></el-input>
        <span @click="searchCompanyList" class="global-search-btn"></span>
      </div>
      <el-table
        :data="tableData"
        max-height="400"
        empty-text="查询无结果"
        style="width: 100%;margin-top: 30px;"
      >
        <el-table-column
          prop="companyName"
          label="公司名称"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createdAt"
          align="center"
          label="创建时间"
        ></el-table-column>
        <el-table-column
          prop="followName"
          align="center"
          label="负责人"
        ></el-table-column>
      </el-table>
      <div
        style="color:#7f7f7f;margin:40px 20px 20px 20px;"
        v-if="tableData.length != 0"
      >
        注：查重结果最多显示10条
      </div>
    </div>
  </div>
</template>

<script>
import { getRepeatCompany } from '@/api/user'
export default {
  name: 'searchCompanyRep',
  data() {
    return {
      searchCompanyModel: {},
      searchCompanyName: '',
      tableData: []
    }
  },

  props: ['name'],

  mounted() {
    this.searchCompanyName = this.name
    if (this.name) {
      this.searchCompanyList()
    }
  },

  methods: {
    searchCompanyList() {
      if (!this.searchCompanyName) {
        this.$store.dispatch('showTip', {
          text: '请输入公司名称',
          type: 'warning'
        })
        return
      }
      const params = {
        companyName: this.searchCompanyName,
        pageId: 2001
      }
      getRepeatCompany(params).then(res => {
        if (res.code === 60000) {
          this.tableData = res.data
        }
      })
    }
  }
}
</script>

<style scoped>
.body {
  width: 100%;
  height: auto;
}
@import '../../../styles/threadpx.scss';
.show_collent_tip {
  margin-right: 10px;
}
.show_collent_tip ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #41cabf;
  border-color: #41cabf;
}
.show_collent_tip ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #41cabf;
}
.searchTop ::v-deep .el-tabs__header {
  margin: 0;
  background: #fff;
}

.searchTop ::v-deep .el-tabs__nav-wrap {
  margin-left: 20px;
}

.searchTop ::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
.searchTop ::v-deep .el-tabs__active-bar {
  background-color: #78d9d1;
}
.searchTop ::v-deep .el-tabs__item.is-active {
  color: #78d9d1;
}
.searchTop ::v-deep .el-tabs__item:hover {
  color: #78d9d1;
}
.global-input-btn {
  width: 692px;
  height: 50px;
  margin: 0 auto;
  font-size: 0;
}
.global-input-btn ::v-deep .el-input {
  width: 70%;
  vertical-align: top;
}
.global-input-btn ::v-deep .el-input__inner {
  border-radius: 0;
  height: 50px;
  display: inline-block;
}
.global-input-btn ::v-deep .el-input__inner:focus {
  border-color: #41cabf;
}
.global-search-btn {
  width: 90px;
  height: 50px;
  background: #78d9d1;
  display: inline-block;
  border: 1px solid #78d9d1;
  position: relative;
  cursor: pointer;
}
.global-search-btn::after {
  content: '';
  position: absolute;
  height: 40px;
  width: 40px;
  top: 5px;
  left: 25px;
  background: url('../../../assets/search-btn.png') no-repeat;
  background-size: 100% 100%;
}
.pond-form-had {
  color: red;
  position: absolute;
  top: 4px;
  left: -110px;
}
.cus-pond-cont {
  height: 50px;
  line-height: 50px;
  float: left;
  width: 450px;
}
.pond-tab-btn {
  width: 130px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  color: #999999;
}
.pond-tab-active {
  color: #1abc9c;
  border-bottom: 2px solid #1abc9c;
}
.search-botton {
  width: 70px;
  height: 30px;
  float: left;
  position: relative;
  top: 10px;
  margin-left: 20px;
  background-color: #78d9d1;
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
.search-company-botton {
  width: 130px;
  height: 40px;
  margin-top: 10px;
  background-color: #78d9d1;
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
.pond-tab-right {
  float: right;
}
.search-input-cla .input {
  width: 200px;
  height: 30px;
  float: left;
  position: relative;
  top: 10px;
}
.el-dialog .hight-cont-title {
  width: 100%;
  height: 66px;
  border-bottom: 1px solid #d8d8d8;
  position: relative;
  font-size: 14px;
  line-height: 80px;
  top: -100px;
  color: #2e2e2e;
  padding-left: 30px;
  margin-top: 30px;
}
.footer-right-cont {
  height: 100%;
  float: left;
  line-height: 40px;
  margin-top: 15px;
}
.add_tag_icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../../assets/add_tag.png') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  margin-bottom: 20px;
}
.btn_title_upload span {
  display: inline-block;
  margin-left: 1px;
}
.down_load_demo {
  position: absolute;
  width: 150px;
  top: 0;
  right: 0;
}
.down_load_title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.down_load_xlsx {
  margin: 20px 0;
  text-align: center;
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.down_load_txt {
  margin: 20px 0;
  text-align: center;
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.upload_cont_out {
  position: relative;
}
.upload_cont_out ::v-deep .el-upload-dragger {
  border: 1px dashed rgba(65, 202, 191, 1) !important;
}
.list-material {
  padding: 0;
}
.list-material ::v-deep label {
  text-align: left;
}
.list-material ::v-deep label::before {
  content: none;
}
.list-material li {
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 29px;
  padding-right: 50px;
  border-bottom: 1px solid rgba(216, 216, 216, 1);
}
.list-material li:last-child {
  margin-bottom: 41px;
}
.list-material li a {
  color: #0099cc;
  cursor: pointer;
}
.list-material ::v-deep .el-radio.is-checked .el-radio__label {
  color: #606266 !important;
}
.list-material ::v-deep .el-radio.is-checked .el-radio__inner {
  border-color: #0099cc;
  background: #0099cc;
}
.hover-tip-icon {
  position: absolute;
  display: inline-block;
  width: 60px;
  text-align: center;
  height: 30px;
  border: 1px solid #979797;
  border-radius: 5px;
  left: 0px;
  top: -40px;
  color: #979797;
  line-height: 28px;
  font-size: 12px;
  background: white;
}

.point {
  color: #0099cc;
  cursor: pointer;
}

.h-body {
  min-height: calc(100% - 180px);
  height: auto;
  background-color: #fff;
}
.page {
  position: absolute;
  bottom: 10px;
  right: 25px;
  background-color: #fff;
}
.page-div {
  margin-bottom: 0 !important;
}
.set-position {
  right: -120px !important;
}
</style>
