<template>
  <div class="booking-main">
    <div class="title">数据融合</div>
    <div class="main">
      <div class="list">
        <div class="item" v-for="item in packageList">
          <div class="item-main-row">
            <svg-icon
              icon-class="khdc"
              className="icon-active"
              class="icon"
            ></svg-icon>
            <div class="desc">
              <div class="name">{{ item.packageName }}</div>
              <div class="num">共{{ item.bookingCount }}家企业</div>
              <div class="btn">
                <div v-if="item.insightDesc" class="btn-desc">
                  {{ item.insightDesc }}
                </div>
                <div v-else></div>
                <div @click="bindPac(item)">编辑</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bindPackage from '@/views/insight/model/dialog/bindPackage'
import { package_list } from '@/api/thread'
export default {
  name: 'booking',
  data() {
    return {
      packageList: []
    }
  },
  mounted() {
    this.getPackageList()
  },
  methods: {
    getPackageList() {
      package_list().then(res => {
        if (res.code === 60000) {
          this.packageList = res?.data || []
        } else {
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'error'
          })
        }
      })
    },

    bindPac(item) {
      this.$store.dispatch('showModal', {
        view: bindPackage,
        title: '选择画像分类',
        size: '600px',
        options: {
          pid: item.id
        },
        onClose: res => {
          console.log(res)
          if (res.refresh) this.getPackageList()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.booking-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  .title {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-left: 10px;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    &::after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.1);
      transform: scaleY(0.5);
    }
  }
  .main {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
    margin-top: 10px;
    .list {
      background-color: #fff;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      & > .item:last-child {
        margin-right: 20px !important;
      }
      .item {
        display: inline-block;
        width: 30%;
        height: 100px;
        margin: 10px;
        border: 1px solid $main-color;
        .item-main-row {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          padding: 10px;
          .icon {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 35px;
            color: rgba(0, 0, 0, 0.1);
          }
          .desc {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            position: relative;
            margin-left: 10px;
            .name {
              width: 100%;
              font-size: 16px;
              font-weight: bolder;
              white-space: pre-wrap;
              word-break: break-word;
            }
            .num {
              font-size: 12px;
              font-weight: bolder;
              color: rgba(0, 0, 0, 0.3);
              margin: 5px 0;
            }
            .btn {
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              width: 100%;
              padding-right: 10px;
              box-sizing: border-box;
              & > .btn-desc {
                background-color: $main-color;
                color: #ffffff;
                padding: 4px;
                line-height: 12px;
                box-sizing: border-box;
                font-size: 12px;
              }
              & > div:last-child {
                color: $main-color;
                cursor: pointer;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
