<template>
  <div class="index-main">
    <div class="btn-list">
      <div class="input-row">
        <el-input
          style="width: 200px"
          v-model="word"
          size="mini"
          placeholder="输入公司名称查询"
        ></el-input>
        <div class="btn-row">
          <el-button type="primary" size="mini" @click="searchMonitorLog"
            >查询</el-button
          >
          <el-button size="mini" @click="reset">重置</el-button>
        </div>
      </div>
    </div>
    <div class="table" v-loading="loading">
      <el-table
        :key="refreshKey"
        class="table-dt"
        :data="tableData"
        @sort-change="sortChange"
        border
        stripe
        style="width: 100%; height: 100%; font-size: 12px;background-color:#fff;"
      >
        <el-table-column
          show-tooltip-when-overflow
          v-for="item in columnList"
          :prop="item.field"
          :label="item.name"
          :align="item.field === 'companyName' ? 'left' : 'center'"
          :sortable="item.type === 'sort' ? 'custom' : false"
        >
          <template slot-scope="scope">
            <companyLink
              package-id="0"
              :company-uuid="scope.row['companyUuid']"
              :company-name="scope.row['companyName']"
              v-if="item.field === 'companyName'"
            />
            <span v-else>
              {{ scope.row[item.field] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page page-div page-1">
      <footer-page
        @handleCurrentChange="handleCurrentChange"
        @goNumPage="goNumPage"
        @changePageSize="changePageSize"
        :pageStatus="pageStatus"
      ></footer-page>
    </div>
  </div>
</template>

<script>
import FooterPage from '@/components/Footerpage/index'
import { monitorLog } from '@/api/thread'
import companyLink from '@/components/companyLink/companyLink'

export default {
  name: 'MonitorLog',
  components: {
    FooterPage,
    companyLink
  },
  data() {
    return {
      word: '',
      loading: false,
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: null
      },
      sort: '',
      sortOrder: '',

      tableData: [],
      columnList: [],
      refreshKey: 0
    }
  },

  beforeDestroy() {},
  mounted() {
    let cache = sessionStorage.getItem('monitorLog')
    if (cache) {
      cache = JSON.parse(cache)
      this.word = cache.word || ''
      this.sort = cache.sort || ''
      this.sortOrder = cache.sortOrder || ''
      this.pageStatus.pageNo = cache.pageNo
      this.pageStatus.pageSize = cache.pageSize
    }
    this.getMonitorLog()
  },
  methods: {
    sortChange(data) {
      const field = data.prop
      const order = data.order
      const map = {
        ascending: 'asc',
        descending: 'desc'
      }
      if (order) {
        this.sort = field
        this.sortOrder = map[order]
      } else {
        this.sort = ''
        this.sortOrder = ''
      }
      this.getMonitorLog()
    },

    reset() {
      this.word = ''
      this.sort = ''
      this.sortOrder = ''
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      this.getMonitorLog()
      this.refreshKey += 1
    },

    searchMonitorLog() {
      if (!this.word) {
        this.$store.dispatch('showTip', {
          text: '请输入公司名称查询',
          type: 'warning'
        })
        return
      }
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      this.getMonitorLog()
    },

    setCache(params) {
      sessionStorage.setItem('monitorLog', params)
    },

    getMonitorLog() {
      this.loading = true
      const params = {
        word: this.word,
        sort: this.sort,
        sortOrder: this.sortOrder,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize
      }
      monitorLog(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.setCache(JSON.stringify(params))
          this.tableData = res.data.list
          this.pageStatus.pageNo = res.data.page.pageNo
          this.pageStatus.total = res.data.page.total
          this.columnList = res.data.columns
        }
      })
    },

    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.getMonitorLog()
    },

    goNumPage(val) {
      this.pageStatus.pageNo = val
      this.getMonitorLog()
    },

    changePageSize(val) {
      this.pageStatus.pageSize = val
      this.getMonitorLog()
    }
  }
}
</script>

<style scoped>
.index-main {
  height: 100%;
}
.btn-list {
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
.input-row {
  display: flex;
  align-items: center;
}
.btn-row {
  margin-left: 10px;
  display: flex;
}
.table {
  margin-top: 10px;
  min-height: calc(100% - 60px - 40px);
  background-color: #fff;
}
.table ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
.table ::v-deep .el-table th,
.el-table tr {
  background-color: #fff;
}
.page-1 {
  background-color: #fff;
}
.set-btn {
  cursor: pointer;
  color: #0099cc;
}
.set > span:last-child {
  display: none;
}
.el-dropdown-link {
  cursor: pointer;
  color: #909399;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.active {
  color: #41cabf !important;
}
.g-search {
  color: #41cabf;
  margin-left: 10px;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
}
.filtrate {
  margin-left: 2px;
}
.condition {
  position: relative;
  margin-left: 10px;
}
.condition /deep/ .el-input__inner {
  padding-right: 40px;
}
.condition-icon {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40px;
  z-index: 99;
  cursor: pointer;
  color: #41cabf;
  /*background-color: rosybrown;*/
}
.editNicheStateDesc {
  cursor: pointer;
  font-size: 15px;
  display: none;
}
.hover-state {
  display: inline-block;
}
.hover-state:hover .editNicheStateDesc {
  display: inline-block;
}
.poper-state {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.poper-footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}
</style>
