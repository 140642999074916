var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DtDropDown-main" },
    [
      _c(
        "el-container",
        [
          _c("el-header", [
            _c(
              "div",
              { staticClass: "search" },
              [
                _c("el-input", {
                  attrs: {
                    size: "mini",
                    placeholder: "快捷搜索",
                    autofocus: "",
                  },
                  on: { input: _vm.changeInput },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "search",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-main",
            [_vm._t("default", null, { filterList: _vm.filterList })],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }