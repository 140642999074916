<template>
  <div class="Filter-main" v-loading="loading">
    <div class="filter-item" v-for="(item, index) in filterList" :key="index">
      <template v-if="item.field === 'area'">
        <div class="title">{{ item.name }}</div>
        <template v-if="!item.filterOptionList.length">
          <div
            style="display: flex;align-items: center;width: 100%;color: rgba(0,0,0,.4);padding-left: 70px;cursor: pointer"
            @click="tipClick"
          >
            未配置地域权限，请联系客服
          </div>
        </template>

        <template v-else>
          <div class="all" @click="selectAll(item)">全部</div>
          <div class="item">
            <span
              v-for="btn in item.filterOptionList"
              :class="btn.checked ? 'active' : ''"
              @click="userClick(btn, item)"
            >
              {{ btn.name }}
            </span>
          </div>
        </template>
      </template>

      <template v-else>
        <div class="title">{{ item.name }}</div>
        <div class="all" @click="selectAll(item)">全部</div>
        <div class="item" v-if="item.field === 'cloudName'">
          <span
            v-for="btn in item.filterOption.cn"
            :class="btn.checked ? 'active' : ''"
            @click="userClick(btn, item)"
          >
            {{ btn.name }}
          </span>
          <div class="_item">
            <span
              v-for="btn in item.filterOption.overseas"
              :class="btn.checked ? 'active' : ''"
              @click="userClick(btn, item)"
              >{{ btn.name }}</span
            >
          </div>
        </div>
        <div class="item" v-else>
          <span
            v-for="(btn, idx) in item.filterOptionList"
            :key="idx"
            :class="btn.checked ? 'active' : ''"
            @click="userClick(btn, item)"
          >
            {{ btn.name }}
          </span>
        </div>
      </template>
    </div>

    <div class="filter-item">
      <div class="title">政策</div>
      <PeSelect
        selectSize="mini"
        :defaultSelect="defaultPolicy"
        style="width:400px"
        :optionsList="policyList"
        @handleSelectChange="handlePolicyChange"
      />
    </div>

    <div class="filter-item">
      <div class="title">政策标签</div>
      <div class="flex">
        <div
          v-for="(item, index) in tagList"
          :class="item.checked ? 'active com' : 'com'"
          :key="index"
          @click="handleTagClick(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="filter-item">
      <div class="title">已选条件</div>
      <div class="item">
        <template v-for="(select, source) in selectList">
          <el-tag
            @close="removeSelect(btn, source)"
            v-for="btn in select"
            closable
          >
            {{ btn.name }}
          </el-tag>
        </template>
        <template v-if="copyTagList.length">
          <el-tag
            v-for="(btn, source) in copyTagList"
            @close="removeTag(btn, source)"
            :key="source"
            closable
          >
            {{ btn.name }}
          </el-tag>
        </template>

        <template v-if="Object.keys(selectList).length || copyTagList.length">
          <div
            @click="removeAll"
            class="remove"
            style="display: flex;align-items: center;height: 25px;font-size: 12px;background-color:rgba(0,0,0,.3);color: #ffffff;padding: 0 5px;box-sizing: border-box;cursor: pointer"
          >
            清除已选
          </div>
        </template>
      </div>
    </div>
    <div class="count" v-if="count">共{{ count }}家企业</div>
  </div>
</template>

<script>
import { parkInsightFilter } from '@/api/customerpond'
import { getPolicyList, getYqTags, getPolicyDetail } from '@/api/module'
import PeSelect from '@/components/Peculiar/select'
import moment from 'moment'
import tip from '@/views/insight/tip'
export default {
  name: 'parkDetailFilter',
  data() {
    // let _data = localStorage.getItem('parkDetailFilter')
    // if (_data) _data = JSON.parse(_data)
    let _data = {}
    return Object.assign(
      {
        loading: false,
        policyId: '',
        policyList: [],
        tagList: [],
        defaultPolicy: '',
        copyTagList: [],
        pageParams: {
          pageNo: 1,
          pageSize: 100
        },
        filterList: [],
        selectList: {},
        htTime: '',
        clTime: ''
      },
      _data || {},
      { loading: false }
    )
  },

  props: {
    count: {
      type: [String, Number],
      default: 0
    }
  },

  created() {
    // let _data = localStorage.getItem('parkDetailFilter')
    // if (_data) _data = JSON.parse(_data)
    // this.loading = false
    // if (!_data || !_data?.filterList?.length) {
    //   this.getFilter()
    // }
    this.getFilter()
    this.getList()
    this.handlePolicyChange()
    this.handleDetail()
  },
  mounted() {},

  methods: {
    async handleDetail() {
      const { data } = await getPolicyDetail({
        policyId: this.$route.query.policyId
      })
      this.defaultPolicy = data.policy
    },

    handleTagClick(item) {
      item.checked = !item.checked
      this.copyTagList = this.tagList.filter(item => item.checked)
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
      // console.log(e)
    },

    async getList() {
      const { data } = await getPolicyList(this.pageParams)
      this.policyList = data.datas.map(item => ({
        label: item.policy,
        value: item
      }))

      console.log(data, 9900)
    },

    async handlePolicyChange(e) {
      const policyId = e?.value?.id || this.$route.query.policyId
      const { data } = await getYqTags({
        policyId,
        parkUuid: this.$route.query.id
      })
      console.log(data, 8855)
      this.tagList = data.map(item => ({
        name: `${item.tag}(${item.count})`,
        value: item.tag,
        checked: false
      }))

      console.log(data, 'tags123')
    },

    tipClick() {
      this.$store.dispatch('showModal', {
        title: '提示',
        view: tip,
        size: '600px',
        options: {
          title: '扫码联系客服'
        }
      })
    },

    getRes() {
      const res = [].concat.apply([], Object.values(this.selectList))
      // localStorage.setItem('parkDetailFilter', JSON.stringify(this.$data))
      return {
        vcStepCode:
          res
            .filter(i => i.key === 'vcStepCode')
            ?.map(ii => ii.value)
            ?.join(',') || null,
        stockCode:
          res
            .filter(i => i.key === 'stockCode')
            ?.map(ii => ii.value)
            ?.join(',') || null,
        certificationCode:
          res
            .filter(i => i.key === 'certificationCode')
            ?.map(ii => ii.value)
            ?.join(',') || null,
        technologyCode:
          res
            .filter(i => i.key === 'technologyCode')
            ?.map(ii => ii.value)
            ?.join(',') || null,
        exportCode:
          res
            .filter(i => i.key === 'exportCode')
            ?.map(ii => ii.value)
            ?.join(',') || null,
        cloudName:
          res
            .filter(i => i.key === 'cloudName')
            ?.map(ii => ii.name)
            ?.join(',') || null,
        tags: this.tagList.filter(item => item.checked).map(item => item.name)
      }
    },

    getFilter() {
      this.loading = true
      parkInsightFilter({ type: 2 })
        .then(res => {
          if (res.code === 60000) {
            this.filterList = res?.data || []
            this.filterList.forEach(item => {
              if (item.field === 'area') {
                this.$set(
                  item,
                  'filterOptionList',
                  item.filterOption?.['中国'] || []
                )
              }
              if (item.field === 'cloudName') {
                item.filterOption?.cn?.forEach(i => {
                  i['key'] = item.field
                })
                item.filterOption?.overseas?.forEach(i => {
                  i['key'] = item.field
                })
              } else {
                item.filterOptionList?.forEach(i => {
                  i['key'] = item.field
                })
              }
            })
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },

    selectAll(item) {
      this.selectList[item.name] = []
      delete this.selectList[item.name]
      if (item.field === 'cloudName') {
        item.filterOption?.cn?.forEach(item => {
          item['checked'] = false
        })
        item.filterOption?.overseas?.forEach(item => {
          item['checked'] = false
        })
      } else {
        item.filterOptionList?.forEach(item => {
          item['checked'] = false
        })
      }
      if (item.name === '获投时间' || item.name === '成立时间') {
        this.clTime = ''
        this.htTime = ''
      }
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    timeChange(value, item) {
      if (this.selectList[item.name]) {
        this.selectList[item.name][0].value = value
      } else {
        this.selectList[item.name] = [
          {
            checked: true,
            key: item.field,
            name: '自定义时间',
            value: value
          }
        ]
      }
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    userClick(btn, source) {
      const hasOb = this.selectList[source.name]
      if (hasOb && hasOb.find(item => item.name === btn.name)) {
        this.selectList[source.name] = hasOb?.filter(i => i.name !== btn.name)
        btn.checked = false
      } else {
        // 判断是不是时间单选
        if (source.name === '获投时间' || source.name === '成立时间') {
          if (btn.name === '近三个月') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(3, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(3, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '最近半年') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(6, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(6, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '最近一年') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(1, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(1, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '最近三年') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(3, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(3, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '2022') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment('2022-01-01').format('YYYY-MM-DD'),
                  moment('2022-12-31').format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment('2022-01-01').format('YYYY-MM-DD'),
                  moment('2022-12-31').format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '2021') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment('2021-01-01').format('YYYY-MM-DD'),
                  moment('2021-12-31').format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment('2021-01-01').format('YYYY-MM-DD'),
                  moment('2021-12-31').format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '2020') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment('2020-01-01').format('YYYY-MM-DD'),
                  moment('2020-12-31').format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment('2020-01-01').format('YYYY-MM-DD'),
                  moment('2020-12-31').format('YYYY-MM-DD')
                ])
          }
          btn.value = source.name === '获投时间' ? this.htTime : this.clTime
          this.selectList[source.name] = [btn]
          this.filterList.forEach(i => {
            if (i.name === source.name) {
              i.filterOptionList.forEach(item => {
                if (item.name !== btn.name) item.checked = false
              })
            }
          })
          this.$forceUpdate()
        } else {
          this.selectList[source.name] = hasOb ? [...hasOb, btn] : [btn]
        }
        btn.checked = true
      }
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    removeSelect(select, name) {
      this.selectList[name] = this.selectList[name].filter(
        i => i.name !== select.name
      )
      if (select.key === 'htTime') this.htTime = null
      if (select.key === 'establishmentTime') this.clTime = null
      select.checked = false
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    removeTag(it, idx) {
      this.copyTagList.splice(idx, 1)
      it.checked = false
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
      console.log(it, idx)
    },

    removeAll() {
      this.getFilter()
      this.selectList = {}
      this.filterList.forEach(item => {
        item.filterOptionList?.forEach(item => {
          item['checked'] = false
        })
        item.filterOption?.cn?.forEach(item => {
          item['checked'] = false
        })
        item.filterOption?.overseas?.forEach(item => {
          item['checked'] = false
        })
      })
      this.tagList.forEach(item => (item.checked = false))
      this.copyTagList = []
      this.$emit('selectChange', this.getRes())
    }
  },

  components: {
    PeSelect
  }
}
</script>
<style lang="scss">
.Filter-main {
  .el-input {
    width: 400px;
  }
  .active {
    color: #ffffff;
    background-color: #41cabf;
  }
  .com {
    cursor: pointer;
    display: inline-block;
    min-width: 80px;
    width: auto;
    height: 25px;
    line-height: 25px;
    margin-right: 5px;
    margin-bottom: 5px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>

<style scoped lang="scss">
.Filter-main {
  min-height: 200px;
  .count {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    color: #7a7a7a;
  }
  .filter-item {
    display: flex;
    font-size: 14px;
    padding: 10px 0;
    box-sizing: border-box;
    position: relative;
    &:after {
      content: '';
      display: inline-block;
      height: 1px;
      width: calc(100% - 150px);
      position: absolute;
      left: 150px;
      bottom: 0;
      transform: scaleY(0.5);
      background-color: rgba(0, 0, 0, 0.1);
    }
    .title {
      width: 100px;
      height: 25px;
      line-height: 25px;
    }
    .all {
      width: 50px;
      text-align: center;
      height: 25px;
      line-height: 25px;
      color: #41cabf;
      cursor: pointer;
    }
    .item {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      & > span {
        display: inline-block;
        min-width: 80px;
        width: auto;
        height: 25px;
        line-height: 25px;
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        border-radius: 3px;
      }
    }
    ._item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      & > span {
        display: inline-block;
        min-width: 80px;
        width: auto;
        height: 25px;
        line-height: 25px;
        //background-color: forestgreen;
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        border-radius: 3px;
      }
      .active {
        color: #ffffff;
        background-color: #41cabf;
      }
    }
  }
}
</style>
