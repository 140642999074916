<template>
  <div class="createContract-main">
    <div class="tab-div">
      <el-tabs v-model="tabType" @tab-click="tabClick" class="dt-tab">
        <el-tab-pane label="基础信息" name="baseMsg" />
        <el-tab-pane label="合同内容" name="contractContent" />
        <el-tab-pane
          label="发票信息"
          name="invoiceMsg"
          v-if="permissionList.includes('客户管理CRM-客户公海-发票')"
        />
      </el-tabs>
    </div>
    <el-scrollbar class="contract-u-c-main dt-dropdown-dt">
      <div class="scroll-main">
        <div v-show="tabType === 'baseMsg'">
          <el-form
            :model="formData"
            ref="form"
            :validate-on-rule-change="false"
            label-width="auto"
            :rules="rules"
            size="small"
            :disabled="hasEditPermission"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="客户名称" prop="companyName">
                  <div
                    class="input-mask"
                    @click="
                      dialogType === 'create'
                        ? (showSelectCompany = true)
                        : (showSelectCompany = false)
                    "
                  >
                    <span v-show="dialogType === 'create'">选择</span>
                  </div>
                  <el-input
                    :disabled="dialogType !== 'create'"
                    v-model="formData.companyName"
                    placeholder="请选择客户名称"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="合同编号" prop="contractId">
                  <el-input
                    v-model="formData.contractId"
                    placeholder="请输入合同编号"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="签约关键人" prop="contactIds">
                  <div class="input-mask input-tag" @click="showSelectUserFunc">
                    <span>
                      <el-tag
                        v-for="item in selectUserList"
                        :closable="!hasEditPermission"
                        size="mini"
                        @close="removeUser(item)"
                        style="margin-right: 3px"
                        >{{ item.contact }}</el-tag
                      >
                    </span>
                    <span>选择</span>
                  </div>
                  <el-input
                    v-model="formData.contactIds"
                    placeholder="请选择签约关键人"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="相关产品" prop="product">
                  <el-input
                    placeholder="请输入相关产品"
                    v-model="formData.product"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="签约销售" prop="signUserId">
                  <el-select
                    filterable
                    clearable
                    v-model="formData.signUserId"
                    placeholder="请选择签约销售"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in personOption"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="负责销售" prop="followUserId">
                  <el-select
                    filterable
                    clearable
                    v-model="formData.followUserId"
                    placeholder="请选择负责销售"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in personOption"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="合同金额" prop="contractAmount">
                  <el-input
                    placeholder="请输入合同金额"
                    v-model="formData.contractAmount"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="签约日期" prop="contractDate">
                  <el-date-picker
                    style="width: 100%"
                    v-model="formData.contractDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择签约日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="开始日期" prop="startDate">
                  <el-date-picker
                    style="width: 100%"
                    v-model="formData.startDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="到期日期" prop="expireDate">
                  <el-date-picker
                    style="width: 100%"
                    v-model="formData.expireDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择到期日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="合同类型" prop="contractType">
                  <el-select
                    v-model="formData.contractType"
                    placeholder="请选择合同类型"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in contractTypeList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="签约渠道" prop="signChannel">
                  <el-select
                    v-model="formData.signChannel"
                    placeholder="请选择签约渠道"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in signChannelList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="合同原件状态" prop="contractOriginStatus">
                  <el-select
                    v-model="formData.contractOriginStatus"
                    placeholder="请选择合同原件状态"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in contractOriginStatusList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="发票状态" prop="invoiceStatus">
                  <el-select
                    v-model="formData.invoiceStatus"
                    placeholder="请选择发票状态"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in invoiceStatusList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="账号开通日期" prop="accountStartDate">
                  <el-date-picker
                    style="width: 100%"
                    v-model="formData.accountStartDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择账号开通日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="账号到期日期" prop="accountExpireDate">
                  <el-date-picker
                    style="width: 100%"
                    v-model="formData.accountExpireDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择账号到期日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="备注" prop="remark">
                  <el-input
                    type="textarea"
                    :autosize="
                      isLn
                        ? { minRows: 5, maxRows: 6 }
                        : { minRows: 9, maxRows: 9 }
                    "
                    placeholder="请输入备注"
                    v-model="formData.remark"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20" v-show="isLn">
              <el-col :span="12">
                <el-form-item label="首次接触日期" prop="firstContactTime">
                  <el-date-picker
                    style="width: 100%"
                    v-model="formData.firstContactTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择首次接触日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="产品类型" prop="productType">
                  <el-select
                    v-model="formData.productType"
                    placeholder="请选择产品类型"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in productTypeList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12" v-show="false">
                <el-form-item label="收款金额" prop="returnAmount">
                  <el-input
                    v-model.number="formData.returnAmount"
                    placeholder="请输入收款金额"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-show="false">
                <el-form-item label="收款日期" prop="payTime">
                  <el-date-picker
                    style="width: 100%"
                    v-model="formData.payTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择收款日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="客户行业" prop="cusIndustry">
                  <el-select
                    v-model="formData.cusIndustry"
                    placeholder="请选择客户行业"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in cusIndustryList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="账号时长" prop="accountDurationSaveData">
                  <el-input
                    disabled
                    v-model="formData.accountDurationSaveData"
                    placeholder="账号时长自动计算"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div v-show="tabType === 'contractContent'">
          <div>
            <el-form size="small" label-width="auto">
              <el-form-item label="合同内容">
                <editor-bar
                  v-model="formData.contractContent"
                  :hasEditPermission="hasEditPermission"
                />
              </el-form-item>
              <el-form-item label="附件">
                <div class="upload-dt-c">
                  <el-upload
                    class="upload-demo"
                    :action="uploadFileUrl"
                    ref="upload"
                    :before-upload="beforeUpload"
                    accept=".doc,.docx,.pdf,.jpg,.png,.gif,.pages"
                    :http-request="uploadFileToOss"
                    :on-remove="handleRemove"
                    :on-preview="downLoadFile"
                    :file-list="fileList"
                    :auto-upload="true"
                    :disabled="hasEditPermission"
                  >
                    <el-button
                      slot="trigger"
                      :disabled="hasEditPermission"
                      size="small"
                      >选取文件</el-button
                    >
                    <div slot="tip" class="el-upload__tip">
                      可上传多个。文件格式限制：doc、docx、pdf、jpg、png、gif、pages
                    </div>
                  </el-upload>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div v-show="tabType === 'invoiceMsg'">
          <span
            class="edit-invoice"
            @click="editInvClick"
            :style="{ visibility: isEdit ? 'hidden' : '' }"
            >修改发票信息</span
          >
          <el-form
            :model="invoiceFormData"
            size="mini"
            label-width="120px"
            label-position="left"
            class="form-invoice"
            :disabled="hasEditPermission"
            v-loading="invoiceLoading"
          >
            <el-form-item label="开具类型">
              <div
                v-show="openType && invoiceFormData.openType"
                class="tip-text"
              >
                {{ openType === '复制成功' ? '复制成功 !' : '点击复制' }}
              </div>
              <span
                class="value"
                @click="copyText(invoiceFormData.openType, 'openType')"
                v-if="!isEdit"
                @mouseenter="spanElStatus('openType', true)"
                @mouseleave="spanElStatus('openType', false)"
                >{{ invoiceFormData.openType }}</span
              >
              <el-select
                v-model="invoiceFormData.openType"
                placeholder="请选择开具类型"
                style="width: 300px"
                v-else
              >
                <el-option
                  v-for="item in openTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票类型">
              <div
                v-show="invoiceType && invoiceFormData.invoiceType"
                class="tip-text"
              >
                {{ invoiceType === '复制成功' ? '复制成功 !' : '点击复制' }}
              </div>
              <span
                class="value"
                @click="copyText(invoiceFormData.invoiceType, 'invoiceType')"
                @mouseenter="spanElStatus('invoiceType', true)"
                @mouseleave="spanElStatus('invoiceType', false)"
                v-if="!isEdit"
                >{{ invoiceFormData.invoiceType }}</span
              >
              <el-select
                v-model="invoiceFormData.invoiceType"
                placeholder="请选择发票类型"
                style="width: 300px"
                v-else
              >
                <el-option
                  v-for="item in invoiceTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票抬头">
              <div
                v-show="invoiceTitle && invoiceFormData.invoiceTitle"
                class="tip-text"
              >
                {{ invoiceTitle === '复制成功' ? '复制成功 !' : '点击复制' }}
              </div>
              <span
                class="value"
                @click="copyText(invoiceFormData.invoiceTitle, 'invoiceTitle')"
                v-if="!isEdit"
                @mouseenter="spanElStatus('invoiceTitle', true)"
                @mouseleave="spanElStatus('invoiceTitle', false)"
                >{{ invoiceFormData.invoiceTitle }}</span
              >
              <el-input
                disabled
                v-model="invoiceFormData.invoiceTitle"
                v-else
                style="width: 300px"
                placeholder="请输入发票抬头"
              ></el-input>
            </el-form-item>
            <el-form-item label="纳税人识别号">
              <div
                v-show="
                  taxpayerIdenifyCode && invoiceFormData.taxpayerIdenifyCode
                "
                class="tip-text"
              >
                {{
                  taxpayerIdenifyCode === '复制成功' ? '复制成功 !' : '点击复制'
                }}
              </div>
              <span
                class="value"
                @click="
                  copyText(
                    invoiceFormData.taxpayerIdenifyCode,
                    'taxpayerIdenifyCode'
                  )
                "
                v-if="!isEdit"
                @mouseenter="spanElStatus('taxpayerIdenifyCode', true)"
                @mouseleave="spanElStatus('taxpayerIdenifyCode', false)"
                >{{ invoiceFormData.taxpayerIdenifyCode }}</span
              >
              <el-input
                v-model="invoiceFormData.taxpayerIdenifyCode"
                v-else
                style="width: 300px"
                placeholder="请输入纳税人识别号"
              ></el-input>
            </el-form-item>
            <el-form-item label="地址">
              <div v-show="address && invoiceFormData.address" class="tip-text">
                {{ address === '复制成功' ? '复制成功 !' : '点击复制' }}
              </div>
              <span
                class="value"
                @click="copyText(invoiceFormData.address, 'address')"
                v-if="!isEdit"
                @mouseenter="spanElStatus('address', true)"
                @mouseleave="spanElStatus('address', false)"
                >{{ invoiceFormData.address }}</span
              >
              <el-input
                v-model="invoiceFormData.address"
                v-else
                style="width: 300px"
                placeholder="请输入地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话">
              <div v-show="phone && invoiceFormData.phone" class="tip-text">
                {{ phone === '复制成功' ? '复制成功 !' : '点击复制' }}
              </div>
              <span
                class="value"
                @click="copyText(invoiceFormData.phone, 'phone')"
                v-if="!isEdit"
                @mouseenter="spanElStatus('phone', true)"
                @mouseleave="spanElStatus('phone', false)"
                >{{ invoiceFormData.phone }}</span
              >
              <el-input
                v-model="invoiceFormData.phone"
                v-else
                style="width: 300px"
                placeholder="请输入电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="开户行">
              <div
                v-show="openBank && invoiceFormData.openBank"
                class="tip-text"
              >
                {{ openBank === '复制成功' ? '复制成功 !' : '点击复制' }}
              </div>
              <span
                class="value"
                @click="copyText(invoiceFormData.openBank, 'openBank')"
                v-if="!isEdit"
                @mouseenter="spanElStatus('openBank', true)"
                @mouseleave="spanElStatus('openBank', false)"
                >{{ invoiceFormData.openBank }}</span
              >
              <el-input
                v-model="invoiceFormData.openBank"
                v-else
                style="width: 300px"
                placeholder="请输入开户行"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行账号">
              <div
                v-show="bankAccount && invoiceFormData.bankAccount"
                class="tip-text"
              >
                {{ bankAccount === '复制成功' ? '复制成功 !' : '点击复制' }}
              </div>
              <span
                class="value"
                @click="copyText(invoiceFormData.bankAccount, 'bankAccount')"
                v-if="!isEdit"
                @mouseenter="spanElStatus('bankAccount', true)"
                @mouseleave="spanElStatus('bankAccount', false)"
                >{{ invoiceFormData.bankAccount }}</span
              >
              <el-input
                v-model="invoiceFormData.bankAccount"
                v-else
                style="width: 300px"
                placeholder="请输入银行账号"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮寄地址">
              <div
                v-show="mailAddress && invoiceFormData.mailAddress"
                class="tip-text"
              >
                {{ mailAddress === '复制成功' ? '复制成功 !' : '点击复制' }}
              </div>
              <span
                class="value"
                @click="copyText(invoiceFormData.mailAddress, 'mailAddress')"
                v-if="!isEdit"
                @mouseenter="spanElStatus('mailAddress', true)"
                @mouseleave="spanElStatus('mailAddress', false)"
                >{{ invoiceFormData.mailAddress }}</span
              >
              <el-input
                v-model="invoiceFormData.mailAddress"
                v-else
                style="width: 300px"
                placeholder="请输入邮寄地址"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="isEdit">
              <div
                style="display: flex;justify-content: flex-end;padding-right: 340px;padding-bottom:30px"
              >
                <el-button @click="isEdit = false">
                  取消
                </el-button>
                <el-button type="primary" @click="editInvoice">
                  保存
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-scrollbar>

    <div v-if="!hasEditPermission" class="footer">
      <el-button size="small" @click="cancel">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="confirmBtnThrottle"
        v-loading="btnLoading"
        >确定</el-button
      >
    </div>

    <el-dialog
      class="el-dialog-dt"
      width="800px"
      top="90px"
      :visible.sync="showSelectCompany"
      :show-close="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <div slot="title" class="slot-title">
        <div>
          选择客户
        </div>
        <div @click="showSelectCompany = false">
          <i class="el-icon-close close"></i>
        </div>
      </div>
      <select-company
        @closeSelectCompany="showSelectCompany = false"
        @selectCompany="selectCompany"
      />
    </el-dialog>

    <el-dialog
      class="el-dialog-dt"
      width="850px"
      top="90px"
      :visible.sync="showSelectUser"
      :show-close="false"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
    >
      <div slot="title" class="slot-title">
        <div>
          选择签约决策人
        </div>
        <div @click="showSelectUser = false">
          <i class="el-icon-close close"></i>
        </div>
      </div>

      <div class="user-list">
        <selectBillKeyPersion
          :company-name="selectCompany.companyName"
          :current-check-row-prop="selectUserList"
          :package-id="selectCompanyData.packageId"
          :company-uuid="selectCompanyData.companyUuid"
          @closeDialog="showSelectUser = false"
          @getRes="getSelectUser"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { throttle } from 'lodash'
import moment from 'moment'
import EditorBar from '@/components/wangEnduit'
import OSS from 'ali-oss'
import { getStsToken } from '@/api/table'

import selectCompany from '@/views/contract/dialog/selectCompany'
import selectBillKeyPersion from '@/views/customerpond/chanceManage/dialog/selectBillKeyPersion'
import {
  contractLeaders,
  getMode,
  invDetail,
  editInv,
  contractAdd,
  contractEdit
} from '@/api/user'
import { getContractNum, removeContractNum } from '@/api/customerpond'
export default {
  name: 'createContract',
  components: {
    EditorBar,
    selectCompany,
    selectBillKeyPersion
  },
  props: {
    hasEditPermission: {
      type: Boolean,
      default: false
    },
    showContract: {
      type: Boolean,
      default: false
    },
    modalOptions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const validatePayDate = (rule, value, callback) => {
      if (!this.formData.returnAmount && value) {
        this.formData.payTime = ''
        callback(new Error('请先输入收款金额'))
      } else if (this.formData.returnAmount && !value) {
        callback(new Error('请选择收款日期'))
      } else {
        callback()
      }
    }

    // 账号到期日期
    const validateaccountExpireDate = (rule, value, callback) => {
      if (value && !this.formData.accountStartDate) {
        this.formData.accountExpireDate = ''
        callback(new Error('请先选择账号开通日期'))
      } else if (
        value &&
        this.formData.accountStartDate &&
        value <= this.formData.accountStartDate
      ) {
        this.formData.accountExpireDate = ''
        callback(new Error('到期日期应大于开通日期'))
      } else {
        callback()
      }
    }

    const validateContractAmount = (rule, value, callback) => {
      let reg = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g
      if (value === '') {
        callback(new Error('请输入合同金额'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入数字'))
      } else {
        callback()
      }
    }

    return {
      btnLoading: false,
      dialogType: 'create',
      tabType: 'baseMsg',
      createPermission: this.hasEditPermission,
      formData: {
        followUserId: '', // 负责销售id
        signUserId: '', // 签约销售id
        companyName: '', // 客户名称
        contractId: '', // 合同编号
        product: '', // 相关产品
        contractDate: '', // 签订日期
        startDate: '', // 开始日期
        expireDate: '', // 到期日期
        contractAmount: '', // 合同金额
        returnAmount: null, // 收款金额 初始状态必须为null
        accessory: '', // 附件url
        remark: '', // 备注
        contractContent: '', //合同内容
        contactIds: '', // 签约关键人,多个用逗号分隔
        contractType: '', // 合同类型
        contractOriginStatus: '', // 合同原件状态
        payTime: '', // 收款时间
        invoiceStatus: '', // 发票状态
        firstContactTime: '', // 首次接触时间

        productType: '', // 产品类型
        signChannel: '', //签约渠道
        cusIndustry: '', // 客户行业
        accountStartDate: '', // 账号开通日期
        accountExpireDate: '', // 账号到期日期
        accountDuration: '', // 账号时长 (M/d)
        accountDurationSaveData: ''
      },
      rules: {
        contractId: [
          { required: true, message: '请输入合同编号', trigger: 'change' }
        ],
        companyName: [
          { required: true, message: '请选择客户', trigger: 'change' }
        ],
        contactIds: [
          { required: true, message: '请选择签约关键人', trigger: 'change' }
        ],
        followUserId: [
          { required: true, message: '请选择负责销售', trigger: 'change' }
        ],
        signUserId: [
          { required: true, message: '请选择签约销售', trigger: 'change' }
        ],
        contractAmount: [
          { required: true, message: '请输入合同金额', trigger: 'change' },
          { validator: validateContractAmount, trigger: 'blur' }
        ],
        startDate: [
          { required: true, message: '请选择开始日期', trigger: 'change' }
        ],
        expireDate: [
          { required: true, message: '请选择到期日期', trigger: 'change' }
        ],
        contractDate: [
          { required: true, message: '请选择签约日期', trigger: 'change' }
        ],

        contractType: [
          { required: true, message: '请选择合同日期', trigger: 'change' }
        ],
        signChannel: [
          { required: true, message: '请选择签约渠道', trigger: 'change' }
        ],
        contractOriginStatus: [
          { required: true, message: '请选择客户原件状态', trigger: 'change' }
        ],
        invoiceStatus: [
          { required: true, message: '请选择发票状态', trigger: 'change' }
        ],
        accountExpireDate: [
          { validator: validateaccountExpireDate, trigger: 'change' }
        ]
      },

      ruleLn: {
        firstContactTime: [
          { required: true, message: '请选择首次接触日期', trigger: 'change' }
        ],

        productType: [
          { required: true, message: '请选择产品类型', trigger: 'change' }
        ],
        // returnAmount: [
        //   { type: 'number', message: '合同金额必须为数字值'}
        // ],
        // payTime: [
        //   { validator: validatePayDate, trigger: 'change' },
        // ],

        cusIndustry: [
          { required: true, message: '请选择客户行业', trigger: 'change' }
        ]
      },

      showSelectCompany: false, // 选择客户名称
      showSelectUser: false, // 选择签约关键人
      selectUserList: [],
      selectCompanyData: {},

      // 下拉列表
      personOption: [], // 负责人下拉列表
      contractTypeList: [
        {
          name: '新购',
          value: 1
        },
        {
          name: '续费',
          value: 2
        },
        {
          name: '增购',
          value: 3
        }
      ], // 合同零星列表
      productTypeList: [], // 产品类型列表
      signChannelList: [], // 签约渠道列表
      cusIndustryList: [
        {
          name: '云',
          value: 1
        },
        {
          name: '非云',
          value: 0
        }
      ], // 客户行业列表
      contractOriginStatusList: [], // 合同原件状态列表
      invoiceStatusList: [], // 发票状态列表

      // 富文本编辑器
      contractContent: '',
      uploadFileUrl: '',
      fileList: [],
      aliOssConfig: {
        region: 'oss-cn-shanghai',
        accessKeyId: '',
        accessKeySecret: '',
        stsToken: '',
        bucket: 'dtbird-miniapp'
      },
      delayUid: '',
      delay: false,

      // 发票
      invoiceLoading: false,
      isEdit: false, // 是否编辑发票
      invoiceFormData: {
        id: null, // 发票id
        openType: '', //开具类型
        invoiceType: '', // 发票类型
        invoiceTitle: '', // 发票抬头
        taxpayerIdenifyCode: '', // 纳税人识别号
        address: '', // 地址
        phone: '', // 电话
        openBank: '', // 开户行
        bankAccount: '', //银行账号
        mailAddress: '' // 邮寄地址
      },
      openType: false,
      invoiceType: false,
      invoiceTitle: false,
      taxpayerIdenifyCode: false,
      address: false,
      phone: false,
      openBank: false,
      bankAccount: false,
      mailAddress: false,
      openTypeList: [
        {
          value: '企业',
          name: '企业'
        },
        {
          value: '个人',
          name: '个人'
        }
      ], // 开具类型列表
      invoiceTypeList: [
        {
          value: '增值税专用发票',
          name: '增值税专用发票'
        },
        {
          value: '增值税普通发票',
          name: '增值税普通发票'
        }
      ] // 发票类型列表
    }
  },
  created() {
    // 添加节流函数 防止多次提交
    this.confirmBtnThrottle = throttle(
      () => {
        this.confirmContract()
      },
      500,
      { trailing: false }
    )
    this.$bus.$on('startCreateContact', () => {
      this.createPermission = true
    })
  },

  mounted() {
    this.getTagList('HTO_01', 'contractOriginStatusList')
    this.getTagList('IS_01', 'invoiceStatusList')
    this.getTagList('CPT_01', 'productTypeList')
    this.getTagList('CSC_01', 'signChannelList')
    this.getFollowUserList()
    // todo 是否为领鸟角色
    if (this.isLn) {
      this.rules = Object.assign({}, this.rules, this.ruleLn)
    }
    // 获取options下拉选项
    this.initOptionsProps()
  },
  watch: {
    // 校验数字类型 非必填
    'formData.returnAmount'(val) {
      if (!val) {
        this.formData.returnAmount = null
      }
    },

    showContract(val) {
      if (val) {
        this.initOptionsProps()
      }
    },
    changeState(val) {
      const dateList = val.split(',')
      let startDate = dateList[0]
      let endDate = dateList[1]
      if (!startDate || !endDate) return
      this.countMonthDay(startDate, endDate)
    }
  },
  computed: {
    isLockData() {
      return this.$store.state.plugin.modal.options.data
    },

    changeState() {
      return `${this.formData.accountStartDate},${
        this.formData.accountExpireDate
      }`
    },

    permissionList() {
      return this.$store.state.permission.permission
    },

    isLn() {
      return this.permissionList.includes(
        '客户管理CRM-合同管理-领鸟内部合同权限'
      )
    }
  },
  methods: {
    initOptionsProps() {
      const modalOptions = this.modalOptions
      console.log(modalOptions.data.id, 333)
      this.dialogType = modalOptions.type || 'create'
      if (this.dialogType !== 'create') {
        this.formData = Object.assign({}, this.formData, modalOptions.data)
        this.fileList =
          modalOptions.data.accessory && modalOptions.data.accessory !== '[]'
            ? JSON.parse(modalOptions.data.accessory)
            : []
        this.selectUserList = modalOptions.data.contactList
          ? modalOptions.data.contactList
          : []
        this.formData.contactIds = this.selectUserList.map(i => i.id).join(',')
        this.selectCompanyData = {
          companyName: modalOptions.data.companyName,
          companyUuid: modalOptions.data.companyUuid
        }
      }
    },
    tabClick(el) {
      if (el.name === 'invoiceMsg') {
        this.getInv()
      }
    },

    copyText(text, type) {
      if (!text) return
      let oInput = document.createElement('input')
      oInput.value = text
      document.body.appendChild(oInput)
      if (oInput.createTextRange) {
        oInput.select()
      } else {
        oInput.setSelectionRange(0, oInput.value.length)
        oInput.focus()
      }
      document.execCommand('Copy')
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this[type] = '复制成功'
    },

    countMonthDay(startDate, endDate) {
      // 不允许开始日期大于结束日期
      if (!startDate || !endDate || startDate >= endDate) {
        this.formData.accountDurationSaveData = ''
        this.formData.accountDuration = ''
        return
      }

      // 保存提交天数
      this.formData.accountDuration = moment(endDate).diff(
        moment(startDate),
        'days'
      )
      // 1、计算月差值
      const month = moment(endDate).diff(moment(startDate), 'months')
      // 月差值等于0则显示天数
      if (month === 0) {
        this.formData.accountDurationSaveData = `${moment(endDate).diff(
          moment(startDate),
          'days'
        )}天`
      } else {
        // 2、月差值大于等于1则计算出(起始时间+月差值)的日期，然后计算此日期与结束日期之间相差的天数
        const diffMonth = moment(startDate)
          .add(month, 'months')
          .format('YYYY-MM-DD')
        // 3、计算差值与结束时间 相差的天数
        const diffDay = moment(endDate).diff(moment(diffMonth), 'days')
        diffDay
          ? (this.formData.accountDurationSaveData = `${month}个月零${diffDay}天`)
          : (this.formData.accountDurationSaveData = `${month}个月整`)
      }
    },

    getTagList(type) {
      getMode({ type: type }).then(res => {
        if (res.code === 60000) {
          if (type === 'HTO_01') {
            this.contractOriginStatusList = res.data['HTO_01']
          } else if (type === 'IS_01') {
            this.invoiceStatusList = res.data['IS_01']
          } else if (type === 'CPT_01') {
            this.productTypeList = res.data['CPT_01']
          } else if (type === 'CSC_01') {
            this.signChannelList = res.data['CSC_01']
          }
        }
      })
    },
    cancel() {
      this.$bus.$emit('handleConcatDrawClose')
      this.$store.dispatch('hideModal')
    },
    confirmContract() {
      if (this.btnLoading) return
      this.$refs.form.validate((valid, target) => {
        if (!valid) return
        // todo 添加合同
        const params = Object.assign({}, this.formData, {
          accessory: JSON.stringify(this.fileList),
          isLn: this.isLn
        })
        console.log(params, 'btnParams')
        this.btnLoading = true
        if (this.dialogType === 'create') {
          contractAdd(params)
            .then(res => {
              this.btnLoading = false
              if (res.code === 60000) {
                this.$store.commit('update', {
                  target: this.$store.state.plugin.modal.options,
                  data: {
                    refresh: true
                  }
                })
                this.$message.success('新建成功')
                this.$store.dispatch('hideModal')
              }
            })
            .catch(() => {
              this.btnLoading = false
            })
        } else {
          // 更新合同
          delete params.contactList
          contractEdit(params)
            .then(res => {
              this.btnLoading = false
              if (res.code === 60000) {
                this.$store.commit('update', {
                  target: this.$store.state.plugin.modal.options,
                  data: {
                    refresh: true
                  }
                })
                this.$message.success('编辑成功')
                this.$bus.$emit('addContactData', true)
                //逻辑处理
                this.$store.dispatch('hideModal')
              }
            })
            .catch(() => {
              this.btnLoading = false
            })
        }
      })
    },

    showSelectUserFunc() {
      if (!this.formData.companyName) {
        this.$store.dispatch('showTip', {
          text: '请先选择客户',
          type: 'warning'
        })
        return
      }
      if (this.hasEditPermission) {
        return
      }
      this.showSelectUser = true
    },

    removeUser(item) {
      this.selectUserList = this.selectUserList.filter(i => i.id !== item.id)
      this.formData.contactIds = this.selectUserList.map(i => i.id).join(',')
    },

    // 选择签约关键人
    getSelectUser(val) {
      this.selectUserList = val
      this.formData.contactIds = val.map(i => i.id).join(',')
      this.showSelectUser = false
    },

    // 选择公司
    selectCompany(val) {
      this.showSelectCompany = false
      this.selectCompanyData = val
      this.formData.companyName = val.companyName

      if (this.formData.contractId) return
      // 生成合同id
      getContractNum({ companyUuid: val.companyUuid }).then(res => {
        if (res.code === 60000) {
          this.formData.contractId = res.data
        } else {
          removeContractNum({ companyUuid: val.companyUuid }).then()
        }
      })
    },

    // 获取负责人列表
    getFollowUserList() {
      contractLeaders({ pageId: 2002 }).then(res => {
        if (res.code === 60000) {
          this.personOption = res.data
        }
      })
    },

    // 合同区域
    beforeUpload(file) {
      let index = file.name.lastIndexOf('.')
      let ext = file.name.substr(index + 1)
      if (
        ext !== 'doc' &&
        ext !== 'docx' &&
        ext !== 'pdf' &&
        ext !== 'jpg' &&
        ext !== 'png' &&
        ext !== 'pages' &&
        ext !== 'gif'
      ) {
        this.$message({
          message: '上传文件格式不正确',
          type: 'error'
        })
        return false
      } else {
        return true
      }
    },

    async uploadFileToOss(file) {
      const index = this.fileList.findIndex(
        item => item.name === file.file.name
      )
      if (index !== -1) {
        this.fileList.splice(index, 1)
      }
      const sysStatus = await this.getStsToken()
      if (!sysStatus) return
      const client = new OSS(this.aliOssConfig)
      let aliObjName = file.file.name
      let result = await client.put('/contract/' + aliObjName, file.file)
      this.fileList.push({
        name: aliObjName,
        url: result.url,
        uid: file.file.uid
      })
    },

    handleRemove(file) {
      const index = this.fileList.findIndex(item => item.uid === file.uid)
      if (index !== -1) {
        this.fileList.splice(index, 1)
      }
    },

    getStsToken() {
      return new Promise((resolve, reject) => {
        getStsToken({}).then(res => {
          if (res.code === 60000) {
            this.aliOssConfig.accessKeyId = res.data.accessKeyId
            this.aliOssConfig.accessKeySecret = res.data.accessKeySecret
            this.aliOssConfig.stsToken = res.data.securityToken
            resolve(true)
          } else {
            this.$store.dispatch('showTip', {
              text: '上传失败'
            })
            resolve(false)
          }
        })
      })
    },

    downLoadFile(file) {
      if (this.delayUid === file.uid && this.delay) {
        return
      } else {
        this.delay = true
        this.delayUid = file.uid
        setTimeout(() => {
          this.delayUid = ''
          this.delay = false
        }, 2000)
      }
      this.downloadWithBlob(file.url, file.name)
    },
    downloadWithBlob(url, name) {
      fetch(url).then(res =>
        res.blob().then(blob => {
          let a = document.createElement('a')
          let url = window.URL.createObjectURL(blob)
          let filename = name
          a.href = url
          a.download = filename
          a.click()
          window.URL.revokeObjectURL(url)
        })
      )
    },

    // 发票区域
    spanElStatus(type, status) {
      this[type] = status
    },

    editInvClick() {
      if (this.selectCompanyData.companyName) {
        this.isEdit = true
        return
      }
      this.$store.dispatch('showTip', {
        text: '请先选择公司',
        type: 'warning'
      })
    },

    // editInvoice(){
    getInv() {
      // 判断是否选则公司
      if (this.selectCompanyData.companyName) {
        this.invoiceFormData.invoiceTitle = this.selectCompanyData.companyName
        // 获取发票信息
        this.invoiceLoading = true
        invDetail({ companyUuid: this.selectCompanyData.companyUuid }).then(
          res => {
            this.invoiceLoading = false
            if (res.code === 60000 && res.data) {
              this.isEdit = false
              this.invoiceFormData = Object.assign(
                {},
                this.invoiceFormData,
                res.data
              )
            }
          }
        )
      }
    },

    editInvoice() {
      if (this.selectCompanyData.companyName) {
        this.invoiceLoading = true
        editInv(this.invoiceFormData).then(res => {
          if (res.code === 60000) {
            this.getInv()
          }
        })
      }
    }
  },
  beforeDestroy() {
    this.$bus.$off()
  }
}
</script>

<style scoped>
.createContract-main {
  width: 100%;
}
.tab-div {
  height: 50px;
  width: 100%;
  background-color: #fff;
  padding-left: 10px;
  box-sizing: border-box;
  position: relative;
  user-select: none;
}
.tab-div::before {
  display: inline-block;
  content: '';
  height: 5px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.dt-tab /deep/ .el-tabs__nav-wrap::after {
  display: none !important;
}
.contract-u-c-main {
  height: 426px;
  width: 100%;
  padding-top: 10px;
  box-sizing: border-box;
}
.scroll-main {
  width: calc(100% - 40px);
  margin: 0 auto;
}
.btn-group {
  margin: 10px;
}
.footer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}
.footer:before {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
  position: absolute;
  top: 0;
  left: 0;
}
.upload-dt-c {
  min-height: 80px;
  height: auto;
}
.edit-invoice {
  font-size: 12px;
  color: #0099cc;
  cursor: pointer;
  display: inline-block;
  padding-bottom: 10px;
  box-sizing: border-box;
  user-select: none;
}
.value {
  /*user-select: none;*/
  color: #8c939d;
  display: inline-block;
  width: 300px;
  position: relative;
  /*height: 38px;*/
  /*line-height: 38px;*/
  cursor: pointer;
}
.value:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

/*.value:before{*/
/*  content: '';*/
/*  height: 1px;*/
/*  width: 100%;*/
/*  position: absolute;*/
/*  left: 0;*/
/*  bottom: 0;*/
/*  background-color: rgba(0,0,0,.08);*/
/*  transform: scaleY(.5);*/
/*}*/

.form-invoice /deep/ .el-form-item {
  margin-bottom: 4px !important;
  min-height: 40px !important;
  height: auto !important;
}

.input-mask {
  position: absolute;
  background-color: transparent;
  height: 30px;
  z-index: 99;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  box-sizing: border-box;
  transform: translate(1px, 1px);
  width: calc(100% - 2px);
}
.input-tag {
  justify-content: space-between !important;
}
.input-mask > span {
  color: #41cabf;
  font-size: 12px;
}

.slot-title {
  height: 50px;
  background-color: #41cabf;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.el-dialog-dt ::v-deep .el-dialog__header,
.el-drawer-dt ::v-deep .el-drawer__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}
.el-drawer-dt ::v-deep .el-drawer__header {
  margin-bottom: 0 !important;
}

.el-dialog-dt ::v-deep .el-dialog__close,
.el-drawer-dt ::v-deep .el-drawer__close {
  color: #fff;
}

.el-dialog-dt ::v-deep .el-dialog__body,
.el-drawer-dt ::v-deep .el-drawer__body {
  padding: 0 !important;
}
.close {
  cursor: pointer;
}
.tip-text {
  position: absolute;
  border: #000000 solid 1px;
  font-size: 12px;
  top: -25px;
  padding: 15px 15px;
  height: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #ffffff;
}
</style>
