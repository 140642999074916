var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("dt-plugins"),
      _vm._v(" "),
      _c("autoCallTask"),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: _vm.transitionName } },
        [
          _c("router-view", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.showExportLoading,
                expression: "showExportLoading",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showBuyCard
        ? _c("pay-card", {
            ref: "payCardInit",
            attrs: { dialogStatus: _vm.dialogStatus },
            on: { goPayCloseCard: _vm.goPayCloseCard },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showGiveMoney
        ? _c(
            "dia-model",
            { ref: "graspTip", attrs: { dialogStatus: _vm.graspData } },
            [
              _c("div", { attrs: { slot: "modalCont" }, slot: "modalCont" }, [
                _c("div", { staticClass: "show_grasp_text" }, [
                  _vm._v(
                    "\n        亲爱的用户，您的账号套餐过期，请扫码联系小助手[领鸟云晓晓]\n      "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "submit" }, slot: "submit" }, [
                _c("div", { staticClass: "er-code" }),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }