var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "oace-key-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.showTableLoading,
              expression: "showTableLoading",
            },
          ],
          key: _vm.keyNum,
          ref: "multipleTable",
          attrs: {
            data: _vm.companyList,
            border: "",
            stripe: "",
            resizable: "",
            size: "small",
            "row-class-name": _vm.tableRowClassName,
          },
          on: {
            "sort-change": _vm.handleSortChange,
            "cell-click": _vm.cellClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("template", { slot: "empty" }, [
            _c(
              "div",
              {
                staticStyle: { "min-height": "400px", "padding-top": "100px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "no-data-icon-body" },
                  [
                    _c("svg-icon", {
                      staticClass: "no-data-icon",
                      attrs: { "icon-class": "noData" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "no-data-text" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.companyList && _vm.companyList.length
            ? _c("el-table-column", {
                attrs: { type: "selection", prop: "collet", width: "45" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "edit",
              "min-width": "150",
              align: "center",
              label: "操作",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      { staticClass: "remove-i" },
                      _vm._l(_vm.btnList.slice(0, 2), function (item) {
                        return _c(
                          "span",
                          {
                            key: item.name,
                            staticClass: "com-list-detail opea",
                            staticStyle: {
                              height: "50px",
                              display: "inline-block",
                              "line-height": "50px",
                            },
                            on: {
                              click: function ($event) {
                                return item.click(scope.row, scope.$index)
                              },
                            },
                          },
                          [
                            item.name === "联系人"
                              ? _c(
                                  "span",
                                  [
                                    scope.row.contactCount
                                      ? _c(
                                          "el-badge",
                                          {
                                            staticClass: "badge-item",
                                            attrs: {
                                              value:
                                                scope.row.isKp == "1"
                                                  ? "KP"
                                                  : scope.row.contactCount,
                                              type: "primary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                联系人\n              "
                                            ),
                                          ]
                                        )
                                      : _c("span", [_vm._v(" 联系人")]),
                                  ],
                                  1
                                )
                              : _c("span", [_vm._v(" " + _vm._s(item.name))]),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.showDropDown
                      ? _c(
                          "span",
                          { staticStyle: { cursor: "pointer" } },
                          [
                            _c(
                              "el-dropdown",
                              { on: { command: _vm.jump } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                      staticStyle: { color: "#0099CC" },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  _vm._l(_vm.btnList.slice(2), function (item) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: item.name,
                                        attrs: {
                                          command: {
                                            item: item,
                                            row: scope.row,
                                            index: scope.$index,
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.name) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          !_vm.showTableLoading
            ? _c(
                "div",
                _vm._l(_vm.companyColunes, function (item, index) {
                  return _c("div", { key: index + "s" }, [
                    item.english !== "profile_match_cnt"
                      ? _c(
                          "div",
                          [
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip":
                                  item.english != "companyName" &&
                                  item.english != "perTags",
                                prop: item.english,
                                align:
                                  item.english === "companyName"
                                    ? "left"
                                    : "center",
                                width:
                                  item.english === "companyName" ||
                                  item.english === "perTag"
                                    ? "220"
                                    : "",
                                label: item.chinese,
                                sortable:
                                  item.type === "sort" ? "custom" : false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "sort_list_cont" },
                                          [
                                            item.english == "dep"
                                              ? [
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      ref: "poop",
                                                      refInFor: true,
                                                      attrs: {
                                                        placement: "bottom",
                                                        width: "400",
                                                        trigger: "click",
                                                      },
                                                    },
                                                    [
                                                      _c("el-tree", {
                                                        ref: "tree",
                                                        refInFor: true,
                                                        attrs: {
                                                          "default-expand-all":
                                                            "",
                                                          "node-key": "id",
                                                          data: _vm.departmentList,
                                                          props:
                                                            _vm.defaultProps,
                                                        },
                                                        on: {
                                                          "node-click":
                                                            _vm.nodeClick,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                node,
                                                                data,
                                                              }) {
                                                                return _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "custom-tree-node",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        class:
                                                                          data.id ==
                                                                          _vm.depId
                                                                            ? "active"
                                                                            : "",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            node.label
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "el-dropdown-link",
                                                          class: _vm.depId
                                                            ? "active"
                                                            : "",
                                                          attrs: {
                                                            slot: "reference",
                                                          },
                                                          slot: "reference",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.chinese
                                                              )
                                                            ),
                                                          ]),
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-arrow-down el-icon--right",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : [
                                                  item.type == "select"
                                                    ? [
                                                        item.chinese ===
                                                        "企业标签"
                                                          ? [
                                                              _c(
                                                                "span",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      _vm.handleOpen,
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      " 企业标签"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "svg-icon",
                                                                    {
                                                                      staticClass:
                                                                        "filterIcon",
                                                                      attrs: {
                                                                        "icon-class":
                                                                          "filterColor",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          : [
                                                              _c(
                                                                "el-dropdown",
                                                                {
                                                                  attrs: {
                                                                    placement:
                                                                      "bottom",
                                                                    trigger:
                                                                      "click",
                                                                  },
                                                                  on: {
                                                                    command: (
                                                                      val
                                                                    ) =>
                                                                      _vm.filterFunc(
                                                                        val,
                                                                        item
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "el-dropdown-link",
                                                                        class:
                                                                          _vm
                                                                            .selectOptions[
                                                                            item
                                                                              .english
                                                                          ]
                                                                            ? "active"
                                                                            : "",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.chinese
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-arrow-down el-icon--right",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-dropdown-menu",
                                                                      {
                                                                        attrs: {
                                                                          slot: "dropdown",
                                                                        },
                                                                        slot: "dropdown",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "dt-drop-down",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "source-list":
                                                                                  item.filterOptionList,
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function (
                                                                                      row
                                                                                    ) {
                                                                                      return [
                                                                                        _c(
                                                                                          "el-scrollbar",
                                                                                          {
                                                                                            staticClass:
                                                                                              "dt-dropdown-dt",
                                                                                          },
                                                                                          [
                                                                                            !row
                                                                                              .filterList
                                                                                              .length
                                                                                              ? _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "dt-drop-down-no-data",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                    无数据\n                                  "
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _vm._l(
                                                                                              row.filterList,
                                                                                              function (
                                                                                                _item
                                                                                              ) {
                                                                                                return _c(
                                                                                                  "el-dropdown-item",
                                                                                                  {
                                                                                                    class:
                                                                                                      _vm
                                                                                                        .selectOptions[
                                                                                                        item
                                                                                                          .english
                                                                                                      ] &&
                                                                                                      _vm
                                                                                                        .selectOptions[
                                                                                                        item
                                                                                                          .english
                                                                                                      ]
                                                                                                        .value ===
                                                                                                        _item.value
                                                                                                        ? "active"
                                                                                                        : "",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        command:
                                                                                                          _item.value,
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        domProps:
                                                                                                          {
                                                                                                            innerHTML:
                                                                                                              _vm._s(
                                                                                                                _item.htmlEl
                                                                                                              ),
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          2
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                ],
                                                                2
                                                              ),
                                                            ],
                                                      ]
                                                    : [
                                                        item.english ===
                                                          "leader" &&
                                                        _vm.activeTabs === "3"
                                                          ? _c(
                                                              "el-dropdown",
                                                              {
                                                                class:
                                                                  _vm.chooseUserId
                                                                    ? "drop-active"
                                                                    : "",
                                                                attrs: {
                                                                  placement:
                                                                    "bottom-start",
                                                                },
                                                                on: {
                                                                  command:
                                                                    _vm.handleCommand,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      _vm.selectLeader &&
                                                                      _vm.selectLeader !==
                                                                        "-2"
                                                                        ? "dt-active"
                                                                        : "",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                        color:
                                                                          "#909399",
                                                                        cursor:
                                                                          "pointer",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        负责人\n                        "
                                                                    ),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-arrow-down el-icon--right",
                                                                    }),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-dropdown-menu",
                                                                  {
                                                                    attrs: {
                                                                      slot: "dropdown",
                                                                    },
                                                                    slot: "dropdown",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "dt-drop-down",
                                                                      {
                                                                        attrs: {
                                                                          "source-list":
                                                                            _vm.followList,
                                                                          "target-value":
                                                                            "text",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function (
                                                                                  row
                                                                                ) {
                                                                                  return [
                                                                                    _c(
                                                                                      "el-scrollbar",
                                                                                      {
                                                                                        staticClass:
                                                                                          "dt-dropdown-dt",
                                                                                      },
                                                                                      [
                                                                                        !row
                                                                                          .filterList
                                                                                          .length
                                                                                          ? _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "dt-drop-down-no-data",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                无数据\n                              "
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _vm._l(
                                                                                          row.filterList,
                                                                                          function (
                                                                                            follow,
                                                                                            ind
                                                                                          ) {
                                                                                            return _c(
                                                                                              "el-dropdown-item",
                                                                                              {
                                                                                                key: ind,
                                                                                                class:
                                                                                                  {
                                                                                                    active:
                                                                                                      _vm.chooseUserId !==
                                                                                                        "-2" &&
                                                                                                      Number.parseInt(
                                                                                                        _vm.chooseUserId
                                                                                                      ) ===
                                                                                                        follow.value,
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    command:
                                                                                                      follow.value,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    domProps:
                                                                                                      {
                                                                                                        innerHTML:
                                                                                                          _vm._s(
                                                                                                            follow.htmlEl
                                                                                                          ),
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      2
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                class:
                                                                  _vm.selectLeader !=
                                                                    "-2" &&
                                                                  item.chinese ==
                                                                    "负责人"
                                                                    ? "sort_list_name active-drop"
                                                                    : "",
                                                                attrs: {
                                                                  title:
                                                                    item.chinese,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.chinese
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "sort_list_left",
                                                                  },
                                                                  [
                                                                    item.hasOrder
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "sort_list_tip",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "sort_list_btn sort_up",
                                                                                class:
                                                                                  item.showUp
                                                                                    ? "sort_up_choose"
                                                                                    : "sort_up",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.sortListBtn(
                                                                                        "up",
                                                                                        index,
                                                                                        item.showUp
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "sort_list_btn",
                                                                                class:
                                                                                  item.showDown
                                                                                    ? "sort_down_choose"
                                                                                    : "sort_down",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.sortListBtn(
                                                                                        "down",
                                                                                        index,
                                                                                        item.showDown
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.consumptionCount !== "0" &&
                                        item.english === "company_name"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "company_had_time_list",
                                                attrs: {
                                                  title:
                                                    "当前线索已被领取" +
                                                    scope.row.consumptionCount +
                                                    "次",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.consumptionCount
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.english === "company_name" &&
                                        _vm.currentHover === scope.$index
                                          ? _c(
                                              "span",
                                              { staticClass: "hover-tip-icon" },
                                              [_vm._v(_vm._s(_vm.hoverText))]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.english === "company_name"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "cus-name-hid",
                                                class:
                                                  scope.row.click === "1"
                                                    ? "list_opcity"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.copyComName(
                                                      item.english,
                                                      scope.$index,
                                                      scope.row
                                                    )
                                                  },
                                                  mouseover: function ($event) {
                                                    return _vm.copyHoverIn(
                                                      item.english,
                                                      scope.$index
                                                    )
                                                  },
                                                  mouseout: function ($event) {
                                                    return _vm.copyHoverOut(
                                                      item.english,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row[item.english]
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.english === "perTags"
                                          ? _c(
                                              "div",
                                              [
                                                _c("Tags", {
                                                  attrs: {
                                                    tagList: _vm.getPerTags(
                                                      scope.row["perTags"]
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                class: {
                                                  company_name:
                                                    item.english ===
                                                      "companyName" &&
                                                    _vm.isLink,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      item.english ===
                                                        "companyName" &&
                                                      scope.row["lastDay"] &&
                                                      scope.row["lastDay"] !==
                                                        -1
                                                        ? "down"
                                                        : "",
                                                    ],
                                                    style:
                                                      item.english ===
                                                        "companyName" &&
                                                      _vm.isLink
                                                        ? "cursor:pointer"
                                                        : "cursor:default",
                                                  },
                                                  [
                                                    item.chinese == "客户名称"
                                                      ? _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goKeyDetail(
                                                                      scope.row,
                                                                      item.english,
                                                                      scope.$index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    scope.row[
                                                                      item
                                                                        .english
                                                                    ]
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#8a8a8a",
                                                                cursor:
                                                                  "default",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    scope.row[
                                                                      "customerGrade"
                                                                    ]
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _c("div", [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                scope.row[
                                                                  item.english
                                                                ]
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                item.english ===
                                                  "companyName" &&
                                                scope.row["lastDay"] &&
                                                scope.row["lastDay"] !== -1
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          "visible-arrow": false,
                                                          "popper-class":
                                                            "popper",
                                                          effect: "dark",
                                                          content:
                                                            "剩余期限内无有效联系人（含姓名、职位、电话）和人为跟进记录，期限结束将会被退回“可跟进的”",
                                                          placement:
                                                            "right-start",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "earn-time",
                                                            style: {
                                                              color:
                                                                scope.row[
                                                                  "lastDay"
                                                                ] <= 7
                                                                  ? "#e20b0b"
                                                                  : "#909399",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  `剩余${scope.row["lastDay"]}天`
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.english ===
                                                  "companyName" &&
                                                scope.row["protectDay"]
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          "visible-arrow": false,
                                                          "popper-class":
                                                            "popper",
                                                          effect: "dark",
                                                          content:
                                                            "保护期内的公司不可转入自己名下",
                                                          placement:
                                                            "right-start",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "earn-time",
                                                            style: {
                                                              color:
                                                                scope.row[
                                                                  "lastDay"
                                                                ] <= 7
                                                                  ? "#e20b0b"
                                                                  : "#909399",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  `保护期${scope.row["protectDay"]}天`
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      "visible-arrow": false,
                                                      "popper-class": "popper",
                                                      effect: "dark",
                                                      content: "客户画像匹配",
                                                      placement: "bottom-start",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "inline",
                                                        },
                                                      },
                                                      [
                                                        item.english ===
                                                          "companyName" &&
                                                        scope.row.match
                                                          ? _c("svg-icon", {
                                                              directives: [
                                                                {
                                                                  name: "dt-permission",
                                                                  rawName:
                                                                    "v-dt-permission",
                                                                  value:
                                                                    _vm.permissionPro,
                                                                  expression:
                                                                    "permissionPro",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "filtrate",
                                                              attrs: {
                                                                "icon-class":
                                                                  "customerpic",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openProfile(
                                                                      scope.row,
                                                                      scope.$index
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": false,
                                prop: item.english,
                                align:
                                  item.english === "companyName"
                                    ? "left"
                                    : "center",
                                width: "100",
                                label: item.chinese,
                                sortable: "customer",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              scope.row.profile_match_cnt
                                                ? scope.row.profile_match_cnt
                                                : ""
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                  ])
                }),
                0
              )
            : _c(
                "div",
                _vm._l(_vm.companyColunes, function (item, index) {
                  return _c(
                    "div",
                    { key: index + "b" },
                    [
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            "show-overflow-tooltip":
                              item.english !== "company_name",
                            prop: item.english,
                            align:
                              item.english === "company_name"
                                ? "left"
                                : "center",
                            width: item.english === "company_name" ? "250" : "",
                            label: item.chinese,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.consumptionCount !== "0" &&
                                    item.english === "company_name"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "company_had_time_list",
                                            attrs: {
                                              title:
                                                "当前线索已被领取" +
                                                scope.row.consumptionCount +
                                                "次",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.consumptionCount)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.english === "company_name" &&
                                    _vm.currentHover === scope.$index
                                      ? _c(
                                          "span",
                                          { staticClass: "hover-tip-icon" },
                                          [_vm._v(_vm._s(_vm.hoverText))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.english === "company_name"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "cus-name-hid",
                                            class:
                                              scope.row.click === "1"
                                                ? "list_opcity"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.copyComName(
                                                  item.english,
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                              mouseover: function ($event) {
                                                return _vm.copyHoverIn(
                                                  item.english,
                                                  scope.$index
                                                )
                                              },
                                              mouseout: function ($event) {
                                                return _vm.copyHoverOut(
                                                  item.english,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row[item.english])
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goKeyDetail(
                                                  scope.row["company_uuid"],
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row[item.english]) +
                                                "\n            "
                                            ),
                                          ]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("p", { staticClass: "sort_list_cont" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "sort_list_name",
                                  class: [
                                    item.hasOrder ? "sort-icon-header" : "",
                                  ],
                                  attrs: { title: item.chinese },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.chinese) +
                                      "\n                "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "sort_list_left" },
                                    [
                                      item.hasOrder
                                        ? _c(
                                            "span",
                                            { staticClass: "sort_list_tip" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "sort_list_btn sort_up",
                                                class: item.showUp
                                                  ? "sort_up_choose"
                                                  : "sort_up",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortListBtn(
                                                      "up",
                                                      index,
                                                      item.showUp
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass: "sort_list_btn",
                                                class: item.showDown
                                                  ? "sort_down_choose"
                                                  : "sort_down",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortListBtn(
                                                      "down",
                                                      index,
                                                      item.showDown
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "商机信息",
            "show-close": false,
            visible: _vm.showChance,
            "with-header": false,
            size: "700px",
            "destroy-on-close": "",
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showChance = $event
            },
            close: _vm.closeChance,
          },
        },
        [
          _c("chance-view", {
            attrs: {
              "key-data": _vm.chanceData,
              "active-tabs": _vm.activeTabs,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }