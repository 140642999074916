var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "createChance-main" },
    [
      _c("el-scrollbar", { staticClass: "scroll-bar dt-dropdown-dt" }, [
        _c(
          "div",
          { staticClass: "main-row" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.formData,
                  rules: _vm.rules,
                  "label-width": "auto",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "nicheName", label: "商机名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入商机名称" },
                      model: {
                        value: _vm.formData.nicheName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "nicheName", $$v)
                        },
                        expression: "formData.nicheName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "pzSignSale", label: "商机提供人" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择商机提供人" },
                        model: {
                          value: _vm.formData.pzSignSale,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "pzSignSale", $$v)
                          },
                          expression: "formData.pzSignSale",
                        },
                      },
                      _vm._l(_vm.userList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "userId", label: "签单销售" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择签单销售" },
                        model: {
                          value: _vm.formData.userId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "userId", $$v)
                          },
                          expression: "formData.userId",
                        },
                      },
                      _vm._l(_vm.userList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "companyName", label: "关联公司" } },
                  [
                    _c(
                      "span",
                      {
                        class:
                          _vm.type === "create" && !_vm.isPond
                            ? "select-create"
                            : "select-edit",
                        on: { click: _vm.changeCompany },
                      },
                      [_vm._v("选择")]
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        disabled: _vm.type !== "create" || _vm.isPond,
                        placeholder: "请选择关联公司",
                      },
                      model: {
                        value: _vm.formData.companyName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "companyName", $$v)
                        },
                        expression: "formData.companyName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "gradeId", label: "客户等级" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择客户等级" },
                        model: {
                          value: _vm.formData.gradeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "gradeId", $$v)
                          },
                          expression: "formData.gradeId",
                        },
                      },
                      _vm._l(_vm.allLevel, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "stateId", label: "客户状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择客户状态" },
                        model: {
                          value: _vm.formData.stateId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "stateId", $$v)
                          },
                          expression: "formData.stateId",
                        },
                      },
                      _vm._l(_vm.allStatus, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: { prop: "predictBillMoney", label: "预估签单金额" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入预估签单金额" },
                      model: {
                        value: _vm.formData.predictBillMoney,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "predictBillMoney",
                            _vm._n($$v)
                          )
                        },
                        expression: "formData.predictBillMoney",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "predictBillDate", label: "预估签单日期" } },
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        editable: false,
                        type: "date",
                        placeholder: "请选择预估签单日期",
                        "picker-options":
                          _vm.type === "create"
                            ? _vm.startCreateDateLimit
                            : _vm.startEditDateLimit,
                        "value-format": "yyyy-MM-dd",
                      },
                      model: {
                        value: _vm.formData.predictBillDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "predictBillDate", $$v)
                        },
                        expression: "formData.predictBillDate",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "billKeyperson", label: "客户联系人" } },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "select-create",
                        on: { click: _vm.selectBillKeyPerson },
                      },
                      [_vm._v("选择")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "select-tag" },
                      _vm._l(_vm.selectUserList, function (item) {
                        return _c(
                          "el-tag",
                          {
                            staticClass: "tag",
                            attrs: { closable: "", size: "mini" },
                            on: {
                              close: function ($event) {
                                return _vm.editSelectUser(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.contact))]
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        placeholder: _vm.selectUserList.length
                          ? ""
                          : "请选择客户联系人",
                      },
                      model: {
                        value: _vm.formData.billKeyperson,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "billKeyperson", $$v)
                        },
                        expression: "formData.billKeyperson",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "nicheState", label: "商机阶段" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择商机阶段" },
                        model: {
                          value: _vm.formData.nicheState,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "nicheState", $$v)
                          },
                          expression: "formData.nicheState",
                        },
                      },
                      _vm._l(_vm.nicheStateList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.name, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "state", label: "商机状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择商机状态" },
                        model: {
                          value: _vm.formData.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "state", $$v)
                          },
                          expression: "formData.state",
                        },
                      },
                      _vm._l(_vm.nicheStatusList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.name, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "nicheRemark", label: "备注" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 4, maxRows: 10 },
                        placeholder: "请输入备注",
                      },
                      model: {
                        value: _vm.formData.nicheRemark,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "nicheRemark", $$v)
                        },
                        expression: "formData.nicheRemark",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingForm,
                  expression: "loadingForm",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            width: "800px",
            top: "90px",
            visible: _vm.showSelectCompany,
            "show-close": false,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectCompany = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slot-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v("\n        选择客户\n      ")]),
              _vm._v(" "),
              _c("div", { on: { click: _vm.cancelCompany } }, [
                _c("i", { staticClass: "el-icon-close close" }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "company-list" }, [
            _c(
              "div",
              { staticClass: "tab-div" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "dt-tab",
                    on: { "tab-click": _vm.tabClick },
                    model: {
                      value: _vm.tabType,
                      callback: function ($$v) {
                        _vm.tabType = $$v
                      },
                      expression: "tabType",
                    },
                  },
                  [
                    _vm.permissionList.includes(
                      "客户管理CRM-客户公海-我负责的-查看客户"
                    )
                      ? _c("el-tab-pane", {
                          attrs: { label: "我负责的", name: "1" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.permissionList.includes(
                      "客户管理CRM-客户公海-全公司的-查看客户"
                    )
                      ? _c("el-tab-pane", {
                          attrs: { label: "全公司的", name: "3" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.permissionList.includes(
                      "客户管理CRM-客户公海-可跟进的-查看客户"
                    )
                      ? _c("el-tab-pane", {
                          attrs: { label: "可跟进的", name: "2" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.permissionList.includes(
                      "客户管理CRM-客户公海-草稿箱-查看客户"
                    )
                      ? _c("el-tab-pane", {
                          attrs: { label: "草稿箱", name: "4" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "search-input" }, [
              _c(
                "div",
                { staticStyle: { width: "630px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入公司查询", size: "small" },
                    model: {
                      value: _vm.companyName,
                      callback: function ($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchCompany },
                    },
                    [_vm._v("\n            查询\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.reset } },
                    [_vm._v(" 重置 ")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.selectCompany.companyName
              ? _c(
                  "div",
                  { staticClass: "tip" },
                  [
                    _c(
                      "el-tag",
                      {
                        attrs: { size: "small", closable: "" },
                        on: { close: _vm.delCompany },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.selectCompany.companyName) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table" },
              [
                _c("dt-table", {
                  key: _vm.refreshKey,
                  attrs: {
                    "data-total": _vm.pageStatus.total,
                    "hide-border": true,
                    "is-add-earn-field": false,
                    "is-count-height": false,
                    "shift-field": _vm.shiftField,
                    "column-width-list": { select: 50 },
                    "table-h": "400px",
                    "is-select": false,
                    "column-list": _vm.columns,
                    "table-loading": _vm.loading,
                    "table-data": _vm.companyList,
                  },
                  on: {
                    sizeChange: _vm.sizeChange,
                    currentChange: _vm.currentChange,
                    singleSelectChange: _vm.singleSelectChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "selectRow",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            [
                              _c("el-checkbox", {
                                attrs: {
                                  value:
                                    row.scope.data.id === _vm.selectCompany.id,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: { click: _vm.cancelCompany },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.confirmCompany },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            width: "850px",
            top: "90px",
            visible: _vm.showSelectUser,
            "show-close": false,
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectUser = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slot-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v("\n        选择签单决策人\n      ")]),
              _vm._v(" "),
              _c("div", { on: { click: _vm.cancelSelectUser } }, [
                _c("i", { staticClass: "el-icon-close close" }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-list" },
            [
              _c("selectBillKeyPersion", {
                key: _vm.refreshSelectKey,
                attrs: {
                  "company-name": _vm.selectCompany.companyName,
                  "is-active-tab": _vm.isActiveTab,
                  "current-check-row-prop": _vm.selectUserList,
                  "package-id": _vm.selectCompany.packageId,
                  "company-uuid": _vm.selectCompany.companyUuid,
                },
                on: { closeDialog: _vm.closeDialog, getRes: _vm.getSelectUser },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }