var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chain-address-reserve", style: _vm.powerStyle },
    [
      _c("div", { staticClass: "header", class: _vm.getClass }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("PeSelect", {
              class: _vm.selectStyle,
              staticStyle: { width: "140px", "margin-left": "0" },
              attrs: {
                popperClass: _vm.getPopperClass,
                optionsList: _vm.cityOptions,
                defaultSelect: _vm.currentCity,
                selectSize: "mini",
              },
              on: { handleSelectChange: _vm.handleCityChange },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "add" },
          _vm._l(_vm.addTab, function (item) {
            return _c(
              "li",
              {
                key: item.name,
                class: _vm.computedTopClass(item),
                on: {
                  click: function ($event) {
                    return _vm.handleTopClick(item)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "operate",
            style: _vm.computedMode ? "opacity:1" : "opacity:0",
          },
          [
            _c(
              "div",
              { staticClass: "screen", on: { click: _vm.handleScreen } },
              [
                _c("svg-icon", {
                  staticStyle: { "font-size": "20px" },
                  attrs: {
                    "icon-class": _vm.model ? "screen-dark" : "screen-light",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("一键投屏")]),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-switch", {
              on: { change: _vm.handleSwitchChange },
              model: {
                value: _vm.model,
                callback: function ($$v) {
                  _vm.model = $$v
                },
                expression: "model",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("AiMap", {
        ref: "mapStore",
        on: { getCurrentCity: _vm.getCurrentCity },
      }),
      _vm._v(" "),
      _c("Panel", { attrs: { cityType: _vm.cityType, model: _vm.model } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }