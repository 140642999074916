var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.moduleParams, function (moduleParam, index) {
      return _vm.columType(moduleParam)
        ? _c(
            "div",
            {
              key: index,
              class: _vm.listshow(moduleParam),
              staticStyle: { position: "relative" },
              attrs: { id: `scrollto-${moduleParam.module_title}` },
            },
            [
              _c("div", {
                class: _vm.listshowMao(moduleParam),
                staticStyle: { position: "absolute", top: "-42px" },
              }),
              _vm._v(" "),
              _c("module-pack", {
                ref: "modulePackList",
                refInFor: true,
                attrs: {
                  moduleParam: moduleParam,
                  isReceive: _vm.detail.show,
                  detail: _vm.detail,
                  "package-id": _vm.packageId,
                },
              }),
            ],
            1
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }