<template>
  <div class="dingding-login">
    <div
      v-loading="loading"
      element-loading-text="登录中"
      class="loading"
    ></div>
  </div>
</template>

<script>
import cookie from 'js-cookie'
import { ddAuth } from '@/api/user'
import store from '@/store'
import { ResetAuthUserMeal, ResetSystemConfig } from '@/utils/auth'
import { AppWebSockt } from '@/utils/websocket'
import expiredTip from '@/views/login/dialog/expiredTip'
import { setPermission } from '@/cores/RouteAndPermission'
export default {
  name: 'ddLogin',
  data() {
    return {
      loading: true,
      type: this.$route.query.from === '/dd-scrm-login' ? 'scrm' : ''
    }
  },
  mounted() {
    if (self != top) return
    this.login()
  },
  methods: {
    login() {
      cookie.remove('token')
      this.$dd.ready(() => {
        const _corpId = this.$route.query.cid
        const corpId =
          _corpId && _corpId !== 'undefined'
            ? _corpId
            : localStorage.getItem('corpId')
        this.$dd.runtime.permission.requestAuthCode({
          corpId: corpId,
          onSuccess: async result => {
            const code = result.code
            const applicationType = this.type === 'scrm' ? 2 : 1
            ddAuth({ code, corpId, applicationType })
              .then(async res => {
                if (res.code === 60000 && res.data) {
                  localStorage.clear()
                  sessionStorage.clear()
                  if (corpId) {
                    localStorage.setItem('corpId', corpId)
                  }
                  const token = res.data.token
                  const step = res.data.step
                  const accountType = res.data.type
                  const userName = res.data.followName || ''
                  store.commit('setToken', token)
                  await store.dispatch('changeStep', step)
                  await store.dispatch('setName', userName)
                  localStorage.setItem('setAccountType', accountType + '')
                  localStorage.setItem('dtBird_user_step', step)
                  localStorage.setItem('setName', userName)
                  localStorage.setItem('userLoginTime', new Date().getTime())
                  if (step != 1) {
                    ResetAuthUserMeal()
                  }
                  ResetSystemConfig() //获取系统信息
                  AppWebSockt('kick_user_offline')
                  await setPermission()
                  localStorage.setItem(
                    'permissionObj',
                    JSON.stringify(store.state.permission.permission)
                  )
                  localStorage.setItem(
                    'menuList',
                    JSON.stringify(store.state.permission.menuList)
                  )
                  localStorage.setItem(
                    'panelList',
                    JSON.stringify(store.state.permission.panelList)
                  )

                  this.$store.commit('update', {
                    target: this.$store.state.user,
                    data: {
                      isScrm: this.type === 'scrm'
                    }
                  })
                  if (this.type === 'scrm') {
                    localStorage.setItem('isScrm', 'true')
                    sessionStorage.setItem('menuType', 'scrm')
                    this.$router.replace('/customerpond/customerpond')
                    return
                  } else {
                    this.$router.replace('/dt-index')
                  }
                }
                if (res.code === 61004) {
                  this.loading = false
                  await this.$store.dispatch('showModal', {
                    view: expiredTip,
                    size: '500px',
                    title: '提示'
                  })
                }
                if (!res.data && res.code !== 61004) {
                  this.loading = false
                  await this.$store.dispatch('showTip', {
                    text: res.msg,
                    type: 'error'
                  })
                }
              })
              .catch(err => {
                this.loading = false
                this.$store.dispatch('showTip', {
                  text: '免登失败,请关闭此页面重试',
                  type: 'error'
                })
              })
          },
          onFail: err => {
            this.loading = false
            this.$store.dispatch('showTip', {
              text: '免登失败,请关闭此页面重试',
              type: 'error'
            })
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.dingding-login {
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading {
  width: 100px;
}
</style>
