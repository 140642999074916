
<template>
  <div class="setingWaihu-main">
    <div class="waihu-dialog-main">
      <el-form>
        <el-form-item label="呼叫间隔(秒)">
          <el-input-number v-model="callInterval" :min="1" label="设置呼叫间隔" size="small"></el-input-number>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <div>
        <el-button size="small" @click="cancelBtn">取消</el-button>
        <el-button size="small" type="primary" @click="confirmBtn">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { editWaiHuSetting } from '@/api/customerpond'
export default {
  name: 'setTingWaiHu',
  data () {
    return {
      callInterval: 0
    }
  },
  mounted () {
    this.callInterval = this.$store.state.plugin.openAutoCallTime || 10
  },
  computed: {},
  methods: {
    cancelBtn(){
      this.$store.dispatch('hideModal')
    },
    confirmBtn(){
      editWaiHuSetting({ callInterval: this.callInterval }).then(res=>{
          if(res.code === 60000){
            this.$store.dispatch('showTip',{
              text: res.msg,
              type: 'success'
            })
            // 更新外呼间隔
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                openAutoCallTime: this.callInterval
              }
            })
            this.cancelBtn()
          }
      })
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.waihu-dialog-main{
  height: 200px;
  width: 100%;
  padding: 10px 20px 0 20px;
  box-sizing: border-box;
}
.footer{
  display: flex;
  justify-content: flex-end;
  height: 50px;
  position: relative;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.1);
  transform: scaleY(.5);
}
</style>
