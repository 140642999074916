<template>
  <div class="pe-store-page" :style="getStoreStyle">
    <div
      id="storeMap"
      ref="storeRef"
      class="store-map"
      v-loading="loading"
      element-loading-text="加载中"
    >
      <infoWindow ref="info" v-show="infoWindow" :info-data="infoWindowData" />
    </div>
    <FullScreen :refDom="$refs.storeRef" />
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { getStoreData } from '@/api/module'
import infoWindow from '@/components/Peculiar/store/info'
import FullScreen from '@/components/Peculiar/expend'
export default {
  name: 'PeStoreMap',
  props: {
    storeType: {
      type: String,
      default: '1'
    },
    mapUuid: {
      type: String,
      default: ''
    }
  },
  components: {
    infoWindow,
    FullScreen
  },
  data() {
    return {
      map: null,
      loading: false,
      district: null,
      clickCity: '',
      polygons: [],
      mapOption: {
        level: 1,
        type: 1,
        companyUuid: this.$route.query.companyUuid
      },
      infoWindow: null,
      infoWindowData: {},
      canReq: true,
      storeList: [],
      isFull: false,
      fullStyle: `
        height:100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
      `
    }
  },
  watch: {
    storeType: {
      handler(val) {
        this.mapOption.type = val
        this.getMapData()
      },
      deep: true,
      immediate: true
    },
    mapUuid: {
      handler() {
        this.getMapData()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    getStoreStyle() {
      return this.isFull ? `position:static` : 'position:relative'
    }
  },
  methods: {
    init() {
      this.map = new window.dtMap.Map('storeMap', {
        zoom: 5,
        resizeEnable: true
      })
      this.map.on('complete', () => {
        this.map.plugin(['AMap.ToolBar'], () => {
          this.map.addControl(new AMap.ToolBar())
        })
        this.loading = false
        this.map.on(
          'moveend',
          debounce(() => {
            if (this.canReq) this.getMapLngLatAndZoom()
          }, 1000)
        )
      })
    },

    getMapLngLatAndZoom() {
      const bound = this.map.getBounds()
      const northWest = bound.getNorthWest()
      const southEast = bound.getSouthEast()
      let zoom = this.map.getZoom()
      console.log(bound, northWest, southEast)
      if (zoom >= 13) {
        zoom = 4 // 公司
      } else if (zoom >= 10 && zoom < 13) {
        zoom = 3 // 区、县
      } else if (zoom >= 7 && zoom < 10) {
        zoom = 2 // 市
      } else {
        zoom = 1 // 省
      }
      //只在区域筛选
      if (zoom != 4) {
        this.clickCity = ''
      }

      this.mapOption = {
        level: zoom,
        topLat: northWest.lat,
        bottomLat: southEast.lat,
        leftLng: northWest.lng,
        rightLng: southEast.lng,
        targetRegionName: this.clickCity,
        companyUuid: this.$route.query.companyUuid,
        type: this.storeType
      }
      this.getMapData(zoom)
    },

    async getMapData(zoom) {
      if (!this.canReq) return
      this.loading = true
      //门店数据
      const data = await getStoreData(this.mapOption)
      this.loading = false
      this.map?.clearMap()
      this.storeList = data.data
      this.addMapMarker(zoom)
    },

    addMapMarker(zoom) {
      this.storeList.forEach(i => {
        i.name = i.companyName || i.district || i.city || i.province
        let startMarker = new AMap.Marker({
          position: [i.longitude, i.latitude],
          offset: new AMap.Pixel(-13, -30),
          content: this.getContent(`${i.name}`, i.count, zoom)
        })
        this.map?.add(startMarker)

        // 点击覆盖物
        startMarker.on('click', () => {
          this.clickCity = i.name
          console.log(i, zoom, 'click')
          if (zoom !== 4) {
            if (zoom === 3) {
              this.map.setZoom(13)
            } else if (zoom === 2) {
              this.map.setZoom(11)
            } else {
              const city = ['重庆市', '上海市', '天津市', '北京市']
              city.includes(i.name) ? this.map.setZoom(11) : this.map.setZoom(8)
            }
            this.map.setCenter([i.longitude, i.latitude])
          }
        })

        // 鼠标进入覆盖物
        startMarker.on('mouseover', () => {
          if (zoom === 4) {
            this.showPop(i)
          }
        })

        // 鼠标离开覆盖物
        startMarker.on('mouseout', () => {
          this.infoWindow?.close()
          this.canReq = true
        })
      })
    },

    getContent(name, value, zoom) {
      if (zoom !== 4) {
        name = name
          .replace('新疆维吾尔自治区', '新疆')
          .replace('西藏自治区', '西藏')
          .replace('内蒙古自治区', '内蒙古')
          .replace('广西壮族自治区', '广西')
          .replace('宁夏回族自治区', '宁夏')
        return `
          <div class="store-map-marker">
            <div>${name}</div>
            <div class="park-map-num">${value}家</div>
          </div>
        `
      } else {
        return `<div class="store-map-marker-cname">
           <i class="el-icon-map-location" style='font-size:25px'/>
          </div>`
      }
    },

    showPop(data) {
      const el = this.$refs.info.$el
      this.infoWindow = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        content: el,
        offset: new AMap.Pixel(-13, -30),
        autoMove: true
      })
      this.infoWindowData = data
      this.infoWindow.open(this.map, [data.longitude, data.latitude])
      this.canReq = false
    }
  },
  beforeDestroy() {
    this.map.off('moveend')
    this.map.destroy()
  }
}
</script>

<style lang="scss">
.pe-store-page {
  position: relative;
  z-index: 1;
  .store-map {
    width: 100%;
    height: 600px;
  }

  .store-map-marker {
    height: 80px;
    width: 80px;
    background-color: #1f6cdde6;
    border: 1px solid transparent;
    color: white;
    padding: 2px;
    border-radius: 50%;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }
  .store-map-marker:hover {
    background-color: orange;
  }
  .park-map-num {
    margin-top: 5px;
    font-size: 16px;
  }
  .store-map-marker-cname {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #1f6cdd;
    color: #fff;
    white-space: nowrap;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  .store-full {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 4px 3px #959595;
    width: 35px;
    height: 35px;
  }
  .scroll-area {
    position: absolute;
    width: 15%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 998;
  }
  .title {
    font-size: 16px;
    padding: 10px;
    color: #2e2e2e;
  }
}
</style>
