<template>
  <div class="oace-key-table">
    <el-table
      :data="companyList"
      border
      stripe
      resizable
      ref="multipleTable"
      size="small"
      @sort-change="handleSortChange"
      @cell-click="cellClick"
      v-loading="showTableLoading"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
      style="height:100%;width: 100%;"
    >
      <template slot="empty">
        <div
          style="height:calc( 100vh - 211px );width:100%;display: flex;justify-content: center;align-items: center;"
        >
          暂无数据源
        </div>
      </template>
      <el-table-column prop="edit" width="1" label="">
        <template slot-scope="scope">
          <span
            class="com-list-detail"
            @click="goKeyDetail(scope.row['company_uuid'], scope.$index)"
          ></span>
        </template>
      </el-table-column>
      <div v-if="!showTableLoading">
        <div v-for="(item, index) in companyColunes" :key="index + 's'">
          <el-table-column
            show-overflow-tooltip
            :prop="item.field"
            align="center"
            :label="item.name"
          >
            <template slot="header">
              <p class="sort_list_cont">
                <span
                  class="sort_list_name"
                  :class="[item.hasOrder ? 'sort-icon-header' : '']"
                  :title="item.name"
                >
                  {{ item.name }}
                  <span class="sort_list_left">
                    <span class="sort_list_tip" v-if="item.hasOrder">
                      <i
                        class="sort_list_btn sort_up"
                        :class="item.showUp ? 'sort_up_choose' : 'sort_up'"
                        @click="sortListBtn('up', index, item.showUp)"
                      >
                      </i>
                      <i
                        class="sort_list_btn"
                        :class="
                          item.showDown ? 'sort_down_choose' : 'sort_down'
                        "
                        @click="sortListBtn('down', index, item.showDown)"
                      >
                      </i>
                    </span>
                  </span>
                </span>
              </p>
            </template>
            <template slot-scope="scope">
              <span
                @click="goKeyDetail(scope.row['company_uuid'], scope.$index)"
                style="cursor:pointer;"
              >
                {{ scope.row[item.field] }}
              </span>
            </template>
          </el-table-column>
        </div>
      </div>

      <div v-else>
        <div v-for="(item, index) in companyColunes" :key="index + 'b'">
          <el-table-column
            :show-overflow-tooltip="item.english !== 'company_name'"
            :prop="item.english"
            :align="item.english === 'company_name' ? 'left' : 'center'"
            :width="item.english === 'company_name' ? '250' : ''"
            :label="item.chinese"
          >
            <template slot="header">
              <p class="sort_list_cont">
                <span
                  class="sort_list_name"
                  :class="[item.hasOrder ? 'sort-icon-header' : '']"
                  :title="item.chinese"
                >
                  {{ item.chinese }}
                  <span class="sort_list_left">
                    <span class="sort_list_tip" v-if="item.hasOrder">
                      <i
                        class="sort_list_btn sort_up"
                        :class="item.showUp ? 'sort_up_choose' : 'sort_up'"
                        @click="sortListBtn('up', index, item.showUp)"
                      >
                      </i>
                      <i
                        class="sort_list_btn"
                        :class="
                          item.showDown ? 'sort_down_choose' : 'sort_down'
                        "
                        @click="sortListBtn('down', index, item.showDown)"
                      >
                      </i>
                    </span>
                  </span>
                </span>
              </p>
            </template>
            <template slot-scope="scope">
              <span
                class="company_had_time_list"
                :title="'当前线索已被领取' + scope.row.consumptionCount + '次'"
                v-if="
                  scope.row.consumptionCount !== '0' &&
                    item.english === 'company_name'
                "
              >
                {{ scope.row.consumptionCount }}
              </span>
              <span
                class="hover-tip-icon"
                v-if="
                  item.english === 'company_name' &&
                    currentHover === scope.$index
                "
              >
                {{ hoverText }}
              </span>
              <span
                v-if="item.english === 'company_name'"
                class="cus-name-hid"
                @click="copyComName(item.english, scope.$index, scope.row)"
                @mouseover="copyHoverIn(item.english, scope.$index)"
                @mouseout="copyHoverOut(item.english, scope.$index)"
                :class="scope.row.click === '1' ? 'list_opcity' : ''"
              >
                {{ scope.row[item.english] }}
              </span>
              <span
                v-else
                @click="goKeyDetail(scope.row['company_uuid'], scope.$index)"
                style="cursor:pointer;"
              >
                {{ scope.row[item.english] }}
              </span>
            </template>
          </el-table-column>
        </div>
      </div>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    companyList: {
      type: Array,
      default: () => []
    },
    companyColunes: {
      type: Array,
      default: () => []
    },
    checkAll: {
      type: Array,
      default: () => []
    },
    keyPackageList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentHover: null,
      hoverText: '点击复制',
      sortOrders: [],
      showTableLoading: false
    }
  },
  created() {},
  mounted() {},
  methods: {
    init(val) {},
    goPackMarket() {
      this.$router.push({
        path: '/market/market'
      })
    },
    openLoading() {
      this.showTableLoading = true
    },
    closeLoading(val) {
      this.showTableLoading = false

      val.forEach((item, index) => {
        this.$set(this.companyColunes, index, item)
      })
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    cellClick(row, column, cell, e) {
      if (column.property === 'collet') {
        return
      }
      this.goKeyDetail(row.company_uuid, row.index)
    },
    goKeyDetail(id, index) {
      const params = {
        id: id,
        index: index
      }
      this.$emit('goKeyDetail', params)
    },
    del_collect(val) {
      this.$emit('del_collect', val)
    },
    add_collect(val) {
      this.$emit('add_collect', val)
    },
    sortListBtn(val, index, type) {
      const currCom = this.companyColunes[index]
      if (val === 'up') {
        this.$set(currCom, 'showDown', !type)
        if (!type) {
          this.$set(currCom, 'showUp', false)
        }
      }
      if (val === 'down') {
        this.$set(currCom, 'showDown', !type)
        if (!type) {
          this.$set(currCom, 'showUp', false)
        }
      }
      const params = {
        type: val,
        index: index,
        showType: type
      }
      this.$emit('sortListBtn', params)
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    toggleSelection(rows) {
      this.$refs.multipleTable.clearSelection()
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSortChange(sortValue) {},
    copyComName(val, index, item) {
      if (val === 'company_name') {
        event.stopPropagation()
        var oInput = document.createElement('input')
        oInput.value = item[val]
        document.body.appendChild(oInput)
        this.selectText(oInput)
        document.execCommand('Copy')
        oInput.className = 'oInput'
        oInput.style.display = 'none'
        this.hoverText = '复制成功!'
        setTimeout(() => {
          this.currentHover = null
        }, 500)
      }
    },
    selectText(oInput) {
      if (oInput.createTextRange) {
        //ie
        oInput.select()
      } else {
        //firefox/chrome
        oInput.setSelectionRange(0, oInput.value.length)
        oInput.focus()
      }
    },
    copyHoverIn(val, index) {
      if (val === 'company_name') {
        this.currentHover = index
        this.hoverText = '点击复制'
      }
    },
    copyHoverOut(val, index) {
      if (val === 'company_name') {
        this.currentHover = null
      }
    }
  }
}
</script>

<style scoped>
.no-data-icon-body {
  width: 100%;
  height: 50px;
  margin: 0 auto;
}
.no-data-icon {
  height: 40px;
  width: 35px;
}
.cus-name-hid {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.sort_down {
  top: 16px;
  background: url('../../assets/no-down.png') no-repeat;
  background-size: 100% 100%;
  background-position: top;
}
.sort_down_choose {
  top: 16px;
  background: url('../../assets/choose-down.png') no-repeat;
  background-size: 100% 100%;
  background-position: top;
}
.sort_up {
  top: 5px;
  background: url('../../assets/no-up.png') no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}
.sort_up_choose {
  top: 5px;
  background: url('../../assets/choose-up.png') no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}
.sort_list_btn {
  height: 8px;
  width: 11px;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  left: 0;
  cursor: pointer;
}
.sort_list_tip {
  display: inline-block;
  width: 12px;
  height: 40px;
  position: relative;
}
.sort_list_left {
  position: absolute;
  right: 0px;
  top: -4px;
  display: inline-block;
}
.sort-icon-header {
  position: relative;
  padding-right: 20px;
}
.com-list-detail {
  color: #0099cc;
  cursor: pointer;
}
.list_opcity {
  /* background: #E7FFFD; */
  opacity: 0.5;
}
.company_had_time_list {
  position: absolute;
  left: 0;
  top: 0px;
  display: inline-block;
  height: 15px;
  border-radius: 8px;
  background: #41cabf;
  color: white;
  padding: 0 5px 0 5px;
  line-height: 15px;
  text-align: center;
  font-size: 12px;
}
.hover-tip-icon {
  position: absolute;
  display: inline-block;
  width: 60px;
  text-align: center;
  height: 30px;
  border: 1px solid #979797;
  border-radius: 5px;
  left: 20px;
  top: -20px;
  color: #979797;
  line-height: 28px;
  font-size: 12px;
  background: white;
}
.sort_list_cont {
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.sort_list_name {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  overflow: hidden;
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.oace-key-table {
  padding-top: 10px;
}
.oace-key-table ::v-deep .el-table--border th {
  border-right: 1px solid #ebeef5;
  height: 50px;
}
.oace-key-table ::v-deep .el-table--border th:nth-child(1) {
  height: 50px;
}
.oace-key-table ::v-deep .el-table--border th:nth-child(2) {
  height: 50px;
}
.oace-key-table
  ::v-deep
  .el-table--enable-row-transition
  .el-table__body
  tbody
  tr
  td {
  border-right: none;
}
.oace-key-table ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
</style>
