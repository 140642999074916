var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "calc( 100% - 80px )" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "info-share" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v("分享标题、简介与头图"),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", [
                  _vm.fontStyle.share["title"] === ""
                    ? _c("p", { staticClass: "title-share" }, [
                        _vm._v(
                          "\n              请输入落地页标题\n            "
                        ),
                      ])
                    : _c("p", { staticClass: "title-share" }, [
                        _vm._v(_vm._s(_vm.fontStyle.share["title"])),
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "desc-share" }, [
                    _c("span", [_vm._v(_vm._s(_vm.fontStyle.share["desc"]))]),
                    _vm._v(" "),
                    _vm.fontStyle.share["img"] === ""
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              width: "50px",
                              height: "50px",
                              "text-align": "center",
                              "line-height": "50px",
                              background: "rgb(216, 216, 216)",
                            },
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "linkUrl",
                              staticStyle: { width: "20px", height: "20px" },
                              attrs: { "icon-class": "linkUrl" },
                            }),
                          ],
                          1
                        )
                      : _c("img", {
                          attrs: { src: _vm.fontStyle.share["img"] },
                        }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { display: "flex", "justify-content": "center" },
              attrs: { span: 12 },
            },
            [
              _c(
                "div",
                { staticClass: "iphone" },
                [
                  _c("div", { staticClass: "iphone-top" }, [
                    _c("span", { staticClass: "camera" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sensor" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "speaker" }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "top-bar" }),
                  _vm._v(" "),
                  _c("el-scrollbar", { staticClass: "scroll" }, [
                    _c("div", { staticClass: "iphone-screen" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.fontStyle.show.video,
                              expression: "fontStyle.show.video",
                            },
                          ],
                          staticClass: "video-row",
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.fontStyle.videoUrl,
                                expression: "!fontStyle.videoUrl",
                              },
                            ],
                            staticClass: "el-icon-video-play",
                          }),
                          _vm._v(" "),
                          _c("video", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.fontStyle.videoUrl,
                                expression: "fontStyle.videoUrl",
                              },
                            ],
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: _vm.fontStyle.videoUrl,
                              controls: "controls",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.fontStyle.show.photo,
                              expression: "fontStyle.show.photo",
                            },
                          ],
                          staticClass: "image-row",
                        },
                        [
                          _c(
                            "el-image",
                            { attrs: { src: _vm.fontStyle.uploadUrl } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-picture-outline",
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.fontStyle.show.content,
                              expression: "fontStyle.show.content",
                            },
                          ],
                          staticClass: "content-row",
                          style: {
                            fontFamily: _vm.fontStyle.fontFamily,
                            backgroundColor: _vm.fontStyle.textBg,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-content",
                              style: {
                                fontFamily: _vm.fontStyle.fontFamily,
                                fontSize: _vm.fontStyle.fontSize + "px",
                                color: _vm.fontStyle.fontColor,
                                fontWeight: _vm.fontStyle.fontWeight,
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.fontStyle.textareaCont) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.fontStyle.show.input &&
                                _vm.fontStyle.checkedArr.length,
                              expression:
                                "fontStyle.show.input && fontStyle.checkedArr.length",
                            },
                          ],
                          staticClass: "input-row",
                          style: { backgroundColor: _vm.fontStyle.pagraBg },
                        },
                        [
                          _c(
                            "el-form",
                            {
                              staticStyle: { width: "90%" },
                              attrs: { "label-width": "80px", size: "small" },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.fontStyle.checkedArr.includes(
                                          "name"
                                        ),
                                      expression:
                                        "fontStyle.checkedArr.includes('name')",
                                    },
                                  ],
                                  attrs: { label: "姓名" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入姓名" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.fontStyle.checkedArr.includes(
                                          "phone"
                                        ),
                                      expression:
                                        "fontStyle.checkedArr.includes('phone')",
                                    },
                                  ],
                                  attrs: { label: "联系方式" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入联系方式" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.fontStyle.checkedArr.includes(
                                          "company"
                                        ),
                                      expression:
                                        "fontStyle.checkedArr.includes('company')",
                                    },
                                  ],
                                  attrs: { label: "公司名称" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入公司名称" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.fontStyle.show.button,
                                  expression: "fontStyle.show.button",
                                },
                              ],
                              staticClass: "input-row-btn",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.fontStyle.buttonText) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c("span", { staticClass: "on-off" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sleep" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "up" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "down" }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "bottom-bar" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "iphone-bottom" }, [_c("span")]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }