<template>
  <div class="pond-contacts-drawr">
    <div class="pond-contacts-top">
      <p style="font-size:14px;">联系人信息</p>
      <div class="search-botton" style="margin-left:10px;">添加联系人</div>
    </div>
  </div>
</template>

<script>
import { getCusFollowRecords } from '@/api/customerpond'
export default {
  props: {},
  data() {
    return {}
  },
  created() {},
  methods: {},
  // 监听父组件传值id
  watch: {}
}
</script>

<style scoped>
.pond-contacts-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
}
.new_card_tag {
  display: inline-block;
  height: 16px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 16px;
  color: #41cabf;
  margin-right: 10px;
  margin-top: 3px;
  background-color: #e7fffd;
  cursor: pointer;
}
.pond-contacts-drawr {
  width: 100%;
  height: 100%;
  padding: 40px 30px;
}
.search-botton {
  width: 80px;
  height: 30px;
  float: left;
  position: relative;
  top: 10px;
  margin-left: 20px;
  background-color: #78d9d1;
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

/* 联系人内容区 */

.pond-contacts-cont {
  width: 100%;
  height: 100%;
}
.pond-contacts-list {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pond-contacts-detail {
  width: 30%;
  height: 134px;
}
</style>
