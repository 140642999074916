var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pe-panel-page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.form.store,
            expression: "form.store",
          },
        ],
        staticClass: "l-panel",
      },
      [
        _c("div", { class: _vm.numClass }, [
          _c("div", { staticClass: "l-item" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("span", { staticClass: "notice" }, [
              _vm._v(" " + _vm._s(_vm.currentCity)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.numClass }, [
          _c("div", { staticClass: "l-item" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("span", { staticClass: "notice" }, [
              _vm._v(_vm._s(_vm.pointData.investmentPromotion)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.numClass }, [
          _c("div", { staticClass: "l-item" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("span", { staticClass: "notice" }, [
              _vm._v(" " + _vm._s(_vm.pointData.operating)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.numClass }, [
          _c("div", { staticClass: "l-item" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("span", { staticClass: "notice" }, [
              _vm._v(" " + _vm._s(_vm.pointData.decoration)),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.panelType == "store"
      ? _c("div", { staticClass: "right-panel" }, [
          _c("div", { class: _vm.model ? "dark-panel" : "panel" }, [
            _c("div", { staticClass: "operate-panel" }, [
              _c("div", { staticClass: "border-list" }, [
                _c("div", { staticClass: "border-item" }, [
                  _c("div", { staticClass: "item-spec" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("热力图")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("span", [_vm._v("客流")]),
                        _vm._v(" "),
                        _c("el-switch", {
                          on: { change: _vm.handleDistribute },
                          model: {
                            value: _vm.form.scatter,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "scatter", $$v)
                            },
                            expression: "form.scatter",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.scatter,
                            expression: "form.scatter",
                          },
                        ],
                        staticClass: "item",
                      },
                      [
                        _c("PeSelect", {
                          class: _vm.selectStyle,
                          staticStyle: { "margin-left": "0" },
                          attrs: {
                            clearable: false,
                            selectSize: "mini",
                            popperClass: _vm.getPopperClass,
                            optionsList: _vm.schemaList,
                          },
                          on: { handleSelectChange: _vm.handleSchema },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "border-item border-top" }, [
                  _c("div", { staticClass: "item-spec" }, [
                    _c(
                      "div",
                      { staticClass: "item-brand" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("竞争品牌"),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          on: { change: _vm.handleCompeteSwitch },
                          model: {
                            value: _vm.form.compete,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "compete", $$v)
                            },
                            expression: "form.compete",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.compete,
                          expression: "form.compete",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("PeSelect", {
                            ref: "compete",
                            class: _vm.selectStyle,
                            staticStyle: { "margin-left": "0" },
                            attrs: {
                              popperClass: _vm.getPopperClass,
                              optionsList: _vm.competeList,
                              isMultiple: true,
                              selectSize: "mini",
                              placeholder: "请选择竞争品牌",
                            },
                            on: {
                              handleSelectChange: _vm.handleCompete,
                              handleVisibleChange: _vm.handleCompeteVisible,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("服务半径")]),
                          _vm._v(" "),
                          _c("PeSelect", {
                            class: _vm.selectStyle,
                            staticStyle: { "margin-left": "0", width: "90px" },
                            attrs: {
                              popperClass: _vm.getPopperClass,
                              optionsList: _vm.rangeList,
                              defaultSelect: _vm.initCompete,
                              selectSize: "mini",
                            },
                            on: { handleSelectChange: _vm.handleCompeteChange },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "border-item border-top" }, [
                  _c("div", { staticClass: "item-spec" }, [
                    _c(
                      "div",
                      { staticClass: "item-brand" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("跟随品牌"),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          on: { change: _vm.handleFollowSwitch },
                          model: {
                            value: _vm.form.follow,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "follow", $$v)
                            },
                            expression: "form.follow",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.follow,
                          expression: "form.follow",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("PeSelect", {
                            ref: "follow",
                            class: _vm.selectStyle,
                            staticStyle: { "margin-left": "0" },
                            attrs: {
                              popperClass: _vm.getPopperClass,
                              optionsList: _vm.followList,
                              isMultiple: true,
                              placeholder: "请选择跟随品牌",
                              selectSize: "mini",
                            },
                            on: {
                              handleVisibleChange: _vm.handleFollowVisible,
                              handleSelectChange: _vm.handleFollow,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("服务半径")]),
                          _vm._v(" "),
                          _c("PeSelect", {
                            class: _vm.selectStyle,
                            staticStyle: { "margin-left": "0", width: "90px" },
                            attrs: {
                              popperClass: _vm.getPopperClass,
                              optionsList: _vm.rangeList,
                              defaultSelect: _vm.initFollow,
                              selectSize: "mini",
                            },
                            on: { handleSelectChange: _vm.handleFollowChange },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "border-item border-top" }, [
                  _c("div", { staticClass: "item-spec" }, [
                    _c(
                      "div",
                      { staticClass: "item-brand" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("招商中的点位"),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          on: { change: _vm.handleInvestSwitch },
                          model: {
                            value: _vm.form.invest,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "invest", $$v)
                            },
                            expression: "form.invest",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "border-item border-top" }, [
                  _c("div", { staticClass: "item-spec" }, [
                    _c(
                      "div",
                      { staticClass: "item-brand" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("营业中的点位"),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          on: { change: _vm.handleBusinessSwitch },
                          model: {
                            value: _vm.form.business,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "business", $$v)
                            },
                            expression: "form.business",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "border-item border-top" }, [
                  _c("div", { staticClass: "item-spec" }, [
                    _c(
                      "div",
                      { staticClass: "item-brand" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("装修中的点位"),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          on: { change: _vm.handleFitmentSwitch },
                          model: {
                            value: _vm.form.fitment,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fitment", $$v)
                            },
                            expression: "form.fitment",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "l-top" }, [
      _c("p", [_vm._v("招商城市")]),
      _vm._v(" "),
      _c("span", { staticClass: "dot city" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "l-top" }, [
      _c("p", [_vm._v("招商中")]),
      _vm._v(" "),
      _c("span", { staticClass: "dot zs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "l-top" }, [
      _c("p", [_vm._v("营业中")]),
      _vm._v(" "),
      _c("span", { staticClass: "dot yy" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "l-top" }, [
      _c("p", [_vm._v("装修中")]),
      _vm._v(" "),
      _c("span", { staticClass: "dot zx" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }