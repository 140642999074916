var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filterWaiHu-main" }, [
    _c("div", { staticClass: "filterWaiHu-main-dialog" }, [
      _c("div", { staticClass: "tip" }, [
        _vm._v("\n      请选择异常号码处理方式：\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row-dialog" },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "100px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "无效" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.filterData.filterInvalid,
                        callback: function ($$v) {
                          _vm.$set(_vm.filterData, "filterInvalid", $$v)
                        },
                        expression: "filterData.filterInvalid",
                      },
                    },
                    [_vm._v("过滤")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.type !== "one"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "重复" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.filterData.filterRepeat,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterData, "filterRepeat", $$v)
                            },
                            expression: "filterData.filterRepeat",
                          },
                        },
                        [_vm._v("过滤")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "被我打过" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.filterData.filterMeCalled,
                        callback: function ($$v) {
                          _vm.$set(_vm.filterData, "filterMeCalled", $$v)
                        },
                        expression: "filterData.filterMeCalled",
                      },
                    },
                    [_vm._v("过滤")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "被他人打过" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.filterData.filterOtherCalled,
                        callback: function ($$v) {
                          _vm.$set(_vm.filterData, "filterOtherCalled", $$v)
                        },
                        expression: "filterData.filterOtherCalled",
                      },
                    },
                    [_vm._v("过滤")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
          [_vm._v("取消")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.confirmBtn },
          },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }