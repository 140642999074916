var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-body" }, [
    _c("div", {
      ref: "code",
      staticClass: "qr-code",
      attrs: { id: "qr-code" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "op" }, [
      _c("span", { on: { click: _vm.copyUrl } }, [_vm._v("复制链接")]),
      _c("span", { on: { click: _vm.downLoadErCode } }, [_vm._v("下载二维码")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }