import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

// 获取子账号列表
export const getSubaccountList = async params => {
  const api = API('GET_SUBACCOUNT')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 添加子账户
export const postChildAcc = async params => {
  const api = API('POST_CHILD_ACC')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 查询子账号数量
export const getChildNum = async params => {
  const api = API('GET_CHILD_NUM')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 修改子账号
export const putChildAcc = async params => {
  const api = API('PUT_CHILD_ACC')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 删除子账号
export const delChildAcc = async params => {
  const api = API('DEL_CHILD_ACC')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 个人中心-账户余额
export const getAccEarn = async params => {
  const api = API('GET_ACCEARN')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 个人中心-获取客户配置
export const getCustomerConfig = async params => {
  const api = API('GET_ACCEARN')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 个人中心-客户配置
export const setCustomerConfig = async params => {
  const api = API('SET_CUSTOMERCONFIG')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取可用线索数量
export const getKeyNum = async params => {
  const api = API('GET_KEYNUM')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 批量删除子账户
export const delSubAccRe = async params => {
  const api = API('DEL_SUBACCRE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 消耗线索
export const postConSum = async params => {
  const api = API('POST_CONSUM')
  return await BASE_REQUEST({ api: api, opt: params })
}

// zizhanghaoquanxian
export const putChildUserControl = async params => {
  const api = API('PUT_CHILDUSERCONTROL')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 设置权限控制的子账户列表
export const getTransUserList = async params => {
  const api = API('GET_TRANSUSERLIST')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 向子账户转派线索
export const getTransInfoToUser = async params => {
  const api = API('GET_TRANSINFOTOUSER')
  return await BASE_REQUEST({ api: api, opt: params })
}

//获取子账户详情
export const getChildUserDetail = async params => {
  const api = API('GET_CHILDUSERDETAIL')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 用户所有可配置线索包
export const packageAll = async params => {
  const api = API('packageAll')
  return await BASE_REQUEST({ api: api, opt: params })
}
