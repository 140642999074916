<template>
  <div class="m-card">
    <div class="left">
      <div class="icon">
        <img src="@/views/charts/theme/images/i1.png" />
      </div>
    </div>
    <div class="right">
      <div class="line" v-for="(item, index) in list" :key="index">
        <div class="name">{{ item.name }}</div>
        <div class="num">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MajorChart',

  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },

  methods: {}
}
</script>
<style lang="scss" scoped>
.m-card {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  background: url(../theme/images/bg1.png) no-repeat center right;
  background-size: 427px 144px;
  overflow: hidden;

  .left {
    padding-left: 58px;
  }

  .icon {
    font-size: 0;
    text-align: center;
    line-height: 144px;

    img {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .right {
    overflow: hidden;
    padding-top: 9px;
    padding-left: 50px;
    padding-right: 30px;
    width: 70%;
    position: absolute;
    right: 0;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    margin-bottom: 5px;

    &:nth-child(1) {
      .num {
        color: #0096ff;
      }
    }

    &:nth-child(2) {
      .num {
        color: #ffb335;
      }
    }
  }

  .name {
    flex: 1;
    font-size: 16px;
    color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .num {
    font-size: 28px;
    font-family: DIN;
  }
}
</style>
