<template>
  <div class="viewPhone-main">
    <div class="kf"></div>
    <div class="header">
      1、五卡话机是什么
    </div>
    <div class="header" style="padding-top: 15px!important;">
      五卡话机方案是领鸟最新上线的电销方案，区别于以往的线路方案，话机方案可以类比于日常的手机打电话场景，具有稳定，高接通率等特点。
    </div>
    <div class="flex">
      <div class="phone-image"></div>
      <div class="phone-table">
        <div>
          <el-table :data="tableData" border size="mini" style="width: 90%">
            <el-table-column
              prop="one"
              label="">
            </el-table-column>
            <el-table-column
              prop="two"
              width="150"
              label="传统电销方案">
            </el-table-column>
            <el-table-column
              prop="three"
              label="五卡话机方案">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="header">
      2、接入流程
    </div>
    <div class="liu-cheng">
      <div class="no-jt">
        申请试用
      </div>
      <div>
        商务沟通
      </div>
      <div>
        设备部署
      </div>
      <div class="long">
        渠道办卡（需提供公司资质且报备销售话术，通过后方可购买SIM卡，卡入网需要实名制）
      </div>
      <div class="no-line">
        投入使用
      </div>
    </div>
    <div class="footer">
        <el-button type="primary" @click="getSim" size="small" v-loading="loading">
          立即申请试用
        </el-button>
    </div>
  </div>
</template>

<script>
import { applySim } from '@/api/thread'
export default {
  name: 'viewPhone',
  data () {
    return {
      tableData: [
        {
          one: '通话方式',
          two: '网络电话',
          three: '通讯电话'
        },
        {
          one: '通话质量',
          two: '差，受网络波动影响大',
          three: '好'
        },
        {
          one: '通话稳定性',
          two: '低，受政策等因素影响',
          three: '高'
        },
        {
          one: '接通率',
          two: '20～30%',
          three: '>85%'
        }
      ],
      loading: false
    }
  },
  mounted () {},
  computed: {},
  methods: {
    getSim(){
      if(this.loading) return
      this.loading = true
      applySim().then(res=>{
        this.loading = false
        if(res.code === 60000){
          this.$store.commit('update', {
            target: this.$store.state.plugin.modal.options,
            data: {
              isOk: true
            }
          })
          this.$store.dispatch('hideModal')
        }
      }).catch(() => {
        this.loading = false
      })
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.viewPhone-main{
  height: 600px;
  overflow-y: auto;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.flex{
  height: 250px;
  display: flex;
}
.flex > div{
  width: 50%;
  height: 100%;
}
.phone-image{
  background: url("https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/huaji5k.jpg") no-repeat center center;
  background-size: 250px 250px;
}
.phone-table > div{
  margin-top: 35px;
}
.liu-cheng{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.liu-cheng > div{
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #EBEEF5;
  height: 30px;
  width: 120px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
}
.long{
  width: 240px!important;
  height: 60px!important;
}
.liu-cheng > div:before{
  content: '';
  display: inline-block;
  height: 25px;
  width: 1px;
  background-color: #8A8A8A;
  opacity: .2;
  position: absolute;
  top: 30px;
}

.liu-cheng > div:after{
  content: '';
  display: inline-block;
  height: 5px;
  width: 5px;
  border-top: 1px solid #8A8A8A;
  border-left: 1px solid #8A8A8A;
  transform: rotate(225deg);
  position: absolute;
  bottom: 33px;
}

.long:after{
  bottom: 60px!important;
}

.no-jt:after{
  display: none!important;
}

.long:before{
  top: 60px!important;
}
.no-line:before{
  display: none!important;
}


.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  /*padding: 20px 0;*/
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  background-color: #fff;
  left: 0;
  bottom: 0;
  height: 50px;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,.1);
  position: absolute;
  left: 0;
  top: 0;
  transform: scaleY(.5);
}
.header{
  font-size: 14px;
  padding: 40px 20px 0 20px;
  box-sizing: border-box;
}
.kf{
  background: url("https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lner.png") no-repeat center center;
  background-size: 100px 100px;
  height: 120px;
  width: 120px;
  position: absolute;
  bottom: 80px;
  right: 50px;
}
.kf:before{
  content: '联系客服';
  font-size: 12px;
  display: inline-block;
  position: absolute;
  bottom: -10px;
  width: 100%;
  text-align: center;
}
</style>
