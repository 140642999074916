var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "visual-work-page" },
    [
      _c(
        "div",
        { staticClass: "crumb-wrap" },
        [
          _c("div", [_vm._v("可视化作品")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("新建作品")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.visualList, function (item, index) {
          return _c("div", { key: index, staticClass: "item" }, [
            _c("div", { staticClass: "top" }, [
              _c("div", [_vm._v(_vm._s(item.workName))]),
              _vm._v(" "),
              _c("div", { staticClass: "size" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(`${item.templateWidth} * ${item.templateHeight}`) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            item.abbreviationsImgUrl
              ? _c("img", {
                  staticClass: "img-default",
                  attrs: { src: item.abbreviationsImgUrl },
                })
              : _c("div", { staticClass: "img-empty" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-footer" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.handleCheck(item)
                      },
                    },
                  },
                  [_vm._v("查看")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.handleEdit(item)
                      },
                    },
                  },
                  [_vm._v("编辑")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.handleSet(item)
                      },
                    },
                  },
                  [_vm._v("属性")]
                ),
                _vm._v(" "),
                _c(
                  "el-popconfirm",
                  {
                    attrs: { title: "确定删除吗？" },
                    on: {
                      confirm: function ($event) {
                        return _vm.handleDelete(item)
                      },
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { slot: "reference", size: "mini" },
                        slot: "reference",
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "PeDialog",
        {
          attrs: {
            "pe-dialog-show": _vm.show,
            "pe-dialog-width": "600px",
            "pe-dialog-class": "screen-dialog",
            "pe-dialog-title": _vm.dialogTitle,
          },
          on: {
            handlePeDialogClose: function ($event) {
              _vm.show = false
            },
            handlePeDialogSure: _vm.handleSure,
          },
        },
        [
          _c("div", { staticClass: "dialog-wrap" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("大屏标题")]),
                _vm._v(" "),
                _c("el-input", {
                  model: {
                    value: _vm.screenForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.screenForm, "name", $$v)
                    },
                    expression: "screenForm.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("首屏简称")]),
                _vm._v(" "),
                _c("el-input", {
                  model: {
                    value: _vm.screenForm.shortName,
                    callback: function ($$v) {
                      _vm.$set(_vm.screenForm, "shortName", $$v)
                    },
                    expression: "screenForm.shortName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("模板选择")]),
                _vm._v(" "),
                _c("PeSelect", {
                  attrs: {
                    optionsList: _vm.templateList,
                    defaultSelect: _vm.defaultSelect,
                    selectDisable: _vm.disabled,
                  },
                  on: { handleSelectChange: _vm.handleChange },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("模板宽度")]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { disabled: !_vm.disabled, placeholder: "输入宽度" },
                  model: {
                    value: _vm.screenForm.width,
                    callback: function ($$v) {
                      _vm.$set(_vm.screenForm, "width", $$v)
                    },
                    expression: "screenForm.width",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("模板高度")]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { placeholder: "输入高度", disabled: !_vm.disabled },
                  model: {
                    value: _vm.screenForm.height,
                    callback: function ($$v) {
                      _vm.$set(_vm.screenForm, "height", $$v)
                    },
                    expression: "screenForm.height",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("浏览权限")]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    staticStyle: { "margin-left": "10px" },
                    model: {
                      value: _vm.screenForm.isAuth,
                      callback: function ($$v) {
                        _vm.$set(_vm.screenForm, "isAuth", $$v)
                      },
                      expression: "screenForm.isAuth",
                    },
                  },
                  _vm._l(_vm.browseList, function (item) {
                    return _c(
                      "el-radio",
                      { key: item.value, attrs: { label: item.value } },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("缩略简图")]),
                _vm._v(" "),
                _c("PeUpload", {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { fileList: _vm.fileList },
                  on: { handleUploadSucc: _vm.handleUploadSucc },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }