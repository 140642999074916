var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schema-policy-page" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search" },
        [
          _c("el-input", {
            staticStyle: { width: "300px", "margin-right": "20px" },
            attrs: { size: "mini", placeholder: "请输入关键字" },
            model: {
              value: _vm.peParams.keyWords,
              callback: function ($$v) {
                _vm.$set(_vm.peParams, "keyWords", $$v)
              },
              expression: "peParams.keyWords",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.handleInquire },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleReset } },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c("p", [_vm._v("共" + _vm._s(_vm.peParams.total) + "条结果")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("PeTable", {
        attrs: {
          peColumnList: _vm.peColumnList,
          peTableData: _vm.peTableData,
          peLoading: _vm.peLoading,
          pePage: _vm.peParams,
          IsAccess: _vm.IsAccess,
        },
        on: {
          sortPeChange: _vm.handleSortChange,
          handleCurrentPePage: _vm.handleCurrentChange,
          handleSizePeChange: _vm.handleSizeChange,
        },
        scopedSlots: _vm._u([
          {
            key: "policy",
            fn: function ({ scope }) {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "span",
                      {
                        staticClass: "c-name",
                        on: {
                          click: function ($event) {
                            return _vm.handleEnter(scope.data, scope.index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.data.policy))]
                    ),
                    _vm._v(" "),
                    _c("Tags", { attrs: { tagList: scope.data.tags } }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("政策监控")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }