<template>
  <div class="industry-schema">
    <div class="mode">
      <ul>
        <li
          v-for="(it, idx) in modeList"
          :key="it.id"
          @click="handleMode(idx)"
          :class="modeIndex == idx ? 'active-mode' : 'not-mode'"
        >
          {{ it.name }}
        </li>
      </ul>
      <div class="color">
        <el-select
          style="width:100px"
          v-model="colorValue"
          placeholder="请选择"
          @change="handleColorChange"
          size="small"
        >
          <el-option
            v-for="item in colorLists"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="count">
        <span>数字显示</span>
        <el-switch v-model="showCount" @change="handleCountChange" />
      </div>
    </div>
    <div v-show="modeIndex == 1" class="child-list">
      <el-collapse v-model="activeNames">
        <el-collapse-item
          v-for="(tag, idx1) in tagList"
          :key="idx1"
          :title="`${tag.tagName}(${tag.tagCnt})`"
          :name="idx1"
        >
          <div class="child_1_name" @click.stop="handleDetail(tag)">
            <span>{{ tag.tagName }}</span>
            <span>({{ tag.tagCnt }})</span>
          </div>
          <div class="child_2">
            <el-collapse-item
              style="margin-left:20px"
              v-for="(el, idx2) in tag.children"
              :key="idx2"
              :title="`${el.tagName}(${el.tagCnt})`"
            >
              <div class="child_1_name" @click.stop="handleDetail(el)">
                <span>{{ el.tagName }}</span>
                <span>({{ el.tagCnt }})</span>
              </div>
              <div class="child_3">
                <el-collapse-item
                  v-for="(i, idx3) in el.children"
                  :key="idx3"
                  style="margin-left:20px"
                  :title="`${i.tagName}(${i.tagCnt})`"
                >
                  <div class="child_1_name" @click.stop="handleDetail(i)">
                    <span>{{ i.tagName }}</span>
                    <span>({{ i.tagCnt }})</span>
                  </div>
                  <div class="child_4">
                    <el-collapse-item
                      style="margin-left:20px"
                      v-for="(ele, idx4) in i.children"
                      :key="idx4"
                      :title="`${ele.tagName}(${ele.tagCnt})`"
                    >
                      <div class="child_1_name" @click.stop="handleDetail(ele)">
                        <span>{{ ele.tagName }}</span>
                        <span>({{ ele.tagCnt }})</span>
                      </div>
                    </el-collapse-item>
                  </div>
                </el-collapse-item>
              </div>
            </el-collapse-item>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-show="modeIndex == 0" class="mode-chart">
      <TreeChart
        :dataJson="treeDataList"
        @clickNode="clickNode"
        :modeIndex="modeIndex"
        :treeDataText="treeDataText"
      />
    </div>
  </div>
</template>

<script>
import { getTagList } from '@/api/thread'
import TreeChart from '@/components/Peculiar/tree/shrink-tree'
export default {
  name: 'SchemaChart',
  data() {
    return {
      showCount: true,
      activeNames: [0],
      resetFlag: false,
      agencyName: '',
      parentName: '',
      treeDataText: '',
      allList: [],
      tagList: [],
      currentIndex: 0,
      companyUuid: '',
      currentSchema: '',
      currentData: {},
      companyName: '',
      modeIndex: 0,
      modeList: [
        {
          name: '图表模式',
          id: 0
        },
        {
          name: '文本模式',
          id: 1
        }
      ],
      treeData: {},
      treeDataList: [],
      scale: 0,
      colorValue: 0,
      colorLists: [
        {
          label: '青色',
          value: 0
        },
        {
          label: '蓝色',
          value: 1
        }
      ]
    }
  },

  components: {
    TreeChart
  },
  mounted() {
    this.companyUuid = this.$route.query.companyUuid
    this.currentSchema = this.$route.query.currentSchema
    this.tagName = this.$route.query.tagName
    this.parentName = this.$route.query.parentName
    this.agencyName = this.$route.query.agencyName
    this.getList()
  },
  methods: {
    handleColorChange() {
      this.$store.state.settings.changeColor = this.colorValue
    },
    handleCountChange() {
      this.$store.state.settings.changeCount = this.showCount
    },
    handleReset() {
      const dom = this.$refs.draggable
      dom.left = 0
      dom.top = 0
      this.$bus.$emit('handleChartReset')
    },
    handleTreeData(treeData) {
      treeData.name = treeData?.tagName
      if (treeData.children) {
        treeData.children.forEach(item => {
          this.handleTreeData(item)
        })
      }
      this.treeData = treeData
      //处理数据
      const list = []
      list.push(treeData)
      this.treeDataList = list
      this.treeDataText = `${list[0].id}`
    },
    clickNode(data) {
      this.$emit('handleReplaceDetail', data)
    },
    handleMode(index) {
      this.modeIndex = index
      this.tagList = this.allList[0].children
    },
    async getList() {
      // debugger
      const { data } = await getTagList({
        companyUuid: this.companyUuid,
        tagName: this.parentName,
        vcAgency: this.agencyName
      })
      if (data.length) {
        this.allList = data
        this.handleTreeData(data[0])
      }
    },
    handleDetail(data) {
      this.$emit('handleReplaceDetail', data)
    }
  },
  beforeDestroy() {
    this.$bus.$off()
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.industry-schema {
  background-color: #fff;
  margin-top: 10px;
  font-size: 14px;
  .top {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    .current-company {
      margin: 10px 0;
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
      }
      .company-desc {
        color: #8a8a8a;
        font-size: 12px;
        margin-left: 4px;
      }
    }
  }
  .btn-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 20px;
    font-weight: bold;

    .el-btn {
      padding: 10px 20px;
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
    .active {
      background-color: rgba(0, 170, 170);
      color: #fff;
    }
  }
  .industry-desc {
    height: 50px;
    line-height: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 16px 0;
    background-color: rgba(65, 202, 191, 0.2);
    color: #00aaaa;
    font-weight: bold;
    cursor: pointer;
  }
  .mode {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // overflow: hidden;
    ul {
      display: flex;
      align-items: center;
      border: 1px solid #e2e2e266;
      border-radius: 4px;
      width: 200px;
      li {
        padding: 8px;
        cursor: pointer;
        text-align: center;
        width: 50%;
      }
      .active-mode {
        color: $main-color;
        border: 1px solid $main-color;
        color: $main-color;
      }
    }
    .color {
      margin: 0 20px;
    }
  }
  .child-list {
    .child_1_name {
      font-size: 16px;
      border: 1px solid $main-color;
      font-weight: bold;
      padding: 10px;
      cursor: pointer;
      text-align: center;
      color: #0084ff;
    }

    .child_2 {
      font-size: 14px;
      color: #0aa;
      font-weight: bold;
      .child_2-name {
        font-size: 14px;
        color: #0aa;
        font-weight: bold;
        margin: 10px;
        background: #00aaaa2b;
        padding: 10px;
        cursor: pointer;
      }
    }
    .child-tag {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border: 1px solid #00aaaa;
      padding: 5px;
      margin: 10px 0 0 20px;
      font-size: 14px;
      color: #41cabf;
      cursor: pointer;
      width: 240px;
    }
    .active {
      background-color: #0aa;
      color: #fff;
    }
  }
  .company-name {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }

  .mode-chart {
    box-sizing: border-box;
    margin-top: 20px;
    position: relative;
  }
  ::v-deep .el-collapse-item__content {
    padding: 0;
  }
}
</style>
<style lang="scss">
.my-draggable {
  height: auto !important;
  .handle {
    display: none !important;
  }
}
.resetBtn {
  position: relative;
  z-index: 2222;
}
</style>
