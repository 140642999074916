var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "detail-person",
        class:
          !_vm.otherParams.isReceive && _vm.otherParams.shade
            ? "module-cont-filter"
            : "",
        staticStyle: { "padding-right": "20px", "box-sizing": "border-box" },
      },
      [
        _vm.isScroll
          ? _c(
              "div",
              { staticClass: "view-pro-all" },
              [
                _c(
                  "el-carousel",
                  {
                    attrs: {
                      interval: 5000,
                      arrow: _vm.apparrow,
                      autoplay: false,
                    },
                  },
                  _vm._l(
                    _vm.group(_vm.tableArr, _vm.currentNum),
                    function (cards, index) {
                      return _c(
                        "el-carousel-item",
                        { key: index },
                        _vm._l(cards, function (item, indexs) {
                          return _c(
                            "div",
                            { key: indexs, staticClass: "pro-view-item" },
                            [
                              _vm._l(
                                _vm.headerArr,
                                function (headName, indexC) {
                                  return _vm.haveAttr(headName.attr, "logo")
                                    ? _c("div", { key: indexC + "1" }, [
                                        _c(
                                          "div",
                                          { staticClass: "pro-fl-view" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: item[headName.field],
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ])
                                    : _vm._e()
                                }
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class:
                                    _vm.cardType !== "logo"
                                      ? "no-logo-view"
                                      : "pro-fr-view",
                                },
                                _vm._l(
                                  _vm.headerArr,
                                  function (headName, indexC) {
                                    return !_vm.haveAttr(headName.attr, "logo")
                                      ? _c(
                                          "div",
                                          { key: indexC + "2" },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    item[headName.field] + "",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _vm.haveAttr(
                                                  headName.attr,
                                                  "title"
                                                )
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "pro-fr-title card-cont-title",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item[
                                                                headName.field
                                                              ]
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.headerArr,
                                                          function (
                                                            subName,
                                                            indexC
                                                          ) {
                                                            return _vm.haveAttr(
                                                              subName.attr,
                                                              "subTitle"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    key:
                                                                      indexC +
                                                                      "a",
                                                                    staticClass:
                                                                      "pro-fr-num",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                      " +
                                                                        _vm._s(
                                                                          item[
                                                                            subName
                                                                              .field
                                                                          ]
                                                                        ) +
                                                                        "1\n                    "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.haveAttr(
                                              headName.attr,
                                              "tag",
                                              headName
                                            )
                                              ? _c(
                                                  "p",
                                                  _vm._l(
                                                    item.tags.split(","),
                                                    function (tag, index) {
                                                      return tag
                                                        ? _c(
                                                            "span",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "ala-ding-tag",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(tag)
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.haveAttr(
                                              headName.attr,
                                              "title",
                                              headName
                                            ) &&
                                            !_vm.haveAttr(
                                              headName.attr,
                                              "tag"
                                            ) &&
                                            !_vm.haveAttr(
                                              headName.attr,
                                              "subTitle"
                                            )
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "hidden-more-txt",
                                                  },
                                                  [
                                                    _vm.showAppUpdateTime(
                                                      headName.field ===
                                                        "updatedTime"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "pro-fr-name",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    headName.name
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    item[
                                                                      headName
                                                                        .field
                                                                    ] + "",
                                                                  placement:
                                                                    "top",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "pro-fr-num",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          headName
                                                                            .field
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  }
                                ),
                                0
                              ),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "view-pro-all" },
              _vm._l(_vm.tableArr, function (item, indexs) {
                return _c(
                  "div",
                  { key: indexs, staticClass: "pro-view-item" },
                  [
                    _vm._l(_vm.headerArr, function (headName, indexC) {
                      return _vm.haveAttr(headName.attr, "logo")
                        ? _c("div", { key: indexC + "1" }, [
                            _c("div", { staticClass: "pro-fl-view" }, [
                              _c("img", {
                                attrs: { src: item[headName.field], alt: "" },
                              }),
                            ]),
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class:
                          _vm.cardType !== "logo"
                            ? "no-logo-view"
                            : "pro-fr-view",
                      },
                      _vm._l(_vm.headerArr, function (headName, indexC) {
                        return !_vm.haveAttr(headName.attr, "logo")
                          ? _c(
                              "div",
                              { key: indexC + "2" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: item[headName.field] + "",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm.haveAttr(headName.attr, "title")
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "pro-fr-title card-cont-title",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item[headName.field]) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.headerArr,
                                      function (subName, indexC) {
                                        return _vm.haveAttr(
                                          subName.attr,
                                          "subTitle"
                                        )
                                          ? _c(
                                              "span",
                                              {
                                                key: indexC + "a",
                                                staticClass: "pro-fr-num",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      item[subName.field]
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.haveAttr(headName.attr, "tag")
                                  ? _c(
                                      "p",
                                      _vm._l(
                                        item.tags.split(","),
                                        function (tag, index) {
                                          return _c(
                                            "span",
                                            {
                                              key: index,
                                              staticClass: "ala-ding-tag",
                                            },
                                            [_vm._v(_vm._s(tag))]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.haveAttr(headName.attr, "title") &&
                                !_vm.haveAttr(headName.attr, "tag") &&
                                !_vm.haveAttr(headName.attr, "subTitle")
                                  ? _c(
                                      "p",
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "pro-fr-name" },
                                          [_vm._v(_vm._s(headName.name) + ":")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content:
                                                item[headName.field] + "",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pro-fr-num" },
                                              [
                                                _vm._v(
                                                  _vm._s(item[headName.field])
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e()
                      }),
                      0
                    ),
                  ],
                  2
                )
              }),
              0
            ),
      ]
    ),
    _vm._v(" "),
    _vm.pageStatus.total
      ? _c("div", { staticStyle: { height: "40px" } }, [
          _c("div", { staticClass: "page-div" }, [
            _c(
              "div",
              { staticStyle: { float: "right" } },
              [
                _c("footer-page", {
                  attrs: {
                    pageSizes: _vm.pageSizes,
                    pageStatus: _vm.pageStatus,
                  },
                  on: {
                    handleCurrentChange: _vm.handleCurrentChange,
                    goNumPage: _vm.goNumPage,
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.otherParams.isReceive && _vm.otherParams.shade
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.getLoading,
                expression: "getLoading",
              },
            ],
            staticClass: "touch-no-receive",
          },
          [
            _c("div", { staticClass: "touch-no-cont" }, [
              _c("div", { staticClass: "touch-no-icon" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#008CD9",
                      "text-decoration": "underline",
                      cursor: "pointer",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getReveiveKey("phone")
                      },
                    },
                  },
                  [_vm._v("领取")]
                ),
                _vm._v(" "),
                _c("span", [_vm._v("以查看所有信息")]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }