var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message-main" }, [
    _c("div", { staticStyle: { height: "100%", "background-color": "#fff" } }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search" },
        [
          _c("el-input", {
            staticStyle: { width: "300px" },
            attrs: {
              size: "small",
              placeholder: "请输入企业、产品名称",
              clearable: "",
            },
            on: { clear: _vm.getMessagePushBySearch },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.getMessagePushBySearch },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.reSet } },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "scroll",
        },
        [
          _c(
            "DtScroll",
            {
              key: _vm.scrollKey,
              attrs: { margin: "0", "subscribe-scroll": "" },
              on: { onScrollBottom: _vm.onScrollBottom },
            },
            [
              _c(
                "div",
                { staticClass: "scroll-main" },
                [
                  _vm._l(_vm.list, function (item) {
                    return _c(
                      "div",
                      {
                        class:
                          item.read === "1"
                            ? "line-item"
                            : "line-item line-item-red",
                      },
                      [
                        _c("div", { staticClass: "item _time" }, [
                          _vm._v(_vm._s(item.created_at)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("推荐方式："),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(item.push_style)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("推送内容："),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(item.message)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c(
                            "span",
                            {
                              staticClass: "_link",
                              on: {
                                click: function ($event) {
                                  return _vm.viewUrl(item.url)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  !_vm.loading && !_vm.list.length
                    ? _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")])
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [_vm._v("移动应用洞察")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }