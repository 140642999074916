<template>
  <div>
    <div class="touch-cont-mang p-b-38">
      <div class="touch-tab-cont">
        <div
          :class="
            !otherParams.isReceive && otherParams.shade
              ? 'module-cont-filter'
              : ''
          "
        >
          <div class="showPhone">
            <el-checkbox
              style="margin-right: 10px"
              @change="changeShowPhone"
              v-model="showPhone"
              v-if="cardType === 'phone'"
            >
              只显示手机号
            </el-checkbox>
            <el-checkbox
              style="margin-right: 10px"
              @change="changeShowKP"
              v-model="showKP"
              v-if="cardType === 'contact'"
            >
              KP
            </el-checkbox>
            <el-checkbox
              style="margin-right: 10px"
              @change="changeShowSale"
              v-model="showSale"
              v-if="cardType === 'contact'"
            >
              销售岗
            </el-checkbox>
            <el-checkbox
              style="margin-right: 10px"
              @change="changeShowDev"
              v-model="showDev"
              v-if="cardType === 'contact'"
            >
              研发岗
            </el-checkbox>
            <el-checkbox
              style="margin-right: 10px"
              @change="changeShowAdmin"
              v-model="showAdmin"
              v-if="cardType === 'contact'"
            >
              管理岗
            </el-checkbox>
            <el-checkbox
              style="margin-right: 40px"
              @change="changeShowElse"
              v-model="showElse"
              v-if="cardType === 'contact'"
            >
              其他
            </el-checkbox>
            <el-button
              size="small"
              type="primary"
              v-if="cardType === 'contact'"
              @click="setKPWord"
              >设置KP关键词</el-button
            >
          </div>
          <el-checkbox-group
            v-model="chooseComId"
            @change="handleClick"
            :class="
              !otherParams.isReceive && otherParams.shade
                ? 'module-cont-filter'
                : ''
            "
          >
            <el-carousel
              indicator-position="outside"
              :autoplay="false"
              ref="carousel"
              :class="{ arrow: false }"
            >
              <el-carousel-item
                v-for="(item1, index1) in tableArr
                  ? Math.ceil(tableArr.length / 8)
                  : 1"
                :key="index1"
              >
                <el-scrollbar
                  class="touch-phone-card dt-dropdown-dt  dt-dropdown-dtt"
                  v-if="cardType === 'phone' || cardType === 'email'"
                >
                  <div
                    :class="{
                      'phone-card-list': true,
                      'phone-card-list-phone': cardType === 'phone'
                    }"
                    v-for="(item, index) in tableArr.slice(
                      (Number(item1) - 1) * 8,
                      Number(item1) * 8
                    )"
                    :key="index"
                    v-if="changeVisiable(item)"
                  >
                    <i
                      v-if="cardType === 'phone'"
                      class="card-phone-icon"
                      @click="call(item.phone)"
                    ></i>
                    <div
                      class="card-cont-txt"
                      style="padding-top: 12px;box-sizing: border-box"
                    >
                      <!--                        <el-checkbox :key="index" :label="index" style="vertical-align:top;margin-top:1px;"></el-checkbox>-->
                      <span
                        class="card-phone-name card-name-cont"
                        v-for="(column, ind) in headerArr"
                        :key="ind + 'a'"
                        v-if="haveAttr(column.attr, 'title')"
                        >{{ item[column.field] }}</span
                      >
                      <!-- <span v-if="item.title" class="card-tag-name">{{item.title}}</span> -->
                    </div>

                    <div class="card-cont-txt origin-tip">
                      <p
                        v-for="(column, ind) in headerArr"
                        :key="ind + 'b'"
                        v-if="
                          haveAttr(column.attr, 'phone') ||
                            haveAttr(column.attr, 'email')
                        "
                      >
                        <span class="card-phone-name">{{ column.name }}:</span>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item[column.field]"
                          placement="top"
                        >
                          <span
                            class="card-f14-txt custo-phone-show"
                            style="color: #2E2E2E"
                            >{{ item[column.field] }}
                            <span v-if="item.ai_konghao" style="color: #41CABF;"
                              >（空号）</span
                            >
                            <span
                              v-else-if="item.ai_tingji"
                              style="color: #41CABF;"
                              >（停机）</span
                            >
                            <span
                              v-else-if="item.wuxiao"
                              style="color: #41CABF;"
                              >（无效）</span
                            >
                            <span
                              v-else-if="item.zhongjie"
                              style="color: #41CABF;"
                              >（疑似中介）</span
                            >
                            <span
                              v-else-if="item.isNotBlank == false"
                              style="color: #41CABF;"
                              >（空号）</span
                            >
                          </span>
                        </el-tooltip>
                      </p>
                      <span
                        v-for="(column, ind) in headerArr"
                        :key="ind + 'c'"
                        v-if="haveAttr(column.attr, 'from')"
                        style="display: inline-block;width: 100%;"
                      >
                        <span class="card-phone-name" style="color:#8A8A8A;"
                          >{{ column.name }}:</span
                        >
                        <span
                          class="card-f14-txt"
                          style="color:#8A8A8A;font-size: 12px;"
                          >{{ item[column.field] }}</span
                        >
                      </span>
                      <div style="height: 34px">
                        <span
                          class="card-edit-time"
                          v-if="
                            item.phoneMarkList &&
                              item.phoneMarkList.length &&
                              cardType === 'phone'
                          "
                        >
                          {{ item['phoneMarkList'][0].follows[0].markDetails }}
                        </span>
                      </div>
                      <div>
                        <el-popover placement="left-end" width="600">
                          <div
                            v-for="(i, index) in item.phoneMarkList"
                            :key="index"
                            class="pond-cond-all"
                          >
                            <p style="margin-bottom:20px;">
                              <span style="display:inline-block;width:70px;">{{
                                i.week
                              }}</span>
                              <span style="font-size:12px;">{{ i.day }}</span>
                            </p>

                            <div
                              style="overflow: hidden;"
                              v-for="(follow, ind) in i.follows"
                              :key="ind + 'c'"
                            >
                              <div
                                class="pond-cond-left"
                                :class="ind !== 0 ? 'pond-no-first' : ''"
                              >
                                <p>{{ follow.time }}</p>
                              </div>
                              <div
                                class="pond-cond-right"
                                :class="ind !== 0 ? 'pond-no-first' : ''"
                              >
                                <p>
                                  <span
                                    style="color:#999999;"
                                    :class="
                                      i.week === '今天'
                                        ? 'pond-cond-posi'
                                        : 'pond-cond-had'
                                    "
                                    v-html="follow.markSummary"
                                  ></span>
                                </p>
                              </div>
                            </div>
                          </div>

                          <span
                            class="card-edit-asign"
                            slot="reference"
                            v-if="cardType === 'phone'"
                            >共{{ item.cnt }}条标记</span
                          >
                        </el-popover>
                        <el-dropdown
                          placement="bottom-start"
                          trigger="click"
                          @command="changeAsign($event, item)"
                          v-if="cardType === 'phone'"
                        >
                          <span class="el-dropdown-link">
                            <i class="card-phone-edit-icon"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                              :command="item.value"
                              v-for="item in asignOption"
                              >{{ item.name }}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>

                    <!--                <i v-if="cardType === 'email'" class="card-email-icon"></i>-->
                  </div>
                </el-scrollbar>
                <el-scrollbar
                  v-else-if="cardType === 'contact'"
                  class="touch-phone-card dt-dropdown-dt dt-dropdown-dtt"
                  :style="{
                    'margin-top': cardType === 'contact' ? '20px' : '0'
                  }"
                >
                  <div
                    class="phone-card-list"
                    v-for="(item, index) in filterTableArr(item1)"
                    :key="index"
                    v-if="changeVisiable1(item)"
                  >
                    <div class="cvatar">
                      <div
                        style="float: left;border-radius: 50%;overflow: hidden;"
                      >
                        <img
                          :src="item.maiMaiAvatar"
                          width="30"
                          height="30"
                          alt=""
                        />
                      </div>
                      <el-badge :value="item.kp ? 'KP' : ''" class="item">
                        <div
                          style="width:auto;line-height: 30px;text-indent: 2em;float: left;color:#2e2e2e;font-size: 12px"
                        >
                          {{ item.maiMaiPersonName }}
                        </div>
                      </el-badge>
                    </div>
                    <div style="overflow:hidden;">
                      <div style="padding-right:6px;height:100%;">
                        <p class="cont-adr-touch">
                          <span
                            v-for="(column, ind) in headerArr"
                            :key="ind + 'g'"
                            v-if="haveAttr(column.field, 'maimaiTeam')"
                            style="display: inline-block;width: 100%;"
                          >
                            <span class="card-phone-name" style="color:#8A8A8A;"
                              >{{ column.name }}:</span
                            >

                            <span
                              class="card-f14-txt"
                              style="color:#8A8A8A;font-size: 12px;"
                              >{{ item[column.field] }}</span
                            >
                          </span>
                          <span
                            v-for="(column, ind) in headerArr"
                            :key="ind + 'g'"
                            v-if="haveAttr(column.field, 'maiMaiDept')"
                            style="display: inline-block;width: 100%;"
                          >
                            <span class="card-phone-name" style="color:#8A8A8A;"
                              >{{ column.name }}:</span
                            >
                            <span
                              class="card-f14-txt"
                              style="color:#8A8A8A;font-size: 12px;"
                              >{{ item[column.field] }}</span
                            >
                          </span>
                          <span
                            v-for="(column, ind) in headerArr"
                            :key="ind + 'g'"
                            v-if="haveAttr(column.field, 'maiMaiPosition')"
                            style="display: inline-block;width: 100%;"
                          >
                            <span class="card-phone-name" style="color:#8A8A8A;"
                              >{{ column.name }}:</span
                            >
                            <span
                              class="card-f14-txt"
                              style="color:#8A8A8A;font-size: 12px;"
                              >{{ item[column.field] }}</span
                            >
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
                <el-scrollbar
                  v-else-if="cardType === 'address'"
                  class="touch-phone-card dt-dropdown-dt dt-dropdown-dtt"
                >
                  <div
                    class="phone-card-list"
                    style="padding-top: 10px;width: 270px;padding-right:6px;padding-top: 20px;margin-top: 20px;"
                    v-for="(item, index) in tableArr.slice(
                      (Number(item1) - 1) * 8,
                      Number(item1) * 8
                    )"
                    :key="index"
                  >
                    <div style="overflow:hidden;height:100%;">
                      <div style="padding-right:6px;height:100%;">
                        <p class="cont-adr-touch">
                          <span
                            v-for="(column, ind) in headerArr"
                            :key="ind + 'e'"
                            v-if="haveAttr(column.attr, 'contact')"
                          >
                            <span class="card-phone-name" style="color:#8A8A8A;"
                              >{{ column.name }}:</span
                            >
                            <span
                              class="card-f14-txt"
                              style="color:#8A8A8A;font-size: 12px;"
                              >{{ item[column.field] }}</span
                            >
                          </span>
                          <span
                            style="display: block;line-height: 20px;height: auto;font-size: 12px;"
                            v-for="(column, ind) in headerArr"
                            :key="ind + 'd'"
                            v-if="haveAttr(column.attr, 'address')"
                          >
                            <span
                              >{{ column.name }}：{{ item[column.field] }}</span
                            >
                          </span>

                          <span
                            v-for="(column, ind) in headerArr"
                            :key="ind + 'e'"
                            v-if="haveAttr(column.attr, 'from')"
                          >
                            <span class="card-phone-name" style="color:#8A8A8A;"
                              >{{ column.name }}:</span
                            >
                            <span
                              class="card-f14-txt"
                              style="color:#8A8A8A;font-size: 12px;"
                              >{{ item[column.field] }}</span
                            >
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </el-carousel-item>
            </el-carousel>
          </el-checkbox-group>
        </div>

        <div
          class="touch-no-receive"
          v-if="!otherParams.isReceive && otherParams.shade"
          v-loading="getLoading"
        >
          <div class="touch-no-cont">
            <div class="touch-no-icon">
              <span
                style="color: #008CD9;text-decoration: underline;cursor:pointer;"
                @click="getReveiveKey('phone')"
                >领取</span
              >
              <span> 以查看所有信息</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from './mixin'
import PhoneStrip from '@/components/PhoneStrip'
import {
  getComDetailModel,
  getDetailAsignOpthon,
  postDetailPhoneAsign
} from '@/api/thread'
import setkpword from '@/components/ModulePack/setkpword'

export default {
  mixins: [Mixin],
  props: {
    cardType: {
      type: String,
      default: ''
    },
    moduleParam: {
      default: () => {},
      type: Object
    },
    detail: {
      default: () => {},
      type: Object
    },
    packageId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      supplierObj: {},
      tableArr: [],
      headerArr: [],
      chooseComId: [],
      showPhone: false,
      asignOption: [],
      showSale: false,
      showDev: false,
      showAdmin: false,
      showElse: false,
      showKP: false
    }
  },
  components: {
    PhoneStrip
  },
  mounted() {
    this.$emit('ModuleDom')
    this.showPhone = this.$store.state.plugin.onlyShowPhone
    this.showSale = this.$store.state.plugin.onlyShowSale
    this.showDev = this.$store.state.plugin.onlyShowDev
    this.showAdmin = this.$store.state.plugin.onlyShowAdmin
    this.showElse = this.$store.state.plugin.onlyShowElse
    this.showKP = this.$store.state.plugin.onlyShowKP
    console.log(this.moduleParam)

    if (this.cardType == 'phone') {
      this.getDetailAsignOpthon()
    }
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    }
  },
  methods: {
    filterTableArr1() {
      if (this.cardType == 'contact') {
        let that = this
        return this.tableArr.filter(i => {
          if (
            (i.type.indexOf('销售岗') > -1 && that.showSale) ||
            (i.type.indexOf('研发岗') > -1 && that.showDev) ||
            (i.type.indexOf('管理岗') > -1 && that.showAdmin) ||
            (i.type.indexOf('其他') > -1 && that.showElse) ||
            (i.kp == true && that.showKP) ||
            (!that.showSale &&
              !that.showDev &&
              !that.showAdmin &&
              !that.showElse &&
              !that.showKP)
          ) {
            return true
          } else {
            return false
          }
        })
      } else if (this.cardType == 'phone') {
        return this.tableArr.filter(i => {
          if (i.hasOwnProperty('phone') && this.showPhone) {
            let reg = /^[1][3,4,5.6,7,8,9][0-9]{9}$/
            if (reg.test(i.phone)) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        })
      } else {
        return this.tableArr
      }
    },
    filterTableArr(item1) {
      if (this.cardType == 'contact') {
        let that = this
        return this.tableArr
          .filter(i => {
            if (
              (i.type.indexOf('销售岗') > -1 && that.showSale) ||
              (i.type.indexOf('研发岗') > -1 && that.showDev) ||
              (i.type.indexOf('管理岗') > -1 && that.showAdmin) ||
              (i.type.indexOf('其他') > -1 && that.showElse) ||
              (i.kp == true && that.showKP) ||
              (!that.showSale &&
                !that.showDev &&
                !that.showAdmin &&
                !that.showElse &&
                !that.showKP)
            ) {
              return true
            } else {
              return false
            }
          })
          .slice((Number(item1) - 1) * 8, Number(item1) * 8)
      } else {
        return this.tableArr
      }
    },
    setKPWord() {
      this.$store.dispatch('showModal', {
        title: '设置KP关键词',
        size: '670px',
        view: setkpword,
        // options: {
        //   remark: val,
        //   isUpdate: true,
        //   companyUuid: this.$route.query.companyUuid,
        // },
        onClose: res => {
          if (res.refresh) {
            this.$emit('getComDetailModel')
          }
        }
      })
    },
    call(phone) {
      this.$bus.$emit('callup', phone, contact, companyName)
    },
    getDetailAsignOpthon() {
      getDetailAsignOpthon().then(res => {
        this.asignOption = res.data
      })
    },
    changeAsign(mark, i) {
      console.log(this.detail)
      console.log(this.packageId)
      const params = {
        mark,
        phone: i.phone,
        companyName: this.detail.company_name,
        contact: i.contact,
        packageId: this.packageId,
        klc: this.permissionList.includes('客户培育-联系人培育-新建联系人'), // '客户培育-联系人培育-新建联系人'
        kkx: this.permissionList.includes('客户管理CRM-客户公海-新建客户'), // '客户管理CRM-客户公海-新建客户'
        kkl: this.permissionList.includes('客户管理CRM-客户公海-联系人'), // '客户管理CRM-客户公海-联系人'
        pageId: 2001
      }
      postDetailPhoneAsign(params).then(res => {
        if (res.code == 60000) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          if (mark == 7) {
            this.$store.state.user.customerInfoMap.inPool = true
            this.$store.state.user.customerInfoMap.desc =
              '客户当前位置：客户公海-' + this.$store.state.user.name
          }
          this.$emit('getComDetailModel')
        }
      })
    },
    getComDetailModel() {
      const params = {
        module: 'touch',
        packageId: this.moduleParam.packageId,
        companyUuid: this.moduleParam.companyUuid
      }
      getComDetailModel(params).then(res => {})
    },

    handleClick() {},
    changeShowPhone(e) {
      this.showPhone = e
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowPhone: e
        }
      })
    },
    changeShowKP(e) {
      this.showKP = e
      this.$refs.carousel.setActiveItem(0)
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowKP: e
        }
      })
    },
    changeShowSale(e) {
      this.showSale = e
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowSale: e
        }
      })
    },
    changeShowDev(e) {
      this.showDev = e
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowDev: e
        }
      })
    },
    changeShowAdmin(e) {
      this.showAdmin = e
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowAdmin: e
        }
      })
    },
    changeShowElse(e) {
      this.showElse = e
      this.$store.commit('update', {
        target: this.$store.state.plugin,
        data: {
          onlyShowElse: e
        }
      })
    },
    changeVisiable(i) {
      if (i.hasOwnProperty('phone') && this.showPhone) {
        let reg = /^[1][3,4,5.6,7,8,9][0-9]{9}$/
        if (reg.test(i.phone)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    changeVisiable1(i) {
      if (
        (i.type.indexOf('销售岗') > -1 && this.showSale) ||
        (i.type.indexOf('研发岗') > -1 && this.showDev) ||
        (i.type.indexOf('管理岗') > -1 && this.showAdmin) ||
        (i.type.indexOf('其他') > -1 && this.showElse) ||
        (i.kp == true && this.showKP) ||
        (!this.showSale &&
          !this.showDev &&
          !this.showAdmin &&
          !this.showElse &&
          !this.showKP)
      ) {
        return true
      } else {
        return false
      }
    },
    haveAttr(val, lag) {
      if (val) {
        const arr = val.split(',')
        if (arr.indexOf(lag) > -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>
<style scoped>
.module-cont-filter {
  filter: blur(1.5px);
  padding-top: 20px;
}

.card-card-cont {
  display: inline-block;
}

.card-name-cont {
  display: inline-block;
  width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custo-phone-show {
  display: inline-block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.phone-card-list ::v-deep .el-checkbox__label {
  display: none;
}

.phone-card-list ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #41cabf;
  border-color: #41cabf;
}

.phone-card-list ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #41cabf;
}

.phone-card-list ::v-deep .el-checkbox__inner:hover {
  border-color: #78d9d1;
}

.card-tag-name {
  display: inline-block;
  height: 17px;
  background: #ebfaf9;
  padding: 0 10px;
  color: #41cabf;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-left: 12px;
  vertical-align: top;
}

.card-f14-txt {
  font-size: 14px;
  color: #41cabf;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  margin-left: 6px;
}

.card-phone-name {
  font-size: 12px;
  color: #2e2e2e;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  vertical-align: top;
}

.card-edit-time {
  font-size: 12px;
  color: #aaa;
  line-height: 20px;
}

.card-edit-asign {
  font-size: 12px;
  color: #0099cc;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  vertical-align: top;
  text-decoration: underline;
}

.card-cont-txt {
  height: 35px;
  width: 100%;
}

.card-cont-txt ::v-deep .el-checkbox {
  margin-right: 6px;
}

.touch-phone-card {
  padding: 2px 0 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 90%;
  height: 400px;
  margin: 0 auto;
  margin-top: 20px;
}

.phone-card-list {
  display: inline-block;
  /*margin: 5px 15px;*/
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11);
  padding-left: 10px;
  height: 160px;
  padding-top: 10px;
  width: 20%;
  margin: 5px;
  min-width: 300px;
  padding-right: 6px;
}

.phone-card-list-phone {
  position: relative;
  top: 0;
  left: 0;
  /*height: 140px;*/
}

.touch-card-title {
  font-weight: 400;
}

.touch-tab-cont {
  width: 100%;
  height: 400px;
  padding: 0 21px;
  position: relative;
}

.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  /*opacity:0.8;*/
  z-index: 100;
}

.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}

.touch-no-cont {
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.touch-cont-mang {
  width: 100%;
  /*min-width: 1200px;*/
  height: 400px;
  background-color: #ffffff;
  margin-top: 4px;
}

.touch-cont-mang .title {
  width: 100%;
  height: 59px;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #2e2e2e;
  line-height: 59px;
  padding-left: 21px;
  padding-right: 36px;
}

.touch-cont-mang ::v-deep .el-tabs__item {
  border: none;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  width: 180px;
  text-align: center;
  color: #8a8a8a;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}

.touch-cont-mang ::v-deep .el-tabs__item.is-active {
  color: #41cabf;
  background: white;
  border-bottom: 1px solid white;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-top: 1px solid #41cabf;
  width: 180px;
}

.touch-cont-mang ::v-deep .el-tabs__item:first-child {
  border-left: none;
}

.touch-cont-mang ::v-deep .el-tabs__item:last-child::after {
  content: '';
  width: 2000px;
  height: 1px;
  background: #e6e6e6;
  top: -1px;
  left: 180px;
  position: absolute;
  display: inline-block;
}

.touch-cont-mang ::v-deep .el-tabs__item:last-child::before {
  content: '';
  width: 2000px;
  height: 1px;
  background: #e6e6e6;
  bottom: -1px;
  left: 180px;
  position: absolute;
  display: inline-block;
}

.touch-cont-mang ::v-deep .el-tabs__item:hover {
  color: #41cabf;
}

.touch-cont-mang ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-radius: 0;
  border: none;
}

.touch-cont-mang ::v-deep .el-tabs__nav-scroll {
  background: #fcfcfc;

  height: 50px;
}

.card-phone-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url('../../assets/touch-phone-icon.png') no-repeat;
  background-size: 100% 100%;
  top: 20px;
  right: 20px;
}

.card-phone-edit-icon {
  position: relative;
  height: 15px;
  width: 15px;
  display: inline-block;
  background: url('../../assets/keydetail_edit.png') no-repeat;
  background-size: 100% 100%;
  left: 14px;
  top: 0px;
}

.card-email-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url('../../assets/touch-email-icon.png') no-repeat;
  background-size: 100% 100%;
  right: 14px;
  top: 11px;
}

.cont-adr-touch {
  padding-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  height: 90px;
  -webkit-box-orient: vertical;
}

.origin-tip span {
  line-height: 16px;
  height: 22px;
}

.showPhone {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  z-index: 99;
}

.pond-cond-all {
  overflow: hidden;
  margin: 10px;
}

.pond-cond-left {
  float: left;
  width: 70px;
}

.pond-cond-left p {
  font-size: 12px;
  vertical-align: top;
}

.pond-cond-right {
  float: left;
  width: calc(100% - 70px);
  border-left: 1px solid #e4e4e4;
  padding-left: 20px;
  padding-right: 10px;
  position: relative;
}

.pond-cond-posi {
  position: relative;
}

.pond-cond-had {
  position: relative;
}

.pond-cond-had::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #e4e4e4;
  top: 2px;
  left: -24px;
  border-radius: 4px;
}

.pond-cond-posi::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: red;
  top: 2px;
  left: -24px;
  border-radius: 4px;
}

.pond-no-first {
  padding-top: 40px;
}

.pond-cond-had::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #e4e4e4;
  top: 2px;
  left: -24px;
  border-radius: 4px;
}
.cvatar {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
}
.touch-tab-cont ::v-deep .el-carousel__container {
  display: block;
  min-height: 400px !important;
  margin-top: 30px;
  /*overflow-y: scroll!important;*/
  /*min-height: 400px!important;*/
  /*width: 1599px!important;*/
}
.touch-tab-cont ::v-deep .el-badge__content {
  background-color: #41cabf !important;
}
.arrow ::v-deep .el-carousel__arrow {
  display: none !important;
}
</style>
