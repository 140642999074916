var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "touch-cont-mang p-b-38" }, [
      _c("div", { staticClass: "touch-tab-cont" }, [
        _c(
          "div",
          { class: !_vm.detail.isReceive ? "module-cont-filter" : "" },
          [
            _vm.cardType === "phone" || _vm.cardType === "contact"
              ? _c("div", { staticClass: "showPhone" }, [
                  _vm.cardType === "phone"
                    ? _c(
                        "div",
                        [
                          _vm.cardType === "phone"
                            ? _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  on: { change: _vm.changeShowPhone },
                                  model: {
                                    value: _vm.showPhone,
                                    callback: function ($$v) {
                                      _vm.showPhone = $$v
                                    },
                                    expression: "showPhone",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              只显示手机号\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cardType === "contact"
                    ? _c(
                        "div",
                        { staticStyle: { width: "70%" } },
                        [
                          _vm.cardType === "contact"
                            ? _c(
                                "el-checkbox",
                                {
                                  staticStyle: {
                                    "margin-right": "20px",
                                    width: "70px",
                                  },
                                  on: { change: _vm.changeShowKP },
                                  model: {
                                    value: _vm.showKP,
                                    callback: function ($$v) {
                                      _vm.showKP = $$v
                                    },
                                    expression: "showKP",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              KP" +
                                      _vm._s(
                                        _vm.moduleDetail.kpTypeCnt &&
                                          _vm.moduleDetail.kpTypeCnt != 0
                                          ? "(" +
                                              _vm.moduleDetail.kpTypeCnt +
                                              ")"
                                          : ""
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cardType === "contact"
                            ? _c(
                                "el-checkbox",
                                {
                                  staticStyle: {
                                    "margin-right": "20px",
                                    width: "70px",
                                  },
                                  on: { change: _vm.changeShowSale },
                                  model: {
                                    value: _vm.showSale,
                                    callback: function ($$v) {
                                      _vm.showSale = $$v
                                    },
                                    expression: "showSale",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              销售岗" +
                                      _vm._s(
                                        _vm.moduleDetail.xiaoShouTypeCnt &&
                                          _vm.moduleDetail.xiaoShouTypeCnt != 0
                                          ? "(" +
                                              _vm.moduleDetail.xiaoShouTypeCnt +
                                              ")"
                                          : ""
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cardType === "contact"
                            ? _c(
                                "el-checkbox",
                                {
                                  staticStyle: {
                                    "margin-right": "20px",
                                    width: "70px",
                                  },
                                  on: { change: _vm.changeShowDev },
                                  model: {
                                    value: _vm.showDev,
                                    callback: function ($$v) {
                                      _vm.showDev = $$v
                                    },
                                    expression: "showDev",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              研发岗" +
                                      _vm._s(
                                        _vm.moduleDetail.yanFaTypeCnt &&
                                          _vm.moduleDetail.yanFaTypeCnt != 0
                                          ? "(" +
                                              _vm.moduleDetail.yanFaTypeCnt +
                                              ")"
                                          : ""
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cardType === "contact"
                            ? _c(
                                "el-checkbox",
                                {
                                  staticStyle: {
                                    "margin-right": "20px",
                                    width: "70px",
                                  },
                                  on: { change: _vm.changeShowAdmin },
                                  model: {
                                    value: _vm.showAdmin,
                                    callback: function ($$v) {
                                      _vm.showAdmin = $$v
                                    },
                                    expression: "showAdmin",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              管理岗" +
                                      _vm._s(
                                        _vm.moduleDetail.guanLiTypeCnt &&
                                          _vm.moduleDetail.guanLiTypeCnt != 0
                                          ? "(" +
                                              _vm.moduleDetail.guanLiTypeCnt +
                                              ")"
                                          : ""
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cardType === "contact"
                            ? _c(
                                "el-checkbox",
                                {
                                  staticStyle: {
                                    "margin-right": "40px",
                                    width: "70px",
                                  },
                                  on: { change: _vm.changeShowElse },
                                  model: {
                                    value: _vm.showElse,
                                    callback: function ($$v) {
                                      _vm.showElse = $$v
                                    },
                                    expression: "showElse",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              其他" +
                                      _vm._s(
                                        _vm.moduleDetail.otherTypeCnt &&
                                          _vm.moduleDetail.otherTypeCnt != 0
                                          ? "(" +
                                              _vm.moduleDetail.otherTypeCnt +
                                              ")"
                                          : ""
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cardType === "contact"
                    ? _c(
                        "div",
                        { staticStyle: { height: "50px", width: "30%" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right" },
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.setKPWord },
                            },
                            [_vm._v("设置KP关键词\n            ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _vm.cardType === "contact"
                ? _c("div", [
                    _vm.filterTableArr1()
                      ? _c(
                          "div",
                          { staticClass: "dt-dropdown-dt" },
                          _vm._l(_vm.filterTableArr1(), function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "contact-list-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "40px",
                                      height: "100px",
                                      "padding-top": "30px",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { "border-radius": "50%" },
                                      attrs: {
                                        src: _vm.attachImageUrl(
                                          item.maiMaiAvatar
                                        ),
                                        width: "40",
                                        height: "40",
                                        alt: "暂无图片",
                                        referrerPolicy: "no-referrer",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "500px",
                                      height: "100px",
                                      "padding-top": "10px",
                                      "margin-left": "20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "con-info" },
                                      [
                                        _c(
                                          "el-badge",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              value: item.kp ? "KP" : "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(item.maiMaiPersonName) +
                                                " · " +
                                                _vm._s(item.maiMaiPosition) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "con-info",
                                        staticStyle: { color: "#999" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(item.maimaiTeam) +
                                            " | " +
                                            _vm._s(item.maiMaiDept) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "290px",
                                      height: "100px",
                                      position: "relative",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "dt-permission",
                                          rawName: "v-dt-permission",
                                          value: "智能获客-推荐线索-脉脉触达",
                                          expression:
                                            "'智能获客-推荐线索-脉脉触达'",
                                        },
                                      ],
                                      staticClass: "yun-icon",
                                      staticStyle: {
                                        color: "rgb(158, 209, 101)",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.jumpMaiMai(
                                            item.maiMaiPersonName
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "dt-permission",
                                          rawName: "v-dt-permission",
                                          value: "智能获客-推荐线索-脉脉触达",
                                          expression:
                                            "'智能获客-推荐线索-脉脉触达'",
                                        },
                                      ],
                                      staticClass: "yun-icon1",
                                      staticStyle: {
                                        color: "rgb(158, 209, 101)",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.jumpLingYing(
                                            item.maiMaiPersonName
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.cardType === "phone"
                ? _c("div", [
                    _vm.filterTableArr1()
                      ? _c(
                          "div",
                          { staticClass: "dt-dropdown-dt" },
                          _vm._l(_vm.filterTableArr1(), function (item, index) {
                            return _c(
                              "div",
                              { staticClass: "touch-list-item" },
                              [
                                _c("div", { staticClass: "i-top" }, [
                                  _c(
                                    "div",
                                    { staticClass: "cus-info" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "0 8px 0 0",
                                            "font-size": "14px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.phone) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticStyle: { padding: "0 8px" } },
                                        [_vm._v(_vm._s(item.contact))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            "popper-class": "el-popper-dt-dt",
                                            placement: "bottom-end",
                                            width: "500",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          item.callCnt
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "0 8px",
                                                    color: "#0099cc",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        `已拨打${item.callCnt}次`
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "time-line" },
                                            _vm._l(
                                              item.callRecords,
                                              function (timeLine, index) {
                                                return _c(
                                                  "el-timeline",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "line-top",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              timeLine.week
                                                            )
                                                          ),
                                                        ]),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(timeLine.day)
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      timeLine.follows,
                                                      function (
                                                        activity,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "el-timeline-item",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              color:
                                                                timeLine.week ===
                                                                "今天"
                                                                  ? "#FF0000"
                                                                  : "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "line-content",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "import-text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                  " +
                                                                          _vm._s(
                                                                            activity.account
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      " 发起外呼，"
                                                                    ),
                                                                  ]),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "通话状态"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "import-text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          activity.phoneStatus
                                                                        ) +
                                                                          "\n                                "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            activity.callDuration,
                                                                          expression:
                                                                            "activity.callDuration",
                                                                        },
                                                                      ],
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "通话时长"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "import-text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            activity.callDuration
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "show",
                                                                              rawName:
                                                                                "v-show",
                                                                              value:
                                                                                activity.callDuration &&
                                                                                activity.ossUrl,
                                                                              expression:
                                                                                "\n                                    activity.callDuration && activity.ossUrl\n                                  ",
                                                                            },
                                                                            {
                                                                              name: "dt-permission",
                                                                              rawName:
                                                                                "v-dt-permission",
                                                                              value:
                                                                                "智能获客-推荐线索-播放录音",
                                                                              expression:
                                                                                "\n                                    '智能获客-推荐线索-播放录音'\n                                  ",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "play-record",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.playRecord(
                                                                                activity.ossUrl
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  播放录音\n                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "time-line-date",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      activity.time
                                                                    ) +
                                                                    "\n                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "cus-info" },
                                    [
                                      _c("i", {
                                        staticClass: "card-phone-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.call(
                                              item.phone,
                                              item.contact,
                                              _vm.detail.company_name
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown",
                                        {
                                          attrs: {
                                            placement: "bottom-start",
                                            trigger: "click",
                                          },
                                          on: {
                                            command: function ($event) {
                                              return _vm.changeAsign(
                                                $event,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "card-phone-edit-icon",
                                            staticStyle: {
                                              "margin-left": "8px",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            _vm._l(
                                              _vm.asignOption,
                                              function (item) {
                                                return _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: {
                                                      command: item.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.name) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "add-waihu",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addWaiHu(item)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "i-tag" }, [
                                  _c("div", { staticClass: "cus-tag" }, [
                                    item.ai_konghao
                                      ? _c("div", { staticClass: "tag-i" }, [
                                          _vm._v("空号"),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.ai_tingji
                                      ? _c("div", { staticClass: "tag-i" }, [
                                          _vm._v("停机"),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.wuxiao
                                      ? _c("div", { staticClass: "tag-i" }, [
                                          _vm._v("无效"),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.zhongjie
                                      ? _c("div", { staticClass: "tag-i" }, [
                                          _vm._v("疑似中介"),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.phoneStatus
                                      ? _c("div", { staticClass: "tag-i" }, [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.phoneStatus) +
                                              "\n                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.recommend
                                      ? _c("div", { staticClass: "tag-i" }, [
                                          _vm._v("推荐"),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.ai_can_call
                                      ? _c("div", { staticClass: "tag-i" }, [
                                          _vm._v(
                                            "\n                      电话可打通\n                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "cus-info" },
                                    _vm._l(item.fromToUrl, function (i) {
                                      return _c(
                                        "div",
                                        {
                                          class:
                                            i.url == " " || i.url == ""
                                              ? "cus-href cus-href-i"
                                              : "cus-href",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openUrl(i.url)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                position: "relative",
                                                top: "-3px",
                                              },
                                            },
                                            [_vm._v(_vm._s(i.from))]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "i-sign" },
                                  [
                                    _c("div", { staticClass: "sign-con" }, [
                                      item.phoneMarkList
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  item["phoneMarkList"][0]
                                                    .follows[0].markDetails
                                                ) +
                                                "\n                    "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "left-end",
                                          width: "600",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          item.phoneMarkList,
                                          function (i, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "pond-cond-all",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          display:
                                                            "inline-block",
                                                          width: "70px",
                                                        },
                                                      },
                                                      [_vm._v(_vm._s(i.week))]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [_vm._v(_vm._s(i.day))]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  i.follows,
                                                  function (follow, ind) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: ind + "c",
                                                        staticStyle: {
                                                          overflow: "hidden",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pond-cond-left",
                                                            class:
                                                              ind !== 0
                                                                ? "pond-no-first"
                                                                : "",
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  follow.time
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pond-cond-right",
                                                            class:
                                                              ind !== 0
                                                                ? "pond-no-first"
                                                                : "",
                                                          },
                                                          [
                                                            _c("p", [
                                                              _c("span", {
                                                                class:
                                                                  i.week ===
                                                                  "今天"
                                                                    ? "pond-cond-posi"
                                                                    : "pond-cond-had",
                                                                staticStyle: {
                                                                  color:
                                                                    "#999999",
                                                                },
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      follow.markSummary
                                                                    ),
                                                                },
                                                              }),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "card-edit-asign",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              "\n                      共" +
                                                _vm._s(item.cnt) +
                                                "条标记\n                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.cardType === "email"
                ? _c("div", [
                    _vm.filterTableArr1()
                      ? _c(
                          "div",
                          { staticClass: "dt-dropdown-dt" },
                          _vm._l(_vm.filterTableArr1(), function (item, index) {
                            return _c(
                              "div",
                              { staticClass: "touch-email-item" },
                              [
                                _c("div", { staticClass: "i-top" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cus-info",
                                      staticStyle: {
                                        position: "relative",
                                        width: "100%",
                                        "line-height": "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "0 8px",
                                            width: "40%",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.email) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "0 8px",
                                            width: "30%",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.contact) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "0 8px",
                                            width: "20%",
                                            position: "absolute",
                                            right: "8px",
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.from) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.cardType === "address"
                ? _c("div", [
                    _vm.filterTableArr1()
                      ? _c(
                          "div",
                          { staticClass: "dt-dropdown-dt" },
                          _vm._l(_vm.filterTableArr1(), function (item, index) {
                            return _c(
                              "div",
                              { staticClass: "touch-email-item" },
                              [
                                _c("div", { staticClass: "i-top" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cus-info",
                                      staticStyle: {
                                        position: "relative",
                                        height: "auto",
                                        "line-height": "20px",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "0 8px",
                                            width: "70%",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.address) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "0 8px",
                                            width: "30%",
                                            position: "absolute",
                                            right: "8px",
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.from) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _vm._v(" "),
        !_vm.detail.isReceive
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.getLoading,
                    expression: "getLoading",
                  },
                ],
                staticClass: "touch-no-receive",
              },
              [
                _c("div", { staticClass: "touch-no-cont" }, [
                  _c("div", { staticClass: "touch-no-icon" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#008CD9",
                          "text-decoration": "underline",
                          cursor: "pointer",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getReveiveKey("phone")
                          },
                        },
                      },
                      [_vm._v("领取")]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("以查看所有信息")]),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }