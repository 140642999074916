var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-scrollbar",
    { staticClass: "hide-x", staticStyle: { height: "calc( 100vh - 180px )" } },
    [
      _c(
        "div",
        { staticClass: "page-show-edit" },
        [
          _c("div", { staticClass: "info-share" }, [
            _c(
              "div",
              { staticClass: "item-share" },
              [
                _c("label", [_vm._v("头图")]),
                _vm._v(" "),
                _c(
                  "el-upload",
                  {
                    attrs: {
                      action: "",
                      accept: ".jpg, .png, .jpeg",
                      "before-upload": _vm.saveFile("thumbnail"),
                      "show-file-list": false,
                      "http-request": _vm.uploadThumbnailToOss,
                      "auto-upload": true,
                    },
                  },
                  [
                    _vm.htmlStyle.share["img"] === ""
                      ? _c(
                          "div",
                          {
                            staticClass: "upload-share",
                            attrs: { slot: "trigger" },
                            slot: "trigger",
                          },
                          [_vm._v("\n            +\n          ")]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "img-upload-share",
                            attrs: { slot: "trigger" },
                            slot: "trigger",
                          },
                          [
                            _c("img", {
                              attrs: { src: _vm.htmlStyle.share["img"] },
                            }),
                          ]
                        ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "13px",
                      color: "rgb(170, 170, 170)",
                      "margin-left": "10px",
                    },
                  },
                  [_vm._v("(大小限制500kb)")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item-share" },
              [
                _c("label", { staticClass: "must-item" }, [_vm._v("标题")]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: {
                    placeholder: "请输入落地页标题",
                    maxlength: "50",
                    "show-word-limit": "",
                  },
                  on: { input: _vm.changeShareTitle },
                  model: {
                    value: _vm.htmlStyle.share["title"],
                    callback: function ($$v) {
                      _vm.$set(_vm.htmlStyle.share, "title", $$v)
                    },
                    expression: "htmlStyle.share['title']",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item-share" },
              [
                _c("label", [_vm._v("简介")]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    maxlength: "120",
                    rows: 3,
                    placeholder: "请输入落地页简介",
                    "show-word-limit": "",
                  },
                  on: { input: _vm.handleChange },
                  model: {
                    value: _vm.htmlStyle.share["desc"],
                    callback: function ($$v) {
                      _vm.$set(_vm.htmlStyle.share, "desc", $$v)
                    },
                    expression: "htmlStyle.share['desc']",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page-edit-title",
              staticStyle: { display: "flex", "align-items": "center" },
            },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.changeShow },
                  model: {
                    value: _vm.htmlStyle.show["video"],
                    callback: function ($$v) {
                      _vm.$set(_vm.htmlStyle.show, "video", $$v)
                    },
                    expression: "htmlStyle.show['video']",
                  },
                },
                [_vm._v("视频")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    action: "",
                    accept: ".mp4, .mov, .avi, .flv, .wmv, .mpeg, .rm",
                    "on-preview": _vm.handlePreview,
                    "show-file-list": false,
                    "before-upload": _vm.saveFile("video"),
                    "http-request": _vm.uploadFileToOss,
                    "auto-upload": true,
                    disabled: !_vm.htmlStyle.show["video"],
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: {
                        "upload-btn": true,
                        "upload-btn-disabled": !_vm.htmlStyle.show.video,
                      },
                      attrs: { slot: "trigger" },
                      slot: "trigger",
                    },
                    [
                      _vm._v("\n          +上传视频\n          "),
                      _c(
                        "span",
                        { staticStyle: { "font-size": "13px", color: "#aaa" } },
                        [_vm._v("(大小限制200M)")]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page-edit-title",
              staticStyle: { display: "flex", "align-items": "center" },
            },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.changeShow },
                  model: {
                    value: _vm.htmlStyle.show.photo,
                    callback: function ($$v) {
                      _vm.$set(_vm.htmlStyle.show, "photo", $$v)
                    },
                    expression: "htmlStyle.show.photo",
                  },
                },
                [_vm._v("图片")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    action: "",
                    "on-preview": _vm.handlePreview,
                    accept: ".jpg, .png, .jpeg",
                    "show-file-list": false,
                    "before-upload": _vm.saveFile("photo"),
                    "http-request": _vm.uploadPhotoToOss,
                    "auto-upload": true,
                    disabled: !_vm.htmlStyle.show["photo"],
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: {
                        "upload-btn": true,
                        "upload-btn-disabled": !_vm.htmlStyle.show.photo,
                      },
                      attrs: { slot: "trigger" },
                      slot: "trigger",
                    },
                    [
                      _vm._v("\n          +上传图片\n          "),
                      _c(
                        "span",
                        { staticStyle: { "font-size": "13px", color: "#aaa" } },
                        [_vm._v("(大小限制10M)")]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page-edit-title",
              staticStyle: { display: "flex", "align-items": "center" },
            },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.changeShow },
                  model: {
                    value: _vm.htmlStyle.show["content"],
                    callback: function ($$v) {
                      _vm.$set(_vm.htmlStyle.show, "content", $$v)
                    },
                    expression: "htmlStyle.show['content']",
                  },
                },
                [_vm._v("内容")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  staticStyle: { height: "40px", "margin-bottom": "10px" },
                  attrs: {
                    placeholder: "字体",
                    disabled: !_vm.htmlStyle.show["content"],
                  },
                  on: { change: _vm.chooseRegisterIndustry },
                  model: {
                    value: _vm.htmlStyle.fontFamily,
                    callback: function ($$v) {
                      _vm.$set(_vm.htmlStyle, "fontFamily", $$v)
                    },
                    expression: "htmlStyle.fontFamily",
                  },
                },
                _vm._l(_vm.allIndustryArr, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.value,
                      attrs: { label: item.name, value: item.value },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#8492a6",
                            "font-size": "13px",
                          },
                          style: { fontFamily: item.value },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "edit-font-size",
                attrs: {
                  "controls-position": "right",
                  label: "px",
                  min: 12,
                  disabled: !_vm.htmlStyle.show["content"],
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.htmlStyle.fontSize,
                  callback: function ($$v) {
                    _vm.$set(_vm.htmlStyle, "fontSize", $$v)
                  },
                  expression: "htmlStyle.fontSize",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "edit-color-bold": true,
                    disabled: !_vm.htmlStyle.show["content"],
                  },
                },
                [
                  _c("div", { staticClass: "edit-flex edit-flex-color" }, [
                    _c("span", { staticClass: "edit-color-name" }, [
                      _vm._v("颜色"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.htmlStyle.fontColor,
                          expression: "htmlStyle.fontColor",
                        },
                      ],
                      staticClass: "edit-choose-color",
                      attrs: {
                        type: "color",
                        disabled: !_vm.htmlStyle.show["content"],
                      },
                      domProps: { value: _vm.htmlStyle.fontColor },
                      on: {
                        change: _vm.colorChange,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.htmlStyle,
                            "fontColor",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "edit-flex" }, [
                    _c(
                      "p",
                      {
                        staticClass: "edit-font-weight",
                        on: { click: _vm.changeFontWeight },
                      },
                      [
                        _c(
                          "i",
                          { style: { fontWeight: _vm.htmlStyle.fontWeight } },
                          [_vm._v("B")]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "edit-color-bold": true,
                    disabled: !_vm.htmlStyle.show["content"],
                  },
                },
                [
                  _c("div", { staticClass: "edit-flex edit-flex-color" }, [
                    _c("span", { staticClass: "edit-color-name" }, [
                      _vm._v("背景颜色"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.htmlStyle.textBg,
                          expression: "htmlStyle.textBg",
                        },
                      ],
                      staticClass: "edit-choose-color",
                      attrs: {
                        "default-color": _vm.htmlStyle.bgColor,
                        type: "color",
                      },
                      domProps: { value: _vm.htmlStyle.textBg },
                      on: {
                        change: _vm.colorChange,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.htmlStyle, "textBg", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-input", {
                staticClass: "edit-text-area",
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "请输入内容",
                  disabled: !_vm.htmlStyle.show["content"],
                },
                on: { input: _vm.editContChange },
                model: {
                  value: _vm.htmlStyle.textareaCont,
                  callback: function ($$v) {
                    _vm.$set(_vm.htmlStyle, "textareaCont", $$v)
                  },
                  expression: "htmlStyle.textareaCont",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page-edit-title",
              staticStyle: { display: "flex", "align-items": "center" },
            },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.changeShow },
                  model: {
                    value: _vm.htmlStyle.show["input"],
                    callback: function ($$v) {
                      _vm.$set(_vm.htmlStyle.show, "input", $$v)
                    },
                    expression: "htmlStyle.show['input']",
                  },
                },
                [_vm._v("字段")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-show-para" },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { disabled: !_vm.htmlStyle.show["input"] },
                  on: { change: _vm.editContChange },
                  model: {
                    value: _vm.htmlStyle.checkedArr,
                    callback: function ($$v) {
                      _vm.$set(_vm.htmlStyle, "checkedArr", $$v)
                    },
                    expression: "htmlStyle.checkedArr",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "name", value: "1" } }, [
                    _vm._v("姓名"),
                  ]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: "phone", value: "2" } }, [
                    _vm._v("手机号"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    { attrs: { label: "company", value: "3" } },
                    [_vm._v("公司")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "edit-color-bold": true,
                    disabled: !_vm.htmlStyle.show["input"],
                  },
                  staticStyle: { "margin-top": "10px" },
                },
                [
                  _c(
                    "div",
                    {
                      class: {
                        "edit-flex": true,
                        "edit-flex-color": true,
                      },
                    },
                    [
                      _c("span", { staticClass: "edit-color-name" }, [
                        _vm._v("背景颜色"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.htmlStyle.pagraBg,
                            expression: "htmlStyle.pagraBg",
                          },
                        ],
                        staticClass: "edit-choose-color",
                        attrs: {
                          "default-color": _vm.htmlStyle.bgColor,
                          type: "color",
                          disabled: !_vm.htmlStyle.show["input"],
                        },
                        domProps: { value: _vm.htmlStyle.pagraBg },
                        on: {
                          change: _vm.colorChange,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.htmlStyle,
                              "pagraBg",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page-edit-title",
              staticStyle: { display: "flex", "align-items": "center" },
            },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.changeShow },
                  model: {
                    value: _vm.htmlStyle.show["button"],
                    callback: function ($$v) {
                      _vm.$set(_vm.htmlStyle.show, "button", $$v)
                    },
                    expression: "htmlStyle.show['button']",
                  },
                },
                [_vm._v("按钮")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "edit-text-area",
            attrs: {
              placeholder: "请输入按钮文案",
              maxlength: "12",
              "show-word-limit": "",
              disabled: !_vm.htmlStyle.show["button"],
            },
            on: { input: _vm.editContChange },
            model: {
              value: _vm.htmlStyle.buttonText,
              callback: function ($$v) {
                _vm.$set(_vm.htmlStyle, "buttonText", $$v)
              },
              expression: "htmlStyle.buttonText",
            },
          }),
          _vm._v(" "),
          false
            ? _c("div", { staticClass: "name-card" }, [
                _c(
                  "div",
                  { staticClass: "page-edit-title" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.changeShow },
                        model: {
                          value: _vm.htmlStyle.show["nameCard"],
                          callback: function ($$v) {
                            _vm.$set(_vm.htmlStyle.show, "nameCard", $$v)
                          },
                          expression: "htmlStyle.show['nameCard']",
                        },
                      },
                      [_vm._v("微名片")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                false
                  ? _c("div", { staticClass: "upload-card" }, [
                      _c("div", { staticClass: "header-card" }, [
                        _c("div", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v("\n            头像\n          "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "el-upload",
                              {
                                attrs: {
                                  disabled: !_vm.htmlStyle.show["nameCard"],
                                  action: "",
                                  accept: ".jpg, .png, .jpeg",
                                  "before-upload": _vm.saveFile("headerImg"),
                                  "show-file-list": false,
                                  "http-request": _vm.uploadHeaderImgToOss,
                                  "auto-upload": true,
                                },
                              },
                              [
                                _vm.htmlStyle.nameCard["headerImg"]
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      attrs: {
                                        src: _vm.htmlStyle.nameCard[
                                          "headerImg"
                                        ],
                                      },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ercode-card" }, [
                        _c("div", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v("\n            二维码\n          "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "el-upload",
                              {
                                attrs: {
                                  disabled: !_vm.htmlStyle.show["nameCard"],
                                  action: "",
                                  accept: ".jpg, .png, .jpeg",
                                  "before-upload": _vm.saveFile("erCodeImg"),
                                  "show-file-list": false,
                                  "http-request": _vm.uploadErCodeImgToOss,
                                  "auto-upload": true,
                                },
                              },
                              [
                                _vm.htmlStyle.nameCard["erCodeImg"]
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      attrs: {
                                        src: _vm.htmlStyle.nameCard[
                                          "erCodeImg"
                                        ],
                                      },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                false
                  ? _c(
                      "div",
                      { staticClass: "name-card-input" },
                      [
                        _c(
                          "el-form",
                          {
                            attrs: {
                              "label-width": "auto",
                              disabled: !_vm.htmlStyle.show["nameCard"],
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "姓名" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "请输入姓名",
                                  },
                                  on: { input: _vm.handleChange },
                                  model: {
                                    value: _vm.htmlStyle.nameCard.username,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.htmlStyle.nameCard,
                                        "username",
                                        $$v
                                      )
                                    },
                                    expression: "htmlStyle.nameCard.username",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "公司" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "请输入公司",
                                  },
                                  on: { input: _vm.handleChange },
                                  model: {
                                    value: _vm.htmlStyle.nameCard.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.htmlStyle.nameCard,
                                        "companyName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "htmlStyle.nameCard.companyName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "职位" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "请输入职位",
                                  },
                                  on: { input: _vm.handleChange },
                                  model: {
                                    value: _vm.htmlStyle.nameCard.position,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.htmlStyle.nameCard,
                                        "position",
                                        $$v
                                      )
                                    },
                                    expression: "htmlStyle.nameCard.position",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }