<template>
  <div class="setPackage-main">
    <div class="main-row">
      <div class="set-filter">
        <span>设置过滤条件</span>
        <div class="filter">
          <el-row v-for="item in filterList" :key="item.id">
            <el-col :span="23">
              <filter-item :ref="`filter${item.id}`" :saved-data="item.data" />
            </el-col>
            <el-col :span="1">
              <div style="height: 48px;line-height: 48px">
                <i
                  class="el-icon-delete del-icon"
                  @click="removeFilter(item.id)"
                ></i>
              </div>
            </el-col>
          </el-row>
          <div>
            <i
              class="el-icon-circle-plus-outline add-icon"
              @click="addFilter"
            ></i>
          </div>
        </div>
      </div>
      <div class="apply-package">
        <span>适用线索包</span>
        <div class="package">
          <el-radio-group v-model="selectPackage">
            <el-radio :label="0">当前线索包</el-radio>
            <el-radio :label="1">全部线索包</el-radio>
            <el-radio :label="-1" :disabled="!packageList.length"
              >自定义</el-radio
            >
          </el-radio-group>
          <div
            v-show="selectPackage === -1 && packageList.length"
            style="padding: 10px 20px;box-sizing: border-box"
          >
            <el-checkbox-group v-model="checkList" class="label">
              <el-checkbox
                :label="item.id"
                v-for="item in packageList"
                :key="item.id"
                >{{ item.packageName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancelBtn">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="confirmBtn"
        v-loading="loading"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import filterItem from '@/views/optimizationPackage/dialog/filterItem'
import { packageListNews, packageEditNew } from '@/api/thread'

export default {
  name: 'setPackage',
  components: {
    filterItem
  },
  data() {
    return {
      packageList: [],
      filterList: [{ id: 0 }],
      selectPackage: 0,
      checkList: [],
      current: null,
      loading: false,
      savedData: {}
    }
  },
  mounted() {
    const modalOptions = this.$store.state.plugin.modal.options
    this.current = modalOptions.current
    if (this.current.filterCondition) {
      const data = JSON.parse(this.current.filterCondition)
      const targetPack = data.target_pack
      if (targetPack.length === 1 && [0, 1].includes(targetPack[0])) {
        this.selectPackage = targetPack[0]
      } else {
        this.selectPackage = -1
        this.checkList = targetPack
      }
      const filterData = data.filter
      this.filterList = []
      filterData.forEach((item, index) => {
        this.filterList.push({
          id: index,
          data: item
        })
      })
    }
    this.getPackageList()
  },
  methods: {
    getPackageList() {
      packageListNews().then(res => {
        if (res.code === 60000) {
          this.packageList = res.data
        }
      })
    },

    addFilter() {
      if (this.filterList.length < 5) {
        let ids = this.filterList.map(item => item.id)
        let maxId = Math.max.apply(null, ids)
        this.filterList.push({
          id: maxId + 1
        })
      } else {
        this.$store.dispatch('showTip', {
          text: '最多只能设置5个过滤项',
          type: 'error'
        })
      }
    },

    removeFilter(id) {
      if (this.filterList.length === 1) {
        this.$store.dispatch('showTip', {
          text: '最少需设置1个过滤项',
          type: 'error'
        })
        return
      }
      this.filterList = this.filterList.filter(i => i.id !== id)
    },

    cancelBtn() {
      this.$store.dispatch('hideModal')
    },

    confirmBtn() {
      if (this.loading) return
      let res = Object.create(null)
      let params = Object.create(null)
      let filter = []
      this.filterList.forEach(item => {
        const refEl = this.$refs[`filter${item.id}`][0]
        filter = [...filter, ...refEl.getResult()]
      })
      res['filter'] = filter
      if (this.selectPackage !== -1) {
        res['target_pack'] = [this.selectPackage]
      } else {
        if (!this.checkList.length) {
          this.$store.dispatch('showTip', {
            text: '请选择需要优化的线索包',
            type: 'error'
          })
          return
        }
        res['target_pack'] = this.checkList
      }
      params['id'] = this.current.packageId
      params['filterCondition'] = JSON.stringify(res)
      this.loading = true
      packageEditNew(params)
        .then(res => {
          this.loading = false
          if (res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
            this.$store.commit('update', {
              target: this.$store.state.plugin.modal.options,
              data: {
                refresh: true
              }
            })
            this.$store.dispatch('hideModal')
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.main-row {
  min-height: 200px;
  padding: 20px 0;
  box-sizing: border-box;
}
.footer {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
}
.footer:before {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
}
.set-filter,
.apply-package {
  padding: 0 10px;
  box-sizing: border-box;
}
.filter,
.package {
  padding: 10px 30px 10px 30px;
  box-sizing: border-box;
}
.del-icon {
  color: #41cabf;
  font-size: 20px;
  cursor: pointer;
}
.add-icon {
  font-size: 20px;
}
.add-icon:hover {
  color: #41cabf;
  cursor: pointer;
}
.label /deep/ .el-checkbox__label {
  color: #606266 !important;
}
</style>
