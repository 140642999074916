/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  // shy07s_tips: 此处可添加禁止使用的用户名
  const validMap = ['admin', 'editor']
  return validMap.indexOf(str.trim()) >= 0
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isCode(path) {
  // 验证验证码
  return /^\d{6}$/.test(path)
}

export function isPhone(path) {
  // 验证手机号
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function isMobile(phone) {
  const isMo = /^1(3|4|5|6|7|8|9)\d{9}$/
  return isMo.test(phone)
}

export function isPsw(psw) {
  // 验证登录密码  6-20 位，包含英文和数字
  const ispsw = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/.test(psw)
  return ispsw
}

export function pwd(psw) {
  const isPwd = /^(?![\d]+$)(?![a-zA-Z]+$)(?![!@#$%^&*()_-]+$)[\da-zA-Z!@#$%^&*()_-]{6,20}$/.test(
    psw
  )
  return isPwd
}

export function isObjNull(obj) {
  // 判断是否为空
  for (const i in obj) {
    return true
  }
  return false
}

// 处理1688字段
export function is1688Data(obj, val) {
  if (isObjNull(obj)) {
    const item = Object.keys(obj)
    for (const i in item) {
      if (item[i] === val) {
        return obj[item[i]]
      }
    }
  } else {
    return ''
  }
}

export function IEVersion() {
  var userAgent = navigator.userAgent //取得浏览器的userAgent字符串
  var isIE =
    userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 //判断是否IE<11浏览器
  var isEdge = userAgent.indexOf('Edge') > -1 && !isIE //判断是否IE的Edge浏览器
  var isIE11 =
    userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
  if (isIE) {
    var reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    var fIEVersion = parseFloat(RegExp['$1'])
    if (fIEVersion == 7) {
      return 7
    } else if (fIEVersion == 8) {
      return 8
    } else if (fIEVersion == 9) {
      return 9
    } else if (fIEVersion == 10) {
      return 10
    } else {
      return 6 //IE版本<=7
    }
  } else if (isEdge) {
    return 'edge' //edge
  } else if (isIE11) {
    return 11 //IE11
  } else {
    return -1 //不是ie浏览器
  }
}

export function isPC() {
  var userAgentInfo = navigator.userAgent
  var Agents = new Array(
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  )
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}
