<template>
  <div ref="moduleitem" v-loading="refreshLoading">
    <div class="title" ref="computedComPic" id="computedComPic">
      {{ moduleParam.module_title }}
    </div>

    <div class="touch-cont-mang p-b-38">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in tabArr"
          :key="index + item"
          :label="`${item}(${handleTabData(item)})`"
          :name="item"
        >
          <div class="touch-tab-cont">
            <component
              :key="item"
              :otherParams="otherParams"
              :cardType="cardType"
              :is="modulCont"
              :detail="detail"
              :moduleParam="moduleParam"
              @getComDetailModel="getComDetailModel"
              :moduleDetail="moduleDetail"
              :packageId="packageId"
              ref="comModuleCont"
            ></component>
            <el-pagination
              v-if="activeName != '人脉'"
              @current-change="handleCurrentChange"
              :current-page="pePage.pageNo"
              :page-sizes="[10]"
              :page-size="pePage.pageSize"
              layout="total, sizes, prev, pager, next,slot"
              :total="pePage.total"
              ref="pageCheck"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getComDetailModel } from '@/api/thread'
import { isObjNull } from '@/utils/validate'
import cardtouch from '@/components/ModulePack/cardtouch'
import Bus from '@/utils/bus'

export default {
  name: 'touchModule',
  props: {
    moduleParams: {
      type: Array,
      default: () => []
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      pePage: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      tabArr: [],
      activeName: '',
      ObjectArr: {},
      moduleDetail: {},
      cardType: 'phone',
      showModule: false,
      otherParams: {},
      refreshLoading: false,
      testTime: null,
      modulCont: cardtouch,
      touchIndex: 0
    }
  },

  computed: {
    moduleParam() {
      const data = this.moduleParams.filter(i => i.module_api === 'touch')
      return data.length ? data[0] : {}
    },

    packageId() {
      return this.moduleParam.packageId
    }
  },

  watch: {
    detail() {
      this.getComDetailModel()
    }
  },

  mounted() {
    this.getComDetailModel()
  },

  beforeDestroy() {
    Bus.$off('refreshModule' + this.moduleParam.module_api)
  },

  methods: {
    handleCurrentChange(e) {
      this.pePage.pageNo = e
      this.refreshLoading = true
      this.getComDetailModel()
    },
    handleTabData(item) {
      return this.ObjectArr[item]?.size
    },
    changeModuleDetail(val) {
      // debugger
      this.moduleDetail = val
    },

    dataParam() {
      this.otherParams.pageSize = this.moduleParam.module_pageSize
      this.otherParams.isScroll = this.moduleParam.module_scroll === 1
      this.otherParams.isReceive = this.moduleParam.isReceive
      this.otherParams.shade = this.moduleParam.module_shade
    },
    handleClick(val) {
      // debugger
      this.refreshLoading = true
      Object.assign(this.moduleDetail, {}, this.ObjectArr[val.name])
      const index = Number(val.index)
      this.touchIndex = index
      if (val.name === '电话') {
        this.cardType = 'phone'
      }
      if (val.name === '邮箱') {
        this.cardType = 'email'
      }
      if (val.name === '地址') {
        this.cardType = 'address'
      }
      if (val.name === '人脉') {
        this.cardType = 'contact'
      }
      this.$store.state.settings.touchModuleList = []
      this.pePage.pageNo = 1
      this.getComDetailModel()
    },

    getComDetailModel() {
      this.dataParam()
      const params = {
        module: this.moduleParam.module_api,
        packageId: this.moduleParam.packageId,
        companyUuid: this.moduleParam.companyUuid,
        param: {
          pageNo: this.pePage.pageNo,
          pageSize: 10,
          touch_branch: this.cardType
        }
      }

      if (!this.moduleParam.module_api) return
      getComDetailModel(params).then(res => {
        if (res.code === 60000) {
          if (isObjNull(res.data)) {
            console.log(res.data, 'request123')
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                modulePackList: [
                  ...this.$store.state.plugin.modulePackList,
                  this.moduleParam
                ]
              }
            })
            // debugger
            this.moduleDetail = {}
            this.showModule = true
            this.ObjectArr = res.data
            console.log(this.ObjectArr, this.activeName, 'this.ObjectArr')
            const tab = []
            for (var i in this.ObjectArr) {
              tab.push(i)
            }

            this.tabArr = [...tab]
            this.activeName = tab[this.touchIndex]
            this.pePage.total = this.ObjectArr[this.activeName]?.size || 0
            this.$store.state.settings.touchModuleList = this.ObjectArr[
              this.activeName
            ]?.list
            console.log(this.touchIndex)
            this.$store.commit('update', {
              target: this,
              data: {
                moduleDetail: Object.assign(
                  this.moduleDetail,
                  {},
                  this.ObjectArr[this.activeName]
                )
              }
            })
          } else {
            this.showModule = false
            this.moduleDetail = {}
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                modulePackList: this.$store.state.plugin.modulePackList.filter(
                  item => item.module_title !== this.moduleParam.module_title
                )
              }
            })
            return
          }
        }
        this.refreshLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.card-name-cont {
  display: inline-block;
  width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custo-phone-show {
  display: inline-block;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.phone-card-list p {
  display: inline-block;
  font-size: 12px;
  line-height: 17px;
  width: 220px;
}

.maimialogo {
  position: relative;
  top: 6px;
  left: 10px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('../../assets/maimai_logo.png') no-repeat center;
  background-size: 24px 24px;
  cursor: pointer;
}

.card-tag-name {
  display: inline-block;
  height: 17px;
  background: #ebfaf9;
  padding: 0 10px;
  color: $main-color;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-left: 12px;
  vertical-align: top;
}

.card-f14-txt {
  font-size: 14px;
  color: $main-color;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  margin-left: 6px;
}

.card-phone-name {
  font-size: 12px;
  color: #2e2e2e;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  vertical-align: top;
}

.card-cont-txt {
  height: 35px;
  width: 100%;
}

.card-cont-txt ::v-deep .el-checkbox {
  margin-right: 6px;
}

.touch-phone-card {
  padding: 2px 0 0;
  height: 100%;
}

.phone-card-list {
  display: inline-block;
  height: 80px;
  width: 220px;
  margin: 5px 5px;
  background: $used-color;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11);
  cursor: pointer;
  position: relative;
  padding-left: 10px;
}

.touch-card-title {
  font-weight: 400;
}

.touch-tab-cont {
  width: 100%;
  min-height: 120px;
  padding: 0;
  position: relative;
}

.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $used-color;
  opacity: 0.9;
  z-index: 100;
}

.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}

.touch-no-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.touch-cont-mang {
  width: 100%;
  background-color: $used-color;
  margin-top: 4px;
}

.title {
  width: 100%;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #2e2e2e;
}

::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}

::v-deep .el-tabs__nav-wrap {
  margin-left: 20px;
}

::v-deep .el-tabs__item {
  font-size: 14px;
}

::v-deep .el-tabs__nav {
  width: 100%;
}

::v-deep .el-tabs__item {
  text-align: center;
  width: 25%;
}
</style>
