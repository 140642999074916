var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-icon" },
    [
      _vm.type == "icon"
        ? [_c("i", { class: _vm.peClass, style: `font-size:${_vm.size}px` })]
        : [_c("img", { staticClass: "img", attrs: { src: _vm.img } })],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }