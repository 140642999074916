var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.sub
        ? _c("div", { staticClass: "user_count_show" }, [
            _c("div", { staticClass: "count_show_cont" }, [
              _c("div", { staticClass: "account_user_cont child_recharge" }, [
                _c("span", [
                  _vm._v("子账号数量： " + _vm._s(_vm.childAccNum.childNumber)),
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { display: "inline-block" } }, [
                  _vm._v(
                    "剩余子账号数量： " + _vm._s(_vm.childAccNum.remainNumber)
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "count_show_cont" }, [
              _c("div", { staticClass: "account_user_cont flow_recharge" }, [
                _c("span", [
                  _vm._v(
                    "【线索包】剩余可用点数：" +
                      _vm._s(_vm.currentKeyNum.companyAvailableFree) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "【企业查询/高级查找/自助找客】剩余可用点数：" +
                      _vm._s(_vm.currentKeyNum.ziZhuAvailableFree) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "【其他配额】剩余可用点数：" +
                      _vm._s(_vm.currentKeyNum.companyAvailable) +
                      "\n        "
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "showUpTip",
          attrs: { dialogStatus: _vm.dialogStatus },
          on: { submitContBtn: _vm.submitContBtn },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "line-height": "40px" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.showUpCont))]),
              _vm._v(" "),
              _vm.currentClick === "d"
                ? _c("img", {
                    staticStyle: { width: "140px", height: "140px" },
                    attrs: { src: require("../../assets/no-kp.png") },
                  })
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }