var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tool-tip" },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", placement: "top-start" },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [_vm._t("txt-content")],
            2
          ),
          _vm._v(" "),
          _vm._t("txt-btn"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }