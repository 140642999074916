var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contactDetail-main" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", [_vm._v("\n      " + _vm._s(_vm.user.contact) + "\n    ")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.editContact },
              },
              [_vm._v("编辑")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.delContact },
              },
              [_vm._v("删除")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "u-main" },
        _vm._l(_vm.columns, function (item) {
          return _c(
            "el-row",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "u-header" }, [
                  _vm._v("\n          " + _vm._s(item.name) + "\n        "),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.user[item.key] || item.value) +
                      "\n        "
                  ),
                ]),
              ]),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            width: "800px",
            top: "90px",
            visible: _vm.showEdit,
            "show-close": false,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEdit = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slot-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v("\n        编辑\n      ")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.showEdit = false
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-close close" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c("edit-contact", {
            attrs: { "modal-options": _vm.user },
            on: {
              close: function ($event) {
                _vm.showEdit = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }