var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c("el-rate", {
        attrs: {
          disabled: "",
          "text-color": "#ff9900",
          "score-template": "{value}",
        },
        model: {
          value: _vm.countScore,
          callback: function ($$v) {
            _vm.countScore = $$v
          },
          expression: "countScore",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }