<template>
  <div class="pond-detail-drawr">
    <el-row type="flex" class="row-bg" justify="space-between">
      <span style="font-size:18px;font-weight: bold" v-if="showTitle"
        >联系人信息</span
      >
      <div v-else>
        <el-tag
          v-for="i in selectCheckObj"
          style="border-color:#5dceca;margin-left: 20px;height: 30px"
          :disable-transitions="true"
          color="#5dceca"
          effect="dark"
          closable
          @close="closeContactTag(i)"
        >
          {{ i.contact }}
        </el-tag>
      </div>
      <div
        class="search-botton"
        style="margin-left:10px;"
        @click="addContactDia"
        v-dt-permission="'客户管理CRM-客户公海-联系人'"
      >
        新建联系人
      </div>
    </el-row>
    <div style="width: 100%;margin-top: 30px;margin-bottom: 40px">
      <el-row>
        <el-checkbox-group v-model="checkList">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            v-for="item in contactList"
          >
            <el-card
              style="margin: 10px;min-height: 280px;height: auto;padding-bottom: 10px;box-sizing: border-box"
            >
              <el-col :span="2" v-if="!showTitle">
                <div style="margin-top: 7px;">
                  <el-checkbox
                    :label="item.id"
                    :key="item.id"
                    @change="checkRow"
                    :disabled="!(item.contact && item.position && item.mobile)"
                    >{{ '' }}</el-checkbox
                  >
                </div>
              </el-col>
              <el-col :span="2" v-else> </el-col>
              <el-col :span="19">
                <div
                  class="search-botton"
                  style="margin-left:2px;height: 24px;background:rgba(0, 0, 0, 0);margin-top: 5px;cursor:default;line-height: 24px;"
                >
                  <el-popover
                    placement="top"
                    trigger="hover"
                    :content="item.contact"
                  >
                    <el-badge
                      slot="reference"
                      :value="item.isKp == '1' ? 'KP' : ''"
                      class="badge-item"
                      type="primary"
                    >
                      <span
                        style="margin:0 0 0 12px;display:inline-block;font-size: 14px;color:#000;"
                        >{{
                          item.contact.length > 4
                            ? item.contact.substring(0, 3) + '...'
                            : item.contact
                        }}</span
                      >
                    </el-badge>
                  </el-popover>
                  <span
                    style="margin:0 0 0 20px;display:inline-block;background: #EBFAF9;color: #41CABF;"
                    >{{
                      `${item.department || ''}-${item.position || ''}`
                        | handelTextFilter
                    }}</span
                  >
                </div>
              </el-col>
              <el-col :span="1">
                <div
                  style="width: 30px;height: 30px; cursor:pointer;font-size: 14px;"
                >
                  <svg-icon
                    icon-class="set"
                    v-if="$store.getters.accountType == '1' || isActiveTab != 2"
                    @click="editContactDia(item)"
                    class="filtrate"
                    style="margin-top: 10px;color: #41cabf"
                  />
                  <svg-icon
                    icon-class="set"
                    v-else
                    class="filtrate"
                    style="margin-top: 10px;color: #949494"
                  />
                </div>
              </el-col>
              <el-col :span="1" :offset="1">
                <div
                  style="width: 30px;height: 30px;cursor:pointer;font-size: 14px;"
                >
                  <svg-icon
                    icon-class="delete"
                    v-if="$store.getters.accountType == '1' || isActiveTab != 2"
                    @click="deleteCard(item)"
                    class="filtrate"
                    style="margin-top: 10px;color: #41cabf"
                  />
                  <svg-icon
                    icon-class="delete"
                    v-else
                    class="delete"
                    style="margin-top: 10px;color: #949494"
                  />
                </div>
              </el-col>

              <el-col :span="24">
                <div class="info-item" style="display: flex">
                  <el-col :span="8" :offset="2">
                    <div style="text-align: left;font-size: 14px;">
                      信息完善度：
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <dt-score :score="item.score" :key="scoreKey"></dt-score>
                  </el-col>
                </div>
              </el-col>

              <el-col :span="24">
                <div class="info-item">
                  <el-col :span="8" :offset="2">
                    <div style="text-align: left;font-size: 14px;">座机：</div>
                  </el-col>
                  <el-col :span="16">
                    {{ item.landLine }}
                  </el-col>
                </div>
              </el-col>

              <el-col :span="24">
                <div class="info-item">
                  <el-col :span="8" :offset="2">
                    <div style="text-align: left;font-size: 14px;">手机：</div>
                  </el-col>
                  <el-col :span="16">
                    {{ item.mobile }}
                  </el-col>
                </div>
              </el-col>

              <el-col :span="24">
                <div class="info-item">
                  <el-col :span="8" :offset="2">
                    <div style="text-align: left;">微信：</div>
                  </el-col>
                  <el-col :span="16">
                    {{ item.wechat }}
                  </el-col>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="info-item">
                  <el-col :span="8" :offset="2">
                    <div style="text-align: left;font-size: 14px;">邮箱：</div>
                  </el-col>
                  <el-col :span="16">
                    {{ item.email }}
                  </el-col>
                </div>
              </el-col>
              <el-col :span="24">
                <div
                  style="height: 14px;line-height: 14px;margin: 10px 0px 30px 0;font-size: 14px;display: flex"
                >
                  <el-col :span="8" :offset="2">
                    <div style="text-align: left;font-size: 14px;">备注：</div>
                  </el-col>
                  <el-col :span="16">
                    <div
                      style="display:-webkit-box;overflow:hidden;text-overflow:ellipsis;-webkit-box-orient:vertical;"
                    >
                      {{ item.remark }}
                    </div>
                  </el-col>
                </div>
              </el-col>
              <el-col :span="24">
                <div
                  style="height: 8px;line-height: 28px;font-size: 8px;color: #7F7F7F"
                  v-if="item.creater"
                >
                  <el-col :span="4" :offset="2">
                    <div style="text-align: left;">创建人：</div>
                  </el-col>
                  <el-col :span="6">
                    <div>{{ item.creater }}</div>
                  </el-col>
                  <el-col :span="10">
                    {{ item.createdAt }}
                  </el-col>
                </div>
              </el-col>
              <el-col :span="24">
                <div
                  style="height: 8px;line-height: 8px;margin: 5px 0px;font-size: 8px;color: #7F7F7F"
                >
                  <el-col :span="4" :offset="2">
                    <div style="text-align: left;">修改人：</div>
                  </el-col>
                  <el-col :span="6">
                    <div>{{ item.renew }}</div>
                  </el-col>
                  <el-col :span="10" v-if="item.renew">
                    {{ item.updatedAt }}
                  </el-col>
                </div>
              </el-col>
            </el-card>
          </el-col>
          <el-col :span="24">
            <div
              class="point"
              v-if="!showTitle && contactList.length"
              style="color: #aaa"
            >
              注：只能选择姓名、电话、职位齐全的有效联系人。
            </div>
          </el-col>
        </el-checkbox-group>
      </el-row>
      <div
        style="width: 100%;height: 300px;text-align: center;line-height: 300px"
        v-if="contactList.length == 0"
      >
        暂无联系人信息
      </div>
    </div>
    <dia-model
      @submitContBtn="addContactBtn"
      class="pond-form-input"
      ref="addContact"
      :btnLoading="btnLoading"
      :dialogStatus="addContactModel"
    >
      <div slot="modalCont" style="padding-top:20px;">
        <el-form
          ref="form"
          :model="formData"
          label-width="auto"
          :rules="createUserRules"
        >
          <el-form-item label="姓名" required>
            <el-input
              placeholder="请输入姓名"
              v-model="formData.contact"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">身份</div>
            <el-select
              v-model="formData.isKp"
              placeholder="请选择"
              style="width: 100%;"
            >
              <el-option label="关键人" value="1"></el-option>
              <el-option label="中间人" value="2"></el-option>
              <el-option label="普通" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">部门</div>
            <el-input
              placeholder="请输入部门"
              v-model="formData.department"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">职位</div>
            <el-input
              placeholder="请输入职位"
              v-model="formData.position"
            ></el-input>
          </el-form-item>
          <el-form-item prop="mobile">
            <div slot="label" style="margin-left:10px;">手机</div>
            <el-input
              placeholder="请输入手机号码"
              v-model="formData.mobile"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <div slot="label" style="margin-left:10px;">座机</div>
            <el-input
              placeholder="请输入座机号码"
              v-model="formData.landLine"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <div slot="label" style="margin-left:10px;">微信号</div>
            <el-input
              placeholder="请输入微信号"
              style="ime-mode:disabled"
              onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
              onpaste="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
              oncontextmenu="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
              v-model="formData.wechat"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">邮箱</div>
            <el-input
              placeholder="请输入邮箱"
              v-model="formData.email"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">状态</div>
            <el-select
              v-model="formData.mark"
              placeholder="请选择状态"
              style="width: 100%"
            >
              <el-option
                :label="_item.name"
                :value="_item.value"
                v-for="_item in markDescList"
                :key="_item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">备注</div>
            <el-input
              type="textarea"
              placeholder="请输入备注"
              v-model="formData.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </dia-model>
    <dia-model
      @submitContBtn="editContactBtn"
      class="pond-form-input"
      ref="editContact"
      :btnLoading="btnLoading"
      :dialogStatus="editContactModel"
    >
      <div slot="modalCont" style="padding-top:20px;">
        <el-form
          ref="formedit"
          :model="formData"
          label-width="auto"
          :rules="createUserRules"
        >
          <el-form-item label="姓名" required>
            <el-input
              placeholder="请输入姓名"
              v-model="formData.contact"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">身份</div>
            <el-select
              v-model="formData.isKp"
              placeholder="请选择"
              style="width: 100%;"
            >
              <el-option label="关键人" value="1"></el-option>
              <el-option label="中间人" value="2"></el-option>
              <el-option label="普通" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">部门</div>
            <el-input
              placeholder="请输入部门"
              v-model="formData.department"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">职位</div>
            <el-input
              placeholder="请输入职位"
              v-model="formData.position"
            ></el-input>
          </el-form-item>
          <el-form-item prop="mobile">
            <div slot="label" style="margin-left:10px;">手机</div>
            <el-input
              placeholder="请输入手机号码"
              v-model="formData.mobile"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <div slot="label" style="margin-left:10px;">座机</div>
            <el-input
              placeholder="请输入座机号码"
              v-model="formData.landLine"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <div slot="label" style="margin-left:10px;">微信号</div>
            <el-input
              placeholder="请输入微信号"
              style="ime-mode:disabled"
              onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
              onpaste="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
              oncontextmenu="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
              v-model="formData.wechat"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">邮箱</div>
            <el-input
              placeholder="请输入邮箱"
              v-model="formData.email"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">状态</div>
            <el-select
              v-model="formData.mark"
              placeholder="请选择状态"
              style="width: 100%"
            >
              <el-option
                :label="_item.name"
                :value="_item.value"
                v-for="_item in markDescList"
                :key="_item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div slot="label" style="margin-left:10px;">备注</div>
            <el-input
              type="textarea"
              placeholder="请输入备注"
              v-model="formData.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </dia-model>
    <dia-model
      ref="deleteMark"
      :dialogStatus="dialogDelStatus"
      @submitContBtn="submitDelBtn"
    >
      <div slot="modalCont" style="margin: 20px 0;">
        确认删除 ？
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import DtScore from './dialog/score'
import {
  coustomerAdd,
  coustomerList,
  coustomerEdit,
  coustomerDel
} from '@/api/user'
import { getCusListFilter } from '@/api/customerpond'
import { getDetailAsignOpthon } from '@/api/thread'
export default {
  name: 'ponDrawer',
  components: {
    diaModel,
    DtScore
  },
  props: {
    rowCont: {
      type: Object,
      default: () => {}
    },
    companyUuid: {
      type: String,
      default: ''
    },
    isActiveTab: {
      type: [String, Number],
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    choContactList: {
      type: Array,
      default: () => []
    },
    tagName: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    let validateMobile = (rule, value, fcb) => {
      if (!value) {
        fcb()
      }
      if (
        value &&
        value.toString().length === 11 &&
        value.toString().startsWith('1')
      ) {
        fcb()
      } else {
        fcb(new Error('手机号格式错误'))
      }
    }
    return {
      scoreKey: 0,
      addContactModel: {},
      editContactModel: {},
      formData: {
        contact: '',
        mobile: '',
        landLine: '', //座机
        position: '',
        department: '',
        remark: '',
        wechat: '',
        email: '',
        isKp: '',
        mark: ''
      },
      markDescList: [],
      contactList: [],
      dialogDelStatus: {},
      selectItem: {},
      checkList: [],
      selectPeos: [],
      selectCheckObj: [],
      btnLoading: false,
      createUserRules: {
        mobile: [{ validator: validateMobile, trigger: 'blur' }]
      }
    }
  },
  filters: {
    handelTextFilter: function(val) {
      console.log(val)
      let text = val.split('-')
      if (!text.length) {
        return ''
      }
      text = text.filter(item => item !== '')
      if (text.length === 2) {
        return val
      }
      return text[0]
    }
  },
  mounted() {
    this.selectCheckObj = this.choContactList
    this.checkList = []
    for (let i in this.selectCheckObj) {
      this.checkList.push(this.selectCheckObj[i].id)
    }
    this.getContactList()
    this.getDetailAsignOpthon()
  },
  methods: {
    getDetailAsignOpthon() {
      getDetailAsignOpthon().then(res => {
        this.markDescList = res.data
      })
    },
    closeContactTag(val) {
      const index = this.checkList.findIndex(ite => ite === val)
      this.checkList.splice(index, 1)
      const index1 = this.selectCheckObj.findIndex(ite => ite.id === val)
      this.selectCheckObj.splice(index, 1)
    },
    checkRow() {
      this.selectCheckObj = []
      for (let i in this.checkList) {
        for (let j in this.contactList) {
          if (this.checkList[i] == this.contactList[j].id) {
            this.selectCheckObj.push(this.contactList[j])
          }
        }
      }
      console.log(this.selectCheckObj)
    },
    deleteCard(item) {
      this.selectItem = item
      this.dialogDelStatus = {
        dialogWidth: '350px',
        dialogTitle: item.contact,
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$refs.deleteMark.dialogVisible = true
    },
    addContactDia() {
      this.formData = {
        contact: '',
        mobile: '',
        landLine: '', //座机
        position: '',
        department: '',
        remark: '',
        wechat: '',
        email: '',
        isKp: ''
      }
      this.addContactModel = {
        dialogWidth: '600px',
        dialogTitle: '新建联系人',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$refs.addContact.dialogVisible = true
    },
    editContactDia(item) {
      this.formData = { ...item }
      this.formData.isKp = this.formData.isKp ? this.formData.isKp + '' : ''
      this.editContactModel = {
        dialogWidth: '550px',
        dialogTitle: '编辑联系人',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$refs.editContact.dialogVisible = true
    },

    addContactBtn() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.formData.wechat) {
            this.formData.wechat = this.formData.wechat.replace(
              /[\u4E00-\u9FA5]/g,
              ''
            )
          }

          if (!this.formData.contact) {
            this.$message({
              message: '请输入姓名',
              type: 'error'
            })
            return
          }
          const params = {
            companyUuid: this.companyUuid,
            contact: this.formData.contact.replace(/(^\s*)|(\s*$)/g, ''),
            mobile: this.formData.mobile.replace(/(^\s*)|(\s*$)/g, ''),
            landLine: this.formData.landLine.replace(/(^\s*)|(\s*$)/g, ''),
            position: this.formData.position.replace(/(^\s*)|(\s*$)/g, ''),
            department: this.formData.department.replace(/(^\s*)|(\s*$)/g, ''),
            remark: this.formData.remark.replace(/(^\s*)|(\s*$)/g, ''),
            wechat: this.formData.wechat.replace(/(^\s*)|(\s*$)/g, ''),
            email: this.formData.email.replace(/(^\s*)|(\s*$)/g, ''),
            isKp: this.formData.isKp,
            mark: this.formData.mark
          }
          this.btnLoading = true
          coustomerAdd(params).then(res => {
            this.btnLoading = false
            if (res.code === 60000) {
              this.getContactList()
              this.checkList = []
              this.$refs.addContact.dialogVisible = false
            }
          })
        }
      })
    },
    editContactBtn() {
      this.$refs['formedit'].validate(valid => {
        if (valid) {
          if (!this.formData.contact) {
            this.$message({
              message: '请输入姓名',
              type: 'error'
            })
            return
          }
          if (this.formData.wechat) {
            this.formData.wechat = this.formData.wechat.replace(
              /[\u4E00-\u9FA5]/g,
              ''
            )
          }
          this.btnLoading = true
          const params = this.formData
          coustomerEdit(params).then(res => {
            this.btnLoading = false
            if (res.code === 60000) {
              this.getContactList()
              this.$refs.editContact.dialogVisible = false
            }
          })
        }
      })
    },
    submitDelBtn() {
      const params = {
        id: this.selectItem.id
      }

      coustomerDel(params).then(res => {
        if (res.code === 60000) {
          this.getContactList()
          this.$refs.deleteMark.dialogVisible = false
        }
      })
    },
    getContactList() {
      const params = {
        companyUuid: this.companyUuid
      }
      coustomerList(params).then(res => {
        if (res.code === 60000) {
          this.contactList = res.data != null ? res.data : []
          let _this = this
          this.$nextTick(() => {
            this.checkList = []
            for (let i in this.selectCheckObj) {
              this.checkList.push(this.selectCheckObj[i].id)
            }
            this.scoreKey += 1 //重置完善度组件
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.pond-detail-drawr {
  width: auto;
  height: 100%;
  padding: 20px 20px 30px;
}
.search-botton {
  width: 90px;
  height: 30px;
  background-color: #78d9d1;
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
  border-radius: 4px;
  cursor: pointer;
}
.search-botton {
  width: auto;
  height: 30px;
  padding: 0 10px;
  background-color: #78d9d1;
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
  border-radius: 4px;
}
.point {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 30px;
  color: #aaa;
  margin-top: 30px;
  font-size: 14px;
}
.info-item {
  height: 14px;
  line-height: 14px;
  margin: 10px 0px 10px 0;
  font-size: 14px;
  display: flex;
}
</style>
