var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "proxy-setting-main",
      attrs: { "element-loading-text": "加载中" },
    },
    [
      _c("el-scrollbar", { staticClass: "dt-dropdown-dt scroll" }, [
        _c(
          "div",
          { staticClass: "main" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  "label-width": "85px",
                  rules: _vm.rules,
                  model: _vm.formData,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "线索包" } },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "all" },
                        model: {
                          value: _vm.packageType,
                          callback: function ($$v) {
                            _vm.packageType = $$v
                          },
                          expression: "packageType",
                        },
                      },
                      [_vm._v("全部")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "userSelect" },
                        model: {
                          value: _vm.packageType,
                          callback: function ($$v) {
                            _vm.packageType = $$v
                          },
                          expression: "packageType",
                        },
                      },
                      [_vm._v("自定义")]
                    ),
                    _vm._v(" "),
                    _vm.packageType === "userSelect"
                      ? _c(
                          "el-row",
                          { attrs: { gutter: 10 } },
                          _vm._l(_vm.packageAllList, function (item) {
                            return _c(
                              "el-col",
                              {
                                key: item.id,
                                staticClass: "col",
                                attrs: { span: 12 },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass:
                                      "checkbox-dt-dt send_check_btn",
                                    attrs: {
                                      title: item.packageName,
                                      value: _vm.selectPackageList.includes(
                                        item.id
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changePackage(item.id)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.packageName) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "线索可见性" } },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "label",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [_vm._v("\n            线索可见性\n          ")]
                    ),
                    _vm._v(" "),
                    _c("el-switch", {
                      model: {
                        value: _vm.formData.isViewKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "isViewKey", $$v)
                        },
                        expression: "formData.isViewKey",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "子账号数",
                              prop: "accountLimitBalance",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入账号数" },
                              model: {
                                value: _vm.formData.accountLimitBalance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "accountLimitBalance",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "formData.accountLimitBalance",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "tip" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.tip.accountLimitBalance) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "companyAvailableFreeBalance" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "label",
                                attrs: { slot: "label" },
                                slot: "label",
                              },
                              [
                                _vm._v(" 推荐线索"),
                                _c("br"),
                                _vm._v("领取条数 "),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              attrs: { placeholder: "请输入推荐线索领取条数" },
                              model: {
                                value: _vm.formData.companyAvailableFreeBalance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "companyAvailableFreeBalance",
                                    _vm._n($$v)
                                  )
                                },
                                expression:
                                  "formData.companyAvailableFreeBalance",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "tip" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.tip.companyAvailableFreeBalance) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "zizhuAvailableFreeBalance" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "label",
                                attrs: { slot: "label" },
                                slot: "label",
                              },
                              [
                                _vm._v(" 自主找客"),
                                _c("br"),
                                _vm._v("领取条数 "),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              attrs: { placeholder: "请输入自主找客领取条数" },
                              model: {
                                value: _vm.formData.zizhuAvailableFreeBalance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "zizhuAvailableFreeBalance",
                                    _vm._n($$v)
                                  )
                                },
                                expression:
                                  "formData.zizhuAvailableFreeBalance",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "tip" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.tip.zizhuAvailableFreeBalance) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "exclusiveAvailableFreeBalance" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "label",
                                attrs: { slot: "label" },
                                slot: "label",
                              },
                              [
                                _vm._v(" 独家信息"),
                                _c("br"),
                                _vm._v("领取条数 "),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              attrs: { placeholder: "请输入独家信息领取条数" },
                              model: {
                                value:
                                  _vm.formData.exclusiveAvailableFreeBalance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "exclusiveAvailableFreeBalance",
                                    _vm._n($$v)
                                  )
                                },
                                expression:
                                  "formData.exclusiveAvailableFreeBalance",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "tip" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.tip.exclusiveAvailableFreeBalance) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
              [_vm._v("取消")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.confirmBtn },
              },
              [_vm._v("确认")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }