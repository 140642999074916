var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fapiao-main" }, [
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c("div", { staticClass: "c-name" }, [
              _vm._v(_vm._s(_vm.selectCompanyData.companyName)),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                style: { visibility: _vm.isEdit ? "hidden" : "" },
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.editInvClick },
              },
              [_vm._v("修改发票信息")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.invoiceLoading,
                expression: "invoiceLoading",
              },
            ],
            staticClass: "form-invoice",
            attrs: {
              model: _vm.invoiceFormData,
              size: "small",
              "label-width": "120px",
              "label-position": "left",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "开具类型" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.openType && _vm.invoiceFormData.openType,
                        expression: "openType && invoiceFormData.openType ",
                      },
                    ],
                    staticClass: "tip-text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.openType === "复制成功" ? "复制成功 !" : "点击复制"
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isEdit
                  ? _c(
                      "span",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            return _vm.copyText(
                              _vm.invoiceFormData.openType,
                              "openType"
                            )
                          },
                          mouseenter: function ($event) {
                            return _vm.spanElStatus("openType", true)
                          },
                          mouseleave: function ($event) {
                            return _vm.spanElStatus("openType", false)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.invoiceFormData.openType))]
                    )
                  : _c(
                      "el-select",
                      {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请选择开具类型" },
                        model: {
                          value: _vm.invoiceFormData.openType,
                          callback: function ($$v) {
                            _vm.$set(_vm.invoiceFormData, "openType", $$v)
                          },
                          expression: "invoiceFormData.openType",
                        },
                      },
                      _vm._l(_vm.openTypeList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.name, value: item.value },
                        })
                      }),
                      1
                    ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "发票类型" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.invoiceType && _vm.invoiceFormData.invoiceType,
                        expression:
                          "invoiceType && invoiceFormData.invoiceType",
                      },
                    ],
                    staticClass: "tip-text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.invoiceType === "复制成功"
                          ? "复制成功 !"
                          : "点击复制"
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isEdit
                  ? _c(
                      "span",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            return _vm.copyText(
                              _vm.invoiceFormData.invoiceType,
                              "invoiceType"
                            )
                          },
                          mouseenter: function ($event) {
                            return _vm.spanElStatus("invoiceType", true)
                          },
                          mouseleave: function ($event) {
                            return _vm.spanElStatus("invoiceType", false)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.invoiceFormData.invoiceType))]
                    )
                  : _c(
                      "el-select",
                      {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请选择发票类型" },
                        model: {
                          value: _vm.invoiceFormData.invoiceType,
                          callback: function ($$v) {
                            _vm.$set(_vm.invoiceFormData, "invoiceType", $$v)
                          },
                          expression: "invoiceFormData.invoiceType",
                        },
                      },
                      _vm._l(_vm.invoiceTypeList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.name, value: item.value },
                        })
                      }),
                      1
                    ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "发票抬头" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.invoiceTitle && _vm.invoiceFormData.invoiceTitle,
                        expression:
                          "invoiceTitle && invoiceFormData.invoiceTitle",
                      },
                    ],
                    staticClass: "tip-text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.invoiceTitle === "复制成功"
                          ? "复制成功 !"
                          : "点击复制"
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isEdit
                  ? _c(
                      "span",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            return _vm.copyText(
                              _vm.invoiceFormData.invoiceTitle,
                              "invoiceTitle"
                            )
                          },
                          mouseenter: function ($event) {
                            return _vm.spanElStatus("invoiceTitle", true)
                          },
                          mouseleave: function ($event) {
                            return _vm.spanElStatus("invoiceTitle", false)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.invoiceFormData.invoiceTitle))]
                    )
                  : _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: { disabled: "", placeholder: "请输入发票抬头" },
                      model: {
                        value: _vm.invoiceFormData.invoiceTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.invoiceFormData, "invoiceTitle", $$v)
                        },
                        expression: "invoiceFormData.invoiceTitle",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "纳税人识别号" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.taxpayerIdenifyCode &&
                          _vm.invoiceFormData.taxpayerIdenifyCode,
                        expression:
                          "taxpayerIdenifyCode && invoiceFormData.taxpayerIdenifyCode",
                      },
                    ],
                    staticClass: "tip-text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.taxpayerIdenifyCode === "复制成功"
                          ? "复制成功 !"
                          : "点击复制"
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isEdit
                  ? _c(
                      "span",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            return _vm.copyText(
                              _vm.invoiceFormData.taxpayerIdenifyCode,
                              "taxpayerIdenifyCode"
                            )
                          },
                          mouseenter: function ($event) {
                            return _vm.spanElStatus("taxpayerIdenifyCode", true)
                          },
                          mouseleave: function ($event) {
                            return _vm.spanElStatus(
                              "taxpayerIdenifyCode",
                              false
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.invoiceFormData.taxpayerIdenifyCode))]
                    )
                  : _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请输入纳税人识别号" },
                      model: {
                        value: _vm.invoiceFormData.taxpayerIdenifyCode,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.invoiceFormData,
                            "taxpayerIdenifyCode",
                            $$v
                          )
                        },
                        expression: "invoiceFormData.taxpayerIdenifyCode",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "地址" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.address && _vm.invoiceFormData.address,
                        expression: "address && invoiceFormData.address",
                      },
                    ],
                    staticClass: "tip-text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.address === "复制成功" ? "复制成功 !" : "点击复制"
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isEdit
                  ? _c(
                      "span",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            return _vm.copyText(
                              _vm.invoiceFormData.address,
                              "address"
                            )
                          },
                          mouseenter: function ($event) {
                            return _vm.spanElStatus("address", true)
                          },
                          mouseleave: function ($event) {
                            return _vm.spanElStatus("address", false)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.invoiceFormData.address))]
                    )
                  : _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请输入地址" },
                      model: {
                        value: _vm.invoiceFormData.address,
                        callback: function ($$v) {
                          _vm.$set(_vm.invoiceFormData, "address", $$v)
                        },
                        expression: "invoiceFormData.address",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "电话" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.phone && _vm.invoiceFormData.phone,
                        expression: "phone && invoiceFormData.phone",
                      },
                    ],
                    staticClass: "tip-text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.phone === "复制成功" ? "复制成功 !" : "点击复制"
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isEdit
                  ? _c(
                      "span",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            return _vm.copyText(
                              _vm.invoiceFormData.phone,
                              "phone"
                            )
                          },
                          mouseenter: function ($event) {
                            return _vm.spanElStatus("phone", true)
                          },
                          mouseleave: function ($event) {
                            return _vm.spanElStatus("phone", false)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.invoiceFormData.phone))]
                    )
                  : _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请输入电话" },
                      model: {
                        value: _vm.invoiceFormData.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.invoiceFormData, "phone", $$v)
                        },
                        expression: "invoiceFormData.phone",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "开户行" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.openBank && _vm.invoiceFormData.openBank,
                        expression: "openBank && invoiceFormData.openBank",
                      },
                    ],
                    staticClass: "tip-text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.openBank === "复制成功" ? "复制成功 !" : "点击复制"
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isEdit
                  ? _c(
                      "span",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            return _vm.copyText(
                              _vm.invoiceFormData.openBank,
                              "openBank"
                            )
                          },
                          mouseenter: function ($event) {
                            return _vm.spanElStatus("openBank", true)
                          },
                          mouseleave: function ($event) {
                            return _vm.spanElStatus("openBank", false)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.invoiceFormData.openBank))]
                    )
                  : _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请输入开户行" },
                      model: {
                        value: _vm.invoiceFormData.openBank,
                        callback: function ($$v) {
                          _vm.$set(_vm.invoiceFormData, "openBank", $$v)
                        },
                        expression: "invoiceFormData.openBank",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "银行账号" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.bankAccount && _vm.invoiceFormData.bankAccount,
                        expression:
                          "bankAccount && invoiceFormData.bankAccount",
                      },
                    ],
                    staticClass: "tip-text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.bankAccount === "复制成功"
                          ? "复制成功 !"
                          : "点击复制"
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isEdit
                  ? _c(
                      "span",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            return _vm.copyText(
                              _vm.invoiceFormData.bankAccount,
                              "bankAccount"
                            )
                          },
                          mouseenter: function ($event) {
                            return _vm.spanElStatus("bankAccount", true)
                          },
                          mouseleave: function ($event) {
                            return _vm.spanElStatus("bankAccount", false)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.invoiceFormData.bankAccount))]
                    )
                  : _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请输入银行账号" },
                      model: {
                        value: _vm.invoiceFormData.bankAccount,
                        callback: function ($$v) {
                          _vm.$set(_vm.invoiceFormData, "bankAccount", $$v)
                        },
                        expression: "invoiceFormData.bankAccount",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "邮寄地址" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.mailAddress && _vm.invoiceFormData.mailAddress,
                        expression:
                          "mailAddress && invoiceFormData.mailAddress",
                      },
                    ],
                    staticClass: "tip-text",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.mailAddress === "复制成功"
                          ? "复制成功 !"
                          : "点击复制"
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isEdit
                  ? _c(
                      "span",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            return _vm.copyText(
                              _vm.invoiceFormData.mailAddress,
                              "mailAddress"
                            )
                          },
                          mouseenter: function ($event) {
                            return _vm.spanElStatus("mailAddress", true)
                          },
                          mouseleave: function ($event) {
                            return _vm.spanElStatus("mailAddress", false)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.invoiceFormData.mailAddress))]
                    )
                  : _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请输入邮寄地址" },
                      model: {
                        value: _vm.invoiceFormData.mailAddress,
                        callback: function ($$v) {
                          _vm.$set(_vm.invoiceFormData, "mailAddress", $$v)
                        },
                        expression: "invoiceFormData.mailAddress",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isEdit
              ? _c("el-form-item", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                        "padding-right": "140px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.isEdit = false
                            },
                          },
                        },
                        [_vm._v("\n              取消\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.editInvoice },
                        },
                        [_vm._v("\n              保存\n            ")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }