var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pro-main" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "dt-permission",
            rawName: "v-dt-permission",
            value: "销售排名-收款排名-查看",
            expression: "'销售排名-收款排名-查看'",
          },
        ],
        staticClass: "btn-list",
      },
      [
        _c(
          "div",
          [
            _c("el-date-picker", {
              attrs: {
                size: "small",
                type: "year",
                "value-format": "yyyy",
                placeholder: "选择年",
              },
              on: { change: _vm.yearChange },
              model: {
                value: _vm.year,
                callback: function ($$v) {
                  _vm.year = $$v
                },
                expression: "year",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "margin-left": "10px" } },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                on: { change: _vm.changeSelectRadio },
                model: {
                  value: _vm.selectDataType,
                  callback: function ($$v) {
                    _vm.selectDataType = $$v
                  },
                  expression: "selectDataType",
                },
              },
              _vm._l(_vm.selectDataTypeList, function (item) {
                return _c("el-radio-button", { attrs: { label: item.value } }, [
                  _vm._v(_vm._s(item.name)),
                ])
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.selectDataType === "m"
          ? _c(
              "div",
              { staticStyle: { "margin-left": "20px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      type: _vm.selectData === "m" ? "primary" : "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeType("m")
                      },
                    },
                  },
                  [_vm._v("本月")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      type: _vm.selectData === "nm" ? "primary" : "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeType("nm")
                      },
                    },
                  },
                  [_vm._v("上月")]
                ),
                _vm._v(" "),
                _c("el-date-picker", {
                  attrs: {
                    size: "mini",
                    type: "monthrange",
                    "range-separator": "至",
                    "value-format": "yyyy-MM-dd",
                    "start-placeholder": "开始月份",
                    "end-placeholder": "结束月份",
                  },
                  on: { change: _vm.getData },
                  model: {
                    value: _vm.selectTime,
                    callback: function ($$v) {
                      _vm.selectTime = $$v
                    },
                    expression: "selectTime",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.selectDataType === "q"
          ? _c(
              "div",
              { staticStyle: { "margin-left": "20px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      type: _vm.selectData === "q" ? "primary" : "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeType("q")
                      },
                    },
                  },
                  [_vm._v("本季度")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      type: _vm.selectData === "nq" ? "primary" : "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeType("nq")
                      },
                    },
                  },
                  [_vm._v("上季度")]
                ),
                _vm._v(" "),
                _c("quarter", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  attrs: {
                    size: "mini",
                    "value-format": "yyyy-q",
                    placeholder: "请选择季度",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeType("mq")
                    },
                  },
                  model: {
                    value: _vm.quarterDate,
                    callback: function ($$v) {
                      _vm.quarterDate = $$v
                    },
                    expression: "quarterDate",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "margin-left": "10px" } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: "请选择员工",
                  multiple: "",
                  filterable: "",
                  size: "small",
                },
                on: { change: _vm.getData },
                model: {
                  value: _vm.selectUser,
                  callback: function ($$v) {
                    _vm.selectUser = $$v
                  },
                  expression: "selectUser",
                },
              },
              _vm._l(_vm.userList, function (group) {
                return _c(
                  "el-option-group",
                  { key: group.label, attrs: { label: group.label } },
                  _vm._l(group.options, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "dt-permission",
            rawName: "v-dt-permission",
            value: "销售排名-收款排名-查看",
            expression: "'销售排名-收款排名-查看'",
          },
        ],
        staticClass: "main-row",
      },
      [
        _c("div", { staticClass: "main-data" }, [
          _c("div", { staticClass: "chart" }, [
            _c(
              "div",
              { staticClass: "chart-btn" },
              [
                _c("div", [
                  _c("i", {
                    class:
                      _vm.chartType === "bar"
                        ? "active-histogram"
                        : "default-histogram",
                    on: {
                      click: function ($event) {
                        return _vm.changeChartType("bar")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    class:
                      _vm.chartType === "circle" ? "active-pie" : "default-pie",
                    on: {
                      click: function ($event) {
                        return _vm.changeChartType("circle")
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: { size: "small" },
                    on: { change: _vm.getData },
                    model: {
                      value: _vm.selectType,
                      callback: function ($$v) {
                        _vm.selectType = $$v
                      },
                      expression: "selectType",
                    },
                  },
                  _vm._l(_vm.selectTypeList, function (item) {
                    return _c("el-option", {
                      key: item.name,
                      attrs: { label: item.name, value: item.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "chart-row", attrs: { id: "chart-row" } }),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "table",
              staticStyle: { padding: "0 20px", "box-sizing": "border-box" },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    "show-summary": "",
                    "summary-method": _vm.getSummaries,
                    data: _vm.tableData,
                    size: "small",
                    border: "",
                  },
                  on: { "sort-change": _vm.sortChange },
                },
                _vm._l(_vm.columns, function (item) {
                  return _c("el-table-column", {
                    attrs: {
                      sortable: item.type === "sort" ? "custom" : false,
                      label: item.name,
                      prop: item.field,
                      "header-align": "left",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function (scope) {
                            return [
                              item.name === "有效收款金额"
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.name) +
                                          "\n                  "
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: `有效收款金额=收款金额-退款金额`,
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-question",
                                            staticStyle: {
                                              "font-size": "15px",
                                              color: "#8A8A8A",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : item.name === "目标收款金额"
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.name) +
                                          "\n                  "
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: `收款目标设置的金额`,
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-question",
                                            staticStyle: {
                                              "font-size": "15px",
                                              color: "#8A8A8A",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : item.name === "外采金额"
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.name) +
                                          "\n                  "
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: `为客户采购物品的花费`,
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-question",
                                            staticStyle: {
                                              "font-size": "15px",
                                              color: "#8A8A8A",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : item.name === "收款完成率"
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.name) +
                                          "\n                  "
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: `收款完成率=有效收款金额/目标收款金额*100%`,
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-question",
                                            staticStyle: {
                                              "font-size": "15px",
                                              color: "#8A8A8A",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : item.name === "利润率"
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.name) +
                                          "\n                  "
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: `利润率=1-外采金额/有效收款金额*100%`,
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-question",
                                            staticStyle: {
                                              "font-size": "15px",
                                              color: "#8A8A8A",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.name) +
                                        "\n                "
                                    ),
                                  ]),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              item.field === "ratio"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          scope.row[item.field]
                                            ? `${scope.row[item.field]}%`
                                            : "0.00%"
                                        ) +
                                        "\n                "
                                    ),
                                  ])
                                : item.format === "RMB"
                                ? _c("div", { staticClass: "input-row" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatValue")(
                                            scope.row[item.field]
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(scope.row[item.field]) +
                                        "\n                "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "z-center" }, [
      _c("div", { staticStyle: { "margin-left": "10px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }