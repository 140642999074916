var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "JcL-main" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10, align: "middle", type: "flex" } },
        [
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _vm.status === "edit"
                ? _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择kp", size: "small" },
                      model: {
                        value: _vm.formData.kpId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "kpId", $$v)
                        },
                        expression: "formData.kpId",
                      },
                    },
                    _vm._l(_vm.userList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.contact, value: item.id },
                        },
                        [
                          _c("div", { staticClass: "se-option" }, [
                            _c("div", [_vm._v(_vm._s(item.contact))]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                item.wechatType === 1
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "30px",
                                        height: "20px",
                                      },
                                      attrs: {
                                        src: require("../../../assets/weixin3.png"),
                                      },
                                    })
                                  : item.wechatType === 2
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "30px",
                                        height: "20px",
                                      },
                                      attrs: {
                                        src: require("../../../assets/qywx2.png"),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  )
                : _c("span", [
                    _vm._v(" " + _vm._s(_vm.kpId || "-") + "\n      "),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _vm.status === "edit"
                ? _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择汇报人", size: "small" },
                      model: {
                        value: _vm.formData.reporterId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "reporterId", $$v)
                        },
                        expression: "formData.reporterId",
                      },
                    },
                    _vm._l(_vm.userList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.contact, value: item.id },
                        },
                        [
                          _c("div", { staticClass: "se-option" }, [
                            _c("div", [_vm._v(_vm._s(item.contact))]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                item.wechatType === 1
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "30px",
                                        height: "20px",
                                      },
                                      attrs: {
                                        src: require("../../../assets/weixin3.png"),
                                      },
                                    })
                                  : _c("el-image", {
                                      staticStyle: {
                                        width: "30px",
                                        height: "20px",
                                      },
                                      attrs: {
                                        src: require("../../../assets/qywx2.png"),
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  )
                : _c("span", [_vm._v(" " + _vm._s(_vm.reporterId || "-"))]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _vm.status === "edit"
                ? _c("el-input", {
                    attrs: { placeholder: "请输入", size: "small" },
                    model: {
                      value: _vm.formData.obstacle,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "obstacle", $$v)
                      },
                      expression: "formData.obstacle",
                    },
                  })
                : _c("span", [
                    _vm._v(" " + _vm._s(_vm.formData.obstacle || "-") + " "),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _vm.status === "edit"
                ? _c("el-input", {
                    attrs: { placeholder: "请输入", size: "small" },
                    model: {
                      value: _vm.formData.measure,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "measure", $$v)
                      },
                      expression: "formData.measure",
                    },
                  })
                : _c("span", [
                    _vm._v(" " + _vm._s(_vm.formData.measure || "-") + " "),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _vm.status === "edit"
                ? [
                    _c("i", {
                      staticClass: "el-icon-check",
                      on: { click: _vm.confirmClick },
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-close",
                      on: { click: _vm.delClick },
                    }),
                  ]
                : [
                    _c("i", {
                      staticClass: "el-icon-edit",
                      on: { click: _vm.editClick },
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-close",
                      on: { click: _vm.delClick },
                    }),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }