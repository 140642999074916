<template>
  <div class="app-container land-page-app">
    <div
      class="land-page-header detail-title"
      style="font-size:14px;line-height:50px;"
    >
      <span class="color-41CABF click" @click="goBackPage()">营销表单管理</span>
      <span>></span>
      <span>{{ navName }}</span>
      <!-- <div class="detail-title-land">
                <span class="color-41CABF" style="color:#0077CC;padding-right: 0;" @click="goBackPage()">{{back}}</span>
                <span class="color-41CABF click" style="color:#0077CC;" @click="goBackPage()">返回</span>
            </div> -->
    </div>
    <div class="line-body m-t-4" style="margin-top:4px;padding: 0px 0;">
      <el-table
        :data="listinfo"
        v-loading="getListLoading"
        ref="myCollectSelect"
        @selection-change="selectionChange"
        empty-text="暂无数据"
        style="width: 100%; font-size: 12px;padding: 0 20px;"
      >
        <el-table-column prop="formName" show-overflow-tooltip label="公司">
          <template slot-scope="scope">
            {{ scope.row.info.company }}
          </template>
        </el-table-column>
        <el-table-column prop="clickNum" show-overflow-tooltip label="姓名">
          <template slot-scope="scope">
            {{ scope.row.info.contact }}
          </template>
        </el-table-column>
        <el-table-column prop="leaveNum" show-overflow-tooltip label="手机号">
          <template slot-scope="scope">
            {{ scope.row.info.phone }}
          </template>
        </el-table-column>
      </el-table>
      <div class="page-div" v-if="pageStatus.total">
        <div style="float:right;">
          <footer-page
            @handleCurrentChange="handleCurrentChange"
            @goNumPage="goNumPage"
            @changePageSize="changePageSize"
            :pageStatus="pageStatus"
          >
          </footer-page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLeaveInfoList } from '@/api/table'
import footerPage from '@/components/Footerpage/index'
export default {
  data() {
    return {
      back: '<',
      currentLandPageParams: '',
      dialogStatus: {},
      currentId: Number(localStorage.getItem('PageId')) || 0,
      formName: '',
      navName: sessionStorage.getItem('pageName'),
      acCodeUrl: '',
      acType: '1',
      landDetail: {},
      haveSendPub: false,
      getListLoading: false,
      currentTitle: '',
      listinfo: [],
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
    }
  },
  created() {
    this.getLeaveInfoList()
  },
  mounted() {},
  components: {
    footerPage
  },
  methods: {
    selectionChange() {},
    goBackPage() {
      this.$router.push('/landpage/landpage')
    },
    getLeaveInfoList() {
      if (!this.currentId) {
        return
      }
      const params = {
        pageId: this.currentId,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize
      }
      getLeaveInfoList(params).then(res => {
        if (res.code === 60000) {
          this.listinfo = res.data.list
          this.pageStatus.total = res.data.page.total
        }
      })
    },
    // 跳转页数
    goNumPage(val) {
      this.pageStatus.pageNo = Number(val)
      this.getLeaveInfoList()
    },
    // 改变页数大小
    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.getLeaveInfoList()
    },
    changePageSize(val) {
      this.pageStatus.pageSize = val
      this.getLeaveInfoList()
    }
  }
}
</script>

<style scoped>
.land-page-right {
  width: 300px;
  float: right;
  height: 100%;
  padding: 30px 0 0 0;
}
.land-page-left {
  width: calc(100% - 300px);
  float: left;
  height: 100%;
  padding: 76px 36px 0;
}
.land-page-left2 {
  width: 100%;
  float: left;
  height: 100%;
  padding: 76px 36px 0;
}
.land-page-cont {
  background: white;
  width: 100%;
  margin-top: 4px;
  overflow: hidden;
}
.land-page-app {
  width: 100%;
  /*min-width: 1200px;*/
}
.land-page-header {
  height: 50px;
  width: 100%;
  background: white;
  padding: 0 20px;
}
.search-botton {
  width: 70px;
  height: 30px;
  float: left;
  position: relative;
  top: 10px;
  margin-left: 20px;
  background-color: #78d9d1;
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
.detail-title-land {
  height: 50px;
  background-color: #ffffff;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  display: inline-block;
  line-height: 50px;
  padding-left: 21px;
  padding-right: 20px;
  color: #8a8a8a;
  margin-bottom: 4px;
  cursor: pointer;
}
.line-body {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.detail-title {
  width: 100%;
  min-width: 1200px;
  height: 50px;
  background-color: #ffffff;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  line-height: 50px;
  padding-left: 21px;
  padding-right: 20px;
  color: #8a8a8a;
  margin-bottom: 4px;
}
.btn_title_upload span {
  display: inline-block;
  margin-left: 1px;
}
.down_load_demo {
  position: absolute;
  width: 150px;
  top: 0;
  right: 0;
}
.down_load_title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.down_load_xlsx {
  margin: 20px 0;
  text-align: center;
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.down_load_txt {
  margin: 20px 0;
  text-align: center;
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.upload_cont_out {
  position: relative;
}
.upload_cont_out ::v-deep .el-upload-dragger {
  border: 1px dashed rgba(65, 202, 191, 1) !important;
}
.show_call_time {
  font-size: 20px;
  color: black;
  margin-top: 20px;
}
.show_call_tip {
  font-size: 16px;
  color: red;
}
.el-table ::v-deep .el-table__empty-block {
  height: 410px !important;
  position: relative;
}
.el-table ::v-deep .el-table__body-wrapper {
  min-height: 440px !important;
  padding-left: 4px;
  padding-right: 4px;
}
.el-table ::v-deep .el-table__body td {
  padding: 0 !important;
  height: 41px;
}
.mark-show-elp {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.now-all-type ::v-deep .el-checkbox__label {
  font-size: 12px !important;
  color: #78d9d1 !important;
}
.call-change-status {
  position: absolute;
  padding: 10px 14px 15px 12px;
  border: 1px solid #41cabf;
  height: 80px;
  background: white;
  width: 180px;
  top: 50px;
  z-index: 2000;
}
.check-change-mark {
  position: absolute;
  padding: 10px 14px 15px 12px;
  border: 1px solid #41cabf;
  height: 120px;
  background: white;
  width: 200px;
  top: 50px;
  z-index: 2000;
}
.check-change-mark p {
  font-size: 12px;
  color: #8a8a8a;
  height: 18px;
  line-height: 18px;
}
.call-change-status p {
  font-size: 12px;
  color: #8a8a8a;
  height: 18px;
  line-height: 18px;
}
.line-body-nodata {
  display: inline-block;
  width: 50px;
  height: 58px;
  background: url('../../assets/no-data.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 170px;
  left: 48%;
  z-index: 1;
}
.line-body {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.hover-go-detail {
  cursor: pointer;
  display: inline-block;
  height: 100%;
}
.grasp_phone {
  display: inline-block;
  font-size: 10px;
  color: white;
  background: #41cabf;
  height: 20px;
  width: 40px;
  line-height: 20px;
  border-radius: 4px;
  text-align: center;
  margin-left: 4px;
  cursor: pointer;
}
.show_grasp_text {
  font-size: 14px;
  color: #8f8f8f;
  height: 60px;
  padding-top: 10px;
}
.grasp_go_pay {
  height: 68px;
  border-top: 1px solid #d8d8d8;
  line-height: 68px;
}
.detail-title span {
  padding-right: 8px;
}
.grasp_go_pay span {
  display: inline-block;
  width: 70px;
  height: 34px;
  background: rgba(120, 217, 209, 1);
  border-radius: 4px;
  line-height: 34px;
  margin-left: 27px;
  text-align: center;
  color: white;
  cursor: pointer;
}
</style>
