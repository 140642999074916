var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qy-tags" },
    [
      _vm._l(_vm.groupClassifyList, function (ele) {
        return [
          _c("div", { staticClass: "company-qy-group" }, [
            _c("span", { staticClass: "qy-group" }, [_vm._v(_vm._s(ele.name))]),
            _vm._v(" "),
            _c(
              "ul",
              [
                _vm._l(ele.data, function (txt) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "qyName",
                        on: {
                          click: function ($event) {
                            return _vm.handleQy(txt.groupUuid)
                          },
                        },
                      },
                      [_vm._v(_vm._s(txt.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "li-list" },
                      [
                        _vm._l(_vm.groupList(txt), function (item, ele) {
                          return [
                            item.show
                              ? _c(
                                  "li",
                                  { key: ele },
                                  [
                                    _c("p", [_vm._v(_vm._s(item.name) + ":")]),
                                    _vm._v(" "),
                                    _c("PeUnit", {
                                      attrs: {
                                        companyInfo: txt,
                                        unit: item.unit,
                                        getKey: item.getKey,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }