var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "band-detail" },
    [
      _c("div", { staticClass: "title" }, [
        _c("span", { on: { click: _vm.handleBandList } }, [
          _vm._v(_vm._s(_vm.routerBandName) + " "),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(" > 榜单详情")]),
        _vm._v(" "),
        _vm.IsAccess == 0 || _vm.IsAccess == 1
          ? _c(
              "span",
              {
                staticClass: "normal-data",
                on: { click: _vm.handleNormalData },
              },
              [_vm._v(_vm._s(_vm.$updateText))]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.receiveData
        ? [
            _c("div", { staticClass: "qy" }, [
              _vm._v(_vm._s(_vm.receiveData.bdName)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "list" }, [
              _c("div", { staticClass: "item" }, [
                _vm._v(
                  "发布机构:" + _vm._s(_vm.receiveData.publishInstitution)
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _vm._v("发布日期:" + _vm._s(_vm.receiveData.publishDate)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item desc" }, [
                _vm._v("榜单简介:" + _vm._s(_vm.receiveData.introduction)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _vm._v("榜单类型:" + _vm._s(_vm.receiveData.type)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _vm._v("企业数量:" + _vm._s(this.peParams.total)),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "PeTable",
        {
          attrs: {
            peColumnList: _vm.peColumnList,
            peTableData: _vm.peTableData,
            peLoading: _vm.peLoading,
            pePage: _vm.peParams,
            IsAccess: _vm.IsAccess,
            "current-column-width": { companyName: 300, bdRank: 80 },
          },
          on: {
            sortPeChange: _vm.handleSortChange,
            handleCurrentPePage: _vm.handleCurrentChange,
            handleSizePeChange: _vm.handleSizeChange,
            handleSelectCommand: _vm.handleSelectCommand,
          },
          scopedSlots: _vm._u([
            {
              key: "companyName",
              fn: function ({ scope }) {
                return [
                  _c(
                    "div",
                    [
                      scope.data.jumpFlag == 1
                        ? _c(
                            "span",
                            {
                              staticClass: "c-name",
                              on: {
                                click: function ($event) {
                                  return _vm.handleQy(scope.data, scope.index)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.data.companyName) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _c("span", [_vm._v(_vm._s(scope.data.companyName))]),
                      _vm._v(" "),
                      _c("Tags", { attrs: { tagList: scope.data.tags } }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "abnormal" },
                        [
                          _c("PeRisk", { attrs: { riskTag: scope.data.risk } }),
                          _vm._v(" "),
                          scope.data.company_status == "注销"
                            ? _c("span", { staticClass: "qy-cancel" }, [
                                _vm._v("注销"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v(" "), void 0],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }