var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "charts-preview-page" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.companyInfo.workName)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container" },
      [
        _vm.isPc
          ? [
              _c(
                "grid-layout",
                {
                  attrs: {
                    layout: _vm.layoutList,
                    "col-num": 60,
                    "row-height": 30,
                  },
                  on: {
                    "update:layout": function ($event) {
                      _vm.layoutList = $event
                    },
                  },
                },
                _vm._l(_vm.layoutList, function (item) {
                  return _c(
                    "grid-item",
                    {
                      key: item.i,
                      attrs: {
                        static: true,
                        x: item.x,
                        y: item.y,
                        w: item.w,
                        h: item.h,
                        i: item.i,
                      },
                    },
                    [
                      item.data.isTitleShow
                        ? _c("div", { staticClass: "chart-title" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.data.title) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("pe-chart", { attrs: { item: item.data } }),
                    ],
                    1
                  )
                }),
                1
              ),
            ]
          : _c(
              "div",
              { staticClass: "is-mobile" },
              _vm._l(_vm.layoutList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "grid-item",
                    style: `width:100%;height:${item.data.height}px`,
                  },
                  [
                    item.data.isTitleShow
                      ? _c("div", { staticClass: "chart-title" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.data.title) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("pe-chart", {
                      attrs: { item: item.data, isPc: _vm.isPc },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }