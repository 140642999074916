import store from '../store'
import { getMenuList, getSyMenus } from '@/api/permission'
import { getCusTagsDdic } from '@/api/customerpond'
import { getCloudList, getChainRadarMenu, getModuleConfig } from '@/api/module'
import { getMode } from '@/api/user'

let DcPages = []
let FcPages = []
let VcPages = []
let EcPages = []
let TpPages = []
let KaPages = []
let LsPages = []
let SrPages = []
let menuList = []
let moduleList = []

export const setPermission = () => {
  return new Promise(resolve => {
    getMenuList().then(async result => {
      if (result.code === 60000) {
        const panelList = result.data.menus.data

        store.commit('savePanelList', panelList)
        handelOverView(panelList)

        const menuType = sessionStorage.menuType || 'main-system'

        if (menuType == 'touRz') {
          FcPages = await configMenu(13, '/insight/finance')
          VcPages = await configMenu(14, '/insight/vc')
          EcPages = await configMenu(15, '/insight/vc/event')
        }

        if (menuType == 'techKa') {
          KaPages = await configMenu(16, '/insight/techka')
        }

        if (menuType === 'schema') {
          const schemaData = await getMode({ type: 'TM_02' })
          let list = schemaData.data['TM_02']
          list.forEach(item => {
            item.uri = `/insight/schema/${item.value}`
            item.id = item.value
          })
          TpPages = list
          DcPages = await DcMenus(12)
        }

        if (menuType === 'chainRadar') {
          const { data } = await getChainRadarMenu({
            tagMenu: 'Chain_Radar'
          })
          LsPages = data.map(item => ({
            name: item.name,
            uri: `/insight/chain-radar/${item.value}`,
            id: item.value
          }))
        }

        if (menuType == 'addrRecommend') {
          const { data } = await getChainRadarMenu({
            tagMenu: 'Site_Recommendation'
          })
          SrPages = data.map(item => ({
            name: item.name,
            uri: `/insight/site-rec/${item.value}`,
            id: item.id
          }))
        }

        saveMenu(menuType, panelList)
        const _res = handlePermission(panelList)
        resolve(_res)
      }
    })
  })
}

/**
 * 处理并保存权限数据到store 保存格式为 ['xxx-xxx-xxx','xxx-xxx']
 * @param res 原始权限数据
 */
function handlePermission(res) {
  let result = []
  res.forEach(item => {
    // 获取pages权限s
    let pages = item.pages
    pages?.forEach(_item => {
      let func = _item.functions
      if (func?.length) {
        result.push(`${item.name}-${_item.name}`)
      }
      func?.forEach(_func => {
        result.push(`${item.name}-${_item.name}-${_func.name}`)
      })
    })
  })
  // 更新到store
  store.commit('updatePermission', result)
  return result
}

//处理左边menu
async function handleMenuList(list, type) {
  const { data } = await getModuleConfig()
  moduleList = data.modules
  store.commit('saveModuleList', data.modules)
  menuList = []
  const currentModule = moduleList.find(item => item.record == type)
  currentModule?.menu_id.forEach(moduleId => {
    list.forEach(item => {
      if (moduleId == item.id) {
        menuList.push(item)
      }
    })
  })
  return menuList
}

async function handleCloud() {
  const { data } = await getCloudList()
  data.forEach(item => {
    if (item?.page?.total > 10) {
      item.pages.push({
        name: '查看更多',
        type: 'more'
      })
    }
  })
  return data
}

/**
 * 错误提示
 * 清空权限信息
 * 清空菜单列表
 */
async function saveMenu(type, data) {
  menuList = await handleMenuList(data, type)
  store.commit('saveAllMenuList', data)

  if (type == 'main-system') {
    let list = menuList.map(item => {
      if (item.id == 60) {
        item.pages = item.pages.slice(0, 1)
      }
      return item
    })
    store.commit('saveMenuList', list)
  }

  if (type == 'robotWatch') {
    menuList = await handleCloud()
    store.commit('saveMenuList', menuList)
  }

  if (type == 'touRz') {
    menuList.forEach(item => {
      if (item.id === 170) {
        item.pages = FcPages
      }
      if (item.id === 280) {
        item.pages = VcPages
      }
      if (item.id === 290) {
        item.pages = EcPages
      }
    })
    store.commit('saveMenuList', menuList)
  }

  if (type == 'techKa') {
    menuList.forEach(item => {
      if (item.id === 310) {
        item.pages = KaPages
      }
    })
    store.commit('saveMenuList', menuList)
  }

  if (type == 'hkfa') {
    menuList.forEach(i => {
      i.pages = i.pages.filter(ii => ii.name === '行业获客方案')
    })
    store.commit('saveMenuList', menuList)
  }

  if (type == 'schema') {
    menuList.forEach(item => {
      if (item.id == 270) {
        item.pages = TpPages
      }
      if (item.id == 165) {
        item.pages = DcPages
      }
    })
    store.commit('saveMenuList', menuList)
  }

  if (type == 'chainRadar') {
    menuList.forEach(item => {
      item.pages = LsPages
    })
    store.commit('saveMenuList', menuList)
  }

  if (type == 'addrRecommend') {
    menuList.forEach(item => {
      item.pages = SrPages
    })
    store.commit('saveMenuList', menuList)
  } else {
    store.commit('saveMenuList', menuList)
  }

  store.commit('update', {
    target: store.state.permission,
    data: {
      menuType: type
    }
  })
}

function handelOverView(data) {
  let res = {}
  data.forEach(item => {
    let pages = item.pages
    pages?.forEach(_item => {
      res[_item.uri] = _item.id
    })
  })
  store.commit('saveOverViewList', res)
}

function DcMenus(type) {
  return new Promise(resolve => {
    getCusTagsDdic({ type }).then(res => {
      if (res.code === 60000) {
        let list = res?.data || []
        list = [...list].map(item => {
          return {
            name: item.name,
            uri: `/dong-cha/list/${item.id}`
          }
        })
        resolve(list)
      }
    })
  })
}

function configMenu(type, url) {
  return new Promise((resolve, reject) => {
    getCusTagsDdic({ type }).then(res => {
      const list = res.data
      const result = list.map(item => ({
        name: item.name,
        uri: `${url}/${item.value}`
      }))
      resolve(result)
    })
  })
}
