var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/miniMarket/landing-page" } } },
                [_vm._v("落地页管理")]
              ),
              _vm._v(" "),
              _c("el-breadcrumb-item", [_vm._v("新建落地页")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c("el-button", { attrs: { size: "small", type: "primary" } }, [
            _vm._v("预览"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.saveLandPage },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "create-row" }, [
      _c("div", { staticClass: "page-title" }, [
        false
          ? _c("div", { staticClass: "title-text" }, [
              _vm._v("\n            分享标题、简介与头图\n          "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "share-show" }, [
          _c("div", { staticClass: "land-page-title" }, [
            _vm._v(
              "\n              " +
                _vm._s(_vm.fontStyle.share.title || "请输入标题") +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "land-page-desc" }, [
            _vm._v(
              "\n              " +
                _vm._s(_vm.fontStyle.share.desc || "请输入简介") +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-title-link" },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.fontStyle.share.img,
                    expression: "!fontStyle.share.img",
                  },
                ],
                staticClass: "el-icon-link",
              }),
              _vm._v(" "),
              _c("el-image", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.fontStyle.share.img,
                    expression: "fontStyle.share.img",
                  },
                ],
                attrs: { src: _vm.fontStyle.share.img, fit: "fit" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page-show" }, [
        _c(
          "div",
          { staticClass: "iphone" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "top-bar" }),
            _vm._v(" "),
            _c("el-scrollbar", { staticClass: "scroll" }, [
              _c("div", { staticClass: "iphone-screen" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.fontStyle.show.video,
                        expression: "fontStyle.show.video",
                      },
                    ],
                    staticClass: "video-row",
                  },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.fontStyle.videoUrl,
                          expression: "!fontStyle.videoUrl",
                        },
                      ],
                      staticClass: "el-icon-video-play",
                    }),
                    _vm._v(" "),
                    _c("video", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fontStyle.videoUrl,
                          expression: "fontStyle.videoUrl",
                        },
                      ],
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src: _vm.fontStyle.videoUrl,
                        controls: "controls",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.fontStyle.show.photo,
                        expression: "fontStyle.show.photo",
                      },
                    ],
                    staticClass: "image-row",
                  },
                  [
                    _c(
                      "el-image",
                      { attrs: { src: _vm.fontStyle.uploadUrl } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [_c("i", { staticClass: "el-icon-picture-outline" })]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.fontStyle.show.content,
                        expression: "fontStyle.show.content",
                      },
                    ],
                    staticClass: "content-row",
                    style: {
                      fontFamily: _vm.fontStyle.fontFamily,
                      backgroundColor: _vm.fontStyle.textBg,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-content",
                        style: {
                          fontFamily: _vm.fontStyle.fontFamily,
                          fontSize: _vm.fontStyle.fontSize + "px",
                          color: _vm.fontStyle.fontColor,
                          fontWeight: _vm.fontStyle.fontWeight,
                        },
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.fontStyle.textareaCont) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.fontStyle.show.input &&
                          _vm.fontStyle.checkedArr.length,
                        expression:
                          "fontStyle.show.input && fontStyle.checkedArr.length ",
                      },
                    ],
                    staticClass: "input-row",
                    style: { backgroundColor: _vm.fontStyle.pagraBg },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: { width: "90%" },
                        attrs: { "label-width": "80px", size: "small" },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.fontStyle.checkedArr.includes("name"),
                                expression:
                                  "fontStyle.checkedArr.includes('name')",
                              },
                            ],
                            attrs: { label: "姓名" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入姓名" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.fontStyle.checkedArr.includes("phone"),
                                expression:
                                  "fontStyle.checkedArr.includes('phone')",
                              },
                            ],
                            attrs: { label: "联系方式" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入联系方式" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.fontStyle.checkedArr.includes("company"),
                                expression:
                                  "fontStyle.checkedArr.includes('company')",
                              },
                            ],
                            attrs: { label: "公司名称" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入公司名称" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.fontStyle.show.button,
                            expression: "fontStyle.show.button",
                          },
                        ],
                        staticClass: "input-row-btn",
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.fontStyle.buttonText) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "bottom-bar" }),
            _vm._v(" "),
            _vm._m(2),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-edit" },
        [
          _c("edit-land", {
            on: {
              getUploadUrl: _vm.getUploadUrl,
              changeFormName: _vm.changeFormName,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "iphone-top" }, [
      _c("span", { staticClass: "camera" }),
      _vm._v(" "),
      _c("span", { staticClass: "sensor" }),
      _vm._v(" "),
      _c("span", { staticClass: "speaker" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "buttons" }, [
      _c("span", { staticClass: "on-off" }),
      _vm._v(" "),
      _c("span", { staticClass: "sleep" }),
      _vm._v(" "),
      _c("span", { staticClass: "up" }),
      _vm._v(" "),
      _c("span", { staticClass: "down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "iphone-bottom" }, [_c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }