<template>
  <div class="oace-key-table">
    <el-table
      :data="companyList"
      border
      stripe
      resizable
      ref="multipleTable"
      size="small"
      @sort-change="handleSortChange"
      @cell-click="cellClick"
      v-loading="showTableLoading"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
      :key="keyNum"
    >
      <template slot="empty">
        <div style="min-height:400px;padding-top:100px;">
          <div class="no-data-icon-body">
            <svg-icon icon-class="noData" class="no-data-icon" />
            <div class="no-data-text">暂无数据</div>
          </div>
        </div>
      </template>

      <el-table-column
        v-if="companyList && companyList.length"
        type="selection"
        prop="collet"
        width="45"
      ></el-table-column>

      <el-table-column
        prop="edit"
        min-width="150"
        align="center"
        label="操作"
        fixed="right"
      >
        <template slot-scope="scope">
          <span class="remove-i">
            <span
              style="height: 50px;display: inline-block;line-height: 50px"
              v-for="item in btnList.slice(0, 2)"
              :key="item.name"
              class="com-list-detail opea"
              @click="item.click(scope.row, scope.$index)"
            >
              <span v-if="item.name === '联系人'">
                <el-badge
                  :value="scope.row.isKp == '1' ? 'KP' : scope.row.contactCount"
                  class="badge-item"
                  type="primary"
                  v-if="scope.row.contactCount"
                >
                  联系人
                </el-badge>
                <span v-else> 联系人</span>
              </span>
              <span v-else> {{ item.name }}</span>
            </span>
          </span>
          <span style="cursor: pointer" v-if="showDropDown">
            <el-dropdown @command="jump">
              <span class="el-dropdown-link">
                <i
                  class="el-icon-arrow-down el-icon--right"
                  style="color: #0099CC"
                ></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in btnList.slice(2)"
                  :key="item.name"
                  :command="{ item: item, row: scope.row, index: scope.$index }"
                >
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </template>
      </el-table-column>

      <div v-if="!showTableLoading">
        <div v-for="(item, index) in companyColunes" :key="index + 's'">
          <div v-if="item.english !== 'profile_match_cnt'">
            <el-table-column
              :show-overflow-tooltip="
                item.english != 'companyName' && item.english != 'perTags'
              "
              :prop="item.english"
              :align="item.english === 'companyName' ? 'left' : 'center'"
              :width="
                item.english === 'companyName' || item.english === 'perTag'
                  ? '220'
                  : ''
              "
              :label="item.chinese"
              :sortable="item.type === 'sort' ? 'custom' : false"
            >
              <template slot="header" slot-scope="scope">
                <span class="sort_list_cont">
                  <template v-if="item.english == 'dep'">
                    <el-popover
                      placement="bottom"
                      width="400"
                      ref="poop"
                      trigger="click"
                    >
                      <el-tree
                        @node-click="nodeClick"
                        default-expand-all
                        ref="tree"
                        node-key="id"
                        :data="departmentList"
                        :props="defaultProps"
                      >
                        <span
                          class="custom-tree-node"
                          slot-scope="{ node, data }"
                        >
                          <span :class="data.id == depId ? 'active' : ''">{{
                            node.label
                          }}</span>
                        </span>
                      </el-tree>
                      <span
                        class="el-dropdown-link"
                        :class="depId ? 'active' : ''"
                        slot="reference"
                      >
                        <span>{{ item.chinese }}</span
                        ><i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                    </el-popover>
                  </template>
                  <template v-else>
                    <template v-if="item.type == 'select'">
                      <template v-if="item.chinese === '企业标签'">
                        <span @click="handleOpen">
                          <span> 企业标签</span>
                          <svg-icon
                            icon-class="filterColor"
                            class="filterIcon"
                          />
                        </span>
                      </template>
                      <template v-else>
                        <el-dropdown
                          placement="bottom"
                          trigger="click"
                          @command="val => filterFunc(val, item)"
                        >
                          <template>
                            <span
                              class="el-dropdown-link"
                              :class="
                                selectOptions[item.english] ? 'active' : ''
                              "
                            >
                              <span>{{ item.chinese }}</span
                              ><i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <dt-drop-down
                                :source-list="item.filterOptionList"
                              >
                                <template v-slot:default="row">
                                  <el-scrollbar class="dt-dropdown-dt">
                                    <div
                                      class="dt-drop-down-no-data"
                                      v-if="!row.filterList.length"
                                    >
                                      无数据
                                    </div>
                                    <el-dropdown-item
                                      :command="_item.value"
                                      :class="
                                        selectOptions[item.english] &&
                                        selectOptions[item.english].value ===
                                          _item.value
                                          ? 'active'
                                          : ''
                                      "
                                      v-for="_item in row.filterList"
                                    >
                                      <span v-html="_item.htmlEl"></span>
                                    </el-dropdown-item>
                                  </el-scrollbar>
                                </template>
                              </dt-drop-down>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </template>
                    </template>
                    <template v-else>
                      <el-dropdown
                        v-if="item.english === 'leader' && activeTabs === '3'"
                        :class="chooseUserId ? 'drop-active' : ''"
                        placement="bottom-start"
                        @command="handleCommand"
                      >
                        <span
                          style="font-size: 12px;color: #909399;cursor: pointer"
                          :class="
                            selectLeader && selectLeader !== '-2'
                              ? 'dt-active'
                              : ''
                          "
                        >
                          负责人
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <dt-drop-down
                            :source-list="followList"
                            target-value="text"
                          >
                            <template v-slot:default="row">
                              <el-scrollbar class="dt-dropdown-dt">
                                <div
                                  class="dt-drop-down-no-data"
                                  v-if="!row.filterList.length"
                                >
                                  无数据
                                </div>
                                <el-dropdown-item
                                  v-for="(follow, ind) in row.filterList"
                                  :key="ind"
                                  :command="follow.value"
                                  :class="{
                                    active:
                                      chooseUserId !== '-2' &&
                                      Number.parseInt(chooseUserId) ===
                                        follow.value
                                  }"
                                >
                                  <span v-html="follow.htmlEl"></span>
                                </el-dropdown-item>
                              </el-scrollbar>
                            </template>
                          </dt-drop-down>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <span
                        v-else
                        :class="
                          selectLeader != '-2' && item.chinese == '负责人'
                            ? 'sort_list_name active-drop'
                            : ''
                        "
                        :title="item.chinese"
                      >
                        {{ item.chinese }}
                        <span class="sort_list_left">
                          <span class="sort_list_tip" v-if="item.hasOrder">
                            <i
                              class="sort_list_btn sort_up"
                              :class="
                                item.showUp ? 'sort_up_choose' : 'sort_up'
                              "
                              @click="sortListBtn('up', index, item.showUp)"
                            ></i>
                            <i
                              class="sort_list_btn"
                              :class="
                                item.showDown ? 'sort_down_choose' : 'sort_down'
                              "
                              @click="sortListBtn('down', index, item.showDown)"
                            ></i>
                          </span>
                        </span>
                      </span>
                    </template>
                  </template>
                </span>
              </template>

              <template slot-scope="scope">
                <span
                  class="company_had_time_list"
                  :title="
                    '当前线索已被领取' + scope.row.consumptionCount + '次'
                  "
                  v-if="
                    scope.row.consumptionCount !== '0' &&
                      item.english === 'company_name'
                  "
                  >{{ scope.row.consumptionCount }}</span
                >
                <span
                  class="hover-tip-icon"
                  v-if="
                    item.english === 'company_name' &&
                      currentHover === scope.$index
                  "
                  >{{ hoverText }}</span
                >
                <span
                  v-if="item.english === 'company_name'"
                  class="cus-name-hid"
                  @click="copyComName(item.english, scope.$index, scope.row)"
                  @mouseover="copyHoverIn(item.english, scope.$index)"
                  @mouseout="copyHoverOut(item.english, scope.$index)"
                  :class="scope.row.click === '1' ? 'list_opcity' : ''"
                  >{{ scope.row[item.english] }}</span
                >

                <div v-if="item.english === 'perTags'">
                  <Tags :tagList="getPerTags(scope.row['perTags'])"></Tags>
                </div>
                <div
                  v-else
                  :class="{
                    company_name: item.english === 'companyName' && isLink
                  }"
                >
                  <div
                    :class="[
                      item.english === 'companyName' &&
                      scope.row['lastDay'] &&
                      scope.row['lastDay'] !== -1
                        ? 'down'
                        : ''
                    ]"
                    :style="
                      item.english === 'companyName' && isLink
                        ? 'cursor:pointer'
                        : 'cursor:default'
                    "
                  >
                    <div v-if="item.chinese == '客户名称'">
                      <div
                        @click="
                          goKeyDetail(scope.row, item.english, scope.$index)
                        "
                      >
                        {{ scope.row[item.english] }}
                      </div>
                      <div style="color: #8a8a8a;cursor: default">
                        {{ scope.row['customerGrade'] }}
                      </div>
                    </div>
                    <div v-else>
                      {{ scope.row[item.english] }}
                    </div>
                  </div>

                  <el-tooltip
                    :visible-arrow="false"
                    popper-class="popper"
                    effect="dark"
                    content="剩余期限内无有效联系人（含姓名、职位、电话）和人为跟进记录，期限结束将会被退回“可跟进的”"
                    placement="right-start"
                    v-if="
                      item.english === 'companyName' &&
                        scope.row['lastDay'] &&
                        scope.row['lastDay'] !== -1
                    "
                  >
                    <div
                      :style="{
                        color: scope.row['lastDay'] <= 7 ? '#e20b0b' : '#909399'
                      }"
                      class="earn-time"
                    >
                      {{ `剩余${scope.row['lastDay']}天` }}
                    </div>
                  </el-tooltip>
                  <el-tooltip
                    :visible-arrow="false"
                    popper-class="popper"
                    effect="dark"
                    content="保护期内的公司不可转入自己名下"
                    placement="right-start"
                    v-if="
                      item.english === 'companyName' && scope.row['protectDay']
                    "
                  >
                    <div
                      :style="{
                        color: scope.row['lastDay'] <= 7 ? '#e20b0b' : '#909399'
                      }"
                      class="earn-time"
                    >
                      {{ `保护期${scope.row['protectDay']}天` }}
                    </div>
                  </el-tooltip>
                  <el-tooltip
                    :visible-arrow="false"
                    popper-class="popper"
                    effect="dark"
                    content="客户画像匹配"
                    placement="bottom-start"
                  >
                    <div style="display: inline">
                      <svg-icon
                        v-dt-permission="permissionPro"
                        icon-class="customerpic"
                        class="filtrate"
                        @click="openProfile(scope.row, scope.$index)"
                        v-if="item.english === 'companyName' && scope.row.match"
                      />
                    </div>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </div>
          <div v-else>
            <el-table-column
              :show-overflow-tooltip="false"
              :prop="item.english"
              :align="item.english === 'companyName' ? 'left' : 'center'"
              width="100"
              :label="item.chinese"
              sortable="customer"
            >
              <template slot-scope="scope">
                {{
                  scope.row.profile_match_cnt ? scope.row.profile_match_cnt : ''
                }}
              </template>
            </el-table-column>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-for="(item, index) in companyColunes" :key="index + 'b'">
          <el-table-column
            :show-overflow-tooltip="item.english !== 'company_name'"
            :prop="item.english"
            :align="item.english === 'company_name' ? 'left' : 'center'"
            :width="item.english === 'company_name' ? '250' : ''"
            :label="item.chinese"
          >
            <template slot="header">
              <p class="sort_list_cont">
                <span
                  class="sort_list_name"
                  :class="[item.hasOrder ? 'sort-icon-header' : '']"
                  :title="item.chinese"
                >
                  {{ item.chinese }}
                  <span class="sort_list_left">
                    <span class="sort_list_tip" v-if="item.hasOrder">
                      <i
                        class="sort_list_btn sort_up"
                        :class="item.showUp ? 'sort_up_choose' : 'sort_up'"
                        @click="sortListBtn('up', index, item.showUp)"
                      ></i>
                      <i
                        class="sort_list_btn"
                        :class="
                          item.showDown ? 'sort_down_choose' : 'sort_down'
                        "
                        @click="sortListBtn('down', index, item.showDown)"
                      ></i>
                    </span>
                  </span>
                </span>
              </p>
            </template>
            <template slot-scope="scope">
              <span
                class="company_had_time_list"
                :title="'当前线索已被领取' + scope.row.consumptionCount + '次'"
                v-if="
                  scope.row.consumptionCount !== '0' &&
                    item.english === 'company_name'
                "
                >{{ scope.row.consumptionCount }}</span
              >
              <span
                class="hover-tip-icon"
                v-if="
                  item.english === 'company_name' &&
                    currentHover === scope.$index
                "
                >{{ hoverText }}</span
              >
              <span
                v-if="item.english === 'company_name'"
                class="cus-name-hid"
                @click="copyComName(item.english, scope.$index, scope.row)"
                @mouseover="copyHoverIn(item.english, scope.$index)"
                @mouseout="copyHoverOut(item.english, scope.$index)"
                :class="scope.row.click === '1' ? 'list_opcity' : ''"
                >{{ scope.row[item.english] }}</span
              >
              <span
                v-else
                @click="goKeyDetail(scope.row['company_uuid'], scope.$index)"
                style="cursor:pointer;"
                >{{ scope.row[item.english] }}
              </span>
            </template>
          </el-table-column>
        </div>
      </div>
    </el-table>

    <el-drawer
      title="商机信息"
      :show-close="false"
      :visible.sync="showChance"
      :with-header="false"
      size="700px"
      destroy-on-close
      @close="closeChance"
      direction="rtl"
    >
      <chance-view :key-data="chanceData" :active-tabs="activeTabs">
      </chance-view>
    </el-drawer>
  </div>
</template>
<style scoped>
.el-table__empty-block {
  width: 100% !important;
}
</style>
<script>
import { getTransUserList } from '@/api/subaccount'
import chanceView from '@/views/customerpond/dialog/chanceView'
import fapiao from '@/views/customerpond/dialog/fapiao'
import DtDropDown from '@/components/element/DtDropDown'
import { departmentList } from '@/api/user'

import {
  customerPermission,
  permissionEditOrDelete,
  enterCompanyDetail
} from '@/utils/helper'
export default {
  name: 'PondList',
  components: {
    chanceView,
    DtDropDown
  },
  props: {
    companyList: {
      type: Array,
      default: () => []
    },
    companyColunes: {
      type: Array,
      default: () => []
    },
    checkAll: {
      type: Array,
      default: () => []
    },
    keyPackageList: {
      type: Array,
      default: () => []
    },
    followList: {
      type: Array,
      default: () => []
    },
    activeTabs: {
      type: String,
      default: ''
    },
    chooseUserId: {
      default: '-2'
    },
    depId: {
      default: null
    },
    resModule: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      accountId: this.$store.getters.accountInfo.id,
      role: this.$store.getters.accountInfo.role,
      currentHover: null,
      hoverText: '点击复制',
      isSelectUser: false,
      selectLeader: '-2',
      sortOrders: [],
      showTableLoading: false,
      checkList: [],
      selectUser: {},
      showDropDown: true,
      btnList: [],
      showChance: false,
      chanceData: {},
      selectOptions: {},
      keyNum: 0,
      departmentList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },

  mounted() {
    this.handelBtn()
  },

  watch: {
    activeTabs() {
      this.handelBtn()
    }
  },

  created() {
    this.getDepartmentList()
    const SSname = `KeyParams${localStorage.getItem('currentPackageId')}`
    const haveCondition = sessionStorage.getItem(SSname)
    if (haveCondition) {
      const params = JSON.parse(haveCondition)
      if (params.followUserIds) {
        const num = params.followUserIds.split(',')
        this.checkList = []
        num.forEach(item => {
          if (item) {
            this.checkList.push(Number(item))
          }
        })
      } else {
        this.checkList = []
      }
    } else {
      this.checkList = []
    }
  },

  computed: {
    isLink() {
      const versionNum = this.$store.state.user.accountInfo.versionNum
      return versionNum.indexOf('008') < 0
    },

    permissionList() {
      return this.$store.state.permission.permission
    }
  },
  methods: {
    handleOpen() {
      this.$emit('handleEnterpriseOpen', 'perTag')
    },
    getDepartmentList() {
      departmentList({ wholeTree: true }).then(res => {
        if (res.code === 60000) {
          this.departmentList = res?.data || []
        }
      })
    },

    nodeClick(data) {
      this.$refs.poop?.[0]?.doClose()
      this.$emit('filterDep', data.id)
    },

    filterFunc(val, item) {
      // debugger
      this.selectOptions[item.english] = {
        field: item.english,
        value: val,
        alias: item.alias,
        isCustomizeFields: !!item.isCustomizeFields
      }
      this.keyNum += 1
      this.$emit('filterTable', this.selectOptions)
    },

    clearSort() {
      this.$refs['multipleTable'].clearSort()
    },

    handleSortChange(data) {
      console.log(data, '1234')
      const alias = this.companyColunes.find(
        item => item.chinese == data.column.label
      )?.alias
      this.$emit('sortList', data, alias)
    },

    permissionPro() {
      const type = localStorage.getItem('activeTabs') || 1
      return customerPermission(type, '智能画像分析')
    },

    reSet() {
      this.selectLeader = '-2'
      this.clearSort()
      this.selectOptions = {}
    },

    jump(res) {
      res.item.click(res.row, res.index)
    },

    handelBtn() {
      let btnList = [
        {
          name: '跟进记录',
          click: (a, b) => {
            this.openCusPondDetail(a, b)
          },
          permission: this.permissionRecord(),
          show: this.permissionList.includes(this.permissionRecord())
        },
        {
          name: '联系人',
          click: (a, b) => {
            this.openContact(a, b)
          },
          permission: this.permissionContact(),
          show: this.permissionList.includes(this.permissionContact())
        },
        {
          name: '微营销',
          click: (a, b) => {
            this.handleMicro(a, b)
          },
          permission: this.permissionYx(),
          show: this.permissionList.includes(this.permissionYx())
        },
        {
          name: '智能画像分析',
          click: (a, b) => {
            this.openProfile(a, b)
          },
          permission: this.permissionPro(),
          show: this.permissionList.includes(this.permissionPro())
        },
        {
          name: '商机',
          click: (a, b) => {
            this.viewNiche(a, b)
          },
          permission: this.permissionNiche(),
          show: this.permissionList.includes(this.permissionNiche())
        },
        {
          name: '编辑',
          click: (a, b) => {
            this.editCusPond(a, b)
          },
          show:
            this.resModule == '我负责的'
              ? true
              : this.permissionList.includes(this.permissionEdit())
        },
        {
          name: '发票',
          click: (a, b) => {
            this.getVote(a, b)
          },
          permission: this.permissionVote(),
          show: this.permissionList.includes(this.permissionVote())
        },
        {
          name: '删除',
          click: (a, b) => {
            this.delCusPond(a, b)
          },
          show:
            this.resModule == '我负责的'
              ? true
              : this.permissionList.includes(this.permissionDel())
        }
      ]

      this.btnList = btnList.filter(item => item.show)
      if (this.btnList.length <= 2) {
        this.showDropDown = false
      }
    },

    getPerTags(perTags) {
      if (perTags) {
        return perTags.split(',')
      }
    },

    permissionEdit() {
      const type = localStorage.getItem('activeTabs') || 1
      return customerPermission(type, '编辑')
    },
    permissionVote() {
      return '客户管理CRM-客户公海-发票'
    },
    permissionRecord() {
      const type = localStorage.getItem('activeTabs') || 1
      return customerPermission(type, '查看跟进记录')
    },
    permissionYx() {
      const type = localStorage.getItem('activeTabs') || 1
      return customerPermission(type, '微营销')
    },
    permissionContact() {
      return '客户管理CRM-客户公海-联系人'
    },
    permissionDel() {
      const type = localStorage.getItem('activeTabs') || 1
      return customerPermission(type, '删除')
    },
    permissionNiche() {
      const type = localStorage.getItem('activeTabs') || 1
      return customerPermission(type, '商机')
    },
    handleCommand(val) {
      let followValue
      if (val === '-2') {
        this.isSelectUser = false
        followValue = val
      } else {
        this.isSelectUser = true
        followValue = this.followList.find(follow => follow.value === val).value
      }
      this.selectLeader = followValue
      this.checkList = []
      if (val === 'aaa') {
        this.checkList.push(null)
      } else {
        this.checkList.push(val)
      }
      this.$emit('chooseSelectFollow', this.checkList, this.selectLeader)
    },
    searveList() {
      this.checkList = []
      this.$emit('chooseSelectFollow', this.checkList)
    },
    getFollowNameList(val) {
      if (val) {
      } else {
        this.checkList = []
      }
      this.$emit('chooseSelectFollow', this.checkList)
    },
    chooseSelectFollow(val) {
      this.checkList = []
      this.checkList.push(val.value)
    },
    getFollowList() {
      getTransUserList({}).then(res => {
        if (res.code === 60000) {
          this.followList = []
          res.data.forEach(item => {
            const obj = {}
            obj.text = item.name
            obj.value = item.userId
            this.followList.push(obj)
          })
        } else {
          this.followList = []
        }
      })
    },
    goPackMarket() {
      this.$router.push({
        path: '/market/market'
      })
    },
    openLoading() {
      this.showTableLoading = true
    },
    closeLoading(val) {
      this.showTableLoading = false

      val.forEach((item, index) => {
        this.$set(this.companyColunes, index, item)
      })
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    cellClick(row, column, cell, e) {
      if (column.property === 'collet') {
        return
      }
    },
    openCusPondDetail(val, index) {
      const params = {
        row: val,
        index: index
      }
      this.$emit('openCusPondDetail', params, this.activeTabs)
    },

    getVote(val) {
      this.$store.dispatch('showModal', {
        view: fapiao,
        size: '600px',
        options: {
          data: val,
          activeTabs: this.activeTabs
        },
        type: 'drawer'
      })
    },

    editCusPond(val, index) {
      const params = {
        row: val,
        index: index
      }

      const res = permissionEditOrDelete(val, '客户公海', '编辑')
      if (res) {
        this.$emit('editCusPond', params)
      }
    },
    delCusPond(val, index) {
      const params = {
        row: val,
        index: index
      }
      const res = permissionEditOrDelete(val, '客户公海', '删除')
      if (res) {
        this.$emit('delCusPond', params)
      }
    },

    closeChance() {
      this.chanceData = {}
      this.showChance = false
    },

    // 商机
    viewNiche(val, index) {
      this.showChance = true
      this.chanceData = val
    },
    handleMicro(val, index) {
      const params = {
        row: val,
        index: index
      }
      this.$emit('openMicro', params)
    },
    goKeyDetail(val, name, index) {
      if (name === 'companyName' && this.isLink) {
        localStorage.setItem('activeTabs', this.activeTabs)
        enterCompanyDetail(val, this.companyList, index, val.packageId, {
          activeTabs: this.activeTabs
        })
      }
    },
    sortListBtn(val, index, type) {
      const currCom = this.companyColunes[index]
      if (val === 'up') {
        this.$set(currCom, 'showDown', !type)
        if (!type) {
          this.$set(currCom, 'showUp', false)
        }
      }
      if (val === 'down') {
        this.$set(currCom, 'showDown', !type)
        if (!type) {
          this.$set(currCom, 'showUp', false)
        }
      }
      const params = {
        type: val,
        index: index,
        showType: type
      }
      this.$emit('sortListBtn', params)
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    toggleSelection(rows) {
      this.$refs.multipleTable.clearSelection()
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    clear() {
      this.$refs.multipleTable.clearSelection()
    },
    copyComName(val, index, item) {
      if (val === 'company_name') {
        event.stopPropagation()
        var oInput = document.createElement('input')
        oInput.value = item[val]
        document.body.appendChild(oInput)
        this.selectText(oInput)
        document.execCommand('Copy')
        oInput.className = 'oInput'
        oInput.style.display = 'none'
        this.hoverText = '复制成功!'
        setTimeout(() => {
          this.currentHover = null
        }, 500)
      }
    },
    selectText(oInput) {
      if (oInput.createTextRange) {
        //ie
        oInput.select()
      } else {
        //firefox/chrome
        oInput.setSelectionRange(0, oInput.value.length)
        oInput.focus()
      }
    },
    copyHoverIn(val, index) {
      if (val === 'company_name') {
        this.currentHover = index
        this.hoverText = '点击复制'
      }
    },
    copyHoverOut(val, index) {
      if (val === 'company_name') {
        this.currentHover = null
      }
    },

    //点击进入官网
    click_web(val) {
      window.open('http://' + val)
    },
    openContact(a, index) {
      // debugger
      const params = {
        row: a,
        index: index
      }
      this.$emit('openContact', params)
    },
    openProfile(a, index) {
      const params = {
        row: a,
        index: index
      }
      this.$emit('openProfile', params)
    },
    // 新建联系人
    openContacts(val, index) {
      const params = {
        row: val,
        index: index
      }
      this.$emit('openPondContacts', params)
    }
  }
}
</script>

<style scoped>
.mapStar {
  font-size: 18px;
}
.filterIcon {
  position: absolute;
  cursor: pointer;
  top: -3px;
  left: 45px;
  font-size: 20px;
}
.company_name {
  color: #0099cc;
}

.di-in-line {
  display: inline-block;
}

.new-select-ad {
  text-align: center;
  font-size: 13px;
}

.el-checkbox__label {
  color: #41cabf !important;
}

.new-select-ad:hover {
  color: #41cabf;
}

.no-data-icon-body {
  width: 100%;
  height: 50px;
  margin: 0 auto;
}

.no-data-icon {
  height: 40px;
  width: 35px;
}

.cus-name-hid {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sort_down {
  top: 16px;
  background: url('../../assets/no-down.png') no-repeat;
  background-size: 100% 100%;
  background-position: top;
}

.sort_down_choose {
  top: 16px;
  background: url('../../assets/choose-down.png') no-repeat;
  background-size: 100% 100%;
  background-position: top;
}

.sort_up {
  top: 5px;
  background: url('../../assets/no-up.png') no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}

.sort_up_choose {
  top: 5px;
  background: url('../../assets/choose-up.png') no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}

.sort_list_btn {
  height: 8px;
  width: 11px;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  left: 0;
  cursor: pointer;
}

.sort_list_tip {
  display: inline-block;
  width: 12px;
  height: 40px;
  position: relative;
}

.sort_list_left {
  position: absolute;
  right: 0px;
  top: -4px;
  display: inline-block;
}

.sort-icon-header {
  position: relative;
  padding-right: 20px;
}

.com-list-detail {
  color: #0099cc;
  cursor: pointer;
}

.opea {
  margin-right: 6px;
  position: relative;
}

.opea::after {
  content: '';
  display: inline-block;
  height: 10px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: -3px;
  top: 20px;
}

.com-list-disable {
  color: #949494;
}

.list_opcity {
  /* background: #E7FFFD; */
  opacity: 0.5;
}

.company_had_time_list {
  position: absolute;
  left: 0;
  top: 0px;
  display: inline-block;
  height: 15px;
  border-radius: 8px;
  background: #41cabf;
  color: white;
  padding: 0 5px 0 5px;
  line-height: 15px;
  text-align: center;
  font-size: 12px;
}

.hover-tip-icon {
  position: absolute;
  display: inline-block;
  width: 60px;
  text-align: center;
  height: 30px;
  border: 1px solid #979797;
  border-radius: 5px;
  left: 20px;
  top: -20px;
  color: #979797;
  line-height: 28px;
  font-size: 12px;
  background: white;
}

.sort_list_cont {
  height: 100%;
  margin: 0 auto;
  position: relative;
  /* display: inline-block;
          width: 80%; */
}

.sort_list_name {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  overflow: hidden;
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.oace-key-table {
  padding-top: 10px;
}

.oace-key-table ::v-deep .el-table--border th {
  border-right: 1px solid #ebeef5;
  height: 50px;
}

.oace-key-table ::v-deep .el-table--border th:nth-child(1) {
  height: 50px;
}

.oace-key-table ::v-deep .el-table--border th:nth-child(2) {
  height: 50px;
}

.oace-key-table
  ::v-deep
  .el-table--enable-row-transition
  .el-table__body
  tbody
  tr
  td {
  border-right: none;
}

.oace-key-table ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}

.active {
  color: #41cabf;
}

.earn-time {
  transform: scale(0.85) translateY(-1px);
  transform-origin: 0 0;
  display: inline-block;
  cursor: pointer;
}

.down {
  transform: translateY(7px);
}

.remove-i > .opea:last-child {
  margin-right: 0 !important;
}

.remove-i > .opea:last-child::after {
  display: none;
}

.active-drop {
  color: #41cabf;
}

.sort_list_cont .active {
  color: #41cabf;
}

.badge-item ::v-deep .el-badge__content.is-fixed {
  top: 10px !important;
}

.filtrate {
  position: absolute;
  top: 50%;
  margin-top: -7.5px;
  left: -12px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.dt-active {
  color: #41cabf !important;
}
.el-dropdown-link {
  color: #909399;
  font-size: 12px;
  cursor: pointer;
}
</style>
