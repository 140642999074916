<template>
  <div class="power-wrap" :style="powerStyle">
    <div class="header" :class="getClass">
      <div class="header-left">
        <div class="left-select">
          <PeSelect
            style="width: 30%;"
            :popperClass="getPopperClass"
            :class="selectStyle"
            :defaultSelect="selectSchema"
            :optionsList="schemaList"
            @handleSelectChange="handleSchema"
          />
          <i
            class="el-icon-location-outline"
            style="font-size:24px;margin-left: 10px;"
          />
          <PeSelect
            style="width: 30%;"
            :popperClass="getPopperClass"
            :class="selectStyle"
            :optionsList="filterOptions"
            @handleSelectChange="handleCityChange"
            :defaultSelect="defaultCity"
            @getLocateSucc="getLocateSucc"
          />
        </div>
      </div>

      <ul class="add">
        <li
          v-for="(item, index) in addTab"
          :key="item.name"
          :class="computedTopClass(index)"
          @click="handleTabClick(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="operate">
        <div class="screen" @click="handleScreen">
          <svg-icon
            :icon-class="model ? 'screen-dark' : 'screen-light'"
            style="font-size:20px"
          />
          <span>一键投屏</span>
        </div>
        <el-switch v-model="model" @change="handleSwitchChange"></el-switch>
      </div>
    </div>

    <!-- 地图 -->
    <template v-if="selectMode == '门店网点'">
      <StoreMap ref="mapStore" @handleBrandSelect="handleBrandSelect" />
    </template>
    <template v-if="selectMode == '热力图谱'">
      <HotMap ref="mapStore" @handleBrandSelect="handleBrandSelect" />
    </template>
  </div>
</template>

<script>
import { getConsumeList } from '@/api/module'
import PeSelect from '@/components/Peculiar/select'
import SchemaMap from '@/components/Peculiar/map/schema-map'
import StoreMap from './shop/map'
import HotMap from './hot/map'
export default {
  name: 'consumeNetPage',
  data() {
    return {
      defaultCity: '',
      filterOptions: [],
      storeLoading: false,
      selectMode: '门店网点',
      chartPrice: '',
      showBuy: false,
      chartName: '',
      totalObj: {},
      applyShow: false,
      isBuyShow: false,
      schemaLoading: false,
      chartId: '',
      originAmount: '',
      quantity: 0,
      currentTab: 0,
      addTab: [
        {
          id: 0,
          name: '门店网点'
        },
        {
          id: 1,
          name: '热力图谱'
        }
      ],
      currentPage: 1,
      clickIndex: 1,
      selectSchema: '',
      pageTotal: 0,
      model: true,
      dialogShow: false,
      schemaShow: false,
      schemaList: [],
      currentIndex: 0,
      isFull: false,
      currentFlag: false,
      currentTime: '',
      cityList: [],
      selectCity: '',
      powerStyle: {
        margin: '10px 0 0 10px',
        color: '#636363'
      }
    }
  },
  mounted() {
    this.selectSchema = this.$route.query.tagName
    this.getSchemaData()()
    this.handleScreenStyle()
    window.addEventListener('fullscreenchange', e => {
      if (!document.fullscreenElement) {
        this.isFull = false
        this.handleExitScreen()
      }
    })
  },
  methods: {
    getLocateSucc(city) {
      this.defaultCity = city
    },
    handleBrandSelect(data) {
      this.filterOptions = data.map(item => ({
        label: `${item.city}(${item.count})`,
        value: item
      }))
      console.log(data, 'filter345')
    },

    computedTopClass(index) {
      return this.currentTab == index
        ? `${this.model ? 'active-dark li' : 'active-light li'}`
        : `li`
    },

    handleTabClick(item, index) {
      this.currentTab = index
      this.selectMode = item.name
    },

    async getSchemaData() {
      this.schemaLoading = true
      const { data } = await getConsumeList()
      this.schemaList = data.data.map(item => ({
        label: item.tagName,
        value: item
      }))
      this.pageTotal = data.page.total
      this.schemaLoading = false
    },

    handleScreenStyle() {
      const position = this.isFull ? 'fixed' : 'relative'
      const margin = this.isFull ? 0 : '10px 0 0 10px'
      const color = this.model ? '#fff' : '#636363'
      const width = this.isFull ? '100%' : 'auto'
      const top = 0
      this.powerStyle = {
        position,
        margin,
        color,
        width,
        top
      }
    },

    handleSwitchChange(e) {
      setTimeout(() => {
        this.$nextTick(() => {
          const map = this.$refs.mapStore?.map
          if (this.model) {
            map?.setMapStyle('amap://styles/dark')
          } else {
            map?.setMapStyle('amap://styles/normal')
          }
          this.handleScreenStyle()
        })
      }, 300)
    },

    handleSchema(data) {
      const item = data.value
      this.$router.replace({
        name: 'consumeSceneMap',
        query: {
          tagName: item.tagName
        }
      })
      this.selectSchema = item.tagName
      setTimeout(() => {
        const map = this.$refs.mapStore
        if (this.selectMode == '门店网点') {
          map?.massMarks?.clear()
        }
        map.renderStore(item.tagName)
      }, 500)
    },

    handleCityChange(data) {
      console.log(data, 'cityChange')
      const { longitude, latitude } = data.value
      const map = this.$refs.mapStore.map
      map.setCenter([longitude, latitude])
      this.selectCity = data.city
    },

    handleFullScreen() {
      let de = document.documentElement
      if (de.requestFullscreen) {
        de.requestFullscreen()
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen()
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen()
      }
      this.handleScreenStyle()
      this.$store.state.settings.isFullScreen = true
    },

    handleExitScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
      this.handleScreenStyle()
      this.$store.state.settings.isFullScreen = false
    },

    handleScreen() {
      this.isFull = !this.isFull
      if (this.isFull) {
        this.handleFullScreen()
      } else {
        this.handleExitScreen()
      }
      this.$store.state.settings.isFull = true
    }
  },
  beforeDestroy() {
    window.removeEventListener('fullscreenchange', () => {})
  },
  computed: {
    getPopperClass() {
      if (this.model) {
        return `active-popper`
      } else {
        return `default-popper`
      }
    },
    selectStyle() {
      if (this.model) {
        return `active-select`
      } else {
        return `default-select`
      }
    },
    getClass() {
      return this.model ? 'dark' : 'light'
    },
    getDialogClass() {
      return this.model ? 'dark-dialog' : 'light-dialog'
    },
    getBtnClass() {
      return this.model ? 'dark-btn' : 'light-btn'
    }
  },
  components: {
    HotMap,
    StoreMap,
    SchemaMap,
    PeSelect
  }
}
</script>
<style lang="scss">
.default-select {
  margin-left: 10px;
}
.active-select {
  margin-left: 10px;
  .el-input__inner {
    background: linear-gradient(
      180deg,
      rgba(99, 255, 250, 0.08) 0%,
      rgba(0, 117, 120, 0.2) 100%
    );
    box-shadow: inset 0px -5px 19px 9px rgba(255, 255, 255, 0.09);
    backdrop-filter: blur(5px);
    color: #fff;
    border: 2px solid rgba(65, 202, 191, 0.46);
  }
  .el-tag--info {
    background: none;
    border: none;
    color: #fff;
  }
}
.active-popper {
  background: linear-gradient(
    180deg,
    rgba(99, 255, 250, 0.08) 0%,
    rgba(0, 117, 120, 0.2) 100%
  );
  box-shadow: inset 0px -5px 19px 9px rgba(255, 255, 255, 0.09);
  backdrop-filter: blur(5px);
  border: 2px solid rgba(65, 202, 191, 0.46);
  .el-select-dropdown__list {
    background: none !important;
  }
  .el-select-dropdown__item {
    color: #fff;
  }
  .hover {
    background: none;
    color: none;
    cursor: pointer;
  }
  .selected {
    background: none !important;
    color: #02a7f0 !important;
  }
}
.el-pagination {
  display: flex;
  align-items: center;
}
.page-ul {
  display: flex;
  align-items: center;
  .li {
    margin: 0 10px;
    color: #636363;
    cursor: pointer;
  }
  .active {
    color: #41cabf;
  }
}
.dark-btn {
  background: linear-gradient(
    180deg,
    rgba(110, 110, 110, 0.3) 0%,
    rgba(0, 117, 120, 0.2) 100%
  );
  box-shadow: inset 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  border: 2px solid rgba(65, 202, 191, 0.46);
  backdrop-filter: blur(5px);
  color: #fff;
}
.light-btn {
  background: transparent;
  color: #636363;
}
.angle {
  position: absolute;
  width: 20px;
  height: 20px;
}
.angle_1 {
  left: -5px;
  top: -5px;
}
.angle_2 {
  left: -5px;
  bottom: -5px;
}
.angle_3 {
  right: -5px;
  top: -5px;
}
.angle_4 {
  right: -5px;
  bottom: -5px;
}
</style>
<style lang="scss" scoped>
.power-wrap {
  position: relative;
  font-size: 16px;
  padding: 0 !important;
  overflow: hidden;
  height: calc(100vh + 150px);
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    font-size: 14px;
    height: 40px;
    .header-left {
      display: flex;
      align-items: center;
      .left-select {
        display: flex;
        align-items: center;
        .item {
          display: flex;
          align-items: center;
          font-size: 16px;
          cursor: pointer;
          &:last-child {
            margin-left: 20px;
          }
          .pe-icon {
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            background: #636363;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            color: #fff;
          }
        }
      }
    }
    .logo {
      width: 140px;
      height: 36px;
    }
    .add {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      .li {
        cursor: pointer;
        margin: 0 10px;
        padding: 10px 20px;
      }
      .active-light {
        border-radius: 4px;
        color: #41cabf;
        border: 2px solid #41cabf;
      }
      .active-dark {
        color: #fff;
        background: linear-gradient(
          180deg,
          rgba(110, 110, 110, 0.3) 0%,
          rgba(0, 117, 120, 0.2) 100%
        );
        border-radius: 4px;
        border: 2px solid rgba(65, 202, 191, 0.46);
        backdrop-filter: blur(2px);
      }
    }
    .operate {
      display: flex;
      align-items: center;
      .time {
        display: flex;
        align-items: center;
        span {
          margin: 0 5px;
        }
      }
      .screen {
        margin: 0 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          margin: 0 5px;
        }
      }
    }
  }
  .dark {
    background: linear-gradient(
      180deg,
      rgba(99, 255, 250, 0.08) 0%,
      rgba(0, 117, 120, 0.2) 100%
    );
    box-shadow: inset 0px -5px 19px 9px rgba(255, 255, 255, 0.09);
    backdrop-filter: blur(5px);
  }
  .light {
    background: #fff;
  }

  .power-content {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0 10px;
    .dark-panel {
      background: linear-gradient(
        180deg,
        rgba(110, 110, 110, 0.3) 0%,
        rgba(0, 117, 120, 0.2) 100%
      );
      border-radius: 4px;
      border: 2px solid rgba(65, 202, 191, 0.46);
      backdrop-filter: blur(2px);
    }
    .panel {
      background: linear-gradient(
        132deg,
        rgba(255, 255, 255, 0.77) 0%,
        rgba(255, 255, 255, 0.84) 100%
      );
      border-radius: 4px;
      border: 2px solid;
      border-image: linear-gradient(
          180deg,
          rgba(161, 255, 247, 1),
          rgba(201, 230, 228, 1)
        )
        2 2;
      backdrop-filter: blur(2px);
    }
    .tab {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-bottom: 1px solid #ccc;
      padding-bottom: 5px;
      .tab-item {
        position: relative;
        cursor: pointer;
        &:last-child {
          margin-left: 20px;
        }
      }
      .active {
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 21px;
          width: 50px;
          height: 4px;
          border-radius: 4px;
          background: #41cabf;
        }
      }
    }
    .word {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      .word-item {
        position: relative;
        span {
          &:last-child {
            margin-left: 20px;
          }
        }
      }
      .text-item {
        display: flex;
        align-items: center;
      }
    }
    .list {
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 17px 0;
        .it-fl {
          display: flex;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #ff8d00;
            margin: 0 5px;
          }
          .init {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #41cabf;
            margin: 0 5px;
          }
        }
        .shrink {
          position: absolute;
          left: 50%;
        }
        .it-fr {
          display: flex;
          align-items: center;
          justify-content: center;
          .it {
            margin-left: 10px;
          }
          .it-hot {
            white-space: nowrap;
          }
          .it-dark {
            color: #d8d8d8;
          }
        }
      }
    }
    .top-40 {
      padding: 10px 20px 0;
    }
    .top-20 {
      margin-top: 40px;
      padding: 10px 20px 0;
    }
    .left {
      position: relative;
      z-index: 10;
      width: 25%;
    }
    .middle {
      position: relative;
      width: 50%;
      .title-center {
        position: absolute;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
        font-weight: bold;
        text-align: center;
        z-index: 10;
        .title {
          font-size: 40px;
        }
        .select-title {
          margin-top: 10px;
          font-size: 30px;
        }
      }

      .bar-footer {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
        width: 100%;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        .bar {
          width: 280px !important;
          height: 280px !important;
          &:last-child {
            margin-left: 100px;
          }
        }
      }
    }
    .right {
      position: relative;
      z-index: 10;
      width: 25%;
      .chart-wrap {
        display: flex;
        align-items: center;
        .circle-chart {
          width: 40%;
          height: 300px;
        }
        ul {
          width: 50%;
          li {
            padding: 8px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .li-inner {
              display: flex;
              align-items: center;
            }

            span {
              &:last-child {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.close-footer {
  width: 84%;
  display: flex;
  justify-content: flex-end;
}
.schema-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 82%;
  margin-top: 20px;
}
.light-dialog {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  background: #fff;
}
.dark-dialog {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #142929;
  border-radius: 4px;
}
</style>
