var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "showTip-main" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "save-this-search" }, [
        _c(
          "span",
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.checked,
                  callback: function ($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked",
                },
              },
              [_vm._v("不再提示")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.cancelBtn } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip-row" }, [
      _c("div", [
        _c("i", { staticClass: "el-icon-warning icon-color" }),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "陌拜营销受运营商管控，过度营销行为存在号码标记、投诉甚至封号风险。请注意营销话术，勿在营销过程中出现欺骗、辱骂、挑衅等行为。感谢配合！"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }