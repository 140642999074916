<template>
  <div class="pro-main">
    <div v-dt-permission="'销售排名-收款排名-查看'" class="btn-list">

      <div>
        <el-date-picker
          @change="yearChange"
          size="small"
          v-model="year"
          type="year"
          value-format="yyyy"
          placeholder="选择年">
        </el-date-picker>
      </div>

      <div style="margin-left: 10px">
        <el-radio-group v-model="selectDataType" size="small" @change="changeSelectRadio">
          <el-radio-button :label="item.value" v-for="item in selectDataTypeList">{{ item.name }}</el-radio-button>
        </el-radio-group>
      </div>

      <div v-if="selectDataType === 'm'" style="margin-left: 20px">
        <el-button size="mini" :type="selectData === 'm' ? 'primary' : '' " @click="changeType('m')">本月</el-button>
        <el-button size="mini" :type="selectData === 'nm' ? 'primary' : '' " @click="changeType('nm')">上月</el-button>
        <el-date-picker
          @change="getData"
          size="mini"
          v-model="selectTime"
          type="monthrange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始月份"
          end-placeholder="结束月份">
        </el-date-picker>
      </div>

      <div v-if="selectDataType === 'q'" style="margin-left: 20px">
        <el-button size="mini" :type="selectData === 'q' ? 'primary' : '' " @click="changeType('q')">本季度</el-button>
        <el-button size="mini" :type="selectData === 'nq' ? 'primary' : '' " @click="changeType('nq')">上季度</el-button>
        <quarter size="mini" value-format="yyyy-q" v-model="quarterDate" @change="changeType('mq')" placeholder="请选择季度" v-show="false"></quarter>
      </div>

      <div style="margin-left: 10px">
<!--        <el-select v-model="selectUser" multiple filterable placeholder="请选择员工" size="small" @change="getData">-->
<!--          <el-option-->
<!--            v-for="item in userList"-->
<!--            :key="item.id"-->
<!--            :label="item.name"-->
<!--            :value="item.id">-->
<!--          </el-option>-->
<!--        </el-select>-->

        <el-select v-model="selectUser" placeholder="请选择员工" multiple filterable size="small" @change="getData">
          <el-option-group
            v-for="group in userList"
            :key="group.label"
            :label="group.label">
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>

      </div>
    </div>
    <div class="main-row" v-dt-permission="'销售排名-收款排名-查看'">
      <div class="main-data">
        <div class="chart">
          <div class="chart-btn">
            <div>
              <i @click="changeChartType('bar')" :class="chartType === 'bar' ? 'active-histogram' : 'default-histogram' "/>
              <i @click="changeChartType('circle')" :class="chartType === 'circle' ? 'active-pie' : 'default-pie' "/>
            </div>
            <el-select v-model="selectType" size="small" @change="getData">
              <el-option
                v-for="item in selectTypeList"
                :key="item.name"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
          </div>
          <div class="chart-row" id="chart-row"></div>
        </div>

        <div class="z-center">
          <div style="margin-left: 10px">
            <!--            <el-select v-model="dataType" size="small" @change="getData">-->
            <!--              <el-option-->
            <!--                v-for="item in dataTypeList"-->
            <!--                :key="item.value"-->
            <!--                :label="item.name"-->
            <!--                :value="item.value">-->
            <!--              </el-option>-->
            <!--            </el-select>-->
          </div>
        </div>

        <div class="table" style="padding: 0 20px;box-sizing: border-box">
          <el-table
            show-summary
            :summary-method="getSummaries"
            @sort-change="sortChange"
            :data="tableData"
            size="small" border>
            <el-table-column
              :sortable="item.type === 'sort' ? 'custom' : false"
              v-for="item in columns"
              :label="item.name"
              :prop="item.field" header-align="left">

              <template slot="header" slot-scope="scope">
                <span v-if="item.name === '有效收款金额'">
                  {{ item.name }}
                  <el-tooltip class="item" effect="dark" :content="`有效收款金额=收款金额-退款金额`" placement="top-start">
                    <i class="el-icon-question" style="font-size: 15px;color: #8A8A8A"></i>
                  </el-tooltip>
                </span>

                <span v-else-if="item.name === '目标收款金额'">
                  {{ item.name }}
                  <el-tooltip class="item" effect="dark" :content="`收款目标设置的金额`" placement="top-start">
                    <i class="el-icon-question" style="font-size: 15px;color: #8A8A8A"></i>
                  </el-tooltip>
                </span>

                <span v-else-if="item.name === '外采金额'">
                  {{ item.name }}
                  <el-tooltip class="item" effect="dark" :content="`为客户采购物品的花费`" placement="top-start">
                    <i class="el-icon-question" style="font-size: 15px;color: #8A8A8A"></i>
                  </el-tooltip>
                </span>

                <span v-else-if="item.name === '收款完成率'">
                  {{ item.name }}
                  <el-tooltip class="item" effect="dark" :content="`收款完成率=有效收款金额/目标收款金额*100%`" placement="top-start">
                    <i class="el-icon-question" style="font-size: 15px;color: #8A8A8A"></i>
                  </el-tooltip>
                </span>

                <span v-else-if="item.name === '利润率'">
                  {{ item.name }}
                  <el-tooltip class="item" effect="dark" :content="`利润率=1-外采金额/有效收款金额*100%`" placement="top-start">
                    <i class="el-icon-question" style="font-size: 15px;color: #8A8A8A"></i>
                  </el-tooltip>
                </span>

                <span v-else>
                  {{ item.name }}
                </span>


              </template>

              <template slot-scope="scope">

                <span v-if="item.field === 'ratio'">
                  {{ scope.row[item.field] ? `${ scope.row[item.field] }%` : '0.00%' }}
                </span>
                <div class="input-row" v-else-if="item.format === 'RMB'">
                  {{ scope.row[item.field] | formatValue }}
                </div>
                <span v-else>
                  {{ scope.row[item.field] }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts'
import quarter from '@/components/element/quarter'

import { dataPOptions, processReturn, saleRankReturnRank } from '@/api/workTable'

export default {
  name: 'rement',
  components: {
    quarter
  },
  data () {
    return {
      chart: null,
      chartType: 'bar',
      selectDataType: 'm',
      quarterDate: '',
      year: '2021',

      selectData: 'm',
      selectTime: [],
      selectUser: [],
      dataType: '1',
      selectType: '收款金额',
      sort: '',
      sortOrder: '',
      tableData: [],
      columns: [],
      colorList:['#3a98ff','#7ecbe7','#37c4c4','#79dab6','#49c46a','#a3da79','#face37','#e79d6b','#f05c72'],

      dataTypeList: [
        {
          name: '按客户数',
          value: '1'
        },
        {
          name: '按次数',
          value: '2'
        }
      ],

      selectDataTypeList: [
        {
          name: '月度',
          value: 'm'
        },
        {
          name: '季度',
          value: 'q'
        }
      ],

      btnList: [
        // {
        //   name: '今天',
        //   value: 'n'
        // },
        // {
        //   name: '昨天',
        //   value: 'y'
        // },
        // {
        //   name: '最近七天',
        //   value: 's'
        // },
        // {
        //   name: '最近三十天',
        //   value: 't'
        // },
        {
          name: '本月',
          value: 'm'
        },
        {
          name: '上月',
          value: 'nm'
        },
        {
          name: '本季度',
          value: 'q'
        },
        {
          name: '上季度',
          value: 'nq'
        },
        {
          name: '全部',
          value: 'a'
        },
      ],
      userList: [],
      selectTypeList: [
        {
          id: 1,
          name: '收款金额'
        },
        {
          id: 2,
          name: '退款金额'
        },
        {
          id: 3,
          name: '有效收款金额'
        },
        {
          id: 4,
          name: '目标收款金额'
        }
      ]
    }
  },
  mounted () {
    this.getUserList()

    let cache = localStorage.getItem('rement-cache')
    if(cache){
      cache = JSON.parse(cache)
      this.year = cache.year || '2021'
      this.chartType = cache.chartType
      this.selectDataType = cache.selectDataType
      this.selectTime = cache.selectTime
      this.selectData = cache.selectData
      this.selectUser = cache.selectUser
      this.selectType = cache.selectType
      this.sort = cache.sort
      this.sortOrder = cache.sortOrder
      this.quarterDate = cache.quarterDate
      this.getData()
    }else {
      this.formatTime()
    }
    window.onresize = () => {
      this.chart ? this.chart.resize() : ''
    }
  },
  filters: {
    formatValue(val){
      const value = isNaN(parseInt(val)) ? 0 : parseInt(val)
      return value.toLocaleString()
    }
  },
  computed: {},
  methods: {

    getSummaries(param) {
      const { columns, data } = param;
      console.log(columns)
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = 0;
        }
        if(column.label === '签约完成率'){
          sums[index] = `${ sums[index].toFixed(2) }%`
        }else {
          sums[index] = sums[index].toLocaleString()
        }
      });
      return sums;
    },

    sortChange(data){
      const field = this.columns.filter(i => i.field === data.prop)[0]
      const order = data.order
      const map = {
        ascending: 'asc',
        descending: 'desc'
      }
      this.sort = field.alias ? field.alias : field.field
      this.sortOrder = map[order]
      this.getData()
    },

    getUserList(){
      dataPOptions({ pageId:14602, isDemo: 0  }).then(res => {
        if(res.code === 60000){
          this.userList = [{
            label: '在职',
            options: res.data.filter( i => i.active === 1)
          }, {
            label: '离职',
            options: res.data.filter( i => i.active !== 1).map(item => {
              item.name += '(离职)'
              return item
            })
          }]
        }
      })
    },

    changeRadio(){
      this.formatTime()
    },

    changeSelectRadio(){
      if(this.selectDataType === 'm') this.changeType('m')
      if(this.selectDataType === 'q') this.changeType('q')
    },

    changeType(data){
      this.selectData = data
      this.formatTime()
    },

    formatTime(){
      if(this.selectData === 'm'){
        this.selectTime = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      }
      if(this.selectData === 'nm'){
        this.selectTime = [moment(new Date()).subtract(1,'months').startOf('month').format('YYYY-MM-DD'),moment(new Date()).subtract(1,'months').endOf('month').format('YYYY-MM-DD')]
      }
      if(this.selectData === 'nq'){
        this.quarterDate = ''
        this.selectTime = [moment().quarter(moment().quarter() - 1).startOf('quarter').format('YYYY-MM-DD'), moment().quarter(moment().quarter() - 1).endOf('quarter').format('YYYY-MM-DD')]
      }
      if(this.selectData === 'q'){
        this.quarterDate = ''
        this.selectTime = [moment().startOf('quarter').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      }
      if(this.selectData === 'a'){
        this.selectTime = ['', moment().format('YYYY-MM-DD')]
      }
      if(this.selectData === 'mq'){
        const list = this.quarterDate.split('-')
        const _map = {
          1: '01-01',
          2: '04-01',
          3: '07-01',
          4: '10-01'
        }
        const time = `${ list[0] }-${ _map[list[1]] }`

        this.selectTime = [moment(time).startOf('quarter').format('YYYY-MM-DD'),moment(time).endOf('quarter').format('YYYY-MM-DD')]
      }
      this.getData()
    },

    setCache(){
      const cache = {
        chartType: this.chartType,
        selectDataType: this.selectDataType,
        selectData: this.selectData,
        selectUser: this.selectUser,
        selectType: this.selectType,
        sort: this.sort,
        sortOrder: this.sortOrder,
        quarterDate: this.quarterDate,
        selectTime: this.selectTime,
        year: this.year
      }
      localStorage.setItem('rement-cache', JSON.stringify(cache))
    },

    yearChange(){
      this.selectDataType = 'm'
      this.getData()
    },

    getData(){
      const params = {
        type: this.selectDataType === 'm' ? 1 : 2,
        pdas: this.selectUser.join(','),
        pageId: 14602,
        endDate: this.selectTime[1],
        startDate: this.selectTime[0],
        sort: this.sort,
        sortOrder: this.sortOrder
      }
      saleRankReturnRank(params).then(res => {
        if(res.code === 60000){
          this.setCache()
          this.drawChart(res.data)
          this.drawTable(res.data)
        }
      })
    },

    drawChart(data){
      if(!this.chart){
        const chart = document.getElementById('chart-row')
        this.chart = echarts.init(chart)
        if(this.chartType === 'bar') this.drawBar(data)
        if(this.chartType === 'circle') this.drawCircle(data)
      }else {
        if(this.chartType === 'bar') this.drawBar(data)
        if(this.chartType === 'circle') this.drawCircle(data)
      }
    },

    drawCircle (data) {
      let option = {
        tooltip: {
          trigger: 'item'
        },
        color: this.colorList,
        series: [
          {
            name: this.selectType,
            type: 'pie',
            radius: '50%',
            data: this.getChartCircleData(data),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label:{
              normal: {
                formatter: (e)=>{
                  if(e.value){
                    let newStr = " ";
                    let start,end;
                    let name_len = `${ e.name }${e.value}(${e.percent}%)`.length;
                    let max_name = 4;
                    let new_row = Math.ceil(name_len / max_name);
                    if(name_len > max_name){
                      for(let i=0; i<new_row; i++){
                        let old = '';
                        start = i * max_name;
                        end = start + max_name;
                        if(i === new_row - 1){
                          old = e.name.substring(start);
                        }else{
                          old = e.name.substring(start,end) + "\n";
                        }
                        newStr += old;
                      }
                    }else{
                      newStr = e.name;
                    }
                    return `${ newStr }${e.value}(${e.percent}%)`
                  }
                  return `${ e.name }(0)`
                },
                textStyle : {
                  fontWeight : 'normal',
                  fontSize : 12,
                  align:"center",
                  baseline:"middle",
                }
              }
            },
          }
        ],
      };
      this.chart.setOption(option, true)
    },

    drawBar(data){
      let option = {
        tooltip: {
          show: true
        },
        color: ['#41cabf'],
        xAxis: {
          type: 'category',
          data: this.getChartData(data).map(i => i.name),
          axisLabel:{
            interval: 'auto',
            rotate:45,
          }
        },
        dataZoom: [{
          type: 'inside'
        }, {
          type: 'slider'
        }],
        grid: {
          containLabel: true,
          left: '100px',
          right: '100px',
          bottom: '3%',
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: this.getChartData(data).map(i => i.value),
          type: 'bar',
          barMaxWidth: 50,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                color: '#000000',
                formatter: (params) => {
                  return this.selectType === '利润率' || this.selectType === '签约完成率' ? params.data + '%' :  params.data
                }
              }
            }
          }
        }]
      }
      this.chart.setOption(option, true)
    },

    changeChartType(type){
      this.chartType = type
      this.getData()
    },

    getChartData(data){
      const target = data.columns.filter(i => i.name === this.selectType)[0]['field']
      return  data.list.map(item => {
        return  {
          name: item.followName,
          value: isNaN(parseInt(item[target])) ? 0 : parseInt(item[target])
        }
      }).sort((a, b) => b.value - a.value)
    },

    getChartCircleData(data){
      const target = data.columns.filter(i => i.name === this.selectType)[0]['field']
      return data.list.map(item => {
        let value = isNaN(parseInt(item[target])) ? 0 : parseInt(item[target])
        return {
          name: item.followName,
          value: value,
          labelLine: { show: !!value },
          label: { show: !!value }
        }
      })
    },

    drawTable(data){
      this.columns = data.columns
      this.tableData = data.list
    },
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.pro-main{
  height: 100%;
}
.main-row{
  height: calc( 100% - 60px );
  width: 100%;
  overflow-y: auto;
}
.btn-list{
  margin-bottom: 10px;
  height: 50px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}
.main-data{
  min-height: calc( 100% - 60px );
  height: auto;
  background-color: #fff;
}
.chart{
  height: 500px;
  width: 100%;
}
.chart-btn{
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  box-sizing: border-box;
}
.chart-row{
  height: 450px;
  width: calc( 100% - 20px );
}
.z-center{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.default-histogram{
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url("../../assets/call_shell.svg") no-repeat center center;
}

.active-histogram{
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url("../../assets/call_shell_active.svg") no-repeat center center;

}
.default-pie{
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url("../../assets/call_cake.svg") no-repeat center center;
}

.active-pie{
  display: inline-block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: url("../../assets/call_cake_active.svg") no-repeat center center;
}
.input-row{
  padding-left: 22px;
  box-sizing: border-box;
  background: url("../../assets/rmb.svg") no-repeat left center;
  background-size: 20px 20px;
}

</style>


