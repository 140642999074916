var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "body",
      style: { height: _vm.showSelect ? "600px" : "100vh" },
    },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "title" },
          _vm._l(_vm.currentCheckObj, function (item) {
            return _c(
              "el-tag",
              {
                staticStyle: { margin: "0 5px 0 0" },
                attrs: { closable: "" },
                on: {
                  close: function ($event) {
                    return _vm.closeTag(item)
                  },
                },
              },
              [_vm._v(_vm._s(_vm._f("FormatText")(item.contact)))]
            )
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "dt-permission",
                rawName: "v-dt-permission",
                value: "客户管理CRM-客户公海-联系人",
                expression: "'客户管理CRM-客户公海-联系人'",
              },
            ],
          },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  placement: "bottom",
                  "popper-class": "popper",
                  "visible-arrow": false,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "font-size": "14px",
                    },
                    attrs: { slot: "content" },
                    slot: "content",
                  },
                  [_vm._v("\n          从系统提供的电话中转入\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "fuck-btn",
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.tranfContact },
                  },
                  [_vm._v("\n          转入联系人\n        ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.addContactDia },
              },
              [_vm._v("新建联系人")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.contactList.length
        ? _c(
            "el-scrollbar",
            {
              staticClass: "main dt-dropdown-dt",
              style: {
                height: _vm.showSelect
                  ? "calc( 100% - 100px )"
                  : "calc( 100% - 50px )",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                _vm._l(_vm.contactList, function (item) {
                  return _c("el-col", { key: item.id, attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "contact-item" },
                      [
                        _c("div", { staticClass: "contact-item-header" }, [
                          _c(
                            "div",
                            { staticClass: "contact-name" },
                            [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.showErrorTip(
                                        item,
                                        !(
                                          item.contact &&
                                          item.position &&
                                          (item.landLine || item.mobile)
                                        )
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.showSelect
                                    ? _c("el-checkbox", {
                                        attrs: {
                                          value: item.selected,
                                          disabled: _vm.permissionList.includes(
                                            "客户管理CRM-客户公海-有效联系人"
                                          )
                                            ? !(
                                                item.contact &&
                                                item.position &&
                                                (item.landLine || item.mobile)
                                              )
                                            : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.selectChange(item)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-badge",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    value: item["isKp"] == 1 ? "KP" : "",
                                    type: "primary",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { padding: "0 10px 0 5px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("FormatText")(item.contact)
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              item.position
                                ? _c(
                                    "span",
                                    {
                                      style: {
                                        marginLeft:
                                          item["isKp"] == 1 ? "20px" : "-5px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tag",
                                        { attrs: { size: "mini" } },
                                        [_vm._v(_vm._s(item.position))]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "contact-icon" }, [
                            _c("span", [
                              (_vm.$store.getters.accountType == "1" ||
                                _vm.isActiveTab != 2) &&
                              !item.isMaiMai
                                ? _c("i", {
                                    staticClass: "el-icon-setting",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editContactDia(item)
                                      },
                                    },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-setting not-allowed-icon",
                                  }),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              (_vm.$store.getters.accountType == "1" ||
                                _vm.isActiveTab != 2) &&
                              !item.isMaiMai
                                ? _c("i", {
                                    staticClass: "el-icon-delete",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCard(item)
                                      },
                                    },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-delete not-allowed-icon",
                                  }),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.itemList, function (_item) {
                          return _c(
                            "div",
                            { staticClass: "contact-item-li" },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v(_vm._s(_item.label)),
                              ]),
                              _vm._v(" "),
                              _item.key === "remark" && item["remark"]
                                ? _c(
                                    "el-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item[_item.key] || _item.default
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "value one-line" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item[_item.key] || _item.default
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _item.key !== "bindWechat" &&
                              _item.key !== "landLine" &&
                              _item.key !== "mobile" &&
                              _item.key !== "score" &&
                              _item.key !== "remark" &&
                              _item.key !== "renew" &&
                              _item.key !== "creater"
                                ? _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item[_item.key] || _item.default)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _item.key === "score"
                                ? _c("dt-score", {
                                    key: _vm.scoreKey,
                                    attrs: {
                                      score: item[_item.key] || _item.default,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _item.key === "renew" && item["renew"]
                                ? _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          `${
                                            item[_item.key] || _item.default
                                          }  ${item["updatedAt"]}`
                                        ) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _item.key === "creater"
                                ? _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          `${
                                            item[_item.key] || _item.default
                                          }  ${item["createdAt"] || ""}`
                                        ) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _item.key === "mobile" && item["mobile"]
                                ? _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          item[_item.key] || _item.default
                                        ) +
                                        "\n              "
                                    ),
                                    _vm.isWaihu == "1"
                                      ? _c("span", {
                                          staticClass: "callicon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.call(
                                                item[_item.key],
                                                item.contact,
                                                _vm.companyName
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _item.key === "landLine" && item["landLine"]
                                ? _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          item[_item.key] || _item.default
                                        ) +
                                        "\n              "
                                    ),
                                    _vm.isWaihu == "1"
                                      ? _c("span", {
                                          staticClass: "callicon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.call(
                                                item[_item.key],
                                                item.contact,
                                                _vm.companyName
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _item.key === "bindWechat"
                                ? _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          item[_item.key] ? "已关联" : "未关联"
                                        ) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.contactList.length
        ? _c(
            "div",
            {
              staticClass: "main center",
              style: {
                height: _vm.showSelect
                  ? "calc( 100% - 100px )"
                  : "calc( 100% - 50px )",
              },
            },
            [_vm._v("\n    暂无联系人\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSelect
        ? _c(
            "div",
            { staticClass: "btn" },
            [
              _vm.showSelect
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "dt-permission",
                          rawName: "v-dt-permission",
                          value: "客户管理CRM-客户公海-有效联系人",
                          expression: "'客户管理CRM-客户公海-有效联系人'",
                        },
                      ],
                      staticClass: "add-tip",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "red", "margin-right": "5px" },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("只能选择姓名、电话、职位齐全的有效联系人\n    "),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.cancelBtn } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.confirmBtn },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "addContact",
          staticClass: "pond-form-input",
          attrs: {
            btnLoading: _vm.btnLoading,
            dialogStatus: _vm.addContactModel,
          },
          on: { submitContBtn: _vm.addContactBtn },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "padding-top": "20px" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    "label-width": "auto",
                    rules: _vm.createUserRules,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名", prop: "contact" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入姓名" },
                                model: {
                                  value: _vm.formData.contact,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "contact", $$v)
                                  },
                                  expression: "formData.contact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("身份")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formData.isKp,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "isKp", $$v)
                                    },
                                    expression: "formData.isKp",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "关键人", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "中间人", value: "2" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "普通", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("部门")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入部门" },
                                model: {
                                  value: _vm.formData.department,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "department", $$v)
                                  },
                                  expression: "formData.department",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("职位")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入职位" },
                                model: {
                                  value: _vm.formData.position,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "position", $$v)
                                  },
                                  expression: "formData.position",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "mobile" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("手机")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入手机号码" },
                                model: {
                                  value: _vm.formData.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "mobile",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("座机")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入座机号码" },
                                model: {
                                  value: _vm.formData.landLine,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "landLine",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.landLine",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("微信号")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                staticStyle: { "ime-mode": "disabled" },
                                attrs: {
                                  placeholder: "请输入微信号",
                                  onkeyup:
                                    "value=value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                                  onpaste:
                                    "value=value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                                  oncontextmenu:
                                    "value=value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                                },
                                model: {
                                  value: _vm.formData.wechat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "wechat", $$v)
                                  },
                                  expression: "formData.wechat",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("邮箱")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入邮箱" },
                                model: {
                                  value: _vm.formData.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "email", $$v)
                                  },
                                  expression: "formData.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { slot: "label" },
                                      slot: "label",
                                    },
                                    [_vm._v("状态")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请选择状态" },
                                      model: {
                                        value: _vm.formData.mark,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "mark", $$v)
                                        },
                                        expression: "formData.mark",
                                      },
                                    },
                                    _vm._l(_vm.markDescList, function (_item) {
                                      return _c("el-option", {
                                        key: _item.value,
                                        attrs: {
                                          label: _item.name,
                                          value: _item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("备注")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  maxlength: "100",
                                  "show-word-limit": "",
                                  placeholder: "请输入备注",
                                  autosize: { minRows: 2, maxRows: 10 },
                                },
                                model: {
                                  value: _vm.formData.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "remark", $$v)
                                  },
                                  expression: "formData.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "deleteMark",
          attrs: { dialogStatus: _vm.dialogDelStatus },
          on: { submitContBtn: _vm.submitDelBtn },
        },
        [
          _c(
            "div",
            {
              staticStyle: { margin: "20px 0" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [_vm._v("\n      确认删除 ？\n    ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "tranfContact",
          staticClass: "pond-form-input",
          attrs: { dialogStatus: _vm.tranfContactModel },
          on: { submitContBtn: _vm.transContactBtn },
        },
        [
          _c(
            "div",
            {
              staticClass: "tranf-main",
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              !_vm.isGeted && _vm.phoneList.length
                ? _c(
                    "div",
                    { staticClass: "mark" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.clickLq },
                        },
                        [_vm._v("领取")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.phoneList.length
                ? _c("div", { staticClass: "no-data-tr" }, [
                    _vm._v("\n        暂无联系人\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.phoneList.length
                ? _c(
                    "el-scrollbar",
                    {
                      staticClass: "dt-dropdown-dt scroll",
                      style: { filter: _vm.isGeted ? "" : "blur(3px)" },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        _vm._l(_vm.phoneList, function (item, index) {
                          return _c(
                            "el-col",
                            {
                              key: index,
                              staticStyle: { "margin-bottom": "20px" },
                              attrs: { span: 12 },
                            },
                            [
                              _c("div", { staticClass: "phone-item" }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "check-box-dt",
                                        attrs: {
                                          value: item.selected,
                                          disabled:
                                            _vm.phoneListFilter.includes(
                                              item.phone
                                            ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.contactCheck(item)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.phone) +
                                            " " +
                                            _vm._s(item.contact || "") +
                                            "\n                  "
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#41CABF" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.phoneListFilter.includes(
                                                  item.phone
                                                )
                                                  ? "(已转入)"
                                                  : ""
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  _vm._l(
                                    _vm.showTelStatus(item),
                                    function (item) {
                                      return _c(
                                        "el-tag",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: { size: "mini" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "follow-source" },
                                  [
                                    _vm._v("\n                来源："),
                                    _vm._l(item.fromToUrl, function (_item) {
                                      return _c(
                                        "span",
                                        {
                                          class: _item.url
                                            ? "active-url"
                                            : "url",
                                          on: {
                                            click: function ($event) {
                                              return _vm.jumpToSource(_item.url)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_item.from))]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "dia-model",
        {
          ref: "editContact",
          staticClass: "pond-form-input",
          attrs: {
            btnLoading: _vm.btnLoading,
            dialogStatus: _vm.editContactModel,
          },
          on: { submitContBtn: _vm.editContactBtn },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "padding-top": "20px" },
              attrs: { slot: "modalCont" },
              slot: "modalCont",
            },
            [
              _c(
                "el-form",
                {
                  ref: "formedit",
                  attrs: {
                    model: _vm.formData,
                    "label-width": "auto",
                    rules: _vm.createUserRules,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名", prop: "contact" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入姓名" },
                                model: {
                                  value: _vm.formData.contact,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "contact", $$v)
                                  },
                                  expression: "formData.contact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("身份")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formData.isKp,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "isKp", $$v)
                                    },
                                    expression: "formData.isKp",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "关键人", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "中间人", value: "2" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "普通", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("部门")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入部门" },
                                model: {
                                  value: _vm.formData.department,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "department", $$v)
                                  },
                                  expression: "formData.department",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("职位")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入职位" },
                                model: {
                                  value: _vm.formData.position,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "position", $$v)
                                  },
                                  expression: "formData.position",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "mobile" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("手机")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入手机号码" },
                                model: {
                                  value: _vm.formData.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "mobile",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("座机")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入座机号码" },
                                model: {
                                  value: _vm.formData.landLine,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "landLine",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.landLine",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("微信号")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                staticStyle: { "ime-mode": "disabled" },
                                attrs: {
                                  placeholder: "请输入微信号",
                                  onkeyup:
                                    "value=value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                                  onpaste:
                                    "value=value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                                  oncontextmenu:
                                    "value=value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                                },
                                model: {
                                  value: _vm.formData.wechat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "wechat", $$v)
                                  },
                                  expression: "formData.wechat",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("邮箱")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请输入邮箱" },
                                model: {
                                  value: _vm.formData.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "email", $$v)
                                  },
                                  expression: "formData.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { slot: "label" },
                                      slot: "label",
                                    },
                                    [_vm._v("状态")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请选择状态" },
                                      model: {
                                        value: _vm.formData.mark,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "mark", $$v)
                                        },
                                        expression: "formData.mark",
                                      },
                                    },
                                    _vm._l(_vm.markDescList, function (_item) {
                                      return _c("el-option", {
                                        key: _item.value,
                                        attrs: {
                                          label: _item.name,
                                          value: _item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("备注")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入备注",
                                  maxlength: "100",
                                  "show-word-limit": "",
                                  autosize: { minRows: 2, maxRows: 10 },
                                },
                                model: {
                                  value: _vm.formData.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "remark", $$v)
                                  },
                                  expression: "formData.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }