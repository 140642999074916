var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "record-main" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "name-tag" }, [
          _c("span", { staticClass: "name" }, [
            _vm._v("\n        " + _vm._s(_vm.userData.contact) + "\n      "),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.userData.position,
                  expression: "userData.position",
                },
              ],
              staticClass: "position",
            },
            [_vm._v("\n        " + _vm._s(_vm.userData.position) + "\n      ")]
          ),
        ]),
        _vm._v(" "),
        _vm.userData.companyName
          ? _c("div", { staticClass: "cname" }, [
              _vm._v("\n      " + _vm._s(_vm.userData.companyName) + "\n    "),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        {
          staticClass: "main-record dt-dropdown-dt",
          style: {
            height: _vm.permissionEditRecord
              ? "calc( 100vh - 290px )"
              : "calc( 100vh - 80px )",
          },
        },
        [
          _vm.permissionViewRecord
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "main-row",
                  style: {
                    minHeight: _vm.permissionEditRecord
                      ? "calc( 100vh - 290px )"
                      : "calc( 100vh - 80px )",
                  },
                },
                [
                  _vm.pondCond && _vm.pondCond.length
                    ? _c(
                        "div",
                        { staticClass: "time-line" },
                        _vm._l(_vm.pondCond, function (item) {
                          return _c(
                            "el-timeline",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              _c("div", { staticClass: "time-header" }, [
                                _c("span", { staticClass: "time-week" }, [
                                  _vm._v(_vm._s(item.week)),
                                ]),
                                _c("span", { staticClass: "time-day" }, [
                                  _vm._v(_vm._s(item.day)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm._l(item.follows, function (follow) {
                                return _c(
                                  "el-timeline-item",
                                  {
                                    attrs: {
                                      placement: "top",
                                      color:
                                        item.week === "今天" ? "#FF0000" : "",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "follow-time" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(follow.time) +
                                          "\n            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "right" }, [
                                      _c(
                                        "div",
                                        { staticClass: "record-name-type" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "record-name" },
                                            [_vm._v(_vm._s(follow.follower))]
                                          ),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "dot" }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "record-type" },
                                            [_vm._v(_vm._s(follow.type))]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "record-text" },
                                        [
                                          _c(
                                            "el-row",
                                            [
                                              false
                                                ? _c(
                                                    "el-col",
                                                    {
                                                      staticClass: "text",
                                                      attrs: { span: 3 },
                                                    },
                                                    [_vm._v("记录：")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "text text-bg",
                                                  attrs: { span: 24 },
                                                },
                                                [_vm._v(_vm._s(follow.records))]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        1
                      )
                    : _c("div", { staticClass: "empty" }, [
                        _vm._v("\n        暂无数据\n      "),
                      ]),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.permissionEditRecord
        ? _c(
            "div",
            { staticClass: "input" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    "label-width": "auto",
                    size: "small",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "跟进方式", prop: "type" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择跟进方式" },
                                  model: {
                                    value: _vm.formData.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "type", $$v)
                                    },
                                    expression: "formData.type",
                                  },
                                },
                                _vm._l(_vm.typeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "跟进时间", prop: "time" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetime",
                                  placeholder: "选择日期时间",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "picker-options": _vm.pickerOptions,
                                },
                                model: {
                                  value: _vm.formData.time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "time", $$v)
                                  },
                                  expression: "formData.time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 4, maxRows: 4 },
                                  placeholder: "请输入内容",
                                },
                                model: {
                                  value: _vm.formData.text,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "text", $$v)
                                  },
                                  expression: "formData.text",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-form-item", { staticClass: "form-item" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "flex-end",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: { click: _vm.submit },
                                  },
                                  [_vm._v("提交")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }