var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dia-model",
        {
          ref: "scribesDialog",
          attrs: { dialogStatus: _vm.dialogStatus },
          on: { submitContBtn: _vm.submitContBtn },
        },
        [
          _c("div", { attrs: { slot: "modalCont" }, slot: "modalCont" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingCode,
                    expression: "loadingCode",
                  },
                ],
                staticClass: "qr-code-cont",
              },
              [
                _c("div", { ref: "qrCodeDiv", attrs: { id: "qrCode" } }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loadingCode,
                        expression: "!loadingCode",
                      },
                    ],
                    staticStyle: { "margin-top": "10px" },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "qr-code-txt qr-code-ac",
                        staticStyle: { "font-size": "12px" },
                        on: {
                          click: function ($event) {
                            return _vm.copyComName(_vm.acCodeUrl)
                          },
                        },
                      },
                      [_vm._v("复制链接")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "qr-code-txt qr-code-ac",
                        staticStyle: { "font-size": "12px" },
                        on: { click: _vm.CopyImgCode },
                      },
                      [_vm._v("下载二维码")]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }