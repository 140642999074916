<template>
  <div class="record-main">
    <div class="header">
      <div class="name-tag">
        <span class="name">
          {{ option.companyName }}
        </span>
      </div>
      <div>
        <el-tag v-if="option.from" size="mini">{{ option.from }}</el-tag>
        <el-tag size="mini" v-if="option.grade">{{ option.grade }}</el-tag>
        <el-tag size="mini" v-if="option.state">{{ option.state }}</el-tag>
        <el-tag size="mini" v-if="option.customerGrade">{{
          option.customerGrade
        }}</el-tag>
      </div>
      <div class="time-c-u">
        <div>
          <span>创建人：</span><span>{{ option.createUserName }}</span>
        </div>
        <div>
          <span>创建时间：</span><span>{{ option.createdAt }}</span>
        </div>
      </div>
    </div>

    <div class="tab">
      <el-tabs v-model="tabType" @tab-click="activeChange">
        <el-tab-pane label="全部" name="all" />
        <el-tab-pane label="跟进记录" name="first" />
        <el-tab-pane label="系统记录" name="second" />
        <el-tab-pane label="客户动态" name="third" />
      </el-tabs>
    </div>

    <el-scrollbar
      class="main-record dt-dropdown-dt"
      :style="{
        height:
          tabType !== 'third' && tabType !== 'second' && hasPermission
            ? ''
            : 'calc( 100vh - 150px )'
      }"
    >
      <div
        class="main-row"
        v-loading="loading"
        :style="{
          minHeight:
            tabType !== 'third' && tabType !== 'second'
              ? ''
              : 'calc( 100vh - 150px )'
        }"
      >
        <div v-if="pondCond && pondCond.length" class="time-line">
          <el-timeline v-for="item in pondCond" style="margin-bottom: 20px">
            <div class="time-header">
              <span class="time-week">{{ item.week }}</span
              ><span class="time-day">{{ item.day }}</span>
            </div>
            <el-timeline-item
              placement="top"
              v-for="follow in item.follows"
              :color="item.week === '今天' ? '#FF0000' : ''"
            >
              <div class="follow-time">
                {{ follow.time }}
              </div>
              <div class="right">
                <div class="record-name-type">
                  <span class="record-name">{{ follow.follower }}</span>
                  <span class="dot"></span>
                  <span class="record-type">{{ follow.opSummary }}</span>
                </div>
                <div class="record-text">
                  <el-row>
                    <el-col :span="3" class="text" v-if="false">记录：</el-col>
                    <el-col :span="24" class="text text-bg">{{
                      follow.records || follow.opDetails
                    }}</el-col>
                    <el-image
                      :src="follow.imagesUrl"
                      v-if="follow.imagesUrl"
                      fit="cover"
                    ></el-image>
                  </el-row>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="empty" v-else>
          暂无数据
        </div>
      </div>
    </el-scrollbar>

    <div
      class="input"
      v-if="tabType !== 'third' && tabType !== 'second' && hasPermission"
    >
      <el-form
        :model="formData"
        label-width="auto"
        size="small"
        :rules="rules"
        ref="form"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="跟进方式" prop="type">
              <el-select
                v-model="formData.type"
                placeholder="请选择跟进方式"
                style="width: 100%"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跟进时间" prop="time">
              <el-date-picker
                style="width: 100%"
                v-model="formData.time"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="跟进对象" prop="followUser">
              <div class="mask mask-user" @click="selectUser">
                <span class="select-tag">
                  <el-tag
                    closable
                    size="mini"
                    class="tag"
                    v-for="item in selectUserList"
                    @close="editSelectUser(item)"
                    >{{ item.contact }}</el-tag
                  >
                </span>
                <span>
                  选择
                </span>
              </div>
              <el-input
                :placeholder="selectUserList.length ? '' : '请选择跟进对象'"
                v-model="formData.followUser"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="跟进记录" prop="text">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4 }"
                placeholder="请输入内容"
                v-model="formData.text"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-item">
              <div style="display: flex;justify-content: flex-end">
                <el-button size="small" type="primary" @click="submit"
                  >提交</el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-dialog
      class="el-dialog-dt"
      width="850px"
      top="90px"
      :visible.sync="showSelectUser"
      :show-close="false"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
    >
      <div slot="title" class="slot-title">
        <div>
          选择跟进对象
        </div>
        <div @click="cancelSelectUser">
          <i class="el-icon-close close"></i>
        </div>
      </div>

      <div class="user-list">
        <selectBillKeyPersion
          :company-name="option.companyName"
          :is-active-tab="activeTabs"
          :current-check-row-prop="selectUserList"
          :package-id="option.packageId"
          :company-uuid="option.companyUuid"
          @closeDialog="closeDialog"
          @getRes="getSelectUser"
          :key="refreshSelectKey"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getFollowType, postFollowSaveRecords } from '@/api/table'
import { getCusFollowRecords } from '@/api/customerpond'

import moment from 'moment'
import { recordAll, recordCustomer } from '@/api/user'
import selectBillKeyPersion from '@/views/customerpond/chanceManage/dialog/selectBillKeyPersion'

export default {
  name: 'record',
  components: {
    selectBillKeyPersion
  },
  data() {
    var validateBillKeyperson = (rule, value, callback) => {
      if (!this.selectUserList.length) {
        callback(new Error('请选择跟进对象'))
      } else {
        callback()
      }
    }
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      pondCond: [],
      loading: false,
      typeList: [],
      option: {},
      formData: {
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: '',
        text: '',
        followUser: ''
      },
      rules: {
        type: [
          { required: true, message: '请选择跟进方式', trigger: 'change' }
        ],
        followUser: [
          { validator: validateBillKeyperson, required: true, trigger: 'blur' }
        ],
        text: [{ required: true, message: '请填写跟进记录', trigger: 'blur' }]
      },

      tabType: 'all',
      activeTabs: '',

      showSelectUser: false,
      refreshSelectKey: 0,
      selectUserList: []
    }
  },
  mounted() {
    this.option = this.$store.state.plugin.modal.options.data
    this.activeTabs = this.$store.state.plugin.modal.options.activeTabs || 1
    this.getFollowType()
    this.getRecordAll()
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    },
    hasPermission() {
      const type = parseInt(this.activeTabs)
      if (type === 1) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-我负责的-填写跟进记录'
        )
      }
      if (type === 2) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-可跟进的-填写跟进记录'
        )
      }
      if (type === 3) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-全公司的-填写跟进记录'
        )
      }
      if (type === 4) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-草稿箱-填写跟进记录'
        )
      }
      return false
    }
  },
  methods: {
    selectUser() {
      if (!this.option.companyName) return
      this.refreshSelectKey += 1
      this.showSelectUser = true
    },

    editSelectUser(item) {
      this.selectUserList = this.selectUserList.filter(i => i.id !== item.id)
      if (!this.selectUserList.length) this.formData.followUser = ''
    },

    cancelSelectUser() {
      this.showSelectUser = false
    },

    closeDialog() {
      this.cancelSelectUser()
    },

    getSelectUser(userList) {
      this.selectUserList = userList
      this.closeDialog()
    },

    activeChange() {
      this.loading = true
      const type = this.tabType
      if (type === 'first') {
        this.getCusFollowRecords()
      }
      if (type === 'all') {
        this.getRecordAll()
      }
      if (type === 'second') {
        this.getRecordSystem()
      }
      if (type === 'third') {
        this.getRecordCustomer()
      }
    },

    getCusFollowRecords() {
      const params = {
        companyUuid: this.option.companyUuid
      }
      getCusFollowRecords(params)
        .then(res => {
          this.loading = false
          if (res.code === 60000) {
            this.pondCond = res.data
          } else {
            this.pondCond = res.data
          }
        })
        .catch(() => {
          this.pondCond = res.data
        })
    },

    getRecordAll() {
      const params = {
        companyUuid: this.option.companyUuid
      }
      recordAll(params)
        .then(res => {
          this.loading = false
          if (res.code === 60000) {
            this.pondCond = res.data
          } else {
            this.pondCond = res.data
          }
        })
        .catch(() => {
          this.pondCond = res.data
        })
    },

    getRecordSystem() {
      const params = {
        type: 2,
        companyUuid: this.option.companyUuid
      }
      recordCustomer(params)
        .then(res => {
          this.loading = false
          if (res.code === 60000) {
            this.pondCond = res.data
          } else {
            this.pondCond = res.data
          }
        })
        .catch(() => {
          this.pondCond = res.data
        })
    },
    getRecordCustomer() {
      const params = {
        type: 3,
        companyUuid: this.option.companyUuid
      }
      recordCustomer(params)
        .then(res => {
          this.loading = false
          if (res.code === 60000) {
            this.pondCond = res.data
          } else {
            this.pondCond = res.data
          }
        })
        .catch(() => {
          this.pondCond = res.data
        })
    },

    getRecord() {
      const params = {
        companyUuid: this.userData.companyUuid,
        contactId: this.userData.id
      }
      this.loading = true
      getCusFollowRecords(params)
        .then(res => {
          this.loading = false
          if (res.code === 60000 && res.data) {
            this.pondCond = res.data
          } else {
            this.pondCond = res.data
          }
        })
        .catch(() => {
          this.pondCond = res.data
        })
    },

    getFollowType() {
      getFollowType({}).then(res => {
        if (res.code === 60000 && res.data) {
          this.typeList = res.data
        }
      })
    },

    submit() {
      this.$refs['form'].validate(valid => {
        if (!valid) return
        const params = {
          id: this.option.id, // 企业id
          followType: this.typeList.filter(
            item => item.value === this.formData.type
          )[0].name,
          followRecords: this.formData.text,
          date: this.formData.time,
          ids: this.option.createUserId
        }
        postFollowSaveRecords(params).then(res => {
          if (res.code === 60000) {
            this.$message({
              message: '提交成功！',
              type: 'success'
            })
            this.formData.text = ''
            this.formData.time = moment().format('YYYY-MM-DD HH:mm:ss')
            this.activeChange()
          }
        })
      })
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.record-main {
  height: 100%;
}
.header {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}
.time-c-u {
  position: absolute;
  right: 10px;
  font-size: 12px;
  line-height: 20px;
  color: #aaaaaa;
}
.name-tag {
  display: flex;
  align-items: center;
}
.name {
  font-size: 15px;
  color: #000000;
  font-weight: bold;
}
.position {
  padding: 5px 10px;
  box-sizing: border-box;
  display: inline-block;
  background-color: #e7fffd;
  color: #41cabf;
  font-size: 12px;
  border-radius: 5px;
  margin-left: 10px;
}
.cname {
  color: #959595;
  font-size: 14px;
}
.tab {
  height: 50px;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
}
.tab:before {
  content: '';
  height: 5px;
  width: 100%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  bottom: 0;
}
.tab /deep/ .el-tabs__nav-wrap::after {
  content: '';
  display: none;
}
.main-record {
  height: calc(100vh - 290px - 100px);
  width: 100%;
}
.main-row {
  width: calc(100% - 40px);
  min-height: calc(100vh - 290px - 100px);
  height: auto;
  margin: 0 auto;
  position: relative;
}
.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #8c939d;
  font-size: 14px;
}
.input {
  height: 260px;
  overflow-y: auto;
  position: relative;
  padding: 10px 20px;
  box-sizing: border-box;
}

.form-item {
  margin-bottom: 0 !important;
}

.time-line {
  padding: 20px 0 20px 80px;
  box-sizing: border-box;
}
.time-day,
.follow-time,
.record-name-type,
.record-text {
  font-size: 12px;
}
.record-text {
  color: #999999;
  margin-top: 20px;
}
.time-header {
  padding: 10px 0;
  box-sizing: border-box;
  transform: translate(-80px, -10px);
  position: relative;
}
.time-day {
  position: absolute;
  left: 108px;
  top: 50%;
  transform: translateY(-50%);
}
.time-week {
  font-size: 16px;
}
.follow-time {
  transform: translate(-110px, -10px);
}
.text {
  line-height: 150%;
  text-align: justify;
  padding: 5px;
  box-sizing: border-box;
}
.text-bg {
  /*background-color: rgba(0,0,0, .025);*/
}
.right {
  transform: translateY(-20px);
}
.dot {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 20px;
  transform: translateY(-5px);
}
.dot::before {
  content: '';
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #333333;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mask {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 99;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
}
.mask > span {
  color: #41cabf;
  font-size: 12px;
}

.slot-title {
  height: 50px;
  background-color: #41cabf;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.el-dialog-dt ::v-deep .el-dialog__header,
.el-drawer-dt ::v-deep .el-drawer__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}
.el-drawer-dt ::v-deep .el-drawer__header {
  margin-bottom: 0 !important;
}

.el-dialog-dt ::v-deep .el-dialog__close,
.el-drawer-dt ::v-deep .el-drawer__close {
  color: #fff;
}

.el-dialog-dt ::v-deep .el-dialog__body,
.el-drawer-dt ::v-deep .el-drawer__body {
  padding: 0 !important;
}
.close {
  cursor: pointer;
}
.select-tag {
  position: absolute;
  z-index: 99;
  left: 10px;
  color: #8c939d;
}
.tag {
  margin-right: 5px;
}
</style>
