<template>
  <div class="modul-table-list">
    <el-table
      :data="tableArr"
      resizable
      border
      ref="multipleTable"
      v-loading="showTableLoading"
      :class="
        !otherParams.isReceive && otherParams.shade ? 'module-cont-filter' : ''
      "
      style="height:100%;"
    >
      <template slot="empty" v-if="!showTableLoading">
        <div
          style="min-height:400px;padding-top:100px;"
          v-loading="showTableLoading"
        ></div>
      </template>
      <el-table-column width="2px"></el-table-column>
      <div v-for="(column, index) in headerArr" :key="index + 's'">
        <el-table-column
          show-overflow-tooltip
          :width="!haveAttr(column.attr, 'width') ? '' : column.width"
        >
          <template slot="header">
            <p class="sort_list_cont">
              <span>
                {{ column.name }}
              </span>
            </p>
          </template>
          <template slot-scope="scope">
            <span
              v-if="column.href && !column.modal"
              @click="goPageUrl(scope.row[column.href])"
              class="table-list-href"
              >{{ scope.row[column.field] }}</span
            >
            <span
              v-if="column.modal && column.href"
              @click="
                openModal(scope.row[column.href], scope.row[headerArr[0].field])
              "
              class="table-list-href"
              >{{ column.modal }}</span
            >
            <span
              v-if="!column.href && !column.modal"
              v-html="scope.row[column.field]"
            ></span>
            <span
              v-if="column.jump"
              @click="goPageUrl(scope.row[column.jump])"
              class="table-list-href"
              >{{ column.modal }}</span
            >
          </template>
        </el-table-column>
      </div>
    </el-table>
    <div style="height:40px;">
      <div class="page-div" v-if="pageStatus.total">
        <div style="float:right;">
          <footer-page
            @handleCurrentChange="handleCurrentChange"
            @goNumPage="goNumPage"
            :pageSizes="pageSizes"
            :pageStatus="pageStatus"
          >
          </footer-page>
        </div>
      </div>
    </div>
    <div
      class="touch-no-receive"
      v-if="!otherParams.isReceive && otherParams.shade"
      v-loading="getLoading"
    >
      <div class="touch-no-cont">
        <div class="touch-no-icon">
          <span
            style="color: #008CD9;text-decoration: underline;cursor:pointer;"
            @click="getReveiveKey('phone')"
            >领取</span
          >
          <span> 以查看所有信息</span>
        </div>
      </div>
    </div>
    <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showDetail">
      <div slot="modalCont">
        <p v-html="recruitStr" style="padding: 20px 0;"></p>
      </div>
      <div slot="submit">
        <div class="grasp_go_pay">
          <span @click="graspGoPay">关闭</span>
        </div>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import Mixin from './mixin'
export default {
  mixins: [Mixin],
  data() {
    return {
      headerArr: [],
      tableArr: [],
      showTableLoading: false,
      graspData: {},
      showDetail: false,
      recruitStr: ''
    }
  },
  created() {},
  mounted() {},
  components: {
    diaModel
  },
  methods: {
    goPageUrl(val) {
      window.open(val)
    },
    openModal(val, name) {
      if (!val) {
        this.$message({
          type: 'warning',
          message: '暂无详情'
        })
        return
      }
      this.goRecruitDetail(val, name)
    },
    goRecruitDetail(val, name) {
      this.recruitStr = val
      this.recruitStr = this.recruitStr.replace(/；/g, '；<br/>')
      this.recruitStr = this.recruitStr.replace(/：/g, '：<br/>')
      this.recruitStr = this.recruitStr.replace(/。/g, '。<br/>')
      this.recruitStr =
        '<div' +
        ' style=' +
        'word-wrap:break-word' +
        '>' +
        this.recruitStr +
        '</div>'
      this.showDetail = true
      this.graspData = {
        dialogWidth: '800px',
        dialogTitle: name,
        dialogTop: '250px',
        showFoot: false,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.graspTip.init()
      })
    },
    graspGoPay() {
      this.showDetail = false
    },
    haveAttr(val, lag) {
      if (val) {
        const arr = val.split(',')
        if (arr.indexOf(lag) > -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>
<style scoped>
.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}
</style>
<style scoped>
.table-list-href {
  color: #0099cc;
  cursor: pointer;
}
.modul-table-list {
  position: relative;
}
.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  opacity: 0.8;
  z-index: 100;
}
.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}
.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}
.touch-no-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
