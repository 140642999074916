var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "createContract-main" },
    [
      _c(
        "div",
        { staticClass: "tab-div" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "dt-tab",
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.tabType,
                callback: function ($$v) {
                  _vm.tabType = $$v
                },
                expression: "tabType",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "基础信息", name: "baseMsg" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "合同内容", name: "contractContent" },
              }),
              _vm._v(" "),
              _vm.permissionList.includes("客户管理CRM-客户公海-发票")
                ? _c("el-tab-pane", {
                    attrs: { label: "发票信息", name: "invoiceMsg" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-scrollbar", { staticClass: "contract-u-c-main dt-dropdown-dt" }, [
        _c("div", { staticClass: "scroll-main" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType === "baseMsg",
                  expression: "tabType === 'baseMsg'",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    "validate-on-rule-change": false,
                    "label-width": "auto",
                    rules: _vm.rules,
                    size: "small",
                    disabled: _vm.hasEditPermission,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "客户名称", prop: "companyName" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "input-mask",
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogType === "create"
                                        ? (_vm.showSelectCompany = true)
                                        : (_vm.showSelectCompany = false)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.dialogType === "create",
                                          expression: "dialogType === 'create'",
                                        },
                                      ],
                                    },
                                    [_vm._v("选择")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.dialogType !== "create",
                                  placeholder: "请选择客户名称",
                                },
                                model: {
                                  value: _vm.formData.companyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "companyName", $$v)
                                  },
                                  expression: "formData.companyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "合同编号", prop: "contractId" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入合同编号" },
                                model: {
                                  value: _vm.formData.contractId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "contractId", $$v)
                                  },
                                  expression: "formData.contractId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "签约关键人",
                                prop: "contactIds",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "input-mask input-tag",
                                  on: { click: _vm.showSelectUserFunc },
                                },
                                [
                                  _c(
                                    "span",
                                    _vm._l(_vm.selectUserList, function (item) {
                                      return _c(
                                        "el-tag",
                                        {
                                          staticStyle: {
                                            "margin-right": "3px",
                                          },
                                          attrs: {
                                            closable: !_vm.hasEditPermission,
                                            size: "mini",
                                          },
                                          on: {
                                            close: function ($event) {
                                              return _vm.removeUser(item)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.contact))]
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("选择")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "请选择签约关键人" },
                                model: {
                                  value: _vm.formData.contactIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "contactIds", $$v)
                                  },
                                  expression: "formData.contactIds",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "相关产品", prop: "product" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入相关产品" },
                                model: {
                                  value: _vm.formData.product,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "product", $$v)
                                  },
                                  expression: "formData.product",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "签约销售", prop: "signUserId" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择签约销售",
                                  },
                                  model: {
                                    value: _vm.formData.signUserId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "signUserId", $$v)
                                    },
                                    expression: "formData.signUserId",
                                  },
                                },
                                _vm._l(_vm.personOption, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "负责销售",
                                prop: "followUserId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择负责销售",
                                  },
                                  model: {
                                    value: _vm.formData.followUserId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "followUserId",
                                        $$v
                                      )
                                    },
                                    expression: "formData.followUserId",
                                  },
                                },
                                _vm._l(_vm.personOption, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同金额",
                                prop: "contractAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入合同金额" },
                                model: {
                                  value: _vm.formData.contractAmount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "contractAmount",
                                      $$v
                                    )
                                  },
                                  expression: "formData.contractAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "签约日期",
                                prop: "contractDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择签约日期",
                                },
                                model: {
                                  value: _vm.formData.contractDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "contractDate", $$v)
                                  },
                                  expression: "formData.contractDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "开始日期", prop: "startDate" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择开始日期",
                                },
                                model: {
                                  value: _vm.formData.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "startDate", $$v)
                                  },
                                  expression: "formData.startDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "到期日期", prop: "expireDate" },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择到期日期",
                                },
                                model: {
                                  value: _vm.formData.expireDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "expireDate", $$v)
                                  },
                                  expression: "formData.expireDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同类型",
                                prop: "contractType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择合同类型" },
                                  model: {
                                    value: _vm.formData.contractType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "contractType",
                                        $$v
                                      )
                                    },
                                    expression: "formData.contractType",
                                  },
                                },
                                _vm._l(_vm.contractTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "签约渠道", prop: "signChannel" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择签约渠道" },
                                  model: {
                                    value: _vm.formData.signChannel,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "signChannel", $$v)
                                    },
                                    expression: "formData.signChannel",
                                  },
                                },
                                _vm._l(_vm.signChannelList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同原件状态",
                                prop: "contractOriginStatus",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择合同原件状态" },
                                  model: {
                                    value: _vm.formData.contractOriginStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "contractOriginStatus",
                                        $$v
                                      )
                                    },
                                    expression: "formData.contractOriginStatus",
                                  },
                                },
                                _vm._l(
                                  _vm.contractOriginStatusList,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.name,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发票状态",
                                prop: "invoiceStatus",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择发票状态" },
                                  model: {
                                    value: _vm.formData.invoiceStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "invoiceStatus",
                                        $$v
                                      )
                                    },
                                    expression: "formData.invoiceStatus",
                                  },
                                },
                                _vm._l(_vm.invoiceStatusList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "账号开通日期",
                                prop: "accountStartDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择账号开通日期",
                                },
                                model: {
                                  value: _vm.formData.accountStartDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "accountStartDate",
                                      $$v
                                    )
                                  },
                                  expression: "formData.accountStartDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "账号到期日期",
                                prop: "accountExpireDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择账号到期日期",
                                },
                                model: {
                                  value: _vm.formData.accountExpireDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "accountExpireDate",
                                      $$v
                                    )
                                  },
                                  expression: "formData.accountExpireDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: _vm.isLn
                                    ? { minRows: 5, maxRows: 6 }
                                    : { minRows: 9, maxRows: 9 },
                                  placeholder: "请输入备注",
                                },
                                model: {
                                  value: _vm.formData.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "remark", $$v)
                                  },
                                  expression: "formData.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLn,
                          expression: "isLn",
                        },
                      ],
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "首次接触日期",
                                prop: "firstContactTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择首次接触日期",
                                },
                                model: {
                                  value: _vm.formData.firstContactTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "firstContactTime",
                                      $$v
                                    )
                                  },
                                  expression: "formData.firstContactTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品类型", prop: "productType" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择产品类型" },
                                  model: {
                                    value: _vm.formData.productType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "productType", $$v)
                                    },
                                    expression: "formData.productType",
                                  },
                                },
                                _vm._l(_vm.productTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          attrs: { span: 12 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收款金额",
                                prop: "returnAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入收款金额" },
                                model: {
                                  value: _vm.formData.returnAmount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "returnAmount",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "formData.returnAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          attrs: { span: 12 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款日期", prop: "payTime" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择收款日期",
                                },
                                model: {
                                  value: _vm.formData.payTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "payTime", $$v)
                                  },
                                  expression: "formData.payTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "客户行业", prop: "cusIndustry" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择客户行业" },
                                  model: {
                                    value: _vm.formData.cusIndustry,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "cusIndustry", $$v)
                                    },
                                    expression: "formData.cusIndustry",
                                  },
                                },
                                _vm._l(_vm.cusIndustryList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "账号时长",
                                prop: "accountDurationSaveData",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "账号时长自动计算",
                                },
                                model: {
                                  value: _vm.formData.accountDurationSaveData,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "accountDurationSaveData",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.accountDurationSaveData",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType === "contractContent",
                  expression: "tabType === 'contractContent'",
                },
              ],
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    { attrs: { size: "small", "label-width": "auto" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同内容" } },
                        [
                          _c("editor-bar", {
                            attrs: { hasEditPermission: _vm.hasEditPermission },
                            model: {
                              value: _vm.formData.contractContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "contractContent", $$v)
                              },
                              expression: "formData.contractContent",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "附件" } }, [
                        _c(
                          "div",
                          { staticClass: "upload-dt-c" },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                staticClass: "upload-demo",
                                attrs: {
                                  action: _vm.uploadFileUrl,
                                  "before-upload": _vm.beforeUpload,
                                  accept:
                                    ".doc,.docx,.pdf,.jpg,.png,.gif,.pages",
                                  "http-request": _vm.uploadFileToOss,
                                  "on-remove": _vm.handleRemove,
                                  "on-preview": _vm.downLoadFile,
                                  "file-list": _vm.fileList,
                                  "auto-upload": true,
                                  disabled: _vm.hasEditPermission,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "trigger",
                                      disabled: _vm.hasEditPermission,
                                      size: "small",
                                    },
                                    slot: "trigger",
                                  },
                                  [_vm._v("选取文件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    可上传多个。文件格式限制：doc、docx、pdf、jpg、png、gif、pages\n                  "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType === "invoiceMsg",
                  expression: "tabType === 'invoiceMsg'",
                },
              ],
            },
            [
              _c(
                "span",
                {
                  staticClass: "edit-invoice",
                  style: { visibility: _vm.isEdit ? "hidden" : "" },
                  on: { click: _vm.editInvClick },
                },
                [_vm._v("修改发票信息")]
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.invoiceLoading,
                      expression: "invoiceLoading",
                    },
                  ],
                  staticClass: "form-invoice",
                  attrs: {
                    model: _vm.invoiceFormData,
                    size: "mini",
                    "label-width": "120px",
                    "label-position": "left",
                    disabled: _vm.hasEditPermission,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开具类型" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.openType && _vm.invoiceFormData.openType,
                              expression:
                                "openType && invoiceFormData.openType",
                            },
                          ],
                          staticClass: "tip-text",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.openType === "复制成功"
                                  ? "复制成功 !"
                                  : "点击复制"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isEdit
                        ? _c(
                            "span",
                            {
                              staticClass: "value",
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceFormData.openType,
                                    "openType"
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.spanElStatus("openType", true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.spanElStatus("openType", false)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.invoiceFormData.openType))]
                          )
                        : _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择开具类型" },
                              model: {
                                value: _vm.invoiceFormData.openType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.invoiceFormData, "openType", $$v)
                                },
                                expression: "invoiceFormData.openType",
                              },
                            },
                            _vm._l(_vm.openTypeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票类型" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.invoiceType &&
                                _vm.invoiceFormData.invoiceType,
                              expression:
                                "invoiceType && invoiceFormData.invoiceType",
                            },
                          ],
                          staticClass: "tip-text",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.invoiceType === "复制成功"
                                  ? "复制成功 !"
                                  : "点击复制"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isEdit
                        ? _c(
                            "span",
                            {
                              staticClass: "value",
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceFormData.invoiceType,
                                    "invoiceType"
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.spanElStatus("invoiceType", true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.spanElStatus("invoiceType", false)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.invoiceFormData.invoiceType))]
                          )
                        : _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择发票类型" },
                              model: {
                                value: _vm.invoiceFormData.invoiceType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.invoiceFormData,
                                    "invoiceType",
                                    $$v
                                  )
                                },
                                expression: "invoiceFormData.invoiceType",
                              },
                            },
                            _vm._l(_vm.invoiceTypeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票抬头" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.invoiceTitle &&
                                _vm.invoiceFormData.invoiceTitle,
                              expression:
                                "invoiceTitle && invoiceFormData.invoiceTitle",
                            },
                          ],
                          staticClass: "tip-text",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.invoiceTitle === "复制成功"
                                  ? "复制成功 !"
                                  : "点击复制"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isEdit
                        ? _c(
                            "span",
                            {
                              staticClass: "value",
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceFormData.invoiceTitle,
                                    "invoiceTitle"
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.spanElStatus("invoiceTitle", true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.spanElStatus("invoiceTitle", false)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.invoiceFormData.invoiceTitle))]
                          )
                        : _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              disabled: "",
                              placeholder: "请输入发票抬头",
                            },
                            model: {
                              value: _vm.invoiceFormData.invoiceTitle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.invoiceFormData,
                                  "invoiceTitle",
                                  $$v
                                )
                              },
                              expression: "invoiceFormData.invoiceTitle",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "纳税人识别号" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.taxpayerIdenifyCode &&
                                _vm.invoiceFormData.taxpayerIdenifyCode,
                              expression:
                                "\n                taxpayerIdenifyCode && invoiceFormData.taxpayerIdenifyCode\n              ",
                            },
                          ],
                          staticClass: "tip-text",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.taxpayerIdenifyCode === "复制成功"
                                  ? "复制成功 !"
                                  : "点击复制"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isEdit
                        ? _c(
                            "span",
                            {
                              staticClass: "value",
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceFormData.taxpayerIdenifyCode,
                                    "taxpayerIdenifyCode"
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.spanElStatus(
                                    "taxpayerIdenifyCode",
                                    true
                                  )
                                },
                                mouseleave: function ($event) {
                                  return _vm.spanElStatus(
                                    "taxpayerIdenifyCode",
                                    false
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.invoiceFormData.taxpayerIdenifyCode)
                              ),
                            ]
                          )
                        : _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入纳税人识别号" },
                            model: {
                              value: _vm.invoiceFormData.taxpayerIdenifyCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.invoiceFormData,
                                  "taxpayerIdenifyCode",
                                  $$v
                                )
                              },
                              expression: "invoiceFormData.taxpayerIdenifyCode",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.address && _vm.invoiceFormData.address,
                              expression: "address && invoiceFormData.address",
                            },
                          ],
                          staticClass: "tip-text",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.address === "复制成功"
                                  ? "复制成功 !"
                                  : "点击复制"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isEdit
                        ? _c(
                            "span",
                            {
                              staticClass: "value",
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceFormData.address,
                                    "address"
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.spanElStatus("address", true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.spanElStatus("address", false)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.invoiceFormData.address))]
                          )
                        : _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入地址" },
                            model: {
                              value: _vm.invoiceFormData.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.invoiceFormData, "address", $$v)
                              },
                              expression: "invoiceFormData.address",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.phone && _vm.invoiceFormData.phone,
                              expression: "phone && invoiceFormData.phone",
                            },
                          ],
                          staticClass: "tip-text",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.phone === "复制成功"
                                  ? "复制成功 !"
                                  : "点击复制"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isEdit
                        ? _c(
                            "span",
                            {
                              staticClass: "value",
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceFormData.phone,
                                    "phone"
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.spanElStatus("phone", true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.spanElStatus("phone", false)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.invoiceFormData.phone))]
                          )
                        : _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入电话" },
                            model: {
                              value: _vm.invoiceFormData.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.invoiceFormData, "phone", $$v)
                              },
                              expression: "invoiceFormData.phone",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户行" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.openBank && _vm.invoiceFormData.openBank,
                              expression:
                                "openBank && invoiceFormData.openBank",
                            },
                          ],
                          staticClass: "tip-text",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.openBank === "复制成功"
                                  ? "复制成功 !"
                                  : "点击复制"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isEdit
                        ? _c(
                            "span",
                            {
                              staticClass: "value",
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceFormData.openBank,
                                    "openBank"
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.spanElStatus("openBank", true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.spanElStatus("openBank", false)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.invoiceFormData.openBank))]
                          )
                        : _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入开户行" },
                            model: {
                              value: _vm.invoiceFormData.openBank,
                              callback: function ($$v) {
                                _vm.$set(_vm.invoiceFormData, "openBank", $$v)
                              },
                              expression: "invoiceFormData.openBank",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行账号" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.bankAccount &&
                                _vm.invoiceFormData.bankAccount,
                              expression:
                                "bankAccount && invoiceFormData.bankAccount",
                            },
                          ],
                          staticClass: "tip-text",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.bankAccount === "复制成功"
                                  ? "复制成功 !"
                                  : "点击复制"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isEdit
                        ? _c(
                            "span",
                            {
                              staticClass: "value",
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceFormData.bankAccount,
                                    "bankAccount"
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.spanElStatus("bankAccount", true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.spanElStatus("bankAccount", false)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.invoiceFormData.bankAccount))]
                          )
                        : _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入银行账号" },
                            model: {
                              value: _vm.invoiceFormData.bankAccount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.invoiceFormData,
                                  "bankAccount",
                                  $$v
                                )
                              },
                              expression: "invoiceFormData.bankAccount",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮寄地址" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.mailAddress &&
                                _vm.invoiceFormData.mailAddress,
                              expression:
                                "mailAddress && invoiceFormData.mailAddress",
                            },
                          ],
                          staticClass: "tip-text",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.mailAddress === "复制成功"
                                  ? "复制成功 !"
                                  : "点击复制"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isEdit
                        ? _c(
                            "span",
                            {
                              staticClass: "value",
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(
                                    _vm.invoiceFormData.mailAddress,
                                    "mailAddress"
                                  )
                                },
                                mouseenter: function ($event) {
                                  return _vm.spanElStatus("mailAddress", true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.spanElStatus("mailAddress", false)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.invoiceFormData.mailAddress))]
                          )
                        : _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入邮寄地址" },
                            model: {
                              value: _vm.invoiceFormData.mailAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.invoiceFormData,
                                  "mailAddress",
                                  $$v
                                )
                              },
                              expression: "invoiceFormData.mailAddress",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isEdit
                    ? _c("el-form-item", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-end",
                              "padding-right": "340px",
                              "padding-bottom": "30px",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.isEdit = false
                                  },
                                },
                              },
                              [_vm._v("\n                取消\n              ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.editInvoice },
                              },
                              [_vm._v("\n                保存\n              ")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.hasEditPermission
        ? _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirmBtnThrottle },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            width: "800px",
            top: "90px",
            visible: _vm.showSelectCompany,
            "show-close": false,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectCompany = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slot-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v("\n        选择客户\n      ")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSelectCompany = false
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-close close" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c("select-company", {
            on: {
              closeSelectCompany: function ($event) {
                _vm.showSelectCompany = false
              },
              selectCompany: _vm.selectCompany,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            width: "850px",
            top: "90px",
            visible: _vm.showSelectUser,
            "show-close": false,
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectUser = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slot-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v("\n        选择签约决策人\n      ")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSelectUser = false
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-close close" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-list" },
            [
              _c("selectBillKeyPersion", {
                attrs: {
                  "company-name": _vm.selectCompany.companyName,
                  "current-check-row-prop": _vm.selectUserList,
                  "package-id": _vm.selectCompanyData.packageId,
                  "company-uuid": _vm.selectCompanyData.companyUuid,
                },
                on: {
                  closeDialog: function ($event) {
                    _vm.showSelectUser = false
                  },
                  getRes: _vm.getSelectUser,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }