<template>
  <div class="index-main">
    <div class="btn-list">
      <div class="input-row">
        <el-input
          style="width: 200px"
          v-model="keywords"
          size="mini"
          placeholder="输入收款单编号、合同编号或客户名称查询"
        ></el-input>
        <div class="btn-row">
          <el-button type="primary" size="mini" @click="searchReturnList"
            >查询</el-button
          >
          <el-button size="mini" @click="reset">重置</el-button>
        </div>
        <div class="g-search" @click="searchPlus">
          高级筛选<svg-icon icon-class="filtrate" class="filtrate" />
        </div>
        <div class="condition" v-show="condition">
          <span class="condition-icon" @click="removeSearch">
            <i class="el-icon-circle-close"></i>
          </span>
          <el-input
            style="width: 200px"
            v-model="showCondition"
            size="mini"
          ></el-input>
        </div>
      </div>

      <div>
        <el-button
          size="mini"
          type="primary"
          @click="createPayBack"
          v-dt-permission="'客户管理CRM-收/退款管理-新建收款单'"
        >
          新建收款单
        </el-button>

        <el-button
          size="mini"
          type="primary"
          @click="createReturnPay"
          v-dt-permission="'客户管理CRM-收/退款管理-新建收款单'"
        >
          新建退款单
        </el-button>
      </div>
    </div>
    <div
      class="table"
      v-loading="loading"
      v-dt-permission="'客户管理CRM-收/退款管理-查看收款单'"
    >
      <el-table
        class="table-dt"
        :key="tableKey"
        :data="tableData"
        @sort-change="sortChange"
        border
        stripe
        style="width: 100%; height: 100%; font-size: 12px;background-color:#fff;"
      >
        <el-table-column
          show-tooltip-when-overflow
          v-for="item in columnList"
          :prop="item.field"
          align="center"
          :sortable="item.type === 'sort' ? 'custom' : false"
          :label="item.name"
        >
          <template slot="header" slot-scope="scope">
            <span v-if="item.type === 'select'">
              <el-dropdown
                placement="bottom"
                trigger="click"
                @command="val => filterFunc(val, item)"
              >
                <template v-if="item.field === 'belongUser'">
                  <span
                    class="el-dropdown-link"
                    :class="userId ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <dt-drop-down :source-list="followUserList">
                      <template v-slot:default="row">
                        <el-scrollbar class="dt-dropdown-dt">
                          <div
                            class="dt-drop-down-no-data"
                            v-if="!row.filterList.length"
                          >
                            无数据
                          </div>
                          <el-dropdown-item
                            :command="_item.id"
                            :class="userId === _item.id ? 'active' : ''"
                            v-for="_item in row.filterList"
                          >
                            <span v-html="_item.htmlEl"></span>
                          </el-dropdown-item>
                        </el-scrollbar>
                      </template>
                    </dt-drop-down>
                  </el-dropdown-menu>
                </template>

                <template v-else-if="item.field === 'returnTypeDesc'">
                  <span
                    class="el-dropdown-link"
                    :class="returnType ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :command="_item.id"
                        :class="returnType === _item.id ? 'active' : ''"
                        v-for="_item in returnTypeList"
                        >{{ _item.name }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>

                <template v-else>
                  <span
                    class="el-dropdown-link"
                    :class="returnPayment ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :command="_item.id"
                        :class="returnPayment === _item.id ? 'active' : ''"
                        v-for="_item in returnPaymentList"
                        >{{ _item.name }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>

            <span v-if="item.type === 'sort'">
              {{ item.name }}
            </span>

            <span v-if="item.type !== 'select' && item.type !== 'sort'">
              {{ item.name }}
            </span>
          </template>

          <template slot-scope="scope">
            <company-link
              v-if="item.field === 'companyName' && isLink"
              :company-name="scope.row.companyName || scope.row.company_name"
              :company-uuid="scope.row.companyUuid || scope.row.company_uuid"
              :package-id="scope.row.packageId || scope.row.package_id"
            />
            <span v-else-if="item.field === 'setting'" class="set">
              <span
                class="set-btn"
                @click="editPayBack(scope.row)"
                v-dt-permission="'客户管理CRM-收/退款管理-编辑'"
                >编辑</span
              >
              <span v-dt-permission="'客户管理CRM-收/退款管理-编辑'"> | </span>
              <span
                class="set-btn"
                @click="delPayBack(scope.row)"
                v-dt-permission="'客户管理CRM-收/退款管理-删除'"
                >删除</span
              >
              <span v-dt-permission="'客户管理CRM-收/退款管理-删除'"> | </span>
            </span>
            <span v-else-if="item.field == 'returnMoney'">{{
              transferNum(scope.row[item.field])
            }}</span>
            <span v-else> {{ scope.row[item.field] }} </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page page-div page-1">
      <footer-page
        @handleCurrentChange="handleCurrentChange"
        @goNumPage="goNumPage"
        @changePageSize="changePageSize"
        :pageStatus="pageStatus"
      ></footer-page>
    </div>
  </div>
</template>

<script>
import FooterPage from '@/components/Footerpage/index'
import { returnList, returnDrown, delPayBack } from '@/api/customerpond'
import companyLink from '@/components/companyLink/companyLink'
import { contractLeaders } from '@/api/user'
import { formatRegex } from '@/utils/helper'
import searchPayBack from '@/views/contract/dialog/searchPayBack'
import createOrEditPayBack from '@/views/contract/dialog/createOrEditPayBack'
import createOrEditReturnBack from '@/views/contract/dialog/createOrEditReturnBack'
import DtDropDown from '@/components/element/DtDropDown'

export default {
  name: 'outstanding',
  components: {
    FooterPage,
    companyLink,
    DtDropDown
  },
  data() {
    return {
      tableKey: 0,
      optionsList: [],
      hashMap: {},
      showCondition: '',
      followUserList: [],
      loading: false,
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: null
      },

      order: '',
      userId: '',
      condition: '',
      keywords: '',
      returnPayment: '',
      returnType: '',

      tableData: [],
      columnList: [],
      returnTypeList: [
        {
          id: 1,
          name: '收款'
        },
        {
          id: 2,
          name: '退款'
        }
      ],
      returnPaymentList: [
        {
          id: 1,
          name: '正常'
        },
        {
          id: 2,
          name: '押金'
        }
      ]
    }
  },
  watch: {
    condition(val) {
      this.showCondition = this.formatCondition(val)
    }
  },
  mounted() {
    this.getOptionsList()
    this.getFollowUserList()
    let hashMap = sessionStorage.getItem('optionsList-returnList')
    if (hashMap) {
      this.hashMap = JSON.parse(hashMap)
    }
    let cache = sessionStorage.getItem('returnListCache')
    if (cache) {
      cache = JSON.parse(cache)
      this.pageStatus.pageNo = cache.pageNo
      this.pageStatus.pageSize = cache.pageSize
      this.order = cache.order
      this.userId = cache.userId
      this.condition = cache.condition
      this.keywords = cache.keywords
      this.returnPayment = cache.returnPayment
      this.returnType = cache.returnType
    }
    this.getReturnList()
  },
  computed: {
    isLink() {
      const versionNum = this.$store.state.user.accountInfo.versionNum
      return versionNum.indexOf('008') < 0
    }
  },
  methods: {
    transferNum(num) {
      if (num) {
        return formatRegex(num)
      }
    },
    formatCondition(val) {
      if (!val) return ''
      let data = val.split(';')
      return data
        .map(i => {
          const target = this.hashMap[i.split(':')[0]]
          return `${target}:${i.split(':')[1]}:${i.split(':')[2]}`
        })
        .join(';')
    },

    // 获取负责人列表
    getFollowUserList() {
      contractLeaders({ pageId: 2006 }).then(res => {
        if (res.code === 60000) {
          this.followUserList = res.data
        }
      })
    },
    getOptionsList() {
      returnDrown({ pageId: 2006 }).then(res => {
        if (res.code === 60000) {
          this.optionsList = res.data
          this.optionsList.forEach(item => {
            this.hashMap[item.filterColumn] = item.filterName
          })
          sessionStorage.setItem(
            'optionsList-returnList',
            JSON.stringify(this.hashMap)
          )
        }
      })
    },

    sortChange(data) {
      const map = {
        ascending: 'asc',
        descending: 'desc'
      }
      map[data.order]
        ? (this.order = `${data.prop}:${map[data.order] || ''}`)
        : (this.order = '')
      this.getReturnList()
    },

    searchPlus() {
      this.$store.dispatch('showModal', {
        title: '高级筛选',
        view: searchPayBack,
        size: '800px',
        options: {
          source: this.optionsList,
          type: 'followRecord'
        },
        onClose: res => {
          if (res.refresh) {
            this.condition = res.searchFilter
            this.pageNo = 1
            this.pageSize = 20
            this.getReturnList()
          }
        }
      })
    },

    removeSearch() {
      this.condition = ''
      this.getReturnList()
    },

    filterFunc(val, item) {
      if (item.field === 'belongUser') {
        this.userId = val
      }
      if (item.field === 'returnTypeDesc') {
        this.returnType = val
      }
      if (item.field === 'returnPaymentDesc') {
        this.returnPayment = val
      }
      this.getReturnList()
    },

    reset() {
      this.condition = ''
      this.keywords = ''
      this.order = ''
      this.userId = ''
      this.returnPayment = ''
      this.returnType = ''

      this.tableKey += 1
      this.pageStatus = {
        pageNo: 1,
        pageSize: this.pageStatus.pageSize || 20,
        total: null
      }
      this.getReturnList()
    },

    searchReturnList() {
      if (!this.keywords) return
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      this.getReturnList()
    },

    setCache(params) {
      sessionStorage.setItem('returnListCache', params)
    },

    getReturnList() {
      this.loading = true
      const params = {
        condition: this.condition,
        keywords: this.keywords,
        userId: this.userId,
        order: this.order,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        returnPayMent: this.returnPayment,
        returnType: this.returnType,
        pageId: '2006'
      }
      returnList(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.setCache(JSON.stringify(params))
          this.tableData = res.data.data
          this.pageStatus.pageNo = res.data.page.pageNo
          this.pageStatus.total = res.data.page.total
          this.columnList = [
            ...res.data.columns,
            {
              field: 'setting',
              name: '操作'
            }
          ]
        }
      })
    },

    delPayBack(params) {
      const { id, contractReturnNumber } = params
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: `确认删除 ${contractReturnNumber}? `,
        onClose: res => {
          if (res === 'confirm') {
            delPayBack({ id }).then(res => {
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: res.code === 60000 ? 'success' : 'error'
              })
              res.code === 60000 ? this.reset() : ''
            })
          }
        },
        type: 'warning'
      })
    },

    createReturnPay() {
      this.$store.dispatch('showModal', {
        view: createOrEditReturnBack,
        size: '600px',
        title: '新建退款单',
        options: {
          userList: this.followUserList
        },
        onClose: res => {
          if (res.refresh) {
            this.reset()
          }
        }
      })
    },

    createPayBack() {
      this.$store.dispatch('showModal', {
        view: createOrEditPayBack,
        size: '600px',
        title: '新建收款单',
        options: {
          userList: this.followUserList
        },
        onClose: res => {
          if (res.refresh) {
            this.reset()
          }
        }
      })
    },

    editPayBack(params) {
      const type = params.returnType
      this.$store.dispatch('showModal', {
        view: type === 1 ? createOrEditPayBack : createOrEditReturnBack,
        size: '600px',
        title: type === 1 ? '编辑收款单' : '编辑退款单',
        options: {
          userList: this.followUserList,
          type: 'update',
          data: params
        },
        onClose: res => {
          if (res.refresh) {
            this.reset()
          }
        }
      })
    },

    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.getReturnList()
    },

    goNumPage(val) {
      this.pageStatus.pageNo = val
      this.getReturnList()
    },

    changePageSize(val) {
      this.pageStatus.pageSize = val
      this.getReturnList()
    }
  }
}
</script>

<style scoped>
.index-main {
  height: 100%;
}
.btn-list {
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
.input-row {
  display: flex;
  align-items: center;
}
.btn-row {
  margin-left: 10px;
  display: flex;
}
.table {
  margin-top: 10px;
  min-height: calc(100% - 60px - 40px);
  background-color: #fff;
}
.table ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
.table ::v-deep .el-table th,
.el-table tr {
  background-color: #fff;
}
.page-1 {
  background-color: #fff;
}
.set-btn {
  cursor: pointer;
  color: #0099cc;
}
.set > span:last-child {
  display: none;
}
.el-dropdown-link {
  cursor: pointer;
  color: #909399;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.active {
  color: #41cabf !important;
}
.g-search {
  color: #41cabf;
  margin-left: 10px;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
}
.filtrate {
  margin-left: 2px;
}
.condition {
  position: relative;
  margin-left: 10px;
}
.condition /deep/ .el-input__inner {
  padding-right: 40px;
}
.condition-icon {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40px;
  z-index: 99;
  cursor: pointer;
  color: #41cabf;
  /*background-color: rosybrown;*/
}
.set-btn {
  cursor: pointer;
  color: #0099cc;
}
.set > span:last-child {
  display: none;
}
</style>
