var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "body",
    },
    [
      _c("div", { staticClass: "go-back", on: { click: _vm.hideModal } }, [
        _c("i", { staticClass: "el-icon-d-arrow-left" }),
      ]),
      _vm._v(" "),
      _vm.contactData
        ? _c("div", [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10, type: "flex", align: "center" } },
                  [
                    _c(
                      "el-col",
                      { staticClass: "center", attrs: { span: 18 } },
                      [
                        _c("el-avatar", {
                          staticStyle: { "margin-right": "5px" },
                          attrs: {
                            src:
                              _vm.contactData.wxHeadImg ||
                              "https://dtbird-miniapp.oss-cn-shanghai.aliyuncs.com/image/header-c.svg",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-badge",
                          {
                            staticClass: "badge-item",
                            attrs: {
                              value: _vm.contactData.isKp == "1" ? "KP" : "",
                              type: "primary",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-right": "10px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.contactData["contact"]) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "inline-block",
                              "margin-left": "20px",
                            },
                          },
                          [
                            _vm.contactData["position"] ||
                            _vm.contactData["department"]
                              ? _c("el-tag", { attrs: { size: "small" } }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.tag) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { staticClass: "center", attrs: { span: 6 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "dt-permission",
                                rawName: "v-dt-permission",
                                value: `客户培育-${_vm.permission}-详情-编辑`,
                                expression:
                                  "`客户培育-${permission}-详情-编辑`",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.edit },
                          },
                          [_vm._v("\n            编辑\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "dt-permission",
                                rawName: "v-dt-permission",
                                value: `客户培育-${_vm.permission}-详情-删除`,
                                expression:
                                  "`客户培育-${permission}-详情-删除`",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.del },
                          },
                          [_vm._v("\n            删除\n          ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "select" }, [
              _c(
                "div",
                {
                  class: _vm.defaultMsg ? "active" : "",
                  on: {
                    click: function ($event) {
                      _vm.defaultMsg = true
                    },
                  },
                },
                [_vm._v("\n        基本信息\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: _vm.defaultMsg ? "" : "active",
                  on: { click: _vm.getCustomerDetail },
                },
                [_vm._v("\n        客户动态\n      ")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.defaultMsg,
                    expression: "defaultMsg",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "common" },
                  _vm._l(_vm.commonAttr, function (item) {
                    return _c(
                      "el-row",
                      { staticClass: "list-item", attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "label", attrs: { span: 3 } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.label) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            class: _vm.contactData[item.key]
                              ? "value"
                              : "value",
                            attrs: { span: 21 },
                          },
                          [
                            item.label === "创建人"
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        `${
                                          _vm.contactData[item.key] ||
                                          item.defaultValue
                                        } ${_vm.contactData["createdAt"]}`
                                      ) +
                                      "\n            "
                                  ),
                                ])
                              : item.label === "修改人"
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        `${
                                          _vm.contactData[item.key] ||
                                          item.defaultValue
                                        } ${_vm.contactData["updatedAt"]}`
                                      ) +
                                      "\n            "
                                  ),
                                ])
                              : item.label === "完善度"
                              ? _c("dt-score", {
                                  key: _vm.refreshNum,
                                  attrs: { score: _vm.contactData[item.key] },
                                })
                              : item.key === "bindWechat"
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.contactData[item.key]
                                          ? "已关联"
                                          : "未关联"
                                      ) +
                                      "\n              "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.contactData[item.key] ||
                                          item.defaultValue
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "no-common" },
                  _vm._l(_vm.noCommonAttr, function (item) {
                    return _c(
                      "el-row",
                      { staticClass: "list-item", attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "label", attrs: { span: 3 } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.label) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            class: _vm.contactData[item.key]
                              ? "value"
                              : "value",
                            attrs: { span: 21 },
                          },
                          [
                            item.label === "创建人"
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        `${
                                          _vm.contactData[item.key] ||
                                          item.defaultValue
                                        }   ${_vm.contactData["createdAt"]}`
                                      ) +
                                      "\n            "
                                  ),
                                ])
                              : item.label === "修改人"
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        `${
                                          _vm.contactData[item.key] ||
                                          item.defaultValue
                                        }   ${
                                          _vm.contactData[item.key]
                                            ? _vm.contactData["updatedAt"]
                                            : ""
                                        }`
                                      ) +
                                      "\n            "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.contactData[item.key]) +
                                      "\n            "
                                  ),
                                ]),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.defaultMsg,
                    expression: "!defaultMsg",
                  },
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.dtLoading,
                    expression: "dtLoading",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "dt-cus" },
                  [
                    _c("div", { staticClass: "dynamic-data" }, [
                      _c("div", { staticClass: "dynamic-item" }, [
                        _c("div", [_vm._v("近7日访问")]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(_vm._s(_vm.dtData["sevenRecord"] || "0")),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "dynamic-item" }, [
                        _c("div", [_vm._v("近30日访问")]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(_vm._s(_vm.dtData["thirtyRecord"] || "0")),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "dynamic-item" }, [
                        _c("div", [_vm._v("总访问次数")]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(_vm._s(_vm.dtData["AllCount"] || "0")),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "dynamic-item" }, [
                        _c("div", [_vm._v("上次访问")]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(_vm._s(_vm.dtData["recentTime"] || "-")),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-scrollbar", { staticClass: "dynamic-data-data" }, [
                      _c("div", [
                        _c(
                          "div",
                          { ref: "line", staticClass: "line" },
                          [
                            _vm._l(_vm.tongJi, function (item, index) {
                              return [
                                _c(
                                  "div",
                                  { key: index + "a", staticClass: "week" },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.day))]),
                                    _c("span", [_vm._v(_vm._s(item.week))]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-timeline",
                                  { key: index + "b" },
                                  _vm._l(
                                    item.follows,
                                    function (activity, index) {
                                      return _c(
                                        "el-timeline-item",
                                        {
                                          key: index,
                                          attrs: {
                                            size: "normal",
                                            color:
                                              activity.createdAt === _vm.nowTime
                                                ? "red"
                                                : "",
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "con-a" }, [
                                            false
                                              ? _c("div", {
                                                  staticClass: "time-text",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      activity.opDetails
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "time-text",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  activity.opSiteDetails
                                                ),
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "time" }, [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(activity.time) +
                                                "\n                    "
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _c("div", { staticClass: "no-data-contact" }, [
            _vm._v("\n    暂无数据\n  "),
          ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            visible: _vm.show,
            modal: true,
            width: "800px",
            top: "90px",
            "close-on-click-modal": false,
            "destroy-on-close": true,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [_vm._v("\n      编辑联系人\n    ")]
          ),
          _vm._v(" "),
          _c(
            _vm.createContact,
            _vm._b(
              { tag: "component", on: { cancelPop: _vm.cancelPop } },
              "component",
              _vm.contactData,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }