<template>
  <div class="body" v-loading="loading">
    <div style="height: 520px;width: 90%;margin:0 auto;">
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="6">
          <span>选择模式</span>
        </el-col>
        <el-col :span="18">
          <el-radio-group v-model="choice">
            <el-radio label="1">单选</el-radio>
            <el-radio label="2" :disabled="isChange == 0 ? true : false"
              >多选</el-radio
            >
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24" style="margin-top: 20px;">
          <span>编辑具体分类项</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <span
            @click="addTag"
            class="cellBtn cell_add"
            style="background: url('../../assets/field_add.svg') no-repeat;"
            >添加</span
          >
          <span
            @click="rename"
            class="cellBtn cell_rename"
            style="background: url('../../assets/field_rename.svg') no-repeat;"
            >重命名</span
          >
          <span
            @click="moveup"
            class="cellBtn cell_up"
            style="background: url('../../assets/field_up.svg') no-repeat;"
            >上移</span
          >
          <span
            @click="movedown"
            class="cellBtn cell_down"
            style="background: url('../../assets/field_down.svg') no-repeat;"
            >下移</span
          >
          <span
            @click="del"
            class="cellBtn cell_del"
            style="background: url('../../assets/field_del.svg') no-repeat;"
            >删除</span
          >
        </el-col>
      </el-row>
      <el-row>
        <div class="operationBox">
          <el-scrollbar
            style="height: 260px;width: 100%"
            class="dt-dropdown-dt"
          >
            <el-col :span="24">
              <el-radio-group v-model="selected">
                <el-col :span="24">
                  <el-radio
                    label="DTSales线索"
                    v-if="mark == 1 && name == '来源'"
                    disabled
                    style="padding: 10px 0;"
                  ></el-radio>
                </el-col>
                <el-col :span="24">
                  <el-radio
                    label="微营销"
                    v-if="mark == 2 && name == '来源'"
                    disabled
                    style="padding: 10px 0;"
                  ></el-radio>
                </el-col>
                <el-col :span="24">
                  <el-radio
                    label="企业微信"
                    v-if="mark == 2 && name == '来源'"
                    disabled
                    style="padding: 10px 0;"
                  ></el-radio>
                </el-col>
                <draggable
                  v-model="tags"
                  @update="datadragEnd"
                  handle=".dargBtn"
                  filter=".undraggable"
                  :options="{ animation: 500 }"
                >
                  <template>
                    <el-col
                      :span="24"
                      v-for="(item, i) in tags"
                      class="radio-item"
                      :key="item.i"
                      v-show="!item.defaultable"
                    >
                      <el-radio
                        :label="item"
                        v-model="item.name"
                        :disabled="enable"
                        @change="chooseTag(i)"
                      >
                        <el-input
                          ref="input"
                          v-if="item.key == selected.key && enable"
                          @blur="outblur($event)"
                          v-model="item.name"
                          size="small"
                          placeholder="请输入内容"
                        ></el-input>
                        <span class="dargBtn" style="cursor: move" v-else>{{
                          item.name
                        }}</span>
                      </el-radio>
                    </el-col>
                  </template>
                </draggable>
              </el-radio-group>
            </el-col>
          </el-scrollbar>
        </div>
      </el-row>
      <div class="footer">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="saveInput"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { editField, checkField, getTagsAdd } from '@/api/user'
import draggable from 'vuedraggable'

export default {
  name: 'CreateCustomer',
  data() {
    return {
      name: '',
      mark: '',
      loading: false,
      selectLine: {},
      choice: null,
      tags: [],
      type: '',
      selected: {},
      key: 1,
      enable: false,
      selectTags: [],
      deleteTags: [],
      activeTabs: '',
      rules: {},
      showCheckRe: false,
      searchName: '',
      isChange: 0,
      oldValue: '',
      radioIndex: -1
    }
  },
  components: {
    draggable
  },
  mounted() {
    const modalOptions = this.$store.state.plugin.modal.options
    console.log(modalOptions, 3399)
    this.tags = modalOptions.row.tags
    this.name = modalOptions.row.name
    this.type = modalOptions.row.type
    this.mark = modalOptions.mark
    this.choice = modalOptions.row.choice.toString()
    this.isChange = modalOptions.row.isChange
    this.key = 1
    for (let i = 0; i < this.tags.length; i++, this.key++) {
      this.tags[i].key = this.key
    }
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    }
  },

  methods: {
    async checkField(id) {
      const params = {
        id: id
      }
      let res = await checkField(params)
      return res.msg
    },
    addTag() {
      if (this.enable) return
      this.selected = {
        name: '示例标签' + (Number(this.tags.length) + 1),
        key: ++this.key
      }
      this.tags.push(this.selected)
      this.enable = true
      this.$nextTick(() => {
        this.$refs.input[0].focus()
        this.oldValue = this.selected.name
      })
    },
    repeat() {
      let flag = true
      for (let i = 0; i < this.tags.length; i++) {
        for (let j = i + 1; j < this.tags.length; j++) {
          if (this.tags[i].name.trim() == this.tags[j].name.trim()) {
            flag = false
            break
          }
        }
      }
      return flag
    },
    outblur(e) {
      if (!this.repeat()) {
        this.$message({
          type: 'error',
          message: '该分类项已存在'
        })
        return
      }
      if (e.target.value.trim() == '') {
        this.selected.name = this.oldValue
      }
      this.enable = false
    },
    getdata(evt) {
      console.log(evt.draggedContext.element.text)
    },
    datadragEnd(evt) {
      evt.preventDefault()
      console.log('拖动前的索引 :' + evt.oldIndex)
      console.log('拖动后的索引 :' + evt.newIndex)
      console.log(this.tags)
    },
    moveup() {
      if (this.enable || !Object.keys(this.selected).length) {
        return
      }
      let index = this.tags.findIndex(i => i.key == this.selected.key)

      if (index != 0 && !this.tags[index - 1].defaultable) {
        this.tags[index] = this.tags.splice(index - 1, 1, this.tags[index])[0]
      }
    },
    movedown() {
      if (this.enable || !Object.keys(this.selected).length) {
        return
      }
      let index = this.tags.findIndex(i => i.key == this.selected.key)
      if (index < this.tags.length - 1) {
        this.tags[index] = this.tags.splice(index + 1, 1, this.tags[index])[0]
      }
    },
    async del() {
      if (!Object.keys(this.selected).length) {
        return
      }
      let that = this
      if (this.selected.hasOwnProperty('id')) {
        let bool = await this.checkField(this.selected.id)

        if (bool == 'false') {
          that.$message({
            type: 'error',
            message: '标签已在使用，不能删除'
          })
          return
        }
      }

      let index = that.tags.findIndex(i => i.key == that.selected.key)
      that.selected = {}
      that.enable = false
      if (that.tags[index].hasOwnProperty('id')) {
        that.deleteTags.push(that.tags[index])
      }
      that.tags.splice(index, 1)
    },
    rename() {
      if (this.enable) {
        if (!this.repeat()) {
          this.$message({
            type: 'error',
            message: '标签重复'
          })
          return
        }
      }
      if (Object.keys(this.selected).length) {
        this.enable = !this.enable
        this.$nextTick(() => {
          if (this.enable) this.$refs.input[0].focus()
          this.oldValue = this.selected.name
        })
      } else {
        this.enable = false
      }
    },
    chooseTag(i) {
      console.log(i, 'radioChange')
      // let index = this.tags.findIndex((i)=>i.name == e.id)
      this.enable = false
      this.radioIndex = i
    },
    // select关闭下拉框
    selectChange() {
      this.$refs['selectTag'].blur()
    },

    // 关闭弹框
    cancel() {
      this.$store.dispatch('hideModal')
    },

    // 是否允许编辑公司名
    disabled() {
      if (this.type === 'create') {
        return false
      }
      if (this.permissionList.includes('客户管理CRM-客户公海-客户名称编辑')) {
        return false
      }
      return true
    },

    // 查重
    checkName() {
      this.showCheckRe = true
    },

    // 提交保存内容
    saveInput() {
      if (!this.choice) {
        this.$message({
          type: 'error',
          message: '请选择模式'
        })
      }
      this.editField()
    },

    // 编辑客户
    editField() {
      this.loading = true
      let obj = {
        choice: this.choice,
        type: this.type,
        tags: this.tags,
        deleteTags: this.deleteTags
      }
      const params = {
        tagJson: encodeURIComponent(JSON.stringify(obj))
      }
      editField(params)
        .then(res => {
          this.loading = false
          if (res && res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })
            // 发送标志 刷新页面
            this.$store.commit('update', {
              target: this.$store.state.plugin.modal.options,
              data: {
                refresh: true
              }
            })
            this.$store.dispatch('hideModal')
          }
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch('showTip', {
            text: '编辑失败',
            type: 'success'
          })
        })
    }
  }
}
</script>
<style lang="scss">
.enterTags {
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0;
}
</style>
<style scoped>
.body {
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
}

.type-l {
  line-height: 100px;
}

.el-row {
  margin: 20px 0;
}

.operationBox {
  width: 100%;
  height: 300px;
  border: 1px solid #000;
  padding: 20px;
}

.radio-item {
  padding: 10px 0;
}

.cellBtn {
  margin: 20px;
  cursor: pointer;
  position: relative;
}

.cell_add::before {
  background: url('../../assets/field_add.svg') no-repeat;
  content: '';
  width: 15px;
  height: 15px;
  left: -15px;
  display: block;
  position: absolute;
  top: 0px;
  background-size: 100% 100%;
}

.cell_rename::before {
  background: url('../../assets/field_rename.svg') no-repeat;
  content: '';
  width: 15px;
  height: 15px;
  left: -15px;
  display: block;
  position: absolute;
  top: 0px;
  background-size: 100% 100%;
}

.cell_up::before {
  background: url('../../assets/field_up.svg') no-repeat;
  content: '';
  width: 15px;
  height: 15px;
  left: -15px;
  display: block;
  position: absolute;
  top: 0px;
  background-size: 100% 100%;
}

.cell_down::before {
  background: url('../../assets/field_down.svg') no-repeat;
  content: '';
  width: 15px;
  height: 15px;
  left: -15px;
  display: block;
  position: absolute;
  top: 0px;
  background-size: 100% 100%;
}

.cell_del::before {
  background: url('../../assets/field_del.svg') no-repeat;
  content: '';
  width: 15px;
  height: 15px;
  left: -15px;
  display: block;
  position: absolute;
  top: 0px;
  background-size: 100% 100%;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
