var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "main",
      staticClass: "task-list-main",
    },
    [
      _c("div", { staticClass: "header" }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "row", staticClass: "row" },
        [
          _c("span", { staticClass: "header-tip" }, [
            _vm._v("* 导出将会自动进行，您可以关闭此弹框"),
          ]),
          _vm._v(" "),
          _c("dt-table", {
            attrs: {
              "show-overflow-tooltip": false,
              "is-count-height": false,
              "dt-height": 550,
              "table-h": _vm.tableHeight,
              "table-data": _vm.tableData,
              "is-select": false,
              "data-total": _vm.count,
              "column-list": _vm.columnList,
              "hide-border": true,
              "page-size": [10],
              "column-width-list": { downloadName: 300 },
            },
            on: {
              sizeChange: _vm.sizeChange,
              currentChange: _vm.currentChange,
            },
            scopedSlots: _vm._u([
              {
                key: "setTingRow",
                fn: function (row) {
                  return [
                    _c("span", [
                      row.scope.data.downloadStatus === 1 &&
                      row.scope.data.downloadUrl
                        ? _c("span", { staticClass: "success" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: row.scope.data.downloadUrl,
                                  download: "download",
                                },
                              },
                              [_vm._v("\n              下载文件\n            ")]
                            ),
                          ])
                        : _c("span", { staticClass: "error" }, [
                            _vm._v("\n            --\n          "),
                          ]),
                    ]),
                  ]
                },
              },
              {
                key: "downloadPercentRow",
                fn: function (row) {
                  return [
                    row.scope.data.downloadStatus === 0
                      ? _c(
                          "span",
                          [
                            _c("el-progress", {
                              staticClass: "layui-progress-bar",
                              attrs: {
                                percentage: row.scope.data.downloadPercent,
                                "stroke-width": 8,
                              },
                            }),
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                row.scope.data.downloadStatus === -1
                                  ? "导出失败"
                                  : "导出成功"
                              ) +
                              "\n        "
                          ),
                        ]),
                  ]
                },
              },
              {
                key: "typeRow",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(row.scope.data.type === 1 ? "培育池" : "") +
                          "\n          " +
                          _vm._s(row.scope.data.type === 2 ? "客户公海" : "") +
                          "\n          " +
                          _vm._s(row.scope.data.type === 3 ? "通话记录" : "") +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }