
<template>
  <div class="keyNoGet-main">
      <div class="keyNoGet-main-dialog">
          <span>
            只能添加已领取的线索。<el-checkbox v-model="checked">一键领取</el-checkbox>
          </span>
      </div>
      <div class="footer">
        <el-button size="small" @click="cancelBtn">取消</el-button>
        <el-button size="small" type="primary" @click="confirmBtn">确认</el-button>
      </div>
  </div>
</template>

<script>
export default {
  name: 'keyNoGet',
  data () {
    return {
      checked: false
    }
  },
  mounted () {},
  computed: {},
  methods: {
    cancelBtn(){
      this.$store.dispatch('hideModal')
    },
    confirmBtn(){
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          checked: true,
          isNext: true
        }
      })
      this.cancelBtn()
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.keyNoGet-main-dialog{
  height: 80px;
  width: 100%;
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  padding: 20px;
  box-sizing: border-box;
}
.footer{
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding-right: 10px;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,.1);
  transform: scaleY(.5);
  position: absolute;
  top: 0;
  left: 0;
}
</style>
