var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chain-map-page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "brand-map",
        attrs: { id: "brandMap", "element-loading-text": "加载中" },
      },
      [
        _c("infoWindow", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.infoWindow,
              expression: "infoWindow",
            },
          ],
          ref: "info",
          attrs: { pointType: _vm.pointType, "info-data": _vm.infoWindowData },
          on: { handleCloseQrCode: _vm.handleCloseQrCode },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }