var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "createFollowRecord-main" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            "label-width": "auto",
            rules: _vm.rule,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "followTime", label: "跟进时间" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "datetime",
                  placeholder: "选择日期时间",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "picker-options": _vm.pickerOptions,
                },
                model: {
                  value: _vm.formData.followTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "followTime", $$v)
                  },
                  expression: "formData.followTime",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "followType", label: "跟进方式" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择跟进方式" },
                  model: {
                    value: _vm.formData.followType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "followType", $$v)
                    },
                    expression: "formData.followType",
                  },
                },
                _vm._l(_vm.followTypeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "companyName", label: "跟进客户" } },
            [
              _c(
                "div",
                { staticClass: "mask", on: { click: _vm.selectCompanyClick } },
                [_c("span", [_vm._v("\n          选择\n        ")])]
              ),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "请选择跟进客户" },
                model: {
                  value: _vm.formData.companyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "companyName", $$v)
                  },
                  expression: "formData.companyName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "followUser", label: "跟进对象" } },
            [
              _c(
                "div",
                {
                  staticClass: "mask mask-user",
                  on: { click: _vm.selectUser },
                },
                [
                  _c(
                    "span",
                    { staticClass: "select-tag" },
                    _vm._l(_vm.selectUserList, function (item) {
                      return _c(
                        "el-tag",
                        {
                          staticClass: "tag",
                          attrs: { closable: "", size: "mini" },
                          on: {
                            close: function ($event) {
                              return _vm.editSelectUser(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.contact))]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("\n          选择\n        ")]),
                ]
              ),
              _vm._v(" "),
              _c("el-input", {
                attrs: {
                  placeholder: _vm.selectUserList.length
                    ? ""
                    : "请选择跟进对象",
                },
                model: {
                  value: _vm.formData.followUser,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "followUser", $$v)
                  },
                  expression: "formData.followUser",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "content", label: "跟进记录" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 5, maxRows: 6 },
                  placeholder: "请输入跟进记录",
                },
                model: {
                  value: _vm.formData.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "content", $$v)
                  },
                  expression: "formData.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelFr } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.confirmFr },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            width: "800px",
            top: "90px",
            visible: _vm.showSelectCompany,
            "show-close": false,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectCompany = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slot-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v("\n        选择客户\n      ")]),
              _vm._v(" "),
              _c("div", { on: { click: _vm.cancelCompany } }, [
                _c("i", { staticClass: "el-icon-close close" }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "company-list" }, [
            _c(
              "div",
              { staticClass: "tab-div" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "dt-tab",
                    on: { "tab-click": _vm.tabClick },
                    model: {
                      value: _vm.tabType,
                      callback: function ($$v) {
                        _vm.tabType = $$v
                      },
                      expression: "tabType",
                    },
                  },
                  [
                    _vm.permissionList.includes(
                      "客户管理CRM-客户公海-我负责的-填写跟进记录"
                    )
                      ? _c("el-tab-pane", {
                          attrs: { label: "我负责的", name: "1" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.permissionList.includes(
                      "客户管理CRM-客户公海-全公司的-填写跟进记录"
                    )
                      ? _c("el-tab-pane", {
                          attrs: { label: "全公司的", name: "3" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.permissionList.includes(
                      "客户管理CRM-客户公海-可跟进的-填写跟进记录"
                    )
                      ? _c("el-tab-pane", {
                          attrs: { label: "可跟进的", name: "2" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.permissionList.includes(
                      "客户管理CRM-客户公海-草稿箱-填写跟进记录"
                    )
                      ? _c("el-tab-pane", {
                          attrs: { label: "草稿箱", name: "4" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "search-input" }, [
              _c(
                "div",
                { staticStyle: { width: "630px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入公司查询", size: "small" },
                    model: {
                      value: _vm.companyName,
                      callback: function ($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchCompany },
                    },
                    [_vm._v("\n            查询\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.reset } },
                    [_vm._v(" 重置 ")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.selectCompany.companyName
              ? _c(
                  "div",
                  { staticClass: "tip" },
                  [
                    _c(
                      "el-tag",
                      {
                        attrs: { size: "small", closable: "" },
                        on: { close: _vm.delCompany },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.selectCompany.companyName) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table" },
              [
                _c("dt-table", {
                  key: _vm.refreshKey,
                  attrs: {
                    "data-total": _vm.pageStatus.total,
                    "hide-border": true,
                    "is-add-earn-field": false,
                    "is-count-height": false,
                    "shift-field": _vm.shiftField,
                    "column-width-list": { select: 50 },
                    "table-h": "400px",
                    "is-select": false,
                    "column-list": _vm.columns,
                    "table-loading": _vm.loading,
                    "table-data": _vm.companyList,
                  },
                  on: {
                    sizeChange: _vm.sizeChange,
                    currentChange: _vm.currentChange,
                    singleSelectChange: _vm.singleSelectChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "selectRow",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            [
                              _c("el-checkbox", {
                                attrs: {
                                  value:
                                    row.scope.data.id === _vm.selectCompany.id,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "leaderHeader",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            [
                              _c(
                                "el-dropdown",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    trigger: "click",
                                  },
                                  on: {
                                    command: (val) =>
                                      _vm.filterFunc(val, "leader"),
                                  },
                                },
                                [
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-dropdown-link",
                                        class: _vm.userId ? "active" : "",
                                      },
                                      [
                                        _c("span", [_vm._v(" 负责人 ")]),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-scrollbar",
                                          { staticClass: "dt-dropdown-dt" },
                                          _vm._l(
                                            _vm.userList,
                                            function (_item) {
                                              return _c(
                                                "el-dropdown-item",
                                                {
                                                  class:
                                                    _vm.userId === _item.id
                                                      ? "active"
                                                      : "",
                                                  attrs: { command: _item.id },
                                                },
                                                [_vm._v(_vm._s(_item.name))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "footer",
                staticStyle: { "padding-right": "20px" },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: { click: _vm.cancelCompany },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.confirmCompany },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            width: "850px",
            top: "90px",
            visible: _vm.showSelectUser,
            "show-close": false,
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectUser = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slot-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v("\n        选择签单决策人\n      ")]),
              _vm._v(" "),
              _c("div", { on: { click: _vm.cancelSelectUser } }, [
                _c("i", { staticClass: "el-icon-close close" }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-list" },
            [
              _c("selectBillKeyPersion", {
                key: _vm.refreshSelectKey,
                attrs: {
                  "company-name": _vm.selectCompany.companyName,
                  "is-active-tab": _vm.isActiveTab,
                  "current-check-row-prop": _vm.selectUserList,
                  "package-id": _vm.selectCompany.packageId,
                  "company-uuid": _vm.selectCompany.companyUuid,
                },
                on: { closeDialog: _vm.closeDialog, getRes: _vm.getSelectUser },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }