var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editSupply-main" }, [
    _c(
      "div",
      { staticClass: "main-row" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.formData,
              rules: _vm.rules,
              "label-width": "auto",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "编号", prop: "sysNum" } },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "供应商编号（留空系统自动生成）",
                    disabled: _vm.type === "edit",
                  },
                  model: {
                    value: _vm.formData.sysNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "sysNum", $$v)
                    },
                    expression: "formData.sysNum",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "名称", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入供应商名称" },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "通讯地址", prop: "address" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入供应商通讯地址" },
                  model: {
                    value: _vm.formData.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "address", $$v)
                    },
                    expression: "formData.address",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "联系人", prop: "contact" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入供应商联系人" },
                  model: {
                    value: _vm.formData.contact,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "contact", $$v)
                    },
                    expression: "formData.contact",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "联系人电话", prop: "phone" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入供应商联系人电话" },
                  model: {
                    value: _vm.formData.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "phone", $$v)
                    },
                    expression: "formData.phone",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "采购主体", prop: "content" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入我方采购主体" },
                  model: {
                    value: _vm.formData.content,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "content", $$v)
                    },
                    expression: "formData.content",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "采购负责人", prop: "userId" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请选择我方采购负责人",
                      filterable: "",
                    },
                    model: {
                      value: _vm.formData.userId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "userId", $$v)
                      },
                      expression: "formData.userId",
                    },
                  },
                  _vm._l(_vm.pdaList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "备注", prop: "remark" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入备注",
                    maxlength: "100",
                    "show-word-limit": "",
                    autosize: { minRows: 4, maxRows: 10 },
                  },
                  model: {
                    value: _vm.formData.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "remark", $$v)
                    },
                    expression: "formData.remark",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
          [_vm._v("取消")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.confirmBtn },
          },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }