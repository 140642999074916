var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "company-introduce" },
    [
      _c("p", { staticClass: "company-title" }, [
        _vm._v("欢迎使用领鸟云AI SaaS"),
      ]),
      _vm._v(" "),
      _c("h5", [_vm._v("公司简介")]),
      _vm._v(" "),
      _c("p", { staticClass: "desc" }, [
        _vm._v(
          "\n    领鸟云，是上海领鸟信息科技有限公司旗下AI\n    SaaS品牌，为B2B企业、行业组织、政府决策部门提供智能决策、行业洞察、智能营销、智能销售工具服务，帮助行业和企业解决增长和提效难题。\n  "
        ),
      ]),
      _vm._v(" "),
      _c("h5", [_vm._v("发展历程")]),
      _vm._v(" "),
      _vm._l(_vm.transferList, function (item, index) {
        return _c(
          "el-timeline",
          { key: index },
          [
            _c(
              "el-timeline-item",
              { attrs: { placement: "top", timestamp: item.time } },
              [_c("el-card", [_c("p", [_vm._v(_vm._s(item.txt))])])],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }