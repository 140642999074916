import store from '@/store'
import Vue from 'vue'
import * as dd from 'dingtalk-jsapi'
import cookie from 'js-cookie'
if (navigator.userAgent.toLowerCase().indexOf('dingtalk') > -1) {
  store.commit('update', {
    target: store.state.plugin,
    data: {
      env: 'dd'
    }
  })
  Vue.prototype.$dd = dd
  try {
    const url = window.location.hash.split('?')[1]
    if (
      url.indexOf('corpId') >= 0 &&
      getQueryVariable('corpId') !== localStorage.getItem('corpId')
    ) {
      cookie.remove('token')
    }
  } catch (e) {}
}

function getQueryVariable(variable) {
  let query = location.hash.split('?')[1]
  let vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return ''
}
