var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "auto-send",
    },
    [
      _c(
        "div",
        { staticClass: "creat-btn" },
        [
          false
            ? _c("el-button", { attrs: { size: "small", type: "primary" } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.list.length,
                  expression: "list.length",
                },
              ],
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.createSendTarget },
            },
            [_vm._v("\n      新建转派任务\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-scrollbar", { staticClass: "send-row dt-dropdown-dt" }, [
        !_vm.list.length
          ? _c("div", { staticClass: "no-list" }, [
              _c("span", [
                _c("span", { staticClass: "no-data-tip" }, [
                  _vm._v("暂无转派任务"),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "new-task-tip",
                    on: { click: _vm.createSendTarget },
                  },
                  [_vm._v("点击新建")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.load,
                expression: "load",
              },
            ],
          },
          _vm._l(_vm.list, function (item) {
            return _c(
              "div",
              { key: item.id, staticClass: "item" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20, type: "flex", align: "middle" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-row",
                          { staticClass: "row", attrs: { gutter: 20 } },
                          _vm._l(_vm.columns, function (col, key) {
                            return _c(
                              "el-col",
                              {
                                key: key,
                                staticClass: "col",
                                attrs: { span: col.span },
                              },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          span: col.span === 24 ? 2 : 4,
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v(_vm._s(col.label)),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          span: col.span === 24 ? 22 : 20,
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "value" }, [
                                          _vm._v(_vm._s(item[key])),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-row",
                          { attrs: { justify: "center", type: "flex" } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    "user-select": "none",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeSwitch(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: { value: item.onOff === "on" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("div", { staticClass: "setting" }, [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.editTask(item)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c("span", { staticClass: "line" }, [
                                  _vm._v("|"),
                                ]),
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeTask(item)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                                _c("span", [_vm._v("|")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            "show-close": false,
            "append-to-body": "",
            visible: _vm.showCreate,
            "close-on-click-modal": false,
            "destroy-on-close": "",
            top: "90px",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCreate = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [
              _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.type === "create" ? "新建转派任务" : "编辑转派任务"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-close",
                on: { click: _vm.cancelBtn },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "create-row",
              attrs: { "loading-text": "正在新建转派任务" },
            },
            [
              _c(
                "el-scrollbar",
                { staticClass: "form-row dt-dropdown-dt" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticClass: "form-el",
                      attrs: {
                        model: _vm.formData,
                        "label-width": "60px",
                        "hide-required-asterisk": "",
                        "label-position": "left",
                      },
                    },
                    [
                      _vm._l(_vm.formList, function (item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: index,
                            attrs: {
                              label: item.label,
                              prop: index,
                              rules: item.rule,
                            },
                          },
                          [
                            item.type === "input"
                              ? [
                                  _c("el-input", {
                                    attrs: { placeholder: item.tip },
                                    model: {
                                      value: _vm.formData[index],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          index,
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "formData[index]",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "select"
                              ? [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        multiple: item.selectMore,
                                        "collapse-tags": "",
                                        placeholder: item.tip,
                                      },
                                      on: {
                                        change: (e) =>
                                          _vm.selectChange(item, index, e),
                                      },
                                      model: {
                                        value: _vm.formData[index],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, index, $$v)
                                        },
                                        expression: "formData[index]",
                                      },
                                    },
                                    _vm._l(item.options, function (_item) {
                                      return _c("el-option", {
                                        key: _item.value,
                                        attrs: {
                                          label: _item.name,
                                          value: _item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            item.type === "checkbox"
                              ? [
                                  index === "assignPackIds"
                                    ? [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: _vm.assignPackIds,
                                              callback: function ($$v) {
                                                _vm.assignPackIds = $$v
                                              },
                                              expression: "assignPackIds",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "-1" } },
                                              [_vm._v("全部")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "2" } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.assignPackIds === "2",
                                                expression:
                                                  "assignPackIds === '2'",
                                              },
                                            ],
                                            staticClass: "pack-list",
                                          },
                                          [
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 10 } },
                                              _vm._l(
                                                item.options,
                                                function (_item) {
                                                  return _c(
                                                    "el-col",
                                                    {
                                                      key: _item.value,
                                                      attrs: { span: 12 },
                                                    },
                                                    [
                                                      _c(
                                                        "el-checkbox",
                                                        {
                                                          staticClass:
                                                            "checkbox-dt-dt",
                                                          attrs: {
                                                            value:
                                                              _vm.formData.assignPackIds.includes(
                                                                _item.value
                                                              ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeAssignPackIds(
                                                                _item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _item.name
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  index === "assignUserIds"
                                    ? [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: _vm.assignUserIds,
                                              callback: function ($$v) {
                                                _vm.assignUserIds = $$v
                                              },
                                              expression: "assignUserIds",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "-1" } },
                                              [_vm._v("全部")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "2" } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.assignUserIds === "2",
                                                expression:
                                                  "assignUserIds === '2'",
                                              },
                                            ],
                                            staticClass: "pack-list",
                                          },
                                          [
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 10 } },
                                              _vm._l(
                                                item.options,
                                                function (_item) {
                                                  return _c(
                                                    "el-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "el-checkbox",
                                                        {
                                                          staticClass:
                                                            "checkbox-dt-dt",
                                                          attrs: {
                                                            title: _item.name,
                                                            value:
                                                              _vm.formData.assignUserIds.includes(
                                                                _item.value
                                                              ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeAssignUserIds(
                                                                _item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatName"
                                                                )(_item.name)
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _vm.formData.expireStr === 2
                        ? _c(
                            "el-form-item",
                            {
                              ref: "scrollObj",
                              attrs: {
                                label: "截止",
                                prop: "_expireStr",
                                rules: [
                                  {
                                    required: true,
                                    message: "请设置自定义有效期",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  editable: false,
                                  type: "date",
                                  "picker-options": _vm.startTime,
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请设置自定义有效期",
                                },
                                model: {
                                  value: _vm.formData._expireStr,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "_expireStr", $$v)
                                  },
                                  expression: "formData._expireStr",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.confirmBtn },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }