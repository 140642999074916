var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-store-page", style: _vm.getStoreStyle },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "storeRef",
          staticClass: "store-map",
          attrs: { id: "storeMap", "element-loading-text": "加载中" },
        },
        [
          _c("infoWindow", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.infoWindow,
                expression: "infoWindow",
              },
            ],
            ref: "info",
            attrs: { "info-data": _vm.infoWindowData },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("FullScreen", { attrs: { refDom: _vm.$refs.storeRef } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }