<template>
  <div class="extendApp-main">
    <div class="no-access" v-if="!access">
      <div class="chong-zhi" @click="addCharge">
        <el-button type="primary"> 查询点数为0,请充值</el-button>
      </div>
    </div>
    <div class="main">
      <div class="base-item yun-history">
        <div class="cloud-wrap">
          <div class="title">历史云服务商</div>
          <div class="c-name">
            <span
              @click="init(true)"
              class="refresh el-icon-refresh-right"
            ></span>
            <span @click="init(true)" class="refresh-text">刷新</span>
          </div>
        </div>
        <div class="img-wrap" v-if="logoList.length">
          <div
            class="tool-item"
            v-for="(item, index) in logoList"
            :key="index"
            :style="
              item.isNew == 0
                ? 'filter: grayscale(100%);border:1px dashed #ccc;opacity:0.5'
                : 'border: 2px solid #41cabf;'
            "
          >
            <PeTool>
              <template slot="txt-content">
                <div v-if="item.isNew == 1">
                  当前合作服务商:{{ item.gongyingshang }}
                </div>
                <div v-else>已停止合作服务商:{{ item.gongyingshang }}</div>
              </template>
              <template slot="txt-btn">
                <div :class="item.logo ? 'img-desc' : 'word-desc'">
                  <img v-if="item.logo" :src="item.logo" />
                  <div v-else class="gys">{{ item.gongyingshang }}</div>
                </div>
              </template>
            </PeTool>
          </div>
        </div>
        <div
          style="height: 320px;overflow-y: auto"
          v-loading="loadingYunService"
          element-loading-text="加载中"
        >
          <yunServiceHistoryList :service-data="yunServiceList" />
        </div>
      </div>

      <div class="base-item domain-search">
        <PeDialog
          :pe-dialog-show="mainShow"
          pe-dialog-width="600px"
          @handlePeDialogClose="mainShow = false"
          :pe-dialog-custom="true"
        >
          <div class="panel-wrap">
            <div class="top">
              <span>上次刷新时间{{ updateTime }}</span>
              <el-button size="mini" @click="handleRefresh">刷新域名</el-button>
            </div>
            <div class="list">
              <div
                class="item"
                v-for="(item, index) in domainList"
                :key="index"
                @click="handleSure(item.domainWww)"
              >
                <span>{{ item.domainWww }}</span>
                <span
                  >备案审核时间:{{
                    item.beianTime ? item.beianTime : '未知'
                  }}</span
                >
                <i
                  v-if="item.access == 1"
                  class="el-icon-success"
                  style="color:#41cabf"
                />
                <i v-else class="el-icon-error" style="color:#f00" />
              </div>
            </div>
          </div>
        </PeDialog>
        <div>
          <el-button @click="mainShow = true" type="primary"
            >域名查询</el-button
          >
          <div
            class="base--item yun-service"
            data-title="云服务商"
            v-loading="yunLoading"
            element-loading-text="加载中"
          >
            <div class="filter">
              <div>运营商:</div>
              <div
                class="filter-item"
                :class="yunType === '全部' ? 'active' : ''"
                @click="yunType = '全部'"
              >
                全部
              </div>
              <div
                class="filter-item"
                :class="yunType === '电信' ? 'active' : ''"
                @click="yunType = '电信'"
              >
                电信
              </div>
              <div
                class="filter-item"
                :class="yunType === '移动' ? 'active' : ''"
                @click="yunType = '移动'"
              >
                移动
              </div>
              <div
                class="filter-item"
                :class="yunType === '联通' ? 'active' : ''"
                @click="yunType = '联通'"
              >
                联通
              </div>
            </div>
            <el-table :data="yunFilterList" border size="small">
              <el-table-column
                header-align="center"
                align="center"
                show-tooltip-when-overflow
                v-for="item in columns"
                :label="item.name"
                :prop="item.prop"
              >
                <template slot-scope="scope">
                  <span v-if="item.prop === 'checkPoint'">
                    {{ `${scope.row.checkPoint} [${scope.row.type}]` }}
                  </span>
                  <span
                    v-else-if="item.prop === 'setting'"
                    class="setting"
                    @click="jump(scope.row.domain)"
                  >
                    专业版域名查询
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            class="base--item yun-service"
            data-title="CDN服务商"
            v-loading="cdnLoading"
            element-loading-text="加载中"
          >
            <div class="filter">
              <div>运营商:</div>
              <div
                class="filter-item"
                :class="cdnType === '全部' ? 'active' : ''"
                @click="cdnType = '全部'"
              >
                全部
              </div>
              <div
                class="filter-item"
                :class="cdnType === '电信' ? 'active' : ''"
                @click="cdnType = '电信'"
              >
                电信
              </div>
              <div
                class="filter-item"
                :class="cdnType === '移动' ? 'active' : ''"
                @click="cdnType = '移动'"
              >
                移动
              </div>
              <div
                class="filter-item"
                :class="cdnType === '联通' ? 'active' : ''"
                @click="cdnType = '联通'"
              >
                联通
              </div>
            </div>
            <el-table :data="cdnFilterList" border size="small">
              <el-table-column
                header-align="center"
                align="center"
                show-tooltip-when-overflow
                v-for="item in columns"
                :label="item.name"
                :prop="item.prop"
              >
                <template slot-scope="scope">
                  <span v-if="item.prop === 'checkPoint'">
                    {{ `${scope.row.checkPoint} [${scope.row.type}]` }}
                  </span>
                  <span
                    v-else-if="item.prop === 'setting'"
                    class="setting"
                    @click="jump(scope.row.domain)"
                  >
                    专业版域名查询
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <div class="base-item yun-history">
        <div class="title">母子公司上云图谱</div>
        <div style="height: 680px">
          <graph type="2" ref="parent" />
        </div>
      </div>

      <div class="base-item yun-history">
        <div class="title">客户及供应商上云图谱</div>
        <div style="height: 680px">
          <graph type="1" ref="customer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PeDialog from '@/components/Peculiar/dialog'
import { formatRegex } from '@/utils/helper'
import yunServiceHistoryList from '@/views/mykey/dialog/yunServiceHistoryList'
import graph from '@/views/mykey/dialog/graph'
import PeTool from '@/components/Peculiar/tool-tip'
import {
  getCdnService,
  getEarnNumPoint,
  getProvider,
  getYunService,
  yunServiceHistory
} from '@/api/customerpond'
import { refreshIdc } from '@/api/thread'
import recharge from '@/views/extendApp/dialog/recharge'
import { getCloudLogo, getNewCloudList, getDomainRefresh } from '@/api/module'
export default {
  name: 'extendApp',
  props: {
    proDetail: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    yunServiceHistoryList,
    graph,
    PeDialog,
    PeTool
  },
  watch: {
    proDetail: {
      handler() {
        this.init()
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      updateTime: '',
      logoList: [],
      loadingYunService: false,
      mainShow: false,
      yunServiceList: [],
      domainList: [],
      domain: '',
      yunLoading: false,
      cdnLoading: false,
      yunType: '全部',
      cdnType: '全部',
      yunList: [],
      cdnList: [],
      columns: [
        {
          name: '监测对象',
          prop: 'domain'
        },
        {
          name: '监测点',
          prop: 'checkPoint'
        },
        {
          name: '域名响应IP',
          prop: 'responseIp'
        },
        {
          name: '域名响应IP所在地',
          prop: 'responseAddress'
        },
        {
          name: '服务商',
          prop: 'serviceProvider'
        },
        {
          name: '操作',
          prop: 'setting'
        }
      ],
      descList: [
        {
          name: '营收指数',
          key: 'income_index',
          icon: 'open_in'
        },
        {
          name: '热度',
          key: 'hot',
          icon: 'rdd-icon'
        },
        {
          name: '活跃度',
          key: 'activity_class',
          icon: 'hyd-icon'
        },
        {
          name: '云服务商',
          key: 'kpProvider',
          icon: 'yy-icon'
        },
        {
          name: 'CDN服务商',
          key: 'cdn',
          icon: 'cdnn-icon'
        },
        {
          name: '邮件服务商',
          key: 'companyemail_mail_gongyingshang',
          icon: 'yjj-icon'
        }
      ],
      remainPoint: '',
      access: true,
      kpProvider: ''
    }
  },
  computed: {
    companyUuid() {
      return this.$route.query.companyUuid
    },

    companyName() {
      return this.proDetail.company_name
    },

    cdnFilterList() {
      if (this.cdnType !== '全部') {
        return this.cdnList.filter(i => i.type === this.cdnType)
      }
      return this.cdnList
    },

    yunFilterList() {
      if (this.yunType !== '全部') {
        return this.yunList.filter(i => i.type === this.yunType)
      }
      return this.yunList
    },

    inComeIndex() {
      const res = this.proDetail['income_index'].split(',').join('') * 1
      console.log(res, 444)
      const unit = Math.pow(10, 8)
      if (res >= unit) {
        return formatRegex((res / unit).toFixed(0)) + '亿'
      } else {
        return formatRegex(res)
      }
    }
  },
  methods: {
    async handleRefresh() {
      const data = await getDomainRefresh({
        companyUuid: this.companyUuid,
        companyName: this.companyName
      })
      if (data.code == 60000) {
        this.$message.success('刷新任务正在启动中....')
      }
      console.log(123)
    },
    handleSure(domain) {
      this.domain = domain
      // console.log(e.split(' '))
      this.getCdnService()
      this.getYunService()
      this.mainShow = false
    },
    async getLogoList() {
      const { data } = await getCloudLogo({
        companyUuid: this.companyUuid
      })
      this.logoList = data || []
      console.log(data, 'logo')
    },
    async init(refresh = false) {
      if (!this.access) {
        await this.$store.dispatch('showTip', {
          text: '没有点数了， 快去充值吧',
          type: 'error'
        })
        return
      }
      await this.getEarnNum(false)
      await this.getDomainList()
      await this.getHistoryYunServiceList(refresh)
      this.getYunService()
      this.getCdnService()
      this.getGraph()
      this.getYuProvider()
      this.getLogoList()
    },
    getYuProvider() {
      getProvider({
        companyName: this.companyName,
        companyUuid: this.companyUuid
      })
        .then(res => {
          if (res.code === 60000) {
            console.log(res, 9988)
            this.kpProvider = res.data.split(',')[0]
            this.proDetail.cdn = res.data.split(',')[1]
          } else {
            this.kpProvider = ''
          }
        })
        .catch(() => {
          this.kpProvider = ''
        })
    },
    getGraph() {
      this.$refs.customer?.init()
      this.$refs.parent?.init()
    },
    async getHistoryYunServiceList(refresh = false) {
      if (!this.access) {
        await this.$store.dispatch('showTip', {
          text: '没有点数了， 快去充值吧',
          type: 'error'
        })
        return
      }
      this.loadingYunService = true
      if (refresh) {
        await this.startRefreshIdc()
      }
      yunServiceHistory({ companyUuid: this.companyUuid }).then(res => {
        this.loadingYunService = false
        if (res.code === 60000) {
          this.yunServiceList = res.data
        }
      })
    },
    startRefreshIdc() {
      return new Promise((resolve, reject) => {
        const params = {
          companyName: this.companyName,
          companyUuid: this.companyUuid
        }
        refreshIdc(params)
          .then(res => {
            resolve()
          })
          .catch(() => resolve())
      })
    },
    getDomainList() {
      getNewCloudList({ companyUuid: this.companyUuid }).then(res => {
        if (res.code === 60000) {
          console.log(res, 'domainList')
          this.updateTime = res?.data?.lastRefreshTime
          this.domainList = res?.data?.list || []
          // this.domain = this.domainList?.[0]?.domain
        }
      })
    },
    getYunService() {
      if (!this.access) {
        this.$store.dispatch('showTip', {
          text: '没有点数了， 快去充值吧',
          type: 'error'
        })
        return
      }
      if (!this.domain) return
      this.yunLoading = true
      getYunService({ url: this.domain }).then(res => {
        this.yunLoading = false
        if (res.code === 60000) {
          this.yunList = res?.data || []
        } else {
          this.yunList = []
        }
      })
    },
    getCdnService() {
      if (!this.access) {
        this.$store.dispatch('showTip', {
          text: '没有点数了， 快去充值吧',
          type: 'error'
        })
        return
      }
      if (!this.domain) return
      this.cdnLoading = true
      getCdnService({ url: this.domain }).then(res => {
        if (res.code === 60000) {
          this.cdnLoading = false
          this.cdnList = res?.data || []
        } else {
          this.cdnList = []
        }
      })
    },
    jump(domain) {
      const { href } = this.$router.resolve({
        path: '/app/domain',
        query: { domain }
      })
      window.open(href, '_blank')
    },
    addCharge() {
      this.$store.dispatch('showModal', {
        title: '充值',
        view: recharge,
        size: '600px',
        onClose: res => {
          if (res.refresh) this.getEarnNum(false)
        }
      })
    },
    getEarnNum(deduct = true) {
      return new Promise((resolve, reject) => {
        getEarnNumPoint({ deduct }).then(res => {
          if (res.code === 60000) {
            this.remainPoint = res?.data?.remainPoint + ''
            this.access = res?.data?.access || false
            resolve()
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.extendApp-main {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  .no-access {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50px, -50px);
    z-index: 99;
  }
  .check {
    margin-top: 50px;
  }
  .panel-wrap {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .list {
      .item {
        margin: 10px 0;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          color: #41cabf;
        }
      }
    }
  }
  .img-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0;
    font-size: 14px;
    padding: 5px;
    .tool-item {
      width: 20%;
      height: 30px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .img-desc {
      width: 20%;
      height: 30px;
      position: relative;
    }
    img {
      height: 60%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .gys {
      color: #41cabf;
    }
  }
  .header {
    .title {
      user-select: none;
      height: 40px;
      position: relative;
      background-color: #abe3e3;
      & > div {
        height: 100%;
        position: absolute;
        top: 0;
      }
      .left-1 {
        left: 0;
        background-color: #00aaaa;
        width: 80px;
        color: #ffffff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .left-2 {
        left: 80px;
        display: flex;
        font-size: 16px;
        align-items: center;
        padding-left: 20px;
      }
      .right-2 {
        right: 0;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & > span {
          cursor: pointer;
          font-weight: bold;
          background-color: orange;
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          font-size: 12px;
          color: #ffffff;
        }
      }
      .right-1 {
        right: 110px;
        display: flex;
        align-items: center;
        color: #8a8a8a;
        font-size: 14px;
      }
    }
  }
  .main {
    height: calc(100% - 40px);
    width: 100%;
    .cloud-wrap {
      display: flex;
      align-items: center;
    }
    .c-name {
      font-size: 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .refresh {
        font-size: 20px;
        color: #41cabf;
        margin-left: 10px;
        cursor: pointer;
      }
      .refresh-text {
        color: #41cabf;
        font-size: 12px;
        margin-left: 5px;
        cursor: pointer;
        user-select: none;
      }
    }
    .desc-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 10px;
      & > div {
        padding: 5px;
        box-sizing: border-box;
        width: calc((100% - 40px) / 3);
        height: 50px;
        border: #32a7f0 solid 1px;
        border-radius: 3px;
        display: flex;
        margin-bottom: 10px;
        margin-right: 10px;
        .left {
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          color: #32a7f0;
        }
        .right {
          width: calc(100% - 35px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 5px;
          color: #8a8a8a;
          font-size: 12px;
          & > div:last-child {
            margin-top: 3px;
            font-weight: bold;
            color: #333333;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .base-item {
      padding: 10px;
      background: #fff;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      margin-top: 10px;
      .title {
        display: flex;
        align-items: center;
        color: #2e2e2e;
      }

      .base--item {
        padding: 50px 0 10px 0;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 20px;
        font-size: 12px !important;
        & > .item:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .item {
          display: flex;
          text-align: center;
          color: #8a8a8a;
          height: 40px;
          line-height: 40px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: none;
          font-size: 12px;
          & > div:first-child {
            width: 30%;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          }
          & > div:last-child {
            width: 70%;
          }
        }
        &::before {
          content: attr(data-title);
          display: inline-block;
          width: 100%;
          height: 50px;
          position: absolute;
          left: 0;
          top: 0;
          line-height: 50px;
          font-size: 16px;
        }
      }
      .yun-service {
        .filter {
          display: flex;
          margin-bottom: 20px;
          & > div {
            margin-right: 10px;
            font-size: 12px;
            user-select: none;
          }
          .filter-item {
            cursor: pointer;
            color: #8a8a8a;
          }
          .active {
            color: #41cabf;
            text-decoration: underline;
          }
        }
        .setting {
          color: #41cabf;
          cursor: pointer;
          user-select: none;
          text-decoration: underline;
        }
      }
    }
    .domain-search {
      .title {
        .name {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
