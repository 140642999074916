<template>
  <div class="breed" v-if="refresh">
    <div class="search-header">
      <div class="search">
        <el-input
          size="small"
          placeholder="输入号码\公司名查询"
          v-model.trim="search"
        ></el-input>
        <el-button
          class="btn-search"
          size="small"
          type="primary"
          @click="reSearch"
        >
          搜索
        </el-button>
        <el-button size="small" type="info" @click="reset">
          重置
        </el-button>
        <div class="search-senior" @click="openHighCond">高级筛选</div>
        <div class="filtrate-body" @click="openHighCond">
          <svg-icon icon-class="filtrate" class="filtrate" />
        </div>
      </div>
      <div class="add-btn">
        <el-button
          size="small"
          type="primary"
          @click="exportCallHistory"
          v-dt-permission="'电销外呼-通话记录-导出通话记录'"
        >
          导出通话记录
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="goExportList"
          v-dt-permission="'电销外呼-通话记录-导出列表'"
        >
          导出列表
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="mp3download"
          v-dt-permission="'电销外呼-通话记录-下载录音文件'"
        >
          下载录音文件
        </el-button>
      </div>
    </div>
    <div
      class="table-row"
      v-loading="loading"
      v-dt-permission="'电销外呼-通话记录-查看通话'"
    >
      <el-table
        :data="callList"
        style="width: 100%; height: 100%; font-size: 12px"
        border
        stripe
        ref="table"
        :default-sort="{ prop: 'date', order: 'descending' }"
        @selection-change="handleSelectionChange"
      >
        <div slot="empty" class="empty">
          <span v-if="isWaihu == '1'">暂无数据</span>
          <div
            v-if="isWaihu == '0' || isWaihu == '2'"
            style="margin-top: -60px;"
          >
            <span style="font-size: 24px;"
              ><img
                src="../../assets/phone-warning.svg"
                class="phonewarn"
                width="30"
                height="30"
                alt=""
              />暂未开通外呼系统</span
            >
            <div>
              <el-button size="small" type="primary" @click="applyOpenwaihu">
                立即申请开通
              </el-button>
            </div>
          </div>
        </div>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          :width="item.field === 'imagesUrl' ? '55' : ''"
          :label="item.name"
          :prop="item.field"
          :align="item.name === '账号' ? 'left' : 'center'"
          :sortable="item.field === 'createdAt'"
          v-for="item in columns"
          v-if="item.name !== '下载地址'"
          show-overflow-tooltip
          :key="item.field"
        >
          <template slot="header" slot-scope="scope">
            <el-dropdown
              @command="selectLeader"
              placement="bottom-start"
              class="dt-dropdown"
              v-if="item.name === '创建人'"
            >
              <span
                style="font-size: 12px;color: #909399;cursor: pointer"
                :class="
                  selectUserName && selectUserName !== '全部' ? 'dt-active' : ''
                "
              >
                创建人
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-scrollbar class="dt-dropdown-dt">
                  <el-dropdown-item
                    :class="selectUserId === _item.id ? 'dt-active' : ''"
                    :command="_item"
                    v-for="_item in leaderList"
                    :key="_item.id"
                  >
                    {{ _item.name }}
                  </el-dropdown-item>
                </el-scrollbar>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown
              @command="selectMark"
              placement="bottom-start"
              class="dt-dropdown"
              v-else-if="item.name === '标记状态'"
            >
              <span
                style="font-size: 12px;color: #909399;cursor: pointer"
                :class="
                  selectMarkName && selectMarkName !== '全部' ? 'dt-active' : ''
                "
              >
                标记状态
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-scrollbar class="dt-dropdown-dt">
                  <el-dropdown-item
                    :class="selectMarkName === _item.name ? 'dt-active' : ''"
                    :command="_item"
                    v-for="_item in markList"
                  >
                    {{ _item.name }}
                  </el-dropdown-item>
                </el-scrollbar>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown
              @command="selectStatus"
              placement="bottom-start"
              class="dt-dropdown"
              v-else-if="item.name === '通话状态'"
            >
              <span
                style="font-size: 12px;color: #909399;cursor: pointer"
                :class="
                  phoneStatus && phoneStatus !== '全部' ? 'dt-active' : ''
                "
              >
                通话状态
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-scrollbar class="dt-dropdown-dt">
                  <el-dropdown-item
                    :class="phoneStatus === _item.name ? 'dt-active' : ''"
                    :command="_item"
                    v-for="_item in statusList"
                    :key="_item.id"
                  >
                    {{ _item.name }}
                  </el-dropdown-item>
                </el-scrollbar>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown
              @command="selectUser"
              placement="bottom-start"
              class="dt-dropdown"
              v-else-if="item.name === '账号'"
            >
              <span
                style="font-size: 12px;color: #909399;cursor: pointer"
                :class="userName && userName !== '全部' ? 'dt-active' : ''"
              >
                账号
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <dt-drop-down :source-list="userList">
                  <template v-slot:default="row">
                    <el-scrollbar class="dt-dropdown-dt">
                      <div
                        class="dt-drop-down-no-data"
                        v-if="!row.filterList.length"
                      >
                        无数据
                      </div>
                      <el-dropdown-item
                        :class="userId === _item.id ? 'dt-active' : ''"
                        :command="_item"
                        v-for="_item in row.filterList"
                        :key="_item.id"
                      >
                        <span v-html="_item.htmlEl"></span>
                      </el-dropdown-item>
                    </el-scrollbar>
                  </template>
                </dt-drop-down>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown
              @command="selectBindWechat"
              placement="bottom-start"
              class="dt-dropdown"
              v-else-if="item.field === 'bindWechat'"
            >
              <span
                style="font-size: 12px;color: #909399;cursor: pointer"
                :class="bindWechat ? 'dt-active' : ''"
              >
                {{ item.name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-scrollbar class="dt-dropdown-dt">
                  <el-dropdown-item
                    :class="bindWechat === _item.value ? 'dt-active' : ''"
                    :command="_item"
                    v-for="_item in bindWechatList"
                    :key="_item.value"
                  >
                    {{ _item.name }}
                  </el-dropdown-item>
                </el-scrollbar>
              </el-dropdown-menu>
            </el-dropdown>
            <span v-else>
              {{ item.name }}
            </span>
          </template>

          <template slot-scope="scope">
            <div>
              <span v-if="item.name === '姓名'">
                <el-badge
                  :value="'KP'"
                  class="badge-item"
                  style="margin-top:10px;"
                  type="primary"
                  v-if="scope.row.isKp == '1'"
                >
                  <span style="margin-right:12px;display:inline-block;">{{
                    scope.row[item.field]
                  }}</span>
                </el-badge>
                <span v-else>
                  {{ scope.row[item.field] }}
                </span>
              </span>

              <span
                v-else-if="item.name === '客户名称'"
                style="color: #0099cc;cursor: pointer"
                @click="goKeyDetail(scope.row, scope.$index)"
              >
                {{ scope.row[item.field] }}
              </span>

              <span v-else-if="item.field === 'bindWechat'">
                <el-tag
                  v-if="scope.row[item.field]"
                  size="small"
                  type="primary"
                >
                  已关联
                </el-tag>
                <!--                <svg-icon icon-class="qywxicon" v-if="scope.row[item.field]" style="font-size: 20px"></svg-icon>-->
              </span>

              <span v-else>
                {{ scope.row[item.field] }}
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          align="center"
          width="100"
          v-if="callList.length"
        >
          <template slot-scope="scope">
            <span
              class="click"
              v-if="scope.row.ossUrl"
              v-dt-permission="'电销外呼-通话记录-播放录音'"
              @click="showAudio(scope.row)"
              >播放录音</span
            >
            <span
              class="click"
              v-else
              v-dt-permission="'电销外呼-通话记录-播放录音'"
              style="color: #c0c4cc"
              >播放录音</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page page-div">
        <footer-page
          @handleCurrentChange="handleCurrentChange"
          @goNumPage="goNumPage"
          @changePageSize="changePageSize"
          :pageStatus="pageStatus"
        ></footer-page>
      </div>
    </div>
    <div class="open_table">
      <el-dialog
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="720px"
        top="25vh"
      >
        <div class="title">满足下列所有条件</div>
        <div class="content">
          <div
            class="select-tag"
            v-for="(item, index) in currentCondition"
            :key="index + 1"
          >
            <el-select
              v-model="item.chooFilterIndex"
              placeholder="请选择"
              @change="getChangeField(item.chooFilterIndex, index)"
            >
              <el-option
                v-for="(item, inde) in filter_field"
                :key="inde + 1"
                :label="item.filterName"
                :value="inde"
              ></el-option>
            </el-select>
            <el-select
              v-model="item.chooFilterCond"
              @change="getChangeChild(item.optional, index)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in item.chooFilterArr"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <el-select
              class="filter-mult-coll select-industry-one"
              v-if="
                item.chooFilterEng == 'leader' ||
                  item.chooFilterEng == 'province' ||
                  item.chooFilterEng == 'city' ||
                  item.chooFilterEng == 'phoneStatus' ||
                  item.chooFilterEng == 'mark' ||
                  item.chooFilterEng == 'ossUrl' ||
                  item.chooFilterEng == 'bindWechat'
              "
              v-model="item.chooFilterInput"
              multiple
              collapse-tags
              filterable
              reserve-keyword
              :placeholder="item.chooFilterTip"
            >
              <el-option
                value="title"
                disabled
                v-if="item.chooFilterInput && item.chooFilterInput.length"
                style="font-size:12px;height:14px;line-height:14px;font-weight:bold;"
                >已选中
              </el-option>
              <!--  -->
              <el-option
                v-for="ite in item.chooseFilterChoo"
                v-if="item.chooFilterInput.indexOf(ite.value) > -1"
                :key="ite.value + 'h'"
                :value="ite.value"
                class="choose-had-list"
              >
              </el-option>
              <el-option
                value="bot"
                v-if="item.chooFilterInput && item.chooFilterInput.length"
                style="height: 1px;background: #41CABF;"
              ></el-option>
              <el-option
                v-for="item in item.chooseFilterChoo"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-date-picker
              class="filter-mult-coll"
              v-else-if="item.chooFilterEng == 'callTime'"
              v-model="item.chooFilterInput"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期"
            >
            </el-date-picker>
            <el-input
              v-else
              v-model="item.chooFilterInput"
              class="search-input filter-mult-coll"
              :placeholder="item.chooFilterTip"
            ></el-input>
            <div>
              <svg-icon
                icon-class="delete"
                class="filtrate"
                @click="deleteTag(index)"
              />
            </div>
          </div>
          <div
            v-if="currentCondition.length < 3 ? true : false"
            class="add_tag_icon"
            @click="addTag()"
          ></div>
        </div>
        <div
          class="footer"
          style="width: 100%; height: 50px;padding-left: 50px;"
        >
          <div style="float:right;margin-top: 15px;">
            <el-button @click="handleClose()">取消</el-button>
            <el-button
              :disabled="proFclick"
              @click="submitPondFilter"
              type="primary"
              >确认
            </el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getCustomerLeaders } from '@/api/customerpond'
import FooterPage from '@/components/Footerpage/index'
import { weChatCheck } from '@/api/pool'
import PhoneStrip from '@/components/PhoneStrip'
import MobileAudio from '@/views/callsystem/mobileaudio'
import DtTable from '@/components/element/DtTable'
import { getDetailAsignOpthon } from '@/api/thread'
import {
  callHistoryList,
  getCallHeiOption,
  getCallOption,
  exportCallHistory,
  applyOpenwaihu
} from '@/api/call'
import taskList from '@/views/collect/dialog/taskList'
import { saveAs } from 'file-saver'
import DtDropDown from '@/components/element/DtDropDown'
export default {
  name: 'contact-breed',
  components: {
    FooterPage,
    DtTable,
    PhoneStrip,
    DtDropDown
  },
  data() {
    return {
      code: '',
      noTransform: false,
      leaderList: [],
      selectUserId: null,
      selectUserName: '',
      selectSourceId: null,
      selectSourceName: '',
      selectMarkName: '',
      selectMarkId: null,
      callList: [],
      columns: [],
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: null
      },
      markDescList: [],
      search: '', //通过联系人名称或公司名称查找
      loading: false,
      loadingWx: false,
      select: [],
      isWaihu: '0',
      refresh: true,
      sourceList: [],
      contactStateList: [
        {
          id: null,
          name: '全部'
        },
        {
          id: 1,
          name: '关键人'
        },
        {
          id: 2,
          name: '中间人'
        },
        {
          id: 3,
          name: '普通'
        }
      ],
      currentCondition: [],
      dialogVisible: false,
      proFclick: false,
      currentCondParam: '',
      currentCondValue: '',
      userId: '',
      userName: '',
      phone: '',
      phoneStatus: '',
      bindWechat: '',
      markList: [],
      statusList: [],
      userList: [],
      bindWechatList: [
        {
          name: '已关联',
          value: '1'
        },
        {
          name: '未关联',
          value: '0'
        }
      ]
    }
  },

  activated() {
    // this.reSearch()
  },
  mounted() {
    Date.prototype.Format = function(fmt) {
      var o = {
        'M+': this.getMonth() + 1, //月份
        'd+': this.getDate(), //日
        'h+': this.getHours(), //小时
        'm+': this.getMinutes(), //分
        's+': this.getSeconds(), //秒
        'q+': Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds() //毫秒
      }
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (this.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      for (var k in o)
        if (new RegExp('(' + k + ')').test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ('00' + o[k]).substr(('' + o[k]).length)
          )
      return fmt
    }
    const pageNo = localStorage.getItem('callhisPageNum')
    let accountInfo = localStorage.getItem('accountInfo')
      ? JSON.parse(localStorage.getItem('accountInfo'))
      : {}
    this.isWaihu = accountInfo.isWaihu
    if (pageNo) {
      this.pageStatus.pageNo = pageNo
    }
    // this.getCallHistoryList()
    this.getLeader()
    this.getDetailAsignOpthon()
    this.getCusListFilter()
    this.deserveDataSession()
    this.getCallOption('1')
    this.getCallOption('2')
    this.getCallOption('3')
  },
  methods: {
    applyOpenwaihu() {
      applyOpenwaihu().then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
        }
      })
    },
    // 展示下载列表
    goExportList() {
      this.$store.dispatch('showModal', {
        title: '导出列表',
        view: taskList,
        size: '900px'
      })
    },
    mp3download() {
      if (this.select.length == 0) {
        this.$message({
          message: '请勾选通话记录',
          type: 'warning'
        })
        return
      }
      for (let i in this.select) {
        if (this.select[i].ossUrl) {
          saveAs(
            this.select[i].ossUrl,
            this.select[i].account + new Date().Format('yyyyMMddhhmmss')
          )
        }
      }
    },
    exportCallHistory() {
      const params = {
        userId: this.userId,
        phone: this.search,
        phoneStatus: this.phoneStatus == '全部' ? '' : this.phoneStatus,
        mark: this.selectMarkName == '全部' ? '' : this.selectMarkName,
        condition: this.currentCondParam,
        pageId: this.$store.state.user.pageId,
        ids: this.select
          .map(obj => {
            return obj.id
          })
          .join()
      }
      this.loading = true
      exportCallHistory(params).then(res => {
        if (res.code === 60000) {
          this.loading = false
          this.getCallTaskList()
        }
      })
    },
    // 导出列表
    getCallTaskList() {
      this.$store.dispatch('showModal', {
        title: '导出列表',
        view: taskList,
        size: '900px'
      })
    },
    getDetailAsignOpthon() {
      getDetailAsignOpthon().then(res => {
        // this.asignOption = res.data
        this.markList = [
          {
            id: '',
            name: '全部'
          }
        ].concat(res.data)
      })
    },
    getCallOption(i) {
      const params = {
        type: i,
        pageId: this.$store.state.user.pageId
      }
      getCallOption(params).then(res => {
        if (res.code === 60000) {
          // if (i == 1) {
          //   this.markList = [{
          //     id: '',
          //     name: '全部'
          //   }].concat(res.data.ddic)
          // }
          if (i == 2) {
            this.statusList = [
              {
                id: '',
                name: '全部'
              }
            ].concat(res.data.ddic)
          }
          if (i == 3) {
            this.userList = [
              {
                id: '',
                name: '全部'
              }
            ].concat(res.data.ddic)
          }
        }
      })
    },
    deserveDataSession() {
      const haveCondition = sessionStorage.getItem('callParams')
      if (haveCondition) {
        const params = JSON.parse(haveCondition)
        this.pageStatus.pageNo = params.pageNo
        this.pageStatus.pageSize = params.pageSize
        this.currentCondition = params.currentCondition
        this.currentCondValue = params.currentCondValue
        this.currentCondParam = params.currentCondParam
        this.userId = params.userId
        this.userName = params.userName
        this.search = params.phone
        this.phoneStatus = params.phoneStatus
        this.selectMarkName = params.selectMarkName
        this.bindWechat = params.bindWechat
      } else {
        this.pageStatus.pageNo = 1
        this.pageStatus.pageSize = 20
        this.currentCondition = []
        this.currentCondValue = ''
        this.currentCondParam = ''
        this.userId = ''
        this.search = ''
        this.phoneStatus = ''
        this.selectMarkName = ''
        this.bindWechat = ''
      }
      this.getCallHistoryList()
    },
    deserveNewTag(val, arr) {
      if (arr.chooFilterEng !== 'tag') {
        return val
      }
      var newTag = []
      if (val && val.length) {
        for (var i = 0; i < val.length; i++) {
          const oneTag = val[i][0]
          newTag.push(val[i][1] + '-' + oneTag)
        }
        return newTag
      }
    },
    submitPondFilter() {
      this.proFclick = true
      setTimeout(() => {
        this.proFclick = false
      }, 500)
      this.currentCondValue = ''
      this.currentCondParam = ''
      if (this.currentCondition && this.currentCondition.length) {
        for (const i in this.currentCondition) {
          if (
            this.currentCondition[i].chooFilterName &&
            this.currentCondition[i].chooFilterCond &&
            this.currentCondition[i].chooFilterInput
          ) {
            var nameArr = []
            var currArr = this.deserveNewTag(
              this.currentCondition[i].chooFilterInput,
              this.currentCondition[i]
            )
            if (i == this.currentCondition.length - 1) {
              this.currentCondValue +=
                this.currentCondition[i].chooFilterName +
                ':' +
                this.currentCondition[i].chooFilterCond +
                (nameArr.length
                  ? nameArr
                  : this.deserveNewTag(
                      this.currentCondition[i].chooFilterInput,
                      this.currentCondition[i]
                    ))

              this.currentCondParam +=
                this.currentCondition[i].chooFilterEng +
                ':' +
                this.currentCondition[i].chooFilterCond +
                ':' +
                this.deserveNewTag(
                  this.currentCondition[i].chooFilterInput,
                  this.currentCondition[i]
                )
            } else {
              this.currentCondValue +=
                this.currentCondition[i].chooFilterName +
                ':' +
                this.currentCondition[i].chooFilterCond +
                (nameArr.length
                  ? nameArr
                  : this.deserveNewTag(
                      this.currentCondition[i].chooFilterInput,
                      this.currentCondition[i]
                    )) +
                ';'

              this.currentCondParam +=
                this.currentCondition[i].chooFilterEng +
                ':' +
                this.currentCondition[i].chooFilterCond +
                ':' +
                this.deserveNewTag(
                  this.currentCondition[i].chooFilterInput,
                  this.currentCondition[i]
                ) +
                ';'
            }
          }
        }
      }
      this.pageStatus.pageNo = 1
      this.dialogVisible = false
      this.productTag = ''
      this.getCallHistoryList()
    },
    getCusListFilter() {
      const params = {
        pageId: this.$store.state.user.pageId
      }
      getCallHeiOption(params).then(res => {
        if (res.code === 60000) {
          this.filter_field = res.data
        }
      })
    },
    getChangeField(index, list) {
      this.$set(
        this.currentCondition[list],
        'chooFilterInput',
        this.filter_field[index].optional
          ? this.filter_field[index].optional[0]
          : ''
      )
      this.$set(
        this.currentCondition[list],
        'chooFilterArr',
        this.filter_field[index].operators
      )
      this.$set(
        this.currentCondition[list],
        'chooFilterCond',
        this.filter_field[index].operators[0] || ''
      )
      this.$set(
        this.currentCondition[list],
        'chooFilterName',
        this.filter_field[index].filterName
      )
      this.$set(
        this.currentCondition[list],
        'chooFilterEng',
        this.filter_field[index].filterColumn
      )
      this.$set(
        this.currentCondition[list],
        'chooFilterTip',
        this.filter_field[index].filterTips
      )
      this.getChangeChild(this.filter_field[index].optional, list)
      console.log(this.currentCondition)
    },
    deleteTag(index) {
      this.currentCondition.splice(index, 1)
    },
    getChangeChild(list, index) {
      if (this.currentCondition[index].chooFilterCond == '选择') {
        this.$set(this.currentCondition[index], 'chooFilterInput', [])
      } else {
        this.$set(this.currentCondition[index], 'chooFilterInput', '')
      }
      var newList = []
      if (list && list.length) {
        for (var ind in list) {
          const obj = {}
          obj.name = list[ind]
          obj.value = list[ind]
          newList.push(obj)
        }
      } else {
        newList = []
      }
      this.$set(this.currentCondition[index], 'chooseFilterChoo', newList || [])
    },
    handleClose() {
      this.dialogVisible = false
    },
    addTag() {
      const params = {
        chooFilterName: '',
        chooFilterTip: '请输入关键字',
        chooFilterIndex: '',
        chooFilterCond: '',
        chooFilterArr: '',
        chooFilterInput: '',
        chooFilterEng: '',
        chooseFilterChoo: []
      }
      this.currentCondition.push(params)
    },
    openHighCond() {
      this.dialogVisible = true
      if (this.currentCondition && this.currentCondition.length) {
      } else {
        var listIndex = ''
        for (var i in this.filter_field) {
          if (this.filter_field[i].filterColumn === 'tag') {
            listIndex = i
          }
        }
        this.$nextTick(() => {
          this.currentCondition = []
          const params = {
            chooFilterName:
              listIndex === '' ? '' : this.filter_field[listIndex].filterName,
            chooFilterIndex: listIndex === '' ? '' : listIndex,
            chooFilterCond: '',
            chooFilterArr: '',
            chooFilterInput: '',
            chooFilterEng: '',
            chooFilterTip: '请输入关键字',
            chooseFilterChoo: []
          }
          this.currentCondition.push(params)
        })
      }
    },
    changeTransform() {
      console.log(this.noTransform)
      this.getCallHistoryList()
    },
    goKeyDetail(val) {
      this.$router.push({
        path: '/keydetail/keydetail',
        query: {
          companyUuid: val.companyUuid,
          companyName: val.companyName,
          packageId: 0,
          showCompanyNameList: false
        }
      })
    },
    reset() {
      this.search = ''
      this.selectMarkName = ''
      this.selectMarkId = null
      this.noTransform = false
      this.userName = ''
      this.userId = null
      this.phoneStatus = null
      this.selectMarkName = null
      this.condition = ''
      this.currentCondParam = ''
      this.bindWechat = ''
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      localStorage.setItem('callhisPageNum', 1)
      this.getCallHistoryList()
    },
    reSearch() {
      this.pageStatus = {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
      localStorage.setItem('callhisPageNum', 1)
      this.getCallHistoryList()
    },
    handleCurrentChange(val) {
      localStorage.setItem('callhisPageNum', val)
      this.pageStatus.pageNo = val
      this.getCallHistoryList()
    },
    goNumPage(val) {
      localStorage.setItem('callhisPageNum', val)
      this.pageStatus.pageNo = val
      this.getCallHistoryList()
    },
    changePageSize(val) {
      this.pageStatus.pageSize = val
      this.getCallHistoryList()
    },

    // 查看详情
    showAudio(val) {
      this.$store.dispatch('showModal', {
        title: '播放录音',
        view: MobileAudio,
        size: '600px',
        options: {
          row: val
        },
        onClose: res => {
          if (res.refresh) {
            this.reset()
          }
          if (res.reRequest) {
            this.getCallHistoryList()
          }
        }
      })
    },
    getCallHistoryList() {
      console.log(this.$route)
      this.loading = true
      const params = {
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        userId: this.userId,
        phone: this.search,
        phoneStatus: this.phoneStatus == '全部' ? '' : this.phoneStatus,
        mark: this.selectMarkName == '全部' ? '' : this.selectMarkName,
        condition: this.currentCondParam,
        pageId: this.$store.state.user.pageId,
        bindWechat: this.bindWechat ? parseInt(this.bindWechat) : null
      }
      const callParams = {
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        condition: this.currentCondParam,
        userId: this.userId,
        userName: this.userName,
        phone: this.search,
        phoneStatus: this.phoneStatus,
        selectMarkName: this.selectMarkName,
        currentCondition: this.currentCondition,
        currentCondValue: this.currentCondValue,
        currentCondParam: this.currentCondParam,
        bindWechat: this.bindWechat
      }
      sessionStorage.setItem('callParams', JSON.stringify(callParams))
      callHistoryList(params)
        .then(res => {
          this.loading = false
          if (res && res.code === 60000) {
            this.callList = [...res.data.data]
            if (this.env === 'dd' || this.env === 'tt') {
              this.columns = [...res.data.column].filter(
                i => i.field !== 'bindWechat'
              )
            } else {
              this.columns = [...res.data.column]
            }

            this.pageStatus = res.data.total
          } else {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'error',
              duration: 2000
            })
          }
        })
        .catch(error => {
          this.loading = false
          this.$store.dispatch('showTip', {
            text: '出错了',
            duration: 2000
          })
        })
    },

    // 选择联系人
    handleSelectionChange(val) {
      this.select = val
    },

    getLeader() {
      getCustomerLeaders({ pageId: 6002 }).then(res => {
        if (res && res.code === 60000) {
          const header = [
            {
              name: '全部',
              id: ''
            }
          ]
          res.data = res.data.filter(item => {
            return item.id !== -1 && item.id !== -2
          })
          this.leaderList = [...header, ...res.data]
          console.log([...header, ...res.data])
        }
      })
    },

    selectLeader(user) {
      this.selectUserName = user.name
      this.selectUserId = user.id
      this.getCallHistoryList()
    },
    selectStatus(user) {
      this.phoneStatus = user.name
      this.getCallHistoryList()
    },
    selectMark(user) {
      this.selectMarkName = user.name
      this.selectMarkId = user.value
      this.getCallHistoryList()
    },
    selectUser(user) {
      this.userName = user.followName
      this.userId = user.id
      this.getCallHistoryList()
    },

    selectBindWechat(data) {
      this.bindWechat = data.value
      this.getCallHistoryList()
    },

    validatorWeChat(fn) {
      this.loadingWx = true
      weChatCheck()
        .then(res => {
          this.loadingWx = false
          if (res && res.code === 60000) {
            // 如果校验通过 则显示登录二维码
            typeof fn === 'function' ? fn(res.data) : ''
          }
        })
        .catch(() => {
          typeof fn === 'function' ? fn(1) : ''
          this.loadingWx = false
        })
    }
  },

  computed: {
    weChatPlugin() {
      return this.$store.state.plugin.weChatPlugin
    },

    env() {
      return this.$store.state.plugin.env
    }
  }
}
</script>

<style scoped>
.breed {
  height: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.btn-row {
  margin: 10px 0;
  height: 50px;
  width: 100%;
  background-color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-header {
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.search {
  box-sizing: border-box;
  height: 100%;
  max-width: 600px;
  min-width: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.add-btn {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn-search {
  margin-left: 10px;
}

.table-row {
  min-height: calc(100% - 120px);
  height: auto;
  padding: 20px 20px 50px 20px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  background-color: #fff;
}

.page {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.empty {
  /*height: 500px;*/
  height: calc(100vh - 285px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}

.click {
  color: #0099cc;
  cursor: pointer;
}

.table-row ::v-deep .el-table--border th {
  border-right: 1px solid #ebeef5;
}

.table-row ::v-deep .el-table--border th:nth-child(1) {
  border-right: none;
}

/*.table-row ::v-deep .el-table--border th:nth-child(2) {*/
/*  border-right: none;*/
/*}*/

.table-row
  ::v-deep
  .el-table--enable-row-transition
  .el-table__body
  tbody
  tr
  td {
  border-right: none;
}

.table-row ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}

.breed ::v-deep .el-button:hover {
  background-color: #41cabf;
  color: #fff;
}

.dt-dropdown ::v-deep .el-dropdown-menu {
  max-height: 300px;
}

.dt-active {
  color: #41cabf !important;
}

.callcol {
  text-indent: 10em;
  margin-left: 15px;
}

.callicon {
  display: block;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  background: url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/tel-sys.svg')
    no-repeat center center;
  background-size: 100% 100%;
  /*background-color: #1abc9c;*/
  border-radius: 5px;
}

.search-senior {
  width: 120px;
  height: 20px;
  float: left;
  position: relative;
  margin-left: 20px;
  color: #78d9d1;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  cursor: pointer;
}

.add_tag_icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/add_tag.png') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  margin-bottom: 20px;
}
.phonewarn {
  display: inline-block;
  position: relative;
  top: 6px;
  right: 5px;
}
</style>
