var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "visual-temp-page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list" },
      _vm._l(_vm.templateList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "item",
            on: {
              click: function ($event) {
                return _vm.handleDetail(item)
              },
            },
          },
          [
            _c("div", { staticClass: "top" }, [
              _c("div", [_vm._v(_vm._s(item.name))]),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "img-default",
              attrs: { src: item.abbreviationsImgUrl },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "crumb-wrap" }, [
      _c("div", [_vm._v("可视化模板")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }