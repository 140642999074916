<template>
  <div class="dt-feed-type">
    <el-dialog
      v-if="modalType === 'dialog'"
      class="el-dialog-dt"
      :visible.sync="show"
      :modal="true"
      :width="modalSize"
      :top="modalTop"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :append-to-body="isChild"
      :show-close="false"
      @closed="onClose"
    >
      <div slot="title" class="title">
        <span>{{ modalTitle }}</span>
        <i class="el-icon-close" @click="closeModal" v-if="!hideClose"></i>
      </div>
      <component v-bind:is="modalView" v-bind="modalOptions"></component>
    </el-dialog>

    <el-drawer
      v-else
      class="el-drawer-dt"
      :visible.sync="show"
      :modal="true"
      direction="rtl"
      :size="modalSize"
      :destroy-on-close="true"
      :show-close="false"
      @closed="onClose"
    >
      <div slot="title" class="drawer-title" v-if="showTitle">
        {{ modalTitle }}
      </div>
      <component v-bind:is="modalView" v-bind="modalOptions"></component>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'DtModal',
  data() {
    let modal = this.$store.state.plugin.modal
    return {
      modal: modal,
      show: false
    }
  },

  mounted() {
    this.$bus.$on('onClose', () => {
      console.log(1111)
      if (typeof this.modal.onClose == 'function') {
        this.modal.onClose(
          Object.assign({}, this.$store.state.plugin.modal.options)
        )
      }
    })
  },

  methods: {
    onClose() {
      if (typeof this.modal.onClose === 'function') {
        this.modal.onClose(
          Object.assign({}, this.$store.state.plugin.modal.options)
        )
      }
    },
    closeModal() {
      typeof this.$store.state.plugin.modal.options.closeModel === 'function'
        ? this.$store.state.plugin.modal.options.closeModel()
        : this.$store.dispatch('hideModal')
    }
  },
  computed: {
    modalId() {
      return this.modal.id
    },
    modalTitle() {
      return this.modal.title
    },
    modalView() {
      return this.modal.view
    },
    modalSize() {
      return this.modal.size
    },
    modalOptions() {
      return this.modal.options
    },
    modalTop() {
      return this.modal.top
    },
    isChild() {
      return this.modal.isChild
    },
    modalType() {
      return this.modal.type
    },
    showTitle() {
      return this.modal.showTitle
    },
    hideClose() {
      return this.modal.hideClose
    }
  },

  watch: {
    modalId(id) {
      this.show = !!id
    }
  },

  beforeDestroy() {
    this.$bus.$off()
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.dt-feed-type {
  .title {
    height: 50px;
    width: 100%;
    color: $used-color;
    background-color: $main-color;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
  }
}

.drawer-title {
  height: 50px;
  width: 100%;
  color: #2e2e2e;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

::v-deep .el-dialog__header,
::v-deep .el-drawer__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}

::v-deep .el-drawer__header {
  margin-bottom: 0 !important;
}

::v-deep .el-dialog__close,
::v-deep .el-drawer__close {
  color: #fff;
}

::v-deep .el-dialog__body,
::v-deep .el-drawer__body {
  padding: 0 !important;
}
</style>
