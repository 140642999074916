<template>
  <div class="ddScanLogin-main" v-loading="loading">
    {{ text }}
  </div>
</template>

<script>
import { ddAuth } from '@/api/user'
import store from '@/store'
import { ResetAuthUserMeal, ResetSystemConfig } from '@/utils/auth'
import { AppWebSockt } from '@/utils/websocket'
import expiredTip from '@/views/login/dialog/expiredTip'
import { setPermission } from '@/cores/RouteAndPermission'
export default {
  name: 'ddScanLogin',
  data() {
    return {
      code: '',
      text: '登陆中。。。',
      loading: false
    }
  },
  mounted() {
    this.code = this.$route.query.code
    if (this.code) {
      this.login()
    }
  },
  computed: {},
  methods: {
    login() {
      this.loading = true
      ddAuth({ code: this.code, type: 2 })
        .then(async res => {
          if (res.code === 60000 && res.data) {
            localStorage.clear()
            sessionStorage.clear()
            const token = res.data.token
            const step = res.data.step
            const accountType = res.data.type
            const userName = res.data.followName || ''
            store.commit('setToken', token)
            await store.dispatch('changeStep', step)
            await store.dispatch('setName', userName)
            localStorage.setItem('setAccountType', accountType + '')
            localStorage.setItem('dtBird_user_step', step)
            localStorage.setItem('setName', userName)
            localStorage.setItem('userLoginTime', new Date().getTime())
            if (step != 1) {
              ResetAuthUserMeal()
            }
            ResetSystemConfig() //获取系统信息
            AppWebSockt('kick_user_offline')
            await setPermission()
            localStorage.setItem(
              'permissionObj',
              JSON.stringify(store.state.permission.permission)
            )
            localStorage.setItem(
              'menuList',
              JSON.stringify(store.state.permission.menuList)
            )
            localStorage.setItem(
              'panelList',
              JSON.stringify(store.state.permission.panelList)
            )
            const isDev = process.env.NODE_ENV === 'development'
            const url = isDev
              ? 'http://test.dtbird.cn/#/dt-index'
              : 'https://ai.dtbird.cn/#/dt-index'
            location.replace(url)
          }
          if (res.code === 61004) {
            this.loading = false
            await this.$store.dispatch('showModal', {
              view: expiredTip,
              size: '500px',
              title: '提示'
            })
          }
          if (!res.data && res.code !== 61004) {
            this.loading = false
            this.text = '登录失败。。。'
            await this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'error'
            })
          }
        })
        .catch(err => {
          this.loading = false
          this.text = '登录失败。。。'
          this.$store.dispatch('showTip', {
            text: '登录失败,请关闭此页面重试',
            type: 'error'
          })
        })
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.ddScanLogin-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
