var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-map-page", style: _vm.getStoreStyle },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mapLoading,
              expression: "mapLoading",
            },
          ],
          ref: "mapRef",
          staticClass: "peClass",
          attrs: { id: "peMap" },
        },
        [
          _c(
            "div",
            { ref: "info", staticClass: "map-info" },
            [
              _vm._l(_vm.infoWindowData, function (item, index) {
                return [_c("div", { key: index }, [_vm._v(_vm._s(item))])]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("FullScreen", { attrs: { refDom: _vm.$refs.mapRef } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }