var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dt-data" }, [
    _c(
      "div",
      { staticClass: "dt-data" },
      [
        _c(
          "div",
          { staticClass: "dt-data-header" },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "bottom-start",
                  width: "500",
                  trigger: "click",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "j-input",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  [
                    !_vm.checkedUserNames.length
                      ? _c("span", [
                          _vm._v("\n            请选择成员\n          "),
                        ])
                      : _vm.checkedUserNames.length <= 2
                      ? _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.checkedUserNames.join()) +
                              "\n          "
                          ),
                        ])
                      : _vm.userList.length &&
                        _vm.checkedUserNames.length == _vm.userList.length
                      ? _c("span", [_vm._v("\n            全公司\n          ")])
                      : _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.checkedUserNames.slice(0, 2).join() +
                                  "等" +
                                  _vm.checkedUserNames.length +
                                  "人"
                              ) +
                              "\n          "
                          ),
                        ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "user-list" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticStyle: { margin: "0 0 10px 10px" },
                            attrs: { indeterminate: _vm.isIndeterminate },
                            on: { change: _vm.handleCheckAllChange },
                            model: {
                              value: _vm.checkAll,
                              callback: function ($$v) {
                                _vm.checkAll = $$v
                              },
                              expression: "checkAll",
                            },
                          },
                          [_vm._v("全公司\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-checkbox-group",
                          {
                            on: { change: _vm.chooseUsers },
                            model: {
                              value: _vm.checkedUsers,
                              callback: function ($$v) {
                                _vm.checkedUsers = $$v
                              },
                              expression: "checkedUsers",
                            },
                          },
                          _vm._l(_vm.userList, function (user) {
                            return _c(
                              "el-col",
                              {
                                key: user.id,
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { span: 8 },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    key: user.id,
                                    staticClass: "checkbox-dt check-black",
                                    attrs: { label: user.id },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(user.name) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticStyle: { padding: "0 10px" },
                attrs: { placeholder: "请选择", size: "small" },
                on: { change: _vm.selectTimeClick },
                model: {
                  value: _vm.value2,
                  callback: function ($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2",
                },
              },
              _vm._l(_vm.dateOptions, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.value },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dt-select-time" },
              [
                _vm.selectBtn == "2"
                  ? _c("el-date-picker", {
                      attrs: {
                        "picker-options": _vm.viewDemo
                          ? _vm.startDateLimit
                          : _vm.startDateLimit2,
                        editable: false,
                        clearable: false,
                        size: "small",
                        type: "daterange",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "-",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      on: { change: _vm.selectTimeClickAuto },
                      model: {
                        value: _vm.selectTime,
                        callback: function ($$v) {
                          _vm.selectTime = $$v
                        },
                        expression: "selectTime",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "reset", on: { click: _vm.reset } }, [
                  _vm._v("重置"),
                ]),
                _vm._v(" "),
                _c(
                  "el-checkbox",
                  {
                    staticClass: "check-black",
                    on: { change: _vm.checkChange },
                    model: {
                      value: _vm.excludeHoliday,
                      callback: function ($$v) {
                        _vm.excludeHoliday = $$v
                      },
                      expression: "excludeHoliday",
                    },
                  },
                  [_vm._v("排除周末节假日")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { position: "absolute", right: "20px" } },
              [
                _c(
                  "el-button",
                  {
                    key: _vm.viewDemo,
                    class: _vm.viewDemo ? "" : "",
                    attrs: {
                      autofocus: "",
                      size: "small",
                      type: _vm.viewDemo ? "" : "primary",
                    },
                    on: { click: _vm.changeViewDemoType },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.viewDemo ? "关闭示例" : "查看示例") +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-scrollbar",
          { ref: "scrollbar", staticClass: "dt-data-row dt-dropdown-dt" },
          [
            _c(
              "div",
              [
                false
                  ? _c(
                      "div",
                      { staticClass: "data-1" },
                      _vm._l(_vm.dataOneData.config, function (key, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "data-1-item" },
                          [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(key.label) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            key.key !== "contractMoneySum"
                              ? _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.dataOneData.data[key.key] || "0"
                                      ) +
                                      "\n            "
                                  ),
                                ])
                              : _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        (
                                          _vm.dataOneData.data[key.key] || "0"
                                        ).toLocaleString()
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        { ref: "znhk", staticClass: "data-2-item" },
                        [
                          _c(
                            "el-row",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingDataOne,
                                  expression: "loadingDataOne",
                                },
                              ],
                              staticStyle: {
                                height: "300px",
                                width: "90%",
                                margin: "0 auto",
                                "border-bottom": "#f5f5f6 solid 1px",
                              },
                              attrs: { gutter: 20 },
                            },
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "box-sizing": "border-box",
                                      height: "100px",
                                      "margin-top": "100px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "value" }, [
                                      _c("i", { staticClass: "count" }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            display: "inline-block",
                                            "text-align": "left",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.callInfoData.callCnt) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "\n                      通话总数\n                    "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "100px",
                                      "margin-top": "100px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "value" }, [
                                      _c("i", { staticClass: "precent" }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            display: "inline-block",
                                            "text-align": "left",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.callInfoData
                                                  .dealingPrecentDesc
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "\n                      接通率\n                    "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "100px",
                                      "margin-top": "100px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "value" }, [
                                      _c("i", { staticClass: "time" }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            display: "inline-block",
                                            "text-align": "left",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.callInfoData
                                                  .callDurationDesc
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "\n                      通话时长\n                    "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "100px",
                                      "margin-top": "100px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "value" }, [
                                      _c("i", { staticClass: "time" }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            display: "inline-block",
                                            "text-align": "left",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.callInfoData.avgCallDuration
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "\n                      平均通话时长\n                    "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loadingDataEnd || _vm.loadingDataFive,
                                  expression:
                                    "loadingDataEnd || loadingDataFive",
                                },
                              ],
                              staticStyle: {
                                height: "100px",
                                width: "90%",
                                margin: "0 auto",
                              },
                              attrs: { gutter: 20 },
                            },
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px",
                                      "box-sizing": "border-box",
                                      height: "50px",
                                      "margin-top": "30px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "\n                      已接听\n                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.callInfoData.dealingCnt) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px",
                                      "box-sizing": "border-box",
                                      height: "50px",
                                      "margin-top": "30px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "\n                      振铃未接听\n                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.callInfoData.notDealCnt) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "20px",
                                      "box-sizing": "border-box",
                                      height: "50px",
                                      "margin-top": "30px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "label" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        ivr\n                        "
                                          ),
                                          _c(
                                            "el-Popover",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                trigger: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  "\n                            1.互动式语音问答，常见于集团业务，属于非人为的应答"
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                            2.主动挂断（含因线路方规则导致的无法呼出）\n                          "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass: "ivr",
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.callInfoData.ivrCnt) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "data-4" }, [
                        _c(
                          "div",
                          { staticClass: "select-data-3" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 0 10px 0",
                                  "box-sizing": "border-box",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  趋势图\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", size: "mini" },
                                on: { change: _vm.selectChange1 },
                                model: {
                                  value: _vm.value1,
                                  callback: function ($$v) {
                                    _vm.value1 = $$v
                                  },
                                  expression: "value1",
                                },
                              },
                              _vm._l(_vm.options, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.name,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            false
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      multiple: "",
                                      "collapse-tags": "",
                                      placeholder: "请选择成员",
                                      size: "mini",
                                    },
                                    on: { change: _vm.selectUserChange },
                                    model: {
                                      value: _vm.selectUserList,
                                      callback: function ($$v) {
                                        _vm.selectUserList = $$v
                                      },
                                      expression: "selectUserList",
                                    },
                                  },
                                  _vm._l(_vm.userList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingDataFour,
                              expression: "loadingDataFour",
                            },
                          ],
                          ref: "dataFourRef",
                          staticClass: "data-4-item",
                          attrs: { id: "data-4-item" },
                        }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showTable && false
                  ? _c("div", { staticClass: "data-3" }, [
                      _c(
                        "div",
                        { staticClass: "select-data-3-table" },
                        [
                          _c("div", { staticClass: "control-lab" }, [
                            _c("div", {
                              class: {
                                table: true,
                                "table-active": _vm.activeType == 1,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLab("1")
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              class: {
                                shell: true,
                                "shell-active": _vm.activeType == 2,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLab("2")
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              class: {
                                cake: true,
                                "cake-active": _vm.activeType == 3,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLab("3")
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "check-black",
                              staticStyle: { margin: "10px 0 0 20px" },
                              on: { change: _vm.checkHaveRecord },
                              model: {
                                value: _vm.excludeHaveRecord,
                                callback: function ($$v) {
                                  _vm.excludeHaveRecord = $$v
                                },
                                expression: "excludeHaveRecord",
                              },
                            },
                            [_vm._v("只看有通话记录\n            ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { float: "right" } },
                        [
                          _vm.activeType == "1"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "20px",
                                    right: "50px",
                                    "z-index": "99",
                                  },
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.exportCallList },
                                },
                                [_vm._v("导出\n            ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "data-3-item" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeType == "1",
                                expression: "activeType == '1'",
                              },
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingDataTwo,
                                expression: "loadingDataTwo",
                              },
                            ],
                            staticClass: "data-3-item-table",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "table-row" },
                              [
                                _vm.activeType == "1"
                                  ? _c(
                                      "el-table",
                                      {
                                        ref: "table",
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          "font-size": "12px",
                                        },
                                        attrs: {
                                          data: _vm.dataTwoData1,
                                          border: "",
                                          stripe: "",
                                        },
                                        on: { "sort-change": _vm.sortchange },
                                      },
                                      _vm._l(_vm.colums, function (item) {
                                        return _c("el-table-column", {
                                          key: item.field,
                                          attrs: {
                                            label: item.name,
                                            prop: item.field,
                                            "show-overflow-tooltip": "",
                                            sortable:
                                              item.name == "账号"
                                                ? false
                                                : "custom",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "header",
                                                fn: function (scope) {
                                                  return [
                                                    item.name == "ivr"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              position:
                                                                "relative",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        ivr\n                        "
                                                            ),
                                                            _c(
                                                              "el-popover",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  trigger:
                                                                    "hover",
                                                                  placement:
                                                                    "top",
                                                                },
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "\n                            1.互动式语音问答，常见于集团业务，属于非人为的应答"
                                                                  ),
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    "\n                            2.主动挂断（含因线路方规则导致的无法呼出）\n                          "
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "ivr-table",
                                                                  attrs: {
                                                                    slot: "reference",
                                                                  },
                                                                  slot: "reference",
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item.name
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("div", [
                                                      _c("span", [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              scope.row[
                                                                item.field
                                                              ]
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.viewDemo,
                                        expression: "!viewDemo",
                                      },
                                    ],
                                    staticClass: "page page-div",
                                  },
                                  [
                                    _c("footer-page", {
                                      attrs: { pageStatus: _vm.pageStatus },
                                      on: {
                                        handleCurrentChange:
                                          _vm.handleCurrentChange,
                                        goNumPage: _vm.goNumPage,
                                        changePageSize: _vm.changePageSize,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeType == "2",
                              expression: "activeType == '2'",
                            },
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingDataTwo,
                              expression: "loadingDataTwo",
                            },
                          ],
                          staticClass: "data-3-item-left",
                          attrs: { id: "data-3-left" },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeType == "3",
                              expression: "activeType == '3'",
                            },
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingDataTwo,
                              expression: "loadingDataTwo",
                            },
                          ],
                          staticClass: "data-3-item-right",
                          attrs: { id: "data-3-right" },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "achievement" }, [
                  _c("div", { staticClass: "btn-table-chart" }, [
                    _c(
                      "div",
                      { staticClass: "btn-chart" },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "inline-block",
                              height: "28px",
                            },
                          },
                          [
                            _c("i", {
                              class:
                                _vm.achievementType === "table"
                                  ? "active-table"
                                  : "default-table",
                              on: {
                                click: function ($event) {
                                  return _vm.changeAchievementType("table")
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              class:
                                _vm.achievementType === "histogram"
                                  ? "active-histogram"
                                  : "default-histogram",
                              on: {
                                click: function ($event) {
                                  return _vm.changeAchievementType("histogram")
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              class:
                                _vm.achievementType === "pie"
                                  ? "active-pie"
                                  : "default-pie",
                              on: {
                                click: function ($event) {
                                  return _vm.changeAchievementType("pie")
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.achievementType !== "table",
                                expression: "achievementType !== 'table'",
                              },
                            ],
                            staticStyle: { transform: "translate(3px,-3px)" },
                            attrs: { placeholder: "请选择", size: "mini" },
                            on: { change: _vm.newSelectChange },
                            model: {
                              value: _vm.currentSelectType,
                              callback: function ($$v) {
                                _vm.currentSelectType = $$v
                              },
                              expression: "currentSelectType",
                            },
                          },
                          _vm._l(_vm.newOptions, function (item) {
                            return _c("el-option", {
                              key: item.field,
                              attrs: { label: item.name, value: item.field },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-checkbox",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.achievementType === "table",
                                expression: "achievementType === 'table'",
                              },
                            ],
                            staticClass: "check-black-new",
                            on: { change: _vm.checkHaveRecord },
                            model: {
                              value: _vm.excludeHaveRecord,
                              callback: function ($$v) {
                                _vm.excludeHaveRecord = $$v
                              },
                              expression: "excludeHaveRecord",
                            },
                          },
                          [_vm._v("只看有通话记录")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-chart" },
                      [
                        _vm.achievementType === "table"
                          ? [
                              _c(
                                "div",
                                { staticClass: "table-new" },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticClass: "table-dt",
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                        "font-size": "12px",
                                        "background-color": "#fff",
                                      },
                                      attrs: {
                                        data: _vm.tableData,
                                        "show-summary": "",
                                        border: "",
                                        stripe: "",
                                      },
                                      on: { "sort-change": _vm.sortChange },
                                    },
                                    _vm._l(_vm.columnList, function (item) {
                                      return _c("el-table-column", {
                                        attrs: {
                                          "show-tooltip-when-overflow": "",
                                          prop: item.field,
                                          align: "center",
                                          sortable:
                                            item.type === "sort"
                                              ? "custom"
                                              : false,
                                          label: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.achievementType === "histogram"
                          ? [
                              _c("div", {
                                staticClass: "histogram",
                                attrs: { id: "histogram" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.achievementType === "pie"
                          ? [
                              _c("div", {
                                staticClass: "pie",
                                attrs: { id: "pie" },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }