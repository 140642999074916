<template>
  <div class="band-wrapper">
    <div class="title">
      <span>榜单KA</span>
      <span
        v-if="IsAccess == 0 || IsAccess == 1"
        class="normal-data"
        @click="handleNormalData"
        >{{ $updateText }}</span
      >
    </div>
    <div class="time">
      <p>发布年份</p>
      <template v-for="item in filterTime">
        <span
          :class="item.checked ? 'active-time name' : 'name'"
          @click="handleTimeClick(item)"
          >{{ item.name }}</span
        >
      </template>
    </div>
    <div class="band">
      <p>榜单标签</p>
      <el-select
        v-model="peTags"
        multiple
        filterable
        style="width:400px"
        @change="handleSelectChange"
        size="mini"
      >
        <el-option
          v-for="(item, index) in filterBand"
          :key="index"
          :label="item.txt"
          :value="item.name"
        >
        </el-option>
      </el-select>
    </div>
    <div class="search">
      <el-input
        v-model="peParams.keywords"
        size="mini"
        style="width:300px;margin-right:20px"
        placeholder="请输入榜单名称、发布机构名称"
      ></el-input>
      <el-button size="mini" type="primary" @click="handleInquire"
        >查询</el-button
      >
      <el-button size="mini" @click="handleReset">重置</el-button>
      <p>共{{ peParams.total }}条结果</p>
    </div>
    <PeTable
      :peColumnList="peColumnList"
      :peTableData="peTableData"
      :peLoading="peLoading"
      :pePage="peParams"
      :IsAccess="IsAccess"
      @sortPeChange="handleSortChange"
      @handleCurrentPePage="handleCurrentChange"
      @handleSizePeChange="handleSizeChange"
    >
      <template v-slot:bdName="{ scope }">
        <div>
          <div>
            <span class="c-name" @click="handleEnter(scope.data)">{{
              scope.data.bdName
            }}</span>
            <span v-if="scope.data.newTag == 1" class="new-add">新增</span>
          </div>
          <Tags :tagList="scope.data.tags" />
          <div class="abnormal">
            <PeRisk :riskTag="scope.data.risk" />
            <span v-if="scope.data.company_status == '注销'" class="qy-cancel"
              >注销</span
            >
          </div>
        </div>
      </template>
    </PeTable>
  </div>
</template>

<script>
import { getBandList, getBandFilter } from '@/api/thread'
import PeTable from '@/components/Peculiar/table'
import PeRisk from '@/components/Peculiar/risk'
import { withOutAccess } from '@/utils/helper'
export default {
  name: 'BandRank',
  components: {
    PeTable,
    PeRisk
  },
  data() {
    return {
      filterTime: [],
      filterBand: [],
      listData: [],
      currentIndex: 0,
      peTableData: [],
      peColumnList: [],
      peTags: [],
      peParams: {
        publishDate: '',
        keywords: '',
        tags: '',
        sortField: '',
        sortOrder: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      peLoading: false
    }
  },
  computed: {
    IsAccess() {
      return this.$store.state.user.accountInfo.industryInsightDetailsOpen
    }
  },
  async mounted() {
    await this.getFilter()
    await this.getList()
  },
  methods: {
    handleNormalData() {
      withOutAccess()
    },
    handleSelectChange() {
      Object.assign(this.peParams, {
        tags: this.peTags.join()
      })
      this.getList()
    },
    async getFilter() {
      const { data } = await getBandFilter()
      this.filterTime = data.find(
        item => item.field == 'publishDate'
      ).filterOptionList
      this.filterBand = data.find(item => item.field == 'tags').filterOptionList
      this.filterBand.forEach(item => {
        item.txt = `${item.name}(${item.value})`
      })
    },
    async getList(flag = false) {
      this.peLoading = true
      let objParams = {}
      if (!flag) {
        objParams = {
          tags: this.peParams.tags,
          keywords: this.peParams.keywords
        }
      }
      Object.assign(this.peParams, objParams)
      const { data } = await getBandList(this.peParams)
      this.peColumnList = data?.columns
      this.peTableData = data?.list
      const pageParams = {
        total: data.page.total
      }
      this.peParams = Object.assign(this.peParams, pageParams)
      this.peLoading = false
    },
    handleTimeClick(item) {
      item.checked = !item.checked
      const publishDate = this.filterTime
        .filter(item => item.checked)
        .map(item => item.value)
        .join()
      const params = {
        publishDate
      }
      Object.assign(this.peParams, params)
      this.getList()
    },
    handleInquire() {
      this.peParams.pageNo = 1
      this.peParams.pageSize = 10
      this.getList()
    },
    handleReset() {
      for (let key in this.peParams) {
        if (key != 'pageNo' || key != 'pageSize' || key != 'total') {
          this.peParams[key] = ''
        }
        const pageParams = {
          pageNo: 1,
          pageSize: 10,
          total: 0
        }
        this.peTags = []
        Object.assign(this.peParams, pageParams)
      }
      this.filterTime.forEach(item => {
        this.$set(item, 'checked', false)
      })
      this.getList(true)
    },
    handleSortChange(...params) {
      const objParams = {
        sortField: params[2].sortField,
        sortOrder: params[2].sortOrder
      }
      this.peParams = Object.assign(this.peParams, objParams)
      this.getList()
    },
    handleCurrentChange(e) {
      this.peParams.pageNo = e
      this.getList()
    },
    handleSizeChange(e) {
      this.peParams.pageNo = 1
      this.peParams.pageSize = e
      this.getList()
    },
    handleEnter(data) {
      if (this.IsAccess == 0) {
        withOutAccess()
        return
      }
      sessionStorage.setItem('bdUuid', data.bdUuid)
      this.$router.push({
        name: 'bandDetail',
        query: {
          bdUuid: data.bdUuid
        }
      })
    }
  }
}
</script>
<style lang="scss">
.band-wrapper {
  background-color: #fff;
  margin: 10px 0 0 10px;
  font-size: 14px;
  .title {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .time {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
    .name {
      margin-left: 20px;
      cursor: pointer;
    }
    .active-time {
      color: #41cabf;
    }
  }
  .band {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 30px 0;
    .el-input {
      margin-left: 20px;
    }
    .el-select__tags {
      left: 22px;
    }
  }
  .search {
    display: flex;
    align-items: center;
    margin-top: 20px;
    p {
      font-size: 14px;
      color: #a8a8a8;
      margin-left: 10px;
    }
  }
  .c-name {
    color: #02a7f0;
    cursor: pointer;
  }
  .new-add {
    color: #f00;
  }
  .abnormal {
    display: flex;
    align-items: center;
  }
  .qy-cancel {
    background: red;
    color: #fff;
    padding: 0 4px;
    transform: scale(0.8);
    display: inline-block;
  }
}
</style>
