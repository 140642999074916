var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pe-angle" }, [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("Icon", {
          staticClass: "angle angle_1",
          attrs: { type: "img", peClass: "angle_1" },
        }),
        _vm._v(" "),
        _c("Icon", {
          staticClass: "angle angle_2",
          attrs: { type: "img", peClass: "angle_2" },
        }),
        _vm._v(" "),
        _c("Icon", {
          staticClass: "angle angle_3",
          attrs: { type: "img", peClass: "angle_3" },
        }),
        _vm._v(" "),
        _c("Icon", {
          staticClass: "angle angle_4",
          attrs: { type: "img", peClass: "angle_4" },
        }),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }