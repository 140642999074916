<template>
    <div>
        <div v-if="previsionArr && previsionArr.length" class="choose-version-cont">
            <div v-for="(item, index) in previsionArr"
                :key="index"
                v-if="item.hide === 0"
                style="flex: 1" 
                >
                <div
                    class="new-meal-type"
                    @click="chooseMealBuy(item)"
                    >
                    <i v-if="item.recommend" class="meal-commend-tag" />
                    <i class="meal-buy-icon" :class="chooseMeal === item.version?'meal-buy-yes':'meal-buy-no'"></i>
                    <div v-if="item.version === 's7'" :class="buyType?'filter-not-allowed':''" class="new-simple-header">
                        <p>
                            {{item.versionTitle}}
                        </p>
                    </div>
                    <div v-if="item.version === 's8'" class="new-simple-header new-company-bg">
                        <p>
                            {{item.versionTitle}}
                        </p>
                    </div>
                    <div v-if="item.version === 's9'" class="new-simple-header new-super-bg">
                        <p>
                            {{item.versionTitle}}
                        </p>
                    </div>

                    <div class="new-version-cont"  :class="(buyType && item.version === 's7')?'filter-not-allowed':''">
                        <p>
                            首充赠送条数: {{item.firstFree}} 条
                        </p>
                        <p>
                            月套餐: {{item.monthFree}} 条/月
                        </p>
                        <p>
                            {{item.cards}} 张订阅卡
                        </p>
                        <p v-if="item.accounts">
                            {{item.accounts}} 个子账号
                        </p>
                        <p v-if="item.recommend">
                            {{item.recommend}} 个定制线索包
                        </p>
                        <!-- <p style="text-align: center;padding-top: 20px;">
                            <span class="cont-tip-o">￥{{previsionArr[0].price}}</span>
                            <span class="cont-tip-t"></span>
                            <span class="cont-tip-th">/年</span>
                        </p> -->
                        <div class="version-price-cont">
                            <p class="version-old-price">
                                <span v-if="item.originalPrice">{{item.originalPrice}}RMB</span>
                            </p>
                            <p class="version-new-price">
                                <span style="color:#FE6A5F">{{item.price}}RMB</span>
                                <span style="color:#525252;">/{{item.unit === 'month'? '月':'年'}}</span>
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getPrePrice } from '@/api/usercenter'
import { getBaseMealList } from '@/api/pay'
export default {
    props: {
        cardNum: {
            type: Number,
            default: 0
        },
        packageNum: {
            type: Number,
            default: 0
        },
        packUserNum: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            chooseMeal: '',
            previsionArr: [],
            buyType: false,
            buyMd5: '826d7ebe97b9f0b69d7906872bcfa48c'
        }
    },
    created() {
        const typeMd5 = this.$route.query.buypayParams
        if (typeMd5 === this.buyMd5) {
            this.buyType = true
        } else {
            this.buyType = false
        }
        this.getBaseMealList()
    },
    methods: {
        chooseMealBuy(val) {
            if (this.buyType && (val.version === 's7')) {
                this.$message({
                    message: '升级套餐请选择企业版或旗舰版',
                    type: 'warning'
                })
                return
            }
            this.chooseMeal = val.version
            this.$emit('goMealPrise', val)
        },
        // 获取购买版本
        getBaseMealList() {
            getBaseMealList({}).then(res => {
                if (res.code === 60000) {
                    this.previsionArr = res.data
                    res.data.forEach(item => {
                        if (item.recommend) {
                            this.chooseMealBuy(item)
                        }
                    })
                } else {
                    this.previsionArr = []
                }
            })
        },
    }
}
</script>

<style scoped>
    .meal-commend-tag {
        display: inline-block;
        height: 54px;
        width: 54px;
        position: absolute;
        top: 0;
        right: 0;
        background: url('../../assets/recommd.png') no-repeat;
        background-size: 100% 100%;
    }
    .choose-version-cont {
        display: flex;
        margin: 0 auto;
        padding-top: 20px;
        max-width: 1400px;
    }
    .new-meal-type {
        width: 380px;
        height: 304px;
        display: inline-block;
        position: relative;
        flex: 1;
        cursor: pointer;
        margin: 0 10px;
    }
    .new-simple-header {
        height: 91px;
        background:linear-gradient(122deg,rgba(255,200,106,1) 0%,rgba(253,167,44,1) 100%);
        width: 100%;
    }
    .new-company-bg {
        background:linear-gradient(122deg,rgba(117,181,249,1) 0%,rgba(83,149,245,1) 100%);
    }
    .new-super-bg {
        background:linear-gradient(122deg,rgba(60,80,104,1) 0%,rgba(43,57,78,1) 100%);
    }
    .new-simple-header p {
        line-height: 91px;
        font-size: 30px;
        text-align: center;
        color: white;
        opacity: .9;
    }
    .new-version-cont {
        height: 213px;
        width: 100%;
        padding: 16px;
        background: white;
        position: relative;
    }
    .new-version-cont p {
        height: 20px;
        line-height: 20px;
        color: #8A8A8A;
        font-size: 14px;
        text-align: center;
    }
    .version-price-cont {
        height: 65px;
        width: 347px;
        position: absolute;
        bottom: 16px;
    }
    .version-price-cont::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(to bottom, transparent 50%, #C1BFBF 50%);
    }
    .version-old-price {
        margin-top: 15px;
        height: 20px;
        color: #8A8A8A;
        text-decoration: line-through;
        line-height: 20px;
    }
    .version-new-price {
        height: 28px;
        line-height: 28px;
        font-size: 20px;
        font-weight:400;
    }
    .filter-not-allowed {
        filter: grayscale(100%);
    }
</style>