var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-div" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", [_vm._v("客户雷达")]),
      _vm._v(" "),
      _c("div", { staticClass: "bar" }, [
        _c(
          "div",
          {
            staticClass: "left-bar",
            attrs: { title: "按时间" },
            on: {
              click: function ($event) {
                _vm.type = 0
              },
            },
          },
          [
            _c("i", {
              staticClass: "icon-liebiaoliulanmoshi iconfont ri",
              class: _vm.type === 0 ? "b-active" : "",
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "right-bar",
            attrs: { title: "按访客" },
            on: {
              click: function ($event) {
                _vm.type = 1
              },
            },
          },
          [
            _c("i", {
              staticClass: "icon-qiapianliulanmoshi iconfont le",
              class: _vm.type !== 0 ? "b-active" : "",
            }),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "content-p",
      },
      [
        _c("div", { staticClass: "filter" }, [
          _c("span", { staticClass: "fk" }, [
            _vm._v("总访客 "),
            _c("span", [_vm._v(_vm._s(_vm.count))]),
          ]),
        ]),
        _vm._v(" "),
        _vm.type === 0
          ? _c(
              "div",
              [
                _c(
                  "el-scrollbar",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.recordObj && _vm.recordObj.length,
                        expression: "recordObj && recordObj.length",
                      },
                    ],
                    staticClass: "content",
                  },
                  [
                    _c(
                      "div",
                      { ref: "line", staticClass: "line" },
                      [
                        _vm._l(_vm.recordObj, function (item, index) {
                          return [
                            _c(
                              "div",
                              { key: index + "a", staticClass: "week" },
                              [
                                _c("span", [_vm._v(_vm._s(item.day))]),
                                _c("span", [_vm._v(_vm._s(item.week))]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-timeline",
                              { key: index + "b" },
                              _vm._l(
                                _vm.formatFollows(item.follows),
                                function (activity, index) {
                                  return _c(
                                    "el-timeline-item",
                                    {
                                      key: index,
                                      attrs: {
                                        size: "normal",
                                        color:
                                          activity.createdAt === _vm.nowTime
                                            ? "red"
                                            : "",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "con-a" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "photo",
                                            on: {
                                              click: function ($event) {
                                                return _vm.showDetail(
                                                  activity.contactId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("el-avatar", {
                                              attrs: {
                                                src: activity.imagesUrl,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(activity.visitsNumber)
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "time-text",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.siteId
                                                ? activity.opSiteDetails
                                                : activity.opDetails
                                            ),
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "time" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(activity.time) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end",
                    },
                  },
                  [
                    _c("el-pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.recordObj && _vm.recordObj.length,
                          expression: "recordObj && recordObj.length",
                        },
                      ],
                      attrs: {
                        "current-page": _vm.pageNo.pageNum,
                        "page-sizes": [10, 20, 30, 50, 100],
                        background: "",
                        "pager-count": 5,
                        layout: "total, sizes, prev, pager, next",
                        total: _vm.pageCount,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.recordObj && !_vm.recordObj.length,
                        expression: "recordObj && !recordObj.length",
                      },
                    ],
                    staticClass: "content empty-tip",
                  },
                  [_vm._v("\n        暂无数据\n      ")]
                ),
              ],
              1
            )
          : _c("div", { staticStyle: {} }, [
              _vm.recordObj2 && _vm.recordObj2.length
                ? _c(
                    "div",
                    { staticClass: "content1" },
                    [
                      _c("dt-table", {
                        attrs: {
                          "v-if": _vm.dtKey,
                          "page-num": _vm.pageNo.pageNum,
                          "page-size": [10, 20, 30, 50, 100],
                          "is-select": false,
                          "table-data": _vm.recordObj2,
                          "is-show-page": true,
                          "is-add-earn-field": false,
                          "data-total": _vm.pageCount,
                          "is-count-height": false,
                          "hide-border": "",
                          "column-list": _vm.column,
                        },
                        on: {
                          sizeChange: _vm.handleSizeChange,
                          currentChange: _vm.handleCurrentChange,
                          sortChange: _vm.sortChange,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "imagesUrlRow",
                              fn: function (row) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showDetail(
                                            row.scope.data.contactId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("el-avatar", {
                                        attrs: {
                                          src: row.scope.data.imagesUrl,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "40px",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.scope.data.nickName) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "imagesUrlHeader",
                              fn: function (row) {
                                return [_c("span")]
                              },
                            },
                          ],
                          null,
                          false,
                          1549080373
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.recordObj && !_vm.recordObj.length,
                      expression: "recordObj && !recordObj.length",
                    },
                  ],
                  staticClass: "content empty-tip",
                },
                [_vm._v("\n        暂无数据\n      ")]
              ),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }