var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-common" }, [
    _vm.item.title
      ? _c("div", { staticClass: "g-title" }, [
          _c("div", { staticClass: "tit" }, [_vm._v(_vm._s(_vm.item.title))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.handleIsBorder(_vm.item)
      ? _c(
          "div",
          { staticClass: "g-box", style: _vm.boxStyle },
          [
            _c("div", { staticClass: "dot tl" }),
            _vm._v(" "),
            _c("div", { staticClass: "dot tr" }),
            _vm._v(" "),
            _c("div", { staticClass: "dot bl" }),
            _vm._v(" "),
            _c("div", { staticClass: "dot br" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "edit-icon",
                on: {
                  click: function ($event) {
                    return _vm.handleEdit(_vm.item)
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-edit-outline" })]
            ),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "no-border", style: _vm.boxStyle },
          [
            _c(
              "div",
              {
                staticClass: "edit-icon",
                on: {
                  click: function ($event) {
                    return _vm.handleEdit(_vm.item)
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-edit-outline" })]
            ),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }