var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "t-main",
      attrs: { "element-loading-text": "正在转成客户中，请稍后。。。" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "auto",
            model: _vm.formData,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "接收人", prop: "followUserId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择客户接收人" },
                  model: {
                    value: _vm.formData.followUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "followUserId", $$v)
                    },
                    expression: "formData.followUserId",
                  },
                },
                _vm._l(_vm.list, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "save-this-search" }),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.closeBtn } },
              [_vm._v("取消")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.confirmBtn },
              },
              [_vm._v("确认")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }