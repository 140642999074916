<template>
  <div class="createUploadTask-main">
    <div class="upload-main" :style="{ display: showUpload? 'flex' : '' }">
      <div class="upload">
        <el-upload class="upload-demo"
                   v-show="showUpload"
                   ref="upload"
                   drag
                   accept=".xlsx"
                   :headers="uploadHeaders"
                   :action="uploadAction"
                   :auto-upload="false"
                   :file-list="fileList"
                   :data="uploadData"
                   :on-success="uploadSuccess"
                   :on-error="uploadError"
                   :limit="1"
                   :multiple="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传xlsx文件</div>
        </el-upload>
      </div>
      <div v-show="!showUpload">
        <div class="div-select">
          请选择异常号码处理方式：
        </div>
        <el-form label-width="100px" size="small" label-position="left" style="padding-left: 20px">
          <el-form-item label="重复">
            <el-checkbox v-model="uploadData.filterRepeat">过滤</el-checkbox>
          </el-form-item>
          <el-form-item label="被我打过">
            <el-checkbox v-model="uploadData.filterMeCalled">过滤</el-checkbox>
          </el-form-item>
          <el-form-item label="被他人打过">
            <el-checkbox v-model="uploadData.filterOtherCalled">过滤</el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="showUpload" class="upload-tip">
        <div>
          <span>
          下载模板
          </span>
        </div>
        <div>
          <a download="a" :href="templateUrl">xlsx文件模板</a>
        </div>
      </div>
    </div>
    <div class="footer">
      <div>
        <el-button size="small" @click="cancelBtn">取消</el-button>
        <el-button size="small" type="primary" @click="confirmBtn" v-loading="loading">{{ this.showUpload ? '下一步' : '确定' }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { importWaiHu } from '@/api/customerpond'
export default {
  name: 'createUploadTask',
  data () {
    return {
      uploadAction: `${process.env.VUE_APP_BASE_API}/api/waihu/task/import`,
      uploadHeaders: null,
      templateUrl: 'https://dtbird-miniapp.oss-cn-shanghai.aliyuncs.com/tools/%E5%A4%96%E5%91%BC%E4%BB%BB%E5%8A%A1%E6%A8%A1%E6%9D%BF.xlsx',
      file: null,
      fileList: [],
      showUpload: true,
      loading: false,
      uploadData: {
        filterRepeat: false,
        filterMeCalled: false,
        filterOtherCalled: false,
      }
    }
  },
  mounted () {
    this.uploadHeaders = {
      Authorization: `Bearer ${ this.token }`
    }
  },
  computed: {
    token(){
      return this.$store.state.permission.token
    }
  },
  methods: {

    cancelBtn(){
      this.$store.dispatch('hideModal')
    },

    confirmBtn(){
      if(this.showUpload){
        const uploadFiles = this.$refs.upload.uploadFiles
        if(uploadFiles && uploadFiles.length){
          this.showUpload = false




        }else {
          this.$store.dispatch('showTip', {
            text: '请先上传文件',
            type: 'warning'
          })
        }
      }else {
        this.$refs.upload.submit()
      }
    },

    uploadSuccess(response, file, fileList){
      if(response.code === 60000){
        this.$store.commit('update', {
          target: this.$store.state.plugin.modal.options,
          data: {
            isOk: true
          }
        })
        this.$store.dispatch('showTip', {
          text: response.msg,
          type: 'success'
        })
        this.cancelBtn()
      }else {
        this.$store.dispatch('showTip', {
          text: response.msg,
          type: 'error'
        })
      }
    },

    uploadError(err, file, fileList){
      this.loading = false
      this.$store.dispatch('showTip', {
        text: '上传失败',
        type: 'error'
      })
      this.cancelBtn()
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.upload-main{
  padding: 10px 20px 10px 20px;
  box-sizing: border-box;
  min-height: 223px;
  height: auto;
}
.footer{
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding-right: 10px;
  box-sizing: border-box;
}
.footer:before{
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,.1);
  top: 0;
  left: 0;
  transform: scaleY(.5);
  position: absolute;
}
.upload-filter > div{
  padding: 20px 0 20px 15px;
  box-sizing: border-box;
}

.div-select{
  padding: 10px 0!important;
  box-sizing: border-box;
}

.upload-filter span{
  margin-right: 10px;
}
.upload{
  width: 75%;
  text-align: center;
}
.upload-tip{
  width: 25%;
}
.upload-tip div{
  text-align: center;
  width: 100%;
}
.upload-tip a{
  padding: 20px 0;
  box-sizing: border-box;
  display: inline-block;
  color: #409eff;
  text-decoration: underline;
}
.upload-tip span{
  font-size: 18px;
  font-weight: bold;
}
.el-upload__tip{
  text-align: left;
  color: red;
  padding-left: 25px;
}
.upload /deep/ .el-upload-list__item{
  text-align: left;
  color: red;
  padding-left: 20px;
}
</style>
