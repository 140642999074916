<template>
  <div class="modul-table-list">
    <div class="title">{{ moduleName }}</div>
    <el-table
      :data="tableData"
      resizable
      border
      ref="multipleTable"
      v-loading="showTableLoading"
      style="height:100%;"
    >
      <template slot="empty" v-if="!showTableLoading">
        <div
          style="min-height:400px;padding-top:100px;"
          v-loading="showTableLoading"
        ></div>
      </template>
      <div v-for="(column, index) in headerArr" :key="index + 's'">
        <el-table-column
          show-overflow-tooltip
          :width="!haveAttr(column.attr, 'width') ? '' : column.width"
        >
          <template slot="header">
            <p class="sort_list_cont">
              <span>
                {{ column.name }}
              </span>
            </p>
          </template>
          <template slot-scope="scope">
            <el-tooltip
              :visible-arrow="false"
              popper-class="popper"
              effect="dark"
              content="网站可访问"
              placement="bottom-start"
            >
              <span
                class="yun-icon7"
                v-if="column.href && !column.modal && scope.row['access']"
                style="color: rgb(158, 209, 101);"
              ></span>
            </el-tooltip>
            <span
              v-if="column.href && !column.modal"
              @click="goPageUrl(scope.row[column.href])"
              class="table-list-href"
              >{{ scope.row[column.field] }}</span
            >
            <span
              v-if="column.modal && column.href"
              @click="
                openModal(scope.row[column.href], scope.row[headerArr[0].field])
              "
              class="table-list-href"
              >{{ column.modal }}</span
            >
            <span
              v-if="!column.href && !column.modal"
              v-html="scope.row[column.field]"
            ></span>
            <span
              v-if="column.jump"
              @click="goPageUrl(scope.row[column.jump])"
              class="table-list-href"
              >{{ column.modal }}</span
            >
          </template>
        </el-table-column>
      </div>
    </el-table>
    <div style="height:40px;">
      <div class="page-div" v-if="pageStatus.total">
        <div style="float:right;">
          <footer-page
            @handleCurrentChange="handleCurrentChange"
            @goNumPage="goNumPage"
            :pageSizes="pageSizes"
            :pageStatus="pageStatus"
          >
          </footer-page>
        </div>
      </div>
    </div>
    <!-- <div class="touch-no-receive" v-if="!otherParams.isReceive && otherParams.shade"  v-loading="getLoading">
            <div class="touch-no-cont">
                <div class="touch-no-icon">
                    <span style="color: #008CD9;text-decoration: underline;cursor:pointer;" @click="getReveiveKey('phone')">领取</span>
                    <span> 以查看所有信息</span>
                </div>
            </div>
        </div> -->
    <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showDetail">
      <div slot="modalCont">
        <p v-html="recruitStr" style="padding: 20px 0;"></p>
      </div>
      <div slot="submit">
        <div class="grasp_go_pay">
          <span @click="graspGoPay">关闭</span>
        </div>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import footerPage from '@/components/Footerpage/index'
export default {
  props: {
    tableArr: {
      default: () => [],
      type: Array
    },
    headerArr: {
      default: () => [],
      type: Array
    },
    moduleName: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      showTableLoading: false,
      graspData: {},
      showDetail: false,
      recruitStr: '',
      pageStatus: {
        pageNo: 1,
        pageSize: 5,
        total: null
      },
      pageSizes: [5],
      tableData: []
    }
  },
  created() {
    this.decesidePage()
  },
  mounted() {},
  components: {
    diaModel,
    footerPage
  },
  methods: {
    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.decesidePage()
    },
    goNumPage(val) {
      this.pageStatus.pageNo = Number(val)
      this.decesidePage()
    },
    decesidePage() {
      this.pageStatus.total = this.tableArr.length
      const start = (this.pageStatus.pageNo - 1) * this.pageStatus.pageSize
      const end = this.pageStatus.pageNo * this.pageStatus.pageSize
      this.tableData = [...this.tableArr.slice(start, end)]
    },
    goPageUrl(val) {
      window.open(val)
    },
    openModal(val, name) {
      if (!val) {
        this.$message({
          type: 'warning',
          message: '暂无详情'
        })
        return
      }
      this.goRecruitDetail(val, name)
    },
    goRecruitDetail(val, name) {
      this.recruitStr = val
      this.recruitStr = this.recruitStr.replace(/；/g, '；<br/>')
      this.recruitStr = this.recruitStr.replace(/：/g, '：<br/>')
      this.recruitStr = this.recruitStr.replace(/。/g, '。<br/>')
      this.recruitStr =
        '<div' +
        ' style=' +
        'word-wrap:break-word' +
        '>' +
        this.recruitStr +
        '</div>'
      this.showDetail = true
      this.graspData = {
        dialogWidth: '700px',
        dialogTitle: name,
        dialogTop: '250px',
        showFoot: false,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.graspTip.init()
      })
    },
    graspGoPay() {
      this.showDetail = false
    },
    haveAttr(val, lag) {
      if (val) {
        const arr = val.split(',')
        if (arr.indexOf(lag) > -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.title {
  width: 100%;
  height: 59px;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #2e2e2e;
  line-height: 59px;
  padding-right: 36px;
}
.table-list-href {
  position: relative;
  left: 10px;
  color: #0099cc;
  cursor: pointer;
}
.modul-table-list {
  position: relative;
}
.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  opacity: 0.8;
  z-index: 100;
}
.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}
.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}
.touch-no-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yun-icon7::before {
  content: '';
  width: 15px;
  height: 15px;
  left: 1px;
  top: 16px;
  display: inline-block;
  position: absolute;
  background: url('../../assets/allowpic.png') no-repeat;
  background-size: 100% 100%;
}
</style>
