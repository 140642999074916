<template>
  <div class="app-container land-page-app">
    <div class="land-page-header">
      <div class="detail-title-land">
        <span class="color-41CABF click" @click="goBackPage()">营销落地页管理</span>
        <span>></span>
        <span>{{ currentName }}</span>
      </div>
      <div style="float:right;">
        <div class="search-botton" @click="previewLand" style="margin-left:10px;">预览</div>
        <div class="search-botton" @click="saveLandPage" style="margin-left:10px;">保存</div>
      </div>
    </div>
    <div class="land-page-cont">

      <el-row>
        <el-col :span="16">
          <div class="land-page-left">
            <page-show
              :pageDetail="landDetail"
              :acType="acType"
              :formName="formName"
              @savePageSuc="savePageSuc"
              @publicPageUrl="publicPageUrl"
              :fontStyle="fontStyle"
              ref="pageShow"
            ></page-show>
          </div>
        </el-col>

        <el-col :span="8">
          <div class="land-page-right">
            <edit-land @getUploadUrl="getUploadUrl" @changeFormName="changeFormName"></edit-land>
          </div>
        </el-col>

      </el-row>
    </div>
    <qc-code ref="userQcCode" :acCodeUrl="acCodeUrl"></qc-code>
    <land-prevw ref="userPrevw" :fontStyle="fontStyle" :currentId="currentId"></land-prevw>
  </div>
</template>

<script>
import PageShow from "./pageshow";
import EditLand from "./editland";
import diaModel from "@/components/Model";
import QcCode from "./qccode";
import LandPrevw from "./landprevw";
import { getMarketlandPageDetail } from "@/api/table";
export default {
  data() {
    return {
      back: "<",
      fontStyle: {
        videoStatus: "",
        photoStatus: "",
        videoUrl: "",
        uploadUrl: "",
        editCont: "",
        fontColor: "#000000",
        textareaCont: "",
        checkedArr: [],
        fontFamily: "Microsoft YaHei",
        fontSize: 14,
        buttonText: "",
        fontWeight: "normal",
        pagraBg: "#D8D8D8",
        textBg: "#D8D8D8",
        share: {
          title: "",
          desc: "",
          img: ""
        },
        show: {
          video: true,
          photo: true,
          content: true,
          input: true,
          button: true
        }
      },
      currentLandPageParams: "",
      dialogStatus: {},
      currentId: Number(localStorage.getItem("PageId")) || 0,
      formName: "",
      acCodeUrl: "",
      acType: "1",
      landDetail: {},
      haveSendPub: false,
      currentName: "新建落地页"
    };
  },
  components: {
    PageShow,
    EditLand,
    diaModel,
    QcCode,
    LandPrevw
  },
  created() {
    Object.assign(this.fontStyle, {}, {});
    if (this.currentId) {
      this.currentName = "编辑落地页";
    } else {
      this.currentName = "新建落地页";
    }
  },
  mounted() {
    this.getMarketlandPageDetail();
  },
  computed:{
    userId(){
      const userInfo = this.$store.state.user.accountInfo
      return userInfo['id']
    },

    user(){
      return this.$store.state.user.accountInfo;
    }
  },
  methods: {
    publicPageUrl(val) {
      if(val.indexOf('?') > -1){
        this.acCodeUrl = `${val}&userId=${ this.userId }`;
      }else {
        this.acCodeUrl = `${val}?userId=${ this.userId }`;
      }
      console.log(this.user)
      const userId = this.user.id;
      const pageObj = this.user.lanSite;
      const pageUrl = pageObj.pageUrl;
      const siteId = pageObj.id;
      const landPageId = localStorage.getItem('PageId');

      const res = `${ pageUrl }?userId=${userId}&siteId=${siteId}&landPageId=${ landPageId }`;
      console.log(res)
      // this.$refs.userQcCode.init(this.acCodeUrl)
      this.acCodeUrl = res

      this.$refs.userQcCode.init(res);
    },
    openSubmit(val) {
      if (this.formName) {
        this.$refs.pageShow.saveLandPage(this.formName);
      } else {
        this.$message({
          type: "warning",
          message: "请输入落地页名称"
        });
      }
    },
    savePageSuc(val) {
      this.currentId = Number(val);
      // this.$refs.deleteMark.subimtCancle();
    },
    changeFormName(val) {
      this.formName = val;
    },
    getUploadUrl(val) {
      const newVal = JSON.parse(JSON.stringify(val));
      if (newVal.buttonText === "") {
        newVal.buttonText = "请输入按钮文案";
      }
      if (newVal.share.title === "") {
        newVal.share.title = "请输入落地页标题";
      }

      Object.assign(this.fontStyle, {}, newVal);
    },
    goBackPage() {
      this.$router.push("/landpage/landpage");
    },
    previewLand() {
      this.$nextTick(() => {
        this.$refs.userPrevw.init({ formName: this.formName });
      });
    },
    saveLandPage() {
      if (this.formName) {
        this.acType = "1";
        if (this.currentId) {
          this.$refs.pageShow.saveLandPage(this.formName);
        } else {
          this.openSubmit();
        }
      } else {
        this.$message({
          type: "warning",
          message: "请输入落地页标题"
        });
      }
    },
    sendPublish() {
      this.acType = "2";
      if (this.currentId) {
        if (this.landDetail.status === 2) {
          this.acCodeUrl = this.landDetail.pageUrl;
          this.$refs.userQcCode.init(this.landDetail.pageUrl);
        } else {
          this.$refs.pageShow.saveLandPage(this.formName);
        }
      } else {
        this.openSubmit();
      }
    },
    getMarketlandPageDetail() {
      if (!this.currentId) {
        return;
      }
      const params = {
        id: this.currentId
      };
      getMarketlandPageDetail(params).then(res => {
        if (res.code === 60000) {
          this.landDetail = res.data;
          this.formName = res.data.formName;
          if (res.data.status === 2) {
            this.haveSendPub = true;
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.land-page-right {
  width: 100%;
  /*float: right;*/
  height: 100%;
  padding: 30px 0 0 0;
}
.land-page-left {
  width: 100%;
  /*float: left;*/
  height: 100%;
  /*padding: 76px 36px 0;*/
  padding: 0 20px;
  box-sizing: border-box;
}
.land-page-left2 {
  width: 100%;
  float: left;
  height: 100%;
  padding: 76px 36px 0;
}
.land-page-cont {
  background: white;
  width: 100%;
  margin-top: 4px;
  height: calc( 100% - 50px );
  overflow: hidden;
}
.land-page-app {
  width: 100%;
  /*min-width: 1200px;*/
}
.land-page-header {
  height: 50px;
  width: 100%;
  background: white;
  padding: 0 20px;
}
.search-botton {
  width: 70px;
  height: 30px;
  float: left;
  position: relative;
  top: 10px;
  margin-left: 20px;
  background-color: #78d9d1;
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
.detail-title-land {
  height: 50px;
  background-color: #ffffff;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  display: inline-block;
  line-height: 50px;
  padding-left: 21px;
  padding-right: 20px;
  color: #8a8a8a;
  margin-bottom: 4px;
  cursor: pointer;
}
</style>
