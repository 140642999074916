<template>
  <div class="body">
    <div v-show="!showCode" class="form">
      <el-form :model="form" label-width="80px" ref="form" :rules="rules">
        <el-form-item label="企业ID" prop="appId">
          <el-input v-model="form.appId" placeholder="企业ID"></el-input>
        </el-form-item>

        <el-form-item label="Secret" prop="secret">
          <el-input v-model="form.secret" placeholder="Secret"></el-input>
        </el-form-item>

        <el-form-item label="AgentId" prop="agentId">
          <el-input v-model="form.agentId" placeholder="AgentId"></el-input>
        </el-form-item>

        <el-form-item class="no-margin">
          <div class="btn">
            <el-button size="small" type="info" @click="close">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small"
              >确认</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <el-popover
        visible-arrow="true"
        placement="bottom-start"
        width="240"
        trigger="hover"
      >
        <div class="code-tip">
          <span>
            微信扫码查看
          </span>
        </div>
        <el-link type="info" underline slot="reference">如何获取?</el-link>
      </el-popover>
    </div>
    <div v-show="showCode" class="from form-wx " ref="code">
      <div id="wx_reg" v-show="showErCode"></div>
      <div v-show="showPercent" class="progress">
        <div>
          正在导入联系人，请稍后
        </div>
        <div>
          <el-progress
            :text-inside="true"
            :stroke-width="30"
            :percentage="percentage"
          ></el-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addWeChatContact } from '@/api/pool'
export default {
  name: 'contactForWx',
  data() {
    return {
      showTip: false,
      // type: 3, // 1:需要填写表单 2:直接显示二维码 3:直接导入联系人
      form: {
        // appId: 'wwd74f35acffd5fda1',
        // secret: 'zlo1kI5_gPiiJ33BcUDLz3dUTwyI9EmCrif99eCHF04',
        // agentId:'1000004',
        appId: '',
        secret: '',
        agentId: '',
        code: null,
        accessToken: null
      },
      url: 'http%3a%2f%2ftest.dtbird.cn%2f%23%2fcollection%2fcontact',
      showErCode: false,
      showCode: false,
      showPercent: false,
      percentage: 0,
      timer: null,
      rules: {
        appId: [{ required: true, message: '请输入appId', trigger: 'change' }],
        secret: [
          { required: true, message: '请输入secret', trigger: 'change' }
        ],
        agentId: [
          { required: true, message: '请输入agentId', trigger: 'change' }
        ]
      },
      status: null
    }
  },

  created() {
    const isProd = process.env.NODE_ENV !== 'development'
    if (isProd) {
      this.url = 'https%3a%2f%2fai.dtbird.cn%2f%23%2fcollection%2fcontact'
    }
  },

  mounted() {
    const modal = this.$store.state.plugin.modal.options
    this.status = modal.type
    if (modal.type === 1 || !modal.type) {
      this.showCode = false
      this.showErCode = false
      this.showPercent = false
    }
    if (modal.type === 2) {
      this.showCode = true
      this.showErCode = true
      this.showPercent = false

      this.$nextTick(() => {
        window.WwLogin({
          id: 'wx_reg',
          appid: modal.appId,
          agentid: modal.agentId,
          redirect_uri: this.url,
          state: true,
          href: ''
        })
      })
    }
    if (modal.type === 3) {
      this.showCode = true
      this.showErCode = false
      this.showPercent = true
      this.getWeChatContact(true)
    }
  },

  watch: {
    $route(router) {
      if (router.query.code) {
        // 清除路由参数
        this.$router.replace(router.path)
        this.showErCode = false
        this.showPercent = true
        this.form.code = router.query.code
        // 请求接口
        this.getWeChatContact()
      }
    }
  },

  methods: {
    isShowTip() {
      this.showTip = true
    },

    // 获取微信联系人
    getWeChatContact(canNullParams = false) {
      this.setPercentage()
      const params = {
        corpId: this.form.appId,
        agentId: this.form.agentId,
        agentSecret: this.form.secret,
        code: this.form.code,
        status: this.status
      }
      addWeChatContact(canNullParams ? { status: this.status } : params)
        .then(res => {
          if (res && res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success',
              duration: 2000
            })
            // 关闭弹框后刷新列表
            this.$store.commit('update', {
              target: this.$store.state.plugin.modal.options,
              data: {
                refresh: true
              }
            })
            // 更新weChatPlugin数据
            if (this.status === 1) {
              this.$store.commit('update', {
                target: this.$store.state.plugin.weChatPlugin,
                data: {
                  appId: params.corpId,
                  secret: params.agentSecret,
                  agentId: params.agentId
                }
              })
            }
            // 关闭弹框
            this.percentage = 100
            this.$store.dispatch('hideModal')
          } else {
            this.$store.dispatch('hideModal')
          }
        })
        .catch(() => {
          this.$store.dispatch('showTip', {
            text: '导入联系人失败',
            type: 'error',
            duration: 2000
          })
          this.$store.dispatch('hideModal')
        })
    },

    // 加载条
    setPercentage() {
      let that = this
      this.timer = setInterval(() => {
        if (that.percentage <= 90) {
          that.percentage += 10
        }
      }, 5)
    },

    //提交信息 显示二维码
    onSubmit() {
      this.$refs['form'].validate(validator => {
        if (validator) {
          this.showCode = true //隐藏表单
          this.showErCode = true //显示二维码
          this.$nextTick(() => {
            window.WwLogin({
              id: 'wx_reg',
              appid: this.form.appId,
              agentid: this.form.agentId,
              redirect_uri: this.url,
              state: true,
              href: ''
            })
          })
        }
      })
    },

    // 手动关闭弹框
    close() {
      this.$store.dispatch('hideModal')
    }
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped>
.body {
  min-height: 150px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.form {
  min-height: 150px;
  height: auto;
  width: 100%;
}
.form-wx {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.btn {
  display: flex;
  justify-content: flex-end;
}
.no-margin {
  margin-bottom: 0 !important;
}
.code-tip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  box-sizing: border-box;
  height: 200px;
  width: 100%;
  background: url('../../../assets/con-wexin.png') no-repeat top 10px center;
  background-size: 150px 150px;
}
.code-tip span {
  transform: translateY(10px);
}
.title {
  height: 50px;
  width: 100%;
  background-color: #41cabf;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.el-dialog-dt ::v-deep .el-dialog__header,
.el-drawer-dt ::v-deep .el-drawer__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}
.el-drawer-dt ::v-deep .el-drawer__header {
  margin-bottom: 0 !important;
}

.el-dialog-dt ::v-deep .el-dialog__close,
.el-drawer-dt ::v-deep .el-drawer__close {
  color: #fff;
}

.el-dialog-dt ::v-deep .el-dialog__body,
.el-drawer-dt ::v-deep .el-drawer__body {
  padding: 0 !important;
}
</style>
