<template>
  <div class="app-container">
    <div class="detail-title">
      <span class="color-41CABF click" @click="goBackPage()">我的培育池</span>
      <span>></span>
      <span>{{ favoriteName }}</span>
      <div v-if="showProcess" class="download-process">
        <span>当前领取任务进度</span>
        <el-progress
          :percentage="processLine"
          :stroke-width="8"
          class="layui-progress-bar"
        ></el-progress>
      </div>
    </div>
    <div class="thread-bag" style="margin-bottom: 4px;">
      <el-input
        v-model="searchInput"
        @focus="focusInputEnter"
        @blur="blurInputEnter"
        placeholder="输入公司名称查询"
        class="input"
      ></el-input>
      <div class="search-botton" @click="searchBtn">查询</div>
      <div
        class="search-botton"
        @click="resetSearch"
        style="background: rgb(144, 147, 153); margin-left: 10px;"
      >
        重置
      </div>
      <div class="search-senior" @click="searchPlus">高级筛选</div>
      <div class="filtrate-body" @click="searchPlus">
        <svg-icon icon-class="filtrate" class="filtrate" />
      </div>

      <el-input
        v-model="showCondition"
        class="input w-320 m-l-26"
        style="margin-left:10px;"
        v-if="condition"
      >
        <el-button
          slot="append"
          icon="el-icon-circle-close"
          @click="resetSearch()"
        ></el-button>
      </el-input>
      <div class="show_num_use">
        <div
          class="search-botton_upload"
          style="background:#78D9D1"
          @click="goExportList"
          v-dt-permission="'客户培育-线索培育-导出线索'"
        >
          <p class="btn_title_upload">
            导出列表
          </p>
        </div>

        <div
          class="search-botton_upload"
          style="background:#78D9D1"
          @click="goExportBtn"
          v-dt-permission="'客户培育-线索培育-导出线索'"
        >
          <p class="btn_title_upload">
            导出线索
          </p>
        </div>
        <div
          class="search-botton_upload"
          style="background:#78D9D1;"
          @click="goUploadList"
          v-dt-permission="'客户培育-线索培育-导入线索'"
        >
          <p class="btn_title_upload">
            导入列表
          </p>
        </div>
        <div
          class="search-botton_upload"
          style="background:#78D9D1;"
          @click="goUploadBtn"
          v-dt-permission="'客户培育-线索培育-导入线索'"
        >
          <p class="btn_title_upload">
            导入线索
          </p>
        </div>

        <div
          class="search-botton_upload"
          style="background:#78D9D1"
          @click="goAddClue"
          v-dt-permission="'客户培育-线索培育-新建线索'"
        >
          <p class="btn_title_upload">
            新建线索
          </p>
        </div>
      </div>
    </div>
    <div class="thread-bag">
      <el-button
        @click="trans_client"
        :loading="transLoading"
        size="small"
        style="margin: 10px;background: #78d9d1;color:#fff"
        v-dt-permission="'客户培育-线索培育-转成客户'"
      >
        转成客户
      </el-button>
      <el-button
        size="small"
        style="margin: 10px 0;background: #78d9d1;color:#fff"
        @click="aKeyGetAll"
      >
        一键领取
      </el-button>
      <el-button
        size="small"
        style="margin: 10px;background: #78d9d1;color:#fff"
        @click="allCancelData"
        v-dt-permission="'智能获客-推荐线索-加入培育'"
      >
        移除培育
      </el-button>
      <el-button
        size="small"
        style="margin: 10px 0;background: #78d9d1;color:#fff"
        @click="changeKeyGroup"
        v-dt-permission="'客户培育-线索培育-改变分组'"
      >
        改变分组
      </el-button>

      <!-- <div
        class="search-botton"
        style="background:#78D9D1;width: 80px!important;"
        @click="addWaiHu"
        v-dt-permission="'客户培育-线索培育-加入外呼任务'"
      >
        加入外呼任务
      </div> -->

      <span class="view show_collent_tip" style="height:100%;">
        <el-checkbox
          v-model="noCustomer"
          @change="checkCollentItem($event, 'no')"
          >未转客户</el-checkbox
        >
      </span>
      <span class="view show_collent_tip" style="height:100%;">
        <el-checkbox
          v-model="hasCustomer"
          @change="checkCollentItem($event, 'has')"
          >已转客户</el-checkbox
        >
      </span>
      <span class="view show_collent_tip" style="height:100%;">
        <el-checkbox v-model="hasConsumption" @change="checkCollentItem"
          >未领取</el-checkbox
        >
      </span>
      <span class="view show_collent_tip" style="height:100%;">
        <el-checkbox v-model="hasWebsite" @change="checkCollentItem"
          >网站可访问</el-checkbox
        >
      </span>
      <span class="view show_collent_tip" style="height:100%;">
        <el-checkbox v-model="hasJob" @change="checkCollentItem"
          >有招聘信息</el-checkbox
        >
      </span>
      <span
        class="view show_collent_tip"
        style="height:100%;"
        v-dt-permission="'客户培育-线索培育-智能画像分析'"
      >
        <el-checkbox v-model="profileMatch" @change="checkCollentItem"
          >客户画像匹配</el-checkbox
        >
      </span>
    </div>

    <div ref="showDialogCall" class="call-change-status" v-if="showChangeStaus">
      <p
        class="change_call_status change_call_icon1"
        @click="changeStatusSelf(0)"
      >
        将状态改为 未拨打
        <span class="change_status_btn"></span>
      </p>
      <p
        class="change_call_status change_call_icon2"
        @click="changeStatusSelf(1)"
      >
        将状态改为 已接通
        <span class="change_status_btn"></span>
      </p>
      <p
        class="change_call_status change_call_icon3"
        @click="changeStatusSelf(2)"
      >
        将状态改为 未接通
        <span class="change_status_btn"></span>
      </p>
    </div>
    <div ref="checkDialogMark" class="check-change-mark" v-if="checkChangeMark">
      <p
        style="height: 70px;display:inline-block;overflow: hidden;text-overflow: ellipsis;"
      >
        {{ currentCheckMark.comment }}
      </p>
      <p
        class="change_mark_btn"
        style="text-align: center;"
        @click="changeMarkBtn"
      >
        修改备注
      </p>
    </div>
    <div class="line-body m-t-4">
      <div v-if="!(listinfo && listinfo.length)" class="line-body-nodata"></div>
      <el-table
        border
        :data="listinfo"
        v-loading="getListLoading"
        ref="myCollectSelect"
        @selection-change="selectionChange"
        @select-all="selectAll"
        empty-text="暂无数据"
        style="width: 100%; font-size: 12px;"
        @sort-change="sortChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop width="70" show-overflow-tooltip label>
          <template slot-scope="props">
            <div class="w-2" style="cursor:pointer;">
              <svg-icon
                icon-class="collect2"
                style="font-size: 30px"
                class="collect-icon"
                @click="
                  cancelCollectData(props.row.packageId, props.row.companyUuid)
                "
              />
              <svg-icon
                style="font-size: 24px"
                v-dt-permission="'客户培育-线索培育-智能画像分析'"
                icon-class="customerpic"
                class="collect-icon"
                @click="openProfile(props.row, props.$index)"
                v-if="props.row.match"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="companyName" label="公司名称" width="220">
          <template slot-scope="props">
            <span
              :class="yztLogic(props.row.hasIdentify)"
              @click="
                goDetailPage(props.row, props.$index, props.row.companyUuid)
              "
              >{{ props.row.companyName }}
            </span>
            <Tags :tagList="props.row.tags" tagOffset="200" />
            <RiskTag :riskTag="props.row.risk" />
            <span
              style="color:#f00;font-size:12px"
              v-if="props.row.hasIdentify == 0"
              >未识别</span
            >
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="修正前公司名称">
          <template slot-scope="props">
            <span>{{ props.row.reviseCompanyName }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="activity_class"
          align="center"
          show-overflow-tooltip
          label="活跃度"
          :sortable="'custom'"
        >
          <template slot-scope="props">
            <span class="hover-go-detail">{{ props.row.activity_class }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="gongyingshang"
          align="center"
          show-overflow-tooltip
          label="云服务商"
          width="200"
          v-if="showHot"
        >
          <template slot-scope="props">
            <span class="hover-go-detail">{{ props.row.gongyingshang }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="hot"
          align="center"
          show-overflow-tooltip
          label="热度"
          :sortable="'custom'"
          v-if="showHot"
        >
          <template slot-scope="props">
            <span class="hover-go-detail">{{ props.row.hot }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="location"
          align="center"
          show-overflow-tooltip
          label="领取状态"
        >
          <template slot-scope="props">
            <span class="hover-go-detail">{{
              props.row.show ? '已领取' : '未领取'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="registeredCapital"
          show-overflow-tooltip
          align="center"
          label="来源"
        >
          <template slot-scope="props">
            <span class="hover-go-detail">{{ props.row.frominfo }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="location"
          align="center"
          show-overflow-tooltip
          label="线索等级"
        >
          <template slot-scope="props">
            <span class="hover-go-detail">{{ props.row.gradeinfo }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="location"
          align="center"
          show-overflow-tooltip
          label="备注"
        >
          <template slot-scope="props">
            <span class="hover-go-detail">{{ props.row.comment }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="edit" align="center" label="操作">
          <template slot-scope="scope">
            <div class="remove-i">
              <span
                class="com-list-detail"
                @click="editClue(scope.row, scope.$index)"
                v-dt-permission="'客户培育-线索培育-培育线索-编辑'"
                >编辑</span
              >
              <span v-dt-permission="'客户培育-线索培育-培育线索-编辑'">|</span>
              <span
                class="com-list-detail"
                @click="openProfile(scope.row, scope.$index)"
                v-dt-permission="'客户培育-线索培育-智能画像分析'"
                >智能画像分析</span
              >
              <span v-dt-permission="'客户培育-线索培育-智能画像分析'">|</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-footer-book" v-if="pageStatus.total">
        <div style="float:right;">
          <footer-page
            @handleCurrentChange="handleCurrentChange"
            @goNumPage="goNumPage"
            @changePageSize="changePageSize"
            :pageStatus="pageStatus"
            :page-sizes="[10, 20, 50, 100, 300]"
          ></footer-page>
        </div>
      </div>
    </div>
    <p class="warn" style="margin-top:20px;">
      数据有误？请
      <span style="color: #2b8dfd;cursor: pointer" @click="errorShowClick">
        点击 </span
      >这里
    </p>
    <div class="open_table evaluate-table">
      <el-dialog
        :visible.sync="dialogVisible"
        width="600px"
        top="25vh"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <div class="title">备注</div>
        <div class="content">
          <div class="evaluate-tag">
            <div
              v-for="(item, index) in biaoqian"
              @click="chooseAppraise(index)"
              :class="currentPro === index ? '' : 'no-choose-colo'"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
          <el-input
            type="textarea"
            :rows="5"
            style="width:80%;margin-top:20px;"
            maxlength="250"
            placeholder="请输入内容(最多填写250个字符)"
            v-model="textarea"
          ></el-input>
          <br />
          <i
            style="height:20px;padding-top:5px;font-size:12px;vertical-align:top;color: red;"
            >剩余{{ 250 - textarea.length }}字符</i
          >
        </div>
        <div class="footer">
          <el-button @click="submitAppraise">提交</el-button>
        </div>
      </el-dialog>
    </div>

    <div class="open_table evaluate-table">
      <el-dialog
        :visible.sync="phoneShow"
        width="34.72%"
        top="25vh"
        :before-close="handleClose"
      >
        <div class="title">请您绑定您要拨打电话的手机号</div>
        <div class="content">
          <valid-phone
            ref="getChangePhone"
            @phoneCodeIsSucc="phoneCodeIsSucc"
          ></valid-phone>
          <div class="search-tag m-t-36">您将使用此手机号进行呼叫</div>
        </div>
        <div class="footer">
          <el-button @click="submitChangePhoneCode">提交</el-button>
        </div>
      </el-dialog>
    </div>

    <div class="open_table evaluate-table">
      <el-dialog
        :visible.sync="callPhone"
        width="34.72%"
        top="25vh"
        :before-close="handleClosetip"
      >
        <div class="title">
          您将使用
          <span>{{ iconCallPhone }}</span
          >拨打
          <span
            v-if="currentSelectData && currentSelectData.length"
            style="color:red;"
            >客户数量: {{ currentSelectData.length }}</span
          >
        </div>
        <div class="content h-121">
          <div class="search-tag">
            服务商将会通过双呼系统拨通此电话，您的电话号码不会被泄露
          </div>
          <p>
            <span>{{ currentCallData.companyName || '' }}</span
            >---
            <span>{{ currentCallData.mobile || '' }}</span>
          </p>
          <p v-if="callTime" class="show_call_time">
            {{ callTime }}
            <span class="show_call_tip" v-if="callResult"
              >({{ callResult }})</span
            >
          </p>
        </div>
        <div class="footer">
          <div v-if="currentCalling === '1'">
            <el-button @click="outCallData">拨打</el-button>
            <el-button @click="callPhone = false">再想想</el-button>
          </div>
          <div v-if="currentCalling === '3'">
            <el-button @click="handleClosetip">关闭</el-button>
          </div>
          <div v-if="currentCalling === '2'">
            <el-button
              @click="outCallDataNext"
              :disabled="nextDisabled"
              style="width: 100px;"
              >拨打下一个</el-button
            >
            <el-button @click="handleClosetip">关闭</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="open_table evaluate-table">
      <el-dialog
        :visible.sync="errorshow"
        width="34.72%"
        top="25vh"
        :before-close="handleClose"
      >
        <div class="title">请您填写数据有误处</div>
        <div class="content">
          <div class="evaluate-textarea">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="commitContent"
              :maxlength="1000"
            ></el-input>
            <el-input
              style="margin-top:10px;"
              v-model="commitPhone"
              class="search-input"
              placeholder="请留下您的联系方式"
              :maxlength="50"
            ></el-input>
          </div>
        </div>
        <div class="footer">
          <el-button @click="submitProject" style="background:#41CABF;"
            >提交</el-button
          >
        </div>
      </el-dialog>
    </div>
    <call-status
      ref="startCall"
      @HavePhoneCall="HavePhoneCall"
      @noPhoneCall="noPhoneCall"
      @currentCallData="currentCallDataC"
      @phoneCallingTo="phoneCallingTo"
      @startComputTime="startComputTime"
      @CallNoAnswer="CallNoAnswer"
      @passPhoneCall="passPhoneCall"
      :callParams="callParams"
    ></call-status>
    <dia-model
      ref="user_model"
      :dialogStatus="dialogStatus"
      v-if="showModel"
      @submitContBtn="submitContBtn"
    >
      <div slot="modalCont">是否确认移除培育？</div>
    </dia-model>
    <!-- 线索余量可用不足 -->
    <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showGrasp">
      <div slot="modalCont">
        <div class="show_grasp_text">
          <span>本月赠送条数剩余：{{ accEarnNum.mounth }} 条;</span>
          <br />
          <span>不限时条数: {{ accEarnNum.permanent }} 条;</span>
          <br />
          <span
            v-if="!$store.getters.userInfo.isAdmin"
            style="color:red;display:inline-block;margin-top:20px;"
            >请联系主管理员充值</span
          >
        </div>
      </div>
      <div slot="submit">
        <div class="grasp_go_pay">
          <span @click="graspGoPay">{{
            $store.getters.userInfo.isAdmin ? '充值' : '确定'
          }}</span>
        </div>
      </div>
    </dia-model>
    <!-- 提示领取线索 -->
    <dia-model
      ref="graspTipCall"
      :dialogStatus="graspDataCall"
      @submitContBtn="submitContBtnCall"
      v-if="showGraspCall"
    >
      <div slot="modalCont">
        <div class="show_grasp_text">
          <span>当前公司未领取，是否领取？</span>
        </div>
      </div>
    </dia-model>
    <!-- 上传文件 -->
    <dia-model
      ref="uploadUserData"
      :dialogStatus="uploadDataModel"
      @submitContBtn="submitUploadBtn"
      :btnLoading="uploadLoading"
      v-if="showUpload"
    >
      <!-- :headers="uploadHeaders" -->
      <div slot="modalCont">
        <div class="upload_cont_out">
          <el-upload
            class="upload-demo"
            drag
            ref="uploadListInput"
            :before-upload="beforeUpload"
            :headers="uploadHeaders"
            :action="actionUrl"
            :data="{ favoriteId: favoriteId }"
            :file-list="fileList"
            :on-success="uploadSuccess"
            :on-change="uploadFileChange"
            :on-error="uploadError"
            :auto-upload="false"
            multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击导入</em>
            </div>
            <div class="el-upload__tip" slot="tip" style="color: red;">
              仅支持导入xlsx文件
            </div>
          </el-upload>
          <div class="down_load_demo">
            <p class="down_load_title">下载模板</p>
            <p class="down_load_xlsx" @click="goDownFile">Excel文件模板</p>
          </div>
        </div>
      </div>
    </dia-model>
    <dia-model
      ref="sureApply"
      :dialogStatus="dialogApplyStatus"
      v-if="showApply"
      @submitContBtn="ApplyContBtn"
    >
      <div slot="modalCont">只能导出培育池中已领取的线索数据。</div>
    </dia-model>
    <!-- 一键领取 -->
    <dia-model
      ref="allKeyTip"
      :dialogStatus="allKeyData"
      :btnLoading="sAllKeyLoading"
      @submitContBtn="submitAllKeyBtn"
      v-if="showAllKey"
    >
      <div slot="modalCont">
        <div class="show_grasp_text" style="text-align: left">
          <span v-if="currentSelectData.length"
            >领取选中的{{ currentSelectData.length }}条线索，是否确认？</span
          >

          <span v-else>领取当前培育池中的所有线索，是否确认？</span>
        </div>
      </div>
    </dia-model>
    <!-- 改变分组 -->
    <mark-group
      @removeSuccess="removeSuccess"
      :currentId="Number(favoriteId)"
      :chooseArr="currentSelectData"
      ref="markGroupModel"
    ></mark-group>
    <dia-model
      ref="addClue"
      :dialogStatus="addClueStatus"
      @submitContBtn="addClueBtn"
      v-if="showAddClue"
    >
      <div
        slot="modalCont"
        style="height:auto;padding-left:20px;padding-top:20px;"
      >
        <el-form ref="form" label-width="auto">
          <el-form-item label="公司名称" required>
            <el-input
              placeholder="请输入公司名称"
              :disabled="!!this.clueInfo.collectionId"
              v-model="clueInfo.companyName"
            ></el-input>
          </el-form-item>
          <el-form-item label="来源">
            <el-select
              placeholder="请选择来源"
              v-model="clueInfo.source"
              style="width: 100%;"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in allSource"
                :key="index + 'a'"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="线索等级">
            <el-select
              placeholder="请选择线索等级"
              v-model="clueInfo.grade"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in allClue"
                :key="index + 'a'"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              placeholder="请输入备注"
              v-model="clueInfo.remarks"
              maxlength="100"
              type="textarea"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </dia-model>
    <!-- 转成客户 -->
    <dia-model
      ref="transToCus"
      :dialogStatus="transToCusStatus"
      @submitContBtn="submitTransBtn"
    >
      <div slot="modalCont" v-loading="showTransResult">
        <div
          v-if="transToCusStatus['template']"
          style="margin: 20px 0 40px;padding-left: 30px"
        >
          <span>转至：</span>
          <el-select
            v-model="currentTransCusUserId"
            default-first-option
            filterable
            placeholder="选择客户接收人"
          >
            <el-option
              v-for="item in transCus"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="trans-to-cus" v-else>
          <p>请选择转客户模式：</p>
          <el-radio v-model="chooseTransType" label="1"
            >模式一：转到对应跟进人名下，无跟进人转至公海</el-radio
          >
          <el-radio v-model="chooseTransType" label="2"
            >模式二：全部转到自己名下</el-radio
          >
        </div>
      </div>
    </dia-model>
    <user-profile
      ref="userPro"
      :customerInfo="customerInfo"
      :title="selectTitle"
      v-if="userProState"
    />
  </div>
</template>

<script>
import {
  collection_list,
  collection_comment,
  getSearchList,
  collectionProcess,
  collection_remove,
  error_commit,
  changeStatusPhone,
  getBindPhone,
  postConsumpTion
} from '@/api/thread'
import taskList from '@/views/collect/dialog/taskList'
import { getMode, addClue, editClue, setCostormer } from '@/api/user'
import { getKeyNum, postConSum } from '@/api/subaccount'
import footerPage from '@/components/Footerpage/index'
import callStatus from '@/components/Callstatus'
import diaModel from '@/components/Model'
import { changePhone, getAccountInfo } from '@/api/usercenter'
import validPhone from '@/components/Validphone'
import { setTimeout } from 'timers'
import { getPversionPrice, postFileUpload, postExport } from '@/api/pay'
import MarkGroup from './markGroup'
import { postMoveFavoritesGroup } from '@/api/table'
import PePopover from '@/components/Peculiar/popover'
import { getCusTagsDdic } from '@/api/customerpond'
import { booklistFilter } from '@/api/highsearch'
import userProfile from '@/views/customerpond/userProfile'
import reportErrorData from '@/views/collect/dialog/reportErrorData'
import bindMobile from '@/views/usercenter/dialog/bindMobile'
import searchBreed from '@/views/collect/search/searchBreed'
import uploadTaskList from '@/views/collect/dialog/uploadTaskList'
import PeiYuList from '@/views/map/dialog/PeiYuList'
import showTip from '@/views/collect/dialog/showTip'
import keyNoGet from '@/views/mykey/addWaiHu/keyNoGet'
import filterWaiHu from '@/views/mykey/addWaiHu/filterWaiHu'
import RiskTag from '@/components/Peculiar/risk'
import { ResetAuthUserMeal } from '@/utils/auth'
import { enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'BookList',
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
      transLoading: false,
      processLine: 0,
      timer: 0,
      showProcess: false,
      show_tab: 2,
      back: '<',
      callPhone: false,
      dialogVisible: false,
      errorshow: false,
      list: null,
      commitContent: '',
      commitPhone: '',
      showChangeStaus: false,
      checkChangeMark: false,
      dialogStatus: {},
      graspData: {},
      callParams: [],
      currentCalling: '1',
      getListLoading: false,
      nextDisabled: false,
      input1: '',
      currentPro: '',
      phoneShow: false,
      listLoading: true,
      accountType: this.$store.getters.accountType,
      authSub: this.$store.getters.sub,
      info: [1, 2, 3, 4, 5, 6, 7, 8],
      listinfo: [],
      pageStatus: {
        pageNo: 1,
        pageSize: sessionStorage.bookPageSize || 20,
        total: null
      },
      pageInfo: {
        pageSize: 10,
        currIndex: 1
      },
      selectTag: [1],
      options: [
        {
          value: 'HTML',
          label: 'HTML'
        },
        {
          value: 'CSS',
          label: 'CSS'
        },
        {
          value: 'JavaScript',
          label: 'JavaScript'
        }
      ],
      value: [],
      searchInput: '',
      checked1: false,
      currentPage4: 1,
      textarea: '',
      currentSelectData: [],
      iconCallId: '',
      iconCallPhone: '',
      showModel: false,
      showGrasp: false,
      sAllKeyLoading: false,
      currenCancle: null,
      biaoqian: [],
      markRow: {},
      currentChangePhoneList: {},
      currentCheckMark: {},
      currentCallingData: {},
      currentCallData: {},
      isIndeterminate: false,
      EnterEvent: null,
      currentFocus: false,
      accEarnNum: {},
      currentGrasp: {},
      graspDataCall: {},
      showGraspCall: false,
      callTime: 0,
      callResult: null,
      showUpload: false,
      uploadDataModel: {},
      actionUrl: `${process.env.VUE_APP_BASE_API}/api/collection/import`,
      fileList: [],
      dialogApplyStatus: {},
      showApply: false,
      allKeyData: {},
      showAllKey: false,
      favoriteId: sessionStorage.getItem('favoriteId') || 0,
      userId: sessionStorage.getItem('collect_userId') || '',
      userSelectAllArr: [],
      userBookMarkList: [],
      favoriteName: '',
      allLevel: [],
      allSource: [],
      allStatus: [],
      allClue: [],
      addClueStatus: {},
      showAddClue: false,
      clueInfo: {
        companyName: '',
        source: '',
        grade: '',
        remarks: ''
      },
      chooseTransType: '',
      showTransResult: false,
      transToCusStatus: {},
      uploadHeaders: {},
      customerInfo: {},
      selectTitle: '',
      userProState: false,

      noCustomer: false,
      hasCustomer: false,
      hasConsumption: false,
      hasWebsite: false,
      hasJob: false,
      profileMatch: false, //客户画像匹配
      uploadLoading: false,
      editRow: {},
      condition: '',
      showCondition: '',
      filterData: [],
      filterTagNameObj: {},
      showHot: true
    }
  },
  watch: {
    condition: function(val) {
      if (!val) {
        this.showCondition = ''
      } else {
        if (!Object.keys(this.filterTagNameObj).length) {
          this.getFilterList(() => {
            let res = []
            let filterList = val.split(';')
            filterList.forEach(item => {
              const data = item.split(':')
              if (this.filterTagNameObj[data[0]]) {
                res.push(
                  `${this.filterTagNameObj[data[0]]}:${data[1]}:${data[2]}`
                )
              }
            })
            this.showCondition = res.join(';')
          })
        } else {
          let res = []
          let filterList = val.split(';')
          filterList.forEach(item => {
            const data = item.split(':')
            if (this.filterTagNameObj[data[0]]) {
              res.push(
                `${this.filterTagNameObj[data[0]]}:${data[1]}:${data[2]}`
              )
            }
          })
          this.showCondition = res.join(';')
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'collection') {
      sessionStorage.setItem('listOutPage', '0')
    } else {
      sessionStorage.setItem('listOutPage', '1')
    }
    next()
  },
  created() {
    this.uploadHeaders = {
      Authorization: 'Bearer ' + this.$store.state.permission.token,
      ContentType: 'multipart/form-data'
    }
    this.favoriteId = sessionStorage.getItem('favoriteId')
    this.favoriteName = sessionStorage.getItem('collectName')
    const haveCollect = localStorage.getItem(`collectParams${this.favoriteId}`)
    if (haveCollect) {
      const params = JSON.parse(haveCollect)
      this.condition = params.condition
      this.pageStatus.pageNo = params.pageNo
      this.pageStatus.pageSize = params.pageSize
      this.searchInput = params.searchInput
      this.noCustomer = params.noCustomer
      this.hasCustomer = params.hasCustomer
      this.hasConsumption = params.hasConsumption
      this.hasWebsite = params.hasWebsite
      this.hasJob = params.hasJob
      this.profileMatch = params.profileMatch
    }
    this.getFilterList()
    this.getKeyNum()
    this.getCusTagsDdic('1')
    this.getCusTagsDdic('2')
    this.getCusTagsDdic('3')
    this.getCusTagsDdic('4')
    this.getSearchList() //第一页,每页10
    this.getCompanyProcInstry()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.showProcess = false
  },
  computed: {
    filterQy() {
      return this.$store.state.plugin.modal.options.filterQy
    },
    filterRange() {
      return this.$store.state.plugin.modal.options.dataRang
    }
  },
  components: {
    footerPage,
    callStatus,
    diaModel,
    validPhone,
    MarkGroup,
    userProfile,
    PePopover,
    RiskTag
  },
  methods: {
    yztLogic(isVip) {
      if (isVip == 1) {
        return 'company_name'
      } else {
        return 'company_not'
      }
    },
    addWaiHu() {
      if (!(this.currentSelectData && this.currentSelectData.length)) {
        this.$store.dispatch('showTip', {
          text: '请选择要加入外呼任务的数据',
          type: 'warning'
        })
        return
      }
      let isGet = false
      this.$store.dispatch('showModal', {
        title: '提示',
        view: keyNoGet,
        size: '400px',
        onClose: res => {
          if (res.isNext) {
            const loading = this.$loading({
              lock: true,
              text: '正在导入',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            const t = setTimeout(() => {
              if (res.checked) isGet = true
              this.filterWaiHu(isGet, 'bookList')
              loading.close()
              clearTimeout(t)
            }, 500)
          }
        }
      })
    },

    filterWaiHu(isGet = false, type = 'one') {
      this.$store.dispatch('showModal', {
        title: '提示',
        view: filterWaiHu,
        options: {
          consumption: isGet,
          collectionIds: this.currentSelectData
            .map(i => i.collectionId)
            .join(','),
          type: type
        },
        size: '600px',
        onClose: res => {
          if (res.isOk) {
            this.currentSelectData = []
            this.$refs.myCollectSelect.clearSelection()
          }
        }
      })
    },

    sortChange(e) {
      console.log(e)
      if (e.order == 'ascending') {
        this.sort = e.prop + ':升序'
      } else if (e.order == 'descending') {
        this.sort = e.prop + ':降序'
      } else {
        this.sort = ''
      }
      this.searchBtn()
    },
    searchPlus() {
      this.getFilterList(() => {
        this.$store.dispatch('showModal', {
          title: '高级筛选',
          view: searchBreed,
          size: '800px',
          options: {
            source: this.filterData,
            type: 'booklist'
          },
          onClose: res => {
            if (res.refresh) {
              this.pageStatus.pageNo = 1
              this.pageStatus.pageSize = 20
              this.condition = res.searchFilter
              this.getSearchList()
            }
          }
        })
      })
    },

    // 获取过滤条件
    getFilterList(fn) {
      const id = sessionStorage.getItem('favoriteId')
      booklistFilter({ favoriteId: id }).then(res => {
        if (res.code === 60000) {
          this.filterData = res.data || []
          this.filterData.forEach(item => {
            this.filterTagNameObj[item.filterColumn] = item.filterName
            typeof fn === 'function' ? fn() : ''
          })
        }
      })
    },

    checkCollentItem(e, item = '') {
      if (this.noCustomer && item == 'no') {
        this.hasCustomer = false
      }
      if (this.hasCustomer && item == 'has') {
        this.noCustomer = false
      }
      this.pageStatus.pageNo = 1
      this.pageStatus.pageSize = 20
      this.getSearchList()
    },
    openProfile(val) {
      console.log(val)
      this.selectTitle = '智能画像分析-' + val.companyName
      this.userProState = false
      this.customerInfo = {}
      Object.assign(this.customerInfo, val, { pathType: 2 })

      this.$nextTick(() => {
        this.userProState = true
        this.$nextTick(() => {
          this.$refs.userPro.init()
        })
      })
    },
    submitTransBtn() {
      const userId = this.currentTransCusUserId
      if (this.fromPath === 'package' && !this.chooseTransType) {
        this.$message({
          message: '请选择要转成客户的模式',
          type: 'warning'
        })
        return
      }
      let props = {}
      if (
        this.fromPath === '/globalquery/globalquery' &&
        this.transToCusStatus.template === 2
      ) {
        if (userId === '' || userId === null || userId === -1) {
          this.$message({
            message: '请选择要转至的账号',
            type: 'warning'
          })
          return
        }
        props.type = 1
        props.followUserIds = this.currentTransCusUserId
      }
    },
    trans_client() {
      if (!this.currentSelectData.length) {
        this.$message({
          message: '请选择要转成客户的数据',
          type: 'warning'
        })
        return
      }
      let str = ''
      for (let i in this.currentSelectData) {
        str +=
          this.currentSelectData[i].packageId +
          '-' +
          this.currentSelectData[i].companyName +
          ','
      }
      str = str.slice(0, -1)
      const params = Object.assign({ packCompName: str })
      this.transLoading = true

      setCostormer(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.searchBtn()
        }
        this.transLoading = false
      })
    },
    openTransCusModel(transToCusStatus) {
      this.chooseTransType = ''
      this.showTransCont = true
      const diaObj = {
        dialogWidth: '550px',
        dialogTitle: '转成客户',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.transToCusStatus = Object.assign(diaObj, transToCusStatus)
      this.$nextTick(() => {
        this.$refs.transToCus.init()
      })
    },
    editClue(row, index) {
      this.editRow = row
      this.showAddClue = true
      this.clueInfo.companyName = row.companyName
      this.clueInfo.source = row.fromId
      this.clueInfo.grade = row.gradeId
      this.clueInfo.remarks = row.comment
      this.clueInfo.tagsId = row.tagsId
      this.clueInfo.collectionId = row.collectionId
      this.addClueStatus = {
        dialogWidth: '500px',
        dialogTitle: '编辑线索',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.addClue.init()
      })
    },
    addClueBtn() {
      if (!this.clueInfo.companyName) {
        this.$message({
          message: '请输入公司名称',
          type: 'warning'
        })
        return
      }
      if (this.clueInfo.collectionId) {
        const params = Object.assign(this.clueInfo, {})
        editClue(params).then(res => {
          if (res.code === 60000) {
            this.$message({
              message: res.msg,
              type: 'success'
            })

            // 伪更新 列表数据
            this.$store.commit('update', {
              target: this.editRow,
              data: {
                gradeinfo: res.data.gradeinfo,
                frominfo: res.data.frominfo,
                comment: res.data.comment
              }
            })
            this.showAddClue = false
          }
        })
      } else {
        const params = Object.assign(this.clueInfo, {
          favoriteId: this.favoriteId
        })
        addClue(params).then(res => {
          if (res.code === 60000) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.showAddClue = false
            this.searchBtn()
          }
        })
      }
    },
    goAddClue() {
      this.showAddClue = true
      this.clueInfo = { companyName: '', source: '', grade: '', remarks: '' }
      this.addClueStatus = {
        dialogWidth: '500px',
        dialogTitle: '新建线索',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.addClue.init()
      })
    },
    // 2.2.22版本修改新建客户字段，增加线索等级状态，修改name字段为type type=1 客户等级 type=2 来源，type=3 状态，type=4 线索等级
    getCusTagsDdic(val) {
      const params = {
        type: val
      }
      getCusTagsDdic(params).then(res => {
        if (res.code === 60000) {
          if (val === '1') {
            this.allLevel = res.data
          }
          if (val === '2') {
            this.allSource = res.data
          }
          if (val === '3') {
            this.allStatus = res.data
          }
          if (val === '4') {
            this.allClue = res.data
          }
        }
      })
    },
    removeSuccess() {
      this.getSearchList()
    },
    changeKeyGroup() {
      if (!(this.listinfo && this.listinfo.length)) {
        this.$message({
          message: '当前文件夹暂无数据',
          type: 'warning'
        })
        return
      }
      if (!(this.currentSelectData && this.currentSelectData.length)) {
        this.$message({
          message: '请选择要移动的数据',
          type: 'warning'
        })
        return
      }

      this.$store.dispatch('showModal', {
        title: '选择培育池',
        view: PeiYuList,
        size: '650px',
        onClose: res => {
          if (res.refresh) {
            const fobj = res.favoriteObj
            this.getListLoading = true
            const params = {
              newfavoritesId: fobj.id,
              oldfavoritesId: this.favoriteId,
              companyUuids: this.currentSelectData
                .map(item => item.companyUuid)
                .toString()
            }
            postMoveFavoritesGroup(params).then(res => {
              this.currentSelectData = []
              if (res.code === 60000) {
                this.resetSearch()
                this.$store.dispatch('showTip', {
                  text: res.msg,
                  type: 'success'
                })
              }
            })
          }
        }
      })
    },
    addBookSuccess() {
      this.$refs.bookMarkRef.refresh()
    },
    addNewBook() {
      this.$refs.addBookModel.init()
    },
    //上传文件  导出已有线索
    showExport() {
      getAccountInfo({}).then(async res => {
        if (res.code === 60000) {
          console.log('reg123')
          await this.$store.dispatch('setAccountInfo', res.data)
          await this.$store.dispatch('showModal', {
            view: showTip,
            title: '导出线索',
            size: '720px',
            options: {
              count: this.currentSelectData.length,
              favoriteId: this.favoriteId,
              currentSelectData: this.currentSelectData,
              total: this.pageStatus.total
            },
            onClose: res => {
              if (res.confirm) {
                this.ApplyContBtn(
                  res.checked,
                  res.selectVal,
                  res.radio,
                  res.status
                )
              }
            }
          })
        }
      })
    },
    ApplyContBtn(checked, selectValue, dataRange, status) {
      if (!(this.listinfo && this.listinfo.length)) {
        this.$message({
          message: '当前账户暂未移除培育公司',
          type: 'warning'
        })
        return
      }
      this.showApply = false
      const arr = []
      for (const i in this.currentSelectData) {
        const obj = {}
        obj.packageId = this.currentSelectData[i].packageId
        obj.companyUuid = this.currentSelectData[i].companyUuid
        obj.favoriteId = this.favoriteId
        arr.push(obj)
      }
      const params = {
        favoriteId: this.favoriteId,
        packageIdAndcompanyuuid: arr.length ? JSON.stringify(arr) : '',
        favoriteName: this.favoriteName,
        filterHasExport: selectValue,
        filterInvalidPhone: checked,
        filterAbnormal: status,
        dataRange
      }

      postExport(params).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
          this.goExportList()
        }
      })
    },
    goExportBtn() {
      this.showExport()
    },

    // 展示下载列表
    goExportList() {
      this.$store.dispatch('showModal', {
        title: '导出列表',
        view: taskList,
        size: '900px'
      })
    },
    // 下载模板文件
    goDownFile() {
      window.open(
        'https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/%20company/template.xlsx'
      )
    },

    goUploadBtn() {
      this.fileList = []
      this.showUpload = true
      this.uploadDataModel = {
        dialogWidth: '600px',
        dialogTitle: '导入已有线索',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.uploadUserData.init()
      })
    },

    goUploadList() {
      this.$store.dispatch('showModal', {
        title: '导入列表',
        view: uploadTaskList,
        size: '900px',
        onClose: res => {
          if (res.refresh) {
            this.favoriteId = sessionStorage.getItem('favoriteId')
            this.favoriteName = sessionStorage.getItem('collectName')
            // 刷新培育池
            this.resetSearch()
          }
        }
      })
    },

    beforeUpload(file) {
      const name = file.name
      const index = name.indexOf('.')
      const fileType = name.substring(index + 1, name.length)
      if (fileType === 'xlsx') {
        this.uploadLoading = true
        return true
      } else {
        this.$message({
          message: '请选择正确得文件格式、 xlsx文件！',
          type: 'warning'
        })
        return false
      }
    },
    uploadFileChange(file, fileList) {
      if (fileList && fileList.length) {
        if (fileList.length === 2) {
          this.fileList = []
          this.fileList.push(file)
        }
      }
    },
    uploadSuccess(res) {
      this.uploadLoading = false
      if (res.code === 60000) {
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.$refs.uploadListInput.clearFiles()
        this.showUpload = false
        this.goUploadList()
      } else {
        this.$message({
          message: res.msg,
          type: 'warning'
        })
        this.$refs.uploadListInput.clearFiles()
      }
    },
    uploadError() {
      this.uploadLoading = false
      this.$message({
        message: res.msg,
        type: 'error'
      })
    },
    postFileUpload(val) {
      const params = new FormData()
      params.append('file', val)
      params.append('favoriteId', this.favoriteId)
      postFileUpload(params).then(res => {})
    },
    submitUploadBtn() {
      this.$refs.uploadListInput.submit()
    },
    // 计时
    startComputTime(val) {
      this.callTime = val
      this.callResult = null
    },
    CallNoAnswer(val) {
      this.callResult = val
    },
    // 充值流量
    graspGoPay() {
      this.showGrasp = false
      if (!this.$store.getters.userInfo.isAdmin) {
        return
      }
      this.$store.dispatch('changeUserBuyType', '1')
      this.$store.dispatch('changeUserBuy', true)
    },
    // 获取剩余流量
    getKeyNum() {
      getKeyNum().then(res => {
        if (res.code === 60000) {
          this.accEarnNum = res.data
        }
      })
    },
    userGraspModel() {
      this.showGrasp = true
      this.graspData = {
        dialogWidth: '500px',
        dialogTitle: '您的线索流量余额不足',
        dialogTop: '250px',
        showFoot: false,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.graspTip.init()
      })
    },
    // 消耗线索
    postConSum() {
      if (this.accEarnNum.mounth <= 0) {
        if (this.accEarnNum.permanent <= 0) {
          // 弹出充值框
          this.userGraspModel()
          return
        }
      }
      const params = {
        companyUuid: this.currentGrasp.companyUuid,
        packageId: this.currentGrasp.packageId
      }
      postConSum(params).then(res => {
        if (res.code === 60000) {
          this.searchBtn()
          this.getKeyNum()
          if (this.showGraspCall) {
            this.showGraspCall = false
          }
        }
      })
    },
    // 用户抓取数据
    async userGraspPhone(val) {
      this.currentGrasp = val
      const data = await ResetAuthUserMeal()
      if (!data.hasBase) {
        this.$store.dispatch('setGoBuyMeal', true)
        return
      }
      this.postConSum()
    },
    // 续费线索套餐
    goBuyMeal() {
      const params = {
        type: 's3'
      }
      getPversionPrice(params).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('changeUserBuyType', '1')
          this.$store.dispatch('changeUserBuy', true)
        }
      })
    },
    postConsumpTion() {
      let companyUuids = ''
      for (let i in this.currentSelectData) {
        companyUuids +=
          this.currentSelectData[i].packageId +
          '-' +
          this.currentSelectData[i].companyUuid +
          ','
      }
      companyUuids = companyUuids.slice(0, -1)
      const params = {
        companyUuids: companyUuids,
        favoriteId: this.favoriteId
      }
      this.$refs.allKeyTip.subimtCancle()
      this.showProcess = true
      this.processLine = 0
      postConsumpTion(params)
        .then(() => {})
        .catch(err => {
          console.log(err, 222)
        })
      this.timer = setInterval(() => {
        this.getProcess()
      }, 1000)
    },
    // 提交领取服务
    submitAllKeyBtn() {
      this.postConsumpTion()
    },
    //领取成功
    async getProcess() {
      const data = await collectionProcess({ favoriteId: this.favoriteId })
      if (data.code === 60000) {
        if (data.data.percent == 100) {
          clearInterval(this.timer)
          this.showProcess = false
          this.$message.success(
            `成功:${data?.data?.successCnt}条,失败${data?.data?.failure}条`
          )
          this.getKeyNum()
          this.getSearchList()
        }
        this.processLine = data?.data?.percent
      }
    },
    // 一键领取提示
    getAllKeyTip() {
      this.showAllKey = true
      this.allKeyData = {
        dialogWidth: '500px',
        dialogTitle: '一键领取线索',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.allKeyTip.init()
      })
    },
    // 当前可用线索条数
    currentKeyUse() {
      const allNum = this.accEarnNum.mounth + this.accEarnNum.permanent
      if (allNum < 0) {
        this.$store.dispatch('changeUserBuyType', '1')
        this.$store.dispatch('changeUserBuy', true)
      } else {
        this.getAllKeyTip()
      }
    },
    // 一键领取
    aKeyGetAll() {
      if (
        this.$store.state.plugin.env === 'dd' &&
        !this.$store.state.user.accountInfo.mobile
      ) {
        this.$store.dispatch('showModal', {
          title: '绑定手机号',
          view: bindMobile,
          size: '600px'
        })
        return
      }
      if (!(this.listinfo.length && this.listinfo.length)) {
        this.$message({
          message: '暂无数据可领取',
          type: 'warning'
        })
        return
      }
      const params = {
        type: 's3'
      }
      getPversionPrice(params)
        .then(res => {
          if (res.code === 60000) {
            this.currentKeyUse()
          }
        })
        .catch(() => {
          console.log('报错了')
        })
    },
    reset_company() {
      localStorage.removeItem(`collectParams${this.favoriteId}`)
      this.pageStatus.pageNo = 1
      this.pageStatus.pageSize = 20
      this.noCustomer = false
      this.hasCustomer = false
      this.hasConsumption = false
      this.hasWebsite = false
      this.hasJob = false
      this.searchInput = ''
      this.searchBtn()
    },
    // 回车搜索
    focusInputEnter() {
      const _this = this
      _this.currentFocus = true
      addEventListener('keydown', function(e) {
        if (_this.currentFocus) {
          let key = e.keyCode
          if (key == 13) {
            _this.searchBtn()
          }
        }
      })
    },
    // 结束回车搜索
    blurInputEnter() {
      this.currentFocus = false
    },
    goDetailPage(val, index) {
      const haveCollect = localStorage.getItem(
        `collectParams${this.favoriteId}`
      )
      localStorage.setItem('bookListScrollCache', haveCollect)
      enterCompanyDetail(val, this.listinfo, index)
    },
    currentCallDataC(val) {
      this.currentCallData = val
    },
    passPhoneCall() {
      this.phoneNoGrasp()
    },
    submitChangePhoneCode() {
      this.$refs.getChangePhone.getCheckPhoneCode('T')
    },
    phoneCodeIsSucc(params) {
      changePhone(params).then(res => {
        if (res.code === 60000) {
          this.phoneShow = false
          this.getBindPhone()
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 电话无抓取
    submitContBtnCall() {
      this.userGraspPhone(this.currentCallData)
    },
    phoneNoGrasp() {
      this.showGraspCall = true
      this.graspDataCall = {
        dialogWidth: '400px',
        dialogTitle: '领取线索',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.graspTipCall.init()
      })
    },
    // 电话回拨
    phoneCallingTo(val) {
      this.currentCalling = val
    },
    // 单个无手机关闭
    noPhoneCall() {
      this.callPhone = false
    },
    // 单个拨打中
    HavePhoneCall() {
      this.currentCalling = '3'
    },
    // 获取绑定手机号
    getBindPhone() {
      getBindPhone({}).then(res => {
        this.iconCallPhone = res.data.mobile
      })
    },
    // 修改备注
    changeMarkBtn() {
      this.checkChangeMark = false
      this.textarea = this.currentCheckMark.comment
      this.dialogVisible = true
      this.markRow = this.currentCheckMark
      // 单选标签
      this.currentPro = ''
      this.biaoqian.forEach((item, i) => {
        if (this.currentCheckMark.commentOption) {
          if (this.currentCheckMark.commentOption.indexOf(item.name) >= 0) {
            this.currentPro = i
            return
          }
        }
      })
    },
    openMarkBtn(val) {
      this.currentCheckMark = val
      this.changeMarkBtn()
    },
    // 查看修改备注
    checkMarkBtn(val, e) {
      this.currentCheckMark = val
      if (this.checkChangeMark) {
        this.checkChangeMark = false
      } else {
        this.checkChangeMark = true
        this.$nextTick(() => {
          const ele = this.$refs.checkDialogMark
          ele.style.top = e.clientY - 60 + 'px'
          ele.style.left = e.clientX - 280 + 'px'
        })
      }
    },
    // 查询搜索数据
    searchBtn() {
      this.pageStatus.pageNo = 1
      this.getSearchList()
    },
    resetSearch() {
      this.searchInput = ''
      this.pageStatus.pageNo = 1
      this.pageStatus.pageSize = 20
      this.noCustomer = false
      this.hasCustomer = false
      this.hasConsumption = false
      this.hasWebsite = false
      this.hasJob = false
      this.profileMatch = false
      this.condition = ''
      localStorage.removeItem('filterList')
      this.getSearchList()
    },
    // btn 搜索数据
    getSearchList() {
      this.getListLoading = true
      const params = {
        companyName: this.searchInput,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        favoriteId: this.favoriteId,
        noCustomer: this.noCustomer ? 1 : 0,
        hasCustomer: this.hasCustomer ? 1 : 0,
        hasConsumption: this.hasConsumption ? 1 : 0,
        hasWebsite: this.hasWebsite ? 1 : 0,
        hasJob: this.hasJob ? 1 : 0,
        profileMatch: this.profileMatch,
        condition: this.condition,
        sort: this.sort
      }
      const collect = {
        searchInput: this.searchInput,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        favoriteId: this.favoriteId,
        noCustomer: this.noCustomer,
        hasCustomer: this.hasCustomer,
        hasConsumption: this.hasConsumption,
        hasWebsite: this.hasWebsite,
        hasJob: this.hasJob,
        profileMatch: this.profileMatch,
        condition: this.condition
      }
      sessionStorage.setItem(`collectLeaveInfo`, JSON.stringify(collect))
      localStorage.setItem(
        `collectParams${this.favoriteId}`,
        JSON.stringify(params)
      )
      getSearchList(params).then(res => {
        this.getListLoading = false
        if (res.code == 60000) {
          this.showHot = res.data.show
          this.listinfo = res.data.list
          this.currentSelectData = []
          if (res.data.page) {
            this.pageStatus.pageNo = Number(res.data.page.pageNo)
            this.pageStatus.pageSize = Number(res.data.page.pageSize)
            this.pageStatus.total = Number(res.data.page.total)
          }
          if (!this.callPhone) {
            this.listinfo = res.data.list
          }
          // 判断拨打电话是否领取，领取更新打电话弹框
          if (this.callPhone) {
            const index = this.currentCallData.index
            this.listinfo.forEach(ele => {
              if (ele.companyUuid === this.currentCallData.companyUuid) {
                Object.assign(this.currentCallData, {}, ele)
                if (this.currentSelectData && this.currentSelectData.length) {
                  Object.assign(this.currentSelectData[index], {}, ele)
                }
              }
            })
            this.$refs.startCall.setCallPhone()
          }
        }
      })
    },
    // 手动修改状态
    changeStatusPhone(val) {
      const params = {
        companyUuid: this.currentChangePhoneList.companyUuid,
        mobile: this.currentChangePhoneList.mobile || '',
        status: val
      }
      changeStatusPhone(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.showChangeStaus = false
          this.searchBtn()
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
          this.showChangeStaus = false
        }
      })
    },
    cancleGet() {
      this.showModel = true
      this.dialogStatus = {
        dialogWidth: '350px',
        dialogTitle: '提示',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.user_model.init()
      })
    },
    // 手动修改电话状态
    changeStatusSelf(val) {
      this.changeStatusPhone(val)
    },
    // 跳转页数
    goNumPage(val) {
      this.pageStatus.pageNo = Number(val)
      this.getSearchList()
    },
    // 改变页数大小
    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.getSearchList()
    },
    changePageSize(val) {
      sessionStorage.setItem('bookPageSize', val)
      this.pageStatus.pageSize = val
      this.getSearchList()
    },

    errorShowClick() {
      this.$store.dispatch('showModal', {
        title: '请填写数据有误处',
        size: '600px',
        view: reportErrorData
      })
    },

    // 反馈错误数据
    submitProject() {
      if (!this.commitContent) {
        this.$message({
          message: '请填写数据有误处内容',
          type: 'warning'
        })
        return
      }
      if (!this.commitContent) {
        this.$message({
          message: '请填写联系方式',
          type: 'warning'
        })
        return
      }
      let data = {
        content: this.commitContent,
        mobile: this.commitPhone
      }
      error_commit(data).then(res => {
        if (res.code == 60000) {
          this.errorshow = false
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        }
      })
    },
    // 选择评价
    chooseAppraise(val) {
      this.currentPro = val
    },
    // 提交评价
    submitAppraise() {
      var appraise = ''
      var tagArr = []
      this.biaoqian.forEach((item, index) => {
        tagArr.push(index)
      })
      if (tagArr.indexOf(this.currentPro) < 0) {
        this.$message({
          message: '请选择标签',
          type: 'warning'
        })
        return
      }
      appraise = this.biaoqian[this.currentPro].name
      const params = {
        companyUuid: this.markRow.companyUuid,
        comment: this.textarea,
        commentOption: appraise
      }
      collection_comment(params).then(res => {
        this.dialogVisible = false
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.searchBtn()
      })
    },
    // 添加备注
    addListRemark(index, val) {
      this.textarea = ''
      this.dialogVisible = true
      this.markRow = val
      this.currentPro = ''
    },
    // 获取公司业务产品 和产品行业
    getCompanyProcInstry() {
      const params = {
        type: ['CO_01'].toString()
      }
      getMode(params).then(res => {
        this.biaoqian = res.data.CO_01
      })
    },
    // 图标拨打
    userCallData(val, id, data, index) {
      if (!this.iconCallPhone) {
        this.phoneShow = true
        return
      }
      this.callTime = null
      this.callResult = null
      this.callParams = []
      this.currentCallData = data
      this.currentCallingData = data
      const param = data
      this.currentCalling = '1'
      this.callParams.push(param)
      this.$nextTick(() => {
        this.callPhone = true
        this.$refs.startCall.init()
      })
    },
    outCallData() {
      this.nextDisabled = true
      setTimeout(() => {
        this.nextDisabled = false
      }, 1000)
      this.$refs.startCall.setCallPhone()
    },
    outCallDataNext() {
      this.callResult = null
      this.nextDisabled = true
      setTimeout(() => {
        this.nextDisabled = false
      }, 1000)
      this.$refs.startCall.setCallPhoneNext()
    },
    // 多的多选
    duodeBtn() {
      if (this.checked1) {
        this.listinfo.forEach((item, index) => {
          this.$refs.myCollectSelect.toggleRowSelection(item, true)
        })
      } else {
        this.listinfo.forEach((item, index) => {
          this.$refs.myCollectSelect.toggleRowSelection(item, false)
        })
      }
    },
    // 选择表格
    selectionChange(val) {
      this.currentSelectData = val
      if (val.length === this.listinfo.length) {
        this.isIndeterminate = false
        this.checked1 = true
        return
      }
      if (val.length && val) {
        if (val.length < this.listinfo.length) {
          this.isIndeterminate = true
          this.checked1 = false
          return
        }
      } else {
        this.isIndeterminate = false
        this.checked1 = false
      }
    },
    // 批量拨打
    allCallData() {
      this.callTime = 0
      this.callResult = null
      this.callParams = this.currentSelectData
      this.currentCalling = '1'
      if (this.callParams && this.callParams.length) {
        this.currentCallData = this.currentSelectData[0]
        this.currentCallData.index = 0
        this.$nextTick(() => {
          this.callPhone = true
          this.$refs.startCall.init()
        })
        if (!this.currentCallData.show) {
          this.phoneNoGrasp()
        }
      } else {
        this.$message({
          message: '请选择要批量拨打的数据！',
          type: 'warning'
        })
        return
      }
    },
    selectAll(val) {
      if (val && val.length) {
        this.checked1 = true
      } else {
        this.checked1 = false
      }
    },
    allCancelData() {
      const arr = []
      for (const i in this.currentSelectData) {
        const obj = {}
        obj.packageId = this.currentSelectData[i].packageId
        obj.companyUuid = this.currentSelectData[i].companyUuid
        arr.push(obj)
      }
      this.currenCancle = arr
      if (arr && arr.length) {
        this.cancleGet()
      } else {
        this.$message({
          message: '请选择要移除培育的数据！',
          type: 'warning'
        })
        return
      }
    },
    submitContBtn() {
      this.collection_remove()
    },
    // 移除培育
    cancelCollectData(id, val) {
      const arr = []
      const obj = {}
      obj.packageId = id
      obj.companyUuid = val
      arr.push(obj)
      this.currenCancle = arr
      this.cancleGet()
    },
    // 取消批量移除培育
    collection_remove() {
      if (!(this.currenCancle && this.currenCancle)) {
        this.$message({
          message: '请选择要移除培育的数据！',
          type: 'warning'
        })
        return
      }
      const params = {
        favoriteId: this.$route.query.favoriteId,
        packageIdAndcompanyuuid: JSON.stringify(this.currenCancle)
      }
      collection_remove(params).then(res => {
        this.showModel = false
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.searchBtn()
      })
    },
    fetchData() {
      this.getListLoading = true
      let data = {
        currIndex: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize
      }
      const collect = {
        searchInput: null,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize
      }
      localStorage.setItem(
        `collectParams${this.favoriteId}`,
        JSON.stringify(collect)
      )
      collection_list(data).then(res => {
        this.getListLoading = false
        if (res.code == 60000) {
          this.listinfo = res.data.list
          if (res.data.page) {
            this.pageStatus.pageNo = Number(res.data.page.pageNo)
            this.pageStatus.pageSize = Number(res.data.page.pageSize)
            this.pageStatus.total = Number(res.data.page.total)
          }
        }
      })
    },
    change_tab(number) {
      this.show_tab = number
    },
    handleClosetip() {
      this.dialogVisible = false
      this.phoneShow = false
      this.callPhone = false
      this.errorshow = false
      this.getSearchList()
    },
    handleClose() {
      this.dialogVisible = false
      this.phoneShow = false
      this.callPhone = false
      this.errorshow = false
      this.getSearchList()
    },
    addTag() {
      this.selectTag.push(this.selectTag.length)
    },
    deleteTag(index) {
      this.selectTag.splice(index, 1)
    },
    showCall(index, e, obj) {
      this.currentChangePhoneList = obj
      if (this.showChangeStaus) {
        this.showChangeStaus = false
      } else {
        this.showChangeStaus = true
        this.$nextTick(() => {
          const ele = this.$refs.showDialogCall
          ele.style.top = e.clientY - 60 + 'px'
          ele.style.left = e.clientX - 280 + 'px'
        })
      }
    },
    closeCall(index) {
      this.listinfo[index]['call'] = false
    },
    showRemark(index) {
      for (let index in this.listinfo) {
        this.listinfo[index]['remark'] = false
      }
      this.listinfo[index]['remark'] = true
    },
    closeRemark(index) {
      this.listinfo[index]['remark'] = false
    },
    goBackPage() {
      this.$router.push('/collection/collection')
    }
  }
}
</script>
<style lang="scss">
.page-footer-book {
  position: fixed;
  bottom: 0;
  right: 28px;
  width: 100%;
  height: 40px;
  padding: 5px 0;
  background: #fff;
  z-index: 222;
}
</style>
<style scoped>
@import '../../styles/threadpx.scss';
.thread-bag .view {
  cursor: initial;
}
.remove-i span:last-child {
  display: none !important;
}

.detail-title {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 1200px;
  background-color: #ffffff;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a8a8a;
  margin-bottom: 4px;
}
.download-process {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.download-process .el-progress {
  width: 200px;
}
.download-process span {
  display: inline-block;
  font-size: 12px;
  color: #78d9d1;
}
.layui-progress-bar ::v-deep .el-progress-bar__inner:before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  animation: move 1s linear infinite;
}
@keyframes move {
  to {
    background: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.3) 0,
        rgba(255, 255, 255, 0.3) 12.5%,
        transparent 0,
        transparent 25%
      )
      0/60px 60px;
  }
  from {
    background: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.3) 12.5%,
        rgba(255, 255, 255, 0.3) 25%,
        transparent 0%,
        transparent 37.5%
      )
      0/60px 60px;
  }
}
.layui-progress-bar ::v-deep .el-progress__text {
  font-size: 10px !important;
}

.company_name {
  cursor: pointer;
  color: #0099cc;
  display: inline-block;
  height: 100%;
}
.company_not {
  pointer-events: none;
}
.btn_title_upload span {
  display: inline-block;
  margin-left: 1px;
}
.down_load_demo {
  position: absolute;
  width: 150px;
  top: 0;
  right: 0;
}
.down_load_title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.down_load_xlsx {
  margin: 20px 0;
  text-align: center;
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.down_load_txt {
  margin: 20px 0;
  text-align: center;
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.detail-title span {
  padding-right: 8px;
}
.upload_cont_out {
  position: relative;
}
.upload_cont_out ::v-deep .el-upload-dragger {
  border: 1px dashed rgba(65, 202, 191, 1) !important;
}
.show_call_time {
  font-size: 20px;
  color: black;
  margin-top: 20px;
}
.show_call_tip {
  font-size: 16px;
  color: red;
}
.el-table ::v-deep .el-table__empty-block {
  height: 410px !important;
  position: relative;
}
.el-table ::v-deep .el-table__body-wrapper {
  min-height: 440px !important;
  padding-left: 4px;
  padding-right: 4px;
}
.el-table ::v-deep .el-table__body td {
  padding: 0 !important;
  height: 41px;
}
.mark-show-elp {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.now-all-type ::v-deep .el-checkbox__label {
  font-size: 12px !important;
  color: #78d9d1 !important;
}
.call-change-status {
  position: absolute;
  padding: 10px 14px 15px 12px;
  border: 1px solid #41cabf;
  height: 80px;
  background: white;
  width: 180px;
  top: 50px;
  z-index: 2000;
}
.check-change-mark {
  position: absolute;
  padding: 10px 14px 15px 12px;
  border: 1px solid #41cabf;
  height: 120px;
  background: white;
  width: 200px;
  top: 50px;
  z-index: 2000;
}
.check-change-mark p {
  font-size: 12px;
  color: #8a8a8a;
  height: 18px;
  line-height: 18px;
}
.call-change-status p {
  font-size: 12px;
  color: #8a8a8a;
  height: 18px;
  line-height: 18px;
}
.line-body-nodata {
  display: inline-block;
  width: 50px;
  height: 58px;
  background: url('../../assets/no-data.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 170px;
  left: 48%;
  z-index: 1;
}
.line-body {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.hover-go-detail {
  display: inline-block;
  height: 100%;
}
.grasp_phone {
  display: inline-block;
  font-size: 10px;
  color: white;
  background: #41cabf;
  height: 20px;
  width: 40px;
  line-height: 20px;
  border-radius: 4px;
  text-align: center;
  margin-left: 4px;
  cursor: pointer;
}
.show_grasp_text {
  font-size: 14px;
  color: #8f8f8f;
  height: 60px;
  padding-top: 10px;
  padding-left: 20px;
}
.grasp_go_pay {
  height: 68px;
  border-top: 1px solid #d8d8d8;
  line-height: 68px;
}
.grasp_go_pay span {
  display: inline-block;
  width: 70px;
  height: 34px;
  background: rgba(120, 217, 209, 1);
  border-radius: 4px;
  line-height: 34px;
  margin-left: 27px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.line-body ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
.diainput ::v-deep .el-input__inner {
  width: 250px !important;
}
.diainput ::v-deep .el-input--suffix {
  width: 250px !important;
}
.diainput ::v-deep .el-select-dropdown__item {
  width: 250px !important;
}

.com-list-detail {
  color: #0099cc;
  cursor: pointer;
}
.trans-to-cus {
  height: 100px;
  line-height: 30px;
}
.show_collent_tip {
  margin-right: 10px;
}
.show_collent_tip ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #41cabf;
  border-color: #41cabf;
}
.show_collent_tip
  ::v-deep
  .el-checkbox__input.is-checked
  + .el-checkbox__label {
  color: #41cabf;
}
.show_collent_tip ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #41cabf;
}
.remove-i > span :last-child {
  display: none;
}
</style>
