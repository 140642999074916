import { API, BASE_REQUEST } from '../_dtbird_api/api_service'
//获取数据源
export const getSalesSource = async params => {
  const api = API('GETSALESSOURCE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// GETSALESSOURCELIST
export const getSalesSourceList = async params => {
  const api = API('GETSALESSOURCELIST')
  return await BASE_REQUEST({ api: api, opt: params })
}

//GETSEARCHKP
export const getSearchKp = async params => {
  const api = API('GETSEARCHKP')
  return await BASE_REQUEST({ api: api, opt: params })
}
