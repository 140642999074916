var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-main" }, [
    _c("div", { staticClass: "tab-div" }, [
      _c(
        "div",
        { staticStyle: { width: "50%" } },
        [
          _c(
            "el-tabs",
            {
              staticClass: "dt-tab",
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.tabType,
                callback: function ($$v) {
                  _vm.tabType = $$v
                },
                expression: "tabType",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "进行中", name: "0" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "已完成", name: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.settingWaiHu },
            },
            [_vm._v("设置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.importTask },
            },
            [_vm._v("导入")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.openUploadList },
            },
            [_vm._v("导入列表")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "btn-list" }, [
      _c(
        "div",
        { staticClass: "input-row" },
        [
          _c("el-input", {
            attrs: { size: "mini", placeholder: "输入公司名/手机号查询" },
            model: {
              value: _vm.word,
              callback: function ($$v) {
                _vm.word = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "word",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-row" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.searchAgent },
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.reset } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn-list", staticStyle: { "margin-top": "10px" } },
      [
        _c(
          "div",
          [
            _vm.tabType === "0"
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      type: _vm.openAutoCallTask ? "" : "primary",
                    },
                    on: { click: _vm.openAutoCall },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.openAutoCallTask ? "关闭自动呼叫" : "开启自动呼叫"
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tabType === "1"
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.addWaiHuTask },
                  },
                  [_vm._v("\n        加入外呼任务\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: { click: _vm.delWaiHu },
              },
              [_vm._v("\n        删除\n      ")]
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "table",
      },
      [
        _c(
          "el-table",
          {
            key: _vm.resetKey,
            staticClass: "table-dt",
            staticStyle: {
              width: "100%",
              height: "100%",
              "font-size": "12px",
              "background-color": "#fff",
            },
            attrs: { data: _vm.tableData, border: "", stripe: "" },
            on: {
              "sort-change": _vm.sortChange,
              "selection-change": _vm.handleSelectionChange,
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _vm._v(" "),
            _vm._l(_vm.columnList, function (item) {
              return _c("el-table-column", {
                attrs: {
                  "show-tooltip-when-overflow": "",
                  prop: item.field,
                  align: "center",
                  width: item.field === "setting" ? "150px" : "",
                  sortable: item.type === "sort" ? "custom" : false,
                  label: item.name,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          item.type === "normal"
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.name) +
                                    "\n          "
                                ),
                              ])
                            : item.type === "filter"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-dropdown",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        trigger: "click",
                                      },
                                      on: {
                                        command: (val) =>
                                          _vm.filterFunc(val, item),
                                      },
                                    },
                                    [
                                      item.field === "from"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.from ? "active" : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-scrollbar",
                                                  {
                                                    staticClass:
                                                      "el-dropdown-link",
                                                  },
                                                  _vm._l(
                                                    item.filterOption,
                                                    function (_item, key) {
                                                      return _c(
                                                        "el-dropdown-item",
                                                        {
                                                          class:
                                                            _vm.from === _item
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            command: _item,
                                                          },
                                                        },
                                                        [_vm._v(_vm._s(key))]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : item.field === "phone_status"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                                class: _vm.phone_status
                                                  ? "active"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-scrollbar",
                                                  {
                                                    staticClass:
                                                      "dt-dropdown-dt",
                                                  },
                                                  _vm._l(
                                                    item.filterOption,
                                                    function (_item, key) {
                                                      return _c(
                                                        "el-dropdown-item",
                                                        {
                                                          class:
                                                            _vm.phone_status ===
                                                            _item
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            command: _item,
                                                          },
                                                        },
                                                        [_vm._v(_vm._s(key))]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : item.type === "sort"
                            ? _c("span", [_vm._v(_vm._s(item.name))])
                            : _c("span", [_vm._v(_vm._s(item.name))]),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.field === "company_name"
                            ? _c("company-link", {
                                attrs: {
                                  "company-name":
                                    scope.row.company_name ||
                                    scope.row.company_name,
                                  "company-uuid":
                                    scope.row.companyUuid ||
                                    scope.row.company_uuid,
                                  "package-id":
                                    scope.row.packageId || scope.row.package_id,
                                },
                              })
                            : _c("span", [
                                _vm._v(
                                  " " + _vm._s(scope.row[item.field]) + " "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page page-div page-1" },
      [
        _c("footer-page", {
          attrs: { pageStatus: _vm.pageStatus },
          on: {
            handleCurrentChange: _vm.handleCurrentChange,
            goNumPage: _vm.goNumPage,
            changePageSize: _vm.changePageSize,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }