<template>
  <div class="charts-preview-page">
    <div class="title">{{ companyInfo.workName }}</div>
    <div class="container">
      <template v-if="isPc">
        <grid-layout :layout.sync="layoutList" :col-num="60" :row-height="30">
          <grid-item
            v-for="item in layoutList"
            :static="true"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="item.i"
          >
            <div v-if="item.data.isTitleShow" class="chart-title">
              {{ item.data.title }}
            </div>
            <pe-chart :item="item.data" />
          </grid-item>
        </grid-layout>
      </template>

      <div v-else class="is-mobile">
        <div
          v-for="(item, index) in layoutList"
          :key="index"
          class="grid-item"
          :style="`width:100%;height:${item.data.height}px`"
        >
          <div v-if="item.data.isTitleShow" class="chart-title">
            {{ item.data.title }}
          </div>
          <pe-chart :item="item.data" :isPc="isPc" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PeChart from '@/views/charts'
import { isPC } from '@/utils/validate'
import { handleIsAuth } from '@/utils/helper'
import { getFlexibleList, getChartsApiDetail } from '@/api/module'
import VueGridLayout from 'vue-grid-layout'
export default {
  name: 'ChartsPreview',

  data() {
    return {
      layoutList: [],
      screenId: '',
      companyInfo: {},
      isPc: true
    }
  },

  mounted() {
    this.$store.state.settings.isFullScreen = true
    this.screenId = this.$route.query.id
    this.handleInfo()

    window.addEventListener('resize', this.updateColNum)
  },

  methods: {
    updateColNum() {
      this.isPc = isPC()
      this.handleLayout()
    },

    async handleLayout() {
      const isAuth = this.companyInfo.isAuth
      const { data } = await getFlexibleList({ pid: this.screenId }, isAuth)
      const list = data.map(item => ({
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
        i: item.i,
        data: item
      }))
      this.layoutList = list
    },

    async handleInfo() {
      const { data } = await getChartsApiDetail({
        pid: this.screenId
      })
      this.companyInfo = data
      const result = handleIsAuth(data.isAuth, `/bi/view?id=${this.screenId}`)
      result && this.updateColNum()
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.updateColNum)
    this.$store.state.settings.isFullScreen = false
  },

  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    PeChart
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.charts-preview-page {
  background: #f5f5f6;
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }

  .card-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .card-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      .top {
        margin-bottom: 5px;
      }
      .last {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .value {
          font-size: 30px;
          font-weight: bold;
        }
        .unit {
          margin-top: 4px;
          margin-left: 4px;
        }
      }
    }
  }

  .vue-grid-item {
    background-color: $used-color;
    padding: 10px;
    overflow: hidden;
  }

  .grid-item {
    background-color: $used-color;
    padding: 10px;
    margin: 10px 0;
    overflow: hidden;
  }
}
</style>
