<template>
  <div class="editOrCreateUser-main">
    <el-form
      ref="form"
      :model="formData"
      label-width="auto"
      :rules="createUserRules"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="姓名" prop="contact">
            <el-input
              placeholder="请输入姓名"
              v-model="formData.contact"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份">
            <el-select
              v-model="formData.isKp"
              placeholder="请选择"
              style="width: 100%;"
            >
              <el-option label="关键人" value="1"></el-option>
              <el-option label="中间人" value="2"></el-option>
              <el-option label="普通" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="部门">
            <el-input
              placeholder="请输入部门"
              v-model="formData.department"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="职位">
            <el-input
              placeholder="请输入职位"
              v-model="formData.position"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="mobile" label="手机">
            <el-input
              placeholder="请输入手机号码"
              v-model.trim="formData.mobile"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="座机">
            <el-input
              placeholder="请输入座机号码"
              v-model.trim="formData.landLine"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="微信号">
            <el-input
              placeholder="请输入微信号"
              style="ime-mode:disabled"
              onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
              onpaste="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
              oncontextmenu="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
              v-model="formData.wechat"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱">
            <el-input
              placeholder="请输入邮箱"
              v-model="formData.email"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="companyName" label="归属企业">
            <span
              :class="type === 'create' ? 'select-create' : 'select-edit'"
              @click="changeCompany"
              v-show="type === 'create'"
              >选择</span
            >
            <el-input
              :disabled="type === 'edit'"
              v-model="formData.companyName"
              placeholder="请选择关联公司"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="客户等级">
            <el-select
              v-model="formData.gradeId"
              placeholder="请选择客户等级"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in allLevel"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="客户状态">
            <el-select
              v-model="formData.stateId"
              placeholder="请选择客户状态"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in allStatus"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="备注">
            <el-input
              type="textarea"
              maxlength="100"
              show-word-limit
              placeholder="请输入备注"
              v-model="formData.remark"
              :autosize="{ minRows: 2, maxRows: 10 }"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-checkbox v-model="formData.hasEncrypt"></el-checkbox>
          <span>联系人号码加密显示，请使用在线电话呼叫</span>
        </el-col>
      </el-row>
    </el-form>
    <div class="footer">
      <el-button size="small" @click="cancelBtn">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="confirmBtn"
        v-loading="btnLoading"
        >确认</el-button
      >
    </div>

    <el-dialog
      class="el-dialog-dt"
      width="800px"
      top="90px"
      :visible.sync="showSelectCompany"
      :show-close="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <div slot="title" class="slot-title">
        <div>
          选择客户
        </div>
        <div @click="cancelCompany">
          <i class="el-icon-close close"></i>
        </div>
      </div>
      <div class="company-list">
        <div class="search-input">
          <div style="width: 50%">
            <el-input
              v-model="companyName"
              placeholder="请输入公司查询"
              size="small"
            ></el-input>
          </div>
          <div class="btn-list">
            <el-button size="small" type="primary" @click="searchCompany">
              查询
            </el-button>
            <el-button size="small" @click="reset"> 重置 </el-button>
          </div>
        </div>

        <div class="tip" v-if="selectCompany.companyName">
          <el-tag size="small" closable @close="delCompany">
            {{ selectCompany.companyName }}
          </el-tag>
        </div>

        <div class="table">
          <dt-table
            :data-total="pageStatus.total"
            hide-border
            :key="refreshKey"
            :is-add-earn-field="false"
            :is-count-height="false"
            :shift-field="shiftField"
            :column-width-list="{ select: 50 }"
            table-h="400px"
            :is-select="false"
            :column-list="columns"
            @sizeChange="sizeChange"
            @currentChange="currentChange"
            @singleSelectChange="singleSelectChange"
            :table-loading="loading"
            :table-data="companyList"
          >
            <template v-slot:selectRow="row">
              <span>
                <el-checkbox :value="row.scope.data.id === selectCompany.id" />
              </span>
            </template>
          </dt-table>
        </div>

        <div class="footer">
          <el-button size="small" @click="cancelCompany">取消</el-button>
          <el-button size="small" type="primary" @click="confirmCompany"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { contractCompanyList, coustomerAdd, coustomerEdit } from '@/api/user'
import { getCusPondList, getCusTagsDdic, parseMobile } from '@/api/customerpond'
import DtTable from '@/components/element/DtTable'
export default {
  name: 'editOrCreateUser',
  components: {
    DtTable
  },
  data() {
    let validateMobile = (rule, value, fcb) => {
      if (!value) {
        fcb()
      }
      if (
        value &&
        value.toString().length === 11 &&
        value.toString().startsWith('1')
      ) {
        fcb()
      } else {
        fcb(new Error('手机号格式错误'))
      }
    }
    return {
      tabType: null,
      btnLoading: false,
      companyUuid: null,
      id: null,
      allLevel: [],
      allStatus: [],
      formData: {
        contact: '',
        isKp: '',
        department: '',
        position: '',
        mobile: '',
        landLine: '',
        wechat: '',
        email: '',
        companyName: '',
        remark: '',
        gradeId: '',
        stateId: '',
        hasEncrypt: false
      },
      createUserRules: {
        mobile: [{ validator: validateMobile, trigger: 'blur' }],
        contact: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请选择归属企业', trigger: 'change' }
        ]
      },
      type: 'create',
      showSelectCompany: false,

      // 选择公司
      refreshKey: 0,
      shiftField: [{ field: 'select', name: '' }],
      companyName: '',
      selectCompany: {},
      companyList: [],
      loading: false,
      pageStatus: {
        total: null,
        pageNo: 1,
        pageSize: 10
      },
      columns: [
        {
          field: 'companyName',
          name: '客户名称'
        },
        {
          field: 'createdAt',
          name: '创建时间'
        }
      ]
    }
  },
  mounted() {
    const modalOptions = this.$store.state.plugin.modal.options
    console.log(modalOptions, 3355)
    this.type = modalOptions.type

    this.tabType = modalOptions.tabType

    if (this.type === 'edit') {
      this.companyUuid = modalOptions.formData.companyUuid
      this.id = modalOptions.formData.id
      this.formData.contact = modalOptions.formData.contact
      this.formData.department = modalOptions.formData.department
      this.formData.position = modalOptions.formData.position
      this.formData.mobile = modalOptions.formData.mobile
      this.formData.landLine = modalOptions.formData.landLine
      this.formData.wechat = modalOptions.formData.wechat
      this.formData.email = modalOptions.formData.email
      this.formData.companyName = modalOptions.formData.companyName
      this.formData.remark = modalOptions.formData.remark
      this.formData.isKp = this.formatKp(modalOptions.formData.category)
      this.formData.gradeId = modalOptions.formData.gradeId
      this.formData.stateId = modalOptions.formData.stateId
      this.formData.hasEncrypt = modalOptions.formData.hasEncrypt ? true : false
      this.getSelectOptions()
    }
  },
  computed: {},
  methods: {
    getSelectOptions() {
      getCusTagsDdic({ type: 1 }).then(res => {
        if (res.code === 60000) {
          this.allLevel = res.data
        }
      })
      getCusTagsDdic({ type: 3 }).then(res => {
        if (res.code === 60000) {
          this.allStatus = res.data
        }
      })
      parseMobile({ id: this.id }).then(res => {
        console.log(res, 1133)
        if (res.code === 60000) {
          this.formData.mobile = res.data?.mobile
          this.formData.contact = res.data?.contact
          this.formData.landLine = res.data?.landLine
        }
      })
    },

    formatKp(data) {
      if (data === '关键人') return '1'
      if (data === '中间人') return '2'
      if (data === '普通') return '3'
      return ''
    },

    confirmBtn() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const params = {
            companyUuid: this.companyUuid,
            contact: this.formData.contact,
            mobile: this.formData.mobile,
            landLine: this.formData.landLine,
            position: this.formData.position,
            department: this.formData.department,
            remark: this.formData.remark,
            wechat: this.formData.wechat,
            email: this.formData.email,
            isKp: this.formData.isKp,
            mark: this.formData.mark,
            id: this.id,
            gradeId: this.formData.gradeId,
            stateId: this.formData.stateId,
            hasEncrypt: this.formData.hasEncrypt ? 1 : 0
          }
          this.btnLoading = true

          if (this.type === 'create') {
            coustomerAdd(params).then(res => {
              this.btnLoading = false
              if (res.code === 60000) {
                this.$store.dispatch('showTip', {
                  text: res.msg,
                  type: 'success'
                })
                this.$store.commit('update', {
                  target: this.$store.state.plugin.modal.options,
                  data: {
                    refresh: true
                  }
                })
                this.cancelBtn()
              }
            })
          } else {
            coustomerEdit(params).then(res => {
              this.btnLoading = false
              if (res.code === 60000) {
                this.$store.dispatch('showTip', {
                  text: res.msg,
                  type: 'success'
                })
                this.$store.commit('update', {
                  target: this.$store.state.plugin.modal.options,
                  data: {
                    refresh: true
                  }
                })
                this.cancelBtn()
              }
            })
          }
        }
      })
    },
    cancelBtn() {
      this.$store.dispatch('hideModal')
    },

    changeCompany() {
      if (this.type === 'edit') return
      this.showSelectCompany = true
      this.getCompanyList()
    },

    currentChange(val) {
      this.pageStatus.pageNo = val
      this.getCompanyList()
    },

    sizeChange(val) {
      this.pageStatus.pageSize = val
      this.getCompanyList()
    },

    singleSelectChange(val) {
      if (!val) return
      this.selectCompany = val
      this.companyUuid = val.companyUuid
      this.formData.companyName = val.companyName
    },

    getCompanyList() {
      this.loading = true
      const params = {
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        pageId: 2001,
        companyName: this.companyName,
        type: this.tabType,
        selectType: 1
      }
      getCusPondList(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.companyList = [...res.data.list]
          // this.columns = res.data.columns
          this.pageStatus = res.data.page
        }
      })
    },

    searchCompany() {
      if (!this.companyName) return
      this.pageStatus.pageNo = 1
      this.pageStatus.pageSize = 10
      this.pageStatus.total = null
      this.getCompanyList()
    },

    confirmCompany() {
      if (!Object.keys(this.selectCompany).length) return
      this.showSelectCompany = false
      this.formData.companyName = this.selectCompany.companyName
      // 置空 设计的真sb
      this.selectUserList = []
      this.formData.billKeyperson = ''
    },

    cancelCompany() {
      this.showSelectCompany = false
      this.selectCompany = {}
    },

    delCompany() {
      this.selectCompany = {}
      this.refreshKey += 1
    },

    reset() {
      this.companyName = ''
      this.pageStatus = {
        pageSize: 10,
        pageNo: 1,
        total: 0
      }
      this.getCompanyList()
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.editOrCreateUser-main {
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}
.footer {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
}
.footer::before {
  content: '';
  display: inline-block;
  width: calc(100% + 40px);
  height: 1px;
  position: absolute;
  transform: scaleY(0.5);
  top: 0;
  left: -20px;
  background-color: rgba(0, 0, 0, 0.1);
}

.select-create {
  position: absolute;
  z-index: 99;
  right: 10px;
  color: #41cabf;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  text-align: right;
}
.select-edit {
  position: absolute;
  z-index: 99;
  right: 10px;
  color: #8c939d;
  cursor: not-allowed;
}
.select-tag {
  position: absolute;
  z-index: 99;
  left: 10px;
  color: #8c939d;
}
.tag {
  margin-right: 5px;
}
.slot-title {
  height: 50px;
  background-color: #41cabf;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.el-dialog-dt ::v-deep .el-dialog__header,
.el-drawer-dt ::v-deep .el-drawer__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}
.el-drawer-dt ::v-deep .el-drawer__header {
  margin-bottom: 0 !important;
}

.el-dialog-dt ::v-deep .el-dialog__close,
.el-drawer-dt ::v-deep .el-drawer__close {
  color: #fff;
}

.el-dialog-dt ::v-deep .el-dialog__body,
.el-drawer-dt ::v-deep .el-drawer__body {
  padding: 0 !important;
}
.close {
  cursor: pointer;
}
.search-input {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;
}

.search-input::before {
  display: inline-block;
  content: '';
  height: 5px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  background-color: rgba(0, 0, 0, 0.03);
}

.tip {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.btn-list {
  margin-left: 20px;
}
.table {
  height: 450px;
  width: calc(100% - 20px);
  margin: 0 auto;
}
</style>
