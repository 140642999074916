var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "weixinLogin-main",
    },
    [
      _c(
        "span",
        {
          on: {
            click: function ($event) {
              return _vm.$router.replace("/login")
            },
          },
        },
        [_vm._v("返回首页")]
      ),
      _vm._v(" "),
      _vm.scan
        ? _c("div", [
            _vm.wordDesc
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(
                    "\n      该微信已绑定领鸟云注册账号:" +
                      _vm._s(_vm.wordDesc) +
                      "\n    "
                  ),
                ])
              : _c("div", { staticClass: "error" }, [
                  _vm._v("该微信未绑定领鸟云注册账号"),
                ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show",
          },
        ],
        staticClass: "code",
        attrs: { id: "wx-login-one" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }