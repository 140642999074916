<template>
  <div class="common-dialog">
    <el-dialog
      top="0"
      class="common-dia-modal"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :width="dialogStatus.dialogWidth"
    >
      <div class="dialog-header">
        <span>{{ dialogStatus.dialogTitle }}</span>
      </div>
      <div class="modal_slot_cont">
        <slot name="modalCont"></slot>
      </div>
      <slot name="submit"></slot>
      <span
        slot="footer"
        v-if="dialogStatus.showFoot"
        class="dialog-footer common-foot-dia"
      >
        <el-button v-if="!dialogStatus.singleBtn" @click="dialogVisible = false"
          >取 消</el-button
        >
        <span class="dialog_modal_btn" @click="dialogVisible = false">{{
          dialogStatus.dialogSurBtn
        }}</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogStatus: {
      type: Object,
      default: {
        showFoot: true
      }
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  created() {},
  methods: {
    init() {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped>
.common-dialog {
  font-size: 12px;
}
.dialog_modal_btn {
  display: inline-block;
  width: 100px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: rgba(65, 202, 191, 1);
  border-radius: 4px;
  color: white;
  cursor: pointer;
}
.modal_slot_cont {
  padding: 8px 29px 20px;
}
.dialog-header {
  height: 66px;
  border-bottom: 1px solid rgba(216, 216, 216, 1);
  padding-left: 29px;
}
.dialog-header span {
  height: 66px;
  line-height: 66px;
  font-size: 14px;
  font-weight: bold;
  color: #2e2e2e;
}
.common-dia-modal {
  display: flex;
  align-items: center;
}
.common-dia-modal ::v-deep .el-dialog__header {
  padding: 0px !important;
}
.common-dia-modal ::v-deep .el-dialog__body {
  padding: 0px !important;
}
.common-foot-dia {
}
.common-dia-modal ::v-deep .el-dialog__footer {
  margin-top: 11px;
  border-top: 1px solid rgba(216, 216, 216, 1);
}

.common-dia-modal ::v-deep .el-textarea__inner {
  border-radius: 0;
  border: 1px solid #c0bebe;
  color: #2e2e2e;
}
</style>
