var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header" }, [
    _c("img", { staticClass: "bg", attrs: { src: _vm.headerBg } }),
    _vm._v(" "),
    _c("div", { staticClass: "h1" }, [_vm._v(_vm._s(_vm.headerTitle))]),
    _vm._v(" "),
    _c("div", { staticClass: "r" }, [
      _c("div", {
        staticClass: "fullpage_btn",
        class: { active: _vm.isFullscreen },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.screenfull.apply(null, arguments)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }