var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showCode,
            expression: "!showCode",
          },
        ],
        staticClass: "form",
      },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: { model: _vm.form, "label-width": "80px", rules: _vm.rules },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "企业ID", prop: "appId" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "企业ID" },
                  model: {
                    value: _vm.form.appId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "appId", $$v)
                    },
                    expression: "form.appId",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "Secret", prop: "secret" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "Secret" },
                  model: {
                    value: _vm.form.secret,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "secret", $$v)
                    },
                    expression: "form.secret",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "AgentId", prop: "agentId" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "AgentId" },
                  model: {
                    value: _vm.form.agentId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "agentId", $$v)
                    },
                    expression: "form.agentId",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-form-item", { staticClass: "no-margin" }, [
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "info" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-popover",
          {
            attrs: {
              "visible-arrow": "true",
              placement: "bottom-start",
              width: "240",
              trigger: "hover",
            },
          },
          [
            _c("div", { staticClass: "code-tip" }, [
              _c("span", [_vm._v("\n          微信扫码查看\n        ")]),
            ]),
            _vm._v(" "),
            _c(
              "el-link",
              {
                attrs: { slot: "reference", type: "info", underline: "" },
                slot: "reference",
              },
              [_vm._v("如何获取?")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCode,
            expression: "showCode",
          },
        ],
        ref: "code",
        staticClass: "from form-wx",
      },
      [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showErCode,
              expression: "showErCode",
            },
          ],
          attrs: { id: "wx_reg" },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPercent,
                expression: "showPercent",
              },
            ],
            staticClass: "progress",
          },
          [
            _c("div", [_vm._v("\n        正在导入联系人，请稍后\n      ")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("el-progress", {
                  attrs: {
                    "text-inside": true,
                    "stroke-width": 30,
                    percentage: _vm.percentage,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }