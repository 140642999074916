<template>
  <div class="tag-cloud-page">
    <div v-if="origin == 'page'" id="tag-page"></div>
    <div v-else id="tag-update"></div>

    <c-dialog
      :show="visible"
      :companyInfo="companyInfo"
      @handleClose="visible = false"
    >
    </c-dialog>
  </div>
</template>

<script>
import { debounceFn } from '@/utils/helper'
import TagCloud from 'TagCloud'
import CDialog from '@/views/charts/components/click-dialog'
export default {
  name: 'TagCloud',
  data() {
    return {
      tagCloud: null,
      dom: null,
      visible: false,
      companyInfo: {}
    }
  },

  props: {
    tagList: {
      type: Array,
      default: () => []
    },

    origin: {
      type: String,
      default: 'page'
    }
  },

  watch: {
    tagList: {
      handler() {
        console.log('wth123')
        this.tagCloud?.destroy()
        this.handleTagCloud()
      },
      immediate: true
    }
  },

  methods: {
    handleClick: debounceFn(function(e) {
      const text = e.target.innerText
      const item = this.tagList.find(item => item.name == text)
      if (item.url) {
        window.open(item.url)
      }
      if (item.alert) {
        this.visible = true
        this.companyInfo = item.alert
      }
      console.log(item)
    }, 100),

    handleTagCloud() {
      this.$nextTick(() => {
        const id = this.origin == 'page' ? 'tag-page' : 'tag-update'
        this.dom = document.getElementById(id)
        const texts = this.tagList.map(item => item.name)
        this.tagCloud = TagCloud(this.dom, texts, {
          containerClass: 'tag-cloud',
          itemClass: 'tag-item',
          radius: 200,
          maxSpeed: 'slow',
          initSpeed: 'slow',
          direction: 135,
          keep: true
        })

        this.dom.addEventListener('click', this.handleClick)
        // this.dom?.removeEventListener('click')
      })
    }
  },

  components: {
    CDialog
  }
}
</script>
<style lang="scss" scoped>
::v-deep .tag-cloud {
  width: 100% !important;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    background: url(../theme/images/bg3.png) no-repeat center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    pointer-events: none;
    width: 436px;
    height: 268px;
  }
}
::v-deep .tag-item {
  border-radius: 5px;
  font-size: 12px;
  padding: 0 10px;
  border: 1px solid #0096ff;
  line-height: 30px;
  cursor: pointer;
}

::v-deep #tag-page {
  .tag-item {
    color: #fff;
  }
}

::v-deep #tag-update {
  .tag-item {
    color: #333;
  }
}
</style>
