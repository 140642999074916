<template>
  <div class="p-main">
    <el-scrollbar class="list dt-dropdown-dt">
      <el-row v-infinite-scroll="load" style="width: calc( 100% - 20px ); margin: 0 auto">
        <el-col :span="24" v-for="item in list" :key="item.id">
          <div class="list-item">
            <el-radio v-model="favoriteId" :label="item.id">{{ item.formName.length > 30 ? `${item.formName.slice(0,30)}...` :item.formName }}</el-radio>
            <span class="view-land-page" @click="previewLand(item)">预览</span>
          </div>
        </el-col>
      </el-row>
    </el-scrollbar>

    <div class="footer">
      <div class="save-this-search">
      </div>
      <div>
        <el-button size="small" @click="cancelBtn">取消</el-button>
        <el-button type="primary" size="small" @click="confirmBtn">确认</el-button>
      </div>
    </div>
    <land-preview ref="userPrevw" :currentId="currentId" :formHtml="formHtml"/>
  </div>
</template>

<script>
import { getMarketingLandList, getPreview } from '@/api/table'
import DtTable from '@/components/element/DtTable'
import LandPreview from '@/views/landpage/landprevw'
export default {
  name: 'marketList',
  components:{
    DtTable,
    LandPreview
  },
  data(){
    return {
      list: [],
      pageObj: {
        pageNo: 0,
        pageSize: 20,
        total: 0
      },
      favoriteId: '',
      currentUserId: '',

      currentId: '', // 当前预览的id
      formHtml: '', // 当前预览的land
    }
  },

  methods:{
    load(){
      if(this.pageObj.total > this.list.length || this.pageObj.total === 0){
        this.pageObj.pageNo += 1
        this.getMarketList()
      }
    },

    getMarketList(){
      getMarketingLandList(this.pageObj).then(res=>{
        if(res.code === 60000){
          this.currentUserId = res.data.currentUserId
          this.list =[...this.list, ...res.data.list]
          this.pageObj.total = res.data.page.total
          this.favoriteId = this.list[0].id
        }
      })
    },

    cancelBtn(){
      this.$store.dispatch('hideModal')
    },

    confirmBtn(){
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          refresh: true,
          id: this.favoriteId,
        }
      })
      this.$store.dispatch('hideModal')
    },

    async previewLand(params){
      let result = await getPreview({ packageId: params.id })
      if (result.code === 60000) {
        this.currentId = params.id
        this.formHtml = result.data.form_html
        this.$refs.userPrevw.init(params)
      }
    }
  }
}
</script>

<style scoped>
.p-main{
  width: 100%;
  min-height: 300px;
  height: auto;
  /*padding: 10px 10px 0 10px;*/
  box-sizing: border-box;
}
.list{
  height: 250px;
  width: 100%;
}

.list-item{
  height: 50px;
  width: 100%;
  font-size: 20px;
  padding: 0 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-item::before{
  content: '';
  display: inline-block;
  height: 1px;
  width: calc( 100% - 10px );
  position: absolute;
  bottom: 0;
  left: 10px;
  background-color: rgba(0,0,0,.1);
  transform: scaleY(.5);
}

.list-item-link{
  height: 40px;
  width: 100%;
  font-size: 20px;
  padding: 0 50px;
}

.name-f{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: relative;
  padding-right: 10px;
  box-sizing: border-box;
}
.footer::before{
  content: '';
  height: 1px;
  width: calc( 100% - 0px );
  background-color: rgba(0,0,0,.1);
  top: 0;
  left: 0;
  position: absolute;
  transform: scaleY(.5);
}
.dialog-dt /deep/ .el-dialog__header{
  padding: 0!important;
}

.dialog-dt /deep/ .el-dialog__close{
  color: #ffffff!important;
}
.dialog-dt /deep/ .el-dialog__footer{
  padding: 0 10px!important;
}
.dialog-dt /deep/ .el-dialog__body{
  padding: 0!important;
}

.header{
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #41cabf;
  padding: 0 20px;
  box-sizing: border-box;
  color: #ffffff;
}

.create-favorite{
  padding: 10px 30px;
  box-sizing: border-box;
}
.view-land-page{
  font-size: 12px;
  color: #0099CC;
  cursor: pointer;
}
</style>

