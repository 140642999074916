<template>
  <div class="register-container">
    <Header></Header>
    <div class="reser-title">欢迎注册领鸟云数据平台</div>
    <div class="register-form">
      <div class="business-title">
        企业信息录取
      </div>
      <div class="business-name" style="margin-top:80px;">
        <label>公司名称</label>
        <input
          class="business-name-input"
          :class="errorMsg.nameMsg ? 'input-error' : ''"
          v-model="registerInfo.businessname"
          maxlength="20"
          placeholder="请输入公司名称"
        />
        <p v-if="errorMsg.nameMsg" class="error" style="margin-left: 38px;">
          {{ errorMsg.nameMsg }}
        </p>
      </div>
      <div class="business-product">
        <div class="business-input-product">
          <label>销售团队规模</label>
          <el-select
            v-model="registerInfo.industry"
            placeholder="请选择销售团队规模"
            style="width:571px;height:40px;margin-left: 24px;"
          >
            <el-option
              v-for="item in products.XS_01"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
              <span style=" color: #8492a6; font-size: 13px">{{
                item.name
              }}</span>
            </el-option>
          </el-select>
          <p
            v-if="errorMsg.industryMsg || errorMsg.productMsg"
            class="error"
            style="margin-left: 38px;"
          >
            {{ errorMsg.industryMsg || errorMsg.productMsg }}
          </p>
        </div>
      </div>
      <div
        style="margin-top: 20px;margin-left: 390px"
        class="error"
        v-if="errorMsg.tipMsg"
      >
        <span>
          {{ errorMsg.tipMsg }}
        </span>
        <el-popover placement="bottom" width="150" trigger="click">
          <div class="er"></div>
          <span
            slot="reference"
            style="text-decoration: underline;cursor: pointer;padding-bottom: 3px"
            >请联系客服</span
          >
        </el-popover>
      </div>
      <div class="register-bt" v-loading="loading" @click="registerBt">
        下一步
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../header.vue'
import { getAddress, getMode, userRegisterBusiness } from '@/api/user'
import { setUserStep } from '@/utils/auth'
export default {
  components: {
    Header
  },
  data() {
    return {
      loading: false,
      errorMsg: {
        nameMsg: '',
        industryMsg: '',
        tipMsg: ''
      },
      registerInfo: {
        businessname: '',
        industry: ''
      },
      products: {},
      customerList: [],
      customerChild: [],
      customerWay: ''
    }
  },
  watch: {
    'registerInfo.businessname': function() {
      this.errorMsg.tipMsg = ''
    }
  },
  mounted() {
    this.getMode()
  },
  methods: {
    //获取字典
    getMode() {
      let arr = 'XS_01'
      const params = {
        type: arr
      }
      getMode(params).then(res => {
        if (res.code == 60000) {
          this.products = res.data
        }
      })
    },
    registerBt() {
      this.errorMsg = {}
      if (!this.registerInfo.businessname) {
        this.errorMsg.nameMsg = '公司名称不能为空'
        return
      }

      if (!this.registerInfo.industry) {
        this.errorMsg.industryMsg = '请选择销售团队规模'
        return
      }

      this.loading = true
      let params = {
        enterpriseName: this.registerInfo.businessname,
        saleNumber: this.registerInfo.industry
      }
      userRegisterBusiness(params)
        .then(res => {
          this.loading = false
          if (res.code === 60000) {
            this.$message({
              message: '企业信息录入成功',
              type: 'success'
            })
            this.$store.dispatch('changeStep', 2)
            setUserStep(2)
            this.$router.replace({ path: '/package' })
          } else if (res.code === 61005) {
            this.errorMsg.tipMsg = `已存在企业名称：${
              this.registerInfo.businessname
            }，`
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.register-container ::v-deep .el-input__inner {
  border-radius: 0;
  padding: 0 21px;
  border: 1px solid #c0bebe;
  color: #2e2e2e;
}

.register-container ::v-deep .el-textarea__inner {
  border-radius: 0;
  height: 80px;
  padding: 5px 21px !important;
  border: 1px solid #c0bebe;
  color: #2e2e2e;
}

.register-container ::v-deep .el-input__inner:focus,
.register-container ::v-deep .el-textarea__inner:focus,
input:focus {
  border-color: #41cabf;
}

.input-error {
  border-color: #ff0000 !important;
}

.business-name-input::-webkit-input-placeholder,
.register-container ::v-deep .el-input__inner::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #8f8f8f;
}

.business-name-input:-moz-placeholder,
.register-container ::v-deep .el-input__inner:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #8f8f8f;
}

.business-name-input::-moz-placeholder,
.register-container ::v-deep .el-input__inner::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #8f8f8f;
}

.business-name-input:-ms-input-placeholder,
.register-container ::v-deep .el-input__inner:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #8f8f8f;
}

.register-container {
  height: 100%;
  min-height: 100%;
  width: 100%;
  background-color: #f5f6f7;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
}
.reser-title {
  width: 100%;
  margin-top: 50px;
  font-size: 30px;
  color: #494949;
  font-weight: 600;
}

.business-title {
  font-size: 20px;
  font-weight: 550;
  color: #494949;
  margin: 0 50px 0 50px;
  padding: 32px 0 31px 20px;
  border-bottom: 1px solid #c1bfbf;
}

.register-form {
  display: flex;
  width: 1200px;
  height: 760px;
  min-height: 760px;
  min-width: 1200px;
  margin-top: 50px;
  margin-bottom: 42px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.business-name,
.business-product,
.business-details,
.business-service,
.business-address {
  padding-left: 160px;
}

.business-name {
  margin-top: 29px;
  height: 40px;
}

.business-name-input {
  background: none;
  border: none;
  outline: none;
  border: 1px solid #c0bebe;
  line-height: 38px;
  padding: 0 20px;
  color: #2e2e2e;
  width: 571px;
  margin-left: 20px;
}

.business-address {
  margin-left: 233px;
}

.business-name label,
.business-product label,
.business-details label,
.business-service label {
  display: inline-block;
  width: 209px;
  font-size: 16px;
  color: #494949;
  font-weight: 550;
}

.business-product {
  display: flex;
}

.business-input-product {
  display: flex;
  margin-top: 52px;
  line-height: 40px;
}

.business-details {
  display: flex;
  margin: 35px 0 0 0;
}

.business-service {
  display: flex;
  flex-direction: column;
}

.business-service-c1 {
  margin: 35px 0 0 0;
}

.business-service-c2 {
  margin: 32px 0 0 233px;
}

.business-service-c3 {
  margin: 32px 0 0 233px;
}

.error {
  color: #ff0000;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  display: inline;
  margin-left: 40px;
  margin-right: auto;
  line-height: 40px;
}

.register-bt {
  width: 350px;
  line-height: 46px;
  /*margin-left: 400px;*/
  background: #41cabf;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 160px;
  cursor: pointer;
  text-align: center;
  flex-shrink: 0;
}
.register-bt:active {
  opacity: 0.8;
}
.er {
  height: 125px;
  width: 125px;
  background: url('../../../assets/dtbird-img-new.jpg') no-repeat center;
  background-size: 125px 125px;
}
</style>
