var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-select" },
    [
      _c(
        "el-select",
        {
          ref: "peSelect",
          attrs: {
            placeholder: _vm.placeholder,
            clearable: _vm.clearable,
            multiple: _vm.isMultiple,
            size: _vm.selectSize,
            disabled: _vm.selectDisable,
            "popper-class": _vm.popperClass,
            filterable: "",
            "value-key": "label",
          },
          on: {
            change: _vm.handleSelect,
            "remove-tag": _vm.handleRemoveTag,
            "visible-change": _vm.handleVisibleChange,
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        [
          _vm.isGroup
            ? _vm._l(_vm.optionsList, function (group) {
                return _c(
                  "el-option-group",
                  { key: group.label, attrs: { label: group.label } },
                  _vm._l(group.options, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item },
                    })
                  }),
                  1
                )
              })
            : _vm._l(_vm.optionsList, function (item, index) {
                return _c(
                  "el-option",
                  { key: index, attrs: { label: item.label, value: item } },
                  [
                    _vm.isShopCustom
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "25px",
                                height: "25px",
                                "border-radius": "50%",
                              },
                              attrs: { src: item.value.brandLogo },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-left": "10px",
                                  "margin-right": "10px",
                                },
                              },
                              [_vm._v(_vm._s(item.value.brandName))]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("(" + _vm._s(item.value.count) + ")"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }