var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "VmiDialog-main" },
    [
      _c(
        "el-dialog",
        _vm._g(
          _vm._b(
            {
              attrs: {
                visible: _vm.show,
                "show-close": false,
                "modal-append-to-body": false,
                width: _vm.dialogWidth,
                "destroy-on-close": "",
                "custom-class": "dialog-class",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show = $event
                },
              },
            },
            "el-dialog",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c(
            "div",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [
              _c("span", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-close",
                on: { click: _vm.closeDialog },
              }),
            ]
          ),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }