<template>
  <div style="height: calc( 100% - 80px )">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="info-share">
          <p class="title">分享标题、简介与头图</p>
          <div>
            <div>
              <p class="title-share" v-if="fontStyle.share['title'] === ''">
                请输入落地页标题
              </p>
              <p class="title-share" v-else>{{ fontStyle.share['title'] }}</p>
              <div class="desc-share">
                <span>{{ fontStyle.share['desc'] }}</span>
                <div
                  v-if="fontStyle.share['img'] === ''"
                  style="width: 50px;height: 50px;text-align: center;line-height: 50px;background: rgb(216, 216, 216)"
                >
                  <svg-icon
                    style="width: 20px;height: 20px"
                    class="linkUrl"
                    icon-class="linkUrl"
                  ></svg-icon>
                </div>
                <img v-else :src="fontStyle.share['img']" />
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="12" style="display: flex;justify-content: center">
        <div class="iphone">
          <div class="iphone-top">
            <span class="camera"></span>
            <span class="sensor"></span>
            <span class="speaker"></span>
          </div>
          <div class="top-bar"></div>
          <el-scrollbar class="scroll">
            <div class="iphone-screen">
              <div class="video-row" v-show="fontStyle.show.video">
                <i class="el-icon-video-play" v-show="!fontStyle.videoUrl"></i>
                <video
                  v-show="fontStyle.videoUrl"
                  :src="fontStyle.videoUrl"
                  style="width: 100%;height: 100%"
                  controls="controls"
                ></video>
              </div>

              <div class="image-row" v-show="fontStyle.show.photo">
                <el-image :src="fontStyle.uploadUrl">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>

              <div
                class="content-row"
                v-show="fontStyle.show.content"
                :style="{
                  fontFamily: fontStyle.fontFamily,
                  backgroundColor: fontStyle.textBg
                }"
              >
                <div
                  :style="{
                    fontFamily: fontStyle.fontFamily,
                    fontSize: fontStyle.fontSize + 'px',
                    color: fontStyle.fontColor,
                    fontWeight: fontStyle.fontWeight
                  }"
                  class="text-content"
                >
                  {{ fontStyle.textareaCont }}
                </div>
              </div>

              <div
                class="input-row"
                v-show="fontStyle.show.input && fontStyle.checkedArr.length"
                :style="{ backgroundColor: fontStyle.pagraBg }"
              >
                <el-form label-width="80px" size="small" style="width: 90%">
                  <el-form-item
                    label="姓名"
                    v-show="fontStyle.checkedArr.includes('name')"
                  >
                    <el-input placeholder="请输入姓名"></el-input>
                  </el-form-item>

                  <el-form-item
                    label="联系方式"
                    v-show="fontStyle.checkedArr.includes('phone')"
                  >
                    <el-input placeholder="请输入联系方式"></el-input>
                  </el-form-item>

                  <el-form-item
                    label="公司名称"
                    v-show="fontStyle.checkedArr.includes('company')"
                  >
                    <el-input placeholder="请输入公司名称"></el-input>
                  </el-form-item>
                </el-form>
                <div class="input-row-btn" v-show="fontStyle.show.button">
                  {{ fontStyle.buttonText }}
                </div>
              </div>
            </div>
          </el-scrollbar>
          <div class="buttons">
            <span class="on-off"></span>
            <span class="sleep"></span>
            <span class="up"></span>
            <span class="down"></span>
          </div>
          <div class="bottom-bar"></div>
          <div class="iphone-bottom">
            <span></span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getMarketingLandPage,
  postMarketingPublish,
  getMarketlandPageDetail,
  getMarketLandPageUpdate
} from '@/api/table'
import { Base64 } from 'js-base64'

export default {
  props: {
    fontStyle: {
      default: () => {},
      type: Object
    },
    pageDetail: {
      default: () => {},
      type: Object
    },
    formName: {
      default: '',
      type: String
    },
    acType: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      pageId: localStorage.getItem('PageId') || '',
      page: '',
      name: '',
      phone: '',
      company: '',
      type: '',
      baseUrl: process.env.VUE_APP_BASE_API,
      isDev: process.env.NODE_ENV === 'development'
    }
  },
  methods: {
    deserveCont(val) {
      val = val.replace(/\n/g, '<br>')
      val = val.replace(/\s/g, '&nbsp;')
      return val
    },
    saveLandPage() {
      this.growHtml(true)
    },
    sendPublish() {
      this.growHtml(false)
    },
    getMarketlandPageDetail() {
      const params = {
        id: this.pageId
      }
      getMarketlandPageDetail(params).then(res => {
        if (res.code === 60000) {
          this.pageDetail = res.data
          console.log('pageId', res)
          this.formName = res.data.formName
          this.pageId = res.data.id
        }
      })
    },
    postMarketingPublish(val) {
      const base = Base64.encode(val)
      const param = new FormData()
      param.append('id', localStorage.getItem('PageId'))
      param.append('name', this.formName || '请输入落地页名称')
      param.append('param', JSON.stringify(this.fontStyle))
      param.append('page', base)
      postMarketingPublish(param).then(res => {
        if (res.code === 60000) {
          this.$emit('publicPageUrl', res.data.pageUrl)
        }
      })
    },
    getMarketLandPageUpdate(val) {
      const base = Base64.encode(val)
      const param = new FormData()
      param.append('id', localStorage.getItem('PageId'))
      param.append('param', JSON.stringify(this.fontStyle))
      param.append('page', base)

      const params = {
        id: localStorage.getItem('PageId'),
        param: JSON.stringify(this.fontStyle),
        page: base
      }
      getMarketLandPageUpdate(params).then(res => {
        if (res.code === 60000) {
          if (this.acType === '1') {
            this.$message({
              type: 'success',
              message: '修改成功'
            })
          } else {
            this.postMarketingPublish(val)
          }
        }
      })
    },
    getMarketingLandPage(val) {
      const base = Base64.encode(val)
      const param = new FormData()
      param.append('name', this.formName || '请输入落地页名称')
      param.append('param', JSON.stringify(this.fontStyle))
      param.append('page', base)
      getMarketingLandPage(param).then(res => {
        if (res.code === 60000) {
          localStorage.setItem('PageId', res.data.pageId)
          this.pageId = res.data.pageId
          if (this.acType === '1') {
            this.$message({
              type: 'success',
              message: '保存成功'
            })
            this.postMarketingPublish(this.page)
            this.$emit('savePageSuc', res.data.pageId)
          }
        }
      })
    },
    updateMarketLandPageUpdate(val, id) {
      const base = Base64.encode(val)
      const param = new FormData()
      param.append('id', id)
      param.append('param', JSON.stringify(this.fontStyle))
      param.append('page', base)
      const params = {
        id: id,
        param: JSON.stringify(this.fontStyle),
        page: base
      }

      this.pageId = id
      getMarketLandPageUpdate(params).then(res => {
        if (this.acType === '2') {
          this.postMarketingPublish(val)
        }
      })
    },
    saveTypePage(val) {
      const currentId = localStorage.getItem('PageId') || ''
      if (!currentId) {
        this.getMarketingLandPage(val)
      } else {
        this.postMarketingPublish(this.page)
      }
    },
    getWxCode() {
      return () => {
        const baseUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?'
        const appid = 'wx947ce8f2ed9ad3fc'
        // const path = window.location.href;
        const path = location.origin + location.pathname

        function getQueryVariable(variable) {
          var query = window.location.search.substring(1)
          var vars = query.split('&')
          for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=')
            if (pair[0] == variable) {
              return pair[1]
            }
          }
          return false
        }

        //设置cookie
        function setCookie(c_name, value, expiredays) {
          var exdate = new Date()
          exdate.setDate(exdate.getDate() + expiredays)
          document.cookie =
            c_name +
            '=' +
            escape(value) +
            (expiredays == null ? '' : ';expires=' + exdate.toGMTString())
        }

        //取回cookie
        function getCookie(c_name) {
          if (document.cookie.length > 0) {
            c_start = document.cookie.indexOf(c_name + '=')
            if (c_start != -1) {
              c_start = c_start + c_name.length + 1
              c_end = document.cookie.indexOf(';', c_start)
              if (c_end == -1) c_end = document.cookie.length
              return unescape(document.cookie.substring(c_start, c_end))
            }
          }
          return false
        }

        function clearCookie() {
          document.cookie =
            'originOpenid=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
        }

        if (!getQueryVariable('code')) {
          localStorage.clear()
          const res_type = 'response_type=code'
          const scope = 'scope=snsapi_userinfo&state=STATE#wechat_redirect'
          if (getQueryVariable('userId')) {
            let userId = getQueryVariable('userId')
            localStorage.setItem('userId', userId)
          }
          if (getQueryVariable('companyUuid')) {
            let companyUuid = getQueryVariable('companyUuid')
            let landPageId = getQueryVariable('landPageId')
            let userId = getQueryVariable('userId')

            localStorage.setItem('userId', userId)
            localStorage.setItem('companyUuid', companyUuid)
            localStorage.setItem('landPageId', landPageId)
            localStorage.setItem('marketId', getQueryVariable('marketId') || '')
            shareKey = `&companyUuid=${companyUuid}&landPageId=${landPageId}&userId=${userId}`
          }
          if (getQueryVariable('originOpenid')) {
            localStorage.setItem(
              'originOpenid',
              getQueryVariable('originOpenid')
            )
          }
          if (getQueryVariable('originOpenid')) {
            originOpenid = `&originOpenid=${getQueryVariable('originOpenid')}`
          }
          const currPath = `${baseUrl}&appid=${appid}&redirect_uri=${encodeURIComponent(
            path
          )}&${res_type}&${scope}`
          window.location.href = currPath
        } else {
          const code = getQueryVariable('code')
          localStorage.setItem('code', code)
          return {
            appid,
            code,
            getCookie,
            getQueryVariable
          }
        }
      }
    },
    growHtml(val, id) {
      console.log('ttt', this.fontStyle)
      const Style =
        '<style>' +
        '.page-cont-title {width: 100%;}' +
        '.page-cont-title p {text-align: center;font-size: 18px;font-weight: bold;margin: 10px;color:black;}' +
        '.page-img-upload {display: block;width: 100%;}' +
        '.page-submit-btn p {text-align: center;color: white;line-height: 30px;}' +
        '.input-txt-list span {display: inline-block;width: 60px;font-size: 14px;}' +
        '.input-txt-list input {outline: none;font-size: 12px;height: 25px;width: 200px;border: none;padding-left: 20px;}' +
        '.input-txt-list {height: 45px;line-height: 45px;}' +
        '.page-show-cont {width: 100%;margin: 0 auto;border-radius: 5px;background: white;height: 100vh;position: relative;padding-bottom: 100px;box-sizing: border-box;}' +
        `${true ? '' : '.page-show-cont {padding-bottom: 0!important;}'} ` +
        '.page-cont-cont {margin: 0 auto;background: white;height: calc( 100vh - 100px ); overflow-y: auto } ' +
        `${true ? '' : '.page-cont-cont {height: 100vh!important}'}` +
        '.page-img-cont {width: 100%;background: white;}' +
        '.page-content-txt {width: 100%;padding: 30px 10px;background: #D8D8D8;box-sizing: border-box;word-break: break-all;word-wrap: normal}' +
        '.page-input-txt {width: 100%;padding: 30px 10px;background: #D8D8D8;box-sizing: border-box;}' +
        '.page-input-txt input{border:1px solid #e6e6e6}' +
        '.page-submit-btn {width: 100%;height: 30px;background: #33CB98;cursor: pointer;}' +
        '#page-have-done {width: 100%;display:none;background:#D8D8D8; height: 30px;line-height: 30px;color:white;text-align:center;}' +
        'body {margin: 0}' +
        'label {width: 70px;text-align: right;margin-right: 5px}' +
        "label::before {content: '*';color: #f56c6c;margin-right: 4px}" +
        '.page-name-card { background-color:#fff; z-index: 99; position: absolute; left: 0; bottom: 0;height: 100px;width: 100%; padding: 0 20px;box-sizing: border-box; box-shadow:rgba(0,0,0,.08) 0 0 1px 1px;display: flex;align-items: center } ' +
        '.card-name-title { height: 20px;font-size: 12px;display: flex;align-items: center;justify-content: flex-start; }  ' +
        '.card-img-input-photo{width: 100%;height: 80px;display: flex;align-items: center;justify-content: flex-start; }  ' +
        '.card-photo { height: 100%;width: 80px;display: flex;align-items: center;justify-content: center;} ' +
        '.card-photo-img {height: 80%;width: 80%;border-radius: 50%; }' +
        '.card-name-position-company{font-size: 12px;width: calc( 100% - 160px )} ' +
        '.card-position{ margin-left: 10px; }  ' +
        '.card-photo-code{ height: 80%;width: 80%; }  ' +
        '.card-photo-co{ height: 100%;width: 80px;display: flex;flex-direction: column;align-items: center;justify-content: space-between; }' +
        '.zixun{font-size: 10px; text-align: center; color: rgba(0,0,0,.3);}' +
        '</style>'
      const HeadHtml = `
        <!DOCTYPE html>
        <html>
          <head>
          <title>${this.fontStyle.share.title}<\/title>
          <meta charset='utf-8'>
          <link rel='shortcut icon' href='https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/favicon.ico' \/>
          <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, height=device-height'>
          <meta name='description' content="${this.fontStyle.share.desc}">
          <meta property="og:title" content="${this.fontStyle.share.title}">
          ${Style}
          <\/head>
      `
      const video =
        this.fontStyle.show['video'] && this.fontStyle.videoUrl !== ''
          ? "<div class='page-img-cont'>" +
            "<video class='page-img-upload video' src='" +
            this.fontStyle.videoUrl +
            "' alt='' controls='controls' x5-video-player-type='h5' x5-video-player-fullscreen='true' webkit-playsinline='true' x-webkit-airplay='true' playsinline='true' x5-playsinline>" +
            '</div>'
          : ''
      const photo =
        this.fontStyle.show['photo'] && this.fontStyle.uploadUrl !== ''
          ? '' +
            "<img class='page-img-upload' src='" +
            this.fontStyle.uploadUrl +
            "' alt=''>" +
            ''
          : ''
      const contentText = this.fontStyle.show['content']
        ? "<div class='page-content-txt' style='background:" +
          this.fontStyle.textBg +
          "'>" +
          "<span style='color:" +
          this.fontStyle.fontColor +
          ';' +
          'font-weight:' +
          this.fontStyle.fontWeight +
          ';' +
          'font-size:' +
          this.fontStyle.fontSize +
          'px;' +
          'font-family:' +
          this.fontStyle.fontFamily +
          ';' +
          "'>" +
          this.deserveCont(this.fontStyle.textareaCont) +
          '</span>' +
          '</div>'
        : ''
      const checks =
        this.fontStyle.show['input'] && this.fontStyle.checkedArr.length > 0
          ? "<div class='page-input-txt' style='background:" +
            this.fontStyle.pagraBg +
            "'>" +
            "<div class='input-txt-list' style='display:" +
            (this.fontStyle.checkedArr.indexOf('name') > -1
              ? 'block;'
              : 'none;') +
            "'>" +
            "<label style='display: inline-block'>姓名</label>" +
            "<input id='input-name' type='text' placeholder='请输入姓名'>" +
            '</div>' +
            "<div class='input-txt-list' style='display:" +
            (this.fontStyle.checkedArr.indexOf('phone') > -1
              ? 'block;'
              : 'none;') +
            "'>" +
            "<label style='display: inline-block'>手机号</label>" +
            "<input id='input-phone' type='number' placeholder='请输入手机号'>" +
            '</div>' +
            "<div class='input-txt-list' style='display:" +
            (this.fontStyle.checkedArr.indexOf('company') > -1
              ? 'block;'
              : 'none;') +
            "'>" +
            "<label style='display: inline-block'>公司</label>" +
            "<input id='input-company' type='text' placeholder='请输入公司名称'>" +
            '</div>' +
            '</div>'
          : ''
      const button =
        this.fontStyle.show['button'] && this.fontStyle.checkedArr.length > 0
          ? "<div class='page-submit-btn' id='page-no-done2'>" +
            '<p>' +
            this.fontStyle.buttonText +
            '</p>' +
            '</div>'
          : ''

      const nameCard = this.fontStyle.show['nameCard']
        ? `<div class='page-name-card'>
        <div class='card-img-input-photo'>
            <div class='card-photo'>
                <img class='card-photo-img' src=${
                  this.fontStyle.nameCard.headerImg
                }>
            </div>
            <div class='card-name-position-company'>
                <p>
                    <span>${
                      this.fontStyle.nameCard.username
                    }</span><span class='card-position'>${
            this.fontStyle.nameCard.position
          }</span>
                </p>
                <p>
                    ${this.fontStyle.nameCard.companyName}
                </p>
            </div>
            <div class='card-photo-co'>
                <img class='card-photo-code' src=${
                  this.fontStyle.nameCard.erCodeImg
                }>
                <div class='zixun'>扫码咨询</div>
            </div>
        </div>
    </div>`
        : ''

      const BodyHtml =
        '<body>' +
        "<div class='page-show-cont'>" +
        "<div class='page-cont-cont'>" +
        video +
        photo +
        contentText +
        checks +
        button +
        nameCard +
        "<div id='page-have-done'>" +
        '<p>提交成功</p>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</body>'
      const Script = `<script src='https://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/yingxiao-js/jquery.min.js'><\/script><script src='https://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/yingxiao-js/lodash.min.js'><\/script> <script src='https://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/yingxiao-js/${
        this.isDev ? 'sc-dev.js' : 'sc.js'
      }'><\/script>`

      const FooterHmtl = '</html>'
      this.page = HeadHtml + BodyHtml + Script + FooterHmtl
      if (val) {
        if (id) {
          this.updateMarketLandPageUpdate(
            HeadHtml + BodyHtml + Script + FooterHmtl,
            id
          )
          return
        }
        this.saveTypePage(HeadHtml + BodyHtml + Script + FooterHmtl)
      } else {
        this.postMarketingPublish(HeadHtml + BodyHtml + Script + FooterHmtl)
      }
    }
  }
}
</script>

<style scoped>
@import '../../styles/threadpx.scss';

.info-share {
  padding-top: 30px;
  width: 100%;
}

.info-share > div {
  background: #f0f2f5;
  padding: 10px;
  margin-top: 20px;
}

.info-share > div > div {
  position: relative;
  border-radius: 3px;
  background: #fff;
  padding: 10px;
}

.info-share > div > div::after {
  content: '';
  display: block;
  position: absolute;
  right: -10px;
  top: 20%;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 5px solid;
  border-color: transparent transparent transparent #fff;
}

.info-share p {
  font-size: 14px;
}

.info-share .title-share {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.info-share .desc-share {
  display: flex;
  margin-top: 10px;

  justify-content: space-between;
}

.info-share .desc-share span {
  font-size: 12px;
  width: 200px;
  color: rgb(138, 138, 138);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.info-share img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.page-cont-title {
  width: 100%;
}

.empty-play {
  position: relative;
  width: 100%;
  height: 100%;
}

.empty-play ::v-deep svg {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  font-size: 70px;
}

.right-cont-tip {
  text-align: left;
  font-size: 14px;
  color: #e4e7ed;
}

.page-cont-title p {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin: 10px;
  color: black;
}

.page-img-upload {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.page-submit-btn p {
  text-align: center;
  color: white;
  font-size: 14px;
  line-height: 30px;
}

.input-txt-list span {
  display: inline-block;
  width: 60px;
  font-size: 14px;
}

.input-txt-list input {
  outline: none;
  font-size: 12px;
  height: 25px;
  border: none;
  padding-left: 20px;
}

.input-txt-list {
  height: 45px;
  line-height: 45px;
  padding-left: 20px;
}

.page-show-cont-1 {
  width: 100%;
  margin: 0 auto;
  padding-top: 65px;
  border-radius: 5px;
  box-sizing: border-box;
}

.page-cont-cont-1 {
  width: 100%;
  margin: 0 auto;
  background: white;
}

.page-img-cont {
  width: 100%;
  height: 150px;
  padding: 10px 0 0 10px;
  background: white;
  margin-bottom: 5px;
}

.not-empty.page-img-cont {
  padding: 0;
}

.page-content-txt {
  width: 100%;
  position: relative;
  word-break: normal;
  word-wrap: break-word;

  padding: 30px 10px;
  background: #d8d8d8;
}

.page-input-txt {
  width: 100%;
  position: relative;
  padding: 30px 10px;
  background: #d8d8d8;
}

.page-input-txt input {
  border: 1px solid #e6e6e6;
}

.page-submit-btn {
  width: 100%;
  height: 30px;
  background: #33cb98;
  cursor: pointer;
}

.prompt {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
}

.card-name-xx {
  height: 120px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 1px 1px;
}

.card-name-title {
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-img-input-photo {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-photo {
  height: 100%;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-photo-img {
  height: 80%;
  width: 80%;
  border-radius: 50%;
}

.card-name-position-company {
  font-size: 12px;
  width: calc(100% - 160px);
}

.card-position {
  margin-left: 10px;
}

.card-photo-code {
  height: 80%;
  width: 80%;
}

.card-photo-co {
  height: 100%;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.zixun {
  font-size: 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}

.pp {
  margin-bottom: 20px;
}

.hide-x ::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.iphone {
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px #999,
    0 0 30px 0px rgba(0, 0, 0, 0.7);
  border: 5px solid #d9dbdc;
  background: #f8f8f8;
  padding: 15px;
  border-radius: 50px;
  height: 877px;
  width: 423px;
  position: relative;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.iphone-top {
  padding: 5px 110px 40px;
  position: relative;
}

.iphone-top .speaker {
  display: block;
  width: 70px;
  height: 6px;
  margin: 0 auto;
  border-radius: 6px;
  background: #292728;
}

.iphone-top .camera {
  display: block;
  margin: 0 auto;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-bottom: 13px;
  background: #333;
}

.iphone-top .sensor {
  display: block;
  width: 15px;
  height: 15px;
  float: left;
  background: #333;
  margin-top: -5px;
  border-radius: 50%;
}

.iphone .top-bar,
.iphone .bottom-bar {
  display: block;
  width: 423px;
  height: 15px;
  border-left: 5px solid #bbb;
  border-right: 5px solid #bbb;
  position: absolute;
  left: -5px;
}

.iphone .top-bar {
  top: 65px;
}

.iphone .bottom-bar {
  bottom: 65px;
}

.iphone-screen {
  background: #fff;
  height: 677px;
  width: 375px;
  margin: 0 auto;
}

.iphone-screen img {
  width: 100%;
}

.iphone .buttons .on-off,
.iphone .buttons .up,
.iphone .buttons .down,
.iphone .buttons .sleep {
  display: block;
  background: #ccc;
  position: absolute;
  border-radius: 2px 0px 0px 2px;
}

.iphone .buttons .on-off {
  height: 40px;
  width: 3px;
  top: 100px;
  left: -8px;
}

.iphone .buttons .up,
.iphone .buttons .down,
.iphone .buttons .sleep {
  height: 60px;
  width: 5px;
  left: -10px;
}

.iphone .buttons .up {
  top: 170px;
}

.iphone .buttons .down {
  top: 250px;
}

.iphone .buttons .sleep {
  left: auto;
  right: -10px;
  top: 170px;
  border-radius: 0px 2px 2px 0px;
}

.iphone-bottom {
  padding: 10px 0 0;
}

.iphone-bottom span {
  display: block;
  margin: 0 auto;
  width: 68px;
  height: 68px;
  background: #ccc;
  border-radius: 50%;
  background: -webkit-linear-gradient(
    315deg,
    #303233 0%,
    #b5b7b9 50%,
    #f0f2f2 69%,
    #303233 100%
  );
  background: linear-gradient(
    135deg,
    #303233 0%,
    #b5b7b9 50%,
    #f0f2f2 69%,
    #303233 100%
  );
  position: relative;
}

.iphone-bottom span:after {
  content: '';
  display: block;
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 4px;
}

.video-row {
  height: 230px;
  width: 100%;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 50px;
  position: relative;
}

.video-row::after {
  content: '视频区';
  display: block;
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 16px;
  opacity: 0.15;
}

.image-row {
  min-height: 230px;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 50px;
  background-color: rgba(0, 0, 0, 0.05);
  color: #909399;
}

.image-row::after {
  content: '图片区';
  display: block;
  position: absolute;
  left: 10px;
  top: 10px;
  color: #333;
  font-size: 16px;
  opacity: 0.15;
}

.content-row {
  min-height: 230px;
  padding: 20px;
  box-sizing: border-box;
  line-height: 130%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.content-row::after {
  content: '内容区';
  display: block;
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 16px;
  opacity: 0.15;
}

.input-row {
  height: 230px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 50px;
  flex-direction: column;
}

.input-row::after {
  content: '字段区';
  display: block;
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.15);
}

.input-row-btn {
  font-size: 16px;
  height: 40px;
  width: 90%;
  background-color: #41cabf;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.m-input {
  height: 32px;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  position: relative;
}

.color-input {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border: none;
  height: 90%;
  width: 50px;
  cursor: pointer;
}

.text-content {
  word-break: break-all;
  word-wrap: break-word;
  line-height: 120%;
}

.scroll {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}
</style>
