var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "waihu-panel" } },
    [
      _c("Workflow", {
        scopedSlots: _vm._u([
          {
            key: "waiHu",
            fn: function (scope) {
              return _c(
                "div",
                {},
                [
                  _vm.bandWaihuType === "webrtc"
                    ? [
                        _vm.bandWaihuType == "webrtc"
                          ? _c("iframe", {
                              ref: "webmain",
                              staticStyle: { height: "230px" },
                              attrs: {
                                src:
                                  "/edb/html/webmain.html?loginName=" +
                                  _vm.bandWaihuAccount +
                                  "&_t=" +
                                  _vm._t +
                                  "&isAutoCall=" +
                                  _vm.openAutoCallTask +
                                  "&waihuAccountDomain=" +
                                  _vm.waihuAccountDomain +
                                  "&password=" +
                                  _vm.bandWaihuPassword +
                                  "&loginType=sip&agentStatus=0&asignOption=" +
                                  encodeURIComponent(
                                    JSON.stringify(_vm.asignOption)
                                  ) +
                                  "&phone=" +
                                  _vm.phone,
                                frameborder: "0",
                                id: "webmain",
                                width: "100%",
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm.bandWaihuType === "simPhone"
                    ? [
                        _c("div", { staticClass: "sim-phone" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.showJt && _vm.simStatus == 0,
                                  expression: "!showJt && simStatus == 0",
                                },
                              ],
                              staticClass: "no-call",
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          size: "small",
                                          placeholder: "请输入号码",
                                        },
                                        model: {
                                          value: _vm.simPhone,
                                          callback: function ($$v) {
                                            _vm.simPhone = $$v
                                          },
                                          expression: "simPhone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          icon: "el-icon-phone",
                                          circle: "",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.simPhoneCall(
                                              _vm.simPhone,
                                              "",
                                              ""
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showHangUp,
                                          expression: "showHangUp",
                                        },
                                      ],
                                      attrs: { span: 4 },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          icon: "el-icon-phone",
                                          circle: "",
                                          type: "danger",
                                        },
                                        on: { click: _vm.hangUpSim },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.showJt && _vm.simStatus == 3,
                                  expression: "!showJt && simStatus == 3",
                                },
                              ],
                              staticClass: "call-ing",
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c("el-col", { attrs: { span: 16 } }, [
                                    _c("span", { staticClass: "phone-num" }, [
                                      _vm._v(_vm._s(_vm.simPhone)),
                                    ]),
                                    _vm._v(" "),
                                    _vm.contact
                                      ? _c(
                                          "span",
                                          { staticClass: "phone-name" },
                                          [_vm._v(_vm._s(_vm.contact))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "phone-time" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.phoneTime
                                              ? `通话中:${_vm.phoneTime}`
                                              : "00:00:00"
                                          )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          icon: "el-icon-phone",
                                          circle: "",
                                          type: "danger",
                                        },
                                        on: { click: _vm.hangUpSim },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-dropdown",
                                        {
                                          attrs: {
                                            placement: "bottom-start",
                                            trigger: "click",
                                          },
                                          on: {
                                            command: function ($event) {
                                              return _vm.changeAsign($event)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("标记")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            _vm._l(
                                              _vm.asignOption,
                                              function (item) {
                                                return _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: {
                                                      command: item.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.name) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showJt,
                                  expression: "showJt",
                                },
                              ],
                              staticClass: "call-jt",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "primary",
                                    disabled: _vm.btnLoading,
                                  },
                                  on: { click: _vm.simJt },
                                },
                                [_vm._v("\n              接听\n            ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : [
                        _c("div", { staticClass: "sim-phone" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.state === "0",
                                  expression: "state === '0'",
                                },
                              ],
                              staticClass: "no-call",
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          size: "small",
                                          placeholder: "请输入号码",
                                        },
                                        model: {
                                          value: _vm.simPhone,
                                          callback: function ($$v) {
                                            _vm.simPhone = $$v
                                          },
                                          expression: "simPhone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          icon: "el-icon-phone",
                                          circle: "",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.callUp(
                                              _vm.simPhone,
                                              "",
                                              ""
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.state === "1",
                                  expression: "state === '1'",
                                },
                              ],
                              staticClass: "call-ing",
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c("el-col", { attrs: { span: 20 } }, [
                                    _c("span", { staticClass: "phone-num" }, [
                                      _vm._v(_vm._s(_vm.simPhone)),
                                    ]),
                                    _vm._v(" "),
                                    _vm.contact
                                      ? _c(
                                          "span",
                                          { staticClass: "phone-name" },
                                          [_vm._v(_vm._s(_vm.contact))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "phone-time" }, [
                                      _vm._v(_vm._s(_vm.callStatus)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          icon: "el-icon-phone",
                                          circle: "",
                                          type: "danger",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.hangUp("0")
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                  _vm._v(" "),
                  _c(
                    "dia-model",
                    {
                      ref: "showmsg",
                      staticStyle: { "z-index": "2010" },
                      attrs: { dialogStatus: _vm.dialogStatus },
                      on: { submitContBtn: _vm.submitContBtn },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "40px",
                            "line-height": "40px",
                          },
                          attrs: { slot: "modalCont" },
                          slot: "modalCont",
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.showmsg) + "\n        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                2
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }