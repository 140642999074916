<template>
  <div></div>
</template>

<script>
import { setTimeout } from 'timers'
import { postApiCall, getCollCallStatus } from '@/api/pay'
export default {
  props: {
    callParams: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentNum: 0,
      currentId: '',
      callTimeSec: 0,
      callTimeMin: 0,
      callTimeHour: 0
    }
  },
  methods: {
    init() {
      this.currentNum = this.callParams.index || 0
    },
    // 下一个
    setCallPhoneNext() {
      this.currentNum++
      this.callTimeSec = 0
      this.callTimeMin = 0
      this.callTimeHour = 0
      this.setCallPhone()
    },
    // 拨打电话
    setCallPhone() {
      if (this.callParams.length === 1) {
        this.$emit('phoneCallingTo', '3')
        this.callParams[0].index = 0
        this.$emit('currentCallData', this.callParams[0])
        if (!this.callParams[0].mobile) {
          this.$message({
            message: '当前公司暂无手机号',
            type: 'warning'
          })
          this.$emit('noPhoneCall', false)
          return
        } else {
          const params = {
            mobile: this.callParams[0].mobile || '',
            companyUuid: this.callParams[0].companyUuid
          }

          this.callTimeSec = 0
          this.callTimeMin = 0
          this.callTimeHour = 0
          postApiCall(params).then(res => {
            if (res.code === 60000) {
              this.$emit('startComputTime', '呼叫中...')
              this.currentId = res.data.contactId
              this.$emit('HavePhoneCall', false)
              this.startPolling()
            }
          })
        }
      } else {
        if (this.currentNum > this.callParams.length - 1) {
          this.$message({
            message: '全部拨打完成',
            type: 'success'
          })
          this.$emit('noPhoneCall', false)
          this.$emit('phoneCallingTo', '3')
          return
        } else {
          this.$emit('phoneCallingTo', '2')
        }

        this.callParams[this.currentNum].index = this.currentNum
        this.$emit('currentCallData', this.callParams[this.currentNum])
        const ele = this.callParams[this.currentNum]
        if (ele.mobile === '-') {
          this.$message({
            message: '当前公司暂无手机号',
            type: 'warning'
          })
        } else {
          if (!this.callParams[this.currentNum].show) {
            this.currentNum--
            this.$emit('passPhoneCall', true)
            return
          }

          this.callTimeSec = 0
          this.callTimeMin = 0
          this.callTimeHour = 0
          const params = {
            mobile: this.callParams[this.currentNum].mobile || '',
            companyUuid: this.callParams[this.currentNum].companyUuid
          }
          postApiCall(params).then(res => {
            if (res.code === 60000) {
              this.$emit('startComputTime', '呼叫中...')
              this.currentId = res.data.contactId
              this.startPolling()
              if (this.callParams.length === 1) {
                this.$emit('HavePhoneCall', false)
              }
            } else {
              this.$message({
                message: res.msg,
                type: 'warning'
              })
            }
          })
        }
      }
    },
    // 计算通话时长
    computCallTime() {
      this.callTimeSec++
      if (this.callTimeSec === 59) {
        this.callTimeMin++
        this.callTimeSec = 0
      }
      if (this.callTimeMin === 59) {
        this.callTimeHour++
        this.callTimeMin = 0
      }
      var HtmlSec = ''
      if (this.callTimeSec < 10) {
        HtmlSec = '0' + this.callTimeSec
      } else {
        HtmlSec = this.callTimeSec
      }
      var HtmlMin = ''
      if (this.callTimeMin < 10) {
        HtmlMin = '0' + this.callTimeMin
      } else {
        HtmlMin = this.callTimeMin
      }
      var HtmlHour = ''
      if (this.callTimeHour < 10) {
        HtmlHour = '0' + this.callTimeHour
      } else {
        HtmlHour = this.callTimeHour
      }
      this.$emit('startComputTime', HtmlHour + ':' + HtmlMin + ':' + HtmlSec)
    },
    // 开始轮询
    startPolling() {
      const params = {
        id: this.currentId
      }
      getCollCallStatus(params).then(res => {
        if (res.code === 60000) {
          if (res.data.contactDisposition === 'Success') {
            this.$emit('CallNoAnswer', '通话结束')
          } else if (res.data.contactDisposition === 'NoAnswer') {
            this.$emit('CallNoAnswer', '呼叫失败')
          } else if (res.data.contactDisposition === 'AbandonedInContactFlow') {
          } else if (res.data.contactDisposition === 'AbandonedInQueue') {
          } else if (res.data.contactDisposition === 'AbandonedRing') {
          } else if (res.data.contactDisposition === 'QueueOverflow') {
          } else if (res.data.contactDisposition === 'OneStepTransfer') {
          } else if (res.data.contactDisposition === '') {
            setTimeout(() => {
              this.computCallTime()
              this.startPolling()
            }, 1000)
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
          if (this.currentNum > this.callParams.length - 1) {
            this.$emit('noPhoneCall', false)
            return
          } else {
            this.$emit('phoneCallingTo', '3')
          }
        }
      })
    }
  }
}
</script>

<style scoped></style>
