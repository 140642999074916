var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "registerTip-main" }, [
    _c("p", [
      _vm._v(
        "\n    恭喜您，账号已注册成功。产品顾问将根据您的行业和业务需求，为您开通系统权限。扫码联系小助手[领鸟云晓晓]，获取产品使用帮助。\n  "
      ),
    ]),
    _vm._v(" "),
    _c("img", { staticClass: "imgIcon", attrs: { src: _vm.Img } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }