var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.recomListArr && _vm.recomListArr.length
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.showRecommend,
              expression: "showRecommend",
            },
          ],
          staticClass: "recom-list-cont",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "0 38px" } },
            _vm._l(_vm.recomListArr, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "recom-list-card",
                  on: {
                    click: function ($event) {
                      return _vm.goRecommendPage(item)
                    },
                  },
                },
                [
                  _c("p", { staticClass: "crad-title over-text" }, [
                    _vm._v(_vm._s(item.company_name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "card-tag-com",
                      staticStyle: { float: "right" },
                    },
                    _vm._l(item.tags, function (tag, ind) {
                      return _c(
                        "span",
                        { key: ind + "tag", staticClass: "card-tag-all" },
                        [_vm._v(_vm._s(tag))]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-detail-cont" }, [
                    item.registered_time
                      ? _c("span", [
                          _vm._v("注册时间:" + _vm._s(item.registered_time)),
                        ])
                      : _vm._e(),
                    item.registered_time ? _c("br") : _vm._e(),
                    _vm._v(" "),
                    item.registered_capital
                      ? _c("span", [
                          _vm._v("注册资本:" + _vm._s(item.registered_capital)),
                        ])
                      : _vm._e(),
                    item.registered_capital ? _c("br") : _vm._e(),
                    _vm._v(" "),
                    item.employe_scale
                      ? _c("span", [
                          _vm._v("人员规模:" + _vm._s(item.employe_scale)),
                        ])
                      : _vm._e(),
                    item.employe_scale ? _c("br") : _vm._e(),
                    _vm._v(" "),
                    item.industry
                      ? _c(
                          "span",
                          {
                            class:
                              !item.registered_time ||
                              !item.registered_capital ||
                              !item.employe_scale
                                ? ""
                                : "over-industry",
                          },
                          [_vm._v("行业:" + _vm._s(item.industry))]
                        )
                      : _vm._e(),
                    _c("br"),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "recom-title-cont" }, [
      _c("span", { staticClass: "recom-tag-tag" }, [_vm._v("新进注册企业")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }