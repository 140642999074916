var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SetTing-main" },
    [
      _vm.dataType === "customerPond"
        ? [
            _c(
              "span",
              { staticClass: "remove-ii" },
              _vm._l(_vm.btnList.slice(0, 2), function (item) {
                return _c(
                  "span",
                  {
                    key: item.name,
                    staticClass: "com-list-detail opea",
                    staticStyle: {
                      height: "50px",
                      display: "inline-block",
                      "line-height": "50px",
                    },
                    on: {
                      click: function ($event) {
                        return item.click(
                          _vm.dataSource.row,
                          _vm.dataSource.$index
                        )
                      },
                    },
                  },
                  [
                    item.name === "联系人"
                      ? _c(
                          "span",
                          [
                            _vm.dataSource.row.contactCount
                              ? _c(
                                  "el-badge",
                                  {
                                    staticClass: "badge-item",
                                    attrs: {
                                      value:
                                        _vm.dataSource.row.isKp === "1"
                                          ? "KP"
                                          : _vm.dataSource.row.contactCount,
                                      type: "primary",
                                    },
                                  },
                                  [_vm._v("联系人")]
                                )
                              : _c("span", [_vm._v("联系人")]),
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(
                            "\n          " + _vm._s(item.name) + "\n        "
                          ),
                        ]),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.showDropDown
              ? _c(
                  "span",
                  { staticStyle: { cursor: "pointer" } },
                  [
                    _c(
                      "el-dropdown",
                      { on: { command: _vm.jump } },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                            staticStyle: { color: "#0099CC" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          _vm._l(_vm.btnList.slice(2), function (item) {
                            return _c(
                              "el-dropdown-item",
                              {
                                key: item.name,
                                attrs: {
                                  command: {
                                    item: item,
                                    row: _vm.dataSource.row,
                                    index: _vm.dataSource.$index,
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.name) +
                                    "\n          "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "我是标题",
                  visible: _vm.openDrawer,
                  size: "600px",
                  "with-header": false,
                  "append-to-body": "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.openDrawer = $event
                  },
                },
              },
              [
                _vm.openDrawer
                  ? _c("pond-detail", {
                      attrs: {
                        source: "costomerpond",
                        customerInfoMap: _vm.dataSource.row,
                        id: _vm.dataSource.row.companyUuid,
                        "company-uuid": _vm.dataSource.row.companyUuid,
                        "package-id": _vm.dataSource.row.packageId,
                        rowCont: _vm.dataSource.row,
                        svg: "",
                        majorId: _vm.dataSource.row.id,
                        isActiveTab1: _vm.dataSource.row.type + "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.userProState
              ? _c("user-profile", {
                  ref: "userPro",
                  attrs: {
                    customerInfo: _vm.dataSource.row,
                    title: _vm.selectTitle,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "商机信息",
                  "show-close": false,
                  "append-to-body": "",
                  visible: _vm.showChance,
                  "with-header": false,
                  size: "700px",
                  "destroy-on-close": "",
                  direction: "rtl",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.showChance = $event
                  },
                  close: function ($event) {
                    _vm.showChance = false
                  },
                },
              },
              [
                _c("chance-view", {
                  attrs: {
                    "key-data": _vm.dataSource.row,
                    "active-tabs": _vm.dataSource.row.type,
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.dataType === "contract"
        ? [
            _c("span", { staticClass: "remove-i" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-合同管理-锁定/解锁合同",
                      expression: "'客户管理CRM-合同管理-锁定/解锁合同'",
                    },
                  ],
                  staticClass: "set-btn",
                  on: { click: _vm.lockContract },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.dataSource.row.isEdit ? "锁定" : "解锁") +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-合同管理-锁定/解锁合同",
                      expression: "'客户管理CRM-合同管理-锁定/解锁合同'",
                    },
                  ],
                },
                [_vm._v(" | ")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-合同管理-编辑",
                      expression: "'客户管理CRM-合同管理-编辑'",
                    },
                  ],
                  class: _vm.dataSource.row.isEdit ? "set-btn" : "lock",
                  on: { click: _vm.editContract },
                },
                [_vm._v("\n        编辑\n      ")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-合同管理-编辑",
                      expression: "'客户管理CRM-合同管理-编辑'",
                    },
                  ],
                },
                [_vm._v(" | ")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-收款管理-查看收款单",
                      expression: "'客户管理CRM-收款管理-查看收款单'",
                    },
                  ],
                  staticClass: "set-btn",
                  on: { click: _vm.payBack },
                },
                [_vm._v("\n        收款\n      ")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-收款管理-查看收款单",
                      expression: "'客户管理CRM-收款管理-查看收款单'",
                    },
                  ],
                },
                [_vm._v(" | ")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-合同管理-删除",
                      expression: "'客户管理CRM-合同管理-删除'",
                    },
                  ],
                  class: _vm.dataSource.row.isEdit ? "set-btn" : "lock",
                  on: { click: _vm.deleteChance },
                },
                [_vm._v("\n        删除\n      ")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-合同管理-删除",
                      expression: "'客户管理CRM-合同管理-删除'",
                    },
                  ],
                },
                [_vm._v(" | ")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "收款管理",
                  size: "40%",
                  "destroy-on-close": "",
                  "append-to-body": "",
                  visible: _vm.showPay,
                  "with-header": false,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.showPay = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("pay-manage", {
                      attrs: {
                        "pay-data": _vm.dataSource.row,
                        "follow-user-list": _vm.followUserList,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.dataType === "callHistory"
        ? [
            _vm.dataSource.row.ossUrl
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "dt-permission",
                        rawName: "v-dt-permission",
                        value: "电销外呼-通话记录-播放录音",
                        expression: "'电销外呼-通话记录-播放录音'",
                      },
                    ],
                    staticClass: "click",
                    on: { click: _vm.showAudio },
                  },
                  [_vm._v("播放录音")]
                )
              : _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "dt-permission",
                        rawName: "v-dt-permission",
                        value: "电销外呼-通话记录-播放录音",
                        expression: "'电销外呼-通话记录-播放录音'",
                      },
                    ],
                    staticClass: "click",
                    staticStyle: { color: "#c0c4cc" },
                  },
                  [_vm._v("播放录音")]
                ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.dataType === "followRecord" ? void 0 : _vm._e(),
      _vm._v(" "),
      _vm.dataType === "payBack"
        ? [
            _c("span", { staticClass: "remove-i" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-收款管理-编辑",
                      expression: "'客户管理CRM-收款管理-编辑'",
                    },
                  ],
                  staticClass: "set-btn",
                  on: { click: _vm.editPayBack },
                },
                [_vm._v("编辑")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-收款管理-编辑",
                      expression: "'客户管理CRM-收款管理-编辑'",
                    },
                  ],
                },
                [_vm._v(" | ")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-收款管理-删除",
                      expression: "'客户管理CRM-收款管理-删除'",
                    },
                  ],
                  staticClass: "set-btn",
                  on: { click: _vm.delPayBack },
                },
                [_vm._v("删除")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "客户管理CRM-收款管理-删除",
                      expression: "'客户管理CRM-收款管理-删除'",
                    },
                  ],
                },
                [_vm._v(" | ")]
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.dataType === "wc"
        ? [
            _c("span", { staticClass: "remove-i" }, [
              _c(
                "span",
                {
                  class:
                    _vm.dataSource.row.purchaseAccessory &&
                    _vm.dataSource.row.purchaseAccessory !== "[]"
                      ? "set-btn"
                      : "set-btn-gray set-btn",
                  on: {
                    click: function ($event) {
                      return _vm.getAccessory(_vm.dataSource.row)
                    },
                  },
                },
                [_vm._v("\n        附件\n      ")]
              ),
              _vm._v(" "),
              _c("span", [_vm._v("|")]),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "供应商-采购管理-编辑",
                      expression: "'供应商-采购管理-编辑'",
                    },
                  ],
                  staticClass: "set-btn",
                  on: {
                    click: function ($event) {
                      return _vm.editPurchase(_vm.dataSource.row)
                    },
                  },
                },
                [_vm._v("\n        编辑\n      ")]
              ),
              _vm._v(" "),
              _c("span", [_vm._v("|")]),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "dt-permission",
                      rawName: "v-dt-permission",
                      value: "供应商-采购管理-删除",
                      expression: "'供应商-采购管理-删除'",
                    },
                  ],
                  staticClass: "set-btn",
                  on: {
                    click: function ($event) {
                      return _vm.delPurchase(_vm.dataSource.row)
                    },
                  },
                },
                [_vm._v("\n        删除\n      ")]
              ),
              _vm._v(" "),
              _c("span", [_vm._v("|")]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }