<template>
    <div class="t-main" v-loading="loading" element-loading-text="正在转成客户中，请稍后。。。">
      <el-form label-width="auto" :model="formData" :rules="rules" ref="form">
        <el-form-item label="接收人" prop="followUserId">
          <el-select v-model="formData.followUserId" placeholder="请选择客户接收人" style="width: 100%">
            <el-option
              v-for="item in list"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <div class="save-this-search">
        </div>
        <div>
          <el-button size="small" @click="closeBtn">取消</el-button>
          <el-button type="primary" size="small" @click="confirmBtn">确认</el-button>
        </div>
      </div>
    </div>
</template>

<script>
  import { getCustomerReceivers, postCusTransCus } from '@/api/customerpond'
  export default {
    name: 'TransCustomer',
    data(){
      return {
        list: [],
        followUserId: '',
        selectionList: [],
        loading: false,
        formData: {
          followUserId: ''
        },
        rules: {
          followUserId: [
            { required: true, message: '请选择客户接收人', trigger: 'change' },
          ]
        }
      }
    },

    mounted () {
      this.selectionList = this.$store.state.plugin.modal.options.selectionList
      this.getUserList()
    },

    methods: {
      getUserList(){
        const params = {
          pageId: 2001,
          type: 1
        }
        getCustomerReceivers(params).then(res=>{
          if(res.code === 60000){
            this.list = res.data
          }else {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'error'
            })
          }
        })
      },

      closeBtn(){
        this.$store.commit('update', {
          target: this.$store.state.plugin.modal.options,
          data: {
            refresh: false
          }
        })
        this.$store.dispatch('hideModal')
      },

      confirmBtn(){
        this.$refs.form.validate(valid=>{
          if(valid){
            // todo 转成客户
            let companyNames = this.selectionList.map(item=>item.company_name).join(',')
            let followUserIds = []
            this.selectionList.forEach(item=>{
              followUserIds.push(this.formData.followUserId)
            })
            const params = {
              companyNames: companyNames,
              followUserIds: followUserIds.join(','),
              packageId: 0,
              type: 1
            }
            this.loading = true
            postCusTransCus(params).then(res=>{
              this.loading = false
              if(res.code === 60000){
                this.$store.dispatch('showTip', {
                  text: res.msg,
                  type: 'success'
                })
                this.$store.commit('update', {
                  target: this.$store.state.plugin.modal.options,
                  data: {
                    refresh: true
                  }
                })
              }
              this.$store.dispatch('hideModal')
            })
          }
        })
      }
    }
  }
</script>

<style scoped>
.t-main{
  padding: 30px 10px 0 30px;
  width: 100%;
}
.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: relative;
}
.footer::before{
  content: '';
  height: 1px;
  width: calc( 100% + 40px );
  background-color: rgba(0,0,0,.1);
  top: 0;
  left: -20px;
  position: absolute;
  transform: scaleY(.5);
}
</style>
