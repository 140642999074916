import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

// 获取验证码
export const getCode = async params => {
  const api = API('GET_CODE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取高级筛选缓存
export const getSearchPlus = async params => {
  const api = API('GETSEARCHPLUS')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 缓存高级筛选
export const setSearchPlus = async params => {
  const api = API('SETSEARCHPLUS')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取地址
export const getAddress = async params => {
  const api = API('GET_ADDRESS')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取地址2
export const getAddress2 = async params => {
  const api = API('GET_ADDRESS2')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获客方式
export const getCustomer = async params => {
  const api = API('CUSTOMER_WAY')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取字典
export const getMode = async params => {
  const api = API('GET_MODE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 手机验证码验证
export const userPhoneCode = async params => {
  const api = API('USER_PHONE_CODE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 密码重置
export const userPwdUpdate = async params => {
  const api = API('USER_PWD_UPDATE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 检测用户名是否存在
export const userCheckName = async params => {
  const api = API('USER_CHECK_NAME')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 注册
export const userRegisterBase = async params => {
  const api = API('USER_REGISTER_BASE')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 企业信息
export const userRegisterBusiness = async params => {
  const api = API('USER_REGISTER_BUSINESS')
  return await BASE_REQUEST({ api: api, opt: params })
}

//字段编辑
export const editField = async params => {
  const api = API('EDITFIELD')
  return await BASE_REQUEST({ api: api, opt: params })
}

//字段校验
export const checkField = async params => {
  const api = API('CHECKFIELD')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 企业画像
export const userRegisterCoustomer = async params => {
  const api = API('USER_REGISTER_COUSTOMER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 注册企业画像
export const userCompanyCoustomer = async params => {
  const api = API('USER_COMPANY_COUSTOMER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 线索包分类
export const packageMarkSort = async params => {
  const api = API('PACKAGE_MARK_SORT')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 线索包市场列表
export const packageMarkList = async params => {
  const api = API('PACKAGE_MARK_LIST')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 线索包订阅
export const packageMarkSubscribe = async params => {
  const api = API('PACKAGE_MARK_SUBSCRIBE')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 线索包取消订阅
export const packageMarkunSubscribe = async params => {
  const api = API('PACKAGE_MARK_UNSUBSCRIBE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 企业画像行业字典
export const getDiccIndustry = async params => {
  const api = API('GET_DDICINDUSTRY')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getEsSearch = async params => {
  const api = API('GET_ESSEARCH')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 线索公海-是否显示云服务商变更
export const setGongyingshang = async params => {
  const api = API('SET_GONGYINGSHANG')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 线索公海-是否显示网站可访问
export const setWebsite = async params => {
  const api = API('SET_WEBSITE')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 跟进记录-全部
export const recordCustomer = async params => {
  const api = API('RECORD_CUSTOMER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 跟进记录-全部
export const recordAll = async params => {
  const api = API('RECORD_All')
  return await BASE_REQUEST({ api: api, opt: params })
}

//客户雷达
export const recordRadar = async params => {
  const api = API('RECORDRADAR')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 查重
export const getRepeatCompany = async params => {
  const api = API('SEARCH_REPEAT')
  return await BASE_REQUEST({ api: api, opt: params })
}

//标签列表
export const getTagsList = async params => {
  const api = API('GET_TagsList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//新增标签
export const getTagsAdd = async params => {
  const api = API('GET_TagsAdd')
  return await BASE_REQUEST({ api: api, opt: params })
}

//删除标签
export const getTagsDel = async params => {
  const api = API('GET_TagsDel')
  return await BASE_REQUEST({ api: api, opt: params })
}

//修改标签
export const getTagsEdit = async params => {
  const api = API('GET_TagsEdit')
  return await BASE_REQUEST({ api: api, opt: params })
}

//新增线索
export const addClue = async params => {
  const api = API('ADD_CLUE')
  return await BASE_REQUEST({ api: api, opt: params })
}

//编辑线索
export const editClue = async params => {
  const api = API('EDIT_CLUE')
  return await BASE_REQUEST({ api: api, opt: params })
}

//转成客户
export const setCostormer = async params => {
  const api = API('SET_COSTORMER')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 设置KP选项
export const getKPOption = async params => {
  const api = API('GET_KPOPTION')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 提交KP选项
export const postSocialKP = async params => {
  const api = API('POSTSOCIALKP')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取KP选项
export const getSocialKP = async params => {
  const api = API('GETSOCIALKP')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 高级查找查询条件
export const getHeiSearchList = async params => {
  const api = API('HEIGHT_SEACH')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 高级查找查询
export const getHeiSearch = async params => {
  const api = API('HEGHT_GET')
  return await BASE_REQUEST({ api: api, opt: params })
}
//联系人删除
export const coustomerDel = async params => {
  const api = API('COUSTOMER_DEL')
  return await BASE_REQUEST({ api: api, opt: params })
}
//联系人编辑
export const coustomerEdit = async params => {
  const api = API('COUSTOMER_EDIT')
  return await BASE_REQUEST({ api: api, opt: params })
}
//联系人列表
export const coustomerList = async params => {
  const api = API('COUSTOMER_LIST')
  return await BASE_REQUEST({ api: api, opt: params })
}
//联系人新增
export const coustomerAdd = async params => {
  const api = API('COUSTOMER_ADD')
  return await BASE_REQUEST({ api: api, opt: params })
}

//客户画像新增
export const userProfileAdd = async params => {
  const api = API('USERPROFILE_ADD')
  return await BASE_REQUEST({ api: api, opt: params })
}
//客户画像编辑
export const userProfileEdit = async params => {
  const api = API('USERPROFILE_EDIT')
  return await BASE_REQUEST({ api: api, opt: params })
}
//客户画像删除
export const userProfileDel = async params => {
  const api = API('USERPROFILE_DEL')
  return await BASE_REQUEST({ api: api, opt: params })
}
//客户画像查询
export const userProfileSearch = async params => {
  const api = API('USERPROFILE_SEARCH')
  return await BASE_REQUEST({ api: api, opt: params })
}
//客户画像查询
export const userProfileWord = async params => {
  const api = API('USERPROFILE_WORD')
  return await BASE_REQUEST({ api: api, opt: params })
}

//合同修改
export const contractEdit = async params => {
  const api = API('CONTRACT_EDIT')
  return await BASE_REQUEST({ api: api, opt: params })
}
//合同-负责人
export const contractLeaders = async params => {
  const api = API('CONTRACT_LEADERS')
  return await BASE_REQUEST({ api: api, opt: params })
}
//合同-客户名称列表
export const contractCompanyList = async params => {
  const api = API('CONTRACT_COMPANYLIST')
  return await BASE_REQUEST({ api: api, opt: params })
}
//合同删除
export const contractDel = async params => {
  const api = API('CONTRACT_DEL')
  return await BASE_REQUEST({ api: api, opt: params })
}
//合同新增
export const contractAdd = async params => {
  const api = API('CONTRACT_ADD')
  return await BASE_REQUEST({ api: api, opt: params })
}
//合同高级查询
export const contractSearch = async params => {
  const api = API('CONTRACT_SEARCH')
  return await BASE_REQUEST({ api: api, opt: params })
}
// 合同高级查找查询条件获取
export const contractFilter = async params => {
  const api = API('CONTRACT_FILTER')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getEsIndustry = async params => {
  const api = API('GET_ESINDUSTRY')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getEsVc = async params => {
  const api = API('GET_ESVC')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getPackPrivate = async params => {
  const api = API('GET_PACKPRIVATE')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const delCancelPrise = async params => {
  const api = API('DEL_CANCELPRISE')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getTouchPackList = async params => {
  const api = API('GET_TOUCHPACK')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getCusProView = async params => {
  const api = API('GET_COMCUSPROVIEW')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getEsRecommend = async params => {
  const api = API('GET_ESRECOMMEND')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getEsIsRecommend = async params => {
  const api = API('GET_ESISRECOMM')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getBookingComNum = async params => {
  const api = API('GET_BOOKINGCOMNUM')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getPackHadBooking = async params => {
  const api = API('GET_PACKHADBOOKING')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getPackBookingMore = async params => {
  const api = API('GET_PACKBOOKINGMORE')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getPackageTagLevel = async params => {
  const api = API('GETPACKAGETAGLEVEL')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const callUp = async params => {
  const api = API('CALL_UP')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const hangUp = async params => {
  const api = API('HANG_UP')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 钉钉免登
export const ddAuth = async params => {
  const api = API('ddAuth')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 代理商列表
export const agentList = async params => {
  const api = API('agentList')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 代理商详情
export const agentDetail = async params => {
  const api = API('agentDetail')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 代理商编辑
export const agentEdit = async params => {
  const api = API('agentEdit')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 新注册接口
export const register = async params => {
  const api = API('register')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 飞书登录
export const ttLogin = async params => {
  const api = API('ttLogin')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 获取发票信息
export const invDetail = async params => {
  const api = API('invDetail')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 新增或修改发票信息
export const editInv = async params => {
  const api = API('editInv')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 锁定合同
export const lockContract = async params => {
  const api = API('lockContract')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const childList = async params => {
  const api = API('childList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const departmentList = async params => {
  const api = API('departmentList')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const addDepartment = async params => {
  const api = API('addDepartment')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const updateDepartment = async params => {
  const api = API('updateDepartment')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const wxLogin = async params => {
  const api = API('wxLogin')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const loginBySmS = async params => {
  const api = API('CODE_PC_TOKEN')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const bingWx = async params => {
  const api = API('bindWx')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const wxVery = async params => {
  const api = API('wxVery')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getConcatHistory = async params => {
  const api = API('getConcatHistory')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getOpenApi = async params => {
  const api = API('getOpenApi')
  return await BASE_REQUEST({ api: api, opt: params })
}
export const getLogList = async params => {
  const api = API('getLogList')
  return await BASE_REQUEST({ api: api, opt: params })
}
