<template>
  <div class="registerTip-main">
    <p>
      恭喜您，账号已注册成功。产品顾问将根据您的行业和业务需求，为您开通系统权限。扫码联系小助手[领鸟云晓晓]，获取产品使用帮助。
    </p>
    <img :src="Img" class="imgIcon" />
  </div>
</template>

<script>
export default {
  name: 'registerTip',
  data() {
    return {
      Img:
        'https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lner.png'
    }
  }
}
</script>

<style scoped lang="scss">
.registerTip-main {
  padding: 10px 20px 30px 20px;
  box-sizing: border-box;
  text-indent: 2rem;
  line-height: 150%;
  .imgIcon {
    width: 100px;
    height: 100px;
    display: block;
    margin: 10px auto 0;
  }
}
</style>
