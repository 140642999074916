<template>
    <div class="edit-body" @click="showLandPageSelect ? showLandPageSelect = false:''">
        <el-scrollbar style="height: 100%;width: 100%" class="mini-scroll">
          <el-form label-position="left" label-width="70px" size="mini">
              <el-form-item label="头图">
                <div class="upload-icon upload-icon4">
                  <el-upload
                    class="avatar-uploader"
                    action
                    accept=".jpg, .png, .jpeg"
                    :before-upload="saveFile('thumbnail')"
                    :show-file-list="false"
                    :http-request="uploadThumbnailToOss"
                    :auto-upload="true">
                    <img v-if="htmlStyle.share.img" :src="htmlStyle.share.img" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-form-item>

              <el-form-item label="标题">
                <el-input placeholder="请输入落地页标题" v-model="htmlStyle.share.title"></el-input>
              </el-form-item>

              <el-form-item label="简介">
                <el-input placeholder="请输入简介" v-model="htmlStyle.share.desc" ></el-input>
              </el-form-item>
          </el-form>

          <el-form label-width="70px" label-position="left" size="small">
            <el-form-item label="选择模版">
              <el-radio v-model="htmlStyle.cardType" label="1">黑色</el-radio>
              <el-radio v-model="htmlStyle.cardType" label="2">白色</el-radio>
            </el-form-item>
            <el-form-item label="公司">
              <el-input placeholder="请输入公司信息" v-model="htmlStyle.earnMsg.companyName"></el-input>
            </el-form-item>

            <el-form-item label="姓名">
              <el-input placeholder="请输入姓名" v-model="htmlStyle.earnMsg.followName"></el-input>
            </el-form-item>

            <el-form-item label="职位">
              <el-input placeholder="请输入职位" v-model="htmlStyle.earnMsg.position"></el-input>
            </el-form-item>

            <el-form-item label="电话">
              <el-input placeholder="请输入电话" v-model="htmlStyle.earnMsg.phoneNum"></el-input>
            </el-form-item>

            <el-form-item label="邮箱">
              <el-input placeholder="请输入邮箱" v-model="htmlStyle.earnMsg.emailNum"></el-input>
            </el-form-item>

            <el-form-item label="官网">
              <el-input placeholder="请输入官网" v-model="htmlStyle.earnMsg.website"></el-input>
            </el-form-item>

            <el-form-item label="地址">
              <el-input placeholder="请输入地址" v-model="htmlStyle.earnMsg.address"></el-input>
            </el-form-item>
          </el-form>

          <div class="upload-row">
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="upload-icon">
                  <el-upload
                    class="avatar-uploader"
                    action
                    accept=".jpg, .png, .jpeg"
                    :before-upload="saveFile('weChatLogo')"
                    :show-file-list="false"
                    :http-request="uploadWeChartHeaderImgToOss"
                    :auto-upload="true">
                    <img v-if="htmlStyle.earnMsg.avatar" :src="htmlStyle.earnMsg.avatar" class="avatar" style="height: 60px;width: 60px">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="upload-icon upload-icon1">
                  <el-upload
                    class="avatar-uploader"
                    action
                    accept=".jpg, .png, .jpeg"
                    :before-upload="saveFile('companyLogo')"
                    :show-file-list="false"
                    :http-request="uploadCompanyLogoToOss"
                    :auto-upload="true">
<!--                    <img v-if="htmlStyle.earnMsg.companyLogo" :src="htmlStyle.earnMsg.companyLogo" class="avatar" style="height: 60px;width: 60px">-->
                    <el-image
                      v-if="htmlStyle.earnMsg.companyLogo"
                      :src="htmlStyle.earnMsg.companyLogo + '?x-oss-process=image/resize,h_60,w_60'"
                      ></el-image>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="upload-icon upload-icon2">
                  <el-upload
                    class="avatar-uploader"
                    action
                    accept=".jpg, .png, .jpeg"
                    :before-upload="saveFile('erCodeImg')"
                    :show-file-list="false"
                    :http-request="uploadErCodeImgToOss"
                    :auto-upload="true">
                    <img v-if="htmlStyle.earnMsg.ercode" :src="htmlStyle.earnMsg.ercode" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-col>
            </el-row>
          </div>

          <el-form label-width="auto" size="mini" label-position="left">
            <el-form-item label="视频">
              <el-checkbox v-model="htmlStyle.show.video"></el-checkbox>
            </el-form-item>

            <el-form-item :disabled="!htmlStyle.show.video">
              <div class="upload-div" :class="htmlStyle.show['video'] ? '':'disabled' ">
                <el-upload
                  class="el-upload"
                  ref="upload"
                  action
                  accept=".mp4, .mov, .avi, .flv, .wmv, .mpeg, .rm"
                  :show-file-list="false"
                  :before-upload="saveFile('video')"
                  :http-request="uploadFileToOss"
                  :auto-upload="true"
                  :disabled="!htmlStyle.show['video']">
                  <div>
                    <i class="el-icon-plus">
                      上传视频
                    </i>
                  </div>
                </el-upload>
              </div>
            </el-form-item>

            <el-form-item label="图片">
              <el-checkbox v-model="htmlStyle.show.photo"></el-checkbox>
            </el-form-item>

            <el-form-item :disable="!htmlStyle.show.photo">
              <div class="upload-div" :class="htmlStyle.show['photo'] ? '':'disabled' ">
                <el-upload
                  class="el-upload"
                  ref="upload"
                  action
                  accept=".jpg, .png, .jpeg"
                  :show-file-list="false"
                  :before-upload="saveFile('photo')"
                  :http-request="uploadPhotoToOss"
                  :auto-upload="true"
                  :disabled="!htmlStyle.show['photo']">
                  <div>
                    <i class="el-icon-plus">
                      上传图片
                    </i>
                  </div>
                </el-upload>
              </div>
            </el-form-item>

            <el-form-item label="文字">
              <el-checkbox v-model.trim="htmlStyle.show.content"></el-checkbox>
            </el-form-item>

            <el-form-item>
              <el-input :disabled="!htmlStyle.show.content" type="textarea" v-model="htmlStyle.textareaCont" :autosize="{ minRows: 4, maxRows: 100}"></el-input>
            </el-form-item>

            <el-form-item label="底部菜单">

            </el-form-item>

            <el-form-item label="动态" style="position: relative">
<!--              <i class="el-icon-arrow-down" style="color:#8a8a8a;position: absolute;right: 10px;top:30%;z-index: 99999999999999999999"></i>-->
              <div class="wyx">
                <div>
                  <el-checkbox v-model="htmlStyle.barStatus.showYx"/>
                </div>
                <div class="up-search" @click.stop="htmlStyle.barStatus.showYx ? showLandPageSelect = true:'' ">
                    <div class="up-search-row" v-show="showLandPageSelect">
                      <div style="padding: 0 10px">
                        <el-form>
                          <el-form-item size="mini">
                            <el-input @input="getLandPage" @click.stop v-model="searchPageName" placeholder="请输入或选择落地页" suffix-icon="el-icon-search"></el-input>
                          </el-form-item>
                        </el-form>
                      </div>
                        <el-scrollbar class="landpage-list" v-loading="loading">
                          <div v-for="item in landPageList" :key="item.value" class="landpage-item" @click.stop="selectChange(item.value)">
                            {{ item.label }}
                          </div>
                          <div class="empty" v-if="!landPageList.length">
                              空空如也～
                          </div>
                        </el-scrollbar>
                    </div>
                    <div style="height: 32px" class="upload-div" :class="htmlStyle.barStatus.showYx ? 'text-left':'disabled text-left' ">
                    {{ htmlStyle.barItem.landPageName || '请选择素材' }}
                    </div>
<!--                  <el-select-->
<!--                    placeholder="请输入或选择落地页"-->
<!--                    :disabled="!htmlStyle.barStatus.showYx"-->
<!--                    @change="selectChange"-->
<!--                    style="width: 100%"-->
<!--                    v-model="searchFilter"-->
<!--                    filterable-->
<!--                    remote-->
<!--                    reserve-keyword-->
<!--                    :placeholder="showName"-->
<!--                    :remote-method="getLandPage"-->
<!--                    :loading="loading">-->
<!--                    <el-option-->
<!--                      v-for="item in landPageList"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
                </div>
              </div>
            </el-form-item>

            <el-form-item label="名片">
              <el-checkbox v-model="htmlStyle.barStatus.showMp"/>
            </el-form-item>

            <el-form-item label="官网">
              <div class="gw">
                <div>
                  <el-checkbox v-model="htmlStyle.barStatus.showWs"/>
                </div>
                <div>

                  <el-input placeholder="请输入官网" :disabled="!htmlStyle.barStatus.showWs" v-model="htmlStyle.barItem.websiteUrl" class="input-with-select">
                    <el-select v-model="httpArr" slot="prepend" placeholder="请选择" style="width: 80px">
                      <el-option label="http" value="http"></el-option>
                      <el-option label="https" value="https"></el-option>
                    </el-select>
                  </el-input>

<!--                  <el-input :disabled="!htmlStyle.barStatus.showWs" placeholder="请输入官网" v-model="htmlStyle.barItem.websiteUrl"></el-input>-->
                </div>
              </div>
            </el-form-item>
          </el-form>
        </el-scrollbar>
    </div>
</template>

<script>
    import OSS from "ali-oss"
    import {getStsToken, getMarketingLandList, getMarketlandPageDetail} from "@/api/table"

    export default {
        name: "miniWebEdit",
        props:{
          fatherClick:{
            type: Boolean,
            default: false
          }
        },
        data(){
          const userInfo = this.$store.state.user.accountInfo
          return {
            searchPageName:'',
            showLandPageSelect: false,
            loadingVideo: false,
            httpArr:'http',
            showName:'请输入或选择落地页',
            landPageList:[],
            landList:{},//完整参数, id为索引
            loading: false,
            searchFilter:'',
            aliOssConfig: {
              // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
              region: "oss-cn-shanghai",
              // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录 https://ram.console.aliyun.com 创建RAM账号。
              accessKeyId: "",
              accessKeySecret: "",
              stsToken:"",
              bucket: "dtbird-yingxiao"
            },

            file: {
              video: "",
              photo: "",
              headerImg:'',
              erCodeImg:'',
              weChatLogo:'',
              companyLogo:'',
              erCode:''
            },

            htmlStyle: {
              videoStatus: "",
              photoStatus: "",
              bgColor: "#ffffff",
              pagraBg: "#FFFFFF",
              textBg: "#D8D8D8",
              uploadUrl: "",
              editCont: "",
              fontWeight: "normal",
              fontFamily: "Microsoft YaHei",
              fontColor: "#FFFFFF",
              textareaCont: "",
              fontSize: 14,
              buttonText: "",
              checkedArr: ['phone','name','company'],
              share: {
                title: "",
                desc: "",
                img: ""
              },
              show: {
                video: false,
                photo: false,
                content: false
              },
              cardType: "1",
              earnMsg: {
                companyName:userInfo ? userInfo.companyName : '',
                phoneNum:userInfo ? userInfo.mobile : '' , //电话
                emailNum:'', //邮箱
                website:'', //官网
                address:'', //地址
                followName: userInfo ? userInfo.followName : '', //姓名
                position:'', // 职位
                avatar:'https://dtbird-miniapp.oss-cn-shanghai.aliyuncs.com/image/header-c.svg', // 微信头像
                ercode:'https://dtbird-miniapp.oss-cn-shanghai.aliyuncs.com/image/dt-code.jpg', // 二维码
                companyLogo:'https://dtbird-yingxiao.oss-cn-shanghai.aliyuncs.com/yingxiao-img/DM-logo.png',
              },
              barStatus:{
                showYx: false,
                showMp: true,
                showWs: false
              },
              barItem:{
                landPageId:'',
                websiteUrl:'',
                landPageUrl:'',
                landPageName: ''
              }
            },
          }
        },

      mounted() {
        this.getLandPage('')
        let obj = this.$store.state.plugin.cardObj.cardParam
        if(!obj){
          obj = localStorage.getItem('minisite')
        }else {
          localStorage.setItem('minisite', obj)
        }
        if(obj){
          this.htmlStyle = JSON.parse(obj)
          this.showName = this.htmlStyle.barItem.landPageName
        }

        console.log(this.htmlStyle.barItem.websiteUrl)
        const websiteUrl = this.htmlStyle.barItem.websiteUrl
        if(websiteUrl.indexOf('https://') > -1){
          this.httpArr = 'https'
        }

        window.addEventListener("click", this.clickOther);
      },
      beforeDestroy() {
        window.removeEventListener('click', this.clickOther);
      },

      watch:{
          httpArr(){
            this.htmlStyle.barItem.websiteUrl += '*dt*'
            this.htmlStyle.barItem.websiteUrl =   this.htmlStyle.barItem.websiteUrl.split('*dt*')[0]
          },
          htmlStyle: {
            handler:function (val) {
                if(val.barItem.websiteUrl.indexOf('http://') > -1 || val.barItem.websiteUrl.indexOf('https://') > -1 ){
                  let url = val.barItem.websiteUrl.split('//')[1]
                  val.barItem.websiteUrl = `${this.httpArr}://${ url }`
                }else {
                  val.barItem.websiteUrl =  `${this.httpArr}://${ val.barItem.websiteUrl }`
                }
                this.$emit('sendData', val)
            },
            deep: true,
            immediate: true
          },

          // 'htmlStyle.barItem.landPageId'(val){
          //   if(val){
          //     this.htmlStyle.barStatus.showYx = true
          //   }else{
          //     this.htmlStyle.barStatus.showYx = false
          //   }
          // },

          // 'htmlStyle.barItem.websiteUrl'(val){
          //   if(val){
          //     this.htmlStyle.barStatus.showWs = true
          //   }else{
          //     this.htmlStyle.barStatus.showWs = false
          //   }
          // },
        },
        methods:{
          clickOther(e){
            this.showLandPageSelect ? this.showLandPageSelect = false : ''
          },


          getLandPage(){
            this.loading = true;
            getMarketingLandList({landPageName: this.searchPageName}).then((res)=>{
              this.loading = false;
              this.landPageList = res.data.list.map((item)=>{
                this.landList[item.id] = item
                return {
                  label: item.formName,
                  value: item.id
                }
              })
            })
          },

          // 查看素材详情
          getLandPageDetail(id){
            getMarketlandPageDetail({id:id}).then((res)=>{
              if(res.code === 60000){
                this.htmlStyle.barItem.landPageUrl = res.data.formHtml
              }
            })
          },

          saveFile(name){
            return file => {
              this.file[name] = file;
            };
          },

          async uploadThumbnailToOss(){
            if (this.file["thumbnail"].size < 1024 * 200) {
              await this.getStsToken()
              const client = new OSS(this.aliOssConfig);
              let mathNum = Math.floor(Math.random() * 100000);
              let newName = Number(new Date()).toString();
              const aliObjName = mathNum + newName + this.file["thumbnail"].name;
              let result = await client.put(
                "/yingxiao-img/" + aliObjName,
                this.file["thumbnail"],
              );
              this.file["thumbnail"] = null;
              const { res, url } = result;
              this.htmlStyle.share["img"] = url;
            } else {
              this.$message.error("头图不能大于200kb");
            }
          },

          async uploadPhotoToOss() {
            this.htmlStyle["photoStatus"] = "uploading";
            await this.getStsToken()
            const client = new OSS(this.aliOssConfig);
            console.log(client)
            let mathNum = Math.floor(Math.random() * 100000);
            let newName = Number(new Date()).toString();
            const aliObjName = mathNum + newName + this.file["photo"].name;
            let result = await client.put(
              "/yingxiao-img/" + aliObjName,
              this.file["photo"]
            );
            this.file["photo"] = null;
            const { res, url } = result;
            this.htmlStyle.uploadUrl = url;
            this.htmlStyle["photoStatus"] = "";
          },

          async uploadFileToOss() {
            this.$emit('loadingVideo', true)
            this.htmlStyle["videoStatus"] = "uploading";
            const _res = await this.getStsToken()
            if(!_res){
              this.$emit('loadingVideo', false)
              return
            }
            const client = new OSS(this.aliOssConfig);

            let mathNum = Math.floor(Math.random() * 100000);
            let newName = Number(new Date()).toString();
            const aliObjName = mathNum + newName + this.file["video"].name;
            let result = await client.multipartUpload(
              "/yingxiao-video/" + aliObjName,
              this.file["video"]
            );
            this.file["video"] = null;
            const url = 'https://'+ result.bucket + '.oss-cn-shanghai.aliyuncs.com/' + result.name
            const { res } = result;
            this.htmlStyle.videoUrl = url;
            this.htmlStyle["videoStatus"] = "";
            this.$emit('loadingVideo', false)
          },

          async uploadWeChartHeaderImgToOss(){
            if (this.file["weChatLogo"].size < 1024 * 500) {
              await this.getStsToken()
              const client = new OSS(this.aliOssConfig);
              let mathNum = Math.floor(Math.random() * 100000);
              let newName = Number(new Date()).toString();
              const aliObjName = mathNum + newName + this.file["weChatLogo"].name;
              let result = await client.put(
                "/yingxiao-img/" + aliObjName,
                this.file["weChatLogo"],
              );
              this.file["weChatLogo"] = null;
              const { res, url } = result;
              this.htmlStyle.earnMsg["avatar"] = url;
            } else {
              this.$message.error("头像不能大于500kb");
            }
          },

          async uploadCompanyLogoToOss(){
            if (this.file["companyLogo"].size < 1024 * 500) {
              await this.getStsToken()
              const client = new OSS(this.aliOssConfig);
              let mathNum = Math.floor(Math.random() * 100000);
              let newName = Number(new Date()).toString();
              const aliObjName = mathNum + newName + this.file["companyLogo"].name;
              let result = await client.put(
                "/yingxiao-img/" + aliObjName,
                this.file["companyLogo"],
              );
              this.file["companyLogo"] = null;
              const { res, url } = result;
              this.htmlStyle.earnMsg["companyLogo"] = url;
            } else {
              this.$message.error("logo不能大于500kb");
            }
          },

          async uploadErCodeImgToOss(){
            if (this.file["erCodeImg"].size < 1024 * 500) {
              await this.getStsToken()
              const client = new OSS(this.aliOssConfig);
              let mathNum = Math.floor(Math.random() * 100000);
              let newName = Number(new Date()).toString();
              const aliObjName = mathNum + newName + this.file["erCodeImg"].name;
              let result = await client.put(
                "/yingxiao-img/" + aliObjName,
                this.file["erCodeImg"],
              );
              this.file["erCodeImg"] = null;
              const { res, url } = result;
              this.htmlStyle.earnMsg["ercode"] = url;
            } else {
              this.$message.error("二维码不能大于500kb");
            }
          },

          getStsToken(){
            return new Promise((resolve)=>{
              getStsToken({}).then(res => {
                if(res && res.code === 60000){
                  this.aliOssConfig.accessKeyId = res.data.accessKeyId
                  this.aliOssConfig.accessKeySecret = res.data.accessKeySecret
                  this.aliOssConfig.stsToken = res.data.securityToken
                  resolve(true)
                }else {
                  resolve(false)
                }
              }).catch(()=>{
                resolve(false)
              });
            })
          },
          selectChange(e){
            this.htmlStyle.barItem.landPageId = e
            this.htmlStyle.barItem.landPageUrl = this.landList[e]['pageUrl'].split('?')[0];
            this.htmlStyle.barItem.landPageName = this.landList[e]['formName']
            // this.getLandPageDetail(e)
            this.showLandPageSelect = !this.showLandPageSelect
          }
        }
    }
</script>

<style scoped>
.edit-body{
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.upload-icon{
  position: relative;
  font-size: 60px;
  border: 1px solid rgba(0,0,0,.1);
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-icon::after{
  content: "微信头像";
  display: block;
  position: absolute;
  bottom: -20px;
  font-size: 12px;
}
.upload-icon1::after{
  content: "企业logo";
  display: block;
  position: absolute;
  bottom: -20px;
  font-size: 12px;
}
.upload-icon2::after{
  content: "二维码";
  display: block;
  position: absolute;
  bottom: -20px;
  font-size: 12px;
}
.upload-icon4::after{
  content: "头图";
  display: none;
  position: absolute;
  bottom: -30px;
  font-size: 12px;
}
.upload-row{
  padding-left: 70px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 40px;
}
.mini-scroll{
  overflow-x: hidden;
}
.mini-scroll /deep/ .el-scrollbar__wrap {
  overflow-x: hidden!important;
}
.mini-scroll /deep/ .el-scrollbar__thumb{
  display: none!important;
}
.upload-wx-share{
  margin-bottom: 40px;
}
.avatar{
  height: 80px;
  width: 80px;
}
.upload-div{
  height: 32px;
  width: 100%;
  line-height: 32px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  cursor: pointer;
  text-align: center;
  color: rgba(0,0,0,.3);
}
.text-left{
  text-align: left!important;
  padding-left: 10px;
  color: #75767a!important;
}
.wyx, .gw{
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wyx > div:first-child{
  width: 30px;
  height: 100%;
}
.wyx > div:last-child{
  width: calc( 100% - 30px );
}
.gw > div:last-child{
  width: calc( 100% - 30px );
}
.gw > div:first-child{
  width: 30px;
  height: 100%;
}
.disabled{
  background-color: #F5F7FA;
  border-color: #E4E7ED;
  color: #C0C4CC;
  cursor: not-allowed;
}
.up-search{
  position: relative;
}

.up-search-row{
  position: absolute;
  min-height: 240px;
  width: 100%;
  bottom: 40px;left: 0;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.1);
  padding: 10px 0;
  box-sizing: border-box;
  z-index: 99999;
}
.landpage-list{
  height: 150px;
  position: relative;
}
.empty{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #8a8a8a;
}
.landpage-item{
  box-sizing: border-box;
  color: rgba(0,0,0,.8);
  cursor: pointer;
  padding: 5px 10px;
}
.landpage-item:hover{
  background-color: rgba(0,0,0,.05);
}


</style>
