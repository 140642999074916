var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.mapLoading,
            expression: "mapLoading",
          },
        ],
        ref: "pointMap",
        staticClass: "brandClass",
        attrs: { id: "brandMap" },
      },
      [
        _c("div", { ref: "info", staticClass: "map-info" }, [
          _c("div", [_vm._v(_vm._s(_vm.infoWindowData.chartItem))]),
          _vm._v(" "),
          _c("div", [_vm._v("打假数量:" + _vm._s(_vm.infoWindowData.count))]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mock" }, [
      _vm._v(
        "\n    AI雷达扫描中,侦查到未授权假店" +
          _vm._s(_vm.brandInfo.fakeCount) +
          "家，疑似假店" +
          _vm._s(_vm.brandInfo.suspectedFakeCount) +
          "家\n  "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }