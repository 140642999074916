var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pe-ai-choose-info" }, [
    _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.infoData.name))]),
    _vm._v(" "),
    _c("div", { staticClass: "label" }, [
      _c("span", [_vm._v("地址:" + _vm._s(_vm.infoData.address))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "label" }, [
      _c("span", [_vm._v("工作人口:" + _vm._s(_vm.infoData.workPeopleCnt))]),
      _vm._v(" "),
      _c("span", { staticStyle: { "margin-left": "10px" } }, [
        _vm._v("居住人口:" + _vm._s(_vm.infoData.livePeopleCnt)),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "label" },
      [
        _c("span", [_vm._v("评分:")]),
        _vm._v(" "),
        _c("el-rate", {
          attrs: {
            disabled: "",
            "show-score": "",
            "text-color": "#ff9900",
            "score-template": "{value}",
          },
          model: {
            value: _vm.infoData.rating,
            callback: function ($$v) {
              _vm.$set(_vm.infoData, "rating", $$v)
            },
            expression: "infoData.rating",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "label" }, [
      _c(
        "p",
        {
          staticClass: "company-name-click ai-link",
          on: { click: _vm.handleAI },
        },
        [_vm._v("加入AI罗盘测评")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }