<template>
  <div class="task-list-main" ref="main" v-loading="loading">
    <div class="header"></div>
    <div class="row" ref="row">
      <span class="header-tip">* 导入将会自动进行，您可以关闭此弹框</span>
      <dt-table
        :show-overflow-tooltip="false"
        :is-count-height="false"
        :table-h="tableHeight"
        :table-data="tableData"
        :is-select="false"
        :data-total="count"
        :algin="'center'"
        @sizeChange="sizeChange"
        @currentChange="currentChange"
        :column-list="columnList"
        :hide-border="true"
        :page-size="[10]"
      >
        <template v-slot:uploadPercentRow="row">
          <span>
            <el-progress
              :percentage="row.scope.data.uploadPercent"
              :stroke-width="8"
              :color="
                row.scope.data.uploadStatus === -1 ? '#f56c6c' : '#41cabf'
              "
              :class="
                row.scope.data.uploadPercent === 100
                  ? 'layui-progress-bar-no'
                  : 'layui-progress-bar'
              "
            >
            </el-progress>
          </span>
        </template>

        <template v-slot:uploadResultRow="row">
          <!--          <span>-->
          <!--            <el-progress-->
          <!--              :percentage="row.scope.data.uploadPercent"-->
          <!--              :stroke-width="8"-->
          <!--              :color="row.scope.data.uploadStatus === -1 ? '#f56c6c' : '#41cabf'"-->
          <!--              :class="row.scope.data.uploadPercent === 100 ? 'layui-progress-bar-no' : 'layui-progress-bar'">-->
          <!--            </el-progress>-->
          <!--          </span>-->

          <el-tooltip
            :visible-arrow="false"
            popper-class="popper"
            effect="dark"
            :content="row.scope.data.uploadResult"
            placement="right-end"
          >
            <div
              style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer"
            >
              {{ row.scope.data.uploadResult }}
            </div>
          </el-tooltip>
        </template>

        <template v-slot:setTingRow="row">
          <span
            v-if="row.scope.data.uploadStatus === 1"
            @click="goPage(row.scope.data)"
            class="py-v"
          >
            查看
          </span>
          <span v-else style="cursor: not-allowed;color: #8a8a8a">
            查看
          </span>
        </template>
      </dt-table>
    </div>
  </div>
</template>

<script>
import DtTable from '@/components/element/DtTable'
import { uploadList } from '@/api/workTable'
export default {
  name: 'uploadTaskList',
  components: {
    DtTable
  },
  data() {
    return {
      tableHeight: '490px',
      loading: false,
      tableData: [],
      count: 0,
      columnList: [
        {
          field: 'name',
          name: '文件名称'
        },
        {
          field: 'uploadStatusDesc',
          name: '状态'
        },
        {
          field: 'uploadResult',
          name: '说明'
        },
        {
          field: 'uploadPercent',
          name: '完成度'
        },
        {
          field: 'createdAt',
          name: '导入时间'
        }
      ],
      pageObj: {
        pageNo: 1,
        pageSize: 10
      },
      timeObj: null
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.startTask()
    })
  },

  beforeDestroy() {
    this.$store.commit('update', {
      target: this.$store.state.plugin.modal.options,
      data: {
        timeObj: this.timeObj
      }
    })
    if (this.timeObj) {
      clearInterval(this.timeObj)
      this.timeObj = null
    }
  },

  methods: {
    goPage(data) {
      const favoriteId = data.favoriteId
      const userId = data.userId
      const favoriteName = data.favoriteName
      if (favoriteId && userId && favoriteName) {
        sessionStorage.setItem('favoriteId', favoriteId)
        sessionStorage.setItem('collectName', favoriteName)
        sessionStorage.setItem('collect_userId', userId)
        this.$store.commit('update', {
          target: this.$store.state.plugin.modal.options,
          data: {
            refresh: true
          }
        })
        this.$store.dispatch('hideModal')
      }
    },

    startTask() {
      if (this.timeObj) {
        clearInterval(this.timeObj)
      }
      this.refreshTask(true)
      this.timeObj = setInterval(() => {
        this.refreshTask(false)
      }, 5 * 1000)
    },

    sizeChange(e) {
      this.pageObj.pageSize = e
      this.pageObj.pageNo = 1
      this.startTask()
    },

    currentChange(e) {
      this.pageObj.pageNo = e
      this.startTask()
    },

    // 刷新下载列表
    refreshTask(isLoding = false) {
      this.loading = isLoding
      uploadList(this.pageObj).then(res => {
        this.loading = false
        if (res.code === 60000 && res.data && res.data.data) {
          this.tableData = res.data.data
          this.count = res.data.total
          // 判断是否关闭轮询 当列表返回的没有正在下载的任务时 关闭轮询
          let isStopRequest = true
          const statusList = [-1, 1, 3]
          this.tableData.forEach(item => {
            if (!statusList.includes(item.uploadStatus)) {
              isStopRequest = false
            }
          })
          if (isStopRequest && this.timeObj) {
            clearInterval(this.timeObj)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.task-list-main {
  height: 550px;
  padding: 10px 20px 0 20px;
  box-sizing: border-box;
  user-select: none;
}
.header {
  /*height: 30px;*/
  height: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.row {
  /*height: calc( 100% - 30px );*/
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: relative;
}
.success {
  color: #0099cc;
  cursor: pointer;
}
.error {
  color: #909399;
  cursor: not-allowed;
  margin: 0;
}
.refresh {
  cursor: pointer;
  font-size: 18px;
}
.anio {
  animation-name: refreshanio;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
@keyframes refreshanio {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.header-tip {
  font-size: 10px;
  color: #909399;
  position: absolute;
  bottom: 15px;
  left: 0;
}

.layui-progress-bar ::v-deep .el-progress-bar__inner:before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  animation: move 1s linear infinite;
}
@keyframes move {
  to {
    background: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.3) 0,
        rgba(255, 255, 255, 0.3) 12.5%,
        transparent 0,
        transparent 25%
      )
      0/60px 60px;
  }
  from {
    background: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.3) 12.5%,
        rgba(255, 255, 255, 0.3) 25%,
        transparent 0%,
        transparent 37.5%
      )
      0/60px 60px;
  }
}

.layui-progress-bar ::v-deep .el-progress__text {
  font-size: 10px !important;
}
.layui-progress-bar-no ::v-deep .el-progress__text {
  font-size: 10px !important;
}
.py-v {
  color: #0099cc;
  cursor: pointer;
}
</style>
