<template>
  <div class="weixinLogin-main" v-loading="loading">
    <div v-if="show" id="wx-login-two"></div>
  </div>
</template>

<script>
import { wxVery } from '@/api/user'
import store from '@/store'
import { ResetAuthUserMeal, ResetSystemConfig } from '@/utils/auth'
import { AppWebSockt } from '@/utils/websocket'
import { setPermission } from '@/cores/RouteAndPermission'
export default {
  name: 'wechartReLogin',
  data() {
    return {
      show: true,
      loading: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.login()
        this.loading = false
      }, 1000)
    })
  },
  computed: {
    token() {
      return this.$store.state.plugin.modal.options?.token
    },
    res() {
      return this.$store.state.plugin.modal.options
    }
  },
  methods: {
    getCode(res) {
      const code = (res?.data?.url || '')?.split('code=')?.[1]?.split('&')?.[0]
      console.log(code, 'code123')
      if (code) this.startLogin(code)
    },
    login() {
      const isPro = process.env.NODE_ENV === 'production'
      const uri = isPro ? 'https://ai.dtbird.cn' : 'https://ai2.dtbird.cn'
      window.removeEventListener('message', this.getCode)
      new WxLogin({
        self_redirect: true,
        id: 'wx-login-two',
        appid: isPro ? 'wx0e7b6e5c3e1c1321' : 'wx5febb47ccea3db0e',
        scope: 'snsapi_login',
        redirect_uri: uri,
        state: '',
        style: '',
        href: 'https://yx.file.dtbird.cn/weixincss.css'
      })
      window.addEventListener('message', this.getCode)
    },

    startLogin(code) {
      // console.log(code, 9900)
      // return
      this.show = false
      wxVery({ code: code, token: this.token })
        .then(async res => {
          console.log(res, 8800)
          if (res.code === 60000) {
            res['data'] = this.res
            localStorage.clear()
            sessionStorage.clear()
            const token = res.data.token
            const step = res.data.step
            const accountType = res.data.type
            const userName = res.data.followName || ''
            store.commit('setToken', token)
            await store.dispatch('changeStep', step)
            await store.dispatch('setName', userName)
            localStorage.setItem('setAccountType', accountType + '')
            localStorage.setItem('dtBird_user_step', step)
            localStorage.setItem('setName', userName)
            localStorage.setItem('userLoginTime', new Date().getTime())
            if (step != 1) {
              ResetAuthUserMeal()
            }
            ResetSystemConfig() //获取系统信息
            AppWebSockt('kick_user_offline')
            await setPermission()
            localStorage.setItem(
              'permissionObj',
              JSON.stringify(store.state.permission.permission)
            )
            localStorage.setItem(
              'menuList',
              JSON.stringify(store.state.permission.menuList)
            )
            localStorage.setItem(
              'panelList',
              JSON.stringify(store.state.permission.panelList)
            )
            this.$store.dispatch('hideModal')
            // location.replace(url)
            this.$router.replace('/dt-index')
          } else {
            this.$message.error(res.msg)
            // this.$store.dispatch('hideModal')
          }
        })
        .catch(err => (this.show = true))
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.getCode)
  }
}
</script>

<style scoped lang="scss">
.weixinLogin-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 410px;
  & > div {
    transform: translateY(40px);
  }
}
</style>
