var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "body" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "auto",
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请填写备注信息",
                  autosize: { minRows: 4, maxRows: 10 },
                  maxlength: "200",
                  "show-word-limit": "",
                },
                on: { blur: _vm.onInputBlur, focus: _vm.onInputFocus },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.submit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }