<template>
  <div>
    <dia-model
      ref="scribesDialog"
      :dialogStatus="dialogStatus"
      @submitContBtn="submitContBtn"
    >
      <div slot="modalCont">
        <div class="qr-code-cont" v-loading="loadingCode">
          <div id="qrCode" ref="qrCodeDiv"></div>
          <div v-show="!loadingCode" style="margin-top: 10px">
            <span
              @click="copyComName(acCodeUrl)"
              class="qr-code-txt qr-code-ac"
              style="font-size:12px;"
              >复制链接</span
            >
            <span
              @click="CopyImgCode"
              class="qr-code-txt qr-code-ac"
              style="font-size:12px;"
              >下载二维码</span
            >
          </div>
        </div>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import QrCode from 'qrcodejs2'
export default {
  props: {
    acCodeUrl: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      dialogStatus: {},
      currentHover: '',
      hoverText: '复制成功',
      loadingCode: false
    }
  },
  components: {
    diaModel
  },

  methods: {
    init() {
      this.dialogStatus = {
        dialogWidth: '500px',
        dialogTitle: '保存成功',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: true,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.scribesDialog.init()
        this.loadingCode = true
        setTimeout(() => {
          this.QrCodeInit()
        }, 1000)
      })
    },
    QrCodeInit() {
      console.log(this.acCodeUrl)
      this.$refs.qrCodeDiv.innerHTML = ''
      new QrCode(this.$refs.qrCodeDiv, {
        text: this.acCodeUrl,
        width: 200,
        height: 200,
        colorDark: '#333333',
        colorLight: '#ffffff',
        correctLevel: QrCode.CorrectLevel.H,
        render: 'canvas'
      })
      this.loadingCode = false
    },
    CopyImgCode() {
      var drawing = this.$refs.qrCodeDiv.childNodes[0]
      //确定浏览器支持<canvas>元素
      if (drawing.getContext) {
        var context = drawing.getContext('2d')
        //取得图像的数据 URI
        var imgURI = drawing.toDataURL('image/png')
        //显示图像
        var image = document.createElement('img')
        image.src = imgURI
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = 'code'
        a.href = imgURI
        a.dispatchEvent(event)
      }
    },
    getSelect(targetNode) {
      if (window.getSelection) {
        //chrome等主流浏览器
        var selection = window.getSelection()
        var range = document.createRange()
        range.selectNode(targetNode)
        selection.removeAllRanges()
        selection.addRange(range)
      } else if (document.body.createTextRange) {
        //ie
        var range = document.body.createTextRange()
        range.moveToElementText(targetNode)
        range.select()
      }
    },
    submitContBtn() {
      this.$refs.scribesDialog.subimtCancle()
    },
    copyComName(val) {
      event.stopPropagation()
      var oInput = document.createElement('input')
      oInput.value = val
      document.body.appendChild(oInput)
      this.selectText(oInput)
      document.execCommand('Copy')
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this.currentHover = 'true'
      this.hoverText = '复制成功!'
      this.$store.dispatch('showTip', {
        text: `复制成功 ${val}  `,
        type: 'success',
        duration: 2000
      })
      setTimeout(() => {
        this.currentHover = null
      }, 500)
    },
    selectText(oInput) {
      if (oInput.createTextRange) {
        //ie
        oInput.select()
      } else {
        //firefox/chrome
        oInput.setSelectionRange(0, oInput.value.length)
        oInput.focus()
      }
    }
  }
}
</script>

<style scoped>
@import '../../styles/threadpx.scss';
</style>
<style scoped>
.qr-code-cont {
  margin: 29px 0;
  height: 200px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.qr-code-txt {
  margin-top: 11px;
  font-size: 14px;
  color: #8f8f8f;
  line-height: 20px;
  position: relative;
}
.qr-code-ac {
  color: #0099cc;
  cursor: pointer;
}
.hover-tip-icon {
  position: absolute;
  display: inline-block;
  width: 60px;
  text-align: center;
  height: 30px;
  border: 1px solid #979797;
  border-radius: 5px;
  left: 0px;
  top: -40px;
  color: #979797;
  line-height: 28px;
  font-size: 12px;
  background: white;
}
#qrCode {
  display: inline-block;
}
</style>
