import { API, BASE_REQUEST } from '../_dtbird_api/api_service'

// 获取菜单列表
export const getMenuList = async params => {
  const api = API('PERMISSIONMENU')
  return await BASE_REQUEST({ api: api, opt: params })
}

//角色面版
export const getPanelList = async params => {
  const api = API('panelList')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 新建角色
export const createRole = async params => {
  const api = API('roleCreate')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 角色列表
export const roleList = async params => {
  const api = API('roleList')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 编辑角色
export const roleEdit = async params => {
  const api = API('roleEdit')
  return await BASE_REQUEST({ api: api, opt: params })
}

// 删除角色
export const roleRemove = async params => {
  const api = API('roleRemove')
  return await BASE_REQUEST({ api: api, opt: params })
}

//为用户分配角色
export const roleBindUser = async params => {
  const api = API('roleUser')
  return await BASE_REQUEST({ api: api, opt: params })
}

//获取权限用户列表
export const userList = async params => {
  const api = API('userList')
  return await BASE_REQUEST({ api: api, opt: params })
}

//保存数据权限
export const saveDataPermission = async params => {
  const api = API('saveDataPermission')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const getSyMenus = async params => {
  const api = API('getSyMenus')
  return await BASE_REQUEST({ api: api, opt: params })
}
