<template>
  <small></small>
</template>

<script>
export default {
  name: 'DtNotice',
  computed: {
    notice() {
      return this.$store.state.plugin.notice
    },
    noticeId() {
      return this.$store.state.plugin.notice.id
    }
  },
  watch: {
    noticeId() {
      this.$notify(Object.assign({}, this.notice))
    }
  }
}
</script>

<style scoped></style>
