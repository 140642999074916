var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "selectCompany-main" }, [
    _c("div", { staticClass: "company-list" }, [
      _c("div", { staticClass: "search-input" }, [
        _c(
          "div",
          { staticStyle: { width: "50%" } },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入公司查询", size: "small" },
              model: {
                value: _vm.companyName,
                callback: function ($$v) {
                  _vm.companyName = $$v
                },
                expression: "companyName",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn-list" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.searchCompany },
              },
              [_vm._v("\n          查询\n        ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.reset } },
              [_vm._v(" 重置 ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.selectCompany.companyName
        ? _c(
            "div",
            { staticClass: "tip" },
            [
              _c(
                "el-tag",
                {
                  attrs: { size: "small", closable: "" },
                  on: { close: _vm.delCompany },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.selectCompany.companyName) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c("dt-table", {
            key: _vm.refreshKey,
            attrs: {
              "data-total": _vm.pageStatus.total,
              "hide-border": "",
              "is-add-earn-field": false,
              "is-count-height": false,
              "shift-field": _vm.shiftField,
              "column-width-list": { select: 50 },
              "table-h": "400px",
              "is-select": false,
              "column-list": _vm.columns,
              "table-loading": _vm.loading,
              "table-data": _vm.companyList,
            },
            on: {
              sizeChange: _vm.sizeChange,
              currentChange: _vm.currentChange,
              singleSelectChange: _vm.singleSelectChange,
            },
            scopedSlots: _vm._u([
              {
                key: "selectRow",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      [
                        _c("el-checkbox", {
                          attrs: {
                            value: row.scope.data.id === _vm.selectCompany.id,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelCompany } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmCompany },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }