<template>
  <div id="app">
    <dt-plugins />
    <autoCallTask />
    <transition :name="transitionName">
      <router-view v-loading="showExportLoading" />
    </transition>
    <pay-card
      v-if="showBuyCard"
      @goPayCloseCard="goPayCloseCard"
      :dialogStatus="dialogStatus"
      ref="payCardInit"
    />
    <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showGiveMoney">
      <div slot="modalCont">
        <div class="show_grasp_text">
          亲爱的用户，您的账号套餐过期，请扫码联系小助手[领鸟云晓晓]
        </div>
      </div>
      <div slot="submit">
        <div class="er-code"></div>
      </div>
    </dia-model>
  </div>
</template>

<script>
import PayCard from '@/components/PayCard'
import diaModel from '@/components/Model'
import { removeToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import Bus from '@/utils/bus'
import { userLogout } from '@/api/usercenter'
import { ResetAuthUserMeal, ResetSystemConfig } from '@/utils/auth'
import DtPlugins from '@/components/element/DtPlugins'
import autoCallTask from '@/views/callsystem/autoCallTask'

export default {
  name: 'App',
  data() {
    return {
      transitionName: 'slide-left',
      dialogStatus: {
        dialogTop: '15vh',
        dialogWidth: '800px'
      },
      graspData: {},
      socketData: {},
      showSocket: false,
      showBuyCard: false,
      showGiveMoney: false,
      showExportLoading: false,
      updateCount: 0
    }
  },
  created() {
    if (
      this.accountInfo?.bandWaihuType === 'simPhone' &&
      this.$store.state.permission.isLogin
    ) {
      this.$store.dispatch('createSocket')
    }

    const _this = this
    Bus.$on('closeUserBuyMeal', function() {
      _this.$store.dispatch('setGoBuyMeal', false)
    })
    if (localStorage.getItem('dtBird_token')) {
      ResetSystemConfig()
    }
    if (this.$store.state.permission.isLogin) {
      ResetSystemConfig()
    }
    Date.prototype.Format = function(fmt) {
      var o = {
        'M+': this.getMonth() + 1, //月份
        'd+': this.getDate(), //日
        'h+': this.getHours(), //小时
        'm+': this.getMinutes(), //分
        's+': this.getSeconds(), //秒
        'q+': Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds() //毫秒
      }
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (this.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      for (var k in o)
        if (new RegExp('(' + k + ')').test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ('00' + o[k]).substr(('' + o[k]).length)
          )
      return fmt
    }
  },
  computed: {
    ...mapGetters([
      'buyMeal',
      'goBuyMeal',
      'buyMealType',
      'InvalidLogin',
      'showExportLoad',
      'registStep'
    ]),

    accountInfo() {
      return this.$store.state.user.accountInfo
    }
  },
  components: {
    PayCard,
    diaModel,
    DtPlugins,
    autoCallTask
  },
  //监听路由的路径，可以通过不同的路径去选择不同的切换效果
  watch: {
    registStep(val) {
      if (val != 1 && this.$store.state.permission.isLogin) {
        ResetAuthUserMeal()
      }
    },

    $route(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    },

    buyMeal(to, from) {
      if (to) {
        this.showBuyCard = true
        this.$nextTick(() => {
          this.$refs.payCardInit.init()
        })
      } else {
        this.$refs.payCardInit.close()
        this.showBuyCard = false
      }
    },

    InvalidLogin(to, from) {
      if (to) {
        this.showSocket = true
        this.socketData = {
          dialogWidth: '350px',
          dialogTitle: '提示',
          dialogTop: '250px',
          showFoot: true,
          singleBtn: true,
          dialogSurBtn: '确认'
        }
        this.$nextTick(() => {
          this.$refs.websocketInfo.init()
        })
      }
    },

    showExportLoad(to, from) {
      if (to) {
        this.showExportLoading = true
      } else {
        this.showExportLoading = false
      }
    },

    goBuyMeal(to, from) {
      // debugger
      if (to && this.buyMealType !== '666') {
        this.showGiveMoney = true
        this.graspData = {
          dialogWidth: '400px',
          dialogTitle: '提示',
          dialogTop: '250px',
          showFoot: false,
          singleBtn: false,
          dialogSurBtn: '确认'
        }
        this.$nextTick(() => {
          this.$refs.graspTip.init()
        })
      } else {
        this.showGiveMoney = false
      }
    }
  },
  methods: {
    closed() {
      this.showSocket = false
      this.$store.dispatch('setUserLoginOut', false)
      this.goOutPage()
    },

    goPayCloseCard() {
      this.$store.dispatch('changeUserBuy', false)
    },

    goOutPage() {
      userLogout().then(res => {
        if (res.code === 60000) {
          removeToken()
          this.$router.push({ path: '/login' })
        }
      })
    }
  }
}
</script>
<style>
#app {
  background-color: #f5f5f6;
}
.el-main {
  padding: 0;
  position: relative;
}
.el-main > .main > div:first-child {
  padding: 10px;
  box-sizing: border-box;
}
.er-code {
  height: 140px;
  width: 100%;
  background: url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lner.png')
    no-repeat top center;
  background-size: auto 120px;
}
.d-active {
  color: #41cabf !important;
}
.dt-hide {
  display: none;
}
.null-data {
  color: rgba(0, 0, 0, 0.15);
}
.el-loading-spinner {
  margin-top: 0;
  position: relative;
  transform: translateY(-50%);
}
.el-loading-spinner .circular {
  height: 25px;
  width: 25px;
}

.w-e-text {
  overflow-y: auto !important;
}

/*下拉框滚动条统一样式*/
.dt-dropdown-dt {
  height: 150px;
  width: 200px;
}
.dt-dropdown-dt > .el-scrollbar__wrap {
  overflow-x: hidden;
}

.dt-dropdown-dtt > .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto;
}

.dt-dropdown-dt > .el-scrollbar__bar.is-horizontal {
  height: 0;
  left: 0;
}

.el-table__empty-block {
  width: auto !important;
}

input:focus {
  border-color: #41cabf;
}

.child-view {
  margin: 300px auto;
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.grasp_go_pay {
  height: 68px;
  border-top: 1px solid #d8d8d8;
  line-height: 68px;
}
.grasp_go_pay span {
  display: inline-block;
  width: 70px;
  height: 34px;
  background: rgba(120, 217, 209, 1);
  border-radius: 4px;
  line-height: 34px;
  margin-left: 27px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.el-autocomplete-suggestion {
  z-index: 10 !important;
}

.el-cascader-panel ::v-deep ul li label {
  display: none !important;
}
.el-tooltip__popper {
  width: auto !important;
  max-width: 30% !important;
  line-height: 150%;
  font-size: 14px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}
.popper {
  background-color: #fff !important;
  color: #333 !important;
  font-size: 12px !important;
  max-width: 260px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  transform: scale(0.9);
}

.el-header {
  height: 50px !important;
  padding: 0 !important;
}
.el-menu {
  border: none !important;
}

.change-radio .el-radio__input.is-checked .el-radio__inner {
  background-color: #41cabf !important;
  border-color: #41cabf !important;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
.change-radio .el-radio__inner {
  width: 14px;
  height: 14px;
}
.change-radio .el-radio__inner::after {
  display: none;
  transform: none;
}

.change-radio .el-radio__input.is-checked .el-radio__inner {
  padding: 3px;
  background-color: #41cabf;
  background-clip: content-box;
}
.change-radio .el-radio__label {
  font-size: 12px;
  padding-left: 5px;
  color: #333 !important;
}
.change-radio .el-radio__input.is-disabled + span.el-radio__label {
  color: #333;
}

.el-breadcrumb__inner.is-link {
  color: #4ddcd2;
}
.el-breadcrumb__inner.is-link:hover {
  color: #4ddcd2;
}

.pond-drop .active {
  color: #41cabf !important;
}
.pond-drop {
  max-height: 300px;
  overflow-y: auto;
}

.checkbox-dt {
  width: 100%;
  display: flex;
  align-items: center;
}
.checkbox-dt .el-checkbox__label {
  width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-message {
  z-index: 2015000 !important;
}
.el-dialog__footer {
  padding: 10px 20px !important;
  box-sizing: border-box;
}
.no-copy {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.el-message-box__title {
  font-size: 12px !important;
}
.amap-copyright {
  display: none !important;
}
.amap-logo {
  display: none !important;
}
.amap-toolbar {
  left: 20px !important;
  top: 20px !important;
  height: 60px !important;
  width: 30px !important;
}
body .el-table th.gutter {
  display: table-cell !important;
}

.dt-data .el-card__body {
  height: 100%;
}

/* message-box 全局样式*/
.el-message-box__header {
  padding: 15px !important;
  background: #41cabf !important;
  color: #ffffff !important;
}
.el-message-box__title {
  color: #ffffff !important;
  position: relative;
  top: 2px;
}
.el-message-box {
  border: none !important;
}
.el-message-box__content {
  min-height: 65px !important;
}
.el-message-box__close {
  color: #ffffff !important;
}
.el-popper-dt-dt {
  padding: 0 !important;
  border-radius: 0 !important;
}
html * {
  font-family: 阿里巴巴普惠体, Alibaba Sans !important;
}
.xxx-scroll .el-scrollbar__wrap {
  overflow-x: hidden;
  /*overflow-y: auto;*/
}

.c-mini-toolbar > div:nth-child(5) {
  display: none !important;
}
.c-mini-toolbar > div:nth-child(6) {
  display: none !important;
}
.c-mini-toolbar > div:nth-child(7) {
  display: none !important;
}
.c-mini-toolbar > div:nth-child(9) {
  display: none !important;
}
.c-mini-toolbar {
  bottom: 10px;
  right: 10px;
  transform: scale(0.7);
}
.dt-drop-hs {
  display: none !important;
}
.el-popconfirm__main {
  margin-bottom: 10px;
}

/*directive*/

.directive-bottom-right {
  bottom: -30px;
  right: -10px;
}
.mou-select .el-select-dropdown__item {
  height: 50px !important;
  line-height: 50px !important;
}
.popper-class-el-cascader-menu .el-cascader-menu {
  min-width: 280px !important;
}
.popper-class-el-cascader-menu .el-cascader-menu__wrap {
  height: 370px !important;
}
.dt-drop-down-no-data {
  height: 80px;
  margin: 0 auto;
  color: #909399;
  font-size: 14px;
  line-height: 80px;
  text-align: center;
}
</style>
