<template>
  <div class="createChance-main">
    <el-scrollbar class="scroll-bar dt-dropdown-dt">
      <div class="main-row">
        <el-form :model="formData" :rules="rules" label-width="auto" ref="form">
          <el-form-item prop="nicheName" label="商机名称">
            <el-input
              v-model="formData.nicheName"
              placeholder="请输入商机名称"
            ></el-input>
          </el-form-item>

          <el-form-item prop="pzSignSale" label="商机提供人">
            <el-select
              v-model="formData.pzSignSale"
              placeholder="请选择商机提供人"
              style="width: 100%"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="userId" label="签单销售">
            <el-select
              v-model="formData.userId"
              placeholder="请选择签单销售"
              style="width: 100%"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="companyName" label="关联公司">
            <span
              :class="
                type === 'create' && !isPond ? 'select-create' : 'select-edit'
              "
              @click="changeCompany"
              >选择</span
            >
            <el-input
              :disabled="type !== 'create' || isPond"
              v-model="formData.companyName"
              placeholder="请选择关联公司"
            ></el-input>
          </el-form-item>

          <el-form-item prop="gradeId" label="客户等级">
            <el-select
              v-model="formData.gradeId"
              placeholder="请选择客户等级"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in allLevel"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="stateId" label="客户状态">
            <el-select
              v-model="formData.stateId"
              placeholder="请选择客户状态"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in allStatus"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="predictBillMoney" label="预估签单金额">
            <el-input
              v-model.number="formData.predictBillMoney"
              placeholder="请输入预估签单金额"
            ></el-input>
          </el-form-item>

          <el-form-item prop="predictBillDate" label="预估签单日期">
            <el-date-picker
              :editable="false"
              style="width: 100%"
              v-model="formData.predictBillDate"
              type="date"
              placeholder="请选择预估签单日期"
              :picker-options="
                type === 'create' ? startCreateDateLimit : startEditDateLimit
              "
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item prop="billKeyperson" label="客户联系人">
            <span class="select-create" @click="selectBillKeyPerson">选择</span>
            <span class="select-tag">
              <el-tag
                closable
                size="mini"
                class="tag"
                v-for="item in selectUserList"
                @close="editSelectUser(item)"
                >{{ item.contact }}</el-tag
              >
            </span>
            <el-input
              v-model="formData.billKeyperson"
              :placeholder="selectUserList.length ? '' : '请选择客户联系人'"
            ></el-input>
          </el-form-item>

          <el-form-item prop="nicheState" label="商机阶段">
            <el-select
              v-model="formData.nicheState"
              placeholder="请选择商机阶段"
              style="width: 100%"
            >
              <el-option
                v-for="item in nicheStateList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="state" label="商机状态">
            <el-select
              v-model="formData.state"
              placeholder="请选择商机状态"
              style="width: 100%"
            >
              <el-option
                v-for="item in nicheStatusList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="nicheRemark" label="备注">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 10 }"
              placeholder="请输入备注"
              v-model="formData.nicheRemark"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-scrollbar>
    <div class="footer">
      <el-button size="small" @click="cancelBtn">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="confirmBtn"
        v-loading="loadingForm"
        >确认</el-button
      >
    </div>

    <el-dialog
      class="el-dialog-dt"
      width="800px"
      top="90px"
      :visible.sync="showSelectCompany"
      :show-close="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <div slot="title" class="slot-title">
        <div>
          选择客户
        </div>
        <div @click="cancelCompany">
          <i class="el-icon-close close"></i>
        </div>
      </div>
      <div class="company-list">
        <div class="tab-div">
          <el-tabs v-model="tabType" class="dt-tab" @tab-click="tabClick">
            <el-tab-pane
              label="我负责的"
              name="1"
              v-if="
                permissionList.includes(
                  '客户管理CRM-客户公海-我负责的-查看客户'
                )
              "
            />
            <el-tab-pane
              label="全公司的"
              name="3"
              v-if="
                permissionList.includes(
                  '客户管理CRM-客户公海-全公司的-查看客户'
                )
              "
            />
            <el-tab-pane
              label="可跟进的"
              name="2"
              v-if="
                permissionList.includes(
                  '客户管理CRM-客户公海-可跟进的-查看客户'
                )
              "
            />
            <el-tab-pane
              label="草稿箱"
              name="4"
              v-if="
                permissionList.includes('客户管理CRM-客户公海-草稿箱-查看客户')
              "
            />
          </el-tabs>
        </div>

        <div class="search-input">
          <div style="width: 630px">
            <el-input
              v-model="companyName"
              placeholder="请输入公司查询"
              size="small"
            ></el-input>
          </div>
          <div class="btn-list">
            <el-button size="small" type="primary" @click="searchCompany">
              查询
            </el-button>
            <el-button size="small" @click="reset"> 重置 </el-button>
          </div>
        </div>

        <div class="tip" v-if="selectCompany.companyName">
          <el-tag size="small" closable @close="delCompany">
            {{ selectCompany.companyName }}
          </el-tag>
        </div>

        <div class="table">
          <dt-table
            :data-total="pageStatus.total"
            :hide-border="true"
            :key="refreshKey"
            :is-add-earn-field="false"
            :is-count-height="false"
            :shift-field="shiftField"
            :column-width-list="{ select: 50 }"
            table-h="400px"
            :is-select="false"
            :column-list="columns"
            @sizeChange="sizeChange"
            @currentChange="currentChange"
            @singleSelectChange="singleSelectChange"
            :table-loading="loading"
            :table-data="companyList"
          >
            <template v-slot:selectRow="row">
              <span>
                <el-checkbox :value="row.scope.data.id === selectCompany.id" />
              </span>
            </template>
          </dt-table>
        </div>

        <div class="footer">
          <el-button size="small" @click="cancelCompany">取消</el-button>
          <el-button size="small" type="primary" @click="confirmCompany"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      class="el-dialog-dt"
      width="850px"
      top="90px"
      :visible.sync="showSelectUser"
      :show-close="false"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
    >
      <div slot="title" class="slot-title">
        <div>
          选择签单决策人
        </div>
        <div @click="cancelSelectUser">
          <i class="el-icon-close close"></i>
        </div>
      </div>

      <div class="user-list">
        <selectBillKeyPersion
          :company-name="selectCompany.companyName"
          :is-active-tab="isActiveTab"
          :current-check-row-prop="selectUserList"
          :package-id="selectCompany.packageId"
          :company-uuid="selectCompany.companyUuid"
          @closeDialog="closeDialog"
          @getRes="getSelectUser"
          :key="refreshSelectKey"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DtTable from '@/components/element/DtTable'
import { contractCompanyList, getMode } from '@/api/user'
import {
  addNiche,
  editNiche,
  getCusPondList,
  getCusTagsDdic,
  getCustomerLeaders
} from '@/api/customerpond'

import selectBillKeyPersion from '@/views/customerpond/chanceManage/dialog/selectBillKeyPersion'
import { getAllSales } from '@/api/thread'

export default {
  name: 'createChance',
  components: {
    DtTable,
    selectBillKeyPersion
  },
  data() {
    var validateBillKeyperson = (rule, value, callback) => {
      if (!this.selectUserList.length) {
        callback(new Error('请选择客户联系人'))
      } else {
        callback()
      }
    }
    return {
      startCreateDateLimit: {
        disabledDate: time => {
          return time < new Date().getTime() - 24 * 60 * 60 * 1000
        }
      },

      startEditDateLimit: {
        disabledDate: time => {
          return false
        }
      },

      loadingForm: false,
      refreshSelectKey: 0,
      showSelectUser: false,
      showSelectCompany: false,
      selectUserList: [], // 选中的签单决策人
      type: 'create',
      userList: [], // 负责人列表
      nicheStateList: [], //商机阶段列表
      nicheStatusList: [], // 商机状态列表
      allLevel: [],
      allStatus: [],
      formData: {
        nicheName: '', // 商机名称
        userId: '', // 负责人,
        pzSignSale: '',
        companyName: '',
        predictBillMoney: null, // 预估签单金额
        predictBillDate: '', //预估签单日期
        billKeyperson: '', // 签单决策人
        nicheState: '', // 商机阶段
        nicheRemark: '', // 商机备注
        id: '',
        state: '',
        gradeId: '',
        stateId: ''
      },
      rules: {
        nicheName: [
          { required: true, message: '请输入商机名称', trigger: 'blur' }
        ],
        userId: [{ required: true, message: '请选择负责人', trigger: 'blur' }],
        pzSignSale: [
          { required: true, message: '请选择商机提供人', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请选择关联公司', trigger: 'change' }
        ],
        predictBillMoney: [
          { required: true, message: '请输入预估签单金额', trigger: 'blur' },
          { type: 'number', message: '预估签单金额必须为数字值' }
        ],
        predictBillDate: [
          { required: true, message: '请输入预估签单日期', trigger: 'blur' }
        ],
        billKeyperson: [
          {
            validator: validateBillKeyperson,
            required: true,
            trigger: 'change'
          }
        ],
        nicheState: [
          { required: true, message: '请选择商机阶段', trigger: 'blur' }
        ]
      },
      isPond: false,

      // 选择公司
      refreshKey: 0,
      shiftField: [{ field: 'select', name: '' }],
      companyName: '',
      selectCompany: {},
      companyList: [],
      loading: false,
      tabType: null,
      isActiveTab: '1',
      pageStatus: {
        total: null,
        pageNo: 1,
        pageSize: 10
      },
      columns: [
        {
          field: 'companyName',
          name: '客户名称'
        },
        {
          field: 'createdAt',
          name: '创建时间'
        }
      ]
    }
  },
  mounted() {
    this.getSelectOptions()
    this.getDdic()
    this.getLeaderNicheList()
    this.setTabType()
    const modalOptions = this.$store.state.plugin.modal.options
    this.type = modalOptions.type
    this.isPond = modalOptions.isPond
    if (modalOptions.isPond) {
      this.isActiveTab = modalOptions.activeTabs
      this.formData.companyName = modalOptions.cname
      this.selectCompany = {
        companyName: modalOptions.cname,
        companyUuid: modalOptions.cuuid
      }
    }
    if (this.type === 'edit') {
      this.startEditDateLimit = {
        disabledDate: time => {
          const date = modalOptions.chanceData.createdAt
          return time < new Date(date).getTime() - 24 * 60 * 60 * 1000
        }
      }
      const chanceData = modalOptions.chanceData
      this.isActiveTab = modalOptions.cusType
      this.formData.userId = chanceData.userId
      this.formData.companyName = chanceData.companyName
      this.formData.nicheName = chanceData.nicheName
      this.formData.predictBillMoney = chanceData.predictBillMoney
      this.formData.predictBillDate = chanceData.predictBillDate
      this.formData.nicheState = chanceData.nicheState
      this.formData.nicheRemark = chanceData.nicheRemark
      this.selectCompany = {
        companyName: chanceData.companyName,
        companyUuid: chanceData.companyUuid
      }
      this.selectUserList = chanceData.contactList ? chanceData.contactList : []
      this.formData.id = chanceData.id
      this.formData.state = chanceData.state
      this.formData.pzSignSale = chanceData.supplyUserId || ''
      this.formData.stateId = chanceData.stateId
      this.formData.gradeId = chanceData.gradeId
    }
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    }
  },
  methods: {
    getSelectOptions() {
      getCusTagsDdic({ type: 1 }).then(res => {
        if (res.code === 60000) {
          this.allLevel = res.data
        }
      })
      getCusTagsDdic({ type: 3 }).then(res => {
        if (res.code === 60000) {
          this.allStatus = res.data
        }
      })
    },

    setTabType() {
      if (
        this.permissionList.includes('客户管理CRM-客户公海-我负责的-查看客户')
      ) {
        this.tabType = '1'
        return
      }
      if (
        this.permissionList.includes('客户管理CRM-客户公海-全公司的-查看客户')
      ) {
        this.tabType = '3'
        return
      }
      if (
        this.permissionList.includes('客户管理CRM-客户公海-可跟进的-查看客户')
      ) {
        this.tabType = '2'
        return
      }
      if (
        this.permissionList.includes('客户管理CRM-客户公海-草稿箱-查看客户')
      ) {
        this.tabType = '4'
      }
    },

    getLeaderNicheList() {
      // const params = {
      //   pageId: 2001,
      //   type: '3'
      // }

      getAllSales().then(res => {
        if (res.code === 60000) {
          this.userList = res.data.filter(i => i.name !== '可跟进的')
        }
      })

      // getCustomerLeaders(params).then(res=>{
      //   if(res.code === 60000 && res.data){
      //     this.userList = res.data.filter(i=>i.name !== '可跟进的')
      //   }
      // })
    },

    getDdic() {
      getMode({ type: 'NH_01' }).then(res => {
        if (res.code === 60000) {
          this.nicheStateList = res.data['NH_01']
        }
      })

      getMode({ type: 'NH_02' }).then(res => {
        if (res.code === 60000) {
          this.nicheStatusList = res.data['NH_02']
        }
      })
    },

    cancelBtn() {
      this.$store.dispatch('hideModal')
    },

    confirmBtn() {
      if (this.loadingForm) return
      // 添加商机
      this.$refs['form'].validate(valid => {
        if (!valid) return
        const params = Object.assign({}, this.formData, {
          billKeyperson: this.selectUserList.map(i => i.id).join(',')
        })
        if (this.type === 'create') {
          this.loadingForm = true
          addNiche(params).then(res => {
            this.loadingForm = false
            if (res.code === 60000) {
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: 'success'
              })
              this.$store.commit('update', {
                target: this.$store.state.plugin.modal.options,
                data: {
                  refresh: true
                }
              })
              this.cancelBtn()
            }
          })
        } else {
          this.loadingForm = true
          editNiche(params).then(res => {
            this.loadingForm = false
            if (res.code === 60000) {
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: 'success'
              })
              this.$store.commit('update', {
                target: this.$store.state.plugin.modal.options,
                data: {
                  refresh: true
                }
              })
              this.cancelBtn()
            }
          })
        }
      })
    },

    // 选择公司
    changeCompany() {
      if (this.type === 'edit' || this.isPond) return
      this.showSelectCompany = true
      this.getCompanyList()
    },

    // 选择签单决策人
    selectBillKeyPerson() {
      if (!this.selectCompany.companyName) {
        this.$store.dispatch('showTip', {
          text: '请先选择关联公司',
          type: 'warning'
        })
        return
      }
      this.refreshSelectKey += 1
      this.showSelectUser = true
    },

    // 关闭签单决策人
    cancelSelectUser() {
      this.showSelectUser = false
    },
    closeDialog() {
      this.cancelSelectUser()
    },
    getSelectUser(userList) {
      this.selectUserList = userList
      this.closeDialog()
    },
    editSelectUser(item) {
      this.selectUserList = this.selectUserList.filter(i => i.id !== item.id)
      if (!this.selectUserList.length) this.formData.billKeyperson = ''
    },
    currentChange(val) {
      this.pageStatus.pageNo = val
      this.getCompanyList()
    },

    sizeChange(val) {
      this.pageStatus.pageSize = val
      this.getCompanyList()
    },

    singleSelectChange(val) {
      if (!val) return
      this.selectCompany = val
    },

    getCompanyList() {
      this.loading = true
      const params = {
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        pageId: 2001,
        companyName: this.companyName,
        type: this.tabType,
        selectType: 1
      }
      getCusPondList(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.companyList = [...res.data.list]
          // this.columns = res.data.columns
          this.pageStatus = res.data.page
        }
      })
    },

    searchCompany() {
      if (!this.companyName) return
      this.pageStatus.pageNo = 1
      this.pageStatus.pageSize = 10
      this.pageStatus.total = null
      this.getCompanyList()
    },

    tabClick() {
      this.selectCompany = {}
      this.getCompanyList()
    },

    confirmCompany() {
      if (!Object.keys(this.selectCompany).length) {
        this.$store.dispatch('showTip', {
          text: '请选择关联公司',
          type: 'warning'
        })
        return
      }
      this.showSelectCompany = false
      this.formData.companyName = this.selectCompany.companyName
      this.isActiveTab = this.tabType
      this.selectUserList = []
      this.formData.billKeyperson = ''
    },

    cancelCompany() {
      this.showSelectCompany = false
      this.selectCompany = {}
    },

    delCompany() {
      this.selectCompany = {}
      this.refreshKey += 1
    },

    reset() {
      this.companyName = ''
      this.pageStatus = {
        pageSize: 10,
        pageNo: 1,
        total: 0
      }
      this.getCompanyList()
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.createChance-main {
  width: 100%;
}
.footer {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
}
.footer::before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  position: absolute;
  transform: scaleY(0.5);
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
.scroll-bar {
  height: 565px;
  width: 100%;
}
.main-row {
  width: calc(100% - 100px);
  margin: 10px auto;
}
.select-create {
  position: absolute;
  z-index: 99;
  right: 10px;
  color: #41cabf;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  text-align: right;
}
.select-edit {
  position: absolute;
  z-index: 99;
  right: 10px;
  color: #8c939d;
  cursor: not-allowed;
}
.select-tag {
  position: absolute;
  z-index: 99;
  left: 10px;
  color: #8c939d;
}
.tag {
  margin-right: 5px;
}
.slot-title {
  height: 50px;
  background-color: #41cabf;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.el-dialog-dt ::v-deep .el-dialog__header,
.el-drawer-dt ::v-deep .el-drawer__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}
.el-drawer-dt ::v-deep .el-drawer__header {
  margin-bottom: 0 !important;
}

.el-dialog-dt ::v-deep .el-dialog__close,
.el-drawer-dt ::v-deep .el-drawer__close {
  color: #fff;
}

.el-dialog-dt ::v-deep .el-dialog__body,
.el-drawer-dt ::v-deep .el-drawer__body {
  padding: 0 !important;
}
.close {
  cursor: pointer;
}
.search-input {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;
}

.search-input::before {
  display: inline-block;
  content: '';
  height: 5px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  background-color: rgba(0, 0, 0, 0.03);
}

.tip {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.btn-list {
  margin-left: 20px;
}
.table {
  height: 450px;
  width: calc(100% - 20px);
  margin: 0 auto;
}
.tab-div {
  height: 50px;
  width: 100%;
  background-color: #fff;
  padding-left: 10px;
  box-sizing: border-box;
  position: relative;
}
.tab-div::before {
  display: inline-block;
  content: '';
  height: 5px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.dt-tab /deep/ .el-tabs__nav-wrap::after {
  display: none !important;
}
</style>
