var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "help-main" }, [
    _c("div", { staticClass: "help-text" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("\n        1、如何申请领鸟云外呼？ / 外呼申请流程\n      "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "chart" },
          [
            _c("el-image", {
              staticStyle: { width: "100%", height: "220px" },
              attrs: {
                src: "https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lcp-n.png",
                fit: "contain",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n        2、可以申请归属地为我公司所在地的号码吗？\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n        可以。详见申请流程。\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n        3、可以申请手机号吗？/ 可以申请座机吗？\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n        可以。详见申请流程。\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n        4、第一次外呼，打不出去\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "\n        首次外呼需要获取浏览器权限，浏览器会弹出对应弹窗，全部点击允许即可。"
        ),
        _c("br"),
        _vm._v(
          "\n        有些浏览器会自动拦截，需要主动点击网址输入框旁边的禁止按钮，弹出对应弹窗。部分浏览器还会发生未知错误，建议使用"
        ),
        _c(
          "a",
          {
            staticClass: "link",
            attrs: { href: "https://www.google.cn/chrome/", target: "_blank" },
          },
          [_vm._v("谷歌浏览器")]
        ),
        _vm._v("\n        。"),
        _c("br"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n        5、外呼出现“嘟嘟嘟”的声音，打不通\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "\n        出现这种情况，请先到通话记录中检查此通电话的通话状态，一般为：振铃未接听或未接通。前者表示通话正常，对方未接；后者表示受到了外呼限制。常见外呼限制："
        ),
        _c("br"),
        _vm._v(
          "\n        1.时间限制：根据线路不同有所区别。参考：8:00 - 19:00 周末限制呼出"
        ),
        _c("br"),
        _vm._v(
          "\n        2.地区限制：根据线路不同有所区别。参考：北京、新疆地区限制较多"
        ),
        _c("br"),
        _vm._v(
          "\n        3.超频限制：一个坐席一天的外呼次数有上限。根据线路不同有所区别。超出上限即被限制呼出。参考：一天200通"
        ),
        _c("br"),
        _vm._v(
          "\n        4.重复拨打：一个被叫号码，在一定时间内不可以重复呼叫（包括未接通）。根据线路不同有所区别。参考：一个号码在5个工作日内只能重复呼叫1次。"
        ),
        _c("br"),
        _vm._v(
          "\n        5.防骚扰：手机自带的防骚扰功能，如果外呼号码被被叫方的手机识别为广告营销等骚扰电话，会被对方手机自动拦截。"
        ),
        _c("br"),
        _vm._v(
          "\n        6.号码标记：号码被标记，也会被对方手机自动拦截。出现这种情况，请截图发送给客服人员协商更换号码。"
        ),
        _c("br"),
        _vm._v(
          "\n        7.黑名单：如果号码所在线路在历史上被被叫投诉过，该条线路下的所有号码都会被被叫设置为黑名单，因此限制呼出。根据线路不同有所区别。参考：黑名单率：18%"
        ),
        _c("br"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }