<template>
    <span>
      <el-rate
        v-model="countScore"
        disabled
        text-color="#ff9900"
        score-template="{value}">
      </el-rate>
    </span>
</template>

<script>
    export default {
        name: "DtScore",
        props:{
          score: {
            type: Number,
            default: 0
          }
        },
        data(){
          return{
            countScore: 0
          }
        },
        // 应该使用计算属性
        mounted() {
          this.countScore = parseFloat((this.score / 20).toFixed(1))
        }
    }
</script>

<style scoped>

</style>
