var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wheel-card" }, [
    _c(
      "ul",
      { staticClass: "ul-nums" },
      _vm._l(_vm.list, function (item, index) {
        return _c("li", { key: index }, [
          _c("div", { staticClass: "con" }, [
            _c("div", { staticClass: "t" }, [_vm._v(_vm._s(item.name))]),
            _vm._v(" "),
            _c("div", { staticClass: "num" }, [_vm._v(_vm._s(item.value))]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }