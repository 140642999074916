var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.g_loading,
            expression: "g_loading",
          },
        ],
        staticStyle: {
          width: "calc(100vw - 2px)",
          height: "calc(100vh - 10px)",
        },
      },
      [
        _c("RelationGraph", {
          ref: "seeksRelationGraph",
          attrs: {
            options: _vm.graphOptions,
            "on-node-click": _vm.onNodeClick,
          },
          scopedSlots: _vm._u([
            {
              key: "node",
              fn: function ({ node }) {
                return _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _vm.isShowCount
                      ? _c("span", [_vm._v(_vm._s(node.data.text))])
                      : _c("span", [_vm._v(_vm._s(node.data.word))]),
                    _vm._v(" "),
                    node.data.icon
                      ? _c("i", {
                          class: node.data.icon,
                          staticStyle: {
                            "font-size": "24px",
                            "margin-left": "4px",
                          },
                        })
                      : _vm._e(),
                  ]
                )
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }