<template>
  <div class="help-main">
    <div class="help-text">
      <div class="item">
        <div class="title">
          1、如何申请领鸟云外呼？ / 外呼申请流程
        </div>
        <div class="chart">
          <el-image
            style="width: 100%; height: 220px"
            src="https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lcp-n.png"
            fit="contain"
          ></el-image>
        </div>
      </div>
      <div class="item">
        <div class="title">
          2、可以申请归属地为我公司所在地的号码吗？
        </div>
        <div class="text">
          可以。详见申请流程。
        </div>
      </div>

      <div class="item">
        <div class="title">
          3、可以申请手机号吗？/ 可以申请座机吗？
        </div>
        <div class="text">
          可以。详见申请流程。
        </div>
      </div>

      <div class="item">
        <div class="title">
          4、第一次外呼，打不出去
        </div>
        <div class="text">
          首次外呼需要获取浏览器权限，浏览器会弹出对应弹窗，全部点击允许即可。<br />
          有些浏览器会自动拦截，需要主动点击网址输入框旁边的禁止按钮，弹出对应弹窗。部分浏览器还会发生未知错误，建议使用<a
            href="https://www.google.cn/chrome/"
            target="_blank"
            class="link"
            >谷歌浏览器</a
          >
          。<br />
        </div>
      </div>

      <div class="item">
        <div class="title">
          5、外呼出现“嘟嘟嘟”的声音，打不通
        </div>
        <div class="text">
          出现这种情况，请先到通话记录中检查此通电话的通话状态，一般为：振铃未接听或未接通。前者表示通话正常，对方未接；后者表示受到了外呼限制。常见外呼限制：<br />
          1.时间限制：根据线路不同有所区别。参考：8:00 - 19:00 周末限制呼出<br />
          2.地区限制：根据线路不同有所区别。参考：北京、新疆地区限制较多<br />
          3.超频限制：一个坐席一天的外呼次数有上限。根据线路不同有所区别。超出上限即被限制呼出。参考：一天200通<br />
          4.重复拨打：一个被叫号码，在一定时间内不可以重复呼叫（包括未接通）。根据线路不同有所区别。参考：一个号码在5个工作日内只能重复呼叫1次。<br />
          5.防骚扰：手机自带的防骚扰功能，如果外呼号码被被叫方的手机识别为广告营销等骚扰电话，会被对方手机自动拦截。<br />
          6.号码标记：号码被标记，也会被对方手机自动拦截。出现这种情况，请截图发送给客服人员协商更换号码。<br />
          7.黑名单：如果号码所在线路在历史上被被叫投诉过，该条线路下的所有号码都会被被叫设置为黑名单，因此限制呼出。根据线路不同有所区别。参考：黑名单率：18%<br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'help'
}
</script>

<style scoped>
.help-main {
  height: 100%;
}
.help-text {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  padding: 20px 0 20px 20px;
  box-sizing: border-box;
}
.item {
  margin-bottom: 20px;
}
.title {
  font-size: 20px;
}
.text {
  padding-left: 2em;
  box-sizing: border-box;
  font-size: 13px;
  margin-top: 20px;
  line-height: 2em;
}
.chart {
  min-height: 100px;
  height: auto;
  font-size: 12px;
  margin: 40px 0;
}

.class-1,
.class-2,
.class-3,
.class-4,
.class-5,
.class-6,
.class-7 {
  height: 60px;
  width: 100%;
  position: relative;
}
.class-7 {
  margin-top: 80px;
}
.class-base {
  height: 100%;
  width: 120px;
  border: 1px solid #333333;
  display: flex;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;
}
.class-base7 {
  height: 100%;
  width: 120px;
  border: 1px solid #333333;
  display: flex;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;
}
.class-base:before {
  content: '';
  display: inline-block;
  height: 2px;
  width: calc(100% - 120px - 20px);
  background-color: #41cabf;
  position: absolute;
  left: 140px;
}
.class-base:after {
  content: '';
  display: inline-block;
  border: 10px solid #fff;
  border-left-color: #41cabf;
  width: 10px;
  height: 10px;
  position: absolute;
  left: calc(100%);
}
.no-line:before {
  content: '';
  display: none;
}
.no-line:after {
  content: '';
  display: none;
}
.up-line {
  position: relative;
}
.up-line:before {
  content: '';
  display: inline-block;
  height: 60px;
  width: 2px;
  background-color: #41cabf;
  position: absolute;
  top: -70px;
  left: 60px;
}
.up-line:after {
  content: '';
  display: inline-block;
  border: 10px solid #fff;
  border-left-color: #41cabf;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 51px;
  top: -30px;
  transform: rotate(90deg);
}
.link {
  color: #0099cc;
  text-decoration: underline;
}
</style>
