var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "send-key-main" }, [
    _c(
      "div",
      { staticStyle: { padding: "0 30px", "box-sizing": "border-box" } },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              rules: _vm.rule,
              model: _vm.formData,
              "label-width": "auto",
            },
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "发送给", prop: "userId" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "default-first-option": "",
                              filterable: "",
                              placeholder: "选择线索接收人",
                            },
                            model: {
                              value: _vm.formData.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "userId", $$v)
                              },
                              expression: "formData.userId",
                            },
                          },
                          _vm._l(_vm.allSendUser, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-checkbox",
                          {
                            on: { change: _vm.handleCheckChange },
                            model: {
                              value: _vm.checked,
                              callback: function ($$v) {
                                _vm.checked = $$v
                              },
                              expression: "checked",
                            },
                          },
                          [_vm._v("同步更新合同负责销售")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _vm.formData.userId == "-1" && _vm.activeTabs !== 4
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "退回理由", prop: "reason" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "default-first-option": "",
                                  filterable: "",
                                  placeholder: "选择线索接收人",
                                },
                                model: {
                                  value: _vm.formData.reason,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "reason", $$v)
                                  },
                                  expression: "formData.reason",
                                },
                              },
                              _vm._l(_vm.allReason, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
          [_vm._v("取消")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small", loading: _vm.loadStatus },
            on: { click: _vm.postCusTransToOThers },
          },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }