var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editOrCreateUser-main" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            "label-width": "auto",
            rules: _vm.createUserRules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "contact" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.formData.contact,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "contact", $$v)
                          },
                          expression: "formData.contact",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formData.isKp,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "isKp", $$v)
                            },
                            expression: "formData.isKp",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "关键人", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "中间人", value: "2" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "普通", value: "3" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "部门" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入部门" },
                        model: {
                          value: _vm.formData.department,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "department", $$v)
                          },
                          expression: "formData.department",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "职位" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入职位" },
                        model: {
                          value: _vm.formData.position,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "position", $$v)
                          },
                          expression: "formData.position",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "mobile", label: "手机" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号码" },
                        model: {
                          value: _vm.formData.mobile,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "mobile",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "座机" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入座机号码" },
                        model: {
                          value: _vm.formData.landLine,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "landLine",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.landLine",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "微信号" } },
                    [
                      _c("el-input", {
                        staticStyle: { "ime-mode": "disabled" },
                        attrs: {
                          placeholder: "请输入微信号",
                          onkeyup:
                            "value=value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                          onpaste:
                            "value=value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                          oncontextmenu:
                            "value=value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                        },
                        model: {
                          value: _vm.formData.wechat,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "wechat", $$v)
                          },
                          expression: "formData.wechat",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入邮箱" },
                        model: {
                          value: _vm.formData.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "email", $$v)
                          },
                          expression: "formData.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "companyName", label: "归属企业" } },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.type === "create",
                              expression: "type === 'create'",
                            },
                          ],
                          class:
                            _vm.type === "create"
                              ? "select-create"
                              : "select-edit",
                          on: { click: _vm.changeCompany },
                        },
                        [_vm._v("选择")]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.type === "edit",
                          placeholder: "请选择关联公司",
                        },
                        model: {
                          value: _vm.formData.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "companyName", $$v)
                          },
                          expression: "formData.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "100",
                          "show-word-limit": "",
                          placeholder: "请输入备注",
                          autosize: { minRows: 2, maxRows: 10 },
                        },
                        model: {
                          value: _vm.formData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "remark", $$v)
                          },
                          expression: "formData.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-dt",
          attrs: {
            width: "800px",
            top: "90px",
            visible: _vm.showSelectCompany,
            "show-close": false,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectCompany = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slot-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v("\n        选择客户\n      ")]),
              _vm._v(" "),
              _c("div", { on: { click: _vm.cancelCompany } }, [
                _c("i", { staticClass: "el-icon-close close" }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "company-list" }, [
            _c("div", { staticClass: "search-input" }, [
              _c(
                "div",
                { staticStyle: { width: "630px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入公司查询", size: "small" },
                    model: {
                      value: _vm.companyName,
                      callback: function ($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchCompany },
                    },
                    [_vm._v("\n            查询\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.reset } },
                    [_vm._v(" 重置 ")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.selectCompany.companyName
              ? _c(
                  "div",
                  { staticClass: "tip" },
                  [
                    _c(
                      "el-tag",
                      {
                        attrs: { size: "small", closable: "" },
                        on: { close: _vm.delCompany },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.selectCompany.companyName) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table" },
              [
                _c("dt-table", {
                  key: _vm.refreshKey,
                  attrs: {
                    "data-total": _vm.pageStatus.total,
                    "hide-border": "",
                    "is-add-earn-field": false,
                    "is-count-height": false,
                    "shift-field": _vm.shiftField,
                    "column-width-list": { select: 50 },
                    "table-h": "400px",
                    "is-select": false,
                    "column-list": _vm.columns,
                    "table-loading": _vm.loading,
                    "table-data": _vm.companyList,
                  },
                  on: {
                    sizeChange: _vm.sizeChange,
                    currentChange: _vm.currentChange,
                    singleSelectChange: _vm.singleSelectChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "selectRow",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            [
                              _c("el-checkbox", {
                                attrs: {
                                  value:
                                    row.scope.data.id === _vm.selectCompany.id,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: { click: _vm.cancelCompany },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.confirmCompany },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }