var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "common-dialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "common-dia-modal",
          attrs: {
            top: "0",
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: _vm.dialogStatus.dialogWidth,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-header" }, [
            _c("span", [_vm._v(_vm._s(_vm.dialogStatus.dialogTitle))]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal_slot_cont" },
            [_vm._t("modalCont")],
            2
          ),
          _vm._v(" "),
          _vm._t("submit"),
          _vm._v(" "),
          _vm.dialogStatus.showFoot
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer common-foot-dia",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  !_vm.dialogStatus.singleBtn
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog_modal_btn",
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.dialogStatus.dialogSurBtn))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }