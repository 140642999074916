var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "oace-key-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.showTableLoading,
              expression: "showTableLoading",
            },
          ],
          ref: "multipleTable",
          staticStyle: { height: "100%" },
          attrs: {
            data: _vm.companyList,
            border: "",
            stripe: "",
            resizable: "",
            size: "small",
            "row-class-name": _vm.tableRowClassName,
          },
          on: {
            "sort-change": _vm.handleSortChange,
            "cell-click": _vm.cellClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("template", { slot: "empty" }, [
            _c(
              "div",
              {
                staticStyle: { "min-height": "400px", "padding-top": "100px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "no-data-icon-body" },
                  [
                    _c("svg-icon", {
                      staticClass: "no-data-icon",
                      attrs: { "icon-class": "noData" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "no-data-text" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { type: "selection", prop: "collet", width: "45" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "collet", width: "45" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "dt-permission",
                            rawName: "v-dt-permission",
                            value: "智能获客-推荐线索-加入培育",
                            expression: "'智能获客-推荐线索-加入培育'",
                          },
                        ],
                        staticClass: "w-3 company-list new-hover-type",
                      },
                      [
                        scope.row.sub === "true"
                          ? _c("svg-icon", {
                              staticClass: "star",
                              attrs: { "icon-class": "collect2" },
                              on: {
                                click: function ($event) {
                                  return _vm.del_collect(scope.row.company_uuid)
                                },
                              },
                            })
                          : _c("svg-icon", {
                              staticClass: "star",
                              attrs: { "icon-class": "collect1" },
                              on: {
                                click: function ($event) {
                                  return _vm.add_collect(scope.row.company_uuid)
                                },
                              },
                            }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "edit", width: "1", label: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      staticClass: "com-list-detail",
                      on: {
                        click: function ($event) {
                          return _vm.goKeyDetail(
                            scope.row["company_uuid"],
                            scope.$index,
                            scope.row.followUserId
                          )
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          !_vm.showTableLoading
            ? _c(
                "div",
                _vm._l(_vm.companyColunes, function (item, index) {
                  return _c(
                    "div",
                    { key: index + "s" },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip":
                            item.english !== "company_name",
                          prop: item.english,
                          align:
                            item.english === "company_name" ? "left" : "center",
                          width: item.english === "company_name" ? "300" : "",
                          label: item.chinese,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "p",
                                    { staticClass: "sort_list_cont" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "sort_list_name",
                                          class: {
                                            "sort-icon-header": item.hasOrder,
                                            active:
                                              (item.english === "follow_name" &&
                                                _vm.followUserIds !== "") ||
                                              (item.english ===
                                                "activity_class" &&
                                                _vm.selectAc &&
                                                _vm.selectAc !== "全部"),
                                          },
                                          attrs: { title: item.chinese },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.chinese) +
                                              "\n                "
                                          ),
                                          true
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "sort_list_left",
                                                },
                                                [
                                                  item.hasOrder
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sort_list_tip",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "sort_list_btn sort_up",
                                                            class: item.showUp
                                                              ? "sort_up_choose"
                                                              : "sort_up",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.sortListBtn(
                                                                  "up",
                                                                  index,
                                                                  item.showUp
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("i", {
                                                            staticClass:
                                                              "sort_list_btn",
                                                            class: item.showDown
                                                              ? "sort_down_choose"
                                                              : "sort_down",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.sortListBtn(
                                                                  "down",
                                                                  index,
                                                                  item.showDown
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      item.english === "follow_name"
                                        ? _c(
                                            "el-dropdown",
                                            {
                                              attrs: { trigger: "click" },
                                              on: {
                                                command: _vm.handleCommand,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-dropdown-link",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-arrow-down el-icon--right",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-menu",
                                                {
                                                  attrs: { slot: "dropdown" },
                                                  slot: "dropdown",
                                                },
                                                [
                                                  _c("dt-drop-down", {
                                                    attrs: {
                                                      "source-list":
                                                        _vm.followList,
                                                      "target-value": "text",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "el-scrollbar",
                                                                {
                                                                  staticClass:
                                                                    "dt-dropdown-dt",
                                                                  staticStyle: {
                                                                    height:
                                                                      "200px",
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                },
                                                                [
                                                                  !row
                                                                    .filterList
                                                                    .length
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "dt-drop-down-no-data",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          无数据\n                        "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    row.filterList,
                                                                    function (
                                                                      follow,
                                                                      ind
                                                                    ) {
                                                                      return _c(
                                                                        "el-dropdown-item",
                                                                        {
                                                                          key: ind,
                                                                          class:
                                                                            {
                                                                              active:
                                                                                _vm.followUserIds !==
                                                                                  "aaa" &&
                                                                                _vm.followUserIds &&
                                                                                Number.parseInt(
                                                                                  _vm.followUserIds
                                                                                ) ===
                                                                                  follow.value,
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              command:
                                                                                follow.value,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              domProps:
                                                                                {
                                                                                  innerHTML:
                                                                                    _vm._s(
                                                                                      follow.htmlEl
                                                                                    ),
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.english === "activity_class"
                                        ? _c(
                                            "el-dropdown",
                                            {
                                              attrs: { trigger: "click" },
                                              on: {
                                                command:
                                                  _vm.handleActiveCommand,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-dropdown-link",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-arrow-down el-icon--right",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-menu",
                                                {
                                                  attrs: { slot: "dropdown" },
                                                  slot: "dropdown",
                                                },
                                                _vm._l(
                                                  ["全部", "高", "中高", "中"],
                                                  function (follow, ind) {
                                                    return _c(
                                                      "el-dropdown-item",
                                                      {
                                                        key: ind,
                                                        class:
                                                          follow ===
                                                          _vm.selectAc
                                                            ? "active"
                                                            : "",
                                                        attrs: {
                                                          command: follow,
                                                        },
                                                      },
                                                      [_vm._v(_vm._s(follow))]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  item.english === "company_name" &&
                                  _vm.currentHover === scope.$index
                                    ? _c(
                                        "span",
                                        { staticClass: "hover-tip-icon" },
                                        [_vm._v(_vm._s(_vm.hoverText))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.english === "company_name"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "cus-name-hid",
                                          class: {
                                            list_opcity:
                                              scope.row.click === "1",
                                            company_name:
                                              item.english === "company_name",
                                            newClues:
                                              scope.row.newClues == "true" &&
                                              scope.row.click !== "1" &&
                                              scope.row.sub === "false",
                                          },
                                          style:
                                            item.english === "company_name"
                                              ? "cursor:pointer"
                                              : "cursor:default",
                                          on: {
                                            click: function ($event) {
                                              return _vm.goKeyDetail(
                                                scope.row["company_uuid"],
                                                scope.$index,
                                                scope.row.followUserId,
                                                scope.row["company_name"]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(scope.row[item.english])
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        item.english === "activity_class"
                                          ? _c(
                                              "span",
                                              [
                                                scope.row[item.english]
                                                  ? _c(
                                                      "el-tag",
                                                      {
                                                        attrs: { size: "mini" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row[
                                                              item.english
                                                            ]
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    scope.row[item.english]
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "div",
                _vm._l(_vm.companyColunes, function (item, index) {
                  return _c(
                    "div",
                    { key: index + "b" },
                    [
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            "show-overflow-tooltip":
                              item.english !== "company_name",
                            prop: item.english,
                            align:
                              item.english === "company_name"
                                ? "left"
                                : "center",
                            width: item.english === "company_name" ? "250" : "",
                            label: item.chinese,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.consumptionCount !== "0" &&
                                    item.english === "company_name"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "company_had_time_list",
                                            attrs: {
                                              title:
                                                "当前线索已被领取" +
                                                scope.row.consumptionCount +
                                                "次",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.consumptionCount)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.english === "company_name" &&
                                    _vm.currentHover === scope.$index
                                      ? _c(
                                          "span",
                                          { staticClass: "hover-tip-icon" },
                                          [_vm._v(_vm._s(_vm.hoverText))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.english === "company_name"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "cus-name-hid",
                                            class:
                                              scope.row.click === "1"
                                                ? "list_opcity"
                                                : "",
                                            style:
                                              item.english === "company_name"
                                                ? "cursor:pointer"
                                                : "cursor:default",
                                            on: {
                                              click: function ($event) {
                                                return _vm.copyComName(
                                                  item.english,
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                              mouseover: function ($event) {
                                                return _vm.copyHoverIn(
                                                  item.english,
                                                  scope.$index
                                                )
                                              },
                                              mouseout: function ($event) {
                                                return _vm.copyHoverOut(
                                                  item.english,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row[item.english])
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goKeyDetail(
                                                  scope.row["company_uuid"],
                                                  scope.$index,
                                                  scope.row.followUserId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row[item.english])
                                            ),
                                          ]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("p", { staticClass: "sort_list_cont" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "sort_list_name",
                                  class: [
                                    item.hasOrder ? "sort-icon-header" : "",
                                  ],
                                  attrs: { title: item.chinese },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.chinese) +
                                      "\n                "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "sort_list_left" },
                                    [
                                      item.hasOrder
                                        ? _c(
                                            "span",
                                            { staticClass: "sort_list_tip" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "sort_list_btn sort_up",
                                                class: item.showUp
                                                  ? "sort_up_choose"
                                                  : "sort_up",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortListBtn(
                                                      "up",
                                                      index,
                                                      item.showUp
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass: "sort_list_btn",
                                                class: item.showDown
                                                  ? "sort_down_choose"
                                                  : "sort_down",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sortListBtn(
                                                      "down",
                                                      index,
                                                      item.showDown
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }