<template>
  <div class="schema-policy-page">
    <div class="title">
      <span>政策监控</span>
    </div>
    <div class="search">
      <el-input
        v-model="peParams.keyWords"
        size="mini"
        style="width:300px;margin-right:20px"
        placeholder="请输入关键字"
      ></el-input>
      <el-button size="mini" type="primary" @click="handleInquire"
        >查询</el-button
      >
      <el-button size="mini" @click="handleReset">重置</el-button>
      <p>共{{ peParams.total }}条结果</p>
    </div>
    <PeTable
      :peColumnList="peColumnList"
      :peTableData="peTableData"
      :peLoading="peLoading"
      :pePage="peParams"
      :IsAccess="IsAccess"
      @sortPeChange="handleSortChange"
      @handleCurrentPePage="handleCurrentChange"
      @handleSizePeChange="handleSizeChange"
    >
      <template v-slot:policy="{ scope }">
        <div>
          <span class="c-name" @click="handleEnter(scope.data, scope.index)">{{
            scope.data.policy
          }}</span>

          <Tags :tagList="scope.data.tags" />
        </div>
      </template>
    </PeTable>
  </div>
</template>

<script>
import { getPolicyList, getQyTagFilter } from '@/api/module'
import PeTable from '@/components/Peculiar/table'
import PeRisk from '@/components/Peculiar/risk'
import { withOutAccess, enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'SchemaPolicy',
  components: {
    PeTable,
    PeRisk
  },
  data() {
    return {
      qyTagLoad: false,
      filterBand: [],
      listData: [],
      currentIndex: 0,
      peTableData: [],
      peColumnList: [],
      peTags: [],
      peParams: {
        keyWords: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      peLoading: false
    }
  },
  computed: {
    IsAccess() {
      return this.$store.state.user.accountInfo.industryInsightDetailsOpen
    }
  },
  async mounted() {
    // await this.getFilter()
    await this.getList()
  },
  methods: {
    handleNormalData() {
      withOutAccess()
    },
    handleSelectChange() {
      Object.assign(this.peParams, {
        tags: this.peTags
      })
      this.getList()
    },

    async getFilter() {
      this.qyTagLoad = true
      const { data } = await getQyTagFilter()
      this.filterBand = data.find(
        item => item.field == 'tags'
      )?.filterOptionList
      this.filterBand.forEach(item => {
        item.txt = `${item.name}(${item.value})`
      })
      this.qyTagLoad = false
    },

    async getList() {
      this.peLoading = true
      const { data } = await getPolicyList(this.peParams)
      this.peColumnList = data?.columns
      this.peTableData = data?.datas
      this.packageId = data?.packageId || 0
      this.peParams = Object.assign(this.peParams, { total: data.page.total })
      this.peLoading = false
    },

    handleInquire() {
      this.peParams.pageNo = 1
      this.peParams.pageSize = 10
      this.getList()
    },

    handleReset() {
      for (let key in this.peParams) {
        if (key != 'pageNo' || key != 'pageSize' || key != 'total') {
          this.peParams[key] = ''
        }
        const pageParams = {
          pageNo: 1,
          pageSize: 10,
          total: 0
        }
        this.peTags = []
        this.peParams.tags = []
        Object.assign(this.peParams, pageParams)
      }
      this.getList(true)
    },
    handleSortChange(...params) {
      const objParams = {
        sortField: params[2].sortField,
        sortOrder: params[2].sortOrder
      }
      this.peParams = Object.assign(this.peParams, objParams)
      this.getList()
    },
    handleCurrentChange(e) {
      this.peParams.pageNo = e
      this.getList()
    },
    handleSizeChange(e) {
      this.peParams.pageNo = 1
      this.peParams.pageSize = e
      this.getList()
    },

    handleEnter(data) {
      console.log(data)
      this.$router.push({
        path: `/industry/policy/${data.id}`
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.schema-policy-page {
  padding: 0 !important;
  margin: 10px 0 0 10px;
  font-size: 14px;

  .title {
    padding: 10px;
    font-size: 14px;
    background-color: #fff;
  }

  .time {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
    .name {
      margin-left: 20px;
      cursor: pointer;
    }
    .active-time {
      color: #41cabf;
    }
  }

  .band {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 30px 0;
    .el-input {
      margin-left: 20px;
    }
    .el-select__tags {
      left: 22px;
    }
  }

  .search {
    display: flex;
    align-items: center;
    margin-top: 10px;
    background: #fff;
    padding: 10px;
    p {
      font-size: 14px;
      color: #a8a8a8;
      margin-left: 10px;
    }
  }

  .c-name {
    color: #02a7f0;
    cursor: pointer;
  }

  .new-add {
    color: #f00;
  }

  .abnormal {
    display: flex;
    align-items: center;
  }

  .qy-cancel {
    background: red;
    color: #fff;
    padding: 0 4px;
    transform: scale(0.8);
    display: inline-block;
  }

  .pe-table {
    margin-top: 10px;
  }
}
</style>
