<template>
    <div class="recharge-main">
      <div class="er-code">
        <el-image
          style="height: 150px;width: 150px;"
          src="https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lner.png"
          fit="fit"></el-image>
        <div style="text-align: center;margin-top: 10px">扫码咨询</div>
      </div>
      <div class="form" v-loading="loading" element-loading-text="加载中">
        <el-form :model="formData" ref="form" style="width: 100%">
          <el-form-item prop="type" :rules="[{ required: true, message: '请选择充值类型' }]">
            <el-select v-model="formData.type" placeholder="请选择充值类型" style="width: 100%">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="price" :rules="[{ required: true, message: '请选择套餐类型' }]">
            <el-select v-model="formData.price" placeholder="请选择套餐类型" style="width: 100%">
              <el-option
                v-for="item in list"
                :key="item.value"
                :label="item.label"
                :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100%" type="primary" @click="submitClick">提交充值申请</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
</template>

<script>
  import { addEarnNum } from '@/api/customerpond'
  export default {
    name: 'recharge',
    data () {
      return {
        loading: false,
        formData: {
          type: null,
          price: null
        },
        typeList:[
          {
            label: '查云服务商（专业版）',
            value: 1
          },
          {
            label: ' 查域名（专业版）',
            value: 2
          }
        ],
        list: [
          {
            label: '套餐1：每月1000点',
            value: 1
          },
          {
            label: '套餐2：每月5000点',
            value: 2
          },
          {
            label: '套餐3：每月10000点',
            value: 3
          },
          {
            label: '套餐4：每月50000点',
            value: 4
          }
        ]
      }
    },
    watch: {},
    beforeDestroy () {},
    created () {},
    mounted () {},
    computed: {},
    methods: {
      submitClick() {
        this.$refs.form.validate(valid => {
          if (valid) {
            const params = {
              type: this.formData.type,
              set: this.formData.price
            }
            if (this.loading) return
            this.loading = true
            addEarnNum(params).then(res => {
              this.loading = false
              if (res.code === 60000){
                this.$store.commit('update', {
                  target: this.$store.state.plugin.modal.options,
                  data: { refresh: true }
                })
                this.$store.dispatch('hideModal')
              }
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .recharge-main {
    display: flex;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    .er-code {
      width: 200px;
      padding: 30px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .form{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
</style>
