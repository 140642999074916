var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "new_page_cont" }, [
    _vm.pageStatus.total
      ? _c(
          "div",
          { staticClass: "new_page_footer" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.pageStatus.pageNo,
                "page-sizes": _vm.pageSizes,
                "page-size": _vm.pageStatus.pageSize,
                layout: "total, sizes, prev, pager, next",
                total: _vm.pageStatus.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "show_total_num" }, [
              _vm._v(
                "\n      共" +
                  _vm._s(_vm.pageStatus.showTotal || _vm.pageStatus.total) +
                  "条\n    "
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }