var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dia-model",
        {
          ref: "scribesDialog",
          attrs: { dialogStatus: _vm.dialogStatus },
          on: { submitContBtn: _vm.submitContBtn },
        },
        [
          _c(
            "div",
            { attrs: { slot: "modalCont" }, slot: "modalCont" },
            [
              _c(
                "el-scrollbar",
                {
                  staticClass: "dt-dropdown-dt",
                  staticStyle: {
                    width: "100%",
                    height: "450px",
                    position: "relative",
                  },
                },
                [
                  _c("div", {
                    staticClass: "h-html",
                    staticStyle: {
                      width: "500px",
                      margin: "0 auto",
                      position: "relative",
                    },
                    domProps: { innerHTML: _vm._s(_vm.pageDetail) },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }