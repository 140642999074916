<template>
  <div class="weixinLogin-main" v-loading="loading">
    <span @click="$router.replace('/login')">返回首页</span>
    <div v-if="scan">
      <div v-if="wordDesc" class="error">
        该微信已绑定领鸟云注册账号:{{ wordDesc }}
      </div>
      <div class="error" v-else>该微信未绑定领鸟云注册账号</div>
    </div>

    <div v-show="show" id="wx-login-one" class="code"></div>
  </div>
</template>

<script>
import { wxLogin } from '@/api/user'
import bindWeChart from '@/views/login/dialog/bindWeChart'
import store from '@/store'
import { ResetAuthUserMeal, ResetSystemConfig } from '@/utils/auth'
import { AppWebSockt } from '@/utils/websocket'
import { getWxScanError } from '@/api/module'
import { setPermission } from '@/cores/RouteAndPermission'
export default {
  name: 'WeixinLogin',
  data() {
    return {
      show: true,
      code: '',
      wordDesc: '',
      scan: false,
      loading: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.login()
        this.loading = false
      }, 1000)
    })
  },
  methods: {
    getCode(res) {
      console.log(res, 7788)
      const code = (res?.data?.url || '')?.split('code=')?.[1]?.split('&')?.[0]
      // const code = res.data
      console.log(code, 'code')
      if (code) this.startLogin(code)
    },
    login() {
      const isPro = process.env.NODE_ENV === 'production'
      const uri = isPro ? 'https://ai.dtbird.cn' : 'https://ai2.dtbird.cn'
      window.removeEventListener('message', this.getCode)
      new WxLogin({
        self_redirect: true,
        id: 'wx-login-one',
        appid: isPro ? 'wx0e7b6e5c3e1c1321' : 'wx5febb47ccea3db0e',
        scope: 'snsapi_login',
        redirect_uri: encodeURIComponent(uri),
        state: '',
        style: '',
        href: ''
      })
      window.addEventListener('message', this.getCode)
    },

    startLogin(code) {
      console.log(code, 1234)
      this.show = false
      if (this.$route.query.type == 'error') {
        getWxScanError({ code }).then(err => {
          this.wordDesc = err.data[0]
          console.log(err, 'scanError')
        })
        this.scan = true
        return
      }
      wxLogin({ code })
        .then(async res => {
          console.log(res, 222)
          if (res.code === 60000) {
            localStorage.clear()
            sessionStorage.clear()
            const token = res.data.token
            const step = res.data.step
            const accountType = res.data.type
            const userName = res.data.followName || ''
            store.commit('setToken', token)
            await store.dispatch('changeStep', step)
            await store.dispatch('setName', userName)
            localStorage.setItem('setAccountType', accountType + '')
            localStorage.setItem('dtBird_user_step', step)
            localStorage.setItem('setName', userName)
            localStorage.setItem('userLoginTime', new Date().getTime())
            if (step != 1) {
              ResetAuthUserMeal()
            }
            ResetSystemConfig() //获取系统信息
            AppWebSockt('kick_user_offline')
            await setPermission()
            localStorage.setItem(
              'permissionObj',
              JSON.stringify(store.state.permission.permission)
            )
            localStorage.setItem(
              'menuList',
              JSON.stringify(store.state.permission.menuList)
            )
            localStorage.setItem(
              'panelList',
              JSON.stringify(store.state.permission.panelList)
            )

            this.$router.replace('/dt-index')
          } else {
            if (res.code === 61006) {
              // 绑定账号
              this.$store.dispatch('showModal', {
                view: bindWeChart,
                size: '600px',
                title: '绑定',
                options: {
                  opId: res?.data?.openid
                },
                onClose: () => {
                  this.show = true
                  this.$nextTick(() => {
                    this.login()
                  })
                }
              })
            } else {
              this.show = true
              this.$message.error(res.msg)
              this.$nextTick(() => {
                this.login()
              })
            }
          }
        })
        .catch(() => {
          this.show = true
        })
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.getCode)
  }
}
</script>

<style scoped lang="scss">
.weixinLogin-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(148%, 855%);
    font-size: 14px;
    cursor: pointer;
    color: #0099cc;
    text-decoration: underline;
    user-select: none;
  }
  .error {
    color: #f00;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 70%;
    font-weight: 400;
    font-size: 20px;
  }
}
</style>
