<template>
  <div>hx</div>
</template>

<script>
import { getHxLogin, getRouterUrl } from '@/api/module'
import { setToken } from '@/utils/auth'
export default {
  name: 'HxLogin',

  mounted() {
    const code = this.$route.query.code
    this.handleLogin(code)
  },
  methods: {
    async handleLogin(code) {
      try {
        const formData = new FormData()
        formData.append('code', code)
        const { data } = await getHxLogin(formData)
        console.log(data, 'data123')
        // setToken({ token: data['one-token'] })
        this.$store.commit('setToken', data['one-token'])
        console.log(data)
        const reg = await getRouterUrl()
        console.log(reg, 'reg')
        this.$router.push({ path: reg.data.mainUrl })
        sessionStorage.setItem('menuType', reg.data.menuType)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
