<template>
  <div class="c-dialog">
    <pe-dialog
      :pe-dialog-show="show"
      :pe-dialog-show-sure="false"
      PeDialogClass="d-class"
      @handlePeDialogClose="handleClose"
      pe-dialog-title="详情"
    >
      <div class="dialog-content">
        <div class="c-name">{{ companyInfo.companyName }}</div>
        <div class="card">
          <div class="title">企业标签</div>
          <el-tag
            size="mini"
            v-for="(tag, idx) in companyInfo.tags"
            :key="idx"
            >{{ tag }}</el-tag
          >
        </div>

        <div class="card">
          <div class="title">企业简介</div>
          <div>{{ companyInfo.introduce }}</div>
        </div>

        <div class="card">
          <div class="title">企业特征</div>
          <div v-for="(item, index) in companyInfo.msgList" :key="index">
            {{ item }}
          </div>
        </div>

        <div class="card">
          <div class="el">
            <div class="title">属地落税：</div>
            <span>{{ companyInfo.taxArea }}</span>
          </div>
          <div class="el">
            <div class="title">区域版块：</div>
            <span>{{ companyInfo.block }}</span>
          </div>
          <div class="el">
            <div class="title">质量评分：</div>
            <span>{{ companyInfo.score }}</span>
          </div>
          <div class="el">
            <div class="title">质量评级：</div>
            <span>{{ companyInfo.level }}</span>
          </div>
        </div>
      </div>
    </pe-dialog>
  </div>
</template>

<script>
import PeDialog from '@/components/Peculiar/dialog'
export default {
  name: 'clickDialog',

  props: {
    companyInfo: {
      type: Object,
      default: () => ({})
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClose() {
      this.$emit('handleClose')
    }
  },

  components: {
    PeDialog
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.c-dialog {
  .dialog-content {
    line-height: 25px;
  }

  ::v-deep .el-tag {
    margin: 5px;
    color: #fff;
    border-color: transparent;
    background: $main-color;
  }

  .card {
    color: #888;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 5px;
    margin-top: 5px;
    .el {
      display: flex;
      align-items: center;
    }
  }

  .c-name {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 600;
  }

  .title {
    color: #2e2e2e;
    font-weight: 600;
    font-size: 14px;
  }
}

.d-class {
  margin-top: 20vh;
}
</style>
