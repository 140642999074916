<template>
  <div class="createFollowRecord-main">
    <el-form :model="formData" label-width="auto" :rules="rule" ref="form">
      <el-form-item prop="followTime" label="跟进时间">
        <el-date-picker
          style="width: 100%"
          v-model="formData.followTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item prop="followType" label="跟进方式">
        <el-select
          v-model="formData.followType"
          placeholder="请选择跟进方式"
          style="width: 100%"
        >
          <el-option
            v-for="item in followTypeList"
            :key="item.value"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="companyName" label="跟进客户">
        <div class="mask" @click="selectCompanyClick">
          <span>
            选择
          </span>
        </div>
        <el-input
          v-model="formData.companyName"
          placeholder="请选择跟进客户"
        ></el-input>
      </el-form-item>

      <el-form-item prop="followUser" label="跟进对象">
        <div class="mask mask-user" @click="selectUser">
          <span class="select-tag">
            <el-tag
              closable
              size="mini"
              class="tag"
              v-for="item in selectUserList"
              @close="editSelectUser(item)"
              >{{ item.contact }}</el-tag
            >
          </span>
          <span>
            选择
          </span>
        </div>
        <el-input
          v-model="formData.followUser"
          :placeholder="selectUserList.length ? '' : '请选择跟进对象'"
        ></el-input>
      </el-form-item>

      <el-form-item prop="content" label="跟进记录">
        <el-input
          type="textarea"
          :autosize="{ minRows: 5, maxRows: 6 }"
          v-model="formData.content"
          placeholder="请输入跟进记录"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button size="small" @click="cancelFr">取消</el-button>
      <el-button type="primary" size="small" @click="confirmFr">确认</el-button>
    </div>

    <el-dialog
      class="el-dialog-dt"
      width="800px"
      top="90px"
      :visible.sync="showSelectCompany"
      :show-close="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <div slot="title" class="slot-title">
        <div>
          选择客户
        </div>
        <div @click="cancelCompany">
          <i class="el-icon-close close"></i>
        </div>
      </div>
      <div class="company-list">
        <div class="tab-div">
          <el-tabs v-model="tabType" class="dt-tab" @tab-click="tabClick">
            <el-tab-pane
              label="我负责的"
              name="1"
              v-if="
                permissionList.includes(
                  '客户管理CRM-客户公海-我负责的-填写跟进记录'
                )
              "
            />
            <el-tab-pane
              label="全公司的"
              name="3"
              v-if="
                permissionList.includes(
                  '客户管理CRM-客户公海-全公司的-填写跟进记录'
                )
              "
            />
            <el-tab-pane
              label="可跟进的"
              name="2"
              v-if="
                permissionList.includes(
                  '客户管理CRM-客户公海-可跟进的-填写跟进记录'
                )
              "
            />
            <el-tab-pane
              label="草稿箱"
              name="4"
              v-if="
                permissionList.includes(
                  '客户管理CRM-客户公海-草稿箱-填写跟进记录'
                )
              "
            />
          </el-tabs>
        </div>

        <div class="search-input">
          <div style="width: 630px">
            <el-input
              v-model="companyName"
              placeholder="请输入公司查询"
              size="small"
            ></el-input>
          </div>
          <div class="btn-list">
            <el-button size="small" type="primary" @click="searchCompany">
              查询
            </el-button>
            <el-button size="small" @click="reset"> 重置 </el-button>
          </div>
        </div>

        <div class="tip" v-if="selectCompany.companyName">
          <el-tag size="small" closable @close="delCompany">
            {{ selectCompany.companyName }}
          </el-tag>
        </div>

        <div class="table">
          <dt-table
            :data-total="pageStatus.total"
            :hide-border="true"
            :key="refreshKey"
            :is-add-earn-field="false"
            :is-count-height="false"
            :shift-field="shiftField"
            :column-width-list="{ select: 50 }"
            table-h="400px"
            :is-select="false"
            :column-list="columns"
            @sizeChange="sizeChange"
            @currentChange="currentChange"
            @singleSelectChange="singleSelectChange"
            :table-loading="loading"
            :table-data="companyList"
          >
            <template v-slot:selectRow="row">
              <span>
                <el-checkbox :value="row.scope.data.id === selectCompany.id" />
              </span>
            </template>
            <template v-slot:leaderHeader="row">
              <span>
                <el-dropdown
                  placement="bottom"
                  trigger="click"
                  @command="val => filterFunc(val, 'leader')"
                >
                  <template>
                    <span
                      class="el-dropdown-link"
                      :class="userId ? 'active' : ''"
                    >
                      <span> 负责人 </span
                      ><i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-scrollbar class="dt-dropdown-dt">
                        <el-dropdown-item
                          :command="_item.id"
                          :class="userId === _item.id ? 'active' : ''"
                          v-for="_item in userList"
                          >{{ _item.name }}</el-dropdown-item
                        >
                      </el-scrollbar>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </span>
            </template>
          </dt-table>
        </div>

        <div class="footer" style="padding-right: 20px">
          <el-button size="small" @click="cancelCompany">取消</el-button>
          <el-button size="small" type="primary" @click="confirmCompany"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      class="el-dialog-dt"
      width="850px"
      top="90px"
      :visible.sync="showSelectUser"
      :show-close="false"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
    >
      <div slot="title" class="slot-title">
        <div>
          选择签单决策人
        </div>
        <div @click="cancelSelectUser">
          <i class="el-icon-close close"></i>
        </div>
      </div>

      <div class="user-list">
        <selectBillKeyPersion
          :company-name="selectCompany.companyName"
          :is-active-tab="isActiveTab"
          :current-check-row-prop="selectUserList"
          :package-id="selectCompany.packageId"
          :company-uuid="selectCompany.companyUuid"
          @closeDialog="closeDialog"
          @getRes="getSelectUser"
          :key="refreshSelectKey"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCusPondList, getCustomerLeaders } from '@/api/customerpond'
import DtTable from '@/components/element/DtTable'
import selectBillKeyPersion from '@/views/customerpond/chanceManage/dialog/selectBillKeyPersion'
import { postFollowSaveRecords } from '@/api/table'
import moment from 'moment'

export default {
  name: 'createFollowRecord',
  components: {
    DtTable,
    selectBillKeyPersion
  },
  data() {
    var validateBillKeyperson = (rule, value, callback) => {
      if (!this.selectUserList.length) {
        callback(new Error('请选择跟进对象'))
      } else {
        callback()
      }
    }
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      followTypeList: [],
      formData: {
        followTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        followType: '',
        companyName: '',
        followUser: '',
        content: ''
      },
      rule: {
        followTime: [
          { required: true, message: '请选择跟进时间', trigger: 'blur' }
        ],
        followType: [
          { required: true, message: '请选择跟进方式', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请选择跟进客户', trigger: 'change' }
        ],
        // followUser: [
        //   { validator: validateBillKeyperson, required: true, trigger: 'blur' }
        // ],
        content: [
          { required: true, message: '请填写跟进记录', trigger: 'blur' }
        ]
      },

      showSelectCompany: false,
      refreshKey: 0,
      shiftField: [{ field: 'select', name: '' }],
      companyName: '',
      selectCompany: {},
      companyList: [],
      loading: false,
      tabType: null,
      isActiveTab: '1',
      pageStatus: {
        total: null,
        pageNo: 1,
        pageSize: 10
      },
      columns: [
        {
          field: 'companyName',
          name: '客户名称'
        },
        {
          field: 'createdAt',
          name: '创建时间'
        }
      ],
      userList: [],
      userId: '',

      selectUserList: [],
      refreshSelectKey: 0,
      showSelectUser: false
    }
  },
  mounted() {
    this.setTabType()
    this.getFollowUserList()
    this.followTypeList = this.$store.state.plugin.modal.options.followTypeList
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    }
  },
  watch: {
    tabType(val) {
      console.log(val, typeof val)
      if (val === '3') {
        this.columns = [
          {
            field: 'companyName',
            name: '客户名称'
          },
          {
            field: 'createdAt',
            name: '创建时间'
          },
          {
            field: 'leader',
            name: '负责人'
          }
        ]
      } else {
        this.columns = [
          {
            field: 'companyName',
            name: '客户名称'
          },
          {
            field: 'createdAt',
            name: '创建时间'
          }
        ]
      }
    }
  },
  methods: {
    cancelFr() {
      this.$store.dispatch('hideModal')
    },
    confirmFr() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const params = {
            id: this.selectCompany.id, // 企业id
            followType: this.formData.followType,
            followRecords: this.formData.content,
            date: this.formData.followTime,
            ids: this.selectUserList.map(i => i.id).join(',')
          }
          postFollowSaveRecords(params).then(res => {
            if (res.code === 60000) {
              this.$store.commit('update', {
                target: this.$store.state.plugin.modal.options,
                data: {
                  refresh: true
                }
              })
              this.cancelFr()
            }
          })
        }
      })
    },

    setTabType() {
      if (
        this.permissionList.includes(
          '客户管理CRM-客户公海-我负责的-填写跟进记录'
        )
      ) {
        this.tabType = '1'
        return
      }
      if (
        this.permissionList.includes(
          '客户管理CRM-客户公海-全公司的-填写跟进记录'
        )
      ) {
        this.tabType = '3'
        return
      }
      if (
        this.permissionList.includes(
          '客户管理CRM-客户公海-可跟进的-填写跟进记录'
        )
      ) {
        this.tabType = '2'
        return
      }
      if (
        this.permissionList.includes('客户管理CRM-客户公海-草稿箱-填写跟进记录')
      ) {
        this.tabType = '4'
      }
    },

    selectCompanyClick() {
      this.showSelectCompany = true
      this.getCompanyList()
    },
    selectUser() {
      if (!this.selectCompany.companyName) return
      this.refreshSelectKey += 1
      this.showSelectUser = true
    },

    confirmCompany() {
      if (!Object.keys(this.selectCompany).length) {
        this.$store.dispatch('showTip', {
          text: '请选择关联公司',
          type: 'warning'
        })
        return
      }
      this.showSelectCompany = false
      this.formData.companyName = this.selectCompany.companyName
      this.isActiveTab = this.tabType
      this.selectUserList = []
    },

    filterFunc(val) {
      this.userId = val
      this.getCompanyList()
    },

    getFollowUserList() {
      getCustomerLeaders({ pageId: 2001, type: 3 }).then(res => {
        if (res.code === 60000) {
          this.userList = res.data.filter(i => i.name !== '可跟进的')
        }
      })
    },

    cancelCompany() {
      this.showSelectCompany = false
      this.selectCompany = {}
    },

    tabClick() {
      this.selectCompany = {}
      this.getCompanyList()
    },

    currentChange(val) {
      this.pageStatus.pageNo = val
      this.getCompanyList()
    },

    sizeChange(val) {
      this.pageStatus.pageSize = val
      this.getCompanyList()
    },

    singleSelectChange(val) {
      if (!val) return
      this.selectCompany = val
    },

    getCompanyList() {
      this.loading = true
      const params = {
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        pageId: 2001,
        companyName: this.companyName,
        type: this.tabType,
        selectType: 1,
        userId: this.userId
      }
      getCusPondList(params).then(res => {
        this.loading = false
        if (res.code === 60000) {
          this.companyList = [...res.data.list]
          this.pageStatus = res.data.page
        }
      })
    },

    searchCompany() {
      if (!this.companyName) return
      this.pageStatus.pageNo = 1
      this.pageStatus.pageSize = 10
      this.pageStatus.total = null
      this.getCompanyList()
    },

    delCompany() {
      this.selectCompany = {}
      this.refreshKey += 1
    },

    reset() {
      this.companyName = ''
      this.userId = ''
      this.pageStatus = {
        pageSize: 10,
        pageNo: 1,
        total: 0
      }
      this.getCompanyList()
    },

    cancelSelectUser() {
      this.showSelectUser = false
    },
    closeDialog() {
      this.cancelSelectUser()
    },
    getSelectUser(userList) {
      this.selectUserList = userList
      this.closeDialog()
    },
    editSelectUser(item) {
      this.selectUserList = this.selectUserList.filter(i => i.id !== item.id)
      if (!this.selectUserList.length) this.formData.billKeyperson = ''
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.createFollowRecord-main {
  padding: 10px 20px 0 20px;
  box-sizing: border-box;
}
.footer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  position: relative;
}
.footer:before {
  content: '';
  display: inline-block;
  height: 1px;
  width: calc(100% + 40px);
  background: rgba(0, 0, 0, 0.1);
  transform: scaleY(0.5);
  position: absolute;
  top: 0;
  left: -20px;
}
.mask {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 99;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
}
.mask > span {
  color: #41cabf;
  font-size: 12px;
}
.scroll-bar {
  height: 565px;
  width: 100%;
}
.main-row {
  width: calc(100% - 100px);
  margin: 10px auto;
}
.select-create {
  position: absolute;
  z-index: 99;
  right: 10px;
  color: #41cabf;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  text-align: right;
}
.select-edit {
  position: absolute;
  z-index: 99;
  right: 10px;
  color: #8c939d;
  cursor: not-allowed;
}
.select-tag {
  position: absolute;
  z-index: 99;
  left: 10px;
  color: #8c939d;
}
.tag {
  margin-right: 5px;
}
.slot-title {
  height: 50px;
  background-color: #41cabf;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.el-dialog-dt ::v-deep .el-dialog__header,
.el-drawer-dt ::v-deep .el-drawer__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}
.el-drawer-dt ::v-deep .el-drawer__header {
  margin-bottom: 0 !important;
}

.el-dialog-dt ::v-deep .el-dialog__close,
.el-drawer-dt ::v-deep .el-drawer__close {
  color: #fff;
}

.el-dialog-dt ::v-deep .el-dialog__body,
.el-drawer-dt ::v-deep .el-drawer__body {
  padding: 0 !important;
}
.close {
  cursor: pointer;
}
.search-input {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;
}

.search-input::before {
  display: inline-block;
  content: '';
  height: 5px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  background-color: rgba(0, 0, 0, 0.03);
}

.tip {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.btn-list {
  margin-left: 20px;
}
.table {
  height: 450px;
  width: calc(100% - 20px);
  margin: 0 auto;
}
.tab-div {
  height: 50px;
  width: 100%;
  background-color: #fff;
  padding-left: 10px;
  box-sizing: border-box;
  position: relative;
}
.tab-div::before {
  display: inline-block;
  content: '';
  height: 5px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.dt-tab /deep/ .el-tabs__nav-wrap::after {
  display: none !important;
}
.active {
  color: #41cabf !important;
}
.el-dropdown-link {
  font-size: 12px;
  color: #909399;
  cursor: pointer;
}
</style>
