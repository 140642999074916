var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "store-container" }, [
    _c(
      "div",
      { staticClass: "item" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("中国商圈分布")]),
        _vm._v(" "),
        _c("PeBusiness"),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "item top10" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            on: { "tab-click": _vm.initChart },
            model: {
              value: _vm.storeType,
              callback: function ($$v) {
                _vm.storeType = $$v
              },
              expression: "storeType",
            },
          },
          [
            _c("el-tab-pane", { attrs: { label: "族群", name: "1" } }),
            _vm._v(" "),
            _c("el-tab-pane", { attrs: { label: "本企业", name: "2" } }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "title" }, [_vm._v("连锁门店数监测")]),
        _vm._v(" "),
        _c("StoreMap", {
          attrs: { storeType: _vm.storeType, mapUuid: _vm.companyUuid },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "item top10" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("营业门店总数数据分析(过去" + _vm._s(_vm.year) + "年趋势)"),
      ]),
      _vm._v(" "),
      _c("div", { ref: "peChartDom", staticClass: "pe-charts" }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item top10" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("门店当月数据分析(开店/关店过去" + _vm._s(_vm.year) + "年趋势)"),
      ]),
      _vm._v(" "),
      _c("div", { ref: "peSwitchDom", staticClass: "pe-charts" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }