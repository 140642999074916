var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c("Header", { attrs: { logo: _vm.configInfo.loginLogo } }),
      _vm._v(" "),
      _vm.isPc
        ? _c("div", { staticClass: "login" }, [
            _c("img", { staticClass: "login-info", attrs: { src: _vm.logo } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "login-input" },
              [
                _c(
                  "div",
                  { staticClass: "login-input-title" },
                  [
                    _c("svg-icon", {
                      staticStyle: { "font-size": "30px" },
                      attrs: {
                        className: "icon-active",
                        "icon-class": "icon-user",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "lg-name" }, [
                      _vm._v("登录账号"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "login-type" },
                  [
                    _c("div", { class: _vm.handleActive("account") }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changeType("account")
                            },
                          },
                        },
                        [_vm._v("账号")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { class: _vm.handleActive("code") }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changeType("code")
                            },
                          },
                        },
                        [_vm._v("手机验证码")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.configInfo.hasWechatLogin
                      ? [
                          _c("div", { staticClass: "account-type" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goWxCode("succ")
                                  },
                                },
                              },
                              [_vm._v("微信扫码")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "account-type" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goWxCode("error")
                                  },
                                },
                              },
                              [_vm._v("扫码异常排查")]
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.loginType === "wxCode"
                  ? [
                      _c("div", {
                        staticStyle: { height: "290px" },
                        attrs: { id: "wx-login-one" },
                      }),
                    ]
                  : [
                      _vm.loginType == "account"
                        ? _c("div", { staticClass: "user-login" }, [
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.loginForm.username,
                                    expression: "loginForm.username",
                                  },
                                ],
                                staticStyle: { "margin-top": "27px" },
                                attrs: {
                                  id: _vm.errorName
                                    ? "login-input-error"
                                    : "11",
                                  maxlength: "25",
                                  placeholder: "请输入用户名",
                                },
                                domProps: { value: _vm.loginForm.username },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.loginForm,
                                      "username",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-password" }, [
                              (_vm.flag ? "password" : "text") === "checkbox"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.password,
                                        expression: "loginForm.password",
                                      },
                                    ],
                                    attrs: {
                                      id: _vm.errorPwd
                                        ? "login-input-error"
                                        : "22",
                                      maxlength: "20",
                                      placeholder: "请输入密码",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.loginForm.password
                                      )
                                        ? _vm._i(_vm.loginForm.password, null) >
                                          -1
                                        : _vm.loginForm.password,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.loginForm.password,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.loginForm,
                                                "password",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.loginForm,
                                                "password",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.loginForm,
                                            "password",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  })
                                : (_vm.flag ? "password" : "text") === "radio"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.password,
                                        expression: "loginForm.password",
                                      },
                                    ],
                                    attrs: {
                                      id: _vm.errorPwd
                                        ? "login-input-error"
                                        : "22",
                                      maxlength: "20",
                                      placeholder: "请输入密码",
                                      type: "radio",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.loginForm.password,
                                        null
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.loginForm,
                                          "password",
                                          null
                                        )
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.loginForm.password,
                                        expression: "loginForm.password",
                                      },
                                    ],
                                    attrs: {
                                      id: _vm.errorPwd
                                        ? "login-input-error"
                                        : "22",
                                      maxlength: "20",
                                      placeholder: "请输入密码",
                                      type: _vm.flag ? "password" : "text",
                                    },
                                    domProps: { value: _vm.loginForm.password },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.loginForm,
                                          "password",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "password-icon" },
                                [
                                  _c("svg-icon", {
                                    staticStyle: {
                                      cursor: "pointer",
                                      "font-size": "20px",
                                    },
                                    attrs: {
                                      "icon-class": _vm.flag
                                        ? "eye"
                                        : "open_eye",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        _vm.flag = !_vm.flag
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.loginType == "code"
                        ? _c("div", { staticStyle: { position: "relative" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.loginForm.username,
                                  expression: "loginForm.username",
                                },
                              ],
                              staticStyle: { "margin-top": "27px" },
                              attrs: {
                                id: _vm.errorName ? "login-input-error" : "111",
                                maxlength: "25",
                                placeholder: "请输入手机号",
                              },
                              domProps: { value: _vm.loginForm.username },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.loginForm,
                                    "username",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.loginForm.code,
                                  expression: "loginForm.code",
                                },
                              ],
                              attrs: {
                                id: _vm.errorCode ? "login-input-error" : "222",
                                oninput: "value=value.replace(/[\\W]/g,'')",
                                type: "text",
                                maxlength: "6",
                                placeholder: "请输入验证码",
                              },
                              domProps: { value: _vm.loginForm.code },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.loginForm,
                                    "code",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.sendMessage == "获取验证码"
                                    ? "sendcode"
                                    : "sendcode-disabled",
                                on: { click: _vm.sendCode },
                              },
                              [_vm._v(_vm._s(_vm.sendMessage))]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.loginType === "ddErCode"
                        ? _c("div", [
                            _c("div", { attrs: { id: "login_container" } }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMsg
                        ? _c("p", [_vm._v(_vm._s(_vm.errorMsg))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.loginType !== "ddErCode"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.showLoging,
                                  expression: "showLoging",
                                },
                              ],
                              staticClass: "login-bt",
                              on: { click: _vm.loginBt },
                            },
                            [_vm._v("\n          登录\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.configInfo.pageShowConfig
                        ? _c(
                            "div",
                            {
                              staticClass: "login-bt",
                              on: { click: _vm.handleVerify },
                            },
                            [_vm._v("\n          统一认证\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$store.state.settings.errorMessage
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                color: "#f00",
                                "margin-top": "10px",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$store.state.settings.errorMessage
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.loginType !== "ddErCode" && _vm.configInfo.hasRegister
                        ? _c(
                            "div",
                            { staticClass: "login-tip" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticStyle: { "margin-right": "28px" },
                                  attrs: { to: { path: "/registerBase" } },
                                },
                                [_vm._v("注册账号")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticStyle: { "margin-right": "28px" },
                                  on: { click: _vm.forgetPaw },
                                },
                                [_vm._v("找回密码")]
                              ),
                              _vm._v(" "),
                              _c("a", { on: { click: _vm.forgetUserName } }, [
                                _vm._v("找回账户名"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
              ],
              2
            ),
          ])
        : _c(
            "div",
            { staticClass: "m-wrap" },
            [
              _c(
                "div",
                { staticClass: "m-list" },
                _vm._l(_vm.mobileList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.value,
                      class:
                        item.value == _vm.loginType
                          ? "c-active m-item"
                          : " m-item",
                      on: {
                        click: function ($event) {
                          return _vm.handleAccount(item)
                        },
                      },
                    },
                    [_vm._v("\n        " + _vm._s(item.label) + "\n      ")]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.loginForm, "label-width": "60px" },
                },
                [
                  _vm.loginType == "account"
                    ? [
                        _c(
                          "el-form-item",
                          { attrs: { label: "用户名" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.loginForm.username,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "username", $$v)
                                },
                                expression: "loginForm.username",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "密码" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.loginForm.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "password", $$v)
                                },
                                expression: "loginForm.password",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-form-item",
                          { attrs: { label: "手机号" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.loginForm.username,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "username", $$v)
                                },
                                expression: "loginForm.username",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "验证码" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.loginForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "code", $$v)
                                },
                                expression: "loginForm.code",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.sendMessage == "获取验证码"
                                    ? "sendcode"
                                    : "sendcode-disabled",
                                on: { click: _vm.sendCode },
                              },
                              [_vm._v(_vm._s(_vm.sendMessage))]
                            ),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "m-login",
                  attrs: { type: "primary" },
                  on: { click: _vm.mobileLogin },
                },
                [_vm._v("登录")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }