var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-main" }, [
    _c(
      "div",
      { staticClass: "btn-list" },
      [
        _c(
          "div",
          { staticClass: "input-row" },
          [
            _c("el-input", {
              staticStyle: { width: "200px" },
              attrs: {
                size: "mini",
                placeholder: "输入跟进记录/跟进客户/跟进对象",
              },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-row" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.searchFollowRecord },
                  },
                  [_vm._v("查询")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.reset } },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "g-search", on: { click: _vm.searchPlus } },
              [
                _vm._v("\n        高级筛选"),
                _c("svg-icon", {
                  staticClass: "filtrate",
                  attrs: { "icon-class": "filtrate" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.condition,
                    expression: "condition",
                  },
                ],
                staticClass: "condition",
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "condition-icon",
                    on: { click: _vm.removeSearch },
                  },
                  [_c("i", { staticClass: "el-icon-circle-close" })]
                ),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.showCondition,
                    callback: function ($$v) {
                      _vm.showCondition = $$v
                    },
                    expression: "showCondition",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "dt-permission",
                rawName: "v-dt-permission",
                value: "客户管理CRM-跟进记录-新建跟进记录",
                expression: "'客户管理CRM-跟进记录-新建跟进记录'",
              },
            ],
            attrs: { size: "mini", type: "primary" },
            on: { click: _vm.createFollowRecord },
          },
          [_vm._v("\n      新建跟进记录\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
          {
            name: "dt-permission",
            rawName: "v-dt-permission",
            value: "客户管理CRM-跟进记录-新建跟进记录",
            expression: "'客户管理CRM-跟进记录-新建跟进记录'",
          },
        ],
        staticClass: "table",
      },
      [
        _c(
          "el-table",
          {
            key: _vm.tableKey,
            staticClass: "table-dt",
            staticStyle: {
              width: "100%",
              height: "100%",
              "font-size": "12px",
              "background-color": "#fff",
            },
            attrs: { data: _vm.tableData, border: "", stripe: "" },
            on: { "sort-change": _vm.sortChange },
          },
          _vm._l(_vm.columnList, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                "show-tooltip-when-overflow": "",
                prop: item.field,
                align: "center",
                sortable: item.type === "sort" ? "custom" : false,
                label: item.name,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        item.type === "filter"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-dropdown",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      trigger: "click",
                                    },
                                    on: {
                                      command: (val) =>
                                        _vm.filterFunc(val, item),
                                    },
                                  },
                                  [
                                    item.field === "followName"
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "el-dropdown-link",
                                              class: _vm.followUserId
                                                ? "active"
                                                : "",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name)),
                                              ]),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c("dt-drop-down", {
                                                attrs: {
                                                  "source-list":
                                                    _vm.followUserList,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (row) {
                                                        return [
                                                          _c(
                                                            "el-scrollbar",
                                                            {
                                                              staticClass:
                                                                "dt-dropdown-dt",
                                                            },
                                                            [
                                                              !row.filterList
                                                                .length
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "dt-drop-down-no-data",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          无数据\n                        "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                row.filterList,
                                                                function (
                                                                  _item
                                                                ) {
                                                                  return _c(
                                                                    "el-dropdown-item",
                                                                    {
                                                                      class:
                                                                        _vm.followUserId ===
                                                                        _item.id
                                                                          ? "active"
                                                                          : "",
                                                                      attrs: {
                                                                        command:
                                                                          _item.id,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  _item.htmlEl
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : item.field === "followType"
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "el-dropdown-link",
                                              class: _vm.followType
                                                ? "active"
                                                : "",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name)),
                                              ]),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c(
                                                "el-scrollbar",
                                                {
                                                  staticClass: "dt-dropdown-dt",
                                                },
                                                _vm._l(
                                                  _vm.followTypeList,
                                                  function (_item) {
                                                    return _c(
                                                      "el-dropdown-item",
                                                      {
                                                        class:
                                                          _vm.followType ===
                                                          _item.name
                                                            ? "active"
                                                            : "",
                                                        attrs: {
                                                          command: _item.name,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_item.name)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : item.type === "sort"
                          ? _c("span", [_vm._v(" " + _vm._s(item.name) + " ")])
                          : _c("span", [_vm._v(" " + _vm._s(item.name))]),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.field === "companyName" && _vm.isLink
                          ? _c("company-link", {
                              attrs: {
                                "company-name":
                                  scope.row.companyName ||
                                  scope.row.company_name,
                                "company-uuid":
                                  scope.row.companyUuid ||
                                  scope.row.company_uuid,
                                "package-id":
                                  scope.row.packageId || scope.row.package_id,
                                companyList: _vm.tableData,
                                companyIndex: index,
                              },
                            })
                          : _c("span", [
                              _vm._v(" " + _vm._s(scope.row[item.field]) + " "),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page page-div page-1" },
      [
        _c("footer-page", {
          attrs: { pageStatus: _vm.pageStatus },
          on: {
            handleCurrentChange: _vm.handleCurrentChange,
            goNumPage: _vm.goNumPage,
            changePageSize: _vm.changePageSize,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }