<template>
  <div class="dingding-login">
    <div v-loading="loading" element-loading-text="登录中" class="loading"></div>
  </div>
</template>

<script>
import cookie from 'js-cookie'
import { ttLogin } from '@/api/user'
import store from '@/store'
import { ResetAuthUserMeal, ResetSystemConfig } from '@/utils/auth'
import { AppWebSockt } from '@/utils/websocket'
import expiredTip from '@/views/login/dialog/expiredTip'
import { setPermission } from '@/cores/RouteAndPermission'
export default {
  name: 'feishuLogin',
  data(){
    return {
      loading: true
    }
  },
  mounted () {
    if(this.isLogin) {
      this.$router.replace('/')
      return
    }
    const code = this.getQueryVariable('code')
    if(code){
      this.login(code)
    }else {
      const isDev = process.env.NODE_ENV === 'development'
      const url = isDev ? 'https://test.dtbird.cn/#/feishu-login' : 'https://ai.dtbird.cn/#/feishu-login'
      const appId = isDev ? 'cli_a01e2228b8f81014' : 'cli_a0d2ed2fec78900c'
      window.location.replace(`https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=${ encodeURIComponent(url) }&app_id=${ appId }`)
    }
  },
  computed: {
    env(){
      return this.$store.state.plugin.env
    },

    isLogin(){
      return  store.state.permission.isLogin
    }
  },
  methods: {

    getQueryVariable(variable) {
      let query = location.hash.split('?')
      if(query.length !== 2) return false
      let vars = query[1].split("&")
      for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=")
        if(pair[0] === variable){return pair[1];}
      }
      return false
    },


    login(code){
      if (!code) return
      cookie.remove('token')
      ttLogin({ code: code }).then(async (res)=>{
        if(res.code === 60000){
          localStorage.clear()
          sessionStorage.clear()
          const token = res.data['one-token'];
          const step = res.data.step || 2;
          const accountType = res.data.type;
          const userName = res.data.followName || '';
          store.commit('setToken', token)
          await store.dispatch('changeStep', step)
          await store.dispatch('setName', userName)
          localStorage.setItem('setAccountType', accountType + '')
          localStorage.setItem('dtBird_user_step', step)
          localStorage.setItem('setName', userName)
          localStorage.setItem('userLoginTime', new Date().getTime())
          sessionStorage.setItem('ttcode', code)
          if(step != 1){
            ResetAuthUserMeal()
          }
          ResetSystemConfig() //获取系统信息
          AppWebSockt('kick_user_offline')
          await setPermission()
          localStorage.setItem('permissionObj', JSON.stringify(store.state.permission.permission))
          localStorage.setItem('menuList', JSON.stringify(store.state.permission.menuList))
          localStorage.setItem('panelList', JSON.stringify(store.state.permission.panelList))
          if(this.env !== 'tt') {
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                env: 'tt'
              }
            })
            localStorage.setItem('env', 'tt')
          }
          this.$router.replace('/dt-index')
        }else {
          this.loading = false
          if(res.code === 61004){
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                env: 'tt'
              }
            })
            localStorage.setItem('env', 'tt')
            await this.$store.dispatch('showModal', {
              view: expiredTip,
              size: '500px',
              title: '提示'
            })
          }
        }
      }).catch((err)=>{
        this.loading = false
        this.$store.dispatch('showTip', {
          text: '免登失败,请关闭此页面重试??',
          type: 'error'
        })
      })
    }
  }
}
</script>

<style scoped>
.dingding-login{
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading{
  width: 100px;
}
</style>
