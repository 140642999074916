var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "main",
      staticClass: "task-list-main",
    },
    [
      _c("div", { staticClass: "header" }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "row", staticClass: "row" },
        [
          _c("span", { staticClass: "header-tip" }, [
            _vm._v("* 导入将会自动进行，您可以关闭此弹框"),
          ]),
          _vm._v(" "),
          _c("dt-table", {
            attrs: {
              "show-overflow-tooltip": false,
              "is-count-height": false,
              "table-h": _vm.tableHeight,
              "table-data": _vm.tableData,
              "is-select": false,
              "data-total": _vm.count,
              algin: "center",
              "column-list": _vm.columnList,
              "hide-border": true,
              "page-size": [10],
            },
            on: {
              sizeChange: _vm.sizeChange,
              currentChange: _vm.currentChange,
            },
            scopedSlots: _vm._u([
              {
                key: "uploadPercentRow",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      [
                        _c("el-progress", {
                          class:
                            row.scope.data.uploadPercent === 100
                              ? "layui-progress-bar-no"
                              : "layui-progress-bar",
                          attrs: {
                            percentage: row.scope.data.uploadPercent,
                            "stroke-width": 8,
                            color:
                              row.scope.data.uploadStatus === -1
                                ? "#f56c6c"
                                : "#41cabf",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "uploadResultRow",
                fn: function (row) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          "visible-arrow": false,
                          "popper-class": "popper",
                          effect: "dark",
                          content: row.scope.data.uploadResult,
                          placement: "right-end",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis",
                              cursor: "pointer",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.scope.data.uploadResult) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "setTingRow",
                fn: function (row) {
                  return [
                    row.scope.data.uploadStatus === 1
                      ? _c(
                          "span",
                          {
                            staticClass: "py-v",
                            on: {
                              click: function ($event) {
                                return _vm.goPage(row.scope.data)
                              },
                            },
                          },
                          [_vm._v("\n          查看\n        ")]
                        )
                      : _c(
                          "span",
                          {
                            staticStyle: {
                              cursor: "not-allowed",
                              color: "#8a8a8a",
                            },
                          },
                          [_vm._v("\n          查看\n        ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }