var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "num-transfer" },
    [
      [
        _c("span", { staticClass: "count", style: `color:${_vm.unitColor}` }, [
          _vm._v(_vm._s(_vm.translateData)),
        ]),
        _vm._v(" "),
        _vm.calculateResult
          ? _c("span", { staticClass: "unit" }, [
              _vm._v("亿" + _vm._s(_vm.unit)),
            ])
          : _c("span", { staticClass: "unit" }, [_vm._v(_vm._s(_vm.unit))]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }