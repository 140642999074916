<template>
  <div class="enterprise-dialog">
    <div class="item">
      <span style="width:90px">标签组名称</span>
      <el-input
        v-model="tagName"
        placeholder="长度20个汉字以内"
        maxlength="20"
      ></el-input>
    </div>
    <div class="item">
      <span style="width:90px">标签组排序</span>
      <el-input
        v-model="tagSort"
        type="number"
        placeholder="数字小的排在最上面，数字相同按创建时间倒序"
      ></el-input>
    </div>
    <div class="footer-group">
      <el-button v-if="editType" @click="handleDelete" size="mini"
        >删除</el-button
      >
      <div>
        <el-button @click="cancelBtn" size="mini">取消</el-button>
        <el-button type="primary" size="mini" @click="handleSure"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { isObjNull } from '@/utils/validate'
export default {
  name: 'EnterpriseDialog',
  data() {
    return {
      tagName: '',
      tagSort: ''
    }
  },
  computed: {
    editType() {
      return this.$store.state.plugin.modal.options.type === 'edit'
    }
  },
  created() {
    const data = this.$store.state.plugin.modal.options
    if (data.type === 'edit') {
      this.tagName = data?.name
      this.tagSort = data?.sort
    }

    console.log(this.$store.state.plugin.modal.options, 'update123')
  },
  methods: {
    cancelBtn() {
      this.$store.dispatch('hideModal')
    },
    handleSure() {
      if (!isObjNull(this.tagName)) {
        this.$message.error('标签组名称必填')
        return
      }
      this.$store.commit('update', {
        target: this.$store.state.plugin.modal.options,
        data: {
          tagName: this.tagName,
          tagSort: this.tagSort || 0,
          refresh: true
        }
      })

      this.cancelBtn()
    },
    handleDelete() {
      this.$store.dispatch('showMessageBox', {
        title: '提示',
        text: '删除后，该标签分组下的标签也将被删除',
        confirm: '确认',
        cancel: '取消',
        onClose: res => {
          if (res === 'confirm') {
            this.$bus.$emit('handleTagGroupDelete')
            // this.closeShowWaihuTip()
            // this.callUp(phone, contact, companyName)
          }
        },
        type: 'warning'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.enterprise-dialog {
  padding: 20px;
  .item {
    display: flex;
    align-items: center;
    ::v-deep .el-input {
      width: 400px;
      margin-left: 20px;
    }
    &:first-child {
      margin-bottom: 20px;
    }
  }
  .footer-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}
</style>
