import Vue from 'vue'
import cookie from 'js-cookie'
import { closeWebsocket } from '@/utils/websocket'

const state = {
  // 权限列表
  permission: [],
  // 保存请求拿到的menu
  menuList: [],
  allMenuList: [],
  // panel列表
  panelList: [],
  // 是否显示无权限
  forbidden: {
    state: false,
    condition: undefined,
    permission: undefined
  },
  customType: 1, //定义权限类型
  token: null,
  isLogin: false,
  overViewList: {},
  menuType: 'main-system',
  warp: false,
  menuOpen: true,
  gyList: [],
  syList: [],
  syCjList: [],
  messageNum: JSON.parse(localStorage.getItem('messagePush') || '{}'),
  fromPath: '',
  moduleList: [],
  isRefreshAuth: true
}

const mutations = {
  update: (state, { target, data }) => {
    for (let name in data) {
      Vue.set(target, name, data[name])
    }
  },

  // 更新permission
  updatePermission: (state, data) => {
    state.permission = data
  },

  saveOverViewList: (state, data) => {
    state.overViewList = data
  },

  // 设置token
  setToken: (state, token) => {
    token = token === 'null' ? undefined : token
    token = token === 'undefined' ? undefined : token
    state.token = token
    if (token) {
      state.isLogin = true
      cookie.set('token', token)
    } else {
      state.token = null
      state.isLogin = false
      cookie.remove('token')
    }
  },

  // 退出登录
  logout: state => {
    state.isLogin = false
    state.token = null
    localStorage.clear()
    cookie.remove('token')
    closeWebsocket()
  },

  saveMenuList: (state, data) => {
    state.menuList = data
  },

  saveAllMenuList: (state, data) => {
    state.allMenuList = data
  },

  savePanelList: (state, data) => {
    state.panelList = data
  },

  saveModuleList: (state, data) => {
    state.moduleList = data
  },

  handlerMoreData: (state, data) => {
    console.log(data, 'ggg')
    state.fromPath = data
  }
}

export default {
  state,
  mutations
}
