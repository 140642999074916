var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c("div", { ref: "toolbar", staticClass: "toolbar" }),
      _vm._v(" "),
      _c("el-scrollbar", { staticClass: "editor-scroll dt-dropdown-dt" }, [
        _c("div", { ref: "editor", staticClass: "text" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }