var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "visual-case-page",
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.handleEnterKey.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "crumb" }, [_vm._v("可视化案例")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.visualList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.handleDetail(item)
                },
              },
            },
            [
              _c("div", { staticClass: "top" }, [
                _c("div", [_vm._v(_vm._s(item.name))]),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "img-default",
                attrs: { src: item.abbreviationsImgUrl },
              }),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "PeDialog",
        {
          attrs: {
            "pe-dialog-show": _vm.show,
            "pe-full-screen": true,
            "pe-dialog-custom": true,
            "pe-dialog-class": "screen-dialog",
            "pe-dialog-title": "模板预览",
          },
          on: {
            handlePeDialogClose: function ($event) {
              _vm.show = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-wrap" },
            [
              _c("el-button", {
                staticClass: "icon-fl",
                attrs: {
                  size: "mini",
                  circle: "",
                  type: "primary",
                  plain: "",
                  icon: "el-icon-arrow-left",
                  disabled: _vm.leftDisable,
                },
                on: { click: _vm.handleLeft },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src: _vm.currentModule.imgUrl,
                  height: "100%",
                  width: "95%",
                },
              }),
              _vm._v(" "),
              _c("el-button", {
                staticClass: "icon-fr",
                attrs: {
                  size: "mini",
                  circle: "",
                  type: "primary",
                  plain: "",
                  icon: "el-icon-arrow-right",
                  disabled: _vm.rightDisable,
                },
                on: { click: _vm.handleRight },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }