<template>
  <div class="body">
    <div class="high-search-cont" v-loading="loading">
      <!--      <el-button type="primary" size="small" @click="addGroup">添加条件组</el-button>-->
      <el-switch
        v-model="chooseSave"
        style="float:right"
        active-color="#41CABF"
        inactive-color="#909399"
      ></el-switch>
      <div style="padding: 10px" v-if="selectGroup.length > 1">
        <div
          class="addgroup"
          type="primary"
          size="small"
          @click="addGroup"
        ></div>
        <span style="font-size:14px">满足下列</span>
        <div class="advanced-select">
          <el-select placeholder="选择" v-model="creatSelect">
            <el-option
              :label="item.name"
              :value="item.name"
              v-for="(item, index) in allSelect"
              :key="index + 'a'"
            ></el-option>
          </el-select>
        </div>
        <span>条件</span>
      </div>
      <div
        :class="{ 'advanced-seach': selectGroup.length > 1, advanced: true }"
        style="padding: 10px"
        v-for="(i, ind) in selectGroup"
        :key="ind"
      >
        <div
          class="delgroup"
          type="primary"
          size="small"
          @click="delGroup(ind)"
          v-if="selectGroup.length > 1"
        ></div>
        <div
          class="addgroup"
          type="primary"
          size="small"
          @click="addGroup"
          v-if="selectGroup.length <= 1"
        ></div>
        <span style="font-size:14px">满足下列</span>
        <div class="advanced-select">
          <el-select placeholder="选择" v-model="i.creatSelect">
            <el-option
              :label="item.name"
              :value="item.name"
              v-for="(item, index) in allSelect"
              :key="index + 'a'"
            ></el-option>
          </el-select>
        </div>
        <span style="font-size:14px;">条件</span>

        <div class="advanced-cont" style="margin-top: 26px;">
          <el-table
            :data="i.selectData"
            :row-style="{ 'min-height': '100px' }"
            :show-header="false"
            style="width: auto"
          >
            <el-table-column width="150" style="border-color:#fff;">
              <template slot-scope="scope">
                <el-select
                  size="mini"
                  v-model="i.selectData[scope.$index].changeTitle"
                  value-key="filterColumn"
                  placeholder="请选择"
                  @change="getChangeTitle(scope.$index, ind)"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in onetitle"
                    :key="item.filterColumn"
                    :label="item.filterName"
                    :value="item"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column width="150" style="border-color:#fff;">
              <template slot-scope="scope">
                <el-select
                  size="mini"
                  v-model="i.selectData[scope.$index].changeTitle2"
                  style="width: 100%"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in i.selectData[scope.$index].twotitle"
                    :key="index + 1"
                    :value="item"
                    :item="item"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              width="250"
              class="tagType"
              style="border-color:#fff;"
            >
              <template slot-scope="scope">
                <el-select
                  size="mini"
                  style="width: 100%;"
                  class="select-search-pop"
                  v-el-select-input="setInputIndex"
                  v-model="i.selectData[scope.$index].changeTitle3"
                  :cont="scope.$index + ',' + ind"
                  filterable
                  multiple
                  allow-create
                  :popper-append-to-body="false"
                  placeholder="请输入关键词"
                  default-first-option
                >
                  <el-option label value></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="bookbuytime">
              <template slot-scope="scope" style="border-color:#fff;">
                <svg-icon
                  icon-class="delete"
                  class="filtrate"
                  @click="handleDelete(scope.$index, ind)"
                />
              </template>
            </el-table-column>
          </el-table>
          <div
            class="add_tag_icon"
            @click="addLine(ind)"
            v-if="i.selectData.length <= 9"
          ></div>

          <!--                            <el-button @click="addLine">添加</el-button>-->
          <!--          <div class="search-botton" style="margin-left:10px;" >查询</div>-->
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancel">取消</el-button>
      <el-button type="primary" size="small" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
import { collection_comment } from '@/api/thread'
import { getKPOption, postSocialKP, getSocialKP } from '@/api/user'

export default {
  directives: {
    elSelectInput: {
      bind: function(el, binding) {
        let el1 = null
        if (el.tagName.toLowerCase() !== 'input') {
          el1 = el.getElementsByTagName('input')[0]
          let el2 = el.attributes.cont.nodeValue
          el1.setAttribute('classname', el2)
        }
        el1.addEventListener('blur', function() {
          let a = el1.getAttribute('classname')
          binding.value(a, el1.value)
        })
      }
    }
  },
  name: 'addRemark',
  data() {
    return {
      allSelect: [
        {
          id: 1,
          name: '所有'
        },
        {
          id: 2,
          name: '任一'
        }
      ],
      selectGroup: [
        {
          creatSelect: '所有', // 选择条件
          selectData: [
            {
              changeTitle: {},
              twotitle: [],
              changeTitle2: '',
              changeTitle3: []
            }
          ]
        }
      ],
      creatSelect: '所有',
      onetitle: [],
      chooseSave: false,
      loading: false
    }
  },
  mounted() {
    // const modal = this.$store.state.plugin.modal.options
    // this.isUpdate = modal.isUpdate
    // this.companyUuid = modal.companyUuid
    // if(this.isUpdate){
    //   this.form.remark = modal.remark;
    //   this.oldRemark = modal.remark;
    // }
    this.getKPOption()
    this.getSocialKP()
  },
  methods: {
    addGroup() {
      this.selectGroup.push({
        creatSelect: '所有', // 选择条件
        selectData: [
          {
            changeTitle: {},
            twotitle: [],
            changeTitle2: '',
            changeTitle3: []
          }
        ]
      })
    },
    delGroup(i) {
      this.selectGroup.splice(i, 1)
    },
    handleDelete(index, i) {
      this.selectGroup[i].selectData.splice(index, 1)
    },
    getKPOption() {
      getKPOption().then(res => {
        if (res.code === 60000) {
          this.onetitle = res.data
        }
      })
    },
    setInputIndex(index, value) {
      index = index.split(',')
      if (value) {
        this.selectGroup[index[1]].selectData[index[0]].changeTitle3.push(value)
      }
    },
    getChangeTitle(index, i) {
      this.selectGroup[i].selectData[index].twotitle = this.selectGroup[
        i
      ].selectData[index].changeTitle.operators
      this.selectGroup[i].selectData[index].changeTitle2 = this.selectGroup[
        i
      ].selectData[index].twotitle[0]
    },
    addLine(i) {
      var newValue = {
        changeTitle: {},
        changeTitle2: '',
        changeTitle3: [],
        twotitle: []
      }
      this.selectGroup[i].selectData.push(newValue)
    },
    cancel() {
      this.$store.dispatch('hideModal')
    },

    submit() {
      let arr = []
      for (let i in this.selectGroup) {
        let obj = {}
        let dataarr = []
        for (let j in this.selectGroup[i].selectData) {
          dataarr.push({
            filterColumn: this.selectGroup[i].selectData[j].changeTitle
              .filterColumn,
            operators: this.selectGroup[i].selectData[j].changeTitle2,
            optional: this.selectGroup[i].selectData[j].changeTitle3,
            filterObj: this.selectGroup[i].selectData[j].changeTitle
          })
        }
        obj.outerCondition = this.selectGroup[i].creatSelect
        obj.conditionJson = dataarr
        arr.push(obj)
      }
      const params = {
        outerCondition: this.creatSelect,
        conditionJson: JSON.stringify(arr),
        open: this.chooseSave
      }
      postSocialKP(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.$store.commit('update', {
            target: this.$store.state.plugin.modal.options,
            data: {
              refresh: true
            }
          })
          this.$store.dispatch('hideModal')
        }
      })
    },
    getSocialKP() {
      this.loading = true
      getSocialKP().then(res => {
        this.loading = false
        if (res.code === 60000 && res.data) {
          console.log(res.data)
          let condition = JSON.parse(res.data.conditionJson)
          this.chooseSave = res.data.switchTag == 'on' ? true : false
          this.creatSelect = res.data.outerCondition
          this.selectGroup = []
          if (!condition.length) {
            this.selectGroup = [
              {
                creatSelect: '所有',
                selectData: [
                  {
                    changeTitle: {},
                    changeTitle2: '',
                    changeTitle3: [],
                    twotitle: []
                  }
                ]
              }
            ]
          }
          for (let i in condition) {
            let obj = {}
            let arr = []
            for (let j in condition[i].conditionJson) {
              let objdata = {}
              objdata.changeTitle = condition[i].conditionJson[j].filterObj
              objdata.twotitle =
                condition[i].conditionJson[j].filterObj.operators
              objdata.changeTitle2 = condition[i].conditionJson[j].operators
              objdata.changeTitle3 = condition[i].conditionJson[j].optional
              arr.push(objdata)
            }
            obj.selectData = arr
            obj.creatSelect = condition[i].outerCondition
            this.selectGroup.push(obj)
          }
        }
      })
    },
    // 新建备注
    createRemark() {
      const params = {
        companyUuid: this.companyUuid,
        comment: this.form.remark
      }
      collection_comment(params).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
          this.$store.commit('update', {
            target: this.$store.state.plugin.modal.options,
            data: {
              refresh: true
            }
          })
          this.$store.dispatch('hideModal')
        }
      })
    }
  }
}
</script>

<style scoped>
.body {
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
}
.footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: auto;
}
.select-search-pop ::v-deep .el-select-dropdown__wrap {
  display: none;
}

.select-search-pop ::v-deep .el-select__caret {
  display: none;
}
.advanced-select {
  display: inline;
  margin: 0 10px;
}

.advanced-select ::v-deep .el-select {
  width: 100px;
  height: 30px;
}

.advanced-select ::v-deep .el-select > .el-input {
  height: 30px;
}

.advanced-select ::v-deep .el-input--suffix .el-input__inner {
  height: 30px;
}

.advanced-select ::v-deep .el-input__icon {
  line-height: 30px;
}
.add_tag_icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/add_tag.png') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 20px;
}
.delgroup {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/del-group.svg') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  position: relative;
  top: 5px;
  left: 0;
}
.addgroup {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/add-group.svg') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  position: relative;
  top: 5px;
  left: 0;
}
.advanced-cont ::v-deep .el-table td {
  border: none !important;
}
.advanced-cont ::v-deep .el-table::before {
  border: none !important;
  height: 0px;
}
.advanced-seach {
  margin-left: 50px;
}
</style>
