var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "touch-cont-mang p-b-38" }, [
      _c("div", { staticClass: "touch-tab-cont" }, [
        _c(
          "div",
          {
            class:
              !_vm.otherParams.isReceive && _vm.otherParams.shade
                ? "module-cont-filter"
                : "",
          },
          [
            _c(
              "div",
              { staticClass: "showPhone" },
              [
                _vm.cardType === "phone"
                  ? _c(
                      "el-checkbox",
                      {
                        staticStyle: { "margin-right": "10px" },
                        on: { change: _vm.changeShowPhone },
                        model: {
                          value: _vm.showPhone,
                          callback: function ($$v) {
                            _vm.showPhone = $$v
                          },
                          expression: "showPhone",
                        },
                      },
                      [_vm._v("\n            只显示手机号\n          ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardType === "contact"
                  ? _c(
                      "el-checkbox",
                      {
                        staticStyle: { "margin-right": "10px" },
                        on: { change: _vm.changeShowKP },
                        model: {
                          value: _vm.showKP,
                          callback: function ($$v) {
                            _vm.showKP = $$v
                          },
                          expression: "showKP",
                        },
                      },
                      [_vm._v("\n            KP\n          ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardType === "contact"
                  ? _c(
                      "el-checkbox",
                      {
                        staticStyle: { "margin-right": "10px" },
                        on: { change: _vm.changeShowSale },
                        model: {
                          value: _vm.showSale,
                          callback: function ($$v) {
                            _vm.showSale = $$v
                          },
                          expression: "showSale",
                        },
                      },
                      [_vm._v("\n            销售岗\n          ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardType === "contact"
                  ? _c(
                      "el-checkbox",
                      {
                        staticStyle: { "margin-right": "10px" },
                        on: { change: _vm.changeShowDev },
                        model: {
                          value: _vm.showDev,
                          callback: function ($$v) {
                            _vm.showDev = $$v
                          },
                          expression: "showDev",
                        },
                      },
                      [_vm._v("\n            研发岗\n          ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardType === "contact"
                  ? _c(
                      "el-checkbox",
                      {
                        staticStyle: { "margin-right": "10px" },
                        on: { change: _vm.changeShowAdmin },
                        model: {
                          value: _vm.showAdmin,
                          callback: function ($$v) {
                            _vm.showAdmin = $$v
                          },
                          expression: "showAdmin",
                        },
                      },
                      [_vm._v("\n            管理岗\n          ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardType === "contact"
                  ? _c(
                      "el-checkbox",
                      {
                        staticStyle: { "margin-right": "40px" },
                        on: { change: _vm.changeShowElse },
                        model: {
                          value: _vm.showElse,
                          callback: function ($$v) {
                            _vm.showElse = $$v
                          },
                          expression: "showElse",
                        },
                      },
                      [_vm._v("\n            其他\n          ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cardType === "contact"
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.setKPWord },
                      },
                      [_vm._v("设置KP关键词")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                class:
                  !_vm.otherParams.isReceive && _vm.otherParams.shade
                    ? "module-cont-filter"
                    : "",
                on: { change: _vm.handleClick },
                model: {
                  value: _vm.chooseComId,
                  callback: function ($$v) {
                    _vm.chooseComId = $$v
                  },
                  expression: "chooseComId",
                },
              },
              [
                _c(
                  "el-carousel",
                  {
                    ref: "carousel",
                    class: { arrow: false },
                    attrs: { "indicator-position": "outside", autoplay: false },
                  },
                  _vm._l(
                    _vm.tableArr ? Math.ceil(_vm.tableArr.length / 8) : 1,
                    function (item1, index1) {
                      return _c(
                        "el-carousel-item",
                        { key: index1 },
                        [
                          _vm.cardType === "phone" || _vm.cardType === "email"
                            ? _c(
                                "el-scrollbar",
                                {
                                  staticClass:
                                    "touch-phone-card dt-dropdown-dt dt-dropdown-dtt",
                                },
                                _vm._l(
                                  _vm.tableArr.slice(
                                    (Number(item1) - 1) * 8,
                                    Number(item1) * 8
                                  ),
                                  function (item, index) {
                                    return _vm.changeVisiable(item)
                                      ? _c(
                                          "div",
                                          {
                                            key: index,
                                            class: {
                                              "phone-card-list": true,
                                              "phone-card-list-phone":
                                                _vm.cardType === "phone",
                                            },
                                          },
                                          [
                                            _vm.cardType === "phone"
                                              ? _c("i", {
                                                  staticClass:
                                                    "card-phone-icon",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.call(
                                                        item.phone
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card-cont-txt",
                                                staticStyle: {
                                                  "padding-top": "12px",
                                                  "box-sizing": "border-box",
                                                },
                                              },
                                              _vm._l(
                                                _vm.headerArr,
                                                function (column, ind) {
                                                  return _vm.haveAttr(
                                                    column.attr,
                                                    "title"
                                                  )
                                                    ? _c(
                                                        "span",
                                                        {
                                                          key: ind + "a",
                                                          staticClass:
                                                            "card-phone-name card-name-cont",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item[column.field]
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                              0
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card-cont-txt origin-tip",
                                              },
                                              [
                                                _vm._l(
                                                  _vm.headerArr,
                                                  function (column, ind) {
                                                    return _vm.haveAttr(
                                                      column.attr,
                                                      "phone"
                                                    ) ||
                                                      _vm.haveAttr(
                                                        column.attr,
                                                        "email"
                                                      )
                                                      ? _c(
                                                          "p",
                                                          { key: ind + "b" },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "card-phone-name",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    column.name
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    item[
                                                                      column
                                                                        .field
                                                                    ],
                                                                  placement:
                                                                    "top",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "card-f14-txt custo-phone-show",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#2E2E2E",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          column
                                                                            .field
                                                                        ]
                                                                      ) +
                                                                        "\n                          "
                                                                    ),
                                                                    item.ai_konghao
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "#41CABF",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "（空号）"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : item.ai_tingji
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "#41CABF",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "（停机）"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : item.wuxiao
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "#41CABF",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "（无效）"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : item.zhongjie
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "#41CABF",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "（疑似中介）"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : item.isNotBlank ==
                                                                        false
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "#41CABF",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "（空号）"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.headerArr,
                                                  function (column, ind) {
                                                    return _vm.haveAttr(
                                                      column.attr,
                                                      "from"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            key: ind + "c",
                                                            staticStyle: {
                                                              display:
                                                                "inline-block",
                                                              width: "100%",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "card-phone-name",
                                                                staticStyle: {
                                                                  color:
                                                                    "#8A8A8A",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    column.name
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "card-f14-txt",
                                                                staticStyle: {
                                                                  color:
                                                                    "#8A8A8A",
                                                                  "font-size":
                                                                    "12px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item[
                                                                      column
                                                                        .field
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      height: "34px",
                                                    },
                                                  },
                                                  [
                                                    item.phoneMarkList &&
                                                    item.phoneMarkList.length &&
                                                    _vm.cardType === "phone"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "card-edit-time",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  item[
                                                                    "phoneMarkList"
                                                                  ][0]
                                                                    .follows[0]
                                                                    .markDetails
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-popover",
                                                      {
                                                        attrs: {
                                                          placement: "left-end",
                                                          width: "600",
                                                        },
                                                      },
                                                      [
                                                        _vm._l(
                                                          item.phoneMarkList,
                                                          function (i, index) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "pond-cond-all",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-bottom":
                                                                          "20px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            display:
                                                                              "inline-block",
                                                                            width:
                                                                              "70px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            i.week
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            i.day
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm._l(
                                                                  i.follows,
                                                                  function (
                                                                    follow,
                                                                    ind
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          ind +
                                                                          "c",
                                                                        staticStyle:
                                                                          {
                                                                            overflow:
                                                                              "hidden",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pond-cond-left",
                                                                            class:
                                                                              ind !==
                                                                              0
                                                                                ? "pond-no-first"
                                                                                : "",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "p",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    follow.time
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pond-cond-right",
                                                                            class:
                                                                              ind !==
                                                                              0
                                                                                ? "pond-no-first"
                                                                                : "",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "p",
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      i.week ===
                                                                                      "今天"
                                                                                        ? "pond-cond-posi"
                                                                                        : "pond-cond-had",
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#999999",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            follow.markSummary
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.cardType === "phone"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "card-edit-asign",
                                                                attrs: {
                                                                  slot: "reference",
                                                                },
                                                                slot: "reference",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "共" +
                                                                    _vm._s(
                                                                      item.cnt
                                                                    ) +
                                                                    "条标记"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.cardType === "phone"
                                                      ? _c(
                                                          "el-dropdown",
                                                          {
                                                            attrs: {
                                                              placement:
                                                                "bottom-start",
                                                              trigger: "click",
                                                            },
                                                            on: {
                                                              command:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.changeAsign(
                                                                    $event,
                                                                    item
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "el-dropdown-link",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "card-phone-edit-icon",
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-dropdown-menu",
                                                              {
                                                                attrs: {
                                                                  slot: "dropdown",
                                                                },
                                                                slot: "dropdown",
                                                              },
                                                              _vm._l(
                                                                _vm.asignOption,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-dropdown-item",
                                                                    {
                                                                      attrs: {
                                                                        command:
                                                                          item.value,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.name
                                                                        ) +
                                                                          "\n                          "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                ),
                                0
                              )
                            : _vm.cardType === "contact"
                            ? _c(
                                "el-scrollbar",
                                {
                                  staticClass:
                                    "touch-phone-card dt-dropdown-dt dt-dropdown-dtt",
                                  style: {
                                    "margin-top":
                                      _vm.cardType === "contact" ? "20px" : "0",
                                  },
                                },
                                _vm._l(
                                  _vm.filterTableArr(item1),
                                  function (item, index) {
                                    return _vm.changeVisiable1(item)
                                      ? _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "phone-card-list",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "cvatar" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      float: "left",
                                                      "border-radius": "50%",
                                                      overflow: "hidden",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: item.maiMaiAvatar,
                                                        width: "30",
                                                        height: "30",
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-badge",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      value: item.kp
                                                        ? "KP"
                                                        : "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "auto",
                                                          "line-height": "30px",
                                                          "text-indent": "2em",
                                                          float: "left",
                                                          color: "#2e2e2e",
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.maiMaiPersonName
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  overflow: "hidden",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "padding-right": "6px",
                                                      height: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "cont-adr-touch",
                                                      },
                                                      [
                                                        _vm._l(
                                                          _vm.headerArr,
                                                          function (
                                                            column,
                                                            ind
                                                          ) {
                                                            return _vm.haveAttr(
                                                              column.field,
                                                              "maimaiTeam"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    key:
                                                                      ind + "g",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline-block",
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "card-phone-name",
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#8A8A8A",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            column.name
                                                                          ) +
                                                                            ":"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "card-f14-txt",
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#8A8A8A",
                                                                            "font-size":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item[
                                                                              column
                                                                                .field
                                                                            ]
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.headerArr,
                                                          function (
                                                            column,
                                                            ind
                                                          ) {
                                                            return _vm.haveAttr(
                                                              column.field,
                                                              "maiMaiDept"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    key:
                                                                      ind + "g",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline-block",
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "card-phone-name",
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#8A8A8A",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            column.name
                                                                          ) +
                                                                            ":"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "card-f14-txt",
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#8A8A8A",
                                                                            "font-size":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item[
                                                                              column
                                                                                .field
                                                                            ]
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.headerArr,
                                                          function (
                                                            column,
                                                            ind
                                                          ) {
                                                            return _vm.haveAttr(
                                                              column.field,
                                                              "maiMaiPosition"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    key:
                                                                      ind + "g",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline-block",
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "card-phone-name",
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#8A8A8A",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            column.name
                                                                          ) +
                                                                            ":"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "card-f14-txt",
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#8A8A8A",
                                                                            "font-size":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item[
                                                                              column
                                                                                .field
                                                                            ]
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                ),
                                0
                              )
                            : _vm.cardType === "address"
                            ? _c(
                                "el-scrollbar",
                                {
                                  staticClass:
                                    "touch-phone-card dt-dropdown-dt dt-dropdown-dtt",
                                },
                                _vm._l(
                                  _vm.tableArr.slice(
                                    (Number(item1) - 1) * 8,
                                    Number(item1) * 8
                                  ),
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "phone-card-list",
                                        staticStyle: {
                                          "padding-top": "20px",
                                          width: "270px",
                                          "padding-right": "6px",
                                          "margin-top": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              overflow: "hidden",
                                              height: "100%",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "padding-right": "6px",
                                                  height: "100%",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "cont-adr-touch",
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.headerArr,
                                                      function (column, ind) {
                                                        return _vm.haveAttr(
                                                          column.attr,
                                                          "contact"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                key: ind + "e",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "card-phone-name",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#8A8A8A",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        column.name
                                                                      ) + ":"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "card-f14-txt",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#8A8A8A",
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          column
                                                                            .field
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      _vm.headerArr,
                                                      function (column, ind) {
                                                        return _vm.haveAttr(
                                                          column.attr,
                                                          "address"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                key: ind + "d",
                                                                staticStyle: {
                                                                  display:
                                                                    "block",
                                                                  "line-height":
                                                                    "20px",
                                                                  height:
                                                                    "auto",
                                                                  "font-size":
                                                                    "12px",
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      column.name
                                                                    ) +
                                                                      "：" +
                                                                      _vm._s(
                                                                        item[
                                                                          column
                                                                            .field
                                                                        ]
                                                                      )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      _vm.headerArr,
                                                      function (column, ind) {
                                                        return _vm.haveAttr(
                                                          column.attr,
                                                          "from"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                key: ind + "e",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "card-phone-name",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#8A8A8A",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        column.name
                                                                      ) + ":"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "card-f14-txt",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#8A8A8A",
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          column
                                                                            .field
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.otherParams.isReceive && _vm.otherParams.shade
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.getLoading,
                    expression: "getLoading",
                  },
                ],
                staticClass: "touch-no-receive",
              },
              [
                _c("div", { staticClass: "touch-no-cont" }, [
                  _c("div", { staticClass: "touch-no-icon" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#008CD9",
                          "text-decoration": "underline",
                          cursor: "pointer",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getReveiveKey("phone")
                          },
                        },
                      },
                      [_vm._v("领取")]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(" 以查看所有信息")]),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }