var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qy-financing-page" },
    [
      _c("pe-finance", {
        attrs: {
          tableList: _vm.investList,
          investProps: _vm.investProps,
          peLoading: _vm.investLoading,
          isMore: _vm.isMore,
          currentPage: "hxSchema",
        },
        on: {
          handleMoreEvent: _vm.handleMoreEvent,
          handleGroup: _vm.handleGroup,
          handleCompanyDetail: _vm.handleCompanyDetail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }