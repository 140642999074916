var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showModule
    ? _c(
        "div",
        { staticClass: "no-tab-cont dt-module-detail" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.moduleParam.name)),
          ]),
          _vm._v(" "),
          _c(_vm.noTabCont, {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.refreshLoading,
                expression: "refreshLoading",
              },
            ],
            ref: "computedComPic",
            tag: "component",
            attrs: {
              id: "computedComPic",
              isScroll: _vm.isScroll,
              otherParams: _vm.otherParams,
              cardType: _vm.cardType,
              detail: _vm.detail,
              moduleDetail: _vm.moduleParam,
            },
            on: { getComDetailModel: _vm.getComDetailModel },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }