<template>
  <div class="index-main">
    <div class="market-index">
      <div class="tab">
        <el-tabs v-model="marketType" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.value" v-for="item in marketTypeList" :key="item.value"/>
        </el-tabs>
      </div>
      <div class="raw-main" v-loading="loading" v-if="Object.keys(this.marketList).length">
        <DtScroll margin="20">
          <div class="item" v-for="(item, name) in marketList">
            <div class="item-name">
              {{ name }}
            </div>
            <div class="item-list" v-if="item && item.length">
              <div class="item-list-item" v-for="packageItem in item">
                <div class="package-item">
                  <div class="icon-name">
                    <div class="icon">
                      <el-image
                        style="width: 35px; height: 35px"
                        :src=" packageItem.image ? packageItem.image : require('@/assets/pack-mark.png') "
                        fit="contain"></el-image>
                    </div>
                    <div class="name">
                      {{packageItem.name}}
                    </div>
                    <div class="btn">
                      <span class="btn-active" style="margin-right: 5px" @click="viewDemo(packageItem)">预览</span>
                      <template v-if="packageItem.bookingStatus === 0">
                        <span class="btn-active" @click="subscribe(packageItem)">订阅</span>
                      </template>
                      <template v-if="packageItem.bookingStatus === 1">
                        <span class="btn-no-active">申请中</span>
                      </template>
                      <template v-if="packageItem.bookingStatus === 2">
                        <span class="btn-no-active">已订阅</span>
                      </template>
                    </div>
                  </div>
                  <div class="desc" v-html="packageItem.description"></div>
                </div>
              </div>
            </div>
            <div v-else class="empty-package">
              暂无数据
            </div>
          </div>
        </DtScroll>
      </div>
      <div class="raw-main empty-main" v-else>
        {{ loading ? '' : '暂无数据' }}
      </div>
    </div>
  </div>
</template>

<script>
import { marketTab, marketList, marketBook } from '@/api/thread'
import DtScroll from '@/components/element/DtScroll'
export default {
  name: 'index',
  components: {
    DtScroll
  },
  data () {
    return {
      marketType: '',
      marketTypeList: [],
      marketList: {},
      loading: false
    }
  },
  mounted () {
    let cache = sessionStorage.getItem('marketCache')
    if(cache){
      cache = JSON.parse(cache)
      this.marketType = cache.tabValue
      this.marketTab(false)
    }else {
      this.marketTab()
    }
  },
  computed: {},
  methods: {
    marketTab(setDefault = true){
      marketTab().then(res => {
        if(res.code === 60000 && res.data){
          this.marketTypeList = res.data
          if(res.data.length){
            if(setDefault){
              this.marketType = res.data[0].value
            }
            this.handleClick({name: this.marketType})
          }
        }
      })
    },

    handleClick(val){
      this.marketList = {}
      this.$nextTick(() => {
        this.marketType = val.name
        this.getMarketList()
      })
    },

    getMarketList(){
      this.loading = true
      const params = { tabValue: this.marketType }
      marketList(params).then(res => {
        this.loading = false
        if(res.code === 60000 && res.data){
          this.setCache(params)
          this.marketList = res.data
        }
      })
    },

    setCache(params){
      sessionStorage.setItem('marketCache', JSON.stringify(params))
    },

    subscribe(params){
      marketBook({ packageTable: params.packageTable }).then(res => {
        if(res.code === 60000){
          this.$store.commit('update', {
            target: params,
            data: {
              bookingStatus: 1
            }
          })
          this.$store.dispatch('showMessageBox', {
            title: '提示',
            text: '订阅申请已提交，工作人员会尽快与您取得联系',
            type: 'success',
            cancel: ''
          })
        }
      })
    },

    viewDemo(params){
      const { name, packageId } = params
      this.$router.push({
        path: '/preview/list',
        query: {
          name,
          packageId
        }
      })
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.index-main{
  height: 100%;
}
.market-index{
  height: 100%;
  background-color: #fff;
}
.tab{
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  height: 54px;
  width: 100%;
}
.tab:before{
  content: '';
  display: inline-block;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.03);
}
.tab /deep/ .el-tabs__nav-wrap::after{
  display: none;
}
.raw-main{
  height: calc( 100% - 54px );
  overflow-y: auto;
  width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  background-color: #fff;
}

.item-name{
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 15px;
}
.item-list{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px 0;
  box-sizing: border-box;
}
.item-list-item{
  margin: 0 20px 20px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  cursor: default;
}
.item-list-item:hover{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.empty-package{
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: #aaaaaa;
}
.icon-name{
  height: 50px;
  display: flex;
  /*justify-content: flex-start;*/
  font-size: 14px;
  color: #8c8c8c;
}
.icon{
  display: flex;
  align-items: center;
  padding-right: 5px;
  box-sizing: border-box;
}
.name{
  flex: 1;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 12px;
}
.desc{
  font-size: 12px;
  color: #8c8c8c;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
  padding-left: 5px;
  box-sizing: border-box;
}
.package-item{
  width: 400px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 15px 5px 10px;
  box-sizing: border-box;
  border-radius: 3px;
}
.btn{
  display: flex;
  align-items: center;
}
.icon-tag{
  color: #FFFFFF!important;
}
.empty-main{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #aaaaaa;
}
.btn-no-active{
  display: inline-block;
  width: 50px;
  background-color: #8A8A8A;
  color: #FFFFFF;
  text-align: center;
  padding: 5px 0;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
}
.btn-active{
  cursor: pointer;
  display: inline-block;
  width: 50px;
  background-color: #41cabf;
  color: #FFFFFF;
  text-align: center;
  padding: 5px 0;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
}
</style>
