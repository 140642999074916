/*
 * @Author: Shy07
 * @Date: 2019-06-27 10:35:29
 * @Last Modified by: Shy07
 * @Last Modified time: 2019-06-28 09:26:17
 */
import store from '@/store'
import { API, BASE_REQUEST } from './api_service'
import { ResetAuthUserMeal, ResetSystemConfig } from '@/utils/auth'
import {
  setToken,
  removeToken,
  setRefreshToken,
  getRefreshToken
} from '@/utils/auth'
import { AppWebSockt } from '@/utils/websocket'
import wechartReLogin from '@/views/login/dialog/wechartReLogin'
import getWeiCode from '@/views/login/dialog/getWeiCode'

export const checkToken = async () => {
  return true
}

const fetchRefreshToken = async () => {
  // 获取刷新token 'dtBird_refresh_token'
  const refreshToken = getRefreshToken()
  if (refreshToken) {
    const res = await BASE_REQUEST({
      api: API('REFRESH_TOKEN'),
      opt: { refreshToken }
    })
    // console.log('+=====我刷新了token')
    const token = res.data.access_token ? res.data.access_token : ''
    const expiresIn = res.data.expires_in ? res.data.expires_in : ''
    setToken({ token, expiresIn })

    const newRefreshToken = res.data.refresh_token ? res.data.refresh_token : ''
    const refreshExpiresIn = res.data.refresh_expires_in
      ? res.data.refresh_expires_in
      : ''
    setRefreshToken({ refreshToken: newRefreshToken, refreshExpiresIn })
    localStorage.setItem('userLoginTime', new Date().getTime())
    store.dispatch('changeStep', res.data.step)
    ResetAuthUserMeal()

    return true
  } else {
    removeToken()
    return false
  }
}

export const sendPcCode = async params => {
  const api = API('SEND_PC_CODE')
  return await BASE_REQUEST({ api: api, opt: params })
}

export const fetchToken = async ({
  username,
  password,
  loginType,
  code,
  success,
  failure,
  mobile = null,
  smsCode = null
}) => {
  try {
    var api = null
    var opt = null
    if (loginType && loginType == 'code') {
      api = API('CODE_PC_TOKEN')
      opt = {
        userName: username,
        smsCode: code
      }
    } else {
      api = API(mobile && smsCode ? 'FETCH_TOKEN_WITH_MOBILE' : 'FETCH_TOKEN')
      opt = {
        userName: username,
        passWord: password,
        mobile,
        smsCode
      }
    }
    const res = await BASE_REQUEST({ api, opt })
    console.log(res, 998877)
    if (res.code === 60000) {
      if (res?.data?.wechatLogin === 1) {
        if (!res?.data?.openid) {
          store.dispatch('showModal', {
            view: getWeiCode,
            size: '600px',
            title: '请使用微信扫码登录',
            options: {
              token: res?.data?.token
            }
          })
          failure()
          return
        } else {
          store.dispatch('showModal', {
            view: wechartReLogin,
            size: '600px',
            title: '请使用微信扫码登录',
            options: res?.data
          })
          failure()
          return
        }
      }
      localStorage.clear()
      sessionStorage.clear()
      const token = res.data ? res.data.token : ''
      const step = res.data ? res.data.step : ''
      const accountType = res.data ? res.data.type : ''
      store.commit('setToken', token)
      store.dispatch('changeStep', step)
      store.dispatch('setName', username)
      localStorage.setItem('setAccountType', accountType + '')
      localStorage.setItem('dtBird_user_step', step)
      localStorage.setItem('setName', username)
      localStorage.setItem('userLoginTime', new Date().getTime())

      if (step != 1) {
        ResetAuthUserMeal()
      }

      ResetSystemConfig() //获取系统信息
      AppWebSockt('kick_user_offline')
      await store.dispatch('createSocket')
      success && success(res)
      return res
    }
    failure && failure(res)
    return res
  } catch (err) {
    console.log(err)
    failure && failure(err)
  }
}

export const signOut = removeToken

export { fetchRefreshToken }
