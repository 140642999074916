var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": true,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            title: "附件预览",
            top: "25px",
            width: "95%",
            "custom-class": "layout-preview-attachment",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm.fileType === "pdf"
              ? _c("div", { staticClass: "doc-preview-pdf" })
              : _vm._e(),
            _vm._v(" "),
            _vm.showOfficeApp(_vm.fileType)
              ? _c("div", { staticClass: "doc-preview" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "layout-img-preview",
                style: { height: _vm.h + "px" },
              },
              [
                _vm.showImageApp(_vm.fileType)
                  ? _c("div", { staticClass: "i-operater" }, [
                      _c("p", { staticClass: "i-title" }, [
                        _vm._v(_vm._s(_vm.curItem.name)),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "i-img-view-wrap",
                    style: { height: _vm.h - 60 + "px", width: "100%" },
                  },
                  [
                    _vm.showImageApp(_vm.fileType)
                      ? _c("img", {
                          staticClass: "i-img-view",
                          style: {
                            width: _vm.imgW,
                            height: _vm.imgH,
                            transform: "rotateZ(" + _vm.rotateVal + "deg)",
                          },
                          attrs: { src: _vm.curItem.url },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showOfficeApp(_vm.fileType)
                      ? _c("iframe", {
                          ref: "doc",
                          attrs: {
                            src:
                              "https://view.officeapps.live.com/op/view.aspx?src=" +
                              _vm.curItem.url,
                            height: _vm.h + "px",
                            width: "100%",
                            frameborder: "1",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fileType === "pdf"
                      ? _c("div", {
                          style: { height: _vm.h + "px" },
                          attrs: { id: "example1" },
                        })
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }