var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tip-main" }, [
    _c("div", { staticClass: "tip-text" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "er-code" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }