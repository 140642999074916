var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mock-map-page" }, [
    _c("div", { staticClass: "m-map" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "ul-nav" },
        _vm._l(_vm.navLs, function (item, index) {
          return _c("li", { key: index }, [
            _c(
              "div",
              {
                staticClass: "link",
                class: { active: _vm.active == index },
                on: {
                  click: function ($event) {
                    return _vm.changeNav(index)
                  },
                },
              },
              [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
            ),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "map-footer" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item item1" }, [
      _c("div", { staticClass: "dot" }, [
        _c("img", {
          attrs: {
            src: require("@/views/charts/theme/images/dot2.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "name" }, [_vm._v("宝山")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item item2" }, [
      _c("div", { staticClass: "dot" }, [
        _c("img", {
          attrs: {
            src: require("@/views/charts/theme/images/dot1.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "name" }, [_vm._v("中心城区")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item item3" }, [
      _c("div", { staticClass: "dot" }, [
        _c("img", {
          attrs: {
            src: require("@/views/charts/theme/images/dot1.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "name", staticStyle: { display: "none" } }, [
        _vm._v("徐汇"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "map-pop", staticStyle: { display: "block" } }, [
        _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "tit" }, [_vm._v("徐汇")]),
          _vm._v(" "),
          _c("div", { staticClass: "line" }, [
            _c("div", { staticClass: "s" }, [_vm._v("跨国公司研发中心：")]),
            _vm._v(" "),
            _c("div", { staticClass: "rr" }, [
              _c("span", { staticClass: "num" }, [_vm._v("23")]),
              _vm._v("家"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "line" }, [
            _c("div", { staticClass: "s" }, [_vm._v("国家级企业技术中心：")]),
            _vm._v(" "),
            _c("div", { staticClass: "rr" }, [
              _c("span", { staticClass: "num" }, [_vm._v("8")]),
              _vm._v("家"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "line" }, [
            _c("div", { staticClass: "s" }, [_vm._v("市级企业技术中心：")]),
            _vm._v(" "),
            _c("div", { staticClass: "rr" }, [
              _c("span", { staticClass: "num" }, [_vm._v("35")]),
              _vm._v("家"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "line" }, [
            _c("div", { staticClass: "s" }, [_vm._v("高新技术企业：")]),
            _vm._v(" "),
            _c("div", { staticClass: "rr" }, [
              _c("span", { staticClass: "num" }, [_vm._v("785")]),
              _vm._v("家"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item item4" }, [
      _c("div", { staticClass: "dot" }, [
        _c("img", {
          attrs: {
            src: require("@/views/charts/theme/images/dot1.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "name left" }, [_vm._v("闵行")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item item5" }, [
      _c("div", { staticClass: "dot" }, [
        _c("img", {
          attrs: {
            src: require("@/views/charts/theme/images/dot2.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "name" }, [_vm._v("松江")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item item6" }, [
      _c("div", { staticClass: "dot" }, [
        _c("img", {
          attrs: {
            src: require("@/views/charts/theme/images/dot2.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "name left" }, [_vm._v("浦东")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }