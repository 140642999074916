var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-market" },
    [
      _vm.isBlur
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.getLoading,
                    expression: "getLoading",
                  },
                ],
                staticClass: "touch-no-receive",
              },
              [
                _c("div", { staticClass: "touch-no-cont" }, [
                  _c("div", { staticClass: "touch-no-icon" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#008CD9",
                          "text-decoration": "underline",
                          cursor: "pointer",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getReceiveData("phone")
                          },
                        },
                      },
                      [_vm._v("领取")]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("以查看联系方式")]),
                  ]),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-wrapper", style: _vm.blurObj },
        [
          _c(
            "div",
            { staticClass: "apply-list" },
            [
              _vm._l(_vm.tabList, function (item, index) {
                return [
                  _c(
                    "div",
                    {
                      class:
                        _vm.currentIndex === index ? "active item" : "item",
                      on: {
                        click: function ($event) {
                          return _vm.handleTabClick(index)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(" " + _vm._s(item.name))]),
                      _vm._v(" "),
                      item.count
                        ? _c("span", [_vm._v("(" + _vm._s(item.count) + ")")])
                        : _vm._e(),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.load,
                  expression: "load",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, stripe: "", border: "" },
              on: { "sort-change": _vm.sortChange },
            },
            [
              _vm._l(_vm.ColumnList, function (item) {
                return [
                  _c("el-table-column", {
                    attrs: {
                      width: item.field === "appLogo" ? "240px" : "auto",
                      label: item.name,
                      sortable: item.sort,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              item.field === "appLogo"
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "apply-wrapper" },
                                      [
                                        _c("img", {
                                          staticClass: "logoImg",
                                          attrs: { src: scope.row.appLogo },
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "right" }, [
                                          _c("div", { staticClass: "layer" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "app-link",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleOpenDialog(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(scope.row.appName)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-classify" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "app-classify" },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.appType)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              scope.row.storeUrl
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "icon-btn",
                                                      attrs: { size: "mini" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleOpenStore(
                                                            scope.row.storeUrl
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("IconHover", {
                                                        attrs: {
                                                          iconClass:
                                                            "app-share",
                                                          hoverMessage:
                                                            "APP发布网站",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : [
                                    item.field === "appStore"
                                      ? _c("div", [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatCompany(
                                                  scope.row["appStore"]
                                                )[0]
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatCompany(
                                                  scope.row["appStore"]
                                                )[1]
                                              )
                                            ),
                                          ]),
                                        ])
                                      : item.field === "totalDownloadNum"
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.formatCount(
                                                  scope.row["totalDownloadNum"]
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ])
                                      : item.field === "comments"
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.formatCount(
                                                  scope.row["comments"]
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " " + _vm._s(scope.row[item.field])
                                          ),
                                        ]),
                                  ],
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-footer" },
            [
              _c("FooterPage", {
                attrs: { pageSizes: [10, 20, 50], pageStatus: _vm.pageStatus },
                on: {
                  handleCurrentChange: _vm.handleCurrentChange,
                  changePageSize: _vm.goNumPage,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Dialog",
            {
              attrs: {
                PeDialogTitle: "APP详情",
                PeDialogShow: _vm.PeDialogShow,
                PeDialogBtnCancel: "关闭",
                PeDialogShowSure: false,
              },
              on: { handlePeDialogClose: _vm.handlePeDialogClose },
            },
            [
              _c("div", { staticClass: "app-content" }, [
                _c("img", {
                  staticClass: "appLogo",
                  attrs: { src: _vm.appDetail.appLogo },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "detail" }, [
                  _c(
                    "div",
                    { staticClass: "top" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.appDetail.appName)),
                      ]),
                      _vm._v(" "),
                      _vm.appDetail.storeUrl
                        ? _c(
                            "el-button",
                            {
                              staticClass: "icon-btn",
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleOpenStore(
                                    _vm.appDetail.storeUrl
                                  )
                                },
                              },
                            },
                            [
                              _c("IconHover", {
                                attrs: {
                                  iconClass: "app-share",
                                  hoverMessage: "APP发布网站",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "introduce" }, [
                    _vm._v(_vm._s(_vm.appDetail.appIntroduction)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list" }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("APP ID")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.bundleId)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("分类")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.appType)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("应用市场")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.appStore)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("价格")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.price)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("开发者")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.developer)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [
                    _vm._v("开发者所属企业"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.companyName)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("发布地区")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.publishArea)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("综合评分")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.appScore)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [
                    _vm._v("评分数/评论数"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.comments)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("下载量")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.totalDownloadNum)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [
                    _vm._v("发布/上架日期"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.lastReleaseDate)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [
                    _vm._v("最后版本更新"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.versionUpdatedTime)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("大小")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-fr" }, [
                    _vm._v(_vm._s(_vm.appDetail.appSize)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("包名")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item-fr" },
                    [
                      _c("HoverMsg", [
                        _vm._v(" " + _vm._s(_vm.appDetail.appPackage)),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("SDK")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item-fr" },
                    [_c("HoverMsg", [_vm._v(" " + _vm._s(_vm.appDetail.sdk))])],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "item-fl" }, [_vm._v("SDK服务商")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item-fr" },
                    [
                      _c("HoverMsg", [
                        _vm._v(" " + _vm._s(_vm.appDetail.sdkProvider)),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }