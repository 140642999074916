var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "software-list-main" }, [
    _c(
      "div",
      { staticClass: "search" },
      [
        _c("nurture-btn", {
          attrs: { selectionList: _vm.selectionList },
          on: { getListData: _vm.getList },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("el-input", {
              attrs: {
                clearable: "",
                size: "small",
                placeholder: "请输入公司名称查询",
              },
              model: {
                value: _vm.word,
                callback: function ($$v) {
                  _vm.word = $$v
                },
                expression: "word",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.getList },
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "small" },
                on: { click: _vm.handleSoftwareReset },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "table list-table-app",
        style: { minHeight: `${_vm.minHeight - 100}px` },
      },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "table",
            attrs: { border: "", data: _vm.list, size: "small" },
            on: {
              "sort-change": _vm.sortChange,
              "selection-change": _vm.handleSelectionChange,
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _vm._v(" "),
            _vm._l(_vm.columns, function (item, index) {
              return _c(
                "el-table-column",
                {
                  key: index,
                  attrs: {
                    align: item.name === "公司名称" ? "left" : "center",
                    sortable: item.hasOrder === 0 ? false : "customer",
                    "show-overflow-tooltip": _vm.computedOverflow(item),
                    width: _vm.computerWidth(item),
                    label: item.name,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.name === "公司名称"
                              ? _c(
                                  "span",
                                  { staticClass: "c-name" },
                                  [
                                    _c("Star", {
                                      attrs: {
                                        data: scope.row.sub,
                                        selectObj: scope.row,
                                      },
                                      on: { getListData: _vm.getList },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.goKeyDetail(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(scope.row[item.field])
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    scope.row.newTag === 1
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#f00" } },
                                          [_vm._v("新增")]
                                        )
                                      : scope.row.updateTag === 1
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "orange",
                                              "font-size": "10px",
                                            },
                                          },
                                          [_vm._v("更新")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : item.field === "qcc_vc_amount"
                              ? _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row[item.field] === 0
                                          ? "未披露"
                                          : scope.row[item.field]
                                      ) +
                                      "\n          "
                                  ),
                                ])
                              : item.field === "tags"
                              ? _c(
                                  "span",
                                  [
                                    _c("Tags", {
                                      attrs: { tagList: scope.row.tags },
                                    }),
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row[item.field])),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c("template", { slot: "header" }, [
                    item.name === "活跃度"
                      ? _c(
                          "span",
                          [
                            _c("draw-down-menu", {
                              attrs: { drawList: item.filterOptionList },
                              on: { handleCommand: _vm.handleCommand },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                2
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "t-footer page-div page-footer-page page" },
      [
        _c("footer-page", {
          attrs: { loading: _vm.loading, pageStatus: _vm.pageObj },
          on: {
            handleCurrentChange: _vm.handleCurrentChange,
            goNumPage: _vm.goNumPage,
            changePageSize: _vm.changePageSize,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }