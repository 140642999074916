var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dt-button-main", on: { click: _vm.click } },
    [
      _c(
        "el-button",
        {
          key: _vm.key,
          attrs: {
            type: _vm.type,
            size: _vm.size,
            plain: _vm.plain,
            round: _vm.round,
            circle: _vm.circle,
            loading: _vm.loading,
            disabled: _vm.disabled,
            icon: _vm.icon,
            autofocus: _vm.autofocus,
            "native-type": _vm.nativeType,
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }