var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setingWaihu-main" }, [
    _c(
      "div",
      { staticClass: "waihu-dialog-main" },
      [
        _c(
          "el-form",
          [
            _c(
              "el-form-item",
              { attrs: { label: "呼叫间隔(秒)" } },
              [
                _c("el-input-number", {
                  attrs: { min: 1, label: "设置呼叫间隔", size: "small" },
                  model: {
                    value: _vm.callInterval,
                    callback: function ($$v) {
                      _vm.callInterval = $$v
                    },
                    expression: "callInterval",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "div",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }