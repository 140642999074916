var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "createUploadTask-main" }, [
    _c(
      "div",
      {
        staticClass: "upload-main",
        style: { display: _vm.showUpload ? "flex" : "" },
      },
      [
        _c(
          "div",
          { staticClass: "upload" },
          [
            _c(
              "el-upload",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showUpload,
                    expression: "showUpload",
                  },
                ],
                ref: "upload",
                staticClass: "upload-demo",
                attrs: {
                  drag: "",
                  accept: ".xlsx",
                  headers: _vm.uploadHeaders,
                  action: _vm.uploadAction,
                  "auto-upload": false,
                  "file-list": _vm.fileList,
                  data: _vm.uploadData,
                  "on-success": _vm.uploadSuccess,
                  "on-error": _vm.uploadError,
                  limit: 1,
                  multiple: false,
                },
              },
              [
                _c("i", { staticClass: "el-icon-upload" }),
                _vm._v(" "),
                _c("div", { staticClass: "el-upload__text" }, [
                  _vm._v("将文件拖到此处，或"),
                  _c("em", [_vm._v("点击上传")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "el-upload__tip",
                    attrs: { slot: "tip" },
                    slot: "tip",
                  },
                  [_vm._v("只能上传xlsx文件")]
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showUpload,
                expression: "!showUpload",
              },
            ],
          },
          [
            _c("div", { staticClass: "div-select" }, [
              _vm._v("\n        请选择异常号码处理方式：\n      "),
            ]),
            _vm._v(" "),
            _c(
              "el-form",
              {
                staticStyle: { "padding-left": "20px" },
                attrs: {
                  "label-width": "100px",
                  size: "small",
                  "label-position": "left",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "重复" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.uploadData.filterRepeat,
                          callback: function ($$v) {
                            _vm.$set(_vm.uploadData, "filterRepeat", $$v)
                          },
                          expression: "uploadData.filterRepeat",
                        },
                      },
                      [_vm._v("过滤")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "被我打过" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.uploadData.filterMeCalled,
                          callback: function ($$v) {
                            _vm.$set(_vm.uploadData, "filterMeCalled", $$v)
                          },
                          expression: "uploadData.filterMeCalled",
                        },
                      },
                      [_vm._v("过滤")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "被他人打过" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.uploadData.filterOtherCalled,
                          callback: function ($$v) {
                            _vm.$set(_vm.uploadData, "filterOtherCalled", $$v)
                          },
                          expression: "uploadData.filterOtherCalled",
                        },
                      },
                      [_vm._v("过滤")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showUpload,
                expression: "showUpload",
              },
            ],
            staticClass: "upload-tip",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", [
              _c("a", { attrs: { download: "a", href: _vm.templateUrl } }, [
                _vm._v("xlsx文件模板"),
              ]),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "div",
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancelBtn } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v(_vm._s(this.showUpload ? "下一步" : "确定"))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span", [_vm._v("\n        下载模板\n        ")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }