<template>
  <div class="index-main">
    <div class="btn-list">
      <span>
        <span @click="$router.go(-1)">数据看板</span>
        <span>></span>
        <span>{{ text }}</span>
      </span>

      <el-button size="mini" @click="reset" type="primary">重置</el-button>
    </div>
    <div class="table" v-loading="loading">
      <el-table
        ref="table"
        class="table-dt"
        :key="tableKey"
        :data="tableData"
        @sort-change="sortChange"
        border
        stripe
        style="width: 100%; height: 100%; font-size: 12px;background-color:#fff;"
      >
        <el-table-column
          :min-width="item.width"
          :show-tooltip-when-overflow="item.field !== 'setting'"
          v-for="item in columnList"
          :prop="item.field"
          align="center"
          :sortable="item.type === 'sort' ? 'custom' : false"
          :fixed="
            item.field === 'companyName' || item.field === 'company_name'
              ? 'left'
              : item.field === 'setting'
              ? 'right'
              : false
          "
          :align="
            item.field === 'companyName' || item.field === 'company_name'
              ? 'left'
              : 'center'
          "
          :label="item.name"
        >
          <template slot="header" slot-scope="scope">
            <span v-if="item.type === 'filter'">
              <el-dropdown
                placement="bottom"
                trigger="click"
                @command="val => filterFunc(val, item)"
              >
                <template>
                  <span
                    class="el-dropdown-link"
                    :class="activeField.field === item.field ? 'active' : ''"
                  >
                    <span>{{ item.name }}</span
                    ><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-scrollbar class="dt-dropdown-dt">
                      <el-dropdown-item
                        :class="
                          (activeField.field === item.field &&
                            activeField.value === _item.value) ||
                          (activeField.alias === item.alias &&
                            activeField.value === _item.value)
                            ? 'active'
                            : ''
                        "
                        :command="_item.value"
                        v-for="_item in item.filterOption"
                        >{{ _item.key }}</el-dropdown-item
                      >
                    </el-scrollbar>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>
            <span v-else>{{ item.name }}</span>
          </template>
          <template slot-scope="scope">
            <span v-if="item.field === 'setting'" class="set">
              <set-ting
                :all-clue="allClue"
                :all-level="allLevel"
                :all-message="allMessage"
                :all-per-tags="allPerTags"
                :all-source="allSource"
                :all-status="allStatus"
                :per-all-per-tags="perAllPerTags"
                :follow-user-list="followUserList"
                :data-source="scope"
                :type="name"
                @refresh="getRunInList"
              />
            </span>
            <company-link
              v-else-if="
                item.field === 'companyName' || item.field === 'company_name'
              "
              :company-name="scope.row.companyName || scope.row.company_name"
              :company-uuid="scope.row.companyUuid || scope.row.company_uuid"
              :package-id="scope.row.packageId || scope.row.package_id"
            />
            <span
              v-else-if="
                (item.field === 'companyName' ||
                  item.field === 'company_name') &&
                  path === 'contractAmount'
              "
            >
              <span class="lock-dt">
                <i class="el-icon-lock" v-if="!scope.row.isEdit"></i>
              </span>
              <company-link
                :company-name="scope.row.companyName || scope.row.company_name"
                :company-uuid="scope.row.companyUuid || scope.row.company_uuid"
                :package-id="scope.row.packageId || scope.row.package_id"
              />
            </span>
            <span v-else-if="item.field === 'bind_wechat'">
              <el-tag
                v-if="scope.row[item.field] === '1'"
                size="small"
                type="primary"
              >
                已关联
              </el-tag>
            </span>
            <span v-else> {{ scope.row[item.field] }} </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page page-div page-1">
      <footer-page
        @handleCurrentChange="handleCurrentChange"
        @goNumPage="goNumPage"
        @changePageSize="changePageSize"
        :pageStatus="pageStatus"
      ></footer-page>
    </div>
  </div>
</template>

<script>
import FooterPage from '@/components/Footerpage/index'
import companyLink from '@/components/companyLink/companyLink'
import SetTing from '@/components/runIn/SetTing'
import { runIn } from '@/api/table'
import { getCusTagsDdic } from '@/api/customerpond'
import { contractLeaders } from '@/api/user'
export default {
  name: 'customerPondRunIn',
  components: {
    FooterPage,
    companyLink,
    SetTing
  },
  data() {
    return {
      path: '',
      text: '',
      name: '',
      sort: '',
      sortOrder: '',
      startDate: '',
      endDate: '',
      userId: '',
      tableKey: 0,
      loading: false,
      pageStatus: {
        pageNo: 1,
        pageSize: 20,
        total: null
      },
      tableData: [],
      columnList: [],
      allSource: [],
      allClue: [],
      allLevel: [],
      allMessage: [],
      allStatus: [],
      allPerTags: [],
      perAllPerTags: [],
      followUserList: [],
      activeField: {}
    }
  },
  mounted() {
    this.path = this.$route.query.path
    this.text = this.$route.query.text
    this.name = this.$route.query.name
    const params = JSON.parse(this.$route.query.params)
    this.startDate = params?.startDate || ''
    this.endDate = params?.endDate || ''
    this.userId = params?.userId || ''
    this.getRunInList()
    localStorage.setItem('runName', this.text)

    if (this.path === 'followCustomerCnt' || this.path === 'transactionCnt') {
      const list = ['1', '2', '3', '4', '5', '6', '7', '8']
      list.forEach(item => {
        this.getCusTagsDdic(item)
      })
    }

    if (this.path === 'contractAmount' || this.path === 'returnAmountSum') {
      this.getFollowUserList()
    }
  },
  methods: {
    getFollowUserList() {
      contractLeaders({ pageId: 2002 }).then(res => {
        if (res.code === 60000) {
          this.followUserList = res.data
        }
      })
    },

    getCusTagsDdic(val) {
      const params = {
        type: val
      }
      getCusTagsDdic(params).then(res => {
        if (res.code === 60000) {
          if (val === '1') {
            this.allLevel = res.data
            // this.creatLevel = 1
          }
          if (val === '2') {
            this.allSource = res.data
          }
          if (val === '3') {
            this.allStatus = res.data
          }
          if (val === '4') {
            this.allClue = res.data
          }
          if (val === '5') {
            this.allPerTags = res.data
          }
          if (val === '7') {
            this.allMessage = res.data
          }
          if (val === '8') {
            this.perAllPerTags = res.data
          }
        }
      })
    },

    sortChange(data) {
      const map = {
        ascending: 'asc',
        descending: 'desc'
      }
      data.order ? (this.sortOrder = map[data.order]) : (this.sortOrder = null)
      const alias = this.columnList.filter(i => i.field === data.prop)[0]?.alias
      this.sort = alias ? alias : data.prop
      this.getRunInList()
    },

    filterFunc(val, item) {
      this.activeField = { field: item.field, value: val, alias: item.alias }
      this.getRunInList()
    },

    reset() {
      this.sort = ''
      this.sortOrder = ''
      this.pageStatus.pageNo = 1
      this.pageStatus.pageSize = 20
      this.activeField = {}
      this.getRunInList()
    },

    getRunInList(query = {}) {
      this.loading = true
      if (this.activeField && Object.keys(this.activeField).length) {
        query = {
          [this.activeField.alias
            ? this.activeField.alias
            : this.activeField.field]: this.activeField.value
        }
      }

      const data = {
        startDate: this.startDate,
        endDate: this.endDate,
        sort: this.sort,
        sortOrder: this.sortOrder,
        pda: this.userId,
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize
      }
      const params = {
        path: this.path,
        params: Object.assign({}, data, query)
      }
      console.log(query)
      runIn(params).then(res => {
        this.loading = false
        if (res.code === 60000 && res.data) {
          this.tableData = res.data.list.map(item => {
            return Object.assign({}, item, {
              ossUrl: item.oss_url,
              companyName: item.companyName
                ? item.companyName
                : item.company_name,
              companyUuid: item.companyUuid
                ? item.companyUuid
                : item.company_uuid
            })
          })
          this.pageStatus.pageNo = res.data.page.pageNo
          this.pageStatus.total = res.data.page.total

          let add = []
          if (
            this.path !== 'remoteDemonstrateCnt' &&
            this.path !== 'visitCompanyCnt'
          ) {
            add = [{ field: 'setting', name: '操作' }]
          }
          this.columnList = [...res.data.columns, ...add].map(item => {
            if (item.type === 'filter') {
              item['filterOption'] = this.formatOption(item['filterOption'])
            }
            if (item.field === 'company_name' || item.field === 'companyName') {
              item['width'] = 200
            } else if (item.field === 'setting') {
              item['width'] = 150
            } else {
              item['width'] =
                item.name.length * 40 +
                (item.type === 'sort' || item.type === 'filter' ? 10 : 0)
            }
            return item
          })
          this.$nextTick(() => {
            this.$refs['table'].doLayout()
          })
        }
      })
    },

    formatOption(data) {
      let res = [
        {
          key: '全部',
          value: null
        }
      ]
      if (!data) return res
      Object.keys(data).forEach(i => {
        if (i !== '全部') {
          res.push({
            key: i,
            value: data[i]
          })
        }
      })
      return res
    },

    handleCurrentChange(val) {
      this.pageStatus.pageNo = val
      this.getRunInList()
    },

    goNumPage(val) {
      this.pageStatus.pageNo = val
      this.getRunInList()
    },

    changePageSize(val) {
      this.pageStatus.pageSize = val
      this.getRunInList()
    }
  }
}
</script>

<style scoped>
.index-main {
  height: 100%;
}
.btn-list {
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
.input-row {
  display: flex;
  align-items: center;
}
.btn-row {
  margin-left: 10px;
  display: flex;
}
.table {
  margin-top: 10px;
  min-height: calc(100% - 60px - 40px);
  background-color: #fff;
}
.table ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
.table ::v-deep .el-table th,
.el-table tr {
  background-color: #fff;
}
.page-1 {
  background-color: #fff;
}
.set-btn {
  cursor: pointer;
  color: #0099cc;
}
.set > span:last-child {
  display: none;
}
.el-dropdown-link {
  cursor: pointer;
  color: #909399;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.active {
  color: #41cabf !important;
}
.g-search {
  color: #41cabf;
  margin-left: 10px;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
}
.filtrate {
  margin-left: 2px;
}
.condition {
  position: relative;
  margin-left: 10px;
}
.condition /deep/ .el-input__inner {
  padding-right: 40px;
}
.condition-icon {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40px;
  z-index: 99;
  cursor: pointer;
  color: #41cabf;
  /*background-color: rosybrown;*/
}
.set-btn {
  cursor: pointer;
  color: #0099cc;
}
.set > span:last-child {
  display: none;
}
.btn-list > span {
  font-size: 13px;
  color: #8a8a8a;
}
.btn-list > span span:nth-child(2) {
  margin: 0 5px;
}
.btn-list > span span:nth-child(1) {
  color: #41cabf;
  cursor: pointer;
}
.lock-dt {
  display: inline-block;
  width: 20px;
  color: #333333;
}
</style>
