import Vue from 'vue'
import Permission from '../cores/Permission'
//删除dom后需要手动实现响应式
const isRealHide = true

// 权限指令
Vue.directive('dt-permission-and', {
  update: (dom, update) => {
    update.def.inserted(dom, update)
  },
  inserted: (dom, binding) => {
    if (!Permission.hasPermissionAnd(binding.value)) {
      dom.classList.add('dt-hide')
      isRealHide && dom.remove()
    } else {
      dom.classList.remove('dt-hide')
    }
  }
})
Vue.directive('dt-permission-or', {
  update: (dom, update) => {
    update.def.inserted(dom, update)
  },
  inserted: (dom, binding) => {
    if (!Permission.hasPermissionOr(binding.value)) {
      dom.classList.add('dt-hide')
      isRealHide && dom.remove()
    } else {
      dom.classList.remove('dt-hide')
    }
  }
})
Vue.directive('dt-permission', {
  update: (dom, update) => {
    update.def.inserted(dom, update)
  },
  inserted: (dom, binding) => {
    if (!Permission.hasPermission(binding.value)) {
      dom.classList.add('dt-hide')
      isRealHide && dom.remove()
      dom.classList.remove('dt-hide')
    }
  }
})

// 未完成
Vue.directive('dt-tip', {
  inserted: (el, binding) => {
    const position = binding.value?.position || 'bottom-right'
    const id = `tip${Math.random()}`
    const addDom = function() {
      el.setAttribute('style', 'position: relative')
      const $el = document.createElement('div')
      $el.setAttribute('class', `directive-${position}`)
      $el.setAttribute(
        'style',
        'border: 1px solid rgba(0,0,0,.1);padding: 5px;box-sizing: border-box;font-size: 12px;position: absolute;z-index: 99;'
      )
      $el.setAttribute('id', id)
      $el.innerText = binding.value.val
      el.appendChild($el)
    }
    const removeDom = function() {
      el.removeChild(document.getElementById(id))
    }
    el.addEventListener('mouseenter', addDom)
    el.addEventListener('mouseleave', removeDom)
  }
})

Vue.directive('resize', {
  bind(el, binding) {
    // el为绑定的元素，binding为绑定给指令的对象
    let width = '',
      height = ''
    function isReize() {
      const style = document.defaultView.getComputedStyle(el)
      if (width !== style.width || height !== style.height) {
        binding.value() // 关键
      }
      width = style.width
      height = style.height
    }
    el.__vueSetInterval__ = setInterval(isReize, 300)
  },
  unbind(el) {
    clearInterval(el.__vueSetInterval__)
  }
})

Vue.directive('watermark', (el, binding) => {
  let text = binding.value.text
  let font = binding.value.font || '16px Microsoft JhengHei'
  let textColor = binding.value.textColor || 'rgba(215, 215, 215, 0.2)'
  let width = binding.value.width || 400
  let height = binding.value.height || 200
  let textRotate = binding.value.textRotate || -20

  function addWaterMarker(parentNode) {
    var can = document.createElement('canvas')
    parentNode.appendChild(can)
    can.width = width
    can.height = height
    can.style.display = 'none'
    var cans = can.getContext('2d')
    cans.rotate((textRotate * Math.PI) / 180)
    cans.font = font
    cans.fillStyle = textColor
    cans.textAlign = 'left'
    cans.textBaseline = 'Middle'
    cans.fillText(text, 0, can.height)
    parentNode.style.backgroundImage = 'url(' + can.toDataURL('image/png') + ')'
  }
  addWaterMarker(el)
})
