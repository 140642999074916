<template>
  <div>
    <div
      class="detail-person"
      style="padding-right: 20px;box-sizing:border-box;"
      :class="
        !otherParams.isReceive && otherParams.shade ? 'module-cont-filter' : ''
      "
    >
      <div class="view-pro-all" v-if="isScroll">
        <el-carousel :interval="5000" :arrow="apparrow" :autoplay="false">
          <el-carousel-item
            v-for="(cards, index) in group(tableArr, currentNum)"
            :key="index"
          >
            <div
              class="pro-view-item"
              v-for="(item, indexs) in cards"
              :key="indexs"
            >
              <div
                v-for="(headName, indexC) in headerArr"
                :key="indexC + '1'"
                v-if="haveAttr(headName.attr, 'logo')"
              >
                <div class="pro-fl-view">
                  <img :src="item[headName.field]" alt="" />
                </div>
              </div>
              <div
                :class="cardType !== 'logo' ? 'no-logo-view' : 'pro-fr-view'"
              >
                <div
                  v-for="(headName, indexC) in headerArr"
                  :key="indexC + '2'"
                  v-if="!haveAttr(headName.attr, 'logo')"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item[headName.field] + ''"
                    placement="top"
                  >
                    <p
                      v-if="haveAttr(headName.attr, 'title')"
                      class="pro-fr-title card-cont-title"
                    >
                      <span>{{ item[headName.field] }}</span>
                      <span
                        class="pro-fr-num"
                        v-for="(subName, indexC) in headerArr"
                        :key="indexC + 'a'"
                        v-if="haveAttr(subName.attr, 'subTitle')"
                      >
                        {{ item[subName.field] }}1
                      </span>
                    </p>
                  </el-tooltip>

                  <p v-if="haveAttr(headName.attr, 'tag', headName)">
                    <span
                      class="ala-ding-tag"
                      v-for="(tag, index) in item.tags.split(',')"
                      v-if="tag"
                      :key="index"
                      >{{ tag }}</span
                    >
                  </p>
                  <p
                    v-if="
                      !haveAttr(headName.attr, 'title', headName) &&
                        !haveAttr(headName.attr, 'tag') &&
                        !haveAttr(headName.attr, 'subTitle')
                    "
                    class="hidden-more-txt"
                  >
                    <span
                      v-if="showAppUpdateTime(headName.field === 'updatedTime')"
                    >
                      <span class="pro-fr-name">{{ headName.name }}:</span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item[headName.field] + ''"
                        placement="top"
                      >
                        <span class="pro-fr-num">{{
                          item[headName.field]
                        }}</span>
                      </el-tooltip>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="view-pro-all" v-else>
        <div
          class="pro-view-item"
          v-for="(item, indexs) in tableArr"
          :key="indexs"
        >
          <div
            v-for="(headName, indexC) in headerArr"
            :key="indexC + '1'"
            v-if="haveAttr(headName.attr, 'logo')"
          >
            <div class="pro-fl-view">
              <img :src="item[headName.field]" alt="" />
            </div>
          </div>
          <div :class="cardType !== 'logo' ? 'no-logo-view' : 'pro-fr-view'">
            <div
              v-for="(headName, indexC) in headerArr"
              :key="indexC + '2'"
              v-if="!haveAttr(headName.attr, 'logo')"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item[headName.field] + ''"
                placement="top"
              >
                <p
                  v-if="haveAttr(headName.attr, 'title')"
                  class="pro-fr-title card-cont-title"
                >
                  {{ item[headName.field] }}
                </p>
                <span
                  class="pro-fr-num"
                  v-for="(subName, indexC) in headerArr"
                  :key="indexC + 'a'"
                  v-if="haveAttr(subName.attr, 'subTitle')"
                >
                  {{ item[subName.field] }}
                </span>
              </el-tooltip>
              <p v-if="haveAttr(headName.attr, 'tag')">
                <span
                  class="ala-ding-tag"
                  v-for="(tag, index) in item.tags.split(',')"
                  :key="index"
                  >{{ tag }}</span
                >
              </p>
              <p
                v-if="
                  !haveAttr(headName.attr, 'title') &&
                    !haveAttr(headName.attr, 'tag') &&
                    !haveAttr(headName.attr, 'subTitle')
                "
              >
                <span class="pro-fr-name">{{ headName.name }}:</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item[headName.field] + ''"
                  placement="top"
                >
                  <span class="pro-fr-num">{{ item[headName.field] }}</span>
                </el-tooltip>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height:40px;" v-if="pageStatus.total">
      <div class="page-div">
        <div style="float:right;">
          <footer-page
            @handleCurrentChange="handleCurrentChange"
            @goNumPage="goNumPage"
            :pageSizes="pageSizes"
            :pageStatus="pageStatus"
          >
          </footer-page>
        </div>
      </div>
    </div>
    <div
      class="touch-no-receive"
      v-if="!otherParams.isReceive && otherParams.shade"
      v-loading="getLoading"
    >
      <div class="touch-no-cont">
        <div class="touch-no-icon">
          <span
            style="color: #008CD9;text-decoration: underline;cursor:pointer;"
            @click="getReveiveKey('phone')"
            >领取</span
          >
          <span>以查看所有信息</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getComDetailModel } from '@/api/thread'
import Mixin from './mixin'
export default {
  name: 'CardPanel',
  props: {
    cardType: {
      type: String,
      default: ''
    },
    isScroll: {
      type: Boolean,
      default: true
    }
  },
  mixins: [Mixin],
  data() {
    return {
      shop: [],
      currentNum: 4,
      apparrow: 'never',
      headerArr: [],
      tableArr: []
    }
  },
  mounted() {
    this.$emit('ModuleDom')
  },
  methods: {
    jianTingWidth(val) {
      this.currentNum = val
      if (this.tableArr && this.currentNum >= this.tableArr.length) {
        this.apparrow = 'never'
      } else {
        this.apparrow = 'always'
      }
    },
    // 把一个数组按照一定长度分割成若干数组
    group(array, subGroupLength) {
      let index = 0
      let newArray = []
      if (array == undefined) {
        return newArray
      }
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)))
      }
      return newArray
    },
    haveAttr(val, lag, h = null) {
      if (val) {
        const arr = val.split(',')
        if (arr.indexOf(lag) > -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    // app最近更新时间权限控制
    showAppUpdateTime(isUpdateField) {
      if (!isUpdateField) {
        return true
      }
      if (this.permissionList.includes('智能获客-推荐线索-app更新时间')) {
        return true
      }
      return false
    }
  },
  computed: {
    permissionList() {
      return this.$store.state.permission.permission
    }
  }
}
</script>
<style scoped>
.view-pro-all ::v-deep .el-carousel .el-carousel__container {
  height: 130px !important;
}
.view-pro-all ::v-deep .el-carousel__item {
  padding: 0 60px;
}
.pro-web-list {
  line-height: 20px;
}
.pro-view-item {
  overflow: hidden;
  display: inline-block;
  width: 250px;
  height: 130px;
}
.pro-fl-view {
  float: left;
  width: 80px;
  height: 100%;
}
.pro-fr-view {
  float: right;
  width: 162px;
  overflow: hidden;
  height: 100%;
}
.no-logo-view {
  float: right;
  width: 210px;
  overflow: hidden;
  height: 100%;
}
.fl-view-cont {
  width: 42px;
  height: 42px;
  margin: 6px 0 0 30px;
  background: url('../../assets/pro-view.png') no-repeat;
  background-size: 100% 100%;
}
.pro-fl-view img {
  display: inline-block;
  height: 42px;
  margin: 6px 0 0 30px;
}
.pro-fr-title {
  font-size: 15px;
  line-height: 26px;
  color: #2e2e2e;
  font-weight: 400;
}
.hidden-more-txt {
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.pro-fr-name {
  font-size: 12px;
  font-weight: 400;
  color: #2e2e2e;
  line-height: 17px;
  /* width: 77px; */
  display: inline-block;
}
.pro-fr-num {
  font-weight: 400;
  color: #8a8a8a;
  line-height: 17px;
  font-size: 12px;
}
.view-app-line {
  display: inline-block;
  margin-left: 5%;
  width: 90%;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.card-cont-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.sub-title-cont {
}
.ala-ding-tag {
  height: 16px;
  padding: 0 2px;
  background: rgba(231, 255, 253, 1);
  border-radius: 2px;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(65, 202, 191, 1);
  line-height: 17px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
}
.modul-table-list {
  position: relative;
}
.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  opacity: 0.9;
  z-index: 100;
}
.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}
.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}
.touch-no-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.module-cont-filter {
  filter: blur(5px);
  padding-top: 20px;
}
</style>
