<template>
  <div class="searchItemComponents-main">
    <template v-if="inputType === 'input'">
      <el-select
        class="el-select-dt-hs"
        size="small"
        style="width: 100%;"
        v-model="inputData.value"
        v-el-select-input="setInputIndex"
        filterable
        multiple
        allow-create
        popper-class="dt-drop-hs"
        :placeholder="tip"
        default-first-option
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </template>
    <template v-else-if="inputType === 'selectMultiple'">
      <cascaderMulti
        :filterable="true"
        popper-class="popper-class-cas"
        v-model="selectMultipleData.value"
        :only-last="true"
        expand-trigger="hover"
        :data="formatDropData"
        size="small"
        value-key="name"
        label-key="name"
        children-key="child"
      >
      </cascaderMulti>
    </template>
    <template v-else-if="inputType === 'select'">
      <el-cascader
        filterable
        :options="formatDropData"
        size="small"
        style="width: 100%"
        :placeholder="tip"
        :props="{
          value: 'name',
          label: 'name',
          children: 'child',
          expandTrigger: 'hover'
        }"
        v-model="selectData.value"
      >
      </el-cascader>
    </template>
    <template v-else-if="inputType === 'onlyChoice'">
      <el-select
        v-model="selectData.value"
        :placeholder="tip"
        size="small"
        style="width: 100%;"
      >
        <el-option
          v-for="item in defaultDropData"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </template>
    <template v-else-if="inputType === 'datePicker'">
      <el-date-picker
        size="small"
        style="width: 100%"
        v-model="datePickerData.value"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        :placeholder="tip"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </template>
    <template v-else-if="inputType === 'year'">
      <el-form size="small">
        <el-form-item :error="yearDataError">
          <el-row style="width: 100%;">
            <el-col :span="10">
              <el-date-picker
                style="width: 100%"
                size="small"
                v-model="yearData.leftValue"
                type="year"
                :placeholder="tip"
                value-format="yyyy"
                placeholder="选择年"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="4">
              <div
                style="width: 100%;height:25px;display: flex;justify-content: center;align-items: center"
              >
                ~
              </div>
            </el-col>
            <el-col :span="10">
              <el-date-picker
                style="width: 100%"
                size="small"
                v-model="yearData.rightValue"
                type="year"
                :placeholder="tip"
                value-format="yyyy"
                placeholder="选择年"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </template>
    <template v-else-if="inputType === 'numeral'">
      <el-form size="small">
        <el-form-item :error="numeralDataError">
          <el-row style="width: 100%;">
            <el-col :span="10">
              <!--          <el-input-number size="small" v-model="numeralData.leftValue" :step="1"></el-input-number>-->
              <el-input
                v-model="numeralData.leftValue"
                size="small"
                type="number"
                :step="1"
                min="1"
                :placeholder="tip"
              ></el-input>
            </el-col>
            <el-col :span="4">
              <div
                style="width: 100%;height:25px;display: flex;justify-content: center;align-items: center"
              >
                ~
              </div>
            </el-col>
            <el-col :span="10">
              <el-input
                v-model="numeralData.rightValue"
                size="small"
                type="number"
                :step="1"
                min="1"
                :placeholder="tip"
              ></el-input>
              <!--          <el-input-number size="small" v-model="numeralData.rightValue" :step="1"></el-input-number>-->
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </template>
    <template v-else-if="inputType === 'ratio'">
      <el-form size="small">
        <el-form-item :error="ratioDataError">
          <el-row style="width: 100%;">
            <el-col :span="10">
              <el-input
                v-model.number="ratioData.leftValue"
                size="small"
                type="number"
                :step="0.1"
                :placeholder="tip"
              ></el-input>
              <!--          <el-input-number size="small" v-model="ratioData.leftValue" :precision="2" :step="0.1" :max="100"></el-input-number>-->
            </el-col>
            <el-col :span="4">
              <div
                style="width: 100%;height:25px;display: flex;justify-content: center;align-items: center"
              >
                ~
              </div>
            </el-col>
            <el-col :span="10">
              <el-input
                v-model.number="ratioData.rightValue"
                size="small"
                type="number"
                :step="0.1"
                max="1"
                :placeholder="tip"
              ></el-input>
              <!--          <el-input-number size="small" v-model="ratioData.rightValue" :precision="2" :step="0.1" :max="100"></el-input-number>-->
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </template>
    <template v-else-if="inputType === 'area'">
      <cascaderMulti
        filterable
        v-model="areaData.value"
        expand-trigger="hover"
        :data="formatDropData"
        size="small"
        value-key="name"
        label-key="name"
        children-key="child"
      >
      </cascaderMulti>
    </template>
    <template v-else>
      <el-input disabled size="small" placeholder="请选择操作方式"></el-input>
    </template>
  </div>
</template>

<script>
import cascaderMulti from '@/components/multi-cascader/multi-cascader'
export default {
  components: {
    cascaderMulti
  },
  name: 'searchItemComponents',
  directives: {
    elSelectInput: {
      bind: function(el, binding) {
        if (el.tagName.toLowerCase() !== 'input') {
          el = el.getElementsByTagName('input')[0]
          el.setAttribute(
            'classname',
            document.getElementsByClassName('select-search').length
          )
        }
        el.addEventListener('blur', function() {
          let a = el.getAttribute('classname')
          binding.value(a, el.value)
        })
      }
    }
  },
  data() {
    return {
      inputData: {
        value: [],
        tip: ''
      },
      selectMultipleData: {
        value: [],
        tip: ''
      },
      selectData: {
        value: '',
        tip: ''
      },
      areaData: {
        value: []
      },
      datePickerData: {
        value: []
      },
      numeralData: {
        leftValue: 1,
        rightValue: 1
      },
      ratioData: {
        leftValue: 1,
        rightValue: 1
      },
      yearData: {
        leftValue: 0,
        rightValue: 0
      },

      options: [], // 特殊业务需求 勿动
      defaultDropData: ['有', '无'],

      yearDataError: '',
      ratioDataError: '',
      numeralDataError: ''
    }
  },
  props: {
    // 输入框类型
    inputType: {
      type: String,
      default: 'input'
    },
    // 下拉框数据
    dropData: {
      type: Array,
      default: () => ['有', '无']
    },
    // 是否可多选
    multiple: {
      type: Boolean,
      default: true
    },

    tip: {
      type: String,
      default: ''
    },

    renderData: {
      type: [Object, Array, String],
      default: () => {}
    }
  },

  watch: {
    renderData: {
      handler: function(val) {
        if (val && val.data) {
          if (this.inputType === 'selectMultiple')
            this.selectMultipleData = val.data
          if (this.inputType === 'input') this.inputData = val.data
          if (this.inputType === 'select') this.selectData = val.data
          if (this.inputType === 'onlyChoice') this.selectData = val.data
          if (this.inputType === 'datePicker') this.datePickerData = val.data
          if (this.inputType === 'year') this.yearData = val.data
          if (this.inputType === 'numeral') this.numeralData = val.data
          if (this.inputType === 'ratio') this.ratioData = val.data
          if (this.inputType === 'area') this.areaData = val.data
        }
      },
      immediate: true,
      deep: true
    }
  },

  mounted() {},
  computed: {
    formatDropData() {
      return this.dropData.map(item => {
        return this.recursionFnc(item)
      })
    }
  },
  methods: {
    recursionFnc(data) {
      if (data.child && data.child.length) {
        data.child = data.child.map(item => {
          return this.recursionFnc(item)
        })
        return data
      } else if (data.child && !data.child.length) {
        delete data.child
        return data
      } else if (!data.child) {
        return data
      }
    },

    getData() {
      if (this.inputType === 'selectMultiple') {
        const res = this.selectMultipleData.value.map(i => i.join('/'))
        const saveData = {
          data: this.selectMultipleData,
          type: this.inputType
        }
        const isOk = true
        return { res, saveData, isOk }
      }

      if (this.inputType === 'input') {
        const res = this.inputData.value
        const saveData = {
          data: this.inputData,
          type: this.inputType
        }
        const isOk = true
        return { res, saveData, isOk }
      }
      if (this.inputType === 'select') {
        const res = this.selectData.value
        const saveData = {
          data: this.selectData,
          type: this.inputType
        }
        const isOk = true
        return { res, saveData, isOk }
      }
      if (this.inputType === 'onlyChoice') {
        const res = [this.selectData.value]
        const saveData = {
          data: this.selectData,
          type: this.inputType
        }
        const isOk = true
        return { res, saveData, isOk }
      }
      if (this.inputType === 'datePicker') {
        const res = this.datePickerData.value
        const saveData = {
          data: this.datePickerData,
          type: this.inputType
        }
        const isOk = true
        return { res, saveData, isOk }
      }
      if (this.inputType === 'year') {
        const res = [this.yearData.leftValue, this.yearData.rightValue]
        const saveData = {
          data: this.yearData,
          type: this.inputType
        }
        let isOk = true
        if (!this.yearData.leftValue) {
          isOk = false
          this.yearDataError = '最小年份不能为空'
        } else if (!this.yearData.rightValue) {
          isOk = false
          this.yearDataError = '最大年份不能为空'
        } else if (
          this.yearData.leftValue &&
          this.yearData.rightValue &&
          this.yearData.leftValue >= this.yearData.rightValue
        ) {
          isOk = false
          this.yearDataError = '最大年份不能小于等于最小年份'
        } else {
          this.yearDataError = ''
        }
        return { res, saveData, isOk }
      }
      if (this.inputType === 'numeral') {
        const res = [this.numeralData.leftValue, this.numeralData.rightValue]
        const saveData = {
          data: this.numeralData,
          type: this.inputType
        }
        let isOk = true

        if (!this.numeralData.leftValue) {
          isOk = false
          this.numeralDataError = '左侧数据必填'
        } else if (!this.numeralData.rightValue) {
          isOk = false
          this.numeralDataError = '右侧数据必填'
        } else if (
          this.numeralData.leftValue &&
          this.numeralData.rightValue &&
          parseInt(this.numeralData.leftValue) >=
            parseInt(this.numeralData.rightValue)
        ) {
          isOk = false
          this.numeralDataError = '左侧数据必须小于右侧数据'
        } else {
          this.numeralDataError = ''
        }
        return { res, saveData, isOk }
      }
      if (this.inputType === 'ratio') {
        const res = [this.ratioData.leftValue, this.ratioData.rightValue]
        const saveData = {
          data: this.ratioData,
          type: this.inputType
        }
        let isOk = true

        if (!this.ratioData.leftValue) {
          isOk = false
          this.ratioDataError = '左侧数据必填'
        } else if (!this.ratioData.rightValue) {
          isOk = false
          this.ratioDataError = '右侧数据必填'
        } else if (
          this.ratioData.leftValue &&
          this.ratioData.rightValue &&
          parseFloat(this.ratioData.leftValue) >=
            parseFloat(this.ratioData.rightValue)
        ) {
          isOk = false
          this.ratioDataError = '左侧数据必须小于右侧数据'
        }
        return { res, saveData, isOk }
      }
      if (this.inputType === 'area') {
        const res = this.areaData.value.map(i => i.join('/'))
        const saveData = {
          data: this.areaData,
          type: this.inputType
        }
        const isOk = true
        return { res, saveData, isOk }
      }
      return { res: null, saveData: null, isOk: false }
    },

    setInputIndex(index, value) {
      if (value && this.inputData.value instanceof Array) {
        this.inputData.value.push(value)
      }
    }
  }
}
</script>

<style scoped>
.searchItemComponents-main {
  width: 100%;
}
</style>
