var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    { attrs: { placement: "top-start", width: "480", trigger: "hover" } },
    [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
      _vm._v(" "),
      _c("i", {
        staticClass: "el-icon-info",
        staticStyle: { color: "#8a8a8a" },
        attrs: { slot: "reference" },
        slot: "reference",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }