<template>
  <div class="mock-map-page">
    <div class="m-map">
      <div class="item item1">
        <div class="dot">
          <img src="@/views/charts/theme/images/dot2.png" alt="" />
        </div>
        <div class="name">宝山</div>
      </div>
      <div class="item item2">
        <div class="dot">
          <img src="@/views/charts/theme/images/dot1.png" alt="" />
        </div>
        <div class="name">中心城区</div>
      </div>
      <div class="item item3">
        <div class="dot">
          <img src="@/views/charts/theme/images/dot1.png" alt="" />
        </div>
        <div class="name" style="display: none;">徐汇</div>
        <div class="map-pop" style="display: block">
          <div class="box">
            <div class="tit">徐汇</div>
            <div class="line">
              <div class="s">跨国公司研发中心：</div>
              <div class="rr"><span class="num">23</span>家</div>
            </div>
            <div class="line">
              <div class="s">国家级企业技术中心：</div>
              <div class="rr"><span class="num">8</span>家</div>
            </div>
            <div class="line">
              <div class="s">市级企业技术中心：</div>
              <div class="rr"><span class="num">35</span>家</div>
            </div>
            <div class="line">
              <div class="s">高新技术企业：</div>
              <div class="rr"><span class="num">785</span>家</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item item4">
        <div class="dot">
          <img src="@/views/charts/theme/images/dot1.png" alt="" />
        </div>
        <div class="name left">闵行</div>
      </div>
      <div class="item item5">
        <div class="dot">
          <img src="@/views/charts/theme/images/dot2.png" alt="" />
        </div>
        <div class="name">松江</div>
      </div>
      <div class="item item6">
        <div class="dot">
          <img src="@/views/charts/theme/images/dot2.png" alt="" />
        </div>
        <div class="name left">浦东</div>
      </div>
      <ul class="ul-nav">
        <li v-for="(item, index) in navLs" :key="index">
          <div
            class="link"
            :class="{ active: active == index }"
            @click="changeNav(index)"
          >
            {{ item.name }}
          </div>
        </li>
      </ul>
    </div>
    <div class="map-footer"></div>
    <!-- <img src="" alt=""> -->
  </div>
</template>

<script>
export default {
  name: 'MockMap',
  data() {
    return {
      navLs: [
        {
          name: '总览'
        },
        {
          name: '企业画像'
        },
        {
          name: '产业图谱'
        },
        {
          name: '专题一'
        },
        {
          name: '专题二'
        },
        {
          name: '专题三'
        }
      ],
      active: 0
    }
  },

  methods: {
    changeNav(index) {
      this.active = index
    }
  }
}
</script>
<style lang="scss" scoped>
.mock-map-page {
  position: absolute;
  left: 50%;
  bottom: -40px;
  transform: translate(-50%);
  width: 1041px;
  height: 100%;
  .m-map {
    width: 100%;
    height: 100%;
    background-image: url('../theme/images/map.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .item {
      position: absolute;
      &:hover {
        .name {
          opacity: 0;
        }
        .map-pop {
          display: block;
        }
      }
    }
    .item1 {
      top: 27%;
      left: 46%;
    }

    .item2 {
      top: 36%;
      left: 46%;
    }
    .item3 {
      top: 43%;
      left: 48.5%;
    }
    .item4 {
      top: 52%;
      left: 47%;
    }

    .item5 {
      top: 54%;
      left: 37%;
    }
    .item6 {
      top: 52%;
      left: 67%;
    }
    .dot {
      position: relative;
      width: 40px;
      height: 40px;
    }
    .name {
      font-size: 18px;
      color: #fff;
      border: 1px solid #0170cf;
      background-color: rgba(4, 35, 74, 0.8);
      padding: 0 10px;
      line-height: 28px;
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
      position: absolute;
      margin-right: 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &.left {
        right: auto;
        left: 100%;
        margin-left: 4px;
        margin-right: 0;
      }
    }
  }

  .map-pop {
    background: url(../theme/images/m-pop.png) no-repeat center;
    background-size: contain;
    width: 248px;
    height: 197px;
    position: absolute;
    bottom: -9px;
    left: 0;
    padding: 16px 35px;
    font-size: 14px;
    color: #eff0f4;
    display: none;
    .tit {
      font-size: 18px;
      color: #fff;
      line-height: 32px;
      margin-bottom: 8px;
    }
    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;
      .s {
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .rr {
        flex-shrink: 0;
        margin-left: 5px;
      }
      .num {
        color: #f39800;
      }
    }
  }

  .ul-nav {
    text-align: center;
    font-size: 0;
    position: absolute;
    bottom: 28px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 100%;

    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
    }

    .link {
      min-width: 100px;
      display: block;
      cursor: pointer;
      font-size: 14px;
      color: rgba(211, 229, 245, 0.8);
      transition: 0.4s;
      border-radius: 5px;
      border: 1px solid #017fd9;
      line-height: 36px;
      box-shadow: 0 0 7px inset rgba(0, 150, 255, 0.4);
      background: linear-gradient(rgba(0, 150, 255, 0), rgba(0, 150, 255, 0.3));
      position: relative;

      &.active {
        color: #fff;
        font-weight: bold;
        border-color: transparent;
        box-shadow: none;
        background: transparent;

        &::after {
          content: '';
          display: block;
          height: 38px;
          background: url(../theme/images/bt_sel.png) no-repeat center;
          background-size: 100% 100%;
          pointer-events: none;
          position: absolute;
          top: -1px;
          right: -1px;
          bottom: -1px;
          left: -1px;
          z-index: -1;
        }
      }
    }
  }

  .mtooltip {
    background-color: rgba(0, 10, 18, 0.8) !important;
    border: 1px solid #0151a5 !important;
    border-radius: 0 !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.75) !important;
  }

  .tooltip-mm {
    font-size: 12px;
    color: #fff;
    padding-right: 15px;
  }

  .tooltip-mm-title {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .tooltip-item {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    > span:nth-child(1) {
      border-radius: 0 !important;
      width: 4px !important;
      height: 4px !important;
    }
  }

  .map-footer {
    background: url(../theme/images/bot-bg.png) no-repeat center;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1228px;
    height: 37px;
    pointer-events: none;
  }
}
</style>
