<template>
  <div class="body" v-loading="loading">
    <div class="go-back" @click="hideModal">
      <i class="el-icon-d-arrow-left"></i>
    </div>
    <div v-if="contactData">
      <div class="header">
        <el-row :gutter="10" type="flex" align="center">
          <el-col :span="18" class="center">
            <el-avatar
              :src="
                contactData.wxHeadImg ||
                  'https://dtbird-miniapp.oss-cn-shanghai.aliyuncs.com/image/header-c.svg'
              "
              style="margin-right: 5px"
            ></el-avatar>
            <el-badge
              :value="contactData.isKp == '1' ? 'KP' : ''"
              class="badge-item"
              type="primary"
            >
              <span style="display: inline-block;margin-right: 10px;">
                {{ contactData['contact'] }}
              </span>
            </el-badge>
            <span style="display:inline-block;margin-left: 20px">
              <el-tag
                size="small"
                v-if="contactData['position'] || contactData['department']"
              >
                {{ tag }}
              </el-tag>
            </span>
          </el-col>
          <el-col :span="6" class="center">
            <el-button
              size="small"
              @click="edit"
              v-dt-permission="`客户培育-${permission}-详情-编辑`"
              type="primary"
            >
              编辑
            </el-button>
            <el-button
              size="small"
              @click="del"
              v-dt-permission="`客户培育-${permission}-详情-删除`"
              type="primary"
            >
              删除
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div class="select">
        <div :class="defaultMsg ? 'active' : ''" @click="defaultMsg = true">
          基本信息
        </div>
        <div :class="defaultMsg ? '' : 'active'" @click="getCustomerDetail">
          客户动态
        </div>
      </div>
      <div v-show="defaultMsg">
        <div class="common">
          <el-row :gutter="10" v-for="item in commonAttr" class="list-item">
            <el-col :span="3" class="label">
              {{ item.label }}
            </el-col>
            <el-col
              :span="21"
              :class="contactData[item.key] ? 'value' : 'value'"
            >
              <span v-if="item.label === '创建人'">
                {{
                  `${contactData[item.key] || item.defaultValue} ${
                    contactData['createdAt']
                  }`
                }}
              </span>
              <span v-else-if="item.label === '修改人'">
                {{
                  `${contactData[item.key] || item.defaultValue} ${
                    contactData['updatedAt']
                  }`
                }}
              </span>
              <dt-score
                :score="contactData[item.key]"
                v-else-if="item.label === '完善度'"
                :key="refreshNum"
              ></dt-score>
              <span v-else-if="item.key === 'bindWechat'">
                {{ contactData[item.key] ? '已关联' : '未关联' }}
                <!--                <span v-show="!contactData[item.key]" class="no-relation">-->
                <!--                  自动关联-->
                <!--                </span>-->
              </span>
              <span v-else>
                {{ contactData[item.key] || item.defaultValue }}
              </span>
            </el-col>
          </el-row>
        </div>
        <div class="no-common">
          <el-row :gutter="10" v-for="item in noCommonAttr" class="list-item">
            <el-col :span="3" class="label">
              {{ item.label }}
            </el-col>
            <el-col
              :span="21"
              :class="contactData[item.key] ? 'value' : 'value'"
            >
              <span v-if="item.label === '创建人'">
                {{
                  `${contactData[item.key] || item.defaultValue} &nbsp ${
                    contactData['createdAt']
                  }`
                }}
              </span>
              <span v-else-if="item.label === '修改人'">
                {{
                  `${contactData[item.key] || item.defaultValue} &nbsp ${
                    contactData[item.key] ? contactData['updatedAt'] : ''
                  }`
                }}
              </span>
              <span v-else>
                {{ contactData[item.key] }}
              </span>
            </el-col>
          </el-row>
        </div>
      </div>

      <div v-show="!defaultMsg" v-loading="dtLoading">
        <div class="dt-cus">
          <div class="dynamic-data">
            <div class="dynamic-item">
              <div>近7日访问</div>
              <div>{{ dtData['sevenRecord'] || '0' }}</div>
            </div>
            <div class="dynamic-item">
              <div>近30日访问</div>
              <div>{{ dtData['thirtyRecord'] || '0' }}</div>
            </div>
            <div class="dynamic-item">
              <div>总访问次数</div>
              <div>{{ dtData['AllCount'] || '0' }}</div>
            </div>
            <div class="dynamic-item">
              <div>上次访问</div>
              <div>{{ dtData['recentTime'] || '-' }}</div>
            </div>
          </div>

          <el-scrollbar class="dynamic-data-data">
            <div>
              <div class="line" ref="line">
                <template v-for="(item, index) in tongJi">
                  <div class="week" :key="index + 'a'">
                    <span>{{ item.day }}</span
                    ><span>{{ item.week }}</span>
                  </div>
                  <el-timeline :key="index + 'b'">
                    <el-timeline-item
                      size="normal"
                      v-for="(activity, index) in item.follows"
                      :key="index"
                      :color="activity.createdAt === nowTime ? 'red' : ''"
                    >
                      <div class="con-a">
                        <div
                          v-html="activity.opDetails"
                          class="time-text"
                          v-if="false"
                        ></div>
                        <div
                          v-html="activity.opSiteDetails"
                          class="time-text"
                        ></div>
                      </div>
                      <div class="time">
                        {{ activity.time }}
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </template>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <div v-else class="no-data-contact">
      暂无数据
    </div>

    <el-dialog
      class="el-dialog-dt"
      :visible.sync="show"
      :modal="true"
      :width="'800px'"
      :top="'90px'"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <div slot="title" class="title">
        编辑联系人
      </div>
      <component
        v-bind:is="createContact"
        v-bind="contactData"
        @cancelPop="cancelPop"
      ></component>
    </el-dialog>
  </div>
</template>

<script>
import { customerDt, getJmPhone, poolContactDelete } from '@/api/pool'
import createContact from '@/views/contactBreed/dialog/createContact'
import { contactSource, getCusTagsDdic } from '@/api/customerpond'
import DtScore from '@/views/customerpond/dialog/score'

export default {
  name: 'contactDetail',
  data() {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day
    let now = `${year}-${month}-${day}`
    return {
      refreshNum: 0,
      value: 2, //完善度
      nowTime: now,
      defaultMsg: true,
      contactData: null,
      tongJi: [],
      dtData: {
        sevenRecord: '',
        thirtyRecord: '',
        AllCount: '',
        recentTime: ''
      },

      dtContact: null,
      createContact: createContact,
      loading: false,
      dtLoading: false,
      commonAttr: [
        {
          key: 'score',
          label: '完善度',
          defaultValue: ''
        },
        {
          key: 'bindWechat',
          label: '企业微信',
          defaultValue: '未关联'
        },
        {
          key: 'mobile',
          label: '手机',
          defaultValue: ''
        },
        {
          key: 'landLine',
          label: '座机',
          defaultValue: ''
        },
        {
          key: 'wechat',
          label: '微信',
          defaultValue: ''
        },
        {
          key: 'fromTypeDesc',
          label: '来源',
          defaultValue: ''
        },
        {
          key: 'nickName',
          label: '昵称',
          defaultValue: ''
        },
        {
          key: 'email',
          label: '邮箱',
          defaultValue: ''
        },
        {
          key: 'companyName',
          label: '归属企业',
          defaultValue: ''
        },
        {
          key: 'markDesc',
          label: '状态',
          defaultValue: ''
        },
        {
          key: 'remark',
          label: '备注',
          defaultValue: ''
        },
        {
          key: 'description',
          label: '描述',
          defaultValue: ''
        }
      ],
      noCommonAttr: [
        {
          key: 'createUser',
          label: '创建人',
          defaultValue: ''
        },
        {
          key: 'updateUser',
          label: '修改人',
          defaultValue: ''
        }
      ],
      show: false,
      contactList: []
    }
  },
  mounted() {
    const modal = this.$store.state.plugin.modal.options
    if (modal && modal.contactDetail) {
      this.contactData = modal.contactDetail
      this.contactList = modal.contactList
    }
  },
  methods: {
    cancelPop() {
      this.show = false
    },

    hideModal() {
      this.$store.dispatch('hideModal')
    },

    getCustomerDetail(id, fn) {
      this.defaultMsg = false
      const hasValue = Object.values(this.dtData).filter(item => item)
      if (this.tongJi.length || hasValue.length) {
        return
      }
      this.dtLoading = true
      customerDt({ id: this.contactData.id, pageId: 3002 })
        .then(res => {
          console.log(res)
          this.dtLoading = false
          if (res.code === 60000) {
            try {
              if (
                res.data.weekDate.follows &&
                res.data.weekDate.follows.length
              ) {
                this.dtData = res.data.weekDate.follows[0]
              }
            } catch (e) {}
            if (res.data.tongJi) {
              this.tongJi = res.data.tongJi
            }
            typeof fn === 'function' ? fn() : ''
          }
        })
        .catch(() => {
          this.dtLoading = false
        })
    },

    getSourceList(fn) {
      const params = {
        type: 6
      }
      getCusTagsDdic(params).then(res => {
        if (res && res.code === 60000 && fn && typeof fn === 'function') {
          fn(res.data)
        } else {
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'error'
          })
        }
      })
    },

    //编辑联系人
    edit() {
      this.getSourceList(sourceList => {
        this.$store.commit('update', {
          target: this.$store.state.plugin.modal.options,
          data: {
            contactData: this.contactData,
            sourceList: sourceList
          }
        })
        this.show = true
        this.$bus.$on('closeChild', res => {
          this.$bus.$off('closeChild')
          this.show = false
          // //更新数据
          // const fromType = sourceList.filter(_res=>_res.value === res.fromType)
          // res = Object.assign({}, res, {fromTypeDesc: fromType[0]['name']})
          this.$store.commit('update', {
            target: this.contactData,
            data: res
          })
          // 刷新完善度
          this.refreshNum += 1
        })
      })
    },
    // 删除联系人
    del() {
      this.$store.dispatch('showMessageBox', {
        type: 'warning',
        text: '确认删除',
        title: '提示',
        onClose: res => {
          if (res === 'confirm') {
            poolContactDelete({ id: this.contactData.id }).then(res => {
              if (res && res.code === 60000) {
                this.$store.dispatch('showTip', {
                  text: '删除成功',
                  type: 'success',
                  duration: 2000
                })
                this.$store.commit('update', {
                  target: this.$store.state.plugin.modal.options,
                  data: {
                    refresh: false,
                    reRequest: true
                  }
                })
                this.$store.dispatch('hideModal')
              }
            })
          }
        }
      })
    }
  },
  computed: {
    tag() {
      const department = this.contactData['department']
        ? `${this.contactData['department']}`
        : ''
      const position = this.contactData['position']
      if (department && position) {
        return `${department}-${position}`
      }
      return `${department}${position}`
    },
    permission() {
      const id = parseInt(this.$route.params.id)
      const gyList = this.$store.state.permission.gyList
      const syList = this.$store.state.permission.syList
      const syCjList = this.$store.state.permission.syCjList
      if (gyList.includes(id)) return '公域潜客池'
      if (syList.includes(id)) return '私域培育池'
      if (syCjList.includes(id)) return '私域成交池'
    }
  },
  components: {
    DtScore
  }
}
</script>

<style scoped>
.body {
  max-height: 100%;
  height: auto;
  overflow-y: auto;
  width: 100%;
  padding: 40px 20px 20px 20px;
  box-sizing: border-box;
  position: relative;
}

.go-back {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 40px;
  background-color: #41cabf;
  border-bottom-right-radius: 4px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}

.header {
  width: 100%;
}

.center {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.no-data-contact {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.15);
}

.list-item {
  margin-bottom: 15px;
}

.common {
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 14px;
}

.no-common {
  font-size: 14px;
}

.common .list-item:last-child {
  margin-bottom: 0;
}

.label {
  color: rgba(0, 0, 0, 0.5);
}

.value {
  color: rgba(0, 0, 0, 0.8);
}

.null-data {
  color: rgba(0, 0, 0, 0.15);
}

.title {
  height: 50px;
  width: 100%;
  background-color: #41cabf;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.el-dialog-dt ::v-deep .el-dialog__close {
  color: #fff;
}

.el-dialog-dt ::v-deep .el-dialog__body {
  padding: 0 !important;
}

.el-dialog-dt ::v-deep .el-dialog__header {
  padding: 0 !important;
  color: #fff;
  font-size: 14px;
}

.select {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*margin-top: 20px;*/
  position: relative;
}

/*.select::after{*/
/*  content: "";*/
/*  display: block;*/
/*  position: absolute;*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  border: 1px solid rgba(0,0,0,.05);*/
/*  left: 0;*/
/*  top: 0;*/
/*}*/

/*.select>div:first-child::after{*/
/*  content: "";*/
/*  display: block;*/
/*  position: absolute;*/
/*  height: 100%;*/
/*  width: 1px;*/
/*  background-color: rgba(0,0,0,.05);*/
/*  right: 0;*/
/*  top: 0;*/
/*}*/

.select > div {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #8a8a8a;
  cursor: pointer;
}

.active {
  color: #333 !important;
}

.active::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 1px;
  width: 90%;
  background-color: #41cabf;
  bottom: 0;
  left: 0;
  position: absolute;
}

.dt-cus {
  height: calc(100vh - 140px);
  font-size: 14px;
  width: 100%;
}

.dynamic-data {
  height: 80px;
  width: 100%;
  margin: 20px 0;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
}

.dynamic-data-data {
  height: calc(100% - 150px);
  width: 100%;
}

.dynamic-data-data ::v-deep .el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.dynamic-item {
  height: 100%;
  width: 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dynamic-item div:last-child {
  margin-top: 20px;
}

.dynamic-item div:first-child {
  color: #8a8a8a;
}

.line {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 100px;
  box-sizing: border-box;
}

.line ::v-deep .el-timeline-item__node--normal {
  height: 8px;
  width: 8px;
  transform: translateX(2px);
}

.time-text {
  font-size: 12px;
  color: #999999;
  line-height: 20px;
  max-width: 248px;
}

.week {
  padding-left: 28px;
  margin-bottom: 8px;
  box-sizing: border-box;
  color: #333;
  font-size: 13px;
  position: relative;
}

.week span:first-child {
  margin-right: 10px;
}

.week span:last-child {
  position: absolute;
  left: -70px;
}

.time {
  color: #999;
  font-size: 14px;
  position: absolute;
  transform: translate(-100px, 0);
  top: 0;
}

.con-a {
  /*display: flex;*/
  /*height: auto;*/
  /*align-items: center;*/
}

.photo {
  text-align: center;
  width: 80px;
  cursor: pointer;
}

.photo p {
  max-width: 80px;
  text-align: center;
  word-wrap: break-word;
  color: #999999;
  font-size: 12px;
  margin-top: 3px;
}

.avatar-dt {
  display: flex;
  justify-content: left;
  cursor: pointer;
}

.no-relation {
  color: #909399;
  font-size: 10px;
}
</style>
