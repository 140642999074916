<template>
  <div>
    <div class="line-body m-t-10" style="padding-top:1px;">
      <div
        v-if="!(bookMarkList && bookMarkList.length)"
        class="line-body-nodata"
      ></div>
      <el-table
        border
        :data="bookMarkList"
        v-loading="getListLoading"
        ref="myCollectSelect"
        @selection-change="selectionChange"
        empty-text="暂无数据"
        style="width: 100%; font-size: 12px;margin-top: 10px;"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" show-overflow-tooltip label="培育池">
          <template slot-scope="props">
            <span
              class="hover-go-detail"
              style="cursor:pointer;color: #0099cc;"
              @click="cellClick(props.row.name, props.row.id, props.row.userId)"
              >{{ props.row.name }}</span
            >
            <span
              v-if="props.row.isDefault"
              style="color: #0099cc;cursor:pointer;"
              @click="cellClick(props.row.name, props.row.id, props.row.userId)"
              >(默认培育池)</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="companyCount"
          show-overflow-tooltip
          label="公司数量"
        >
          <template slot-scope="props">
            <span class="hover-go-detail">{{ props.row.companyCount }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="note" show-overflow-tooltip label="备注">
          <template slot-scope="props">
            <span class="hover-go-detail">{{ props.row.note }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="isDefault"
          width="250"
          align="center"
          show-overflow-tooltip
          label="操作"
        >
          <template slot-scope="props">
            <span
              style="cursor:pointer;color:#0099cc;"
              @click="openUpdateName(props.row)"
              v-dt-permission="'客户培育-线索培育-培育池-编辑'"
              >编辑</span
            >
            <span
              style="cursor:pointer;"
              :style="{ color: props.row.isDefault ? '#C0C4CC' : '#0099cc' }"
              @click="setFirst(props.row)"
              v-dt-permission="'客户培育-线索培育-设为默认'"
              >|设为默认|</span
            >
            <span
              style="cursor:pointer;"
              :style="{ color: props.row.isDefault ? '#C0C4CC' : '#0099cc' }"
              @click="deleteFavorites(props.row)"
              v-dt-permission="'客户培育-线索培育-删除'"
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="height:50px;" v-if="pageStatusNew.total">
        <div class="page-div">
          <div style="float:right;">
            <footer-page
              @handleCurrentChange="handleCurrentChange"
              @goNumPage="goNumPage"
              @changePageSize="changePageSize"
              :pageStatus="pageStatusNew"
            ></footer-page>
          </div>
        </div>
      </div>
    </div>
    <dia-model
      ref="deleteMark"
      :dialogStatus="dialogStatus"
      @submitContBtn="submitContBtn"
    >
      <div slot="modalCont" style="margin: 10px 0 20px; ">
        删除培育池，该培育池内所有线索也会被移除培育。是否确认？
      </div>
    </dia-model>
    <add-book
      @updateMarkName="updateMarkName(arguments)"
      :currentId="currentId"
      ref="openAddBook"
      @updateMarkRemark="updateMarkRemark"
      :BookStatus="BookStatus"
    ></add-book>
  </div>
</template>

<script>
import FooterPage from '@/components/Footerpage/index'
import diaModel from '@/components/Model'
import AddBook from './addBook'
import {
  getFavoritesList,
  postFavoritesUpdate,
  delFavoritesDel
} from '@/api/table'
import { ResetSystemConfig } from '@/utils/auth'
export default {
  props: {
    pageStatus: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      bookMarkList: [],
      getListLoading: false,
      currentList: {},
      dialogStatus: {},
      currentId: null,
      BookStatus: {},
      pageStatusNew: {
        pageNo: 1,
        pageSize: 20,
        total: null
      }
    }
  },
  components: {
    diaModel,
    AddBook,
    FooterPage
  },
  created() {
    const pageStatusNew = sessionStorage.getItem('peiyuParams')
    if (pageStatusNew) {
      this.pageStatusNew = JSON.parse(pageStatusNew)
    }
    this.getFavoritesList()
  },
  methods: {
    handleCurrentChange(val) {
      this.pageStatusNew.pageNo = val
      this.getFavoritesList()
    },
    goNumPage(val) {
      this.pageStatusNew.pageNo = Number(val)
      this.getFavoritesList()
    },
    changePageSize(val) {
      this.pageStatusNew.pageSize = val
      this.getFavoritesList()
    },
    cellClick(name, id, userId) {
      sessionStorage.setItem('favoriteId', id)
      sessionStorage.setItem('collectName', name)
      sessionStorage.setItem('collect_userId', userId)
      this.$router.push({
        name: 'booklist',
        query: {
          favoriteId: id
        }
      })
    },
    updateMarkName(val) {
      const params = {
        id: this.currentId,
        name: val[0],
        note: val[1]
      }
      this.postFavoritesUpdate(params)
    },
    updateMarkRemark(val) {
      const params = {
        id: this.currentId,
        name: val[0],
        note: val[1]
      }
      this.postFavoritesUpdate(params)
    },
    openUpdateName(val) {
      this.currentId = val.id
      this.BookStatus = val
      this.BookStatus.showName = true
      this.BookStatus.showMark = true
      this.$nextTick(() => {
        this.$refs.openAddBook.init()
      })
    },
    addListRemark(val) {
      this.currentId = val.id
      this.BookStatus = val
      this.BookStatus.showName = false
      this.BookStatus.showMark = true
      this.$nextTick(() => {
        this.$refs.openAddBook.init()
      })
    },
    setFirst(val) {
      if (val.isDefault) {
        return
      }
      const params = {
        id: val.id,
        isDefault: 1,
        name: val.name
      }
      this.postFavoritesUpdate(params)
    },
    deleteFavorites(val) {
      if (val.isDefault) {
        return
      }
      this.currentList = val
      this.openSubmit(val)
    },
    submitContBtn() {
      const params = {
        id: this.currentList.id
      }
      this.delFavoritesDel(params)
    },
    openSubmit(val) {
      this.$store.dispatch('showMessageBox', {
        title: `删除--${val.name}`,
        text:
          '培育池内所有线索会被移除，与该培育池相关的导入任务也会被删除。是否确认？',
        type: 'warning',
        onClose: res => {
          if (res === 'confirm') {
            this.submitContBtn()
          }
        }
      })
    },
    refresh() {
      this.getFavoritesList()
    },
    selectionChange(val) {
      const params = {
        bookMark: val,
        len: this.bookMarkList.length
      }
      this.$emit('selectBookMark', params)
    },
    getFavoritesList() {
      getFavoritesList(this.pageStatusNew).then(res => {
        if (res.code === 60000) {
          sessionStorage.setItem(
            'peiyuParams',
            JSON.stringify(this.pageStatusNew)
          )
          this.bookMarkList = res.data.list
          this.pageStatusNew.total = res.data.page.total
          this.selectionChange([])
        }
      })
    },
    postFavoritesUpdate(params) {
      postFavoritesUpdate(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: '更新成功',
            type: 'success'
          })
          this.refresh()
          ResetSystemConfig()
          // const haveDefault = this.$store.getters.sysConfig;
          // haveDefault.isShowSelectBook = 1;
          // this.$store.dispatch("setSysConfig", haveDefault);
          // if (res.data.isShowSelectBook === 0) {
          //   localStorage.setItem("isShowSelectBook", res.data.isShowSelectBook);
          // }
          this.$refs.openAddBook.closeAddBook()
        }
      })
    },
    delFavoritesDel(params) {
      delFavoritesDel(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.refresh()
          this.$refs.deleteMark.subimtCancle()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.book-mark-del {
  cursor: pointer;
  color: #0099cc;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/remove-pic.png') no-repeat;
  background-size: 100% 100%;
}
.book_remark_reseve {
  display: inline-block;
  height: 18px;
  width: 15px;
  cursor: pointer;
  background: url('../../assets/xg_remark.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 2px;
  right: 0;
}
.remark-book-mark {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.line-body ::v-deep .el-table--border .el-table__body tbody tr td {
  border-right: none;
}
.line-body ::v-deep .el-table--border th:nth-child(1) {
  border-right: none;
  height: 50px;
}
.dt-footer {
  height: 50px;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
