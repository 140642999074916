/*
 * @Author: Shy07
 * @Date: 2019-06-27 10:35:33
 * @Last Modified by: Shy07
 * @Last Modified time: 2019-06-27 11:44:56
 */
import axios from 'axios'
import qs from 'qs'
import errorCode from './errors.json'
import store from '@/store'
import router from '../router'
import { Message } from 'element-ui'
import { saveErrorHandel } from '@/api/system'

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'
axios.interceptors.request.use(
  config => {
    const outTime = '/api/collection/export'
    if (config.url.indexOf(outTime) > -1) {
      config.timeout = 600000
    }
    const outEs = '/api/es/company/industry'
    if (config.url.indexOf(outEs) > -1) {
      config.timeout = 600000
    }
    const uploadFile = '/api/marketing/landPage/upload/file'
    if (config.url.indexOf(uploadFile) > -1) {
      config.timeout = 600000
    }
    config.cancelToken = new axios.CancelToken(cancel => {
      // 在发送请求设置cancel token
      store.commit('settings/pushAxiosPromiseArr', cancel)
    })

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.defaults.timeout = 120000

axios.interceptors.response.use(
  resp => {
    if (resp.data.code === 611001) {
      Message({ message: resp.data.msg, type: 'warning' })
    }

    if (resp.data.code === 70000) {
      const params = { url: resp?.request?.responseURL, msg: '限流' }
      saveErrorHandel({ error: JSON.stringify(params) }).then(() => {})
    }

    return resp.data
  },
  err => {
    // if (err && err.response && err.response.status == 401) {
    //   store.commit('logout')
    //   if (
    //     !(store.state.plugin.env === 'dd' || store.state.plugin.env === 'tt')
    //   ) {
    //     router.replace('/login')
    //   } else {
    //     router.replace('/dingding-login')
    //   }
    //   return
    // }
    const errMsg = err.toString()
    const code = errMsg.substr(errMsg.indexOf('code') + 5)
    let _message = errorCode[code]
    if (!_message) {
      _message = errMsg.substr(0)
    }
    return { message: _message, result: false }
  }
)

export const get = (url, params, headers) => axios.get(url, { params, headers })

export const pathGet = (url, params, headers) =>
  axios.get(`${url}/${params.path}`, { params: params.params, headers })

export const noHGet = (url, params) => axios.get(url, { params })

const have = {}
export const PinGet = (url, params, headers) =>
  axios.get(`${url}/${params.code}`, { have, headers })

export const PingGet = (url, params, headers) =>
  axios.get(`${url}/${params.id}`, { have, headers })

export const JsonPost = (url, data, headers = {}) => {
  const defaultHeaders = {}
  const newHeaders = { ...defaultHeaders, ...headers }
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  return axios.post(url, data, { headers: newHeaders })
}

export const JsonPostImg = (url, data, headers = {}) => {
  const defaultHeaders = {}
  const newHeaders = { ...defaultHeaders, ...headers }
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  return axios.post(url, data, {
    headers: newHeaders,
    responseType: 'blob'
  })
}

export const post = (url, data, headers = {}) => {
  let _data = data
  typeof data === 'object' ? (_data = JSON.stringify(data)) : ''
  const defaultHeaders = {}
  const newHeaders = { ...defaultHeaders, ...headers }
  axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded'
  return axios.post(url, qs.stringify(data), { headers: newHeaders })
}

export const loadPost = (url, data, headers = {}) => {
  const defaultHeaders = {}
  const newHeaders = { ...defaultHeaders, ...headers }
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  return axios.post(url, data, { headers: newHeaders })
}

export const delet = (url, params, headers) => {
  return axios.delete(url, { params, headers })
}

export const put = (url, data, headers = {}) => {
  const defaultHeaders = {}
  const newHeaders = { ...defaultHeaders, ...headers }
  return axios.put(url, qs.stringify(data), { headers: newHeaders })
}

export const del = (url, params = {}, headers) => {
  const defaultHeaders = {}
  const newHeaders = { ...defaultHeaders, ...headers }
  const query = qs.stringify(params)
  const newUrl = `${url}?${query}`
  return axios.delete(newUrl, { headers: newHeaders })
}

export const PinDel = (url, params = {}, headers) => {
  const defaultHeaders = {}
  const newHeaders = { ...defaultHeaders, ...headers }
  const newUrl = `${url}/${params.id}`
  return axios.delete(newUrl, { headers: newHeaders })
}

export default {
  get,
  post,
  put,
  noHGet,
  delete: del,
  loadPost,
  delet,
  PinGet,
  PingGet,
  PinDel,
  pathGet,
  JsonPost,
  JsonPostImg
}
