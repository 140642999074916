var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticStyle: { height: "calc(100vh)" } },
      [
        _c("RelationGraph", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.graphLoad,
              expression: "graphLoad",
            },
          ],
          ref: "graphRef",
          attrs: { options: _vm.hTreeOptions },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }