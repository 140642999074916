var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "update-container" },
    [
      _c("Header"),
      _vm._v(" "),
      !_vm.checkCode
        ? _c("div", { staticClass: "reser-title" }, [_vm._v("验证手机验证码")])
        : _vm._e(),
      _vm._v(" "),
      _vm.checkCode
        ? _c(
            "div",
            {
              staticClass: "update-form",
              staticStyle: { position: "relative" },
            },
            [
              _c("p", [_vm._v("您的用户名为：")]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "20px",
                    "margin-top": "50px",
                    color: "black",
                    "font-weight": "bold",
                  },
                },
                [_vm._v(_vm._s(_vm.userName))]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "update-bt new_btn_sty",
                  on: { click: _vm.goLogin },
                },
                [_vm._v("去登录")]
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "update-form",
              staticStyle: { position: "relative", "padding-top": "60px" },
            },
            [
              _c("div", { staticClass: "reset-code" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.validCode,
                      expression: "validCode",
                    },
                  ],
                  class: _vm.error.errorCode ? "input-error" : "",
                  attrs: {
                    oninput: "value=value.replace(/[^\\d]/g,'')",
                    placeholder: "验证码",
                    type: "text",
                    maxlength: "8",
                  },
                  domProps: { value: _vm.validCode },
                  on: {
                    change: _vm.codeChangeLa,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.validCode = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "getCodeBtn",
                    staticStyle: { "text-align": "center" },
                    on: { click: _vm.getPhoneCode },
                  },
                  [_vm._v(_vm._s(_vm.getCodeNum))]
                ),
              ]),
              _vm._v(" "),
              _vm.errorMsg
                ? _c("p", { attrs: { id: "error" } }, [
                    _vm._v(_vm._s(_vm.errorMsg)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "update-bt",
                  staticStyle: { "margin-top": "60px" },
                  on: { click: _vm.getUserName },
                },
                [_vm._v("确认")]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }