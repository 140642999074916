<template>
  <div class="chain-radar-wrap" :style="powerStyle">
    <div class="header" :class="getClass">
      <div class="left">
        <div class="brand-name">{{ brandName }}</div>
        <div class="location" v-if="currentCity">
          <i class="el-icon-location-outline" style="font-size:24px" />
          <!-- <span>{{ currentCity }}</span> -->
        </div>
        <PeSelect
          v-if="showSelectCity"
          :popperClass="getPopperClass"
          :class="selectStyle"
          style="width:140px;margin-left: 0;"
          :optionsList="cityOptions"
          :defaultSelect="currentCity"
          selectSize="mini"
          @handleSelectChange="handleCityChange"
        />
        <!-- <span style="margin-left: 10px;">测试版本，仅展示部分抽样数据</span> -->
      </div>

      <ul class="add">
        <li
          v-for="item in addTab"
          :key="item.name"
          :class="computedTopClass(item)"
          @click="handleTopClick(item)"
        >
          {{ item.name }}
        </li>
      </ul>

      <div :style="computedMode ? 'opacity:1' : 'opacity:0'" class="operate">
        <div class="screen" @click="handleScreen">
          <svg-icon
            :icon-class="model ? 'screen-dark' : 'screen-light'"
            style="font-size:20px"
          />
          <span>一键投屏</span>
        </div>
        <el-switch v-model="model" @change="handleSwitchChange" />
      </div>
    </div>

    <template v-if="selectMode == '已开门店'">
      <StoreMap
        ref="mapStore"
        @getCurrentCity="getCurrentCity"
        @handleLocateError="handleLocateError"
        @handleMapClick="handleMapClick"
        :circleSwitch="circleSwitch"
        :storeSwitch="storeSwitch"
        :scatterSwitch="scatterSwitch"
        :distributeFlag="distributeFlag"
        :borderRadius="borderRadius"
        :followSwitch="followSwitch"
        :competeSwitch="competeSwitch"
        :followBorders="followBorders"
        :competeBorders="competeBorders"
        :typeCode="typeCode"
      />
      <Panel
        :cityType="cityType"
        :model="model"
        :panelType="panelType"
        :mapClick="mapClick"
        @handleDistribute="handleDistribute"
        @handleStoreSwitch="handleStoreSwitch"
        @handleCircleSwitch="handleCircleSwitch"
        @handleScatterChange="handleScatterChange"
        @handleRangeChange="handleRangeChange"
        @handleFollow="handleFollow"
        @handleCompete="handleCompete"
        @handleFollowSwitch="handleFollowSwitch"
        @handleCompeteSwitch="handleCompeteSwitch"
        @handleFollowScan="handleFollowScan"
        @handleCompeteScan="handleCompeteScan"
        @handleTypeCode="handleTypeCode"
      />
    </template>

    <template v-if="selectMode == '钻石商圈'">
      <diamondMap ref="mapStore" @getCurrentCity="getCurrentCity" />
      <Panel :cityType="cityType" :model="model" />
    </template>

    <template v-if="selectMode == '入驻商场'">
      <PeSelect
        :popperClass="getPopperClass"
        :class="selectStyle"
        :isMultiple="true"
        :isShopCustom="true"
        :optionsList="filterOptions"
        style="position: absolute;right:10px;top:90px;z-index: 111;"
        @handleSelectChange="handleFilterChange"
      />

      <div class="show-total">以下品牌共同入驻的商场共{{ brandTotal }}家</div>

      <ShopMap
        ref="mapStore"
        @getCurrentCity="getCurrentCity"
        @handleBrandSelect="handleBrandSelect"
        @handleBrandTotal="handleBrandTotal"
      />
      <Panel :cityType="cityType" :model="model" />
    </template>

    <template v-if="selectMode == '地址储备'">
      <AiMap ref="mapStore" @getCurrentCity="getCurrentCity" />
      <Panel :cityType="cityType" :model="model" />
    </template>

    <template v-if="selectMode == '品牌打假'">
      <BrandMap ref="mapStore" :rankList="brandList" :model="model" />
    </template>

    <template v-if="selectMode == '媒体舆情'">
      <div class="brand-charts" ref="brandChart"></div>
      <div class="media" v-loading="mediaLoading">
        <div class="top">
          <div class="left">
            <div class="ele">
              <span>时间筛选</span>
              <PeSelect
                :optionsList="timeOptions"
                @handleSelectChange="handleTime"
              />
            </div>
            <div class="ele">
              <span>情感类型</span>
              <PeSelect
                :optionsList="typeOptions"
                @handleSelectChange="handleMode"
              />
            </div>
          </div>
          <div class="right">
            <el-button @click="handleMixed">刷新</el-button>
            <el-button
              class="btn"
              @click="handleRefresh"
              :loading="btnLoading"
              size="small"
              >获取最新舆情</el-button
            >
          </div>
        </div>
        <template v-if="mediaList.length">
          <div class="news-list">
            <div
              class="news-item"
              v-for="(item, index) in mediaList"
              @click="handleDetail(item)"
              :key="index"
            >
              <div class="news-fl">
                <div class="status">
                  <div v-if="item.emotion == '积极'" class="jj">积极</div>
                  <div v-if="item.emotion == '中立'" class="zl">中立</div>
                  <div v-if="item.emotion == '消极'" class="xj">消极</div>
                </div>

                <div class="company">
                  <div class="title">
                    {{ item.title.replace(/\s+/g, '') }}
                  </div>
                  <div
                    class="company-name-click"
                    @click.stop="handleEnter(item)"
                  >
                    {{ item.companyName }}
                  </div>
                </div>
              </div>
              <div class="news-fr">
                <span>来源:{{ item.website }}</span>
                <span class="time">{{ item.rtm }}</span>
              </div>
            </div>
          </div>
          <div class="list-page">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :page-size="20"
              :total="newsParams.total"
            >
            </el-pagination>
          </div>
        </template>
        <NoData v-else />
      </div>
    </template>

    <PeDialog
      :PeDialogShow="noticeShow"
      PeDialogWidth="400px"
      :PeDialogShowSure="false"
      @handlePeDialogClose="noticeShow = false"
    >
      先选择左上角的城市
    </PeDialog>
  </div>
</template>

<script>
import {
  getStoreOpen,
  getStoreClose,
  getStoreBusiness,
  getNewsList,
  getNewsListFilter,
  getNewsListRefresh,
  getBrandFalse,
  getBrandCharts,
  getCitySelect
} from '@/api/module'

import * as echarts from 'echarts'
import PeSelect from '@/components/Peculiar/select'
import PeAngle from '@/components/Peculiar/angle/angle'
import BrandMap from './fake'
import diamondMap from './diamond/map'
import AiMap from './invest/map'
import Panel from './components/panel'
import StoreMap from './store/map'
import ShopMap from './shop'
import PeDialog from '@/components/Peculiar/dialog'
import {
  formatTime,
  enterCompanyDetail,
  brandCustomCharts
} from '@/utils/helper'
export default {
  name: 'PeChainScreen',
  data() {
    return {
      noticeShow: false,
      typeCode: '',
      followBorders: 3000,
      competeBorders: 3000,
      followSwitch: true,
      competeSwitch: true,
      panelType: 'store',
      borderRadius: 3000,
      circleSwitch: false,
      storeSwitch: true,
      scatterSwitch: null,
      distributeFlag: false,
      brandTotal: '',
      filterOptions: [],
      mediaLoading: false,
      cityType: 'Store_Outlet',
      cityOptions: [],
      brandList: [],
      brandName: '',
      newsParams: {
        brandId: '',
        emotion: '',
        publishTime: '',
        pageNo: 1,
        pageSize: 20,
        total: 0
      },
      timeOptions: [],
      typeOptions: [],
      selectMode: '已开门店',
      btnLoading: false,
      mediaList: [],
      currentCity: sessionStorage.cityStorage || '',
      year: 2,
      rankList: [],
      addTab: [
        {
          id: 0,
          name: '已开门店',
          word: '门店数',
          type: 'Store_Outlet'
        },
        // {
        //   id: 1,
        //   name: '钻石商圈',
        //   word: '商圈数',
        //   type: 'Gem_Circle'
        // },
        // {
        //   id: 2,
        //   name: '入驻商场',
        //   word: '商场数',
        //   type: 'Popular_Shop'
        // },
        // {
        //   id: 3,
        //   name: '地址储备',
        //   word: '地址数',
        //   type: 'Ai_Choice'
        // }
        // {
        //   id: 4,
        //   name: '品牌打假'
        // },
        {
          id: 5,
          name: '媒体舆情'
        }
      ],
      model: true,
      isFull: false,
      powerStyle: {
        margin: 0,
        color: '#636363'
      },
      mapClick: 0
    }
  },

  computed: {
    getPopperClass() {
      return this.model ? `active-popper` : `default-popper`
    },

    selectStyle() {
      return this.model ? `active-select` : `default-select`
    },

    showSelectCity() {
      const notList = ['品牌打假', '媒体舆情']
      if (notList.includes(this.selectMode)) {
        return false
      }
      return true
    },
    getClass() {
      if (this.selectMode == '媒体舆情') {
        return 'spec'
      }
      return this.model ? 'dark' : 'light'
    },

    computedMode() {
      if (this.selectMode == '媒体舆情') {
        return false
      }
      return true
    }
  },

  mounted() {
    this.handleInitData()
    this.handleSwitchChange()
    this.brandName = this.$route.query.brandName
    window.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        this.isFull = false
        this.handleExitScreen()
      }
    })
  },

  methods: {
    handleLocateError() {
      this.noticeShow = true
    },

    handleMapClick() {
      this.mapClick++
    },

    handleTypeCode(e) {
      console.log(e, 'ggff')
      this.typeCode = e
    },

    handleFollowScan(e) {
      this.followBorders = e
    },

    handleCompeteScan(e) {
      this.competeBorders = e
    },

    handleFollowSwitch(e) {
      this.followSwitch = e
    },

    handleCompeteSwitch(e) {
      this.competeSwitch = e
    },

    async handleFollow(e) {
      const map = this.$refs.mapStore
      console.log(map, 'follow')
      await map.renderFollowData(e)
      await map.handleDrawScatter(
        this.followBorders,
        map.massFollowList,
        '#6633ff',
        'follow'
      )
    },

    async handleCompete(e) {
      const map = this.$refs.mapStore
      await map.renderCompeteData(e)
      await map.handleDrawScatter(
        this.competeBorders,
        map.massCompeteList,
        '#f00',
        'compete'
      )
    },

    handleRangeChange(e) {
      this.borderRadius = e.value
    },

    handleDistribute(e) {
      this.distributeFlag = e
    },

    handleCircleSwitch(e) {
      this.circleSwitch = e
    },
    handleStoreSwitch(e) {
      this.storeSwitch = e
    },
    handleScatterChange(e) {
      this.scatterSwitch = e
    },

    handleBrandTotal(data) {
      this.brandTotal = data
    },
    handleBrandSelect(data) {
      this.filterOptions = data.map(item => ({
        label: item.brandName,
        value: item
      }))
      console.log(data, 'filter345')
    },
    handleMixed() {
      this.handleNewsList()
      this.handleNewsCharts()
    },
    handleCityChange({ value }) {
      const map = this.$refs.mapStore.map
      map.setCenter([value.longitude, value.latitude])
      sessionStorage.setItem('cityStorage', value.city)
      sessionStorage.setItem('city_lng', value.longitude)
      sessionStorage.setItem('city_lat', value.latitude)
      this.currentCity = value.city
    },
    handleEnter(item) {
      enterCompanyDetail(item, [item])
    },
    handleInitData() {
      if (this.selectMode == '媒体舆情') {
        this.getFilter()
        this.handleNewsList()
        this.handleNewsCharts()
      } else if (this.selectMode == '品牌打假') {
        this.handleBrandList()
      } else {
        // this.initChart()
        this.handleSelectCity()
      }
    },

    handleFilterChange(value) {
      console.log(value, 9966)
      const params = value.map(item => item.value.brandId).join()
      this.$bus.$emit('handleFilterBrand', params)
    },
    async handleSelectCity() {
      const { data } = await getCitySelect({
        type: this.cityType,
        brandId: this.$route.query.brandId
      })
      this.cityOptions = data.map(item => ({
        label: `${item.city}(${item.count})`,
        value: item
      }))
    },
    async handleNewsCharts() {
      const { data } = await getBrandCharts({
        brandId: this.$route.query.brandId
      })
      const topData = data.map(item => item.forwardCount)
      const bottomData = data.map(item => -item.negativeCount)
      const labelData = data.map(item => item.yearMonth)
      const brandChart = echarts.init(this.$refs.brandChart)
      const options = brandCustomCharts(topData, bottomData, labelData)
      brandChart.setOption(options)
    },
    async handleBrandList() {
      const { data } = await getBrandFalse({
        brandId: this.$route.query.brandId
      })
      this.brandList = data
      console.log(data, 'brandList')
    },
    handleDetail(item) {
      if (item.uri) {
        window.open(item.uri)
      }
    },
    handleTime({ value }) {
      const e = value?.value
      this.newsParams.pageNo = 1
      const len = formatTime(e)
      if (!len || !value) {
        this.newsParams.publishTime = ''
      } else {
        this.newsParams.publishTime = len[0]
      }
      this.handleNewsList()
    },
    handleMode({ value }) {
      console.log(value)
      this.newsParams.pageNo = 1
      this.newsParams.emotion = value?.value
      this.handleNewsList()
    },
    handleCurrentChange(e) {
      this.newsParams.pageNo = e
      this.handleNewsList()
    },
    handleTopClick(item) {
      sessionStorage.setItem('selectMode', item.name)
      this.selectMode = item.name
      this.cityType = item.type
      this.handleInitData()
      this.handleSwitchChange()
    },
    async handleRefresh() {
      this.btnLoading = true
      await getNewsListRefresh({
        brandId: this.$route.query.brandId
      })
      this.btnLoading = false
      this.handleNewsList()
    },
    async getFilter() {
      const { data } = await getNewsListFilter({
        brandId: this.$route.query.brandId
      })
      this.timeOptions = data[0].filterOptionList.map(item => ({
        label: item.name,
        value: item
      }))
      this.typeOptions = data[1].filterOptionList.map(item => ({
        label: `${item.name}(${item.count})`,
        value: item
      }))
    },
    async handleNewsList() {
      this.newsParams.brandId = this.$route.query.brandId
      this.mediaLoading = true
      const { data } = await getNewsList(this.newsParams)
      this.mediaList = data?.data || []
      this.newsParams.total = data?.page?.total
      this.mediaLoading = false
    },
    async initChart() {
      const openData = await getStoreOpen({
        year: this.year,
        brandId: this.$route.query.brandId,
        companyUuid: this.$route.query.companyUuid,
        type: 1
      })
      const closeData = await getStoreClose({
        year: this.year,
        brandId: this.$route.query.brandId,
        companyUuid: this.$route.query.companyUuid,
        type: 1
      })

      //营业
      const revenueData = await getStoreBusiness({
        year: this.year,
        brandId: this.$route.query.brandId,
        companyUuid: this.$route.query.companyUuid,
        type: 1
      })
      this.handleRevenueChart(revenueData)
      this.handleSwitchChart(openData, closeData)
    },
    handleRevenueChart(listData) {
      const that = this
      this.$nextTick(() => {
        this.peChart = echarts.init(this.$refs.peChartDom)
        const lineOptions = {
          tooltip: {
            trigger: 'axis'
          },
          color: ['#41cabf'],
          legend: {
            data: ['营业中总门店数'],
            textStyle: {
              color: that.model ? '#fff' : '#333'
            }
          },

          grid: {
            top: '10%',
            left: '10%'
          },
          axisLabel: {
            color: that.model ? '#fff' : '#333'
          },
          axisTick: {
            lineStyle: {
              color: that.model ? '#fff' : '#333'
            }
          },
          xAxis: {
            type: 'category',
            data: listData.data.map(item => item.yearMonth),
            axisLine: {
              lineStyle: {
                color: that.model ? '#fff' : '#333'
              }
            }
          },

          yAxis: {
            type: 'value',
            axisLine: {
              lineStyle: {
                color: that.model ? '#fff' : '#333'
              }
            },
            splitLine: {
              show: false
            }
          },

          series: [
            {
              name: '营业中总门店数',
              data: listData.data.map(item => item.count),
              type: 'line',
              smooth: true
            }
          ]
        }
        this.peChart.setOption(lineOptions)
      })
    },
    handleSwitchChart(openData, closeData) {
      const that = this
      this.$nextTick(() => {
        this.peSwitchChart = echarts.init(this.$refs.peSwitchDom)
        const lineOptions = {
          tooltip: {
            trigger: 'axis'
          },
          color: ['#41cabf', '#f00'],
          legend: {
            data: ['当月新开店数', '当月关店数'],
            textStyle: {
              color: that.model ? '#fff' : '#333'
            }
          },
          grid: {
            top: '10%',
            left: '10%'
          },
          xAxis: {
            type: 'category',
            data: openData.data.map(item => item.yearMonth),
            axisLine: {
              lineStyle: {
                color: that.model ? '#fff' : '#333'
              }
            }
          },
          axisLabel: {
            color: that.model ? '#fff' : '#333'
          },
          axisTick: {
            lineStyle: {
              color: that.model ? '#fff' : '#333'
            }
          },
          yAxis: {
            type: 'value',
            axisLine: {
              lineStyle: {
                color: that.model ? '#fff' : '#333'
              }
            },
            splitLine: {
              show: false
            }
          },

          series: [
            {
              name: '当月新开店数',
              data: openData.data.map(item => item.count),
              type: 'line',
              smooth: true,
              yAxisIndex: 0
            },
            {
              name: '当月关店数',
              data: closeData.data.map(item => item.count),
              type: 'line',
              smooth: true,
              yAxisIndex: 0
            }
          ]
        }
        this.peSwitchChart.setOption(lineOptions)
      })
    },
    getCurrentCity(val) {
      this.currentCity = sessionStorage.cityStorage || val
    },
    computedTopClass(item) {
      if (this.selectMode == item.name) {
        if (this.selectMode == '媒体舆情') {
          return 'active-light li'
        }
        return `${this.model ? 'active-dark li' : 'active-light li'}`
      }
      return 'li'
    },
    computedRank(index) {
      return index + 1 < 10 ? '0' + (index + 1) : index + 1
    },
    handleScreenStyle() {
      const position = this.isFull ? 'fixed' : 'relative'
      const color = this.model ? '#fff' : '#636363'
      const width = this.isFull ? '100%' : 'auto'
      const top = 0
      this.powerStyle = {
        position,
        color,
        width,
        top
      }
    },
    handleSwitchChange() {
      setTimeout(() => {
        this.$nextTick(() => {
          const map = this.$refs.mapStore?.map
          map?.setMapStyle(`amap://styles/${this.model ? 'dark' : 'normal'} `)
          this.handleScreenStyle()
        })
      }, 300)
    },

    handleFullScreen() {
      let de = document.documentElement
      if (de.requestFullscreen) {
        de.requestFullscreen()
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen()
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen()
      }
      this.handleScreenStyle()
      this.$store.state.settings.isFullScreen = true
    },
    handleExitScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
      this.handleScreenStyle()
      this.$store.state.settings.isFullScreen = false
    },
    handleScreen() {
      this.isFull = !this.isFull
      if (this.isFull) {
        this.handleFullScreen()
      } else {
        this.handleExitScreen()
      }
      this.$store.state.settings.isFull = true
    },
    handleChart() {
      this.$router.replace({
        name: 'schemaDetail',
        query: {
          agencyName: this.$route.query.agencyName,
          tagName: this.$route.query.tagName,
          parentName: this.$route.query.parentName,
          id: this.$route.query.id
        }
      })
    }
  },

  components: {
    PeDialog,
    Panel,
    ShopMap,
    AiMap,
    diamondMap,
    BrandMap,
    StoreMap,
    PeAngle,
    PeSelect
  },
  beforeDestroy() {
    sessionStorage.setItem('mode', false)
    window.removeEventListener('fullscreenchange', () => {})
  }
}
</script>
<style lang="scss">
.default-select {
  margin-left: 10px;
}
.active-select {
  margin-left: 10px;
  .el-input__inner {
    background: linear-gradient(
      180deg,
      rgba(99, 255, 250, 0.08) 0%,
      rgba(0, 117, 120, 0.2) 100%
    );
    box-shadow: inset 0px -5px 19px 9px rgba(255, 255, 255, 0.09);
    backdrop-filter: blur(5px);
    color: #fff;
    border: 2px solid rgba(65, 202, 191, 0.46);
  }
  .el-tag--info {
    background: none;
    border: none;
    color: #fff;
  }
}
.active-popper {
  background: linear-gradient(
    180deg,
    rgba(99, 255, 250, 0.08) 0%,
    rgba(0, 117, 120, 0.2) 100%
  );
  box-shadow: inset 0px -5px 19px 9px rgba(255, 255, 255, 0.09);
  backdrop-filter: blur(5px);
  border: 2px solid rgba(65, 202, 191, 0.46);
  .el-select-dropdown__list {
    background: none !important;
  }
  .el-select-dropdown__item {
    color: #fff;
  }
  .hover {
    background: none;
    color: none;
    cursor: pointer;
  }
  .selected {
    background: none !important;
    color: #02a7f0 !important;
  }
}
.chain-radar-wrap {
  .tab {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
    .tab-item {
      position: relative;
      cursor: pointer;
      &:last-child {
        margin-left: 20px;
      }
    }
    .active {
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 21px;
        width: 50px;
        height: 4px;
        border-radius: 4px;
        background: #41cabf;
      }
    }
  }
  .word {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .word-item {
      position: relative;
      span {
        &:last-child {
          margin-left: 20px;
        }
      }
    }
    .text-item {
      display: flex;
      align-items: center;
    }
  }
  .list {
    width: 100%;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 14px 0;
      .it-fl {
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #ff8d00;
          margin: 0 5px;
        }
        .init {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #41cabf;
          margin: 0 5px;
        }
      }

      .it-mid {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      .shrink {
        white-space: nowrap;
      }
      .shrink-spec {
        position: absolute;
        left: 50%;
        transform: translateX(-40%);
      }
      .it-fr {
        display: flex;
        align-items: center;
        justify-content: center;
        .it {
          margin-left: 10px;
        }

        .it-dark {
          color: #d8d8d8;
        }
      }
    }
  }
  .spec {
    background: #fff;
    color: #999;
  }
  .btn {
    margin-left: 20px;
  }
}
</style>
<style lang="scss" scoped>
.chain-radar-wrap {
  position: relative;
  font-size: 14px;
  padding: 0 !important;
  overflow: hidden;
  background: #fff;
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 10px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    font-size: 14px;
    .left {
      display: flex;
      align-items: center;
      .logo {
        width: 140px;
        height: 36px;
      }
      .location {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
      .brand-name {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .add {
      display: flex;
      align-items: center;
      .li {
        cursor: pointer;
        margin: 0 10px;
        padding: 10px;
      }
      .active-light {
        border-radius: 4px;
        color: #41cabf;
        border: 2px solid #41cabf;
      }
      .active-dark {
        color: #fff;
        background: linear-gradient(
          180deg,
          rgba(110, 110, 110, 0.3) 0%,
          rgba(0, 117, 120, 0.2) 100%
        );
        border-radius: 4px;
        border: 2px solid rgba(65, 202, 191, 0.46);
        backdrop-filter: blur(2px);
      }
    }
    .operate {
      display: flex;
      align-items: center;
      .time {
        display: flex;
        align-items: center;
        span {
          margin: 0 5px;
        }
      }
      .screen {
        margin: 0 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          margin: 0 5px;
        }
      }
    }
  }
  .show-total {
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 60px;
    z-index: 111;
  }
  .dark {
    background: linear-gradient(
      180deg,
      rgba(99, 255, 250, 0.08) 0%,
      rgba(0, 117, 120, 0.2) 100%
    );
    box-shadow: inset 0px -5px 19px 9px rgba(255, 255, 255, 0.09);
    backdrop-filter: blur(5px);
  }
  .light {
    background: #fff;
  }
  ::v-deep .amap-ui-district-cluster-marker-body {
    display: none !important;
  }

  .area {
    font-size: 16px;
  }

  .media {
    padding: 0 20px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        .ele {
          display: flex;
          align-items: center;
          color: #909399;
          &:last-child {
            margin: 0 20px;
          }
          span {
            margin-right: 10px;
          }
        }
      }
    }
    .news-list {
      margin-top: 20px;
      padding-bottom: 30px;
      .news-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #f3f3f3;
        &:hover {
          cursor: pointer;
          background-color: rgba(50, 50, 50, 0.05);
          color: #1890ff !important;
        }
        .news-fl {
          display: flex;
          align-items: center;
          width: 85%;
          .status {
            font-weight: 400;
            font-size: 14px;
            width: 5%;
            text-align: center;
            .jj {
              background: #f7fcfa;
              border: 1px solid #41cabf;
              color: #41cabf;
              padding: 2px 6px;
              border-radius: 2px;
            }
            .zl {
              color: gray;
              background: #f9f9f9;
              border: 1px solid #d8d8d8;
              padding: 2px 6px;
              border-radius: 2px;
            }
            .xj {
              background: snow;
              border: 1px solid #f00;
              color: #f00;
              padding: 2px 6px;
              border-radius: 2px;
            }
          }
          .company {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            width: 90%;
            .title {
              font-size: 14px;
              font-weight: bold;
              line-height: 20px;
              margin-bottom: 10px;
              color: #999;
            }
          }
        }
        .news-fr {
          display: flex;
          font-size: 12px;
          align-items: center;
          color: #999;
          .time {
            margin-left: 10px;
          }
        }
      }
    }
    .list-page {
      position: fixed;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      background: #fff;
    }
  }
  .brand-charts {
    width: 100%;
    height: 400px;
  }
}
</style>
