<template>
  <div class="editPurchase-main">
    <div class="main-row">
      <el-form :model="formData" label-width="auto" :rules="rules" size="small" ref="form">

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="采购编号" prop="purchaseId">
              <el-input v-model="formData.purchaseId" placeholder="采购单编号（留空系统自动生成）" :disabled="type === 'edit'"></el-input>
            </el-form-item>

            <el-form-item label="供应商" prop="supplyId">
              <el-select
                style="width: 100%"
                v-model="formData.supplyId"
                filterable
                remote
                reserve-keyword
                placeholder="请选择供应商"
                :remote-method="getSupplyIdList"
                :loading="loading">
                <el-option
                  v-for="item in supplyIdList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="采购类型" prop="purchaseType">
              <el-select v-model="formData.purchaseType" placeholder="请选择采购类型" style="width: 100%">
                <el-option
                  v-for="item in purchaseTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="采购负责人" prop="pda">
              <el-select v-model="formData.pda" placeholder="请选择采购负责人" style="width: 100%" filterable>
                <el-option
                  v-for="item in pdaList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="归属日期" prop="purchaseBelongDate">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM"
                v-model="formData.purchaseBelongDate"
                type="month"
                placeholder="请选择归属日期">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="采购金额" prop="purchaseMoney">
              <el-input v-model="formData.purchaseMoney" placeholder="请输入采购金额"></el-input>
            </el-form-item>

            <el-form-item label="合同" prop="contractId">
              <el-select
                clearable
                popper-class="mou-select"
                style="width: 100%"
                v-model="formData.contractId"
                filterable
                remote
                reserve-keyword
                placeholder="请选择合同"
                :remote-method="getContractIdList"
                :loading="loading">
                <el-option
                  v-for="item in contractIdList"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id">
                  <div style="height: 50px">
<!--                    <div style="font-size: 14px;height: 30px;line-height: 30px">-->
<!--                      {{ item.companyName }}-->
<!--                    </div>-->
<!--                    <div style="font-size: 12px;height: 20px;line-height: 20px">-->
<!--                      {{ '合同金额：' + item.contractAmount }}-->
<!--                    </div>-->
                    {{ `${ item.companyName }(合同金额：${ item.contractAmount })` }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="物流信息" prop="logisticsInfo">
              <el-input v-model="formData.logisticsInfo" placeholder="请输入物流信息"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="采购日期" prop="purchaseDate">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM-dd"
                v-model="formData.purchaseDate"
                type="date"
                placeholder="请选择采购日期">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="采购内容" prop="purchaseContent">
              <el-input v-model="formData.purchaseContent" placeholder="请选择采购内容"
                        type="textarea"
                        maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 4, maxRows: 10 }">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="formData.remark" placeholder="请输入备注"
                        type="textarea"
                        maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 4, maxRows: 10 }"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="附件">
              <el-upload
                class="upload-demo"
                :action="uploadFileUrl"
                ref="upload"
                :before-upload="beforeUpload"
                accept=".doc,.docx,.pdf,.jpg,.png,xls,.xlsx"
                :http-request="uploadFileToOss"
                :on-remove="handleRemove"
                :on-preview="downLoadFile"
                :file-list="fileList"
                :auto-upload="true">
                <el-button slot="trigger" size="mini">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">可上传多个。文件格式限制：doc、docx、pdf、jpg、png、xls、xlsx</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="footer">
      <el-button size="small" @click="cancelBtn">取消</el-button>
      <el-button size="small" type="primary" @click="confirmBtn" v-loading="loading">确认</el-button>
    </div>
  </div>
</template>

<script>
import { getAllSales, supplierList, purchaseAdd, purchaseEdit } from '@/api/thread'
import { contractSearch, getMode } from '@/api/user'
import moment from 'moment'
import OSS from 'ali-oss'
import { getStsToken } from '@/api/table'

export default {
  name: 'editPurchase',
  data () {
    const validateContractAmount = (rule, value, callback) => {
      let reg = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g;
      if (value === '') {
        callback(new Error('请输入合同金额'));
      } else if (!reg.test(value)) {
        callback(new Error('请输入数字'));
      } else {
        callback();
      }
    }
    return {
      formData: {
        id: '',
        purchaseId: `P${ moment().unix() }${ Math.floor(Math.random () * 900) + 100 }` , // 采购单编号
        supplyId: '', // 供应商id
        supplyName: '',
        purchaseType: '', // 采购类型
        purchaseMoney: '', // 采购金额
        pda: '', // 采购负责人
        purchaseDate: '', // 采购日期
        purchaseBelongDate: '', // 归属月份
        contractId: '', // 合同id
        companyName: '',
        contractAmount:'',
        purchaseContent: '', // 采购内容
        purchaseAccessory: '', // 附件
        logisticsInfo: '', // 物流信息
        remark: '', // 备注
      },
      rules: {
        purchaseId: [
          { required: true, message: '请输入采购单编号', trigger: 'blur' }
        ],
        purchaseMoney: [
          { required: true, message: '请输入采购金额', trigger: 'blur' },
          { validator: validateContractAmount, trigger: 'blur' }
        ],
        supplyId: [
          { required: true, message: '请选择供应商', trigger: 'change' }
        ],
        purchaseType: [
          { required: true, message: '请选择采购类型', trigger: 'change' }
        ],
        pda: [
          { required: true, message: '请选择采购负责人', trigger: 'change' }
        ],
        purchaseDate: [
          { required: true, message: '请选择采购日期', trigger: 'change' }
        ],
        purchaseBelongDate: [
          { required: true, message: '请选择归属日期', trigger: 'change' }
        ],
      },
      supplyIdList: [],
      purchaseTypeList: [],
      pdaList: [],
      contractIdList: [],
      loading: false,

      uploadFileUrl:'',
      fileList: [],
      aliOssConfig: {
        region: "oss-cn-shanghai",
        accessKeyId: "",
        accessKeySecret: "",
        stsToken:"",
        bucket: "dtbird-miniapp"
      },
      type: 'create',
      appCustomersContract: null

    }
  },
  mounted () {
    const modalOptions = this.$store.state.plugin.modal.options
    this.type = modalOptions.type || 'create'
    if(this.type === 'edit'){
      if(modalOptions.params.purchaseAccessory) this.fileList = JSON.parse(modalOptions.params.purchaseAccessory)
      if(modalOptions.params.appCustomersContract)  this.contractIdList  = [modalOptions.params.appCustomersContract]
      if(modalOptions.params.supplyId && modalOptions.params.supplyInfo) this.supplyIdList = [{ id: modalOptions.params.supplyId, name: modalOptions.params.supplyInfo }]
      const params = modalOptions.params
      this.formData.supplyName = params.supplyInfo
      this.formData.companyName = params.appCustomersContract?.companyName || ''
      this.formData.contractAmount = params.appCustomersContract?.contractAmount || ''
      this.appCustomersContract = params.appCustomersContract
      this.formData.id = params.id
      this.formData.purchaseId = params.purchaseId
      this.formData.supplyId = params.supplyId
      this.formData.purchaseType = params.purchaseType
      this.formData.purchaseMoney = params.purchaseMoney
      this.formData.pda = params.userId
      this.formData.purchaseDate = params.purchaseDate
      this.formData.purchaseBelongDate = params.purchaseBelongDateDesc
      this.formData.contractId = params.contractId
      this.formData.purchaseContent = params.purchaseContent
      this.formData.logisticsInfo = params.logisticsInfo
      this.formData.remark = params.remark
    }
    this.getPurchaseTypeList()
    this.getAllSales()
    this.getContractIdList()
    this.getSupplyIdList()
  },
  computed: {},
  methods: {
    getSupplyIdList(query){
      supplierList({ pageNo: 1, pageSize: 10, word: query || '' }).then(res => {
        if(res.code === 60000){
          if(this.type === 'edit'){
            this.supplyIdList = [{ id: this.formData.supplyId, name: this.formData.supplyName }, ...res.data.list.filter( i => i.id !== this.formData.supplyId )]
          }else {
            this.supplyIdList = res.data.list
          }
        }
      })
    },
    getContractIdList(query){
      contractSearch({ pageNo: 1, pageSize: 10, companyName: query || '', pageId: 2002 }).then(res => {
        if(res.code === 60000){
          if(this.type === 'edit'){
            this.contractIdList = [{ id: this.formData.contractId, companyName: this.formData.companyName, contractAmount: this.formData.contractAmount }, ...res.data.list.filter(i => i.id !== this.formData.contractId )]
          }else {
            this.contractIdList = res.data.list
          }
        }
      })
    },

    getPurchaseTypeList(){
      getMode({ type: 'SP_01' }).then((res) => {
        if(res.code === 60000){
          this.purchaseTypeList = res.data['SP_01']
        }
      })
    },
    getAllSales(){
      getAllSales().then(res => {
        if(res.code === 60000){
          this.pdaList = res.data
        }
      })
    },

    cancelBtn(){
      this.$store.dispatch('hideModal')
    },
    confirmBtn(){
      if(this.loading) return
      this.$refs['form'].validate(valid => {
        if(!valid) return
        this.loading = true
        if(this.type === 'create'){
          purchaseAdd(Object.assign({}, this.formData, { purchaseAccessory: JSON.stringify(this.fileList), purchaseBelongDate: `${ this.formData.purchaseBelongDate }-01` })).then(res => {
            this.loading = false
            if(res.code === 60000){
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: 'success'
              })
              this.$store.commit('update', {
                target: this.$store.state.plugin.modal.options,
                data: { refresh: true }
              })
              this.cancelBtn()
            }
          })
        }else {
          const params = Object.assign({}, this.formData, { purchaseAccessory: JSON.stringify(this.fileList), purchaseBelongDate: `${ this.formData.purchaseBelongDate }-01` })
          purchaseEdit(params).then(res => {
            this.loading = false
            if(res.code === 60000){
              this.$store.dispatch('showTip', {
                text: res.msg,
                type: 'success'
              })
              this.$store.commit('update', {
                target: this.$store.state.plugin.modal.options,
                data: { refresh: true }
              })
              this.cancelBtn()
            }
          })
        }
      })
    },




    beforeUpload(file){
      let index= file.name.lastIndexOf(".");
      let ext = file.name.substr(index+1);
      if(ext !== 'doc' && ext !== 'docx' && ext !== 'pdf' && ext !== 'jpg' && ext !== 'png' && ext !== 'pages' && ext !== 'gif' && ext !== 'xls' && ext !== 'xlsx'){
        this.$message({
          message: "上传文件格式不正确",
          type: "error"
        });
        return false
      }else{
        return true
      }
    },

    getStsToken(){
      return new Promise(( resolve, reject) => {
        getStsToken({}).then(res => {
          if(res.code === 60000){
            this.aliOssConfig.accessKeyId = res.data.accessKeyId
            this.aliOssConfig.accessKeySecret = res.data.accessKeySecret
            this.aliOssConfig.stsToken = res.data.securityToken
            resolve(true)
          }else {
            this.$store.dispatch('showTip', {
              text: '上传失败'
            })
            resolve(false)
          }
        })
      })
    },

    async uploadFileToOss(file) {
      const index = this.fileList.findIndex(item => item.name === file.file.name);
      if(index !== -1){
        this.fileList.splice(index, 1)
      }
      const sysStatus = await this.getStsToken()
      if(!sysStatus) return
      const client = new OSS(this.aliOssConfig)
      let aliObjName =  file.file.name
      let result = await client.put('/contract/'+aliObjName, file.file)
      this.fileList.push({
        name: aliObjName,
        url: result.url,
        uid : file.file.uid
      })
    },

    handleRemove(file){
      const index = this.fileList.findIndex(item => item.uid === file.uid)
      if(index !== -1){
        this.fileList.splice(index, 1)
      }
    },

    downLoadFile(file){
      if(this.delayUid === file.uid && this.delay){
        return
      }else{
        this.delay = true
        this.delayUid = file.uid
        setTimeout(()=>{
          this.delayUid = ''
          this.delay = false
        },2000)
      }
      this.downloadWithBlob(file.url,file.name)
    },

    downloadWithBlob(url,name) {
      fetch(url).then(res => res.blob().then(blob => {
        let a = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        let filename = name;
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      }));
    },
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.main-row{
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}
.footer{
  height: 50px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.footer:before{
  content: "";
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,.1);
  transform: scaleY(.5);
  position: absolute;
  top: 0;
  left: 0;
}

</style>
