<template>
  <div class="Filter-main" v-loading="loading">
    <div class="filter-item" v-for="(item, index) in filterList">
      <template v-if="item.field === 'province'">
        <div class="title">{{ item.name }}</div>
        <template v-if="!item.filterOptionList.length">
          <div
            style="display: flex;align-items: center;width: 100%;color: rgba(0,0,0,.4);padding-left: 70px;cursor: pointer"
            @click="tipClick"
          >
            未配置地域权限，请联系客服
          </div>
        </template>

        <template v-else>
          <div class="all" @click="selectAll(item)">全部</div>
          <div class="item">
            <span
              v-for="(btn, idx0) in item.filterOptionList"
              :class="btn.checked ? 'active' : ''"
              @click="userClick(btn, item)"
            >
              {{ btn.name }}
            </span>
            <template
              v-if="item.name === '获投时间' || item.name === '成立时间'"
            >
              <el-date-picker
                v-if="item.name === '获投时间'"
                :editable="false"
                :clearable="false"
                size="small"
                @change="value => timeChange(value, item)"
                v-model="htTime"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              <el-date-picker
                v-else
                size="small"
                v-model="clTime"
                @change="value => timeChange(value, item)"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </template>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="title">
          {{ item.name }}
          <el-popover
            v-if="item.name === '上云阶段'"
            placement="end"
            title="上云阶段定义"
            width="500"
            trigger="hover"
          >
            <i class="el-icon-info" style="color: #8a8a8a" slot="reference"></i>
            <el-table :data="ddicList" size="mini" border>
              <el-table-column prop="name" label="上云阶段"> </el-table-column>
              <el-table-column prop="hotDesc" label="热度"> </el-table-column>
              <el-table-column prop="note" label="建议商务方式">
              </el-table-column>
            </el-table>
          </el-popover>
        </div>
        <div class="all" @click="selectAll(item)">全部</div>
        <div class="item" v-if="item.field === 'cloudName'">
          <span
            v-for="(btn, idx1) in item.filterOption.cn"
            :class="btn.checked ? 'active' : ''"
            @click="userClick(btn, item)"
          >
            {{ btn.name }}
          </span>
          <div class="_item">
            <span
              v-for="(btn, idx2) in item.filterOption.overseas"
              :class="btn.checked ? 'active' : ''"
              @click="userClick(btn, item)"
              >{{ btn.name }}</span
            >
          </div>
        </div>
        <div v-else class="item">
          <span
            v-for="(btn, idx3) in item.filterOptionList"
            :class="btn.checked ? 'active' : ''"
            @click="userClick(btn, item)"
          >
            {{ btn.name }}
          </span>
          <template v-if="item.field === 'establishTime'">
            <el-date-picker
              size="small"
              v-model="clTime"
              @change="value => timeChange(value, item)"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </template>
        </div>
      </template>
    </div>
    <div class="filter-item">
      <div class="title">已选条件</div>
      <div class="item">
        <template v-for="(select, source) in selectList">
          <el-tag
            @close="removeSelect(btn, source)"
            v-for="(btn, idx) in select"
            closable
          >
            {{ btn.name }}
          </el-tag>
        </template>
        <template v-if="Object.keys(selectList).length">
          <div @click="removeAll" class="remove">
            清除已选
          </div>
        </template>
      </div>
    </div>
    <div class="count" v-if="count">共{{ count }}家企业</div>
  </div>
</template>

<script>
import { overSeaDdic, applicationFilter } from '@/api/customerpond'
import moment from 'moment'
import tip from '@/views/insight/tip'
export default {
  name: 'ApplicationFilter',
  data() {
    let _data = localStorage.getItem('developmentFilter')
    if (_data) _data = JSON.parse(_data)
    return Object.assign(
      {
        loading: false,
        filterList: [],
        selectList: {},
        htTime: '',
        clTime: '',
        ddicList: []
      },
      _data || {},
      { loading: false }
    )
  },
  props: {
    count: {
      type: [String, Number],
      default: 0
    }
  },
  created() {
    this.getFilter()
  },
  mounted() {
    overSeaDdic().then(res => {
      if (res.code === 60000) {
        this.ddicList = res?.data || []
      }
    })
  },
  methods: {
    tipClick() {
      this.$store.dispatch('showModal', {
        title: '提示',
        view: tip,
        size: '600px',
        options: {
          title: '扫码联系客服'
        }
      })
    },
    getRes() {
      const res = [].concat.apply([], Object.values(this.selectList))
      localStorage.setItem('developmentFilter', JSON.stringify(this.$data))
      return {
        params: {
          firstOverseasIndustry:
            res
              .filter(i => i.key === 'firstOverseasIndustry')
              ?.map(ii => ii.value)
              ?.join(',') || null,
          vcStepCode:
            res
              .filter(i => i.key === 'vcStepCode')
              ?.map(ii => ii.value)
              ?.join(',') || null,
          province:
            res
              .filter(i => i.key === 'province')
              ?.map(ii => ii.name)
              ?.join(',') || null,
          cloudName:
            res
              .filter(i => i.key === 'cloudName')
              ?.map(ii => ii.name)
              ?.join(',') || null,
          cloudStage:
            res
              .filter(i => i.key === 'cloudStage')
              ?.map(ii => ii.value)
              ?.join(',') || null,
          establishTime:
            res
              .filter(i => i.key === 'establishTime')
              ?.map(ii => ii.value)
              ?.join(',') || null
        },
        list: this.selectList
      }
    },
    getFilter(setDefault = true) {
      console.log('received-data')
      applicationFilter({ id: 1 })
        .then(res => {
          if (res.code === 60000) {
            this.filterList = res?.data || []
            this.filterList.forEach(item => {
              if (item.field === 'province') {
                this.$set(
                  item,
                  'filterOptionList',
                  item.filterOption?.['中国'] || []
                )
              }
              if (item.field === 'cloudName') {
                item.filterOption?.cn?.forEach(i => {
                  i['key'] = item.field
                })
                item.filterOption?.overseas?.forEach(i => {
                  i['key'] = item.field
                })
              } else {
                item.filterOptionList?.forEach(i => {
                  i['key'] = item.field
                })
              }
            })
            setDefault ? this.setDefault() : ''
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    setDefault() {
      // const selectList = {
      //   一级行业: [
      //     {
      //       name: "移动应用",
      //       value: 1019,
      //       key: "firstOverseasIndustry",
      //       checked: true
      //     },

      //     {
      //       name: "游戏",
      //       value: 1015,
      //       key: "firstOverseasIndustry",
      //       checked: true
      //     }
      //   ],
      //   云服务商: [
      //     {
      //       name: "阿里云",
      //       value: 1000,
      //       key: "cloudName",
      //       checked: true
      //     },
      //     {
      //       name: "华为云",
      //       value: 1001,
      //       key: "cloudName",
      //       checked: true
      //     },
      //     {
      //       name: "腾讯云",
      //       value: 1002,
      //       key: "cloudName",
      //       checked: true
      //     },
      //     {
      //       name: "谷歌云",
      //       value: 2002,
      //       key: "cloudName",
      //       checked: true
      //     },
      //     {
      //       name: "Azure",
      //       value: 2001,
      //       key: "cloudName",
      //       checked: true
      //     },
      //     {
      //       name: "AWS",
      //       value: 1006,
      //       key: "cloudName",
      //       checked: true
      //     }
      //   ]
      // };
      // this.$set(this, "selectList", selectList);
      this.filterList.forEach(item => {
        if (item.name === '一级行业') {
          item.filterOptionList.forEach(_item => {
            this.$set(_item, 'checked', false)
          })
        } else if (item.name === '云服务商') {
          const typeList = [
            '阿里云',
            '华为云',
            '腾讯云',
            'AWS',
            'Azure',
            '谷歌云'
          ]
          item.filterOption?.cn?.forEach(i => {
            if (typeList.includes(i.name)) {
              this.$set(i, 'checked', false)
            }
          })
          item.filterOption?.overseas?.forEach(i => {
            if (typeList.includes(i.name)) {
              this.$set(i, 'checked', false)
            }
          })
        }
      })
      this.$emit('selectChange', this.getRes())
    },
    selectAll(item) {
      this.selectList[item.name] = []
      delete this.selectList[item.name]

      if (item.field === 'cloudName') {
        item.filterOption?.cn?.forEach(item => {
          item['checked'] = false
        })
        item.filterOption?.overseas?.forEach(item => {
          item['checked'] = false
        })
      } else {
        item.filterOptionList?.forEach(item => {
          item['checked'] = false
        })
      }

      if (item.name === '获投时间' || item.name === '成立时间') {
        this.clTime = ''
        this.htTime = ''
      }
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    timeChange(value, item) {
      if (this.selectList[item.name]) {
        this.selectList[item.name][0].value = value
      } else {
        this.selectList[item.name] = [
          {
            checked: true,
            key: item.field,
            name: '自定义时间',
            value: value
          }
        ]
      }
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    userClick(btn, source) {
      console.log(source, btn, this.clTime, 'source')
      const hasOb = this.selectList[source.name]
      if (hasOb && hasOb.find(item => item.name === btn.name)) {
        this.selectList[source.name] = hasOb?.filter(i => i.name !== btn.name)
        btn.checked = false
      } else {
        // 判断是不是时间单选
        if (source.name === '获投时间' || source.name === '公司成立时间') {
          if (btn.name === '近三个月') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(3, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(3, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '最近半年') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(6, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(6, 'months')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '最近一年') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(1, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(1, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '最近三年') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment()
                    .subtract(3, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment()
                    .subtract(3, 'year')
                    .format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '2022') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment('2022-01-01').format('YYYY-MM-DD'),
                  moment('2022-12-31').format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment('2022-01-01').format('YYYY-MM-DD'),
                  moment('2022-12-31').format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '2021') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment('2021-01-01').format('YYYY-MM-DD'),
                  moment('2021-12-31').format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment('2021-01-01').format('YYYY-MM-DD'),
                  moment('2021-12-31').format('YYYY-MM-DD')
                ])
          }
          if (btn.name === '2020') {
            source.name === '获投时间'
              ? (this.htTime = [
                  moment('2020-01-01').format('YYYY-MM-DD'),
                  moment('2020-12-31').format('YYYY-MM-DD')
                ])
              : (this.clTime = [
                  moment('2020-01-01').format('YYYY-MM-DD'),
                  moment('2020-12-31').format('YYYY-MM-DD')
                ])
          }
          btn.value = source.name === '获投时间' ? this.htTime : this.clTime
          this.selectList[source.name] = [btn]
          this.filterList.forEach(i => {
            if (i.name === source.name) {
              i.filterOptionList.forEach(item => {
                if (item.name !== btn.name) item.checked = false
              })
            }
          })
          this.$forceUpdate()
        } else {
          this.selectList[source.name] = hasOb ? [...hasOb, btn] : [btn]
        }
        btn.checked = true
      }
      this.$forceUpdate()
      this.$emit('selectChange', this.getRes())
    },

    removeSelect(select, name) {
      this.selectList[name] = this.selectList[name].filter(
        i => i.name !== select.name
      )
      if (select.key === 'htTime') this.htTime = null
      if (select.key === 'establishmentTime') this.clTime = null
      select.checked = false
      this.$forceUpdate()

      this.$emit('selectChange', this.getRes())
    },

    removeAll() {
      this.getFilter(false)
      this.selectList = {}
      this.filterList.forEach(item => {
        item.filterOptionList?.forEach(item => {
          item['checked'] = false
        })
        item.filterOption?.cn?.forEach(item => {
          item['checked'] = false
        })
        item.filterOption?.overseas?.forEach(item => {
          item['checked'] = false
        })
      })
      this.$emit('selectChange', this.getRes())
    }
  }
}
</script>

<style scoped lang="scss">
.Filter-main {
  min-height: 200px;
  .remove {
    display: flex;
    align-items: center;
    height: 25px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    padding: 0 5px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .count {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    color: #7a7a7a;
  }
  .filter-item {
    display: flex;
    font-size: 14px;
    padding: 10px 0;
    box-sizing: border-box;
    position: relative;
    &:after {
      content: '';
      display: inline-block;
      height: 1px;
      width: calc(100% - 150px);
      position: absolute;
      left: 150px;
      bottom: 0;
      transform: scaleY(0.5);
      background-color: rgba(0, 0, 0, 0.1);
    }
    .title {
      width: 100px;
      height: 25px;
      line-height: 25px;
    }
    .all {
      width: 50px;
      text-align: center;
      height: 25px;
      line-height: 25px;
      color: #41cabf;
      cursor: pointer;
    }
    .item {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      & > span {
        display: inline-block;
        min-width: 80px;
        width: auto;
        height: 25px;
        line-height: 25px;
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        border-radius: 3px;
      }
      .active {
        color: #ffffff;
        background-color: #41cabf;
      }
    }
    ._item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      & > span {
        display: inline-block;
        min-width: 80px;
        width: auto;
        height: 25px;
        line-height: 25px;
        //background-color: forestgreen;
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        border-radius: 3px;
      }
      .active {
        color: #ffffff;
        background-color: #41cabf;
      }
    }
  }
}
</style>
