var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dt-name-card" }, [
    _c(
      "div",
      { staticClass: "card", class: _vm.type === "1" ? "card" : "card card-2" },
      [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            style: { display: _vm.companyLogo ? "block" : "none" },
            attrs: {
              src: _vm.companyLogo + "?x-oss-process=image/resize,h_40",
              alt: "",
              width: "100%",
              height: "100%",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "avatar-name-position" }, [
          _c("div", {
            staticClass: "avatar",
            style: {
              background: `url(${_vm.avatar}) no-repeat center center`,
              backgroundSize: "60px 60px",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "name-position" }, [
            _c(
              "p",
              { staticClass: "name", class: _vm.type === "2" ? "name-2" : "" },
              [_vm._v("\n          " + _vm._s(_vm.followName) + "\n        ")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "position",
                class: _vm.type === "2" ? "position-2" : "",
              },
              [_vm._v("\n          " + _vm._s(_vm.position) + "\n        ")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "people-detail-msg" }, [
          _c(
            "div",
            {
              staticClass: "detail",
              class: _vm.type === "2" ? "detail-2" : "",
            },
            [
              _c("p", { class: _vm.type === "2" ? "tel-2" : "tel" }, [
                _vm._v(_vm._s(_vm.phoneNum)),
              ]),
              _vm._v(" "),
              _c("p", { class: _vm.type === "2" ? "email-2" : "email" }, [
                _vm._v(_vm._s(_vm.emailNum)),
              ]),
              _vm._v(" "),
              _c("p", { class: _vm.type === "2" ? "website-2" : "website" }, [
                _vm._v(_vm._s(_vm.website)),
              ]),
              _vm._v(" "),
              _c("p", { class: _vm.type === "2" ? "address-2" : "address" }, [
                _vm._v(" " + _vm._s(_vm.address) + " "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "ercode",
            style: {
              background: `url(${_vm.ercode}) no-repeat center center`,
              backgroundSize: "80px 80px",
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }